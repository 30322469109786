import React from 'react';
import { TEXT_SIZE, TEXT_COLOR } from '@components/Text';
import { getFlexibleSize } from '@utils/cssHelpers';
import ResourcesList from '@shared/Resources/shared/components/ResourcesList';
import { getDateDisplay } from '@shared/Resources/shared/components/ListWrappers';
import { listNameHelper } from './helpers';
import {useTranslation} from "react-i18next";

const CourseList = (props) => {
  const {t} = useTranslation()
  const tableConf = [
    {
      getDisplay: (item) => listNameHelper(item,t),
      textOptions: {
        size: TEXT_SIZE.H4,
        color: TEXT_COLOR.DARK_BLUE,
        withEllipsis: true,
      },
      name: t('Common.UI.name'),
    },
    {
      getDisplay: (item) => getDateDisplay(item.entityCreated),
      name: t('Common.UI.created'),
      textOptions: {
        withEllipsis: true,
      },
      width: getFlexibleSize(95, 140),
    },
    {
      getDisplay: (item) => getDateDisplay(item.entityUpdated),
      name: t('Common.UI.modified'),
      textOptions: {
        withEllipsis: true,
      },
      width: getFlexibleSize(95, 120),
    },
    {
      getDisplay: (item) => item.isTemplate ? t('Common.UI.template') : `${t('Common.UI.ownBy')} ${item.owner?.fullName}`,
      name: t('Common.UI.type'),
      textOptions: {
        withEllipsis: true,
      },
      width: getFlexibleSize(95, 120),
    },
  ];
  return <ResourcesList {...props} tableConf={tableConf} />;
};

export default CourseList;
