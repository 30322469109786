/**
 *
 * BuilderHeader
 *
 */

import { Component } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { connect } from 'react-redux';
import { compose } from 'redux';
import injectReducer from '@utils/injectReducer';
import injectSaga from '@utils/injectSaga';
import ToggleSwitch from '@components/ToggleSwitch';
import ErrorMessage, { ERROR_MESSAGE_TYPES } from '@components/ErrorMessage';
import Text, { TEXT_SIZE, TEXT_WEIGHT, TEXT_COLOR } from '@components/Text';
import {
  getUSATimeFromFullDate,
  getUSADateFromFullDate,
} from '@utils/timeHelpers';
import { BUILDER_TYPES, RESOURCES, TYPES } from '@shared/Resources/types';
import {
  BuilderHeaderContainer,
  DetailsContainer,
  NameArea,
  getStyledResourceIcon,
  StyledLoader,
  ControlsArea,
  StyledCTAButton,
  LastSavedContainer,
} from './styles';
import Breadcrumbs from './components/BuilderBreadcrumbs';
import ExpandingInput from './components/ExpandingInput';
import reducer from './reducer';
import saga from './saga';
import { checkForExistingName } from './actions';
import { openPopUp } from '@containers/App/containers/PopUp/actions';
import POPUP_TYPES from '@containers/App/containers/PopUp/types';
import { ENABLE_FREE_SKILL } from '@shared/constants';
import {withTranslation} from "react-i18next";

export class BuilderHeader extends Component {
  constructor(props) {
    super(props);
    this.state = {
      editMode: false,
      name: '',
      switchValue: false,
    };
  }

  componentDidMount() {
    this.updateInitialState();
  }

  componentDidUpdate(prevProps) {
    if (_.get(this.props, 'info.name') !== _.get(prevProps, 'info.name')) {
      this.updateInitialState();
    }
  }

  onNameTextChange = (name) => {
    this.setState({ name });
  };

  onSaveClicked = () => {
    if (!this.state.editMode) {
      this.props.onSave();
    }
  };

  onEditModeChange = (editMode) => {
    if (!editMode) {
      this.acceptChanges();
    }
    this.setState({ editMode });
  };

  isFieldsValid = (type) => {
    const { info, components } = this.props;

    const checkDescription = _.has(info.texts, 'description') || info.texts?.description?.length > 0;
    const checkPollDescription = _.has(info, 'description') || info.description?.length > 0;
    const checkInstructions = _.has(info.texts, 'instructions') || info.texts?.instructions?.length > 0;
    const checkMonitor = _.has(info, 'monitor') && info.monitor?.monitor?.type.length;
    const checkMediaLesson = _.has(info.media, 'lesson') && info.media.lesson !== null;

    const checkPollData = components
      .every(({ type, payload: { questionText, text, description } }) => (type === TYPES.QUESTION && questionText?.length)
        || (type === TYPES.TEXT && text?.length)
        || (type === TYPES.MEDIA && description?.length));

    switch (type) {
      case BUILDER_TYPES.COURSE:
      case BUILDER_TYPES.SKILL:
        return checkDescription;
      case BUILDER_TYPES.LESSON:
        return checkDescription
          && checkInstructions
          && checkMonitor
          && checkMediaLesson;
      case BUILDER_TYPES.EXERCISE:
        return checkInstructions
          && checkMonitor;
      case BUILDER_TYPES.POLL:
        return checkPollDescription
          && checkPollData;
      default: return true;
    }
  };

  isDisabled = () => {
    const { name } = this.state;
    const {
      isFormValid,
      editState: { dirty },
      searchingForExistingName,
      existingName,
      type,
    } = this.props;

    return (
      !isFormValid
      || _.isEmpty(name)
      || !dirty
      || Boolean(searchingForExistingName)
      || Boolean(existingName)
      || !this.isFieldsValid(type)
    );
  };

  showPopup = () => {
    const { type, dispatchOpenPopUp, t } = this.props;

    dispatchOpenPopUp(POPUP_TYPES.CONFIRM, {
      hideCloseIcon: true,
      disableBackdrop: true,
      titleText: t('Informative.Notifying.defaultCourse'),
      bodyText: t('Informative.Notifying.wantDefaultCourse'),
      cancelText: '',
      confirmText: t('Actions.Simple.yes'),
      onConfirm: () => {
        if (type === RESOURCES.SKILL) {
          this.props.updateInfoField('free', true);
        }
        if (type === RESOURCES.COURSE) {
          this.props.updateInfoField('isDefault', true);
        }
        this.setState({ switchValue: true });
      },
    });
  };

  onChangeToggleValue = (value) => {
    const { type } = this.props;
    switch (type) {
      case RESOURCES.SKILL: {
        this.props.updateInfoField('free', value);
        break;
      }
      case RESOURCES.COURSE: {
        if (value) {
          this.showPopup();
          break;
        }
        this.props.updateInfoField('isDefault', false);
        break;
      }
      default:
        break;
    }
    this.setState({ switchValue: false });
  };

  acceptChanges = () => {
    const {
      checkExistingName,
      updateInfoField,
      type,
      info: { name },
      originalName,
    } = this.props;
    this.setState({ name: this.state.name.trim() }, () => {
      updateInfoField('name', this.state.name);
      if (originalName !== this.state.name) {
        checkExistingName(type, this.state.name);
      }
    });
  };

  updateInitialState = () => {
    const { name, free, isDefault } = this.props.info;
    this.setState({
      name,
      switchValue: free || isDefault,
    });
  };

  renderInputError = () => {
    const { searchingForExistingName, existingName, t } = this.props;
    const existingNameError = !searchingForExistingName && existingName;
    const errorProps = {
      type: ERROR_MESSAGE_TYPES.FORMATTED,
    };

    if (_.isEmpty(this.state.name)) {
      errorProps.formatMessageObject = t('Errors.emptyNameError');
      errorProps.className = 'emptyNameError';
    } else if (existingNameError) {
      errorProps.formatMessageObject = t('Errors.existingNameError');
      errorProps.className = 'existingNameError';
    }

    return errorProps.formatMessageObject ? (
      <span>
        <ErrorMessage {...errorProps} />
      </span>
    ) : (
      <span />
    );
  };

  renderResourceName = () => {
    const { name = '', editMode } = this.state;
    const { searchingForExistingName, existingName, type } = this.props;
    const existingNameError = !searchingForExistingName && existingName;
    const isError = !editMode && (_.isEmpty(this.state.name) || existingNameError);

    return (
      <>
        <ExpandingInput
          id="editNameInput"
          value={name}
          editMode={editMode}
          onEditModeChange={this.onEditModeChange}
          onChange={this.onNameTextChange}
          error={isError}
          type={type}
        />
        {searchingForExistingName && (
          <StyledLoader className="searchingExistingName" />
        )}
        {isError && this.renderInputError()}
      </>
    );
  };

  getToggleSwitchTitle = () => {
    const { type, t } = this.props;
    if (type === RESOURCES.SKILL) return t('UI.freeSkill');
    return t('UI.defaultCourse');
  };

  renderControlArea = () => {
    const {
      onPreview,
      editState: { lastSaved },
      type,
      info: {
        free,
        isDefault,
      },
      t
    } = this.props;
    const showToggleSwitch = ENABLE_FREE_SKILL || type === RESOURCES.COURSE;

    return (
      <ControlsArea>
        {showToggleSwitch
          && (
          <ToggleSwitch
            showNames
            names={[t('Actions.Simple.no'), t('Actions.Simple.yes')]}
            value={free || isDefault}
            onChange={this.onChangeToggleValue}
            title={this.getToggleSwitchTitle()}
          />
          )}
        <StyledCTAButton
          className="preview"
          onClick={onPreview}
          outline
          primary
        >
          {t('Actions.Instance.preview')}
        </StyledCTAButton>
        <StyledCTAButton
          id="saveButton"
          disabled={this.isDisabled()}
          className="save"
          onClick={this.onSaveClicked}
        >
          {t('Actions.Instance.save')}
        </StyledCTAButton>
        {lastSaved && (
          <LastSavedContainer>
            <Text
              size={TEXT_SIZE.T4}
              textColor={TEXT_COLOR.DISABLED_LIGHT}
              weight={TEXT_WEIGHT.BOLD}
            >
              {t('Informative.Statuses.lastSaved')}
            </Text>
            <Text
              size={TEXT_SIZE.T4}
              textColor={TEXT_COLOR.DISABLED_LIGHT}
              className="lastSaved"
            >
              {`${getUSADateFromFullDate(lastSaved)}  ${getUSATimeFromFullDate(
                lastSaved,
              )}`}
            </Text>
          </LastSavedContainer>
        )}
      </ControlsArea>
    );
  };

  render() {
    const {
      breadcrumbs,
      onBreadcrumbsNavigation,
      type,
      editState: { lastSaved },
    } = this.props;
    const ResourceIcon = getStyledResourceIcon(type);

    return (
      <BuilderHeaderContainer hasLastSavedItem={lastSaved}>
        <Breadcrumbs
          breadcrumbs={breadcrumbs}
          onNavigation={onBreadcrumbsNavigation}
        />
        <DetailsContainer>
          <NameArea>
            <ResourceIcon />
            {this.renderResourceName()}
          </NameArea>
          {this.renderControlArea()}
        </DetailsContainer>
      </BuilderHeaderContainer>
    );
  }
}

BuilderHeader.propTypes = {
  type: PropTypes.string,
  info: PropTypes.shape({
    name: PropTypes.string,
    switchValue: PropTypes.bool,
  }),
  isFormValid: PropTypes.bool,
  editState: PropTypes.shape({
    dirty: PropTypes.bool,
    lastSaved: PropTypes.number,
  }),
  // name props
  updateInfoField: PropTypes.func,
  checkExistingName: PropTypes.func,
  searchingForExistingName: PropTypes.bool,
  existingName: PropTypes.bool,
  // control props
  onSave: PropTypes.func,
  onPreview: PropTypes.func,
  onBreadcrumbsNavigation: PropTypes.func,
  breadcrumbs: PropTypes.array,
};

export const mapDispatchToProps = (dispatch) => ({
  checkExistingName: (type, name) => dispatch(checkForExistingName(type, name)),
  dispatchOpenPopUp: (type, props) => dispatch(openPopUp(type, props)),
});

const mapStateToProps = (state) => {
  const { searchingForExistingName, existingName } = state.get('nameChecker').toJS();
  const { info, components, originalName } = state.get('builder').toJS();
  return {
    searchingForExistingName, existingName, info, components, originalName,
  };
};

const withReducer = injectReducer({ key: 'nameChecker', reducer });
const withSaga = injectSaga({ key: 'nameChecker', saga });
const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default compose(
    withReducer,
    withConnect,
    withSaga,
    withTranslation()
)(BuilderHeader);
