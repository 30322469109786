import {styled} from "@mui/material";
import { getIconsByType, iconTypes } from '@services/icons';

export const getStyledIcon = (iconName) => getIconsByType(iconTypes[iconName]);

export const IconWrapper = styled('div')(
  {
    maxWidth: 20,
    maxHeight: 20,
    flexShrink: 0,
    marginRight: 8,
    strokeWidth: 3,
    '&>svg': {
      overflow: 'visible',
    },
  },
  ({ theme }) => ({
    background: theme.palette.novotalk.$backgroundColorLight,
  })
);

export const InsightsContainer = styled('div')({
  lineHeight: '1.2em',
  display: 'flex',
});

export const TipContainer = styled('div')({
  display: 'flex',
  marginBottom: 27,
});

export const SkillWrapper = styled('div')(
  {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    margin: '0 10px',
    maxWidth: '350px',
    minWidth: '350px',
    minHeight: '268px',
    padding: '35px 17px 25px',
  },
  ({ theme }) => ({
    background: theme.palette.novotalk.$backgroundColorLight,
  })
);

export const PerfectIcon = getIconsByType(iconTypes.CHECKMARK);

export const PerfectIconWrapper = styled('div')(
  {
    width: 27,
    height: 20,
    marginTop: 45,
    marginBottom: 12,
    '&>svg': {
      fontSize: '2em',
    },
  },
  ({ theme }) => ({
    color: theme.palette.novotalk.$completedColor,
  })
);

export const PerfectWrapper = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
  alignItems: 'center',
});
