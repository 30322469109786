import {styled} from "@mui/material";

export const ModalContainer = styled('div')({
  width: 400,
  borderRadius: 5,
  padding: '20px 20px',
  margin: '0 auto',
  backgroundColor: '#fafafa',
});

export const WithMargin = styled('div')((props) => ({
  marginTop: props.marginTop,
  marginBottom: props.marginBottom,
}));
