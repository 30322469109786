import { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { withRouter } from 'react-router-dom';
import _ from 'lodash';
import injectReducer from '@utils/injectReducer';
import injectSaga from '@utils/injectSaga';
import SectionLoading from '@components/SectionLoading';
import { ROUTES } from '@shared/constants';
import reducer from './reducer';
import saga from './saga';
import { getCourses, setActivityContext, updateActivityLog } from './actions';
import Course from './containers/Course';
import Lesson from './containers/Lesson';
import Practice from './containers/Practice';
import Poll from './containers/Poll';
import courseStatusConsts from '../../helpers/courseProgressConsts';
import COURSE_TYPES from '../../helpers/courseTypes';
import { CourseComponentWrapper } from './styles';
import { LOG_STATUS } from './consts';
import { getIndexAndTypeOfPractice, getIndexesOfCurrentEntity, loggerHelper } from './helpers';

const COUNT_SLASHES_IN_PATH_URL = 2;

export class CourseRouter extends Component {
  constructor(props) {
    super(props);
    this.state = {
      pathVariables: {},
      currentLocation: this.props.history.location.pathname,
    };
  }

  // If number of slashes in previous path and current path is different then course will be updated
  updateCourseAfterReturnBackToOverview = () => {
    const currentLocationIndex = this.props.history.location.pathname.match(new RegExp('/', 'g')).length;
    const prevLocationIndex = this.state.currentLocation.match(new RegExp('/', 'g')).length;

    if (currentLocationIndex <= COUNT_SLASHES_IN_PATH_URL && currentLocationIndex !== prevLocationIndex) {
      this.props.dispatchGetCourses();
    }
  };

  componentDidMount() {
    this.props.dispatchGetCourses();
  }

  componentDidUpdate(prevProps, prevState) {
    const { match, courses, history } = this.props;
    const getCourses = history.location.state?.getCourses;
    if (this.props.location !== prevProps.location) {
      const prevPathname = prevProps.location?.pathname;
      const pathname = this.props.location?.pathname;

      if (prevPathname && pathname) {
        loggerHelper(pathname, prevPathname, this.updateActivityLogCallback, courses);
      }
    }
    this.updateCourseAfterReturnBackToOverview();

    if (_.isEmpty(courses)) return;
    if (!_.isEqual(prevProps.match, match)
      || !_.isEqual(prevProps.courses, courses || !_.isEqual(this.state.pathVariables, prevState.pathVariables))
      || _.isEmpty(this.state.pathVariables)
      || prevProps.location.pathname !== this.props.location.pathname
    ) {
      const pathVariables = this.parsePathParams();

      const { originalPathValid, path } = pathVariables;
      if (!originalPathValid) {
        if (getCourses) {
          this.props.dispatchGetCourses();
        }
        history.push(path);
      } else {
        this.setState({ pathVariables });
        this.updateActivityContext(pathVariables);
      }
    }

    if (this.state.currentLocation !== history.location.pathname) {
      this.setState({ currentLocation: history.location.pathname });
    }
  }

  updateActivityLogCallback = (id, type, status) => {
    const {
      dispatchUpdateActivityLog, courses, location,
    } = this.props;
    const { pathname } = location;
    const numb = `[${pathname.match(/\d/g)?.join(',')}]`;

    try {
      const array = JSON.parse(numb);
      const skillName = courses[0].Skills[array[1]].name;
      dispatchUpdateActivityLog(id, type, status, skillName);
    } catch (e) {
      dispatchUpdateActivityLog(id, type, status, '');
    }
  };

  getIndexFromParameter(parameter) {
    const index = parseInt(parameter.match(/\d+/g)[0]);
    return index;
  }

  getTypeFromParameter(parameter) {
    const type = parameter.match(/[a-zA-Z]+/g)[0];
    return type;
  }

  getExerciseVariables(pathVariables, contextStack, skillPartToDisplay) {
    const exerciseVariables = {};
    if (pathVariables.componentToDisplay === COURSE_TYPES.PRACTICE) {
      const exerciseIndex = contextStack[3] && this.getIndexFromParameter(contextStack[3]);
      const exerciseToDisplay = skillPartToDisplay.Exercises[exerciseIndex];
      if (
        _.isNumber(exerciseIndex)
        && exerciseToDisplay
        && exerciseToDisplay.status !== courseStatusConsts.LOCKED
      ) {
        exerciseVariables.exerciseIndex = exerciseIndex;
      } else {
        exerciseVariables.exerciseIndex = 0;
        exerciseVariables.originalPathValid = false;
      }
      exerciseVariables.path = `${pathVariables.path}/${exerciseVariables.exerciseIndex}`;
    } else {
      exerciseVariables.originalPathValid = false;
    }
    return exerciseVariables;
  }

  updateActivityContext = (pathVariables) => {
    const { courseIndex, skillToDisplay } = pathVariables;
    const activityContext = {
      COURSE: {
        id: this.props.courses[courseIndex].id,
      },
    };
    if (skillToDisplay) {
      activityContext.SKILL = {
        id: skillToDisplay.id,
        version: skillToDisplay.version,
      };
    }
    if (!_.isEqual(this.props.activityContext, activityContext)) {
      this.props.dispatchSetActivityContext(activityContext);
    }
  };

  parsePathParams() {
    const { match, courses, history } = this.props;
    const nextPath = history.location.state?.nextPath;
    const fallbackPathVariables = {
      path: `${ROUTES.COURSE_PAGE}/0`,
      courseIndex: 0,
      originalPathValid: false,
    };
    let pathVariables = {
      path: `${ROUTES.COURSE_PAGE}/`,
      originalPathValid: true,
      componentToDisplay: COURSE_TYPES.COURSE,
    };

    const contextStack = _.map(match.params, (value) => value).filter(
      (value) => value !== undefined,
    );
    if (contextStack.length === 0) return fallbackPathVariables;
    const courseIndex = contextStack[0];
    const courseToDisplay = courses[courseIndex];
    if (!courseToDisplay) {
      pathVariables = fallbackPathVariables;
    }
    pathVariables.courseIndex = parseInt(courseIndex);
    pathVariables.path += `${courseIndex}`;
    if (contextStack.length === 1) return pathVariables;

    const skillIndex = this.getIndexFromParameter(contextStack[1]);
    const skillToDisplay = courseToDisplay.Skills[skillIndex];

    if (!skillToDisplay) {
      pathVariables = fallbackPathVariables;
    }

    const skillpart = contextStack[2];
    const skillpartIndex = skillpart && this.getIndexFromParameter(skillpart);
    const skillpartType = skillpart && this.getTypeFromParameter(skillpart);
    const skillPartToDisplay = _.get(
      skillToDisplay,
      [`${_.capitalize(skillpartType)}s`, skillpartIndex],
      null,
    );
    if (
      skillpart
      && skillPartToDisplay
      && (skillPartToDisplay.status !== courseStatusConsts.LOCKED || nextPath)
    ) {
      pathVariables.skillIndex = skillIndex;
      pathVariables.path += `/skill${skillIndex}/${skillpart}`;
      pathVariables.skillToDisplay = skillToDisplay;
      pathVariables.componentToDisplay = COURSE_TYPES[skillpartType.toUpperCase()];
      pathVariables.skillPartToDisplay = skillPartToDisplay;
      pathVariables.skillpartIndex = skillpartIndex;
      pathVariables.skillpartType = skillpartType;
    } else {
      pathVariables.originalPathValid = false;
    }
    if (contextStack.length > 3) {
      const exerciseVariables = this.getExerciseVariables(
        pathVariables,
        contextStack,
        skillPartToDisplay,
      );
      pathVariables = {
        ...pathVariables,
        ...exerciseVariables,
      };
    }
    return pathVariables;
  }

  renderCourseComponent() {
    const { pathVariables } = this.state;
    if (_.isEmpty(this.props.courses)) {
      return <div>the user has no courses</div>;
    }
    if (_.isEmpty(pathVariables)) return null;
    const {
      componentToDisplay,
      courseIndex,
      skillToDisplay,
      skillpartIndex,
      exerciseIndex,
    } = pathVariables;
    switch (componentToDisplay) {
      case COURSE_TYPES.COURSE:
        return (
          <Course
            {...this.props.courses[courseIndex]}
            courseIndex={courseIndex}
          />
        );
      case COURSE_TYPES.LESSON:
        return (
          <Lesson skillpartIndex={skillpartIndex} skill={skillToDisplay} />
        );
      case COURSE_TYPES.PRACTICE:
        return (
          <Practice
            skillpartIndex={skillpartIndex}
            skill={skillToDisplay}
            exerciseIndex={exerciseIndex}
          />
        );
      case COURSE_TYPES.POLL:
        return <Poll skillpartIndex={skillpartIndex} skill={skillToDisplay} />;
      default:
        return null;
    }
  }

  render() {
    const { loading } = this.props;
    if (loading) {
      return <SectionLoading />;
    }

    return (
      <CourseComponentWrapper>
        {this.renderCourseComponent()}
      </CourseComponentWrapper>
    );
  }
}

CourseRouter.propTypes = {
  dispatchGetCourses: PropTypes.func,
  dispatchSetActivityContext: PropTypes.func,
  loading: PropTypes.bool,
  history: PropTypes.object,
  match: PropTypes.object,
  courses: PropTypes.array,
  activityContext: PropTypes.object,
};

export const mapStateToProps = (state) => {
  const { courses, loading, activityContext } = state.getIn(['course']).toJS();
  return {
    courses, loading, activityContext,
  };
};

export function mapDispatchToProps(dispatch) {
  return {
    dispatchGetCourses: () => dispatch(getCourses()),
    dispatchSetActivityContext: (activityContext) => dispatch(setActivityContext(activityContext)),
    dispatchUpdateActivityLog: (id, typeEntity, status, skillName) => dispatch(updateActivityLog(id, typeEntity, status, skillName)),
  };
}

const withConnect = connect(mapStateToProps, mapDispatchToProps);
const withReducer = injectReducer({ key: 'course', reducer });
const withSaga = injectSaga({ key: 'course', saga });

export default compose(
  withReducer,
  withRouter,
  withSaga,
  withConnect,
)(CourseRouter);
