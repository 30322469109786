import { BUILDER_TYPES } from '@shared/Resources/types';
import {
  ExerciseFields,
  SkillFields,
  LessonFields,
  CourseFields,
  PollFields,
} from '@containers/Admin/containers/Builder/forms/index';

export const creatingInitialFormByType = (type) => {
  switch (type) {
    case BUILDER_TYPES.COURSE: return CourseFields;
    case BUILDER_TYPES.SKILL: return SkillFields;
    case BUILDER_TYPES.LESSON: return LessonFields;
    case BUILDER_TYPES.EXERCISE: return ExerciseFields;
    case BUILDER_TYPES.POLL: return PollFields;
    case BUILDER_TYPES.STIMULUS: return null; //TODO
    default: return [];
  }
};
