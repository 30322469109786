import {styled} from "@mui/material";
import { getIconsByType, iconTypes } from '@services/icons';

export const SearchIcon = getIconsByType(iconTypes.SEARCH);

export const StyledSearchIcon = styled(SearchIcon)({
  fontSize: 19,
});

export const TemplateMenuHeader = styled('div')({
  display: 'flex',
  alignItems: 'center',
});

const chevronStyles = ({ theme }) => ({
  fontSize: 17,
  marginLeft: 3,
  marginTop: 3,
  color: theme.palette.novotalk.$activeColor,
});

export const ChevronUpIcon = styled(getIconsByType(iconTypes.FILLED_ARROW_UP))(
  chevronStyles
);
export const ChevronDownIcon = styled(
  getIconsByType(iconTypes.FILLED_ARROW_DOWN)
)(chevronStyles);

export const MenuContainer = styled('div')({
  position: 'absolute',
  top: 100,
  right: '17%',
  width: 250,
  height: 'auto',
  backgroundColor: 'white',
  border: '1px solid #DDD',
  borderRadius: 2,
  zIndex: 500,
});

export const MenuContainerHeader = styled('div')(
  {
    padding: 15,
  },
  ({ theme }) => ({
    borderBottom: `2px solid ${theme.palette.novotalk.$borderColorLight}`,
  })
);

export const ItemsListContainer = styled('div')({
  overflow: 'scroll',
  overflowX: 'hidden',
  height: 'auto',
  maxHeight: 350,
  '::WebkitScrollbar': {
    width: 10,
    zIndex: -10,
  },
  '::WebkitScrollbarThumb': {
    width: 18,
    backgroundColor: '#d6d4d4',
    borderRadius: 4,
    borderRight: '2px solid white',
  },
});

export const GroupNameContainer = styled('div')({
  paddingLeft: 15,
  paddingTop: 10,
  paddingBottom: 10,
});
