import {styled} from "@mui/material";
import {
  getIconByResourceType,
  getIconsByType,
  iconTypes,
} from '@services/icons';
import CTAButton from '@components/CTAButton';

export const Wrapper = styled('div')({
  width: '100%',
  display: 'flex',
  alignItems: 'center',
  marginBottom: 12,
});

export const ResourceButton = styled((props) =>
  CTAButton({ ...props, cancel: true })
)(
  {
    display: 'flex',
    alignItems: 'center',
    marginRight: 4,
  },
  ({ theme, isCurrent }) =>
    isCurrent && {
      pointerEvents: 'none',
      color: theme.palette.novotalk.$activeColor,
      marginLeft: 5,
    }
);

const iconStyles = {
  fontSize: 16,
  marginRight: 5,
};

export const getStyledResourceIcon = (type) =>
  styled(getIconByResourceType(type))(iconStyles);

export const StyledHomeIcon = styled(getIconsByType(iconTypes.HOME))(
  iconStyles
);

export const StyledChevron = styled(getIconsByType(iconTypes.CHEVRON_RIGHT))(
  {
    fontSize: 13,
    marginRight: 4,
    marginBottom: -3,
  },
  ({ theme }) => ({
    color: theme.palette.novotalk.$buttonColorDisabled,
  })
);
