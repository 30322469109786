import { put, takeLatest, all, fork, join } from 'redux-saga/effects';
import { authenticationWrapper } from '@services/errorHandlingService';
import {
  TYPES,
  checkForExistingNameComplete,
  checkForExistingNameError,
} from './actions';
import { checkExistingEntityName, checkExistingMediaTitle } from './service';
import { RESOURCES } from '@shared/Resources/types';

export function* callCheckForExistingName(action) {
  yield authenticationWrapper(function* codeBlock() {
    try {
      const { type, name } = action.payload;
      const res = type === RESOURCES.MEDIA
        ? yield fork(checkExistingMediaTitle, name)
        : yield fork(checkExistingEntityName, type, name)
      const result = yield join(res);
      yield put(checkForExistingNameComplete(result));
    } catch (error) {
      yield put(checkForExistingNameError(error.message));
    }
  });
}

function* watchCallCheckForExistingName() {
  yield takeLatest(TYPES.CHECK_FOR_EXISTING_NAME, callCheckForExistingName);
}

export default function* builderDataBuilderHeaderSaga() {
  yield all([fork(watchCallCheckForExistingName)]);
}
