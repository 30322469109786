import {styled} from "@mui/material";
import CTAButton from '@components/CTAButton';

export const Wrapper = styled('div')(
  {
    minHeight: 250,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: '40px 75px',
    borderRadius: 4,
  },
  ({ theme, width }) => ({
    minWidth: width || 490,
    maxWidth: width || 490,
    backgroundColor: theme.palette.novotalk.$backgroundColor,
  })
);

export const Body = styled('div')({
  display: 'flex',
  alignItems: 'center',
  marginBottom: 33,
  justifyContent: 'center',
  textAlign: 'center',
});

export const Title = styled(Body)({
  marginBottom: 5,
});

export const StyledCTAButton = styled(CTAButton)({
  width: 220,
  height: 54,
  borderRadius: 27,
});
