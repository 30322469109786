const map = {
  'Technical issues': {
    'users do other things while practicing': 'Please remember that while practicing you are recorded and your screen is shared. ',
    "screen isn't shared": 'Make sure to share the screen you practice in, so that the clinician can watch and listen to the lesson and be able to give feedback',
    'User does not notice the marks on the monitor': 'Make sure that you watch the Monitor all the time in order to receive visual feedback. The progress bars are there as guidelines throughout the program and it is important to use them.',
    'background noises': 'The Novotalk system is extremely sensitive and may sometimes pick up breathing and background noise unrelated to your actual performance. In this case, try and move the microphone slghtly further away from your mouth and recalibrate.',
    "system isn't calibrated": 'Make sure to carry out calibration of the system.',
  },
};
export default map;
