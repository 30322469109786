import courseTypes from '../../../../../helpers/courseTypes';

export const practices = [
            {
                id: 'p1',
                type: courseTypes.PRACTICE,
                duration: 10,
                name: 'breathing beginner',
                children: [
                    {
                        "locale" : "en",
                        "id" : "5a130ed9a0800000",
                        "name" : "Breathing",
                        "version" : 1,
                        "type" : "EXERCISE",
                        "media" : {
                            "demo" : 'https://s3-eu-west-1.amazonaws.com/nvtk-video-media/03_01%20Hebrew%20Low.mp4'
                        },
                        "texts" : {
                            "instructions" : "breathing exercise"
                        },
                        "monitor" : {
                            "monitor" : {
                                "type" : "BREATHING",
                                "inhalation" : {
                                    "on" : true,
                                    "value" : 4
                                },
                                "video" : {
                                    "on" : false,
                                    "url" : null,
                                    "id" : null
                                },
                                "mirror" : {
                                    "on" : false
                                },
                                "speedometer" : {
                                    "on" : false
                                }
                            },
                            "pattern" : {
                                "type" : "GENTLE_VOICE",
                                "productionDuration" : 5
                            },
                            "errors" : {
                                "on" : true,
                                "masteryLevel" : 40,
                                "production" : {
                                    "tooShort" : {
                                        "on" : true,
                                        "type" : "EASY"
                                    },
                                    "tooLong" : {
                                        "on" : true,
                                        "type" : "EASY"
                                    },
                                    "notLoud" : {
                                        "on" : false,
                                        "type" : "EASY"
                                    },
                                    "notGentle" : {
                                        "on" : false,
                                        "type" : "EASY"
                                    },
                                    "noPattern" : {
                                        "on" : false,
                                        "type" : "EASY"
                                    }
                                },
                                "specific" : {
                                    "beginning" : {
                                        "nonGentleOnset" : {
                                            "on" : false,
                                            "type" : "EASY"
                                        },
                                        "notGentle" : {
                                            "on" : false,
                                            "type" : "EASY"
                                        },
                                        "volumeControl" : {
                                            "on" : false,
                                            "type" : "EASY"
                                        }
                                    },
                                    "peak" : {
                                        "notLoud" : {
                                            "on" : false,
                                            "type" : "EASY"
                                        },
                                        "softPeak" : {
                                            "on" : false,
                                            "type" : "EASY"
                                        },
                                        "excessivePeak" : {
                                            "on" : false,
                                            "type" : "EASY"
                                        }
                                    },
                                    "end" : {
                                        "volumeControl" : {
                                            "on" : false,
                                            "type" : "EASY"
                                        },
                                        "nonGentleOffset" : {
                                            "on" : false,
                                            "type" : "EASY"
                                        }
                                    }
                                }
                            },
                            "progress" : {
                                "type" : "SEQUENCES",
                                "value" : 15,
                                "repetition" : {
                                    "on" : false,
                                    "count" : 0
                                }
                            },
                            "stimulus" : {
                                "type" : "CUSTOM",
                                "config" : {
                                    "customText" : "ssssss"
                                }
                            }
                        },
                    },                 
                ],
                activity: {
                    id: "activityId",
                    createdAt: 'Wed Dec 01 2018 13:52:30 GMT+0200',
                    exercises: [
                       { 
                         id: '5a130ed9a0800000', 
                         achievedMasteryLevel: 60, 
                         fails: 0,
                         tips: [
                            {
                              id:'tip1'
                            },
                            { 
                              id: 'tip2'
                            },
                            { 
                                id: 'tip3'
                            }
                         ] 
                      },
                    ],
                    feedback: [
                      {
                        id: 'postId1',
                        publisherName: 'John Smith',
                        publisherAvatar: 'https://s3-eu-west-1.amazonaws.com/nvtk-user-avatar/defaults/4.svg',
                        createdAt: 'Wed Dec 05 2018 13:52:30 GMT+0200',
                        data: 'Lorem ipsum dolor amet cloud bread shoreditch before they sold out hot chicken brunch, health goth retro actually narwhal gentrify ugh XOXO mlkshk. Roof party sartorial slow-carb lomo.'
                       },
                     {
                        id: 'postId2',
                        publisherName: 'John Smith',
                        publisherAvatar: 'https://s3-eu-west-1.amazonaws.com/nvtk-user-avatar/defaults/5.svg',
                        createdAt: 'Wed Dec 05 2018 13:54:30 GMT+0200',
                        data: 'Lorem ipsum dolor amet cloud bread shoreditch before they sold out hot chicken brunch, health goth retro actually narwhal gentrify ugh XOXO mlkshk. Roof party sartorial slow-carb lomo.'
                       }
                    ]
                }

            },
            {
                id: 'p2',
                type: courseTypes.PRACTICE,
                duration: 15,
                name: 'breathing advanced',
                children: [
                    {
                        "locale" : "en",
                        "id" : "5a130ed9a0800000",
                        "name" : "Breathing",
                        "version" : 1,
                        "type" : "EXERCISE",
                        "media" : {
                            "demo" : 'https://s3-eu-west-1.amazonaws.com/nvtk-video-media/03_01%20Hebrew%20Low.mp4'
                        },
                        "texts" : {
                            "instructions" : "breathing exercise"
                        },
                        "monitor" : {
                            "monitor" : {
                                "type" : "BREATHING",
                                "inhalation" : {
                                    "on" : true,
                                    "value" : 4
                                },
                                "video" : {
                                    "on" : false,
                                    "url" : null,
                                    "id" : null
                                },
                                "mirror" : {
                                    "on" : false
                                },
                                "speedometer" : {
                                    "on" : false
                                }
                            },
                            "pattern" : {
                                "type" : "GENTLE_VOICE",
                                "productionDuration" : 5
                            },
                            "errors" : {
                                "on" : true,
                                "masteryLevel" : 40,
                                "production" : {
                                    "tooShort" : {
                                        "on" : true,
                                        "type" : "EASY"
                                    },
                                    "tooLong" : {
                                        "on" : true,
                                        "type" : "EASY"
                                    },
                                    "notLoud" : {
                                        "on" : false,
                                        "type" : "EASY"
                                    },
                                    "notGentle" : {
                                        "on" : false,
                                        "type" : "EASY"
                                    },
                                    "noPattern" : {
                                        "on" : false,
                                        "type" : "EASY"
                                    }
                                },
                                "specific" : {
                                    "beginning" : {
                                        "nonGentleOnset" : {
                                            "on" : false,
                                            "type" : "EASY"
                                        },
                                        "notGentle" : {
                                            "on" : false,
                                            "type" : "EASY"
                                        },
                                        "volumeControl" : {
                                            "on" : false,
                                            "type" : "EASY"
                                        }
                                    },
                                    "peak" : {
                                        "notLoud" : {
                                            "on" : false,
                                            "type" : "EASY"
                                        },
                                        "softPeak" : {
                                            "on" : false,
                                            "type" : "EASY"
                                        },
                                        "excessivePeak" : {
                                            "on" : false,
                                            "type" : "EASY"
                                        }
                                    },
                                    "end" : {
                                        "volumeControl" : {
                                            "on" : false,
                                            "type" : "EASY"
                                        },
                                        "nonGentleOffset" : {
                                            "on" : false,
                                            "type" : "EASY"
                                        }
                                    }
                                }
                            },
                            "progress" : {
                                "type" : "SEQUENCES",
                                "value" : 15,
                                "repetition" : {
                                    "on" : false,
                                    "count" : 0
                                }
                            },
                            "stimulus" : {
                                "type" : "CUSTOM",
                                "config" : {
                                    "customText" : "ssssss"
                                }
                            }
                        },
                    },                    
                ],
            },
            {
                id: 'p3',
                duration: 20,
                name: 'breathing expert',
                type: courseTypes.PRACTICE,
                children: [
                    {
                        "locale" : "en",
                        "id" : "5a130ed9a0800000",
                        "name" : "Breathing",
                        "version" : 1,
                        "type" : "EXERCISE",
                        "media" : {
                            "demo" : 'https://s3-eu-west-1.amazonaws.com/nvtk-video-media/03_01%20Hebrew%20Low.mp4'
                        },
                        "texts" : {
                            "instructions" : "breathing exercise"
                        },
                        "monitor" : {
                            "monitor" : {
                                "type" : "BREATHING",
                                "inhalation" : {
                                    "on" : true,
                                    "value" : 4
                                },
                                "video" : {
                                    "on" : false,
                                    "url" : null,
                                    "id" : null
                                },
                                "mirror" : {
                                    "on" : false
                                },
                                "speedometer" : {
                                    "on" : false
                                }
                            },
                            "pattern" : {
                                "type" : "GENTLE_VOICE",
                                "productionDuration" : 5
                            },
                            "errors" : {
                                "on" : true,
                                "masteryLevel" : 40,
                                "production" : {
                                    "tooShort" : {
                                        "on" : true,
                                        "type" : "EASY"
                                    },
                                    "tooLong" : {
                                        "on" : true,
                                        "type" : "EASY"
                                    },
                                    "notLoud" : {
                                        "on" : false,
                                        "type" : "EASY"
                                    },
                                    "notGentle" : {
                                        "on" : false,
                                        "type" : "EASY"
                                    },
                                    "noPattern" : {
                                        "on" : false,
                                        "type" : "EASY"
                                    }
                                },
                                "specific" : {
                                    "beginning" : {
                                        "nonGentleOnset" : {
                                            "on" : false,
                                            "type" : "EASY"
                                        },
                                        "notGentle" : {
                                            "on" : false,
                                            "type" : "EASY"
                                        },
                                        "volumeControl" : {
                                            "on" : false,
                                            "type" : "EASY"
                                        }
                                    },
                                    "peak" : {
                                        "notLoud" : {
                                            "on" : false,
                                            "type" : "EASY"
                                        },
                                        "softPeak" : {
                                            "on" : false,
                                            "type" : "EASY"
                                        },
                                        "excessivePeak" : {
                                            "on" : false,
                                            "type" : "EASY"
                                        }
                                    },
                                    "end" : {
                                        "volumeControl" : {
                                            "on" : false,
                                            "type" : "EASY"
                                        },
                                        "nonGentleOffset" : {
                                            "on" : false,
                                            "type" : "EASY"
                                        }
                                    }
                                }
                            },
                            "progress" : {
                                "type" : "SEQUENCES",
                                "value" : 15,
                                "repetition" : {
                                    "on" : false,
                                    "count" : 0
                                }
                            },
                            "stimulus" : {
                                "type" : "CUSTOM",
                                "config" : {
                                    "customText" : "ssssss"
                                }
                            }
                        },
                    },                  
                ],
            },
            {
                id: 'p4',
                duration: 10,
                name: 'relaxation - beginner',
                type: courseTypes.PRACTICE,
                children: [
                    {
                        "locale" : "en",
                        "id" : "5a130ed9a0800000",
                        "name" : "Breathing",
                        "version" : 1,
                        "type" : "EXERCISE",
                        "media" : {
                            "demo" : 'https://s3-eu-west-1.amazonaws.com/nvtk-video-media/03_01%20Hebrew%20Low.mp4'
                        },
                        "texts" : {
                            "instructions" : "breathing exercise"
                        },
                        "monitor" : {
                            "monitor" : {
                                "type" : "BREATHING",
                                "inhalation" : {
                                    "on" : true,
                                    "value" : 4
                                },
                                "video" : {
                                    "on" : false,
                                    "url" : null,
                                    "id" : null
                                },
                                "mirror" : {
                                    "on" : false
                                },
                                "speedometer" : {
                                    "on" : false
                                }
                            },
                            "pattern" : {
                                "type" : "GENTLE_VOICE",
                                "productionDuration" : 5
                            },
                            "errors" : {
                                "on" : true,
                                "masteryLevel" : 40,
                                "production" : {
                                    "tooShort" : {
                                        "on" : true,
                                        "type" : "EASY"
                                    },
                                    "tooLong" : {
                                        "on" : true,
                                        "type" : "EASY"
                                    },
                                    "notLoud" : {
                                        "on" : false,
                                        "type" : "EASY"
                                    },
                                    "notGentle" : {
                                        "on" : false,
                                        "type" : "EASY"
                                    },
                                    "noPattern" : {
                                        "on" : false,
                                        "type" : "EASY"
                                    }
                                },
                                "specific" : {
                                    "beginning" : {
                                        "nonGentleOnset" : {
                                            "on" : false,
                                            "type" : "EASY"
                                        },
                                        "notGentle" : {
                                            "on" : false,
                                            "type" : "EASY"
                                        },
                                        "volumeControl" : {
                                            "on" : false,
                                            "type" : "EASY"
                                        }
                                    },
                                    "peak" : {
                                        "notLoud" : {
                                            "on" : false,
                                            "type" : "EASY"
                                        },
                                        "softPeak" : {
                                            "on" : false,
                                            "type" : "EASY"
                                        },
                                        "excessivePeak" : {
                                            "on" : false,
                                            "type" : "EASY"
                                        }
                                    },
                                    "end" : {
                                        "volumeControl" : {
                                            "on" : false,
                                            "type" : "EASY"
                                        },
                                        "nonGentleOffset" : {
                                            "on" : false,
                                            "type" : "EASY"
                                        }
                                    }
                                }
                            },
                            "progress" : {
                                "type" : "SEQUENCES",
                                "value" : 15,
                                "repetition" : {
                                    "on" : false,
                                    "count" : 0
                                }
                            },
                            "stimulus" : {
                                "type" : "CUSTOM",
                                "config" : {
                                    "customText" : "ssssss"
                                }
                            }
                        },
                    },
                    {
                        "locale" : "en",
                        "id" : "5a1310825e400000",
                        "name" : "Waves",
                        "version" : 0,
                        "type" : "EXERCISE",
                        "media" : {
                            "demo" : 'https://s3-eu-west-1.amazonaws.com/nvtk-video-media/03_01%20Hebrew%20Low.mp4'
                        },
                        "texts" : {
                            "instructions" : "waves exercise"
                        },
                        "monitor" : {
                            "monitor" : {
                                "type" : "LOUDNESS",
                                "inhalation" : {
                                    "on" : true,
                                    "value" : 2
                                },
                                "video" : {
                                    "on" : false,
                                    "url" : null,
                                    "id" : null
                                },
                                "mirror" : {
                                    "on" : false
                                },
                                "speedometer" : {
                                    "on" : false
                                }
                            },
                            "pattern" : {
                                "type" : "WAVES",
                                "productionDuration" : 5
                            },
                            "errors" : {
                                "on" : true,
                                "masteryLevel" : 40,
                                "production" : {
                                    "tooShort" : {
                                        "on" : true,
                                        "type" : "EASY"
                                    },
                                    "tooLong" : {
                                        "on" : true,
                                        "type" : "EASY"
                                    },
                                    "notLoud" : {
                                        "on" : false,
                                        "type" : "EASY"
                                    },
                                    "notGentle" : {
                                        "on" : false,
                                        "type" : "EASY"
                                    },
                                    "noPattern" : {
                                        "on" : false,
                                        "type" : "EASY"
                                    }
                                },
                                "specific" : {
                                    "beginning" : {
                                        "nonGentleOnset" : {
                                            "on" : false,
                                            "type" : "EASY"
                                        },
                                        "notGentle" : {
                                            "on" : false,
                                            "type" : "EASY"
                                        },
                                        "volumeControl" : {
                                            "on" : false,
                                            "type" : "EASY"
                                        }
                                    },
                                    "peak" : {
                                        "notLoud" : {
                                            "on" : false,
                                            "type" : "EASY"
                                        },
                                        "softPeak" : {
                                            "on" : false,
                                            "type" : "EASY"
                                        },
                                        "excessivePeak" : {
                                            "on" : false,
                                            "type" : "EASY"
                                        }
                                    },
                                    "end" : {
                                        "volumeControl" : {
                                            "on" : false,
                                            "type" : "EASY"
                                        },
                                        "nonGentleOffset" : {
                                            "on" : false,
                                            "type" : "EASY"
                                        }
                                    }
                                }
                            },
                            "progress" : {
                                "type" : "SEQUENCES",
                                "value" : 15,
                                "repetition" : {
                                    "on" : true,
                                    "count" : 2
                                }
                            },
                            "stimulus" : {
                                "type" : "CUSTOM",
                                "config" : {
                                    "customText" : "sSsSsSsSsSsSs"
                                }
                            }
                        },
                    },
                    {
                        "locale" : "en",
                        "id" : "5a131136edc00000",
                        "name" : "Gentle voice",
                        "version" : 0,
                        "type" : "EXERCISE",
                        "media" : {
                            "demo" : 'https://s3-eu-west-1.amazonaws.com/nvtk-video-media/03_01%20Hebrew%20Low.mp4'
                        },
                        "texts" : {
                            "instructions" : "gentle voice exercise"
                        },
                        "monitor" : {
                            "monitor" : {
                                "type" : "LOUDNESS",
                                "inhalation" : {
                                    "on" : true,
                                    "value" : 2
                                },
                                "video" : {
                                    "on" : false,
                                    "url" : null,
                                    "id" : null
                                },
                                "mirror" : {
                                    "on" : false
                                },
                                "speedometer" : {
                                    "on" : false
                                }
                            },
                            "pattern" : {
                                "type" : "GENTLE_VOICE",
                                "productionDuration" : 3
                            },
                            "errors" : {
                                "on" : true,
                                "masteryLevel" : 40,
                                "production" : {
                                    "tooShort" : {
                                        "on" : true,
                                        "type" : "EASY"
                                    },
                                    "tooLong" : {
                                        "on" : true,
                                        "type" : "EASY"
                                    },
                                    "notLoud" : {
                                        "on" : false,
                                        "type" : "EASY"
                                    },
                                    "notGentle" : {
                                        "on" : true,
                                        "type" : "EASY"
                                    },
                                    "noPattern" : {
                                        "on" : true,
                                        "type" : "EASY"
                                    }
                                },
                                "specific" : {
                                    "beginning" : {
                                        "nonGentleOnset" : {
                                            "on" : false,
                                            "type" : "EASY"
                                        },
                                        "notGentle" : {
                                            "on" : false,
                                            "type" : "EASY"
                                        },
                                        "volumeControl" : {
                                            "on" : false,
                                            "type" : "EASY"
                                        }
                                    },
                                    "peak" : {
                                        "notLoud" : {
                                            "on" : false,
                                            "type" : "EASY"
                                        },
                                        "softPeak" : {
                                            "on" : false,
                                            "type" : "EASY"
                                        },
                                        "excessivePeak" : {
                                            "on" : false,
                                            "type" : "EASY"
                                        }
                                    },
                                    "end" : {
                                        "volumeControl" : {
                                            "on" : false,
                                            "type" : "EASY"
                                        },
                                        "nonGentleOffset" : {
                                            "on" : false,
                                            "type" : "EASY"
                                        }
                                    }
                                }
                            },
                            "progress" : {
                                "type" : "SEQUENCES",
                                "value" : 10,
                                "repetition" : {
                                    "on" : true,
                                    "count" : 3
                                }
                            },
                            "stimulus" : {
                                "type" : "SYLLABLES",
                                "config" : {
                                    "syllableStructure" : {
                                        "v" : true,
                                        "cv" : true,
                                        "vc" : true,
                                        "cvc" : true
                                    },
                                    "consonantsFeatures" : {
                                        "PLOSIVE" : true,
                                        "CONTINUANT" : true,
                                        "CLUSTER_START" : true,
                                        "CLUSTER_END" : true
                                    }
                                }
                            }
                        },
                    },
                    {
                        "id" : "5a1311f4e3800000",
                        "name" : "Loud voice",
                        "version" : 1,
                        "type" : "EXERCISE",
                        "locale" : "en",
                        "media" : {
                            "demo" : 'https://s3-eu-west-1.amazonaws.com/nvtk-video-media/03_01%20Hebrew%20Low.mp4'
                        },
                        "texts" : {
                            "instructions" : "loud voice exercise"
                        },
                        "monitor" : {
                            "monitor" : {
                                "type" : "LOUDNESS",
                                "inhalation" : {
                                    "on" : true,
                                    "value" : 2
                                },
                                "video" : {
                                    "on" : false,
                                    "url" : null,
                                    "id" : null
                                },
                                "mirror" : {
                                    "on" : false
                                },
                                "speedometer" : {
                                    "on" : false
                                }
                            },
                            "pattern" : {
                                "type" : "LOUD_VOICE",
                                "productionDuration" : 2
                            },
                            "errors" : {
                                "on" : true,
                                "masteryLevel" : 40,
                                "production" : {
                                    "tooShort" : {
                                        "on" : true,
                                        "type" : "EASY"
                                    },
                                    "tooLong" : {
                                        "on" : true,
                                        "type" : "EASY"
                                    },
                                    "notLoud" : {
                                        "on" : true,
                                        "type" : "EASY"
                                    },
                                    "notGentle" : {
                                        "on" : false,
                                        "type" : "EASY"
                                    },
                                    "noPattern" : {
                                        "on" : true,
                                        "type" : "EASY"
                                    }
                                },
                                "specific" : {
                                    "beginning" : {
                                        "nonGentleOnset" : {
                                            "on" : false,
                                            "type" : "EASY"
                                        },
                                        "notGentle" : {
                                            "on" : false,
                                            "type" : "EASY"
                                        },
                                        "volumeControl" : {
                                            "on" : false,
                                            "type" : "EASY"
                                        }
                                    },
                                    "peak" : {
                                        "notLoud" : {
                                            "on" : false,
                                            "type" : "EASY"
                                        },
                                        "softPeak" : {
                                            "on" : false,
                                            "type" : "EASY"
                                        },
                                        "excessivePeak" : {
                                            "on" : false,
                                            "type" : "EASY"
                                        }
                                    },
                                    "end" : {
                                        "volumeControl" : {
                                            "on" : false,
                                            "type" : "EASY"
                                        },
                                        "nonGentleOffset" : {
                                            "on" : false,
                                            "type" : "EASY"
                                        }
                                    }
                                }
                            },
                            "progress" : {
                                "type" : "SEQUENCES",
                                "value" : 20,
                                "repetition" : {
                                    "on" : true,
                                    "count" : 4
                                }
                            },
                            "stimulus" : {
                                "type" : "SYLLABLES",
                                "config" : {
                                    "syllableStructure" : {
                                        "v" : true,
                                        "cv" : true,
                                        "vc" : true,
                                        "cvc" : true
                                    },
                                    "consonantsFeatures" : {
                                        "PLOSIVE" : true,
                                        "CONTINUANT" : true,
                                        "CLUSTER_START" : true,
                                        "CLUSTER_END" : true
                                    }
                                }
                            }
                        },
                    },
                    {
                        "id" : "5a1312a409400000",
                        "name" : "Voice transition",
                        "version" : 0,
                        "type" : "EXERCISE",
                        "locale" : "en",
                        "media" : {
                            "demo" : 'https://s3-eu-west-1.amazonaws.com/nvtk-video-media/03_01%20Hebrew%20Low.mp4'
                        },
                        "texts" : {
                            "instructions" : "voice transition exercise"
                        },
                        "monitor" : {
                            "monitor" : {
                                "type" : "LOUDNESS",
                                "inhalation" : {
                                    "on" : true,
                                    "value" : 3
                                },
                                "video" : {
                                    "on" : false,
                                    "url" : null,
                                    "id" : null
                                },
                                "mirror" : {
                                    "on" : false
                                },
                                "speedometer" : {
                                    "on" : false
                                }
                            },
                            "pattern" : {
                                "type" : "VOICE_TRANSITION",
                                "productionDuration" : 3
                            },
                            "errors" : {
                                "on" : true,
                                "masteryLevel" : 40,
                                "production" : {
                                    "tooShort" : {
                                        "on" : false,
                                        "type" : "EASY"
                                    },
                                    "tooLong" : {
                                        "on" : true,
                                        "type" : "EASY"
                                    },
                                    "notLoud" : {
                                        "on" : true,
                                        "type" : "EASY"
                                    },
                                    "notGentle" : {
                                        "on" : true,
                                        "type" : "EASY"
                                    },
                                    "noPattern" : {
                                        "on" : false,
                                        "type" : "EASY"
                                    }
                                },
                                "specific" : {
                                    "beginning" : {
                                        "nonGentleOnset" : {
                                            "on" : false,
                                            "type" : "EASY"
                                        },
                                        "notGentle" : {
                                            "on" : false,
                                            "type" : "EASY"
                                        },
                                        "volumeControl" : {
                                            "on" : false,
                                            "type" : "EASY"
                                        }
                                    },
                                    "peak" : {
                                        "notLoud" : {
                                            "on" : false,
                                            "type" : "EASY"
                                        },
                                        "softPeak" : {
                                            "on" : false,
                                            "type" : "EASY"
                                        },
                                        "excessivePeak" : {
                                            "on" : false,
                                            "type" : "EASY"
                                        }
                                    },
                                    "end" : {
                                        "volumeControl" : {
                                            "on" : false,
                                            "type" : "EASY"
                                        },
                                        "nonGentleOffset" : {
                                            "on" : false,
                                            "type" : "EASY"
                                        }
                                    }
                                }
                            },
                            "progress" : {
                                "type" : "SEQUENCES",
                                "value" : 10,
                                "repetition" : {
                                    "on" : true,
                                    "count" : 5
                                }
                            },
                            "stimulus" : {
                                "type" : "SYLLABLES",
                                "config" : {
                                    "syllableStructure" : {
                                        "v" : true,
                                        "cv" : true
                                    },
                                    "consonantsFeatures" : {
                                        "CONTINUANT" : true,
                                        "CLUSTER_START" : true
                                    }
                                }
                            }
                        },
                    },
                    {
                        "id" : "5a1312dff8800000",
                        "name" : "symmetrical transition",
                        "version" : 0,
                        "type" : "EXERCISE",
                        "locale" : "en",
                        "media" : {
                            "demo" : 'https://s3-eu-west-1.amazonaws.com/nvtk-video-media/03_01%20Hebrew%20Low.mp4'
                        },
                        "texts" : {
                            "instructions" : "symmetrical transition exercise"
                        },
                        "monitor" : {
                            "monitor" : {
                                "type" : "LOUDNESS",
                                "inhalation" : {
                                    "on" : true,
                                    "value" : 3
                                },
                                "video" : {
                                    "on" : false,
                                    "url" : null,
                                    "id" : null
                                },
                                "mirror" : {
                                    "on" : false
                                },
                                "speedometer" : {
                                    "on" : false
                                }
                            },
                            "pattern" : {
                                "type" : "SYMMETRICAL_TRANSITION",
                                "productionDuration" : 3
                            },
                            "errors" : {
                                "on" : true,
                                "masteryLevel" : 40,
                                "production" : {
                                    "tooShort" : {
                                        "on" : true,
                                        "type" : "EASY"
                                    },
                                    "tooLong" : {
                                        "on" : true,
                                        "type" : "EASY"
                                    },
                                    "notLoud" : {
                                        "on" : false,
                                        "type" : "EASY"
                                    },
                                    "notGentle" : {
                                        "on" : true,
                                        "type" : "EASY"
                                    },
                                    "noPattern" : {
                                        "on" : true,
                                        "type" : "EASY"
                                    }
                                },
                                "specific" : {
                                    "beginning" : {
                                        "nonGentleOnset" : {
                                            "on" : false,
                                            "type" : "EASY"
                                        },
                                        "notGentle" : {
                                            "on" : false,
                                            "type" : "EASY"
                                        },
                                        "volumeControl" : {
                                            "on" : false,
                                            "type" : "EASY"
                                        }
                                    },
                                    "peak" : {
                                        "notLoud" : {
                                            "on" : false,
                                            "type" : "EASY"
                                        },
                                        "softPeak" : {
                                            "on" : false,
                                            "type" : "EASY"
                                        },
                                        "excessivePeak" : {
                                            "on" : false,
                                            "type" : "EASY"
                                        }
                                    },
                                    "end" : {
                                        "volumeControl" : {
                                            "on" : false,
                                            "type" : "EASY"
                                        },
                                        "nonGentleOffset" : {
                                            "on" : false,
                                            "type" : "EASY"
                                        }
                                    }
                                }
                            },
                            "progress" : {
                                "type" : "SEQUENCES",
                                "value" : 10,
                                "repetition" : {
                                    "on" : true,
                                    "count" : 2
                                }
                            },
                            "stimulus" : {
                                "type" : "SYLLABLES",
                                "config" : {
                                    "syllableStructure" : {
                                        "cv" : true,
                                        "vc" : true
                                    },
                                    "consonantsFeatures" : {
                                        "PLOSIVE" : true,
                                        "CONTINUANT" : true
                                    }
                                }
                            }
                        },
                    },
                    {
                        "id" : "5a13139568c00000",
                        "name" : "2 seconds syllables",
                        "version" : 0,
                        "type" : "EXERCISE",
                        "locale" : "en",
                        "media" : {
                            "demo" : 'https://s3-eu-west-1.amazonaws.com/nvtk-video-media/03_01%20Hebrew%20Low.mp4'
                        },
                        "texts" : {
                            "instructions" : "2 seconds syllables exercise"
                        },
                        "monitor" : {
                            "monitor" : {
                                "type" : "LOUDNESS",
                                "inhalation" : {
                                    "on" : true,
                                    "value" : 1
                                },
                                "video" : {
                                    "on" : false,
                                    "url" : null,
                                    "id" : null
                                },
                                "mirror" : {
                                    "on" : false
                                },
                                "speedometer" : {
                                    "on" : false
                                }
                            },
                            "pattern" : {
                                "type" : "TWO_SECONDS",
                                "productionDuration" : 2
                            },
                            "errors" : {
                                "on" : true,
                                "masteryLevel" : 40,
                                "production" : {
                                    "tooShort" : {
                                        "on" : true,
                                        "type" : "EASY"
                                    },
                                    "tooLong" : {
                                        "on" : true,
                                        "type" : "EASY"
                                    },
                                    "notLoud" : {
                                        "on" : true,
                                        "type" : "EASY"
                                    },
                                    "notGentle" : {
                                        "on" : true,
                                        "type" : "EASY"
                                    },
                                    "noPattern" : {
                                        "on" : true,
                                        "type" : "EASY"
                                    }
                                },
                                "specific" : {
                                    "beginning" : {
                                        "nonGentleOnset" : {
                                            "on" : false,
                                            "type" : "EASY"
                                        },
                                        "notGentle" : {
                                            "on" : false,
                                            "type" : "EASY"
                                        },
                                        "volumeControl" : {
                                            "on" : false,
                                            "type" : "EASY"
                                        }
                                    },
                                    "peak" : {
                                        "notLoud" : {
                                            "on" : false,
                                            "type" : "EASY"
                                        },
                                        "softPeak" : {
                                            "on" : false,
                                            "type" : "EASY"
                                        },
                                        "excessivePeak" : {
                                            "on" : false,
                                            "type" : "EASY"
                                        }
                                    },
                                    "end" : {
                                        "volumeControl" : {
                                            "on" : false,
                                            "type" : "EASY"
                                        },
                                        "nonGentleOffset" : {
                                            "on" : false,
                                            "type" : "EASY"
                                        }
                                    }
                                }
                            },
                            "progress" : {
                                "type" : "SEQUENCES",
                                "value" : 15,
                                "repetition" : {
                                    "on" : true,
                                    "count" : 3
                                }
                            },
                            "stimulus" : {
                                "type" : "SYLLABLES",
                                "config" : {
                                    "syllableStructure" : {
                                        "cv" : true,
                                        "vc" : true,
                                        "cvc" : true
                                    },
                                    "consonantsFeatures" : {
                                        "CONTINUANT" : true,
                                        "CLUSTER_START" : true
                                    }
                                }
                            }
                        },
                    },
                    {
                        "id" : "5a1313e50f000000",
                        "name" : "2 seconds words",
                        "version" : 0,
                        "type" : "EXERCISE",
                        "locale" : "en",
                        "media" : {
                            "demo" : 'https://s3-eu-west-1.amazonaws.com/nvtk-video-media/03_01%20Hebrew%20Low.mp4'
                        },
                        "texts" : {
                            "instructions" : "2 seconds words exercise"
                        },
                        "monitor" : {
                            "monitor" : {
                                "type" : "LOUDNESS",
                                "inhalation" : {
                                    "on" : true,
                                    "value" : 2
                                },
                                "video" : {
                                    "on" : false,
                                    "url" : null,
                                    "id" : null
                                },
                                "mirror" : {
                                    "on" : false
                                },
                                "speedometer" : {
                                    "on" : false
                                }
                            },
                            "pattern" : {
                                "type" : "TWO_SECONDS",
                                "productionDuration" : 2
                            },
                            "errors" : {
                                "on" : true,
                                "masteryLevel" : 40,
                                "production" : {
                                    "tooShort" : {
                                        "on" : true,
                                        "type" : "EASY"
                                    },
                                    "tooLong" : {
                                        "on" : true,
                                        "type" : "EASY"
                                    },
                                    "notLoud" : {
                                        "on" : true,
                                        "type" : "EASY"
                                    },
                                    "notGentle" : {
                                        "on" : true,
                                        "type" : "EASY"
                                    },
                                    "noPattern" : {
                                        "on" : true,
                                        "type" : "EASY"
                                    }
                                },
                                "specific" : {
                                    "beginning" : {
                                        "nonGentleOnset" : {
                                            "on" : false,
                                            "type" : "EASY"
                                        },
                                        "notGentle" : {
                                            "on" : false,
                                            "type" : "EASY"
                                        },
                                        "volumeControl" : {
                                            "on" : false,
                                            "type" : "EASY"
                                        }
                                    },
                                    "peak" : {
                                        "notLoud" : {
                                            "on" : false,
                                            "type" : "EASY"
                                        },
                                        "softPeak" : {
                                            "on" : false,
                                            "type" : "EASY"
                                        },
                                        "excessivePeak" : {
                                            "on" : false,
                                            "type" : "EASY"
                                        }
                                    },
                                    "end" : {
                                        "volumeControl" : {
                                            "on" : false,
                                            "type" : "EASY"
                                        },
                                        "nonGentleOffset" : {
                                            "on" : false,
                                            "type" : "EASY"
                                        }
                                    }
                                }
                            },
                            "progress" : {
                                "type" : "SEQUENCES",
                                "value" : 7,
                                "repetition" : {
                                    "on" : false,
                                    "count" : 2
                                }
                            },
                            "stimulus" : {
                                "type" : "WORDS",
                                "config" : {
                                    "wordType" : {
                                        "REAL" : true
                                    },
                                    "division" : "DIVIDED",
                                    "syllableCount" : {
                                        "min" : 1,
                                        "max" : 6
                                    },
                                    "syllableStructure" : {
                                        "cvc" : true,
                                        "vc" : true,
                                        "cv" : true
                                    },
                                    "consonantsFeatures" : {
                                        "PLOSIVE" : true,
                                        "CONTINUANT" : true,
                                        "CLUSTER_START" : true
                                    }
                                }
                            }
                        },

                    },
                    {
                        "id" : "5a131585b1000000",
                        "name" : "2 seconds sentences",
                        "version" : 0,
                        "type" : "EXERCISE",
                        "locale" : "en",
                        "media" : {
                            "demo" : 'https://s3-eu-west-1.amazonaws.com/nvtk-video-media/03_01%20Hebrew%20Low.mp4'
                        },
                        "texts" : {
                            "instructions" : "2 seconds sentences exercise"
                        },
                        "monitor" : {
                            "monitor" : {
                                "type" : "LOUDNESS",
                                "inhalation" : {
                                    "on" : true,
                                    "value" : 2
                                },
                                "video" : {
                                    "on" : false,
                                    "url" : null,
                                    "id" : null
                                },
                                "mirror" : {
                                    "on" : false
                                },
                                "speedometer" : {
                                    "on" : false
                                }
                            },
                            "pattern" : {
                                "type" : "TWO_SECONDS",
                                "productionDuration" : 2
                            },
                            "errors" : {
                                "on" : true,
                                "masteryLevel" : 40,
                                "production" : {
                                    "tooShort" : {
                                        "on" : true,
                                        "type" : "EASY"
                                    },
                                    "tooLong" : {
                                        "on" : true,
                                        "type" : "EASY"
                                    },
                                    "notLoud" : {
                                        "on" : true,
                                        "type" : "EASY"
                                    },
                                    "notGentle" : {
                                        "on" : true,
                                        "type" : "EASY"
                                    },
                                    "noPattern" : {
                                        "on" : true,
                                        "type" : "EASY"
                                    }
                                },
                                "specific" : {
                                    "beginning" : {
                                        "nonGentleOnset" : {
                                            "on" : false,
                                            "type" : "EASY"
                                        },
                                        "notGentle" : {
                                            "on" : true,
                                            "type" : "EASY"
                                        },
                                        "volumeControl" : {
                                            "on" : false,
                                            "type" : "EASY"
                                        }
                                    },
                                    "peak" : {
                                        "notLoud" : {
                                            "on" : false,
                                            "type" : "EASY"
                                        },
                                        "softPeak" : {
                                            "on" : true,
                                            "type" : "EASY"
                                        },
                                        "excessivePeak" : {
                                            "on" : false,
                                            "type" : "EASY"
                                        }
                                    },
                                    "end" : {
                                        "volumeControl" : {
                                            "on" : false,
                                            "type" : "EASY"
                                        },
                                        "nonGentleOffset" : {
                                            "on" : true,
                                            "type" : "EASY"
                                        }
                                    }
                                }
                            },
                            "progress" : {
                                "type" : "SEQUENCES",
                                "value" : 15,
                                "repetition" : {
                                    "on" : false,
                                    "count" : 0
                                }
                            },
                            "stimulus" : {
                                "type" : "SENTENCES",
                                "config" : {
                                    "division" : "DIVIDED",
                                    "wordCount" : {
                                        "SHORT" : true,
                                        "MEDIUM" : true
                                    }
                                }
                            }
                        },
                    }
                ],
                activity: {
                    exercises: [
                        { 
                          id: '5a130ed9a0800000', 
                          achievedMasteryLevel: 60, 
                          fails: 0,
                          tips: [] 
                       },
                       { 
                        id: '5a1310825e400000', 
                        achievedMasteryLevel: 60, 
                        fails: 0,
                        tips: [] 
                     },
                     { 
                        id: '5a131136edc00000', 
                        achievedMasteryLevel: 60, 
                        fails: 0,
                        tips: [] 
                     },
                     { 
                        id: '5a1311f4e3800000', 
                        achievedMasteryLevel: 60, 
                        fails: 0,
                        tips: [] 
                     },
                    //  { 
                    //     id: '5a1312a409400000', 
                    //     achievedMasteryLevel: 60, 
                    //     fails: 0,
                    //     tips: [] 
                    //  },
                    //  { 
                    //     id: '5a1312dff8800000', 
                    //     achievedMasteryLevel: 60, 
                    //     fails: 0,
                    //     tips: [] 
                    //  },
                    //  { 
                    //     id: '5a13139568c00000', 
                    //     achievedMasteryLevel: 60, 
                    //     fails: 0,
                    //     tips: [] 
                    //  },
                    //  { 
                    //     id: '5a1313e50f000000', 
                    //     achievedMasteryLevel: 60, 
                    //     fails: 0,
                    //     tips: [] 
                    //  },
                    //  { 
                    //     id: '5a131585b1000000', 
                    //     achievedMasteryLevel: 30, 
                    //     fails: 0,
                    //     tips: [] 
                    //  },
                    ]
                }
            },
            {
                id: 'p5',
                duration: 10,
                name: 'voice production - beginner',
                type: courseTypes.PRACTICE,
                children: [
                    {
                        "locale" : "en",
                        "id" : "5a130ed9a0800000",
                        "name" : "Breathing",
                        "version" : 1,
                        "type" : "EXERCISE",
                        "media" : {
                            "demo" : 'https://s3-eu-west-1.amazonaws.com/nvtk-video-media/03_01%20Hebrew%20Low.mp4'
                        },
                        "texts" : {
                            "instructions" : "breathing exercise"
                        },
                        "monitor" : {
                            "monitor" : {
                                "type" : "BREATHING",
                                "inhalation" : {
                                    "on" : true,
                                    "value" : 4
                                },
                                "video" : {
                                    "on" : false,
                                    "url" : null,
                                    "id" : null
                                },
                                "mirror" : {
                                    "on" : false
                                },
                                "speedometer" : {
                                    "on" : false
                                }
                            },
                            "pattern" : {
                                "type" : "GENTLE_VOICE",
                                "productionDuration" : 5
                            },
                            "errors" : {
                                "on" : true,
                                "masteryLevel" : 40,
                                "production" : {
                                    "tooShort" : {
                                        "on" : true,
                                        "type" : "EASY"
                                    },
                                    "tooLong" : {
                                        "on" : true,
                                        "type" : "EASY"
                                    },
                                    "notLoud" : {
                                        "on" : false,
                                        "type" : "EASY"
                                    },
                                    "notGentle" : {
                                        "on" : false,
                                        "type" : "EASY"
                                    },
                                    "noPattern" : {
                                        "on" : false,
                                        "type" : "EASY"
                                    }
                                },
                                "specific" : {
                                    "beginning" : {
                                        "nonGentleOnset" : {
                                            "on" : false,
                                            "type" : "EASY"
                                        },
                                        "notGentle" : {
                                            "on" : false,
                                            "type" : "EASY"
                                        },
                                        "volumeControl" : {
                                            "on" : false,
                                            "type" : "EASY"
                                        }
                                    },
                                    "peak" : {
                                        "notLoud" : {
                                            "on" : false,
                                            "type" : "EASY"
                                        },
                                        "softPeak" : {
                                            "on" : false,
                                            "type" : "EASY"
                                        },
                                        "excessivePeak" : {
                                            "on" : false,
                                            "type" : "EASY"
                                        }
                                    },
                                    "end" : {
                                        "volumeControl" : {
                                            "on" : false,
                                            "type" : "EASY"
                                        },
                                        "nonGentleOffset" : {
                                            "on" : false,
                                            "type" : "EASY"
                                        }
                                    }
                                }
                            },
                            "progress" : {
                                "type" : "SEQUENCES",
                                "value" : 15,
                                "repetition" : {
                                    "on" : false,
                                    "count" : 0
                                }
                            },
                            "stimulus" : {
                                "type" : "CUSTOM",
                                "config" : {
                                    "customText" : "ssssss"
                                }
                            }
                        },
                    },
                ]

            },
            {
                id: 'p6',
                duration: 10,
                name: '2 seconds pattern - beginner',
                type: courseTypes.PRACTICE,
                children: [
                    {
                        "locale" : "en",
                        "id" : "5a130ed9a0800000",
                        "name" : "Breathing",
                        "version" : 1,
                        "type" : "EXERCISE",
                        "media" : {
                            "demo" : 'https://s3-eu-west-1.amazonaws.com/nvtk-video-media/03_01%20Hebrew%20Low.mp4'
                        },
                        "texts" : {
                            "instructions" : "breathing exercise"
                        },
                        "monitor" : {
                            "monitor" : {
                                "type" : "BREATHING",
                                "inhalation" : {
                                    "on" : true,
                                    "value" : 4
                                },
                                "video" : {
                                    "on" : false,
                                    "url" : null,
                                    "id" : null
                                },
                                "mirror" : {
                                    "on" : false
                                },
                                "speedometer" : {
                                    "on" : false
                                }
                            },
                            "pattern" : {
                                "type" : "GENTLE_VOICE",
                                "productionDuration" : 5
                            },
                            "errors" : {
                                "on" : true,
                                "masteryLevel" : 40,
                                "production" : {
                                    "tooShort" : {
                                        "on" : true,
                                        "type" : "EASY"
                                    },
                                    "tooLong" : {
                                        "on" : true,
                                        "type" : "EASY"
                                    },
                                    "notLoud" : {
                                        "on" : false,
                                        "type" : "EASY"
                                    },
                                    "notGentle" : {
                                        "on" : false,
                                        "type" : "EASY"
                                    },
                                    "noPattern" : {
                                        "on" : false,
                                        "type" : "EASY"
                                    }
                                },
                                "specific" : {
                                    "beginning" : {
                                        "nonGentleOnset" : {
                                            "on" : false,
                                            "type" : "EASY"
                                        },
                                        "notGentle" : {
                                            "on" : false,
                                            "type" : "EASY"
                                        },
                                        "volumeControl" : {
                                            "on" : false,
                                            "type" : "EASY"
                                        }
                                    },
                                    "peak" : {
                                        "notLoud" : {
                                            "on" : false,
                                            "type" : "EASY"
                                        },
                                        "softPeak" : {
                                            "on" : false,
                                            "type" : "EASY"
                                        },
                                        "excessivePeak" : {
                                            "on" : false,
                                            "type" : "EASY"
                                        }
                                    },
                                    "end" : {
                                        "volumeControl" : {
                                            "on" : false,
                                            "type" : "EASY"
                                        },
                                        "nonGentleOffset" : {
                                            "on" : false,
                                            "type" : "EASY"
                                        }
                                    }
                                }
                            },
                            "progress" : {
                                "type" : "SEQUENCES",
                                "value" : 15,
                                "repetition" : {
                                    "on" : false,
                                    "count" : 0
                                }
                            },
                            "stimulus" : {
                                "type" : "CUSTOM",
                                "config" : {
                                    "customText" : "ssssss"
                                }
                            }
                        },
                    },
                ]

            },
            {
                id: 'p7',
                duration:10,
                name: '1 second pattern - beginner',
                type: courseTypes.PRACTICE,
                children: [
                    {
                        "locale" : "en",
                        "id" : "5a130ed9a0800000",
                        "name" : "Breathing",
                        "version" : 1,
                        "type" : "EXERCISE",
                        "media" : {
                            "demo" : 'https://s3-eu-west-1.amazonaws.com/nvtk-video-media/03_01%20Hebrew%20Low.mp4'
                        },
                        "texts" : {
                            "instructions" : "breathing exercise"
                        },
                        "monitor" : {
                            "monitor" : {
                                "type" : "BREATHING",
                                "inhalation" : {
                                    "on" : true,
                                    "value" : 4
                                },
                                "video" : {
                                    "on" : false,
                                    "url" : null,
                                    "id" : null
                                },
                                "mirror" : {
                                    "on" : false
                                },
                                "speedometer" : {
                                    "on" : false
                                }
                            },
                            "pattern" : {
                                "type" : "GENTLE_VOICE",
                                "productionDuration" : 5
                            },
                            "errors" : {
                                "on" : true,
                                "masteryLevel" : 40,
                                "production" : {
                                    "tooShort" : {
                                        "on" : true,
                                        "type" : "EASY"
                                    },
                                    "tooLong" : {
                                        "on" : true,
                                        "type" : "EASY"
                                    },
                                    "notLoud" : {
                                        "on" : false,
                                        "type" : "EASY"
                                    },
                                    "notGentle" : {
                                        "on" : false,
                                        "type" : "EASY"
                                    },
                                    "noPattern" : {
                                        "on" : false,
                                        "type" : "EASY"
                                    }
                                },
                                "specific" : {
                                    "beginning" : {
                                        "nonGentleOnset" : {
                                            "on" : false,
                                            "type" : "EASY"
                                        },
                                        "notGentle" : {
                                            "on" : false,
                                            "type" : "EASY"
                                        },
                                        "volumeControl" : {
                                            "on" : false,
                                            "type" : "EASY"
                                        }
                                    },
                                    "peak" : {
                                        "notLoud" : {
                                            "on" : false,
                                            "type" : "EASY"
                                        },
                                        "softPeak" : {
                                            "on" : false,
                                            "type" : "EASY"
                                        },
                                        "excessivePeak" : {
                                            "on" : false,
                                            "type" : "EASY"
                                        }
                                    },
                                    "end" : {
                                        "volumeControl" : {
                                            "on" : false,
                                            "type" : "EASY"
                                        },
                                        "nonGentleOffset" : {
                                            "on" : false,
                                            "type" : "EASY"
                                        }
                                    }
                                }
                            },
                            "progress" : {
                                "type" : "SEQUENCES",
                                "value" : 15,
                                "repetition" : {
                                    "on" : false,
                                    "count" : 0
                                }
                            },
                            "stimulus" : {
                                "type" : "CUSTOM",
                                "config" : {
                                    "customText" : "ssssss"
                                }
                            }
                        },
                    },
                ]

            },
            {
                id: 'p8',
                duration:10,
                name: '1 sec with intonation - beginner',
                type: courseTypes.PRACTICE,
                children: [
                    {
                        "locale" : "en",
                        "id" : "5a130ed9a0800000",
                        "name" : "Breathing",
                        "version" : 1,
                        "type" : "EXERCISE",
                        "media" : {
                            "demo" : 'https://s3-eu-west-1.amazonaws.com/nvtk-video-media/03_01%20Hebrew%20Low.mp4'
                        },
                        "texts" : {
                            "instructions" : "breathing exercise"
                        },
                        "monitor" : {
                            "monitor" : {
                                "type" : "BREATHING",
                                "inhalation" : {
                                    "on" : true,
                                    "value" : 4
                                },
                                "video" : {
                                    "on" : false,
                                    "url" : null,
                                    "id" : null
                                },
                                "mirror" : {
                                    "on" : false
                                },
                                "speedometer" : {
                                    "on" : false
                                }
                            },
                            "pattern" : {
                                "type" : "GENTLE_VOICE",
                                "productionDuration" : 5
                            },
                            "errors" : {
                                "on" : true,
                                "masteryLevel" : 40,
                                "production" : {
                                    "tooShort" : {
                                        "on" : true,
                                        "type" : "EASY"
                                    },
                                    "tooLong" : {
                                        "on" : true,
                                        "type" : "EASY"
                                    },
                                    "notLoud" : {
                                        "on" : false,
                                        "type" : "EASY"
                                    },
                                    "notGentle" : {
                                        "on" : false,
                                        "type" : "EASY"
                                    },
                                    "noPattern" : {
                                        "on" : false,
                                        "type" : "EASY"
                                    }
                                },
                                "specific" : {
                                    "beginning" : {
                                        "nonGentleOnset" : {
                                            "on" : false,
                                            "type" : "EASY"
                                        },
                                        "notGentle" : {
                                            "on" : false,
                                            "type" : "EASY"
                                        },
                                        "volumeControl" : {
                                            "on" : false,
                                            "type" : "EASY"
                                        }
                                    },
                                    "peak" : {
                                        "notLoud" : {
                                            "on" : false,
                                            "type" : "EASY"
                                        },
                                        "softPeak" : {
                                            "on" : false,
                                            "type" : "EASY"
                                        },
                                        "excessivePeak" : {
                                            "on" : false,
                                            "type" : "EASY"
                                        }
                                    },
                                    "end" : {
                                        "volumeControl" : {
                                            "on" : false,
                                            "type" : "EASY"
                                        },
                                        "nonGentleOffset" : {
                                            "on" : false,
                                            "type" : "EASY"
                                        }
                                    }
                                }
                            },
                            "progress" : {
                                "type" : "SEQUENCES",
                                "value" : 15,
                                "repetition" : {
                                    "on" : false,
                                    "count" : 0
                                }
                            },
                            "stimulus" : {
                                "type" : "CUSTOM",
                                "config" : {
                                    "customText" : "ssssss"
                                }
                            }
                        },
                    },
                ]

            },
            {
                id: 'p9',
                duration:10,
                name: 'self managed speech rate - beginner',
                type: courseTypes.PRACTICE,
                children: [
                    {
                        "locale" : "he",
                        "id" : "5a130ed9a0800000",
                        "name" :  'basics',
                        "version" : 1,
                        "type" : "EXERCISE",
                        "media" : {
                            "demo" : 'https://s3-eu-west-1.amazonaws.com/nvtk-video-media/03_01%20Hebrew%20Low.mp4'
                        },
                        "texts" : {
                            "instructions" : "breathing exercise"
                        },
                        "monitor" : {
                            "monitor" : {
                                "type" : "BREATHING",
                                "inhalation" : {
                                    "on" : true,
                                    "value" : 4
                                },
                                "video" : {
                                    "on" : false,
                                    "url" : null,
                                    "id" : null
                                },
                                "mirror" : {
                                    "on" : false
                                },
                                "speedometer" : {
                                    "on" : false
                                }
                            },
                            "pattern" : {
                                "type" : "GENTLE_VOICE",
                                "productionDuration" : 5
                            },
                            "errors" : {
                                "on" : true,
                                "masteryLevel" : 40,
                                "production" : {
                                    "tooShort" : {
                                        "on" : true,
                                        "type" : "EASY"
                                    },
                                    "tooLong" : {
                                        "on" : true,
                                        "type" : "EASY"
                                    },
                                    "notLoud" : {
                                        "on" : false,
                                        "type" : "EASY"
                                    },
                                    "notGentle" : {
                                        "on" : false,
                                        "type" : "EASY"
                                    },
                                    "noPattern" : {
                                        "on" : false,
                                        "type" : "EASY"
                                    }
                                },
                                "specific" : {
                                    "beginning" : {
                                        "nonGentleOnset" : {
                                            "on" : false,
                                            "type" : "EASY"
                                        },
                                        "notGentle" : {
                                            "on" : false,
                                            "type" : "EASY"
                                        },
                                        "volumeControl" : {
                                            "on" : false,
                                            "type" : "EASY"
                                        }
                                    },
                                    "peak" : {
                                        "notLoud" : {
                                            "on" : false,
                                            "type" : "EASY"
                                        },
                                        "softPeak" : {
                                            "on" : false,
                                            "type" : "EASY"
                                        },
                                        "excessivePeak" : {
                                            "on" : false,
                                            "type" : "EASY"
                                        }
                                    },
                                    "end" : {
                                        "volumeControl" : {
                                            "on" : false,
                                            "type" : "EASY"
                                        },
                                        "nonGentleOffset" : {
                                            "on" : false,
                                            "type" : "EASY"
                                        }
                                    }
                                }
                            },
                            "progress" : {
                                "type" : "SEQUENCES",
                                "value" : 15,
                                "repetition" : {
                                    "on" : false,
                                    "count" : 0
                                }
                            },
                            "stimulus" : {
                                "type" : "CUSTOM",
                                "config" : {
                                    "customText" : "ssssss"
                                }
                            }
                        },
                    }
                ]

            },
            {
                id: 'p10',
                duration:10,
                name: 'pace changes - beginner',
                type: courseTypes.PRACTICE,
                children: [
                    {
                        "locale" : "en",
                        "id" : "5a130ed9a0800000",
                        "name" : "Breathing",
                        "version" : 1,
                        "type" : "EXERCISE",
                        "media" : {
                            "demo" : 'https://s3-eu-west-1.amazonaws.com/nvtk-video-media/03_01%20Hebrew%20Low.mp4'
                        },
                        "texts" : {
                            "instructions" : "breathing exercise"
                        },
                        "monitor" : {
                            "monitor" : {
                                "type" : "BREATHING",
                                "inhalation" : {
                                    "on" : true,
                                    "value" : 4
                                },
                                "video" : {
                                    "on" : false,
                                    "url" : null,
                                    "id" : null
                                },
                                "mirror" : {
                                    "on" : false
                                },
                                "speedometer" : {
                                    "on" : false
                                }
                            },
                            "pattern" : {
                                "type" : "GENTLE_VOICE",
                                "productionDuration" : 5
                            },
                            "errors" : {
                                "on" : true,
                                "masteryLevel" : 40,
                                "production" : {
                                    "tooShort" : {
                                        "on" : true,
                                        "type" : "EASY"
                                    },
                                    "tooLong" : {
                                        "on" : true,
                                        "type" : "EASY"
                                    },
                                    "notLoud" : {
                                        "on" : false,
                                        "type" : "EASY"
                                    },
                                    "notGentle" : {
                                        "on" : false,
                                        "type" : "EASY"
                                    },
                                    "noPattern" : {
                                        "on" : false,
                                        "type" : "EASY"
                                    }
                                },
                                "specific" : {
                                    "beginning" : {
                                        "nonGentleOnset" : {
                                            "on" : false,
                                            "type" : "EASY"
                                        },
                                        "notGentle" : {
                                            "on" : false,
                                            "type" : "EASY"
                                        },
                                        "volumeControl" : {
                                            "on" : false,
                                            "type" : "EASY"
                                        }
                                    },
                                    "peak" : {
                                        "notLoud" : {
                                            "on" : false,
                                            "type" : "EASY"
                                        },
                                        "softPeak" : {
                                            "on" : false,
                                            "type" : "EASY"
                                        },
                                        "excessivePeak" : {
                                            "on" : false,
                                            "type" : "EASY"
                                        }
                                    },
                                    "end" : {
                                        "volumeControl" : {
                                            "on" : false,
                                            "type" : "EASY"
                                        },
                                        "nonGentleOffset" : {
                                            "on" : false,
                                            "type" : "EASY"
                                        }
                                    }
                                }
                            },
                            "progress" : {
                                "type" : "SEQUENCES",
                                "value" : 15,
                                "repetition" : {
                                    "on" : false,
                                    "count" : 0
                                }
                            },
                            "stimulus" : {
                                "type" : "CUSTOM",
                                "config" : {
                                    "customText" : "ssssss"
                                }
                            }
                        },
                    },
                ]

            },
            {
                id: 'p11',
                duration:10,
                name: 'integrated speech - beginner',
                type: courseTypes.PRACTICE,
                children: [
                    {
                        "locale" : "en",
                        "id" : "5a130ed9a0800000",
                        "name" : "Breathing",
                        "version" : 1,
                        "type" : "EXERCISE",
                        "media" : {
                            "demo" : 'https://s3-eu-west-1.amazonaws.com/nvtk-video-media/03_01%20Hebrew%20Low.mp4'
                        },
                        "texts" : {
                            "instructions" : "breathing exercise"
                        },
                        "monitor" : {
                            "monitor" : {
                                "type" : "BREATHING",
                                "inhalation" : {
                                    "on" : true,
                                    "value" : 4
                                },
                                "video" : {
                                    "on" : false,
                                    "url" : null,
                                    "id" : null
                                },
                                "mirror" : {
                                    "on" : false
                                },
                                "speedometer" : {
                                    "on" : false
                                }
                            },
                            "pattern" : {
                                "type" : "GENTLE_VOICE",
                                "productionDuration" : 5
                            },
                            "errors" : {
                                "on" : true,
                                "masteryLevel" : 40,
                                "production" : {
                                    "tooShort" : {
                                        "on" : true,
                                        "type" : "EASY"
                                    },
                                    "tooLong" : {
                                        "on" : true,
                                        "type" : "EASY"
                                    },
                                    "notLoud" : {
                                        "on" : false,
                                        "type" : "EASY"
                                    },
                                    "notGentle" : {
                                        "on" : false,
                                        "type" : "EASY"
                                    },
                                    "noPattern" : {
                                        "on" : false,
                                        "type" : "EASY"
                                    }
                                },
                                "specific" : {
                                    "beginning" : {
                                        "nonGentleOnset" : {
                                            "on" : false,
                                            "type" : "EASY"
                                        },
                                        "notGentle" : {
                                            "on" : false,
                                            "type" : "EASY"
                                        },
                                        "volumeControl" : {
                                            "on" : false,
                                            "type" : "EASY"
                                        }
                                    },
                                    "peak" : {
                                        "notLoud" : {
                                            "on" : false,
                                            "type" : "EASY"
                                        },
                                        "softPeak" : {
                                            "on" : false,
                                            "type" : "EASY"
                                        },
                                        "excessivePeak" : {
                                            "on" : false,
                                            "type" : "EASY"
                                        }
                                    },
                                    "end" : {
                                        "volumeControl" : {
                                            "on" : false,
                                            "type" : "EASY"
                                        },
                                        "nonGentleOffset" : {
                                            "on" : false,
                                            "type" : "EASY"
                                        }
                                    }
                                }
                            },
                            "progress" : {
                                "type" : "SEQUENCES",
                                "value" : 15,
                                "repetition" : {
                                    "on" : false,
                                    "count" : 0
                                }
                            },
                            "stimulus" : {
                                "type" : "CUSTOM",
                                "config" : {
                                    "customText" : "ssssss"
                                }
                            }
                        },
                    },
                ]

            },
            {
                id: 'p1h',
                duration: 10,
                name: 'מונפרר סוברט',
                type: courseTypes.PRACTICE,
                children: [
                    {
                        "locale" : "he",
                        "id" : "5a130ed9a0800000",
                        "name" :  'נמרגי שהכים תוק',
                        "version" : 1,
                        "type" : "EXERCISE",
                        "media" : {
                            "demo" : 'https://s3-eu-west-1.amazonaws.com/nvtk-video-media/03_01%20Hebrew%20Low.mp4'
                        },
                        "texts" : {
                            "instructions" : "breathing exercise"
                        },
                        "monitor" : {
                            "monitor" : {
                                "type" : "BREATHING",
                                "inhalation" : {
                                    "on" : true,
                                    "value" : 4
                                },
                                "video" : {
                                    "on" : false,
                                    "url" : null,
                                    "id" : null
                                },
                                "mirror" : {
                                    "on" : false
                                },
                                "speedometer" : {
                                    "on" : false
                                }
                            },
                            "pattern" : {
                                "type" : "GENTLE_VOICE",
                                "productionDuration" : 5
                            },
                            "errors" : {
                                "on" : true,
                                "masteryLevel" : 40,
                                "production" : {
                                    "tooShort" : {
                                        "on" : true,
                                        "type" : "EASY"
                                    },
                                    "tooLong" : {
                                        "on" : true,
                                        "type" : "EASY"
                                    },
                                    "notLoud" : {
                                        "on" : false,
                                        "type" : "EASY"
                                    },
                                    "notGentle" : {
                                        "on" : false,
                                        "type" : "EASY"
                                    },
                                    "noPattern" : {
                                        "on" : false,
                                        "type" : "EASY"
                                    }
                                },
                                "specific" : {
                                    "beginning" : {
                                        "nonGentleOnset" : {
                                            "on" : false,
                                            "type" : "EASY"
                                        },
                                        "notGentle" : {
                                            "on" : false,
                                            "type" : "EASY"
                                        },
                                        "volumeControl" : {
                                            "on" : false,
                                            "type" : "EASY"
                                        }
                                    },
                                    "peak" : {
                                        "notLoud" : {
                                            "on" : false,
                                            "type" : "EASY"
                                        },
                                        "softPeak" : {
                                            "on" : false,
                                            "type" : "EASY"
                                        },
                                        "excessivePeak" : {
                                            "on" : false,
                                            "type" : "EASY"
                                        }
                                    },
                                    "end" : {
                                        "volumeControl" : {
                                            "on" : false,
                                            "type" : "EASY"
                                        },
                                        "nonGentleOffset" : {
                                            "on" : false,
                                            "type" : "EASY"
                                        }
                                    }
                                }
                            },
                            "progress" : {
                                "type" : "SEQUENCES",
                                "value" : 15,
                                "repetition" : {
                                    "on" : false,
                                    "count" : 0
                                }
                            },
                            "stimulus" : {
                                "type" : "CUSTOM",
                                "config" : {
                                    "customText" : "ssssss"
                                }
                            }
                        },
                    }                      
                ]
            },
            {
                id: 'p2h',
                duration: 15,
                name: 'אדנדום סילקוף',
                type: courseTypes.PRACTICE,
                children: [
                    {
                        "locale" : "he",
                        "id" : "5a130ed9a0800000",
                        "name" :  'נמרגי שהכים תוק',
                        "version" : 1,
                        "type" : "EXERCISE",
                        "media" : {
                            "demo" : 'https://s3-eu-west-1.amazonaws.com/nvtk-video-media/03_01%20Hebrew%20Low.mp4'
                        },
                        "texts" : {
                            "instructions" : "breathing exercise"
                        },
                        "monitor" : {
                            "monitor" : {
                                "type" : "BREATHING",
                                "inhalation" : {
                                    "on" : true,
                                    "value" : 4
                                },
                                "video" : {
                                    "on" : false,
                                    "url" : null,
                                    "id" : null
                                },
                                "mirror" : {
                                    "on" : false
                                },
                                "speedometer" : {
                                    "on" : false
                                }
                            },
                            "pattern" : {
                                "type" : "GENTLE_VOICE",
                                "productionDuration" : 5
                            },
                            "errors" : {
                                "on" : true,
                                "masteryLevel" : 40,
                                "production" : {
                                    "tooShort" : {
                                        "on" : true,
                                        "type" : "EASY"
                                    },
                                    "tooLong" : {
                                        "on" : true,
                                        "type" : "EASY"
                                    },
                                    "notLoud" : {
                                        "on" : false,
                                        "type" : "EASY"
                                    },
                                    "notGentle" : {
                                        "on" : false,
                                        "type" : "EASY"
                                    },
                                    "noPattern" : {
                                        "on" : false,
                                        "type" : "EASY"
                                    }
                                },
                                "specific" : {
                                    "beginning" : {
                                        "nonGentleOnset" : {
                                            "on" : false,
                                            "type" : "EASY"
                                        },
                                        "notGentle" : {
                                            "on" : false,
                                            "type" : "EASY"
                                        },
                                        "volumeControl" : {
                                            "on" : false,
                                            "type" : "EASY"
                                        }
                                    },
                                    "peak" : {
                                        "notLoud" : {
                                            "on" : false,
                                            "type" : "EASY"
                                        },
                                        "softPeak" : {
                                            "on" : false,
                                            "type" : "EASY"
                                        },
                                        "excessivePeak" : {
                                            "on" : false,
                                            "type" : "EASY"
                                        }
                                    },
                                    "end" : {
                                        "volumeControl" : {
                                            "on" : false,
                                            "type" : "EASY"
                                        },
                                        "nonGentleOffset" : {
                                            "on" : false,
                                            "type" : "EASY"
                                        }
                                    }
                                }
                            },
                            "progress" : {
                                "type" : "SEQUENCES",
                                "value" : 15,
                                "repetition" : {
                                    "on" : false,
                                    "count" : 0
                                }
                            },
                            "stimulus" : {
                                "type" : "CUSTOM",
                                "config" : {
                                    "customText" : "ssssss"
                                }
                            }
                        },
                    }                     
                ]
            },
            {
                id: 'p3h',
                duration: 20,
                name: 'בלאסטיק מונופץ',
                type: courseTypes.PRACTICE,
                children: [
                    {
                        "locale" : "he",
                        "id" : "5a130ed9a0800000",
                        "name" :  'נמרגי שהכים תוק',
                        "version" : 1,
                        "type" : "EXERCISE",
                        "media" : {
                            "demo" : 'https://s3-eu-west-1.amazonaws.com/nvtk-video-media/03_01%20Hebrew%20Low.mp4'
                        },
                        "texts" : {
                            "instructions" : "breathing exercise"
                        },
                        "monitor" : {
                            "monitor" : {
                                "type" : "BREATHING",
                                "inhalation" : {
                                    "on" : true,
                                    "value" : 4
                                },
                                "video" : {
                                    "on" : false,
                                    "url" : null,
                                    "id" : null
                                },
                                "mirror" : {
                                    "on" : false
                                },
                                "speedometer" : {
                                    "on" : false
                                }
                            },
                            "pattern" : {
                                "type" : "GENTLE_VOICE",
                                "productionDuration" : 5
                            },
                            "errors" : {
                                "on" : true,
                                "masteryLevel" : 40,
                                "production" : {
                                    "tooShort" : {
                                        "on" : true,
                                        "type" : "EASY"
                                    },
                                    "tooLong" : {
                                        "on" : true,
                                        "type" : "EASY"
                                    },
                                    "notLoud" : {
                                        "on" : false,
                                        "type" : "EASY"
                                    },
                                    "notGentle" : {
                                        "on" : false,
                                        "type" : "EASY"
                                    },
                                    "noPattern" : {
                                        "on" : false,
                                        "type" : "EASY"
                                    }
                                },
                                "specific" : {
                                    "beginning" : {
                                        "nonGentleOnset" : {
                                            "on" : false,
                                            "type" : "EASY"
                                        },
                                        "notGentle" : {
                                            "on" : false,
                                            "type" : "EASY"
                                        },
                                        "volumeControl" : {
                                            "on" : false,
                                            "type" : "EASY"
                                        }
                                    },
                                    "peak" : {
                                        "notLoud" : {
                                            "on" : false,
                                            "type" : "EASY"
                                        },
                                        "softPeak" : {
                                            "on" : false,
                                            "type" : "EASY"
                                        },
                                        "excessivePeak" : {
                                            "on" : false,
                                            "type" : "EASY"
                                        }
                                    },
                                    "end" : {
                                        "volumeControl" : {
                                            "on" : false,
                                            "type" : "EASY"
                                        },
                                        "nonGentleOffset" : {
                                            "on" : false,
                                            "type" : "EASY"
                                        }
                                    }
                                }
                            },
                            "progress" : {
                                "type" : "SEQUENCES",
                                "value" : 15,
                                "repetition" : {
                                    "on" : false,
                                    "count" : 0
                                }
                            },
                            "stimulus" : {
                                "type" : "CUSTOM",
                                "config" : {
                                    "customText" : "ssssss"
                                }
                            }
                        },
                    }                      
                ]
            },
      
            {
                id: 'p4h',
                duration: 10,
                name: 'להאמית קרהשק',
                type: courseTypes.PRACTICE,
                children: [
                    {
                        "locale" : "he",
                        "id" : "5a130ed9a0800000",
                        "name" :  'נמרגי שהכים תוק',
                        "version" : 1,
                        "type" : "EXERCISE",
                        "media" : {
                            "demo" : 'https://s3-eu-west-1.amazonaws.com/nvtk-video-media/03_01%20Hebrew%20Low.mp4'
                        },
                        "texts" : {
                            "instructions" : "breathing exercise"
                        },
                        "monitor" : {
                            "monitor" : {
                                "type" : "BREATHING",
                                "inhalation" : {
                                    "on" : true,
                                    "value" : 4
                                },
                                "video" : {
                                    "on" : false,
                                    "url" : null,
                                    "id" : null
                                },
                                "mirror" : {
                                    "on" : false
                                },
                                "speedometer" : {
                                    "on" : false
                                }
                            },
                            "pattern" : {
                                "type" : "GENTLE_VOICE",
                                "productionDuration" : 5
                            },
                            "errors" : {
                                "on" : true,
                                "masteryLevel" : 40,
                                "production" : {
                                    "tooShort" : {
                                        "on" : true,
                                        "type" : "EASY"
                                    },
                                    "tooLong" : {
                                        "on" : true,
                                        "type" : "EASY"
                                    },
                                    "notLoud" : {
                                        "on" : false,
                                        "type" : "EASY"
                                    },
                                    "notGentle" : {
                                        "on" : false,
                                        "type" : "EASY"
                                    },
                                    "noPattern" : {
                                        "on" : false,
                                        "type" : "EASY"
                                    }
                                },
                                "specific" : {
                                    "beginning" : {
                                        "nonGentleOnset" : {
                                            "on" : false,
                                            "type" : "EASY"
                                        },
                                        "notGentle" : {
                                            "on" : false,
                                            "type" : "EASY"
                                        },
                                        "volumeControl" : {
                                            "on" : false,
                                            "type" : "EASY"
                                        }
                                    },
                                    "peak" : {
                                        "notLoud" : {
                                            "on" : false,
                                            "type" : "EASY"
                                        },
                                        "softPeak" : {
                                            "on" : false,
                                            "type" : "EASY"
                                        },
                                        "excessivePeak" : {
                                            "on" : false,
                                            "type" : "EASY"
                                        }
                                    },
                                    "end" : {
                                        "volumeControl" : {
                                            "on" : false,
                                            "type" : "EASY"
                                        },
                                        "nonGentleOffset" : {
                                            "on" : false,
                                            "type" : "EASY"
                                        }
                                    }
                                }
                            },
                            "progress" : {
                                "type" : "SEQUENCES",
                                "value" : 15,
                                "repetition" : {
                                    "on" : false,
                                    "count" : 0
                                }
                            },
                            "stimulus" : {
                                "type" : "CUSTOM",
                                "config" : {
                                    "customText" : "ssssss"
                                }
                            }
                        },
                    }
                ]
            },
            {
                id: 'p5h',
                duration: 10,
                name: 'מרגשי ומרגשח',
                type: courseTypes.PRACTICE,
                children: [
                    {
                        "locale" : "he",
                        "id" : "5a130ed9a0800000",
                        "name" :  'נמרגי שהכים תוק',
                        "version" : 1,
                        "type" : "EXERCISE",
                        "media" : {
                            "demo" : 'https://s3-eu-west-1.amazonaws.com/nvtk-video-media/03_01%20Hebrew%20Low.mp4'
                        },
                        "texts" : {
                            "instructions" : "breathing exercise"
                        },
                        "monitor" : {
                            "monitor" : {
                                "type" : "BREATHING",
                                "inhalation" : {
                                    "on" : true,
                                    "value" : 4
                                },
                                "video" : {
                                    "on" : false,
                                    "url" : null,
                                    "id" : null
                                },
                                "mirror" : {
                                    "on" : false
                                },
                                "speedometer" : {
                                    "on" : false
                                }
                            },
                            "pattern" : {
                                "type" : "GENTLE_VOICE",
                                "productionDuration" : 5
                            },
                            "errors" : {
                                "on" : true,
                                "masteryLevel" : 40,
                                "production" : {
                                    "tooShort" : {
                                        "on" : true,
                                        "type" : "EASY"
                                    },
                                    "tooLong" : {
                                        "on" : true,
                                        "type" : "EASY"
                                    },
                                    "notLoud" : {
                                        "on" : false,
                                        "type" : "EASY"
                                    },
                                    "notGentle" : {
                                        "on" : false,
                                        "type" : "EASY"
                                    },
                                    "noPattern" : {
                                        "on" : false,
                                        "type" : "EASY"
                                    }
                                },
                                "specific" : {
                                    "beginning" : {
                                        "nonGentleOnset" : {
                                            "on" : false,
                                            "type" : "EASY"
                                        },
                                        "notGentle" : {
                                            "on" : false,
                                            "type" : "EASY"
                                        },
                                        "volumeControl" : {
                                            "on" : false,
                                            "type" : "EASY"
                                        }
                                    },
                                    "peak" : {
                                        "notLoud" : {
                                            "on" : false,
                                            "type" : "EASY"
                                        },
                                        "softPeak" : {
                                            "on" : false,
                                            "type" : "EASY"
                                        },
                                        "excessivePeak" : {
                                            "on" : false,
                                            "type" : "EASY"
                                        }
                                    },
                                    "end" : {
                                        "volumeControl" : {
                                            "on" : false,
                                            "type" : "EASY"
                                        },
                                        "nonGentleOffset" : {
                                            "on" : false,
                                            "type" : "EASY"
                                        }
                                    }
                                }
                            },
                            "progress" : {
                                "type" : "SEQUENCES",
                                "value" : 15,
                                "repetition" : {
                                    "on" : false,
                                    "count" : 0
                                }
                            },
                            "stimulus" : {
                                "type" : "CUSTOM",
                                "config" : {
                                    "customText" : "ssssss"
                                }
                            }
                        },
                    }
                ]
            },
        ]