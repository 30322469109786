import {
    ArrowRight,
    CheckMark,
    Lock
} from "@shared/Resources/shared/components/OverviewListItem/components/OverviewBaseListItem/styles";
import React from "react";
import { ILesson, IPractice, ISkill, ListEntitiesToCheck } from "../../types";
import { setEntitiesAsCompleted } from "@containers/Admin/containers/UsersList/actions";
import { Dispatch } from "redux";

interface ProgressIconProps {
    hasPassed: boolean
    isAccessible: boolean
}

export const ProgressIcon:React.FC<ProgressIconProps> = ({hasPassed, isAccessible}) => {
    if (!hasPassed && !isAccessible) {
        return <Lock/>
    } else if (hasPassed && isAccessible) {
        return <CheckMark/>
    } else {
        return <ArrowRight/>
    }
}

//TODO: After refactor redux -> fix
interface ISetEntitiesAsCompletedDispatch {
    type: string;
    entitiesLists:ListEntitiesToCheck,
    userId:string
  }

export const moveHereHelper =(Skills:ISkill[],id:string,cb:Dispatch<ISetEntitiesAsCompletedDispatch>,userId:string):void=>{
    const entitiesLists:ListEntitiesToCheck = {
        lessons:[],
        practices:[],
    }
    let BreakException={};
    try{
    Skills.forEach((skill:ISkill)=>{
        skill.Lessons.forEach((lesson:ILesson)=>{
            if(lesson.id===id){
                throw BreakException; 
            }
            entitiesLists.lessons.push(lesson.id)
        });
        skill.Practices.forEach((practice:IPractice)=>{
            if(practice.id===id){
                throw BreakException; 
            }
            entitiesLists.practices.push(practice.id)
        });
    })
}catch(e){
    if (e !== BreakException) throw e;
} 
   cb(setEntitiesAsCompleted(entitiesLists,userId));
}