import {styled} from "@mui/material";

export const Wrapper = styled('div')(({ theme, innerSection }) =>
  innerSection
    ? {
        marginLeft: 24,
        '& .noMediaContainer': {
          margin: 0,
          fontSize: 12,
        },
      }
    : {
        borderBottom: `1px solid ${theme.palette.novotalk.$resourcesTableBorderColor}`,
        paddingBottom: 5,
        marginBottom: 15,
      }
);
