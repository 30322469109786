import React, { useContext } from 'react';
import { VideoCallContext } from '@shared/VideoChat/components/VideoCall';

export const useVideoCallContext = () => {
  const { stateContext, dispatchContext } = useContext(VideoCallContext);
  if (!stateContext || !dispatchContext) {
    throw new Error('Usage context outside of Provider');
  } else {
    return { stateContext, dispatchContext };
  }
};
