import {styled} from "@mui/material";

export const Wrapper = styled('div')({
  padding: 20,
  textAlign: 'center',
});

export const ButtonContainer = styled('div')({
  marginTop: 20,
  display: 'flex',
  justifyContent: 'center',
});
