const map = {
  'Have a good evening,': 'Have a good evening,',
  'Let me know if you need any further explenation': 'Let me know if you need any further explenation',
  'Have a good day.': 'Have a good day.',
  'Have a great day!': 'Have a great day!',
  'Keep on the good work': 'Keep on the good work',
  'Well done! ': 'Well done! ',
  'Have a good week': 'Have a good week',
  'Have a nice weekend': 'Have a nice weekend',
  'Let me know if you have any questions': 'Let me know if you have any questions',
  'Greath job': 'Greath job',
  'Good luck!': 'Good luck!',
  'You are doing great, keep on practicing': 'You are doing great, keep on practicing',
};
export default map;
