import { t } from 'i18next';
import format from 'date-fns/format';
import { RESOURCES_DATE_FORMAT, getMinutesSecondsFromSeconds, getResourcesDate } from '@utils/timeHelpers';
import {
  getCellText,
  getStatusText,
  STATUS_TYPES,
} from '@containers/Admin/containers/PendingDashboard/shared/SharedComponents';
import { ACTIVITY_TYPES } from '@shared/Resources/types';
import { iconTypes } from '@services/icons';
import { novotalkBlue } from '@services/theme/themeColors';
import { getStyledIcon } from './styles';
/**
 * Generate table columns
 * @returns {React.ReactElement} React element
 */

export const getTableColumn = () => [
  {
    getDisplay: ({ type }) => {
      const Icon = getStyledIcon(type);
      return <Icon />;
    },
    width: '5%',
  },
  {
    title: 'Skill',
    getDisplay: ({ skillName }) => getCellText(skillName),
    width: '25%',
    sortable: true,
    sortField: 'skill?.name',
    textOptions: {
      withEllipsis: true,
    },
  },
  {
    title: 'Start time',
    getDisplay: ({ startDate }) => getCellText(format(new Date(startDate), RESOURCES_DATE_FORMAT)),
    width: '23%',
    sortable: true,
    sortField: 'startTime',
  },
  {
    title: 'Duration',
    getDisplay: ({ duration }) => getCellText(getMinutesSecondsFromSeconds(duration)),
    width: '19%',
    sortable: true,
    sortField: 'duration',
  },
  {
    title: 'Status',
    getDisplay: ({ entityStatus, type }) => entityStatus && type !== ACTIVITY_TYPES.ACTIVITY_CHAT && getStatusText(
      t(`Common.Statuses.${entityStatus === 'DONE' ? 'done' : 'incomplete'}`),
      entityStatus === 'DONE' ? STATUS_TYPES.DONE : STATUS_TYPES.FAILED,
    ),
    width: '13%',
    sortable: true,
    sortField: 'status',
  },
  {
    title: 'Feedback',
    getDisplay: ({ feedback, type }) => {
      if (type === ACTIVITY_TYPES.ACTIVITY_PRACTICE && feedback) {
        switch (feedback) {
          case STATUS_TYPES.NO:
            return null;
          case STATUS_TYPES.YES:
            return getStatusText(STATUS_TYPES.SENT, null, true);
          case STATUS_TYPES.SENT:
            return getStatusText(STATUS_TYPES.DISMISSED, null, true);
          default:
            return null;
        }
      }
    },
    width: '15%',
    sortable: true,
    sortField: 'feedback',
  },
  {
    getDisplay: ({}, current) => {
      const Icon = getStyledIcon(iconTypes.FILLED_ARROW_RIGHT);
      return current ? <Icon style={{ fontSize: 25, color: novotalkBlue }} /> : null;
    },
    width: '5%',
  },
];
