import { styled } from '@mui/material';
import { getIconsByType, iconTypes } from '@services/icons';

export const TableWrapperUserLog = styled('div')(({ theme }) => ({
  width: '100%',
  color: theme.palette.novotalk.$textColorDarkBlue,
}));

export const NoItemsUserLog = styled('div')({
  height: 100,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
});

export const LoadingOverlayUserLog = styled('div')({
  position: 'absolute',
  left: 0,
  backgroundColor: '#FFF',
  width: '100%',
  height: '90%',
  zIndex: 101,
  display: 'flex',
  alignContent: 'center',
  justifyContent: 'center',
  alignItems: 'center',
});

export const TableListUserLog = styled('div')({
  overflow: 'scroll', height: '68vh',
});

type StyledTableHeaderProps ={
  spread:string
}

export const TableHeaderUserLog = styled('div', {
  shouldForwardProp: (prop) => prop !== 'spread',
})<StyledTableHeaderProps>(({ spread, theme }) => ({
  display: 'grid',
  alignItems: 'center',
  padding: '0 16px',
  height: 43,
  gridTemplateColumns: spread,
  borderBottom: `solid 1px ${theme.palette.novotalk.$resourcesTableBorderColor}`,
  backgroundColor: theme.palette.novotalk.$backgroundColor,
  color: theme.palette.novotalk.$textColorDisabled,
}));

type StyledTableCellProps = {
  noPadding?:boolean, titlesUppercase?:boolean, isLast?:boolean, current?:boolean
}

export const CellUserLog = styled('div', {
  shouldForwardProp: (prop) => prop !== 'noPadding' && prop !== 'titlesUppercase' && prop !== 'isLast' && prop !== 'current',
})<StyledTableCellProps>(({
  noPadding, titlesUppercase, isLast, current, theme,
}) => ({
  overflow: 'hidden',
  display: 'flex',
  cursor: !current ? 'pointer' : 'auto',
  color: current ? `${theme.palette.novotalk.$novotalkBlue}` : `${theme.palette.novotalk.$textColorDarkBlue}`,

}));

export const HeaderColumnUserLog = styled(CellUserLog)({
  display: 'flex',
  alignItems: 'baseline',
});

type TableRowUserLogProps={
  spread: string
  isHighlighted:boolean
}

export const TableRowUserLog = styled('div', {
  shouldForwardProp: (prop) => prop !== 'spread' && prop !== 'isHighlighted',
})<TableRowUserLogProps>(({
  spread, isHighlighted, theme,
}) => ({
  display: 'grid',
  alignItems: 'center',
  padding: '8px 16px',
  minHeight: 47,
  cursor: 'pointer',
  position: 'relative',
  gridTemplateColumns: spread,
  borderBottom: `solid 1px ${theme.palette.novotalk.$resourcesTableBorderColor}`,
  backgroundColor: isHighlighted
    ? theme.palette.novotalk.$backgroundColorMedium
    : theme.palette.novotalk.$backgroundColor,
  '&:hover': {
    backgroundColor: isHighlighted
      ? theme.palette.novotalk.$activityUnreadBackgroundColorHover
      : theme.palette.novotalk.$activityReadBackgroundColorHover,
  },
  '&:active': {
    backgroundColor: isHighlighted
      ? theme.palette.novotalk.$activityUnreadBackgroundColorActive
      : theme.palette.novotalk.$activityReadBackgroundColorActive,
  },

}));
