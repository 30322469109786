import { fromJS } from 'immutable';
import _ from 'lodash';
import getTime from 'date-fns/get_time';
import differenceInHours from 'date-fns/difference_in_hours';
import { toast } from 'react-toastify';
import { TOAST_AUTO_CLOSE_TIME } from '@shared/constants';
import { TYPES } from './actions';
import { getFeedbacks } from '../../../../helpers/feedbackHelpers';

// The initial state of the Practice
export const initialState = fromJS({
  practice: {},
  currentExercise: {},
  exerciseInProgress: false,
  loading: true,
  feedbacks: [],
  currentPracticeActivity: {},
  currentExerciseActivity: {},
  tryingToExit: false,
  error: '',
});

const HOURS_TO_KEEP_PRACTICE_ALIVE = 10;

const getFeedbacksAndCurrentActivity = (practice) => {
  const activities = practice.Activities;
  if (activities.length === 0) {
    return {
      feedbacks: [],
      currentPracticeActivity: {},
    };
  }
  const feedbacks = getFeedbacks(activities);
  const latestActivityPractice = _.maxBy(activities, (activity) => getTime(+activity.activityCreated.at));
  const exercises = latestActivityPractice.Exercises;
  let hoursSinceLastActivity;
  if (exercises.length > 0) {
    const latestActivityExercise = _.maxBy(exercises, (activity) => getTime(+activity.activityCreated.at));
    hoursSinceLastActivity = differenceInHours(
      new Date(),
      latestActivityExercise.activityCreated.at,
    );
  } else {
    hoursSinceLastActivity = differenceInHours(
      new Date(),
      latestActivityPractice.activityCreated.at,
    );
  }
  const currentPracticeActivity = hoursSinceLastActivity > HOURS_TO_KEEP_PRACTICE_ALIVE
    ? {}
    : latestActivityPractice;
  return {
    feedbacks,
    currentPracticeActivity,
  };
};

function practiceReducer(state = initialState, action) {
  switch (action.type) {
    case TYPES.SET_USER_FEEDBACKS: {
      const { feedbacks } = action;
      return state.set('feedbacks', feedbacks);
    }
    case TYPES.GET_CURRENT_PRACTICE:
      return state.set('loading', true);
    case TYPES.SET_CURRENT_PRACTICE: {
      const { Exercises: exercises, ...rest } = action.practice;
      const formatPractice = { exercises, ...rest };
      const {
        feedbacks,
        currentPracticeActivity,
      } = getFeedbacksAndCurrentActivity(formatPractice);

      return state
        .set('practice', formatPractice)
        .set('feedbacks', feedbacks)
        .set('currentPracticeActivity', currentPracticeActivity)
        .set('loading', false);
    }
    case TYPES.SET_ACTIVITY_PRACTICE:
      return state.set('currentPracticeActivity', action.activity);
    case TYPES.SET_ACTIVITY_EXERCISE:
      return state.set('currentExerciseActivity', action.activity);
    case TYPES.SET_CURRENT_EXERCISE:
      return state.set('currentExercise', action.exercise);
    case TYPES.RESET_CURRENT_EXERCISE:
      return state.set('currentExercise', {});
    case TYPES.START_EXERCISE:
      return state.set('exerciseInProgress', true);
    case TYPES.END_EXERCISE:
      return state.set('exerciseInProgress', false);
    case TYPES.RESET_ACTIVITY_EXERCISE:
      return state
        .set('currentExerciseActivity', {})
        .set('exerciseInProgress', false);
    case TYPES.RESET_PRACTICE:
      return state
        .set('practice', {});
    case TYPES.TRYING_EXIT_EXERCISE:
      return state.set('tryingToExit', action.tryingToExit);
    case TYPES.SET_PRACTICE_ERROR:
      return state.set('error', action.error);
    case TYPES.PRACTICE_ERROR: {
      state.set('errorData', true);
      toast.error('You didn\'t completed previous exercise', {
        autoClose: TOAST_AUTO_CLOSE_TIME,
        position: 'bottom-right',
      });
    }
    default:
      return state;
  }
}

export default practiceReducer;
