/**
 *
 * OverviewListItem
 *
 */

import { PureComponent } from 'react';
import PropTypes from 'prop-types';
import Text, { TEXT_SIZE, TEXT_COLOR } from '@components/Text';
import { RESOURCES } from '@shared/Resources/types';
import { withRouter } from "react-router";
import { connect } from 'react-redux';
import { compose } from 'redux';
import {
  ListItem,
  getStyledIconByType,
  NameContainer,
  NameText,
  InfoWrapper,
  CountWrapper,
  StyledOverviewButton,
  Lock,
  CheckMark,
  ArrowRight,
  IconWrapper
} from './styles';
import { detectCurrentCourseLevel } from '../../helpers';
import {withTranslation} from "react-i18next";

const SHOW_MOVE_BUTTON = false;

class OverviewBaseListItem extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      isHovering: false,
    };
  }

  getTypeCount = (type) => {
    const {
      resource: { payload },
    } = this.props;
    const TypeIcon = getStyledIconByType(type, true);
    return (
      <CountWrapper>
        <TypeIcon />
        <Text size={TEXT_SIZE.H4} textColor={TEXT_COLOR.DARK_BLUE}>
          {payload.contains[type] || 0}
        </Text>
      </CountWrapper>
    );
  };

  renderButtons = (isCurrentLevel) => {
    const {
      resource: { type, payload, id },
      onView,
      onEdit,
      onMoveToCourseLevel,
      readOnly,
      isInnerList,
      isUserPageProgram,
      courseId,
      match: { params },
      isFirstElement,
      t
    } = this.props;
    const { isHovering } = this.state;

    if (readOnly) {
      return null;
    } else if (isUserPageProgram) {
      if (!isFirstElement && !isCurrentLevel && SHOW_MOVE_BUTTON) {
        return (
          <StyledOverviewButton
            onClick={() => onMoveToCourseLevel({ userId: params.id, courseId, levelId: id })}
            className='moveButton'
            hideButton={!isHovering}
          >
            {t('Actions.Navigation.moveHere')}
          </StyledOverviewButton>
        )
      }
    } else {
      return (
        <div>
          <StyledOverviewButton
            onClick={() => onView({ type, payload, id })}
            isInnerList={isInnerList}
            className='viewItemButton'
          >
            {t('Actions.Instance.view').toUpperCase()}
          </StyledOverviewButton>
          <StyledOverviewButton
            full
            onClick={() => onEdit({ type, payload, id })}
            isInnerList={isInnerList}
            className='editItemButton'
          >
            {t('Actions.Instance.edit').toUpperCase()}
          </StyledOverviewButton>
        </div>
      );
    }
  };

  renderChildrenInfo = () => {
    const {
      resource: { type },
      isUserPageProgram,
    } = this.props;
    if (type !== RESOURCES.SKILL) return null;
    return (
      <>
        {!isUserPageProgram &&
          <InfoWrapper>
            {this.getTypeCount(RESOURCES.LESSON)}
            {this.getTypeCount(RESOURCES.PRACTICE)}
            {this.getTypeCount(RESOURCES.POLL)}
          </InfoWrapper>
        }
      </>
    );
  };

  renderProgressIcon = (hasPassed, isAccessible) => {
    if (!hasPassed && !isAccessible) {
      return <Lock />
    } else if (hasPassed && isAccessible) {
      return <CheckMark />
    } else {
      return <ArrowRight />
    }
  }

  handleMouseOver = () => {
    this.setState({ isHovering: true });
  };

  handleMouseOut = () => {
    this.setState({ isHovering: false });
  };

  render() {
    const {
      resource: {
        type,
        payload,
        name,
        hasPassed,
        isAccessible,
        Lessons,
        Practices,
      },
      isExpandable,
      onExpand,
      isUserPageProgram,
      isFirstElement,
      hasCurrentLevel,
    } = this.props;

    const TypeIcon = getStyledIconByType(type);
    const checkCurrentLevelInLessons = detectCurrentCourseLevel(Lessons);
    const checkCurrentLevelInPractices = detectCurrentCourseLevel(Practices);
    const isCurrentLevel = checkCurrentLevelInLessons || checkCurrentLevelInPractices;

    return (
      <ListItem
        isUserPageProgram={isUserPageProgram}
        isFirstElement={isFirstElement}
        onMouseOver={this.handleMouseOver}
        onMouseOut={this.handleMouseOut}
      >
        {!isFirstElement
          && isUserPageProgram
          && <IconWrapper>{this.renderProgressIcon(hasPassed, isAccessible)}</IconWrapper>
        }
        <TypeIcon
          isExpandable={isExpandable}
          onClick={isExpandable ? onExpand : null}
          isCurrentLevel={hasCurrentLevel || isCurrentLevel}
        />
        <NameContainer>
          <NameText
            isExpandable={isExpandable}
            onClick={isExpandable ? onExpand : null}
            size={TEXT_SIZE.H4}
            textColor={TEXT_COLOR[`${(hasCurrentLevel || isCurrentLevel) ? 'INFO' : 'DARK_BLUE'}`]}
          >
            {payload?.name || name}
          </NameText>
        </NameContainer>
        {this.renderChildrenInfo()}
        {this.renderButtons(isCurrentLevel)}
      </ListItem>
    );
  }
}

OverviewBaseListItem.defaultProps = {
  flatList: false,
};

OverviewBaseListItem.propTypes = {
  readOnly: PropTypes.bool,
  resource: PropTypes.shape({
    type: PropTypes.string.isRequired,
    payload: PropTypes.object.isRequired,
    id: PropTypes.string.isRequired,
  }),
  onView: PropTypes.func,
  onEdit: PropTypes.func,
  isInnerList: PropTypes.bool,
  isExpandable: PropTypes.bool,
  onExpand: PropTypes.func,
};

const mapStateToProps = (state) => {
  const { personalProgram } = state.getIn(['userPage', 'userData']);
  if (personalProgram) {
    const courseId = personalProgram.courseId;
    return { courseId };
  }
};

const withConnect = connect(mapStateToProps, null);

export default compose(
  withConnect,
  withRouter,
  withTranslation()
)(OverviewBaseListItem);
