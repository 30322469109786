import React, {useEffect} from 'react';
import { Wrapper } from './styles';
import {Select} from "@components/Toggle/styles";
import {useTranslation} from "react-i18next";
import {en, he} from "@containers/LocaleToggle/consts";
import {useDispatch, useSelector} from "react-redux";
import {changeLocale} from "@containers/App/actions";

const LocaleToggle = () => {
    const { i18n } = useTranslation();
    const dispatch = useDispatch()
    const { locale } = useSelector((state) => state.getIn(['app', 'authentication', 'user']).toJS());

    const toggleLocale = () => {
        dispatch(changeLocale(locale.toLowerCase() === he ? en : he))
    }

    useEffect(() => {
        i18n.changeLanguage(locale.toLowerCase() === he ? he : en)
    }, [locale])

    return (
      <Wrapper>
          <Select onChange={toggleLocale}>
              <option selected={i18n.language !== he} value={en}>
                  English
              </option>
              <option selected={i18n.language === he} value={he}>
                  Hebrew
              </option>
          </Select>
      </Wrapper>
    );
}

export default LocaleToggle