import {styled} from "@mui/material";
import { EditButton } from '@shared/Resources/Skill/Builder/styles';
import TextArea from '@components/TextArea';

const avatarSize = 40;

export const FeedbackPostContainer = styled('div')(
  {
    padding: 18,
    borderRadius: 4,
    marginBottom: 10,
  },
  ({ theme }) => ({
    backgroundColor: theme.palette.novotalk.$backgroundColor,
  })
);

export const FeedbackPostHeader = styled('div')({
  display: 'grid',
  gridTemplateColumns: `${avatarSize + 10}px auto`,
  alignItems: 'center',
  marginBottom: 18,
});

export const PostAvatar = styled('img')({
  width: avatarSize,
  height: avatarSize,
  borderRadius: '50%',
  objectFit: 'cover',
});

export const PostDetails = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  '& span.postedOn': {
    whiteSpace: 'pre',
  },
});

export const PostText = styled('div')({
  lineHeight: '23px',
});

export const ActionButtonsWrapper = styled('div')({
  gridColumn: 3,
});

export const IconWrapper = styled(EditButton)`
  float: right;
`;

export const StyledTextArea = styled(TextArea)({
  height: 180,
});
