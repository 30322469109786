import {styled} from "@mui/material";
import { getIconsByType, iconTypes } from '@services/icons';

export const StyledDropdownIcon = styled(
  getIconsByType(iconTypes.FILLED_ARROW_DOWN)
)(
  {
    fontSize: 21,
  },
  ({ theme }) => ({
    color: theme.palette.novotalk.$textColorDisabled,
  })
);

export const Wrapper = styled('div')(
  {
    minWidth: '800px',
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    flexShrink: 0,
    position: 'relative',
  },
  ({ theme }) => ({
    margin: theme.RTL ? '10px 30px 30px 0' : '10px 0 30px 30px',
  })
);

export const selectStyles = (error, theme) => ({
  container: (provided) => ({
    ...provided,
    margin: '2px 0',
  }),
  indicatorsContainer: (provided) => ({
    ...provided,
    [theme.RTL ? 'marginLeft' : 'marginRight']: 7,
  }),
  indicatorSeparator: () => 'none',
  clearIndicator: () => ({
    display: 'none',
  }),
  control: (provided) => ({
    ...provided,
    maxWidth: '314px',
    minWidth: '314px',
    height: 44,
    [theme.RTL ? 'paddingRight' : 'paddingLeft']: 5,
    boxShadow: 'none',
    borderColor: error ? theme.palette.novotalk.$errorColor : theme.palette.novotalk.$inputBorderColor,
    '&:hover': {
      borderColor: error ? theme.palette.novotalk.$errorColor : theme.palette.novotalk.$inputBorderColorHover,
    },
  }),
  singleValue: (provided) => ({
    ...provided,
    fontSize: 14,
    color: theme.palette.novotalk.$textColorDarkBlue,
  }),
  menu: (provided) => ({
    ...provided,
    maxWidth: '312px',
    minWidth: '312px',
    margin: '0 1px',
  }),
  menuList: (provided) => ({
    ...provided,
    paddingTop: 0,
    paddingBottom: 0,
  }),
  option: (provided, state) => ({
    ...provided,
    height: 44,
    [theme.RTL ? 'paddingRight' : 'paddingLeft']: 15,
    display: 'flex',
    alignItems: 'center',
    fontSize: 14,
    color: theme.palette.novotalk.$textColorDarkBlue,
    backgroundColor: state.isSelected
      ? theme.palette.novotalk.$dropdownBackgroundColorSelected
      : theme.palette.novotalk.$backgroundColor,
    '&:hover': {
      backgroundColor: state.isSelected
        ? theme.palette.novotalk.$dropdownBackgroundColorSelected
        : theme.palette.novotalk.$dropdownBackgroundColorHover,
    },
  }),
});

export const AnswerWrapper = styled('div')({
  marginBottom: 12,
  display: 'flex',
  alignItems: 'center',
});

export const RadioOffIcon = styled(getIconsByType(iconTypes.RADIO_OFF))(
  {
    fontSize: 22,
  },
  ({ theme }) => ({
    color: theme.palette.novotalk.$selectStandartColor,
    [theme.RTL ? 'marginLeft' : 'marginRight']: 5,
  })
);
