import {styled} from "@mui/material";
import Text from "@components/Text";

export const Container = styled('div')({
    width: '100%',
    display: 'flex',
  },
  ({ theme }) => ({
    height: `calc(100vh - ${theme.palette.novotalk.navigationBarHeight}px)`,
  })
);

export const Main = styled('main')({
    width: '100%',
    paddingTop: 28,
    paddingLeft: 30,
    overflowY: "scroll",
    minHeight: '100%',
});

export const TitleUserPage = styled(Text)({
    marginBottom: 96,
    display: 'block',
    fontSize: 26,
    fontWeight: 700,
  },
  ({ theme }) => ({
    color: theme.$textColorDarkBlue,
  })
);

export const LoadingOverlay = styled('div')({
  position: 'absolute',
  left: 0,
  backgroundColor: '#FFF',
  width: '100%',
  height: '90%',
  zIndex: 101,
  display: 'flex',
  alignContent: 'center',
  justifyContent: 'center',
  alignItems: 'center',
});
