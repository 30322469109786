import {styled} from "@mui/material";
import SwipeableViews from 'react-swipeable-views';

export const Wrapper = styled('div')(
  {
    width: 900,
    minHeight: 500,
    padding: 30,
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    borderRadius: 4,
  },
  ({ theme }) => ({
    backgroundColor: theme.palette.novotalk.$backgroundColor,
  })
);

export const Header = styled('div')({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  width: '100%',
  marginBottom: 20,
});

export const ButtonContent = styled('div')({
  width: '100%',
  display: 'flex',
  justifyContent: 'space-around',
  padding: '27px 0 0',
});

export const Slider = styled(SwipeableViews)({
  maxWidth: 870,
})
