import {
  useMemo, useState, useEffect, useCallback,
} from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { fetchUserActivityLog, fetchUserActivityDetail, fetchUserActivityDetailByExternalId } from '@containers/Admin/containers/UserPage/actions';
import { TitleWithFilter } from '../../../title-with-filter';
import { getTableColumn } from '../../helpers';
import { TitleWithTable, TableWrapper } from './styles';
import { TableUserLog } from './TableUserLog/TableUserLog';

export const ActivityTable = () => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const { activityLog, loading } = useSelector((state) => state.get('userPage').toJS());

  const [activitiesList, setActivitiesList] = useState([]);

  const handleSetActivitiesList = (activities) => setActivitiesList(activities);

  /**
   * Select value and filter activities
   * @params {Activity} activity - picked activity for overview
   */
  const config = useMemo(() => getTableColumn(), []);
  const onRowSelect = useCallback((item) => {
    if (item?.activityId && item.activityId !== 'undefined') {
      dispatch(fetchUserActivityDetail(item.id, item.activityId, item.type));
    } else if (item?.externalId) {
      dispatch(fetchUserActivityDetailByExternalId(item.id, item.externalId, item.type));
    }
  });

  useEffect(() => {
    setActivitiesList(activityLog);
  }, [activityLog]);

  useEffect(() => {
    dispatch(fetchUserActivityLog(id));
  }, [dispatch, id]);

  return (
    <TitleWithTable>
      <TitleWithFilter
        handleSetActivitiesList={handleSetActivitiesList}
        activityLog={activityLog}
      />
      <TableWrapper>
        <TableUserLog config={config} onRowSelect={onRowSelect} userLogs={activitiesList} loading={loading} />
      </TableWrapper>
    </TitleWithTable>
  );
};
