import { RESOURCES } from '../../types';

export const overview = {
  id:1,
  name:'A Beutiful video',
  description: 'Demonstrates the 2 second pattern Pellentesque orci lectus, consectetur vel posuere posuere, rutrum eu ipsum. ',
  value: 'someURL',
  entityCreated: {
    at: 'Thu Aug 02 2018 18:05:31 GMT+0000 (UTC)',
    by: 'User McUser',
  },
  entityUpdated: {
    at: 'Thu Aug 09 2018 08:46:00 GMT+0000 (UTC)',
    by: 'Useriko McUserico',
  },
  usedIn: [
    { id: 1, type: RESOURCES.COURSE, name: 'Some course 1' },
    { id: 2, type: RESOURCES.POLL, name: 'Some poll 2' },
  ]
};