/**
 *
 * App.js
 *
 * This component is the skeleton around the actual pages, and should only
 * contain code that should be seen on all pages. (e.g. navigation bar)
 *
 * NOTE: while this component should technically be a stateless functional
 * component (SFC), hot reloading does not currently support SFCs. If hot
 * reloading is not a necessity for you then you can refactor it and remove
 * the linting exception.
 */

import React, { Component } from 'react';
import { Switch, BrowserRouter as Router } from 'react-router-dom';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import _ from 'lodash';
import { ThemeProvider, StyledEngineProvider } from '@mui/material/styles';
import PropTypes from 'prop-types';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import { ApolloProvider } from 'react-apollo';
import { withTranslation } from 'react-i18next';
import injectReducer from '@utils/injectReducer';
import injectSaga from '@utils/injectSaga';
import { ROUTES } from '@shared/constants';
import { graphClient } from '@services/commService';
import VideoChat from '@shared/VideoChat';
import PopUp from '@containers/App/containers/PopUp';
import { STRIPE_TOKEN } from '@services/servers';
import SectionLoading from '@components/SectionLoading';
import { UsersList } from '@containers/Admin/containers/UsersList';
import { USER_PAGE_TYPES } from '@containers/Admin/containers/UserPage/consts';
import { UserPageWrapper } from '@containers/Admin/containers/UserPage/user-page-wrapper';
import { theme as muiTheme } from '@services/theme';
import GlobalStyles from './globalStyles'; // import global styles
import HomePage from '../HomePage/Loadable';
import { Wrapper, MainPane } from './styles';
import ProtectedRoute from './components/ProtectedRoute';
import AuthenticationRoute from './components/AuthenticationRoute';
import UnprotectedRoute from './components/UnprotectedRoute';
import NotFoundRoute from './components/NotFoundRoute';
// Admin Containers
import PendingDashboard from '../Admin/containers/PendingDashboard';
import SupervisorDashboard from '../Admin/containers/SupervisorDashboard';
import ActivityPage from '../Admin/containers/ActivityPage';
import MediaPage from '../Admin/containers/MediaPage';
import Builder from '../Admin/containers/Builder';
import RoleManagement from '../Admin/containers/RoleManagement';
import ResourceManager from '../Admin/containers/ResourceManager';

// App Containers
import SignIn from './containers/Authentication/SignIn';
import SignUp from './containers/Authentication/SignUp';
import ForgotPassword from './containers/Authentication/ForgotPassword';
import ResetPassword from './containers/Authentication/ResetPassword';
import ResetEmailSent from './containers/Authentication/ResetEmailSent';
import { VerifyEmailSent } from './containers/Authentication/VerifyEmailSent';
import ConfirmEmail from './containers/Authentication/ConfirmEmail';
import ValidatingView from './components/ValidatingView';
import CourseRouter from '../User/containers/CourseRouter';
import Billing from '../User/containers/Billing';
import Messaging from './containers/Messaging';
import Settings from './containers/Settings';

// Global components
import NavigationBar from './containers/NavigationBar';
import SocketManager from './components/SocketManager';
import Redirector from './components/Redirector';
import PollingService from './components/PollingService';
import PrivacyPolicy from './components/PrivacyPolicy';
import TermsOfUse from './components/TermsOfUse';

import saga from './saga';
import reducer from './reducer';
import {
  validateBridgeToken,
  setTheme,
  getUserRoles,
  getServerProperties,
} from './actions';
import {
  getFromLocalStorage,
  LOCAL_STORAGE_ITEM,
} from '../../utils/localStorageHelper';

const stripePromise = loadStripe(STRIPE_TOKEN);

export class App extends Component {
  constructor(props) {
    super(props);
    props.setTheme('novotalk');
    this.state = {
      userRolesLocal: null,
    };
  }

  componentDidMount() {
    this.userRolesHandler();
    this.checkForURLToken();
    this.props.getServerProperties();
  }

  componentDidUpdate() {
    this.userRolesHandler();
  }

  userRolesHandler() {
    if (!this.state.userRolesLocal) {
      const rolesEnum = getFromLocalStorage(LOCAL_STORAGE_ITEM.USER_ROLES);

      if (rolesEnum) {
        this.setState({ userRolesLocal: rolesEnum });
      } else {
        this.props.getUserRoles();
      }
    }
  }

  checkForURLToken() {
    if (Location.search) {
      // remove unwanted chars from search
      const searchString = Location.search.replace('?', '');
      const [key, token] = searchString.split('=');

      if (token && key === 'token') {
        this.props.validateBridgeToken(token);
      }
    }
  }

  render() {
    let theme = this.props.theme === 'novotalk' ? muiTheme : {};
    const { user } = this.props;
    theme = { ...theme, RTL: this.props.i18n.language === 'he' };

    const isAuthenticated = !_.isEmpty(user) && user.token;
    const { userRolesLocal } = this.state;
    return this.props.validating ? (
      <ValidatingView />
    ) : (
      <ApolloProvider client={graphClient}>
        <Router>
          <Elements stripe={stripePromise}>
            <StyledEngineProvider injectFirst>
              <ThemeProvider theme={theme}>
                <Wrapper>
                  <GlobalStyles theme={theme} />
                  <SocketManager />
                  <PopUp />
                  <Redirector />
                  <PollingService />
                  {isAuthenticated && <NavigationBar user={user} />}
                  <MainPane withNavBar={isAuthenticated}>
                    <Switch>
                      <AuthenticationRoute
                        exact
                        path={ROUTES.SIGN_IN}
                        component={SignIn}
                      />
                      <AuthenticationRoute
                        exact
                        path={ROUTES.SIGN_UP}
                        component={SignUp}
                      />
                      <UnprotectedRoute
                        exact
                        path={ROUTES.FORGOT_PASSWORD}
                        component={ForgotPassword}
                      />
                      <AuthenticationRoute
                        path={`${ROUTES.RESET_PASSWORD}/:token?/:roleIndicator?`}
                        component={ResetPassword}
                      />
                      <AuthenticationRoute
                        exact
                        path={ROUTES.RESET_EMAIL_SENT}
                        component={ResetEmailSent}
                      />
                      <AuthenticationRoute
                        exact
                        path={ROUTES.VERIFY_EMAIL_SENT}
                        component={VerifyEmailSent}
                      />
                      <UnprotectedRoute
                        path={ROUTES.CONFIRM_EMAIL}
                        component={ConfirmEmail}
                      />
                      <UnprotectedRoute
                        exact
                        path={ROUTES.TERMS_OF_USE}
                        component={TermsOfUse}
                      />
                      <UnprotectedRoute
                        exact
                        path={ROUTES.PRIVACY_POLICY}
                        component={PrivacyPolicy}
                      />

                      <ProtectedRoute
                        exact
                        path={ROUTES.HOME}
                        component={HomePage}
                        roles={[]}
                      />

                      {this.state.userRolesLocal ? (
                        <div>
                          <ProtectedRoute
                            exact
                            path={ROUTES.PENDING_DASHBOARD}
                            component={PendingDashboard}
                            roles={[
                              userRolesLocal.DEFAULT_MENTOR,
                              userRolesLocal.CLINICAL_MENTOR_HEB,
                              userRolesLocal.EMOTIONAL_MENTOR_HEB,
                              userRolesLocal.CLINICAL_MENTOR_EN,
                              userRolesLocal.EMOTIONAL_MENTOR_EN,
                              userRolesLocal.SUPERVISOR,
                              userRolesLocal.TECH_ADMIN,
                              userRolesLocal.MANAGEMENT_ADMIN,
                            ]}
                          />
                          <ProtectedRoute
                            exact
                            path={ROUTES.MEDIA_PAGE}
                            component={MediaPage}
                            roles={[
                              userRolesLocal.SUPERVISOR,
                              userRolesLocal.TECH_ADMIN,
                              userRolesLocal.MANAGEMENT_ADMIN,
                            ]}
                          />
                          <ProtectedRoute
                            exact
                            path={ROUTES.SUPERVISOR_DASHBOARD}
                            component={SupervisorDashboard}
                            roles={[userRolesLocal.SUPERVISOR]}
                          />
                          <ProtectedRoute
                            exact
                            path="/course/:courseIndex?/:skillIndex?/:skillPartIndex?/:exerciseIndex?"
                            component={CourseRouter}
                            roles={[]}
                          />
                          <ProtectedRoute
                            exact
                            path={`${ROUTES.ACTIVITY_PAGE}/:activityType/:activityId`}
                            component={ActivityPage}
                            roles={[]}
                          />
                          <ProtectedRoute
                            exact
                            path={`${ROUTES.VIDEO_CHAT}/:chatId?`}
                            component={VideoChat}
                            roles={[]}
                          />
                          <ProtectedRoute
                            exact
                            path={`${ROUTES.BUILDER}/:type/:id`}
                            component={Builder}
                            roles={[
                              userRolesLocal.SUPERVISOR,
                              userRolesLocal.TECH_ADMIN,
                              userRolesLocal.MANAGEMENT_ADMIN,
                            ]}
                          />
                          <ProtectedRoute
                            exact
                            path={ROUTES.ROLES_MANAGEMENT}
                            component={RoleManagement}
                            roles={[userRolesLocal.SUPERVISOR]}
                          />
                          <ProtectedRoute
                            exact
                            path={`${ROUTES.RESOURCES_MANAGER}/:type?/:id?`}
                            component={ResourceManager}
                            roles={[userRolesLocal.SUPERVISOR]}
                          />
                          <ProtectedRoute
                            exact
                            path={ROUTES.BILLING}
                            component={Billing}
                            roles={[]}
                          />
                          <ProtectedRoute
                            exact
                            path={ROUTES.MESSAGING}
                            component={Messaging}
                            roles={[]}
                          />
                          <ProtectedRoute
                            exact
                            path={ROUTES.SETTINGS}
                            component={Settings}
                            roles={[]}
                          />
                          <ProtectedRoute
                            exact
                            path={ROUTES.USERS.LIST}
                            component={UsersList}
                            roles={[
                              userRolesLocal.SUPERVISOR,
                              userRolesLocal.CLINICAL_MENTOR_EN,
                              userRolesLocal.CLINICAL_MENTOR_HEB,
                            ]}
                          />
                          <ProtectedRoute
                            exact
                            path={`${ROUTES.USERS.LIST}/:id/${USER_PAGE_TYPES.INFORMATION}`}
                            component={UserPageWrapper}
                            roles={[
                              userRolesLocal.SUPERVISOR,
                              userRolesLocal.CLINICAL_MENTOR_EN,
                              userRolesLocal.CLINICAL_MENTOR_HEB,
                            ]}
                          />
                          <ProtectedRoute
                            exact
                            path={`${ROUTES.USERS.LIST}/:id/${USER_PAGE_TYPES.PERSONAL_PROGRAM}`}
                            component={UserPageWrapper}
                            roles={[
                              userRolesLocal.SUPERVISOR,
                              userRolesLocal.CLINICAL_MENTOR_EN,
                              userRolesLocal.CLINICAL_MENTOR_HEB,
                            ]}
                          />
                          <ProtectedRoute
                            exact
                            path={`${ROUTES.USERS.LIST}/:id/${USER_PAGE_TYPES.LOG}`}
                            component={UserPageWrapper}
                            roles={[
                              userRolesLocal.SUPERVISOR,
                              userRolesLocal.CLINICAL_MENTOR_EN,
                              userRolesLocal.CLINICAL_MENTOR_HEB,
                            ]}
                          />
                        </div>
                      ) : (
                        <SectionLoading />
                      )}

                      <NotFoundRoute />
                    </Switch>
                  </MainPane>
                  <ToastContainer />
                </Wrapper>
              </ThemeProvider>
            </StyledEngineProvider>
          </Elements>
        </Router>
      </ApolloProvider>
    );
  }
}

App.propTypes = {
  validateBridgeToken: PropTypes.func,
  validating: PropTypes.bool,
  user: PropTypes.object,
  theme: PropTypes.string,
  setTheme: PropTypes.func,
  userRoles: PropTypes.object,
  getUserRoles: PropTypes.func,
};

const mapStateToProps = (state) => {
  const validating = state.getIn(['app', 'authentication', 'validating']);
  const user = state.getIn(['app', 'authentication', 'user']).toJS();
  const theme = state.getIn(['app', 'theme']);
  const userRoles = state.getIn(['app', ' userRoles']);
  return {
    validating, user, theme, userRoles,
  };
};

export function mapDispatchToProps(dispatch) {
  return {
    validateBridgeToken: (bridgeToken) => dispatch(validateBridgeToken(bridgeToken)),
    setTheme: (theme) => dispatch(setTheme(theme)),
    getUserRoles: () => dispatch(getUserRoles()),
    getServerProperties: () => dispatch(getServerProperties()),
  };
}

const withConnect = connect(mapStateToProps, mapDispatchToProps);
const withReducer = injectReducer({ key: 'app', reducer });
const withSaga = injectSaga({ key: 'app', saga });

export default compose(
  withReducer,
  withSaga,
  withConnect,
  withTranslation(),
)(App);
