import React  from 'react';
import { Container } from '../../styles';
import VideoCall from '../VideoCall';

export const SelfContainer = () => {
  return (
    <Container id='selfVideoContainer' column>
      <VideoCall.SelfVideo />
      <VideoCall.StopBtn />
    </Container>
  );
};
