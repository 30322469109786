import {styled} from "@mui/material";
import { getIconsByType, iconTypes } from '@services/icons';
import { getResponsiveCSS } from '@utils/cssHelpers';

export const ScreenWrapper = styled('div')(
  {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    textAlign: 'center',
  },
  getResponsiveCSS('marginTop', 100, 130)
);

export const StyledHeader = styled('div')(
  {
    display: 'flex',
    alignItems: 'center',
  },
  getResponsiveCSS('marginBottom', 20, 30)
);

const CheckmarkIcon = getIconsByType(iconTypes.CIRCLE_CHECKMARK_OVERFLOWED);

export const StyledCheckmarkIcon = styled(CheckmarkIcon)(
  {
    marginRight: 8,
    fontSize: 28,
  },
  ({ theme }) => ({
    color: theme.palette.novotalk.$completedColor,
  })
);

export const ContentWrapper = styled('div')(
  {
    width: 460,
    padding: '25px 30px 30px',
    borderRadius: 8,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  ({ theme }) => ({
    boxShadow: `0 4px 28px ${theme.palette.novotalk.$shadowTooltipColor}`,
  })
);

export const StyledImage = styled('img')({
  width: 140,
  marginBottom: 10,
});
