import {styled} from "@mui/material";
import { Link } from 'react-router-dom';
import { getResponsiveCSS } from '@utils/cssHelpers';

export const ForgotPasswordScreenContainer = styled('div')(
  {
    minHeight: '100vh',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  ({ theme }) => ({
    direction: theme.RTL ? 'rtl' : 'inherit',
  })
);

export const WithHeaderComponentContainer = styled('div')(
  {
    padding: '30px 0',
  },
  ({ theme }) => ({
    width: theme.palette.novotalk.authFormsWidth,
  })
);

export const ForgotPasswordComponentContainer = styled('div')(
  {
    paddingLeft: 66,
    paddingRight: 66,
    borderRadius: 4,
  },
  getResponsiveCSS('paddingTop', 30, 42),
  getResponsiveCSS('paddingBottom', 32, 50),
  ({ theme }) => ({
    border: `1px solid ${theme.palette.novotalk.$borderColorLight}`,
    backgroundColor: theme.palette.novotalk.$backgroundColorAuthScreens,
  })
);

export const HeaderContainer = styled('div')(
  {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  getResponsiveCSS('marginBottom', 12, 18)
);

export const StyledLogo = styled('img')(
  {
    marginBottom: 60,
  },
  getResponsiveCSS('width', 300, 340)
);

export const TextContainer = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  marginBottom: 46,
  textAlign: 'center',
});

export const InputContainer = styled('div')({
  height: 116,
  textAlign: 'auto',
});

export const ButtonContainer = styled('div')(
  getResponsiveCSS('height', 44, 48, '& #send_button'),
  getResponsiveCSS('marginBottom', 25, 20)
);

export const StyledLink = styled(Link)({
  textDecoration: 'none',
  lineHeight: '10px',
});
