import {styled} from "@mui/material";
import { getResponsiveCSS } from '@utils/cssHelpers';

export const HEADER_HEIGHT = 140;

export const BuilderContainer = styled('div')({
  maxWidth: 1920,
  margin: '0 auto',
  marginBottom: 100,
});

export const HeaderWrapper = styled('div')(
  {
    position: 'sticky',
    top: 0,
    zIndex: 100,
    width: '100%',
    height: HEADER_HEIGHT,
    marginBottom: 37,
  },
  getResponsiveCSS('paddingLeft', 50, 280),
  getResponsiveCSS('paddingRight', 50, 280),
  ({ theme }) => ({
    backgroundColor: theme.palette.novotalk.$backgroundColor,
    borderBottom: `${theme.palette.novotalk.$borderColorLight} 1px solid`,
  })
);

export const DetailsContainer = styled('div')({
  position: 'relative',
});

export const ContentWrapper = styled('div')(
  getResponsiveCSS('marginLeft', 50, 280),
  getResponsiveCSS('marginRight', 50, 280),
  ({ hasWidgets }) => hasWidgets && getResponsiveCSS('paddingRight', 200, 228)
);

export const ComponentsContainer = styled('div')(
  {
    width: '100%',
    '& .resource-item': {
      paddingBottom: 30,
      marginBottom: 30,
    },
  },
  ({ theme }) => ({
    '& .resource-item': {
      borderBottom: `1px solid ${theme.palette.novotalk.$borderColorLight}`,
    },
  })
);

export const WidgetWrapper = styled('div')({
  position: 'absolute',
  top: HEADER_HEIGHT + 30,
  right: 'calc(8.5% - 24px)',
});

export const LoadingOverlay = styled('div')({
  position: 'fixed',
  backgroundColor: 'rgba(0,0,0,0.6)',
  color: 'white',
  width: '100%',
  height: '100%',
  zIndex: 10,
  display: 'flex',
  alignContent: 'center',
  justifyContent: 'center',
  alignItems: 'center',
});
