import { fromJS } from 'immutable';
import { TYPES } from './actions';

export const initialState = fromJS({
  url: '',
  stateToPass: {},
});

const redirectorReducer = (state = initialState, action) => {
  switch (action.type) {
    case TYPES.SET_REDIRECT_URL:
      return state.set('url', action.url).set('stateToPass', fromJS(action.stateToPass || {}));
    case TYPES.REMOVE_REDIRECT_URL:
      return state.set('url', '').set('stateToPass', fromJS({}));
    default:
      return state;
  }
};

export default redirectorReducer;
