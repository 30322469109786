/**
 *
 * ResourceOverview
 *
 */

import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import Text, { TEXT_SIZE, TEXT_COLOR, TEXT_WEIGHT } from '@components/Text';
import { getIconByResourceType } from '@services/icons';
import { RESOURCES } from '../Resources/types';
import { createOverView } from '../Resources/overviewFactory';
import SectionLoading from '@components/SectionLoading';
import { getMediaDate, getResourcesDate } from '@utils/timeHelpers';
import {
  MetadataHeader,
  HeaderText,
  MetadataButtons,
  HeaderDuplicateButton,
  HeaderButton,
  HeaderEditButton,
  MetadataSection,
  MedaDataLine,
  UsedInSection,
  UsedInTitle,
  MedaDataUsedIn,
  MedaDataUsedInIcon,
} from './styles';
import {withTranslation} from "react-i18next";
import {compose} from "redux";

class ResourceOverview extends PureComponent {
  renderHeader = () => {
    const {
      type,
      resource,
      onDuplicate,
      onDelete,
      onEdit,
      onCopy,
      readOnly,
      disableEdit,
      t
    } = this.props;
    return (
      <MetadataHeader className='header'>
        <HeaderText
          size={TEXT_SIZE.T1}
          textColor={TEXT_COLOR.DARK_BLUE}
          className='title'
        >
          {resource.name || resource.title}
        </HeaderText>
        <MetadataButtons>
          {!readOnly
            && (type === RESOURCES.EXERCISE || type === RESOURCES.LESSON)
            && resource.isTemplate
            && (
            <HeaderButton
              className='copyButton'
              onClick={() => onCopy(resource)}
            >
              {t('Actions.Instance.copy')}
            </HeaderButton>
          )}
          {!readOnly && type === RESOURCES.COURSE && resource.isTemplate && (
            <HeaderDuplicateButton
              className='duplicateButton'
              onClick={() => onDuplicate(resource)}
            >
              {t('Actions.Instance.duplicate').toUpperCase()}
            </HeaderDuplicateButton>
          )}
          {!readOnly && (
            <HeaderButton
              className='deleteButton'
              onClick={() => onDelete(resource)}
            >
              {t('Actions.Instance.delete').toUpperCase()}
            </HeaderButton>
          )}
          {!disableEdit && (
            <HeaderEditButton
              full
              className='editButton'
              onClick={() => onEdit(resource)}
            >
              {t('Actions.Instance.edit').toUpperCase()}
            </HeaderEditButton>
          )}
        </MetadataButtons>
      </MetadataHeader>
    );
  };

  renderDates = (type, created, updated) => {
    const {t} = this.props
    return (
        <MetadataSection>
          <MedaDataLine>
            <div>
              <Text size={TEXT_SIZE.T4} textColor={TEXT_COLOR.DARK_BLUE}>
                {t('Common.UI.created')}
              </Text>
            </div>
            <Text size={TEXT_SIZE.T4} textColor={TEXT_COLOR.DISABLED}>
              <span className='created'>
                    {t('Dates.timeByUser', {
                      time: type === RESOURCES.MEDIA ? getMediaDate(created?.at) : getResourcesDate(created?.at) ,
                      userName: created?.by,
                    })}
                  </span>
            </Text>
          </MedaDataLine>
          {type !== RESOURCES.MEDIA && (
              <MedaDataLine>
                <div>
                  <Text size={TEXT_SIZE.T4} textColor={TEXT_COLOR.DARK_BLUE}>
                    {t('Common.UI.modified')}
                  </Text>
                </div>
                <Text size={TEXT_SIZE.T4} textColor={TEXT_COLOR.DISABLED}>
                  <span className='updated'>
                    {t('Dates.timeByUser', {
                      time: getResourcesDate(updated?.at),
                      userName: updated?.by,
                    })}
                  </span>
                </Text>
              </MedaDataLine>
          )}
        </MetadataSection>
    );
  }

  renderUsedIn = () => {
    const {
      type,
      resource: { usedIn },
      onView,
      readOnly,
    } = this.props;
    if (
      [RESOURCES.SKILL, RESOURCES.COURSE].includes(type) ||
      usedIn.length === 0
    )
      return null;
    return (
      <UsedInSection withoutBorder={type === RESOURCES.PRACTICE}>
        <UsedInTitle>
          <Text
            size={TEXT_SIZE.T4}
            textColor={TEXT_COLOR.DARK_BLUE}
            weight={TEXT_WEIGHT.BOLD}
          >
            {this.props.t('UI.usedIn')}
          </Text>
        </UsedInTitle>
        {usedIn.map((usedInItem) => {
          const TypeIcon = getIconByResourceType(usedInItem.type);
          return (
            <MedaDataUsedIn
              readOnly={readOnly}
              onClick={() => {
                if (!readOnly) onView(usedInItem);
              }}
              className='usedIn'
              key={usedInItem.id}
            >
              <MedaDataUsedInIcon>
                <TypeIcon className='usedInType' />
              </MedaDataUsedInIcon>
              <Text
                size={TEXT_SIZE.T4}
                textColor={TEXT_COLOR.ACTIVE}
                className='usedInName'
              >
                {usedInItem.name}
              </Text>
            </MedaDataUsedIn>
          );
        })}
      </UsedInSection>
    );
  };

  render() {
    const {
      resource,
      type,
      onEdit,
      onView,
      onExpand,
      readOnly,
      loading,
      error,
      t
    } = this.props;
    if (loading) return <SectionLoading className='loading' />
    if (error) return t('UI.errorOverview')
    return (
      <div id='overview'>
        {this.renderHeader()}
        {this.renderDates(type, resource.entityCreated, resource.entityUpdated)}
        {this.renderUsedIn()}
        {createOverView(type, resource, { onEdit, onView, onExpand, readOnly })}
      </div>
    );
  }
}
ResourceOverview.propTypes = {
  // all props are derived and not explicity called with "this.prop"
  onDuplicate: PropTypes.func,
  onDelete: PropTypes.func,
  disableEdit: PropTypes.bool,
  loading: PropTypes.bool,
  error: PropTypes.bool,
  readOnly: PropTypes.bool,
  type: PropTypes.string,
  resource: PropTypes.object,
  onEdit: PropTypes.func,
  onView: PropTypes.func,
  onExpand: PropTypes.func,
};

export default compose(
    withTranslation()
)(ResourceOverview);
