import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

import { Wrapper } from './styles';
import { editStatus } from '../../actions';
import { Success } from './components/success';
import { Pending } from './components/pending';

export const LoadingArea = ({ onLoadingFinishedButtonClick }) => {
  const { editStatus: status } = useSelector((state) => state.getIn(['editMedia']).toJS());
  return (
    <Wrapper data-cy='media_page_loading_progress_modal'>
      {status === editStatus.SENT || status === editStatus.ERROR
        ? <Success
            status={status}
            onLoadingFinishedButtonClick={onLoadingFinishedButtonClick}
          />
        : <Pending />
      }
    </Wrapper>
  );
}

LoadingArea.propTypes = {
  status: PropTypes.string,
  onLoadingFinishedButtonClick: PropTypes.func,
  error: PropTypes.object,
};

