import {styled} from "@mui/material";
import { Link } from 'react-router-dom';
import CTAButton from '@components/CTAButton';
import CheckBox from '@components/CheckBox';
import { getResponsiveCSS } from '@utils/cssHelpers';
import { getIconsByType, iconTypes } from '@services/icons';

export const Wrapper = styled('div')(
  {
    alignSelf: 'stretch',
    padding: '50px 10px 55px 20px',
    position: 'relative',
    height: 'calc(100vh - 70px)',
    display: 'flex',
    flexDirection: 'column',
  },
  ({ theme }) => ({
    borderLeft: `1px solid ${theme.palette.novotalk.$borderColorLight}`,
  })
);

export const TitleWrapper = styled('div')(
  {
    paddingBottom: 20,
    marginBottom: 20,
  },
  ({ theme }) => ({
    borderBottom: `1px solid ${theme.palette.novotalk.$borderColorLight}`,
  })
);

export const TitleTop = styled('div')({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  marginBottom: 6,
});

export const StyledLink = styled(Link)({
  textDecoration: 'none',
  display: 'flex',
});

export const StyledNewItemIcon = styled(getIconsByType(iconTypes.CREATE))({
  width: 14,
  height: 14,
  margin: '-3px 6px 0',
});

export const UsersWrapper = styled('div')({
  overflowY: 'auto',
  margin: '20px 0 -10px',
  paddingLeft: 10,
  height: 'calc(80vh - 80px)'
});

export const ActiveUser = styled('div')(
  {
    display: 'grid',
    gridTemplateColumns: '30px 42px auto 34px 30px',
    alignItems: 'center',
    height: 56,
  },
  ({ theme }) => ({
    borderBottom: `solid 1px ${theme.palette.novotalk.$resourcesTableBorderColor}`,
  })
);

export const Avatar = styled('img')({
  height: 34,
  width: 34,
  borderRadius: '50%',
  objectFit: 'cover',
});

export const StyledCheckbox = styled(CheckBox)({
  '& label.switch': {
    width: 30,
    height: 30,
  },
});

const iconStyles = ({ theme }) => ({
  fontSize: 21,
  cursor: 'pointer',
  color: theme.palette.novotalk.$textColorLightGray,
  '&:hover': {
    color: theme.palette.novotalk.$secondaryButtonColorHover,
  },
  '&:active': {
    color: theme.palette.novotalk.$secondaryButtonColorActive,
  },
});

export const StyledMessageIcon = styled(getIconsByType(iconTypes.MESSAGE))(
  iconStyles,
  {
    fontSize: 19,
  }
);

export const StyledVideoIcon = styled(getIconsByType(iconTypes.VIDEO_ON))(
  iconStyles
);

export const StyledCTAButton = styled(CTAButton)(
  {
    width: '100%',
    borderRadius: 0,
    position: 'absolute',
    bottom: 0,
    left: 0,
    right: 0,
  },
  getResponsiveCSS('height', 48, 52)
);
