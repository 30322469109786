import React from 'react';
import { toggleChatVideo } from '../../actions';
import {
  ControlsIconWrapper,
  StyledVideoOnIcon,
  StyledVideoOffIcon,
} from '../../styles';
import { useSelector, useDispatch } from 'react-redux';
export const VideoBtn = () => {
  const { isSelfVideoOn } = useSelector((state) =>
    state.getIn(['videoChat']).toJS()
  );
  const dispatch = useDispatch();
  return (
    <ControlsIconWrapper onClick={() => dispatch(toggleChatVideo())}>
      {isSelfVideoOn ? <StyledVideoOnIcon /> : <StyledVideoOffIcon />}
    </ControlsIconWrapper>
  );
};
