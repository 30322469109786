import {styled} from "@mui/material";
import Text from "@components/Text";

export const TitleUserPage = styled(Text)({
  marginBottom: 30,
  display: 'block',
});

export const TitleWithFilterWrapper = styled('div')({
  display: 'flex',
  justifyContent: 'space-between',
});
