  export const sim1 =  {
    id:"A",
    duration:60,
    createdAt:"",
    focus: [
      "SPEECH_GOALS_FOCUS_BREATHING",
      "SPEECH_GOALS_FOCUS_GENTLE_ONSET"
    ],
    events: [
      {
        timestamp:1521461212,
        type:"CLICK"
      },
      {
        timestamp:1521451212,
        type:"CLICK"
      },
      {
        timestamp:1521561212,
        type:"CLICK"
      }
  ]
  };

  export const sim2 =  {
    id:"B",
    duration:30,
    createdAt:"",
    focus: ["SPEECH_GOALS_FOCUS_GENTLE_ONSET"],
    events: [{
      timestamp:1521461412,
      type:"CLICK"
    }]
  };

  export const sim3 =  {
    id:"C",
    duration:95,
    createdAt:"",
    focus: ["SPEECH_GOALS_FOCUS_LIGHT_CONTACT"],
    events: [{
      timestamp:1521461812,
      type:"REPLAY"
    }]
  }; 