import {styled} from "@mui/material";
import { getIconsByType, iconTypes } from '@services/icons';

export const ListItem = styled('div')({
    display: 'grid',
    gridTemplate: `
    'EXPAND ITEM'
    'SUB_LIST SUB_LIST'
  `,
    alignItems: 'center',
  },
  ({ theme, isExpandableItem }) => ({
    gridTemplateColumns: isExpandableItem ? '34px auto' : '0 auto',
    borderBottom: `1px solid ${theme.palette.novotalk.$resourcesTableBorderColor}`,
  })
);


const iconStyles = {
    gridArea: 'EXPAND',
    marginLeft: 5,
    fontSize: 24,
    cursor: 'pointer',
};

export const StyledArrowRightIcon = styled(
    getIconsByType(iconTypes.FILLED_ARROW_RIGHT)
)(iconStyles, ({ theme }) => ({
    color: theme.palette.novotalk.$textColorLightGray,
}));

export const StyledArrowDownIcon = styled(
    getIconsByType(iconTypes.FILLED_ARROW_DOWN)
)(iconStyles, ({ theme }) => ({
    color: theme.palette.novotalk.$activeColor,
}));

export const Item = styled('div')({
  gridArea: 'ITEM',
});

export const SubList = styled('div')(
  {
    gridArea: 'SUB_LIST',
    marginTop: 10,
    minHeight: 50,
    borderRadius: 4,
    '& > div': {
      marginBottom: 24,
    },
    '& > div:last-of-type': {
      marginBottom: 0,
    },
    '& > div:last-of-type > div:last-of-type > div': {
      borderBottom: 'none',
    },
  },
  ({ theme, isUserPageProgram }) => ({
    backgroundColor: !isUserPageProgram && theme.palette.novotalk.$resourcesTableBgColorSelected,
    padding: `20px 0 0 ${isUserPageProgram ? 60 : 20}px`,
  })
);
