import { fromJS } from 'immutable';
import { USER_PAGE_ACTIONS } from './actions';
import { formatMentorsToOptions } from './helpers';
import { DEFAULT_VALUES } from './consts';

export const initialState = fromJS({
  userData: {},
  userCourse: {},
  userStatus: '',
  userMentors: [],
  allMentors: [],
  personalProgram: {},
  activityLog: [],
  activityDetail: null,
  loading: false,
  toastType: null,
  error: false,
});

export const userPageReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case USER_PAGE_ACTIONS.SET_LOADING_STATUS: {
      return state.set('loading', payload);
    }

    case USER_PAGE_ACTIONS.SHOW_ERROR: {
      return state.set('error', true);
    }

    case USER_PAGE_ACTIONS.SET_ONE_USER: {
      return state
        .set('userData', payload)
        .set('userCourse', payload.course ?? DEFAULT_VALUES.COURSE)
        .set('userMentors', formatMentorsToOptions(payload.mentor ?? DEFAULT_VALUES.MENTORS))
        .set('userStatus', payload.state ?? DEFAULT_VALUES.STATUS);
    }

    case USER_PAGE_ACTIONS.SET_USER_PAGE_MENTORS: {
      return state.set('allMentors', payload);
    }

    case USER_PAGE_ACTIONS.SET_USER_ACTIVITY_LOG: {
      return state.set('activityLog', payload);
    }

    case USER_PAGE_ACTIONS.SET_USER_ACTIVITY_DETAIL: {
      return state.set('activityDetail', payload);
    }

    case USER_PAGE_ACTIONS.REMOVE_USER_ACTIVITY_DETAIL: {
      return state.set('activityDetail', null);
    }

    case USER_PAGE_ACTIONS.SHOW_TOAST: {
      return state.set('toastType', payload);
    }

    default: {
      return state;
    }
  }
};
