import React from 'react';

const icon = (props) => (
  <svg width="160px" height="160px" viewBox="0 0 160 160" version="1.1" xmlns="http://www.w3.org/2000/svg" {...props}>
    <defs>
      <path d="M159.375,79.6875 C159.375,123.697274 123.698985,159.375 79.6892112,159.375 C35.6777264,159.375 0,123.697274 0,79.6875 C0,35.6777264 35.6777264,0 79.6892112,0 C123.698985,0 159.375,35.6777264 159.375,79.6875" id="path-1"></path>
    </defs>
    <g id="Project-Styleguide" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g id="Illustrations" transform="translate(-530.000000, -232.000000)">
        <rect fill="#FFFFFF" x="0" y="0" width="1440" height="520"></rect>
        <g id="children" transform="translate(530.000000, 232.000000)">
          <mask id="mask-2" fill="white">
            <use href="#path-1"></use>
          </mask>
          <use id="Mask" fill="#D5F6FF" href="#path-1"></use>
          <g id="videocall" mask="url(#mask-2)">
            <g transform="translate(15.000000, 51.000000)">
              <path d="M120.456613,4.80185615 L9.26589327,4.80185615 C6.71174014,4.80185615 4.63294664,6.88064965 4.63294664,9.43480278 L4.63294664,72.7517401 L125.089559,72.7517401 L125.089559,9.43480278 C125.089559,6.88064965 123.010766,4.80185615 120.456613,4.80185615 Z" id="Path" fill="#FAFAFA"></path>
              <path d="M120.456613,0.168909513 L9.26589327,0.168909513 C4.16965197,0.168909513 0,4.33856148 0,9.43480278 L0,72.7517401 L4.63294664,72.7517401 L4.63294664,9.43480278 C4.63294664,6.88064965 6.71174014,4.80185615 9.26589327,4.80185615 L120.456613,4.80185615 C123.010766,4.80185615 125.089559,6.88064965 125.089559,9.43480278 L125.089559,72.7517401 L129.722506,72.7517401 L129.722506,9.43480278 C129.722506,4.33856148 125.552854,0.168909513 120.456613,0.168909513 Z" id="Path" fill="#CCCFD4"></path>
              <path d="M69.6950812,40.8833411 C74.1147796,39.002413 77.2157773,34.6180046 77.2157773,29.5109049 C77.2157773,22.6881671 71.6839907,17.1563805 64.8612529,17.1563805 C58.0385151,17.1563805 52.5067285,22.6881671 52.5067285,29.5109049 C52.5067285,34.6180046 55.6077262,39.002413 60.0274246,40.8833411 C50.4170766,43.0809745 43.2408353,51.6642923 43.2408353,61.9415313 L86.4816705,61.9415313 C86.4816705,51.6639907 79.3051276,43.0806729 69.6950812,40.8833411 Z" id="Path" fill="#F0891A"></path>
              <polyline id="Path" fill="#F2F2F2" points="73.8553828 21.0063109 73.8553828 21.007819 73.856891 21.007819 73.8553828 21.0063109"></polyline>
              <polyline id="Path" fill="#1BB7EA" points="62.8476102 78.4596752 62.8458005 78.4611833 62.8476102 78.4611833 62.8476102 78.4596752"></polyline>
              <path d="M73.856891,21.007819 L73.8415081,21.0280278 C75.9338747,23.239536 77.2157773,26.2262181 77.2157773,29.5109049 C77.2157773,34.6056381 74.1301624,38.9822042 69.7258469,40.8709745 C79.3223202,43.0776566 86.4816705,51.6733411 86.4816705,61.9415313 L43.3364501,61.9415313 L54.1466589,72.7517401 L125.089559,72.7517401 L125.089559,72.2389791 L73.856891,21.007819" id="Path" fillOpacity="0.200152853" fill="#CCCFD4"></path>
              <rect id="Rectangle" fill="#A4ABB1" x="50.962413" y="94.3721578" width="27.7976798" height="10.038051"></rect>
              <polygon id="Path" fill="#BABDC2" points="78.7600928 104.410209 78.7600928 94.3721578 50.962413 94.3721578 50.962413 94.3983991 60.9742227 104.410209"></polygon>
              <path d="M38.6078886,109.043155 L92.6589327,109.043155 C93.9375174,109.043155 94.975406,108.005267 94.975406,106.726682 C94.975406,105.446288 93.9375174,104.410209 92.6589327,104.410209 L38.6078886,104.410209 C37.3293039,104.410209 36.2914153,105.446288 36.2914153,106.726682 C36.2914153,108.005267 37.3293039,109.043155 38.6078886,109.043155" id="Path" fill="#CCCFD4"></path>
              <path d="M129.722506,72.7517401 L0,72.7517401 L0,85.1062645 C0,90.2025058 4.16965197,94.3721578 9.26589327,94.3721578 L120.456613,94.3721578 C125.552854,94.3721578 129.722506,90.2025058 129.722506,85.1062645 L129.722506,72.7517401" id="Path" fill="#23B7E5"></path>
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
);

export default icon;

