import React, { Component } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import Container from '@components/Container';
import Text, { TEXT_SIZE, TEXT_COLOR } from '@components/Text';
import { getMessagingDate, getMessagingTime } from '@utils/timeHelpers';

import {
  Wrapper,
  StyledAvatar,
  UserInfo,
  PostDate,
  StyledCTAButton,
  StyledRemoveIcon,
} from './styles';
import {StyledPersonIcon} from "@containers/App/containers/Messaging/components/MessagesList/styles";
import {withTranslation} from "react-i18next";
import {compose} from "redux";

class ConversationHeader extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const {
      currentConversation,
      isNewConversationStarted,
      selectedUser,
      canEndConversation,
      onEndConversation,
        t
    } = this.props;
    if (!isNewConversationStarted && _.isEmpty(currentConversation))
      return null;

    const isConversationClosed = Boolean(
      !_.isEmpty(currentConversation) && currentConversation.Thread.closed
    );
    if (!selectedUser && (!currentConversation
      || !currentConversation.Thread
      || !currentConversation.Thread.Posts
      || !currentConversation.Thread.Posts.length)
    ) return null;
    const firstPostDate = isNewConversationStarted
      ? Date.now()
      : currentConversation.Thread.Posts[
        currentConversation.Thread.Posts.length - 1
      ].createdAt;
    const userData = isNewConversationStarted
      ? selectedUser
      : currentConversation.Users.find((user) => !user.self);

    return (
      <Wrapper>
        <Container>
          {userData?.avatar ? <StyledAvatar src={userData.avatar} /> : <StyledPersonIcon />}
          <UserInfo>
            <Text size={TEXT_SIZE.H4} textColor={TEXT_COLOR.NORMAL}>
              {userData?.fullName}
            </Text>
            {userData?.isMentor && (
              <Text size={TEXT_SIZE.T4} textColor={TEXT_COLOR.DISABLED}>
                {t('Common.Roles.mentor')}
              </Text>
            )}
          </UserInfo>
        </Container>
        <PostDate>
          <Text size={TEXT_SIZE.H4} textColor={TEXT_COLOR.DISABLED}>
            {`${getMessagingDate(firstPostDate)} | ${getMessagingTime(
              firstPostDate
            )}`}
          </Text>
        </PostDate>
        {canEndConversation && !isNewConversationStarted && (
          <StyledCTAButton
            disabled={isConversationClosed}
            onClick={onEndConversation}
          >
            {t(`Informative.Notifying.${isConversationClosed ? 'endConversation' : 'conversationEnded'}`)}
          </StyledCTAButton>
        )}
        {isNewConversationStarted && (
          <StyledRemoveIcon onClick={this.props.removeConversationPartner} />
        )}
      </Wrapper>
    );
  }
}

ConversationHeader.propTypes = {
  currentConversation: PropTypes.object,
  isNewConversationStarted: PropTypes.bool,
  selectedUser: PropTypes.object,
  canEndConversation: PropTypes.bool,
  onEndConversation: PropTypes.func,
  removeConversationPartner: PropTypes.func,
};

export default compose(
    withTranslation()
)(ConversationHeader);
