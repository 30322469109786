// Extract mandatory children from mongo db data
import _ from 'lodash';
import { BUILDER_TYPES } from '../editorObjects/editorObjectTypes';
import {
  getMonitor,
  getDefaultFreeText,
  getDefaultMediaSelection,
} from '../editorObjects/editorObjectsDefaults';

export const getDescriptionElement = (data) =>
  getDefaultTextElement(
    data,
    'description',
    'Description',
    'some default description...'
  );

export const getInstructions = (data) =>
  getDefaultTextElement(
    data,
    'texts.instructions',
    'Instructions',
    'some default instructions...'
  );

export const getDescription = (data) =>
  getDefaultTextElement(
    data,
    'texts.description',
    'Description',
    'some default description...'
  );

export const getMediaDemo = (data) =>
  getDefaultMediaElement(data, 'media.demo', 'Demo');

export const getMediaLesson = (data) =>
  getDefaultMediaElement(data, 'media.lesson', 'Lesson');

const getDefaultTextElement = (data, mapToField, textTitle, defaultValue) => {
  const defaultElement = getDefaultFreeText(
    mapToField,
    textTitle,
    defaultValue
  );

  const fieldValue = _.get(data, mapToField, null);

  if (fieldValue) defaultElement.payload.value = fieldValue;
  defaultElement.id = 'defaultElement';

  return defaultElement;
};

const getDefaultMediaElement = (data, mapToField, textTitle) => {
  const defaultElement = getDefaultMediaSelection(mapToField, textTitle);
  defaultElement.payload.value = _.get(data, mapToField, null);
  defaultElement.payload.id = _.get(data, `${mapToField}Id`, null);
  return defaultElement;
};

export const getBuilderChildrenFromData = (type, data) => {
  switch (type) {
    case BUILDER_TYPES.POLL: {
      return [getDescriptionElement(data)];
    }
    case BUILDER_TYPES.PRACTICE: {
      return [];
    }
    case BUILDER_TYPES.EXERCISE: {
      return [getMonitor(data), getMediaDemo(data), getInstructions(data)];
    }
    case BUILDER_TYPES.LESSON: {
      return [
        getMonitor(data),
        getMediaLesson(data),
        getMediaDemo(data),
        getInstructions(data),
        getDescription(data),
      ];
    }
    case BUILDER_TYPES.SKILL: {
      return [getDescription(data)];
    }
    case BUILDER_TYPES.COURSE: {
      return [getDescription(data)];
    }
    default:
      return [];
  }
};
