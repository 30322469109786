import {styled} from "@mui/material";
import { getIconByActivityType } from '@services/icons';

export const getStyledActivityIcon = (type) =>
  styled(getIconByActivityType(type))(
    {
      fontSize: 14,
      marginTop: -2,
    },
    ({ theme }) => ({
      color: theme.palette.novotalk.$textColorDisabled,
    })
  );

export const SectionHeader = styled('div')({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  marginBottom: 10,
});

export const StatusWrapper = styled('div')(
  {
    width: 80,
    height: 26,
    borderRadius: 4,
    display: 'inline-flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  ({ theme, type }) => ({
    backgroundColor: theme.palette.novotalk?.[`$status${type}BackgroundColor`],
  })
);
