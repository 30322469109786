import {styled} from "@mui/material";
import { getIconsByType, iconTypes } from '@services/icons';
import { getFlexibleSize } from '@utils/cssHelpers';

export const AlertIcon = styled(getIconsByType(iconTypes.ERROR_FILLED))(
  {
    display: 'block',
    fontSize: 70,
  },
  ({ theme }) => ({
    color: theme.palette.novotalk.$ctaButtonColor,
  })
);

export const Wrapper = styled('div')(
  {
    width: 500,
    padding: '25px 60px 28px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    alignItems: 'center',
    borderRadius: 4,
    '@media(min-width: 1280px)': {
      width: getFlexibleSize(500, 575),
    },
    '@media(min-width: 1920px)': {
      width: 575,
    },
  },
  ({ theme }) => ({
    direction: theme.RTL ? 'rtl' : 'ltr',
    backgroundColor: theme.palette.novotalk.$backgroundColor,
    color: theme.palette.novotalk.$textColorDark,
  })
);

export const TitleContainer = styled('div')(
  {
    textAlign: 'center',
  },
  ({ exerciseInProgress }) => {
    if (exerciseInProgress) {
      return {
        marginTop: 20,
        marginBottom: 8,
        '@media(min-width: 1280px)': {
          marginTop: getFlexibleSize(20, 26),
        },
        '@media(min-width: 1920px)': {
          marginTop: 26,
        },
      };
    }
    return {
      marginTop: 9,
      marginBottom: 8,
      '@media(min-width: 1280px)': {
        marginTop: getFlexibleSize(9, 16),
        marginBottom: getFlexibleSize(8, 14),
      },
      '@media(min-width: 1920px)': {
        marginTop: 16,
        marginBottom: 14,
      },
    };
  }
);

export const MessageContainer = styled('div')({
  maxWidth: 350,
  textAlign: 'center',
  lineHeight: '20px',
  marginBottom: 22,
  '@media(min-width: 1280px)': {
    lineHeight: getFlexibleSize(20, 23),
    marginBottom: getFlexibleSize(22, 26),
  },
  '@media(min-width: 1920px)': {
    lineHeight: '23px',
    marginBottom: 26,
  },
});

export const InProgressMessageContainer = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  marginBottom: 32,
  '@media(min-width: 1280px)': {
    marginTop: getFlexibleSize(0, 10),
    marginBottom: getFlexibleSize(32, 40),
  },
  '@media(min-width: 1920px)': {
    marginTop: 10,
    marginBottom: 40,
  },
});

export const ButtonsContainer = styled('div')({
  display: 'flex',
  justifyContent: 'center',
  '& > *': {
    width: 180,
    height: 44,
    margin: '0 6px',
  },
  '@media(min-width: 1280px)': {
    '& > *': {
      height: getFlexibleSize(44, 48),
    },
  },
  '@media(min-width: 1920px)': {
    '& > *': {
      height: 48,
    },
  },
});
