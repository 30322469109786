import { styled } from '@mui/material';

export const TableWrapperPending = styled('div')(({ theme }) => ({
  width: '100%',
  color: theme.palette.novotalk.$textColorDarkBlue,
}));

export const NoItemsPending = styled('div')({
  height: 100,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
});

export const LoadingOverlayPending = styled('div')({
  position: 'absolute',
  left: 0,
  backgroundColor: '#FFF',
  width: '100%',
  height: '90%',
  zIndex: 101,
  display: 'flex',
  alignContent: 'center',
  justifyContent: 'center',
  alignItems: 'center',
});

export const TableListPending = styled('div')({
  overflow: 'scroll', maxHeight: 180,
});

type StyledTableHeaderProps ={
  spread:string
}

export const TableHeaderPending = styled('div', {
  shouldForwardProp: (prop) => prop !== 'spread',
})<StyledTableHeaderProps>(({ spread, theme }) => ({
  display: 'grid',
  alignItems: 'center',
  padding: '0 16px',
  height: 43,
  gridTemplateColumns: spread,
  borderBottom: `solid 1px ${theme.palette.novotalk.$resourcesTableBorderColor}`,
  backgroundColor: theme.palette.novotalk.$backgroundColor,
  color: theme.palette.novotalk.$textColorDisabled,
}));

type StyledTableCellProps = {
  noPadding?:boolean, titlesUppercase?:boolean, isLast?:boolean, pointer?:boolean
}

export const CellPending = styled('div', {
  shouldForwardProp: (prop) => prop !== 'noPadding' && prop !== 'titlesUppercase' && prop !== 'isLast' && prop !== 'pointer',
})<StyledTableCellProps>(({
  noPadding, titlesUppercase, isLast, pointer,
}) => ({
  overflow: 'hidden',
  display: 'flex',
  cursor: pointer ? 'pointer' : 'auto',
  '& span': {
    whiteSpace: 'pre',
    textOverflow: 'ellipsis',
    paddingRight: noPadding ? 0 : 10,
    textTransform: titlesUppercase && 'uppercase',
    justifyContent: isLast && 'end',
    '> span': {
      marginRight: isLast && 32,
    },
  },
}));

export const HeaderColumnPending = styled(CellPending)({
  display: 'flex',
  alignItems: 'baseline',
});

type TableRowPendingProps={
  spread: string
  isHighlighted:boolean
}

export const TableRowPending = styled('div', {
  shouldForwardProp: (prop) => prop !== 'spread' && prop !== 'isHighlighted',
})<TableRowPendingProps>(({
  spread, isHighlighted, theme,
}) => ({
  display: 'grid',
  alignItems: 'center',
  padding: '8px 16px',
  minHeight: 47,
  cursor: 'pointer',
  position: 'relative',
  gridTemplateColumns: spread,
  borderBottom: `solid 1px ${theme.palette.novotalk.$resourcesTableBorderColor}`,
  backgroundColor: isHighlighted
    ? theme.palette.novotalk.$backgroundColorMedium
    : theme.palette.novotalk.$backgroundColor,
  '&:hover': {
    backgroundColor: isHighlighted
      ? theme.palette.novotalk.$activityUnreadBackgroundColorHover
      : theme.palette.novotalk.$activityReadBackgroundColorHover,
  },
  '&:active': {
    backgroundColor: isHighlighted
      ? theme.palette.novotalk.$activityUnreadBackgroundColorActive
      : theme.palette.novotalk.$activityReadBackgroundColorActive,
  },

}));
