export const TYPES = {
  GET_USER_INFO: 'GET_USER_INFO',
  SET_USER_INFO: 'SET_USER_INFO',
  CHANGE_PASSWORD: 'CHANGE_PASSWORD',
  SET_CHANGE_PASSWORD_RESPONSE: 'SET_CHANGE_PASSWORD_RESPONSE',
  SET_CHANGE_PASSWORD_RESPONSE_FAIL: 'SET_CHANGE_PASSWORD_RESPONSE_FAIL',
  CANCEL_SUBSCRIPTION: 'CANCEL_SUBSCRIPTION',
};

export function getUserInfoAction() {
  return {
    type: TYPES.GET_USER_INFO,
  };
}

export function setUserInfoAction(userData) {
  return {
    type: TYPES.SET_USER_INFO,
    userData,
  };
}

export function changePasswordAction(oldPassword, newPassword) {
  return {
    type: TYPES.CHANGE_PASSWORD,
    oldPassword,
    newPassword,
  };
}

export function setChangePasswordResponseAction() {
  return {
    type: TYPES.SET_CHANGE_PASSWORD_RESPONSE,
  };
}

export function setChangePasswordResponseFailAction(error) {
  return {
    type: TYPES.SET_CHANGE_PASSWORD_RESPONSE_FAIL,
    error,
  };
}

export function cancelSubscriptionAction(planId) {
  return {
    type: TYPES.CANCEL_SUBSCRIPTION,
    planId,
  };
}

