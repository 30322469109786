import _ from 'lodash';
import { overview } from '@shared/Resources/Exercise/__fixtures__';
import { graphMutation, graphQuery } from '@services/commService';

export const createEntity = async ({
    name, media,
    texts: {
     instructions,
    },
    monitor: {
     monitor, pattern, errors, progress, stimulus,
    },
  }, components, exerciseID) => {
  const demo = _.get(media, 'demo', null);

  const mutation = `
  mutation($monitor:JSON, $pattern:JSON, $errors:JSON, $progress:JSON, $stimulus:JSON, ){
    createEntityExercise(
      name:"${name}",
      media:{
        demo:"${demo}"
      },
      texts:{
        instructions:"${instructions}"
      },
      monitor:{
        monitor:$monitor,
        pattern:$pattern,
        errors:$errors,
        progress:$progress,
        stimulus:$stimulus,
      },
       ){
        id
      }
    }
  `;
  return await graphMutation(mutation, {monitor, pattern, errors, progress, stimulus}, true);
};

export const markExerciseAsCompleted = async(id)=>{
  const mutation = `
  mutation{
    modifyExercise(
      id:"${id}"
      hasPassed:true,
       ){
        id
      }
    }
  `;
  return await graphMutation(mutation, {}, true);
}

export const editEntity = async ({
  id,name, media,
  texts: {
   instructions,
  },
  monitor: {
   monitor, pattern, errors, progress, stimulus,
  },
}, components) => {
  
const demo = _.get(media, 'demo', null);

const mutation = `
mutation($monitor:JSON, $pattern:JSON, $errors:JSON, $progress:JSON, $stimulus:JSON, ){
  modifyExercise(
    id:"${id}"
    name:"${name}",
    media:{
      demo:"${demo}"
    },
    texts:{
      instructions:"${instructions}"
    },
    monitor:{
      monitor:$monitor,
      pattern:$pattern,
      errors:$errors,
      progress:$progress,
      stimulus:$stimulus,
    },
     ){
      id
    }
  }
`;
return await graphMutation(mutation, {monitor, pattern, errors, progress, stimulus}, true);
};

export const deleteEntity = async(id)=>{
  
  const mutation = `mutation{
    deleteExercise(
      id:"${id}"
    ){
      name
    }

  }`
  
  return await graphMutation(mutation,{}, true);
}

export const loadEntity = async (id) => {
  if (process.env.USE_FIXTURES) {
    return { data: { entity: [{ ...overview, id }] } };
  }
  const query = `
  {
    entity: entityExercises(id:"${id}",range:[0,1],fullData:true){
    id
    name
    createdAt
    media {
      demo
      demoId
    }
    texts{
      instructions
    }
    monitor {
      monitor
      pattern
      errors
      progress
      stimulus
    }
    masteryLevel
  }}
  `;
  return await graphQuery(query, true);
};
