import {
  put, takeLatest, all, fork, join,
} from 'redux-saga/effects';
import _ from 'lodash';
import { authenticationWrapper } from '@services/errorHandlingService';

import {
  startVideoRecording, stopVideoRecording,
} from '@shared/VideoMonitor/actions';
import { setRedirectUrl } from '@containers/App/components/Redirector/actions';
import { ROUTES } from '@shared/constants';
import {
  setUserFeedbacks,
  setCurrentPractice,
  TYPES,
  setActivityPractice,
  setActivityExercise,
  setPracticeError,

  practiceError,
} from './actions';
import { practices } from './__fixtures__/practices_new';
import { practices as practicesCypress } from './__fixtures__/practicesCypress';
import { setActivityContext } from '../../actions';
import {
  getUserFeedbacks,
  getEntityPractice,
  createActivityPractice,
  createActivityExercise,
  getActivityExercise,
} from '../../services';

const useFixtures = process.env.USE_FIXTURES;

const getPracticeById = (id) => {
  const practice = _.find(window.Cypress ? practicesCypress : practices, {
    id,
  });
  return practice;
};

export function* callGetUserFeedbacks(action) {
  try {
    yield authenticationWrapper(function* codeBlock() {
      const res = yield fork(getUserFeedbacks);
      const feedbacks = res ? yield join(res) : [];
      yield put(setUserFeedbacks(feedbacks));
    });
  } catch (error) {
    yield put(practiceError());
  }
}

export function* callGetCurrentPractice(action) {
  yield authenticationWrapper(function* codeBlock() {
    const res = yield fork(getEntityPractice, action.id);
    const practice = useFixtures ? getPracticeById(action.id) : yield join(res);
    if (!practice) {
      yield put(practiceError());
      yield put(setRedirectUrl(`${ROUTES.COURSE_PAGE}`));
    } else {
      yield put(setCurrentPractice(practice));
    }
  });
}

export function* callStartActivityPractice({
  entityPracticeInfo,
  entityExerciseInfo,
  activityContext,
  activityLogId,
}) {
  try {
    const context = _.clone(activityContext);
    context.PRACTICE = entityPracticeInfo;
    const res = yield fork(createActivityPractice, context, activityLogId);
    const activityPractice = useFixtures
      ? { id: 'activityPracticeId' }
      : yield join(res);
    yield put(setActivityPractice(activityPractice));
    context.ACTIVITY_PRACTICE = activityPractice.id;

    context.EXERCISE = entityExerciseInfo;
    const response = yield fork(createActivityExercise, context);
    const activityExercise = useFixtures
      ? { id: 'activityExerciseId' }
      : yield join(response);
    context.ACTIVITY_EXERCISE = activityExercise.id;

    yield put(setActivityExercise(activityExercise));
    yield put(setActivityContext(context));

    yield put(
      startVideoRecording({ ...context, deepestChildType: 'ACTIVITY_EXERCISE' }),
    );
  } catch (e) {
    yield put(setPracticeError(e.message));
  }
}

export function* callContinueActivityPractice({
  entityExerciseInfo,
  activityContext,
}) {
  try {
    const context = _.clone(activityContext);
    context.EXERCISE = entityExerciseInfo;
    const res = yield fork(createActivityExercise, context);
    const activityExercise = useFixtures
      ? { id: 'activityExerciseId' }
      : yield join(res);
    context.ACTIVITY_EXERCISE = activityExercise.id;
    yield put(
      startVideoRecording({ ...context, deepestChildType: 'ACTIVITY_EXERCISE' }),
    );
    yield put(setActivityExercise(activityExercise));
    yield put(setActivityContext(context));
  } catch (e) {
    yield put(setPracticeError(e.message));
  }
}

export function* callEndExercise({ activityExerciseId }) {
  try {
    const res = yield fork(getActivityExercise, activityExerciseId);
    const activityExercise = useFixtures
      ? { id: 'activityExerciseId' }
      : yield join(res);
    const { summary } = activityExercise;
    // this way we will know that we updated the mastery level
    if (summary) {
      summary.updatedMasteryLevel = true;
      activityExercise.summary = summary;
    }
    yield put(stopVideoRecording());
    yield put(setActivityExercise(activityExercise));
  } catch (e) {
    yield put(setPracticeError(e.message));
  }
}

function* watchCallGetCurrentPractice() {
  yield takeLatest(TYPES.GET_CURRENT_PRACTICE, callGetCurrentPractice);
}
function* watchCallStartActivityPractice() {
  yield takeLatest(TYPES.START_ACTIVITY_PRACTICE, callStartActivityPractice);
}
function* watchCallContinueActivityPractice() {
  yield takeLatest(
    TYPES.CONTINUE_ACTIVITY_PRACTICE,
    callContinueActivityPractice,
  );
}
function* watchCallEndExercise() {
  yield takeLatest(TYPES.END_EXERCISE, callEndExercise);
}

function* watchCallGetUserFeedbacks() {
  yield takeLatest(TYPES.GET_USER_FEEDBACKS, callGetUserFeedbacks);
}

export default function* practiceSaga() {
  yield all([
    fork(watchCallGetCurrentPractice),
    fork(watchCallStartActivityPractice),
    fork(watchCallContinueActivityPractice),
    fork(watchCallEndExercise),
    fork(watchCallGetUserFeedbacks),
  ]);
}
