const TYPES = {
  COURSE_VIDEO: 'COURSE_VIDEO',
  MEDIA_UPLOAD: 'MEDIA_UPLOAD',
  MEDIA_VIEWER: 'MEDIA_VIEWER',
  MEDIA_EDITOR: 'MEDIA_EDITOR',
  MEDIA: 'MEDIA',
  POLL_SAVE: 'POLL_SAVE',
  ALERT: 'ALERT',
  CONFIRM: 'CONFIRM',
  RESOURCE_OVERVIEW: 'RESOURCE_OVERVIEW',
  SIGN_UP_UNAVAILABLE: 'SIGN_UP_UNAVAILABLE',
  AGE_NOTIFICATION_INFO: 'AGE_NOTIFICATION_INFO',
  FEEDBACK_PREVIEW: 'FEEDBACK_PREVIEW',
  CONFIRM_DISMISS_ACTIVITY: 'CONFIRM_DISMISS_ACTIVITY',
  VIDEO_CHAT_SUMMARY: 'VIDEO_CHAT_SUMMARY',
  SELECT_RESOURCE: 'SELECT_RESOURCE',
  PRACTICE_OVERVIEW: 'PRACTICE_OVERVIEW',
  EXERCISE_EXIT: 'EXERCISE_EXIT',
  EXERCISE_TIPS: 'EXERCISE_TIPS',
  EXERCISE_END: 'EXERCISE_END',
  CLINICIAN_FEEDBACK: 'CLINICIAN_FEEDBACK',
  LESSON_END: 'LESSON_END',
  UPGRADE_COURSE: 'UPGRADE_COURSE',
  CHANGE_PASSWORD: 'CHANGE_PASSWORD',
  CHANGE_PASSWORD_CONFIRMATION: 'CHANGE_PASSWORD_CONFIRMATION',
  CANCEL_SUBSCRIPTION: 'CANCEL_SUBSCRIPTION',
  MEDIA_DEVICES_PERMISSIONS: 'MEDIA_DEVICES_PERMISSIONS',
  DUPLICATE:'DUPLICATE',
  NOISE_CALIBRATION: 'NOISE_CALIBRATION',
};

export default TYPES;
