import React from 'react';
import PropTypes from 'prop-types';
import {
  differenceInMinutes,
  differenceInHours,
  differenceInDays,
} from 'date-fns';
import Text, { TEXT_SIZE } from '@components/Text';
import {useTranslation} from "react-i18next";

const makeTwoDigits = (num) => `0${num}`.slice(-2);

const getFormatOptionsInHours = (hoursAgo, now, then) => {
  const {t} = useTranslation()
  const minutesAgo = differenceInMinutes(now, then) - hoursAgo * 60;
  return t('Dates.hoursMinutesAgo', {hours: makeTwoDigits(hoursAgo), minutes: makeTwoDigits(minutesAgo)})
};

const getFormatOptionsInDays = (now, then) => {
  const {t} = useTranslation()
  const daysAgo = differenceInDays(now, then);
  return t('Dates.daysAgo', {days: daysAgo})
};

const Timestamp = ({ timestamp, isUnvisited }) => {
  const now = Date.now();
  const then = new Date(+timestamp);
  const hoursAgo = differenceInHours(now, then);

  const formatOptions =
    hoursAgo < 48
      ? getFormatOptionsInHours(hoursAgo, now, then)
      : getFormatOptionsInDays(now, then);

  return (
    <Text
      size={TEXT_SIZE.H4}
      textColor={[isUnvisited ? 'DARK_BLUE' : 'DISABLED']}
    >
      {formatOptions}
    </Text>
  );
};

Timestamp.propTypes = {
  timestamp: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  isUnvisited: PropTypes.bool,
};

export default Timestamp;
