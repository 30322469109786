import gql from 'graphql-tag';
import { graphMutation } from '@services/commService';

export const visit = async (type, id) => {
  const mutation = gql`
  mutation{
    visit(type:${type},id:"${id}"){
      type
      id
      visitorId
    }
  }`;
  const res = await graphMutation(mutation);
  return res;
};
