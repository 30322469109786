import {styled} from "@mui/material";
import { getIconsByType, iconTypes } from '@services/icons';

const ErrorIcon = getIconsByType(iconTypes.CLOSE_CIRCLE);
const CorrectIcon = getIconsByType(iconTypes.CIRCLE_CHECKMARK_FILLED);

export const StyledCorrectIcon = styled(CorrectIcon)(
  {
    fontSize: 30,
    margin: '0 3px',
  },
  ({ theme }) => ({
    color: theme.palette.novotalk.$monitorFeedbackCorrectColor,
  })
);

export const StyledErrorIcon = styled(ErrorIcon)(
  {
    fontSize: 30,
    margin: '0 3px',
  },
  ({ theme }) => ({
    color: theme.palette.novotalk.$monitorFeedbackErrorColor,
  })
);

export const StyledCurrentIcon = styled('span')(
  {
    width: 24,
    height: 24,
    border: '3px solid',
    borderRadius: '50%',
    margin: '0 6px',
  },
  ({ theme }) => ({
    borderColor: theme.palette.novotalk.$repetitionCurrentIconColor,
  })
);

export const StyledFutureIcon = styled('span')(
  {
    width: 24,
    height: 24,
    border: '2px solid',
    borderRadius: '50%',
    margin: '0 6px',
  },
  ({ theme }) => ({
    borderColor: theme.palette.novotalk.$circularProgressEmptyColor,
  })
);
