import PropTypes from 'prop-types';
import Text from '../Text';
import {useTranslation} from "react-i18next";

export const InsightText = ({
  additionalTextProps = {},
  insight: {
    error,
    severity,
  }
}) => {
  const {t} = useTranslation()
  return (
      <Text className="insightTextWrapper" {...additionalTextProps}>
        {t(`Advices.${error}_${severity}`)}
      </Text>
  );
}


InsightText.propTypes = {
  additionalTextProps: PropTypes.object,
  insight: PropTypes.shape({
    error: PropTypes.string,
    severity: PropTypes.string,
  }),
};