import React from 'react';
import PropTypes from 'prop-types';
import Text, { TEXT_SIZE, TEXT_COLOR } from '@components/Text';
import { Wrapper, TextArea } from './styles';
import {compose} from "redux";
import {withTranslation} from "react-i18next";

class DescriptionText extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      valid: props.value ? props.value.length > 0 : false,
      value: props.value || '',
    };
  }

  onChange = (event) => {
    const { value } = event.target;
    const valid = value.length > 0;
    this.setState({ valid, value });
    this.props.onChange({ valid, value });
  };

  render() {
    const { valid, value } = this.state;
    const {parentType, t} = this.props;
    return (
      <Wrapper>
        <div>
          <Text size={TEXT_SIZE.T2} textColor={TEXT_COLOR.DARK_BLUE}>
            {this.props.questionText}
          </Text>
        </div>
        <TextArea
          value={value}
          error={!valid}
          onChange={this.onChange}
          placeholder={t('UI.resourceDescription', {
            resource: parentType.toLowerCase(),
          })}
        />
      </Wrapper>
    );
  }
}

DescriptionText.propTypes = {
  questionText: PropTypes.string,
  value: PropTypes.string,
  onChange: PropTypes.func,
  parentType: PropTypes.string,
};

export default compose(
withTranslation()
)(DescriptionText);
