export const TYPES = {
  SAVE_ACTIVITY_PRODUCTION: 'SAVE_ACTIVITY_PRODUCTION',
  SET_MONITOR_PRODUCTION: 'SET_MONITOR_PRODUCTION',
  SET_MONITOR_SUB_PRODUCTION_IN_PROGRESS_FLAG:
    'SET_MONITOR_SUB_PRODUCTION_IN_PROGRESS_FLAG',
  INSERT_NEW_REPETITION_INTO_REPETITIONS_RESULTS:
    'INSERT_NEW_REPETITION_INTO_PRODUCTION_RESULTS',
  SET_ERRORS_IN_PRODUCTION_RESULTS: 'SET_ERRORS_IN_PRODUCTION_RESULTS',
  SET_MONITOR_ERRORS: 'SET_MONITOR_ERRORS',
  SET_REPETITION_IN_SEQUENCE_INDEX: 'SET_REPETITION_IN_SEQUENCE_INDEX',
  SET_MONITOR_ACTIVE_FLAG: 'SET_MONITOR_ACTIVE_FLAG',
  SET_MICROPHONE_ACTIVE_FLAG: 'SET_MICROPHONE_ACTIVE_FLAG',
  SET_EXERCISE_PAUSED_FLAG: 'SET_EXERCISE_PAUSED_FLAG',
  SET_INTERVAL_EXERCISE: 'SET_INTERVAL_EXERCISE',
  RESET_EXERCISE_DATA: 'RESET_EXERCISE_DATA',
  ADVANCE_TO_NEXT_SEQUENCE: 'ADVANCE_TO_NEXT_SEQUENCE',
  ADVANCE_TO_NEXT_SYLLABLE: 'ADVANCE_TO_NEXT_SYLLABLE',
  DISCONNECT_FROM_MIRROR_SESSION: 'DISCONNECT_FROM_MIRROR_SESSION',
  SET_NOISE_CALIBRATION: 'SET_NOISE_CALIBRATION',
  RESET_REPETITION_RESULTS: 'RESET_REPETITION_RESULTS',
  CHANGE_CALIBRATION_STATUS: 'CHANGE_CALIBRATION_STATUS',
  SET_LIST_ONE_SECOND_AMOUNT_OF_SYLLABLES:'SET_LIST_ONE_SECOND_AMOUNT_OF_SYLLABLES',
};

export const saveActivityProduction = (payload) => ({
  type: TYPES.SAVE_ACTIVITY_PRODUCTION,
  payload,
});

export const setMonitorProduction = (production) => ({
  type: TYPES.SET_MONITOR_PRODUCTION,
  production,
});

export const setMonitorSubProductionInProgressFlag = (value) => ({
  type: TYPES.SET_MONITOR_SUB_PRODUCTION_IN_PROGRESS_FLAG,
  value,
});

export const insertNewRepetitionIntoRepetitionResults = () => ({
  type: TYPES.INSERT_NEW_REPETITION_INTO_REPETITIONS_RESULTS,
});

export const setErrorsInProductionResults = () => ({
  type: TYPES.SET_ERRORS_IN_PRODUCTION_RESULTS,
});

export const setMonitorErrors = (errors) => ({
  type: TYPES.SET_MONITOR_ERRORS,
  errors,
});

export const setRepetitionInSequenceIndex = (index) => ({
  type: TYPES.SET_REPETITION_IN_SEQUENCE_INDEX,
  index,
});

export const setMonitorActiveFlag = (value) => ({
  type: TYPES.SET_MONITOR_ACTIVE_FLAG,
  value,
});

export const setMicrophoneActiveFlag = (value) => ({
  type: TYPES.SET_MICROPHONE_ACTIVE_FLAG,
  value,
});

export const setExercisePausedFlag = (value) => ({
  type: TYPES.SET_EXERCISE_PAUSED_FLAG,
  value,
});

export const resetExerciseData = (repetitionsInSequence) => ({
  type: TYPES.RESET_EXERCISE_DATA,
  repetitionsInSequence,
});

export const advanceToNextSequence = () => ({
  type: TYPES.ADVANCE_TO_NEXT_SEQUENCE,
});

export const advanceToNextSyllable = (value) => ({
  type: TYPES.ADVANCE_TO_NEXT_SYLLABLE,
  value
})

export const resetRepetitionResults = () => ({
  type: TYPES.RESET_REPETITION_RESULTS,
})

export const disconnectFromMirrorSession = (archiveId) => ({
  type: TYPES.DISCONNECT_FROM_MIRROR_SESSION,
  archiveId
});

export const setNoiseCalibration = (value) => ({
  type: TYPES.SET_NOISE_CALIBRATION,
  value
});

export const changeCalibrationStatus = (value) => ({
  type: TYPES.CHANGE_CALIBRATION_STATUS,
  value
});
export const setListOneSecondAmountsOfSyllables =(array)=>({
  type:TYPES.SET_LIST_ONE_SECOND_AMOUNT_OF_SYLLABLES,
  payload:array
})

export const setIntervalExercise = (interval) => ({
  type: TYPES.SET_INTERVAL_EXERCISE,
  interval
});
