import React from 'react';
import PropTypes from 'prop-types';
import Text, { TEXT_COLOR } from '@components/Text';
import SectionLoading from "@components/SectionLoading";
import { Wrapper, Title } from './styles';

const NoiseCalibration = ({
  titleText,
  width,
}) => (
  <Wrapper width={width}>
    <div dir='auto'>
      <Title>
        <Text size='T0' textColor={TEXT_COLOR.DARK_BLUE} weight='900'>
          {titleText}
        </Text>
      </Title>
    </div>
    <SectionLoading />
  </Wrapper>
);

NoiseCalibration.propTypes = {
  titleText: PropTypes.node,
  width: PropTypes.number,
};

export default NoiseCalibration;
