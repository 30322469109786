import React from 'react';
import { toggleChatAudio } from '../../actions';
import {
  ControlsIconWrapper,
  StyledSoundOnIcon,
  StyledSoundOffIcon,
} from '../../styles';
import { useSelector, useDispatch } from 'react-redux';

export const AudioBtn = () => {
  const { isSelfAudioOn } = useSelector((state) =>
    state.getIn(['videoChat']).toJS()
  );
  const dispatch = useDispatch();
  return (
    <ControlsIconWrapper onClick={() => dispatch(toggleChatAudio())}>
      {isSelfAudioOn ? <StyledSoundOnIcon /> : <StyledSoundOffIcon />}
    </ControlsIconWrapper>
  );
};
