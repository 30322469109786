import { TableColumn } from '../PendingTable';
import { Activity } from '../types';
import { CellPending, TableRowPending } from '../styles';

type RowPendingProps = {
  item: Activity;
  spread: string;
  handleRowSelect: (item: Activity) => void;
  config: TableColumn[];
};

export const RowPending = ({ item, spread, handleRowSelect, config }: RowPendingProps) => {
  const rowProps = {
    spread,
    onClick: () => handleRowSelect(item),
    key: item.id,
    isHighlighted: false,
  };
  return (
    <TableRowPending className="row" {...rowProps}>
      {config.map(({ getDisplay }, colIndex) => (
        <CellPending className="cell" key={`Row${item.id}Column${colIndex}`} isLast={colIndex === config.length - 1}>
          {getDisplay && getDisplay(item)}
        </CellPending>
      ))}
    </TableRowPending>
  );
};
