import { styled } from '@mui/material';
import { getResponsiveCSS } from '@utils/cssHelpers';
import Text from '@components/Text';
import { isDesktop as isDesktopCheck } from '@shared/constants/media-queries';
import CTAButton from '@components/CTAButton';
import {
    calculateLoaderHeight, calculateWrapperHeight
} from "./helpers"
import {BIG_SCREEN_BREAKPOINT} from "@containers/App/consts";

const isDesktop = isDesktopCheck();

export const Wrapper = styled('div')({
  display: 'flex',
  justifyContent: 'space-around',
  flexShrink: 0,
  justifyItems: 'center',
  alignItems: 'flex-start',
});

export const StimuliModuleWrapper = styled('div')(
  {
    display: 'flex',
    justifyContent: 'center',
    flexWrap: 'wrap',
    flexDirection: 'column',
    alignItems: 'center',
  },
);

export const StimuliPratsWrapper = styled('div')(
  {
    display: 'flex',
    justifyContent: 'center',
    flexWrap: 'wrap',
  },
  getResponsiveCSS('width', 780, 1130),
);

export const Syllable = styled('div')(
  {
    position: 'relative',
    lineHeight: '32px',
    transition: '0.3s linear',
    marginLeft: '10px',
    textAlign: 'center',
    '&:first-child': {
      marginLeft: 0,
    },
  },
);

export const StimuliText = styled(Text)(

  ({ theme, isCurrent }) => ({
    color: isCurrent ? theme.palette.novotalk.$novotalkBlue : theme.palette.novotalk.$textColorDisabled,
    fontWeight: 400,
    marginRight: 10,
      fontSize: 25,
      lineHeight: '30px',
      [theme.breakpoints.down(BIG_SCREEN_BREAKPOINT)]: {
          fontSize: 19,
          lineHeight: '23px'
      },
  }),
);

export const SpeechTopicText = styled(Text)(
  ({ theme }) => ({
    color: theme.palette.novotalk.$textColorDisabled,
      fontSize: 25,

      [theme.breakpoints.down(BIG_SCREEN_BREAKPOINT)]: {
          fontSize: 19,
      },
  }),
);

export const StimuliSpeechTopicButton = styled(CTAButton)({
    margin: '0 auto',
});


export const StimuliButton = styled(CTAButton)({
  margin: '20px auto 0',
});

export const Loader = styled("div")(({theme}) => ({
    width: 48,
    height: 48,
    border: `5px solid ${theme.palette.novotalk.$novotalkBlue}`,
    borderBottomColor: 'transparent',
    borderRadius: '50%',
    display: 'inline-block',
    boxSizing: 'border-box',
    animation: 'rotation 1s linear infinite',
    "@keyframes rotation": {
        '0%': {
        transform: 'rotate(0deg)'
        },
        '100%': {
    transform: 'rotate(360deg)'
}
    }
}))

export const LoaderWrapper = styled("div")(({theme, type}) => ({
    height: calculateLoaderHeight(type),
    display: "flex",
    justifyContent: 'center',
    alignItems: 'center',
    [theme.breakpoints.down(BIG_SCREEN_BREAKPOINT)]: {
        height: calculateLoaderHeight(type, true),
    }
}))

export const SpeechTopicWrapper = styled("div")({
    height: 130,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    marginBottom: 20,
})

export const CommonStimuliWrapper = styled("div")(({theme, type}) => ({
    height: calculateWrapperHeight(type),
    [theme.breakpoints.down(BIG_SCREEN_BREAKPOINT)]: {
        height: calculateWrapperHeight(type, true),
    }
}))
