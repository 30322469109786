import {styled} from "@mui/material";
import Input from '@components/Input';

export const ErrorsSection = styled('div')(({ isDisabled }) => ({
  pointerEvents: isDisabled ? 'none' : null,
  opacity: isDisabled ? 0.4 : 1,
}));

export const ErrorsSectionPart = styled('div')({
  marginBottom: 25,
});

export const SpecificErrors = styled('div')({
  display: 'flex',
  flexWrap: 'wrap',
  justifyContent: 'stretch',
});

export const SpecificPosition = styled('div')({
  minWidth: 385,
  marginBottom: 10,
});

export const TitleWrapper = styled('div')({
  display: 'flex',
  alignItems: 'center',
  marginBottom: 20,
});

export const ToggleWrapper = styled('span')({
  margin: '3px 30px 0',
});

export const ErrorsSectionTitle = styled('div')({
  marginBottom: 10,
});

export const MasteryLevelContainer = styled('div')({
  display: 'flex',
  alignItems: 'center',
});

export const StyledInput = styled(Input)({
  textAlign: 'center',
  marginRight: 6,
  marginLeft: 15,
});
