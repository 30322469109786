import {styled} from "@mui/material";
import TYPES from './types';

const primarySchema = (theme, disabled) => {
  if (disabled) {
    return {
      backgroundColor: theme.palette.novotalk.$buttonColorDisabled,
    };
  }
  return {
    backgroundColor: theme.palette.novotalk.$ctaButtonColor,
    '&:hover': {
      backgroundColor: theme.palette.novotalk.$ctaButtonColorHover,
    },
    '&:active': {
      backgroundColor: theme.palette.novotalk.$ctaButtonColorActive,
    },
  };
};

const secondarySchema = (theme, disabled) => {
  if (disabled) {
    return {
      backgroundColor: theme.palette.novotalk.$buttonColorDisabled,
    };
  }
  return {
    backgroundColor: theme.palette.novotalk.$secondaryButtonColor,
    '&:hover': {
      backgroundColor: theme.palette.novotalk.$secondaryButtonColorHover,
    },
    '&:active': {
      backgroundColor: theme.palette.novotalk.$secondaryButtonColorActive,
    },
  };
};

const actionSchema = (theme, disabled) => {
  if (disabled) {
    return {
      backgroundColor: theme.palette.novotalk.$buttonColorDisabled,
    };
  }
  return {
    backgroundColor: theme.palette.novotalk.$actionButtonColor,
    '&:hover': {
      backgroundColor: theme.palette.novotalk.$actionButtonColorHover,
    },
    '&:active': {
      backgroundColor: theme.palette.novotalk.$actionButtonColorActive,
    },
  };
};

const outlineSchema = (theme, disabled, { mainColor, activeColor }) => {
  if (disabled) {
    return {
      backgroundColor: theme.palette.novotalk.$outlineButtonColorReversed,
      border: `1px solid ${theme.palette.novotalk.$buttonColorDisabled}`,
      color: theme.palette.novotalk.$buttonColorDisabled,
    };
  }
  return {
    backgroundColor: theme.palette.novotalk.$outlineButtonColorReversed,
    border: `1px solid ${mainColor}`,
    color: mainColor,
    '&:hover': {
      backgroundColor: mainColor,
      color: theme.palette.novotalk.$outlineButtonColorReversed,
    },
    '&:active': {
      backgroundColor: activeColor,
      color: theme.palette.novotalk.$outlineButtonColorReversed,
      border: `1px solid ${activeColor}`,
    },
  };
};

const cancelSchema = (theme, disabled) => {
  if (disabled) {
    return {
      fontSize: 14,
      color: theme.palette.novotalk.$buttonColorDisabled,
    };
  }
  return {
    fontSize: 14,
    color: theme.palette.novotalk.$textColorDisabledLight,
    opacity: '0.7',
    '&:hover': {
      opacity: '1',
    },
    '&:active': {
      color: theme.palette.novotalk.$activeColor,
      opacity: '1',
    },
  };
};

const getButtonSchema = (type, theme, disabled) => {
  switch (type) {
    case TYPES.PRIMARY:
      return primarySchema(theme, disabled);
    case TYPES.SECONDARY:
      return secondarySchema(theme, disabled);
    case TYPES.ACTION:
      return actionSchema(theme, disabled);
    case TYPES.OUTLINE:
      return outlineSchema(theme, disabled, {
        mainColor: theme.palette.novotalk.$outlineButtonColor,
        activeColor: theme.palette.novotalk.$outlineButtonColorActive,
      });
    case TYPES.OUTLINE_PRIMARY:
      return outlineSchema(theme, disabled, {
        mainColor: theme.palette.novotalk.$ctaButtonColor,
        activeColor: theme.palette.novotalk.$ctaButtonColorActive,
      });
    case TYPES.CANCEL:
      return cancelSchema(theme, disabled);
    default:
      return primarySchema(theme);
  }
};

export const Wrapper = styled('div')(
  {
    alignItems: 'center',
    display: 'flex',
    boxSizing: 'border-box',
    flexShrink: 0,
    fontSize: 16,
    fontWeight: 'normal',
  },
  ({ theme, type, expanded, disabled }) => {
    let width;
    let height;
    let borderRadius;
    if (expanded) {
      width = '100%';
      height = 48;
      borderRadius = 24;
    } else if (type === TYPES.CANCEL) {
      width = 'auto';
      height = 'auto';
      borderRadius = 0;
    } else {
      width = 180;
      height = 48;
      borderRadius = 24;
    }
    return {
      justifyContent: 'center',
      cursor: disabled ? 'default' : 'pointer',
      color: theme.palette.novotalk.$textColorReversed,
      pointerEvents: disabled ? 'none' : 'initial',
      width,
      height,
      borderRadius,
      ...getButtonSchema(type, theme, disabled),
    };
  }
);
