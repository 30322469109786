const parts = [
          {
            "title": "הרפיה- פלג גוף עליון",
            "config": {
              "uiSettings": {
                "enableMirror": true,
                "video": {
                  "id": 120,
                  "type": "video",
                  "title": "demonstration: demonstration.relaxation.mp4",
                  "value": "https://s3-eu-west-1.amazonaws.com/nvtk-video-media/2016_05/הדגמות/demonstration.relaxation.mp4",
                  "tags": [],
                  "thumb": "http://www.depaul.edu/campustour/Pages/_layouts/spotlight/btn-videoplayoverlay.png"
                },
                "nolimit": true,
                "repetition": 1,
                "instructions": "את תרגילי ההרפיה יש לבצע בישיבה נינוחה, לא זקופה מדי ולא רפויה מדי, כאשר מנח הרגליים הוא 90 מעלות וכפות הרגליים נוגעות ברצפה.\nהתרגילים הבאים מסייעים להרפיה של פלג הגוף העליון.  חשוב  לבצע את תרגילי ההרפיה לפני כל תרגול ואף מומלץ לבצעם במהלך היום בכל הזדמנות. \nהביטו בהדגמה שבתרגול והצטרפו למדגים.",
                "videoInstructions": {
                  "id": 128,
                  "type": "video",
                  "title": "instructions: instructions.relaxation.mp4",
                  "value": "https://s3-eu-west-1.amazonaws.com/nvtk-video-media/2016_05/הוראות/instructions.relaxation.mp4",
                  "tags": [],
                  "thumb": "http://www.depaul.edu/campustour/Pages/_layouts/spotlight/btn-videoplayoverlay.png"
                },
                "isRecordEnabled": true,
                "isScreenShareEnabled": true
              },
              "description": "הרפיה- פלג גוף עליון",
              "name": "mirror task",
              "timeEstimation": 60,
              "uiType": {
                "type": "mirror",
                "icon": "icon-camcorder"
              },
              "engineType": {
                "type": "DummyEngine",
                "icon": "icon-book-open"
              },
              "pressed": false,
              "passScore": 50,
              "filterSettings": [
                {
                  "filter": {
                    "selectedBank": "none"
                  }
                }
              ],
              "wordGoal": 1,
              "duration": 1,
              "analysisDetails": [
                {
                  "analyzeNumSylsPerSec": 1,
                  "configs": [
                    {
                      "analyzeNumSylsPerSec": 1,
                      "weight": 1
                    },
                    {
                      "analyzeNumSylsPerSec": 1,
                      "weight": 1
                    }
                  ],
                  "analyzeTypeNumOfSylsInSeq": 1,
                  "mistakesSkills": [],
                  "mistakes": []
                }
              ],
              "jqyoui_pos": 11,
              "type": "wout"
            },
            "sequences": [],
            "results": {
              "fieldType": "results",
              "woutActivityId": "20633",
              "woutIndex": "0",
              "userId": "709",
              "timerPos": 196,
              "woutActivityShardGuid": "1ddde471413e0524e0db9f86ea2d744f0fdb2d6e",
              "wout": {
                "levelIndex": "26",
                "partIndex": 0,
                "part": {
                  "uiSettings": {
                    "enableMirror": true,
                    "video": {
                      "id": 120,
                      "type": "video",
                      "title": "demonstration: demonstration.relaxation.mp4",
                      "value": "https://s3-eu-west-1.amazonaws.com/nvtk-video-media/2016_05/הדגמות/demonstration.relaxation.mp4",
                      "tags": [],
                      "thumb": "http://www.depaul.edu/campustour/Pages/_layouts/spotlight/btn-videoplayoverlay.png"
                    },
                    "nolimit": true,
                    "repetition": 1,
                    "instructions": "את תרגילי ההרפיה יש לבצע בישיבה נינוחה, לא זקופה מדי ולא רפויה מדי, כאשר מנח הרגליים הוא 90 מעלות וכפות הרגליים נוגעות ברצפה.\nהתרגילים הבאים מסייעים להרפיה של פלג הגוף העליון.  חשוב  לבצע את תרגילי ההרפיה לפני כל תרגול ואף מומלץ לבצעם במהלך היום בכל הזדמנות. \nהביטו בהדגמה שבתרגול והצטרפו למדגים.",
                    "videoInstructions": {
                      "id": 128,
                      "type": "video",
                      "title": "instructions: instructions.relaxation.mp4",
                      "value": "https://s3-eu-west-1.amazonaws.com/nvtk-video-media/2016_05/הוראות/instructions.relaxation.mp4",
                      "tags": [],
                      "thumb": "http://www.depaul.edu/campustour/Pages/_layouts/spotlight/btn-videoplayoverlay.png"
                    },
                    "isRecordEnabled": true,
                    "isScreenShareEnabled": true
                  },
                  "description": "הרפיה- פלג גוף עליון",
                  "name": "mirror task",
                  "timeEstimation": 60,
                  "uiType": {
                    "type": "mirror",
                    "icon": "icon-camcorder"
                  },
                  "engineType": {
                    "type": "DummyEngine",
                    "icon": "icon-book-open"
                  },
                  "pressed": false,
                  "passScore": 50,
                  "filterSettings": [
                    {
                      "filter": {
                        "selectedBank": "none"
                      }
                    }
                  ],
                  "wordGoal": 1,
                  "duration": 1,
                  "analysisDetails": [
                    {
                      "analyzeNumSylsPerSec": 1,
                      "configs": [
                        {
                          "analyzeNumSylsPerSec": 1,
                          "weight": 1
                        },
                        {
                          "analyzeNumSylsPerSec": 1,
                          "weight": 1
                        }
                      ],
                      "analyzeTypeNumOfSylsInSeq": 1,
                      "mistakesSkills": [],
                      "mistakes": []
                    }
                  ],
                  "jqyoui_pos": 11,
                  "type": "wout"
                },
                "totalLevels": 52,
                "totalParts": 10,
                "tp2uInstanceId": "1984fcbe7fa059f148897aea947062da8efe9df6",
                "tpId": "809",
                "woutTag": "הרפיה- פלג גוף עליון"
              },
              "requiredDuration": 0,
              "actualDuration": -1,
              "isLastPartInLevel": false,
              "isLastLevel": false,
              "requiredPassRate": 50,
              "overallRate": 0,
              "totalActualSequencesDone": 0,
              "totalRequiredSequences": 1,
              "isWoutPassed": true,
              "nolimit": true,
              "showWordGoal": false,
              "showDurationGoal": false,
              "isPracticeExpired": false
            }
          },
          {
            "title": "נשימה- הגדלת נפח",
            "config": {
              "analysisDetails": [
                {
                  "breathingEnabled": true,
                  "breathTimeMs": 3000,
                  "mistakesEnabled": true,
                  "mistakes": [
                    "gentleOnset",
                    "gentleOffset",
                    "softPeak",
                    "volControlDown",
                    "volControlUp",
                    "tooShort",
                    "tooFast",
                    "tooSlow",
                    "symetric",
                    "tooSoft",
                    "tooHard",
                    "breathEarly",
                    "intensePeak",
                    "sharpRise",
                    "sharpFall",
                    "shapeStart",
                    "shapeEnd",
                    "volControlStart",
                    "volControlEnd",
                    "notSoft",
                    "notHard"
                  ],
                  "boundariesEnabled": true,
                  "coloringEnabled": false,
                  "analysisType": "breath",
                  "analyzeTypeSylLenMs": 5000,
                  "analyzeTypeNumOfSylsInSeq": 1,
                  "mistakesSkills": {
                    "tooShort": 2,
                    "tooLong": 1,
                    "breathMiss": 1,
                    "breathEarly": 2
                  },
                  "configs": [
                    {
                      "analyzeTypeSylLenMs": 500,
                      "analyzeTypeNumOfSylsInSeq": 5,
                      "weight": 3,
                      "analyzeNumSylsPerSec": 1
                    },
                    {
                      "analyzeTypeSylLenMs": 200,
                      "analyzeTypeNumOfSylsInSeq": 5,
                      "weight": 5,
                      "analyzeNumSylsPerSec": 1
                    }
                  ],
                  "analyzeNumSylsPerSec": 1,
                  "boundariesGridEnabled": true
                }
              ],
              "uiSettings": {
                "enableMirror": false,
                "instructions": "על המסך תופיע הנחיה למשך כמה זמן לשאוף אויר, ולמשך כמה זמן להפיק זזז.  שימו לב למוניטור והתאימו את הפקת הקול  שלכם לסרגלי ההתקדמות של השאיפה והנשיפה.",
                "showWordGoal": true,
                "inhaleText": "לשאוף אויר למשך 3 שניות",
                "exhaleText": "להגיד זזזז רציף למשך 5 שניות לפחות.",
                "repetition": 1,
                "videoInstructions": {
                  "id": 125,
                  "type": "video",
                  "title": "instructions: instructions.breathing+z+volume.mp4",
                  "value": "https://s3-eu-west-1.amazonaws.com/nvtk-video-media/2016_05/הוראות/instructions.breathing+z+volume.mp4",
                  "tags": [],
                  "thumb": "http://www.depaul.edu/campustour/Pages/_layouts/spotlight/btn-videoplayoverlay.png"
                },
                "isRecordEnabled": true,
                "isScreenShareEnabled": true
              },
              "wordGoal": 15,
              "description": "נשימה- הגדלת נפח",
              "name": "breathing 1-3",
              "timeEstimation": 120,
              "uiType": {
                "type": "breath",
                "icon": "icon-star"
              },
              "engineType": {
                "type": "DummyEngine",
                "icon": "icon-book-open"
              },
              "pressed": false,
              "passScore": "20",
              "filterSettings": [
                {
                  "filter": {
                    "selectedBank": "none"
                  }
                }
              ],
              "jqyoui_pos": 11,
              "type": "wout"
            },
            "sequences": [
              {
                "fieldType": "seq",
                "timerPos": 207,
                "calibrationEventId": "6581",
                "rate": 100,
                "deltaTimeMsFromPrevSeq": "-1.00",
                "yvalues": [
                  "138",
                  "176",
                  "184",
                  "186",
                  "184",
                  "182",
                  "182",
                  "180",
                  "177",
                  "178",
                  "180",
                  "184",
                  "185",
                  "186",
                  "181",
                  "179",
                  "176",
                  "176",
                  "179",
                  "155",
                  "146",
                  "142",
                  "139",
                  "141",
                  "146",
                  "138",
                  "140",
                  "137",
                  "136",
                  "134",
                  "132",
                  "135",
                  "137",
                  "137",
                  "138",
                  "138",
                  "140",
                  "138",
                  "139",
                  "141",
                  "130",
                  "65",
                  "0"
                ],
                "yDrawvalues": "2658,3270,3176,3211,3054,2985,3018,2905,2764,2860,2966,3157,3158,3161,2911,2888,2766,2787,2905,1850,1696,1629,1557,1637,1809,1477,1617,1496,1494,1447,1384,1514,1522,1504,1568,1550,1643,1529,1572,1639,1269,174,0",
                "mistakes": [],
                "hiddenMistakes": [],
                "softVals": [
                  "1",
                  "0",
                  "0",
                  "0",
                  "0",
                  "0",
                  "0",
                  "0",
                  "0",
                  "0",
                  "0",
                  "0",
                  "0",
                  "0",
                  "0",
                  "0",
                  "0",
                  "0",
                  "0",
                  "1",
                  "1",
                  "1",
                  "1",
                  "1",
                  "1",
                  "1",
                  "1",
                  "1",
                  "1",
                  "1",
                  "1",
                  "1",
                  "1",
                  "1",
                  "1",
                  "1",
                  "1",
                  "1",
                  "1",
                  "1",
                  "1",
                  "1",
                  "1"
                ],
                "expectedSylsPerSecond": "0.00",
                "actualSylsPerSecond": "0.35",
                "timeLength": 5375,
                "features": [
                  {
                    "sampleYVal": 193,
                    "sampleYDrawVal": 3418
                  },
                  {
                    "sampleYVal": 194,
                    "sampleYDrawVal": 3486
                  },
                  {
                    "sampleYVal": 188,
                    "sampleYDrawVal": 3234
                  },
                  {
                    "sampleYVal": 188,
                    "sampleYDrawVal": 3206
                  },
                  {
                    "sampleYVal": 179,
                    "sampleYDrawVal": 2853
                  },
                  {
                    "sampleYVal": 183,
                    "sampleYDrawVal": 3036
                  },
                  {
                    "sampleYVal": 180,
                    "sampleYDrawVal": 2878
                  },
                  {
                    "sampleYVal": 188,
                    "sampleYDrawVal": 3205
                  },
                  {
                    "sampleYVal": 172,
                    "sampleYDrawVal": 2616
                  },
                  {
                    "sampleYVal": 177,
                    "sampleYDrawVal": 2783
                  },
                  {
                    "sampleYVal": 192,
                    "sampleYDrawVal": 3392
                  },
                  {
                    "sampleYVal": 183,
                    "sampleYDrawVal": 3002
                  },
                  {
                    "sampleYVal": 186,
                    "sampleYDrawVal": 3128
                  },
                  {
                    "sampleYVal": 193,
                    "sampleYDrawVal": 3433
                  },
                  {
                    "sampleYVal": 183,
                    "sampleYDrawVal": 3006
                  },
                  {
                    "sampleYVal": 175,
                    "sampleYDrawVal": 2687
                  },
                  {
                    "sampleYVal": 174,
                    "sampleYDrawVal": 2678
                  },
                  {
                    "sampleYVal": 177,
                    "sampleYDrawVal": 2778
                  },
                  {
                    "sampleYVal": 185,
                    "sampleYDrawVal": 3076
                  },
                  {
                    "sampleYVal": 130,
                    "sampleYDrawVal": 1327
                  },
                  {
                    "sampleYVal": 145,
                    "sampleYDrawVal": 1733
                  },
                  {
                    "sampleYVal": 140,
                    "sampleYDrawVal": 1588
                  },
                  {
                    "sampleYVal": 139,
                    "sampleYDrawVal": 1558
                  },
                  {
                    "sampleYVal": 137,
                    "sampleYDrawVal": 1507
                  },
                  {
                    "sampleYVal": 142,
                    "sampleYDrawVal": 1643
                  },
                  {
                    "sampleYVal": 134,
                    "sampleYDrawVal": 1436
                  },
                  {
                    "sampleYVal": 137,
                    "sampleYDrawVal": 1505
                  },
                  {
                    "sampleYVal": 137,
                    "sampleYDrawVal": 1513
                  },
                  {
                    "sampleYVal": 141,
                    "sampleYDrawVal": 1621
                  },
                  {
                    "sampleYVal": 141,
                    "sampleYDrawVal": 1620
                  },
                  {
                    "sampleYVal": 134,
                    "sampleYDrawVal": 1431
                  },
                  {
                    "sampleYVal": 133,
                    "sampleYDrawVal": 1398
                  },
                  {
                    "sampleYVal": 137,
                    "sampleYDrawVal": 1507
                  },
                  {
                    "sampleYVal": 139,
                    "sampleYDrawVal": 1538
                  },
                  {
                    "sampleYVal": 145,
                    "sampleYDrawVal": 1712
                  },
                  {
                    "sampleYVal": 138,
                    "sampleYDrawVal": 1537
                  },
                  {
                    "sampleYVal": 143,
                    "sampleYDrawVal": 1677
                  },
                  {
                    "sampleYVal": 134,
                    "sampleYDrawVal": 1417
                  },
                  {
                    "sampleYVal": 138,
                    "sampleYDrawVal": 1531
                  },
                  {
                    "sampleYVal": 142,
                    "sampleYDrawVal": 1640
                  },
                  {
                    "sampleYVal": 105,
                    "sampleYDrawVal": 834
                  },
                  {
                    "sampleYVal": 5,
                    "sampleYDrawVal": 1
                  }
                ],
                "recordOffset": 5822,
                "seqUrl": "1527723593306_20633_709",
                "seqDescription": {
                  "analysis": {
                    "analysisType": "breath"
                  },
                  "pbId": -1
                },
                "mistakesObjects": {},
                "hiddenMistakesObjects": {}
              },
              {
                "fieldType": "seq",
                "timerPos": 216,
                "calibrationEventId": "6581",
                "rate": 100,
                "deltaTimeMsFromPrevSeq": "3703.00",
                "yvalues": [
                  "151",
                  "186",
                  "195",
                  "196",
                  "197",
                  "196",
                  "196",
                  "194",
                  "194",
                  "189",
                  "190",
                  "190",
                  "194",
                  "199",
                  "200",
                  "185",
                  "166",
                  "160",
                  "162",
                  "163",
                  "165",
                  "162",
                  "161",
                  "153",
                  "152",
                  "152",
                  "151",
                  "153",
                  "151",
                  "154",
                  "154",
                  "153",
                  "154",
                  "161",
                  "159",
                  "157",
                  "155",
                  "158",
                  "165",
                  "161",
                  "126",
                  "46",
                  "0"
                ],
                "yDrawvalues": "3036,3655,3689,3586,3636,3592,3618,3484,3552,3207,3378,3353,3611,3789,3804,2962,2197,2125,2306,2334,2426,2222,2235,1900,1955,1979,1912,2032,1905,2066,2025,1978,2030,2344,2174,2085,2018,2202,2470,2196,1116,31,0",
                "mistakes": [],
                "hiddenMistakes": [],
                "softVals": [
                  "1",
                  "0",
                  "0",
                  "0",
                  "0",
                  "0",
                  "0",
                  "0",
                  "0",
                  "0",
                  "0",
                  "0",
                  "0",
                  "0",
                  "0",
                  "0",
                  "1",
                  "1",
                  "1",
                  "1",
                  "1",
                  "1",
                  "1",
                  "1",
                  "1",
                  "1",
                  "1",
                  "1",
                  "1",
                  "1",
                  "1",
                  "1",
                  "1",
                  "1",
                  "1",
                  "1",
                  "1",
                  "1",
                  "1",
                  "1",
                  "1",
                  "1",
                  "1"
                ],
                "expectedSylsPerSecond": "0.00",
                "actualSylsPerSecond": "0.52",
                "timeLength": 5375,
                "features": [
                  {
                    "sampleYVal": 201,
                    "sampleYDrawVal": 3811
                  },
                  {
                    "sampleYVal": 191,
                    "sampleYDrawVal": 3356
                  },
                  {
                    "sampleYVal": 194,
                    "sampleYDrawVal": 3484
                  },
                  {
                    "sampleYVal": 202,
                    "sampleYDrawVal": 3813
                  },
                  {
                    "sampleYVal": 198,
                    "sampleYDrawVal": 3651
                  },
                  {
                    "sampleYVal": 201,
                    "sampleYDrawVal": 3781
                  },
                  {
                    "sampleYVal": 199,
                    "sampleYDrawVal": 3704
                  },
                  {
                    "sampleYVal": 198,
                    "sampleYDrawVal": 3656
                  },
                  {
                    "sampleYVal": 193,
                    "sampleYDrawVal": 3437
                  },
                  {
                    "sampleYVal": 184,
                    "sampleYDrawVal": 3048
                  },
                  {
                    "sampleYVal": 195,
                    "sampleYDrawVal": 3543
                  },
                  {
                    "sampleYVal": 198,
                    "sampleYDrawVal": 3656
                  },
                  {
                    "sampleYVal": 206,
                    "sampleYDrawVal": 4037
                  },
                  {
                    "sampleYVal": 204,
                    "sampleYDrawVal": 3917
                  },
                  {
                    "sampleYVal": 207,
                    "sampleYDrawVal": 4067
                  },
                  {
                    "sampleYVal": 169,
                    "sampleYDrawVal": 2500
                  },
                  {
                    "sampleYVal": 170,
                    "sampleYDrawVal": 2519
                  },
                  {
                    "sampleYVal": 161,
                    "sampleYDrawVal": 2226
                  },
                  {
                    "sampleYVal": 167,
                    "sampleYDrawVal": 2435
                  },
                  {
                    "sampleYVal": 162,
                    "sampleYDrawVal": 2292
                  },
                  {
                    "sampleYVal": 171,
                    "sampleYDrawVal": 2573
                  },
                  {
                    "sampleYVal": 163,
                    "sampleYDrawVal": 2283
                  },
                  {
                    "sampleYVal": 160,
                    "sampleYDrawVal": 2180
                  },
                  {
                    "sampleYVal": 154,
                    "sampleYDrawVal": 1991
                  },
                  {
                    "sampleYVal": 154,
                    "sampleYDrawVal": 1984
                  },
                  {
                    "sampleYVal": 152,
                    "sampleYDrawVal": 1942
                  },
                  {
                    "sampleYVal": 153,
                    "sampleYDrawVal": 1962
                  },
                  {
                    "sampleYVal": 154,
                    "sampleYDrawVal": 2015
                  },
                  {
                    "sampleYVal": 156,
                    "sampleYDrawVal": 2073
                  },
                  {
                    "sampleYVal": 157,
                    "sampleYDrawVal": 2099
                  },
                  {
                    "sampleYVal": 158,
                    "sampleYDrawVal": 2129
                  },
                  {
                    "sampleYVal": 149,
                    "sampleYDrawVal": 1842
                  },
                  {
                    "sampleYVal": 157,
                    "sampleYDrawVal": 2101
                  },
                  {
                    "sampleYVal": 159,
                    "sampleYDrawVal": 2172
                  },
                  {
                    "sampleYVal": 156,
                    "sampleYDrawVal": 2064
                  },
                  {
                    "sampleYVal": 151,
                    "sampleYDrawVal": 1892
                  },
                  {
                    "sampleYVal": 157,
                    "sampleYDrawVal": 2114
                  },
                  {
                    "sampleYVal": 167,
                    "sampleYDrawVal": 2453
                  },
                  {
                    "sampleYVal": 164,
                    "sampleYDrawVal": 2324
                  },
                  {
                    "sampleYVal": 155,
                    "sampleYDrawVal": 2039
                  },
                  {
                    "sampleYVal": 71,
                    "sampleYDrawVal": 329
                  },
                  {
                    "sampleYVal": 16,
                    "sampleYDrawVal": 14
                  }
                ],
                "recordOffset": 15291,
                "seqUrl": "1527723593306_20633_709",
                "seqDescription": {
                  "analysis": {
                    "analysisType": "breath"
                  },
                  "pbId": -1
                },
                "mistakesObjects": {},
                "hiddenMistakesObjects": {}
              },
              {
                "fieldType": "seq",
                "timerPos": 226,
                "calibrationEventId": "6581",
                "rate": 100,
                "deltaTimeMsFromPrevSeq": "3704.00",
                "yvalues": [
                  "155",
                  "176",
                  "188",
                  "198",
                  "199",
                  "196",
                  "192",
                  "194",
                  "187",
                  "187",
                  "187",
                  "187",
                  "190",
                  "187",
                  "191",
                  "173",
                  "160",
                  "157",
                  "153",
                  "152",
                  "150",
                  "146",
                  "151",
                  "154",
                  "156",
                  "160",
                  "161",
                  "159",
                  "164",
                  "163",
                  "156",
                  "157",
                  "155",
                  "154",
                  "153",
                  "154",
                  "156",
                  "149",
                  "145",
                  "143",
                  "113",
                  "35",
                  "0"
                ],
                "yDrawvalues": "2988,3056,3427,3818,3754,3571,3385,3532,3104,3224,3231,3211,3394,3159,3414,2483,2052,2084,1975,1954,1879,1739,1968,2060,2097,2267,2248,2159,2382,2318,2006,2137,2049,2003,1992,2039,2077,1798,1709,1691,877,14,0",
                "mistakes": [],
                "hiddenMistakes": [],
                "softVals": [
                  "1",
                  "0",
                  "0",
                  "0",
                  "0",
                  "0",
                  "0",
                  "0",
                  "0",
                  "0",
                  "0",
                  "0",
                  "0",
                  "0",
                  "0",
                  "0",
                  "1",
                  "1",
                  "1",
                  "1",
                  "1",
                  "1",
                  "1",
                  "1",
                  "1",
                  "1",
                  "1",
                  "1",
                  "1",
                  "1",
                  "1",
                  "1",
                  "1",
                  "1",
                  "1",
                  "1",
                  "1",
                  "1",
                  "1",
                  "1",
                  "1",
                  "1",
                  "1"
                ],
                "expectedSylsPerSecond": "0.00",
                "actualSylsPerSecond": "0.52",
                "timeLength": 5375,
                "features": [
                  {
                    "sampleYVal": 171,
                    "sampleYDrawVal": 2580
                  },
                  {
                    "sampleYVal": 192,
                    "sampleYDrawVal": 3394
                  },
                  {
                    "sampleYVal": 193,
                    "sampleYDrawVal": 3452
                  },
                  {
                    "sampleYVal": 201,
                    "sampleYDrawVal": 3787
                  },
                  {
                    "sampleYVal": 200,
                    "sampleYDrawVal": 3740
                  },
                  {
                    "sampleYVal": 190,
                    "sampleYDrawVal": 3323
                  },
                  {
                    "sampleYVal": 188,
                    "sampleYDrawVal": 3246
                  },
                  {
                    "sampleYVal": 191,
                    "sampleYDrawVal": 3340
                  },
                  {
                    "sampleYVal": 192,
                    "sampleYDrawVal": 3399
                  },
                  {
                    "sampleYVal": 180,
                    "sampleYDrawVal": 2910
                  },
                  {
                    "sampleYVal": 184,
                    "sampleYDrawVal": 3080
                  },
                  {
                    "sampleYVal": 181,
                    "sampleYDrawVal": 2949
                  },
                  {
                    "sampleYVal": 183,
                    "sampleYDrawVal": 3016
                  },
                  {
                    "sampleYVal": 189,
                    "sampleYDrawVal": 3279
                  },
                  {
                    "sampleYVal": 191,
                    "sampleYDrawVal": 3325
                  },
                  {
                    "sampleYVal": 158,
                    "sampleYDrawVal": 2140
                  },
                  {
                    "sampleYVal": 154,
                    "sampleYDrawVal": 2007
                  },
                  {
                    "sampleYVal": 157,
                    "sampleYDrawVal": 2080
                  },
                  {
                    "sampleYVal": 147,
                    "sampleYDrawVal": 1796
                  },
                  {
                    "sampleYVal": 157,
                    "sampleYDrawVal": 2104
                  },
                  {
                    "sampleYVal": 143,
                    "sampleYDrawVal": 1684
                  },
                  {
                    "sampleYVal": 146,
                    "sampleYDrawVal": 1770
                  },
                  {
                    "sampleYVal": 161,
                    "sampleYDrawVal": 2217
                  },
                  {
                    "sampleYVal": 151,
                    "sampleYDrawVal": 1909
                  },
                  {
                    "sampleYVal": 161,
                    "sampleYDrawVal": 2209
                  },
                  {
                    "sampleYVal": 149,
                    "sampleYDrawVal": 1846
                  },
                  {
                    "sampleYVal": 162,
                    "sampleYDrawVal": 2258
                  },
                  {
                    "sampleYVal": 162,
                    "sampleYDrawVal": 2259
                  },
                  {
                    "sampleYVal": 161,
                    "sampleYDrawVal": 2203
                  },
                  {
                    "sampleYVal": 163,
                    "sampleYDrawVal": 2331
                  },
                  {
                    "sampleYVal": 147,
                    "sampleYDrawVal": 1800
                  },
                  {
                    "sampleYVal": 158,
                    "sampleYDrawVal": 2139
                  },
                  {
                    "sampleYVal": 146,
                    "sampleYDrawVal": 1754
                  },
                  {
                    "sampleYVal": 155,
                    "sampleYDrawVal": 2055
                  },
                  {
                    "sampleYVal": 162,
                    "sampleYDrawVal": 2243
                  },
                  {
                    "sampleYVal": 151,
                    "sampleYDrawVal": 1905
                  },
                  {
                    "sampleYVal": 154,
                    "sampleYDrawVal": 1990
                  },
                  {
                    "sampleYVal": 134,
                    "sampleYDrawVal": 1436
                  },
                  {
                    "sampleYVal": 145,
                    "sampleYDrawVal": 1722
                  },
                  {
                    "sampleYVal": 144,
                    "sampleYDrawVal": 1721
                  },
                  {
                    "sampleYVal": 61,
                    "sampleYDrawVal": 225
                  },
                  {
                    "sampleYVal": 0,
                    "sampleYDrawVal": 0
                  }
                ],
                "recordOffset": -5233,
                "seqUrl": "1527723623307_20633_709",
                "seqDescription": {
                  "analysis": {
                    "analysisType": "breath"
                  },
                  "pbId": -1
                },
                "mistakesObjects": {},
                "hiddenMistakesObjects": {}
              },
              {
                "fieldType": "seq",
                "timerPos": 236,
                "calibrationEventId": "6581",
                "rate": 100,
                "deltaTimeMsFromPrevSeq": "3820.00",
                "yvalues": [
                  "132",
                  "169",
                  "184",
                  "186",
                  "187",
                  "182",
                  "190",
                  "190",
                  "194",
                  "190",
                  "190",
                  "193",
                  "190",
                  "193",
                  "189",
                  "177",
                  "156",
                  "152",
                  "149",
                  "154",
                  "153",
                  "156",
                  "150",
                  "159",
                  "157",
                  "156",
                  "156",
                  "159",
                  "158",
                  "158",
                  "154",
                  "153",
                  "149",
                  "145",
                  "147",
                  "144",
                  "149",
                  "148",
                  "150",
                  "146",
                  "146",
                  "148",
                  "142",
                  "97",
                  "36",
                  "0"
                ],
                "yDrawvalues": "2450,2991,3326,3213,3263,2945,3476,3383,3575,3286,3332,3488,3326,3525,3274,2707,1882,1963,1817,2074,1970,2146,1857,2302,2109,2078,2093,2210,2152,2144,1991,2004,1854,1694,1845,1719,1901,1813,1924,1729,1762,1871,1613,543,23,0",
                "mistakes": [],
                "hiddenMistakes": [],
                "softVals": [
                  "1",
                  "1",
                  "0",
                  "0",
                  "0",
                  "0",
                  "0",
                  "0",
                  "0",
                  "0",
                  "0",
                  "0",
                  "0",
                  "0",
                  "0",
                  "0",
                  "1",
                  "1",
                  "1",
                  "1",
                  "1",
                  "1",
                  "1",
                  "1",
                  "1",
                  "1",
                  "1",
                  "1",
                  "1",
                  "1",
                  "1",
                  "1",
                  "1",
                  "1",
                  "1",
                  "1",
                  "1",
                  "1",
                  "1",
                  "1",
                  "1",
                  "1",
                  "1",
                  "1",
                  "1",
                  "1"
                ],
                "expectedSylsPerSecond": "0.00",
                "actualSylsPerSecond": "0.49",
                "timeLength": 5750,
                "features": [
                  {
                    "sampleYVal": 186,
                    "sampleYDrawVal": 3135
                  },
                  {
                    "sampleYVal": 185,
                    "sampleYDrawVal": 3110
                  },
                  {
                    "sampleYVal": 185,
                    "sampleYDrawVal": 3114
                  },
                  {
                    "sampleYVal": 191,
                    "sampleYDrawVal": 3349
                  },
                  {
                    "sampleYVal": 176,
                    "sampleYDrawVal": 2794
                  },
                  {
                    "sampleYVal": 181,
                    "sampleYDrawVal": 2953
                  },
                  {
                    "sampleYVal": 192,
                    "sampleYDrawVal": 3417
                  },
                  {
                    "sampleYVal": 186,
                    "sampleYDrawVal": 3139
                  },
                  {
                    "sampleYVal": 208,
                    "sampleYDrawVal": 4146
                  },
                  {
                    "sampleYVal": 175,
                    "sampleYDrawVal": 2737
                  },
                  {
                    "sampleYVal": 194,
                    "sampleYDrawVal": 3476
                  },
                  {
                    "sampleYVal": 195,
                    "sampleYDrawVal": 3532
                  },
                  {
                    "sampleYVal": 192,
                    "sampleYDrawVal": 3402
                  },
                  {
                    "sampleYVal": 207,
                    "sampleYDrawVal": 4067
                  },
                  {
                    "sampleYVal": 187,
                    "sampleYDrawVal": 3201
                  },
                  {
                    "sampleYVal": 165,
                    "sampleYDrawVal": 2367
                  },
                  {
                    "sampleYVal": 141,
                    "sampleYDrawVal": 1629
                  },
                  {
                    "sampleYVal": 154,
                    "sampleYDrawVal": 2022
                  },
                  {
                    "sampleYVal": 147,
                    "sampleYDrawVal": 1796
                  },
                  {
                    "sampleYVal": 152,
                    "sampleYDrawVal": 1937
                  },
                  {
                    "sampleYVal": 155,
                    "sampleYDrawVal": 2029
                  },
                  {
                    "sampleYVal": 150,
                    "sampleYDrawVal": 1895
                  },
                  {
                    "sampleYVal": 149,
                    "sampleYDrawVal": 1845
                  },
                  {
                    "sampleYVal": 159,
                    "sampleYDrawVal": 2167
                  },
                  {
                    "sampleYVal": 158,
                    "sampleYDrawVal": 2144
                  },
                  {
                    "sampleYVal": 165,
                    "sampleYDrawVal": 2363
                  },
                  {
                    "sampleYVal": 156,
                    "sampleYDrawVal": 2080
                  },
                  {
                    "sampleYVal": 151,
                    "sampleYDrawVal": 1902
                  },
                  {
                    "sampleYVal": 154,
                    "sampleYDrawVal": 1975
                  },
                  {
                    "sampleYVal": 153,
                    "sampleYDrawVal": 1972
                  },
                  {
                    "sampleYVal": 154,
                    "sampleYDrawVal": 2005
                  },
                  {
                    "sampleYVal": 159,
                    "sampleYDrawVal": 2169
                  },
                  {
                    "sampleYVal": 138,
                    "sampleYDrawVal": 1545
                  },
                  {
                    "sampleYVal": 144,
                    "sampleYDrawVal": 1693
                  },
                  {
                    "sampleYVal": 156,
                    "sampleYDrawVal": 2066
                  },
                  {
                    "sampleYVal": 141,
                    "sampleYDrawVal": 1618
                  },
                  {
                    "sampleYVal": 142,
                    "sampleYDrawVal": 1652
                  },
                  {
                    "sampleYVal": 162,
                    "sampleYDrawVal": 2238
                  },
                  {
                    "sampleYVal": 158,
                    "sampleYDrawVal": 2143
                  },
                  {
                    "sampleYVal": 142,
                    "sampleYDrawVal": 1647
                  },
                  {
                    "sampleYVal": 149,
                    "sampleYDrawVal": 1844
                  },
                  {
                    "sampleYVal": 152,
                    "sampleYDrawVal": 1941
                  },
                  {
                    "sampleYVal": 135,
                    "sampleYDrawVal": 1471
                  },
                  {
                    "sampleYVal": 38,
                    "sampleYDrawVal": 85
                  },
                  {
                    "sampleYVal": 8,
                    "sampleYDrawVal": 13
                  }
                ],
                "recordOffset": 4347,
                "seqUrl": "1527723623307_20633_709",
                "seqDescription": {
                  "analysis": {
                    "analysisType": "breath"
                  },
                  "pbId": -1
                },
                "mistakesObjects": {},
                "hiddenMistakesObjects": {}
              },
              {
                "fieldType": "seq",
                "timerPos": 245,
                "calibrationEventId": "6581",
                "rate": 100,
                "deltaTimeMsFromPrevSeq": "3587.00",
                "yvalues": [
                  "101",
                  "160",
                  "184",
                  "182",
                  "192",
                  "192",
                  "197",
                  "194",
                  "197",
                  "190",
                  "187",
                  "186",
                  "189",
                  "191",
                  "188",
                  "186",
                  "186",
                  "166",
                  "152",
                  "152",
                  "153",
                  "149",
                  "151",
                  "156",
                  "155",
                  "150",
                  "150",
                  "151",
                  "152",
                  "152",
                  "155",
                  "155",
                  "158",
                  "166",
                  "164",
                  "163",
                  "167",
                  "167",
                  "165",
                  "165",
                  "164",
                  "137",
                  "53",
                  "0"
                ],
                "yDrawvalues": "1213,2922,3404,3010,3582,3425,3713,3447,3709,3224,3174,3194,3330,3409,3234,3150,3221,2211,1825,1949,1997,1856,1959,2164,2044,1854,1919,1949,1982,1981,2068,2068,2191,2515,2336,2292,2509,2442,2358,2377,2335,1403,50,0",
                "mistakes": [],
                "hiddenMistakes": [],
                "softVals": [
                  "1",
                  "1",
                  "0",
                  "0",
                  "0",
                  "0",
                  "0",
                  "0",
                  "0",
                  "0",
                  "0",
                  "0",
                  "0",
                  "0",
                  "0",
                  "0",
                  "0",
                  "1",
                  "1",
                  "1",
                  "1",
                  "1",
                  "1",
                  "1",
                  "1",
                  "1",
                  "1",
                  "1",
                  "1",
                  "1",
                  "1",
                  "1",
                  "1",
                  "1",
                  "1",
                  "1",
                  "1",
                  "1",
                  "1",
                  "1",
                  "1",
                  "1",
                  "1",
                  "1"
                ],
                "expectedSylsPerSecond": "0.00",
                "actualSylsPerSecond": "0.68",
                "timeLength": 5500,
                "features": [
                  {
                    "sampleYVal": 139,
                    "sampleYDrawVal": 1608
                  },
                  {
                    "sampleYVal": 193,
                    "sampleYDrawVal": 3429
                  },
                  {
                    "sampleYVal": 200,
                    "sampleYDrawVal": 3744
                  },
                  {
                    "sampleYVal": 184,
                    "sampleYDrawVal": 3060
                  },
                  {
                    "sampleYVal": 197,
                    "sampleYDrawVal": 3639
                  },
                  {
                    "sampleYVal": 197,
                    "sampleYDrawVal": 3587
                  },
                  {
                    "sampleYVal": 201,
                    "sampleYDrawVal": 3768
                  },
                  {
                    "sampleYVal": 188,
                    "sampleYDrawVal": 3212
                  },
                  {
                    "sampleYVal": 205,
                    "sampleYDrawVal": 3979
                  },
                  {
                    "sampleYVal": 179,
                    "sampleYDrawVal": 2868
                  },
                  {
                    "sampleYVal": 199,
                    "sampleYDrawVal": 3707
                  },
                  {
                    "sampleYVal": 190,
                    "sampleYDrawVal": 3301
                  },
                  {
                    "sampleYVal": 186,
                    "sampleYDrawVal": 3153
                  },
                  {
                    "sampleYVal": 197,
                    "sampleYDrawVal": 3617
                  },
                  {
                    "sampleYVal": 196,
                    "sampleYDrawVal": 3562
                  },
                  {
                    "sampleYVal": 188,
                    "sampleYDrawVal": 3250
                  },
                  {
                    "sampleYVal": 176,
                    "sampleYDrawVal": 2783
                  },
                  {
                    "sampleYVal": 158,
                    "sampleYDrawVal": 2130
                  },
                  {
                    "sampleYVal": 141,
                    "sampleYDrawVal": 1615
                  },
                  {
                    "sampleYVal": 155,
                    "sampleYDrawVal": 2040
                  },
                  {
                    "sampleYVal": 152,
                    "sampleYDrawVal": 1974
                  },
                  {
                    "sampleYVal": 149,
                    "sampleYDrawVal": 1855
                  },
                  {
                    "sampleYVal": 158,
                    "sampleYDrawVal": 2161
                  },
                  {
                    "sampleYVal": 159,
                    "sampleYDrawVal": 2150
                  },
                  {
                    "sampleYVal": 147,
                    "sampleYDrawVal": 1817
                  },
                  {
                    "sampleYVal": 154,
                    "sampleYDrawVal": 2022
                  },
                  {
                    "sampleYVal": 152,
                    "sampleYDrawVal": 1942
                  },
                  {
                    "sampleYVal": 151,
                    "sampleYDrawVal": 1932
                  },
                  {
                    "sampleYVal": 157,
                    "sampleYDrawVal": 2113
                  },
                  {
                    "sampleYVal": 162,
                    "sampleYDrawVal": 2270
                  },
                  {
                    "sampleYVal": 156,
                    "sampleYDrawVal": 2043
                  },
                  {
                    "sampleYVal": 160,
                    "sampleYDrawVal": 2189
                  },
                  {
                    "sampleYVal": 176,
                    "sampleYDrawVal": 2758
                  },
                  {
                    "sampleYVal": 164,
                    "sampleYDrawVal": 2333
                  },
                  {
                    "sampleYVal": 178,
                    "sampleYDrawVal": 2849
                  },
                  {
                    "sampleYVal": 157,
                    "sampleYDrawVal": 2096
                  },
                  {
                    "sampleYVal": 170,
                    "sampleYDrawVal": 2554
                  },
                  {
                    "sampleYVal": 159,
                    "sampleYDrawVal": 2152
                  },
                  {
                    "sampleYVal": 166,
                    "sampleYDrawVal": 2395
                  },
                  {
                    "sampleYVal": 168,
                    "sampleYDrawVal": 2478
                  },
                  {
                    "sampleYVal": 157,
                    "sampleYDrawVal": 2109
                  },
                  {
                    "sampleYVal": 82,
                    "sampleYDrawVal": 464
                  },
                  {
                    "sampleYVal": 12,
                    "sampleYDrawVal": 7
                  }
                ],
                "recordOffset": 14066,
                "seqUrl": "1527723623307_20633_709",
                "seqDescription": {
                  "analysis": {
                    "analysisType": "breath"
                  },
                  "pbId": -1
                },
                "mistakesObjects": {},
                "hiddenMistakesObjects": {}
              },
              {
                "fieldType": "seq",
                "timerPos": 254,
                "calibrationEventId": "6581",
                "rate": 100,
                "deltaTimeMsFromPrevSeq": "3591.00",
                "yvalues": [
                  "151",
                  "194",
                  "201",
                  "207",
                  "206",
                  "203",
                  "205",
                  "204",
                  "202",
                  "199",
                  "200",
                  "200",
                  "200",
                  "204",
                  "207",
                  "199",
                  "177",
                  "173",
                  "167",
                  "165",
                  "166",
                  "167",
                  "163",
                  "157",
                  "162",
                  "163",
                  "166",
                  "166",
                  "166",
                  "168",
                  "169",
                  "164",
                  "163",
                  "167",
                  "169",
                  "159",
                  "162",
                  "165",
                  "168",
                  "168",
                  "139",
                  "59",
                  "0"
                ],
                "yDrawvalues": "3274,4159,3963,4212,4038,3877,4070,3926,3852,3705,3797,3750,3779,4029,4126,3613,2528,2614,2396,2385,2428,2491,2267,2060,2333,2333,2445,2424,2409,2519,2560,2299,2306,2489,2555,2075,2300,2401,2501,2479,1388,103,0",
                "mistakes": [],
                "hiddenMistakes": [],
                "softVals": [
                  "1",
                  "0",
                  "0",
                  "0",
                  "0",
                  "0",
                  "0",
                  "0",
                  "0",
                  "0",
                  "0",
                  "0",
                  "0",
                  "0",
                  "0",
                  "0",
                  "0",
                  "0",
                  "1",
                  "1",
                  "1",
                  "1",
                  "1",
                  "1",
                  "1",
                  "1",
                  "1",
                  "1",
                  "1",
                  "1",
                  "1",
                  "1",
                  "1",
                  "1",
                  "1",
                  "1",
                  "1",
                  "1",
                  "1",
                  "1",
                  "1",
                  "1",
                  "1"
                ],
                "expectedSylsPerSecond": "0.00",
                "actualSylsPerSecond": "0.53",
                "timeLength": 5375,
                "features": [
                  {
                    "sampleYVal": 195,
                    "sampleYDrawVal": 3541
                  },
                  {
                    "sampleYVal": 199,
                    "sampleYDrawVal": 3694
                  },
                  {
                    "sampleYVal": 207,
                    "sampleYDrawVal": 4061
                  },
                  {
                    "sampleYVal": 213,
                    "sampleYDrawVal": 4361
                  },
                  {
                    "sampleYVal": 201,
                    "sampleYDrawVal": 3805
                  },
                  {
                    "sampleYVal": 197,
                    "sampleYDrawVal": 3642
                  },
                  {
                    "sampleYVal": 200,
                    "sampleYDrawVal": 3746
                  },
                  {
                    "sampleYVal": 209,
                    "sampleYDrawVal": 4172
                  },
                  {
                    "sampleYVal": 199,
                    "sampleYDrawVal": 3693
                  },
                  {
                    "sampleYVal": 209,
                    "sampleYDrawVal": 4169
                  },
                  {
                    "sampleYVal": 205,
                    "sampleYDrawVal": 3978
                  },
                  {
                    "sampleYVal": 199,
                    "sampleYDrawVal": 3708
                  },
                  {
                    "sampleYVal": 218,
                    "sampleYDrawVal": 4606
                  },
                  {
                    "sampleYVal": 206,
                    "sampleYDrawVal": 4031
                  },
                  {
                    "sampleYVal": 217,
                    "sampleYDrawVal": 4574
                  },
                  {
                    "sampleYVal": 179,
                    "sampleYDrawVal": 2862
                  },
                  {
                    "sampleYVal": 162,
                    "sampleYDrawVal": 2282
                  },
                  {
                    "sampleYVal": 180,
                    "sampleYDrawVal": 2914
                  },
                  {
                    "sampleYVal": 166,
                    "sampleYDrawVal": 2410
                  },
                  {
                    "sampleYVal": 163,
                    "sampleYDrawVal": 2287
                  },
                  {
                    "sampleYVal": 168,
                    "sampleYDrawVal": 2456
                  },
                  {
                    "sampleYVal": 166,
                    "sampleYDrawVal": 2401
                  },
                  {
                    "sampleYVal": 172,
                    "sampleYDrawVal": 2597
                  },
                  {
                    "sampleYVal": 165,
                    "sampleYDrawVal": 2348
                  },
                  {
                    "sampleYVal": 164,
                    "sampleYDrawVal": 2311
                  },
                  {
                    "sampleYVal": 174,
                    "sampleYDrawVal": 2682
                  },
                  {
                    "sampleYVal": 161,
                    "sampleYDrawVal": 2230
                  },
                  {
                    "sampleYVal": 175,
                    "sampleYDrawVal": 2726
                  },
                  {
                    "sampleYVal": 169,
                    "sampleYDrawVal": 2479
                  },
                  {
                    "sampleYVal": 171,
                    "sampleYDrawVal": 2584
                  },
                  {
                    "sampleYVal": 167,
                    "sampleYDrawVal": 2428
                  },
                  {
                    "sampleYVal": 166,
                    "sampleYDrawVal": 2395
                  },
                  {
                    "sampleYVal": 171,
                    "sampleYDrawVal": 2565
                  },
                  {
                    "sampleYVal": 174,
                    "sampleYDrawVal": 2694
                  },
                  {
                    "sampleYVal": 167,
                    "sampleYDrawVal": 2422
                  },
                  {
                    "sampleYVal": 154,
                    "sampleYDrawVal": 2005
                  },
                  {
                    "sampleYVal": 163,
                    "sampleYDrawVal": 2276
                  },
                  {
                    "sampleYVal": 172,
                    "sampleYDrawVal": 2615
                  },
                  {
                    "sampleYVal": 170,
                    "sampleYDrawVal": 2534
                  },
                  {
                    "sampleYVal": 157,
                    "sampleYDrawVal": 2091
                  },
                  {
                    "sampleYVal": 100,
                    "sampleYDrawVal": 728
                  },
                  {
                    "sampleYVal": 8,
                    "sampleYDrawVal": 6
                  }
                ],
                "recordOffset": 23461,
                "seqUrl": "1527723623307_20633_709",
                "seqDescription": {
                  "analysis": {
                    "analysisType": "breath"
                  },
                  "pbId": -1
                },
                "mistakesObjects": {},
                "hiddenMistakesObjects": {}
              },
              {
                "fieldType": "seq",
                "timerPos": 263,
                "calibrationEventId": "6581",
                "rate": 100,
                "deltaTimeMsFromPrevSeq": "3580.00",
                "yvalues": [
                  "135",
                  "140",
                  "148",
                  "166",
                  "179",
                  "185",
                  "185",
                  "180",
                  "184",
                  "180",
                  "172",
                  "173",
                  "175",
                  "182",
                  "184",
                  "169",
                  "156",
                  "155",
                  "157",
                  "155",
                  "153",
                  "154",
                  "155",
                  "155",
                  "156",
                  "148",
                  "143",
                  "142",
                  "139",
                  "141",
                  "137",
                  "141",
                  "135",
                  "137",
                  "138",
                  "145",
                  "139",
                  "141",
                  "140",
                  "99",
                  "30",
                  "0"
                ],
                "yDrawvalues": "2021,1678,1959,2631,3049,3214,3159,2849,3167,2874,2527,2677,2761,3126,3104,2343,1962,2036,2148,2021,1993,2044,2076,2055,2079,1783,1659,1671,1567,1666,1472,1695,1446,1544,1590,1819,1520,1669,1602,651,5,0",
                "mistakes": [],
                "hiddenMistakes": [],
                "softVals": [
                  "1",
                  "1",
                  "1",
                  "1",
                  "0",
                  "0",
                  "0",
                  "0",
                  "0",
                  "0",
                  "0",
                  "0",
                  "0",
                  "0",
                  "0",
                  "1",
                  "1",
                  "1",
                  "1",
                  "1",
                  "1",
                  "1",
                  "1",
                  "1",
                  "1",
                  "1",
                  "1",
                  "1",
                  "1",
                  "1",
                  "1",
                  "1",
                  "1",
                  "1",
                  "1",
                  "1",
                  "1",
                  "1",
                  "1",
                  "1",
                  "1",
                  "1"
                ],
                "expectedSylsPerSecond": "0.00",
                "actualSylsPerSecond": "0.71",
                "timeLength": 5250,
                "features": [
                  {
                    "sampleYVal": 130,
                    "sampleYDrawVal": 1335
                  },
                  {
                    "sampleYVal": 129,
                    "sampleYDrawVal": 1328
                  },
                  {
                    "sampleYVal": 166,
                    "sampleYDrawVal": 2415
                  },
                  {
                    "sampleYVal": 166,
                    "sampleYDrawVal": 2410
                  },
                  {
                    "sampleYVal": 181,
                    "sampleYDrawVal": 2957
                  },
                  {
                    "sampleYVal": 185,
                    "sampleYDrawVal": 3092
                  },
                  {
                    "sampleYVal": 191,
                    "sampleYDrawVal": 3353
                  },
                  {
                    "sampleYVal": 181,
                    "sampleYDrawVal": 2943
                  },
                  {
                    "sampleYVal": 185,
                    "sampleYDrawVal": 3095
                  },
                  {
                    "sampleYVal": 186,
                    "sampleYDrawVal": 3127
                  },
                  {
                    "sampleYVal": 163,
                    "sampleYDrawVal": 2288
                  },
                  {
                    "sampleYVal": 182,
                    "sampleYDrawVal": 2986
                  },
                  {
                    "sampleYVal": 177,
                    "sampleYDrawVal": 2772
                  },
                  {
                    "sampleYVal": 186,
                    "sampleYDrawVal": 3166
                  },
                  {
                    "sampleYVal": 180,
                    "sampleYDrawVal": 2884
                  },
                  {
                    "sampleYVal": 159,
                    "sampleYDrawVal": 2202
                  },
                  {
                    "sampleYVal": 159,
                    "sampleYDrawVal": 2170
                  },
                  {
                    "sampleYVal": 150,
                    "sampleYDrawVal": 1870
                  },
                  {
                    "sampleYVal": 158,
                    "sampleYDrawVal": 2120
                  },
                  {
                    "sampleYVal": 153,
                    "sampleYDrawVal": 1979
                  },
                  {
                    "sampleYVal": 160,
                    "sampleYDrawVal": 2203
                  },
                  {
                    "sampleYVal": 152,
                    "sampleYDrawVal": 1952
                  },
                  {
                    "sampleYVal": 155,
                    "sampleYDrawVal": 2037
                  },
                  {
                    "sampleYVal": 161,
                    "sampleYDrawVal": 2242
                  },
                  {
                    "sampleYVal": 156,
                    "sampleYDrawVal": 2058
                  },
                  {
                    "sampleYVal": 140,
                    "sampleYDrawVal": 1586
                  },
                  {
                    "sampleYVal": 145,
                    "sampleYDrawVal": 1723
                  },
                  {
                    "sampleYVal": 139,
                    "sampleYDrawVal": 1586
                  },
                  {
                    "sampleYVal": 139,
                    "sampleYDrawVal": 1585
                  },
                  {
                    "sampleYVal": 141,
                    "sampleYDrawVal": 1659
                  },
                  {
                    "sampleYVal": 139,
                    "sampleYDrawVal": 1557
                  },
                  {
                    "sampleYVal": 131,
                    "sampleYDrawVal": 1393
                  },
                  {
                    "sampleYVal": 133,
                    "sampleYDrawVal": 1411
                  },
                  {
                    "sampleYVal": 148,
                    "sampleYDrawVal": 1851
                  },
                  {
                    "sampleYVal": 138,
                    "sampleYDrawVal": 1558
                  },
                  {
                    "sampleYVal": 143,
                    "sampleYDrawVal": 1683
                  },
                  {
                    "sampleYVal": 144,
                    "sampleYDrawVal": 1690
                  },
                  {
                    "sampleYVal": 145,
                    "sampleYDrawVal": 1734
                  },
                  {
                    "sampleYVal": 139,
                    "sampleYDrawVal": 1588
                  },
                  {
                    "sampleYVal": 30,
                    "sampleYDrawVal": 42
                  },
                  {
                    "sampleYVal": 8,
                    "sampleYDrawVal": 4
                  }
                ],
                "recordOffset": 2785,
                "seqUrl": "1527723653307_20633_709",
                "seqDescription": {
                  "analysis": {
                    "analysisType": "breath"
                  },
                  "pbId": -1
                },
                "mistakesObjects": {},
                "hiddenMistakesObjects": {}
              },
              {
                "fieldType": "seq",
                "timerPos": 273,
                "calibrationEventId": "6581",
                "rate": 100,
                "deltaTimeMsFromPrevSeq": "3461.00",
                "yvalues": [
                  "91",
                  "152",
                  "174",
                  "184",
                  "188",
                  "194",
                  "197",
                  "198",
                  "197",
                  "200",
                  "201",
                  "203",
                  "205",
                  "207",
                  "209",
                  "195",
                  "170",
                  "163",
                  "162",
                  "162",
                  "151",
                  "153",
                  "154",
                  "159",
                  "159",
                  "160",
                  "160",
                  "158",
                  "155",
                  "154",
                  "155",
                  "153",
                  "143",
                  "144",
                  "141",
                  "147",
                  "147",
                  "158",
                  "152",
                  "152",
                  "131",
                  "53",
                  "0"
                ],
                "yDrawvalues": "1394,2625,2968,3206,3330,3563,3669,3708,3600,3793,3818,3938,4017,4133,4216,3366,2257,2260,2276,2291,1838,2008,2030,2236,2156,2205,2209,2115,2025,2008,2064,1989,1598,1741,1615,1848,1830,2259,1899,1975,1278,61,0",
                "mistakes": [],
                "hiddenMistakes": [],
                "softVals": [
                  "1",
                  "1",
                  "0",
                  "0",
                  "0",
                  "0",
                  "0",
                  "0",
                  "0",
                  "0",
                  "0",
                  "0",
                  "0",
                  "0",
                  "0",
                  "0",
                  "1",
                  "1",
                  "1",
                  "1",
                  "1",
                  "1",
                  "1",
                  "1",
                  "1",
                  "1",
                  "1",
                  "1",
                  "1",
                  "1",
                  "1",
                  "1",
                  "1",
                  "1",
                  "1",
                  "1",
                  "1",
                  "1",
                  "1",
                  "1",
                  "1",
                  "1",
                  "1"
                ],
                "expectedSylsPerSecond": "0.00",
                "actualSylsPerSecond": "0.52",
                "timeLength": 5375,
                "features": [
                  {
                    "sampleYVal": 163,
                    "sampleYDrawVal": 2287
                  },
                  {
                    "sampleYVal": 177,
                    "sampleYDrawVal": 2794
                  },
                  {
                    "sampleYVal": 189,
                    "sampleYDrawVal": 3266
                  },
                  {
                    "sampleYVal": 194,
                    "sampleYDrawVal": 3505
                  },
                  {
                    "sampleYVal": 194,
                    "sampleYDrawVal": 3492
                  },
                  {
                    "sampleYVal": 193,
                    "sampleYDrawVal": 3437
                  },
                  {
                    "sampleYVal": 203,
                    "sampleYDrawVal": 3866
                  },
                  {
                    "sampleYVal": 195,
                    "sampleYDrawVal": 3523
                  },
                  {
                    "sampleYVal": 201,
                    "sampleYDrawVal": 3767
                  },
                  {
                    "sampleYVal": 202,
                    "sampleYDrawVal": 3833
                  },
                  {
                    "sampleYVal": 199,
                    "sampleYDrawVal": 3695
                  },
                  {
                    "sampleYVal": 199,
                    "sampleYDrawVal": 3715
                  },
                  {
                    "sampleYVal": 205,
                    "sampleYDrawVal": 3970
                  },
                  {
                    "sampleYVal": 215,
                    "sampleYDrawVal": 4477
                  },
                  {
                    "sampleYVal": 212,
                    "sampleYDrawVal": 4311
                  },
                  {
                    "sampleYVal": 163,
                    "sampleYDrawVal": 2281
                  },
                  {
                    "sampleYVal": 156,
                    "sampleYDrawVal": 2063
                  },
                  {
                    "sampleYVal": 150,
                    "sampleYDrawVal": 1879
                  },
                  {
                    "sampleYVal": 169,
                    "sampleYDrawVal": 2492
                  },
                  {
                    "sampleYVal": 159,
                    "sampleYDrawVal": 2159
                  },
                  {
                    "sampleYVal": 145,
                    "sampleYDrawVal": 1732
                  },
                  {
                    "sampleYVal": 148,
                    "sampleYDrawVal": 1804
                  },
                  {
                    "sampleYVal": 161,
                    "sampleYDrawVal": 2222
                  },
                  {
                    "sampleYVal": 171,
                    "sampleYDrawVal": 2584
                  },
                  {
                    "sampleYVal": 162,
                    "sampleYDrawVal": 2248
                  },
                  {
                    "sampleYVal": 163,
                    "sampleYDrawVal": 2296
                  },
                  {
                    "sampleYVal": 161,
                    "sampleYDrawVal": 2224
                  },
                  {
                    "sampleYVal": 152,
                    "sampleYDrawVal": 1944
                  },
                  {
                    "sampleYVal": 166,
                    "sampleYDrawVal": 2416
                  },
                  {
                    "sampleYVal": 159,
                    "sampleYDrawVal": 2157
                  },
                  {
                    "sampleYVal": 152,
                    "sampleYDrawVal": 1978
                  },
                  {
                    "sampleYVal": 146,
                    "sampleYDrawVal": 1743
                  },
                  {
                    "sampleYVal": 141,
                    "sampleYDrawVal": 1620
                  },
                  {
                    "sampleYVal": 145,
                    "sampleYDrawVal": 1724
                  },
                  {
                    "sampleYVal": 137,
                    "sampleYDrawVal": 1517
                  },
                  {
                    "sampleYVal": 151,
                    "sampleYDrawVal": 1900
                  },
                  {
                    "sampleYVal": 148,
                    "sampleYDrawVal": 1858
                  },
                  {
                    "sampleYVal": 156,
                    "sampleYDrawVal": 2061
                  },
                  {
                    "sampleYVal": 159,
                    "sampleYDrawVal": 2168
                  },
                  {
                    "sampleYVal": 153,
                    "sampleYDrawVal": 1965
                  },
                  {
                    "sampleYVal": 86,
                    "sampleYDrawVal": 498
                  },
                  {
                    "sampleYVal": 7,
                    "sampleYDrawVal": 7
                  }
                ],
                "recordOffset": 11881,
                "seqUrl": "1527723653307_20633_709",
                "seqDescription": {
                  "analysis": {
                    "analysisType": "breath"
                  },
                  "pbId": -1
                },
                "mistakesObjects": {},
                "hiddenMistakesObjects": {}
              },
              {
                "fieldType": "seq",
                "timerPos": 283,
                "calibrationEventId": "6581",
                "rate": 100,
                "deltaTimeMsFromPrevSeq": "3720.00",
                "yvalues": [
                  "145",
                  "175",
                  "183",
                  "180",
                  "181",
                  "184",
                  "184",
                  "184",
                  "184",
                  "184",
                  "182",
                  "181",
                  "185",
                  "185",
                  "183",
                  "158",
                  "149",
                  "147",
                  "148",
                  "145",
                  "144",
                  "143",
                  "144",
                  "148",
                  "152",
                  "152",
                  "152",
                  "158",
                  "157",
                  "159",
                  "157",
                  "156",
                  "158",
                  "158",
                  "166",
                  "165",
                  "159",
                  "154",
                  "149",
                  "146",
                  "143",
                  "149",
                  "145",
                  "91",
                  "27",
                  "0"
                ],
                "yDrawvalues": "2874,3122,3158,2900,3006,3111,3078,3107,3101,3082,3008,2945,3183,3133,3014,1879,1779,1780,1861,1717,1707,1682,1719,1872,2011,1944,1956,2220,2102,2202,2069,2077,2151,2162,2529,2387,2111,1978,1823,1750,1657,1926,1705,480,4,0",
                "mistakes": [],
                "hiddenMistakes": [],
                "softVals": [
                  "1",
                  "0",
                  "0",
                  "0",
                  "0",
                  "0",
                  "0",
                  "0",
                  "0",
                  "0",
                  "0",
                  "0",
                  "0",
                  "0",
                  "0",
                  "1",
                  "1",
                  "1",
                  "1",
                  "1",
                  "1",
                  "1",
                  "1",
                  "1",
                  "1",
                  "1",
                  "1",
                  "1",
                  "1",
                  "1",
                  "1",
                  "1",
                  "1",
                  "1",
                  "1",
                  "1",
                  "1",
                  "1",
                  "1",
                  "1",
                  "1",
                  "1",
                  "1",
                  "1",
                  "1",
                  "1"
                ],
                "expectedSylsPerSecond": "0.00",
                "actualSylsPerSecond": "0.81",
                "timeLength": 5750,
                "features": [
                  {
                    "sampleYVal": 192,
                    "sampleYDrawVal": 3418
                  },
                  {
                    "sampleYVal": 183,
                    "sampleYDrawVal": 3009
                  },
                  {
                    "sampleYVal": 178,
                    "sampleYDrawVal": 2841
                  },
                  {
                    "sampleYVal": 178,
                    "sampleYDrawVal": 2829
                  },
                  {
                    "sampleYVal": 192,
                    "sampleYDrawVal": 3381
                  },
                  {
                    "sampleYVal": 179,
                    "sampleYDrawVal": 2863
                  },
                  {
                    "sampleYVal": 182,
                    "sampleYDrawVal": 2987
                  },
                  {
                    "sampleYVal": 189,
                    "sampleYDrawVal": 3284
                  },
                  {
                    "sampleYVal": 185,
                    "sampleYDrawVal": 3113
                  },
                  {
                    "sampleYVal": 183,
                    "sampleYDrawVal": 2998
                  },
                  {
                    "sampleYVal": 185,
                    "sampleYDrawVal": 3114
                  },
                  {
                    "sampleYVal": 184,
                    "sampleYDrawVal": 3058
                  },
                  {
                    "sampleYVal": 185,
                    "sampleYDrawVal": 3080
                  },
                  {
                    "sampleYVal": 178,
                    "sampleYDrawVal": 2833
                  },
                  {
                    "sampleYVal": 173,
                    "sampleYDrawVal": 2655
                  },
                  {
                    "sampleYVal": 149,
                    "sampleYDrawVal": 1857
                  },
                  {
                    "sampleYVal": 142,
                    "sampleYDrawVal": 1624
                  },
                  {
                    "sampleYVal": 149,
                    "sampleYDrawVal": 1878
                  },
                  {
                    "sampleYVal": 152,
                    "sampleYDrawVal": 1929
                  },
                  {
                    "sampleYVal": 143,
                    "sampleYDrawVal": 1671
                  },
                  {
                    "sampleYVal": 147,
                    "sampleYDrawVal": 1800
                  },
                  {
                    "sampleYVal": 149,
                    "sampleYDrawVal": 1862
                  },
                  {
                    "sampleYVal": 141,
                    "sampleYDrawVal": 1599
                  },
                  {
                    "sampleYVal": 147,
                    "sampleYDrawVal": 1781
                  },
                  {
                    "sampleYVal": 145,
                    "sampleYDrawVal": 1739
                  },
                  {
                    "sampleYVal": 152,
                    "sampleYDrawVal": 1929
                  },
                  {
                    "sampleYVal": 157,
                    "sampleYDrawVal": 2062
                  },
                  {
                    "sampleYVal": 159,
                    "sampleYDrawVal": 2151
                  },
                  {
                    "sampleYVal": 151,
                    "sampleYDrawVal": 1885
                  },
                  {
                    "sampleYVal": 158,
                    "sampleYDrawVal": 2146
                  },
                  {
                    "sampleYVal": 154,
                    "sampleYDrawVal": 2003
                  },
                  {
                    "sampleYVal": 165,
                    "sampleYDrawVal": 2363
                  },
                  {
                    "sampleYVal": 163,
                    "sampleYDrawVal": 2300
                  },
                  {
                    "sampleYVal": 161,
                    "sampleYDrawVal": 2215
                  },
                  {
                    "sampleYVal": 174,
                    "sampleYDrawVal": 2664
                  },
                  {
                    "sampleYVal": 168,
                    "sampleYDrawVal": 2468
                  },
                  {
                    "sampleYVal": 142,
                    "sampleYDrawVal": 1654
                  },
                  {
                    "sampleYVal": 150,
                    "sampleYDrawVal": 1877
                  },
                  {
                    "sampleYVal": 142,
                    "sampleYDrawVal": 1645
                  },
                  {
                    "sampleYVal": 141,
                    "sampleYDrawVal": 1611
                  },
                  {
                    "sampleYVal": 145,
                    "sampleYDrawVal": 1723
                  },
                  {
                    "sampleYVal": 155,
                    "sampleYDrawVal": 2013
                  },
                  {
                    "sampleYVal": 136,
                    "sampleYDrawVal": 1513
                  },
                  {
                    "sampleYVal": 22,
                    "sampleYDrawVal": 21
                  },
                  {
                    "sampleYVal": 4,
                    "sampleYDrawVal": 3
                  }
                ],
                "recordOffset": 21376,
                "seqUrl": "1527723653307_20633_709",
                "seqDescription": {
                  "analysis": {
                    "analysisType": "breath"
                  },
                  "pbId": -1
                },
                "mistakesObjects": {},
                "hiddenMistakesObjects": {}
              },
              {
                "fieldType": "seq",
                "timerPos": 292,
                "calibrationEventId": "6581",
                "rate": 100,
                "deltaTimeMsFromPrevSeq": "3740.00",
                "yvalues": [
                  "111",
                  "177",
                  "184",
                  "185",
                  "190",
                  "189",
                  "192",
                  "195",
                  "192",
                  "192",
                  "190",
                  "187",
                  "187",
                  "192",
                  "191",
                  "178",
                  "163",
                  "157",
                  "158",
                  "154",
                  "150",
                  "145",
                  "146",
                  "149",
                  "149",
                  "147",
                  "144",
                  "146",
                  "142",
                  "142",
                  "143",
                  "142",
                  "145",
                  "147",
                  "149",
                  "149",
                  "148",
                  "143",
                  "144",
                  "137",
                  "133",
                  "89",
                  "25",
                  "0"
                ],
                "yDrawvalues": "1674,3758,3198,3160,3414,3306,3471,3590,3401,3431,3334,3175,3216,3502,3360,2715,2134,2026,2174,1995,1857,1701,1802,1894,1867,1783,1700,1802,1625,1687,1681,1649,1776,1820,1860,1857,1838,1630,1723,1464,1402,509,1,0",
                "mistakes": [],
                "hiddenMistakes": [],
                "softVals": [
                  "1",
                  "0",
                  "0",
                  "0",
                  "0",
                  "0",
                  "0",
                  "0",
                  "0",
                  "0",
                  "0",
                  "0",
                  "0",
                  "0",
                  "0",
                  "0",
                  "1",
                  "1",
                  "1",
                  "1",
                  "1",
                  "1",
                  "1",
                  "1",
                  "1",
                  "1",
                  "1",
                  "1",
                  "1",
                  "1",
                  "1",
                  "1",
                  "1",
                  "1",
                  "1",
                  "1",
                  "1",
                  "1",
                  "1",
                  "1",
                  "1",
                  "1",
                  "1",
                  "1"
                ],
                "expectedSylsPerSecond": "0.00",
                "actualSylsPerSecond": "0.51",
                "timeLength": 5500,
                "features": [
                  {
                    "sampleYVal": 191,
                    "sampleYDrawVal": 3360
                  },
                  {
                    "sampleYVal": 214,
                    "sampleYDrawVal": 4434
                  },
                  {
                    "sampleYVal": 185,
                    "sampleYDrawVal": 3113
                  },
                  {
                    "sampleYVal": 187,
                    "sampleYDrawVal": 3178
                  },
                  {
                    "sampleYVal": 206,
                    "sampleYDrawVal": 4030
                  },
                  {
                    "sampleYVal": 177,
                    "sampleYDrawVal": 2783
                  },
                  {
                    "sampleYVal": 192,
                    "sampleYDrawVal": 3398
                  },
                  {
                    "sampleYVal": 196,
                    "sampleYDrawVal": 3581
                  },
                  {
                    "sampleYVal": 188,
                    "sampleYDrawVal": 3237
                  },
                  {
                    "sampleYVal": 196,
                    "sampleYDrawVal": 3544
                  },
                  {
                    "sampleYVal": 192,
                    "sampleYDrawVal": 3404
                  },
                  {
                    "sampleYVal": 191,
                    "sampleYDrawVal": 3355
                  },
                  {
                    "sampleYVal": 189,
                    "sampleYDrawVal": 3254
                  },
                  {
                    "sampleYVal": 197,
                    "sampleYDrawVal": 3621
                  },
                  {
                    "sampleYVal": 188,
                    "sampleYDrawVal": 3204
                  },
                  {
                    "sampleYVal": 167,
                    "sampleYDrawVal": 2432
                  },
                  {
                    "sampleYVal": 159,
                    "sampleYDrawVal": 2153
                  },
                  {
                    "sampleYVal": 153,
                    "sampleYDrawVal": 1955
                  },
                  {
                    "sampleYVal": 163,
                    "sampleYDrawVal": 2297
                  },
                  {
                    "sampleYVal": 153,
                    "sampleYDrawVal": 1955
                  },
                  {
                    "sampleYVal": 152,
                    "sampleYDrawVal": 1942
                  },
                  {
                    "sampleYVal": 138,
                    "sampleYDrawVal": 1530
                  },
                  {
                    "sampleYVal": 146,
                    "sampleYDrawVal": 1742
                  },
                  {
                    "sampleYVal": 145,
                    "sampleYDrawVal": 1717
                  },
                  {
                    "sampleYVal": 153,
                    "sampleYDrawVal": 1957
                  },
                  {
                    "sampleYVal": 149,
                    "sampleYDrawVal": 1821
                  },
                  {
                    "sampleYVal": 144,
                    "sampleYDrawVal": 1703
                  },
                  {
                    "sampleYVal": 152,
                    "sampleYDrawVal": 1921
                  },
                  {
                    "sampleYVal": 127,
                    "sampleYDrawVal": 1254
                  },
                  {
                    "sampleYVal": 148,
                    "sampleYDrawVal": 1799
                  },
                  {
                    "sampleYVal": 139,
                    "sampleYDrawVal": 1566
                  },
                  {
                    "sampleYVal": 149,
                    "sampleYDrawVal": 1851
                  },
                  {
                    "sampleYVal": 154,
                    "sampleYDrawVal": 1999
                  },
                  {
                    "sampleYVal": 146,
                    "sampleYDrawVal": 1750
                  },
                  {
                    "sampleYVal": 150,
                    "sampleYDrawVal": 1872
                  },
                  {
                    "sampleYVal": 147,
                    "sampleYDrawVal": 1791
                  },
                  {
                    "sampleYVal": 141,
                    "sampleYDrawVal": 1618
                  },
                  {
                    "sampleYVal": 145,
                    "sampleYDrawVal": 1731
                  },
                  {
                    "sampleYVal": 135,
                    "sampleYDrawVal": 1449
                  },
                  {
                    "sampleYVal": 138,
                    "sampleYDrawVal": 1512
                  },
                  {
                    "sampleYVal": 127,
                    "sampleYDrawVal": 1245
                  },
                  {
                    "sampleYVal": 16,
                    "sampleYDrawVal": 9
                  },
                  {
                    "sampleYVal": 3,
                    "sampleYDrawVal": 0
                  }
                ],
                "recordOffset": 1253,
                "seqUrl": "1527723683309_20633_709",
                "seqDescription": {
                  "analysis": {
                    "analysisType": "breath"
                  },
                  "pbId": -1
                },
                "mistakesObjects": {},
                "hiddenMistakesObjects": {}
              },
              {
                "fieldType": "seq",
                "timerPos": 301,
                "calibrationEventId": "6581",
                "rate": 100,
                "deltaTimeMsFromPrevSeq": "3311.00",
                "yvalues": [
                  "93",
                  "159",
                  "185",
                  "191",
                  "188",
                  "185",
                  "186",
                  "190",
                  "191",
                  "190",
                  "191",
                  "192",
                  "197",
                  "192",
                  "195",
                  "188",
                  "164",
                  "161",
                  "159",
                  "159",
                  "156",
                  "154",
                  "152",
                  "153",
                  "149",
                  "151",
                  "146",
                  "152",
                  "157",
                  "151",
                  "150",
                  "153",
                  "155",
                  "151",
                  "153",
                  "152",
                  "149",
                  "144",
                  "141",
                  "143",
                  "144",
                  "101",
                  "31",
                  "0"
                ],
                "yDrawvalues": "1183,2993,3515,3459,3224,3116,3192,3420,3394,3349,3379,3464,3732,3329,3573,3167,2098,2225,2166,2181,2061,2001,1927,1990,1826,1948,1724,2022,2170,1886,1889,2029,2061,1885,1999,1941,1839,1685,1606,1713,1738,651,5,0",
                "mistakes": [],
                "hiddenMistakes": [],
                "softVals": [
                  "1",
                  "1",
                  "0",
                  "0",
                  "0",
                  "0",
                  "0",
                  "0",
                  "0",
                  "0",
                  "0",
                  "0",
                  "0",
                  "0",
                  "0",
                  "0",
                  "1",
                  "1",
                  "1",
                  "1",
                  "1",
                  "1",
                  "1",
                  "1",
                  "1",
                  "1",
                  "1",
                  "1",
                  "1",
                  "1",
                  "1",
                  "1",
                  "1",
                  "1",
                  "1",
                  "1",
                  "1",
                  "1",
                  "1",
                  "1",
                  "1",
                  "1",
                  "1",
                  "1"
                ],
                "expectedSylsPerSecond": "0.00",
                "actualSylsPerSecond": "0.51",
                "timeLength": 5500,
                "features": [
                  {
                    "sampleYVal": 186,
                    "sampleYDrawVal": 3142
                  },
                  {
                    "sampleYVal": 183,
                    "sampleYDrawVal": 3027
                  },
                  {
                    "sampleYVal": 196,
                    "sampleYDrawVal": 3561
                  },
                  {
                    "sampleYVal": 195,
                    "sampleYDrawVal": 3541
                  },
                  {
                    "sampleYVal": 179,
                    "sampleYDrawVal": 2885
                  },
                  {
                    "sampleYVal": 180,
                    "sampleYDrawVal": 2903
                  },
                  {
                    "sampleYVal": 191,
                    "sampleYDrawVal": 3357
                  },
                  {
                    "sampleYVal": 183,
                    "sampleYDrawVal": 3033
                  },
                  {
                    "sampleYVal": 194,
                    "sampleYDrawVal": 3491
                  },
                  {
                    "sampleYVal": 190,
                    "sampleYDrawVal": 3307
                  },
                  {
                    "sampleYVal": 190,
                    "sampleYDrawVal": 3315
                  },
                  {
                    "sampleYVal": 199,
                    "sampleYDrawVal": 3700
                  },
                  {
                    "sampleYVal": 204,
                    "sampleYDrawVal": 3909
                  },
                  {
                    "sampleYVal": 200,
                    "sampleYDrawVal": 3759
                  },
                  {
                    "sampleYVal": 187,
                    "sampleYDrawVal": 3198
                  },
                  {
                    "sampleYVal": 168,
                    "sampleYDrawVal": 2438
                  },
                  {
                    "sampleYVal": 158,
                    "sampleYDrawVal": 2106
                  },
                  {
                    "sampleYVal": 155,
                    "sampleYDrawVal": 2033
                  },
                  {
                    "sampleYVal": 168,
                    "sampleYDrawVal": 2454
                  },
                  {
                    "sampleYVal": 158,
                    "sampleYDrawVal": 2125
                  },
                  {
                    "sampleYVal": 159,
                    "sampleYDrawVal": 2168
                  },
                  {
                    "sampleYVal": 154,
                    "sampleYDrawVal": 2015
                  },
                  {
                    "sampleYVal": 147,
                    "sampleYDrawVal": 1775
                  },
                  {
                    "sampleYVal": 149,
                    "sampleYDrawVal": 1827
                  },
                  {
                    "sampleYVal": 146,
                    "sampleYDrawVal": 1744
                  },
                  {
                    "sampleYVal": 150,
                    "sampleYDrawVal": 1869
                  },
                  {
                    "sampleYVal": 141,
                    "sampleYDrawVal": 1609
                  },
                  {
                    "sampleYVal": 160,
                    "sampleYDrawVal": 2201
                  },
                  {
                    "sampleYVal": 153,
                    "sampleYDrawVal": 1969
                  },
                  {
                    "sampleYVal": 155,
                    "sampleYDrawVal": 2017
                  },
                  {
                    "sampleYVal": 154,
                    "sampleYDrawVal": 2001
                  },
                  {
                    "sampleYVal": 160,
                    "sampleYDrawVal": 2182
                  },
                  {
                    "sampleYVal": 154,
                    "sampleYDrawVal": 2006
                  },
                  {
                    "sampleYVal": 142,
                    "sampleYDrawVal": 1652
                  },
                  {
                    "sampleYVal": 154,
                    "sampleYDrawVal": 1999
                  },
                  {
                    "sampleYVal": 150,
                    "sampleYDrawVal": 1872
                  },
                  {
                    "sampleYVal": 150,
                    "sampleYDrawVal": 1874
                  },
                  {
                    "sampleYVal": 142,
                    "sampleYDrawVal": 1665
                  },
                  {
                    "sampleYVal": 138,
                    "sampleYDrawVal": 1534
                  },
                  {
                    "sampleYVal": 147,
                    "sampleYDrawVal": 1780
                  },
                  {
                    "sampleYVal": 143,
                    "sampleYDrawVal": 1664
                  },
                  {
                    "sampleYVal": 42,
                    "sampleYDrawVal": 92
                  },
                  {
                    "sampleYVal": 5,
                    "sampleYDrawVal": 4
                  }
                ],
                "recordOffset": 10454,
                "seqUrl": "1527723683309_20633_709",
                "seqDescription": {
                  "analysis": {
                    "analysisType": "breath"
                  },
                  "pbId": -1
                },
                "mistakesObjects": {},
                "hiddenMistakesObjects": {}
              },
              {
                "fieldType": "seq",
                "timerPos": 311,
                "calibrationEventId": "6581",
                "rate": 100,
                "deltaTimeMsFromPrevSeq": "3688.00",
                "yvalues": [
                  "100",
                  "161",
                  "190",
                  "193",
                  "194",
                  "192",
                  "190",
                  "195",
                  "193",
                  "195",
                  "199",
                  "197",
                  "194",
                  "192",
                  "197",
                  "197",
                  "171",
                  "159",
                  "154",
                  "152",
                  "151",
                  "149",
                  "146",
                  "146",
                  "146",
                  "144",
                  "146",
                  "146",
                  "147",
                  "146",
                  "143",
                  "143",
                  "145",
                  "148",
                  "144",
                  "143",
                  "147",
                  "144",
                  "147",
                  "130",
                  "57",
                  "0"
                ],
                "yDrawvalues": "1059,2977,3785,3496,3511,3399,3321,3600,3421,3592,3787,3617,3451,3419,3746,3672,2311,2060,1972,1939,1930,1833,1751,1769,1786,1699,1795,1772,1807,1782,1663,1712,1757,1859,1675,1693,1837,1668,1833,1261,108,0",
                "mistakes": [],
                "hiddenMistakes": [],
                "softVals": [
                  "1",
                  "1",
                  "0",
                  "0",
                  "0",
                  "0",
                  "0",
                  "0",
                  "0",
                  "0",
                  "0",
                  "0",
                  "0",
                  "0",
                  "0",
                  "0",
                  "0",
                  "1",
                  "1",
                  "1",
                  "1",
                  "1",
                  "1",
                  "1",
                  "1",
                  "1",
                  "1",
                  "1",
                  "1",
                  "1",
                  "1",
                  "1",
                  "1",
                  "1",
                  "1",
                  "1",
                  "1",
                  "1",
                  "1",
                  "1",
                  "1",
                  "1"
                ],
                "expectedSylsPerSecond": "0.00",
                "actualSylsPerSecond": "0.53",
                "timeLength": 5250,
                "features": [
                  {
                    "sampleYVal": 149,
                    "sampleYDrawVal": 1859
                  },
                  {
                    "sampleYVal": 193,
                    "sampleYDrawVal": 3435
                  },
                  {
                    "sampleYVal": 208,
                    "sampleYDrawVal": 4125
                  },
                  {
                    "sampleYVal": 190,
                    "sampleYDrawVal": 3314
                  },
                  {
                    "sampleYVal": 191,
                    "sampleYDrawVal": 3369
                  },
                  {
                    "sampleYVal": 185,
                    "sampleYDrawVal": 3115
                  },
                  {
                    "sampleYVal": 200,
                    "sampleYDrawVal": 3738
                  },
                  {
                    "sampleYVal": 197,
                    "sampleYDrawVal": 3607
                  },
                  {
                    "sampleYVal": 197,
                    "sampleYDrawVal": 3625
                  },
                  {
                    "sampleYVal": 200,
                    "sampleYDrawVal": 3755
                  },
                  {
                    "sampleYVal": 199,
                    "sampleYDrawVal": 3706
                  },
                  {
                    "sampleYVal": 194,
                    "sampleYDrawVal": 3476
                  },
                  {
                    "sampleYVal": 193,
                    "sampleYDrawVal": 3436
                  },
                  {
                    "sampleYVal": 195,
                    "sampleYDrawVal": 3518
                  },
                  {
                    "sampleYVal": 196,
                    "sampleYDrawVal": 3575
                  },
                  {
                    "sampleYVal": 185,
                    "sampleYDrawVal": 3102
                  },
                  {
                    "sampleYVal": 166,
                    "sampleYDrawVal": 2392
                  },
                  {
                    "sampleYVal": 162,
                    "sampleYDrawVal": 2283
                  },
                  {
                    "sampleYVal": 157,
                    "sampleYDrawVal": 2082
                  },
                  {
                    "sampleYVal": 150,
                    "sampleYDrawVal": 1891
                  },
                  {
                    "sampleYVal": 155,
                    "sampleYDrawVal": 2040
                  },
                  {
                    "sampleYVal": 139,
                    "sampleYDrawVal": 1552
                  },
                  {
                    "sampleYVal": 139,
                    "sampleYDrawVal": 1569
                  },
                  {
                    "sampleYVal": 149,
                    "sampleYDrawVal": 1827
                  },
                  {
                    "sampleYVal": 147,
                    "sampleYDrawVal": 1791
                  },
                  {
                    "sampleYVal": 142,
                    "sampleYDrawVal": 1643
                  },
                  {
                    "sampleYVal": 147,
                    "sampleYDrawVal": 1792
                  },
                  {
                    "sampleYVal": 146,
                    "sampleYDrawVal": 1739
                  },
                  {
                    "sampleYVal": 152,
                    "sampleYDrawVal": 1922
                  },
                  {
                    "sampleYVal": 143,
                    "sampleYDrawVal": 1661
                  },
                  {
                    "sampleYVal": 149,
                    "sampleYDrawVal": 1849
                  },
                  {
                    "sampleYVal": 138,
                    "sampleYDrawVal": 1544
                  },
                  {
                    "sampleYVal": 140,
                    "sampleYDrawVal": 1591
                  },
                  {
                    "sampleYVal": 149,
                    "sampleYDrawVal": 1853
                  },
                  {
                    "sampleYVal": 138,
                    "sampleYDrawVal": 1540
                  },
                  {
                    "sampleYVal": 155,
                    "sampleYDrawVal": 2016
                  },
                  {
                    "sampleYVal": 150,
                    "sampleYDrawVal": 1887
                  },
                  {
                    "sampleYVal": 136,
                    "sampleYDrawVal": 1484
                  },
                  {
                    "sampleYVal": 149,
                    "sampleYDrawVal": 1848
                  },
                  {
                    "sampleYVal": 94,
                    "sampleYDrawVal": 618
                  },
                  {
                    "sampleYVal": 8,
                    "sampleYDrawVal": 5
                  }
                ],
                "recordOffset": 20023,
                "seqUrl": "1527723683309_20633_709",
                "seqDescription": {
                  "analysis": {
                    "analysisType": "breath"
                  },
                  "pbId": -1
                },
                "mistakesObjects": {},
                "hiddenMistakesObjects": {}
              },
              {
                "fieldType": "seq",
                "timerPos": 320,
                "calibrationEventId": "6581",
                "rate": 100,
                "deltaTimeMsFromPrevSeq": "3200.00",
                "yvalues": [
                  "90",
                  "113",
                  "116",
                  "152",
                  "170",
                  "169",
                  "166",
                  "168",
                  "164",
                  "163",
                  "165",
                  "163",
                  "165",
                  "163",
                  "166",
                  "161",
                  "160",
                  "148",
                  "142",
                  "135",
                  "133",
                  "137",
                  "139",
                  "139",
                  "137",
                  "143",
                  "140",
                  "140",
                  "137",
                  "133",
                  "133",
                  "134",
                  "133",
                  "134",
                  "131",
                  "131",
                  "130",
                  "133",
                  "129",
                  "92",
                  "28",
                  "0"
                ],
                "yDrawvalues": "841,1151,1058,2395,2748,2509,2394,2489,2274,2310,2392,2275,2443,2284,2454,2176,2209,1717,1590,1411,1391,1564,1597,1591,1504,1759,1565,1596,1474,1382,1402,1443,1418,1466,1334,1373,1336,1429,1271,552,3,0",
                "mistakes": [],
                "hiddenMistakes": [],
                "softVals": [
                  "1",
                  "1",
                  "1",
                  "1",
                  "1",
                  "1",
                  "1",
                  "1",
                  "1",
                  "1",
                  "1",
                  "1",
                  "1",
                  "1",
                  "1",
                  "1",
                  "1",
                  "1",
                  "1",
                  "1",
                  "1",
                  "1",
                  "1",
                  "1",
                  "1",
                  "1",
                  "1",
                  "1",
                  "1",
                  "1",
                  "1",
                  "1",
                  "1",
                  "1",
                  "1",
                  "1",
                  "1",
                  "1",
                  "1",
                  "1",
                  "1",
                  "1"
                ],
                "expectedSylsPerSecond": "0.00",
                "actualSylsPerSecond": "0.53",
                "timeLength": 5250,
                "features": [
                  {
                    "sampleYVal": 124,
                    "sampleYDrawVal": 1186
                  },
                  {
                    "sampleYVal": 137,
                    "sampleYDrawVal": 1507
                  },
                  {
                    "sampleYVal": 108,
                    "sampleYDrawVal": 852
                  },
                  {
                    "sampleYVal": 175,
                    "sampleYDrawVal": 2687
                  },
                  {
                    "sampleYVal": 172,
                    "sampleYDrawVal": 2604
                  },
                  {
                    "sampleYVal": 163,
                    "sampleYDrawVal": 2282
                  },
                  {
                    "sampleYVal": 162,
                    "sampleYDrawVal": 2238
                  },
                  {
                    "sampleYVal": 174,
                    "sampleYDrawVal": 2677
                  },
                  {
                    "sampleYVal": 160,
                    "sampleYDrawVal": 2194
                  },
                  {
                    "sampleYVal": 164,
                    "sampleYDrawVal": 2306
                  },
                  {
                    "sampleYVal": 160,
                    "sampleYDrawVal": 2190
                  },
                  {
                    "sampleYVal": 168,
                    "sampleYDrawVal": 2438
                  },
                  {
                    "sampleYVal": 156,
                    "sampleYDrawVal": 2081
                  },
                  {
                    "sampleYVal": 164,
                    "sampleYDrawVal": 2306
                  },
                  {
                    "sampleYVal": 165,
                    "sampleYDrawVal": 2334
                  },
                  {
                    "sampleYVal": 154,
                    "sampleYDrawVal": 1971
                  },
                  {
                    "sampleYVal": 153,
                    "sampleYDrawVal": 1994
                  },
                  {
                    "sampleYVal": 140,
                    "sampleYDrawVal": 1568
                  },
                  {
                    "sampleYVal": 128,
                    "sampleYDrawVal": 1264
                  },
                  {
                    "sampleYVal": 126,
                    "sampleYDrawVal": 1216
                  },
                  {
                    "sampleYVal": 135,
                    "sampleYDrawVal": 1435
                  },
                  {
                    "sampleYVal": 140,
                    "sampleYDrawVal": 1575
                  },
                  {
                    "sampleYVal": 142,
                    "sampleYDrawVal": 1644
                  },
                  {
                    "sampleYVal": 142,
                    "sampleYDrawVal": 1650
                  },
                  {
                    "sampleYVal": 141,
                    "sampleYDrawVal": 1614
                  },
                  {
                    "sampleYVal": 145,
                    "sampleYDrawVal": 1708
                  },
                  {
                    "sampleYVal": 140,
                    "sampleYDrawVal": 1585
                  },
                  {
                    "sampleYVal": 144,
                    "sampleYDrawVal": 1699
                  },
                  {
                    "sampleYVal": 135,
                    "sampleYDrawVal": 1449
                  },
                  {
                    "sampleYVal": 135,
                    "sampleYDrawVal": 1460
                  },
                  {
                    "sampleYVal": 132,
                    "sampleYDrawVal": 1369
                  },
                  {
                    "sampleYVal": 130,
                    "sampleYDrawVal": 1330
                  },
                  {
                    "sampleYVal": 126,
                    "sampleYDrawVal": 1231
                  },
                  {
                    "sampleYVal": 132,
                    "sampleYDrawVal": 1377
                  },
                  {
                    "sampleYVal": 135,
                    "sampleYDrawVal": 1449
                  },
                  {
                    "sampleYVal": 137,
                    "sampleYDrawVal": 1483
                  },
                  {
                    "sampleYVal": 133,
                    "sampleYDrawVal": 1391
                  },
                  {
                    "sampleYVal": 127,
                    "sampleYDrawVal": 1257
                  },
                  {
                    "sampleYVal": 129,
                    "sampleYDrawVal": 1308
                  },
                  {
                    "sampleYVal": 28,
                    "sampleYDrawVal": 37
                  },
                  {
                    "sampleYVal": 3,
                    "sampleYDrawVal": 0
                  }
                ],
                "recordOffset": -1121,
                "seqUrl": "1527723713308_20633_709",
                "seqDescription": {
                  "analysis": {
                    "analysisType": "breath"
                  },
                  "pbId": -1
                },
                "mistakesObjects": {},
                "hiddenMistakesObjects": {}
              },
              {
                "fieldType": "seq",
                "timerPos": 328,
                "calibrationEventId": "6581",
                "rate": 100,
                "deltaTimeMsFromPrevSeq": "1840.00",
                "yvalues": [
                  "119",
                  "157",
                  "173",
                  "175",
                  "171",
                  "175",
                  "174",
                  "172",
                  "171",
                  "176",
                  "169",
                  "165",
                  "167",
                  "169",
                  "172",
                  "165",
                  "151",
                  "151",
                  "148",
                  "148",
                  "143",
                  "138",
                  "135",
                  "135",
                  "142",
                  "143",
                  "144",
                  "142",
                  "146",
                  "145",
                  "141",
                  "142",
                  "145",
                  "143",
                  "142",
                  "142",
                  "142",
                  "125",
                  "61",
                  "0"
                ],
                "yDrawvalues": "1639,2528,2855,2765,2524,2794,2683,2636,2605,2839,2444,2330,2479,2540,2676,2311,1773,1910,1804,1825,1642,1527,1445,1491,1713,1691,1726,1621,1801,1720,1602,1684,1776,1680,1646,1682,1655,1121,167,0",
                "mistakes": [],
                "hiddenMistakes": [],
                "softVals": [
                  "1",
                  "1",
                  "0",
                  "0",
                  "0",
                  "0",
                  "0",
                  "0",
                  "0",
                  "0",
                  "1",
                  "1",
                  "1",
                  "1",
                  "0",
                  "1",
                  "1",
                  "1",
                  "1",
                  "1",
                  "1",
                  "1",
                  "1",
                  "1",
                  "1",
                  "1",
                  "1",
                  "1",
                  "1",
                  "1",
                  "1",
                  "1",
                  "1",
                  "1",
                  "1",
                  "1",
                  "1",
                  "1",
                  "1",
                  "1"
                ],
                "expectedSylsPerSecond": "0.00",
                "actualSylsPerSecond": "0.56",
                "timeLength": 5000,
                "features": [
                  {
                    "sampleYVal": 158,
                    "sampleYDrawVal": 2127
                  },
                  {
                    "sampleYVal": 176,
                    "sampleYDrawVal": 2747
                  },
                  {
                    "sampleYVal": 184,
                    "sampleYDrawVal": 3059
                  },
                  {
                    "sampleYVal": 176,
                    "sampleYDrawVal": 2750
                  },
                  {
                    "sampleYVal": 177,
                    "sampleYDrawVal": 2780
                  },
                  {
                    "sampleYVal": 183,
                    "sampleYDrawVal": 2994
                  },
                  {
                    "sampleYVal": 174,
                    "sampleYDrawVal": 2662
                  },
                  {
                    "sampleYVal": 163,
                    "sampleYDrawVal": 2282
                  },
                  {
                    "sampleYVal": 179,
                    "sampleYDrawVal": 2868
                  },
                  {
                    "sampleYVal": 179,
                    "sampleYDrawVal": 2878
                  },
                  {
                    "sampleYVal": 159,
                    "sampleYDrawVal": 2130
                  },
                  {
                    "sampleYVal": 167,
                    "sampleYDrawVal": 2444
                  },
                  {
                    "sampleYVal": 174,
                    "sampleYDrawVal": 2647
                  },
                  {
                    "sampleYVal": 166,
                    "sampleYDrawVal": 2388
                  },
                  {
                    "sampleYVal": 166,
                    "sampleYDrawVal": 2428
                  },
                  {
                    "sampleYVal": 146,
                    "sampleYDrawVal": 1736
                  },
                  {
                    "sampleYVal": 147,
                    "sampleYDrawVal": 1799
                  },
                  {
                    "sampleYVal": 153,
                    "sampleYDrawVal": 1961
                  },
                  {
                    "sampleYVal": 155,
                    "sampleYDrawVal": 2016
                  },
                  {
                    "sampleYVal": 141,
                    "sampleYDrawVal": 1614
                  },
                  {
                    "sampleYVal": 139,
                    "sampleYDrawVal": 1568
                  },
                  {
                    "sampleYVal": 146,
                    "sampleYDrawVal": 1774
                  },
                  {
                    "sampleYVal": 133,
                    "sampleYDrawVal": 1415
                  },
                  {
                    "sampleYVal": 141,
                    "sampleYDrawVal": 1623
                  },
                  {
                    "sampleYVal": 147,
                    "sampleYDrawVal": 1784
                  },
                  {
                    "sampleYVal": 140,
                    "sampleYDrawVal": 1577
                  },
                  {
                    "sampleYVal": 136,
                    "sampleYDrawVal": 1468
                  },
                  {
                    "sampleYVal": 150,
                    "sampleYDrawVal": 1854
                  },
                  {
                    "sampleYVal": 149,
                    "sampleYDrawVal": 1826
                  },
                  {
                    "sampleYVal": 144,
                    "sampleYDrawVal": 1691
                  },
                  {
                    "sampleYVal": 140,
                    "sampleYDrawVal": 1587
                  },
                  {
                    "sampleYVal": 148,
                    "sampleYDrawVal": 1822
                  },
                  {
                    "sampleYVal": 147,
                    "sampleYDrawVal": 1775
                  },
                  {
                    "sampleYVal": 144,
                    "sampleYDrawVal": 1711
                  },
                  {
                    "sampleYVal": 154,
                    "sampleYDrawVal": 1997
                  },
                  {
                    "sampleYVal": 145,
                    "sampleYDrawVal": 1726
                  },
                  {
                    "sampleYVal": 142,
                    "sampleYDrawVal": 1633
                  },
                  {
                    "sampleYVal": 105,
                    "sampleYDrawVal": 795
                  },
                  {
                    "sampleYVal": 5,
                    "sampleYDrawVal": 2
                  }
                ],
                "recordOffset": 7626,
                "seqUrl": "1527723713308_20633_709",
                "seqDescription": {
                  "analysis": {
                    "analysisType": "breath"
                  },
                  "pbId": -1
                },
                "mistakesObjects": {},
                "hiddenMistakesObjects": {}
              },
              {
                "fieldType": "seq",
                "timerPos": 337,
                "calibrationEventId": "6581",
                "rate": 100,
                "deltaTimeMsFromPrevSeq": "3610.00",
                "yvalues": [
                  "87",
                  "134",
                  "168",
                  "181",
                  "184",
                  "178",
                  "178",
                  "178",
                  "175",
                  "179",
                  "179",
                  "183",
                  "181",
                  "179",
                  "174",
                  "181",
                  "174",
                  "156",
                  "158",
                  "155",
                  "153",
                  "151",
                  "145",
                  "141",
                  "142",
                  "142",
                  "143",
                  "145",
                  "149",
                  "143",
                  "132",
                  "132",
                  "135",
                  "135",
                  "134",
                  "133",
                  "136",
                  "132",
                  "132",
                  "122",
                  "96",
                  "36",
                  "0"
                ],
                "yDrawvalues": "804,2123,2903,3131,3138,2783,2865,2821,2706,2966,2874,3120,2955,2885,2648,3053,2625,1888,2140,1999,1980,1879,1699,1578,1667,1650,1701,1777,1912,1635,1308,1393,1489,1478,1433,1424,1507,1355,1377,1085,628,22,0",
                "mistakes": [],
                "hiddenMistakes": [],
                "softVals": [
                  "1",
                  "1",
                  "1",
                  "0",
                  "0",
                  "0",
                  "0",
                  "0",
                  "0",
                  "0",
                  "0",
                  "0",
                  "0",
                  "0",
                  "0",
                  "0",
                  "0",
                  "1",
                  "1",
                  "1",
                  "1",
                  "1",
                  "1",
                  "1",
                  "1",
                  "1",
                  "1",
                  "1",
                  "1",
                  "1",
                  "1",
                  "1",
                  "1",
                  "1",
                  "1",
                  "1",
                  "1",
                  "1",
                  "1",
                  "1",
                  "1",
                  "1",
                  "1"
                ],
                "expectedSylsPerSecond": "0.00",
                "actualSylsPerSecond": "0.69",
                "timeLength": 5375,
                "features": [
                  {
                    "sampleYVal": 109,
                    "sampleYDrawVal": 871
                  },
                  {
                    "sampleYVal": 194,
                    "sampleYDrawVal": 3499
                  },
                  {
                    "sampleYVal": 178,
                    "sampleYDrawVal": 2825
                  },
                  {
                    "sampleYVal": 193,
                    "sampleYDrawVal": 3450
                  },
                  {
                    "sampleYVal": 188,
                    "sampleYDrawVal": 3208
                  },
                  {
                    "sampleYVal": 171,
                    "sampleYDrawVal": 2585
                  },
                  {
                    "sampleYVal": 184,
                    "sampleYDrawVal": 3063
                  },
                  {
                    "sampleYVal": 181,
                    "sampleYDrawVal": 2934
                  },
                  {
                    "sampleYVal": 171,
                    "sampleYDrawVal": 2554
                  },
                  {
                    "sampleYVal": 180,
                    "sampleYDrawVal": 2895
                  },
                  {
                    "sampleYVal": 183,
                    "sampleYDrawVal": 3030
                  },
                  {
                    "sampleYVal": 192,
                    "sampleYDrawVal": 3389
                  },
                  {
                    "sampleYVal": 181,
                    "sampleYDrawVal": 2946
                  },
                  {
                    "sampleYVal": 180,
                    "sampleYDrawVal": 2931
                  },
                  {
                    "sampleYVal": 171,
                    "sampleYDrawVal": 2554
                  },
                  {
                    "sampleYVal": 186,
                    "sampleYDrawVal": 3141
                  },
                  {
                    "sampleYVal": 152,
                    "sampleYDrawVal": 1917
                  },
                  {
                    "sampleYVal": 162,
                    "sampleYDrawVal": 2248
                  },
                  {
                    "sampleYVal": 158,
                    "sampleYDrawVal": 2107
                  },
                  {
                    "sampleYVal": 157,
                    "sampleYDrawVal": 2105
                  },
                  {
                    "sampleYVal": 152,
                    "sampleYDrawVal": 1948
                  },
                  {
                    "sampleYVal": 153,
                    "sampleYDrawVal": 1967
                  },
                  {
                    "sampleYVal": 146,
                    "sampleYDrawVal": 1760
                  },
                  {
                    "sampleYVal": 143,
                    "sampleYDrawVal": 1662
                  },
                  {
                    "sampleYVal": 142,
                    "sampleYDrawVal": 1639
                  },
                  {
                    "sampleYVal": 148,
                    "sampleYDrawVal": 1816
                  },
                  {
                    "sampleYVal": 151,
                    "sampleYDrawVal": 1892
                  },
                  {
                    "sampleYVal": 153,
                    "sampleYDrawVal": 1976
                  },
                  {
                    "sampleYVal": 147,
                    "sampleYDrawVal": 1784
                  },
                  {
                    "sampleYVal": 134,
                    "sampleYDrawVal": 1432
                  },
                  {
                    "sampleYVal": 130,
                    "sampleYDrawVal": 1340
                  },
                  {
                    "sampleYVal": 127,
                    "sampleYDrawVal": 1238
                  },
                  {
                    "sampleYVal": 138,
                    "sampleYDrawVal": 1540
                  },
                  {
                    "sampleYVal": 132,
                    "sampleYDrawVal": 1377
                  },
                  {
                    "sampleYVal": 134,
                    "sampleYDrawVal": 1415
                  },
                  {
                    "sampleYVal": 135,
                    "sampleYDrawVal": 1453
                  },
                  {
                    "sampleYVal": 133,
                    "sampleYDrawVal": 1399
                  },
                  {
                    "sampleYVal": 132,
                    "sampleYDrawVal": 1372
                  },
                  {
                    "sampleYVal": 132,
                    "sampleYDrawVal": 1368
                  },
                  {
                    "sampleYVal": 121,
                    "sampleYDrawVal": 1113
                  },
                  {
                    "sampleYVal": 37,
                    "sampleYDrawVal": 82
                  },
                  {
                    "sampleYVal": 19,
                    "sampleYDrawVal": 31
                  }
                ],
                "recordOffset": 16620,
                "seqUrl": "1527723713308_20633_709",
                "seqDescription": {
                  "analysis": {
                    "analysisType": "breath"
                  },
                  "pbId": -1
                },
                "mistakesObjects": {},
                "hiddenMistakesObjects": {}
              }
            ],
            "results": {
              "fieldType": "results",
              "woutActivityId": "20633",
              "woutIndex": "0",
              "userId": "709",
              "timerPos": 337,
              "woutActivityShardGuid": "093c90dc01487096a0b235eec4615f6e03a574df",
              "wout": {
                "levelIndex": "26",
                "partIndex": 1,
                "part": {
                  "analysisDetails": [
                    {
                      "breathingEnabled": true,
                      "breathTimeMs": 3000,
                      "mistakesEnabled": true,
                      "mistakes": [
                        "gentleOnset",
                        "gentleOffset",
                        "softPeak",
                        "volControlDown",
                        "volControlUp",
                        "tooShort",
                        "tooFast",
                        "tooSlow",
                        "symetric",
                        "tooSoft",
                        "tooHard",
                        "breathEarly",
                        "intensePeak",
                        "sharpRise",
                        "sharpFall",
                        "shapeStart",
                        "shapeEnd",
                        "volControlStart",
                        "volControlEnd",
                        "notSoft",
                        "notHard"
                      ],
                      "boundariesEnabled": true,
                      "coloringEnabled": false,
                      "analysisType": "breath",
                      "analyzeTypeSylLenMs": 5000,
                      "analyzeTypeNumOfSylsInSeq": 1,
                      "mistakesSkills": {
                        "tooShort": 2,
                        "tooLong": 1,
                        "breathMiss": 1,
                        "breathEarly": 2
                      },
                      "configs": [
                        {
                          "analyzeTypeSylLenMs": 500,
                          "analyzeTypeNumOfSylsInSeq": 5,
                          "weight": 3,
                          "analyzeNumSylsPerSec": 1
                        },
                        {
                          "analyzeTypeSylLenMs": 200,
                          "analyzeTypeNumOfSylsInSeq": 5,
                          "weight": 5,
                          "analyzeNumSylsPerSec": 1
                        }
                      ],
                      "analyzeNumSylsPerSec": 1,
                      "boundariesGridEnabled": true
                    }
                  ],
                  "uiSettings": {
                    "enableMirror": false,
                    "instructions": "על המסך תופיע הנחיה למשך כמה זמן לשאוף אויר, ולמשך כמה זמן להפיק זזז.  שימו לב למוניטור והתאימו את הפקת הקול  שלכם לסרגלי ההתקדמות של השאיפה והנשיפה.",
                    "showWordGoal": true,
                    "inhaleText": "לשאוף אויר למשך 3 שניות",
                    "exhaleText": "להגיד זזזז רציף למשך 5 שניות לפחות.",
                    "repetition": 1,
                    "videoInstructions": {
                      "id": 125,
                      "type": "video",
                      "title": "instructions: instructions.breathing+z+volume.mp4",
                      "value": "https://s3-eu-west-1.amazonaws.com/nvtk-video-media/2016_05/הוראות/instructions.breathing+z+volume.mp4",
                      "tags": [],
                      "thumb": "http://www.depaul.edu/campustour/Pages/_layouts/spotlight/btn-videoplayoverlay.png"
                    },
                    "isRecordEnabled": true,
                    "isScreenShareEnabled": true
                  },
                  "wordGoal": 15,
                  "description": "נשימה- הגדלת נפח",
                  "name": "breathing 1-3",
                  "timeEstimation": 120,
                  "uiType": {
                    "type": "breath",
                    "icon": "icon-star"
                  },
                  "engineType": {
                    "type": "DummyEngine",
                    "icon": "icon-book-open"
                  },
                  "pressed": false,
                  "passScore": "20",
                  "filterSettings": [
                    {
                      "filter": {
                        "selectedBank": "none"
                      }
                    }
                  ],
                  "jqyoui_pos": 11,
                  "type": "wout"
                },
                "totalLevels": 52,
                "totalParts": 10,
                "tp2uInstanceId": "1984fcbe7fa059f148897aea947062da8efe9df6",
                "tpId": "809",
                "woutTag": "נשימה- הגדלת נפח"
              },
              "requiredDuration": 0,
              "actualDuration": -1,
              "isLastPartInLevel": false,
              "isLastLevel": false,
              "requiredPassRate": "20",
              "overallRate": 100,
              "totalActualSequencesDone": 15,
              "totalRequiredSequences": 15,
              "isWoutPassed": true,
              "nolimit": false,
              "showWordGoal": true,
              "showDurationGoal": false,
              "isPracticeExpired": false
            }
          },
          {
            "title": "תרגיל: הרפיה- שרירי הפנים",
            "config": {
              "uiSettings": {
                "enableMirror": true,
                "video": {
                  "id": 119,
                  "type": "video",
                  "title": "demonstration.relaxation.face.mp4",
                  "value": "https://s3-eu-west-1.amazonaws.com/nvtk-video-media/2016_05/הדגמות/demonstration.relaxation.face.mp4",
                  "tags": [],
                  "thumb": "http://www.depaul.edu/campustour/Pages/_layouts/spotlight/btn-videoplayoverlay.png"
                },
                "nolimit": true,
                "repetition": 1,
                "instructions": "לפניכם תרגילים להרפיית שרירי הפה והלסת ולהרפיית שרירי הצוואר הפנימיים.  יש לבצע תרגילים אלו בישיבה נינוחה או בעמידה יציבה. הביטו בהדגמה שמופיעה בתרגול והצטרפו למדגים.",
                "videoInstructions": {
                  "id": 127,
                  "type": "video",
                  "title": "instructions.relaxation.face.mp4",
                  "value": "https://s3-eu-west-1.amazonaws.com/nvtk-video-media/2016_05/הוראות/instructions.relaxation.face.mp4",
                  "tags": [],
                  "thumb": "http://www.depaul.edu/campustour/Pages/_layouts/spotlight/btn-videoplayoverlay.png"
                },
                "isRecordEnabled": true,
                "isScreenShareEnabled": true
              },
              "description": "תרגיל: הרפיה- שרירי הפנים",
              "name": "mirror task",
              "timeEstimation": 60,
              "uiType": {
                "type": "mirror",
                "icon": "icon-camcorder"
              },
              "engineType": {
                "type": "DummyEngine",
                "icon": "icon-book-open"
              },
              "pressed": false,
              "passScore": 50,
              "filterSettings": [
                {
                  "filter": {
                    "selectedBank": "none"
                  }
                }
              ],
              "wordGoal": 1,
              "duration": 1,
              "analysisDetails": [
                {
                  "analyzeNumSylsPerSec": 1,
                  "configs": [
                    {
                      "analyzeNumSylsPerSec": 1,
                      "weight": 1
                    },
                    {
                      "analyzeNumSylsPerSec": 1,
                      "weight": 1
                    }
                  ],
                  "analyzeTypeNumOfSylsInSeq": 1,
                  "mistakesSkills": [],
                  "mistakes": []
                }
              ],
              "jqyoui_pos": 12,
              "type": "wout"
            },
            "sequences": [],
            "results": {
              "fieldType": "results",
              "woutActivityId": "20633",
              "woutIndex": "2",
              "userId": "709",
              "timerPos": 546,
              "woutActivityShardGuid": "fe89bcb4dea4810854653c71aea8fcea82095246",
              "wout": {
                "levelIndex": "26",
                "partIndex": 2,
                "part": {
                  "uiSettings": {
                    "enableMirror": true,
                    "video": {
                      "id": 119,
                      "type": "video",
                      "title": "demonstration.relaxation.face.mp4",
                      "value": "https://s3-eu-west-1.amazonaws.com/nvtk-video-media/2016_05/הדגמות/demonstration.relaxation.face.mp4",
                      "tags": [],
                      "thumb": "http://www.depaul.edu/campustour/Pages/_layouts/spotlight/btn-videoplayoverlay.png"
                    },
                    "nolimit": true,
                    "repetition": 1,
                    "instructions": "לפניכם תרגילים להרפיית שרירי הפה והלסת ולהרפיית שרירי הצוואר הפנימיים.  יש לבצע תרגילים אלו בישיבה נינוחה או בעמידה יציבה. הביטו בהדגמה שמופיעה בתרגול והצטרפו למדגים.",
                    "videoInstructions": {
                      "id": 127,
                      "type": "video",
                      "title": "instructions.relaxation.face.mp4",
                      "value": "https://s3-eu-west-1.amazonaws.com/nvtk-video-media/2016_05/הוראות/instructions.relaxation.face.mp4",
                      "tags": [],
                      "thumb": "http://www.depaul.edu/campustour/Pages/_layouts/spotlight/btn-videoplayoverlay.png"
                    },
                    "isRecordEnabled": true,
                    "isScreenShareEnabled": true
                  },
                  "description": "תרגיל: הרפיה- שרירי הפנים",
                  "name": "mirror task",
                  "timeEstimation": 60,
                  "uiType": {
                    "type": "mirror",
                    "icon": "icon-camcorder"
                  },
                  "engineType": {
                    "type": "DummyEngine",
                    "icon": "icon-book-open"
                  },
                  "pressed": false,
                  "passScore": 50,
                  "filterSettings": [
                    {
                      "filter": {
                        "selectedBank": "none"
                      }
                    }
                  ],
                  "wordGoal": 1,
                  "duration": 1,
                  "analysisDetails": [
                    {
                      "analyzeNumSylsPerSec": 1,
                      "configs": [
                        {
                          "analyzeNumSylsPerSec": 1,
                          "weight": 1
                        },
                        {
                          "analyzeNumSylsPerSec": 1,
                          "weight": 1
                        }
                      ],
                      "analyzeTypeNumOfSylsInSeq": 1,
                      "mistakesSkills": [],
                      "mistakes": []
                    }
                  ],
                  "jqyoui_pos": 12,
                  "type": "wout"
                },
                "totalLevels": 52,
                "totalParts": 10,
                "tp2uInstanceId": "1984fcbe7fa059f148897aea947062da8efe9df6",
                "tpId": "809",
                "woutTag": "תרגיל: הרפיה- שרירי הפנים"
              },
              "requiredDuration": 0,
              "actualDuration": -1,
              "isLastPartInLevel": false,
              "isLastLevel": false,
              "requiredPassRate": 50,
              "overallRate": 0,
              "totalActualSequencesDone": 0,
              "totalRequiredSequences": 1,
              "isWoutPassed": true,
              "nolimit": true,
              "showWordGoal": false,
              "showDurationGoal": false,
              "isPracticeExpired": false
            }
          },
          {
            "title": "תרגול: 2 שניות להברה - משפטים",
            "config": {
              "filterSettings": [
                {
                  "filter": {
                    "lang": "he",
                    "selectedBank": "sentence",
                    "sentenceSize": {
                      "exp": {
                        "sign": "gt",
                        "val": 3
                      },
                      "expB": {
                        "sign": "lt",
                        "val": 6
                      }
                    },
                    "syl": [],
                    "audioType": {
                      "filePath": "none",
                      "desc": "FILTERBUILDER.AUDIO_OPTION_NONE"
                    }
                  }
                }
              ],
              "analysisDetails": [
                {
                  "breathingEnabled": true,
                  "breathTimeMs": 500,
                  "mistakesEnabled": true,
                  "mistakes": [
                    "gentleOnset",
                    "gentleOffset",
                    "softPeak",
                    "volControlUp",
                    "tooShort",
                    "tooFast",
                    "tooSlow",
                    "symetric",
                    "tooSoft",
                    "tooHard",
                    "breathEarly",
                    "intensePeak",
                    "sharpRise",
                    "volControlStart",
                    "volControlEnd",
                    "notSoft",
                    "notHard"
                  ],
                  "boundariesEnabled": true,
                  "boundariesGridEnabled": true,
                  "coloringEnabled": true,
                  "analysisType": "2sec",
                  "analyzeTypeSylLenMs": 2000,
                  "analyzeTypeNumOfSylsInSeq": 1,
                  "mistakesSkills": {
                    "gentleOnset": 2,
                    "gentleOffset": 2,
                    "softPeak": 2,
                    "volControlDown": 2,
                    "volControlUp": 2,
                    "tooShort": 2,
                    "tooLong": 2,
                    "breathMiss": 1,
                    "breathEarly": 2,
                    "intensePeak": 2,
                    "sharpRise": 2,
                    "sharpFall": 2,
                    "shapeStart": 2,
                    "shapeEnd": 2,
                    "volControlStart": 2,
                    "volControlEnd": 2
                  },
                  "configs": [
                    {
                      "analyzeTypeSylLenMs": 500,
                      "analyzeTypeNumOfSylsInSeq": 5,
                      "weight": 3
                    },
                    {
                      "analyzeTypeSylLenMs": 200,
                      "analyzeTypeNumOfSylsInSeq": 5,
                      "weight": 5
                    }
                  ]
                }
              ],
              "uiSettings": {
                "enableMirror": false,
                "instructions": "בתרגול זה יש להפיק משפטים בתבנית 2 שניות להברה, ולשאוף אויר בין הברה להברה. \nעל המסך יופיעו משפטים באורכים שונים. כל מילה במשפט תהיה מחולקת להברות, וההברה שיש להפיק תהיה מודגשת. \n חשוב להיעזר בתבנית המטרה המוצללת שמופיעה על גבי המוניטור, על מנת להפיק את ההברות בצורה הנכונה.\nיש להקפיד על שאיפת אויר בין הברה להברה, ועל שימוש נכון בנשימה סרעפתית ומפתח פה, על מנת להפיק את התבנית הרצויה.",
                "defaultVideoInstructionsId": 0,
                "phraseAudioType": [],
                "repetition": 1,
                "videoInstructions": {
                  "id": 195,
                  "type": "video",
                  "title": "instructions.2sec_pattern_sentence.monitor.mp4",
                  "value": "https://s3-eu-west-1.amazonaws.com/nvtk-video-media/2016_05/הוראות/instructions.2sec_pattern_sentence.monitor.mp4",
                  "tags": [],
                  "thumb": "http://www.depaul.edu/campustour/Pages/_layouts/spotlight/btn-videoplayoverlay.png"
                },
                "isRecordEnabled": true,
                "isScreenShareEnabled": true
              },
              "wordGoal": 100,
              "description": "תרגול: 2 שניות להברה - משפטים",
              "name": "2-sec sentences",
              "timeEstimation": 300,
              "uiType": {
                "type": "text",
                "icon": "glyphicon glyphicon-file"
              },
              "engineType": {
                "type": "TextEngine",
                "icon": "icon-book-open"
              },
              "pressed": false,
              "passScore": "20",
              "jqyoui_pos": 1,
              "type": "wout"
            },
            "sequences": [
              {
                "fieldType": "seq",
                "timerPos": 550,
                "calibrationEventId": "6581",
                "rate": 85,
                "deltaTimeMsFromPrevSeq": "-1.00",
                "yvalues": [
                  "77",
                  "95",
                  "141",
                  "164",
                  "170",
                  "178",
                  "179",
                  "163",
                  "138",
                  "120",
                  "84",
                  "70",
                  "56",
                  "0"
                ],
                "yDrawvalues": "678,906,2145,2721,2672,2995,2936,2237,1506,1140,426,296,163,0",
                "mistakes": [
                  {
                    "sampleOffset": 13,
                    "mistakeId": 6
                  }
                ],
                "hiddenMistakes": [],
                "softVals": [
                  "1",
                  "1",
                  "1",
                  "1",
                  "1",
                  "0",
                  "0",
                  "1",
                  "1",
                  "1",
                  "1",
                  "1",
                  "1",
                  "1"
                ],
                "expectedSylsPerSecond": "0.00",
                "actualSylsPerSecond": "0.56",
                "timeLength": 1750,
                "features": [
                  {
                    "sampleYVal": 93,
                    "sampleYDrawVal": 713
                  },
                  {
                    "sampleYVal": 116,
                    "sampleYDrawVal": 1288
                  },
                  {
                    "sampleYVal": 161,
                    "sampleYDrawVal": 2466
                  },
                  {
                    "sampleYVal": 173,
                    "sampleYDrawVal": 2724
                  },
                  {
                    "sampleYVal": 179,
                    "sampleYDrawVal": 2918
                  },
                  {
                    "sampleYVal": 170,
                    "sampleYDrawVal": 2526
                  },
                  {
                    "sampleYVal": 180,
                    "sampleYDrawVal": 2945
                  },
                  {
                    "sampleYVal": 147,
                    "sampleYDrawVal": 1851
                  },
                  {
                    "sampleYVal": 136,
                    "sampleYDrawVal": 1653
                  },
                  {
                    "sampleYVal": 91,
                    "sampleYDrawVal": 655
                  },
                  {
                    "sampleYVal": 83,
                    "sampleYDrawVal": 510
                  },
                  {
                    "sampleYVal": 60,
                    "sampleYDrawVal": 207
                  },
                  {
                    "sampleYVal": 49,
                    "sampleYDrawVal": 129
                  }
                ],
                "recordOffset": 2402,
                "seqUrl": "1527724003056_20633_709",
                "seqDescription": {
                  "analysis": {
                    "analysisType": "2sec"
                  },
                  "completeCount": 0,
                  "currentSeqPos": 0,
                  "pbId": "1240",
                  "text": "נָ ",
                  "type": "sentence",
                  "word": {
                    "id": 1237,
                    "language": "he",
                    "cleanInput": "נָאֶה",
                    "type": "word",
                    "data": "[{\"text\":\"\\u05e0\\u05b8\",\"sylType\":\"cv\",\"vowel\":\"A\",\"isContinous\":true},{\"text\":\"\\u05d0\\u05b6\\u05d4\",\"sylType\":\"v\",\"vowel\":\"E\"}]",
                    "isNonesense": 0,
                    "audios": ""
                  }
                },
                "mistakesObjects": {
                  "tooShort": {
                    "titlei18Key": "MISTAKES.TOO_SHORT"
                  }
                },
                "hiddenMistakesObjects": {}
              },
              {
                "fieldType": "seq",
                "timerPos": 553,
                "calibrationEventId": "6581",
                "rate": 85,
                "deltaTimeMsFromPrevSeq": "1729.00",
                "yvalues": [
                  "73",
                  "113",
                  "136",
                  "157",
                  "184",
                  "198",
                  "200",
                  "202",
                  "190",
                  "136",
                  "103",
                  "84",
                  "60",
                  "0"
                ],
                "yDrawvalues": "637,1377,1874,2409,3433,3892,3873,3972,3339,1429,809,502,204,0",
                "mistakes": [
                  {
                    "sampleOffset": 13,
                    "mistakeId": 6
                  }
                ],
                "hiddenMistakes": [],
                "softVals": [
                  "1",
                  "1",
                  "1",
                  "1",
                  "0",
                  "0",
                  "0",
                  "0",
                  "0",
                  "1",
                  "1",
                  "1",
                  "1",
                  "1"
                ],
                "expectedSylsPerSecond": "0.00",
                "actualSylsPerSecond": "0.56",
                "timeLength": 1750,
                "features": [
                  {
                    "sampleYVal": 88,
                    "sampleYDrawVal": 602
                  },
                  {
                    "sampleYVal": 142,
                    "sampleYDrawVal": 1885
                  },
                  {
                    "sampleYVal": 152,
                    "sampleYDrawVal": 2079
                  },
                  {
                    "sampleYVal": 172,
                    "sampleYDrawVal": 2670
                  },
                  {
                    "sampleYVal": 196,
                    "sampleYDrawVal": 3653
                  },
                  {
                    "sampleYVal": 201,
                    "sampleYDrawVal": 3849
                  },
                  {
                    "sampleYVal": 206,
                    "sampleYDrawVal": 4095
                  },
                  {
                    "sampleYVal": 202,
                    "sampleYDrawVal": 3920
                  },
                  {
                    "sampleYVal": 168,
                    "sampleYDrawVal": 2647
                  },
                  {
                    "sampleYVal": 105,
                    "sampleYDrawVal": 1073
                  },
                  {
                    "sampleYVal": 90,
                    "sampleYDrawVal": 635
                  },
                  {
                    "sampleYVal": 69,
                    "sampleYDrawVal": 374
                  },
                  {
                    "sampleYVal": 46,
                    "sampleYDrawVal": 115
                  }
                ],
                "recordOffset": 5925,
                "seqUrl": "1527724003056_20633_709",
                "seqDescription": {
                  "analysis": {
                    "analysisType": "2sec"
                  },
                  "completeCount": 0,
                  "currentSeqPos": 1,
                  "pbId": "1240",
                  "text": "אֶה ",
                  "type": "sentence",
                  "word": {
                    "id": 1237,
                    "language": "he",
                    "cleanInput": "נָאֶה",
                    "type": "word",
                    "data": "[{\"text\":\"\\u05e0\\u05b8\",\"sylType\":\"cv\",\"vowel\":\"A\",\"isContinous\":true},{\"text\":\"\\u05d0\\u05b6\\u05d4\",\"sylType\":\"v\",\"vowel\":\"E\"}]",
                    "isNonesense": 0,
                    "audios": ""
                  }
                },
                "mistakesObjects": {
                  "tooShort": {
                    "titlei18Key": "MISTAKES.TOO_SHORT"
                  }
                },
                "hiddenMistakesObjects": {}
              },
              {
                "fieldType": "seq",
                "timerPos": 557,
                "calibrationEventId": "6581",
                "rate": 100,
                "deltaTimeMsFromPrevSeq": "1785.00",
                "yvalues": [
                  "91",
                  "121",
                  "131",
                  "158",
                  "175",
                  "182",
                  "179",
                  "175",
                  "157",
                  "132",
                  "111",
                  "86",
                  "65",
                  "40",
                  "0"
                ],
                "yDrawvalues": "1236,1603,1827,2732,3097,3279,3088,2912,2241,1648,1204,638,331,68,0",
                "mistakes": [],
                "hiddenMistakes": [],
                "softVals": [
                  "1",
                  "1",
                  "1",
                  "1",
                  "0",
                  "0",
                  "0",
                  "0",
                  "1",
                  "1",
                  "1",
                  "1",
                  "1",
                  "1",
                  "1"
                ],
                "expectedSylsPerSecond": "0.00",
                "actualSylsPerSecond": "0.52",
                "timeLength": 1875,
                "features": [
                  {
                    "sampleYVal": 112,
                    "sampleYDrawVal": 1351
                  },
                  {
                    "sampleYVal": 132,
                    "sampleYDrawVal": 1647
                  },
                  {
                    "sampleYVal": 150,
                    "sampleYDrawVal": 2287
                  },
                  {
                    "sampleYVal": 186,
                    "sampleYDrawVal": 3333
                  },
                  {
                    "sampleYVal": 184,
                    "sampleYDrawVal": 3200
                  },
                  {
                    "sampleYVal": 186,
                    "sampleYDrawVal": 3272
                  },
                  {
                    "sampleYVal": 174,
                    "sampleYDrawVal": 3001
                  },
                  {
                    "sampleYVal": 175,
                    "sampleYDrawVal": 2891
                  },
                  {
                    "sampleYVal": 136,
                    "sampleYDrawVal": 1825
                  },
                  {
                    "sampleYVal": 120,
                    "sampleYDrawVal": 1462
                  },
                  {
                    "sampleYVal": 103,
                    "sampleYDrawVal": 983
                  },
                  {
                    "sampleYVal": 73,
                    "sampleYDrawVal": 497
                  },
                  {
                    "sampleYVal": 41,
                    "sampleYDrawVal": 118
                  },
                  {
                    "sampleYVal": 28,
                    "sampleYDrawVal": 36
                  }
                ],
                "recordOffset": 9504,
                "seqUrl": "1527724003056_20633_709",
                "seqDescription": {
                  "analysis": {
                    "analysisType": "2sec"
                  },
                  "completeCount": 1,
                  "currentSeqPos": 0,
                  "pbId": "1240",
                  "text": "דּוֹ ",
                  "type": "sentence",
                  "word": {
                    "id": 1238,
                    "language": "he",
                    "cleanInput": "דּוֹרֵשׁ",
                    "type": "word",
                    "data": "[{\"text\":\"\\u05d3\\u05bc\\u05d5\\u05b9\",\"sylType\":\"cv\",\"vowel\":\"O\",\"isPlosive\":true},{\"text\":\"\\u05e8\\u05b5\\u05e9\\u05c1\",\"sylType\":\"cvc\",\"vowel\":\"E\",\"isContinous\":true}]",
                    "isNonesense": 0,
                    "audios": ""
                  }
                },
                "mistakesObjects": {},
                "hiddenMistakesObjects": {}
              },
              {
                "fieldType": "seq",
                "timerPos": 560,
                "calibrationEventId": "6581",
                "rate": 80,
                "deltaTimeMsFromPrevSeq": "1539.00",
                "yvalues": [
                  "74",
                  "103",
                  "99",
                  "125",
                  "156",
                  "168",
                  "169",
                  "178",
                  "174",
                  "162",
                  "149",
                  "123",
                  "69",
                  "43",
                  "0"
                ],
                "yDrawvalues": "852,1157,946,1617,2497,2704,2629,3019,2821,2338,1974,1275,303,89,0",
                "mistakes": [
                  {
                    "sampleOffset": 1,
                    "mistakeId": 5
                  }
                ],
                "hiddenMistakes": [],
                "softVals": [
                  "1",
                  "1",
                  "1",
                  "1",
                  "1",
                  "1",
                  "1",
                  "0",
                  "0",
                  "1",
                  "1",
                  "1",
                  "1",
                  "1",
                  "1"
                ],
                "expectedSylsPerSecond": "0.00",
                "actualSylsPerSecond": "0.52",
                "timeLength": 1875,
                "features": [
                  {
                    "sampleYVal": 101,
                    "sampleYDrawVal": 1106
                  },
                  {
                    "sampleYVal": 86,
                    "sampleYDrawVal": 698
                  },
                  {
                    "sampleYVal": 100,
                    "sampleYDrawVal": 926
                  },
                  {
                    "sampleYVal": 145,
                    "sampleYDrawVal": 1924
                  },
                  {
                    "sampleYVal": 176,
                    "sampleYDrawVal": 2898
                  },
                  {
                    "sampleYVal": 176,
                    "sampleYDrawVal": 2799
                  },
                  {
                    "sampleYVal": 162,
                    "sampleYDrawVal": 2364
                  },
                  {
                    "sampleYVal": 186,
                    "sampleYDrawVal": 3247
                  },
                  {
                    "sampleYVal": 172,
                    "sampleYDrawVal": 2722
                  },
                  {
                    "sampleYVal": 146,
                    "sampleYDrawVal": 1938
                  },
                  {
                    "sampleYVal": 142,
                    "sampleYDrawVal": 1788
                  },
                  {
                    "sampleYVal": 92,
                    "sampleYDrawVal": 761
                  },
                  {
                    "sampleYVal": 41,
                    "sampleYDrawVal": 96
                  },
                  {
                    "sampleYVal": 34,
                    "sampleYDrawVal": 62
                  }
                ],
                "recordOffset": 12963,
                "seqUrl": "1527724003056_20633_709",
                "seqDescription": {
                  "analysis": {
                    "analysisType": "2sec"
                  },
                  "completeCount": 1,
                  "currentSeqPos": 1,
                  "pbId": "1240",
                  "text": "רֵשׁ ",
                  "type": "sentence",
                  "word": {
                    "id": 1238,
                    "language": "he",
                    "cleanInput": "דּוֹרֵשׁ",
                    "type": "word",
                    "data": "[{\"text\":\"\\u05d3\\u05bc\\u05d5\\u05b9\",\"sylType\":\"cv\",\"vowel\":\"O\",\"isPlosive\":true},{\"text\":\"\\u05e8\\u05b5\\u05e9\\u05c1\",\"sylType\":\"cvc\",\"vowel\":\"E\",\"isContinous\":true}]",
                    "isNonesense": 0,
                    "audios": ""
                  }
                },
                "mistakesObjects": {
                  "volControlUp": {
                    "titlei18Key": "MISTAKES.VOLCONTROL_UP"
                  }
                },
                "hiddenMistakesObjects": {}
              },
              {
                "fieldType": "seq",
                "timerPos": 564,
                "calibrationEventId": "6581",
                "rate": 100,
                "deltaTimeMsFromPrevSeq": "1598.00",
                "yvalues": [
                  "96",
                  "129",
                  "155",
                  "171",
                  "173",
                  "178",
                  "185",
                  "179",
                  "163",
                  "149",
                  "129",
                  "103",
                  "65",
                  "41",
                  "0"
                ],
                "yDrawvalues": "1063,1731,2473,2809,2737,2952,3268,2921,2275,1857,1307,793,260,63,0",
                "mistakes": [],
                "hiddenMistakes": [],
                "softVals": [
                  "1",
                  "1",
                  "1",
                  "0",
                  "0",
                  "0",
                  "0",
                  "0",
                  "1",
                  "1",
                  "1",
                  "1",
                  "1",
                  "1",
                  "1"
                ],
                "expectedSylsPerSecond": "0.00",
                "actualSylsPerSecond": "0.52",
                "timeLength": 1875,
                "features": [
                  {
                    "sampleYVal": 120,
                    "sampleYDrawVal": 1340
                  },
                  {
                    "sampleYVal": 139,
                    "sampleYDrawVal": 1806
                  },
                  {
                    "sampleYVal": 173,
                    "sampleYDrawVal": 2842
                  },
                  {
                    "sampleYVal": 173,
                    "sampleYDrawVal": 2698
                  },
                  {
                    "sampleYVal": 174,
                    "sampleYDrawVal": 2729
                  },
                  {
                    "sampleYVal": 182,
                    "sampleYDrawVal": 3032
                  },
                  {
                    "sampleYVal": 188,
                    "sampleYDrawVal": 3306
                  },
                  {
                    "sampleYVal": 166,
                    "sampleYDrawVal": 2449
                  },
                  {
                    "sampleYVal": 150,
                    "sampleYDrawVal": 1957
                  },
                  {
                    "sampleYVal": 131,
                    "sampleYDrawVal": 1472
                  },
                  {
                    "sampleYVal": 122,
                    "sampleYDrawVal": 1237
                  },
                  {
                    "sampleYVal": 79,
                    "sampleYDrawVal": 505
                  },
                  {
                    "sampleYVal": 37,
                    "sampleYDrawVal": 97
                  },
                  {
                    "sampleYVal": 33,
                    "sampleYDrawVal": 61
                  }
                ],
                "recordOffset": 16484,
                "seqUrl": "1527724003056_20633_709",
                "seqDescription": {
                  "analysis": {
                    "analysisType": "2sec"
                  },
                  "completeCount": 2,
                  "currentSeqPos": 0,
                  "pbId": "1240",
                  "text": "נָ ",
                  "type": "sentence",
                  "word": {
                    "id": 1237,
                    "language": "he",
                    "cleanInput": "נָאֶה",
                    "type": "word",
                    "data": "[{\"text\":\"\\u05e0\\u05b8\",\"sylType\":\"cv\",\"vowel\":\"A\",\"isContinous\":true},{\"text\":\"\\u05d0\\u05b6\\u05d4\",\"sylType\":\"v\",\"vowel\":\"E\"}]",
                    "isNonesense": 0,
                    "audios": ""
                  }
                },
                "mistakesObjects": {},
                "hiddenMistakesObjects": {}
              },
              {
                "fieldType": "seq",
                "timerPos": 567,
                "calibrationEventId": "6581",
                "rate": 85,
                "deltaTimeMsFromPrevSeq": "1399.00",
                "yvalues": [
                  "78",
                  "113",
                  "130",
                  "146",
                  "171",
                  "169",
                  "167",
                  "166",
                  "162",
                  "150",
                  "135",
                  "118",
                  "85",
                  "49",
                  "0"
                ],
                "yDrawvalues": "527,1374,1571,2030,3002,2626,2624,2711,2491,2079,1631,1281,550,95,0",
                "mistakes": [
                  {
                    "sampleOffset": 4,
                    "mistakeId": 3
                  }
                ],
                "hiddenMistakes": [],
                "softVals": [
                  "1",
                  "1",
                  "1",
                  "1",
                  "0",
                  "1",
                  "1",
                  "1",
                  "1",
                  "1",
                  "1",
                  "1",
                  "1",
                  "1",
                  "1"
                ],
                "expectedSylsPerSecond": "0.00",
                "actualSylsPerSecond": "0.52",
                "timeLength": 1875,
                "features": [
                  {
                    "sampleYVal": 114,
                    "sampleYDrawVal": 1215
                  },
                  {
                    "sampleYVal": 139,
                    "sampleYDrawVal": 1577
                  },
                  {
                    "sampleYVal": 142,
                    "sampleYDrawVal": 1791
                  },
                  {
                    "sampleYVal": 165,
                    "sampleYDrawVal": 2515
                  },
                  {
                    "sampleYVal": 182,
                    "sampleYDrawVal": 3104
                  },
                  {
                    "sampleYVal": 169,
                    "sampleYDrawVal": 2667
                  },
                  {
                    "sampleYVal": 167,
                    "sampleYDrawVal": 2710
                  },
                  {
                    "sampleYVal": 170,
                    "sampleYDrawVal": 2798
                  },
                  {
                    "sampleYVal": 165,
                    "sampleYDrawVal": 2525
                  },
                  {
                    "sampleYVal": 134,
                    "sampleYDrawVal": 1677
                  },
                  {
                    "sampleYVal": 128,
                    "sampleYDrawVal": 1562
                  },
                  {
                    "sampleYVal": 112,
                    "sampleYDrawVal": 1156
                  },
                  {
                    "sampleYVal": 50,
                    "sampleYDrawVal": 166
                  },
                  {
                    "sampleYVal": 34,
                    "sampleYDrawVal": 53
                  }
                ],
                "recordOffset": 19805,
                "seqUrl": "1527724003056_20633_709",
                "seqDescription": {
                  "analysis": {
                    "analysisType": "2sec"
                  },
                  "completeCount": 2,
                  "currentSeqPos": 1,
                  "pbId": "1240",
                  "text": "אֶה ",
                  "type": "sentence",
                  "word": {
                    "id": 1237,
                    "language": "he",
                    "cleanInput": "נָאֶה",
                    "type": "word",
                    "data": "[{\"text\":\"\\u05e0\\u05b8\",\"sylType\":\"cv\",\"vowel\":\"A\",\"isContinous\":true},{\"text\":\"\\u05d0\\u05b6\\u05d4\",\"sylType\":\"v\",\"vowel\":\"E\"}]",
                    "isNonesense": 0,
                    "audios": ""
                  }
                },
                "mistakesObjects": {
                  "softPeak": {
                    "titlei18Key": "MISTAKES.SOFT_PEAK"
                  }
                },
                "hiddenMistakesObjects": {}
              },
              {
                "fieldType": "seq",
                "timerPos": 571,
                "calibrationEventId": "6581",
                "rate": 100,
                "deltaTimeMsFromPrevSeq": "1490.00",
                "yvalues": [
                  "74",
                  "124",
                  "149",
                  "159",
                  "164",
                  "164",
                  "180",
                  "181",
                  "176",
                  "163",
                  "139",
                  "110",
                  "91",
                  "58",
                  "0"
                ],
                "yDrawvalues": "847,1758,2270,2498,2532,2407,3106,3030,2793,2314,1552,888,635,189,0",
                "mistakes": [],
                "hiddenMistakes": [],
                "softVals": [
                  "1",
                  "1",
                  "1",
                  "1",
                  "1",
                  "1",
                  "0",
                  "0",
                  "0",
                  "1",
                  "1",
                  "1",
                  "1",
                  "1",
                  "1"
                ],
                "expectedSylsPerSecond": "0.00",
                "actualSylsPerSecond": "0.52",
                "timeLength": 1875,
                "features": [
                  {
                    "sampleYVal": 91,
                    "sampleYDrawVal": 985
                  },
                  {
                    "sampleYVal": 159,
                    "sampleYDrawVal": 2300
                  },
                  {
                    "sampleYVal": 161,
                    "sampleYDrawVal": 2452
                  },
                  {
                    "sampleYVal": 160,
                    "sampleYDrawVal": 2424
                  },
                  {
                    "sampleYVal": 166,
                    "sampleYDrawVal": 2455
                  },
                  {
                    "sampleYVal": 184,
                    "sampleYDrawVal": 3056
                  },
                  {
                    "sampleYVal": 188,
                    "sampleYDrawVal": 3281
                  },
                  {
                    "sampleYVal": 174,
                    "sampleYDrawVal": 2768
                  },
                  {
                    "sampleYVal": 174,
                    "sampleYDrawVal": 2734
                  },
                  {
                    "sampleYVal": 146,
                    "sampleYDrawVal": 1857
                  },
                  {
                    "sampleYVal": 113,
                    "sampleYDrawVal": 1084
                  },
                  {
                    "sampleYVal": 85,
                    "sampleYDrawVal": 628
                  },
                  {
                    "sampleYVal": 78,
                    "sampleYDrawVal": 492
                  },
                  {
                    "sampleYVal": 35,
                    "sampleYDrawVal": 58
                  }
                ],
                "recordOffset": 23215,
                "seqUrl": "1527724003056_20633_709",
                "seqDescription": {
                  "analysis": {
                    "analysisType": "2sec"
                  },
                  "completeCount": 3,
                  "currentSeqPos": 0,
                  "pbId": "1240",
                  "text": "מְ ",
                  "type": "sentence",
                  "word": {
                    "id": 1239,
                    "language": "he",
                    "cleanInput": "מְקַיֵּם",
                    "type": "word",
                    "data": "[{\"text\":\"\\u05de\\u05b0\",\"sylType\":\"cv\",\"vowel\":\"E\",\"isContinous\":true},{\"text\":\"\\u05e7\\u05b7\",\"sylType\":\"cv\",\"vowel\":\"A\",\"isPlosive\":true},{\"text\":\"\\u05d9\\u05bc\\u05b5\\u05dd\",\"sylType\":\"cvc\",\"vowel\":\"E\",\"isContinous\":true}]",
                    "isNonesense": 0,
                    "audios": ""
                  }
                },
                "mistakesObjects": {},
                "hiddenMistakesObjects": {}
              },
              {
                "fieldType": "seq",
                "timerPos": 574,
                "calibrationEventId": "6581",
                "rate": 100,
                "deltaTimeMsFromPrevSeq": "1469.00",
                "yvalues": [
                  "75",
                  "111",
                  "141",
                  "167",
                  "181",
                  "188",
                  "184",
                  "184",
                  "180",
                  "174",
                  "169",
                  "145",
                  "127",
                  "88",
                  "46",
                  "0"
                ],
                "yDrawvalues": "694,1196,1982,2787,3212,3432,3163,3204,3009,2757,2535,1760,1353,571,68,0",
                "mistakes": [],
                "hiddenMistakes": [],
                "softVals": [
                  "1",
                  "1",
                  "1",
                  "1",
                  "0",
                  "0",
                  "0",
                  "0",
                  "0",
                  "0",
                  "1",
                  "1",
                  "1",
                  "1",
                  "1",
                  "1"
                ],
                "expectedSylsPerSecond": "0.00",
                "actualSylsPerSecond": "0.48",
                "timeLength": 2000,
                "features": [
                  {
                    "sampleYVal": 110,
                    "sampleYDrawVal": 903
                  },
                  {
                    "sampleYVal": 122,
                    "sampleYDrawVal": 1218
                  },
                  {
                    "sampleYVal": 166,
                    "sampleYDrawVal": 2501
                  },
                  {
                    "sampleYVal": 185,
                    "sampleYDrawVal": 3158
                  },
                  {
                    "sampleYVal": 188,
                    "sampleYDrawVal": 3318
                  },
                  {
                    "sampleYVal": 189,
                    "sampleYDrawVal": 3317
                  },
                  {
                    "sampleYVal": 176,
                    "sampleYDrawVal": 2855
                  },
                  {
                    "sampleYVal": 183,
                    "sampleYDrawVal": 3183
                  },
                  {
                    "sampleYVal": 176,
                    "sampleYDrawVal": 2885
                  },
                  {
                    "sampleYVal": 175,
                    "sampleYDrawVal": 2783
                  },
                  {
                    "sampleYVal": 162,
                    "sampleYDrawVal": 2313
                  },
                  {
                    "sampleYVal": 131,
                    "sampleYDrawVal": 1580
                  },
                  {
                    "sampleYVal": 130,
                    "sampleYDrawVal": 1505
                  },
                  {
                    "sampleYVal": 45,
                    "sampleYDrawVal": 129
                  },
                  {
                    "sampleYVal": 34,
                    "sampleYDrawVal": 58
                  }
                ],
                "recordOffset": 26657,
                "seqUrl": "1527724003056_20633_709",
                "seqDescription": {
                  "analysis": {
                    "analysisType": "2sec"
                  },
                  "completeCount": 3,
                  "currentSeqPos": 1,
                  "pbId": "1240",
                  "text": "קַ ",
                  "type": "sentence",
                  "word": {
                    "id": 1239,
                    "language": "he",
                    "cleanInput": "מְקַיֵּם",
                    "type": "word",
                    "data": "[{\"text\":\"\\u05de\\u05b0\",\"sylType\":\"cv\",\"vowel\":\"E\",\"isContinous\":true},{\"text\":\"\\u05e7\\u05b7\",\"sylType\":\"cv\",\"vowel\":\"A\",\"isPlosive\":true},{\"text\":\"\\u05d9\\u05bc\\u05b5\\u05dd\",\"sylType\":\"cvc\",\"vowel\":\"E\",\"isContinous\":true}]",
                    "isNonesense": 0,
                    "audios": ""
                  }
                },
                "mistakesObjects": {},
                "hiddenMistakesObjects": {}
              },
              {
                "fieldType": "seq",
                "timerPos": 577,
                "calibrationEventId": "6581",
                "rate": 85,
                "deltaTimeMsFromPrevSeq": "1341.00",
                "yvalues": [
                  "118",
                  "145",
                  "167",
                  "172",
                  "170",
                  "168",
                  "169",
                  "171",
                  "161",
                  "134",
                  "92",
                  "60",
                  "0"
                ],
                "yDrawvalues": "1653,2079,2724,2721,2605,2561,2617,2682,2256,1413,633,269,0",
                "mistakes": [
                  {
                    "sampleOffset": 12,
                    "mistakeId": 6
                  }
                ],
                "hiddenMistakes": [],
                "softVals": [
                  "1",
                  "1",
                  "1",
                  "0",
                  "1",
                  "1",
                  "1",
                  "0",
                  "1",
                  "1",
                  "1",
                  "1",
                  "1"
                ],
                "expectedSylsPerSecond": "0.00",
                "actualSylsPerSecond": "1.21",
                "timeLength": 1625,
                "features": [
                  {
                    "sampleYVal": 145,
                    "sampleYDrawVal": 1887
                  },
                  {
                    "sampleYVal": 161,
                    "sampleYDrawVal": 2365
                  },
                  {
                    "sampleYVal": 173,
                    "sampleYDrawVal": 2701
                  },
                  {
                    "sampleYVal": 176,
                    "sampleYDrawVal": 2814
                  },
                  {
                    "sampleYVal": 177,
                    "sampleYDrawVal": 2852
                  },
                  {
                    "sampleYVal": 163,
                    "sampleYDrawVal": 2383
                  },
                  {
                    "sampleYVal": 166,
                    "sampleYDrawVal": 2505
                  },
                  {
                    "sampleYVal": 170,
                    "sampleYDrawVal": 2643
                  },
                  {
                    "sampleYVal": 152,
                    "sampleYDrawVal": 2040
                  },
                  {
                    "sampleYVal": 105,
                    "sampleYDrawVal": 897
                  },
                  {
                    "sampleYVal": 81,
                    "sampleYDrawVal": 597
                  },
                  {
                    "sampleYVal": 42,
                    "sampleYDrawVal": 112
                  }
                ],
                "recordOffset": 32,
                "seqUrl": "1527724033057_20633_709",
                "seqDescription": {
                  "analysis": {
                    "analysisType": "2sec"
                  },
                  "completeCount": 3,
                  "currentSeqPos": 2,
                  "pbId": "1240",
                  "text": "יֵּם ",
                  "type": "sentence",
                  "word": {
                    "id": 1239,
                    "language": "he",
                    "cleanInput": "מְקַיֵּם",
                    "type": "word",
                    "data": "[{\"text\":\"\\u05de\\u05b0\",\"sylType\":\"cv\",\"vowel\":\"E\",\"isContinous\":true},{\"text\":\"\\u05e7\\u05b7\",\"sylType\":\"cv\",\"vowel\":\"A\",\"isPlosive\":true},{\"text\":\"\\u05d9\\u05bc\\u05b5\\u05dd\",\"sylType\":\"cvc\",\"vowel\":\"E\",\"isContinous\":true}]",
                    "isNonesense": 0,
                    "audios": ""
                  }
                },
                "mistakesObjects": {
                  "tooShort": {
                    "titlei18Key": "MISTAKES.TOO_SHORT"
                  }
                },
                "hiddenMistakesObjects": {}
              },
              {
                "fieldType": "seq",
                "timerPos": 581,
                "calibrationEventId": "6581",
                "rate": 100,
                "deltaTimeMsFromPrevSeq": "1804.00",
                "yvalues": [
                  "73",
                  "116",
                  "154",
                  "165",
                  "183",
                  "176",
                  "172",
                  "178",
                  "181",
                  "173",
                  "158",
                  "146",
                  "126",
                  "84",
                  "54",
                  "0"
                ],
                "yDrawvalues": "513,1513,2440,2649,3371,2845,2727,3036,3156,2752,2155,1899,1370,468,140,0",
                "mistakes": [],
                "hiddenMistakes": [],
                "softVals": [
                  "1",
                  "1",
                  "1",
                  "1",
                  "0",
                  "0",
                  "0",
                  "0",
                  "0",
                  "0",
                  "1",
                  "1",
                  "1",
                  "1",
                  "1",
                  "1"
                ],
                "expectedSylsPerSecond": "0.00",
                "actualSylsPerSecond": "0.98",
                "timeLength": 2000,
                "features": [
                  {
                    "sampleYVal": 120,
                    "sampleYDrawVal": 1249
                  },
                  {
                    "sampleYVal": 151,
                    "sampleYDrawVal": 2108
                  },
                  {
                    "sampleYVal": 163,
                    "sampleYDrawVal": 2422
                  },
                  {
                    "sampleYVal": 176,
                    "sampleYDrawVal": 2913
                  },
                  {
                    "sampleYVal": 193,
                    "sampleYDrawVal": 3525
                  },
                  {
                    "sampleYVal": 170,
                    "sampleYDrawVal": 2636
                  },
                  {
                    "sampleYVal": 176,
                    "sampleYDrawVal": 2850
                  },
                  {
                    "sampleYVal": 181,
                    "sampleYDrawVal": 3106
                  },
                  {
                    "sampleYVal": 176,
                    "sampleYDrawVal": 2940
                  },
                  {
                    "sampleYVal": 166,
                    "sampleYDrawVal": 2549
                  },
                  {
                    "sampleYVal": 164,
                    "sampleYDrawVal": 2390
                  },
                  {
                    "sampleYVal": 124,
                    "sampleYDrawVal": 1333
                  },
                  {
                    "sampleYVal": 113,
                    "sampleYDrawVal": 1236
                  },
                  {
                    "sampleYVal": 50,
                    "sampleYDrawVal": 140
                  },
                  {
                    "sampleYVal": 33,
                    "sampleYDrawVal": 57
                  }
                ],
                "recordOffset": 3508,
                "seqUrl": "1527724033057_20633_709",
                "seqDescription": {
                  "analysis": {
                    "analysisType": "2sec"
                  },
                  "completeCount": 0,
                  "currentSeqPos": 0,
                  "pbId": "1279",
                  "text": "אָ ",
                  "type": "sentence",
                  "word": {
                    "id": 1264,
                    "language": "he",
                    "cleanInput": "אָנִי",
                    "type": "word",
                    "data": "[{\"text\":\"\\u05d0\\u05b8\",\"sylType\":\"v\",\"vowel\":\"A\"},{\"text\":\"\\u05e0\\u05b4\\u05d9\",\"sylType\":\"cv\",\"vowel\":\"I\",\"isContinous\":true}]",
                    "isNonesense": 0,
                    "audios": ""
                  }
                },
                "mistakesObjects": {},
                "hiddenMistakesObjects": {}
              },
              {
                "fieldType": "seq",
                "timerPos": 584,
                "calibrationEventId": "6581",
                "rate": 100,
                "deltaTimeMsFromPrevSeq": "1529.00",
                "yvalues": [
                  "66",
                  "106",
                  "131",
                  "165",
                  "185",
                  "194",
                  "192",
                  "189",
                  "164",
                  "143",
                  "126",
                  "89",
                  "67",
                  "44",
                  "0"
                ],
                "yDrawvalues": "668,1407,1738,2728,3380,3650,3454,3316,2242,1650,1298,562,372,104,0",
                "mistakes": [],
                "hiddenMistakes": [],
                "softVals": [
                  "1",
                  "1",
                  "1",
                  "1",
                  "0",
                  "0",
                  "0",
                  "0",
                  "1",
                  "1",
                  "1",
                  "1",
                  "1",
                  "1",
                  "1"
                ],
                "expectedSylsPerSecond": "0.00",
                "actualSylsPerSecond": "0.53",
                "timeLength": 1875,
                "features": [
                  {
                    "sampleYVal": 101,
                    "sampleYDrawVal": 1047
                  },
                  {
                    "sampleYVal": 127,
                    "sampleYDrawVal": 1551
                  },
                  {
                    "sampleYVal": 165,
                    "sampleYDrawVal": 2416
                  },
                  {
                    "sampleYVal": 172,
                    "sampleYDrawVal": 2672
                  },
                  {
                    "sampleYVal": 197,
                    "sampleYDrawVal": 3597
                  },
                  {
                    "sampleYVal": 198,
                    "sampleYDrawVal": 3677
                  },
                  {
                    "sampleYVal": 195,
                    "sampleYDrawVal": 3578
                  },
                  {
                    "sampleYVal": 178,
                    "sampleYDrawVal": 2869
                  },
                  {
                    "sampleYVal": 138,
                    "sampleYDrawVal": 1639
                  },
                  {
                    "sampleYVal": 135,
                    "sampleYDrawVal": 1523
                  },
                  {
                    "sampleYVal": 95,
                    "sampleYDrawVal": 840
                  },
                  {
                    "sampleYVal": 74,
                    "sampleYDrawVal": 535
                  },
                  {
                    "sampleYVal": 57,
                    "sampleYDrawVal": 337
                  },
                  {
                    "sampleYVal": 37,
                    "sampleYDrawVal": 70
                  }
                ],
                "recordOffset": 7058,
                "seqUrl": "1527724033057_20633_709",
                "seqDescription": {
                  "analysis": {
                    "analysisType": "2sec"
                  },
                  "completeCount": 0,
                  "currentSeqPos": 1,
                  "pbId": "1279",
                  "text": "נִי ",
                  "type": "sentence",
                  "word": {
                    "id": 1264,
                    "language": "he",
                    "cleanInput": "אָנִי",
                    "type": "word",
                    "data": "[{\"text\":\"\\u05d0\\u05b8\",\"sylType\":\"v\",\"vowel\":\"A\"},{\"text\":\"\\u05e0\\u05b4\\u05d9\",\"sylType\":\"cv\",\"vowel\":\"I\",\"isContinous\":true}]",
                    "isNonesense": 0,
                    "audios": ""
                  }
                },
                "mistakesObjects": {},
                "hiddenMistakesObjects": {}
              },
              {
                "fieldType": "seq",
                "timerPos": 588,
                "calibrationEventId": "6581",
                "rate": 100,
                "deltaTimeMsFromPrevSeq": "1415.00",
                "yvalues": [
                  "72",
                  "136",
                  "154",
                  "160",
                  "167",
                  "172",
                  "184",
                  "190",
                  "182",
                  "163",
                  "145",
                  "111",
                  "72",
                  "41",
                  "0"
                ],
                "yDrawvalues": "861,2080,2225,2314,2557,2753,3241,3396,2962,2260,1770,856,272,59,0",
                "mistakes": [],
                "hiddenMistakes": [],
                "softVals": [
                  "1",
                  "1",
                  "1",
                  "1",
                  "1",
                  "0",
                  "0",
                  "0",
                  "0",
                  "1",
                  "1",
                  "1",
                  "1",
                  "1",
                  "1"
                ],
                "expectedSylsPerSecond": "0.00",
                "actualSylsPerSecond": "0.52",
                "timeLength": 1875,
                "features": [
                  {
                    "sampleYVal": 112,
                    "sampleYDrawVal": 1186
                  },
                  {
                    "sampleYVal": 158,
                    "sampleYDrawVal": 2174
                  },
                  {
                    "sampleYVal": 154,
                    "sampleYDrawVal": 2069
                  },
                  {
                    "sampleYVal": 164,
                    "sampleYDrawVal": 2371
                  },
                  {
                    "sampleYVal": 173,
                    "sampleYDrawVal": 2672
                  },
                  {
                    "sampleYVal": 181,
                    "sampleYDrawVal": 2964
                  },
                  {
                    "sampleYVal": 195,
                    "sampleYDrawVal": 3568
                  },
                  {
                    "sampleYVal": 190,
                    "sampleYDrawVal": 3308
                  },
                  {
                    "sampleYVal": 164,
                    "sampleYDrawVal": 2385
                  },
                  {
                    "sampleYVal": 155,
                    "sampleYDrawVal": 2184
                  },
                  {
                    "sampleYVal": 118,
                    "sampleYDrawVal": 1194
                  },
                  {
                    "sampleYVal": 76,
                    "sampleYDrawVal": 377
                  },
                  {
                    "sampleYVal": 42,
                    "sampleYDrawVal": 86
                  },
                  {
                    "sampleYVal": 28,
                    "sampleYDrawVal": 36
                  }
                ],
                "recordOffset": 10392,
                "seqUrl": "1527724033057_20633_709",
                "seqDescription": {
                  "analysis": {
                    "analysisType": "2sec"
                  },
                  "completeCount": 1,
                  "currentSeqPos": 0,
                  "pbId": "1279",
                  "text": "וְ ",
                  "type": "sentence",
                  "word": {
                    "id": 1277,
                    "language": "he",
                    "cleanInput": "וְאַתָּה",
                    "type": "word",
                    "data": "[{\"text\":\"\\u05d5\\u05b0\",\"sylType\":\"cv\",\"vowel\":\"E\",\"isContinous\":true},{\"text\":\"\\u05d0\\u05b7\",\"sylType\":\"v\",\"vowel\":\"A\"},{\"text\":\"\\u05ea\\u05bc\\u05b8\\u05d4\",\"sylType\":\"cv\",\"vowel\":\"A\",\"isPlosive\":true}]",
                    "isNonesense": 0,
                    "audios": ""
                  }
                },
                "mistakesObjects": {},
                "hiddenMistakesObjects": {}
              },
              {
                "fieldType": "seq",
                "timerPos": 591,
                "calibrationEventId": "6581",
                "rate": 80,
                "deltaTimeMsFromPrevSeq": "1340.00",
                "yvalues": [
                  "132",
                  "157",
                  "173",
                  "172",
                  "166",
                  "173",
                  "171",
                  "168",
                  "140",
                  "82",
                  "44",
                  "0"
                ],
                "yDrawvalues": "2163,2489,2902,2756,2482,2861,2650,2595,1657,437,65,0",
                "mistakes": [
                  {
                    "sampleOffset": 2,
                    "mistakeId": 1
                  }
                ],
                "hiddenMistakes": [],
                "softVals": [
                  "1",
                  "1",
                  "0",
                  "0",
                  "1",
                  "0",
                  "0",
                  "1",
                  "1",
                  "1",
                  "1",
                  "1"
                ],
                "expectedSylsPerSecond": "0.00",
                "actualSylsPerSecond": "1.29",
                "timeLength": 1500,
                "features": [
                  {
                    "sampleYVal": 164,
                    "sampleYDrawVal": 2452
                  },
                  {
                    "sampleYVal": 178,
                    "sampleYDrawVal": 2915
                  },
                  {
                    "sampleYVal": 177,
                    "sampleYDrawVal": 2846
                  },
                  {
                    "sampleYVal": 170,
                    "sampleYDrawVal": 2659
                  },
                  {
                    "sampleYVal": 169,
                    "sampleYDrawVal": 2607
                  },
                  {
                    "sampleYVal": 174,
                    "sampleYDrawVal": 2766
                  },
                  {
                    "sampleYVal": 178,
                    "sampleYDrawVal": 2892
                  },
                  {
                    "sampleYVal": 153,
                    "sampleYDrawVal": 2202
                  },
                  {
                    "sampleYVal": 118,
                    "sampleYDrawVal": 1222
                  },
                  {
                    "sampleYVal": 49,
                    "sampleYDrawVal": 150
                  },
                  {
                    "sampleYVal": 31,
                    "sampleYDrawVal": 63
                  }
                ],
                "recordOffset": 13658,
                "seqUrl": "1527724033057_20633_709",
                "seqDescription": {
                  "analysis": {
                    "analysisType": "2sec"
                  },
                  "completeCount": 1,
                  "currentSeqPos": 1,
                  "pbId": "1279",
                  "text": "אַ ",
                  "type": "sentence",
                  "word": {
                    "id": 1277,
                    "language": "he",
                    "cleanInput": "וְאַתָּה",
                    "type": "word",
                    "data": "[{\"text\":\"\\u05d5\\u05b0\",\"sylType\":\"cv\",\"vowel\":\"E\",\"isContinous\":true},{\"text\":\"\\u05d0\\u05b7\",\"sylType\":\"v\",\"vowel\":\"A\"},{\"text\":\"\\u05ea\\u05bc\\u05b8\\u05d4\",\"sylType\":\"cv\",\"vowel\":\"A\",\"isPlosive\":true}]",
                    "isNonesense": 0,
                    "audios": ""
                  }
                },
                "mistakesObjects": {
                  "gentleOnset": {
                    "titlei18Key": "MISTAKES.GENTLE_ONSET"
                  }
                },
                "hiddenMistakesObjects": {}
              },
              {
                "fieldType": "seq",
                "timerPos": 594,
                "calibrationEventId": "6581",
                "rate": 80,
                "deltaTimeMsFromPrevSeq": "1792.00",
                "yvalues": [
                  "116",
                  "166",
                  "179",
                  "185",
                  "185",
                  "177",
                  "174",
                  "164",
                  "147",
                  "126",
                  "97",
                  "95",
                  "77",
                  "49",
                  "0"
                ],
                "yDrawvalues": "1720,2963,3130,3334,3309,2896,2737,2352,1820,1235,657,779,411,122,0",
                "mistakes": [
                  {
                    "sampleOffset": 2,
                    "mistakeId": 1
                  }
                ],
                "hiddenMistakes": [],
                "softVals": [
                  "1",
                  "1",
                  "0",
                  "0",
                  "0",
                  "0",
                  "0",
                  "1",
                  "1",
                  "1",
                  "1",
                  "1",
                  "1",
                  "1",
                  "1"
                ],
                "expectedSylsPerSecond": "0.00",
                "actualSylsPerSecond": "0.52",
                "timeLength": 1875,
                "features": [
                  {
                    "sampleYVal": 170,
                    "sampleYDrawVal": 2618
                  },
                  {
                    "sampleYVal": 185,
                    "sampleYDrawVal": 3201
                  },
                  {
                    "sampleYVal": 180,
                    "sampleYDrawVal": 3038
                  },
                  {
                    "sampleYVal": 185,
                    "sampleYDrawVal": 3280
                  },
                  {
                    "sampleYVal": 187,
                    "sampleYDrawVal": 3321
                  },
                  {
                    "sampleYVal": 164,
                    "sampleYDrawVal": 2467
                  },
                  {
                    "sampleYVal": 167,
                    "sampleYDrawVal": 2502
                  },
                  {
                    "sampleYVal": 160,
                    "sampleYDrawVal": 2273
                  },
                  {
                    "sampleYVal": 136,
                    "sampleYDrawVal": 1563
                  },
                  {
                    "sampleYVal": 113,
                    "sampleYDrawVal": 1066
                  },
                  {
                    "sampleYVal": 77,
                    "sampleYDrawVal": 434
                  },
                  {
                    "sampleYVal": 97,
                    "sampleYDrawVal": 817
                  },
                  {
                    "sampleYVal": 54,
                    "sampleYDrawVal": 219
                  },
                  {
                    "sampleYVal": 41,
                    "sampleYDrawVal": 99
                  }
                ],
                "recordOffset": 16992,
                "seqUrl": "1527724033057_20633_709",
                "seqDescription": {
                  "analysis": {
                    "analysisType": "2sec"
                  },
                  "completeCount": 1,
                  "currentSeqPos": 2,
                  "pbId": "1279",
                  "text": "תָּה ",
                  "type": "sentence",
                  "word": {
                    "id": 1277,
                    "language": "he",
                    "cleanInput": "וְאַתָּה",
                    "type": "word",
                    "data": "[{\"text\":\"\\u05d5\\u05b0\",\"sylType\":\"cv\",\"vowel\":\"E\",\"isContinous\":true},{\"text\":\"\\u05d0\\u05b7\",\"sylType\":\"v\",\"vowel\":\"A\"},{\"text\":\"\\u05ea\\u05bc\\u05b8\\u05d4\",\"sylType\":\"cv\",\"vowel\":\"A\",\"isPlosive\":true}]",
                    "isNonesense": 0,
                    "audios": ""
                  }
                },
                "mistakesObjects": {
                  "gentleOnset": {
                    "titlei18Key": "MISTAKES.GENTLE_ONSET"
                  }
                },
                "hiddenMistakesObjects": {}
              },
              {
                "fieldType": "seq",
                "timerPos": 598,
                "calibrationEventId": "6581",
                "rate": 80,
                "deltaTimeMsFromPrevSeq": "1450.00",
                "yvalues": [
                  "104",
                  "133",
                  "149",
                  "156",
                  "163",
                  "174",
                  "179",
                  "177",
                  "183",
                  "180",
                  "160",
                  "136",
                  "98",
                  "73",
                  "55",
                  "0"
                ],
                "yDrawvalues": "1381,1787,2087,2181,2403,2832,2930,2847,3134,2904,2125,1441,606,316,158,0",
                "mistakes": [
                  {
                    "sampleOffset": 6,
                    "mistakeId": 5
                  }
                ],
                "hiddenMistakes": [],
                "softVals": [
                  "1",
                  "1",
                  "1",
                  "1",
                  "1",
                  "0",
                  "0",
                  "0",
                  "0",
                  "0",
                  "1",
                  "1",
                  "1",
                  "1",
                  "1",
                  "1"
                ],
                "expectedSylsPerSecond": "0.00",
                "actualSylsPerSecond": "0.95",
                "timeLength": 2000,
                "features": [
                  {
                    "sampleYVal": 132,
                    "sampleYDrawVal": 1552
                  },
                  {
                    "sampleYVal": 140,
                    "sampleYDrawVal": 1734
                  },
                  {
                    "sampleYVal": 157,
                    "sampleYDrawVal": 2181
                  },
                  {
                    "sampleYVal": 150,
                    "sampleYDrawVal": 1947
                  },
                  {
                    "sampleYVal": 162,
                    "sampleYDrawVal": 2321
                  },
                  {
                    "sampleYVal": 174,
                    "sampleYDrawVal": 2680
                  },
                  {
                    "sampleYVal": 179,
                    "sampleYDrawVal": 2882
                  },
                  {
                    "sampleYVal": 167,
                    "sampleYDrawVal": 2491
                  },
                  {
                    "sampleYVal": 191,
                    "sampleYDrawVal": 3380
                  },
                  {
                    "sampleYVal": 176,
                    "sampleYDrawVal": 2783
                  },
                  {
                    "sampleYVal": 140,
                    "sampleYDrawVal": 1684
                  },
                  {
                    "sampleYVal": 108,
                    "sampleYDrawVal": 897
                  },
                  {
                    "sampleYVal": 60,
                    "sampleYDrawVal": 233
                  },
                  {
                    "sampleYVal": 66,
                    "sampleYDrawVal": 286
                  },
                  {
                    "sampleYVal": 42,
                    "sampleYDrawVal": 90
                  }
                ],
                "recordOffset": 20428,
                "seqUrl": "1527724033057_20633_709",
                "seqDescription": {
                  "analysis": {
                    "analysisType": "2sec"
                  },
                  "completeCount": 2,
                  "currentSeqPos": 0,
                  "pbId": "1279",
                  "text": "נְ ",
                  "type": "sentence",
                  "word": {
                    "id": 1278,
                    "language": "he",
                    "cleanInput": "נְשַׁנֶּה",
                    "type": "word",
                    "data": "[{\"text\":\"\\u05e0\\u05b0\",\"sylType\":\"cv\",\"vowel\":\"E\",\"isContinous\":true},{\"text\":\"\\u05e9\\u05c1\\u05b7\",\"sylType\":\"cv\",\"vowel\":\"A\",\"isContinous\":true},{\"text\":\"\\u05e0\\u05bc\\u05b6\\u05d4\",\"sylType\":\"cv\",\"vowel\":\"E\",\"isContinous\":true}]",
                    "isNonesense": 0,
                    "audios": ""
                  }
                },
                "mistakesObjects": {
                  "volControlUp": {
                    "titlei18Key": "MISTAKES.VOLCONTROL_UP"
                  }
                },
                "hiddenMistakesObjects": {}
              },
              {
                "fieldType": "seq",
                "timerPos": 601,
                "calibrationEventId": "6581",
                "rate": 85,
                "deltaTimeMsFromPrevSeq": "1732.00",
                "yvalues": [
                  "79",
                  "133",
                  "156",
                  "161",
                  "176",
                  "188",
                  "188",
                  "174",
                  "158",
                  "122",
                  "105",
                  "73",
                  "47",
                  "0"
                ],
                "yDrawvalues": "912,1925,2335,2390,2969,3368,3290,2672,2143,1100,879,427,133,0",
                "mistakes": [
                  {
                    "sampleOffset": 13,
                    "mistakeId": 6
                  }
                ],
                "hiddenMistakes": [],
                "softVals": [
                  "1",
                  "1",
                  "1",
                  "1",
                  "0",
                  "0",
                  "0",
                  "0",
                  "1",
                  "1",
                  "1",
                  "1",
                  "1",
                  "1"
                ],
                "expectedSylsPerSecond": "0.00",
                "actualSylsPerSecond": "0.56",
                "timeLength": 1750,
                "features": [
                  {
                    "sampleYVal": 139,
                    "sampleYDrawVal": 1706
                  },
                  {
                    "sampleYVal": 158,
                    "sampleYDrawVal": 2209
                  },
                  {
                    "sampleYVal": 157,
                    "sampleYDrawVal": 2205
                  },
                  {
                    "sampleYVal": 167,
                    "sampleYDrawVal": 2510
                  },
                  {
                    "sampleYVal": 192,
                    "sampleYDrawVal": 3396
                  },
                  {
                    "sampleYVal": 193,
                    "sampleYDrawVal": 3431
                  },
                  {
                    "sampleYVal": 188,
                    "sampleYDrawVal": 3240
                  },
                  {
                    "sampleYVal": 161,
                    "sampleYDrawVal": 2335
                  },
                  {
                    "sampleYVal": 137,
                    "sampleYDrawVal": 1608
                  },
                  {
                    "sampleYVal": 107,
                    "sampleYDrawVal": 982
                  },
                  {
                    "sampleYVal": 89,
                    "sampleYDrawVal": 655
                  },
                  {
                    "sampleYVal": 42,
                    "sampleYDrawVal": 142
                  },
                  {
                    "sampleYVal": 41,
                    "sampleYDrawVal": 136
                  }
                ],
                "recordOffset": 24198,
                "seqUrl": "1527724033057_20633_709",
                "seqDescription": {
                  "analysis": {
                    "analysisType": "2sec"
                  },
                  "completeCount": 2,
                  "currentSeqPos": 1,
                  "pbId": "1279",
                  "text": "שַׁ ",
                  "type": "sentence",
                  "word": {
                    "id": 1278,
                    "language": "he",
                    "cleanInput": "נְשַׁנֶּה",
                    "type": "word",
                    "data": "[{\"text\":\"\\u05e0\\u05b0\",\"sylType\":\"cv\",\"vowel\":\"E\",\"isContinous\":true},{\"text\":\"\\u05e9\\u05c1\\u05b7\",\"sylType\":\"cv\",\"vowel\":\"A\",\"isContinous\":true},{\"text\":\"\\u05e0\\u05bc\\u05b6\\u05d4\",\"sylType\":\"cv\",\"vowel\":\"E\",\"isContinous\":true}]",
                    "isNonesense": 0,
                    "audios": ""
                  }
                },
                "mistakesObjects": {
                  "tooShort": {
                    "titlei18Key": "MISTAKES.TOO_SHORT"
                  }
                },
                "hiddenMistakesObjects": {}
              },
              {
                "fieldType": "seq",
                "timerPos": 605,
                "calibrationEventId": "6581",
                "rate": 100,
                "deltaTimeMsFromPrevSeq": "1657.00",
                "yvalues": [
                  "105",
                  "126",
                  "158",
                  "171",
                  "174",
                  "176",
                  "186",
                  "188",
                  "168",
                  "138",
                  "112",
                  "94",
                  "72",
                  "53",
                  "0"
                ],
                "yDrawvalues": "1471,1497,2444,2748,2761,2827,3272,3262,2398,1437,898,584,323,158,0",
                "mistakes": [],
                "hiddenMistakes": [],
                "softVals": [
                  "1",
                  "1",
                  "1",
                  "0",
                  "0",
                  "0",
                  "0",
                  "0",
                  "1",
                  "1",
                  "1",
                  "1",
                  "1",
                  "1",
                  "1"
                ],
                "expectedSylsPerSecond": "0.00",
                "actualSylsPerSecond": "0.52",
                "timeLength": 1875,
                "features": [
                  {
                    "sampleYVal": 152,
                    "sampleYDrawVal": 2025
                  },
                  {
                    "sampleYVal": 149,
                    "sampleYDrawVal": 1889
                  },
                  {
                    "sampleYVal": 171,
                    "sampleYDrawVal": 2597
                  },
                  {
                    "sampleYVal": 174,
                    "sampleYDrawVal": 2715
                  },
                  {
                    "sampleYVal": 173,
                    "sampleYDrawVal": 2684
                  },
                  {
                    "sampleYVal": 186,
                    "sampleYDrawVal": 3164
                  },
                  {
                    "sampleYVal": 192,
                    "sampleYDrawVal": 3397
                  },
                  {
                    "sampleYVal": 185,
                    "sampleYDrawVal": 3096
                  },
                  {
                    "sampleYVal": 155,
                    "sampleYDrawVal": 2082
                  },
                  {
                    "sampleYVal": 117,
                    "sampleYDrawVal": 1087
                  },
                  {
                    "sampleYVal": 73,
                    "sampleYDrawVal": 346
                  },
                  {
                    "sampleYVal": 93,
                    "sampleYDrawVal": 615
                  },
                  {
                    "sampleYVal": 59,
                    "sampleYDrawVal": 216
                  },
                  {
                    "sampleYVal": 34,
                    "sampleYDrawVal": 64
                  }
                ],
                "recordOffset": 27653,
                "seqUrl": "1527724033057_20633_709",
                "seqDescription": {
                  "analysis": {
                    "analysisType": "2sec"
                  },
                  "completeCount": 2,
                  "currentSeqPos": 2,
                  "pbId": "1279",
                  "text": "נֶּה ",
                  "type": "sentence",
                  "word": {
                    "id": 1278,
                    "language": "he",
                    "cleanInput": "נְשַׁנֶּה",
                    "type": "word",
                    "data": "[{\"text\":\"\\u05e0\\u05b0\",\"sylType\":\"cv\",\"vowel\":\"E\",\"isContinous\":true},{\"text\":\"\\u05e9\\u05c1\\u05b7\",\"sylType\":\"cv\",\"vowel\":\"A\",\"isContinous\":true},{\"text\":\"\\u05e0\\u05bc\\u05b6\\u05d4\",\"sylType\":\"cv\",\"vowel\":\"E\",\"isContinous\":true}]",
                    "isNonesense": 0,
                    "audios": ""
                  }
                },
                "mistakesObjects": {},
                "hiddenMistakesObjects": {}
              },
              {
                "fieldType": "seq",
                "timerPos": 608,
                "calibrationEventId": "6581",
                "rate": 100,
                "deltaTimeMsFromPrevSeq": "1529.00",
                "yvalues": [
                  "83",
                  "125",
                  "149",
                  "163",
                  "175",
                  "183",
                  "186",
                  "189",
                  "187",
                  "168",
                  "137",
                  "94",
                  "67",
                  "56",
                  "0"
                ],
                "yDrawvalues": "902,1632,2103,2474,2854,3120,3217,3331,3225,2396,1346,590,262,188,0",
                "mistakes": [],
                "hiddenMistakes": [],
                "softVals": [
                  "1",
                  "1",
                  "1",
                  "1",
                  "0",
                  "0",
                  "0",
                  "0",
                  "0",
                  "1",
                  "1",
                  "1",
                  "1",
                  "1",
                  "1"
                ],
                "expectedSylsPerSecond": "0.00",
                "actualSylsPerSecond": "0.52",
                "timeLength": 1875,
                "features": [
                  {
                    "sampleYVal": 116,
                    "sampleYDrawVal": 1259
                  },
                  {
                    "sampleYVal": 145,
                    "sampleYDrawVal": 1764
                  },
                  {
                    "sampleYVal": 165,
                    "sampleYDrawVal": 2388
                  },
                  {
                    "sampleYVal": 168,
                    "sampleYDrawVal": 2498
                  },
                  {
                    "sampleYVal": 178,
                    "sampleYDrawVal": 2850
                  },
                  {
                    "sampleYVal": 188,
                    "sampleYDrawVal": 3219
                  },
                  {
                    "sampleYVal": 192,
                    "sampleYDrawVal": 3390
                  },
                  {
                    "sampleYVal": 188,
                    "sampleYDrawVal": 3248
                  },
                  {
                    "sampleYVal": 186,
                    "sampleYDrawVal": 3135
                  },
                  {
                    "sampleYVal": 142,
                    "sampleYDrawVal": 1722
                  },
                  {
                    "sampleYVal": 118,
                    "sampleYDrawVal": 1097
                  },
                  {
                    "sampleYVal": 83,
                    "sampleYDrawVal": 561
                  },
                  {
                    "sampleYVal": 58,
                    "sampleYDrawVal": 248
                  },
                  {
                    "sampleYVal": 39,
                    "sampleYDrawVal": 82
                  }
                ],
                "recordOffset": 1105,
                "seqUrl": "1527724063056_20633_709",
                "seqDescription": {
                  "analysis": {
                    "analysisType": "2sec"
                  },
                  "completeCount": 3,
                  "currentSeqPos": 0,
                  "pbId": "1279",
                  "text": "אֶת ",
                  "type": "sentence",
                  "word": {
                    "id": 748,
                    "language": "he",
                    "cleanInput": "אֶת",
                    "type": "word",
                    "data": "[{\"text\":\"\\u05d0\\u05b6\\u05ea\",\"sylType\":\"vc\",\"vowel\":\"E\",\"isPlosive\":true}]",
                    "isNonesense": 0,
                    "audios": ""
                  }
                },
                "mistakesObjects": {},
                "hiddenMistakesObjects": {}
              },
              {
                "fieldType": "seq",
                "timerPos": 612,
                "calibrationEventId": "6581",
                "rate": 100,
                "deltaTimeMsFromPrevSeq": "1667.00",
                "yvalues": [
                  "86",
                  "126",
                  "158",
                  "177",
                  "179",
                  "180",
                  "177",
                  "174",
                  "166",
                  "150",
                  "134",
                  "109",
                  "92",
                  "81",
                  "53",
                  "0"
                ],
                "yDrawvalues": "950,1687,2548,3082,3005,3040,2942,2855,2504,1915,1495,853,651,522,133,0",
                "mistakes": [],
                "hiddenMistakes": [],
                "softVals": [
                  "1",
                  "1",
                  "1",
                  "0",
                  "0",
                  "0",
                  "0",
                  "0",
                  "1",
                  "1",
                  "1",
                  "1",
                  "1",
                  "1",
                  "1",
                  "1"
                ],
                "expectedSylsPerSecond": "0.00",
                "actualSylsPerSecond": "0.49",
                "timeLength": 2000,
                "features": [
                  {
                    "sampleYVal": 121,
                    "sampleYDrawVal": 1289
                  },
                  {
                    "sampleYVal": 141,
                    "sampleYDrawVal": 1786
                  },
                  {
                    "sampleYVal": 175,
                    "sampleYDrawVal": 2784
                  },
                  {
                    "sampleYVal": 176,
                    "sampleYDrawVal": 2785
                  },
                  {
                    "sampleYVal": 178,
                    "sampleYDrawVal": 2955
                  },
                  {
                    "sampleYVal": 179,
                    "sampleYDrawVal": 2986
                  },
                  {
                    "sampleYVal": 182,
                    "sampleYDrawVal": 3115
                  },
                  {
                    "sampleYVal": 169,
                    "sampleYDrawVal": 2705
                  },
                  {
                    "sampleYVal": 160,
                    "sampleYDrawVal": 2229
                  },
                  {
                    "sampleYVal": 133,
                    "sampleYDrawVal": 1605
                  },
                  {
                    "sampleYVal": 117,
                    "sampleYDrawVal": 1196
                  },
                  {
                    "sampleYVal": 107,
                    "sampleYDrawVal": 890
                  },
                  {
                    "sampleYVal": 87,
                    "sampleYDrawVal": 661
                  },
                  {
                    "sampleYVal": 58,
                    "sampleYDrawVal": 228
                  },
                  {
                    "sampleYVal": 44,
                    "sampleYDrawVal": 124
                  }
                ],
                "recordOffset": 4698,
                "seqUrl": "1527724063056_20633_709",
                "seqDescription": {
                  "analysis": {
                    "analysisType": "2sec"
                  },
                  "completeCount": 4,
                  "currentSeqPos": 0,
                  "pbId": "1279",
                  "text": "הָ ",
                  "type": "sentence",
                  "word": {
                    "id": 800,
                    "language": "he",
                    "cleanInput": "הָעוֹלָם",
                    "type": "word",
                    "data": "[{\"text\":\"\\u05d4\\u05b8\",\"sylType\":\"v\",\"vowel\":\"A\"},{\"text\":\"\\u05e2\\u05d5\\u05b9\",\"sylType\":\"v\",\"vowel\":\"O\"},{\"text\":\"\\u05dc\\u05b8\\u05dd\",\"sylType\":\"cvc\",\"vowel\":\"A\",\"isContinous\":true}]",
                    "isNonesense": 0,
                    "audios": ""
                  }
                },
                "mistakesObjects": {},
                "hiddenMistakesObjects": {}
              },
              {
                "fieldType": "seq",
                "timerPos": 619,
                "calibrationEventId": "6581",
                "rate": 95,
                "deltaTimeMsFromPrevSeq": "1673.00",
                "yvalues": [
                  "79",
                  "137",
                  "159",
                  "180",
                  "190",
                  "198",
                  "194",
                  "189",
                  "182",
                  "171",
                  "162",
                  "151",
                  "136",
                  "81",
                  "46",
                  "0"
                ],
                "yDrawvalues": "1015,2030,2477,3202,3518,3813,3580,3371,3032,2583,2279,1894,1589,412,96,0",
                "mistakes": [
                  {
                    "sampleOffset": 4,
                    "mistakeId": 17
                  }
                ],
                "hiddenMistakes": [],
                "softVals": [
                  "1",
                  "1",
                  "1",
                  "0",
                  "0",
                  "0",
                  "0",
                  "0",
                  "0",
                  "0",
                  "1",
                  "1",
                  "1",
                  "1",
                  "1",
                  "1"
                ],
                "expectedSylsPerSecond": "0.00",
                "actualSylsPerSecond": "0.49",
                "timeLength": 2000,
                "features": [
                  {
                    "sampleYVal": 136,
                    "sampleYDrawVal": 1593
                  },
                  {
                    "sampleYVal": 164,
                    "sampleYDrawVal": 2438
                  },
                  {
                    "sampleYVal": 168,
                    "sampleYDrawVal": 2574
                  },
                  {
                    "sampleYVal": 186,
                    "sampleYDrawVal": 3195
                  },
                  {
                    "sampleYVal": 200,
                    "sampleYDrawVal": 3830
                  },
                  {
                    "sampleYVal": 197,
                    "sampleYDrawVal": 3702
                  },
                  {
                    "sampleYVal": 192,
                    "sampleYDrawVal": 3489
                  },
                  {
                    "sampleYVal": 188,
                    "sampleYDrawVal": 3336
                  },
                  {
                    "sampleYVal": 182,
                    "sampleYDrawVal": 3047
                  },
                  {
                    "sampleYVal": 166,
                    "sampleYDrawVal": 2487
                  },
                  {
                    "sampleYVal": 145,
                    "sampleYDrawVal": 1802
                  },
                  {
                    "sampleYVal": 147,
                    "sampleYDrawVal": 1804
                  },
                  {
                    "sampleYVal": 118,
                    "sampleYDrawVal": 1295
                  },
                  {
                    "sampleYVal": 44,
                    "sampleYDrawVal": 124
                  },
                  {
                    "sampleYVal": 43,
                    "sampleYDrawVal": 118
                  }
                ],
                "recordOffset": 11537,
                "seqUrl": "1527724063056_20633_709",
                "seqDescription": {
                  "analysis": {
                    "analysisType": "2sec"
                  },
                  "completeCount": 4,
                  "currentSeqPos": 1,
                  "pbId": "1279",
                  "text": "עוֹ ",
                  "type": "sentence",
                  "word": {
                    "id": 800,
                    "language": "he",
                    "cleanInput": "הָעוֹלָם",
                    "type": "word",
                    "data": "[{\"text\":\"\\u05d4\\u05b8\",\"sylType\":\"v\",\"vowel\":\"A\"},{\"text\":\"\\u05e2\\u05d5\\u05b9\",\"sylType\":\"v\",\"vowel\":\"O\"},{\"text\":\"\\u05dc\\u05b8\\u05dd\",\"sylType\":\"cvc\",\"vowel\":\"A\",\"isContinous\":true}]",
                    "isNonesense": 0,
                    "audios": ""
                  }
                },
                "mistakesObjects": {
                  "intensePeak": {
                    "titlei18Key": "MISTAKES.INTENSE_PEAK"
                  }
                },
                "hiddenMistakesObjects": {}
              },
              {
                "fieldType": "seq",
                "timerPos": 622,
                "calibrationEventId": "6581",
                "rate": 100,
                "deltaTimeMsFromPrevSeq": "1480.00",
                "yvalues": [
                  "71",
                  "127",
                  "152",
                  "162",
                  "166",
                  "170",
                  "176",
                  "193",
                  "190",
                  "179",
                  "153",
                  "114",
                  "79",
                  "57",
                  "0"
                ],
                "yDrawvalues": "677,1674,2257,2406,2507,2633,2890,3682,3349,2903,1904,1049,396,193,0",
                "mistakes": [],
                "hiddenMistakes": [],
                "softVals": [
                  "1",
                  "1",
                  "1",
                  "1",
                  "1",
                  "1",
                  "0",
                  "0",
                  "0",
                  "0",
                  "1",
                  "1",
                  "1",
                  "1",
                  "1"
                ],
                "expectedSylsPerSecond": "0.00",
                "actualSylsPerSecond": "0.52",
                "timeLength": 1875,
                "features": [
                  {
                    "sampleYVal": 118,
                    "sampleYDrawVal": 1092
                  },
                  {
                    "sampleYVal": 149,
                    "sampleYDrawVal": 1911
                  },
                  {
                    "sampleYVal": 152,
                    "sampleYDrawVal": 2056
                  },
                  {
                    "sampleYVal": 168,
                    "sampleYDrawVal": 2503
                  },
                  {
                    "sampleYVal": 175,
                    "sampleYDrawVal": 2747
                  },
                  {
                    "sampleYVal": 174,
                    "sampleYDrawVal": 2725
                  },
                  {
                    "sampleYVal": 186,
                    "sampleYDrawVal": 3182
                  },
                  {
                    "sampleYVal": 200,
                    "sampleYDrawVal": 3749
                  },
                  {
                    "sampleYVal": 178,
                    "sampleYDrawVal": 2872
                  },
                  {
                    "sampleYVal": 155,
                    "sampleYDrawVal": 2235
                  },
                  {
                    "sampleYVal": 137,
                    "sampleYDrawVal": 1600
                  },
                  {
                    "sampleYVal": 87,
                    "sampleYDrawVal": 595
                  },
                  {
                    "sampleYVal": 65,
                    "sampleYDrawVal": 344
                  },
                  {
                    "sampleYVal": 43,
                    "sampleYDrawVal": 128
                  }
                ],
                "recordOffset": 15065,
                "seqUrl": "1527724063056_20633_709",
                "seqDescription": {
                  "analysis": {
                    "analysisType": "2sec"
                  },
                  "completeCount": 4,
                  "currentSeqPos": 2,
                  "pbId": "1279",
                  "text": "לָם ",
                  "type": "sentence",
                  "word": {
                    "id": 800,
                    "language": "he",
                    "cleanInput": "הָעוֹלָם",
                    "type": "word",
                    "data": "[{\"text\":\"\\u05d4\\u05b8\",\"sylType\":\"v\",\"vowel\":\"A\"},{\"text\":\"\\u05e2\\u05d5\\u05b9\",\"sylType\":\"v\",\"vowel\":\"O\"},{\"text\":\"\\u05dc\\u05b8\\u05dd\",\"sylType\":\"cvc\",\"vowel\":\"A\",\"isContinous\":true}]",
                    "isNonesense": 0,
                    "audios": ""
                  }
                },
                "mistakesObjects": {},
                "hiddenMistakesObjects": {}
              },
              {
                "fieldType": "seq",
                "timerPos": 626,
                "calibrationEventId": "6581",
                "rate": 100,
                "deltaTimeMsFromPrevSeq": "1788.00",
                "yvalues": [
                  "81",
                  "135",
                  "162",
                  "176",
                  "177",
                  "178",
                  "172",
                  "155",
                  "132",
                  "115",
                  "94",
                  "82",
                  "63",
                  "38",
                  "0"
                ],
                "yDrawvalues": "880,1928,2668,2952,2864,2915,2591,1939,1297,934,567,428,209,56,0",
                "mistakes": [],
                "hiddenMistakes": [],
                "softVals": [
                  "1",
                  "1",
                  "1",
                  "0",
                  "0",
                  "0",
                  "0",
                  "1",
                  "1",
                  "1",
                  "1",
                  "1",
                  "1",
                  "1",
                  "1"
                ],
                "expectedSylsPerSecond": "0.00",
                "actualSylsPerSecond": "0.52",
                "timeLength": 1875,
                "features": [
                  {
                    "sampleYVal": 141,
                    "sampleYDrawVal": 1634
                  },
                  {
                    "sampleYVal": 169,
                    "sampleYDrawVal": 2613
                  },
                  {
                    "sampleYVal": 173,
                    "sampleYDrawVal": 2772
                  },
                  {
                    "sampleYVal": 182,
                    "sampleYDrawVal": 3019
                  },
                  {
                    "sampleYVal": 183,
                    "sampleYDrawVal": 3087
                  },
                  {
                    "sampleYVal": 173,
                    "sampleYDrawVal": 2663
                  },
                  {
                    "sampleYVal": 163,
                    "sampleYDrawVal": 2327
                  },
                  {
                    "sampleYVal": 152,
                    "sampleYDrawVal": 1957
                  },
                  {
                    "sampleYVal": 120,
                    "sampleYDrawVal": 1122
                  },
                  {
                    "sampleYVal": 101,
                    "sampleYDrawVal": 721
                  },
                  {
                    "sampleYVal": 90,
                    "sampleYDrawVal": 572
                  },
                  {
                    "sampleYVal": 72,
                    "sampleYDrawVal": 324
                  },
                  {
                    "sampleYVal": 46,
                    "sampleYDrawVal": 109
                  },
                  {
                    "sampleYVal": 17,
                    "sampleYDrawVal": 17
                  }
                ],
                "recordOffset": 18773,
                "seqUrl": "1527724063056_20633_709",
                "seqDescription": {
                  "analysis": {
                    "analysisType": "2sec"
                  },
                  "completeCount": 0,
                  "currentSeqPos": 0,
                  "pbId": "2056",
                  "text": "אִם ",
                  "type": "sentence",
                  "word": {
                    "id": 1112,
                    "language": "he",
                    "cleanInput": "אִם",
                    "type": "word",
                    "data": "[{\"text\":\"\\u05d0\\u05b4\\u05dd\",\"sylType\":\"vc\",\"vowel\":\"I\",\"isContinous\":true}]",
                    "isNonesense": 0,
                    "audios": "[]"
                  }
                },
                "mistakesObjects": {},
                "hiddenMistakesObjects": {}
              },
              {
                "fieldType": "seq",
                "timerPos": 629,
                "calibrationEventId": "6581",
                "rate": 100,
                "deltaTimeMsFromPrevSeq": "960.00",
                "yvalues": [
                  "70",
                  "137",
                  "158",
                  "175",
                  "187",
                  "186",
                  "175",
                  "165",
                  "158",
                  "158",
                  "150",
                  "134",
                  "110",
                  "59",
                  "0"
                ],
                "yDrawvalues": "760,2201,2476,3059,3459,3276,2690,2346,2178,2214,1896,1427,876,120,0",
                "mistakes": [],
                "hiddenMistakes": [],
                "softVals": [
                  "1",
                  "1",
                  "1",
                  "0",
                  "0",
                  "0",
                  "0",
                  "1",
                  "1",
                  "1",
                  "1",
                  "1",
                  "1",
                  "1",
                  "1"
                ],
                "expectedSylsPerSecond": "0.00",
                "actualSylsPerSecond": "0.52",
                "timeLength": 1875,
                "features": [
                  {
                    "sampleYVal": 134,
                    "sampleYDrawVal": 1584
                  },
                  {
                    "sampleYVal": 163,
                    "sampleYDrawVal": 2422
                  },
                  {
                    "sampleYVal": 165,
                    "sampleYDrawVal": 2529
                  },
                  {
                    "sampleYVal": 187,
                    "sampleYDrawVal": 3346
                  },
                  {
                    "sampleYVal": 192,
                    "sampleYDrawVal": 3539
                  },
                  {
                    "sampleYVal": 184,
                    "sampleYDrawVal": 3174
                  },
                  {
                    "sampleYVal": 175,
                    "sampleYDrawVal": 2747
                  },
                  {
                    "sampleYVal": 159,
                    "sampleYDrawVal": 2207
                  },
                  {
                    "sampleYVal": 153,
                    "sampleYDrawVal": 2061
                  },
                  {
                    "sampleYVal": 151,
                    "sampleYDrawVal": 2030
                  },
                  {
                    "sampleYVal": 147,
                    "sampleYDrawVal": 1874
                  },
                  {
                    "sampleYVal": 128,
                    "sampleYDrawVal": 1412
                  },
                  {
                    "sampleYVal": 67,
                    "sampleYDrawVal": 277
                  },
                  {
                    "sampleYVal": 37,
                    "sampleYDrawVal": 68
                  }
                ],
                "recordOffset": 21652,
                "seqUrl": "1527724063056_20633_709",
                "seqDescription": {
                  "analysis": {
                    "analysisType": "2sec"
                  },
                  "completeCount": 1,
                  "currentSeqPos": 0,
                  "pbId": "2056",
                  "text": "תִּרְ ",
                  "type": "sentence",
                  "word": {
                    "id": 2055,
                    "language": "he",
                    "cleanInput": "תִּרְצוּ",
                    "type": "word",
                    "data": "[{\"text\":\"\\u05ea\\u05bc\\u05b4\\u05e8\\u05b0\",\"sylType\":\"cvc\",\"vowel\":\"I\",\"isPlosive\":true,\"isContinous\":true},{\"text\":\"\\u05e6\\u05d5\\u05bc\",\"sylType\":\"cv\",\"vowel\":\"U\",\"isContinous\":true}]",
                    "isNonesense": 0,
                    "audios": "[]"
                  }
                },
                "mistakesObjects": {},
                "hiddenMistakesObjects": {}
              },
              {
                "fieldType": "seq",
                "timerPos": 632,
                "calibrationEventId": "6581",
                "rate": 85,
                "deltaTimeMsFromPrevSeq": "1600.00",
                "yvalues": [
                  "92",
                  "121",
                  "136",
                  "148",
                  "160",
                  "172",
                  "164",
                  "149",
                  "126",
                  "93",
                  "63",
                  "41",
                  "0"
                ],
                "yDrawvalues": "1089,1659,2050,2388,2767,3066,2516,2066,1539,748,271,82,0",
                "mistakes": [
                  {
                    "sampleOffset": 12,
                    "mistakeId": 6
                  }
                ],
                "hiddenMistakes": [],
                "softVals": [
                  "1",
                  "1",
                  "1",
                  "1",
                  "1",
                  "0",
                  "1",
                  "1",
                  "1",
                  "1",
                  "1",
                  "1",
                  "1"
                ],
                "expectedSylsPerSecond": "0.00",
                "actualSylsPerSecond": "0.59",
                "timeLength": 1625,
                "features": [
                  {
                    "sampleYVal": 123,
                    "sampleYDrawVal": 1485
                  },
                  {
                    "sampleYVal": 132,
                    "sampleYDrawVal": 1891
                  },
                  {
                    "sampleYVal": 144,
                    "sampleYDrawVal": 2151
                  },
                  {
                    "sampleYVal": 157,
                    "sampleYDrawVal": 2487
                  },
                  {
                    "sampleYVal": 169,
                    "sampleYDrawVal": 2914
                  },
                  {
                    "sampleYVal": 175,
                    "sampleYDrawVal": 3029
                  },
                  {
                    "sampleYVal": 157,
                    "sampleYDrawVal": 2313
                  },
                  {
                    "sampleYVal": 132,
                    "sampleYDrawVal": 1839
                  },
                  {
                    "sampleYVal": 119,
                    "sampleYDrawVal": 1416
                  },
                  {
                    "sampleYVal": 75,
                    "sampleYDrawVal": 637
                  },
                  {
                    "sampleYVal": 43,
                    "sampleYDrawVal": 124
                  },
                  {
                    "sampleYVal": 37,
                    "sampleYDrawVal": 78
                  }
                ],
                "recordOffset": 25197,
                "seqUrl": "1527724063056_20633_709",
                "seqDescription": {
                  "analysis": {
                    "analysisType": "2sec"
                  },
                  "completeCount": 1,
                  "currentSeqPos": 1,
                  "pbId": "2056",
                  "text": "צוּ ",
                  "type": "sentence",
                  "word": {
                    "id": 2055,
                    "language": "he",
                    "cleanInput": "תִּרְצוּ",
                    "type": "word",
                    "data": "[{\"text\":\"\\u05ea\\u05bc\\u05b4\\u05e8\\u05b0\",\"sylType\":\"cvc\",\"vowel\":\"I\",\"isPlosive\":true,\"isContinous\":true},{\"text\":\"\\u05e6\\u05d5\\u05bc\",\"sylType\":\"cv\",\"vowel\":\"U\",\"isContinous\":true}]",
                    "isNonesense": 0,
                    "audios": "[]"
                  }
                },
                "mistakesObjects": {
                  "tooShort": {
                    "titlei18Key": "MISTAKES.TOO_SHORT"
                  }
                },
                "hiddenMistakesObjects": {}
              },
              {
                "fieldType": "seq",
                "timerPos": 636,
                "calibrationEventId": "6581",
                "rate": 80,
                "deltaTimeMsFromPrevSeq": "1485.00",
                "yvalues": [
                  "112",
                  "146",
                  "166",
                  "172",
                  "170",
                  "170",
                  "175",
                  "175",
                  "168",
                  "154",
                  "139",
                  "108",
                  "66",
                  "40",
                  "0"
                ],
                "yDrawvalues": "1528,2166,2624,2768,2675,2639,2823,2777,2474,1957,1552,851,214,66,0",
                "mistakes": [
                  {
                    "sampleOffset": 3,
                    "mistakeId": 5
                  }
                ],
                "hiddenMistakes": [],
                "softVals": [
                  "1",
                  "1",
                  "1",
                  "0",
                  "1",
                  "1",
                  "0",
                  "0",
                  "1",
                  "1",
                  "1",
                  "1",
                  "1",
                  "1",
                  "1"
                ],
                "expectedSylsPerSecond": "0.00",
                "actualSylsPerSecond": "1.04",
                "timeLength": 1875,
                "features": [
                  {
                    "sampleYVal": 149,
                    "sampleYDrawVal": 1993
                  },
                  {
                    "sampleYVal": 174,
                    "sampleYDrawVal": 2723
                  },
                  {
                    "sampleYVal": 172,
                    "sampleYDrawVal": 2651
                  },
                  {
                    "sampleYVal": 171,
                    "sampleYDrawVal": 2707
                  },
                  {
                    "sampleYVal": 173,
                    "sampleYDrawVal": 2702
                  },
                  {
                    "sampleYVal": 177,
                    "sampleYDrawVal": 2819
                  },
                  {
                    "sampleYVal": 173,
                    "sampleYDrawVal": 2701
                  },
                  {
                    "sampleYVal": 173,
                    "sampleYDrawVal": 2699
                  },
                  {
                    "sampleYVal": 160,
                    "sampleYDrawVal": 2249
                  },
                  {
                    "sampleYVal": 141,
                    "sampleYDrawVal": 1722
                  },
                  {
                    "sampleYVal": 131,
                    "sampleYDrawVal": 1407
                  },
                  {
                    "sampleYVal": 86,
                    "sampleYDrawVal": 639
                  },
                  {
                    "sampleYVal": 60,
                    "sampleYDrawVal": 236
                  },
                  {
                    "sampleYVal": 33,
                    "sampleYDrawVal": 55
                  }
                ],
                "recordOffset": -1646,
                "seqUrl": "1527724093055_20633_709",
                "seqDescription": {
                  "analysis": {
                    "analysisType": "2sec"
                  },
                  "completeCount": 2,
                  "currentSeqPos": 0,
                  "pbId": "2056",
                  "text": "אֵין ",
                  "type": "sentence",
                  "word": {
                    "id": 937,
                    "language": "he",
                    "cleanInput": "אֵין",
                    "type": "word",
                    "data": "[{\"text\":\"\\u05d0\\u05b5\\u05d9\\u05df\",\"sylType\":\"vc\",\"vowel\":\"E\",\"isContinous\":true}]",
                    "isNonesense": 0,
                    "audios": "[]"
                  }
                },
                "mistakesObjects": {
                  "volControlUp": {
                    "titlei18Key": "MISTAKES.VOLCONTROL_UP"
                  }
                },
                "hiddenMistakesObjects": {}
              },
              {
                "fieldType": "seq",
                "timerPos": 639,
                "calibrationEventId": "6581",
                "rate": 85,
                "deltaTimeMsFromPrevSeq": "1349.00",
                "yvalues": [
                  "88",
                  "158",
                  "166",
                  "173",
                  "177",
                  "184",
                  "177",
                  "169",
                  "152",
                  "124",
                  "118",
                  "77",
                  "61",
                  "0"
                ],
                "yDrawvalues": "1144,2838,2659,2876,3002,3320,2997,2697,2180,1432,1195,438,317,0",
                "mistakes": [
                  {
                    "sampleOffset": 13,
                    "mistakeId": 6
                  }
                ],
                "hiddenMistakes": [],
                "softVals": [
                  "1",
                  "1",
                  "1",
                  "0",
                  "0",
                  "0",
                  "0",
                  "1",
                  "1",
                  "1",
                  "1",
                  "1",
                  "1",
                  "1"
                ],
                "expectedSylsPerSecond": "0.00",
                "actualSylsPerSecond": "0.56",
                "timeLength": 1750,
                "features": [
                  {
                    "sampleYVal": 145,
                    "sampleYDrawVal": 1967
                  },
                  {
                    "sampleYVal": 173,
                    "sampleYDrawVal": 2727
                  },
                  {
                    "sampleYVal": 175,
                    "sampleYDrawVal": 2840
                  },
                  {
                    "sampleYVal": 175,
                    "sampleYDrawVal": 2886
                  },
                  {
                    "sampleYVal": 174,
                    "sampleYDrawVal": 2852
                  },
                  {
                    "sampleYVal": 185,
                    "sampleYDrawVal": 3261
                  },
                  {
                    "sampleYVal": 169,
                    "sampleYDrawVal": 2778
                  },
                  {
                    "sampleYVal": 166,
                    "sampleYDrawVal": 2622
                  },
                  {
                    "sampleYVal": 128,
                    "sampleYDrawVal": 1717
                  },
                  {
                    "sampleYVal": 116,
                    "sampleYDrawVal": 1228
                  },
                  {
                    "sampleYVal": 96,
                    "sampleYDrawVal": 782
                  },
                  {
                    "sampleYVal": 58,
                    "sampleYDrawVal": 345
                  },
                  {
                    "sampleYVal": 51,
                    "sampleYDrawVal": 162
                  }
                ],
                "recordOffset": 1623,
                "seqUrl": "1527724093055_20633_709",
                "seqDescription": {
                  "analysis": {
                    "analysisType": "2sec"
                  },
                  "completeCount": 3,
                  "currentSeqPos": 0,
                  "pbId": "2056",
                  "text": "זוֹ ",
                  "type": "sentence",
                  "word": {
                    "id": 1075,
                    "language": "he",
                    "cleanInput": "זוֹ",
                    "type": "word",
                    "data": "[{\"text\":\"\\u05d6\\u05d5\\u05b9\",\"sylType\":\"cv\",\"vowel\":\"O\",\"isContinous\":true}]",
                    "isNonesense": 0,
                    "audios": ""
                  }
                },
                "mistakesObjects": {
                  "tooShort": {
                    "titlei18Key": "MISTAKES.TOO_SHORT"
                  }
                },
                "hiddenMistakesObjects": {}
              },
              {
                "fieldType": "seq",
                "timerPos": 642,
                "calibrationEventId": "6581",
                "rate": 80,
                "deltaTimeMsFromPrevSeq": "1086.00",
                "yvalues": [
                  "70",
                  "146",
                  "163",
                  "168",
                  "177",
                  "187",
                  "185",
                  "187",
                  "177",
                  "132",
                  "107",
                  "86",
                  "64",
                  "51",
                  "0"
                ],
                "yDrawvalues": "793,2466,2497,2582,2940,3314,3131,3282,2798,1267,925,545,260,169,0",
                "mistakes": [
                  {
                    "sampleOffset": 5,
                    "mistakeId": 5
                  }
                ],
                "hiddenMistakes": [],
                "softVals": [
                  "1",
                  "1",
                  "1",
                  "1",
                  "0",
                  "0",
                  "0",
                  "0",
                  "0",
                  "1",
                  "1",
                  "1",
                  "1",
                  "1",
                  "1"
                ],
                "expectedSylsPerSecond": "0.00",
                "actualSylsPerSecond": "0.52",
                "timeLength": 1875,
                "features": [
                  {
                    "sampleYVal": 168,
                    "sampleYDrawVal": 2499
                  },
                  {
                    "sampleYVal": 169,
                    "sampleYDrawVal": 2552
                  },
                  {
                    "sampleYVal": 171,
                    "sampleYDrawVal": 2600
                  },
                  {
                    "sampleYVal": 175,
                    "sampleYDrawVal": 2763
                  },
                  {
                    "sampleYVal": 187,
                    "sampleYDrawVal": 3191
                  },
                  {
                    "sampleYVal": 193,
                    "sampleYDrawVal": 3446
                  },
                  {
                    "sampleYVal": 187,
                    "sampleYDrawVal": 3231
                  },
                  {
                    "sampleYVal": 183,
                    "sampleYDrawVal": 3113
                  },
                  {
                    "sampleYVal": 166,
                    "sampleYDrawVal": 2507
                  },
                  {
                    "sampleYVal": 102,
                    "sampleYDrawVal": 874
                  },
                  {
                    "sampleYVal": 103,
                    "sampleYDrawVal": 971
                  },
                  {
                    "sampleYVal": 63,
                    "sampleYDrawVal": 276
                  },
                  {
                    "sampleYVal": 58,
                    "sampleYDrawVal": 249
                  },
                  {
                    "sampleYVal": 30,
                    "sampleYDrawVal": 53
                  }
                ],
                "recordOffset": 4504,
                "seqUrl": "1527724093055_20633_709",
                "seqDescription": {
                  "analysis": {
                    "analysisType": "2sec"
                  },
                  "completeCount": 4,
                  "currentSeqPos": 0,
                  "pbId": "2056",
                  "text": "אַ ",
                  "type": "sentence",
                  "word": {
                    "id": 2054,
                    "language": "he",
                    "cleanInput": "אַגָּדָה",
                    "type": "word",
                    "data": "[{\"text\":\"\\u05d0\\u05b7\",\"sylType\":\"v\",\"isContinous\":true,\"vowel\":\"A\"},{\"text\":\"\\u05d2\\u05bc\\u05b8\",\"sylType\":\"cv\",\"vowel\":\"A\",\"isPlosive\":true},{\"text\":\"\\u05d3\\u05b8\\u05d4\",\"sylType\":\"cv\",\"vowel\":\"A\",\"isPlosive\":true}]",
                    "isNonesense": 0,
                    "audios": "[]"
                  }
                },
                "mistakesObjects": {
                  "volControlUp": {
                    "titlei18Key": "MISTAKES.VOLCONTROL_UP"
                  }
                },
                "hiddenMistakesObjects": {}
              },
              {
                "fieldType": "seq",
                "timerPos": 645,
                "calibrationEventId": "6581",
                "rate": 100,
                "deltaTimeMsFromPrevSeq": "1280.00",
                "yvalues": [
                  "100",
                  "127",
                  "154",
                  "165",
                  "175",
                  "180",
                  "187",
                  "186",
                  "181",
                  "171",
                  "158",
                  "136",
                  "92",
                  "63",
                  "45",
                  "0"
                ],
                "yDrawvalues": "1279,1667,2392,2583,2850,2996,3294,3190,2978,2549,2100,1447,581,428,117,0",
                "mistakes": [],
                "hiddenMistakes": [],
                "softVals": [
                  "1",
                  "1",
                  "1",
                  "1",
                  "0",
                  "0",
                  "0",
                  "0",
                  "0",
                  "0",
                  "1",
                  "1",
                  "1",
                  "1",
                  "1",
                  "1"
                ],
                "expectedSylsPerSecond": "0.00",
                "actualSylsPerSecond": "0.49",
                "timeLength": 2000,
                "features": [
                  {
                    "sampleYVal": 132,
                    "sampleYDrawVal": 1582
                  },
                  {
                    "sampleYVal": 147,
                    "sampleYDrawVal": 1990
                  },
                  {
                    "sampleYVal": 165,
                    "sampleYDrawVal": 2524
                  },
                  {
                    "sampleYVal": 173,
                    "sampleYDrawVal": 2660
                  },
                  {
                    "sampleYVal": 186,
                    "sampleYDrawVal": 3158
                  },
                  {
                    "sampleYVal": 184,
                    "sampleYDrawVal": 3060
                  },
                  {
                    "sampleYVal": 190,
                    "sampleYDrawVal": 3324
                  },
                  {
                    "sampleYVal": 185,
                    "sampleYDrawVal": 3136
                  },
                  {
                    "sampleYVal": 174,
                    "sampleYDrawVal": 2742
                  },
                  {
                    "sampleYVal": 168,
                    "sampleYDrawVal": 2488
                  },
                  {
                    "sampleYVal": 130,
                    "sampleYDrawVal": 1386
                  },
                  {
                    "sampleYVal": 116,
                    "sampleYDrawVal": 1117
                  },
                  {
                    "sampleYVal": 63,
                    "sampleYDrawVal": 370
                  },
                  {
                    "sampleYVal": 44,
                    "sampleYDrawVal": 264
                  },
                  {
                    "sampleYVal": 45,
                    "sampleYDrawVal": 108
                  }
                ],
                "recordOffset": 7708,
                "seqUrl": "1527724093055_20633_709",
                "seqDescription": {
                  "analysis": {
                    "analysisType": "2sec"
                  },
                  "completeCount": 4,
                  "currentSeqPos": 1,
                  "pbId": "2056",
                  "text": "גָּ ",
                  "type": "sentence",
                  "word": {
                    "id": 2054,
                    "language": "he",
                    "cleanInput": "אַגָּדָה",
                    "type": "word",
                    "data": "[{\"text\":\"\\u05d0\\u05b7\",\"sylType\":\"v\",\"isContinous\":true,\"vowel\":\"A\"},{\"text\":\"\\u05d2\\u05bc\\u05b8\",\"sylType\":\"cv\",\"vowel\":\"A\",\"isPlosive\":true},{\"text\":\"\\u05d3\\u05b8\\u05d4\",\"sylType\":\"cv\",\"vowel\":\"A\",\"isPlosive\":true}]",
                    "isNonesense": 0,
                    "audios": "[]"
                  }
                },
                "mistakesObjects": {},
                "hiddenMistakesObjects": {}
              },
              {
                "fieldType": "seq",
                "timerPos": 648,
                "calibrationEventId": "6581",
                "rate": 80,
                "deltaTimeMsFromPrevSeq": "1209.00",
                "yvalues": [
                  "76",
                  "136",
                  "172",
                  "181",
                  "180",
                  "184",
                  "192",
                  "193",
                  "185",
                  "164",
                  "143",
                  "122",
                  "117",
                  "83",
                  "52",
                  "0"
                ],
                "yDrawvalues": "832,2070,3039,3131,2993,3242,3561,3580,3106,2232,1647,1228,1274,510,119,0",
                "mistakes": [
                  {
                    "sampleOffset": 2,
                    "mistakeId": 1
                  }
                ],
                "hiddenMistakes": [],
                "softVals": [
                  "1",
                  "1",
                  "0",
                  "0",
                  "0",
                  "0",
                  "0",
                  "0",
                  "0",
                  "1",
                  "1",
                  "1",
                  "1",
                  "1",
                  "1",
                  "1"
                ],
                "expectedSylsPerSecond": "0.00",
                "actualSylsPerSecond": "0.49",
                "timeLength": 2000,
                "features": [
                  {
                    "sampleYVal": 132,
                    "sampleYDrawVal": 1674
                  },
                  {
                    "sampleYVal": 175,
                    "sampleYDrawVal": 2779
                  },
                  {
                    "sampleYVal": 191,
                    "sampleYDrawVal": 3412
                  },
                  {
                    "sampleYVal": 181,
                    "sampleYDrawVal": 2992
                  },
                  {
                    "sampleYVal": 187,
                    "sampleYDrawVal": 3240
                  },
                  {
                    "sampleYVal": 184,
                    "sampleYDrawVal": 3194
                  },
                  {
                    "sampleYVal": 195,
                    "sampleYDrawVal": 3626
                  },
                  {
                    "sampleYVal": 192,
                    "sampleYDrawVal": 3486
                  },
                  {
                    "sampleYVal": 169,
                    "sampleYDrawVal": 2540
                  },
                  {
                    "sampleYVal": 151,
                    "sampleYDrawVal": 1956
                  },
                  {
                    "sampleYVal": 118,
                    "sampleYDrawVal": 1203
                  },
                  {
                    "sampleYVal": 109,
                    "sampleYDrawVal": 1072
                  },
                  {
                    "sampleYVal": 118,
                    "sampleYDrawVal": 1332
                  },
                  {
                    "sampleYVal": 53,
                    "sampleYDrawVal": 156
                  },
                  {
                    "sampleYVal": 39,
                    "sampleYDrawVal": 83
                  }
                ],
                "recordOffset": 10976,
                "seqUrl": "1527724093055_20633_709",
                "seqDescription": {
                  "analysis": {
                    "analysisType": "2sec"
                  },
                  "completeCount": 4,
                  "currentSeqPos": 2,
                  "pbId": "2056",
                  "text": "דָה ",
                  "type": "sentence",
                  "word": {
                    "id": 2054,
                    "language": "he",
                    "cleanInput": "אַגָּדָה",
                    "type": "word",
                    "data": "[{\"text\":\"\\u05d0\\u05b7\",\"sylType\":\"v\",\"isContinous\":true,\"vowel\":\"A\"},{\"text\":\"\\u05d2\\u05bc\\u05b8\",\"sylType\":\"cv\",\"vowel\":\"A\",\"isPlosive\":true},{\"text\":\"\\u05d3\\u05b8\\u05d4\",\"sylType\":\"cv\",\"vowel\":\"A\",\"isPlosive\":true}]",
                    "isNonesense": 0,
                    "audios": "[]"
                  }
                },
                "mistakesObjects": {
                  "gentleOnset": {
                    "titlei18Key": "MISTAKES.GENTLE_ONSET"
                  }
                },
                "hiddenMistakesObjects": {}
              },
              {
                "fieldType": "seq",
                "timerPos": 651,
                "calibrationEventId": "6581",
                "rate": 85,
                "deltaTimeMsFromPrevSeq": "1406.00",
                "yvalues": [
                  "74",
                  "106",
                  "135",
                  "157",
                  "173",
                  "188",
                  "178",
                  "167",
                  "161",
                  "143",
                  "123",
                  "82",
                  "52",
                  "0"
                ],
                "yDrawvalues": "667,1201,1847,2483,2850,3436,2780,2392,2226,1685,1362,713,175,0",
                "mistakes": [
                  {
                    "sampleOffset": 13,
                    "mistakeId": 6
                  }
                ],
                "hiddenMistakes": [],
                "softVals": [
                  "1",
                  "1",
                  "1",
                  "1",
                  "0",
                  "0",
                  "0",
                  "1",
                  "1",
                  "1",
                  "1",
                  "1",
                  "1",
                  "1"
                ],
                "expectedSylsPerSecond": "0.00",
                "actualSylsPerSecond": "0.58",
                "timeLength": 1750,
                "features": [
                  {
                    "sampleYVal": 94,
                    "sampleYDrawVal": 788
                  },
                  {
                    "sampleYVal": 122,
                    "sampleYDrawVal": 1329
                  },
                  {
                    "sampleYVal": 160,
                    "sampleYDrawVal": 2337
                  },
                  {
                    "sampleYVal": 163,
                    "sampleYDrawVal": 2399
                  },
                  {
                    "sampleYVal": 184,
                    "sampleYDrawVal": 3100
                  },
                  {
                    "sampleYVal": 188,
                    "sampleYDrawVal": 3248
                  },
                  {
                    "sampleYVal": 172,
                    "sampleYDrawVal": 2631
                  },
                  {
                    "sampleYVal": 159,
                    "sampleYDrawVal": 2199
                  },
                  {
                    "sampleYVal": 149,
                    "sampleYDrawVal": 1914
                  },
                  {
                    "sampleYVal": 133,
                    "sampleYDrawVal": 1508
                  },
                  {
                    "sampleYVal": 105,
                    "sampleYDrawVal": 1167
                  },
                  {
                    "sampleYVal": 60,
                    "sampleYDrawVal": 507
                  },
                  {
                    "sampleYVal": 39,
                    "sampleYDrawVal": 116
                  }
                ],
                "recordOffset": 14358,
                "seqUrl": "1527724093055_20633_709",
                "seqDescription": {
                  "analysis": {
                    "analysisType": "2sec"
                  },
                  "completeCount": 0,
                  "currentSeqPos": 0,
                  "pbId": "836",
                  "text": "אֵי ",
                  "type": "sentence",
                  "word": {
                    "id": 832,
                    "language": "he",
                    "cleanInput": "אֵיזֶה",
                    "type": "word",
                    "data": "[{\"text\":\"\\u05d0\\u05b5\\u05d9\",\"sylType\":\"vc\",\"vowel\":\"E\",\"isContinous\":true},{\"text\":\"\\u05d6\\u05b6\\u05d4\",\"sylType\":\"cv\",\"vowel\":\"E\",\"isContinous\":true}]",
                    "isNonesense": 0,
                    "audios": ""
                  }
                },
                "mistakesObjects": {
                  "tooShort": {
                    "titlei18Key": "MISTAKES.TOO_SHORT"
                  }
                },
                "hiddenMistakesObjects": {}
              },
              {
                "fieldType": "seq",
                "timerPos": 655,
                "calibrationEventId": "6581",
                "rate": 85,
                "deltaTimeMsFromPrevSeq": "1651.00",
                "yvalues": [
                  "71",
                  "132",
                  "163",
                  "169",
                  "168",
                  "165",
                  "170",
                  "172",
                  "169",
                  "163",
                  "151",
                  "134",
                  "115",
                  "79",
                  "41",
                  "0"
                ],
                "yDrawvalues": "802,2080,2713,2652,2563,2435,2657,2735,2573,2312,1894,1407,1072,445,59,0",
                "mistakes": [
                  {
                    "sampleOffset": 7,
                    "mistakeId": 3
                  }
                ],
                "hiddenMistakes": [],
                "softVals": [
                  "1",
                  "1",
                  "1",
                  "1",
                  "1",
                  "1",
                  "1",
                  "0",
                  "1",
                  "1",
                  "1",
                  "1",
                  "1",
                  "1",
                  "1",
                  "1"
                ],
                "expectedSylsPerSecond": "0.00",
                "actualSylsPerSecond": "0.95",
                "timeLength": 2000,
                "features": [
                  {
                    "sampleYVal": 103,
                    "sampleYDrawVal": 1137
                  },
                  {
                    "sampleYVal": 157,
                    "sampleYDrawVal": 2358
                  },
                  {
                    "sampleYVal": 175,
                    "sampleYDrawVal": 2794
                  },
                  {
                    "sampleYVal": 167,
                    "sampleYDrawVal": 2490
                  },
                  {
                    "sampleYVal": 167,
                    "sampleYDrawVal": 2498
                  },
                  {
                    "sampleYVal": 159,
                    "sampleYDrawVal": 2242
                  },
                  {
                    "sampleYVal": 179,
                    "sampleYDrawVal": 2900
                  },
                  {
                    "sampleYVal": 168,
                    "sampleYDrawVal": 2550
                  },
                  {
                    "sampleYVal": 156,
                    "sampleYDrawVal": 2150
                  },
                  {
                    "sampleYVal": 154,
                    "sampleYDrawVal": 2060
                  },
                  {
                    "sampleYVal": 144,
                    "sampleYDrawVal": 1785
                  },
                  {
                    "sampleYVal": 119,
                    "sampleYDrawVal": 1205
                  },
                  {
                    "sampleYVal": 96,
                    "sampleYDrawVal": 878
                  },
                  {
                    "sampleYVal": 47,
                    "sampleYDrawVal": 135
                  },
                  {
                    "sampleYVal": 19,
                    "sampleYDrawVal": 17
                  }
                ],
                "recordOffset": 17870,
                "seqUrl": "1527724093055_20633_709",
                "seqDescription": {
                  "analysis": {
                    "analysisType": "2sec"
                  },
                  "completeCount": 0,
                  "currentSeqPos": 1,
                  "pbId": "836",
                  "text": "זֶה ",
                  "type": "sentence",
                  "word": {
                    "id": 832,
                    "language": "he",
                    "cleanInput": "אֵיזֶה",
                    "type": "word",
                    "data": "[{\"text\":\"\\u05d0\\u05b5\\u05d9\",\"sylType\":\"vc\",\"vowel\":\"E\",\"isContinous\":true},{\"text\":\"\\u05d6\\u05b6\\u05d4\",\"sylType\":\"cv\",\"vowel\":\"E\",\"isContinous\":true}]",
                    "isNonesense": 0,
                    "audios": ""
                  }
                },
                "mistakesObjects": {
                  "softPeak": {
                    "titlei18Key": "MISTAKES.SOFT_PEAK"
                  }
                },
                "hiddenMistakesObjects": {}
              },
              {
                "fieldType": "seq",
                "timerPos": 658,
                "calibrationEventId": "6581",
                "rate": 100,
                "deltaTimeMsFromPrevSeq": "1340.00",
                "yvalues": [
                  "84",
                  "123",
                  "134",
                  "156",
                  "169",
                  "184",
                  "186",
                  "179",
                  "165",
                  "160",
                  "142",
                  "112",
                  "87",
                  "62",
                  "0"
                ],
                "yDrawvalues": "842,1591,1754,2515,3021,3350,3328,2956,2552,2388,1881,1188,721,262,0",
                "mistakes": [],
                "hiddenMistakes": [],
                "softVals": [
                  "1",
                  "1",
                  "1",
                  "1",
                  "1",
                  "0",
                  "0",
                  "0",
                  "1",
                  "1",
                  "1",
                  "1",
                  "1",
                  "1",
                  "1"
                ],
                "expectedSylsPerSecond": "0.00",
                "actualSylsPerSecond": "0.52",
                "timeLength": 1875,
                "features": [
                  {
                    "sampleYVal": 116,
                    "sampleYDrawVal": 1189
                  },
                  {
                    "sampleYVal": 144,
                    "sampleYDrawVal": 1878
                  },
                  {
                    "sampleYVal": 132,
                    "sampleYDrawVal": 1666
                  },
                  {
                    "sampleYVal": 165,
                    "sampleYDrawVal": 2734
                  },
                  {
                    "sampleYVal": 179,
                    "sampleYDrawVal": 3103
                  },
                  {
                    "sampleYVal": 185,
                    "sampleYDrawVal": 3279
                  },
                  {
                    "sampleYVal": 190,
                    "sampleYDrawVal": 3372
                  },
                  {
                    "sampleYVal": 164,
                    "sampleYDrawVal": 2523
                  },
                  {
                    "sampleYVal": 164,
                    "sampleYDrawVal": 2532
                  },
                  {
                    "sampleYVal": 145,
                    "sampleYDrawVal": 1963
                  },
                  {
                    "sampleYVal": 128,
                    "sampleYDrawVal": 1654
                  },
                  {
                    "sampleYVal": 79,
                    "sampleYDrawVal": 722
                  },
                  {
                    "sampleYVal": 82,
                    "sampleYDrawVal": 703
                  },
                  {
                    "sampleYVal": 52,
                    "sampleYDrawVal": 175
                  }
                ],
                "recordOffset": 21253,
                "seqUrl": "1527724093055_20633_709",
                "seqDescription": {
                  "analysis": {
                    "analysisType": "2sec"
                  },
                  "completeCount": 1,
                  "currentSeqPos": 0,
                  "pbId": "836",
                  "text": "יוֹם ",
                  "type": "sentence",
                  "word": {
                    "id": 833,
                    "language": "he",
                    "cleanInput": "יוֹם",
                    "type": "word",
                    "data": "[{\"text\":\"\\u05d9\\u05d5\\u05b9\\u05dd\",\"sylType\":\"cvc\",\"vowel\":\"O\",\"isContinous\":true}]",
                    "isNonesense": 0,
                    "audios": ""
                  }
                },
                "mistakesObjects": {},
                "hiddenMistakesObjects": {}
              },
              {
                "fieldType": "seq",
                "timerPos": 662,
                "calibrationEventId": "6581",
                "rate": 80,
                "deltaTimeMsFromPrevSeq": "708.00",
                "yvalues": [
                  "87",
                  "151",
                  "186",
                  "193",
                  "192",
                  "181",
                  "170",
                  "152",
                  "138",
                  "130",
                  "95",
                  "60",
                  "0"
                ],
                "yDrawvalues": "1080,2681,3595,3557,3456,2926,2522,1932,1554,1481,810,246,0",
                "mistakes": [
                  {
                    "sampleOffset": 2,
                    "mistakeId": 1
                  }
                ],
                "hiddenMistakes": [],
                "softVals": [
                  "1",
                  "1",
                  "0",
                  "0",
                  "0",
                  "0",
                  "1",
                  "1",
                  "1",
                  "1",
                  "1",
                  "1",
                  "1"
                ],
                "expectedSylsPerSecond": "0.00",
                "actualSylsPerSecond": "0.62",
                "timeLength": 1625,
                "features": [
                  {
                    "sampleYVal": 127,
                    "sampleYDrawVal": 1588
                  },
                  {
                    "sampleYVal": 189,
                    "sampleYDrawVal": 3349
                  },
                  {
                    "sampleYVal": 191,
                    "sampleYDrawVal": 3403
                  },
                  {
                    "sampleYVal": 193,
                    "sampleYDrawVal": 3508
                  },
                  {
                    "sampleYVal": 186,
                    "sampleYDrawVal": 3176
                  },
                  {
                    "sampleYVal": 175,
                    "sampleYDrawVal": 2770
                  },
                  {
                    "sampleYVal": 163,
                    "sampleYDrawVal": 2341
                  },
                  {
                    "sampleYVal": 143,
                    "sampleYDrawVal": 1763
                  },
                  {
                    "sampleYVal": 136,
                    "sampleYDrawVal": 1590
                  },
                  {
                    "sampleYVal": 115,
                    "sampleYDrawVal": 1202
                  },
                  {
                    "sampleYVal": 70,
                    "sampleYDrawVal": 559
                  },
                  {
                    "sampleYVal": 36,
                    "sampleYDrawVal": 76
                  }
                ],
                "recordOffset": 24645,
                "seqUrl": "1527724093055_20633_709",
                "seqDescription": {
                  "analysis": {
                    "analysisType": "2sec"
                  },
                  "completeCount": 2,
                  "currentSeqPos": 0,
                  "pbId": "836",
                  "text": "נִפְ ",
                  "type": "sentence",
                  "word": {
                    "id": 834,
                    "language": "he",
                    "cleanInput": "נִפְלָא",
                    "type": "word",
                    "data": "[{\"text\":\"\\u05e0\\u05b4\\u05e4\\u05b0\",\"sylType\":\"cvc\",\"vowel\":\"I\",\"isContinous\":true},{\"text\":\"\\u05dc\\u05b8\\u05d0\",\"sylType\":\"cv\",\"vowel\":\"A\",\"isContinous\":true}]",
                    "isNonesense": 0,
                    "audios": ""
                  }
                },
                "mistakesObjects": {
                  "gentleOnset": {
                    "titlei18Key": "MISTAKES.GENTLE_ONSET"
                  }
                },
                "hiddenMistakesObjects": {}
              },
              {
                "fieldType": "seq",
                "timerPos": 665,
                "calibrationEventId": "6581",
                "rate": 80,
                "deltaTimeMsFromPrevSeq": "1788.00",
                "yvalues": [
                  "117",
                  "155",
                  "176",
                  "188",
                  "188",
                  "193",
                  "195",
                  "191",
                  "179",
                  "162",
                  "126",
                  "87",
                  "72",
                  "62",
                  "0"
                ],
                "yDrawvalues": "1639,2512,3083,3463,3325,3575,3652,3452,2831,2229,1200,454,341,236,0",
                "mistakes": [
                  {
                    "sampleOffset": 2,
                    "mistakeId": 1
                  }
                ],
                "hiddenMistakes": [],
                "softVals": [
                  "1",
                  "1",
                  "0",
                  "0",
                  "0",
                  "0",
                  "0",
                  "0",
                  "0",
                  "1",
                  "1",
                  "1",
                  "1",
                  "1",
                  "1"
                ],
                "expectedSylsPerSecond": "0.00",
                "actualSylsPerSecond": "0.52",
                "timeLength": 1875,
                "features": [
                  {
                    "sampleYVal": 153,
                    "sampleYDrawVal": 2042
                  },
                  {
                    "sampleYVal": 184,
                    "sampleYDrawVal": 3195
                  },
                  {
                    "sampleYVal": 190,
                    "sampleYDrawVal": 3375
                  },
                  {
                    "sampleYVal": 192,
                    "sampleYDrawVal": 3454
                  },
                  {
                    "sampleYVal": 194,
                    "sampleYDrawVal": 3570
                  },
                  {
                    "sampleYVal": 194,
                    "sampleYDrawVal": 3577
                  },
                  {
                    "sampleYVal": 187,
                    "sampleYDrawVal": 3239
                  },
                  {
                    "sampleYVal": 187,
                    "sampleYDrawVal": 3240
                  },
                  {
                    "sampleYVal": 179,
                    "sampleYDrawVal": 2891
                  },
                  {
                    "sampleYVal": 155,
                    "sampleYDrawVal": 2166
                  },
                  {
                    "sampleYVal": 81,
                    "sampleYDrawVal": 464
                  },
                  {
                    "sampleYVal": 70,
                    "sampleYDrawVal": 323
                  },
                  {
                    "sampleYVal": 58,
                    "sampleYDrawVal": 191
                  },
                  {
                    "sampleYVal": 52,
                    "sampleYDrawVal": 169
                  }
                ],
                "recordOffset": 28104,
                "seqUrl": "1527724093055_20633_709",
                "seqDescription": {
                  "analysis": {
                    "analysisType": "2sec"
                  },
                  "completeCount": 2,
                  "currentSeqPos": 1,
                  "pbId": "836",
                  "text": "לָא ",
                  "type": "sentence",
                  "word": {
                    "id": 834,
                    "language": "he",
                    "cleanInput": "נִפְלָא",
                    "type": "word",
                    "data": "[{\"text\":\"\\u05e0\\u05b4\\u05e4\\u05b0\",\"sylType\":\"cvc\",\"vowel\":\"I\",\"isContinous\":true},{\"text\":\"\\u05dc\\u05b8\\u05d0\",\"sylType\":\"cv\",\"vowel\":\"A\",\"isContinous\":true}]",
                    "isNonesense": 0,
                    "audios": ""
                  }
                },
                "mistakesObjects": {
                  "gentleOnset": {
                    "titlei18Key": "MISTAKES.GENTLE_ONSET"
                  }
                },
                "hiddenMistakesObjects": {}
              },
              {
                "fieldType": "seq",
                "timerPos": 669,
                "calibrationEventId": "6581",
                "rate": 85,
                "deltaTimeMsFromPrevSeq": "1829.00",
                "yvalues": [
                  "74",
                  "114",
                  "142",
                  "155",
                  "166",
                  "181",
                  "188",
                  "174",
                  "148",
                  "110",
                  "72",
                  "62",
                  "0"
                ],
                "yDrawvalues": "436,1259,1946,2229,2559,3222,3392,2703,1779,901,297,243,0",
                "mistakes": [
                  {
                    "sampleOffset": 12,
                    "mistakeId": 6
                  }
                ],
                "hiddenMistakes": [],
                "softVals": [
                  "1",
                  "1",
                  "1",
                  "1",
                  "1",
                  "0",
                  "0",
                  "0",
                  "1",
                  "1",
                  "1",
                  "1",
                  "1"
                ],
                "expectedSylsPerSecond": "0.00",
                "actualSylsPerSecond": "0.60",
                "timeLength": 1625,
                "features": [
                  {
                    "sampleYVal": 102,
                    "sampleYDrawVal": 791
                  },
                  {
                    "sampleYVal": 137,
                    "sampleYDrawVal": 1531
                  },
                  {
                    "sampleYVal": 157,
                    "sampleYDrawVal": 2141
                  },
                  {
                    "sampleYVal": 167,
                    "sampleYDrawVal": 2478
                  },
                  {
                    "sampleYVal": 178,
                    "sampleYDrawVal": 2842
                  },
                  {
                    "sampleYVal": 194,
                    "sampleYDrawVal": 3528
                  },
                  {
                    "sampleYVal": 185,
                    "sampleYDrawVal": 3205
                  },
                  {
                    "sampleYVal": 148,
                    "sampleYDrawVal": 2007
                  },
                  {
                    "sampleYVal": 130,
                    "sampleYDrawVal": 1497
                  },
                  {
                    "sampleYVal": 95,
                    "sampleYDrawVal": 812
                  },
                  {
                    "sampleYVal": 50,
                    "sampleYDrawVal": 139
                  },
                  {
                    "sampleYVal": 54,
                    "sampleYDrawVal": 211
                  }
                ],
                "recordOffset": 1851,
                "seqUrl": "1527724123056_20633_709",
                "seqDescription": {
                  "analysis": {
                    "analysisType": "2sec"
                  },
                  "completeCount": 3,
                  "currentSeqPos": 0,
                  "pbId": "836",
                  "text": "הַ ",
                  "type": "sentence",
                  "word": {
                    "id": 835,
                    "language": "he",
                    "cleanInput": "הַיּוֹם",
                    "type": "word",
                    "data": "[{\"text\":\"\\u05d4\\u05b7\",\"sylType\":\"v\",\"vowel\":\"A\"},{\"text\":\"\\u05d9\\u05bc\\u05d5\\u05b9\\u05dd\",\"sylType\":\"cvc\",\"vowel\":\"O\",\"isContinous\":true}]",
                    "isNonesense": 0,
                    "audios": ""
                  }
                },
                "mistakesObjects": {
                  "tooShort": {
                    "titlei18Key": "MISTAKES.TOO_SHORT"
                  }
                },
                "hiddenMistakesObjects": {}
              },
              {
                "fieldType": "seq",
                "timerPos": 672,
                "calibrationEventId": "6581",
                "rate": 80,
                "deltaTimeMsFromPrevSeq": "1341.00",
                "yvalues": [
                  "102",
                  "128",
                  "127",
                  "125",
                  "139",
                  "153",
                  "181",
                  "185",
                  "182",
                  "156",
                  "132",
                  "134",
                  "116",
                  "87",
                  "55",
                  "0"
                ],
                "yDrawvalues": "1433,1667,1544,1627,2194,2401,3372,3297,3068,2153,1554,1561,1202,668,166,0",
                "mistakes": [
                  {
                    "sampleOffset": 1,
                    "mistakeId": 5
                  }
                ],
                "hiddenMistakes": [],
                "softVals": [
                  "1",
                  "1",
                  "1",
                  "1",
                  "1",
                  "1",
                  "0",
                  "0",
                  "0",
                  "1",
                  "1",
                  "1",
                  "1",
                  "1",
                  "1",
                  "1"
                ],
                "expectedSylsPerSecond": "0.00",
                "actualSylsPerSecond": "0.95",
                "timeLength": 2000,
                "features": [
                  {
                    "sampleYVal": 151,
                    "sampleYDrawVal": 2054
                  },
                  {
                    "sampleYVal": 128,
                    "sampleYDrawVal": 1529
                  },
                  {
                    "sampleYVal": 131,
                    "sampleYDrawVal": 1646
                  },
                  {
                    "sampleYVal": 123,
                    "sampleYDrawVal": 1712
                  },
                  {
                    "sampleYVal": 156,
                    "sampleYDrawVal": 2489
                  },
                  {
                    "sampleYVal": 172,
                    "sampleYDrawVal": 2793
                  },
                  {
                    "sampleYVal": 186,
                    "sampleYDrawVal": 3282
                  },
                  {
                    "sampleYVal": 181,
                    "sampleYDrawVal": 3065
                  },
                  {
                    "sampleYVal": 178,
                    "sampleYDrawVal": 2910
                  },
                  {
                    "sampleYVal": 140,
                    "sampleYDrawVal": 1863
                  },
                  {
                    "sampleYVal": 118,
                    "sampleYDrawVal": 1201
                  },
                  {
                    "sampleYVal": 131,
                    "sampleYDrawVal": 1543
                  },
                  {
                    "sampleYVal": 94,
                    "sampleYDrawVal": 865
                  },
                  {
                    "sampleYVal": 55,
                    "sampleYDrawVal": 271
                  },
                  {
                    "sampleYVal": 36,
                    "sampleYDrawVal": 63
                  }
                ],
                "recordOffset": 4928,
                "seqUrl": "1527724123056_20633_709",
                "seqDescription": {
                  "analysis": {
                    "analysisType": "2sec"
                  },
                  "completeCount": 3,
                  "currentSeqPos": 1,
                  "pbId": "836",
                  "text": "יּוֹם ",
                  "type": "sentence",
                  "word": {
                    "id": 835,
                    "language": "he",
                    "cleanInput": "הַיּוֹם",
                    "type": "word",
                    "data": "[{\"text\":\"\\u05d4\\u05b7\",\"sylType\":\"v\",\"vowel\":\"A\"},{\"text\":\"\\u05d9\\u05bc\\u05d5\\u05b9\\u05dd\",\"sylType\":\"cvc\",\"vowel\":\"O\",\"isContinous\":true}]",
                    "isNonesense": 0,
                    "audios": ""
                  }
                },
                "mistakesObjects": {
                  "volControlUp": {
                    "titlei18Key": "MISTAKES.VOLCONTROL_UP"
                  }
                },
                "hiddenMistakesObjects": {}
              },
              {
                "fieldType": "seq",
                "timerPos": 676,
                "calibrationEventId": "6581",
                "rate": 80,
                "deltaTimeMsFromPrevSeq": "1600.00",
                "yvalues": [
                  "73",
                  "74",
                  "125",
                  "159",
                  "175",
                  "183",
                  "193",
                  "200",
                  "198",
                  "180",
                  "143",
                  "90",
                  "52",
                  "0"
                ],
                "yDrawvalues": "677,514,1861,2602,3030,3207,3700,3923,3726,2968,1704,570,121,0",
                "mistakes": [
                  {
                    "sampleOffset": 9,
                    "mistakeId": 2
                  }
                ],
                "hiddenMistakes": [],
                "softVals": [
                  "1",
                  "1",
                  "1",
                  "1",
                  "0",
                  "0",
                  "0",
                  "0",
                  "0",
                  "0",
                  "1",
                  "1",
                  "1",
                  "1"
                ],
                "expectedSylsPerSecond": "0.00",
                "actualSylsPerSecond": "0.56",
                "timeLength": 1750,
                "features": [
                  {
                    "sampleYVal": 62,
                    "sampleYDrawVal": 277
                  },
                  {
                    "sampleYVal": 95,
                    "sampleYDrawVal": 934
                  },
                  {
                    "sampleYVal": 157,
                    "sampleYDrawVal": 2275
                  },
                  {
                    "sampleYVal": 173,
                    "sampleYDrawVal": 2819
                  },
                  {
                    "sampleYVal": 182,
                    "sampleYDrawVal": 3115
                  },
                  {
                    "sampleYVal": 180,
                    "sampleYDrawVal": 3034
                  },
                  {
                    "sampleYVal": 201,
                    "sampleYDrawVal": 3869
                  },
                  {
                    "sampleYVal": 199,
                    "sampleYDrawVal": 3806
                  },
                  {
                    "sampleYVal": 195,
                    "sampleYDrawVal": 3601
                  },
                  {
                    "sampleYVal": 169,
                    "sampleYDrawVal": 2728
                  },
                  {
                    "sampleYVal": 112,
                    "sampleYDrawVal": 1168
                  },
                  {
                    "sampleYVal": 56,
                    "sampleYDrawVal": 221
                  },
                  {
                    "sampleYVal": 36,
                    "sampleYDrawVal": 72
                  }
                ],
                "recordOffset": 8579,
                "seqUrl": "1527724123056_20633_709",
                "seqDescription": {
                  "analysis": {
                    "analysisType": "2sec"
                  },
                  "completeCount": 0,
                  "currentSeqPos": 0,
                  "pbId": "1270",
                  "text": "אָ ",
                  "type": "sentence",
                  "word": {
                    "id": 1264,
                    "language": "he",
                    "cleanInput": "אָנִי",
                    "type": "word",
                    "data": "[{\"text\":\"\\u05d0\\u05b8\",\"sylType\":\"v\",\"vowel\":\"A\"},{\"text\":\"\\u05e0\\u05b4\\u05d9\",\"sylType\":\"cv\",\"vowel\":\"I\",\"isContinous\":true}]",
                    "isNonesense": 0,
                    "audios": ""
                  }
                },
                "mistakesObjects": {
                  "gentleOffset": {
                    "titlei18Key": "MISTAKES.GENTLE_OFFSET"
                  }
                },
                "hiddenMistakesObjects": {}
              },
              {
                "fieldType": "seq",
                "timerPos": 679,
                "calibrationEventId": "6581",
                "rate": 80,
                "deltaTimeMsFromPrevSeq": "1599.00",
                "yvalues": [
                  "106",
                  "158",
                  "176",
                  "179",
                  "187",
                  "192",
                  "187",
                  "167",
                  "136",
                  "119",
                  "100",
                  "82",
                  "60",
                  "0"
                ],
                "yDrawvalues": "1561,2766,3097,3015,3338,3504,3172,2383,1507,1139,863,562,323,0",
                "mistakes": [
                  {
                    "sampleOffset": 2,
                    "mistakeId": 1
                  }
                ],
                "hiddenMistakes": [],
                "softVals": [
                  "1",
                  "1",
                  "0",
                  "0",
                  "0",
                  "0",
                  "0",
                  "1",
                  "1",
                  "1",
                  "1",
                  "1",
                  "1",
                  "1"
                ],
                "expectedSylsPerSecond": "0.00",
                "actualSylsPerSecond": "0.56",
                "timeLength": 1750,
                "features": [
                  {
                    "sampleYVal": 164,
                    "sampleYDrawVal": 2543
                  },
                  {
                    "sampleYVal": 176,
                    "sampleYDrawVal": 2950
                  },
                  {
                    "sampleYVal": 181,
                    "sampleYDrawVal": 3027
                  },
                  {
                    "sampleYVal": 185,
                    "sampleYDrawVal": 3171
                  },
                  {
                    "sampleYVal": 197,
                    "sampleYDrawVal": 3638
                  },
                  {
                    "sampleYVal": 188,
                    "sampleYDrawVal": 3283
                  },
                  {
                    "sampleYVal": 181,
                    "sampleYDrawVal": 2960
                  },
                  {
                    "sampleYVal": 142,
                    "sampleYDrawVal": 1798
                  },
                  {
                    "sampleYVal": 111,
                    "sampleYDrawVal": 1151
                  },
                  {
                    "sampleYVal": 116,
                    "sampleYDrawVal": 1154
                  },
                  {
                    "sampleYVal": 91,
                    "sampleYDrawVal": 720
                  },
                  {
                    "sampleYVal": 67,
                    "sampleYDrawVal": 472
                  },
                  {
                    "sampleYVal": 43,
                    "sampleYDrawVal": 169
                  }
                ],
                "recordOffset": 11968,
                "seqUrl": "1527724123056_20633_709",
                "seqDescription": {
                  "analysis": {
                    "analysisType": "2sec"
                  },
                  "completeCount": 0,
                  "currentSeqPos": 1,
                  "pbId": "1270",
                  "text": "נִי ",
                  "type": "sentence",
                  "word": {
                    "id": 1264,
                    "language": "he",
                    "cleanInput": "אָנִי",
                    "type": "word",
                    "data": "[{\"text\":\"\\u05d0\\u05b8\",\"sylType\":\"v\",\"vowel\":\"A\"},{\"text\":\"\\u05e0\\u05b4\\u05d9\",\"sylType\":\"cv\",\"vowel\":\"I\",\"isContinous\":true}]",
                    "isNonesense": 0,
                    "audios": ""
                  }
                },
                "mistakesObjects": {
                  "gentleOnset": {
                    "titlei18Key": "MISTAKES.GENTLE_ONSET"
                  }
                },
                "hiddenMistakesObjects": {}
              },
              {
                "fieldType": "seq",
                "timerPos": 682,
                "calibrationEventId": "6581",
                "rate": 85,
                "deltaTimeMsFromPrevSeq": "1412.00",
                "yvalues": [
                  "102",
                  "99",
                  "150",
                  "177",
                  "184",
                  "192",
                  "181",
                  "169",
                  "153",
                  "126",
                  "79",
                  "50",
                  "0"
                ],
                "yDrawvalues": "1199,1155,2718,3299,3434,3704,3037,2585,2209,1520,541,128,0",
                "mistakes": [
                  {
                    "sampleOffset": 12,
                    "mistakeId": 6
                  }
                ],
                "hiddenMistakes": [],
                "softVals": [
                  "1",
                  "1",
                  "1",
                  "0",
                  "0",
                  "0",
                  "0",
                  "1",
                  "1",
                  "1",
                  "1",
                  "1",
                  "1"
                ],
                "expectedSylsPerSecond": "0.00",
                "actualSylsPerSecond": "0.60",
                "timeLength": 1625,
                "features": [
                  {
                    "sampleYVal": 103,
                    "sampleYDrawVal": 1010
                  },
                  {
                    "sampleYVal": 135,
                    "sampleYDrawVal": 1972
                  },
                  {
                    "sampleYVal": 176,
                    "sampleYDrawVal": 3002
                  },
                  {
                    "sampleYVal": 186,
                    "sampleYDrawVal": 3403
                  },
                  {
                    "sampleYVal": 189,
                    "sampleYDrawVal": 3539
                  },
                  {
                    "sampleYVal": 183,
                    "sampleYDrawVal": 3251
                  },
                  {
                    "sampleYVal": 177,
                    "sampleYDrawVal": 2916
                  },
                  {
                    "sampleYVal": 166,
                    "sampleYDrawVal": 2589
                  },
                  {
                    "sampleYVal": 138,
                    "sampleYDrawVal": 2030
                  },
                  {
                    "sampleYVal": 101,
                    "sampleYDrawVal": 1099
                  },
                  {
                    "sampleYVal": 55,
                    "sampleYDrawVal": 351
                  },
                  {
                    "sampleYVal": 29,
                    "sampleYDrawVal": 46
                  }
                ],
                "recordOffset": 15183,
                "seqUrl": "1527724123056_20633_709",
                "seqDescription": {
                  "analysis": {
                    "analysisType": "2sec"
                  },
                  "completeCount": 1,
                  "currentSeqPos": 0,
                  "pbId": "1270",
                  "text": "רֹ ",
                  "type": "sentence",
                  "word": {
                    "id": 1265,
                    "language": "he",
                    "cleanInput": "רֹאֶה",
                    "type": "word",
                    "data": "[{\"text\":\"\\u05e8\\u05b9\",\"sylType\":\"cv\",\"vowel\":\"O\",\"isContinous\":true},{\"text\":\"\\u05d0\\u05b6\\u05d4\",\"sylType\":\"v\",\"vowel\":\"E\"}]",
                    "isNonesense": 0,
                    "audios": ""
                  }
                },
                "mistakesObjects": {
                  "tooShort": {
                    "titlei18Key": "MISTAKES.TOO_SHORT"
                  }
                },
                "hiddenMistakesObjects": {}
              },
              {
                "fieldType": "seq",
                "timerPos": 686,
                "calibrationEventId": "6581",
                "rate": 100,
                "deltaTimeMsFromPrevSeq": "1599.00",
                "yvalues": [
                  "81",
                  "135",
                  "157",
                  "180",
                  "201",
                  "205",
                  "199",
                  "192",
                  "169",
                  "146",
                  "131",
                  "104",
                  "89",
                  "72",
                  "51",
                  "0"
                ],
                "yDrawvalues": "985,1982,2427,3197,4061,4038,3679,3343,2374,1681,1377,847,656,534,136,0",
                "mistakes": [],
                "hiddenMistakes": [],
                "softVals": [
                  "1",
                  "1",
                  "1",
                  "0",
                  "0",
                  "0",
                  "0",
                  "0",
                  "1",
                  "1",
                  "1",
                  "1",
                  "1",
                  "1",
                  "1",
                  "1"
                ],
                "expectedSylsPerSecond": "0.00",
                "actualSylsPerSecond": "0.47",
                "timeLength": 2000,
                "features": [
                  {
                    "sampleYVal": 135,
                    "sampleYDrawVal": 1740
                  },
                  {
                    "sampleYVal": 139,
                    "sampleYDrawVal": 1699
                  },
                  {
                    "sampleYVal": 183,
                    "sampleYDrawVal": 3067
                  },
                  {
                    "sampleYVal": 199,
                    "sampleYDrawVal": 3703
                  },
                  {
                    "sampleYVal": 209,
                    "sampleYDrawVal": 4165
                  },
                  {
                    "sampleYVal": 207,
                    "sampleYDrawVal": 4060
                  },
                  {
                    "sampleYVal": 199,
                    "sampleYDrawVal": 3690
                  },
                  {
                    "sampleYVal": 188,
                    "sampleYDrawVal": 3230
                  },
                  {
                    "sampleYVal": 147,
                    "sampleYDrawVal": 1857
                  },
                  {
                    "sampleYVal": 133,
                    "sampleYDrawVal": 1512
                  },
                  {
                    "sampleYVal": 106,
                    "sampleYDrawVal": 969
                  },
                  {
                    "sampleYVal": 97,
                    "sampleYDrawVal": 801
                  },
                  {
                    "sampleYVal": 91,
                    "sampleYDrawVal": 725
                  },
                  {
                    "sampleYVal": 48,
                    "sampleYDrawVal": 281
                  },
                  {
                    "sampleYVal": 40,
                    "sampleYDrawVal": 85
                  }
                ],
                "recordOffset": 18518,
                "seqUrl": "1527724123056_20633_709",
                "seqDescription": {
                  "analysis": {
                    "analysisType": "2sec"
                  },
                  "completeCount": 1,
                  "currentSeqPos": 1,
                  "pbId": "1270",
                  "text": "אֶה ",
                  "type": "sentence",
                  "word": {
                    "id": 1265,
                    "language": "he",
                    "cleanInput": "רֹאֶה",
                    "type": "word",
                    "data": "[{\"text\":\"\\u05e8\\u05b9\",\"sylType\":\"cv\",\"vowel\":\"O\",\"isContinous\":true},{\"text\":\"\\u05d0\\u05b6\\u05d4\",\"sylType\":\"v\",\"vowel\":\"E\"}]",
                    "isNonesense": 0,
                    "audios": ""
                  }
                },
                "mistakesObjects": {},
                "hiddenMistakesObjects": {}
              },
              {
                "fieldType": "seq",
                "timerPos": 689,
                "calibrationEventId": "6581",
                "rate": 100,
                "deltaTimeMsFromPrevSeq": "1339.00",
                "yvalues": [
                  "110",
                  "126",
                  "126",
                  "160",
                  "175",
                  "175",
                  "172",
                  "169",
                  "168",
                  "160",
                  "141",
                  "137",
                  "100",
                  "49",
                  "0"
                ],
                "yDrawvalues": "1609,1667,1577,2768,3179,2903,2737,2591,2583,2293,1882,1913,903,90,0",
                "mistakes": [],
                "hiddenMistakes": [],
                "softVals": [
                  "1",
                  "1",
                  "1",
                  "1",
                  "0",
                  "0",
                  "0",
                  "1",
                  "1",
                  "1",
                  "1",
                  "1",
                  "1",
                  "1",
                  "1"
                ],
                "expectedSylsPerSecond": "0.00",
                "actualSylsPerSecond": "0.52",
                "timeLength": 1875,
                "features": [
                  {
                    "sampleYVal": 117,
                    "sampleYDrawVal": 1401
                  },
                  {
                    "sampleYVal": 120,
                    "sampleYDrawVal": 1408
                  },
                  {
                    "sampleYVal": 150,
                    "sampleYDrawVal": 2147
                  },
                  {
                    "sampleYVal": 176,
                    "sampleYDrawVal": 3015
                  },
                  {
                    "sampleYVal": 185,
                    "sampleYDrawVal": 3319
                  },
                  {
                    "sampleYVal": 177,
                    "sampleYDrawVal": 2970
                  },
                  {
                    "sampleYVal": 181,
                    "sampleYDrawVal": 2993
                  },
                  {
                    "sampleYVal": 168,
                    "sampleYDrawVal": 2563
                  },
                  {
                    "sampleYVal": 166,
                    "sampleYDrawVal": 2531
                  },
                  {
                    "sampleYVal": 148,
                    "sampleYDrawVal": 2057
                  },
                  {
                    "sampleYVal": 132,
                    "sampleYDrawVal": 1784
                  },
                  {
                    "sampleYVal": 133,
                    "sampleYDrawVal": 1840
                  },
                  {
                    "sampleYVal": 56,
                    "sampleYDrawVal": 225
                  },
                  {
                    "sampleYVal": 31,
                    "sampleYDrawVal": 56
                  }
                ],
                "recordOffset": 21903,
                "seqUrl": "1527724123056_20633_709",
                "seqDescription": {
                  "analysis": {
                    "analysisType": "2sec"
                  },
                  "completeCount": 2,
                  "currentSeqPos": 0,
                  "pbId": "1270",
                  "text": "אוֹ ",
                  "type": "sentence",
                  "word": {
                    "id": 1266,
                    "language": "he",
                    "cleanInput": "אוֹתָהּ",
                    "type": "word",
                    "data": "[{\"text\":\"\\u05d0\\u05d5\\u05b9\",\"sylType\":\"v\",\"vowel\":\"O\"},{\"text\":\"\\u05ea\\u05b8\\u05d4\\u05bc\",\"vowel\":\"A\",\"isPlosive\":true}]",
                    "isNonesense": 0,
                    "audios": ""
                  }
                },
                "mistakesObjects": {},
                "hiddenMistakesObjects": {}
              },
              {
                "fieldType": "seq",
                "timerPos": 692,
                "calibrationEventId": "6581",
                "rate": 85,
                "deltaTimeMsFromPrevSeq": "1409.00",
                "yvalues": [
                  "114",
                  "143",
                  "149",
                  "162",
                  "173",
                  "187",
                  "195",
                  "194",
                  "176",
                  "151",
                  "98",
                  "63",
                  "48",
                  "0"
                ],
                "yDrawvalues": "1452,2020,1985,2517,2916,3427,3757,3618,2697,1947,590,208,161,0",
                "mistakes": [
                  {
                    "sampleOffset": 13,
                    "mistakeId": 6
                  }
                ],
                "hiddenMistakes": [],
                "softVals": [
                  "1",
                  "1",
                  "1",
                  "1",
                  "0",
                  "0",
                  "0",
                  "0",
                  "0",
                  "1",
                  "1",
                  "1",
                  "1",
                  "1"
                ],
                "expectedSylsPerSecond": "0.00",
                "actualSylsPerSecond": "0.56",
                "timeLength": 1750,
                "features": [
                  {
                    "sampleYVal": 147,
                    "sampleYDrawVal": 1839
                  },
                  {
                    "sampleYVal": 154,
                    "sampleYDrawVal": 2124
                  },
                  {
                    "sampleYVal": 151,
                    "sampleYDrawVal": 2002
                  },
                  {
                    "sampleYVal": 188,
                    "sampleYDrawVal": 3311
                  },
                  {
                    "sampleYVal": 181,
                    "sampleYDrawVal": 3019
                  },
                  {
                    "sampleYVal": 192,
                    "sampleYDrawVal": 3506
                  },
                  {
                    "sampleYVal": 201,
                    "sampleYDrawVal": 3887
                  },
                  {
                    "sampleYVal": 192,
                    "sampleYDrawVal": 3510
                  },
                  {
                    "sampleYVal": 157,
                    "sampleYDrawVal": 2192
                  },
                  {
                    "sampleYVal": 127,
                    "sampleYDrawVal": 1479
                  },
                  {
                    "sampleYVal": 80,
                    "sampleYDrawVal": 429
                  },
                  {
                    "sampleYVal": 49,
                    "sampleYDrawVal": 175
                  },
                  {
                    "sampleYVal": 46,
                    "sampleYDrawVal": 146
                  }
                ],
                "recordOffset": 25227,
                "seqUrl": "1527724123056_20633_709",
                "seqDescription": {
                  "analysis": {
                    "analysisType": "2sec"
                  },
                  "completeCount": 2,
                  "currentSeqPos": 1,
                  "pbId": "1270",
                  "text": "תָהּ ",
                  "type": "sentence",
                  "word": {
                    "id": 1266,
                    "language": "he",
                    "cleanInput": "אוֹתָהּ",
                    "type": "word",
                    "data": "[{\"text\":\"\\u05d0\\u05d5\\u05b9\",\"sylType\":\"v\",\"vowel\":\"O\"},{\"text\":\"\\u05ea\\u05b8\\u05d4\\u05bc\",\"vowel\":\"A\",\"isPlosive\":true}]",
                    "isNonesense": 0,
                    "audios": ""
                  }
                },
                "mistakesObjects": {
                  "tooShort": {
                    "titlei18Key": "MISTAKES.TOO_SHORT"
                  }
                },
                "hiddenMistakesObjects": {}
              },
              {
                "fieldType": "seq",
                "timerPos": 696,
                "calibrationEventId": "6581",
                "rate": 100,
                "deltaTimeMsFromPrevSeq": "1471.00",
                "yvalues": [
                  "97",
                  "134",
                  "167",
                  "189",
                  "195",
                  "196",
                  "193",
                  "191",
                  "184",
                  "164",
                  "137",
                  "97",
                  "68",
                  "50",
                  "0"
                ],
                "yDrawvalues": "1235,1866,2791,3567,3669,3648,3487,3394,3094,2316,1499,694,269,139,0",
                "mistakes": [],
                "hiddenMistakes": [],
                "softVals": [
                  "1",
                  "1",
                  "1",
                  "0",
                  "0",
                  "0",
                  "0",
                  "0",
                  "0",
                  "1",
                  "1",
                  "1",
                  "1",
                  "1",
                  "1"
                ],
                "expectedSylsPerSecond": "0.00",
                "actualSylsPerSecond": "0.51",
                "timeLength": 1875,
                "features": [
                  {
                    "sampleYVal": 122,
                    "sampleYDrawVal": 1385
                  },
                  {
                    "sampleYVal": 147,
                    "sampleYDrawVal": 1916
                  },
                  {
                    "sampleYVal": 195,
                    "sampleYDrawVal": 3549
                  },
                  {
                    "sampleYVal": 198,
                    "sampleYDrawVal": 3707
                  },
                  {
                    "sampleYVal": 197,
                    "sampleYDrawVal": 3671
                  },
                  {
                    "sampleYVal": 194,
                    "sampleYDrawVal": 3492
                  },
                  {
                    "sampleYVal": 198,
                    "sampleYDrawVal": 3718
                  },
                  {
                    "sampleYVal": 184,
                    "sampleYDrawVal": 3133
                  },
                  {
                    "sampleYVal": 185,
                    "sampleYDrawVal": 3191
                  },
                  {
                    "sampleYVal": 149,
                    "sampleYDrawVal": 1947
                  },
                  {
                    "sampleYVal": 122,
                    "sampleYDrawVal": 1291
                  },
                  {
                    "sampleYVal": 60,
                    "sampleYDrawVal": 236
                  },
                  {
                    "sampleYVal": 63,
                    "sampleYDrawVal": 298
                  },
                  {
                    "sampleYVal": 42,
                    "sampleYDrawVal": 98
                  }
                ],
                "recordOffset": -1448,
                "seqUrl": "1527724153057_20633_709",
                "seqDescription": {
                  "analysis": {
                    "analysisType": "2sec"
                  },
                  "completeCount": 3,
                  "currentSeqPos": 0,
                  "pbId": "1270",
                  "text": "בַּ ",
                  "type": "sentence",
                  "word": {
                    "id": 1267,
                    "language": "he",
                    "cleanInput": "בַּדֶּרֶךְ",
                    "type": "word",
                    "data": "[{\"text\":\"\\u05d1\\u05bc\\u05b7\",\"sylType\":\"cv\",\"vowel\":\"A\",\"isPlosive\":true},{\"text\":\"\\u05d3\\u05bc\\u05b6\",\"sylType\":\"cv\",\"vowel\":\"E\",\"isPlosive\":true},{\"text\":\"\\u05e8\\u05b6\\u05da\\u05b0\",\"sylType\":\"cvc\",\"vowel\":\"E\",\"isContinous\":true}]",
                    "isNonesense": 0,
                    "audios": ""
                  }
                },
                "mistakesObjects": {},
                "hiddenMistakesObjects": {}
              },
              {
                "fieldType": "seq",
                "timerPos": 699,
                "calibrationEventId": "6581",
                "rate": 80,
                "deltaTimeMsFromPrevSeq": "1340.00",
                "yvalues": [
                  "119",
                  "156",
                  "175",
                  "187",
                  "197",
                  "200",
                  "199",
                  "188",
                  "171",
                  "151",
                  "136",
                  "115",
                  "95",
                  "60",
                  "0"
                ],
                "yDrawvalues": "1765,2595,2994,3378,3764,3835,3735,3158,2501,1977,1591,1214,767,189,0",
                "mistakes": [
                  {
                    "sampleOffset": 2,
                    "mistakeId": 1
                  }
                ],
                "hiddenMistakes": [],
                "softVals": [
                  "1",
                  "1",
                  "0",
                  "0",
                  "0",
                  "0",
                  "0",
                  "0",
                  "0",
                  "1",
                  "1",
                  "1",
                  "1",
                  "1",
                  "1"
                ],
                "expectedSylsPerSecond": "0.00",
                "actualSylsPerSecond": "0.52",
                "timeLength": 1875,
                "features": [
                  {
                    "sampleYVal": 149,
                    "sampleYDrawVal": 2079
                  },
                  {
                    "sampleYVal": 170,
                    "sampleYDrawVal": 2702
                  },
                  {
                    "sampleYVal": 180,
                    "sampleYDrawVal": 2956
                  },
                  {
                    "sampleYVal": 194,
                    "sampleYDrawVal": 3519
                  },
                  {
                    "sampleYVal": 203,
                    "sampleYDrawVal": 3897
                  },
                  {
                    "sampleYVal": 202,
                    "sampleYDrawVal": 3850
                  },
                  {
                    "sampleYVal": 196,
                    "sampleYDrawVal": 3584
                  },
                  {
                    "sampleYVal": 180,
                    "sampleYDrawVal": 2957
                  },
                  {
                    "sampleYVal": 167,
                    "sampleYDrawVal": 2510
                  },
                  {
                    "sampleYVal": 141,
                    "sampleYDrawVal": 1804
                  },
                  {
                    "sampleYVal": 122,
                    "sampleYDrawVal": 1369
                  },
                  {
                    "sampleYVal": 103,
                    "sampleYDrawVal": 1056
                  },
                  {
                    "sampleYVal": 77,
                    "sampleYDrawVal": 493
                  },
                  {
                    "sampleYVal": 45,
                    "sampleYDrawVal": 114
                  }
                ],
                "recordOffset": 1812,
                "seqUrl": "1527724153057_20633_709",
                "seqDescription": {
                  "analysis": {
                    "analysisType": "2sec"
                  },
                  "completeCount": 3,
                  "currentSeqPos": 1,
                  "pbId": "1270",
                  "text": "דֶּ ",
                  "type": "sentence",
                  "word": {
                    "id": 1267,
                    "language": "he",
                    "cleanInput": "בַּדֶּרֶךְ",
                    "type": "word",
                    "data": "[{\"text\":\"\\u05d1\\u05bc\\u05b7\",\"sylType\":\"cv\",\"vowel\":\"A\",\"isPlosive\":true},{\"text\":\"\\u05d3\\u05bc\\u05b6\",\"sylType\":\"cv\",\"vowel\":\"E\",\"isPlosive\":true},{\"text\":\"\\u05e8\\u05b6\\u05da\\u05b0\",\"sylType\":\"cvc\",\"vowel\":\"E\",\"isContinous\":true}]",
                    "isNonesense": 0,
                    "audios": ""
                  }
                },
                "mistakesObjects": {
                  "gentleOnset": {
                    "titlei18Key": "MISTAKES.GENTLE_ONSET"
                  }
                },
                "hiddenMistakesObjects": {}
              },
              {
                "fieldType": "seq",
                "timerPos": 703,
                "calibrationEventId": "6581",
                "rate": 100,
                "deltaTimeMsFromPrevSeq": "1540.00",
                "yvalues": [
                  "105",
                  "133",
                  "157",
                  "166",
                  "175",
                  "179",
                  "189",
                  "198",
                  "195",
                  "172",
                  "147",
                  "133",
                  "130",
                  "103",
                  "81",
                  "51",
                  "0"
                ],
                "yDrawvalues": "1329,1845,2525,2587,2835,2962,3397,3776,3533,2482,1737,1494,1446,910,623,145,0",
                "mistakes": [],
                "hiddenMistakes": [],
                "softVals": [
                  "1",
                  "1",
                  "1",
                  "1",
                  "0",
                  "0",
                  "0",
                  "0",
                  "0",
                  "0",
                  "1",
                  "1",
                  "1",
                  "1",
                  "1",
                  "1",
                  "1"
                ],
                "expectedSylsPerSecond": "0.00",
                "actualSylsPerSecond": "0.46",
                "timeLength": 2125,
                "features": [
                  {
                    "sampleYVal": 134,
                    "sampleYDrawVal": 1646
                  },
                  {
                    "sampleYVal": 143,
                    "sampleYDrawVal": 1952
                  },
                  {
                    "sampleYVal": 169,
                    "sampleYDrawVal": 2661
                  },
                  {
                    "sampleYVal": 174,
                    "sampleYDrawVal": 2736
                  },
                  {
                    "sampleYVal": 179,
                    "sampleYDrawVal": 2903
                  },
                  {
                    "sampleYVal": 182,
                    "sampleYDrawVal": 3040
                  },
                  {
                    "sampleYVal": 196,
                    "sampleYDrawVal": 3568
                  },
                  {
                    "sampleYVal": 193,
                    "sampleYDrawVal": 3462
                  },
                  {
                    "sampleYVal": 188,
                    "sampleYDrawVal": 3206
                  },
                  {
                    "sampleYVal": 149,
                    "sampleYDrawVal": 1913
                  },
                  {
                    "sampleYVal": 122,
                    "sampleYDrawVal": 1296
                  },
                  {
                    "sampleYVal": 136,
                    "sampleYDrawVal": 1624
                  },
                  {
                    "sampleYVal": 125,
                    "sampleYDrawVal": 1386
                  },
                  {
                    "sampleYVal": 98,
                    "sampleYDrawVal": 965
                  },
                  {
                    "sampleYVal": 52,
                    "sampleYDrawVal": 261
                  },
                  {
                    "sampleYVal": 37,
                    "sampleYDrawVal": 70
                  }
                ],
                "recordOffset": 5273,
                "seqUrl": "1527724153057_20633_709",
                "seqDescription": {
                  "analysis": {
                    "analysisType": "2sec"
                  },
                  "completeCount": 3,
                  "currentSeqPos": 2,
                  "pbId": "1270",
                  "text": "רֶךְ ",
                  "type": "sentence",
                  "word": {
                    "id": 1267,
                    "language": "he",
                    "cleanInput": "בַּדֶּרֶךְ",
                    "type": "word",
                    "data": "[{\"text\":\"\\u05d1\\u05bc\\u05b7\",\"sylType\":\"cv\",\"vowel\":\"A\",\"isPlosive\":true},{\"text\":\"\\u05d3\\u05bc\\u05b6\",\"sylType\":\"cv\",\"vowel\":\"E\",\"isPlosive\":true},{\"text\":\"\\u05e8\\u05b6\\u05da\\u05b0\",\"sylType\":\"cvc\",\"vowel\":\"E\",\"isContinous\":true}]",
                    "isNonesense": 0,
                    "audios": ""
                  }
                },
                "mistakesObjects": {},
                "hiddenMistakesObjects": {}
              },
              {
                "fieldType": "seq",
                "timerPos": 706,
                "calibrationEventId": "6581",
                "rate": 100,
                "deltaTimeMsFromPrevSeq": "1529.00",
                "yvalues": [
                  "89",
                  "127",
                  "141",
                  "159",
                  "181",
                  "202",
                  "204",
                  "187",
                  "169",
                  "133",
                  "112",
                  "93",
                  "67",
                  "48",
                  "0"
                ],
                "yDrawvalues": "901,1674,1863,2493,3362,4195,4068,3100,2453,1454,1067,842,349,150,0",
                "mistakes": [],
                "hiddenMistakes": [],
                "softVals": [
                  "1",
                  "1",
                  "1",
                  "1",
                  "0",
                  "0",
                  "0",
                  "0",
                  "1",
                  "1",
                  "1",
                  "1",
                  "1",
                  "1",
                  "1"
                ],
                "expectedSylsPerSecond": "0.00",
                "actualSylsPerSecond": "0.52",
                "timeLength": 1875,
                "features": [
                  {
                    "sampleYVal": 112,
                    "sampleYDrawVal": 1188
                  },
                  {
                    "sampleYVal": 145,
                    "sampleYDrawVal": 1871
                  },
                  {
                    "sampleYVal": 149,
                    "sampleYDrawVal": 1968
                  },
                  {
                    "sampleYVal": 169,
                    "sampleYDrawVal": 2682
                  },
                  {
                    "sampleYVal": 204,
                    "sampleYDrawVal": 4013
                  },
                  {
                    "sampleYVal": 211,
                    "sampleYDrawVal": 4304
                  },
                  {
                    "sampleYVal": 196,
                    "sampleYDrawVal": 3631
                  },
                  {
                    "sampleYVal": 177,
                    "sampleYDrawVal": 2855
                  },
                  {
                    "sampleYVal": 149,
                    "sampleYDrawVal": 2013
                  },
                  {
                    "sampleYVal": 113,
                    "sampleYDrawVal": 1153
                  },
                  {
                    "sampleYVal": 112,
                    "sampleYDrawVal": 1136
                  },
                  {
                    "sampleYVal": 64,
                    "sampleYDrawVal": 574
                  },
                  {
                    "sampleYVal": 54,
                    "sampleYDrawVal": 288
                  },
                  {
                    "sampleYVal": 35,
                    "sampleYDrawVal": 84
                  }
                ],
                "recordOffset": 8972,
                "seqUrl": "1527724153057_20633_709",
                "seqDescription": {
                  "analysis": {
                    "analysisType": "2sec"
                  },
                  "completeCount": 4,
                  "currentSeqPos": 0,
                  "pbId": "1270",
                  "text": "לְ ",
                  "type": "sentence",
                  "word": {
                    "id": 1268,
                    "language": "he",
                    "cleanInput": "לְבֵית",
                    "type": "word",
                    "data": "[{\"text\":\"\\u05dc\\u05b0\",\"sylType\":\"cv\",\"vowel\":\"E\",\"isContinous\":true},{\"text\":\"\\u05d1\\u05b5\\u05d9\\u05ea\",\"sylType\":\"cvc\",\"isClusterEnd\":true,\"isPlosive\":true,\"isContinous\":true,\"vowel\":\"E\"}]",
                    "isNonesense": 0,
                    "audios": ""
                  }
                },
                "mistakesObjects": {},
                "hiddenMistakesObjects": {}
              },
              {
                "fieldType": "seq",
                "timerPos": 710,
                "calibrationEventId": "6581",
                "rate": 80,
                "deltaTimeMsFromPrevSeq": "1340.00",
                "yvalues": [
                  "117",
                  "120",
                  "135",
                  "160",
                  "176",
                  "175",
                  "182",
                  "185",
                  "183",
                  "173",
                  "162",
                  "150",
                  "105",
                  "67",
                  "42",
                  "0"
                ],
                "yDrawvalues": "1711,1308,1814,2629,3094,2769,3090,3205,3064,2640,2261,1881,759,262,83,0",
                "mistakes": [
                  {
                    "sampleOffset": 4,
                    "mistakeId": 5
                  }
                ],
                "hiddenMistakes": [],
                "softVals": [
                  "1",
                  "1",
                  "1",
                  "1",
                  "0",
                  "0",
                  "0",
                  "0",
                  "0",
                  "0",
                  "1",
                  "1",
                  "1",
                  "1",
                  "1",
                  "1"
                ],
                "expectedSylsPerSecond": "0.00",
                "actualSylsPerSecond": "0.49",
                "timeLength": 2000,
                "features": [
                  {
                    "sampleYVal": 130,
                    "sampleYDrawVal": 1471
                  },
                  {
                    "sampleYVal": 126,
                    "sampleYDrawVal": 1449
                  },
                  {
                    "sampleYVal": 154,
                    "sampleYDrawVal": 2218
                  },
                  {
                    "sampleYVal": 182,
                    "sampleYDrawVal": 3100
                  },
                  {
                    "sampleYVal": 181,
                    "sampleYDrawVal": 3017
                  },
                  {
                    "sampleYVal": 179,
                    "sampleYDrawVal": 2901
                  },
                  {
                    "sampleYVal": 187,
                    "sampleYDrawVal": 3238
                  },
                  {
                    "sampleYVal": 185,
                    "sampleYDrawVal": 3138
                  },
                  {
                    "sampleYVal": 176,
                    "sampleYDrawVal": 2801
                  },
                  {
                    "sampleYVal": 169,
                    "sampleYDrawVal": 2559
                  },
                  {
                    "sampleYVal": 149,
                    "sampleYDrawVal": 1939
                  },
                  {
                    "sampleYVal": 123,
                    "sampleYDrawVal": 1298
                  },
                  {
                    "sampleYVal": 74,
                    "sampleYDrawVal": 399
                  },
                  {
                    "sampleYVal": 40,
                    "sampleYDrawVal": 158
                  },
                  {
                    "sampleYVal": 37,
                    "sampleYDrawVal": 94
                  }
                ],
                "recordOffset": 12237,
                "seqUrl": "1527724153057_20633_709",
                "seqDescription": {
                  "analysis": {
                    "analysisType": "2sec"
                  },
                  "completeCount": 4,
                  "currentSeqPos": 1,
                  "pbId": "1270",
                  "text": "בֵית ",
                  "type": "sentence",
                  "word": {
                    "id": 1268,
                    "language": "he",
                    "cleanInput": "לְבֵית",
                    "type": "word",
                    "data": "[{\"text\":\"\\u05dc\\u05b0\",\"sylType\":\"cv\",\"vowel\":\"E\",\"isContinous\":true},{\"text\":\"\\u05d1\\u05b5\\u05d9\\u05ea\",\"sylType\":\"cvc\",\"isClusterEnd\":true,\"isPlosive\":true,\"isContinous\":true,\"vowel\":\"E\"}]",
                    "isNonesense": 0,
                    "audios": ""
                  }
                },
                "mistakesObjects": {
                  "volControlUp": {
                    "titlei18Key": "MISTAKES.VOLCONTROL_UP"
                  }
                },
                "hiddenMistakesObjects": {}
              },
              {
                "fieldType": "seq",
                "timerPos": 713,
                "calibrationEventId": "6581",
                "rate": 85,
                "deltaTimeMsFromPrevSeq": "1659.00",
                "yvalues": [
                  "103",
                  "126",
                  "140",
                  "169",
                  "186",
                  "187",
                  "179",
                  "173",
                  "157",
                  "142",
                  "84",
                  "55",
                  "0"
                ],
                "yDrawvalues": "1338,1503,1827,2864,3436,3331,2946,2700,2086,1693,414,152,0",
                "mistakes": [
                  {
                    "sampleOffset": 12,
                    "mistakeId": 6
                  }
                ],
                "hiddenMistakes": [],
                "softVals": [
                  "1",
                  "1",
                  "1",
                  "1",
                  "0",
                  "0",
                  "0",
                  "0",
                  "1",
                  "1",
                  "1",
                  "1",
                  "1"
                ],
                "expectedSylsPerSecond": "0.00",
                "actualSylsPerSecond": "0.60",
                "timeLength": 1625,
                "features": [
                  {
                    "sampleYVal": 135,
                    "sampleYDrawVal": 1574
                  },
                  {
                    "sampleYVal": 149,
                    "sampleYDrawVal": 1934
                  },
                  {
                    "sampleYVal": 142,
                    "sampleYDrawVal": 1816
                  },
                  {
                    "sampleYVal": 190,
                    "sampleYDrawVal": 3339
                  },
                  {
                    "sampleYVal": 198,
                    "sampleYDrawVal": 3723
                  },
                  {
                    "sampleYVal": 186,
                    "sampleYDrawVal": 3230
                  },
                  {
                    "sampleYVal": 172,
                    "sampleYDrawVal": 2716
                  },
                  {
                    "sampleYVal": 163,
                    "sampleYDrawVal": 2394
                  },
                  {
                    "sampleYVal": 144,
                    "sampleYDrawVal": 1827
                  },
                  {
                    "sampleYVal": 115,
                    "sampleYDrawVal": 1137
                  },
                  {
                    "sampleYVal": 55,
                    "sampleYDrawVal": 194
                  },
                  {
                    "sampleYVal": 45,
                    "sampleYDrawVal": 142
                  }
                ],
                "recordOffset": 15942,
                "seqUrl": "1527724153057_20633_709",
                "seqDescription": {
                  "analysis": {
                    "analysisType": "2sec"
                  },
                  "completeCount": 5,
                  "currentSeqPos": 0,
                  "pbId": "1270",
                  "text": "הַ ",
                  "type": "sentence",
                  "word": {
                    "id": 1269,
                    "language": "he",
                    "cleanInput": "הַסֵּפֶר",
                    "type": "word",
                    "data": "[{\"text\":\"\\u05d4\\u05b7\",\"sylType\":\"v\",\"vowel\":\"A\"},{\"text\":\"\\u05e1\\u05bc\\u05b5\",\"sylType\":\"cv\",\"vowel\":\"E\",\"isContinous\":true},{\"text\":\"\\u05e4\\u05b6\\u05e8\",\"sylType\":\"cvc\",\"vowel\":\"E\",\"isContinous\":true}]",
                    "isNonesense": 0,
                    "audios": ""
                  }
                },
                "mistakesObjects": {
                  "tooShort": {
                    "titlei18Key": "MISTAKES.TOO_SHORT"
                  }
                },
                "hiddenMistakesObjects": {}
              },
              {
                "fieldType": "seq",
                "timerPos": 716,
                "calibrationEventId": "6581",
                "rate": 80,
                "deltaTimeMsFromPrevSeq": "1601.00",
                "yvalues": [
                  "142",
                  "179",
                  "188",
                  "184",
                  "180",
                  "178",
                  "183",
                  "175",
                  "156",
                  "143",
                  "121",
                  "103",
                  "64",
                  "38",
                  "0"
                ],
                "yDrawvalues": "2480,3355,3466,3141,2948,2881,3156,2710,1982,1657,1077,808,227,58,0",
                "mistakes": [
                  {
                    "sampleOffset": 1,
                    "mistakeId": 1
                  }
                ],
                "hiddenMistakes": [],
                "softVals": [
                  "1",
                  "0",
                  "0",
                  "0",
                  "0",
                  "0",
                  "0",
                  "0",
                  "1",
                  "1",
                  "1",
                  "1",
                  "1",
                  "1",
                  "1"
                ],
                "expectedSylsPerSecond": "0.00",
                "actualSylsPerSecond": "1.04",
                "timeLength": 1875,
                "features": [
                  {
                    "sampleYVal": 173,
                    "sampleYDrawVal": 2810
                  },
                  {
                    "sampleYVal": 189,
                    "sampleYDrawVal": 3418
                  },
                  {
                    "sampleYVal": 192,
                    "sampleYDrawVal": 3521
                  },
                  {
                    "sampleYVal": 177,
                    "sampleYDrawVal": 2882
                  },
                  {
                    "sampleYVal": 178,
                    "sampleYDrawVal": 2846
                  },
                  {
                    "sampleYVal": 173,
                    "sampleYDrawVal": 2692
                  },
                  {
                    "sampleYVal": 184,
                    "sampleYDrawVal": 3110
                  },
                  {
                    "sampleYVal": 169,
                    "sampleYDrawVal": 2554
                  },
                  {
                    "sampleYVal": 160,
                    "sampleYDrawVal": 2211
                  },
                  {
                    "sampleYVal": 129,
                    "sampleYDrawVal": 1355
                  },
                  {
                    "sampleYVal": 107,
                    "sampleYDrawVal": 856
                  },
                  {
                    "sampleYVal": 103,
                    "sampleYDrawVal": 920
                  },
                  {
                    "sampleYVal": 33,
                    "sampleYDrawVal": 51
                  },
                  {
                    "sampleYVal": 24,
                    "sampleYDrawVal": 46
                  }
                ],
                "recordOffset": 19211,
                "seqUrl": "1527724153057_20633_709",
                "seqDescription": {
                  "analysis": {
                    "analysisType": "2sec"
                  },
                  "completeCount": 5,
                  "currentSeqPos": 1,
                  "pbId": "1270",
                  "text": "סֵּ ",
                  "type": "sentence",
                  "word": {
                    "id": 1269,
                    "language": "he",
                    "cleanInput": "הַסֵּפֶר",
                    "type": "word",
                    "data": "[{\"text\":\"\\u05d4\\u05b7\",\"sylType\":\"v\",\"vowel\":\"A\"},{\"text\":\"\\u05e1\\u05bc\\u05b5\",\"sylType\":\"cv\",\"vowel\":\"E\",\"isContinous\":true},{\"text\":\"\\u05e4\\u05b6\\u05e8\",\"sylType\":\"cvc\",\"vowel\":\"E\",\"isContinous\":true}]",
                    "isNonesense": 0,
                    "audios": ""
                  }
                },
                "mistakesObjects": {
                  "gentleOnset": {
                    "titlei18Key": "MISTAKES.GENTLE_ONSET"
                  }
                },
                "hiddenMistakesObjects": {}
              },
              {
                "fieldType": "seq",
                "timerPos": 719,
                "calibrationEventId": "6581",
                "rate": 80,
                "deltaTimeMsFromPrevSeq": "1080.00",
                "yvalues": [
                  "98",
                  "151",
                  "175",
                  "183",
                  "182",
                  "190",
                  "177",
                  "162",
                  "146",
                  "132",
                  "93",
                  "68",
                  "41",
                  "0"
                ],
                "yDrawvalues": "1305,2449,3099,3276,3125,3510,2854,2270,1856,1551,712,485,86,0",
                "mistakes": [
                  {
                    "sampleOffset": 2,
                    "mistakeId": 1
                  }
                ],
                "hiddenMistakes": [],
                "softVals": [
                  "1",
                  "1",
                  "0",
                  "0",
                  "0",
                  "0",
                  "0",
                  "1",
                  "1",
                  "1",
                  "1",
                  "1",
                  "1",
                  "1"
                ],
                "expectedSylsPerSecond": "0.00",
                "actualSylsPerSecond": "0.56",
                "timeLength": 1750,
                "features": [
                  {
                    "sampleYVal": 147,
                    "sampleYDrawVal": 1936
                  },
                  {
                    "sampleYVal": 171,
                    "sampleYDrawVal": 2659
                  },
                  {
                    "sampleYVal": 180,
                    "sampleYDrawVal": 3045
                  },
                  {
                    "sampleYVal": 184,
                    "sampleYDrawVal": 3188
                  },
                  {
                    "sampleYVal": 193,
                    "sampleYDrawVal": 3528
                  },
                  {
                    "sampleYVal": 179,
                    "sampleYDrawVal": 3052
                  },
                  {
                    "sampleYVal": 173,
                    "sampleYDrawVal": 2758
                  },
                  {
                    "sampleYVal": 154,
                    "sampleYDrawVal": 2132
                  },
                  {
                    "sampleYVal": 138,
                    "sampleYDrawVal": 1762
                  },
                  {
                    "sampleYVal": 112,
                    "sampleYDrawVal": 1123
                  },
                  {
                    "sampleYVal": 74,
                    "sampleYDrawVal": 685
                  },
                  {
                    "sampleYVal": 53,
                    "sampleYDrawVal": 279
                  },
                  {
                    "sampleYVal": 30,
                    "sampleYDrawVal": 39
                  }
                ],
                "recordOffset": 22217,
                "seqUrl": "1527724153057_20633_709",
                "seqDescription": {
                  "analysis": {
                    "analysisType": "2sec"
                  },
                  "completeCount": 5,
                  "currentSeqPos": 2,
                  "pbId": "1270",
                  "text": "פֶר ",
                  "type": "sentence",
                  "word": {
                    "id": 1269,
                    "language": "he",
                    "cleanInput": "הַסֵּפֶר",
                    "type": "word",
                    "data": "[{\"text\":\"\\u05d4\\u05b7\",\"sylType\":\"v\",\"vowel\":\"A\"},{\"text\":\"\\u05e1\\u05bc\\u05b5\",\"sylType\":\"cv\",\"vowel\":\"E\",\"isContinous\":true},{\"text\":\"\\u05e4\\u05b6\\u05e8\",\"sylType\":\"cvc\",\"vowel\":\"E\",\"isContinous\":true}]",
                    "isNonesense": 0,
                    "audios": ""
                  }
                },
                "mistakesObjects": {
                  "gentleOnset": {
                    "titlei18Key": "MISTAKES.GENTLE_ONSET"
                  }
                },
                "hiddenMistakesObjects": {}
              },
              {
                "fieldType": "seq",
                "timerPos": 722,
                "calibrationEventId": "6581",
                "rate": 100,
                "deltaTimeMsFromPrevSeq": "1340.00",
                "yvalues": [
                  "93",
                  "142",
                  "166",
                  "178",
                  "188",
                  "194",
                  "199",
                  "195",
                  "187",
                  "169",
                  "135",
                  "96",
                  "70",
                  "58",
                  "0"
                ],
                "yDrawvalues": "991,2128,2682,3055,3381,3625,3880,3618,3255,2507,1316,599,277,176,0",
                "mistakes": [],
                "hiddenMistakes": [],
                "softVals": [
                  "1",
                  "1",
                  "1",
                  "0",
                  "0",
                  "0",
                  "0",
                  "0",
                  "0",
                  "1",
                  "1",
                  "1",
                  "1",
                  "1",
                  "1"
                ],
                "expectedSylsPerSecond": "0.00",
                "actualSylsPerSecond": "0.52",
                "timeLength": 1875,
                "features": [
                  {
                    "sampleYVal": 140,
                    "sampleYDrawVal": 1626
                  },
                  {
                    "sampleYVal": 168,
                    "sampleYDrawVal": 2510
                  },
                  {
                    "sampleYVal": 177,
                    "sampleYDrawVal": 2866
                  },
                  {
                    "sampleYVal": 187,
                    "sampleYDrawVal": 3208
                  },
                  {
                    "sampleYVal": 193,
                    "sampleYDrawVal": 3466
                  },
                  {
                    "sampleYVal": 204,
                    "sampleYDrawVal": 3997
                  },
                  {
                    "sampleYVal": 196,
                    "sampleYDrawVal": 3723
                  },
                  {
                    "sampleYVal": 191,
                    "sampleYDrawVal": 3487
                  },
                  {
                    "sampleYVal": 181,
                    "sampleYDrawVal": 3087
                  },
                  {
                    "sampleYVal": 154,
                    "sampleYDrawVal": 2092
                  },
                  {
                    "sampleYVal": 122,
                    "sampleYDrawVal": 1194
                  },
                  {
                    "sampleYVal": 58,
                    "sampleYDrawVal": 220
                  },
                  {
                    "sampleYVal": 58,
                    "sampleYDrawVal": 215
                  },
                  {
                    "sampleYVal": 50,
                    "sampleYDrawVal": 139
                  }
                ],
                "recordOffset": 25353,
                "seqUrl": "1527724153057_20633_709",
                "seqDescription": {
                  "analysis": {
                    "analysisType": "2sec"
                  },
                  "completeCount": 0,
                  "currentSeqPos": 0,
                  "pbId": "1042",
                  "text": "הַ ",
                  "type": "sentence",
                  "word": {
                    "id": 1038,
                    "language": "he",
                    "cleanInput": "הַמְּכוֹנִית",
                    "type": "word",
                    "data": "[{\"text\":\"\\u05d4\\u05b7\",\"sylType\":\"v\",\"vowel\":\"A\"},{\"text\":\"\\u05de\\u05bc\\u05b0\",\"sylType\":\"cv\",\"vowel\":\"E\",\"isContinous\":true},{\"text\":\"\\u05db\\u05d5\\u05b9\",\"sylType\":\"cv\",\"vowel\":\"O\",\"isContinous\":true},{\"text\":\"\\u05e0\\u05b4\\u05d9\\u05ea\",\"sylType\":\"cvc\",\"vowel\":\"I\",\"isPlosive\":true,\"isContinous\":true}]",
                    "isNonesense": 0,
                    "audios": ""
                  }
                },
                "mistakesObjects": {},
                "hiddenMistakesObjects": {}
              },
              {
                "fieldType": "seq",
                "timerPos": 725,
                "calibrationEventId": "6581",
                "rate": 100,
                "deltaTimeMsFromPrevSeq": "1089.00",
                "yvalues": [
                  "106",
                  "146",
                  "167",
                  "173",
                  "183",
                  "188",
                  "173",
                  "169",
                  "158",
                  "123",
                  "110",
                  "113",
                  "91",
                  "52",
                  "0"
                ],
                "yDrawvalues": "1330,2258,2836,2892,3260,3380,2687,2701,2313,1209,1003,1154,694,129,0",
                "mistakes": [],
                "hiddenMistakes": [],
                "softVals": [
                  "1",
                  "1",
                  "1",
                  "0",
                  "0",
                  "0",
                  "0",
                  "1",
                  "1",
                  "1",
                  "1",
                  "1",
                  "1",
                  "1",
                  "1"
                ],
                "expectedSylsPerSecond": "0.00",
                "actualSylsPerSecond": "0.52",
                "timeLength": 1875,
                "features": [
                  {
                    "sampleYVal": 133,
                    "sampleYDrawVal": 1618
                  },
                  {
                    "sampleYVal": 161,
                    "sampleYDrawVal": 2360
                  },
                  {
                    "sampleYVal": 160,
                    "sampleYDrawVal": 2526
                  },
                  {
                    "sampleYVal": 175,
                    "sampleYDrawVal": 2893
                  },
                  {
                    "sampleYVal": 193,
                    "sampleYDrawVal": 3518
                  },
                  {
                    "sampleYVal": 187,
                    "sampleYDrawVal": 3259
                  },
                  {
                    "sampleYVal": 163,
                    "sampleYDrawVal": 2588
                  },
                  {
                    "sampleYVal": 166,
                    "sampleYDrawVal": 2612
                  },
                  {
                    "sampleYVal": 145,
                    "sampleYDrawVal": 1911
                  },
                  {
                    "sampleYVal": 77,
                    "sampleYDrawVal": 526
                  },
                  {
                    "sampleYVal": 111,
                    "sampleYDrawVal": 1033
                  },
                  {
                    "sampleYVal": 105,
                    "sampleYDrawVal": 998
                  },
                  {
                    "sampleYVal": 58,
                    "sampleYDrawVal": 297
                  },
                  {
                    "sampleYVal": 31,
                    "sampleYDrawVal": 46
                  }
                ],
                "recordOffset": -1635,
                "seqUrl": "1527724183055_20633_709",
                "seqDescription": {
                  "analysis": {
                    "analysisType": "2sec"
                  },
                  "completeCount": 0,
                  "currentSeqPos": 1,
                  "pbId": "1042",
                  "text": "מְּ ",
                  "type": "sentence",
                  "word": {
                    "id": 1038,
                    "language": "he",
                    "cleanInput": "הַמְּכוֹנִית",
                    "type": "word",
                    "data": "[{\"text\":\"\\u05d4\\u05b7\",\"sylType\":\"v\",\"vowel\":\"A\"},{\"text\":\"\\u05de\\u05bc\\u05b0\",\"sylType\":\"cv\",\"vowel\":\"E\",\"isContinous\":true},{\"text\":\"\\u05db\\u05d5\\u05b9\",\"sylType\":\"cv\",\"vowel\":\"O\",\"isContinous\":true},{\"text\":\"\\u05e0\\u05b4\\u05d9\\u05ea\",\"sylType\":\"cvc\",\"vowel\":\"I\",\"isPlosive\":true,\"isContinous\":true}]",
                    "isNonesense": 0,
                    "audios": ""
                  }
                },
                "mistakesObjects": {},
                "hiddenMistakesObjects": {}
              },
              {
                "fieldType": "seq",
                "timerPos": 728,
                "calibrationEventId": "6581",
                "rate": 85,
                "deltaTimeMsFromPrevSeq": "969.00",
                "yvalues": [
                  "64",
                  "56",
                  "101",
                  "143",
                  "163",
                  "175",
                  "183",
                  "180",
                  "174",
                  "147",
                  "110",
                  "65",
                  "41",
                  "0"
                ],
                "yDrawvalues": "342,322,1362,2398,2665,2984,3241,3023,2859,1989,1098,273,114,0",
                "mistakes": [
                  {
                    "sampleOffset": 13,
                    "mistakeId": 6
                  }
                ],
                "hiddenMistakes": [],
                "softVals": [
                  "1",
                  "1",
                  "1",
                  "1",
                  "1",
                  "0",
                  "0",
                  "0",
                  "0",
                  "1",
                  "1",
                  "1",
                  "1",
                  "1"
                ],
                "expectedSylsPerSecond": "0.00",
                "actualSylsPerSecond": "0.56",
                "timeLength": 1750,
                "features": [
                  {
                    "sampleYVal": 37,
                    "sampleYDrawVal": 66
                  },
                  {
                    "sampleYVal": 81,
                    "sampleYDrawVal": 687
                  },
                  {
                    "sampleYVal": 148,
                    "sampleYDrawVal": 2202
                  },
                  {
                    "sampleYVal": 162,
                    "sampleYDrawVal": 2522
                  },
                  {
                    "sampleYVal": 169,
                    "sampleYDrawVal": 2686
                  },
                  {
                    "sampleYVal": 182,
                    "sampleYDrawVal": 3104
                  },
                  {
                    "sampleYVal": 185,
                    "sampleYDrawVal": 3250
                  },
                  {
                    "sampleYVal": 175,
                    "sampleYDrawVal": 2835
                  },
                  {
                    "sampleYVal": 158,
                    "sampleYDrawVal": 2455
                  },
                  {
                    "sampleYVal": 130,
                    "sampleYDrawVal": 1787
                  },
                  {
                    "sampleYVal": 70,
                    "sampleYDrawVal": 485
                  },
                  {
                    "sampleYVal": 51,
                    "sampleYDrawVal": 228
                  },
                  {
                    "sampleYVal": 33,
                    "sampleYDrawVal": 58
                  }
                ],
                "recordOffset": 1249,
                "seqUrl": "1527724183055_20633_709",
                "seqDescription": {
                  "analysis": {
                    "analysisType": "2sec"
                  },
                  "completeCount": 0,
                  "currentSeqPos": 2,
                  "pbId": "1042",
                  "text": "כוֹ ",
                  "type": "sentence",
                  "word": {
                    "id": 1038,
                    "language": "he",
                    "cleanInput": "הַמְּכוֹנִית",
                    "type": "word",
                    "data": "[{\"text\":\"\\u05d4\\u05b7\",\"sylType\":\"v\",\"vowel\":\"A\"},{\"text\":\"\\u05de\\u05bc\\u05b0\",\"sylType\":\"cv\",\"vowel\":\"E\",\"isContinous\":true},{\"text\":\"\\u05db\\u05d5\\u05b9\",\"sylType\":\"cv\",\"vowel\":\"O\",\"isContinous\":true},{\"text\":\"\\u05e0\\u05b4\\u05d9\\u05ea\",\"sylType\":\"cvc\",\"vowel\":\"I\",\"isPlosive\":true,\"isContinous\":true}]",
                    "isNonesense": 0,
                    "audios": ""
                  }
                },
                "mistakesObjects": {
                  "tooShort": {
                    "titlei18Key": "MISTAKES.TOO_SHORT"
                  }
                },
                "hiddenMistakesObjects": {}
              },
              {
                "fieldType": "seq",
                "timerPos": 731,
                "calibrationEventId": "6581",
                "rate": 80,
                "deltaTimeMsFromPrevSeq": "1150.00",
                "yvalues": [
                  "94",
                  "152",
                  "175",
                  "184",
                  "185",
                  "180",
                  "181",
                  "185",
                  "171",
                  "143",
                  "120",
                  "95",
                  "61",
                  "0"
                ],
                "yDrawvalues": "1274,2601,3032,3253,3282,3008,3043,3252,2567,1632,1155,731,190,0",
                "mistakes": [
                  {
                    "sampleOffset": 2,
                    "mistakeId": 1
                  }
                ],
                "hiddenMistakes": [],
                "softVals": [
                  "1",
                  "1",
                  "0",
                  "0",
                  "0",
                  "0",
                  "0",
                  "0",
                  "0",
                  "1",
                  "1",
                  "1",
                  "1",
                  "1"
                ],
                "expectedSylsPerSecond": "0.00",
                "actualSylsPerSecond": "1.16",
                "timeLength": 1750,
                "features": [
                  {
                    "sampleYVal": 135,
                    "sampleYDrawVal": 1776
                  },
                  {
                    "sampleYVal": 177,
                    "sampleYDrawVal": 2880
                  },
                  {
                    "sampleYVal": 188,
                    "sampleYDrawVal": 3276
                  },
                  {
                    "sampleYVal": 190,
                    "sampleYDrawVal": 3387
                  },
                  {
                    "sampleYVal": 185,
                    "sampleYDrawVal": 3256
                  },
                  {
                    "sampleYVal": 174,
                    "sampleYDrawVal": 2776
                  },
                  {
                    "sampleYVal": 185,
                    "sampleYDrawVal": 3188
                  },
                  {
                    "sampleYVal": 175,
                    "sampleYDrawVal": 2827
                  },
                  {
                    "sampleYVal": 152,
                    "sampleYDrawVal": 2005
                  },
                  {
                    "sampleYVal": 128,
                    "sampleYDrawVal": 1426
                  },
                  {
                    "sampleYVal": 106,
                    "sampleYDrawVal": 993
                  },
                  {
                    "sampleYVal": 73,
                    "sampleYDrawVal": 437
                  },
                  {
                    "sampleYVal": 43,
                    "sampleYDrawVal": 100
                  }
                ],
                "recordOffset": 4129,
                "seqUrl": "1527724183055_20633_709",
                "seqDescription": {
                  "analysis": {
                    "analysisType": "2sec"
                  },
                  "completeCount": 0,
                  "currentSeqPos": 3,
                  "pbId": "1042",
                  "text": "נִית ",
                  "type": "sentence",
                  "word": {
                    "id": 1038,
                    "language": "he",
                    "cleanInput": "הַמְּכוֹנִית",
                    "type": "word",
                    "data": "[{\"text\":\"\\u05d4\\u05b7\",\"sylType\":\"v\",\"vowel\":\"A\"},{\"text\":\"\\u05de\\u05bc\\u05b0\",\"sylType\":\"cv\",\"vowel\":\"E\",\"isContinous\":true},{\"text\":\"\\u05db\\u05d5\\u05b9\",\"sylType\":\"cv\",\"vowel\":\"O\",\"isContinous\":true},{\"text\":\"\\u05e0\\u05b4\\u05d9\\u05ea\",\"sylType\":\"cvc\",\"vowel\":\"I\",\"isPlosive\":true,\"isContinous\":true}]",
                    "isNonesense": 0,
                    "audios": ""
                  }
                },
                "mistakesObjects": {
                  "gentleOnset": {
                    "titlei18Key": "MISTAKES.GENTLE_ONSET"
                  }
                },
                "hiddenMistakesObjects": {}
              },
              {
                "fieldType": "seq",
                "timerPos": 734,
                "calibrationEventId": "6581",
                "rate": 80,
                "deltaTimeMsFromPrevSeq": "1174.00",
                "yvalues": [
                  "140",
                  "168",
                  "175",
                  "168",
                  "156",
                  "150",
                  "151",
                  "122",
                  "97",
                  "81",
                  "65",
                  "40",
                  "0"
                ],
                "yDrawvalues": "2516,2984,3011,2660,2260,2203,2247,1374,914,755,461,69,0",
                "mistakes": [
                  {
                    "sampleOffset": 2,
                    "mistakeId": 1
                  }
                ],
                "hiddenMistakes": [],
                "softVals": [
                  "1",
                  "1",
                  "0",
                  "1",
                  "1",
                  "1",
                  "1",
                  "1",
                  "1",
                  "1",
                  "1",
                  "1",
                  "1"
                ],
                "expectedSylsPerSecond": "0.00",
                "actualSylsPerSecond": "0.63",
                "timeLength": 1625,
                "features": [
                  {
                    "sampleYVal": 166,
                    "sampleYDrawVal": 2706
                  },
                  {
                    "sampleYVal": 173,
                    "sampleYDrawVal": 2943
                  },
                  {
                    "sampleYVal": 174,
                    "sampleYDrawVal": 2904
                  },
                  {
                    "sampleYVal": 156,
                    "sampleYDrawVal": 2358
                  },
                  {
                    "sampleYVal": 149,
                    "sampleYDrawVal": 2169
                  },
                  {
                    "sampleYVal": 152,
                    "sampleYDrawVal": 2220
                  },
                  {
                    "sampleYVal": 142,
                    "sampleYDrawVal": 1926
                  },
                  {
                    "sampleYVal": 95,
                    "sampleYDrawVal": 1036
                  },
                  {
                    "sampleYVal": 83,
                    "sampleYDrawVal": 776
                  },
                  {
                    "sampleYVal": 73,
                    "sampleYDrawVal": 664
                  },
                  {
                    "sampleYVal": 46,
                    "sampleYDrawVal": 185
                  },
                  {
                    "sampleYVal": 38,
                    "sampleYDrawVal": 72
                  }
                ],
                "recordOffset": 7029,
                "seqUrl": "1527724183055_20633_709",
                "seqDescription": {
                  "analysis": {
                    "analysisType": "2sec"
                  },
                  "completeCount": 1,
                  "currentSeqPos": 0,
                  "pbId": "1042",
                  "text": "נוֹ ",
                  "type": "sentence",
                  "word": {
                    "id": 794,
                    "language": "he",
                    "cleanInput": "נוֹסַעַת",
                    "type": "word",
                    "data": "[{\"text\":\"\\u05e0\\u05d5\\u05b9\",\"sylType\":\"cv\",\"vowel\":\"O\",\"isContinous\":true},{\"text\":\"\\u05e1\\u05b7\",\"sylType\":\"cv\",\"vowel\":\"A\",\"isContinous\":true},{\"text\":\"\\u05e2\\u05b7\\u05ea\",\"sylType\":\"vc\",\"vowel\":\"A\",\"isPlosive\":true}]",
                    "isNonesense": 0,
                    "audios": ""
                  }
                },
                "mistakesObjects": {
                  "gentleOnset": {
                    "titlei18Key": "MISTAKES.GENTLE_ONSET"
                  }
                },
                "hiddenMistakesObjects": {}
              },
              {
                "fieldType": "seq",
                "timerPos": 737,
                "calibrationEventId": "6581",
                "rate": 80,
                "deltaTimeMsFromPrevSeq": "1016.00",
                "yvalues": [
                  "84",
                  "154",
                  "184",
                  "198",
                  "203",
                  "193",
                  "183",
                  "175",
                  "166",
                  "154",
                  "120",
                  "100",
                  "57",
                  "0"
                ],
                "yDrawvalues": "1149,2739,3507,3953,4055,3463,3019,2752,2359,2022,1153,805,183,0",
                "mistakes": [
                  {
                    "sampleOffset": 2,
                    "mistakeId": 1
                  }
                ],
                "hiddenMistakes": [],
                "softVals": [
                  "1",
                  "1",
                  "0",
                  "0",
                  "0",
                  "0",
                  "0",
                  "0",
                  "1",
                  "1",
                  "1",
                  "1",
                  "1",
                  "1"
                ],
                "expectedSylsPerSecond": "0.00",
                "actualSylsPerSecond": "0.56",
                "timeLength": 1750,
                "features": [
                  {
                    "sampleYVal": 147,
                    "sampleYDrawVal": 1932
                  },
                  {
                    "sampleYVal": 179,
                    "sampleYDrawVal": 2934
                  },
                  {
                    "sampleYVal": 201,
                    "sampleYDrawVal": 3859
                  },
                  {
                    "sampleYVal": 206,
                    "sampleYDrawVal": 4145
                  },
                  {
                    "sampleYVal": 197,
                    "sampleYDrawVal": 3628
                  },
                  {
                    "sampleYVal": 188,
                    "sampleYDrawVal": 3355
                  },
                  {
                    "sampleYVal": 175,
                    "sampleYDrawVal": 2816
                  },
                  {
                    "sampleYVal": 173,
                    "sampleYDrawVal": 2711
                  },
                  {
                    "sampleYVal": 170,
                    "sampleYDrawVal": 2552
                  },
                  {
                    "sampleYVal": 135,
                    "sampleYDrawVal": 1604
                  },
                  {
                    "sampleYVal": 110,
                    "sampleYDrawVal": 1078
                  },
                  {
                    "sampleYVal": 79,
                    "sampleYDrawVal": 469
                  },
                  {
                    "sampleYVal": 29,
                    "sampleYDrawVal": 43
                  }
                ],
                "recordOffset": 9709,
                "seqUrl": "1527724183055_20633_709",
                "seqDescription": {
                  "analysis": {
                    "analysisType": "2sec"
                  },
                  "completeCount": 1,
                  "currentSeqPos": 1,
                  "pbId": "1042",
                  "text": "סַ ",
                  "type": "sentence",
                  "word": {
                    "id": 794,
                    "language": "he",
                    "cleanInput": "נוֹסַעַת",
                    "type": "word",
                    "data": "[{\"text\":\"\\u05e0\\u05d5\\u05b9\",\"sylType\":\"cv\",\"vowel\":\"O\",\"isContinous\":true},{\"text\":\"\\u05e1\\u05b7\",\"sylType\":\"cv\",\"vowel\":\"A\",\"isContinous\":true},{\"text\":\"\\u05e2\\u05b7\\u05ea\",\"sylType\":\"vc\",\"vowel\":\"A\",\"isPlosive\":true}]",
                    "isNonesense": 0,
                    "audios": ""
                  }
                },
                "mistakesObjects": {
                  "gentleOnset": {
                    "titlei18Key": "MISTAKES.GENTLE_ONSET"
                  }
                },
                "hiddenMistakesObjects": {}
              },
              {
                "fieldType": "seq",
                "timerPos": 740,
                "calibrationEventId": "6581",
                "rate": 80,
                "deltaTimeMsFromPrevSeq": "1092.00",
                "yvalues": [
                  "107",
                  "145",
                  "180",
                  "189",
                  "193",
                  "198",
                  "195",
                  "198",
                  "190",
                  "176",
                  "146",
                  "97",
                  "52",
                  "0"
                ],
                "yDrawvalues": "1379,2215,3390,3454,3543,3812,3650,3809,3377,2769,1741,658,97,0",
                "mistakes": [
                  {
                    "sampleOffset": 2,
                    "mistakeId": 1
                  }
                ],
                "hiddenMistakes": [],
                "softVals": [
                  "1",
                  "1",
                  "0",
                  "0",
                  "0",
                  "0",
                  "0",
                  "0",
                  "0",
                  "0",
                  "1",
                  "1",
                  "1",
                  "1"
                ],
                "expectedSylsPerSecond": "0.00",
                "actualSylsPerSecond": "1.11",
                "timeLength": 1750,
                "features": [
                  {
                    "sampleYVal": 135,
                    "sampleYDrawVal": 1689
                  },
                  {
                    "sampleYVal": 175,
                    "sampleYDrawVal": 2798
                  },
                  {
                    "sampleYVal": 202,
                    "sampleYDrawVal": 3901
                  },
                  {
                    "sampleYVal": 194,
                    "sampleYDrawVal": 3497
                  },
                  {
                    "sampleYVal": 197,
                    "sampleYDrawVal": 3664
                  },
                  {
                    "sampleYVal": 202,
                    "sampleYDrawVal": 3899
                  },
                  {
                    "sampleYVal": 189,
                    "sampleYDrawVal": 3446
                  },
                  {
                    "sampleYVal": 195,
                    "sampleYDrawVal": 3643
                  },
                  {
                    "sampleYVal": 183,
                    "sampleYDrawVal": 3133
                  },
                  {
                    "sampleYVal": 158,
                    "sampleYDrawVal": 2242
                  },
                  {
                    "sampleYVal": 132,
                    "sampleYDrawVal": 1515
                  },
                  {
                    "sampleYVal": 45,
                    "sampleYDrawVal": 130
                  },
                  {
                    "sampleYVal": 41,
                    "sampleYDrawVal": 97
                  }
                ],
                "recordOffset": 12600,
                "seqUrl": "1527724183055_20633_709",
                "seqDescription": {
                  "analysis": {
                    "analysisType": "2sec"
                  },
                  "completeCount": 1,
                  "currentSeqPos": 2,
                  "pbId": "1042",
                  "text": "עַת ",
                  "type": "sentence",
                  "word": {
                    "id": 794,
                    "language": "he",
                    "cleanInput": "נוֹסַעַת",
                    "type": "word",
                    "data": "[{\"text\":\"\\u05e0\\u05d5\\u05b9\",\"sylType\":\"cv\",\"vowel\":\"O\",\"isContinous\":true},{\"text\":\"\\u05e1\\u05b7\",\"sylType\":\"cv\",\"vowel\":\"A\",\"isContinous\":true},{\"text\":\"\\u05e2\\u05b7\\u05ea\",\"sylType\":\"vc\",\"vowel\":\"A\",\"isPlosive\":true}]",
                    "isNonesense": 0,
                    "audios": ""
                  }
                },
                "mistakesObjects": {
                  "gentleOnset": {
                    "titlei18Key": "MISTAKES.GENTLE_ONSET"
                  }
                },
                "hiddenMistakesObjects": {}
              },
              {
                "fieldType": "seq",
                "timerPos": 743,
                "calibrationEventId": "6581",
                "rate": 80,
                "deltaTimeMsFromPrevSeq": "1223.00",
                "yvalues": [
                  "88",
                  "155",
                  "165",
                  "157",
                  "162",
                  "165",
                  "185",
                  "192",
                  "194",
                  "186",
                  "173",
                  "164",
                  "144",
                  "118",
                  "63",
                  "39",
                  "0"
                ],
                "yDrawvalues": "1221,2669,2530,2104,2372,2413,3328,3515,3538,3120,2650,2393,1733,1076,186,71,0",
                "mistakes": [
                  {
                    "sampleOffset": 2,
                    "mistakeId": 5
                  }
                ],
                "hiddenMistakes": [
                  {
                    "sampleOffset": 16,
                    "mistakeId": 7
                  }
                ],
                "softVals": [
                  "1",
                  "1",
                  "1",
                  "1",
                  "1",
                  "1",
                  "0",
                  "0",
                  "0",
                  "0",
                  "0",
                  "1",
                  "1",
                  "1",
                  "1",
                  "1",
                  "1"
                ],
                "expectedSylsPerSecond": "0.00",
                "actualSylsPerSecond": "0.92",
                "timeLength": 2125,
                "features": [
                  {
                    "sampleYVal": 156,
                    "sampleYDrawVal": 2155
                  },
                  {
                    "sampleYVal": 178,
                    "sampleYDrawVal": 2853
                  },
                  {
                    "sampleYVal": 160,
                    "sampleYDrawVal": 2241
                  },
                  {
                    "sampleYVal": 162,
                    "sampleYDrawVal": 2384
                  },
                  {
                    "sampleYVal": 161,
                    "sampleYDrawVal": 2292
                  },
                  {
                    "sampleYVal": 176,
                    "sampleYDrawVal": 2745
                  },
                  {
                    "sampleYVal": 196,
                    "sampleYDrawVal": 3558
                  },
                  {
                    "sampleYVal": 196,
                    "sampleYDrawVal": 3572
                  },
                  {
                    "sampleYVal": 193,
                    "sampleYDrawVal": 3465
                  },
                  {
                    "sampleYVal": 174,
                    "sampleYDrawVal": 2734
                  },
                  {
                    "sampleYVal": 172,
                    "sampleYDrawVal": 2693
                  },
                  {
                    "sampleYVal": 156,
                    "sampleYDrawVal": 2135
                  },
                  {
                    "sampleYVal": 129,
                    "sampleYDrawVal": 1464
                  },
                  {
                    "sampleYVal": 89,
                    "sampleYDrawVal": 601
                  },
                  {
                    "sampleYVal": 44,
                    "sampleYDrawVal": 154
                  },
                  {
                    "sampleYVal": 33,
                    "sampleYDrawVal": 61
                  }
                ],
                "recordOffset": 15627,
                "seqUrl": "1527724183055_20633_709",
                "seqDescription": {
                  "analysis": {
                    "analysisType": "2sec"
                  },
                  "completeCount": 2,
                  "currentSeqPos": 0,
                  "pbId": "1042",
                  "text": "מַ ",
                  "type": "sentence",
                  "word": {
                    "id": 1039,
                    "language": "he",
                    "cleanInput": "מַהֵר",
                    "type": "word",
                    "data": "[{\"text\":\"\\u05de\\u05b7\",\"sylType\":\"cv\",\"vowel\":\"A\",\"isContinous\":true},{\"text\":\"\\u05d4\\u05b5\\u05e8\",\"sylType\":\"vc\",\"vowel\":\"E\",\"isContinous\":true}]",
                    "isNonesense": 0,
                    "audios": ""
                  }
                },
                "mistakesObjects": {
                  "volControlUp": {
                    "titlei18Key": "MISTAKES.VOLCONTROL_UP"
                  }
                },
                "hiddenMistakesObjects": {
                  "tooLong": {
                    "titlei18Key": "MISTAKES.TOO_LONG"
                  }
                }
              },
              {
                "fieldType": "seq",
                "timerPos": 746,
                "calibrationEventId": "6581",
                "rate": 80,
                "deltaTimeMsFromPrevSeq": "1280.00",
                "yvalues": [
                  "100",
                  "143",
                  "174",
                  "186",
                  "182",
                  "175",
                  "166",
                  "160",
                  "147",
                  "137",
                  "122",
                  "90",
                  "62",
                  "0"
                ],
                "yDrawvalues": "1234,2138,3035,3304,3032,2707,2359,2180,1769,1493,1143,514,226,0",
                "mistakes": [
                  {
                    "sampleOffset": 2,
                    "mistakeId": 1
                  }
                ],
                "hiddenMistakes": [],
                "softVals": [
                  "1",
                  "1",
                  "0",
                  "0",
                  "0",
                  "0",
                  "1",
                  "1",
                  "1",
                  "1",
                  "1",
                  "1",
                  "1",
                  "1"
                ],
                "expectedSylsPerSecond": "0.00",
                "actualSylsPerSecond": "0.56",
                "timeLength": 1750,
                "features": [
                  {
                    "sampleYVal": 131,
                    "sampleYDrawVal": 1569
                  },
                  {
                    "sampleYVal": 171,
                    "sampleYDrawVal": 2609
                  },
                  {
                    "sampleYVal": 189,
                    "sampleYDrawVal": 3313
                  },
                  {
                    "sampleYVal": 189,
                    "sampleYDrawVal": 3298
                  },
                  {
                    "sampleYVal": 175,
                    "sampleYDrawVal": 2778
                  },
                  {
                    "sampleYVal": 168,
                    "sampleYDrawVal": 2502
                  },
                  {
                    "sampleYVal": 153,
                    "sampleYDrawVal": 2022
                  },
                  {
                    "sampleYVal": 143,
                    "sampleYDrawVal": 1773
                  },
                  {
                    "sampleYVal": 132,
                    "sampleYDrawVal": 1421
                  },
                  {
                    "sampleYVal": 133,
                    "sampleYDrawVal": 1452
                  },
                  {
                    "sampleYVal": 99,
                    "sampleYDrawVal": 749
                  },
                  {
                    "sampleYVal": 79,
                    "sampleYDrawVal": 469
                  },
                  {
                    "sampleYVal": 46,
                    "sampleYDrawVal": 132
                  }
                ],
                "recordOffset": 19069,
                "seqUrl": "1527724183055_20633_709",
                "seqDescription": {
                  "analysis": {
                    "analysisType": "2sec"
                  },
                  "completeCount": 2,
                  "currentSeqPos": 1,
                  "pbId": "1042",
                  "text": "הֵר ",
                  "type": "sentence",
                  "word": {
                    "id": 1039,
                    "language": "he",
                    "cleanInput": "מַהֵר",
                    "type": "word",
                    "data": "[{\"text\":\"\\u05de\\u05b7\",\"sylType\":\"cv\",\"vowel\":\"A\",\"isContinous\":true},{\"text\":\"\\u05d4\\u05b5\\u05e8\",\"sylType\":\"vc\",\"vowel\":\"E\",\"isContinous\":true}]",
                    "isNonesense": 0,
                    "audios": ""
                  }
                },
                "mistakesObjects": {
                  "gentleOnset": {
                    "titlei18Key": "MISTAKES.GENTLE_ONSET"
                  }
                },
                "hiddenMistakesObjects": {}
              },
              {
                "fieldType": "seq",
                "timerPos": 750,
                "calibrationEventId": "6581",
                "rate": 100,
                "deltaTimeMsFromPrevSeq": "1470.00",
                "yvalues": [
                  "110",
                  "133",
                  "143",
                  "166",
                  "184",
                  "176",
                  "175",
                  "174",
                  "169",
                  "156",
                  "149",
                  "121",
                  "99",
                  "71",
                  "46",
                  "0"
                ],
                "yDrawvalues": "1712,1652,1884,2691,3364,2831,2838,2783,2554,2068,1894,1127,723,286,102,0",
                "mistakes": [],
                "hiddenMistakes": [],
                "softVals": [
                  "1",
                  "1",
                  "1",
                  "1",
                  "0",
                  "0",
                  "0",
                  "0",
                  "1",
                  "1",
                  "1",
                  "1",
                  "1",
                  "1",
                  "1",
                  "1"
                ],
                "expectedSylsPerSecond": "0.00",
                "actualSylsPerSecond": "0.49",
                "timeLength": 2000,
                "features": [
                  {
                    "sampleYVal": 151,
                    "sampleYDrawVal": 1982
                  },
                  {
                    "sampleYVal": 136,
                    "sampleYDrawVal": 1584
                  },
                  {
                    "sampleYVal": 161,
                    "sampleYDrawVal": 2269
                  },
                  {
                    "sampleYVal": 182,
                    "sampleYDrawVal": 3050
                  },
                  {
                    "sampleYVal": 187,
                    "sampleYDrawVal": 3254
                  },
                  {
                    "sampleYVal": 168,
                    "sampleYDrawVal": 2604
                  },
                  {
                    "sampleYVal": 174,
                    "sampleYDrawVal": 2797
                  },
                  {
                    "sampleYVal": 169,
                    "sampleYDrawVal": 2562
                  },
                  {
                    "sampleYVal": 168,
                    "sampleYDrawVal": 2541
                  },
                  {
                    "sampleYVal": 145,
                    "sampleYDrawVal": 1790
                  },
                  {
                    "sampleYVal": 142,
                    "sampleYDrawVal": 1746
                  },
                  {
                    "sampleYVal": 113,
                    "sampleYDrawVal": 1046
                  },
                  {
                    "sampleYVal": 84,
                    "sampleYDrawVal": 539
                  },
                  {
                    "sampleYVal": 59,
                    "sampleYDrawVal": 259
                  },
                  {
                    "sampleYVal": 31,
                    "sampleYDrawVal": 53
                  }
                ],
                "recordOffset": 22339,
                "seqUrl": "1527724183055_20633_709",
                "seqDescription": {
                  "analysis": {
                    "analysisType": "2sec"
                  },
                  "completeCount": 3,
                  "currentSeqPos": 0,
                  "pbId": "1042",
                  "text": "בָּרְ ",
                  "type": "sentence",
                  "word": {
                    "id": 1040,
                    "language": "he",
                    "cleanInput": "בָּרְחוֹב",
                    "type": "word",
                    "data": "[{\"text\":\"\\u05d1\\u05bc\\u05b8\\u05e8\\u05b0\",\"sylType\":\"cvc\",\"vowel\":\"A\",\"isContinous\":true,\"isPlosive\":true},{\"text\":\"\\u05d7\\u05d5\\u05b9\\u05d1\",\"sylType\":\"cvc\",\"vowel\":\"O\",\"isContinous\":true}]",
                    "isNonesense": 0,
                    "audios": ""
                  }
                },
                "mistakesObjects": {},
                "hiddenMistakesObjects": {}
              },
              {
                "fieldType": "seq",
                "timerPos": 753,
                "calibrationEventId": "6581",
                "rate": 85,
                "deltaTimeMsFromPrevSeq": "1410.00",
                "yvalues": [
                  "68",
                  "109",
                  "133",
                  "145",
                  "166",
                  "167",
                  "162",
                  "158",
                  "159",
                  "170",
                  "174",
                  "161",
                  "134",
                  "88",
                  "43",
                  "0"
                ],
                "yDrawvalues": "388,1287,1746,2023,2699,2516,2318,2259,2273,2731,2804,2246,1472,643,69,0",
                "mistakes": [
                  {
                    "sampleOffset": 10,
                    "mistakeId": 3
                  }
                ],
                "hiddenMistakes": [],
                "softVals": [
                  "1",
                  "1",
                  "1",
                  "1",
                  "1",
                  "1",
                  "1",
                  "1",
                  "1",
                  "1",
                  "0",
                  "1",
                  "1",
                  "1",
                  "1",
                  "1"
                ],
                "expectedSylsPerSecond": "0.00",
                "actualSylsPerSecond": "0.98",
                "timeLength": 2000,
                "features": [
                  {
                    "sampleYVal": 60,
                    "sampleYDrawVal": 240
                  },
                  {
                    "sampleYVal": 136,
                    "sampleYDrawVal": 1768
                  },
                  {
                    "sampleYVal": 145,
                    "sampleYDrawVal": 1842
                  },
                  {
                    "sampleYVal": 158,
                    "sampleYDrawVal": 2243
                  },
                  {
                    "sampleYVal": 174,
                    "sampleYDrawVal": 2729
                  },
                  {
                    "sampleYVal": 164,
                    "sampleYDrawVal": 2423
                  },
                  {
                    "sampleYVal": 155,
                    "sampleYDrawVal": 2147
                  },
                  {
                    "sampleYVal": 161,
                    "sampleYDrawVal": 2352
                  },
                  {
                    "sampleYVal": 160,
                    "sampleYDrawVal": 2289
                  },
                  {
                    "sampleYVal": 181,
                    "sampleYDrawVal": 3043
                  },
                  {
                    "sampleYVal": 171,
                    "sampleYDrawVal": 2635
                  },
                  {
                    "sampleYVal": 145,
                    "sampleYDrawVal": 1879
                  },
                  {
                    "sampleYVal": 113,
                    "sampleYDrawVal": 1145
                  },
                  {
                    "sampleYVal": 60,
                    "sampleYDrawVal": 351
                  },
                  {
                    "sampleYVal": 23,
                    "sampleYDrawVal": 29
                  }
                ],
                "recordOffset": 25789,
                "seqUrl": "1527724183055_20633_709",
                "seqDescription": {
                  "analysis": {
                    "analysisType": "2sec"
                  },
                  "completeCount": 3,
                  "currentSeqPos": 1,
                  "pbId": "1042",
                  "text": "חוֹב ",
                  "type": "sentence",
                  "word": {
                    "id": 1040,
                    "language": "he",
                    "cleanInput": "בָּרְחוֹב",
                    "type": "word",
                    "data": "[{\"text\":\"\\u05d1\\u05bc\\u05b8\\u05e8\\u05b0\",\"sylType\":\"cvc\",\"vowel\":\"A\",\"isContinous\":true,\"isPlosive\":true},{\"text\":\"\\u05d7\\u05d5\\u05b9\\u05d1\",\"sylType\":\"cvc\",\"vowel\":\"O\",\"isContinous\":true}]",
                    "isNonesense": 0,
                    "audios": ""
                  }
                },
                "mistakesObjects": {
                  "softPeak": {
                    "titlei18Key": "MISTAKES.SOFT_PEAK"
                  }
                },
                "hiddenMistakesObjects": {}
              },
              {
                "fieldType": "seq",
                "timerPos": 756,
                "calibrationEventId": "6581",
                "rate": 100,
                "deltaTimeMsFromPrevSeq": "1219.00",
                "yvalues": [
                  "75",
                  "75",
                  "132",
                  "153",
                  "167",
                  "185",
                  "200",
                  "206",
                  "202",
                  "188",
                  "176",
                  "152",
                  "94",
                  "66",
                  "47",
                  "0"
                ],
                "yDrawvalues": "553,454,1941,2451,2726,3434,4003,4119,3842,3215,2776,1841,508,260,159,0",
                "mistakes": [],
                "hiddenMistakes": [],
                "softVals": [
                  "1",
                  "1",
                  "1",
                  "1",
                  "1",
                  "0",
                  "0",
                  "0",
                  "0",
                  "0",
                  "0",
                  "1",
                  "1",
                  "1",
                  "1",
                  "1"
                ],
                "expectedSylsPerSecond": "0.00",
                "actualSylsPerSecond": "0.49",
                "timeLength": 2000,
                "features": [
                  {
                    "sampleYVal": 70,
                    "sampleYDrawVal": 305
                  },
                  {
                    "sampleYVal": 107,
                    "sampleYDrawVal": 899
                  },
                  {
                    "sampleYVal": 156,
                    "sampleYDrawVal": 2293
                  },
                  {
                    "sampleYVal": 165,
                    "sampleYDrawVal": 2619
                  },
                  {
                    "sampleYVal": 178,
                    "sampleYDrawVal": 2930
                  },
                  {
                    "sampleYVal": 202,
                    "sampleYDrawVal": 3872
                  },
                  {
                    "sampleYVal": 211,
                    "sampleYDrawVal": 4330
                  },
                  {
                    "sampleYVal": 209,
                    "sampleYDrawVal": 4177
                  },
                  {
                    "sampleYVal": 190,
                    "sampleYDrawVal": 3371
                  },
                  {
                    "sampleYVal": 181,
                    "sampleYDrawVal": 3062
                  },
                  {
                    "sampleYVal": 166,
                    "sampleYDrawVal": 2497
                  },
                  {
                    "sampleYVal": 136,
                    "sampleYDrawVal": 1518
                  },
                  {
                    "sampleYVal": 71,
                    "sampleYDrawVal": 351
                  },
                  {
                    "sampleYVal": 57,
                    "sampleYDrawVal": 252
                  },
                  {
                    "sampleYVal": 43,
                    "sampleYDrawVal": 131
                  }
                ],
                "recordOffset": -943,
                "seqUrl": "1527724213056_20633_709",
                "seqDescription": {
                  "analysis": {
                    "analysisType": "2sec"
                  },
                  "completeCount": 4,
                  "currentSeqPos": 0,
                  "pbId": "1042",
                  "text": "הַ ",
                  "type": "sentence",
                  "word": {
                    "id": 1041,
                    "language": "he",
                    "cleanInput": "הַסּוֹאֵן",
                    "type": "word",
                    "data": "[{\"text\":\"\\u05d4\\u05b7\",\"sylType\":\"v\",\"vowel\":\"A\"},{\"text\":\"\\u05e1\\u05bc\\u05d5\\u05b9\",\"sylType\":\"cv\",\"vowel\":\"O\",\"isContinous\":true},{\"text\":\"\\u05d0\\u05b5\\u05df\",\"sylType\":\"vc\",\"vowel\":\"E\",\"isContinous\":true}]",
                    "isNonesense": 0,
                    "audios": ""
                  }
                },
                "mistakesObjects": {},
                "hiddenMistakesObjects": {}
              },
              {
                "fieldType": "seq",
                "timerPos": 760,
                "calibrationEventId": "6581",
                "rate": 100,
                "deltaTimeMsFromPrevSeq": "1541.00",
                "yvalues": [
                  "67",
                  "142",
                  "169",
                  "181",
                  "185",
                  "196",
                  "198",
                  "197",
                  "185",
                  "156",
                  "107",
                  "74",
                  "62",
                  "77",
                  "55",
                  "0"
                ],
                "yDrawvalues": "753,2565,3077,3341,3397,3870,3824,3727,3093,2009,881,383,275,543,167,0",
                "mistakes": [],
                "hiddenMistakes": [],
                "softVals": [
                  "1",
                  "1",
                  "1",
                  "0",
                  "0",
                  "0",
                  "0",
                  "0",
                  "0",
                  "1",
                  "1",
                  "1",
                  "1",
                  "1",
                  "1",
                  "1"
                ],
                "expectedSylsPerSecond": "0.00",
                "actualSylsPerSecond": "0.49",
                "timeLength": 2000,
                "features": [
                  {
                    "sampleYVal": 152,
                    "sampleYDrawVal": 2094
                  },
                  {
                    "sampleYVal": 164,
                    "sampleYDrawVal": 2623
                  },
                  {
                    "sampleYVal": 183,
                    "sampleYDrawVal": 3284
                  },
                  {
                    "sampleYVal": 185,
                    "sampleYDrawVal": 3319
                  },
                  {
                    "sampleYVal": 197,
                    "sampleYDrawVal": 3772
                  },
                  {
                    "sampleYVal": 202,
                    "sampleYDrawVal": 3977
                  },
                  {
                    "sampleYVal": 200,
                    "sampleYDrawVal": 3813
                  },
                  {
                    "sampleYVal": 193,
                    "sampleYDrawVal": 3560
                  },
                  {
                    "sampleYVal": 168,
                    "sampleYDrawVal": 2580
                  },
                  {
                    "sampleYVal": 131,
                    "sampleYDrawVal": 1499
                  },
                  {
                    "sampleYVal": 80,
                    "sampleYDrawVal": 573
                  },
                  {
                    "sampleYVal": 59,
                    "sampleYDrawVal": 265
                  },
                  {
                    "sampleYVal": 56,
                    "sampleYDrawVal": 228
                  },
                  {
                    "sampleYVal": 88,
                    "sampleYDrawVal": 605
                  },
                  {
                    "sampleYVal": 44,
                    "sampleYDrawVal": 132
                  }
                ],
                "recordOffset": 2643,
                "seqUrl": "1527724213056_20633_709",
                "seqDescription": {
                  "analysis": {
                    "analysisType": "2sec"
                  },
                  "completeCount": 4,
                  "currentSeqPos": 1,
                  "pbId": "1042",
                  "text": "סּוֹ ",
                  "type": "sentence",
                  "word": {
                    "id": 1041,
                    "language": "he",
                    "cleanInput": "הַסּוֹאֵן",
                    "type": "word",
                    "data": "[{\"text\":\"\\u05d4\\u05b7\",\"sylType\":\"v\",\"vowel\":\"A\"},{\"text\":\"\\u05e1\\u05bc\\u05d5\\u05b9\",\"sylType\":\"cv\",\"vowel\":\"O\",\"isContinous\":true},{\"text\":\"\\u05d0\\u05b5\\u05df\",\"sylType\":\"vc\",\"vowel\":\"E\",\"isContinous\":true}]",
                    "isNonesense": 0,
                    "audios": ""
                  }
                },
                "mistakesObjects": {},
                "hiddenMistakesObjects": {}
              },
              {
                "fieldType": "seq",
                "timerPos": 763,
                "calibrationEventId": "6581",
                "rate": 100,
                "deltaTimeMsFromPrevSeq": "1345.00",
                "yvalues": [
                  "107",
                  "144",
                  "170",
                  "188",
                  "197",
                  "196",
                  "192",
                  "182",
                  "169",
                  "151",
                  "143",
                  "125",
                  "97",
                  "63",
                  "40",
                  "0"
                ],
                "yDrawvalues": "1305,2142,2961,3582,3799,3718,3491,2966,2451,1797,1692,1169,609,198,66,0",
                "mistakes": [],
                "hiddenMistakes": [],
                "softVals": [
                  "1",
                  "1",
                  "1",
                  "0",
                  "0",
                  "0",
                  "0",
                  "0",
                  "1",
                  "1",
                  "1",
                  "1",
                  "1",
                  "1",
                  "1",
                  "1"
                ],
                "expectedSylsPerSecond": "0.00",
                "actualSylsPerSecond": "0.49",
                "timeLength": 2000,
                "features": [
                  {
                    "sampleYVal": 139,
                    "sampleYDrawVal": 1715
                  },
                  {
                    "sampleYVal": 170,
                    "sampleYDrawVal": 2667
                  },
                  {
                    "sampleYVal": 184,
                    "sampleYDrawVal": 3218
                  },
                  {
                    "sampleYVal": 200,
                    "sampleYDrawVal": 3795
                  },
                  {
                    "sampleYVal": 202,
                    "sampleYDrawVal": 3939
                  },
                  {
                    "sampleYVal": 192,
                    "sampleYDrawVal": 3499
                  },
                  {
                    "sampleYVal": 184,
                    "sampleYDrawVal": 3098
                  },
                  {
                    "sampleYVal": 177,
                    "sampleYDrawVal": 2835
                  },
                  {
                    "sampleYVal": 155,
                    "sampleYDrawVal": 2064
                  },
                  {
                    "sampleYVal": 139,
                    "sampleYDrawVal": 1563
                  },
                  {
                    "sampleYVal": 138,
                    "sampleYDrawVal": 1567
                  },
                  {
                    "sampleYVal": 99,
                    "sampleYDrawVal": 710
                  },
                  {
                    "sampleYVal": 66,
                    "sampleYDrawVal": 282
                  },
                  {
                    "sampleYVal": 46,
                    "sampleYDrawVal": 134
                  },
                  {
                    "sampleYVal": 24,
                    "sampleYDrawVal": 34
                  }
                ],
                "recordOffset": 6048,
                "seqUrl": "1527724213056_20633_709",
                "seqDescription": {
                  "analysis": {
                    "analysisType": "2sec"
                  },
                  "completeCount": 4,
                  "currentSeqPos": 2,
                  "pbId": "1042",
                  "text": "אֵן ",
                  "type": "sentence",
                  "word": {
                    "id": 1041,
                    "language": "he",
                    "cleanInput": "הַסּוֹאֵן",
                    "type": "word",
                    "data": "[{\"text\":\"\\u05d4\\u05b7\",\"sylType\":\"v\",\"vowel\":\"A\"},{\"text\":\"\\u05e1\\u05bc\\u05d5\\u05b9\",\"sylType\":\"cv\",\"vowel\":\"O\",\"isContinous\":true},{\"text\":\"\\u05d0\\u05b5\\u05df\",\"sylType\":\"vc\",\"vowel\":\"E\",\"isContinous\":true}]",
                    "isNonesense": 0,
                    "audios": ""
                  }
                },
                "mistakesObjects": {},
                "hiddenMistakesObjects": {}
              },
              {
                "fieldType": "seq",
                "timerPos": 767,
                "calibrationEventId": "6581",
                "rate": 95,
                "deltaTimeMsFromPrevSeq": "1211.00",
                "yvalues": [
                  "65",
                  "81",
                  "110",
                  "168",
                  "181",
                  "177",
                  "180",
                  "183",
                  "184",
                  "189",
                  "171",
                  "134",
                  "107",
                  "80",
                  "41",
                  "0"
                ],
                "yDrawvalues": "504,534,1355,3197,3162,2870,3059,3131,3219,3473,2636,1435,939,502,58,0",
                "mistakes": [
                  {
                    "sampleOffset": 8,
                    "mistakeId": 17
                  }
                ],
                "hiddenMistakes": [],
                "softVals": [
                  "1",
                  "1",
                  "1",
                  "1",
                  "0",
                  "0",
                  "0",
                  "0",
                  "0",
                  "0",
                  "0",
                  "1",
                  "1",
                  "1",
                  "1",
                  "1"
                ],
                "expectedSylsPerSecond": "0.00",
                "actualSylsPerSecond": "0.49",
                "timeLength": 2000,
                "features": [
                  {
                    "sampleYVal": 111,
                    "sampleYDrawVal": 912
                  },
                  {
                    "sampleYVal": 78,
                    "sampleYDrawVal": 447
                  },
                  {
                    "sampleYVal": 160,
                    "sampleYDrawVal": 2406
                  },
                  {
                    "sampleYVal": 191,
                    "sampleYDrawVal": 3460
                  },
                  {
                    "sampleYVal": 187,
                    "sampleYDrawVal": 3227
                  },
                  {
                    "sampleYVal": 174,
                    "sampleYDrawVal": 2767
                  },
                  {
                    "sampleYVal": 191,
                    "sampleYDrawVal": 3425
                  },
                  {
                    "sampleYVal": 177,
                    "sampleYDrawVal": 2883
                  },
                  {
                    "sampleYVal": 190,
                    "sampleYDrawVal": 3414
                  },
                  {
                    "sampleYVal": 186,
                    "sampleYDrawVal": 3291
                  },
                  {
                    "sampleYVal": 145,
                    "sampleYDrawVal": 1870
                  },
                  {
                    "sampleYVal": 109,
                    "sampleYDrawVal": 1044
                  },
                  {
                    "sampleYVal": 94,
                    "sampleYDrawVal": 824
                  },
                  {
                    "sampleYVal": 52,
                    "sampleYDrawVal": 173
                  },
                  {
                    "sampleYVal": 20,
                    "sampleYDrawVal": 20
                  }
                ],
                "recordOffset": 9314,
                "seqUrl": "1527724213056_20633_709",
                "seqDescription": {
                  "analysis": {
                    "analysisType": "2sec"
                  },
                  "completeCount": 0,
                  "currentSeqPos": 0,
                  "pbId": "25",
                  "text": "אַ ",
                  "type": "sentence",
                  "word": {
                    "id": 796,
                    "language": "he",
                    "cleanInput": "אַבָּא",
                    "type": "word",
                    "data": "[{\"text\":\"\\u05d0\\u05b7\",\"sylType\":\"v\",\"vowel\":\"A\"},{\"text\":\"\\u05d1\\u05bc\\u05b8\\u05d0\",\"sylType\":\"cv\",\"vowel\":\"A\",\"isPlosive\":true}]",
                    "isNonesense": 0,
                    "audios": ""
                  }
                },
                "mistakesObjects": {
                  "intensePeak": {
                    "titlei18Key": "MISTAKES.INTENSE_PEAK"
                  }
                },
                "hiddenMistakesObjects": {}
              },
              {
                "fieldType": "seq",
                "timerPos": 770,
                "calibrationEventId": "6581",
                "rate": 80,
                "deltaTimeMsFromPrevSeq": "1406.00",
                "yvalues": [
                  "131",
                  "167",
                  "181",
                  "192",
                  "199",
                  "193",
                  "183",
                  "163",
                  "144",
                  "140",
                  "99",
                  "43",
                  "0"
                ],
                "yDrawvalues": "2373,2892,3264,3677,3906,3534,3079,2255,1692,1683,757,46,0",
                "mistakes": [
                  {
                    "sampleOffset": 2,
                    "mistakeId": 1
                  }
                ],
                "hiddenMistakes": [],
                "softVals": [
                  "1",
                  "1",
                  "0",
                  "0",
                  "0",
                  "0",
                  "0",
                  "1",
                  "1",
                  "1",
                  "1",
                  "1",
                  "1"
                ],
                "expectedSylsPerSecond": "0.00",
                "actualSylsPerSecond": "0.61",
                "timeLength": 1625,
                "features": [
                  {
                    "sampleYVal": 171,
                    "sampleYDrawVal": 2698
                  },
                  {
                    "sampleYVal": 187,
                    "sampleYDrawVal": 3301
                  },
                  {
                    "sampleYVal": 185,
                    "sampleYDrawVal": 3265
                  },
                  {
                    "sampleYVal": 201,
                    "sampleYDrawVal": 3903
                  },
                  {
                    "sampleYVal": 199,
                    "sampleYDrawVal": 3829
                  },
                  {
                    "sampleYVal": 193,
                    "sampleYDrawVal": 3561
                  },
                  {
                    "sampleYVal": 177,
                    "sampleYDrawVal": 2893
                  },
                  {
                    "sampleYVal": 145,
                    "sampleYDrawVal": 1860
                  },
                  {
                    "sampleYVal": 139,
                    "sampleYDrawVal": 1619
                  },
                  {
                    "sampleYVal": 138,
                    "sampleYDrawVal": 1608
                  },
                  {
                    "sampleYVal": 35,
                    "sampleYDrawVal": 61
                  },
                  {
                    "sampleYVal": 29,
                    "sampleYDrawVal": 39
                  }
                ],
                "recordOffset": 12733,
                "seqUrl": "1527724213056_20633_709",
                "seqDescription": {
                  "analysis": {
                    "analysisType": "2sec"
                  },
                  "completeCount": 0,
                  "currentSeqPos": 1,
                  "pbId": "25",
                  "text": "בָּא ",
                  "type": "sentence",
                  "word": {
                    "id": 796,
                    "language": "he",
                    "cleanInput": "אַבָּא",
                    "type": "word",
                    "data": "[{\"text\":\"\\u05d0\\u05b7\",\"sylType\":\"v\",\"vowel\":\"A\"},{\"text\":\"\\u05d1\\u05bc\\u05b8\\u05d0\",\"sylType\":\"cv\",\"vowel\":\"A\",\"isPlosive\":true}]",
                    "isNonesense": 0,
                    "audios": ""
                  }
                },
                "mistakesObjects": {
                  "gentleOnset": {
                    "titlei18Key": "MISTAKES.GENTLE_ONSET"
                  }
                },
                "hiddenMistakesObjects": {}
              },
              {
                "fieldType": "seq",
                "timerPos": 773,
                "calibrationEventId": "6581",
                "rate": 100,
                "deltaTimeMsFromPrevSeq": "1210.00",
                "yvalues": [
                  "83",
                  "134",
                  "155",
                  "168",
                  "181",
                  "190",
                  "183",
                  "172",
                  "164",
                  "152",
                  "137",
                  "101",
                  "75",
                  "36",
                  "0"
                ],
                "yDrawvalues": "787,2086,2503,2768,3225,3508,3102,2652,2430,2076,1538,781,443,43,0",
                "mistakes": [],
                "hiddenMistakes": [],
                "softVals": [
                  "1",
                  "1",
                  "1",
                  "1",
                  "0",
                  "0",
                  "0",
                  "0",
                  "1",
                  "1",
                  "1",
                  "1",
                  "1",
                  "1",
                  "1"
                ],
                "expectedSylsPerSecond": "0.00",
                "actualSylsPerSecond": "0.52",
                "timeLength": 1875,
                "features": [
                  {
                    "sampleYVal": 127,
                    "sampleYDrawVal": 1519
                  },
                  {
                    "sampleYVal": 158,
                    "sampleYDrawVal": 2397
                  },
                  {
                    "sampleYVal": 160,
                    "sampleYDrawVal": 2470
                  },
                  {
                    "sampleYVal": 180,
                    "sampleYDrawVal": 3014
                  },
                  {
                    "sampleYVal": 191,
                    "sampleYDrawVal": 3474
                  },
                  {
                    "sampleYVal": 193,
                    "sampleYDrawVal": 3513
                  },
                  {
                    "sampleYVal": 176,
                    "sampleYDrawVal": 2888
                  },
                  {
                    "sampleYVal": 165,
                    "sampleYDrawVal": 2515
                  },
                  {
                    "sampleYVal": 163,
                    "sampleYDrawVal": 2472
                  },
                  {
                    "sampleYVal": 146,
                    "sampleYDrawVal": 1907
                  },
                  {
                    "sampleYVal": 134,
                    "sampleYDrawVal": 1495
                  },
                  {
                    "sampleYVal": 66,
                    "sampleYDrawVal": 398
                  },
                  {
                    "sampleYVal": 47,
                    "sampleYDrawVal": 169
                  },
                  {
                    "sampleYVal": 21,
                    "sampleYDrawVal": 24
                  }
                ],
                "recordOffset": 15613,
                "seqUrl": "1527724213056_20633_709",
                "seqDescription": {
                  "analysis": {
                    "analysisType": "2sec"
                  },
                  "completeCount": 1,
                  "currentSeqPos": 0,
                  "pbId": "25",
                  "text": "קָ ",
                  "type": "sentence",
                  "word": {
                    "id": 797,
                    "language": "he",
                    "cleanInput": "קָנָה",
                    "type": "word",
                    "data": "[{\"text\":\"\\u05e7\\u05b8\",\"sylType\":\"cv\",\"vowel\":\"A\",\"isPlosive\":true},{\"text\":\"\\u05e0\\u05b8\\u05d4\",\"sylType\":\"cv\",\"vowel\":\"A\",\"isContinous\":true}]",
                    "isNonesense": 0,
                    "audios": ""
                  }
                },
                "mistakesObjects": {},
                "hiddenMistakesObjects": {}
              },
              {
                "fieldType": "seq",
                "timerPos": 776,
                "calibrationEventId": "6581",
                "rate": 80,
                "deltaTimeMsFromPrevSeq": "1219.00",
                "yvalues": [
                  "71",
                  "135",
                  "179",
                  "189",
                  "190",
                  "192",
                  "192",
                  "186",
                  "169",
                  "146",
                  "132",
                  "83",
                  "41",
                  "0"
                ],
                "yDrawvalues": "681,2048,3465,3425,3412,3462,3456,3156,2454,1713,1388,392,57,0",
                "mistakes": [
                  {
                    "sampleOffset": 2,
                    "mistakeId": 1
                  }
                ],
                "hiddenMistakes": [],
                "softVals": [
                  "1",
                  "1",
                  "0",
                  "0",
                  "0",
                  "0",
                  "0",
                  "0",
                  "1",
                  "1",
                  "1",
                  "1",
                  "1",
                  "1"
                ],
                "expectedSylsPerSecond": "0.00",
                "actualSylsPerSecond": "0.56",
                "timeLength": 1750,
                "features": [
                  {
                    "sampleYVal": 130,
                    "sampleYDrawVal": 1365
                  },
                  {
                    "sampleYVal": 166,
                    "sampleYDrawVal": 2549
                  },
                  {
                    "sampleYVal": 199,
                    "sampleYDrawVal": 3760
                  },
                  {
                    "sampleYVal": 192,
                    "sampleYDrawVal": 3433
                  },
                  {
                    "sampleYVal": 189,
                    "sampleYDrawVal": 3309
                  },
                  {
                    "sampleYVal": 194,
                    "sampleYDrawVal": 3545
                  },
                  {
                    "sampleYVal": 194,
                    "sampleYDrawVal": 3534
                  },
                  {
                    "sampleYVal": 180,
                    "sampleYDrawVal": 2986
                  },
                  {
                    "sampleYVal": 153,
                    "sampleYDrawVal": 2059
                  },
                  {
                    "sampleYVal": 140,
                    "sampleYDrawVal": 1647
                  },
                  {
                    "sampleYVal": 118,
                    "sampleYDrawVal": 1091
                  },
                  {
                    "sampleYVal": 51,
                    "sampleYDrawVal": 167
                  },
                  {
                    "sampleYVal": 27,
                    "sampleYDrawVal": 33
                  }
                ],
                "recordOffset": 18747,
                "seqUrl": "1527724213056_20633_709",
                "seqDescription": {
                  "analysis": {
                    "analysisType": "2sec"
                  },
                  "completeCount": 1,
                  "currentSeqPos": 1,
                  "pbId": "25",
                  "text": "נָה ",
                  "type": "sentence",
                  "word": {
                    "id": 797,
                    "language": "he",
                    "cleanInput": "קָנָה",
                    "type": "word",
                    "data": "[{\"text\":\"\\u05e7\\u05b8\",\"sylType\":\"cv\",\"vowel\":\"A\",\"isPlosive\":true},{\"text\":\"\\u05e0\\u05b8\\u05d4\",\"sylType\":\"cv\",\"vowel\":\"A\",\"isContinous\":true}]",
                    "isNonesense": 0,
                    "audios": ""
                  }
                },
                "mistakesObjects": {
                  "gentleOnset": {
                    "titlei18Key": "MISTAKES.GENTLE_ONSET"
                  }
                },
                "hiddenMistakesObjects": {}
              },
              {
                "fieldType": "seq",
                "timerPos": 779,
                "calibrationEventId": "6581",
                "rate": 80,
                "deltaTimeMsFromPrevSeq": "1410.00",
                "yvalues": [
                  "119",
                  "159",
                  "173",
                  "173",
                  "182",
                  "184",
                  "183",
                  "178",
                  "163",
                  "146",
                  "121",
                  "80",
                  "49",
                  "0"
                ],
                "yDrawvalues": "1662,2627,2908,2769,3225,3221,3086,2880,2295,1759,1127,360,100,0",
                "mistakes": [
                  {
                    "sampleOffset": 2,
                    "mistakeId": 1
                  }
                ],
                "hiddenMistakes": [],
                "softVals": [
                  "1",
                  "1",
                  "0",
                  "0",
                  "0",
                  "0",
                  "0",
                  "0",
                  "1",
                  "1",
                  "1",
                  "1",
                  "1",
                  "1"
                ],
                "expectedSylsPerSecond": "0.00",
                "actualSylsPerSecond": "0.58",
                "timeLength": 1750,
                "features": [
                  {
                    "sampleYVal": 148,
                    "sampleYDrawVal": 1976
                  },
                  {
                    "sampleYVal": 174,
                    "sampleYDrawVal": 2803
                  },
                  {
                    "sampleYVal": 176,
                    "sampleYDrawVal": 2827
                  },
                  {
                    "sampleYVal": 172,
                    "sampleYDrawVal": 2721
                  },
                  {
                    "sampleYVal": 184,
                    "sampleYDrawVal": 3211
                  },
                  {
                    "sampleYVal": 181,
                    "sampleYDrawVal": 3036
                  },
                  {
                    "sampleYVal": 180,
                    "sampleYDrawVal": 2946
                  },
                  {
                    "sampleYVal": 171,
                    "sampleYDrawVal": 2696
                  },
                  {
                    "sampleYVal": 148,
                    "sampleYDrawVal": 1911
                  },
                  {
                    "sampleYVal": 131,
                    "sampleYDrawVal": 1455
                  },
                  {
                    "sampleYVal": 98,
                    "sampleYDrawVal": 785
                  },
                  {
                    "sampleYVal": 63,
                    "sampleYDrawVal": 287
                  },
                  {
                    "sampleYVal": 24,
                    "sampleYDrawVal": 26
                  }
                ],
                "recordOffset": 21888,
                "seqUrl": "1527724213056_20633_709",
                "seqDescription": {
                  "analysis": {
                    "analysisType": "2sec"
                  },
                  "completeCount": 2,
                  "currentSeqPos": 0,
                  "pbId": "25",
                  "text": "לִי ",
                  "type": "sentence",
                  "word": {
                    "id": 798,
                    "language": "he",
                    "cleanInput": "לִי",
                    "type": "word",
                    "data": "[{\"text\":\"\\u05dc\\u05b4\\u05d9\",\"sylType\":\"cv\",\"vowel\":\"I\",\"isContinous\":true}]",
                    "isNonesense": 0,
                    "audios": "[]"
                  }
                },
                "mistakesObjects": {
                  "gentleOnset": {
                    "titlei18Key": "MISTAKES.GENTLE_ONSET"
                  }
                },
                "hiddenMistakesObjects": {}
              },
              {
                "fieldType": "seq",
                "timerPos": 782,
                "calibrationEventId": "6581",
                "rate": 85,
                "deltaTimeMsFromPrevSeq": "1280.00",
                "yvalues": [
                  "92",
                  "136",
                  "162",
                  "172",
                  "174",
                  "179",
                  "184",
                  "178",
                  "162",
                  "134",
                  "104",
                  "71",
                  "37",
                  "0"
                ],
                "yDrawvalues": "1056,2104,2716,2914,2821,3045,3219,2882,2250,1420,746,270,54,0",
                "mistakes": [
                  {
                    "sampleOffset": 13,
                    "mistakeId": 6
                  }
                ],
                "hiddenMistakes": [],
                "softVals": [
                  "1",
                  "1",
                  "1",
                  "0",
                  "0",
                  "0",
                  "0",
                  "0",
                  "1",
                  "1",
                  "1",
                  "1",
                  "1",
                  "1"
                ],
                "expectedSylsPerSecond": "0.00",
                "actualSylsPerSecond": "0.58",
                "timeLength": 1750,
                "features": [
                  {
                    "sampleYVal": 127,
                    "sampleYDrawVal": 1536
                  },
                  {
                    "sampleYVal": 157,
                    "sampleYDrawVal": 2356
                  },
                  {
                    "sampleYVal": 177,
                    "sampleYDrawVal": 2989
                  },
                  {
                    "sampleYVal": 174,
                    "sampleYDrawVal": 2827
                  },
                  {
                    "sampleYVal": 178,
                    "sampleYDrawVal": 2943
                  },
                  {
                    "sampleYVal": 187,
                    "sampleYDrawVal": 3289
                  },
                  {
                    "sampleYVal": 183,
                    "sampleYDrawVal": 3119
                  },
                  {
                    "sampleYVal": 173,
                    "sampleYDrawVal": 2752
                  },
                  {
                    "sampleYVal": 152,
                    "sampleYDrawVal": 2024
                  },
                  {
                    "sampleYVal": 118,
                    "sampleYDrawVal": 1191
                  },
                  {
                    "sampleYVal": 68,
                    "sampleYDrawVal": 304
                  },
                  {
                    "sampleYVal": 49,
                    "sampleYDrawVal": 135
                  },
                  {
                    "sampleYVal": 16,
                    "sampleYDrawVal": 18
                  }
                ],
                "recordOffset": 24901,
                "seqUrl": "1527724213056_20633_709",
                "seqDescription": {
                  "analysis": {
                    "analysisType": "2sec"
                  },
                  "completeCount": 3,
                  "currentSeqPos": 0,
                  "pbId": "25",
                  "text": "גְּלִי ",
                  "type": "sentence",
                  "word": {
                    "id": 799,
                    "language": "he",
                    "cleanInput": "גְּלִידָה",
                    "type": "word",
                    "data": "[{\"text\":\"\\u05d2\\u05b0\\u05bc\\u05dc\\u05b4\\u05d9\",\"sylType\":\"cv\",\"vowel\":\"I\",\"isContinous\":true,\"isPlosive\":true,\"isClusterStart\":true},{\"text\":\"\\u05d3\\u05b8\\u05d4\",\"sylType\":\"cv\",\"vowel\":\"A\",\"isPlosive\":true}]",
                    "isNonesense": 0,
                    "audios": ""
                  }
                },
                "mistakesObjects": {
                  "tooShort": {
                    "titlei18Key": "MISTAKES.TOO_SHORT"
                  }
                },
                "hiddenMistakesObjects": {}
              },
              {
                "fieldType": "seq",
                "timerPos": 785,
                "calibrationEventId": "6581",
                "rate": 80,
                "deltaTimeMsFromPrevSeq": "1398.00",
                "yvalues": [
                  "133",
                  "177",
                  "192",
                  "196",
                  "193",
                  "193",
                  "190",
                  "176",
                  "157",
                  "141",
                  "109",
                  "54",
                  "0"
                ],
                "yDrawvalues": "2188,3363,3639,3628,3476,3487,3376,2784,2104,1661,900,116,0",
                "mistakes": [
                  {
                    "sampleOffset": 1,
                    "mistakeId": 1
                  }
                ],
                "hiddenMistakes": [],
                "softVals": [
                  "1",
                  "0",
                  "0",
                  "0",
                  "0",
                  "0",
                  "0",
                  "0",
                  "1",
                  "1",
                  "1",
                  "1",
                  "1"
                ],
                "expectedSylsPerSecond": "0.00",
                "actualSylsPerSecond": "0.60",
                "timeLength": 1625,
                "features": [
                  {
                    "sampleYVal": 165,
                    "sampleYDrawVal": 2538
                  },
                  {
                    "sampleYVal": 193,
                    "sampleYDrawVal": 3517
                  },
                  {
                    "sampleYVal": 198,
                    "sampleYDrawVal": 3686
                  },
                  {
                    "sampleYVal": 198,
                    "sampleYDrawVal": 3657
                  },
                  {
                    "sampleYVal": 193,
                    "sampleYDrawVal": 3448
                  },
                  {
                    "sampleYVal": 194,
                    "sampleYDrawVal": 3524
                  },
                  {
                    "sampleYVal": 189,
                    "sampleYDrawVal": 3349
                  },
                  {
                    "sampleYVal": 165,
                    "sampleYDrawVal": 2575
                  },
                  {
                    "sampleYVal": 144,
                    "sampleYDrawVal": 1820
                  },
                  {
                    "sampleYVal": 130,
                    "sampleYDrawVal": 1444
                  },
                  {
                    "sampleYVal": 76,
                    "sampleYDrawVal": 452
                  },
                  {
                    "sampleYVal": 24,
                    "sampleYDrawVal": 23
                  }
                ],
                "recordOffset": 28093,
                "seqUrl": "1527724213056_20633_709",
                "seqDescription": {
                  "analysis": {
                    "analysisType": "2sec"
                  },
                  "completeCount": 3,
                  "currentSeqPos": 1,
                  "pbId": "25",
                  "text": "דָה ",
                  "type": "sentence",
                  "word": {
                    "id": 799,
                    "language": "he",
                    "cleanInput": "גְּלִידָה",
                    "type": "word",
                    "data": "[{\"text\":\"\\u05d2\\u05b0\\u05bc\\u05dc\\u05b4\\u05d9\",\"sylType\":\"cv\",\"vowel\":\"I\",\"isContinous\":true,\"isPlosive\":true,\"isClusterStart\":true},{\"text\":\"\\u05d3\\u05b8\\u05d4\",\"sylType\":\"cv\",\"vowel\":\"A\",\"isPlosive\":true}]",
                    "isNonesense": 0,
                    "audios": ""
                  }
                },
                "mistakesObjects": {
                  "gentleOnset": {
                    "titlei18Key": "MISTAKES.GENTLE_ONSET"
                  }
                },
                "hiddenMistakesObjects": {}
              },
              {
                "fieldType": "seq",
                "timerPos": 789,
                "calibrationEventId": "6581",
                "rate": 100,
                "deltaTimeMsFromPrevSeq": "1761.00",
                "yvalues": [
                  "76",
                  "112",
                  "135",
                  "167",
                  "200",
                  "207",
                  "205",
                  "192",
                  "175",
                  "164",
                  "152",
                  "133",
                  "105",
                  "62",
                  "0"
                ],
                "yDrawvalues": "642,1302,1701,2938,4211,4248,3998,3335,2668,2372,2059,1511,916,227,0",
                "mistakes": [],
                "hiddenMistakes": [],
                "softVals": [
                  "1",
                  "1",
                  "1",
                  "1",
                  "0",
                  "0",
                  "0",
                  "0",
                  "0",
                  "1",
                  "1",
                  "1",
                  "1",
                  "1",
                  "1"
                ],
                "expectedSylsPerSecond": "0.00",
                "actualSylsPerSecond": "0.52",
                "timeLength": 1875,
                "features": [
                  {
                    "sampleYVal": 86,
                    "sampleYDrawVal": 705
                  },
                  {
                    "sampleYVal": 133,
                    "sampleYDrawVal": 1498
                  },
                  {
                    "sampleYVal": 148,
                    "sampleYDrawVal": 1952
                  },
                  {
                    "sampleYVal": 196,
                    "sampleYDrawVal": 3655
                  },
                  {
                    "sampleYVal": 209,
                    "sampleYDrawVal": 4261
                  },
                  {
                    "sampleYVal": 205,
                    "sampleYDrawVal": 3999
                  },
                  {
                    "sampleYVal": 199,
                    "sampleYDrawVal": 3739
                  },
                  {
                    "sampleYVal": 181,
                    "sampleYDrawVal": 2997
                  },
                  {
                    "sampleYVal": 168,
                    "sampleYDrawVal": 2522
                  },
                  {
                    "sampleYVal": 151,
                    "sampleYDrawVal": 2052
                  },
                  {
                    "sampleYVal": 145,
                    "sampleYDrawVal": 1895
                  },
                  {
                    "sampleYVal": 106,
                    "sampleYDrawVal": 961
                  },
                  {
                    "sampleYVal": 92,
                    "sampleYDrawVal": 851
                  },
                  {
                    "sampleYVal": 26,
                    "sampleYDrawVal": 33
                  }
                ],
                "recordOffset": 1522,
                "seqUrl": "1527724243057_20633_709",
                "seqDescription": {
                  "analysis": {
                    "analysisType": "2sec"
                  },
                  "completeCount": 0,
                  "currentSeqPos": 0,
                  "pbId": "1263",
                  "text": "אָז ",
                  "type": "sentence",
                  "word": {
                    "id": 1258,
                    "language": "he",
                    "cleanInput": "אָז",
                    "type": "word",
                    "data": "[{\"text\":\"\\u05d0\\u05b8\\u05d6\",\"sylType\":\"vc\",\"vowel\":\"A\",\"isContinous\":true}]",
                    "isNonesense": 0,
                    "audios": ""
                  }
                },
                "mistakesObjects": {},
                "hiddenMistakesObjects": {}
              },
              {
                "fieldType": "seq",
                "timerPos": 792,
                "calibrationEventId": "6581",
                "rate": 85,
                "deltaTimeMsFromPrevSeq": "1340.00",
                "yvalues": [
                  "97",
                  "129",
                  "144",
                  "169",
                  "176",
                  "182",
                  "187",
                  "174",
                  "151",
                  "128",
                  "96",
                  "61",
                  "0"
                ],
                "yDrawvalues": "1316,1787,2017,2901,2994,3227,3400,2746,1912,1308,658,175,0",
                "mistakes": [
                  {
                    "sampleOffset": 12,
                    "mistakeId": 6
                  }
                ],
                "hiddenMistakes": [],
                "softVals": [
                  "1",
                  "1",
                  "1",
                  "1",
                  "0",
                  "0",
                  "0",
                  "0",
                  "1",
                  "1",
                  "1",
                  "1",
                  "1"
                ],
                "expectedSylsPerSecond": "0.00",
                "actualSylsPerSecond": "0.60",
                "timeLength": 1625,
                "features": [
                  {
                    "sampleYVal": 132,
                    "sampleYDrawVal": 1733
                  },
                  {
                    "sampleYVal": 137,
                    "sampleYDrawVal": 1666
                  },
                  {
                    "sampleYVal": 165,
                    "sampleYDrawVal": 2493
                  },
                  {
                    "sampleYVal": 181,
                    "sampleYDrawVal": 3073
                  },
                  {
                    "sampleYVal": 183,
                    "sampleYDrawVal": 3187
                  },
                  {
                    "sampleYVal": 185,
                    "sampleYDrawVal": 3237
                  },
                  {
                    "sampleYVal": 191,
                    "sampleYDrawVal": 3477
                  },
                  {
                    "sampleYVal": 153,
                    "sampleYDrawVal": 2083
                  },
                  {
                    "sampleYVal": 131,
                    "sampleYDrawVal": 1533
                  },
                  {
                    "sampleYVal": 99,
                    "sampleYDrawVal": 760
                  },
                  {
                    "sampleYVal": 60,
                    "sampleYDrawVal": 264
                  },
                  {
                    "sampleYVal": 51,
                    "sampleYDrawVal": 144
                  }
                ],
                "recordOffset": 4772,
                "seqUrl": "1527724243057_20633_709",
                "seqDescription": {
                  "analysis": {
                    "analysisType": "2sec"
                  },
                  "completeCount": 1,
                  "currentSeqPos": 0,
                  "pbId": "1263",
                  "text": "תִּשְׁ ",
                  "type": "sentence",
                  "word": {
                    "id": 1259,
                    "language": "he",
                    "cleanInput": "תִּשְׁתֶּה",
                    "type": "word",
                    "data": "[{\"text\":\"\\u05ea\\u05bc\\u05b4\\u05e9\\u05c1\\u05b0\",\"sylType\":\"cvc\",\"vowel\":\"I\",\"isContinous\":true,\"isPlosive\":true},{\"text\":\"\\u05ea\\u05bc\\u05b6\\u05d4\",\"sylType\":\"cv\",\"vowel\":\"E\",\"isPlosive\":true}]",
                    "isNonesense": 0,
                    "audios": ""
                  }
                },
                "mistakesObjects": {
                  "tooShort": {
                    "titlei18Key": "MISTAKES.TOO_SHORT"
                  }
                },
                "hiddenMistakesObjects": {}
              },
              {
                "fieldType": "seq",
                "timerPos": 795,
                "calibrationEventId": "6581",
                "rate": 100,
                "deltaTimeMsFromPrevSeq": "1360.00",
                "yvalues": [
                  "78",
                  "135",
                  "153",
                  "159",
                  "165",
                  "171",
                  "187",
                  "191",
                  "185",
                  "180",
                  "160",
                  "157",
                  "125",
                  "94",
                  "78",
                  "45",
                  "0"
                ],
                "yDrawvalues": "1056,2115,2291,2358,2596,2812,3511,3595,3214,3010,2188,2329,1272,832,609,121,0",
                "mistakes": [],
                "hiddenMistakes": [],
                "softVals": [
                  "1",
                  "1",
                  "1",
                  "1",
                  "1",
                  "0",
                  "0",
                  "0",
                  "0",
                  "0",
                  "1",
                  "1",
                  "1",
                  "1",
                  "1",
                  "1",
                  "1"
                ],
                "expectedSylsPerSecond": "0.00",
                "actualSylsPerSecond": "0.46",
                "timeLength": 2125,
                "features": [
                  {
                    "sampleYVal": 141,
                    "sampleYDrawVal": 1973
                  },
                  {
                    "sampleYVal": 157,
                    "sampleYDrawVal": 2303
                  },
                  {
                    "sampleYVal": 164,
                    "sampleYDrawVal": 2483
                  },
                  {
                    "sampleYVal": 168,
                    "sampleYDrawVal": 2579
                  },
                  {
                    "sampleYVal": 167,
                    "sampleYDrawVal": 2615
                  },
                  {
                    "sampleYVal": 178,
                    "sampleYDrawVal": 2966
                  },
                  {
                    "sampleYVal": 200,
                    "sampleYDrawVal": 3909
                  },
                  {
                    "sampleYVal": 183,
                    "sampleYDrawVal": 3183
                  },
                  {
                    "sampleYVal": 180,
                    "sampleYDrawVal": 3087
                  },
                  {
                    "sampleYVal": 156,
                    "sampleYDrawVal": 2212
                  },
                  {
                    "sampleYVal": 151,
                    "sampleYDrawVal": 2063
                  },
                  {
                    "sampleYVal": 146,
                    "sampleYDrawVal": 2015
                  },
                  {
                    "sampleYVal": 100,
                    "sampleYDrawVal": 953
                  },
                  {
                    "sampleYVal": 82,
                    "sampleYDrawVal": 786
                  },
                  {
                    "sampleYVal": 72,
                    "sampleYDrawVal": 566
                  },
                  {
                    "sampleYVal": 34,
                    "sampleYDrawVal": 71
                  }
                ],
                "recordOffset": 7815,
                "seqUrl": "1527724243057_20633_709",
                "seqDescription": {
                  "analysis": {
                    "analysisType": "2sec"
                  },
                  "completeCount": 1,
                  "currentSeqPos": 1,
                  "pbId": "1263",
                  "text": "תֶּה ",
                  "type": "sentence",
                  "word": {
                    "id": 1259,
                    "language": "he",
                    "cleanInput": "תִּשְׁתֶּה",
                    "type": "word",
                    "data": "[{\"text\":\"\\u05ea\\u05bc\\u05b4\\u05e9\\u05c1\\u05b0\",\"sylType\":\"cvc\",\"vowel\":\"I\",\"isContinous\":true,\"isPlosive\":true},{\"text\":\"\\u05ea\\u05bc\\u05b6\\u05d4\",\"sylType\":\"cv\",\"vowel\":\"E\",\"isPlosive\":true}]",
                    "isNonesense": 0,
                    "audios": ""
                  }
                },
                "mistakesObjects": {},
                "hiddenMistakesObjects": {}
              },
              {
                "fieldType": "seq",
                "timerPos": 798,
                "calibrationEventId": "6581",
                "rate": 80,
                "deltaTimeMsFromPrevSeq": "1221.00",
                "yvalues": [
                  "88",
                  "160",
                  "188",
                  "180",
                  "183",
                  "194",
                  "202",
                  "194",
                  "173",
                  "143",
                  "107",
                  "88",
                  "72",
                  "59",
                  "0"
                ],
                "yDrawvalues": "1077,2943,3602,2973,3162,3680,4021,3450,2539,1544,704,482,307,217,0",
                "mistakes": [
                  {
                    "sampleOffset": 2,
                    "mistakeId": 1
                  }
                ],
                "hiddenMistakes": [],
                "softVals": [
                  "1",
                  "1",
                  "0",
                  "0",
                  "0",
                  "0",
                  "0",
                  "0",
                  "0",
                  "1",
                  "1",
                  "1",
                  "1",
                  "1",
                  "1"
                ],
                "expectedSylsPerSecond": "0.00",
                "actualSylsPerSecond": "1.04",
                "timeLength": 1875,
                "features": [
                  {
                    "sampleYVal": 147,
                    "sampleYDrawVal": 1909
                  },
                  {
                    "sampleYVal": 185,
                    "sampleYDrawVal": 3158
                  },
                  {
                    "sampleYVal": 195,
                    "sampleYDrawVal": 3628
                  },
                  {
                    "sampleYVal": 178,
                    "sampleYDrawVal": 2922
                  },
                  {
                    "sampleYVal": 184,
                    "sampleYDrawVal": 3143
                  },
                  {
                    "sampleYVal": 204,
                    "sampleYDrawVal": 3971
                  },
                  {
                    "sampleYVal": 204,
                    "sampleYDrawVal": 4010
                  },
                  {
                    "sampleYVal": 188,
                    "sampleYDrawVal": 3264
                  },
                  {
                    "sampleYVal": 158,
                    "sampleYDrawVal": 2203
                  },
                  {
                    "sampleYVal": 126,
                    "sampleYDrawVal": 1268
                  },
                  {
                    "sampleYVal": 92,
                    "sampleYDrawVal": 575
                  },
                  {
                    "sampleYVal": 79,
                    "sampleYDrawVal": 411
                  },
                  {
                    "sampleYVal": 71,
                    "sampleYDrawVal": 336
                  },
                  {
                    "sampleYVal": 39,
                    "sampleYDrawVal": 78
                  }
                ],
                "recordOffset": 11202,
                "seqUrl": "1527724243057_20633_709",
                "seqDescription": {
                  "analysis": {
                    "analysisType": "2sec"
                  },
                  "completeCount": 2,
                  "currentSeqPos": 0,
                  "pbId": "1263",
                  "text": "קָ ",
                  "type": "sentence",
                  "word": {
                    "id": 1260,
                    "language": "he",
                    "cleanInput": "קָפֶה",
                    "type": "word",
                    "data": "[{\"text\":\"\\u05e7\\u05b8\",\"sylType\":\"cv\",\"isPlosive\":true,\"vowel\":\"A\"},{\"text\":\"\\u05e4\\u05b6\\u05d4\",\"isContinous\":true,\"vowel\":\"E\",\"sylType\":\"cv\"}]",
                    "isNonesense": 0,
                    "audios": ""
                  }
                },
                "mistakesObjects": {
                  "gentleOnset": {
                    "titlei18Key": "MISTAKES.GENTLE_ONSET"
                  }
                },
                "hiddenMistakesObjects": {}
              },
              {
                "fieldType": "seq",
                "timerPos": 802,
                "calibrationEventId": "6581",
                "rate": 100,
                "deltaTimeMsFromPrevSeq": "1279.00",
                "yvalues": [
                  "82",
                  "136",
                  "158",
                  "166",
                  "166",
                  "169",
                  "178",
                  "191",
                  "191",
                  "177",
                  "161",
                  "145",
                  "131",
                  "118",
                  "91",
                  "52",
                  "0"
                ],
                "yDrawvalues": "1108,2185,2545,2672,2574,2678,3079,3648,3502,2892,2353,1920,1617,1327,857,205,0",
                "mistakes": [],
                "hiddenMistakes": [],
                "softVals": [
                  "1",
                  "1",
                  "1",
                  "1",
                  "1",
                  "1",
                  "0",
                  "0",
                  "0",
                  "0",
                  "1",
                  "1",
                  "1",
                  "1",
                  "1",
                  "1",
                  "1"
                ],
                "expectedSylsPerSecond": "0.00",
                "actualSylsPerSecond": "0.46",
                "timeLength": 2125,
                "features": [
                  {
                    "sampleYVal": 133,
                    "sampleYDrawVal": 1743
                  },
                  {
                    "sampleYVal": 168,
                    "sampleYDrawVal": 2655
                  },
                  {
                    "sampleYVal": 166,
                    "sampleYDrawVal": 2569
                  },
                  {
                    "sampleYVal": 168,
                    "sampleYDrawVal": 2587
                  },
                  {
                    "sampleYVal": 167,
                    "sampleYDrawVal": 2559
                  },
                  {
                    "sampleYVal": 174,
                    "sampleYDrawVal": 2796
                  },
                  {
                    "sampleYVal": 185,
                    "sampleYDrawVal": 3245
                  },
                  {
                    "sampleYVal": 198,
                    "sampleYDrawVal": 3818
                  },
                  {
                    "sampleYVal": 188,
                    "sampleYDrawVal": 3378
                  },
                  {
                    "sampleYVal": 169,
                    "sampleYDrawVal": 2732
                  },
                  {
                    "sampleYVal": 149,
                    "sampleYDrawVal": 2097
                  },
                  {
                    "sampleYVal": 136,
                    "sampleYDrawVal": 1783
                  },
                  {
                    "sampleYVal": 117,
                    "sampleYDrawVal": 1423
                  },
                  {
                    "sampleYVal": 109,
                    "sampleYDrawVal": 1142
                  },
                  {
                    "sampleYVal": 66,
                    "sampleYDrawVal": 629
                  },
                  {
                    "sampleYVal": 25,
                    "sampleYDrawVal": 39
                  }
                ],
                "recordOffset": 14402,
                "seqUrl": "1527724243057_20633_709",
                "seqDescription": {
                  "analysis": {
                    "analysisType": "2sec"
                  },
                  "completeCount": 2,
                  "currentSeqPos": 1,
                  "pbId": "1263",
                  "text": "פֶה ",
                  "type": "sentence",
                  "word": {
                    "id": 1260,
                    "language": "he",
                    "cleanInput": "קָפֶה",
                    "type": "word",
                    "data": "[{\"text\":\"\\u05e7\\u05b8\",\"sylType\":\"cv\",\"isPlosive\":true,\"vowel\":\"A\"},{\"text\":\"\\u05e4\\u05b6\\u05d4\",\"isContinous\":true,\"vowel\":\"E\",\"sylType\":\"cv\"}]",
                    "isNonesense": 0,
                    "audios": ""
                  }
                },
                "mistakesObjects": {},
                "hiddenMistakesObjects": {}
              },
              {
                "fieldType": "seq",
                "timerPos": 805,
                "calibrationEventId": "6581",
                "rate": 80,
                "deltaTimeMsFromPrevSeq": "1150.00",
                "yvalues": [
                  "97",
                  "142",
                  "174",
                  "189",
                  "198",
                  "206",
                  "208",
                  "194",
                  "173",
                  "146",
                  "126",
                  "107",
                  "105",
                  "96",
                  "56",
                  "0"
                ],
                "yDrawvalues": "1242,2210,3181,3548,3862,4228,4220,3414,2573,1635,1235,986,1054,975,208,0",
                "mistakes": [
                  {
                    "sampleOffset": 2,
                    "mistakeId": 1
                  }
                ],
                "hiddenMistakes": [],
                "softVals": [
                  "1",
                  "1",
                  "0",
                  "0",
                  "0",
                  "0",
                  "0",
                  "0",
                  "0",
                  "1",
                  "1",
                  "1",
                  "1",
                  "1",
                  "1",
                  "1"
                ],
                "expectedSylsPerSecond": "0.00",
                "actualSylsPerSecond": "0.49",
                "timeLength": 2000,
                "features": [
                  {
                    "sampleYVal": 137,
                    "sampleYDrawVal": 1759
                  },
                  {
                    "sampleYVal": 156,
                    "sampleYDrawVal": 2297
                  },
                  {
                    "sampleYVal": 190,
                    "sampleYDrawVal": 3448
                  },
                  {
                    "sampleYVal": 192,
                    "sampleYDrawVal": 3532
                  },
                  {
                    "sampleYVal": 203,
                    "sampleYDrawVal": 3950
                  },
                  {
                    "sampleYVal": 212,
                    "sampleYDrawVal": 4368
                  },
                  {
                    "sampleYVal": 207,
                    "sampleYDrawVal": 4103
                  },
                  {
                    "sampleYVal": 180,
                    "sampleYDrawVal": 2964
                  },
                  {
                    "sampleYVal": 151,
                    "sampleYDrawVal": 2005
                  },
                  {
                    "sampleYVal": 132,
                    "sampleYDrawVal": 1415
                  },
                  {
                    "sampleYVal": 109,
                    "sampleYDrawVal": 1006
                  },
                  {
                    "sampleYVal": 118,
                    "sampleYDrawVal": 1263
                  },
                  {
                    "sampleYVal": 125,
                    "sampleYDrawVal": 1393
                  },
                  {
                    "sampleYVal": 65,
                    "sampleYDrawVal": 667
                  },
                  {
                    "sampleYVal": 28,
                    "sampleYDrawVal": 39
                  }
                ],
                "recordOffset": 17730,
                "seqUrl": "1527724243057_20633_709",
                "seqDescription": {
                  "analysis": {
                    "analysisType": "2sec"
                  },
                  "completeCount": 3,
                  "currentSeqPos": 0,
                  "pbId": "1263",
                  "text": "וְ ",
                  "type": "sentence",
                  "word": {
                    "id": 1261,
                    "language": "he",
                    "cleanInput": "וְתִתְקַשֵּׁר",
                    "type": "word",
                    "data": "[{\"text\":\"\\u05d5\\u05b0\",\"sylType\":\"cv\",\"vowel\":\"E\",\"isContinous\":true},{\"text\":\"\\u05ea\\u05b4\\u05ea\\u05b0\",\"sylType\":\"cvc\",\"vowel\":\"I\",\"isPlosive\":true},{\"text\":\"\\u05e7\\u05b7\",\"sylType\":\"cv\",\"vowel\":\"A\",\"isPlosive\":true},{\"text\":\"\\u05e9\\u05c1\\u05bc\\u05b5\\u05e8\",\"sylType\":\"cvc\",\"isContinous\":true,\"vowel\":\"E\"}]",
                    "isNonesense": 0,
                    "audios": ""
                  }
                },
                "mistakesObjects": {
                  "gentleOnset": {
                    "titlei18Key": "MISTAKES.GENTLE_ONSET"
                  }
                },
                "hiddenMistakesObjects": {}
              },
              {
                "fieldType": "seq",
                "timerPos": 808,
                "calibrationEventId": "6581",
                "rate": 80,
                "deltaTimeMsFromPrevSeq": "1029.00",
                "yvalues": [
                  "133",
                  "161",
                  "171",
                  "181",
                  "198",
                  "204",
                  "201",
                  "178",
                  "157",
                  "133",
                  "120",
                  "115",
                  "96",
                  "64",
                  "48",
                  "0"
                ],
                "yDrawvalues": "2247,2639,2839,3216,3960,4114,3844,2722,2039,1431,1255,1149,850,435,147,0",
                "mistakes": [
                  {
                    "sampleOffset": 2,
                    "mistakeId": 1
                  }
                ],
                "hiddenMistakes": [],
                "softVals": [
                  "1",
                  "1",
                  "0",
                  "0",
                  "0",
                  "0",
                  "0",
                  "0",
                  "1",
                  "1",
                  "1",
                  "1",
                  "1",
                  "1",
                  "1",
                  "1"
                ],
                "expectedSylsPerSecond": "0.00",
                "actualSylsPerSecond": "0.49",
                "timeLength": 2000,
                "features": [
                  {
                    "sampleYVal": 171,
                    "sampleYDrawVal": 2692
                  },
                  {
                    "sampleYVal": 166,
                    "sampleYDrawVal": 2533
                  },
                  {
                    "sampleYVal": 177,
                    "sampleYDrawVal": 2967
                  },
                  {
                    "sampleYVal": 195,
                    "sampleYDrawVal": 3629
                  },
                  {
                    "sampleYVal": 203,
                    "sampleYDrawVal": 3954
                  },
                  {
                    "sampleYVal": 206,
                    "sampleYDrawVal": 4095
                  },
                  {
                    "sampleYVal": 193,
                    "sampleYDrawVal": 3471
                  },
                  {
                    "sampleYVal": 167,
                    "sampleYDrawVal": 2555
                  },
                  {
                    "sampleYVal": 141,
                    "sampleYDrawVal": 1709
                  },
                  {
                    "sampleYVal": 134,
                    "sampleYDrawVal": 1573
                  },
                  {
                    "sampleYVal": 105,
                    "sampleYDrawVal": 992
                  },
                  {
                    "sampleYVal": 111,
                    "sampleYDrawVal": 1066
                  },
                  {
                    "sampleYVal": 77,
                    "sampleYDrawVal": 645
                  },
                  {
                    "sampleYVal": 50,
                    "sampleYDrawVal": 237
                  },
                  {
                    "sampleYVal": 34,
                    "sampleYDrawVal": 64
                  }
                ],
                "recordOffset": 20798,
                "seqUrl": "1527724243057_20633_709",
                "seqDescription": {
                  "analysis": {
                    "analysisType": "2sec"
                  },
                  "completeCount": 3,
                  "currentSeqPos": 1,
                  "pbId": "1263",
                  "text": "תִתְ ",
                  "type": "sentence",
                  "word": {
                    "id": 1261,
                    "language": "he",
                    "cleanInput": "וְתִתְקַשֵּׁר",
                    "type": "word",
                    "data": "[{\"text\":\"\\u05d5\\u05b0\",\"sylType\":\"cv\",\"vowel\":\"E\",\"isContinous\":true},{\"text\":\"\\u05ea\\u05b4\\u05ea\\u05b0\",\"sylType\":\"cvc\",\"vowel\":\"I\",\"isPlosive\":true},{\"text\":\"\\u05e7\\u05b7\",\"sylType\":\"cv\",\"vowel\":\"A\",\"isPlosive\":true},{\"text\":\"\\u05e9\\u05c1\\u05bc\\u05b5\\u05e8\",\"sylType\":\"cvc\",\"isContinous\":true,\"vowel\":\"E\"}]",
                    "isNonesense": 0,
                    "audios": ""
                  }
                },
                "mistakesObjects": {
                  "gentleOnset": {
                    "titlei18Key": "MISTAKES.GENTLE_ONSET"
                  }
                },
                "hiddenMistakesObjects": {}
              },
              {
                "fieldType": "seq",
                "timerPos": 811,
                "calibrationEventId": "6581",
                "rate": 80,
                "deltaTimeMsFromPrevSeq": "1480.00",
                "yvalues": [
                  "125",
                  "160",
                  "184",
                  "199",
                  "198",
                  "195",
                  "189",
                  "184",
                  "158",
                  "136",
                  "104",
                  "88",
                  "54",
                  "0"
                ],
                "yDrawvalues": "1867,2648,3370,3898,3700,3536,3258,3068,2057,1470,775,572,147,0",
                "mistakes": [
                  {
                    "sampleOffset": 2,
                    "mistakeId": 1
                  }
                ],
                "hiddenMistakes": [],
                "softVals": [
                  "1",
                  "1",
                  "0",
                  "0",
                  "0",
                  "0",
                  "0",
                  "0",
                  "1",
                  "1",
                  "1",
                  "1",
                  "1",
                  "1"
                ],
                "expectedSylsPerSecond": "0.00",
                "actualSylsPerSecond": "0.56",
                "timeLength": 1750,
                "features": [
                  {
                    "sampleYVal": 152,
                    "sampleYDrawVal": 2109
                  },
                  {
                    "sampleYVal": 185,
                    "sampleYDrawVal": 3140
                  },
                  {
                    "sampleYVal": 193,
                    "sampleYDrawVal": 3488
                  },
                  {
                    "sampleYVal": 202,
                    "sampleYDrawVal": 3870
                  },
                  {
                    "sampleYVal": 197,
                    "sampleYDrawVal": 3645
                  },
                  {
                    "sampleYVal": 193,
                    "sampleYDrawVal": 3441
                  },
                  {
                    "sampleYVal": 187,
                    "sampleYDrawVal": 3216
                  },
                  {
                    "sampleYVal": 174,
                    "sampleYDrawVal": 2725
                  },
                  {
                    "sampleYVal": 152,
                    "sampleYDrawVal": 1995
                  },
                  {
                    "sampleYVal": 116,
                    "sampleYDrawVal": 1129
                  },
                  {
                    "sampleYVal": 98,
                    "sampleYDrawVal": 815
                  },
                  {
                    "sampleYVal": 68,
                    "sampleYDrawVal": 348
                  },
                  {
                    "sampleYVal": 32,
                    "sampleYDrawVal": 57
                  }
                ],
                "recordOffset": 24317,
                "seqUrl": "1527724243057_20633_709",
                "seqDescription": {
                  "analysis": {
                    "analysisType": "2sec"
                  },
                  "completeCount": 3,
                  "currentSeqPos": 2,
                  "pbId": "1263",
                  "text": "קַ ",
                  "type": "sentence",
                  "word": {
                    "id": 1261,
                    "language": "he",
                    "cleanInput": "וְתִתְקַשֵּׁר",
                    "type": "word",
                    "data": "[{\"text\":\"\\u05d5\\u05b0\",\"sylType\":\"cv\",\"vowel\":\"E\",\"isContinous\":true},{\"text\":\"\\u05ea\\u05b4\\u05ea\\u05b0\",\"sylType\":\"cvc\",\"vowel\":\"I\",\"isPlosive\":true},{\"text\":\"\\u05e7\\u05b7\",\"sylType\":\"cv\",\"vowel\":\"A\",\"isPlosive\":true},{\"text\":\"\\u05e9\\u05c1\\u05bc\\u05b5\\u05e8\",\"sylType\":\"cvc\",\"isContinous\":true,\"vowel\":\"E\"}]",
                    "isNonesense": 0,
                    "audios": ""
                  }
                },
                "mistakesObjects": {
                  "gentleOnset": {
                    "titlei18Key": "MISTAKES.GENTLE_ONSET"
                  }
                },
                "hiddenMistakesObjects": {}
              },
              {
                "fieldType": "seq",
                "timerPos": 814,
                "calibrationEventId": "6581",
                "rate": 85,
                "deltaTimeMsFromPrevSeq": "1350.00",
                "yvalues": [
                  "70",
                  "127",
                  "161",
                  "186",
                  "198",
                  "199",
                  "198",
                  "182",
                  "171",
                  "152",
                  "124",
                  "101",
                  "62",
                  "0"
                ],
                "yDrawvalues": "848,1836,2669,3531,3960,3823,3799,2936,2588,1947,1228,770,223,0",
                "mistakes": [
                  {
                    "sampleOffset": 13,
                    "mistakeId": 6
                  }
                ],
                "hiddenMistakes": [],
                "softVals": [
                  "1",
                  "1",
                  "1",
                  "0",
                  "0",
                  "0",
                  "0",
                  "0",
                  "0",
                  "1",
                  "1",
                  "1",
                  "1",
                  "1"
                ],
                "expectedSylsPerSecond": "0.00",
                "actualSylsPerSecond": "0.56",
                "timeLength": 1750,
                "features": [
                  {
                    "sampleYVal": 114,
                    "sampleYDrawVal": 1331
                  },
                  {
                    "sampleYVal": 150,
                    "sampleYDrawVal": 2063
                  },
                  {
                    "sampleYVal": 174,
                    "sampleYDrawVal": 2802
                  },
                  {
                    "sampleYVal": 201,
                    "sampleYDrawVal": 3925
                  },
                  {
                    "sampleYVal": 199,
                    "sampleYDrawVal": 3831
                  },
                  {
                    "sampleYVal": 201,
                    "sampleYDrawVal": 3863
                  },
                  {
                    "sampleYVal": 192,
                    "sampleYDrawVal": 3520
                  },
                  {
                    "sampleYVal": 175,
                    "sampleYDrawVal": 2801
                  },
                  {
                    "sampleYVal": 161,
                    "sampleYDrawVal": 2326
                  },
                  {
                    "sampleYVal": 136,
                    "sampleYDrawVal": 1597
                  },
                  {
                    "sampleYVal": 102,
                    "sampleYDrawVal": 870
                  },
                  {
                    "sampleYVal": 77,
                    "sampleYDrawVal": 535
                  },
                  {
                    "sampleYVal": 50,
                    "sampleYDrawVal": 237
                  }
                ],
                "recordOffset": 27463,
                "seqUrl": "1527724243057_20633_709",
                "seqDescription": {
                  "analysis": {
                    "analysisType": "2sec"
                  },
                  "completeCount": 3,
                  "currentSeqPos": 3,
                  "pbId": "1263",
                  "text": "שֵּׁר ",
                  "type": "sentence",
                  "word": {
                    "id": 1261,
                    "language": "he",
                    "cleanInput": "וְתִתְקַשֵּׁר",
                    "type": "word",
                    "data": "[{\"text\":\"\\u05d5\\u05b0\",\"sylType\":\"cv\",\"vowel\":\"E\",\"isContinous\":true},{\"text\":\"\\u05ea\\u05b4\\u05ea\\u05b0\",\"sylType\":\"cvc\",\"vowel\":\"I\",\"isPlosive\":true},{\"text\":\"\\u05e7\\u05b7\",\"sylType\":\"cv\",\"vowel\":\"A\",\"isPlosive\":true},{\"text\":\"\\u05e9\\u05c1\\u05bc\\u05b5\\u05e8\",\"sylType\":\"cvc\",\"isContinous\":true,\"vowel\":\"E\"}]",
                    "isNonesense": 0,
                    "audios": ""
                  }
                },
                "mistakesObjects": {
                  "tooShort": {
                    "titlei18Key": "MISTAKES.TOO_SHORT"
                  }
                },
                "hiddenMistakesObjects": {}
              },
              {
                "fieldType": "seq",
                "timerPos": 818,
                "calibrationEventId": "6581",
                "rate": 100,
                "deltaTimeMsFromPrevSeq": "1341.00",
                "yvalues": [
                  "74",
                  "129",
                  "164",
                  "181",
                  "189",
                  "196",
                  "198",
                  "186",
                  "175",
                  "166",
                  "157",
                  "138",
                  "108",
                  "70",
                  "48",
                  "0"
                ],
                "yDrawvalues": "879,1954,2808,3263,3468,3776,3796,3088,2711,2436,2155,1606,912,303,112,0",
                "mistakes": [],
                "hiddenMistakes": [],
                "softVals": [
                  "1",
                  "1",
                  "1",
                  "0",
                  "0",
                  "0",
                  "0",
                  "0",
                  "0",
                  "1",
                  "1",
                  "1",
                  "1",
                  "1",
                  "1",
                  "1"
                ],
                "expectedSylsPerSecond": "0.00",
                "actualSylsPerSecond": "0.47",
                "timeLength": 2000,
                "features": [
                  {
                    "sampleYVal": 100,
                    "sampleYDrawVal": 1156
                  },
                  {
                    "sampleYVal": 159,
                    "sampleYDrawVal": 2321
                  },
                  {
                    "sampleYVal": 179,
                    "sampleYDrawVal": 3033
                  },
                  {
                    "sampleYVal": 190,
                    "sampleYDrawVal": 3429
                  },
                  {
                    "sampleYVal": 196,
                    "sampleYDrawVal": 3648
                  },
                  {
                    "sampleYVal": 203,
                    "sampleYDrawVal": 3953
                  },
                  {
                    "sampleYVal": 190,
                    "sampleYDrawVal": 3382
                  },
                  {
                    "sampleYVal": 177,
                    "sampleYDrawVal": 2864
                  },
                  {
                    "sampleYVal": 171,
                    "sampleYDrawVal": 2647
                  },
                  {
                    "sampleYVal": 153,
                    "sampleYDrawVal": 2063
                  },
                  {
                    "sampleYVal": 157,
                    "sampleYDrawVal": 2232
                  },
                  {
                    "sampleYVal": 119,
                    "sampleYDrawVal": 1287
                  },
                  {
                    "sampleYVal": 85,
                    "sampleYDrawVal": 584
                  },
                  {
                    "sampleYVal": 49,
                    "sampleYDrawVal": 172
                  },
                  {
                    "sampleYVal": 42,
                    "sampleYDrawVal": 92
                  }
                ],
                "recordOffset": 662,
                "seqUrl": "1527724273056_20633_709",
                "seqDescription": {
                  "analysis": {
                    "analysisType": "2sec"
                  },
                  "completeCount": 4,
                  "currentSeqPos": 0,
                  "pbId": "1263",
                  "text": "לְ ",
                  "type": "sentence",
                  "word": {
                    "id": 1262,
                    "language": "he",
                    "cleanInput": "לְאִמָּא",
                    "type": "word",
                    "data": "[{\"text\":\"\\u05dc\\u05b0\",\"sylType\":\"cv\",\"vowel\":\"E\",\"isContinous\":true},{\"text\":\"\\u05d0\\u05b4\",\"sylType\":\"v\",\"vowel\":\"I\"},{\"text\":\"\\u05de\\u05bc\\u05b8\\u05d0\",\"sylType\":\"cv\",\"vowel\":\"A\",\"isContinous\":true}]",
                    "isNonesense": 0,
                    "audios": ""
                  }
                },
                "mistakesObjects": {},
                "hiddenMistakesObjects": {}
              },
              {
                "fieldType": "seq",
                "timerPos": 821,
                "calibrationEventId": "6581",
                "rate": 100,
                "deltaTimeMsFromPrevSeq": "1089.00",
                "yvalues": [
                  "68",
                  "107",
                  "142",
                  "161",
                  "175",
                  "177",
                  "179",
                  "180",
                  "180",
                  "173",
                  "164",
                  "142",
                  "97",
                  "62",
                  "0"
                ],
                "yDrawvalues": "694,1239,2054,2516,2988,2923,3064,3105,3091,2777,2410,1647,685,217,0",
                "mistakes": [],
                "hiddenMistakes": [],
                "softVals": [
                  "1",
                  "1",
                  "1",
                  "1",
                  "0",
                  "0",
                  "0",
                  "0",
                  "0",
                  "0",
                  "1",
                  "1",
                  "1",
                  "1",
                  "1"
                ],
                "expectedSylsPerSecond": "0.00",
                "actualSylsPerSecond": "0.52",
                "timeLength": 1875,
                "features": [
                  {
                    "sampleYVal": 92,
                    "sampleYDrawVal": 762
                  },
                  {
                    "sampleYVal": 128,
                    "sampleYDrawVal": 1420
                  },
                  {
                    "sampleYVal": 159,
                    "sampleYDrawVal": 2232
                  },
                  {
                    "sampleYVal": 173,
                    "sampleYDrawVal": 2742
                  },
                  {
                    "sampleYVal": 181,
                    "sampleYDrawVal": 3085
                  },
                  {
                    "sampleYVal": 175,
                    "sampleYDrawVal": 2842
                  },
                  {
                    "sampleYVal": 186,
                    "sampleYDrawVal": 3311
                  },
                  {
                    "sampleYVal": 183,
                    "sampleYDrawVal": 3190
                  },
                  {
                    "sampleYVal": 181,
                    "sampleYDrawVal": 3079
                  },
                  {
                    "sampleYVal": 165,
                    "sampleYDrawVal": 2514
                  },
                  {
                    "sampleYVal": 148,
                    "sampleYDrawVal": 1935
                  },
                  {
                    "sampleYVal": 125,
                    "sampleYDrawVal": 1363
                  },
                  {
                    "sampleYVal": 76,
                    "sampleYDrawVal": 480
                  },
                  {
                    "sampleYVal": 50,
                    "sampleYDrawVal": 148
                  }
                ],
                "recordOffset": 3793,
                "seqUrl": "1527724273056_20633_709",
                "seqDescription": {
                  "analysis": {
                    "analysisType": "2sec"
                  },
                  "completeCount": 4,
                  "currentSeqPos": 1,
                  "pbId": "1263",
                  "text": "אִ ",
                  "type": "sentence",
                  "word": {
                    "id": 1262,
                    "language": "he",
                    "cleanInput": "לְאִמָּא",
                    "type": "word",
                    "data": "[{\"text\":\"\\u05dc\\u05b0\",\"sylType\":\"cv\",\"vowel\":\"E\",\"isContinous\":true},{\"text\":\"\\u05d0\\u05b4\",\"sylType\":\"v\",\"vowel\":\"I\"},{\"text\":\"\\u05de\\u05bc\\u05b8\\u05d0\",\"sylType\":\"cv\",\"vowel\":\"A\",\"isContinous\":true}]",
                    "isNonesense": 0,
                    "audios": ""
                  }
                },
                "mistakesObjects": {},
                "hiddenMistakesObjects": {}
              },
              {
                "fieldType": "seq",
                "timerPos": 824,
                "calibrationEventId": "6581",
                "rate": 100,
                "deltaTimeMsFromPrevSeq": "1340.00",
                "yvalues": [
                  "116",
                  "136",
                  "158",
                  "169",
                  "176",
                  "202",
                  "208",
                  "212",
                  "199",
                  "182",
                  "166",
                  "155",
                  "137",
                  "114",
                  "59",
                  "0"
                ],
                "yDrawvalues": "1420,1742,2422,2716,2937,4237,4345,4484,3693,2949,2428,2070,1588,1036,145,0",
                "mistakes": [],
                "hiddenMistakes": [],
                "softVals": [
                  "1",
                  "1",
                  "1",
                  "1",
                  "0",
                  "0",
                  "0",
                  "0",
                  "0",
                  "0",
                  "1",
                  "1",
                  "1",
                  "1",
                  "1",
                  "1"
                ],
                "expectedSylsPerSecond": "0.00",
                "actualSylsPerSecond": "0.49",
                "timeLength": 2000,
                "features": [
                  {
                    "sampleYVal": 126,
                    "sampleYDrawVal": 1356
                  },
                  {
                    "sampleYVal": 149,
                    "sampleYDrawVal": 1927
                  },
                  {
                    "sampleYVal": 170,
                    "sampleYDrawVal": 2600
                  },
                  {
                    "sampleYVal": 178,
                    "sampleYDrawVal": 2899
                  },
                  {
                    "sampleYVal": 191,
                    "sampleYDrawVal": 3410
                  },
                  {
                    "sampleYVal": 208,
                    "sampleYDrawVal": 4217
                  },
                  {
                    "sampleYVal": 214,
                    "sampleYDrawVal": 4504
                  },
                  {
                    "sampleYVal": 211,
                    "sampleYDrawVal": 4339
                  },
                  {
                    "sampleYVal": 183,
                    "sampleYDrawVal": 3174
                  },
                  {
                    "sampleYVal": 175,
                    "sampleYDrawVal": 2834
                  },
                  {
                    "sampleYVal": 157,
                    "sampleYDrawVal": 2239
                  },
                  {
                    "sampleYVal": 150,
                    "sampleYDrawVal": 1994
                  },
                  {
                    "sampleYVal": 131,
                    "sampleYDrawVal": 1544
                  },
                  {
                    "sampleYVal": 83,
                    "sampleYDrawVal": 536
                  },
                  {
                    "sampleYVal": 30,
                    "sampleYDrawVal": 40
                  }
                ],
                "recordOffset": 7066,
                "seqUrl": "1527724273056_20633_709",
                "seqDescription": {
                  "analysis": {
                    "analysisType": "2sec"
                  },
                  "completeCount": 4,
                  "currentSeqPos": 2,
                  "pbId": "1263",
                  "text": "מָּא ",
                  "type": "sentence",
                  "word": {
                    "id": 1262,
                    "language": "he",
                    "cleanInput": "לְאִמָּא",
                    "type": "word",
                    "data": "[{\"text\":\"\\u05dc\\u05b0\",\"sylType\":\"cv\",\"vowel\":\"E\",\"isContinous\":true},{\"text\":\"\\u05d0\\u05b4\",\"sylType\":\"v\",\"vowel\":\"I\"},{\"text\":\"\\u05de\\u05bc\\u05b8\\u05d0\",\"sylType\":\"cv\",\"vowel\":\"A\",\"isContinous\":true}]",
                    "isNonesense": 0,
                    "audios": ""
                  }
                },
                "mistakesObjects": {},
                "hiddenMistakesObjects": {}
              },
              {
                "fieldType": "seq",
                "timerPos": 827,
                "calibrationEventId": "6581",
                "rate": 80,
                "deltaTimeMsFromPrevSeq": "1280.00",
                "yvalues": [
                  "109",
                  "149",
                  "177",
                  "184",
                  "185",
                  "188",
                  "193",
                  "189",
                  "170",
                  "140",
                  "107",
                  "81",
                  "56",
                  "0"
                ],
                "yDrawvalues": "1382,2347,3194,3304,3268,3441,3617,3391,2494,1635,928,526,244,0",
                "mistakes": [
                  {
                    "sampleOffset": 2,
                    "mistakeId": 1
                  }
                ],
                "hiddenMistakes": [],
                "softVals": [
                  "1",
                  "1",
                  "0",
                  "0",
                  "0",
                  "0",
                  "0",
                  "0",
                  "1",
                  "1",
                  "1",
                  "1",
                  "1",
                  "1"
                ],
                "expectedSylsPerSecond": "0.00",
                "actualSylsPerSecond": "0.58",
                "timeLength": 1750,
                "features": [
                  {
                    "sampleYVal": 144,
                    "sampleYDrawVal": 1888
                  },
                  {
                    "sampleYVal": 171,
                    "sampleYDrawVal": 2694
                  },
                  {
                    "sampleYVal": 186,
                    "sampleYDrawVal": 3231
                  },
                  {
                    "sampleYVal": 180,
                    "sampleYDrawVal": 3052
                  },
                  {
                    "sampleYVal": 183,
                    "sampleYDrawVal": 3160
                  },
                  {
                    "sampleYVal": 198,
                    "sampleYDrawVal": 3837
                  },
                  {
                    "sampleYVal": 191,
                    "sampleYDrawVal": 3473
                  },
                  {
                    "sampleYVal": 184,
                    "sampleYDrawVal": 3172
                  },
                  {
                    "sampleYVal": 154,
                    "sampleYDrawVal": 2172
                  },
                  {
                    "sampleYVal": 113,
                    "sampleYDrawVal": 1165
                  },
                  {
                    "sampleYVal": 91,
                    "sampleYDrawVal": 813
                  },
                  {
                    "sampleYVal": 65,
                    "sampleYDrawVal": 428
                  },
                  {
                    "sampleYVal": 45,
                    "sampleYDrawVal": 139
                  }
                ],
                "recordOffset": 10318,
                "seqUrl": "1527724273056_20633_709",
                "seqDescription": {
                  "analysis": {
                    "analysisType": "2sec"
                  },
                  "completeCount": 0,
                  "currentSeqPos": 0,
                  "pbId": "1211",
                  "text": "אֵין ",
                  "type": "sentence",
                  "word": {
                    "id": 937,
                    "language": "he",
                    "cleanInput": "אֵין",
                    "type": "word",
                    "data": "[{\"text\":\"\\u05d0\\u05b5\\u05d9\\u05df\",\"sylType\":\"vc\",\"vowel\":\"E\",\"isContinous\":true}]",
                    "isNonesense": 0,
                    "audios": "[]"
                  }
                },
                "mistakesObjects": {
                  "gentleOnset": {
                    "titlei18Key": "MISTAKES.GENTLE_ONSET"
                  }
                },
                "hiddenMistakesObjects": {}
              },
              {
                "fieldType": "seq",
                "timerPos": 830,
                "calibrationEventId": "6581",
                "rate": 80,
                "deltaTimeMsFromPrevSeq": "1150.00",
                "yvalues": [
                  "74",
                  "146",
                  "185",
                  "200",
                  "194",
                  "195",
                  "197",
                  "191",
                  "173",
                  "161",
                  "155",
                  "138",
                  "133",
                  "106",
                  "60",
                  "0"
                ],
                "yDrawvalues": "858,2555,3658,4019,3519,3612,3728,3369,2543,2221,2081,1484,1455,851,167,0",
                "mistakes": [
                  {
                    "sampleOffset": 2,
                    "mistakeId": 1
                  }
                ],
                "hiddenMistakes": [],
                "softVals": [
                  "1",
                  "1",
                  "0",
                  "0",
                  "0",
                  "0",
                  "0",
                  "0",
                  "0",
                  "1",
                  "1",
                  "1",
                  "1",
                  "1",
                  "1",
                  "1"
                ],
                "expectedSylsPerSecond": "0.00",
                "actualSylsPerSecond": "0.99",
                "timeLength": 2000,
                "features": [
                  {
                    "sampleYVal": 143,
                    "sampleYDrawVal": 1890
                  },
                  {
                    "sampleYVal": 189,
                    "sampleYDrawVal": 3396
                  },
                  {
                    "sampleYVal": 201,
                    "sampleYDrawVal": 3856
                  },
                  {
                    "sampleYVal": 199,
                    "sampleYDrawVal": 3810
                  },
                  {
                    "sampleYVal": 194,
                    "sampleYDrawVal": 3550
                  },
                  {
                    "sampleYVal": 202,
                    "sampleYDrawVal": 3873
                  },
                  {
                    "sampleYVal": 194,
                    "sampleYDrawVal": 3498
                  },
                  {
                    "sampleYVal": 179,
                    "sampleYDrawVal": 2904
                  },
                  {
                    "sampleYVal": 167,
                    "sampleYDrawVal": 2493
                  },
                  {
                    "sampleYVal": 163,
                    "sampleYDrawVal": 2341
                  },
                  {
                    "sampleYVal": 138,
                    "sampleYDrawVal": 1625
                  },
                  {
                    "sampleYVal": 131,
                    "sampleYDrawVal": 1369
                  },
                  {
                    "sampleYVal": 129,
                    "sampleYDrawVal": 1353
                  },
                  {
                    "sampleYVal": 67,
                    "sampleYDrawVal": 366
                  },
                  {
                    "sampleYVal": 37,
                    "sampleYDrawVal": 83
                  }
                ],
                "recordOffset": 13238,
                "seqUrl": "1527724273056_20633_709",
                "seqDescription": {
                  "analysis": {
                    "analysisType": "2sec"
                  },
                  "completeCount": 1,
                  "currentSeqPos": 0,
                  "pbId": "1211",
                  "text": "דָּ ",
                  "type": "sentence",
                  "word": {
                    "id": 1207,
                    "language": "he",
                    "cleanInput": "דָּבָר",
                    "type": "word",
                    "data": "[{\"text\":\"\\u05d3\\u05bc\\u05b8\",\"sylType\":\"cv\",\"vowel\":\"A\",\"isPlosive\":true},{\"text\":\"\\u05d1\\u05b8\\u05e8\",\"sylType\":\"cvc\",\"vowel\":\"A\",\"isContinous\":true}]",
                    "isNonesense": 0,
                    "audios": ""
                  }
                },
                "mistakesObjects": {
                  "gentleOnset": {
                    "titlei18Key": "MISTAKES.GENTLE_ONSET"
                  }
                },
                "hiddenMistakesObjects": {}
              },
              {
                "fieldType": "seq",
                "timerPos": 834,
                "calibrationEventId": "6581",
                "rate": 100,
                "deltaTimeMsFromPrevSeq": "1221.00",
                "yvalues": [
                  "71",
                  "114",
                  "161",
                  "186",
                  "188",
                  "185",
                  "187",
                  "174",
                  "156",
                  "130",
                  "111",
                  "83",
                  "67",
                  "37",
                  "0"
                ],
                "yDrawvalues": "482,1375,2761,3520,3367,3258,3369,2746,2098,1440,1045,691,439,70,0",
                "mistakes": [],
                "hiddenMistakes": [],
                "softVals": [
                  "1",
                  "1",
                  "1",
                  "0",
                  "0",
                  "0",
                  "0",
                  "0",
                  "1",
                  "1",
                  "1",
                  "1",
                  "1",
                  "1",
                  "1"
                ],
                "expectedSylsPerSecond": "0.00",
                "actualSylsPerSecond": "1.04",
                "timeLength": 1875,
                "features": [
                  {
                    "sampleYVal": 81,
                    "sampleYDrawVal": 562
                  },
                  {
                    "sampleYVal": 163,
                    "sampleYDrawVal": 2328
                  },
                  {
                    "sampleYVal": 188,
                    "sampleYDrawVal": 3334
                  },
                  {
                    "sampleYVal": 190,
                    "sampleYDrawVal": 3369
                  },
                  {
                    "sampleYVal": 193,
                    "sampleYDrawVal": 3527
                  },
                  {
                    "sampleYVal": 197,
                    "sampleYDrawVal": 3717
                  },
                  {
                    "sampleYVal": 185,
                    "sampleYDrawVal": 3230
                  },
                  {
                    "sampleYVal": 160,
                    "sampleYDrawVal": 2304
                  },
                  {
                    "sampleYVal": 145,
                    "sampleYDrawVal": 1857
                  },
                  {
                    "sampleYVal": 113,
                    "sampleYDrawVal": 1224
                  },
                  {
                    "sampleYVal": 87,
                    "sampleYDrawVal": 743
                  },
                  {
                    "sampleYVal": 74,
                    "sampleYDrawVal": 676
                  },
                  {
                    "sampleYVal": 46,
                    "sampleYDrawVal": 162
                  },
                  {
                    "sampleYVal": 15,
                    "sampleYDrawVal": 13
                  }
                ],
                "recordOffset": 16509,
                "seqUrl": "1527724273056_20633_709",
                "seqDescription": {
                  "analysis": {
                    "analysisType": "2sec"
                  },
                  "completeCount": 1,
                  "currentSeqPos": 1,
                  "pbId": "1211",
                  "text": "בָר ",
                  "type": "sentence",
                  "word": {
                    "id": 1207,
                    "language": "he",
                    "cleanInput": "דָּבָר",
                    "type": "word",
                    "data": "[{\"text\":\"\\u05d3\\u05bc\\u05b8\",\"sylType\":\"cv\",\"vowel\":\"A\",\"isPlosive\":true},{\"text\":\"\\u05d1\\u05b8\\u05e8\",\"sylType\":\"cvc\",\"vowel\":\"A\",\"isContinous\":true}]",
                    "isNonesense": 0,
                    "audios": ""
                  }
                },
                "mistakesObjects": {},
                "hiddenMistakesObjects": {}
              },
              {
                "fieldType": "seq",
                "timerPos": 836,
                "calibrationEventId": "6581",
                "rate": 85,
                "deltaTimeMsFromPrevSeq": "1090.00",
                "yvalues": [
                  "68",
                  "114",
                  "156",
                  "186",
                  "192",
                  "188",
                  "167",
                  "157",
                  "145",
                  "113",
                  "78",
                  "47",
                  "0"
                ],
                "yDrawvalues": "686,1412,2691,3569,3571,3275,2383,2206,1853,938,430,100,0",
                "mistakes": [
                  {
                    "sampleOffset": 12,
                    "mistakeId": 6
                  }
                ],
                "hiddenMistakes": [],
                "softVals": [
                  "1",
                  "1",
                  "1",
                  "0",
                  "0",
                  "0",
                  "1",
                  "1",
                  "1",
                  "1",
                  "1",
                  "1",
                  "1"
                ],
                "expectedSylsPerSecond": "0.00",
                "actualSylsPerSecond": "0.60",
                "timeLength": 1625,
                "features": [
                  {
                    "sampleYVal": 128,
                    "sampleYDrawVal": 1427
                  },
                  {
                    "sampleYVal": 135,
                    "sampleYDrawVal": 1719
                  },
                  {
                    "sampleYVal": 179,
                    "sampleYDrawVal": 3035
                  },
                  {
                    "sampleYVal": 195,
                    "sampleYDrawVal": 3629
                  },
                  {
                    "sampleYVal": 190,
                    "sampleYDrawVal": 3364
                  },
                  {
                    "sampleYVal": 168,
                    "sampleYDrawVal": 2515
                  },
                  {
                    "sampleYVal": 147,
                    "sampleYDrawVal": 1926
                  },
                  {
                    "sampleYVal": 146,
                    "sampleYDrawVal": 2007
                  },
                  {
                    "sampleYVal": 133,
                    "sampleYDrawVal": 1555
                  },
                  {
                    "sampleYVal": 75,
                    "sampleYDrawVal": 490
                  },
                  {
                    "sampleYVal": 58,
                    "sampleYDrawVal": 295
                  },
                  {
                    "sampleYVal": 36,
                    "sampleYDrawVal": 70
                  }
                ],
                "recordOffset": 19506,
                "seqUrl": "1527724273056_20633_709",
                "seqDescription": {
                  "analysis": {
                    "analysisType": "2sec"
                  },
                  "completeCount": 2,
                  "currentSeqPos": 0,
                  "pbId": "1211",
                  "text": "הָ ",
                  "type": "sentence",
                  "word": {
                    "id": 1208,
                    "language": "he",
                    "cleanInput": "הָעוֹמֵד",
                    "type": "word",
                    "data": "[{\"text\":\"\\u05d4\\u05b8\",\"sylType\":\"v\",\"vowel\":\"A\"},{\"text\":\"\\u05e2\\u05d5\\u05b9\",\"sylType\":\"v\",\"vowel\":\"O\"},{\"text\":\"\\u05de\\u05b5\\u05d3\",\"sylType\":\"cvc\",\"vowel\":\"E\",\"isContinous\":true,\"isPlosive\":true}]",
                    "isNonesense": 0,
                    "audios": ""
                  }
                },
                "mistakesObjects": {
                  "tooShort": {
                    "titlei18Key": "MISTAKES.TOO_SHORT"
                  }
                },
                "hiddenMistakesObjects": {}
              },
              {
                "fieldType": "seq",
                "timerPos": 839,
                "calibrationEventId": "6581",
                "rate": 85,
                "deltaTimeMsFromPrevSeq": "1091.00",
                "yvalues": [
                  "92",
                  "150",
                  "153",
                  "162",
                  "153",
                  "161",
                  "170",
                  "163",
                  "137",
                  "105",
                  "93",
                  "88",
                  "68",
                  "53",
                  "0"
                ],
                "yDrawvalues": "1145,2542,2322,2581,2407,2689,2881,2543,1719,1094,990,756,351,189,0",
                "mistakes": [
                  {
                    "sampleOffset": 6,
                    "mistakeId": 3
                  }
                ],
                "hiddenMistakes": [],
                "softVals": [
                  "1",
                  "1",
                  "1",
                  "1",
                  "1",
                  "1",
                  "1",
                  "1",
                  "1",
                  "1",
                  "1",
                  "1",
                  "1",
                  "1",
                  "1"
                ],
                "expectedSylsPerSecond": "0.00",
                "actualSylsPerSecond": "1.06",
                "timeLength": 1875,
                "features": [
                  {
                    "sampleYVal": 157,
                    "sampleYDrawVal": 2246
                  },
                  {
                    "sampleYVal": 168,
                    "sampleYDrawVal": 2651
                  },
                  {
                    "sampleYVal": 163,
                    "sampleYDrawVal": 2551
                  },
                  {
                    "sampleYVal": 160,
                    "sampleYDrawVal": 2393
                  },
                  {
                    "sampleYVal": 151,
                    "sampleYDrawVal": 2417
                  },
                  {
                    "sampleYVal": 168,
                    "sampleYDrawVal": 2783
                  },
                  {
                    "sampleYVal": 171,
                    "sampleYDrawVal": 2819
                  },
                  {
                    "sampleYVal": 155,
                    "sampleYDrawVal": 2297
                  },
                  {
                    "sampleYVal": 110,
                    "sampleYDrawVal": 1332
                  },
                  {
                    "sampleYVal": 92,
                    "sampleYDrawVal": 927
                  },
                  {
                    "sampleYVal": 90,
                    "sampleYDrawVal": 907
                  },
                  {
                    "sampleYVal": 81,
                    "sampleYDrawVal": 665
                  },
                  {
                    "sampleYVal": 52,
                    "sampleYDrawVal": 216
                  },
                  {
                    "sampleYVal": 39,
                    "sampleYDrawVal": 92
                  }
                ],
                "recordOffset": 22227,
                "seqUrl": "1527724273056_20633_709",
                "seqDescription": {
                  "analysis": {
                    "analysisType": "2sec"
                  },
                  "completeCount": 2,
                  "currentSeqPos": 1,
                  "pbId": "1211",
                  "text": "עוֹ ",
                  "type": "sentence",
                  "word": {
                    "id": 1208,
                    "language": "he",
                    "cleanInput": "הָעוֹמֵד",
                    "type": "word",
                    "data": "[{\"text\":\"\\u05d4\\u05b8\",\"sylType\":\"v\",\"vowel\":\"A\"},{\"text\":\"\\u05e2\\u05d5\\u05b9\",\"sylType\":\"v\",\"vowel\":\"O\"},{\"text\":\"\\u05de\\u05b5\\u05d3\",\"sylType\":\"cvc\",\"vowel\":\"E\",\"isContinous\":true,\"isPlosive\":true}]",
                    "isNonesense": 0,
                    "audios": ""
                  }
                },
                "mistakesObjects": {
                  "softPeak": {
                    "titlei18Key": "MISTAKES.SOFT_PEAK"
                  }
                },
                "hiddenMistakesObjects": {}
              },
              {
                "fieldType": "seq",
                "timerPos": 842,
                "calibrationEventId": "6581",
                "rate": 80,
                "deltaTimeMsFromPrevSeq": "1104.00",
                "yvalues": [
                  "91",
                  "146",
                  "182",
                  "197",
                  "205",
                  "208",
                  "209",
                  "205",
                  "190",
                  "172",
                  "146",
                  "113",
                  "93",
                  "60",
                  "0"
                ],
                "yDrawvalues": "1015,2358,3466,3863,4133,4250,4265,4021,3335,2542,1726,972,775,243,0",
                "mistakes": [
                  {
                    "sampleOffset": 2,
                    "mistakeId": 1
                  }
                ],
                "hiddenMistakes": [],
                "softVals": [
                  "1",
                  "1",
                  "0",
                  "0",
                  "0",
                  "0",
                  "0",
                  "0",
                  "0",
                  "0",
                  "1",
                  "1",
                  "1",
                  "1",
                  "1"
                ],
                "expectedSylsPerSecond": "0.00",
                "actualSylsPerSecond": "0.50",
                "timeLength": 1875,
                "features": [
                  {
                    "sampleYVal": 140,
                    "sampleYDrawVal": 1679
                  },
                  {
                    "sampleYVal": 183,
                    "sampleYDrawVal": 3122
                  },
                  {
                    "sampleYVal": 200,
                    "sampleYDrawVal": 3804
                  },
                  {
                    "sampleYVal": 202,
                    "sampleYDrawVal": 3927
                  },
                  {
                    "sampleYVal": 209,
                    "sampleYDrawVal": 4222
                  },
                  {
                    "sampleYVal": 210,
                    "sampleYDrawVal": 4295
                  },
                  {
                    "sampleYVal": 207,
                    "sampleYDrawVal": 4146
                  },
                  {
                    "sampleYVal": 201,
                    "sampleYDrawVal": 3901
                  },
                  {
                    "sampleYVal": 184,
                    "sampleYDrawVal": 3185
                  },
                  {
                    "sampleYVal": 155,
                    "sampleYDrawVal": 2085
                  },
                  {
                    "sampleYVal": 133,
                    "sampleYDrawVal": 1544
                  },
                  {
                    "sampleYVal": 103,
                    "sampleYDrawVal": 955
                  },
                  {
                    "sampleYVal": 62,
                    "sampleYDrawVal": 361
                  },
                  {
                    "sampleYVal": 36,
                    "sampleYDrawVal": 98
                  }
                ],
                "recordOffset": 25315,
                "seqUrl": "1527724273056_20633_709",
                "seqDescription": {
                  "analysis": {
                    "analysisType": "2sec"
                  },
                  "completeCount": 2,
                  "currentSeqPos": 2,
                  "pbId": "1211",
                  "text": "מֵד ",
                  "type": "sentence",
                  "word": {
                    "id": 1208,
                    "language": "he",
                    "cleanInput": "הָעוֹמֵד",
                    "type": "word",
                    "data": "[{\"text\":\"\\u05d4\\u05b8\",\"sylType\":\"v\",\"vowel\":\"A\"},{\"text\":\"\\u05e2\\u05d5\\u05b9\",\"sylType\":\"v\",\"vowel\":\"O\"},{\"text\":\"\\u05de\\u05b5\\u05d3\",\"sylType\":\"cvc\",\"vowel\":\"E\",\"isContinous\":true,\"isPlosive\":true}]",
                    "isNonesense": 0,
                    "audios": ""
                  }
                },
                "mistakesObjects": {
                  "gentleOnset": {
                    "titlei18Key": "MISTAKES.GENTLE_ONSET"
                  }
                },
                "hiddenMistakesObjects": {}
              },
              {
                "fieldType": "seq",
                "timerPos": 846,
                "calibrationEventId": "6581",
                "rate": 100,
                "deltaTimeMsFromPrevSeq": "1398.00",
                "yvalues": [
                  "104",
                  "137",
                  "161",
                  "172",
                  "184",
                  "188",
                  "186",
                  "182",
                  "169",
                  "152",
                  "133",
                  "105",
                  "84",
                  "50",
                  "0"
                ],
                "yDrawvalues": "1037,1881,2534,2801,3274,3360,3262,3064,2574,2073,1579,899,571,151,0",
                "mistakes": [],
                "hiddenMistakes": [],
                "softVals": [
                  "1",
                  "1",
                  "1",
                  "0",
                  "0",
                  "0",
                  "0",
                  "0",
                  "1",
                  "1",
                  "1",
                  "1",
                  "1",
                  "1",
                  "1"
                ],
                "expectedSylsPerSecond": "0.00",
                "actualSylsPerSecond": "0.53",
                "timeLength": 1875,
                "features": [
                  {
                    "sampleYVal": 134,
                    "sampleYDrawVal": 1471
                  },
                  {
                    "sampleYVal": 172,
                    "sampleYDrawVal": 2654
                  },
                  {
                    "sampleYVal": 169,
                    "sampleYDrawVal": 2563
                  },
                  {
                    "sampleYVal": 180,
                    "sampleYDrawVal": 3013
                  },
                  {
                    "sampleYVal": 190,
                    "sampleYDrawVal": 3386
                  },
                  {
                    "sampleYVal": 187,
                    "sampleYDrawVal": 3283
                  },
                  {
                    "sampleYVal": 186,
                    "sampleYDrawVal": 3250
                  },
                  {
                    "sampleYVal": 176,
                    "sampleYDrawVal": 2874
                  },
                  {
                    "sampleYVal": 156,
                    "sampleYDrawVal": 2261
                  },
                  {
                    "sampleYVal": 146,
                    "sampleYDrawVal": 1925
                  },
                  {
                    "sampleYVal": 104,
                    "sampleYDrawVal": 1001
                  },
                  {
                    "sampleYVal": 110,
                    "sampleYDrawVal": 1086
                  },
                  {
                    "sampleYVal": 53,
                    "sampleYDrawVal": 187
                  },
                  {
                    "sampleYVal": 43,
                    "sampleYDrawVal": 177
                  }
                ],
                "recordOffset": -1398,
                "seqUrl": "1527724303057_20633_709",
                "seqDescription": {
                  "analysis": {
                    "analysisType": "2sec"
                  },
                  "completeCount": 3,
                  "currentSeqPos": 0,
                  "pbId": "1211",
                  "text": "בִּפְ ",
                  "type": "sentence",
                  "word": {
                    "id": 1210,
                    "language": "he",
                    "cleanInput": "בִּפְנֵי",
                    "type": "word",
                    "data": "[{\"text\":\"\\u05d1\\u05bc\\u05b4\\u05e4\\u05b0\",\"sylType\":\"cvc\",\"vowel\":\"I\",\"isContinous\":true},{\"text\":\"\\u05e0\\u05b5\\u05d9\",\"sylType\":\"cvc\",\"vowel\":\"E\",\"isContinous\":true}]",
                    "isNonesense": 0,
                    "audios": ""
                  }
                },
                "mistakesObjects": {},
                "hiddenMistakesObjects": {}
              },
              {
                "fieldType": "seq",
                "timerPos": 849,
                "calibrationEventId": "6581",
                "rate": 85,
                "deltaTimeMsFromPrevSeq": "1244.00",
                "yvalues": [
                  "104",
                  "122",
                  "141",
                  "157",
                  "163",
                  "165",
                  "169",
                  "170",
                  "167",
                  "154",
                  "129",
                  "88",
                  "45",
                  "0"
                ],
                "yDrawvalues": "1245,1403,1874,2292,2468,2501,2686,2693,2647,2177,1410,497,65,0",
                "mistakes": [
                  {
                    "sampleOffset": 13,
                    "mistakeId": 6
                  }
                ],
                "hiddenMistakes": [],
                "softVals": [
                  "1",
                  "1",
                  "1",
                  "1",
                  "1",
                  "1",
                  "1",
                  "1",
                  "1",
                  "1",
                  "1",
                  "1",
                  "1",
                  "1"
                ],
                "expectedSylsPerSecond": "0.00",
                "actualSylsPerSecond": "0.56",
                "timeLength": 1750,
                "features": [
                  {
                    "sampleYVal": 116,
                    "sampleYDrawVal": 1193
                  },
                  {
                    "sampleYVal": 131,
                    "sampleYDrawVal": 1523
                  },
                  {
                    "sampleYVal": 153,
                    "sampleYDrawVal": 2040
                  },
                  {
                    "sampleYVal": 162,
                    "sampleYDrawVal": 2314
                  },
                  {
                    "sampleYVal": 174,
                    "sampleYDrawVal": 2768
                  },
                  {
                    "sampleYVal": 164,
                    "sampleYDrawVal": 2423
                  },
                  {
                    "sampleYVal": 171,
                    "sampleYDrawVal": 2662
                  },
                  {
                    "sampleYVal": 176,
                    "sampleYDrawVal": 2884
                  },
                  {
                    "sampleYVal": 165,
                    "sampleYDrawVal": 2584
                  },
                  {
                    "sampleYVal": 135,
                    "sampleYDrawVal": 1668
                  },
                  {
                    "sampleYVal": 89,
                    "sampleYDrawVal": 747
                  },
                  {
                    "sampleYVal": 49,
                    "sampleYDrawVal": 135
                  },
                  {
                    "sampleYVal": 24,
                    "sampleYDrawVal": 29
                  }
                ],
                "recordOffset": 1758,
                "seqUrl": "1527724303057_20633_709",
                "seqDescription": {
                  "analysis": {
                    "analysisType": "2sec"
                  },
                  "completeCount": 3,
                  "currentSeqPos": 1,
                  "pbId": "1211",
                  "text": "נֵי ",
                  "type": "sentence",
                  "word": {
                    "id": 1210,
                    "language": "he",
                    "cleanInput": "בִּפְנֵי",
                    "type": "word",
                    "data": "[{\"text\":\"\\u05d1\\u05bc\\u05b4\\u05e4\\u05b0\",\"sylType\":\"cvc\",\"vowel\":\"I\",\"isContinous\":true},{\"text\":\"\\u05e0\\u05b5\\u05d9\",\"sylType\":\"cvc\",\"vowel\":\"E\",\"isContinous\":true}]",
                    "isNonesense": 0,
                    "audios": ""
                  }
                },
                "mistakesObjects": {
                  "tooShort": {
                    "titlei18Key": "MISTAKES.TOO_SHORT"
                  }
                },
                "hiddenMistakesObjects": {}
              },
              {
                "fieldType": "seq",
                "timerPos": 852,
                "calibrationEventId": "6581",
                "rate": 100,
                "deltaTimeMsFromPrevSeq": "1220.00",
                "yvalues": [
                  "75",
                  "119",
                  "156",
                  "184",
                  "186",
                  "188",
                  "191",
                  "194",
                  "192",
                  "186",
                  "156",
                  "141",
                  "118",
                  "108",
                  "96",
                  "72",
                  "41",
                  "0"
                ],
                "yDrawvalues": "676,1393,2508,3445,3224,3311,3442,3559,3392,3152,2001,1665,1102,996,789,406,61,0",
                "mistakes": [],
                "hiddenMistakes": [],
                "softVals": [
                  "1",
                  "1",
                  "1",
                  "0",
                  "0",
                  "0",
                  "0",
                  "0",
                  "0",
                  "0",
                  "1",
                  "1",
                  "1",
                  "1",
                  "1",
                  "1",
                  "1",
                  "1"
                ],
                "expectedSylsPerSecond": "0.00",
                "actualSylsPerSecond": "0.42",
                "timeLength": 2250,
                "features": [
                  {
                    "sampleYVal": 126,
                    "sampleYDrawVal": 1265
                  },
                  {
                    "sampleYVal": 131,
                    "sampleYDrawVal": 1448
                  },
                  {
                    "sampleYVal": 189,
                    "sampleYDrawVal": 3330
                  },
                  {
                    "sampleYVal": 194,
                    "sampleYDrawVal": 3546
                  },
                  {
                    "sampleYVal": 181,
                    "sampleYDrawVal": 2997
                  },
                  {
                    "sampleYVal": 185,
                    "sampleYDrawVal": 3132
                  },
                  {
                    "sampleYVal": 194,
                    "sampleYDrawVal": 3486
                  },
                  {
                    "sampleYVal": 198,
                    "sampleYDrawVal": 3673
                  },
                  {
                    "sampleYVal": 187,
                    "sampleYDrawVal": 3195
                  },
                  {
                    "sampleYVal": 175,
                    "sampleYDrawVal": 2779
                  },
                  {
                    "sampleYVal": 147,
                    "sampleYDrawVal": 1888
                  },
                  {
                    "sampleYVal": 127,
                    "sampleYDrawVal": 1421
                  },
                  {
                    "sampleYVal": 103,
                    "sampleYDrawVal": 889
                  },
                  {
                    "sampleYVal": 86,
                    "sampleYDrawVal": 728
                  },
                  {
                    "sampleYVal": 84,
                    "sampleYDrawVal": 645
                  },
                  {
                    "sampleYVal": 61,
                    "sampleYDrawVal": 271
                  },
                  {
                    "sampleYVal": 24,
                    "sampleYDrawVal": 26
                  }
                ],
                "recordOffset": 4837,
                "seqUrl": "1527724303057_20633_709",
                "seqDescription": {
                  "analysis": {
                    "analysisType": "2sec"
                  },
                  "completeCount": 4,
                  "currentSeqPos": 0,
                  "pbId": "1211",
                  "text": "הָ ",
                  "type": "sentence",
                  "word": {
                    "id": 1209,
                    "language": "he",
                    "cleanInput": "הָרָצוֹן",
                    "type": "word",
                    "data": "[{\"text\":\"\\u05d4\\u05b8\",\"sylType\":\"v\",\"vowel\":\"A\"},{\"text\":\"\\u05e8\\u05b8\",\"sylType\":\"cv\",\"vowel\":\"A\",\"isContinous\":true},{\"text\":\"\\u05e6\\u05d5\\u05b9\\u05df\",\"sylType\":\"cvc\",\"vowel\":\"O\",\"isContinous\":true}]",
                    "isNonesense": 0,
                    "audios": ""
                  }
                },
                "mistakesObjects": {},
                "hiddenMistakesObjects": {}
              },
              {
                "fieldType": "seq",
                "timerPos": 855,
                "calibrationEventId": "6581",
                "rate": 80,
                "deltaTimeMsFromPrevSeq": "1120.00",
                "yvalues": [
                  "133",
                  "174",
                  "179",
                  "181",
                  "185",
                  "177",
                  "176",
                  "181",
                  "178",
                  "169",
                  "145",
                  "115",
                  "71",
                  "39",
                  "0"
                ],
                "yDrawvalues": "2071,3165,2972,3022,3182,2825,2827,3075,2850,2497,1765,1115,327,76,0",
                "mistakes": [
                  {
                    "sampleOffset": 1,
                    "mistakeId": 1
                  }
                ],
                "hiddenMistakes": [],
                "softVals": [
                  "1",
                  "0",
                  "0",
                  "0",
                  "0",
                  "0",
                  "0",
                  "0",
                  "0",
                  "1",
                  "1",
                  "1",
                  "1",
                  "1",
                  "1"
                ],
                "expectedSylsPerSecond": "0.00",
                "actualSylsPerSecond": "1.04",
                "timeLength": 1875,
                "features": [
                  {
                    "sampleYVal": 177,
                    "sampleYDrawVal": 2856
                  },
                  {
                    "sampleYVal": 183,
                    "sampleYDrawVal": 3043
                  },
                  {
                    "sampleYVal": 180,
                    "sampleYDrawVal": 2932
                  },
                  {
                    "sampleYVal": 185,
                    "sampleYDrawVal": 3163
                  },
                  {
                    "sampleYVal": 184,
                    "sampleYDrawVal": 3102
                  },
                  {
                    "sampleYVal": 172,
                    "sampleYDrawVal": 2697
                  },
                  {
                    "sampleYVal": 180,
                    "sampleYDrawVal": 2970
                  },
                  {
                    "sampleYVal": 186,
                    "sampleYDrawVal": 3189
                  },
                  {
                    "sampleYVal": 174,
                    "sampleYDrawVal": 2731
                  },
                  {
                    "sampleYVal": 156,
                    "sampleYDrawVal": 2150
                  },
                  {
                    "sampleYVal": 136,
                    "sampleYDrawVal": 1675
                  },
                  {
                    "sampleYVal": 96,
                    "sampleYDrawVal": 874
                  },
                  {
                    "sampleYVal": 47,
                    "sampleYDrawVal": 135
                  },
                  {
                    "sampleYVal": 18,
                    "sampleYDrawVal": 21
                  }
                ],
                "recordOffset": 8252,
                "seqUrl": "1527724303057_20633_709",
                "seqDescription": {
                  "analysis": {
                    "analysisType": "2sec"
                  },
                  "completeCount": 4,
                  "currentSeqPos": 1,
                  "pbId": "1211",
                  "text": "רָ ",
                  "type": "sentence",
                  "word": {
                    "id": 1209,
                    "language": "he",
                    "cleanInput": "הָרָצוֹן",
                    "type": "word",
                    "data": "[{\"text\":\"\\u05d4\\u05b8\",\"sylType\":\"v\",\"vowel\":\"A\"},{\"text\":\"\\u05e8\\u05b8\",\"sylType\":\"cv\",\"vowel\":\"A\",\"isContinous\":true},{\"text\":\"\\u05e6\\u05d5\\u05b9\\u05df\",\"sylType\":\"cvc\",\"vowel\":\"O\",\"isContinous\":true}]",
                    "isNonesense": 0,
                    "audios": ""
                  }
                },
                "mistakesObjects": {
                  "gentleOnset": {
                    "titlei18Key": "MISTAKES.GENTLE_ONSET"
                  }
                },
                "hiddenMistakesObjects": {}
              },
              {
                "fieldType": "seq",
                "timerPos": 859,
                "calibrationEventId": "6581",
                "rate": 80,
                "deltaTimeMsFromPrevSeq": "1211.00",
                "yvalues": [
                  "73",
                  "114",
                  "145",
                  "168",
                  "181",
                  "180",
                  "180",
                  "188",
                  "194",
                  "189",
                  "184",
                  "159",
                  "131",
                  "113",
                  "84",
                  "57",
                  "0"
                ],
                "yDrawvalues": "725,1426,2209,2984,3311,3109,3161,3450,3696,3406,3182,2313,1592,1183,665,170,0",
                "mistakes": [
                  {
                    "sampleOffset": 4,
                    "mistakeId": 5
                  }
                ],
                "hiddenMistakes": [
                  {
                    "sampleOffset": 16,
                    "mistakeId": 7
                  }
                ],
                "softVals": [
                  "1",
                  "1",
                  "1",
                  "1",
                  "0",
                  "0",
                  "0",
                  "0",
                  "0",
                  "0",
                  "0",
                  "1",
                  "1",
                  "1",
                  "1",
                  "1",
                  "1"
                ],
                "expectedSylsPerSecond": "0.00",
                "actualSylsPerSecond": "0.46",
                "timeLength": 2125,
                "features": [
                  {
                    "sampleYVal": 114,
                    "sampleYDrawVal": 1142
                  },
                  {
                    "sampleYVal": 141,
                    "sampleYDrawVal": 1762
                  },
                  {
                    "sampleYVal": 160,
                    "sampleYDrawVal": 2482
                  },
                  {
                    "sampleYVal": 172,
                    "sampleYDrawVal": 2984
                  },
                  {
                    "sampleYVal": 184,
                    "sampleYDrawVal": 3248
                  },
                  {
                    "sampleYVal": 180,
                    "sampleYDrawVal": 3138
                  },
                  {
                    "sampleYVal": 183,
                    "sampleYDrawVal": 3222
                  },
                  {
                    "sampleYVal": 190,
                    "sampleYDrawVal": 3433
                  },
                  {
                    "sampleYVal": 196,
                    "sampleYDrawVal": 3682
                  },
                  {
                    "sampleYVal": 182,
                    "sampleYDrawVal": 3140
                  },
                  {
                    "sampleYVal": 178,
                    "sampleYDrawVal": 2985
                  },
                  {
                    "sampleYVal": 129,
                    "sampleYDrawVal": 1810
                  },
                  {
                    "sampleYVal": 135,
                    "sampleYDrawVal": 1690
                  },
                  {
                    "sampleYVal": 113,
                    "sampleYDrawVal": 1178
                  },
                  {
                    "sampleYVal": 64,
                    "sampleYDrawVal": 382
                  },
                  {
                    "sampleYVal": 49,
                    "sampleYDrawVal": 149
                  }
                ],
                "recordOffset": 11394,
                "seqUrl": "1527724303057_20633_709",
                "seqDescription": {
                  "analysis": {
                    "analysisType": "2sec"
                  },
                  "completeCount": 4,
                  "currentSeqPos": 2,
                  "pbId": "1211",
                  "text": "צוֹן ",
                  "type": "sentence",
                  "word": {
                    "id": 1209,
                    "language": "he",
                    "cleanInput": "הָרָצוֹן",
                    "type": "word",
                    "data": "[{\"text\":\"\\u05d4\\u05b8\",\"sylType\":\"v\",\"vowel\":\"A\"},{\"text\":\"\\u05e8\\u05b8\",\"sylType\":\"cv\",\"vowel\":\"A\",\"isContinous\":true},{\"text\":\"\\u05e6\\u05d5\\u05b9\\u05df\",\"sylType\":\"cvc\",\"vowel\":\"O\",\"isContinous\":true}]",
                    "isNonesense": 0,
                    "audios": ""
                  }
                },
                "mistakesObjects": {
                  "volControlUp": {
                    "titlei18Key": "MISTAKES.VOLCONTROL_UP"
                  }
                },
                "hiddenMistakesObjects": {
                  "tooLong": {
                    "titlei18Key": "MISTAKES.TOO_LONG"
                  }
                }
              },
              {
                "fieldType": "seq",
                "timerPos": 862,
                "calibrationEventId": "6581",
                "rate": 100,
                "deltaTimeMsFromPrevSeq": "1688.00",
                "yvalues": [
                  "69",
                  "120",
                  "144",
                  "164",
                  "173",
                  "178",
                  "180",
                  "174",
                  "169",
                  "152",
                  "126",
                  "111",
                  "102",
                  "98",
                  "74",
                  "36",
                  "0"
                ],
                "yDrawvalues": "732,1523,2040,2615,2806,2942,2999,2726,2602,1935,1262,1027,946,927,450,44,0",
                "mistakes": [],
                "hiddenMistakes": [],
                "softVals": [
                  "1",
                  "1",
                  "1",
                  "1",
                  "0",
                  "0",
                  "0",
                  "0",
                  "1",
                  "1",
                  "1",
                  "1",
                  "1",
                  "1",
                  "1",
                  "1",
                  "1"
                ],
                "expectedSylsPerSecond": "0.00",
                "actualSylsPerSecond": "0.46",
                "timeLength": 2125,
                "features": [
                  {
                    "sampleYVal": 123,
                    "sampleYDrawVal": 1228
                  },
                  {
                    "sampleYVal": 147,
                    "sampleYDrawVal": 1836
                  },
                  {
                    "sampleYVal": 158,
                    "sampleYDrawVal": 2280
                  },
                  {
                    "sampleYVal": 175,
                    "sampleYDrawVal": 2804
                  },
                  {
                    "sampleYVal": 175,
                    "sampleYDrawVal": 2805
                  },
                  {
                    "sampleYVal": 183,
                    "sampleYDrawVal": 3056
                  },
                  {
                    "sampleYVal": 177,
                    "sampleYDrawVal": 2845
                  },
                  {
                    "sampleYVal": 162,
                    "sampleYDrawVal": 2382
                  },
                  {
                    "sampleYVal": 161,
                    "sampleYDrawVal": 2335
                  },
                  {
                    "sampleYVal": 146,
                    "sampleYDrawVal": 1843
                  },
                  {
                    "sampleYVal": 120,
                    "sampleYDrawVal": 1245
                  },
                  {
                    "sampleYVal": 102,
                    "sampleYDrawVal": 897
                  },
                  {
                    "sampleYVal": 98,
                    "sampleYDrawVal": 897
                  },
                  {
                    "sampleYVal": 98,
                    "sampleYDrawVal": 974
                  },
                  {
                    "sampleYVal": 41,
                    "sampleYDrawVal": 97
                  },
                  {
                    "sampleYVal": 23,
                    "sampleYDrawVal": 27
                  }
                ],
                "recordOffset": 15253,
                "seqUrl": "1527724303057_20633_709",
                "seqDescription": {
                  "analysis": {
                    "analysisType": "2sec"
                  },
                  "completeCount": 0,
                  "currentSeqPos": 0,
                  "pbId": "1298",
                  "text": "תִּרְ ",
                  "type": "sentence",
                  "word": {
                    "id": 1284,
                    "language": "he",
                    "cleanInput": "תִּרְאוּ",
                    "type": "word",
                    "data": "[{\"text\":\"\\u05ea\\u05bc\\u05b4\\u05e8\\u05b0\",\"sylType\":\"cvc\",\"vowel\":\"I\",\"isContinous\":true,\"isPlosive\":true},{\"text\":\"\\u05d0\\u05d5\\u05bc\",\"sylType\":\"v\",\"vowel\":\"U\"}]",
                    "isNonesense": 0,
                    "audios": ""
                  }
                },
                "mistakesObjects": {},
                "hiddenMistakesObjects": {}
              },
              {
                "fieldType": "seq",
                "timerPos": 869,
                "calibrationEventId": "6581",
                "rate": 80,
                "deltaTimeMsFromPrevSeq": "1281.00",
                "yvalues": [
                  "94",
                  "148",
                  "179",
                  "181",
                  "177",
                  "173",
                  "173",
                  "171",
                  "158",
                  "138",
                  "128",
                  "124",
                  "112",
                  "96",
                  "97",
                  "49",
                  "0"
                ],
                "yDrawvalues": "1295,2456,3355,3194,3157,3018,3015,2879,2360,1864,1602,1504,1191,858,1016,137,0",
                "mistakes": [
                  {
                    "sampleOffset": 2,
                    "mistakeId": 1
                  }
                ],
                "hiddenMistakes": [],
                "softVals": [
                  "1",
                  "1",
                  "0",
                  "0",
                  "0",
                  "0",
                  "0",
                  "0",
                  "1",
                  "1",
                  "1",
                  "1",
                  "1",
                  "1",
                  "1",
                  "1",
                  "1"
                ],
                "expectedSylsPerSecond": "0.00",
                "actualSylsPerSecond": "0.46",
                "timeLength": 2125,
                "features": [
                  {
                    "sampleYVal": 127,
                    "sampleYDrawVal": 1437
                  },
                  {
                    "sampleYVal": 176,
                    "sampleYDrawVal": 3006
                  },
                  {
                    "sampleYVal": 176,
                    "sampleYDrawVal": 2896
                  },
                  {
                    "sampleYVal": 174,
                    "sampleYDrawVal": 3064
                  },
                  {
                    "sampleYVal": 178,
                    "sampleYDrawVal": 3145
                  },
                  {
                    "sampleYVal": 170,
                    "sampleYDrawVal": 2937
                  },
                  {
                    "sampleYVal": 171,
                    "sampleYDrawVal": 2894
                  },
                  {
                    "sampleYVal": 167,
                    "sampleYDrawVal": 2738
                  },
                  {
                    "sampleYVal": 147,
                    "sampleYDrawVal": 2149
                  },
                  {
                    "sampleYVal": 120,
                    "sampleYDrawVal": 1508
                  },
                  {
                    "sampleYVal": 132,
                    "sampleYDrawVal": 1745
                  },
                  {
                    "sampleYVal": 116,
                    "sampleYDrawVal": 1316
                  },
                  {
                    "sampleYVal": 89,
                    "sampleYDrawVal": 729
                  },
                  {
                    "sampleYVal": 96,
                    "sampleYDrawVal": 953
                  },
                  {
                    "sampleYVal": 82,
                    "sampleYDrawVal": 715
                  },
                  {
                    "sampleYVal": 37,
                    "sampleYDrawVal": 88
                  }
                ],
                "recordOffset": 21844,
                "seqUrl": "1527724303057_20633_709",
                "seqDescription": {
                  "analysis": {
                    "analysisType": "2sec"
                  },
                  "completeCount": 0,
                  "currentSeqPos": 1,
                  "pbId": "1298",
                  "text": "אוּ ",
                  "type": "sentence",
                  "word": {
                    "id": 1284,
                    "language": "he",
                    "cleanInput": "תִּרְאוּ",
                    "type": "word",
                    "data": "[{\"text\":\"\\u05ea\\u05bc\\u05b4\\u05e8\\u05b0\",\"sylType\":\"cvc\",\"vowel\":\"I\",\"isContinous\":true,\"isPlosive\":true},{\"text\":\"\\u05d0\\u05d5\\u05bc\",\"sylType\":\"v\",\"vowel\":\"U\"}]",
                    "isNonesense": 0,
                    "audios": ""
                  }
                },
                "mistakesObjects": {
                  "gentleOnset": {
                    "titlei18Key": "MISTAKES.GENTLE_ONSET"
                  }
                },
                "hiddenMistakesObjects": {}
              },
              {
                "fieldType": "seq",
                "timerPos": 872,
                "calibrationEventId": "6581",
                "rate": 100,
                "deltaTimeMsFromPrevSeq": "1093.00",
                "yvalues": [
                  "70",
                  "105",
                  "140",
                  "163",
                  "168",
                  "170",
                  "176",
                  "177",
                  "172",
                  "164",
                  "148",
                  "110",
                  "78",
                  "73",
                  "42",
                  "0"
                ],
                "yDrawvalues": "893,1287,2209,2762,2803,2808,3082,3048,2774,2503,1946,1051,693,735,123,0",
                "mistakes": [],
                "hiddenMistakes": [],
                "softVals": [
                  "1",
                  "1",
                  "1",
                  "1",
                  "1",
                  "1",
                  "0",
                  "0",
                  "0",
                  "1",
                  "1",
                  "1",
                  "1",
                  "1",
                  "1",
                  "1"
                ],
                "expectedSylsPerSecond": "0.00",
                "actualSylsPerSecond": "0.49",
                "timeLength": 2000,
                "features": [
                  {
                    "sampleYVal": 118,
                    "sampleYDrawVal": 1489
                  },
                  {
                    "sampleYVal": 125,
                    "sampleYDrawVal": 1384
                  },
                  {
                    "sampleYVal": 161,
                    "sampleYDrawVal": 2580
                  },
                  {
                    "sampleYVal": 176,
                    "sampleYDrawVal": 2928
                  },
                  {
                    "sampleYVal": 165,
                    "sampleYDrawVal": 2666
                  },
                  {
                    "sampleYVal": 173,
                    "sampleYDrawVal": 2786
                  },
                  {
                    "sampleYVal": 181,
                    "sampleYDrawVal": 3192
                  },
                  {
                    "sampleYVal": 177,
                    "sampleYDrawVal": 3033
                  },
                  {
                    "sampleYVal": 170,
                    "sampleYDrawVal": 2751
                  },
                  {
                    "sampleYVal": 158,
                    "sampleYDrawVal": 2300
                  },
                  {
                    "sampleYVal": 126,
                    "sampleYDrawVal": 1425
                  },
                  {
                    "sampleYVal": 89,
                    "sampleYDrawVal": 777
                  },
                  {
                    "sampleYVal": 75,
                    "sampleYDrawVal": 795
                  },
                  {
                    "sampleYVal": 59,
                    "sampleYDrawVal": 544
                  },
                  {
                    "sampleYVal": 21,
                    "sampleYDrawVal": 20
                  }
                ],
                "recordOffset": 25108,
                "seqUrl": "1527724303057_20633_709",
                "seqDescription": {
                  "analysis": {
                    "analysisType": "2sec"
                  },
                  "completeCount": 1,
                  "currentSeqPos": 0,
                  "pbId": "1298",
                  "text": "אוֹ ",
                  "type": "sentence",
                  "word": {
                    "id": 1294,
                    "language": "he",
                    "cleanInput": "אוֹתִי",
                    "type": "word",
                    "data": "[{\"text\":\"\\u05d0\\u05d5\\u05b9\",\"sylType\":\"v\",\"vowel\":\"O\"},{\"text\":\"\\u05ea\\u05b4\\u05d9\",\"sylType\":\"cv\",\"vowel\":\"I\",\"isPlosive\":true}]",
                    "isNonesense": 0,
                    "audios": ""
                  }
                },
                "mistakesObjects": {},
                "hiddenMistakesObjects": {}
              },
              {
                "fieldType": "seq",
                "timerPos": 876,
                "calibrationEventId": "6581",
                "rate": 100,
                "deltaTimeMsFromPrevSeq": "1539.00",
                "yvalues": [
                  "95",
                  "133",
                  "152",
                  "165",
                  "172",
                  "185",
                  "190",
                  "179",
                  "165",
                  "135",
                  "119",
                  "92",
                  "78",
                  "45",
                  "0"
                ],
                "yDrawvalues": "1085,1838,2298,2652,2822,3392,3484,2869,2412,1519,1110,646,611,116,0",
                "mistakes": [],
                "hiddenMistakes": [],
                "softVals": [
                  "1",
                  "1",
                  "1",
                  "1",
                  "0",
                  "0",
                  "0",
                  "0",
                  "1",
                  "1",
                  "1",
                  "1",
                  "1",
                  "1",
                  "1"
                ],
                "expectedSylsPerSecond": "0.00",
                "actualSylsPerSecond": "0.52",
                "timeLength": 1875,
                "features": [
                  {
                    "sampleYVal": 121,
                    "sampleYDrawVal": 1318
                  },
                  {
                    "sampleYVal": 148,
                    "sampleYDrawVal": 2045
                  },
                  {
                    "sampleYVal": 160,
                    "sampleYDrawVal": 2356
                  },
                  {
                    "sampleYVal": 172,
                    "sampleYDrawVal": 2734
                  },
                  {
                    "sampleYVal": 178,
                    "sampleYDrawVal": 2959
                  },
                  {
                    "sampleYVal": 193,
                    "sampleYDrawVal": 3550
                  },
                  {
                    "sampleYVal": 191,
                    "sampleYDrawVal": 3389
                  },
                  {
                    "sampleYVal": 178,
                    "sampleYDrawVal": 2868
                  },
                  {
                    "sampleYVal": 151,
                    "sampleYDrawVal": 2129
                  },
                  {
                    "sampleYVal": 106,
                    "sampleYDrawVal": 978
                  },
                  {
                    "sampleYVal": 110,
                    "sampleYDrawVal": 967
                  },
                  {
                    "sampleYVal": 97,
                    "sampleYDrawVal": 850
                  },
                  {
                    "sampleYVal": 60,
                    "sampleYDrawVal": 452
                  },
                  {
                    "sampleYVal": 29,
                    "sampleYDrawVal": 43
                  }
                ],
                "recordOffset": -1307,
                "seqUrl": "1527724333057_20633_709",
                "seqDescription": {
                  "analysis": {
                    "analysisType": "2sec"
                  },
                  "completeCount": 1,
                  "currentSeqPos": 1,
                  "pbId": "1298",
                  "text": "תִי ",
                  "type": "sentence",
                  "word": {
                    "id": 1294,
                    "language": "he",
                    "cleanInput": "אוֹתִי",
                    "type": "word",
                    "data": "[{\"text\":\"\\u05d0\\u05d5\\u05b9\",\"sylType\":\"v\",\"vowel\":\"O\"},{\"text\":\"\\u05ea\\u05b4\\u05d9\",\"sylType\":\"cv\",\"vowel\":\"I\",\"isPlosive\":true}]",
                    "isNonesense": 0,
                    "audios": ""
                  }
                },
                "mistakesObjects": {},
                "hiddenMistakesObjects": {}
              },
              {
                "fieldType": "seq",
                "timerPos": 879,
                "calibrationEventId": "6581",
                "rate": 100,
                "deltaTimeMsFromPrevSeq": "1210.00",
                "yvalues": [
                  "81",
                  "127",
                  "156",
                  "167",
                  "181",
                  "186",
                  "193",
                  "202",
                  "207",
                  "198",
                  "163",
                  "144",
                  "124",
                  "112",
                  "77",
                  "47",
                  "0"
                ],
                "yDrawvalues": "983,1647,2488,2687,3170,3310,3616,4031,4183,3648,2205,1675,1292,1135,461,92,0",
                "mistakes": [],
                "hiddenMistakes": [],
                "softVals": [
                  "1",
                  "1",
                  "1",
                  "1",
                  "0",
                  "0",
                  "0",
                  "0",
                  "0",
                  "0",
                  "1",
                  "1",
                  "1",
                  "1",
                  "1",
                  "1",
                  "1"
                ],
                "expectedSylsPerSecond": "0.00",
                "actualSylsPerSecond": "0.45",
                "timeLength": 2125,
                "features": [
                  {
                    "sampleYVal": 123,
                    "sampleYDrawVal": 1267
                  },
                  {
                    "sampleYVal": 155,
                    "sampleYDrawVal": 2145
                  },
                  {
                    "sampleYVal": 170,
                    "sampleYDrawVal": 2658
                  },
                  {
                    "sampleYVal": 177,
                    "sampleYDrawVal": 2887
                  },
                  {
                    "sampleYVal": 189,
                    "sampleYDrawVal": 3300
                  },
                  {
                    "sampleYVal": 191,
                    "sampleYDrawVal": 3423
                  },
                  {
                    "sampleYVal": 197,
                    "sampleYDrawVal": 3668
                  },
                  {
                    "sampleYVal": 208,
                    "sampleYDrawVal": 4147
                  },
                  {
                    "sampleYVal": 212,
                    "sampleYDrawVal": 4322
                  },
                  {
                    "sampleYVal": 174,
                    "sampleYDrawVal": 2844
                  },
                  {
                    "sampleYVal": 150,
                    "sampleYDrawVal": 1987
                  },
                  {
                    "sampleYVal": 129,
                    "sampleYDrawVal": 1453
                  },
                  {
                    "sampleYVal": 125,
                    "sampleYDrawVal": 1419
                  },
                  {
                    "sampleYVal": 91,
                    "sampleYDrawVal": 771
                  },
                  {
                    "sampleYVal": 50,
                    "sampleYDrawVal": 183
                  },
                  {
                    "sampleYVal": 35,
                    "sampleYDrawVal": 58
                  }
                ],
                "recordOffset": 1851,
                "seqUrl": "1527724333057_20633_709",
                "seqDescription": {
                  "analysis": {
                    "analysisType": "2sec"
                  },
                  "completeCount": 2,
                  "currentSeqPos": 0,
                  "pbId": "1298",
                  "text": "אָ ",
                  "type": "sentence",
                  "word": {
                    "id": 1264,
                    "language": "he",
                    "cleanInput": "אָנִי",
                    "type": "word",
                    "data": "[{\"text\":\"\\u05d0\\u05b8\",\"sylType\":\"v\",\"vowel\":\"A\"},{\"text\":\"\\u05e0\\u05b4\\u05d9\",\"sylType\":\"cv\",\"vowel\":\"I\",\"isContinous\":true}]",
                    "isNonesense": 0,
                    "audios": ""
                  }
                },
                "mistakesObjects": {},
                "hiddenMistakesObjects": {}
              },
              {
                "fieldType": "seq",
                "timerPos": 883,
                "calibrationEventId": "6581",
                "rate": 100,
                "deltaTimeMsFromPrevSeq": "1340.00",
                "yvalues": [
                  "87",
                  "118",
                  "147",
                  "168",
                  "181",
                  "184",
                  "190",
                  "195",
                  "195",
                  "191",
                  "165",
                  "136",
                  "126",
                  "108",
                  "65",
                  "40",
                  "0"
                ],
                "yDrawvalues": "880,1415,2142,2760,3222,3277,3591,3758,3714,3454,2281,1490,1447,1063,278,66,0",
                "mistakes": [],
                "hiddenMistakes": [],
                "softVals": [
                  "1",
                  "1",
                  "1",
                  "1",
                  "0",
                  "0",
                  "0",
                  "0",
                  "0",
                  "0",
                  "1",
                  "1",
                  "1",
                  "1",
                  "1",
                  "1",
                  "1"
                ],
                "expectedSylsPerSecond": "0.00",
                "actualSylsPerSecond": "0.46",
                "timeLength": 2125,
                "features": [
                  {
                    "sampleYVal": 122,
                    "sampleYDrawVal": 1328
                  },
                  {
                    "sampleYVal": 137,
                    "sampleYDrawVal": 1637
                  },
                  {
                    "sampleYVal": 164,
                    "sampleYDrawVal": 2395
                  },
                  {
                    "sampleYVal": 178,
                    "sampleYDrawVal": 2926
                  },
                  {
                    "sampleYVal": 185,
                    "sampleYDrawVal": 3264
                  },
                  {
                    "sampleYVal": 188,
                    "sampleYDrawVal": 3391
                  },
                  {
                    "sampleYVal": 198,
                    "sampleYDrawVal": 3798
                  },
                  {
                    "sampleYVal": 203,
                    "sampleYDrawVal": 3972
                  },
                  {
                    "sampleYVal": 198,
                    "sampleYDrawVal": 3803
                  },
                  {
                    "sampleYVal": 181,
                    "sampleYDrawVal": 3070
                  },
                  {
                    "sampleYVal": 146,
                    "sampleYDrawVal": 1919
                  },
                  {
                    "sampleYVal": 117,
                    "sampleYDrawVal": 1231
                  },
                  {
                    "sampleYVal": 141,
                    "sampleYDrawVal": 1763
                  },
                  {
                    "sampleYVal": 80,
                    "sampleYDrawVal": 714
                  },
                  {
                    "sampleYVal": 44,
                    "sampleYDrawVal": 167
                  },
                  {
                    "sampleYVal": 24,
                    "sampleYDrawVal": 25
                  }
                ],
                "recordOffset": 5381,
                "seqUrl": "1527724333057_20633_709",
                "seqDescription": {
                  "analysis": {
                    "analysisType": "2sec"
                  },
                  "completeCount": 2,
                  "currentSeqPos": 1,
                  "pbId": "1298",
                  "text": "נִי ",
                  "type": "sentence",
                  "word": {
                    "id": 1264,
                    "language": "he",
                    "cleanInput": "אָנִי",
                    "type": "word",
                    "data": "[{\"text\":\"\\u05d0\\u05b8\",\"sylType\":\"v\",\"vowel\":\"A\"},{\"text\":\"\\u05e0\\u05b4\\u05d9\",\"sylType\":\"cv\",\"vowel\":\"I\",\"isContinous\":true}]",
                    "isNonesense": 0,
                    "audios": ""
                  }
                },
                "mistakesObjects": {},
                "hiddenMistakesObjects": {}
              }
            ],
            "results": {
              "fieldType": "results",
              "woutActivityId": "20633",
              "woutIndex": "2",
              "userId": "709",
              "timerPos": 883,
              "woutActivityShardGuid": "6064067ff4e236781cb0d0e0bb3166c05e7b6f43",
              "wout": {
                "levelIndex": "26",
                "partIndex": 3,
                "part": {
                  "filterSettings": [
                    {
                      "filter": {
                        "lang": "he",
                        "selectedBank": "sentence",
                        "sentenceSize": {
                          "exp": {
                            "sign": "gt",
                            "val": 3
                          },
                          "expB": {
                            "sign": "lt",
                            "val": 6
                          }
                        },
                        "syl": [],
                        "audioType": {
                          "filePath": "none",
                          "desc": "FILTERBUILDER.AUDIO_OPTION_NONE"
                        }
                      }
                    }
                  ],
                  "analysisDetails": [
                    {
                      "breathingEnabled": true,
                      "breathTimeMs": 500,
                      "mistakesEnabled": true,
                      "mistakes": [
                        "gentleOnset",
                        "gentleOffset",
                        "softPeak",
                        "volControlUp",
                        "tooShort",
                        "tooFast",
                        "tooSlow",
                        "symetric",
                        "tooSoft",
                        "tooHard",
                        "breathEarly",
                        "intensePeak",
                        "sharpRise",
                        "volControlStart",
                        "volControlEnd",
                        "notSoft",
                        "notHard"
                      ],
                      "boundariesEnabled": true,
                      "boundariesGridEnabled": true,
                      "coloringEnabled": true,
                      "analysisType": "2sec",
                      "analyzeTypeSylLenMs": 2000,
                      "analyzeTypeNumOfSylsInSeq": 1,
                      "mistakesSkills": {
                        "gentleOnset": 2,
                        "gentleOffset": 2,
                        "softPeak": 2,
                        "volControlDown": 2,
                        "volControlUp": 2,
                        "tooShort": 2,
                        "tooLong": 2,
                        "breathMiss": 1,
                        "breathEarly": 2,
                        "intensePeak": 2,
                        "sharpRise": 2,
                        "sharpFall": 2,
                        "shapeStart": 2,
                        "shapeEnd": 2,
                        "volControlStart": 2,
                        "volControlEnd": 2
                      },
                      "configs": [
                        {
                          "analyzeTypeSylLenMs": 500,
                          "analyzeTypeNumOfSylsInSeq": 5,
                          "weight": 3
                        },
                        {
                          "analyzeTypeSylLenMs": 200,
                          "analyzeTypeNumOfSylsInSeq": 5,
                          "weight": 5
                        }
                      ]
                    }
                  ],
                  "uiSettings": {
                    "enableMirror": false,
                    "instructions": "בתרגול זה יש להפיק משפטים בתבנית 2 שניות להברה, ולשאוף אויר בין הברה להברה. \nעל המסך יופיעו משפטים באורכים שונים. כל מילה במשפט תהיה מחולקת להברות, וההברה שיש להפיק תהיה מודגשת. \n חשוב להיעזר בתבנית המטרה המוצללת שמופיעה על גבי המוניטור, על מנת להפיק את ההברות בצורה הנכונה.\nיש להקפיד על שאיפת אויר בין הברה להברה, ועל שימוש נכון בנשימה סרעפתית ומפתח פה, על מנת להפיק את התבנית הרצויה.",
                    "defaultVideoInstructionsId": 0,
                    "phraseAudioType": [],
                    "repetition": 1,
                    "videoInstructions": {
                      "id": 195,
                      "type": "video",
                      "title": "instructions.2sec_pattern_sentence.monitor.mp4",
                      "value": "https://s3-eu-west-1.amazonaws.com/nvtk-video-media/2016_05/הוראות/instructions.2sec_pattern_sentence.monitor.mp4",
                      "tags": [],
                      "thumb": "http://www.depaul.edu/campustour/Pages/_layouts/spotlight/btn-videoplayoverlay.png"
                    },
                    "isRecordEnabled": true,
                    "isScreenShareEnabled": true
                  },
                  "wordGoal": 100,
                  "description": "תרגול: 2 שניות להברה - משפטים",
                  "name": "2-sec sentences",
                  "timeEstimation": 300,
                  "uiType": {
                    "type": "text",
                    "icon": "glyphicon glyphicon-file"
                  },
                  "engineType": {
                    "type": "TextEngine",
                    "icon": "icon-book-open"
                  },
                  "pressed": false,
                  "passScore": "20",
                  "jqyoui_pos": 1,
                  "type": "wout"
                },
                "totalLevels": 52,
                "totalParts": 10,
                "tp2uInstanceId": "1984fcbe7fa059f148897aea947062da8efe9df6",
                "tpId": "809",
                "woutTag": "תרגול: 2 שניות להברה - משפטים"
              },
              "requiredDuration": 0,
              "actualDuration": -1,
              "isLastPartInLevel": false,
              "isLastLevel": false,
              "requiredPassRate": "20",
              "overallRate": 64,
              "totalActualSequencesDone": 100,
              "totalRequiredSequences": 100,
              "isWoutPassed": true,
              "nolimit": false,
              "showWordGoal": false,
              "showDurationGoal": false,
              "isPracticeExpired": false
            }
          },
          {
            "title": "תרגול: שניה להברה- מילים רב הברתיות-שליטה בעוצמת קול",
            "config": {
              "filterSettings": [
                {
                  "filter": {
                    "lang": "he",
                    "syl": [],
                    "sylAmount": {
                      "exp": {
                        "val": 1,
                        "sign": "gt"
                      }
                    },
                    "selectedBank": "word",
                    "audioType": {
                      "filePath": "none",
                      "desc": "FILTERBUILDER.AUDIO_OPTION_NONE"
                    }
                  }
                }
              ],
              "analysisDetails": [
                {
                  "breathingEnabled": true,
                  "breathTimeMs": 2000,
                  "mistakesEnabled": true,
                  "mistakes": [
                    "gentleOnset",
                    "gentleOffset",
                    "volControlDown",
                    "volControlUp",
                    "symetric",
                    "intensePeak",
                    "sharpRise",
                    "sharpFall",
                    "shapeStart",
                    "shapeEnd",
                    "volControlStart",
                    "volControlEnd",
                    "notSoft",
                    "notHard",
                    "tooSoft",
                    "tooHard"
                  ],
                  "boundariesEnabled": true,
                  "boundariesGridEnabled": true,
                  "coloringEnabled": true,
                  "analysisType": "1sec",
                  "analyzeTypeSylLenMs": 1000,
                  "analyzeTypeNumOfSylsInSeq": 1,
                  "mistakesSkills": {
                    "gentleOnset": 2,
                    "gentleOffset": 2,
                    "softPeak": 1,
                    "tooShort": 1,
                    "tooLong": 1,
                    "breathMiss": 1,
                    "tooFast": 1,
                    "tooSlow": 1,
                    "tooSoft": 2,
                    "tooHard": 2,
                    "breathEarly": 1,
                    "intensePeak": 2
                  },
                  "configs": [
                    {
                      "analyzeTypeSylLenMs": 500,
                      "analyzeTypeNumOfSylsInSeq": 5,
                      "weight": 3
                    },
                    {
                      "analyzeTypeSylLenMs": 200,
                      "analyzeTypeNumOfSylsInSeq": 5,
                      "weight": 5
                    }
                  ],
                  "analyzeTypeNumOfSylsInSeqAuto": true
                }
              ],
              "uiSettings": {
                "repetition": 5,
                "enableMirror": false,
                "instructions": ".בתרגול זה יש להפיק מילים בתבנית שניה להברה, ולשאוף אויר בין מילה למילה\nעל המסך יופיעו מילים באורכים שונים. יש להקפיד לשמור על רצף קולי בעת הפקת המילה.\n חשוב להיעזר בתבנית המטרה המוצללת שמופיעה על גבי המוניטור, על מנת להפיק את המילים בצורה הנכונה, בדגם הגלים .\nבתרגול זה עליכם ליישם בצורה נכונה את העקרונות שנלמדו בסרטונים קודמים, וכן לחזק את השליטה בעוצמת הקול ולהשתמש נכון בקול המוגבר ובקול הרך. כפועל יוצא, תוכלו לבסס ביצוע נכון של שינויים בעוצמת הקול בהתאם לתבנית המטרה המוצללת",
                "showWordGoal": true,
                "defaultVideoInstructionsId": 0,
                "phraseAudioType": [],
                "videoInstructions": {
                  "id": 213,
                  "type": "video",
                  "title": "instructions.1sec_pattern_syllable_long_words_error_voice control.monitor.mp4",
                  "value": "https://s3-eu-west-1.amazonaws.com/nvtk-video-media/2016_05/הוראות/instructions.1sec_pattern_syllable_long_words_error_voice control.monitor.mp4",
                  "tags": [],
                  "thumb": "http://www.depaul.edu/campustour/Pages/_layouts/spotlight/btn-videoplayoverlay.png"
                },
                "isRecordEnabled": true,
                "isScreenShareEnabled": true
              },
              "wordGoal": 25,
              "description": "תרגול: שניה להברה- מילים רב הברתיות-שליטה בעוצמת קול",
              "name": "1-sec words",
              "timeEstimation": 375,
              "uiType": {
                "type": "box5stars",
                "icon": "icon-star"
              },
              "engineType": {
                "type": "AdvancedEngine",
                "icon": "icon-diamond"
              },
              "pressed": false,
              "passScore": "30",
              "type": "wout"
            },
            "sequences": [
              {
                "fieldType": "seq",
                "timerPos": 889,
                "calibrationEventId": "6581",
                "rate": 100,
                "deltaTimeMsFromPrevSeq": "-1.00",
                "yvalues": [
                  "123",
                  "162",
                  "175",
                  "182",
                  "186",
                  "174",
                  "164",
                  "159",
                  "156",
                  "164",
                  "175",
                  "179",
                  "174",
                  "146",
                  "123",
                  "106",
                  "81",
                  "0"
                ],
                "yDrawvalues": "2002,3053,3105,3297,3334,2612,2287,2238,2210,2719,3252,3125,2764,1755,1276,992,440,0",
                "mistakes": [],
                "hiddenMistakes": [],
                "softVals": [
                  "1",
                  "1",
                  "1",
                  "0",
                  "0",
                  "1",
                  "1",
                  "1",
                  "1",
                  "1",
                  "1",
                  "0",
                  "1",
                  "1",
                  "1",
                  "1",
                  "1",
                  "1"
                ],
                "expectedSylsPerSecond": "0.00",
                "actualSylsPerSecond": "0.85",
                "timeLength": 2250,
                "features": [
                  {
                    "sampleYVal": 162,
                    "sampleYDrawVal": 2419
                  },
                  {
                    "sampleYVal": 173,
                    "sampleYDrawVal": 2836
                  },
                  {
                    "sampleYVal": 186,
                    "sampleYDrawVal": 3276
                  },
                  {
                    "sampleYVal": 191,
                    "sampleYDrawVal": 3493
                  },
                  {
                    "sampleYVal": 183,
                    "sampleYDrawVal": 3034
                  },
                  {
                    "sampleYVal": 161,
                    "sampleYDrawVal": 2348
                  },
                  {
                    "sampleYVal": 154,
                    "sampleYDrawVal": 2116
                  },
                  {
                    "sampleYVal": 163,
                    "sampleYDrawVal": 2443
                  },
                  {
                    "sampleYVal": 159,
                    "sampleYDrawVal": 2291
                  },
                  {
                    "sampleYVal": 177,
                    "sampleYDrawVal": 3271
                  },
                  {
                    "sampleYVal": 178,
                    "sampleYDrawVal": 3161
                  },
                  {
                    "sampleYVal": 181,
                    "sampleYDrawVal": 3109
                  },
                  {
                    "sampleYVal": 153,
                    "sampleYDrawVal": 2244
                  },
                  {
                    "sampleYVal": 116,
                    "sampleYDrawVal": 1355
                  },
                  {
                    "sampleYVal": 138,
                    "sampleYDrawVal": 1680
                  },
                  {
                    "sampleYVal": 87,
                    "sampleYDrawVal": 637
                  },
                  {
                    "sampleYVal": 51,
                    "sampleYDrawVal": 204
                  }
                ],
                "recordOffset": 4340,
                "seqUrl": "1527724412127_20633_709",
                "seqDescription": {
                  "analysis": {
                    "analysisType": "1sec"
                  },
                  "completeCount": 0,
                  "currentSeqPos": 0,
                  "pbId": "1468",
                  "text": "מְאֹד",
                  "type": "word",
                  "word": {
                    "id": 1468,
                    "language": "he",
                    "cleanInput": "מְאֹד",
                    "type": "word",
                    "data": "[{\"text\":\"\\u05de\\u05b0\",\"sylType\":\"cv\",\"vowel\":\"E\",\"isContinous\":true},{\"text\":\"\\u05d0\\u05b9\\u05d3\",\"sylType\":\"vc\",\"vowel\":\"O\",\"isPlosive\":true}]",
                    "isNonesense": 0,
                    "audios": "{\"intoQuestionOneSec\":{\"desc\":\"Intonation question 1 sec\",\"filePath\":\"intoQuestionOneSec\",\"isIntonation\":true,\"analysisType\":{\"type\":\"1sec\"}},\"intoQuestionTwoSec\":{\"desc\":\"Intonation question 2 sec\",\"filePath\":\"intoQuestionTwoSec\",\"isIntonation\":true,\"analysisType\":{\"type\":\"2sec\"}},\"intoQuestionManaged\":{\"desc\":\"Intonation question managed\",\"filePath\":\"intoQuestionManaged\",\"isIntonation\":true,\"analysisType\":{\"type\":\"managed\"}},\"intoWonderOneSec\":{\"desc\":\"Intonation wounder 1 sec\",\"filePath\":\"intoWonderOneSec\",\"isIntonation\":true,\"analysisType\":{\"type\":\"1sec\"}},\"intoWonderTwoSec\":{\"desc\":\"Intonation wounder 2 sec\",\"filePath\":\"intoWonderTwoSec\",\"isIntonation\":true,\"analysisType\":{\"type\":\"2sec\"}},\"intoWonderManaged\":{\"desc\":\"Intonation wounder managed\",\"filePath\":\"intoWonderManaged\",\"isIntonation\":true,\"analysisType\":{\"type\":\"managed\"}},\"intoStatementsOneSec\":{\"desc\":\"Intonation statements 1 sec\",\"filePath\":\"intoStatementsOneSec\",\"isIntonation\":true,\"analysisType\":{\"type\":\"1sec\"}},\"intoStatementsTwoSec\":{\"desc\":\"Intonation statements 2 sec\",\"filePath\":\"intoStatementsTwoSec\",\"isIntonation\":true,\"analysisType\":{\"type\":\"2sec\"}},\"intoStatementsManaged\":{\"desc\":\"Intonation statements managed\",\"filePath\":\"intoStatementsManaged\",\"isIntonation\":true,\"analysisType\":{\"type\":\"managed\"}}}"
                  }
                },
                "mistakesObjects": {},
                "hiddenMistakesObjects": {}
              },
              {
                "fieldType": "seq",
                "timerPos": 894,
                "calibrationEventId": "6581",
                "rate": 80,
                "deltaTimeMsFromPrevSeq": "2190.00",
                "yvalues": [
                  "144",
                  "181",
                  "198",
                  "200",
                  "197",
                  "188",
                  "171",
                  "160",
                  "158",
                  "156",
                  "177",
                  "188",
                  "191",
                  "188",
                  "184",
                  "172",
                  "129",
                  "81",
                  "0"
                ],
                "yDrawvalues": "2959,3731,4154,3905,3645,3198,2435,2171,2201,2154,3302,3566,3589,3301,3175,2534,1035,274,0",
                "mistakes": [
                  {
                    "sampleOffset": 0,
                    "mistakeId": 1
                  }
                ],
                "hiddenMistakes": [
                  {
                    "sampleOffset": 3,
                    "mistakeId": 3
                  },
                  {
                    "sampleOffset": 18,
                    "mistakeId": 7
                  }
                ],
                "softVals": [
                  "1",
                  "0",
                  "0",
                  "0",
                  "0",
                  "0",
                  "1",
                  "1",
                  "1",
                  "1",
                  "0",
                  "0",
                  "0",
                  "0",
                  "0",
                  "1",
                  "1",
                  "1",
                  "1"
                ],
                "expectedSylsPerSecond": "0.00",
                "actualSylsPerSecond": "0.80",
                "timeLength": 2375,
                "features": [
                  {
                    "sampleYVal": 186,
                    "sampleYDrawVal": 3263
                  },
                  {
                    "sampleYVal": 199,
                    "sampleYDrawVal": 3797
                  },
                  {
                    "sampleYVal": 207,
                    "sampleYDrawVal": 4184
                  },
                  {
                    "sampleYVal": 206,
                    "sampleYDrawVal": 4104
                  },
                  {
                    "sampleYVal": 196,
                    "sampleYDrawVal": 3617
                  },
                  {
                    "sampleYVal": 181,
                    "sampleYDrawVal": 3025
                  },
                  {
                    "sampleYVal": 153,
                    "sampleYDrawVal": 2077
                  },
                  {
                    "sampleYVal": 156,
                    "sampleYDrawVal": 2177
                  },
                  {
                    "sampleYVal": 152,
                    "sampleYDrawVal": 2074
                  },
                  {
                    "sampleYVal": 153,
                    "sampleYDrawVal": 2185
                  },
                  {
                    "sampleYVal": 192,
                    "sampleYDrawVal": 3555
                  },
                  {
                    "sampleYVal": 191,
                    "sampleYDrawVal": 3446
                  },
                  {
                    "sampleYVal": 192,
                    "sampleYDrawVal": 3497
                  },
                  {
                    "sampleYVal": 188,
                    "sampleYDrawVal": 3340
                  },
                  {
                    "sampleYVal": 180,
                    "sampleYDrawVal": 3044
                  },
                  {
                    "sampleYVal": 152,
                    "sampleYDrawVal": 1992
                  },
                  {
                    "sampleYVal": 88,
                    "sampleYDrawVal": 578
                  },
                  {
                    "sampleYVal": 44,
                    "sampleYDrawVal": 112
                  }
                ],
                "recordOffset": 8893,
                "seqUrl": "1527724412127_20633_709",
                "seqDescription": {
                  "analysis": {
                    "analysisType": "1sec"
                  },
                  "completeCount": 1,
                  "currentSeqPos": 0,
                  "pbId": "1468",
                  "text": "מְאֹד",
                  "type": "word",
                  "word": {
                    "id": 1468,
                    "language": "he",
                    "cleanInput": "מְאֹד",
                    "type": "word",
                    "data": "[{\"text\":\"\\u05de\\u05b0\",\"sylType\":\"cv\",\"vowel\":\"E\",\"isContinous\":true},{\"text\":\"\\u05d0\\u05b9\\u05d3\",\"sylType\":\"vc\",\"vowel\":\"O\",\"isPlosive\":true}]",
                    "isNonesense": 0,
                    "audios": "{\"intoQuestionOneSec\":{\"desc\":\"Intonation question 1 sec\",\"filePath\":\"intoQuestionOneSec\",\"isIntonation\":true,\"analysisType\":{\"type\":\"1sec\"}},\"intoQuestionTwoSec\":{\"desc\":\"Intonation question 2 sec\",\"filePath\":\"intoQuestionTwoSec\",\"isIntonation\":true,\"analysisType\":{\"type\":\"2sec\"}},\"intoQuestionManaged\":{\"desc\":\"Intonation question managed\",\"filePath\":\"intoQuestionManaged\",\"isIntonation\":true,\"analysisType\":{\"type\":\"managed\"}},\"intoWonderOneSec\":{\"desc\":\"Intonation wounder 1 sec\",\"filePath\":\"intoWonderOneSec\",\"isIntonation\":true,\"analysisType\":{\"type\":\"1sec\"}},\"intoWonderTwoSec\":{\"desc\":\"Intonation wounder 2 sec\",\"filePath\":\"intoWonderTwoSec\",\"isIntonation\":true,\"analysisType\":{\"type\":\"2sec\"}},\"intoWonderManaged\":{\"desc\":\"Intonation wounder managed\",\"filePath\":\"intoWonderManaged\",\"isIntonation\":true,\"analysisType\":{\"type\":\"managed\"}},\"intoStatementsOneSec\":{\"desc\":\"Intonation statements 1 sec\",\"filePath\":\"intoStatementsOneSec\",\"isIntonation\":true,\"analysisType\":{\"type\":\"1sec\"}},\"intoStatementsTwoSec\":{\"desc\":\"Intonation statements 2 sec\",\"filePath\":\"intoStatementsTwoSec\",\"isIntonation\":true,\"analysisType\":{\"type\":\"2sec\"}},\"intoStatementsManaged\":{\"desc\":\"Intonation statements managed\",\"filePath\":\"intoStatementsManaged\",\"isIntonation\":true,\"analysisType\":{\"type\":\"managed\"}}}"
                  }
                },
                "mistakesObjects": {
                  "gentleOnset": {
                    "titlei18Key": "MISTAKES.GENTLE_ONSET"
                  }
                },
                "hiddenMistakesObjects": {
                  "softPeak": {
                    "titlei18Key": "MISTAKES.SOFT_PEAK"
                  },
                  "tooLong": {
                    "titlei18Key": "MISTAKES.TOO_LONG"
                  }
                }
              },
              {
                "fieldType": "seq",
                "timerPos": 899,
                "calibrationEventId": "6581",
                "rate": 80,
                "deltaTimeMsFromPrevSeq": "2750.00",
                "yvalues": [
                  "140",
                  "173",
                  "191",
                  "197",
                  "188",
                  "175",
                  "160",
                  "150",
                  "153",
                  "168",
                  "178",
                  "187",
                  "189",
                  "181",
                  "158",
                  "104",
                  "66",
                  "0"
                ],
                "yDrawvalues": "2531,3365,3768,3820,3179,2652,2092,1866,2147,2837,3248,3492,3445,2964,2056,559,198,0",
                "mistakes": [
                  {
                    "sampleOffset": 0,
                    "mistakeId": 1
                  }
                ],
                "hiddenMistakes": [
                  {
                    "sampleOffset": 3,
                    "mistakeId": 3
                  },
                  {
                    "sampleOffset": 12,
                    "mistakeId": 3
                  },
                  {
                    "sampleOffset": 17,
                    "mistakeId": 7
                  }
                ],
                "softVals": [
                  "1",
                  "1",
                  "0",
                  "0",
                  "0",
                  "1",
                  "1",
                  "1",
                  "1",
                  "1",
                  "0",
                  "0",
                  "0",
                  "0",
                  "1",
                  "1",
                  "1",
                  "1"
                ],
                "expectedSylsPerSecond": "0.00",
                "actualSylsPerSecond": "0.87",
                "timeLength": 2250,
                "features": [
                  {
                    "sampleYVal": 175,
                    "sampleYDrawVal": 2902
                  },
                  {
                    "sampleYVal": 194,
                    "sampleYDrawVal": 3567
                  },
                  {
                    "sampleYVal": 201,
                    "sampleYDrawVal": 3814
                  },
                  {
                    "sampleYVal": 199,
                    "sampleYDrawVal": 3749
                  },
                  {
                    "sampleYVal": 180,
                    "sampleYDrawVal": 2991
                  },
                  {
                    "sampleYVal": 155,
                    "sampleYDrawVal": 2121
                  },
                  {
                    "sampleYVal": 153,
                    "sampleYDrawVal": 2015
                  },
                  {
                    "sampleYVal": 150,
                    "sampleYDrawVal": 2005
                  },
                  {
                    "sampleYVal": 163,
                    "sampleYDrawVal": 2431
                  },
                  {
                    "sampleYVal": 184,
                    "sampleYDrawVal": 3261
                  },
                  {
                    "sampleYVal": 185,
                    "sampleYDrawVal": 3376
                  },
                  {
                    "sampleYVal": 195,
                    "sampleYDrawVal": 3646
                  },
                  {
                    "sampleYVal": 190,
                    "sampleYDrawVal": 3424
                  },
                  {
                    "sampleYVal": 171,
                    "sampleYDrawVal": 2679
                  },
                  {
                    "sampleYVal": 104,
                    "sampleYDrawVal": 921
                  },
                  {
                    "sampleYVal": 77,
                    "sampleYDrawVal": 497
                  },
                  {
                    "sampleYVal": 48,
                    "sampleYDrawVal": 155
                  }
                ],
                "recordOffset": 14076,
                "seqUrl": "1527724412127_20633_709",
                "seqDescription": {
                  "analysis": {
                    "analysisType": "1sec"
                  },
                  "completeCount": 2,
                  "currentSeqPos": 0,
                  "pbId": "1468",
                  "text": "מְאֹד",
                  "type": "word",
                  "word": {
                    "id": 1468,
                    "language": "he",
                    "cleanInput": "מְאֹד",
                    "type": "word",
                    "data": "[{\"text\":\"\\u05de\\u05b0\",\"sylType\":\"cv\",\"vowel\":\"E\",\"isContinous\":true},{\"text\":\"\\u05d0\\u05b9\\u05d3\",\"sylType\":\"vc\",\"vowel\":\"O\",\"isPlosive\":true}]",
                    "isNonesense": 0,
                    "audios": "{\"intoQuestionOneSec\":{\"desc\":\"Intonation question 1 sec\",\"filePath\":\"intoQuestionOneSec\",\"isIntonation\":true,\"analysisType\":{\"type\":\"1sec\"}},\"intoQuestionTwoSec\":{\"desc\":\"Intonation question 2 sec\",\"filePath\":\"intoQuestionTwoSec\",\"isIntonation\":true,\"analysisType\":{\"type\":\"2sec\"}},\"intoQuestionManaged\":{\"desc\":\"Intonation question managed\",\"filePath\":\"intoQuestionManaged\",\"isIntonation\":true,\"analysisType\":{\"type\":\"managed\"}},\"intoWonderOneSec\":{\"desc\":\"Intonation wounder 1 sec\",\"filePath\":\"intoWonderOneSec\",\"isIntonation\":true,\"analysisType\":{\"type\":\"1sec\"}},\"intoWonderTwoSec\":{\"desc\":\"Intonation wounder 2 sec\",\"filePath\":\"intoWonderTwoSec\",\"isIntonation\":true,\"analysisType\":{\"type\":\"2sec\"}},\"intoWonderManaged\":{\"desc\":\"Intonation wounder managed\",\"filePath\":\"intoWonderManaged\",\"isIntonation\":true,\"analysisType\":{\"type\":\"managed\"}},\"intoStatementsOneSec\":{\"desc\":\"Intonation statements 1 sec\",\"filePath\":\"intoStatementsOneSec\",\"isIntonation\":true,\"analysisType\":{\"type\":\"1sec\"}},\"intoStatementsTwoSec\":{\"desc\":\"Intonation statements 2 sec\",\"filePath\":\"intoStatementsTwoSec\",\"isIntonation\":true,\"analysisType\":{\"type\":\"2sec\"}},\"intoStatementsManaged\":{\"desc\":\"Intonation statements managed\",\"filePath\":\"intoStatementsManaged\",\"isIntonation\":true,\"analysisType\":{\"type\":\"managed\"}}}"
                  }
                },
                "mistakesObjects": {
                  "gentleOnset": {
                    "titlei18Key": "MISTAKES.GENTLE_ONSET"
                  }
                },
                "hiddenMistakesObjects": {
                  "softPeak": {
                    "titlei18Key": "MISTAKES.SOFT_PEAK"
                  },
                  "tooLong": {
                    "titlei18Key": "MISTAKES.TOO_LONG"
                  }
                }
              },
              {
                "fieldType": "seq",
                "timerPos": 903,
                "calibrationEventId": "6581",
                "rate": 100,
                "deltaTimeMsFromPrevSeq": "2109.00",
                "yvalues": [
                  "120",
                  "156",
                  "168",
                  "179",
                  "182",
                  "174",
                  "156",
                  "142",
                  "132",
                  "149",
                  "169",
                  "179",
                  "175",
                  "166",
                  "155",
                  "86",
                  "0"
                ],
                "yDrawvalues": "2079,2791,2783,3156,3134,2723,1967,1617,1464,2370,2909,3131,2762,2440,2027,270,0",
                "mistakes": [],
                "hiddenMistakes": [],
                "softVals": [
                  "1",
                  "1",
                  "1",
                  "0",
                  "0",
                  "1",
                  "1",
                  "1",
                  "1",
                  "1",
                  "1",
                  "0",
                  "1",
                  "1",
                  "1",
                  "1",
                  "1"
                ],
                "expectedSylsPerSecond": "0.00",
                "actualSylsPerSecond": "0.92",
                "timeLength": 2125,
                "features": [
                  {
                    "sampleYVal": 168,
                    "sampleYDrawVal": 2620
                  },
                  {
                    "sampleYVal": 180,
                    "sampleYDrawVal": 3068
                  },
                  {
                    "sampleYVal": 175,
                    "sampleYDrawVal": 2838
                  },
                  {
                    "sampleYVal": 188,
                    "sampleYDrawVal": 3296
                  },
                  {
                    "sampleYVal": 172,
                    "sampleYDrawVal": 2726
                  },
                  {
                    "sampleYVal": 159,
                    "sampleYDrawVal": 2291
                  },
                  {
                    "sampleYVal": 151,
                    "sampleYDrawVal": 2026
                  },
                  {
                    "sampleYVal": 122,
                    "sampleYDrawVal": 1253
                  },
                  {
                    "sampleYVal": 125,
                    "sampleYDrawVal": 1500
                  },
                  {
                    "sampleYVal": 170,
                    "sampleYDrawVal": 2705
                  },
                  {
                    "sampleYVal": 186,
                    "sampleYDrawVal": 3178
                  },
                  {
                    "sampleYVal": 181,
                    "sampleYDrawVal": 3024
                  },
                  {
                    "sampleYVal": 170,
                    "sampleYDrawVal": 2611
                  },
                  {
                    "sampleYVal": 169,
                    "sampleYDrawVal": 2662
                  },
                  {
                    "sampleYVal": 129,
                    "sampleYDrawVal": 1390
                  },
                  {
                    "sampleYVal": 33,
                    "sampleYDrawVal": 53
                  }
                ],
                "recordOffset": 18492,
                "seqUrl": "1527724412127_20633_709",
                "seqDescription": {
                  "analysis": {
                    "analysisType": "1sec"
                  },
                  "completeCount": 3,
                  "currentSeqPos": 0,
                  "pbId": "1468",
                  "text": "מְאֹד",
                  "type": "word",
                  "word": {
                    "id": 1468,
                    "language": "he",
                    "cleanInput": "מְאֹד",
                    "type": "word",
                    "data": "[{\"text\":\"\\u05de\\u05b0\",\"sylType\":\"cv\",\"vowel\":\"E\",\"isContinous\":true},{\"text\":\"\\u05d0\\u05b9\\u05d3\",\"sylType\":\"vc\",\"vowel\":\"O\",\"isPlosive\":true}]",
                    "isNonesense": 0,
                    "audios": "{\"intoQuestionOneSec\":{\"desc\":\"Intonation question 1 sec\",\"filePath\":\"intoQuestionOneSec\",\"isIntonation\":true,\"analysisType\":{\"type\":\"1sec\"}},\"intoQuestionTwoSec\":{\"desc\":\"Intonation question 2 sec\",\"filePath\":\"intoQuestionTwoSec\",\"isIntonation\":true,\"analysisType\":{\"type\":\"2sec\"}},\"intoQuestionManaged\":{\"desc\":\"Intonation question managed\",\"filePath\":\"intoQuestionManaged\",\"isIntonation\":true,\"analysisType\":{\"type\":\"managed\"}},\"intoWonderOneSec\":{\"desc\":\"Intonation wounder 1 sec\",\"filePath\":\"intoWonderOneSec\",\"isIntonation\":true,\"analysisType\":{\"type\":\"1sec\"}},\"intoWonderTwoSec\":{\"desc\":\"Intonation wounder 2 sec\",\"filePath\":\"intoWonderTwoSec\",\"isIntonation\":true,\"analysisType\":{\"type\":\"2sec\"}},\"intoWonderManaged\":{\"desc\":\"Intonation wounder managed\",\"filePath\":\"intoWonderManaged\",\"isIntonation\":true,\"analysisType\":{\"type\":\"managed\"}},\"intoStatementsOneSec\":{\"desc\":\"Intonation statements 1 sec\",\"filePath\":\"intoStatementsOneSec\",\"isIntonation\":true,\"analysisType\":{\"type\":\"1sec\"}},\"intoStatementsTwoSec\":{\"desc\":\"Intonation statements 2 sec\",\"filePath\":\"intoStatementsTwoSec\",\"isIntonation\":true,\"analysisType\":{\"type\":\"2sec\"}},\"intoStatementsManaged\":{\"desc\":\"Intonation statements managed\",\"filePath\":\"intoStatementsManaged\",\"isIntonation\":true,\"analysisType\":{\"type\":\"managed\"}}}"
                  }
                },
                "mistakesObjects": {},
                "hiddenMistakesObjects": {}
              },
              {
                "fieldType": "seq",
                "timerPos": 908,
                "calibrationEventId": "6581",
                "rate": 100,
                "deltaTimeMsFromPrevSeq": "2620.00",
                "yvalues": [
                  "117",
                  "163",
                  "183",
                  "185",
                  "182",
                  "174",
                  "162",
                  "141",
                  "144",
                  "171",
                  "188",
                  "195",
                  "182",
                  "146",
                  "76",
                  "0"
                ],
                "yDrawvalues": "2343,3209,3499,3244,3024,2699,2206,1559,2030,3444,3735,3817,3060,1686,188,0",
                "mistakes": [],
                "hiddenMistakes": [],
                "softVals": [
                  "1",
                  "1",
                  "0",
                  "0",
                  "0",
                  "1",
                  "1",
                  "1",
                  "1",
                  "1",
                  "0",
                  "0",
                  "0",
                  "1",
                  "1",
                  "1"
                ],
                "expectedSylsPerSecond": "0.00",
                "actualSylsPerSecond": "0.98",
                "timeLength": 2000,
                "features": [
                  {
                    "sampleYVal": 180,
                    "sampleYDrawVal": 3017
                  },
                  {
                    "sampleYVal": 192,
                    "sampleYDrawVal": 3518
                  },
                  {
                    "sampleYVal": 196,
                    "sampleYDrawVal": 3615
                  },
                  {
                    "sampleYVal": 189,
                    "sampleYDrawVal": 3332
                  },
                  {
                    "sampleYVal": 179,
                    "sampleYDrawVal": 2944
                  },
                  {
                    "sampleYVal": 166,
                    "sampleYDrawVal": 2527
                  },
                  {
                    "sampleYVal": 152,
                    "sampleYDrawVal": 2043
                  },
                  {
                    "sampleYVal": 130,
                    "sampleYDrawVal": 1541
                  },
                  {
                    "sampleYVal": 172,
                    "sampleYDrawVal": 2863
                  },
                  {
                    "sampleYVal": 196,
                    "sampleYDrawVal": 3725
                  },
                  {
                    "sampleYVal": 200,
                    "sampleYDrawVal": 3877
                  },
                  {
                    "sampleYVal": 198,
                    "sampleYDrawVal": 3766
                  },
                  {
                    "sampleYVal": 169,
                    "sampleYDrawVal": 2824
                  },
                  {
                    "sampleYVal": 98,
                    "sampleYDrawVal": 918
                  },
                  {
                    "sampleYVal": 33,
                    "sampleYDrawVal": 51
                  }
                ],
                "recordOffset": 23286,
                "seqUrl": "1527724412127_20633_709",
                "seqDescription": {
                  "analysis": {
                    "analysisType": "1sec"
                  },
                  "completeCount": 4,
                  "currentSeqPos": 0,
                  "pbId": "1468",
                  "text": "מְאֹד",
                  "type": "word",
                  "word": {
                    "id": 1468,
                    "language": "he",
                    "cleanInput": "מְאֹד",
                    "type": "word",
                    "data": "[{\"text\":\"\\u05de\\u05b0\",\"sylType\":\"cv\",\"vowel\":\"E\",\"isContinous\":true},{\"text\":\"\\u05d0\\u05b9\\u05d3\",\"sylType\":\"vc\",\"vowel\":\"O\",\"isPlosive\":true}]",
                    "isNonesense": 0,
                    "audios": "{\"intoQuestionOneSec\":{\"desc\":\"Intonation question 1 sec\",\"filePath\":\"intoQuestionOneSec\",\"isIntonation\":true,\"analysisType\":{\"type\":\"1sec\"}},\"intoQuestionTwoSec\":{\"desc\":\"Intonation question 2 sec\",\"filePath\":\"intoQuestionTwoSec\",\"isIntonation\":true,\"analysisType\":{\"type\":\"2sec\"}},\"intoQuestionManaged\":{\"desc\":\"Intonation question managed\",\"filePath\":\"intoQuestionManaged\",\"isIntonation\":true,\"analysisType\":{\"type\":\"managed\"}},\"intoWonderOneSec\":{\"desc\":\"Intonation wounder 1 sec\",\"filePath\":\"intoWonderOneSec\",\"isIntonation\":true,\"analysisType\":{\"type\":\"1sec\"}},\"intoWonderTwoSec\":{\"desc\":\"Intonation wounder 2 sec\",\"filePath\":\"intoWonderTwoSec\",\"isIntonation\":true,\"analysisType\":{\"type\":\"2sec\"}},\"intoWonderManaged\":{\"desc\":\"Intonation wounder managed\",\"filePath\":\"intoWonderManaged\",\"isIntonation\":true,\"analysisType\":{\"type\":\"managed\"}},\"intoStatementsOneSec\":{\"desc\":\"Intonation statements 1 sec\",\"filePath\":\"intoStatementsOneSec\",\"isIntonation\":true,\"analysisType\":{\"type\":\"1sec\"}},\"intoStatementsTwoSec\":{\"desc\":\"Intonation statements 2 sec\",\"filePath\":\"intoStatementsTwoSec\",\"isIntonation\":true,\"analysisType\":{\"type\":\"2sec\"}},\"intoStatementsManaged\":{\"desc\":\"Intonation statements managed\",\"filePath\":\"intoStatementsManaged\",\"isIntonation\":true,\"analysisType\":{\"type\":\"managed\"}}}"
                  }
                },
                "mistakesObjects": {},
                "hiddenMistakesObjects": {}
              },
              {
                "fieldType": "seq",
                "timerPos": 911,
                "calibrationEventId": "6581",
                "rate": 80,
                "deltaTimeMsFromPrevSeq": "2511.00",
                "yvalues": [
                  "138",
                  "178",
                  "200",
                  "206",
                  "198",
                  "178",
                  "158",
                  "144",
                  "94",
                  "0"
                ],
                "yDrawvalues": "2420,3711,4274,4235,3654,2655,1993,1683,618,0",
                "mistakes": [
                  {
                    "sampleOffset": 0,
                    "mistakeId": 1
                  }
                ],
                "hiddenMistakes": [
                  {
                    "sampleOffset": 8,
                    "mistakeId": 26
                  }
                ],
                "softVals": [
                  "1",
                  "0",
                  "0",
                  "0",
                  "0",
                  "0",
                  "1",
                  "1",
                  "1",
                  "1"
                ],
                "expectedSylsPerSecond": "0.00",
                "actualSylsPerSecond": "0.83",
                "timeLength": 1250,
                "features": [
                  {
                    "sampleYVal": 169,
                    "sampleYDrawVal": 2622
                  },
                  {
                    "sampleYVal": 213,
                    "sampleYDrawVal": 4449
                  },
                  {
                    "sampleYVal": 211,
                    "sampleYDrawVal": 4304
                  },
                  {
                    "sampleYVal": 207,
                    "sampleYDrawVal": 4177
                  },
                  {
                    "sampleYVal": 187,
                    "sampleYDrawVal": 3288
                  },
                  {
                    "sampleYVal": 161,
                    "sampleYDrawVal": 2366
                  },
                  {
                    "sampleYVal": 147,
                    "sampleYDrawVal": 1921
                  },
                  {
                    "sampleYVal": 119,
                    "sampleYDrawVal": 1236
                  },
                  {
                    "sampleYVal": 47,
                    "sampleYDrawVal": 178
                  }
                ],
                "recordOffset": 27756,
                "seqUrl": "1527724412127_20633_709",
                "seqDescription": {
                  "analysis": {
                    "analysisType": "1sec"
                  },
                  "completeCount": 0,
                  "currentSeqPos": 0,
                  "pbId": "2055",
                  "text": "תִּרְצוּ",
                  "type": "word",
                  "word": {
                    "id": 2055,
                    "language": "he",
                    "cleanInput": "תִּרְצוּ",
                    "type": "word",
                    "data": "[{\"text\":\"\\u05ea\\u05bc\\u05b4\\u05e8\\u05b0\",\"sylType\":\"cvc\",\"vowel\":\"I\",\"isPlosive\":true,\"isContinous\":true},{\"text\":\"\\u05e6\\u05d5\\u05bc\",\"sylType\":\"cv\",\"vowel\":\"U\",\"isContinous\":true}]",
                    "isNonesense": 0,
                    "audios": "[]"
                  }
                },
                "mistakesObjects": {
                  "gentleOnset": {
                    "titlei18Key": "MISTAKES.GENTLE_ONSET"
                  }
                },
                "hiddenMistakesObjects": {
                  "linkativity": {
                    "titlei18Key": "MISTAKES.LINKATIVITY"
                  }
                }
              },
              {
                "fieldType": "seq",
                "timerPos": 913,
                "calibrationEventId": "6581",
                "rate": 80,
                "deltaTimeMsFromPrevSeq": "198.00",
                "yvalues": [
                  "144",
                  "175",
                  "179",
                  "167",
                  "132",
                  "100",
                  "64",
                  "0"
                ],
                "yDrawvalues": "2642,3423,3140,2549,1526,918,255,0",
                "mistakes": [
                  {
                    "sampleOffset": 0,
                    "mistakeId": 1
                  }
                ],
                "hiddenMistakes": [
                  {
                    "sampleOffset": 2,
                    "mistakeId": 3
                  },
                  {
                    "sampleOffset": 6,
                    "mistakeId": 26
                  }
                ],
                "softVals": [
                  "1",
                  "1",
                  "0",
                  "1",
                  "1",
                  "1",
                  "1",
                  "1"
                ],
                "expectedSylsPerSecond": "0.00",
                "actualSylsPerSecond": "1.03",
                "timeLength": 1000,
                "features": [
                  {
                    "sampleYVal": 186,
                    "sampleYDrawVal": 3205
                  },
                  {
                    "sampleYVal": 187,
                    "sampleYDrawVal": 3329
                  },
                  {
                    "sampleYVal": 171,
                    "sampleYDrawVal": 2792
                  },
                  {
                    "sampleYVal": 156,
                    "sampleYDrawVal": 2328
                  },
                  {
                    "sampleYVal": 112,
                    "sampleYDrawVal": 1383
                  },
                  {
                    "sampleYVal": 66,
                    "sampleYDrawVal": 479
                  },
                  {
                    "sampleYVal": 38,
                    "sampleYDrawVal": 114
                  }
                ],
                "recordOffset": -822,
                "seqUrl": "1527724442127_20633_709",
                "seqDescription": {
                  "analysis": {
                    "analysisType": "1sec"
                  },
                  "completeCount": 1,
                  "currentSeqPos": 0,
                  "pbId": "2055",
                  "text": "תִּרְצוּ",
                  "type": "word",
                  "word": {
                    "id": 2055,
                    "language": "he",
                    "cleanInput": "תִּרְצוּ",
                    "type": "word",
                    "data": "[{\"text\":\"\\u05ea\\u05bc\\u05b4\\u05e8\\u05b0\",\"sylType\":\"cvc\",\"vowel\":\"I\",\"isPlosive\":true,\"isContinous\":true},{\"text\":\"\\u05e6\\u05d5\\u05bc\",\"sylType\":\"cv\",\"vowel\":\"U\",\"isContinous\":true}]",
                    "isNonesense": 0,
                    "audios": "[]"
                  }
                },
                "mistakesObjects": {
                  "gentleOnset": {
                    "titlei18Key": "MISTAKES.GENTLE_ONSET"
                  }
                },
                "hiddenMistakesObjects": {
                  "softPeak": {
                    "titlei18Key": "MISTAKES.SOFT_PEAK"
                  },
                  "linkativity": {
                    "titlei18Key": "MISTAKES.LINKATIVITY"
                  }
                }
              },
              {
                "fieldType": "seq",
                "timerPos": 917,
                "calibrationEventId": "6581",
                "rate": 100,
                "deltaTimeMsFromPrevSeq": "2441.00",
                "yvalues": [
                  "123",
                  "157",
                  "182",
                  "193",
                  "190",
                  "177",
                  "165",
                  "157",
                  "138",
                  "163",
                  "180",
                  "176",
                  "166",
                  "160",
                  "139",
                  "109",
                  "72",
                  "0"
                ],
                "yDrawvalues": "2184,2779,3576,3721,3343,2732,2289,2083,1584,2842,3390,2880,2584,2457,1771,896,245,0",
                "mistakes": [],
                "hiddenMistakes": [],
                "softVals": [
                  "1",
                  "1",
                  "0",
                  "0",
                  "0",
                  "0",
                  "1",
                  "1",
                  "1",
                  "1",
                  "0",
                  "1",
                  "1",
                  "1",
                  "1",
                  "1",
                  "1",
                  "1"
                ],
                "expectedSylsPerSecond": "0.00",
                "actualSylsPerSecond": "0.87",
                "timeLength": 2250,
                "features": [
                  {
                    "sampleYVal": 157,
                    "sampleYDrawVal": 2228
                  },
                  {
                    "sampleYVal": 178,
                    "sampleYDrawVal": 3005
                  },
                  {
                    "sampleYVal": 195,
                    "sampleYDrawVal": 3623
                  },
                  {
                    "sampleYVal": 196,
                    "sampleYDrawVal": 3592
                  },
                  {
                    "sampleYVal": 190,
                    "sampleYDrawVal": 3362
                  },
                  {
                    "sampleYVal": 163,
                    "sampleYDrawVal": 2390
                  },
                  {
                    "sampleYVal": 155,
                    "sampleYDrawVal": 2112
                  },
                  {
                    "sampleYVal": 146,
                    "sampleYDrawVal": 1855
                  },
                  {
                    "sampleYVal": 148,
                    "sampleYDrawVal": 2051
                  },
                  {
                    "sampleYVal": 188,
                    "sampleYDrawVal": 3352
                  },
                  {
                    "sampleYVal": 185,
                    "sampleYDrawVal": 3261
                  },
                  {
                    "sampleYVal": 170,
                    "sampleYDrawVal": 2739
                  },
                  {
                    "sampleYVal": 158,
                    "sampleYDrawVal": 2480
                  },
                  {
                    "sampleYVal": 153,
                    "sampleYDrawVal": 2325
                  },
                  {
                    "sampleYVal": 118,
                    "sampleYDrawVal": 1444
                  },
                  {
                    "sampleYVal": 85,
                    "sampleYDrawVal": 617
                  },
                  {
                    "sampleYVal": 41,
                    "sampleYDrawVal": 98
                  }
                ],
                "recordOffset": 2678,
                "seqUrl": "1527724442127_20633_709",
                "seqDescription": {
                  "analysis": {
                    "analysisType": "1sec"
                  },
                  "completeCount": 2,
                  "currentSeqPos": 0,
                  "pbId": "2055",
                  "text": "תִּרְצוּ",
                  "type": "word",
                  "word": {
                    "id": 2055,
                    "language": "he",
                    "cleanInput": "תִּרְצוּ",
                    "type": "word",
                    "data": "[{\"text\":\"\\u05ea\\u05bc\\u05b4\\u05e8\\u05b0\",\"sylType\":\"cvc\",\"vowel\":\"I\",\"isPlosive\":true,\"isContinous\":true},{\"text\":\"\\u05e6\\u05d5\\u05bc\",\"sylType\":\"cv\",\"vowel\":\"U\",\"isContinous\":true}]",
                    "isNonesense": 0,
                    "audios": "[]"
                  }
                },
                "mistakesObjects": {},
                "hiddenMistakesObjects": {}
              },
              {
                "fieldType": "seq",
                "timerPos": 922,
                "calibrationEventId": "6581",
                "rate": 100,
                "deltaTimeMsFromPrevSeq": "2619.00",
                "yvalues": [
                  "107",
                  "145",
                  "164",
                  "173",
                  "176",
                  "184",
                  "183",
                  "169",
                  "158",
                  "160",
                  "170",
                  "177",
                  "176",
                  "165",
                  "152",
                  "123",
                  "81",
                  "0"
                ],
                "yDrawvalues": "1488,2340,2719,2932,2997,3395,3151,2478,2173,2446,2893,3076,2942,2636,2286,1429,440,0",
                "mistakes": [],
                "hiddenMistakes": [],
                "softVals": [
                  "1",
                  "1",
                  "1",
                  "1",
                  "1",
                  "0",
                  "0",
                  "1",
                  "1",
                  "1",
                  "1",
                  "0",
                  "1",
                  "1",
                  "1",
                  "1",
                  "1",
                  "1"
                ],
                "expectedSylsPerSecond": "0.00",
                "actualSylsPerSecond": "0.84",
                "timeLength": 2250,
                "features": [
                  {
                    "sampleYVal": 156,
                    "sampleYDrawVal": 2100
                  },
                  {
                    "sampleYVal": 172,
                    "sampleYDrawVal": 2699
                  },
                  {
                    "sampleYVal": 177,
                    "sampleYDrawVal": 2902
                  },
                  {
                    "sampleYVal": 182,
                    "sampleYDrawVal": 3129
                  },
                  {
                    "sampleYVal": 181,
                    "sampleYDrawVal": 3082
                  },
                  {
                    "sampleYVal": 192,
                    "sampleYDrawVal": 3532
                  },
                  {
                    "sampleYVal": 183,
                    "sampleYDrawVal": 3159
                  },
                  {
                    "sampleYVal": 160,
                    "sampleYDrawVal": 2282
                  },
                  {
                    "sampleYVal": 150,
                    "sampleYDrawVal": 2084
                  },
                  {
                    "sampleYVal": 170,
                    "sampleYDrawVal": 2744
                  },
                  {
                    "sampleYVal": 180,
                    "sampleYDrawVal": 3073
                  },
                  {
                    "sampleYVal": 179,
                    "sampleYDrawVal": 3012
                  },
                  {
                    "sampleYVal": 172,
                    "sampleYDrawVal": 2820
                  },
                  {
                    "sampleYVal": 164,
                    "sampleYDrawVal": 2741
                  },
                  {
                    "sampleYVal": 137,
                    "sampleYDrawVal": 1957
                  },
                  {
                    "sampleYVal": 89,
                    "sampleYDrawVal": 996
                  },
                  {
                    "sampleYVal": 49,
                    "sampleYDrawVal": 167
                  }
                ],
                "recordOffset": 7666,
                "seqUrl": "1527724442127_20633_709",
                "seqDescription": {
                  "analysis": {
                    "analysisType": "1sec"
                  },
                  "completeCount": 3,
                  "currentSeqPos": 0,
                  "pbId": "2055",
                  "text": "תִּרְצוּ",
                  "type": "word",
                  "word": {
                    "id": 2055,
                    "language": "he",
                    "cleanInput": "תִּרְצוּ",
                    "type": "word",
                    "data": "[{\"text\":\"\\u05ea\\u05bc\\u05b4\\u05e8\\u05b0\",\"sylType\":\"cvc\",\"vowel\":\"I\",\"isPlosive\":true,\"isContinous\":true},{\"text\":\"\\u05e6\\u05d5\\u05bc\",\"sylType\":\"cv\",\"vowel\":\"U\",\"isContinous\":true}]",
                    "isNonesense": 0,
                    "audios": "[]"
                  }
                },
                "mistakesObjects": {},
                "hiddenMistakesObjects": {}
              },
              {
                "fieldType": "seq",
                "timerPos": 928,
                "calibrationEventId": "6581",
                "rate": 100,
                "deltaTimeMsFromPrevSeq": "2840.00",
                "yvalues": [
                  "106",
                  "149",
                  "180",
                  "192",
                  "192",
                  "185",
                  "170",
                  "144",
                  "128",
                  "154",
                  "170",
                  "169",
                  "163",
                  "161",
                  "152",
                  "131",
                  "104",
                  "58",
                  "0"
                ],
                "yDrawvalues": "1528,2616,3614,3727,3532,3137,2496,1700,1440,2592,3034,2777,2576,2537,2188,1586,1048,150,0",
                "mistakes": [],
                "hiddenMistakes": [],
                "softVals": [
                  "1",
                  "1",
                  "0",
                  "0",
                  "0",
                  "0",
                  "1",
                  "1",
                  "1",
                  "1",
                  "1",
                  "1",
                  "1",
                  "1",
                  "1",
                  "1",
                  "1",
                  "1",
                  "1"
                ],
                "expectedSylsPerSecond": "0.00",
                "actualSylsPerSecond": "0.82",
                "timeLength": 2375,
                "features": [
                  {
                    "sampleYVal": 143,
                    "sampleYDrawVal": 1837
                  },
                  {
                    "sampleYVal": 189,
                    "sampleYDrawVal": 3374
                  },
                  {
                    "sampleYVal": 199,
                    "sampleYDrawVal": 3776
                  },
                  {
                    "sampleYVal": 203,
                    "sampleYDrawVal": 3964
                  },
                  {
                    "sampleYVal": 194,
                    "sampleYDrawVal": 3581
                  },
                  {
                    "sampleYVal": 176,
                    "sampleYDrawVal": 2884
                  },
                  {
                    "sampleYVal": 160,
                    "sampleYDrawVal": 2321
                  },
                  {
                    "sampleYVal": 107,
                    "sampleYDrawVal": 1132
                  },
                  {
                    "sampleYVal": 140,
                    "sampleYDrawVal": 1802
                  },
                  {
                    "sampleYVal": 172,
                    "sampleYDrawVal": 2828
                  },
                  {
                    "sampleYVal": 173,
                    "sampleYDrawVal": 2936
                  },
                  {
                    "sampleYVal": 171,
                    "sampleYDrawVal": 2817
                  },
                  {
                    "sampleYVal": 166,
                    "sampleYDrawVal": 2772
                  },
                  {
                    "sampleYVal": 157,
                    "sampleYDrawVal": 2390
                  },
                  {
                    "sampleYVal": 140,
                    "sampleYDrawVal": 1966
                  },
                  {
                    "sampleYVal": 112,
                    "sampleYDrawVal": 1336
                  },
                  {
                    "sampleYVal": 84,
                    "sampleYDrawVal": 760
                  },
                  {
                    "sampleYVal": 29,
                    "sampleYDrawVal": 40
                  }
                ],
                "recordOffset": 12832,
                "seqUrl": "1527724442127_20633_709",
                "seqDescription": {
                  "analysis": {
                    "analysisType": "1sec"
                  },
                  "completeCount": 4,
                  "currentSeqPos": 0,
                  "pbId": "2055",
                  "text": "תִּרְצוּ",
                  "type": "word",
                  "word": {
                    "id": 2055,
                    "language": "he",
                    "cleanInput": "תִּרְצוּ",
                    "type": "word",
                    "data": "[{\"text\":\"\\u05ea\\u05bc\\u05b4\\u05e8\\u05b0\",\"sylType\":\"cvc\",\"vowel\":\"I\",\"isPlosive\":true,\"isContinous\":true},{\"text\":\"\\u05e6\\u05d5\\u05bc\",\"sylType\":\"cv\",\"vowel\":\"U\",\"isContinous\":true}]",
                    "isNonesense": 0,
                    "audios": "[]"
                  }
                },
                "mistakesObjects": {},
                "hiddenMistakesObjects": {}
              },
              {
                "fieldType": "seq",
                "timerPos": 933,
                "calibrationEventId": "6581",
                "rate": 100,
                "deltaTimeMsFromPrevSeq": "2749.00",
                "yvalues": [
                  "111",
                  "165",
                  "187",
                  "191",
                  "187",
                  "178",
                  "167",
                  "163",
                  "147",
                  "162",
                  "176",
                  "183",
                  "187",
                  "183",
                  "167",
                  "156",
                  "115",
                  "59",
                  "0"
                ],
                "yDrawvalues": "1992,3386,3735,3579,3314,2887,2500,2441,1900,2682,3184,3299,3429,3140,2452,2166,873,66,0",
                "mistakes": [],
                "hiddenMistakes": [],
                "softVals": [
                  "1",
                  "1",
                  "0",
                  "0",
                  "0",
                  "0",
                  "1",
                  "1",
                  "1",
                  "1",
                  "1",
                  "0",
                  "0",
                  "0",
                  "1",
                  "1",
                  "1",
                  "1",
                  "1"
                ],
                "expectedSylsPerSecond": "0.00",
                "actualSylsPerSecond": "0.80",
                "timeLength": 2375,
                "features": [
                  {
                    "sampleYVal": 169,
                    "sampleYDrawVal": 2714
                  },
                  {
                    "sampleYVal": 196,
                    "sampleYDrawVal": 3663
                  },
                  {
                    "sampleYVal": 195,
                    "sampleYDrawVal": 3650
                  },
                  {
                    "sampleYVal": 197,
                    "sampleYDrawVal": 3706
                  },
                  {
                    "sampleYVal": 181,
                    "sampleYDrawVal": 3117
                  },
                  {
                    "sampleYVal": 164,
                    "sampleYDrawVal": 2522
                  },
                  {
                    "sampleYVal": 157,
                    "sampleYDrawVal": 2303
                  },
                  {
                    "sampleYVal": 159,
                    "sampleYDrawVal": 2326
                  },
                  {
                    "sampleYVal": 148,
                    "sampleYDrawVal": 2070
                  },
                  {
                    "sampleYVal": 181,
                    "sampleYDrawVal": 3131
                  },
                  {
                    "sampleYVal": 185,
                    "sampleYDrawVal": 3263
                  },
                  {
                    "sampleYVal": 191,
                    "sampleYDrawVal": 3467
                  },
                  {
                    "sampleYVal": 192,
                    "sampleYDrawVal": 3550
                  },
                  {
                    "sampleYVal": 170,
                    "sampleYDrawVal": 2681
                  },
                  {
                    "sampleYVal": 158,
                    "sampleYDrawVal": 2349
                  },
                  {
                    "sampleYVal": 148,
                    "sampleYDrawVal": 2001
                  },
                  {
                    "sampleYVal": 54,
                    "sampleYDrawVal": 167
                  },
                  {
                    "sampleYVal": 33,
                    "sampleYDrawVal": 51
                  }
                ],
                "recordOffset": 18072,
                "seqUrl": "1527724442127_20633_709",
                "seqDescription": {
                  "analysis": {
                    "analysisType": "1sec"
                  },
                  "completeCount": 0,
                  "currentSeqPos": 0,
                  "pbId": "884",
                  "text": "דֶּבֶר",
                  "type": "word",
                  "word": {
                    "id": 884,
                    "language": "he",
                    "cleanInput": "דֶּבֶר",
                    "type": "word",
                    "data": "[{\"text\":\"\\u05d3\\u05bc\\u05b6\",\"sylType\":\"cv\",\"vowel\":\"E\",\"isPlosive\":true},{\"text\":\"\\u05d1\\u05b6\\u05e8\",\"sylType\":\"cvc\",\"vowel\":\"E\",\"isContinous\":true}]",
                    "isNonesense": 0,
                    "audios": ""
                  }
                },
                "mistakesObjects": {},
                "hiddenMistakesObjects": {}
              },
              {
                "fieldType": "seq",
                "timerPos": 938,
                "calibrationEventId": "6581",
                "rate": 100,
                "deltaTimeMsFromPrevSeq": "2760.00",
                "yvalues": [
                  "106",
                  "158",
                  "186",
                  "198",
                  "198",
                  "186",
                  "168",
                  "157",
                  "165",
                  "180",
                  "191",
                  "198",
                  "189",
                  "182",
                  "166",
                  "132",
                  "68",
                  "0"
                ],
                "yDrawvalues": "1896,3073,3711,3974,3744,2998,2280,2213,2648,3218,3597,3890,3222,2983,2339,1321,97,0",
                "mistakes": [],
                "hiddenMistakes": [],
                "softVals": [
                  "1",
                  "1",
                  "0",
                  "0",
                  "0",
                  "0",
                  "1",
                  "1",
                  "1",
                  "0",
                  "0",
                  "0",
                  "0",
                  "0",
                  "1",
                  "1",
                  "1",
                  "1"
                ],
                "expectedSylsPerSecond": "0.00",
                "actualSylsPerSecond": "0.87",
                "timeLength": 2250,
                "features": [
                  {
                    "sampleYVal": 163,
                    "sampleYDrawVal": 2360
                  },
                  {
                    "sampleYVal": 191,
                    "sampleYDrawVal": 3436
                  },
                  {
                    "sampleYVal": 198,
                    "sampleYDrawVal": 3721
                  },
                  {
                    "sampleYVal": 199,
                    "sampleYDrawVal": 3769
                  },
                  {
                    "sampleYVal": 195,
                    "sampleYDrawVal": 3584
                  },
                  {
                    "sampleYVal": 171,
                    "sampleYDrawVal": 2606
                  },
                  {
                    "sampleYVal": 163,
                    "sampleYDrawVal": 2347
                  },
                  {
                    "sampleYVal": 152,
                    "sampleYDrawVal": 2322
                  },
                  {
                    "sampleYVal": 175,
                    "sampleYDrawVal": 2791
                  },
                  {
                    "sampleYVal": 189,
                    "sampleYDrawVal": 3273
                  },
                  {
                    "sampleYVal": 200,
                    "sampleYDrawVal": 3816
                  },
                  {
                    "sampleYVal": 194,
                    "sampleYDrawVal": 3575
                  },
                  {
                    "sampleYVal": 183,
                    "sampleYDrawVal": 3095
                  },
                  {
                    "sampleYVal": 170,
                    "sampleYDrawVal": 2677
                  },
                  {
                    "sampleYVal": 155,
                    "sampleYDrawVal": 2199
                  },
                  {
                    "sampleYVal": 65,
                    "sampleYDrawVal": 306
                  },
                  {
                    "sampleYVal": 38,
                    "sampleYDrawVal": 102
                  }
                ],
                "recordOffset": 23256,
                "seqUrl": "1527724442127_20633_709",
                "seqDescription": {
                  "analysis": {
                    "analysisType": "1sec"
                  },
                  "completeCount": 1,
                  "currentSeqPos": 0,
                  "pbId": "884",
                  "text": "דֶּבֶר",
                  "type": "word",
                  "word": {
                    "id": 884,
                    "language": "he",
                    "cleanInput": "דֶּבֶר",
                    "type": "word",
                    "data": "[{\"text\":\"\\u05d3\\u05bc\\u05b6\",\"sylType\":\"cv\",\"vowel\":\"E\",\"isPlosive\":true},{\"text\":\"\\u05d1\\u05b6\\u05e8\",\"sylType\":\"cvc\",\"vowel\":\"E\",\"isContinous\":true}]",
                    "isNonesense": 0,
                    "audios": ""
                  }
                },
                "mistakesObjects": {},
                "hiddenMistakesObjects": {}
              },
              {
                "fieldType": "seq",
                "timerPos": 943,
                "calibrationEventId": "6581",
                "rate": 80,
                "deltaTimeMsFromPrevSeq": "2753.00",
                "yvalues": [
                  "142",
                  "161",
                  "177",
                  "183",
                  "179",
                  "174",
                  "179",
                  "151",
                  "156",
                  "175",
                  "185",
                  "185",
                  "181",
                  "167",
                  "122",
                  "66",
                  "0"
                ],
                "yDrawvalues": "2517,2546,3092,3174,2906,2714,3041,1944,2412,3152,3369,3217,2933,2365,978,108,0",
                "mistakes": [
                  {
                    "sampleOffset": 0,
                    "mistakeId": 1
                  }
                ],
                "hiddenMistakes": [
                  {
                    "sampleOffset": 3,
                    "mistakeId": 3
                  },
                  {
                    "sampleOffset": 16,
                    "mistakeId": 7
                  }
                ],
                "softVals": [
                  "1",
                  "1",
                  "0",
                  "0",
                  "0",
                  "1",
                  "0",
                  "1",
                  "1",
                  "1",
                  "0",
                  "0",
                  "0",
                  "1",
                  "1",
                  "1",
                  "1"
                ],
                "expectedSylsPerSecond": "0.00",
                "actualSylsPerSecond": "1.34",
                "timeLength": 2125,
                "features": [
                  {
                    "sampleYVal": 165,
                    "sampleYDrawVal": 2379
                  },
                  {
                    "sampleYVal": 176,
                    "sampleYDrawVal": 2761
                  },
                  {
                    "sampleYVal": 185,
                    "sampleYDrawVal": 3149
                  },
                  {
                    "sampleYVal": 182,
                    "sampleYDrawVal": 3035
                  },
                  {
                    "sampleYVal": 167,
                    "sampleYDrawVal": 2584
                  },
                  {
                    "sampleYVal": 181,
                    "sampleYDrawVal": 2999
                  },
                  {
                    "sampleYVal": 174,
                    "sampleYDrawVal": 2781
                  },
                  {
                    "sampleYVal": 130,
                    "sampleYDrawVal": 1706
                  },
                  {
                    "sampleYVal": 168,
                    "sampleYDrawVal": 2560
                  },
                  {
                    "sampleYVal": 191,
                    "sampleYDrawVal": 3430
                  },
                  {
                    "sampleYVal": 190,
                    "sampleYDrawVal": 3378
                  },
                  {
                    "sampleYVal": 186,
                    "sampleYDrawVal": 3171
                  },
                  {
                    "sampleYVal": 180,
                    "sampleYDrawVal": 2933
                  },
                  {
                    "sampleYVal": 145,
                    "sampleYDrawVal": 1778
                  },
                  {
                    "sampleYVal": 56,
                    "sampleYDrawVal": 184
                  },
                  {
                    "sampleYVal": 34,
                    "sampleYDrawVal": 67
                  }
                ],
                "recordOffset": -1632,
                "seqUrl": "1527724472131_20633_709",
                "seqDescription": {
                  "analysis": {
                    "analysisType": "1sec"
                  },
                  "completeCount": 2,
                  "currentSeqPos": 0,
                  "pbId": "884",
                  "text": "דֶּבֶר",
                  "type": "word",
                  "word": {
                    "id": 884,
                    "language": "he",
                    "cleanInput": "דֶּבֶר",
                    "type": "word",
                    "data": "[{\"text\":\"\\u05d3\\u05bc\\u05b6\",\"sylType\":\"cv\",\"vowel\":\"E\",\"isPlosive\":true},{\"text\":\"\\u05d1\\u05b6\\u05e8\",\"sylType\":\"cvc\",\"vowel\":\"E\",\"isContinous\":true}]",
                    "isNonesense": 0,
                    "audios": ""
                  }
                },
                "mistakesObjects": {
                  "gentleOnset": {
                    "titlei18Key": "MISTAKES.GENTLE_ONSET"
                  }
                },
                "hiddenMistakesObjects": {
                  "softPeak": {
                    "titlei18Key": "MISTAKES.SOFT_PEAK"
                  },
                  "tooLong": {
                    "titlei18Key": "MISTAKES.TOO_LONG"
                  }
                }
              },
              {
                "fieldType": "seq",
                "timerPos": 948,
                "calibrationEventId": "6581",
                "rate": 100,
                "deltaTimeMsFromPrevSeq": "2379.00",
                "yvalues": [
                  "108",
                  "148",
                  "177",
                  "189",
                  "191",
                  "184",
                  "176",
                  "164",
                  "162",
                  "176",
                  "184",
                  "196",
                  "200",
                  "193",
                  "166",
                  "139",
                  "92",
                  "0"
                ],
                "yDrawvalues": "1515,2513,3323,3537,3419,2986,2711,2317,2372,3126,3302,3916,3924,3396,2127,1410,420,0",
                "mistakes": [],
                "hiddenMistakes": [],
                "softVals": [
                  "1",
                  "1",
                  "0",
                  "0",
                  "0",
                  "0",
                  "1",
                  "1",
                  "1",
                  "1",
                  "0",
                  "0",
                  "0",
                  "0",
                  "1",
                  "1",
                  "1",
                  "1"
                ],
                "expectedSylsPerSecond": "0.00",
                "actualSylsPerSecond": "0.85",
                "timeLength": 2250,
                "features": [
                  {
                    "sampleYVal": 151,
                    "sampleYDrawVal": 2163
                  },
                  {
                    "sampleYVal": 168,
                    "sampleYDrawVal": 2591
                  },
                  {
                    "sampleYVal": 192,
                    "sampleYDrawVal": 3443
                  },
                  {
                    "sampleYVal": 197,
                    "sampleYDrawVal": 3644
                  },
                  {
                    "sampleYVal": 189,
                    "sampleYDrawVal": 3242
                  },
                  {
                    "sampleYVal": 176,
                    "sampleYDrawVal": 2795
                  },
                  {
                    "sampleYVal": 170,
                    "sampleYDrawVal": 2617
                  },
                  {
                    "sampleYVal": 160,
                    "sampleYDrawVal": 2353
                  },
                  {
                    "sampleYVal": 169,
                    "sampleYDrawVal": 2604
                  },
                  {
                    "sampleYVal": 187,
                    "sampleYDrawVal": 3299
                  },
                  {
                    "sampleYVal": 188,
                    "sampleYDrawVal": 3302
                  },
                  {
                    "sampleYVal": 206,
                    "sampleYDrawVal": 4085
                  },
                  {
                    "sampleYVal": 204,
                    "sampleYDrawVal": 3965
                  },
                  {
                    "sampleYVal": 177,
                    "sampleYDrawVal": 2791
                  },
                  {
                    "sampleYVal": 143,
                    "sampleYDrawVal": 1749
                  },
                  {
                    "sampleYVal": 100,
                    "sampleYDrawVal": 767
                  },
                  {
                    "sampleYVal": 51,
                    "sampleYDrawVal": 167
                  }
                ],
                "recordOffset": 2989,
                "seqUrl": "1527724472131_20633_709",
                "seqDescription": {
                  "analysis": {
                    "analysisType": "1sec"
                  },
                  "completeCount": 3,
                  "currentSeqPos": 0,
                  "pbId": "884",
                  "text": "דֶּבֶר",
                  "type": "word",
                  "word": {
                    "id": 884,
                    "language": "he",
                    "cleanInput": "דֶּבֶר",
                    "type": "word",
                    "data": "[{\"text\":\"\\u05d3\\u05bc\\u05b6\",\"sylType\":\"cv\",\"vowel\":\"E\",\"isPlosive\":true},{\"text\":\"\\u05d1\\u05b6\\u05e8\",\"sylType\":\"cvc\",\"vowel\":\"E\",\"isContinous\":true}]",
                    "isNonesense": 0,
                    "audios": ""
                  }
                },
                "mistakesObjects": {},
                "hiddenMistakesObjects": {}
              },
              {
                "fieldType": "seq",
                "timerPos": 953,
                "calibrationEventId": "6581",
                "rate": 100,
                "deltaTimeMsFromPrevSeq": "2760.00",
                "yvalues": [
                  "114",
                  "147",
                  "169",
                  "178",
                  "188",
                  "184",
                  "176",
                  "156",
                  "136",
                  "154",
                  "172",
                  "180",
                  "181",
                  "177",
                  "158",
                  "143",
                  "104",
                  "58",
                  "0"
                ],
                "yDrawvalues": "1845,2291,2912,3066,3424,3023,2789,2036,1652,2400,3005,3157,3107,2884,1933,1599,617,90,0",
                "mistakes": [],
                "hiddenMistakes": [],
                "softVals": [
                  "1",
                  "1",
                  "1",
                  "0",
                  "0",
                  "0",
                  "1",
                  "1",
                  "1",
                  "1",
                  "1",
                  "0",
                  "0",
                  "0",
                  "1",
                  "1",
                  "1",
                  "1",
                  "1"
                ],
                "expectedSylsPerSecond": "0.00",
                "actualSylsPerSecond": "0.82",
                "timeLength": 2375,
                "features": [
                  {
                    "sampleYVal": 154,
                    "sampleYDrawVal": 2043
                  },
                  {
                    "sampleYVal": 173,
                    "sampleYDrawVal": 2707
                  },
                  {
                    "sampleYVal": 181,
                    "sampleYDrawVal": 3014
                  },
                  {
                    "sampleYVal": 188,
                    "sampleYDrawVal": 3231
                  },
                  {
                    "sampleYVal": 195,
                    "sampleYDrawVal": 3519
                  },
                  {
                    "sampleYVal": 179,
                    "sampleYDrawVal": 2960
                  },
                  {
                    "sampleYVal": 154,
                    "sampleYDrawVal": 2162
                  },
                  {
                    "sampleYVal": 137,
                    "sampleYDrawVal": 1811
                  },
                  {
                    "sampleYVal": 134,
                    "sampleYDrawVal": 1680
                  },
                  {
                    "sampleYVal": 176,
                    "sampleYDrawVal": 2837
                  },
                  {
                    "sampleYVal": 187,
                    "sampleYDrawVal": 3256
                  },
                  {
                    "sampleYVal": 183,
                    "sampleYDrawVal": 3089
                  },
                  {
                    "sampleYVal": 182,
                    "sampleYDrawVal": 3143
                  },
                  {
                    "sampleYVal": 165,
                    "sampleYDrawVal": 2461
                  },
                  {
                    "sampleYVal": 148,
                    "sampleYDrawVal": 1889
                  },
                  {
                    "sampleYVal": 115,
                    "sampleYDrawVal": 1084
                  },
                  {
                    "sampleYVal": 76,
                    "sampleYDrawVal": 445
                  },
                  {
                    "sampleYVal": 27,
                    "sampleYDrawVal": 33
                  }
                ],
                "recordOffset": 8050,
                "seqUrl": "1527724472131_20633_709",
                "seqDescription": {
                  "analysis": {
                    "analysisType": "1sec"
                  },
                  "completeCount": 4,
                  "currentSeqPos": 0,
                  "pbId": "884",
                  "text": "דֶּבֶר",
                  "type": "word",
                  "word": {
                    "id": 884,
                    "language": "he",
                    "cleanInput": "דֶּבֶר",
                    "type": "word",
                    "data": "[{\"text\":\"\\u05d3\\u05bc\\u05b6\",\"sylType\":\"cv\",\"vowel\":\"E\",\"isPlosive\":true},{\"text\":\"\\u05d1\\u05b6\\u05e8\",\"sylType\":\"cvc\",\"vowel\":\"E\",\"isContinous\":true}]",
                    "isNonesense": 0,
                    "audios": ""
                  }
                },
                "mistakesObjects": {},
                "hiddenMistakesObjects": {}
              },
              {
                "fieldType": "seq",
                "timerPos": 958,
                "calibrationEventId": "6581",
                "rate": 100,
                "deltaTimeMsFromPrevSeq": "3009.00",
                "yvalues": [
                  "121",
                  "160",
                  "188",
                  "188",
                  "195",
                  "186",
                  "178",
                  "177",
                  "179",
                  "193",
                  "199",
                  "199",
                  "177",
                  "158",
                  "127",
                  "87",
                  "0"
                ],
                "yDrawvalues": "1716,2972,3804,3268,3729,3070,2775,2875,2978,3768,3947,3804,2507,1972,1085,344,0",
                "mistakes": [],
                "hiddenMistakes": [],
                "softVals": [
                  "1",
                  "1",
                  "0",
                  "0",
                  "0",
                  "0",
                  "0",
                  "0",
                  "0",
                  "0",
                  "0",
                  "0",
                  "0",
                  "1",
                  "1",
                  "1",
                  "1"
                ],
                "expectedSylsPerSecond": "0.00",
                "actualSylsPerSecond": "0.89",
                "timeLength": 2125,
                "features": [
                  {
                    "sampleYVal": 163,
                    "sampleYDrawVal": 2403
                  },
                  {
                    "sampleYVal": 194,
                    "sampleYDrawVal": 3587
                  },
                  {
                    "sampleYVal": 197,
                    "sampleYDrawVal": 3613
                  },
                  {
                    "sampleYVal": 189,
                    "sampleYDrawVal": 3314
                  },
                  {
                    "sampleYVal": 194,
                    "sampleYDrawVal": 3510
                  },
                  {
                    "sampleYVal": 180,
                    "sampleYDrawVal": 2938
                  },
                  {
                    "sampleYVal": 175,
                    "sampleYDrawVal": 2780
                  },
                  {
                    "sampleYVal": 179,
                    "sampleYDrawVal": 2911
                  },
                  {
                    "sampleYVal": 191,
                    "sampleYDrawVal": 3355
                  },
                  {
                    "sampleYVal": 198,
                    "sampleYDrawVal": 3757
                  },
                  {
                    "sampleYVal": 205,
                    "sampleYDrawVal": 4033
                  },
                  {
                    "sampleYVal": 190,
                    "sampleYDrawVal": 3309
                  },
                  {
                    "sampleYVal": 162,
                    "sampleYDrawVal": 2280
                  },
                  {
                    "sampleYVal": 145,
                    "sampleYDrawVal": 1811
                  },
                  {
                    "sampleYVal": 101,
                    "sampleYDrawVal": 762
                  },
                  {
                    "sampleYVal": 65,
                    "sampleYDrawVal": 269
                  }
                ],
                "recordOffset": 13549,
                "seqUrl": "1527724472131_20633_709",
                "seqDescription": {
                  "analysis": {
                    "analysisType": "1sec"
                  },
                  "completeCount": 0,
                  "currentSeqPos": 0,
                  "pbId": "1443",
                  "text": "הָעַכְ",
                  "type": "word",
                  "word": {
                    "id": 1443,
                    "language": "he",
                    "cleanInput": "הָעַכְבָּר",
                    "type": "word",
                    "data": "[{\"text\":\"\\u05d4\\u05b8\",\"sylType\":\"v\",\"vowel\":\"A\"},{\"text\":\"\\u05e2\\u05b7\\u05db\\u05b0\",\"sylType\":\"vc\",\"vowel\":\"A\",\"isContinous\":true},{\"text\":\"\\u05d1\\u05bc\\u05b8\\u05e8\",\"sylType\":\"cvc\",\"vowel\":\"A\",\"isContinous\":true,\"isPlosive\":true}]",
                    "isNonesense": 0,
                    "audios": "{\"managedQuestion\":{\"desc\":\"Managed question\",\"filePath\":\"managedQuestion\",\"analysisType\":{\"type\":\"managed\"}},\"oneSecQuestion\":{\"desc\":\"One sec question\",\"filePath\":\"oneSecQuestion\",\"analysisType\":{\"type\":\"1sec\"}}}"
                  }
                },
                "mistakesObjects": {},
                "hiddenMistakesObjects": {}
              },
              {
                "fieldType": "seq",
                "timerPos": 959,
                "calibrationEventId": "6581",
                "rate": 100,
                "deltaTimeMsFromPrevSeq": "118.00",
                "yvalues": [
                  "104",
                  "154",
                  "181",
                  "180",
                  "160",
                  "141",
                  "125",
                  "108",
                  "78",
                  "0"
                ],
                "yDrawvalues": "1461,2869,3510,2948,1956,1463,1149,814,312,0",
                "mistakes": [],
                "hiddenMistakes": [],
                "softVals": [
                  "1",
                  "1",
                  "0",
                  "0",
                  "1",
                  "1",
                  "1",
                  "1",
                  "1",
                  "1"
                ],
                "expectedSylsPerSecond": "0.00",
                "actualSylsPerSecond": "0.82",
                "timeLength": 1250,
                "features": [
                  {
                    "sampleYVal": 150,
                    "sampleYDrawVal": 2010
                  },
                  {
                    "sampleYVal": 187,
                    "sampleYDrawVal": 3221
                  },
                  {
                    "sampleYVal": 195,
                    "sampleYDrawVal": 3572
                  },
                  {
                    "sampleYVal": 168,
                    "sampleYDrawVal": 2472
                  },
                  {
                    "sampleYVal": 136,
                    "sampleYDrawVal": 1559
                  },
                  {
                    "sampleYVal": 140,
                    "sampleYDrawVal": 1670
                  },
                  {
                    "sampleYVal": 111,
                    "sampleYDrawVal": 954
                  },
                  {
                    "sampleYVal": 95,
                    "sampleYDrawVal": 689
                  },
                  {
                    "sampleYVal": 46,
                    "sampleYDrawVal": 113
                  }
                ],
                "recordOffset": 15763,
                "seqUrl": "1527724472131_20633_709",
                "seqDescription": {
                  "analysis": {
                    "analysisType": "1sec"
                  },
                  "completeCount": 1,
                  "currentSeqPos": 0,
                  "pbId": "1443",
                  "text": "הָעַכְ",
                  "type": "word",
                  "word": {
                    "id": 1443,
                    "language": "he",
                    "cleanInput": "הָעַכְבָּר",
                    "type": "word",
                    "data": "[{\"text\":\"\\u05d4\\u05b8\",\"sylType\":\"v\",\"vowel\":\"A\"},{\"text\":\"\\u05e2\\u05b7\\u05db\\u05b0\",\"sylType\":\"vc\",\"vowel\":\"A\",\"isContinous\":true},{\"text\":\"\\u05d1\\u05bc\\u05b8\\u05e8\",\"sylType\":\"cvc\",\"vowel\":\"A\",\"isContinous\":true,\"isPlosive\":true}]",
                    "isNonesense": 0,
                    "audios": "{\"managedQuestion\":{\"desc\":\"Managed question\",\"filePath\":\"managedQuestion\",\"analysisType\":{\"type\":\"managed\"}},\"oneSecQuestion\":{\"desc\":\"One sec question\",\"filePath\":\"oneSecQuestion\",\"analysisType\":{\"type\":\"1sec\"}}}"
                  }
                },
                "mistakesObjects": {},
                "hiddenMistakesObjects": {}
              },
              {
                "fieldType": "seq",
                "timerPos": 966,
                "calibrationEventId": "6581",
                "rate": 100,
                "deltaTimeMsFromPrevSeq": "2880.00",
                "yvalues": [
                  "107",
                  "143",
                  "165",
                  "186",
                  "191",
                  "182",
                  "173",
                  "170",
                  "177",
                  "182",
                  "188",
                  "191",
                  "190",
                  "179",
                  "170",
                  "151",
                  "109",
                  "128",
                  "156",
                  "177",
                  "189",
                  "189",
                  "173",
                  "132",
                  "70",
                  "0"
                ],
                "yDrawvalues": "1113,2257,2800,3553,3510,2884,2598,2562,3019,3167,3380,3462,3363,2757,2458,1825,662,1809,2622,3254,3610,3395,2582,1061,161,0",
                "mistakes": [],
                "hiddenMistakes": [],
                "softVals": [
                  "1",
                  "1",
                  "1",
                  "0",
                  "0",
                  "0",
                  "1",
                  "1",
                  "0",
                  "0",
                  "0",
                  "0",
                  "0",
                  "0",
                  "1",
                  "1",
                  "1",
                  "1",
                  "1",
                  "0",
                  "0",
                  "0",
                  "1",
                  "1",
                  "1",
                  "1"
                ],
                "expectedSylsPerSecond": "0.00",
                "actualSylsPerSecond": "0.87",
                "timeLength": 3250,
                "features": [
                  {
                    "sampleYVal": 146,
                    "sampleYDrawVal": 1830
                  },
                  {
                    "sampleYVal": 162,
                    "sampleYDrawVal": 2348
                  },
                  {
                    "sampleYVal": 195,
                    "sampleYDrawVal": 3560
                  },
                  {
                    "sampleYVal": 193,
                    "sampleYDrawVal": 3446
                  },
                  {
                    "sampleYVal": 195,
                    "sampleYDrawVal": 3536
                  },
                  {
                    "sampleYVal": 168,
                    "sampleYDrawVal": 2546
                  },
                  {
                    "sampleYVal": 170,
                    "sampleYDrawVal": 2580
                  },
                  {
                    "sampleYVal": 174,
                    "sampleYDrawVal": 2761
                  },
                  {
                    "sampleYVal": 184,
                    "sampleYDrawVal": 3113
                  },
                  {
                    "sampleYVal": 179,
                    "sampleYDrawVal": 2901
                  },
                  {
                    "sampleYVal": 189,
                    "sampleYDrawVal": 3271
                  },
                  {
                    "sampleYVal": 192,
                    "sampleYDrawVal": 3453
                  },
                  {
                    "sampleYVal": 183,
                    "sampleYDrawVal": 3013
                  },
                  {
                    "sampleYVal": 171,
                    "sampleYDrawVal": 2600
                  },
                  {
                    "sampleYVal": 166,
                    "sampleYDrawVal": 2421
                  },
                  {
                    "sampleYVal": 128,
                    "sampleYDrawVal": 1357
                  },
                  {
                    "sampleYVal": 96,
                    "sampleYDrawVal": 823
                  },
                  {
                    "sampleYVal": 163,
                    "sampleYDrawVal": 2386
                  },
                  {
                    "sampleYVal": 171,
                    "sampleYDrawVal": 2710
                  },
                  {
                    "sampleYVal": 194,
                    "sampleYDrawVal": 3561
                  },
                  {
                    "sampleYVal": 195,
                    "sampleYDrawVal": 3611
                  },
                  {
                    "sampleYVal": 185,
                    "sampleYDrawVal": 3194
                  },
                  {
                    "sampleYVal": 139,
                    "sampleYDrawVal": 1672
                  },
                  {
                    "sampleYVal": 103,
                    "sampleYDrawVal": 826
                  },
                  {
                    "sampleYVal": 23,
                    "sampleYDrawVal": 23
                  }
                ],
                "recordOffset": 20111,
                "seqUrl": "1527724472131_20633_709",
                "seqDescription": {
                  "analysis": {
                    "analysisType": "1sec"
                  },
                  "completeCount": 2,
                  "currentSeqPos": 0,
                  "pbId": "1443",
                  "text": "הָעַכְ",
                  "type": "word",
                  "word": {
                    "id": 1443,
                    "language": "he",
                    "cleanInput": "הָעַכְבָּר",
                    "type": "word",
                    "data": "[{\"text\":\"\\u05d4\\u05b8\",\"sylType\":\"v\",\"vowel\":\"A\"},{\"text\":\"\\u05e2\\u05b7\\u05db\\u05b0\",\"sylType\":\"vc\",\"vowel\":\"A\",\"isContinous\":true},{\"text\":\"\\u05d1\\u05bc\\u05b8\\u05e8\",\"sylType\":\"cvc\",\"vowel\":\"A\",\"isContinous\":true,\"isPlosive\":true}]",
                    "isNonesense": 0,
                    "audios": "{\"managedQuestion\":{\"desc\":\"Managed question\",\"filePath\":\"managedQuestion\",\"analysisType\":{\"type\":\"managed\"}},\"oneSecQuestion\":{\"desc\":\"One sec question\",\"filePath\":\"oneSecQuestion\",\"analysisType\":{\"type\":\"1sec\"}}}"
                  }
                },
                "mistakesObjects": {},
                "hiddenMistakesObjects": {}
              },
              {
                "fieldType": "seq",
                "timerPos": 973,
                "calibrationEventId": "6581",
                "rate": 100,
                "deltaTimeMsFromPrevSeq": "3122.00",
                "yvalues": [
                  "106",
                  "142",
                  "159",
                  "180",
                  "191",
                  "196",
                  "190",
                  "177",
                  "162",
                  "135",
                  "123",
                  "156",
                  "174",
                  "182",
                  "186",
                  "184",
                  "173",
                  "154",
                  "112",
                  "120",
                  "149",
                  "166",
                  "174",
                  "175",
                  "175",
                  "162",
                  "104",
                  "42",
                  "0"
                ],
                "yDrawvalues": "1316,2286,2505,3353,3628,3789,3316,2687,2102,1303,1332,2715,3126,3307,3363,3178,2674,2012,797,1482,2390,2804,2882,2831,2889,2211,595,19,0",
                "mistakes": [],
                "hiddenMistakes": [],
                "softVals": [
                  "1",
                  "1",
                  "1",
                  "0",
                  "0",
                  "0",
                  "0",
                  "0",
                  "1",
                  "1",
                  "1",
                  "1",
                  "1",
                  "0",
                  "0",
                  "0",
                  "1",
                  "1",
                  "1",
                  "1",
                  "1",
                  "1",
                  "1",
                  "1",
                  "1",
                  "1",
                  "1",
                  "1",
                  "1"
                ],
                "expectedSylsPerSecond": "0.00",
                "actualSylsPerSecond": "0.78",
                "timeLength": 3625,
                "features": [
                  {
                    "sampleYVal": 124,
                    "sampleYDrawVal": 1405
                  },
                  {
                    "sampleYVal": 167,
                    "sampleYDrawVal": 2557
                  },
                  {
                    "sampleYVal": 176,
                    "sampleYDrawVal": 2810
                  },
                  {
                    "sampleYVal": 191,
                    "sampleYDrawVal": 3443
                  },
                  {
                    "sampleYVal": 195,
                    "sampleYDrawVal": 3581
                  },
                  {
                    "sampleYVal": 198,
                    "sampleYDrawVal": 3754
                  },
                  {
                    "sampleYVal": 186,
                    "sampleYDrawVal": 3219
                  },
                  {
                    "sampleYVal": 162,
                    "sampleYDrawVal": 2354
                  },
                  {
                    "sampleYVal": 136,
                    "sampleYDrawVal": 1531
                  },
                  {
                    "sampleYVal": 115,
                    "sampleYDrawVal": 1108
                  },
                  {
                    "sampleYVal": 137,
                    "sampleYDrawVal": 1729
                  },
                  {
                    "sampleYVal": 180,
                    "sampleYDrawVal": 3027
                  },
                  {
                    "sampleYVal": 181,
                    "sampleYDrawVal": 3131
                  },
                  {
                    "sampleYVal": 186,
                    "sampleYDrawVal": 3283
                  },
                  {
                    "sampleYVal": 183,
                    "sampleYDrawVal": 3172
                  },
                  {
                    "sampleYVal": 180,
                    "sampleYDrawVal": 3098
                  },
                  {
                    "sampleYVal": 164,
                    "sampleYDrawVal": 2451
                  },
                  {
                    "sampleYVal": 127,
                    "sampleYDrawVal": 1503
                  },
                  {
                    "sampleYVal": 84,
                    "sampleYDrawVal": 606
                  },
                  {
                    "sampleYVal": 154,
                    "sampleYDrawVal": 2191
                  },
                  {
                    "sampleYVal": 166,
                    "sampleYDrawVal": 2574
                  },
                  {
                    "sampleYVal": 169,
                    "sampleYDrawVal": 2620
                  },
                  {
                    "sampleYVal": 177,
                    "sampleYDrawVal": 2872
                  },
                  {
                    "sampleYVal": 175,
                    "sampleYDrawVal": 2795
                  },
                  {
                    "sampleYVal": 164,
                    "sampleYDrawVal": 2480
                  },
                  {
                    "sampleYVal": 136,
                    "sampleYDrawVal": 1645
                  },
                  {
                    "sampleYVal": 36,
                    "sampleYDrawVal": 85
                  },
                  {
                    "sampleYVal": 17,
                    "sampleYDrawVal": 40
                  }
                ],
                "recordOffset": -3289,
                "seqUrl": "1527724502128_20633_709",
                "seqDescription": {
                  "analysis": {
                    "analysisType": "1sec"
                  },
                  "completeCount": 3,
                  "currentSeqPos": 0,
                  "pbId": "1443",
                  "text": "הָעַכְ",
                  "type": "word",
                  "word": {
                    "id": 1443,
                    "language": "he",
                    "cleanInput": "הָעַכְבָּר",
                    "type": "word",
                    "data": "[{\"text\":\"\\u05d4\\u05b8\",\"sylType\":\"v\",\"vowel\":\"A\"},{\"text\":\"\\u05e2\\u05b7\\u05db\\u05b0\",\"sylType\":\"vc\",\"vowel\":\"A\",\"isContinous\":true},{\"text\":\"\\u05d1\\u05bc\\u05b8\\u05e8\",\"sylType\":\"cvc\",\"vowel\":\"A\",\"isContinous\":true,\"isPlosive\":true}]",
                    "isNonesense": 0,
                    "audios": "{\"managedQuestion\":{\"desc\":\"Managed question\",\"filePath\":\"managedQuestion\",\"analysisType\":{\"type\":\"managed\"}},\"oneSecQuestion\":{\"desc\":\"One sec question\",\"filePath\":\"oneSecQuestion\",\"analysisType\":{\"type\":\"1sec\"}}}"
                  }
                },
                "mistakesObjects": {},
                "hiddenMistakesObjects": {}
              },
              {
                "fieldType": "seq",
                "timerPos": 979,
                "calibrationEventId": "6581",
                "rate": 100,
                "deltaTimeMsFromPrevSeq": "3020.00",
                "yvalues": [
                  "116",
                  "153",
                  "174",
                  "183",
                  "191",
                  "183",
                  "173",
                  "157",
                  "160",
                  "173",
                  "176",
                  "177",
                  "180",
                  "176",
                  "153",
                  "121",
                  "114",
                  "150",
                  "181",
                  "190",
                  "186",
                  "179",
                  "163",
                  "118",
                  "69",
                  "0"
                ],
                "yDrawvalues": "1507,2621,3189,3312,3603,3014,2612,1988,2461,2979,2869,2917,3095,2850,1953,998,1235,2592,3586,3624,3187,2908,2294,825,164,0",
                "mistakes": [],
                "hiddenMistakes": [],
                "softVals": [
                  "1",
                  "1",
                  "1",
                  "0",
                  "0",
                  "0",
                  "1",
                  "1",
                  "1",
                  "1",
                  "1",
                  "0",
                  "0",
                  "1",
                  "1",
                  "1",
                  "1",
                  "1",
                  "0",
                  "0",
                  "0",
                  "0",
                  "1",
                  "1",
                  "1",
                  "1"
                ],
                "expectedSylsPerSecond": "0.00",
                "actualSylsPerSecond": "0.88",
                "timeLength": 3250,
                "features": [
                  {
                    "sampleYVal": 150,
                    "sampleYDrawVal": 1971
                  },
                  {
                    "sampleYVal": 182,
                    "sampleYDrawVal": 3079
                  },
                  {
                    "sampleYVal": 186,
                    "sampleYDrawVal": 3270
                  },
                  {
                    "sampleYVal": 192,
                    "sampleYDrawVal": 3506
                  },
                  {
                    "sampleYVal": 192,
                    "sampleYDrawVal": 3505
                  },
                  {
                    "sampleYVal": 175,
                    "sampleYDrawVal": 2816
                  },
                  {
                    "sampleYVal": 166,
                    "sampleYDrawVal": 2433
                  },
                  {
                    "sampleYVal": 139,
                    "sampleYDrawVal": 1663
                  },
                  {
                    "sampleYVal": 176,
                    "sampleYDrawVal": 2847
                  },
                  {
                    "sampleYVal": 182,
                    "sampleYDrawVal": 3082
                  },
                  {
                    "sampleYVal": 172,
                    "sampleYDrawVal": 2666
                  },
                  {
                    "sampleYVal": 177,
                    "sampleYDrawVal": 2909
                  },
                  {
                    "sampleYVal": 169,
                    "sampleYDrawVal": 2621
                  },
                  {
                    "sampleYVal": 164,
                    "sampleYDrawVal": 2559
                  },
                  {
                    "sampleYVal": 135,
                    "sampleYDrawVal": 1594
                  },
                  {
                    "sampleYVal": 65,
                    "sampleYDrawVal": 257
                  },
                  {
                    "sampleYVal": 135,
                    "sampleYDrawVal": 1755
                  },
                  {
                    "sampleYVal": 184,
                    "sampleYDrawVal": 3178
                  },
                  {
                    "sampleYVal": 194,
                    "sampleYDrawVal": 3573
                  },
                  {
                    "sampleYVal": 194,
                    "sampleYDrawVal": 3556
                  },
                  {
                    "sampleYVal": 182,
                    "sampleYDrawVal": 3031
                  },
                  {
                    "sampleYVal": 182,
                    "sampleYDrawVal": 3060
                  },
                  {
                    "sampleYVal": 145,
                    "sampleYDrawVal": 1901
                  },
                  {
                    "sampleYVal": 87,
                    "sampleYDrawVal": 555
                  },
                  {
                    "sampleYVal": 38,
                    "sampleYDrawVal": 74
                  }
                ],
                "recordOffset": 3516,
                "seqUrl": "1527724502128_20633_709",
                "seqDescription": {
                  "analysis": {
                    "analysisType": "1sec"
                  },
                  "completeCount": 4,
                  "currentSeqPos": 0,
                  "pbId": "1443",
                  "text": "הָעַכְ",
                  "type": "word",
                  "word": {
                    "id": 1443,
                    "language": "he",
                    "cleanInput": "הָעַכְבָּר",
                    "type": "word",
                    "data": "[{\"text\":\"\\u05d4\\u05b8\",\"sylType\":\"v\",\"vowel\":\"A\"},{\"text\":\"\\u05e2\\u05b7\\u05db\\u05b0\",\"sylType\":\"vc\",\"vowel\":\"A\",\"isContinous\":true},{\"text\":\"\\u05d1\\u05bc\\u05b8\\u05e8\",\"sylType\":\"cvc\",\"vowel\":\"A\",\"isContinous\":true,\"isPlosive\":true}]",
                    "isNonesense": 0,
                    "audios": "{\"managedQuestion\":{\"desc\":\"Managed question\",\"filePath\":\"managedQuestion\",\"analysisType\":{\"type\":\"managed\"}},\"oneSecQuestion\":{\"desc\":\"One sec question\",\"filePath\":\"oneSecQuestion\",\"analysisType\":{\"type\":\"1sec\"}}}"
                  }
                },
                "mistakesObjects": {},
                "hiddenMistakesObjects": {}
              },
              {
                "fieldType": "seq",
                "timerPos": 985,
                "calibrationEventId": "6581",
                "rate": 100,
                "deltaTimeMsFromPrevSeq": "2618.00",
                "yvalues": [
                  "102",
                  "137",
                  "172",
                  "183",
                  "183",
                  "178",
                  "160",
                  "141",
                  "107",
                  "101",
                  "153",
                  "180",
                  "188",
                  "190",
                  "183",
                  "170",
                  "147",
                  "127",
                  "147",
                  "160",
                  "174",
                  "185",
                  "180",
                  "157",
                  "106",
                  "49",
                  "0"
                ],
                "yDrawvalues": "965,2089,3277,3339,3123,2821,2036,1565,745,1006,2930,3467,3504,3462,3009,2459,1748,1469,2214,2510,3023,3353,2929,2002,718,38,0",
                "mistakes": [],
                "hiddenMistakes": [],
                "softVals": [
                  "1",
                  "1",
                  "1",
                  "0",
                  "0",
                  "0",
                  "1",
                  "1",
                  "1",
                  "1",
                  "1",
                  "0",
                  "0",
                  "0",
                  "0",
                  "1",
                  "1",
                  "1",
                  "1",
                  "1",
                  "1",
                  "0",
                  "0",
                  "1",
                  "1",
                  "1",
                  "1"
                ],
                "expectedSylsPerSecond": "0.00",
                "actualSylsPerSecond": "0.84",
                "timeLength": 3375,
                "features": [
                  {
                    "sampleYVal": 128,
                    "sampleYDrawVal": 1396
                  },
                  {
                    "sampleYVal": 171,
                    "sampleYDrawVal": 2664
                  },
                  {
                    "sampleYVal": 187,
                    "sampleYDrawVal": 3293
                  },
                  {
                    "sampleYVal": 190,
                    "sampleYDrawVal": 3402
                  },
                  {
                    "sampleYVal": 180,
                    "sampleYDrawVal": 2991
                  },
                  {
                    "sampleYVal": 168,
                    "sampleYDrawVal": 2490
                  },
                  {
                    "sampleYVal": 145,
                    "sampleYDrawVal": 1784
                  },
                  {
                    "sampleYVal": 125,
                    "sampleYDrawVal": 1430
                  },
                  {
                    "sampleYVal": 56,
                    "sampleYDrawVal": 195
                  },
                  {
                    "sampleYVal": 147,
                    "sampleYDrawVal": 1994
                  },
                  {
                    "sampleYVal": 195,
                    "sampleYDrawVal": 3617
                  },
                  {
                    "sampleYVal": 190,
                    "sampleYDrawVal": 3376
                  },
                  {
                    "sampleYVal": 190,
                    "sampleYDrawVal": 3389
                  },
                  {
                    "sampleYVal": 184,
                    "sampleYDrawVal": 3148
                  },
                  {
                    "sampleYVal": 163,
                    "sampleYDrawVal": 2425
                  },
                  {
                    "sampleYVal": 164,
                    "sampleYDrawVal": 2415
                  },
                  {
                    "sampleYVal": 134,
                    "sampleYDrawVal": 1642
                  },
                  {
                    "sampleYVal": 122,
                    "sampleYDrawVal": 1516
                  },
                  {
                    "sampleYVal": 164,
                    "sampleYDrawVal": 2470
                  },
                  {
                    "sampleYVal": 166,
                    "sampleYDrawVal": 2523
                  },
                  {
                    "sampleYVal": 191,
                    "sampleYDrawVal": 3410
                  },
                  {
                    "sampleYVal": 189,
                    "sampleYDrawVal": 3333
                  },
                  {
                    "sampleYVal": 168,
                    "sampleYDrawVal": 2598
                  },
                  {
                    "sampleYVal": 131,
                    "sampleYDrawVal": 1527
                  },
                  {
                    "sampleYVal": 42,
                    "sampleYDrawVal": 122
                  },
                  {
                    "sampleYVal": 23,
                    "sampleYDrawVal": 32
                  }
                ],
                "recordOffset": 9590,
                "seqUrl": "1527724502128_20633_709",
                "seqDescription": {
                  "analysis": {
                    "analysisType": "1sec"
                  },
                  "completeCount": 0,
                  "currentSeqPos": 0,
                  "pbId": "837",
                  "text": "הַשֶּׁ",
                  "type": "word",
                  "word": {
                    "id": 837,
                    "language": "he",
                    "cleanInput": "הַשֶּׁמֶשׁ",
                    "type": "word",
                    "data": "[{\"text\":\"\\u05d4\\u05b7\",\"sylType\":\"v\",\"isPlosive\":false,\"vowel\":\"A\"},{\"text\":\"\\u05e9\\u05bc\\u05c1\\u05b6\",\"sylType\":\"cv\",\"vowel\":\"E\",\"isContinous\":true},{\"text\":\"\\u05de\\u05b6\\u05e9\\u05c1\",\"sylType\":\"cvc\",\"vowel\":\"E\",\"isContinous\":true}]",
                    "isNonesense": 0,
                    "audios": ""
                  }
                },
                "mistakesObjects": {},
                "hiddenMistakesObjects": {}
              },
              {
                "fieldType": "seq",
                "timerPos": 991,
                "calibrationEventId": "6581",
                "rate": 100,
                "deltaTimeMsFromPrevSeq": "2633.00",
                "yvalues": [
                  "112",
                  "150",
                  "169",
                  "184",
                  "186",
                  "175",
                  "161",
                  "133",
                  "77",
                  "103",
                  "151",
                  "177",
                  "184",
                  "177",
                  "170",
                  "155",
                  "137",
                  "144",
                  "161",
                  "184",
                  "183",
                  "171",
                  "157",
                  "127",
                  "68",
                  "0"
                ],
                "yDrawvalues": "1365,2587,2984,3460,3314,2729,2179,1336,208,1402,2759,3353,3334,2821,2533,1982,1532,2070,2674,3581,3129,2535,2075,1223,249,0",
                "mistakes": [],
                "hiddenMistakes": [],
                "softVals": [
                  "1",
                  "1",
                  "1",
                  "0",
                  "0",
                  "1",
                  "1",
                  "1",
                  "1",
                  "1",
                  "1",
                  "0",
                  "0",
                  "0",
                  "1",
                  "1",
                  "1",
                  "1",
                  "1",
                  "0",
                  "0",
                  "1",
                  "1",
                  "1",
                  "1",
                  "1"
                ],
                "expectedSylsPerSecond": "0.00",
                "actualSylsPerSecond": "0.89",
                "timeLength": 3250,
                "features": [
                  {
                    "sampleYVal": 150,
                    "sampleYDrawVal": 2031
                  },
                  {
                    "sampleYVal": 182,
                    "sampleYDrawVal": 3122
                  },
                  {
                    "sampleYVal": 179,
                    "sampleYDrawVal": 3016
                  },
                  {
                    "sampleYVal": 186,
                    "sampleYDrawVal": 3287
                  },
                  {
                    "sampleYVal": 178,
                    "sampleYDrawVal": 2980
                  },
                  {
                    "sampleYVal": 167,
                    "sampleYDrawVal": 2497
                  },
                  {
                    "sampleYVal": 130,
                    "sampleYDrawVal": 1455
                  },
                  {
                    "sampleYVal": 72,
                    "sampleYDrawVal": 374
                  },
                  {
                    "sampleYVal": 25,
                    "sampleYDrawVal": 29
                  },
                  {
                    "sampleYVal": 155,
                    "sampleYDrawVal": 2239
                  },
                  {
                    "sampleYVal": 185,
                    "sampleYDrawVal": 3218
                  },
                  {
                    "sampleYVal": 193,
                    "sampleYDrawVal": 3519
                  },
                  {
                    "sampleYVal": 188,
                    "sampleYDrawVal": 3278
                  },
                  {
                    "sampleYVal": 173,
                    "sampleYDrawVal": 2741
                  },
                  {
                    "sampleYVal": 161,
                    "sampleYDrawVal": 2271
                  },
                  {
                    "sampleYVal": 135,
                    "sampleYDrawVal": 1638
                  },
                  {
                    "sampleYVal": 110,
                    "sampleYDrawVal": 1181
                  },
                  {
                    "sampleYVal": 153,
                    "sampleYDrawVal": 2210
                  },
                  {
                    "sampleYVal": 179,
                    "sampleYDrawVal": 3009
                  },
                  {
                    "sampleYVal": 193,
                    "sampleYDrawVal": 3511
                  },
                  {
                    "sampleYVal": 176,
                    "sampleYDrawVal": 2897
                  },
                  {
                    "sampleYVal": 161,
                    "sampleYDrawVal": 2354
                  },
                  {
                    "sampleYVal": 140,
                    "sampleYDrawVal": 1765
                  },
                  {
                    "sampleYVal": 95,
                    "sampleYDrawVal": 881
                  },
                  {
                    "sampleYVal": 24,
                    "sampleYDrawVal": 32
                  }
                ],
                "recordOffset": 15736,
                "seqUrl": "1527724502128_20633_709",
                "seqDescription": {
                  "analysis": {
                    "analysisType": "1sec"
                  },
                  "completeCount": 1,
                  "currentSeqPos": 0,
                  "pbId": "837",
                  "text": "הַשֶּׁ",
                  "type": "word",
                  "word": {
                    "id": 837,
                    "language": "he",
                    "cleanInput": "הַשֶּׁמֶשׁ",
                    "type": "word",
                    "data": "[{\"text\":\"\\u05d4\\u05b7\",\"sylType\":\"v\",\"isPlosive\":false,\"vowel\":\"A\"},{\"text\":\"\\u05e9\\u05bc\\u05c1\\u05b6\",\"sylType\":\"cv\",\"vowel\":\"E\",\"isContinous\":true},{\"text\":\"\\u05de\\u05b6\\u05e9\\u05c1\",\"sylType\":\"cvc\",\"vowel\":\"E\",\"isContinous\":true}]",
                    "isNonesense": 0,
                    "audios": ""
                  }
                },
                "mistakesObjects": {},
                "hiddenMistakesObjects": {}
              },
              {
                "fieldType": "seq",
                "timerPos": 998,
                "calibrationEventId": "6581",
                "rate": 80,
                "deltaTimeMsFromPrevSeq": "2640.00",
                "yvalues": [
                  "139",
                  "172",
                  "186",
                  "185",
                  "178",
                  "166",
                  "152",
                  "142",
                  "116",
                  "141",
                  "165",
                  "178",
                  "183",
                  "177",
                  "165",
                  "147",
                  "140",
                  "151",
                  "163",
                  "181",
                  "191",
                  "198",
                  "176",
                  "147",
                  "103",
                  "57",
                  "0"
                ],
                "yDrawvalues": "2345,3325,3500,3253,2889,2463,1975,1809,1019,2173,2896,3188,3232,2854,2290,1744,1724,2301,2681,3385,3650,3892,2544,1604,656,121,0",
                "mistakes": [
                  {
                    "sampleOffset": 0,
                    "mistakeId": 1
                  }
                ],
                "hiddenMistakes": [
                  {
                    "sampleOffset": 2,
                    "mistakeId": 3
                  },
                  {
                    "sampleOffset": 12,
                    "mistakeId": 3
                  },
                  {
                    "sampleOffset": 21,
                    "mistakeId": 3
                  },
                  {
                    "sampleOffset": 26,
                    "mistakeId": 7
                  }
                ],
                "softVals": [
                  "1",
                  "1",
                  "0",
                  "0",
                  "0",
                  "1",
                  "1",
                  "1",
                  "1",
                  "1",
                  "1",
                  "0",
                  "0",
                  "0",
                  "1",
                  "1",
                  "1",
                  "1",
                  "1",
                  "0",
                  "0",
                  "0",
                  "1",
                  "1",
                  "1",
                  "1",
                  "1"
                ],
                "expectedSylsPerSecond": "0.00",
                "actualSylsPerSecond": "0.85",
                "timeLength": 3375,
                "features": [
                  {
                    "sampleYVal": 177,
                    "sampleYDrawVal": 2936
                  },
                  {
                    "sampleYVal": 196,
                    "sampleYDrawVal": 3668
                  },
                  {
                    "sampleYVal": 191,
                    "sampleYDrawVal": 3433
                  },
                  {
                    "sampleYVal": 187,
                    "sampleYDrawVal": 3313
                  },
                  {
                    "sampleYVal": 170,
                    "sampleYDrawVal": 2697
                  },
                  {
                    "sampleYVal": 160,
                    "sampleYDrawVal": 2354
                  },
                  {
                    "sampleYVal": 144,
                    "sampleYDrawVal": 1926
                  },
                  {
                    "sampleYVal": 143,
                    "sampleYDrawVal": 1834
                  },
                  {
                    "sampleYVal": 120,
                    "sampleYDrawVal": 1426
                  },
                  {
                    "sampleYVal": 167,
                    "sampleYDrawVal": 2608
                  },
                  {
                    "sampleYVal": 179,
                    "sampleYDrawVal": 3007
                  },
                  {
                    "sampleYVal": 186,
                    "sampleYDrawVal": 3252
                  },
                  {
                    "sampleYVal": 188,
                    "sampleYDrawVal": 3330
                  },
                  {
                    "sampleYVal": 164,
                    "sampleYDrawVal": 2422
                  },
                  {
                    "sampleYVal": 151,
                    "sampleYDrawVal": 2002
                  },
                  {
                    "sampleYVal": 138,
                    "sampleYDrawVal": 1717
                  },
                  {
                    "sampleYVal": 144,
                    "sampleYDrawVal": 1903
                  },
                  {
                    "sampleYVal": 165,
                    "sampleYDrawVal": 2545
                  },
                  {
                    "sampleYVal": 176,
                    "sampleYDrawVal": 2915
                  },
                  {
                    "sampleYVal": 194,
                    "sampleYDrawVal": 3572
                  },
                  {
                    "sampleYVal": 200,
                    "sampleYDrawVal": 3807
                  },
                  {
                    "sampleYVal": 197,
                    "sampleYDrawVal": 3713
                  },
                  {
                    "sampleYVal": 151,
                    "sampleYDrawVal": 1997
                  },
                  {
                    "sampleYVal": 125,
                    "sampleYDrawVal": 1354
                  },
                  {
                    "sampleYVal": 46,
                    "sampleYDrawVal": 135
                  },
                  {
                    "sampleYVal": 38,
                    "sampleYDrawVal": 134
                  }
                ],
                "recordOffset": 21772,
                "seqUrl": "1527724502128_20633_709",
                "seqDescription": {
                  "analysis": {
                    "analysisType": "1sec"
                  },
                  "completeCount": 2,
                  "currentSeqPos": 0,
                  "pbId": "837",
                  "text": "הַשֶּׁ",
                  "type": "word",
                  "word": {
                    "id": 837,
                    "language": "he",
                    "cleanInput": "הַשֶּׁמֶשׁ",
                    "type": "word",
                    "data": "[{\"text\":\"\\u05d4\\u05b7\",\"sylType\":\"v\",\"isPlosive\":false,\"vowel\":\"A\"},{\"text\":\"\\u05e9\\u05bc\\u05c1\\u05b6\",\"sylType\":\"cv\",\"vowel\":\"E\",\"isContinous\":true},{\"text\":\"\\u05de\\u05b6\\u05e9\\u05c1\",\"sylType\":\"cvc\",\"vowel\":\"E\",\"isContinous\":true}]",
                    "isNonesense": 0,
                    "audios": ""
                  }
                },
                "mistakesObjects": {
                  "gentleOnset": {
                    "titlei18Key": "MISTAKES.GENTLE_ONSET"
                  }
                },
                "hiddenMistakesObjects": {
                  "softPeak": {
                    "titlei18Key": "MISTAKES.SOFT_PEAK"
                  },
                  "tooLong": {
                    "titlei18Key": "MISTAKES.TOO_LONG"
                  }
                }
              },
              {
                "fieldType": "seq",
                "timerPos": 1001,
                "calibrationEventId": "6581",
                "rate": 100,
                "deltaTimeMsFromPrevSeq": "2749.00",
                "yvalues": [
                  "133",
                  "156",
                  "173",
                  "183",
                  "179",
                  "164",
                  "134",
                  "95",
                  "0"
                ],
                "yDrawvalues": "2115,2566,3089,3416,3007,2260,1329,696,0",
                "mistakes": [],
                "hiddenMistakes": [],
                "softVals": [
                  "1",
                  "1",
                  "1",
                  "0",
                  "0",
                  "1",
                  "1",
                  "1",
                  "1"
                ],
                "expectedSylsPerSecond": "0.00",
                "actualSylsPerSecond": "0.92",
                "timeLength": 1125,
                "features": [
                  {
                    "sampleYVal": 156,
                    "sampleYDrawVal": 2212
                  },
                  {
                    "sampleYVal": 178,
                    "sampleYDrawVal": 2978
                  },
                  {
                    "sampleYVal": 183,
                    "sampleYDrawVal": 3229
                  },
                  {
                    "sampleYVal": 181,
                    "sampleYDrawVal": 3169
                  },
                  {
                    "sampleYVal": 170,
                    "sampleYDrawVal": 2662
                  },
                  {
                    "sampleYVal": 144,
                    "sampleYDrawVal": 1863
                  },
                  {
                    "sampleYVal": 109,
                    "sampleYDrawVal": 1033
                  },
                  {
                    "sampleYVal": 56,
                    "sampleYDrawVal": 421
                  }
                ],
                "recordOffset": 27861,
                "seqUrl": "1527724502128_20633_709",
                "seqDescription": {
                  "analysis": {
                    "analysisType": "1sec"
                  },
                  "completeCount": 3,
                  "currentSeqPos": 0,
                  "pbId": "837",
                  "text": "הַשֶּׁ",
                  "type": "word",
                  "word": {
                    "id": 837,
                    "language": "he",
                    "cleanInput": "הַשֶּׁמֶשׁ",
                    "type": "word",
                    "data": "[{\"text\":\"\\u05d4\\u05b7\",\"sylType\":\"v\",\"isPlosive\":false,\"vowel\":\"A\"},{\"text\":\"\\u05e9\\u05bc\\u05c1\\u05b6\",\"sylType\":\"cv\",\"vowel\":\"E\",\"isContinous\":true},{\"text\":\"\\u05de\\u05b6\\u05e9\\u05c1\",\"sylType\":\"cvc\",\"vowel\":\"E\",\"isContinous\":true}]",
                    "isNonesense": 0,
                    "audios": ""
                  }
                },
                "mistakesObjects": {},
                "hiddenMistakesObjects": {}
              },
              {
                "fieldType": "seq",
                "timerPos": 1004,
                "calibrationEventId": "6581",
                "rate": 100,
                "deltaTimeMsFromPrevSeq": "130.00",
                "yvalues": [
                  "134",
                  "157",
                  "168",
                  "168",
                  "161",
                  "153",
                  "140",
                  "140",
                  "157",
                  "168",
                  "191",
                  "199",
                  "196",
                  "177",
                  "141",
                  "110",
                  "73",
                  "0"
                ],
                "yDrawvalues": "2048,2569,2754,2634,2232,1980,1708,1805,2518,2842,3947,4022,3672,2696,1425,915,300,0",
                "mistakes": [],
                "hiddenMistakes": [],
                "softVals": [
                  "1",
                  "1",
                  "1",
                  "1",
                  "1",
                  "1",
                  "1",
                  "1",
                  "1",
                  "1",
                  "0",
                  "0",
                  "0",
                  "0",
                  "1",
                  "1",
                  "1",
                  "1"
                ],
                "expectedSylsPerSecond": "0.00",
                "actualSylsPerSecond": "0.84",
                "timeLength": 2250,
                "features": [
                  {
                    "sampleYVal": 158,
                    "sampleYDrawVal": 2237
                  },
                  {
                    "sampleYVal": 173,
                    "sampleYDrawVal": 2707
                  },
                  {
                    "sampleYVal": 168,
                    "sampleYDrawVal": 2598
                  },
                  {
                    "sampleYVal": 164,
                    "sampleYDrawVal": 2430
                  },
                  {
                    "sampleYVal": 155,
                    "sampleYDrawVal": 2126
                  },
                  {
                    "sampleYVal": 146,
                    "sampleYDrawVal": 1913
                  },
                  {
                    "sampleYVal": 125,
                    "sampleYDrawVal": 1493
                  },
                  {
                    "sampleYVal": 151,
                    "sampleYDrawVal": 2085
                  },
                  {
                    "sampleYVal": 170,
                    "sampleYDrawVal": 2707
                  },
                  {
                    "sampleYVal": 182,
                    "sampleYDrawVal": 3135
                  },
                  {
                    "sampleYVal": 213,
                    "sampleYDrawVal": 4419
                  },
                  {
                    "sampleYVal": 207,
                    "sampleYDrawVal": 4157
                  },
                  {
                    "sampleYVal": 189,
                    "sampleYDrawVal": 3381
                  },
                  {
                    "sampleYVal": 159,
                    "sampleYDrawVal": 2307
                  },
                  {
                    "sampleYVal": 118,
                    "sampleYDrawVal": 1211
                  },
                  {
                    "sampleYVal": 69,
                    "sampleYDrawVal": 521
                  },
                  {
                    "sampleYVal": 35,
                    "sampleYDrawVal": 76
                  }
                ],
                "recordOffset": -755,
                "seqUrl": "1527724532128_20633_709",
                "seqDescription": {
                  "analysis": {
                    "analysisType": "1sec"
                  },
                  "completeCount": 4,
                  "currentSeqPos": 0,
                  "pbId": "837",
                  "text": "הַשֶּׁ",
                  "type": "word",
                  "word": {
                    "id": 837,
                    "language": "he",
                    "cleanInput": "הַשֶּׁמֶשׁ",
                    "type": "word",
                    "data": "[{\"text\":\"\\u05d4\\u05b7\",\"sylType\":\"v\",\"isPlosive\":false,\"vowel\":\"A\"},{\"text\":\"\\u05e9\\u05bc\\u05c1\\u05b6\",\"sylType\":\"cv\",\"vowel\":\"E\",\"isContinous\":true},{\"text\":\"\\u05de\\u05b6\\u05e9\\u05c1\",\"sylType\":\"cvc\",\"vowel\":\"E\",\"isContinous\":true}]",
                    "isNonesense": 0,
                    "audios": ""
                  }
                },
                "mistakesObjects": {},
                "hiddenMistakesObjects": {}
              }
            ],
            "results": {
              "fieldType": "results",
              "woutActivityId": "20633",
              "woutIndex": "4",
              "userId": "709",
              "timerPos": 1004,
              "woutActivityShardGuid": "709fb7eca239f2449552d8c8712172ec4331e39f",
              "wout": {
                "levelIndex": "26",
                "partIndex": 4,
                "part": {
                  "filterSettings": [
                    {
                      "filter": {
                        "lang": "he",
                        "syl": [],
                        "sylAmount": {
                          "exp": {
                            "val": 1,
                            "sign": "gt"
                          }
                        },
                        "selectedBank": "word",
                        "audioType": {
                          "filePath": "none",
                          "desc": "FILTERBUILDER.AUDIO_OPTION_NONE"
                        }
                      }
                    }
                  ],
                  "analysisDetails": [
                    {
                      "breathingEnabled": true,
                      "breathTimeMs": 2000,
                      "mistakesEnabled": true,
                      "mistakes": [
                        "gentleOnset",
                        "gentleOffset",
                        "volControlDown",
                        "volControlUp",
                        "symetric",
                        "intensePeak",
                        "sharpRise",
                        "sharpFall",
                        "shapeStart",
                        "shapeEnd",
                        "volControlStart",
                        "volControlEnd",
                        "notSoft",
                        "notHard",
                        "tooSoft",
                        "tooHard"
                      ],
                      "boundariesEnabled": true,
                      "boundariesGridEnabled": true,
                      "coloringEnabled": true,
                      "analysisType": "1sec",
                      "analyzeTypeSylLenMs": 1000,
                      "analyzeTypeNumOfSylsInSeq": 1,
                      "mistakesSkills": {
                        "gentleOnset": 2,
                        "gentleOffset": 2,
                        "softPeak": 1,
                        "tooShort": 1,
                        "tooLong": 1,
                        "breathMiss": 1,
                        "tooFast": 1,
                        "tooSlow": 1,
                        "tooSoft": 2,
                        "tooHard": 2,
                        "breathEarly": 1,
                        "intensePeak": 2
                      },
                      "configs": [
                        {
                          "analyzeTypeSylLenMs": 500,
                          "analyzeTypeNumOfSylsInSeq": 5,
                          "weight": 3
                        },
                        {
                          "analyzeTypeSylLenMs": 200,
                          "analyzeTypeNumOfSylsInSeq": 5,
                          "weight": 5
                        }
                      ],
                      "analyzeTypeNumOfSylsInSeqAuto": true
                    }
                  ],
                  "uiSettings": {
                    "repetition": 5,
                    "enableMirror": false,
                    "instructions": ".בתרגול זה יש להפיק מילים בתבנית שניה להברה, ולשאוף אויר בין מילה למילה\nעל המסך יופיעו מילים באורכים שונים. יש להקפיד לשמור על רצף קולי בעת הפקת המילה.\n חשוב להיעזר בתבנית המטרה המוצללת שמופיעה על גבי המוניטור, על מנת להפיק את המילים בצורה הנכונה, בדגם הגלים .\nבתרגול זה עליכם ליישם בצורה נכונה את העקרונות שנלמדו בסרטונים קודמים, וכן לחזק את השליטה בעוצמת הקול ולהשתמש נכון בקול המוגבר ובקול הרך. כפועל יוצא, תוכלו לבסס ביצוע נכון של שינויים בעוצמת הקול בהתאם לתבנית המטרה המוצללת",
                    "showWordGoal": true,
                    "defaultVideoInstructionsId": 0,
                    "phraseAudioType": [],
                    "videoInstructions": {
                      "id": 213,
                      "type": "video",
                      "title": "instructions.1sec_pattern_syllable_long_words_error_voice control.monitor.mp4",
                      "value": "https://s3-eu-west-1.amazonaws.com/nvtk-video-media/2016_05/הוראות/instructions.1sec_pattern_syllable_long_words_error_voice control.monitor.mp4",
                      "tags": [],
                      "thumb": "http://www.depaul.edu/campustour/Pages/_layouts/spotlight/btn-videoplayoverlay.png"
                    },
                    "isRecordEnabled": true,
                    "isScreenShareEnabled": true
                  },
                  "wordGoal": 25,
                  "description": "תרגול: שניה להברה- מילים רב הברתיות-שליטה בעוצמת קול",
                  "name": "1-sec words",
                  "timeEstimation": 375,
                  "uiType": {
                    "type": "box5stars",
                    "icon": "icon-star"
                  },
                  "engineType": {
                    "type": "AdvancedEngine",
                    "icon": "icon-diamond"
                  },
                  "pressed": false,
                  "passScore": "30",
                  "type": "wout"
                },
                "totalLevels": 52,
                "totalParts": 10,
                "tp2uInstanceId": "1984fcbe7fa059f148897aea947062da8efe9df6",
                "tpId": "809",
                "woutTag": "תרגול: שניה להברה- מילים רב הברתיות-שליטה בעוצמת קול"
              },
              "requiredDuration": 0,
              "actualDuration": -1,
              "isLastPartInLevel": false,
              "isLastLevel": false,
              "requiredPassRate": "30",
              "overallRate": 76,
              "totalActualSequencesDone": 25,
              "totalRequiredSequences": 25,
              "isWoutPassed": true,
              "nolimit": false,
              "showWordGoal": true,
              "showDurationGoal": false,
              "isPracticeExpired": false
            }
          },
          {
            "title": "תרגול: שניה להברה- מילים רב הברתיות-מפתח פה",
            "config": {
              "filterSettings": [
                {
                  "filter": {
                    "lang": "he",
                    "syl": [],
                    "sylAmount": {
                      "exp": {
                        "val": 1,
                        "sign": "gt"
                      }
                    },
                    "selectedBank": "word",
                    "audioType": {
                      "filePath": "none",
                      "desc": "FILTERBUILDER.AUDIO_OPTION_NONE"
                    }
                  }
                }
              ],
              "analysisDetails": [
                {
                  "breathingEnabled": true,
                  "breathTimeMs": 2000,
                  "mistakesEnabled": true,
                  "mistakes": [
                    "gentleOnset",
                    "gentleOffset",
                    "volControlDown",
                    "volControlUp",
                    "symetric",
                    "intensePeak",
                    "sharpRise",
                    "sharpFall",
                    "shapeStart",
                    "shapeEnd",
                    "volControlStart",
                    "volControlEnd",
                    "notSoft",
                    "notHard",
                    "tooSoft",
                    "tooHard"
                  ],
                  "boundariesEnabled": true,
                  "boundariesGridEnabled": true,
                  "coloringEnabled": true,
                  "analysisType": "1sec",
                  "analyzeTypeSylLenMs": 1000,
                  "analyzeTypeNumOfSylsInSeq": 1,
                  "mistakesSkills": {
                    "gentleOnset": 2,
                    "gentleOffset": 2,
                    "softPeak": 1,
                    "tooShort": 1,
                    "tooLong": 1,
                    "breathMiss": 1,
                    "tooFast": 1,
                    "tooSlow": 1,
                    "tooSoft": 2,
                    "tooHard": 2,
                    "breathEarly": 1,
                    "intensePeak": 2
                  },
                  "configs": [
                    {
                      "analyzeTypeSylLenMs": 500,
                      "analyzeTypeNumOfSylsInSeq": 5,
                      "weight": 3
                    },
                    {
                      "analyzeTypeSylLenMs": 200,
                      "analyzeTypeNumOfSylsInSeq": 5,
                      "weight": 5
                    }
                  ],
                  "analyzeTypeNumOfSylsInSeqAuto": true
                }
              ],
              "uiSettings": {
                "repetition": 5,
                "enableMirror": true,
                "instructions": "בתרגול זה יש להפיק מילים בתבנית שניה להברה, ולשאוף אויר בין מילה למילה. \nעל המסך יופיעו מילים באורכים שונים. יש להקפיד לשמור על רצף קולי בעת הפקת המילה.. \n חשוב להיעזר בתבנית המטרה המוצללת שמופיעה על גבי המוניטור, על מנת להפיק את המילים בצורה הנכונה, בדגם הגלים .\nבתרגול זה עליכם ליישם בצורה נכונה את העקרונות שנלמדו בסרטונים קודמים, בדגש על מפתח פה שמשתנה במעבר בין הברה להברה.\n הסתכלו על דמותכם המופיעה על המסך לקבלת משוב אודות ביצוע התרגיל. \nכך, תוכלו לבסס ביצוע נכון של שינויים בעוצמת הקול בהתאם לתבנית המטרה המוצללת",
                "showWordGoal": true,
                "defaultVideoInstructionsId": 0,
                "phraseAudioType": [],
                "videoInstructions": {
                  "id": 211,
                  "type": "video",
                  "title": "instructions.1sec_pattern_syllable_long_words_error_mouth_posture.monitor.mp4",
                  "value": "https://s3-eu-west-1.amazonaws.com/nvtk-video-media/2016_05/הוראות/instructions.1sec_pattern_syllable_long_words_error_mouth_posture.monitor.mp4",
                  "tags": [],
                  "thumb": "http://www.depaul.edu/campustour/Pages/_layouts/spotlight/btn-videoplayoverlay.png"
                },
                "isRecordEnabled": true,
                "isScreenShareEnabled": true
              },
              "wordGoal": 25,
              "description": "תרגול: שניה להברה- מילים רב הברתיות-מפתח פה",
              "name": "1-sec words",
              "timeEstimation": 375,
              "uiType": {
                "type": "box5stars",
                "icon": "icon-star"
              },
              "engineType": {
                "type": "AdvancedEngine",
                "icon": "icon-diamond"
              },
              "pressed": false,
              "passScore": "30",
              "type": "wout"
            },
            "sequences": [
              {
                "fieldType": "seq",
                "timerPos": 1010,
                "calibrationEventId": "6581",
                "rate": 80,
                "deltaTimeMsFromPrevSeq": "-1.00",
                "yvalues": [
                  "139",
                  "175",
                  "188",
                  "187",
                  "182",
                  "179",
                  "159",
                  "139",
                  "168",
                  "188",
                  "209",
                  "212",
                  "210",
                  "194",
                  "178",
                  "123",
                  "73",
                  "0"
                ],
                "yDrawvalues": "2800,3455,3616,3277,3033,2985,2118,1744,3041,3709,4670,4364,4186,3229,2655,793,208,0",
                "mistakes": [
                  {
                    "sampleOffset": 0,
                    "mistakeId": 1
                  }
                ],
                "hiddenMistakes": [
                  {
                    "sampleOffset": 2,
                    "mistakeId": 3
                  },
                  {
                    "sampleOffset": 11,
                    "mistakeId": 3
                  },
                  {
                    "sampleOffset": 17,
                    "mistakeId": 7
                  }
                ],
                "softVals": [
                  "1",
                  "1",
                  "0",
                  "0",
                  "0",
                  "0",
                  "1",
                  "1",
                  "1",
                  "0",
                  "0",
                  "0",
                  "0",
                  "0",
                  "0",
                  "1",
                  "1",
                  "1"
                ],
                "expectedSylsPerSecond": "0.00",
                "actualSylsPerSecond": "0.88",
                "timeLength": 2250,
                "features": [
                  {
                    "sampleYVal": 189,
                    "sampleYDrawVal": 3344
                  },
                  {
                    "sampleYVal": 191,
                    "sampleYDrawVal": 3460
                  },
                  {
                    "sampleYVal": 192,
                    "sampleYDrawVal": 3514
                  },
                  {
                    "sampleYVal": 187,
                    "sampleYDrawVal": 3266
                  },
                  {
                    "sampleYVal": 179,
                    "sampleYDrawVal": 2979
                  },
                  {
                    "sampleYVal": 174,
                    "sampleYDrawVal": 2875
                  },
                  {
                    "sampleYVal": 128,
                    "sampleYDrawVal": 1517
                  },
                  {
                    "sampleYVal": 168,
                    "sampleYDrawVal": 2642
                  },
                  {
                    "sampleYVal": 186,
                    "sampleYDrawVal": 3215
                  },
                  {
                    "sampleYVal": 215,
                    "sampleYDrawVal": 4524
                  },
                  {
                    "sampleYVal": 220,
                    "sampleYDrawVal": 4706
                  },
                  {
                    "sampleYVal": 214,
                    "sampleYDrawVal": 4363
                  },
                  {
                    "sampleYVal": 203,
                    "sampleYDrawVal": 3869
                  },
                  {
                    "sampleYVal": 185,
                    "sampleYDrawVal": 3150
                  },
                  {
                    "sampleYVal": 149,
                    "sampleYDrawVal": 1919
                  },
                  {
                    "sampleYVal": 85,
                    "sampleYDrawVal": 530
                  },
                  {
                    "sampleYVal": 30,
                    "sampleYDrawVal": 66
                  }
                ],
                "recordOffset": 4481,
                "seqUrl": "1527724593075_20633_709",
                "seqDescription": {
                  "analysis": {
                    "analysisType": "1sec"
                  },
                  "completeCount": 0,
                  "currentSeqPos": 0,
                  "pbId": "1907",
                  "text": "נִפְגַּשׁ",
                  "type": "word",
                  "word": {
                    "id": 1907,
                    "language": "he",
                    "cleanInput": "נִפְגַּשׁ",
                    "type": "word",
                    "data": "[{\"text\":\"\\u05e0\\u05b4\\u05e4\\u05b0\",\"sylType\":\"cvc\",\"vowel\":\"I\",\"isContinous\":true},{\"text\":\"\\u05d2\\u05bc\\u05b7\\u05e9\\u05c1\",\"sylType\":\"cvc\",\"vowel\":\"A\",\"isContinous\":true,\"isPlosive\":true}]",
                    "isNonesense": 0,
                    "audios": "[]"
                  }
                },
                "mistakesObjects": {
                  "gentleOnset": {
                    "titlei18Key": "MISTAKES.GENTLE_ONSET"
                  }
                },
                "hiddenMistakesObjects": {
                  "softPeak": {
                    "titlei18Key": "MISTAKES.SOFT_PEAK"
                  },
                  "tooLong": {
                    "titlei18Key": "MISTAKES.TOO_LONG"
                  }
                }
              },
              {
                "fieldType": "seq",
                "timerPos": 1015,
                "calibrationEventId": "6581",
                "rate": 100,
                "deltaTimeMsFromPrevSeq": "2655.00",
                "yvalues": [
                  "112",
                  "154",
                  "177",
                  "191",
                  "202",
                  "201",
                  "181",
                  "152",
                  "120",
                  "148",
                  "178",
                  "204",
                  "215",
                  "206",
                  "189",
                  "158",
                  "110",
                  "63",
                  "0"
                ],
                "yDrawvalues": "1747,2737,3308,3763,4144,3883,2784,1673,1033,2342,3478,4530,4711,3925,3026,1834,645,117,0",
                "mistakes": [],
                "hiddenMistakes": [],
                "softVals": [
                  "1",
                  "1",
                  "0",
                  "0",
                  "0",
                  "0",
                  "0",
                  "1",
                  "1",
                  "1",
                  "0",
                  "0",
                  "0",
                  "0",
                  "0",
                  "1",
                  "1",
                  "1",
                  "1"
                ],
                "expectedSylsPerSecond": "0.00",
                "actualSylsPerSecond": "0.82",
                "timeLength": 2375,
                "features": [
                  {
                    "sampleYVal": 148,
                    "sampleYDrawVal": 2024
                  },
                  {
                    "sampleYVal": 178,
                    "sampleYDrawVal": 2958
                  },
                  {
                    "sampleYVal": 194,
                    "sampleYDrawVal": 3572
                  },
                  {
                    "sampleYVal": 203,
                    "sampleYDrawVal": 3958
                  },
                  {
                    "sampleYVal": 200,
                    "sampleYDrawVal": 3832
                  },
                  {
                    "sampleYVal": 190,
                    "sampleYDrawVal": 3416
                  },
                  {
                    "sampleYVal": 157,
                    "sampleYDrawVal": 2217
                  },
                  {
                    "sampleYVal": 127,
                    "sampleYDrawVal": 1337
                  },
                  {
                    "sampleYVal": 137,
                    "sampleYDrawVal": 1648
                  },
                  {
                    "sampleYVal": 171,
                    "sampleYDrawVal": 2689
                  },
                  {
                    "sampleYVal": 210,
                    "sampleYDrawVal": 4241
                  },
                  {
                    "sampleYVal": 222,
                    "sampleYDrawVal": 4837
                  },
                  {
                    "sampleYVal": 220,
                    "sampleYDrawVal": 4694
                  },
                  {
                    "sampleYVal": 187,
                    "sampleYDrawVal": 3224
                  },
                  {
                    "sampleYVal": 179,
                    "sampleYDrawVal": 2885
                  },
                  {
                    "sampleYVal": 114,
                    "sampleYDrawVal": 1104
                  },
                  {
                    "sampleYVal": 71,
                    "sampleYDrawVal": 316
                  },
                  {
                    "sampleYVal": 24,
                    "sampleYDrawVal": 27
                  }
                ],
                "recordOffset": 9444,
                "seqUrl": "1527724593075_20633_709",
                "seqDescription": {
                  "analysis": {
                    "analysisType": "1sec"
                  },
                  "completeCount": 1,
                  "currentSeqPos": 0,
                  "pbId": "1907",
                  "text": "נִפְגַּשׁ",
                  "type": "word",
                  "word": {
                    "id": 1907,
                    "language": "he",
                    "cleanInput": "נִפְגַּשׁ",
                    "type": "word",
                    "data": "[{\"text\":\"\\u05e0\\u05b4\\u05e4\\u05b0\",\"sylType\":\"cvc\",\"vowel\":\"I\",\"isContinous\":true},{\"text\":\"\\u05d2\\u05bc\\u05b7\\u05e9\\u05c1\",\"sylType\":\"cvc\",\"vowel\":\"A\",\"isContinous\":true,\"isPlosive\":true}]",
                    "isNonesense": 0,
                    "audios": "[]"
                  }
                },
                "mistakesObjects": {},
                "hiddenMistakesObjects": {}
              },
              {
                "fieldType": "seq",
                "timerPos": 1021,
                "calibrationEventId": "6581",
                "rate": 100,
                "deltaTimeMsFromPrevSeq": "3070.00",
                "yvalues": [
                  "125",
                  "177",
                  "194",
                  "193",
                  "186",
                  "175",
                  "162",
                  "133",
                  "130",
                  "174",
                  "201",
                  "213",
                  "208",
                  "192",
                  "179",
                  "148",
                  "104",
                  "52",
                  "0"
                ],
                "yDrawvalues": "2437,3807,3913,3556,3184,2787,2250,1312,1659,3546,4397,4690,4019,3118,2745,1618,598,49,0",
                "mistakes": [],
                "hiddenMistakes": [],
                "softVals": [
                  "1",
                  "0",
                  "0",
                  "0",
                  "0",
                  "1",
                  "1",
                  "1",
                  "1",
                  "1",
                  "0",
                  "0",
                  "0",
                  "0",
                  "0",
                  "1",
                  "1",
                  "1",
                  "1"
                ],
                "expectedSylsPerSecond": "0.00",
                "actualSylsPerSecond": "0.81",
                "timeLength": 2375,
                "features": [
                  {
                    "sampleYVal": 193,
                    "sampleYDrawVal": 3490
                  },
                  {
                    "sampleYVal": 204,
                    "sampleYDrawVal": 4031
                  },
                  {
                    "sampleYVal": 192,
                    "sampleYDrawVal": 3522
                  },
                  {
                    "sampleYVal": 188,
                    "sampleYDrawVal": 3363
                  },
                  {
                    "sampleYVal": 176,
                    "sampleYDrawVal": 2938
                  },
                  {
                    "sampleYVal": 166,
                    "sampleYDrawVal": 2572
                  },
                  {
                    "sampleYVal": 146,
                    "sampleYDrawVal": 1920
                  },
                  {
                    "sampleYVal": 114,
                    "sampleYDrawVal": 1175
                  },
                  {
                    "sampleYVal": 162,
                    "sampleYDrawVal": 2468
                  },
                  {
                    "sampleYVal": 208,
                    "sampleYDrawVal": 4158
                  },
                  {
                    "sampleYVal": 218,
                    "sampleYDrawVal": 4617
                  },
                  {
                    "sampleYVal": 219,
                    "sampleYDrawVal": 4681
                  },
                  {
                    "sampleYVal": 202,
                    "sampleYDrawVal": 3808
                  },
                  {
                    "sampleYVal": 187,
                    "sampleYDrawVal": 3193
                  },
                  {
                    "sampleYVal": 162,
                    "sampleYDrawVal": 2296
                  },
                  {
                    "sampleYVal": 120,
                    "sampleYDrawVal": 1255
                  },
                  {
                    "sampleYVal": 54,
                    "sampleYDrawVal": 170
                  },
                  {
                    "sampleYVal": 22,
                    "sampleYDrawVal": 22
                  }
                ],
                "recordOffset": 14974,
                "seqUrl": "1527724593075_20633_709",
                "seqDescription": {
                  "analysis": {
                    "analysisType": "1sec"
                  },
                  "completeCount": 2,
                  "currentSeqPos": 0,
                  "pbId": "1907",
                  "text": "נִפְגַּשׁ",
                  "type": "word",
                  "word": {
                    "id": 1907,
                    "language": "he",
                    "cleanInput": "נִפְגַּשׁ",
                    "type": "word",
                    "data": "[{\"text\":\"\\u05e0\\u05b4\\u05e4\\u05b0\",\"sylType\":\"cvc\",\"vowel\":\"I\",\"isContinous\":true},{\"text\":\"\\u05d2\\u05bc\\u05b7\\u05e9\\u05c1\",\"sylType\":\"cvc\",\"vowel\":\"A\",\"isContinous\":true,\"isPlosive\":true}]",
                    "isNonesense": 0,
                    "audios": "[]"
                  }
                },
                "mistakesObjects": {},
                "hiddenMistakesObjects": {}
              },
              {
                "fieldType": "seq",
                "timerPos": 1026,
                "calibrationEventId": "6581",
                "rate": 100,
                "deltaTimeMsFromPrevSeq": "2750.00",
                "yvalues": [
                  "115",
                  "159",
                  "188",
                  "201",
                  "207",
                  "186",
                  "166",
                  "147",
                  "144",
                  "158",
                  "170",
                  "195",
                  "210",
                  "207",
                  "195",
                  "178",
                  "114",
                  "66",
                  "0"
                ],
                "yDrawvalues": "2091,3000,3894,4179,4329,3049,2443,1920,2032,2469,2878,4092,4573,4037,3373,2655,600,136,0",
                "mistakes": [],
                "hiddenMistakes": [],
                "softVals": [
                  "1",
                  "1",
                  "0",
                  "0",
                  "0",
                  "0",
                  "1",
                  "1",
                  "1",
                  "1",
                  "1",
                  "0",
                  "0",
                  "0",
                  "0",
                  "0",
                  "1",
                  "1",
                  "1"
                ],
                "expectedSylsPerSecond": "0.00",
                "actualSylsPerSecond": "0.82",
                "timeLength": 2375,
                "features": [
                  {
                    "sampleYVal": 182,
                    "sampleYDrawVal": 3054
                  },
                  {
                    "sampleYVal": 180,
                    "sampleYDrawVal": 3091
                  },
                  {
                    "sampleYVal": 203,
                    "sampleYDrawVal": 3953
                  },
                  {
                    "sampleYVal": 215,
                    "sampleYDrawVal": 4522
                  },
                  {
                    "sampleYVal": 205,
                    "sampleYDrawVal": 4125
                  },
                  {
                    "sampleYVal": 168,
                    "sampleYDrawVal": 2708
                  },
                  {
                    "sampleYVal": 141,
                    "sampleYDrawVal": 2012
                  },
                  {
                    "sampleYVal": 134,
                    "sampleYDrawVal": 1843
                  },
                  {
                    "sampleYVal": 154,
                    "sampleYDrawVal": 2231
                  },
                  {
                    "sampleYVal": 172,
                    "sampleYDrawVal": 2708
                  },
                  {
                    "sampleYVal": 191,
                    "sampleYDrawVal": 3384
                  },
                  {
                    "sampleYVal": 213,
                    "sampleYDrawVal": 4398
                  },
                  {
                    "sampleYVal": 213,
                    "sampleYDrawVal": 4366
                  },
                  {
                    "sampleYVal": 205,
                    "sampleYDrawVal": 3958
                  },
                  {
                    "sampleYVal": 185,
                    "sampleYDrawVal": 3157
                  },
                  {
                    "sampleYVal": 147,
                    "sampleYDrawVal": 1799
                  },
                  {
                    "sampleYVal": 57,
                    "sampleYDrawVal": 188
                  },
                  {
                    "sampleYVal": 38,
                    "sampleYDrawVal": 85
                  }
                ],
                "recordOffset": 20166,
                "seqUrl": "1527724593075_20633_709",
                "seqDescription": {
                  "analysis": {
                    "analysisType": "1sec"
                  },
                  "completeCount": 3,
                  "currentSeqPos": 0,
                  "pbId": "1907",
                  "text": "נִפְגַּשׁ",
                  "type": "word",
                  "word": {
                    "id": 1907,
                    "language": "he",
                    "cleanInput": "נִפְגַּשׁ",
                    "type": "word",
                    "data": "[{\"text\":\"\\u05e0\\u05b4\\u05e4\\u05b0\",\"sylType\":\"cvc\",\"vowel\":\"I\",\"isContinous\":true},{\"text\":\"\\u05d2\\u05bc\\u05b7\\u05e9\\u05c1\",\"sylType\":\"cvc\",\"vowel\":\"A\",\"isContinous\":true,\"isPlosive\":true}]",
                    "isNonesense": 0,
                    "audios": "[]"
                  }
                },
                "mistakesObjects": {},
                "hiddenMistakesObjects": {}
              },
              {
                "fieldType": "seq",
                "timerPos": 1032,
                "calibrationEventId": "6581",
                "rate": 80,
                "deltaTimeMsFromPrevSeq": "3571.00",
                "yvalues": [
                  "143",
                  "176",
                  "201",
                  "210",
                  "194",
                  "162",
                  "142",
                  "118",
                  "155",
                  "183",
                  "198",
                  "198",
                  "171",
                  "137",
                  "92",
                  "0"
                ],
                "yDrawvalues": "2499,3408,4397,4473,3320,2010,1688,1331,2947,3811,4108,3826,2407,1516,477,0",
                "mistakes": [
                  {
                    "sampleOffset": 0,
                    "mistakeId": 1
                  }
                ],
                "hiddenMistakes": [
                  {
                    "sampleOffset": 3,
                    "mistakeId": 3
                  },
                  {
                    "sampleOffset": 14,
                    "mistakeId": 26
                  }
                ],
                "softVals": [
                  "1",
                  "1",
                  "0",
                  "0",
                  "0",
                  "1",
                  "1",
                  "1",
                  "1",
                  "0",
                  "0",
                  "0",
                  "1",
                  "1",
                  "1",
                  "1"
                ],
                "expectedSylsPerSecond": "0.00",
                "actualSylsPerSecond": "0.97",
                "timeLength": 2000,
                "features": [
                  {
                    "sampleYVal": 184,
                    "sampleYDrawVal": 3161
                  },
                  {
                    "sampleYVal": 195,
                    "sampleYDrawVal": 3581
                  },
                  {
                    "sampleYVal": 219,
                    "sampleYDrawVal": 4697
                  },
                  {
                    "sampleYVal": 206,
                    "sampleYDrawVal": 4092
                  },
                  {
                    "sampleYVal": 174,
                    "sampleYDrawVal": 2816
                  },
                  {
                    "sampleYVal": 149,
                    "sampleYDrawVal": 1991
                  },
                  {
                    "sampleYVal": 113,
                    "sampleYDrawVal": 1285
                  },
                  {
                    "sampleYVal": 146,
                    "sampleYDrawVal": 2010
                  },
                  {
                    "sampleYVal": 184,
                    "sampleYDrawVal": 3361
                  },
                  {
                    "sampleYVal": 205,
                    "sampleYDrawVal": 4119
                  },
                  {
                    "sampleYVal": 200,
                    "sampleYDrawVal": 3923
                  },
                  {
                    "sampleYVal": 194,
                    "sampleYDrawVal": 3607
                  },
                  {
                    "sampleYVal": 145,
                    "sampleYDrawVal": 1940
                  },
                  {
                    "sampleYVal": 96,
                    "sampleYDrawVal": 916
                  },
                  {
                    "sampleYVal": 66,
                    "sampleYDrawVal": 397
                  }
                ],
                "recordOffset": 26171,
                "seqUrl": "1527724593075_20633_709",
                "seqDescription": {
                  "analysis": {
                    "analysisType": "1sec"
                  },
                  "completeCount": 4,
                  "currentSeqPos": 0,
                  "pbId": "1907",
                  "text": "נִפְגַּשׁ",
                  "type": "word",
                  "word": {
                    "id": 1907,
                    "language": "he",
                    "cleanInput": "נִפְגַּשׁ",
                    "type": "word",
                    "data": "[{\"text\":\"\\u05e0\\u05b4\\u05e4\\u05b0\",\"sylType\":\"cvc\",\"vowel\":\"I\",\"isContinous\":true},{\"text\":\"\\u05d2\\u05bc\\u05b7\\u05e9\\u05c1\",\"sylType\":\"cvc\",\"vowel\":\"A\",\"isContinous\":true,\"isPlosive\":true}]",
                    "isNonesense": 0,
                    "audios": "[]"
                  }
                },
                "mistakesObjects": {
                  "gentleOnset": {
                    "titlei18Key": "MISTAKES.GENTLE_ONSET"
                  }
                },
                "hiddenMistakesObjects": {
                  "softPeak": {
                    "titlei18Key": "MISTAKES.SOFT_PEAK"
                  },
                  "linkativity": {
                    "titlei18Key": "MISTAKES.LINKATIVITY"
                  }
                }
              },
              {
                "fieldType": "seq",
                "timerPos": 1038,
                "calibrationEventId": "6581",
                "rate": 80,
                "deltaTimeMsFromPrevSeq": "2748.00",
                "yvalues": [
                  "140",
                  "174",
                  "192",
                  "196",
                  "187",
                  "171",
                  "148",
                  "114",
                  "87",
                  "114",
                  "156",
                  "173",
                  "183",
                  "182",
                  "175",
                  "169",
                  "153",
                  "144",
                  "153",
                  "175",
                  "189",
                  "187",
                  "181",
                  "170",
                  "115",
                  "68",
                  "0"
                ],
                "yDrawvalues": "2766,3415,3845,3740,3149,2483,1723,969,711,1712,2882,3151,3335,3143,2799,2625,2034,1944,2514,3280,3667,3360,3024,2545,663,146,0",
                "mistakes": [
                  {
                    "sampleOffset": 0,
                    "mistakeId": 1
                  }
                ],
                "hiddenMistakes": [
                  {
                    "sampleOffset": 3,
                    "mistakeId": 3
                  },
                  {
                    "sampleOffset": 12,
                    "mistakeId": 3
                  },
                  {
                    "sampleOffset": 20,
                    "mistakeId": 3
                  },
                  {
                    "sampleOffset": 26,
                    "mistakeId": 7
                  }
                ],
                "softVals": [
                  "1",
                  "1",
                  "0",
                  "0",
                  "0",
                  "1",
                  "1",
                  "1",
                  "1",
                  "1",
                  "1",
                  "1",
                  "0",
                  "0",
                  "1",
                  "1",
                  "1",
                  "1",
                  "1",
                  "1",
                  "0",
                  "0",
                  "0",
                  "1",
                  "1",
                  "1",
                  "1"
                ],
                "expectedSylsPerSecond": "0.00",
                "actualSylsPerSecond": "0.83",
                "timeLength": 3375,
                "features": [
                  {
                    "sampleYVal": 183,
                    "sampleYDrawVal": 3110
                  },
                  {
                    "sampleYVal": 198,
                    "sampleYDrawVal": 3778
                  },
                  {
                    "sampleYVal": 201,
                    "sampleYDrawVal": 3839
                  },
                  {
                    "sampleYVal": 199,
                    "sampleYDrawVal": 3763
                  },
                  {
                    "sampleYVal": 178,
                    "sampleYDrawVal": 2945
                  },
                  {
                    "sampleYVal": 156,
                    "sampleYDrawVal": 2211
                  },
                  {
                    "sampleYVal": 111,
                    "sampleYDrawVal": 1121
                  },
                  {
                    "sampleYVal": 101,
                    "sampleYDrawVal": 1041
                  },
                  {
                    "sampleYVal": 78,
                    "sampleYDrawVal": 781
                  },
                  {
                    "sampleYVal": 170,
                    "sampleYDrawVal": 2674
                  },
                  {
                    "sampleYVal": 182,
                    "sampleYDrawVal": 3153
                  },
                  {
                    "sampleYVal": 187,
                    "sampleYDrawVal": 3332
                  },
                  {
                    "sampleYVal": 190,
                    "sampleYDrawVal": 3398
                  },
                  {
                    "sampleYVal": 174,
                    "sampleYDrawVal": 2871
                  },
                  {
                    "sampleYVal": 170,
                    "sampleYDrawVal": 2712
                  },
                  {
                    "sampleYVal": 162,
                    "sampleYDrawVal": 2432
                  },
                  {
                    "sampleYVal": 143,
                    "sampleYDrawVal": 1863
                  },
                  {
                    "sampleYVal": 143,
                    "sampleYDrawVal": 2118
                  },
                  {
                    "sampleYVal": 174,
                    "sampleYDrawVal": 2888
                  },
                  {
                    "sampleYVal": 191,
                    "sampleYDrawVal": 3470
                  },
                  {
                    "sampleYVal": 195,
                    "sampleYDrawVal": 3624
                  },
                  {
                    "sampleYVal": 183,
                    "sampleYDrawVal": 3215
                  },
                  {
                    "sampleYVal": 182,
                    "sampleYDrawVal": 3059
                  },
                  {
                    "sampleYVal": 141,
                    "sampleYDrawVal": 1701
                  },
                  {
                    "sampleYVal": 65,
                    "sampleYDrawVal": 260
                  },
                  {
                    "sampleYVal": 31,
                    "sampleYDrawVal": 46
                  }
                ],
                "recordOffset": 1144,
                "seqUrl": "1527724623075_20633_709",
                "seqDescription": {
                  "analysis": {
                    "analysisType": "1sec"
                  },
                  "completeCount": 0,
                  "currentSeqPos": 0,
                  "pbId": "991",
                  "text": "הַדָּ",
                  "type": "word",
                  "word": {
                    "id": 991,
                    "language": "he",
                    "cleanInput": "הַדָּבָר",
                    "type": "word",
                    "data": "[{\"text\":\"\\u05d4\\u05b7\",\"sylType\":\"v\",\"vowel\":\"A\"},{\"text\":\"\\u05d3\\u05bc\\u05b8\",\"sylType\":\"cv\",\"vowel\":\"A\",\"isPlosive\":true},{\"text\":\"\\u05d1\\u05b8\\u05e8\",\"sylType\":\"cvc\",\"vowel\":\"A\",\"isContinous\":true}]",
                    "isNonesense": 0,
                    "audios": ""
                  }
                },
                "mistakesObjects": {
                  "gentleOnset": {
                    "titlei18Key": "MISTAKES.GENTLE_ONSET"
                  }
                },
                "hiddenMistakesObjects": {
                  "softPeak": {
                    "titlei18Key": "MISTAKES.SOFT_PEAK"
                  },
                  "tooLong": {
                    "titlei18Key": "MISTAKES.TOO_LONG"
                  }
                }
              },
              {
                "fieldType": "seq",
                "timerPos": 1044,
                "calibrationEventId": "6581",
                "rate": 80,
                "deltaTimeMsFromPrevSeq": "2889.00",
                "yvalues": [
                  "146",
                  "185",
                  "196",
                  "195",
                  "173",
                  "152",
                  "147",
                  "130",
                  "150",
                  "181",
                  "193",
                  "196",
                  "189",
                  "179",
                  "162",
                  "153",
                  "154",
                  "167",
                  "176",
                  "186",
                  "187",
                  "175",
                  "139",
                  "80",
                  "0"
                ],
                "yDrawvalues": "2885,3951,3890,3625,2481,2027,1991,1613,2479,3609,3809,3781,3266,2832,2198,2039,2380,2926,3182,3492,3343,2642,1367,203,0",
                "mistakes": [
                  {
                    "sampleOffset": 0,
                    "mistakeId": 1
                  }
                ],
                "hiddenMistakes": [
                  {
                    "sampleOffset": 2,
                    "mistakeId": 3
                  },
                  {
                    "sampleOffset": 11,
                    "mistakeId": 3
                  },
                  {
                    "sampleOffset": 20,
                    "mistakeId": 3
                  },
                  {
                    "sampleOffset": 24,
                    "mistakeId": 7
                  }
                ],
                "softVals": [
                  "1",
                  "0",
                  "0",
                  "0",
                  "1",
                  "1",
                  "1",
                  "1",
                  "1",
                  "0",
                  "0",
                  "0",
                  "0",
                  "0",
                  "1",
                  "1",
                  "1",
                  "1",
                  "1",
                  "0",
                  "0",
                  "1",
                  "1",
                  "1",
                  "1"
                ],
                "expectedSylsPerSecond": "0.00",
                "actualSylsPerSecond": "0.92",
                "timeLength": 3125,
                "features": [
                  {
                    "sampleYVal": 192,
                    "sampleYDrawVal": 3521
                  },
                  {
                    "sampleYVal": 204,
                    "sampleYDrawVal": 4001
                  },
                  {
                    "sampleYVal": 201,
                    "sampleYDrawVal": 3848
                  },
                  {
                    "sampleYVal": 176,
                    "sampleYDrawVal": 2853
                  },
                  {
                    "sampleYVal": 161,
                    "sampleYDrawVal": 2393
                  },
                  {
                    "sampleYVal": 145,
                    "sampleYDrawVal": 2049
                  },
                  {
                    "sampleYVal": 142,
                    "sampleYDrawVal": 1918
                  },
                  {
                    "sampleYVal": 127,
                    "sampleYDrawVal": 1598
                  },
                  {
                    "sampleYVal": 182,
                    "sampleYDrawVal": 3123
                  },
                  {
                    "sampleYVal": 196,
                    "sampleYDrawVal": 3682
                  },
                  {
                    "sampleYVal": 199,
                    "sampleYDrawVal": 3797
                  },
                  {
                    "sampleYVal": 195,
                    "sampleYDrawVal": 3630
                  },
                  {
                    "sampleYVal": 187,
                    "sampleYDrawVal": 3272
                  },
                  {
                    "sampleYVal": 165,
                    "sampleYDrawVal": 2532
                  },
                  {
                    "sampleYVal": 158,
                    "sampleYDrawVal": 2227
                  },
                  {
                    "sampleYVal": 135,
                    "sampleYDrawVal": 1685
                  },
                  {
                    "sampleYVal": 171,
                    "sampleYDrawVal": 2819
                  },
                  {
                    "sampleYVal": 181,
                    "sampleYDrawVal": 3176
                  },
                  {
                    "sampleYVal": 184,
                    "sampleYDrawVal": 3288
                  },
                  {
                    "sampleYVal": 195,
                    "sampleYDrawVal": 3635
                  },
                  {
                    "sampleYVal": 181,
                    "sampleYDrawVal": 3038
                  },
                  {
                    "sampleYVal": 168,
                    "sampleYDrawVal": 2547
                  },
                  {
                    "sampleYVal": 90,
                    "sampleYDrawVal": 606
                  },
                  {
                    "sampleYVal": 43,
                    "sampleYDrawVal": 98
                  }
                ],
                "recordOffset": 7562,
                "seqUrl": "1527724623075_20633_709",
                "seqDescription": {
                  "analysis": {
                    "analysisType": "1sec"
                  },
                  "completeCount": 1,
                  "currentSeqPos": 0,
                  "pbId": "991",
                  "text": "הַדָּ",
                  "type": "word",
                  "word": {
                    "id": 991,
                    "language": "he",
                    "cleanInput": "הַדָּבָר",
                    "type": "word",
                    "data": "[{\"text\":\"\\u05d4\\u05b7\",\"sylType\":\"v\",\"vowel\":\"A\"},{\"text\":\"\\u05d3\\u05bc\\u05b8\",\"sylType\":\"cv\",\"vowel\":\"A\",\"isPlosive\":true},{\"text\":\"\\u05d1\\u05b8\\u05e8\",\"sylType\":\"cvc\",\"vowel\":\"A\",\"isContinous\":true}]",
                    "isNonesense": 0,
                    "audios": ""
                  }
                },
                "mistakesObjects": {
                  "gentleOnset": {
                    "titlei18Key": "MISTAKES.GENTLE_ONSET"
                  }
                },
                "hiddenMistakesObjects": {
                  "softPeak": {
                    "titlei18Key": "MISTAKES.SOFT_PEAK"
                  },
                  "tooLong": {
                    "titlei18Key": "MISTAKES.TOO_LONG"
                  }
                }
              },
              {
                "fieldType": "seq",
                "timerPos": 1051,
                "calibrationEventId": "6581",
                "rate": 100,
                "deltaTimeMsFromPrevSeq": "3133.00",
                "yvalues": [
                  "130",
                  "174",
                  "196",
                  "197",
                  "190",
                  "169",
                  "152",
                  "140",
                  "141",
                  "171",
                  "188",
                  "196",
                  "198",
                  "190",
                  "175",
                  "161",
                  "145",
                  "156",
                  "178",
                  "190",
                  "197",
                  "184",
                  "132",
                  "77",
                  "0"
                ],
                "yDrawvalues": "2636,3612,4072,3694,3324,2369,1937,1786,2108,3283,3725,3907,3863,3319,2659,2273,2026,2620,3383,3689,3880,3003,1121,226,0",
                "mistakes": [],
                "hiddenMistakes": [],
                "softVals": [
                  "1",
                  "1",
                  "0",
                  "0",
                  "0",
                  "1",
                  "1",
                  "1",
                  "1",
                  "1",
                  "0",
                  "0",
                  "0",
                  "0",
                  "1",
                  "1",
                  "1",
                  "1",
                  "0",
                  "0",
                  "0",
                  "0",
                  "1",
                  "1",
                  "1"
                ],
                "expectedSylsPerSecond": "0.00",
                "actualSylsPerSecond": "0.87",
                "timeLength": 3125,
                "features": [
                  {
                    "sampleYVal": 176,
                    "sampleYDrawVal": 2947
                  },
                  {
                    "sampleYVal": 203,
                    "sampleYDrawVal": 3956
                  },
                  {
                    "sampleYVal": 206,
                    "sampleYDrawVal": 4044
                  },
                  {
                    "sampleYVal": 197,
                    "sampleYDrawVal": 3687
                  },
                  {
                    "sampleYVal": 177,
                    "sampleYDrawVal": 2889
                  },
                  {
                    "sampleYVal": 151,
                    "sampleYDrawVal": 2077
                  },
                  {
                    "sampleYVal": 139,
                    "sampleYDrawVal": 1755
                  },
                  {
                    "sampleYVal": 115,
                    "sampleYDrawVal": 1340
                  },
                  {
                    "sampleYVal": 172,
                    "sampleYDrawVal": 2857
                  },
                  {
                    "sampleYVal": 192,
                    "sampleYDrawVal": 3578
                  },
                  {
                    "sampleYVal": 195,
                    "sampleYDrawVal": 3682
                  },
                  {
                    "sampleYVal": 201,
                    "sampleYDrawVal": 3939
                  },
                  {
                    "sampleYVal": 195,
                    "sampleYDrawVal": 3669
                  },
                  {
                    "sampleYVal": 182,
                    "sampleYDrawVal": 3070
                  },
                  {
                    "sampleYVal": 159,
                    "sampleYDrawVal": 2332
                  },
                  {
                    "sampleYVal": 152,
                    "sampleYDrawVal": 2147
                  },
                  {
                    "sampleYVal": 135,
                    "sampleYDrawVal": 2032
                  },
                  {
                    "sampleYVal": 174,
                    "sampleYDrawVal": 2923
                  },
                  {
                    "sampleYVal": 194,
                    "sampleYDrawVal": 3638
                  },
                  {
                    "sampleYVal": 200,
                    "sampleYDrawVal": 3871
                  },
                  {
                    "sampleYVal": 197,
                    "sampleYDrawVal": 3687
                  },
                  {
                    "sampleYVal": 158,
                    "sampleYDrawVal": 2240
                  },
                  {
                    "sampleYVal": 82,
                    "sampleYDrawVal": 507
                  },
                  {
                    "sampleYVal": 26,
                    "sampleYDrawVal": 30
                  }
                ],
                "recordOffset": 14141,
                "seqUrl": "1527724623075_20633_709",
                "seqDescription": {
                  "analysis": {
                    "analysisType": "1sec"
                  },
                  "completeCount": 2,
                  "currentSeqPos": 0,
                  "pbId": "991",
                  "text": "הַדָּ",
                  "type": "word",
                  "word": {
                    "id": 991,
                    "language": "he",
                    "cleanInput": "הַדָּבָר",
                    "type": "word",
                    "data": "[{\"text\":\"\\u05d4\\u05b7\",\"sylType\":\"v\",\"vowel\":\"A\"},{\"text\":\"\\u05d3\\u05bc\\u05b8\",\"sylType\":\"cv\",\"vowel\":\"A\",\"isPlosive\":true},{\"text\":\"\\u05d1\\u05b8\\u05e8\",\"sylType\":\"cvc\",\"vowel\":\"A\",\"isContinous\":true}]",
                    "isNonesense": 0,
                    "audios": ""
                  }
                },
                "mistakesObjects": {},
                "hiddenMistakesObjects": {}
              },
              {
                "fieldType": "seq",
                "timerPos": 1057,
                "calibrationEventId": "6581",
                "rate": 100,
                "deltaTimeMsFromPrevSeq": "3066.00",
                "yvalues": [
                  "122",
                  "162",
                  "181",
                  "189",
                  "198",
                  "202",
                  "190",
                  "183",
                  "170",
                  "136",
                  "165",
                  "184",
                  "194",
                  "201",
                  "197",
                  "186",
                  "175",
                  "168",
                  "154",
                  "166",
                  "181",
                  "189",
                  "191",
                  "185",
                  "177",
                  "121",
                  "61",
                  "0"
                ],
                "yDrawvalues": "1882,3032,3397,3473,3932,3972,3174,2949,2480,1555,3009,3568,3832,4020,3594,3062,2712,2565,2252,2853,3420,3634,3587,3219,2883,910,68,0",
                "mistakes": [],
                "hiddenMistakes": [],
                "softVals": [
                  "1",
                  "1",
                  "0",
                  "0",
                  "0",
                  "0",
                  "0",
                  "0",
                  "1",
                  "1",
                  "1",
                  "0",
                  "0",
                  "0",
                  "0",
                  "0",
                  "1",
                  "1",
                  "1",
                  "1",
                  "0",
                  "0",
                  "0",
                  "0",
                  "0",
                  "1",
                  "1",
                  "1"
                ],
                "expectedSylsPerSecond": "0.00",
                "actualSylsPerSecond": "0.81",
                "timeLength": 3500,
                "features": [
                  {
                    "sampleYVal": 167,
                    "sampleYDrawVal": 2584
                  },
                  {
                    "sampleYVal": 182,
                    "sampleYDrawVal": 3088
                  },
                  {
                    "sampleYVal": 197,
                    "sampleYDrawVal": 3672
                  },
                  {
                    "sampleYVal": 196,
                    "sampleYDrawVal": 3610
                  },
                  {
                    "sampleYVal": 216,
                    "sampleYDrawVal": 4516
                  },
                  {
                    "sampleYVal": 200,
                    "sampleYDrawVal": 3779
                  },
                  {
                    "sampleYVal": 178,
                    "sampleYDrawVal": 2895
                  },
                  {
                    "sampleYVal": 180,
                    "sampleYDrawVal": 2914
                  },
                  {
                    "sampleYVal": 141,
                    "sampleYDrawVal": 1823
                  },
                  {
                    "sampleYVal": 157,
                    "sampleYDrawVal": 2292
                  },
                  {
                    "sampleYVal": 190,
                    "sampleYDrawVal": 3413
                  },
                  {
                    "sampleYVal": 196,
                    "sampleYDrawVal": 3715
                  },
                  {
                    "sampleYVal": 199,
                    "sampleYDrawVal": 3854
                  },
                  {
                    "sampleYVal": 206,
                    "sampleYDrawVal": 4046
                  },
                  {
                    "sampleYVal": 191,
                    "sampleYDrawVal": 3369
                  },
                  {
                    "sampleYVal": 180,
                    "sampleYDrawVal": 2971
                  },
                  {
                    "sampleYVal": 168,
                    "sampleYDrawVal": 2587
                  },
                  {
                    "sampleYVal": 157,
                    "sampleYDrawVal": 2341
                  },
                  {
                    "sampleYVal": 160,
                    "sampleYDrawVal": 2607
                  },
                  {
                    "sampleYVal": 176,
                    "sampleYDrawVal": 2975
                  },
                  {
                    "sampleYVal": 192,
                    "sampleYDrawVal": 3516
                  },
                  {
                    "sampleYVal": 197,
                    "sampleYDrawVal": 3748
                  },
                  {
                    "sampleYVal": 189,
                    "sampleYDrawVal": 3465
                  },
                  {
                    "sampleYVal": 183,
                    "sampleYDrawVal": 3171
                  },
                  {
                    "sampleYVal": 167,
                    "sampleYDrawVal": 2626
                  },
                  {
                    "sampleYVal": 51,
                    "sampleYDrawVal": 145
                  },
                  {
                    "sampleYVal": 20,
                    "sampleYDrawVal": 18
                  }
                ],
                "recordOffset": 20540,
                "seqUrl": "1527724623075_20633_709",
                "seqDescription": {
                  "analysis": {
                    "analysisType": "1sec"
                  },
                  "completeCount": 3,
                  "currentSeqPos": 0,
                  "pbId": "991",
                  "text": "הַדָּ",
                  "type": "word",
                  "word": {
                    "id": 991,
                    "language": "he",
                    "cleanInput": "הַדָּבָר",
                    "type": "word",
                    "data": "[{\"text\":\"\\u05d4\\u05b7\",\"sylType\":\"v\",\"vowel\":\"A\"},{\"text\":\"\\u05d3\\u05bc\\u05b8\",\"sylType\":\"cv\",\"vowel\":\"A\",\"isPlosive\":true},{\"text\":\"\\u05d1\\u05b8\\u05e8\",\"sylType\":\"cvc\",\"vowel\":\"A\",\"isContinous\":true}]",
                    "isNonesense": 0,
                    "audios": ""
                  }
                },
                "mistakesObjects": {},
                "hiddenMistakesObjects": {}
              },
              {
                "fieldType": "seq",
                "timerPos": 1064,
                "calibrationEventId": "6581",
                "rate": 100,
                "deltaTimeMsFromPrevSeq": "2699.00",
                "yvalues": [
                  "114",
                  "160",
                  "183",
                  "196",
                  "190",
                  "176",
                  "164",
                  "155",
                  "119",
                  "138",
                  "157",
                  "172",
                  "190",
                  "189",
                  "180",
                  "171",
                  "151",
                  "128",
                  "138",
                  "158",
                  "176",
                  "195",
                  "191",
                  "156",
                  "89",
                  "0"
                ],
                "yDrawvalues": "1864,2993,3544,3900,3340,2598,2249,2120,1230,2151,2677,3121,3752,3459,2983,2673,1996,1592,2057,2660,3222,3950,3460,1946,287,0",
                "mistakes": [],
                "hiddenMistakes": [],
                "softVals": [
                  "1",
                  "1",
                  "0",
                  "0",
                  "0",
                  "1",
                  "1",
                  "1",
                  "1",
                  "1",
                  "1",
                  "1",
                  "0",
                  "0",
                  "0",
                  "1",
                  "1",
                  "1",
                  "1",
                  "1",
                  "1",
                  "0",
                  "0",
                  "1",
                  "1",
                  "1"
                ],
                "expectedSylsPerSecond": "0.00",
                "actualSylsPerSecond": "0.86",
                "timeLength": 3250,
                "features": [
                  {
                    "sampleYVal": 167,
                    "sampleYDrawVal": 2578
                  },
                  {
                    "sampleYVal": 191,
                    "sampleYDrawVal": 3419
                  },
                  {
                    "sampleYVal": 202,
                    "sampleYDrawVal": 3910
                  },
                  {
                    "sampleYVal": 198,
                    "sampleYDrawVal": 3702
                  },
                  {
                    "sampleYVal": 173,
                    "sampleYDrawVal": 2778
                  },
                  {
                    "sampleYVal": 163,
                    "sampleYDrawVal": 2385
                  },
                  {
                    "sampleYVal": 160,
                    "sampleYDrawVal": 2286
                  },
                  {
                    "sampleYVal": 143,
                    "sampleYDrawVal": 1883
                  },
                  {
                    "sampleYVal": 105,
                    "sampleYDrawVal": 1428
                  },
                  {
                    "sampleYVal": 161,
                    "sampleYDrawVal": 2467
                  },
                  {
                    "sampleYVal": 165,
                    "sampleYDrawVal": 2647
                  },
                  {
                    "sampleYVal": 193,
                    "sampleYDrawVal": 3567
                  },
                  {
                    "sampleYVal": 196,
                    "sampleYDrawVal": 3676
                  },
                  {
                    "sampleYVal": 178,
                    "sampleYDrawVal": 3123
                  },
                  {
                    "sampleYVal": 169,
                    "sampleYDrawVal": 2660
                  },
                  {
                    "sampleYVal": 152,
                    "sampleYDrawVal": 2257
                  },
                  {
                    "sampleYVal": 133,
                    "sampleYDrawVal": 1765
                  },
                  {
                    "sampleYVal": 131,
                    "sampleYDrawVal": 1816
                  },
                  {
                    "sampleYVal": 159,
                    "sampleYDrawVal": 2443
                  },
                  {
                    "sampleYVal": 178,
                    "sampleYDrawVal": 2998
                  },
                  {
                    "sampleYVal": 185,
                    "sampleYDrawVal": 3240
                  },
                  {
                    "sampleYVal": 199,
                    "sampleYDrawVal": 3718
                  },
                  {
                    "sampleYVal": 172,
                    "sampleYDrawVal": 2840
                  },
                  {
                    "sampleYVal": 105,
                    "sampleYDrawVal": 1010
                  },
                  {
                    "sampleYVal": 44,
                    "sampleYDrawVal": 120
                  }
                ],
                "recordOffset": -2994,
                "seqUrl": "1527724653075_20633_709",
                "seqDescription": {
                  "analysis": {
                    "analysisType": "1sec"
                  },
                  "completeCount": 4,
                  "currentSeqPos": 0,
                  "pbId": "991",
                  "text": "הַדָּ",
                  "type": "word",
                  "word": {
                    "id": 991,
                    "language": "he",
                    "cleanInput": "הַדָּבָר",
                    "type": "word",
                    "data": "[{\"text\":\"\\u05d4\\u05b7\",\"sylType\":\"v\",\"vowel\":\"A\"},{\"text\":\"\\u05d3\\u05bc\\u05b8\",\"sylType\":\"cv\",\"vowel\":\"A\",\"isPlosive\":true},{\"text\":\"\\u05d1\\u05b8\\u05e8\",\"sylType\":\"cvc\",\"vowel\":\"A\",\"isContinous\":true}]",
                    "isNonesense": 0,
                    "audios": ""
                  }
                },
                "mistakesObjects": {},
                "hiddenMistakesObjects": {}
              },
              {
                "fieldType": "seq",
                "timerPos": 1070,
                "calibrationEventId": "6581",
                "rate": 100,
                "deltaTimeMsFromPrevSeq": "2820.00",
                "yvalues": [
                  "115",
                  "156",
                  "186",
                  "190",
                  "183",
                  "169",
                  "167",
                  "153",
                  "140",
                  "161",
                  "182",
                  "190",
                  "192",
                  "191",
                  "186",
                  "175",
                  "157",
                  "164",
                  "175",
                  "189",
                  "194",
                  "196",
                  "169",
                  "115",
                  "68",
                  "0"
                ],
                "yDrawvalues": "1556,2854,3727,3440,3086,2393,2483,2015,1699,2735,3514,3513,3493,3423,3158,2620,2043,2604,3106,3680,3710,3677,2263,623,156,0",
                "mistakes": [],
                "hiddenMistakes": [],
                "softVals": [
                  "1",
                  "1",
                  "0",
                  "0",
                  "0",
                  "1",
                  "1",
                  "1",
                  "1",
                  "1",
                  "0",
                  "0",
                  "0",
                  "0",
                  "0",
                  "1",
                  "1",
                  "1",
                  "1",
                  "0",
                  "0",
                  "0",
                  "1",
                  "1",
                  "1",
                  "1"
                ],
                "expectedSylsPerSecond": "0.00",
                "actualSylsPerSecond": "0.87",
                "timeLength": 3250,
                "features": [
                  {
                    "sampleYVal": 158,
                    "sampleYDrawVal": 2225
                  },
                  {
                    "sampleYVal": 198,
                    "sampleYDrawVal": 3747
                  },
                  {
                    "sampleYVal": 195,
                    "sampleYDrawVal": 3546
                  },
                  {
                    "sampleYVal": 191,
                    "sampleYDrawVal": 3422
                  },
                  {
                    "sampleYVal": 176,
                    "sampleYDrawVal": 2860
                  },
                  {
                    "sampleYVal": 147,
                    "sampleYDrawVal": 1906
                  },
                  {
                    "sampleYVal": 170,
                    "sampleYDrawVal": 2534
                  },
                  {
                    "sampleYVal": 129,
                    "sampleYDrawVal": 1654
                  },
                  {
                    "sampleYVal": 155,
                    "sampleYDrawVal": 2246
                  },
                  {
                    "sampleYVal": 180,
                    "sampleYDrawVal": 3123
                  },
                  {
                    "sampleYVal": 195,
                    "sampleYDrawVal": 3558
                  },
                  {
                    "sampleYVal": 192,
                    "sampleYDrawVal": 3366
                  },
                  {
                    "sampleYVal": 196,
                    "sampleYDrawVal": 3610
                  },
                  {
                    "sampleYVal": 189,
                    "sampleYDrawVal": 3344
                  },
                  {
                    "sampleYVal": 174,
                    "sampleYDrawVal": 2745
                  },
                  {
                    "sampleYVal": 166,
                    "sampleYDrawVal": 2548
                  },
                  {
                    "sampleYVal": 154,
                    "sampleYDrawVal": 2275
                  },
                  {
                    "sampleYVal": 171,
                    "sampleYDrawVal": 2704
                  },
                  {
                    "sampleYVal": 196,
                    "sampleYDrawVal": 3676
                  },
                  {
                    "sampleYVal": 193,
                    "sampleYDrawVal": 3565
                  },
                  {
                    "sampleYVal": 201,
                    "sampleYDrawVal": 3864
                  },
                  {
                    "sampleYVal": 185,
                    "sampleYDrawVal": 3178
                  },
                  {
                    "sampleYVal": 125,
                    "sampleYDrawVal": 1252
                  },
                  {
                    "sampleYVal": 82,
                    "sampleYDrawVal": 487
                  },
                  {
                    "sampleYVal": 34,
                    "sampleYDrawVal": 53
                  }
                ],
                "recordOffset": 3269,
                "seqUrl": "1527724653075_20633_709",
                "seqDescription": {
                  "analysis": {
                    "analysisType": "1sec"
                  },
                  "completeCount": 0,
                  "currentSeqPos": 0,
                  "pbId": "1830",
                  "text": "שֶׁהִכְ",
                  "type": "word",
                  "word": {
                    "id": 1830,
                    "language": "he",
                    "cleanInput": "שֶׁהִכְרִיז",
                    "type": "word",
                    "data": "[{\"text\":\"\\u05e9\\u05c1\\u05b6\",\"sylType\":\"cv\",\"vowel\":\"E\",\"isContinous\":false},{\"text\":\"\\u05d4\\u05b4\\u05db\\u05b0\",\"sylType\":\"vc\",\"vowel\":\"I\",\"isContinous\":true},{\"text\":\"\\u05e8\\u05b4\\u05d9\\u05d6\",\"sylType\":\"cvc\",\"vowel\":\"I\",\"isContinous\":true}]",
                    "isNonesense": 0,
                    "audios": "{\"intoQuestionOneSec\":{\"desc\":\"Intonation question 1 sec\",\"filePath\":\"intoQuestionOneSec\",\"isIntonation\":true,\"analysisType\":{\"type\":\"1sec\"}},\"intoQuestionTwoSec\":{\"desc\":\"Intonation question 2 sec\",\"filePath\":\"intoQuestionTwoSec\",\"isIntonation\":true,\"analysisType\":{\"type\":\"2sec\"}},\"intoQuestionManaged\":{\"desc\":\"Intonation question managed\",\"filePath\":\"intoQuestionManaged\",\"isIntonation\":true,\"analysisType\":{\"type\":\"managed\"}},\"intoWonderOneSec\":{\"desc\":\"Intonation wounder 1 sec\",\"filePath\":\"intoWonderOneSec\",\"isIntonation\":true,\"analysisType\":{\"type\":\"1sec\"}},\"intoWonderTwoSec\":{\"desc\":\"Intonation wounder 2 sec\",\"filePath\":\"intoWonderTwoSec\",\"isIntonation\":true,\"analysisType\":{\"type\":\"2sec\"}},\"intoWonderManaged\":{\"desc\":\"Intonation wounder managed\",\"filePath\":\"intoWonderManaged\",\"isIntonation\":true,\"analysisType\":{\"type\":\"managed\"}},\"intoStatementsOneSec\":{\"desc\":\"Intonation statements 1 sec\",\"filePath\":\"intoStatementsOneSec\",\"isIntonation\":true,\"analysisType\":{\"type\":\"1sec\"}},\"intoStatementsTwoSec\":{\"desc\":\"Intonation statements 2 sec\",\"filePath\":\"intoStatementsTwoSec\",\"isIntonation\":true,\"analysisType\":{\"type\":\"2sec\"}},\"intoStatementsManaged\":{\"desc\":\"Intonation statements managed\",\"filePath\":\"intoStatementsManaged\",\"isIntonation\":true,\"analysisType\":{\"type\":\"managed\"}}}"
                  }
                },
                "mistakesObjects": {},
                "hiddenMistakesObjects": {}
              },
              {
                "fieldType": "seq",
                "timerPos": 1076,
                "calibrationEventId": "6581",
                "rate": 80,
                "deltaTimeMsFromPrevSeq": "2880.00",
                "yvalues": [
                  "137",
                  "170",
                  "185",
                  "199",
                  "202",
                  "187",
                  "173",
                  "156",
                  "140",
                  "154",
                  "176",
                  "196",
                  "200",
                  "199",
                  "183",
                  "164",
                  "119",
                  "81",
                  "100",
                  "135",
                  "165",
                  "181",
                  "188",
                  "172",
                  "146",
                  "97",
                  "0"
                ],
                "yDrawvalues": "2480,3195,3464,4092,3996,3072,2524,1968,1539,2345,3258,4071,3874,3748,2875,2275,898,370,1181,2130,3000,3390,3483,2540,1690,622,0",
                "mistakes": [
                  {
                    "sampleOffset": 0,
                    "mistakeId": 1
                  }
                ],
                "hiddenMistakes": [
                  {
                    "sampleOffset": 4,
                    "mistakeId": 3
                  },
                  {
                    "sampleOffset": 12,
                    "mistakeId": 3
                  },
                  {
                    "sampleOffset": 22,
                    "mistakeId": 3
                  },
                  {
                    "sampleOffset": 26,
                    "mistakeId": 7
                  }
                ],
                "softVals": [
                  "1",
                  "1",
                  "0",
                  "0",
                  "0",
                  "0",
                  "1",
                  "1",
                  "1",
                  "1",
                  "1",
                  "0",
                  "0",
                  "0",
                  "0",
                  "1",
                  "1",
                  "1",
                  "1",
                  "1",
                  "1",
                  "0",
                  "0",
                  "1",
                  "1",
                  "1",
                  "1"
                ],
                "expectedSylsPerSecond": "0.00",
                "actualSylsPerSecond": "0.85",
                "timeLength": 3375,
                "features": [
                  {
                    "sampleYVal": 164,
                    "sampleYDrawVal": 2523
                  },
                  {
                    "sampleYVal": 188,
                    "sampleYDrawVal": 3364
                  },
                  {
                    "sampleYVal": 199,
                    "sampleYDrawVal": 3774
                  },
                  {
                    "sampleYVal": 209,
                    "sampleYDrawVal": 4204
                  },
                  {
                    "sampleYVal": 197,
                    "sampleYDrawVal": 3690
                  },
                  {
                    "sampleYVal": 168,
                    "sampleYDrawVal": 2563
                  },
                  {
                    "sampleYVal": 159,
                    "sampleYDrawVal": 2269
                  },
                  {
                    "sampleYVal": 144,
                    "sampleYDrawVal": 1784
                  },
                  {
                    "sampleYVal": 139,
                    "sampleYDrawVal": 1659
                  },
                  {
                    "sampleYVal": 173,
                    "sampleYDrawVal": 2727
                  },
                  {
                    "sampleYVal": 199,
                    "sampleYDrawVal": 3804
                  },
                  {
                    "sampleYVal": 208,
                    "sampleYDrawVal": 4200
                  },
                  {
                    "sampleYVal": 199,
                    "sampleYDrawVal": 3756
                  },
                  {
                    "sampleYVal": 194,
                    "sampleYDrawVal": 3539
                  },
                  {
                    "sampleYVal": 172,
                    "sampleYDrawVal": 2696
                  },
                  {
                    "sampleYVal": 143,
                    "sampleYDrawVal": 1859
                  },
                  {
                    "sampleYVal": 61,
                    "sampleYDrawVal": 262
                  },
                  {
                    "sampleYVal": 76,
                    "sampleYDrawVal": 519
                  },
                  {
                    "sampleYVal": 134,
                    "sampleYDrawVal": 1706
                  },
                  {
                    "sampleYVal": 155,
                    "sampleYDrawVal": 2245
                  },
                  {
                    "sampleYVal": 195,
                    "sampleYDrawVal": 3616
                  },
                  {
                    "sampleYVal": 188,
                    "sampleYDrawVal": 3326
                  },
                  {
                    "sampleYVal": 190,
                    "sampleYDrawVal": 3389
                  },
                  {
                    "sampleYVal": 149,
                    "sampleYDrawVal": 2036
                  },
                  {
                    "sampleYVal": 127,
                    "sampleYDrawVal": 1494
                  },
                  {
                    "sampleYVal": 40,
                    "sampleYDrawVal": 146
                  }
                ],
                "recordOffset": 9554,
                "seqUrl": "1527724653075_20633_709",
                "seqDescription": {
                  "analysis": {
                    "analysisType": "1sec"
                  },
                  "completeCount": 1,
                  "currentSeqPos": 0,
                  "pbId": "1830",
                  "text": "שֶׁהִכְ",
                  "type": "word",
                  "word": {
                    "id": 1830,
                    "language": "he",
                    "cleanInput": "שֶׁהִכְרִיז",
                    "type": "word",
                    "data": "[{\"text\":\"\\u05e9\\u05c1\\u05b6\",\"sylType\":\"cv\",\"vowel\":\"E\",\"isContinous\":false},{\"text\":\"\\u05d4\\u05b4\\u05db\\u05b0\",\"sylType\":\"vc\",\"vowel\":\"I\",\"isContinous\":true},{\"text\":\"\\u05e8\\u05b4\\u05d9\\u05d6\",\"sylType\":\"cvc\",\"vowel\":\"I\",\"isContinous\":true}]",
                    "isNonesense": 0,
                    "audios": "{\"intoQuestionOneSec\":{\"desc\":\"Intonation question 1 sec\",\"filePath\":\"intoQuestionOneSec\",\"isIntonation\":true,\"analysisType\":{\"type\":\"1sec\"}},\"intoQuestionTwoSec\":{\"desc\":\"Intonation question 2 sec\",\"filePath\":\"intoQuestionTwoSec\",\"isIntonation\":true,\"analysisType\":{\"type\":\"2sec\"}},\"intoQuestionManaged\":{\"desc\":\"Intonation question managed\",\"filePath\":\"intoQuestionManaged\",\"isIntonation\":true,\"analysisType\":{\"type\":\"managed\"}},\"intoWonderOneSec\":{\"desc\":\"Intonation wounder 1 sec\",\"filePath\":\"intoWonderOneSec\",\"isIntonation\":true,\"analysisType\":{\"type\":\"1sec\"}},\"intoWonderTwoSec\":{\"desc\":\"Intonation wounder 2 sec\",\"filePath\":\"intoWonderTwoSec\",\"isIntonation\":true,\"analysisType\":{\"type\":\"2sec\"}},\"intoWonderManaged\":{\"desc\":\"Intonation wounder managed\",\"filePath\":\"intoWonderManaged\",\"isIntonation\":true,\"analysisType\":{\"type\":\"managed\"}},\"intoStatementsOneSec\":{\"desc\":\"Intonation statements 1 sec\",\"filePath\":\"intoStatementsOneSec\",\"isIntonation\":true,\"analysisType\":{\"type\":\"1sec\"}},\"intoStatementsTwoSec\":{\"desc\":\"Intonation statements 2 sec\",\"filePath\":\"intoStatementsTwoSec\",\"isIntonation\":true,\"analysisType\":{\"type\":\"2sec\"}},\"intoStatementsManaged\":{\"desc\":\"Intonation statements managed\",\"filePath\":\"intoStatementsManaged\",\"isIntonation\":true,\"analysisType\":{\"type\":\"managed\"}}}"
                  }
                },
                "mistakesObjects": {
                  "gentleOnset": {
                    "titlei18Key": "MISTAKES.GENTLE_ONSET"
                  }
                },
                "hiddenMistakesObjects": {
                  "softPeak": {
                    "titlei18Key": "MISTAKES.SOFT_PEAK"
                  },
                  "tooLong": {
                    "titlei18Key": "MISTAKES.TOO_LONG"
                  }
                }
              },
              {
                "fieldType": "seq",
                "timerPos": 1082,
                "calibrationEventId": "6581",
                "rate": 100,
                "deltaTimeMsFromPrevSeq": "2620.00",
                "yvalues": [
                  "124",
                  "162",
                  "181",
                  "191",
                  "185",
                  "168",
                  "149",
                  "136",
                  "136",
                  "155",
                  "173",
                  "185",
                  "187",
                  "176",
                  "161",
                  "152",
                  "115",
                  "125",
                  "151",
                  "163",
                  "165",
                  "167",
                  "160",
                  "135",
                  "90",
                  "0"
                ],
                "yDrawvalues": "2032,3021,3355,3641,3066,2299,1736,1489,1617,2472,3116,3441,3343,2728,2225,2006,844,1682,2481,2605,2529,2623,2302,1402,501,0",
                "mistakes": [],
                "hiddenMistakes": [],
                "softVals": [
                  "1",
                  "1",
                  "0",
                  "0",
                  "0",
                  "1",
                  "1",
                  "1",
                  "1",
                  "1",
                  "1",
                  "0",
                  "0",
                  "1",
                  "1",
                  "1",
                  "1",
                  "1",
                  "1",
                  "1",
                  "1",
                  "1",
                  "1",
                  "1",
                  "1",
                  "1"
                ],
                "expectedSylsPerSecond": "0.00",
                "actualSylsPerSecond": "0.87",
                "timeLength": 3250,
                "features": [
                  {
                    "sampleYVal": 160,
                    "sampleYDrawVal": 2352
                  },
                  {
                    "sampleYVal": 192,
                    "sampleYDrawVal": 3488
                  },
                  {
                    "sampleYVal": 198,
                    "sampleYDrawVal": 3686
                  },
                  {
                    "sampleYVal": 184,
                    "sampleYDrawVal": 3115
                  },
                  {
                    "sampleYVal": 172,
                    "sampleYDrawVal": 2636
                  },
                  {
                    "sampleYVal": 148,
                    "sampleYDrawVal": 1900
                  },
                  {
                    "sampleYVal": 136,
                    "sampleYDrawVal": 1544
                  },
                  {
                    "sampleYVal": 118,
                    "sampleYDrawVal": 1259
                  },
                  {
                    "sampleYVal": 147,
                    "sampleYDrawVal": 1873
                  },
                  {
                    "sampleYVal": 175,
                    "sampleYDrawVal": 2870
                  },
                  {
                    "sampleYVal": 182,
                    "sampleYDrawVal": 3131
                  },
                  {
                    "sampleYVal": 193,
                    "sampleYDrawVal": 3555
                  },
                  {
                    "sampleYVal": 187,
                    "sampleYDrawVal": 3237
                  },
                  {
                    "sampleYVal": 168,
                    "sampleYDrawVal": 2584
                  },
                  {
                    "sampleYVal": 147,
                    "sampleYDrawVal": 2006
                  },
                  {
                    "sampleYVal": 148,
                    "sampleYDrawVal": 1921
                  },
                  {
                    "sampleYVal": 90,
                    "sampleYDrawVal": 890
                  },
                  {
                    "sampleYVal": 158,
                    "sampleYDrawVal": 2286
                  },
                  {
                    "sampleYVal": 169,
                    "sampleYDrawVal": 2664
                  },
                  {
                    "sampleYVal": 164,
                    "sampleYDrawVal": 2452
                  },
                  {
                    "sampleYVal": 171,
                    "sampleYDrawVal": 2713
                  },
                  {
                    "sampleYVal": 164,
                    "sampleYDrawVal": 2476
                  },
                  {
                    "sampleYVal": 141,
                    "sampleYDrawVal": 1783
                  },
                  {
                    "sampleYVal": 112,
                    "sampleYDrawVal": 1151
                  },
                  {
                    "sampleYVal": 48,
                    "sampleYDrawVal": 224
                  }
                ],
                "recordOffset": 15759,
                "seqUrl": "1527724653075_20633_709",
                "seqDescription": {
                  "analysis": {
                    "analysisType": "1sec"
                  },
                  "completeCount": 2,
                  "currentSeqPos": 0,
                  "pbId": "1830",
                  "text": "שֶׁהִכְ",
                  "type": "word",
                  "word": {
                    "id": 1830,
                    "language": "he",
                    "cleanInput": "שֶׁהִכְרִיז",
                    "type": "word",
                    "data": "[{\"text\":\"\\u05e9\\u05c1\\u05b6\",\"sylType\":\"cv\",\"vowel\":\"E\",\"isContinous\":false},{\"text\":\"\\u05d4\\u05b4\\u05db\\u05b0\",\"sylType\":\"vc\",\"vowel\":\"I\",\"isContinous\":true},{\"text\":\"\\u05e8\\u05b4\\u05d9\\u05d6\",\"sylType\":\"cvc\",\"vowel\":\"I\",\"isContinous\":true}]",
                    "isNonesense": 0,
                    "audios": "{\"intoQuestionOneSec\":{\"desc\":\"Intonation question 1 sec\",\"filePath\":\"intoQuestionOneSec\",\"isIntonation\":true,\"analysisType\":{\"type\":\"1sec\"}},\"intoQuestionTwoSec\":{\"desc\":\"Intonation question 2 sec\",\"filePath\":\"intoQuestionTwoSec\",\"isIntonation\":true,\"analysisType\":{\"type\":\"2sec\"}},\"intoQuestionManaged\":{\"desc\":\"Intonation question managed\",\"filePath\":\"intoQuestionManaged\",\"isIntonation\":true,\"analysisType\":{\"type\":\"managed\"}},\"intoWonderOneSec\":{\"desc\":\"Intonation wounder 1 sec\",\"filePath\":\"intoWonderOneSec\",\"isIntonation\":true,\"analysisType\":{\"type\":\"1sec\"}},\"intoWonderTwoSec\":{\"desc\":\"Intonation wounder 2 sec\",\"filePath\":\"intoWonderTwoSec\",\"isIntonation\":true,\"analysisType\":{\"type\":\"2sec\"}},\"intoWonderManaged\":{\"desc\":\"Intonation wounder managed\",\"filePath\":\"intoWonderManaged\",\"isIntonation\":true,\"analysisType\":{\"type\":\"managed\"}},\"intoStatementsOneSec\":{\"desc\":\"Intonation statements 1 sec\",\"filePath\":\"intoStatementsOneSec\",\"isIntonation\":true,\"analysisType\":{\"type\":\"1sec\"}},\"intoStatementsTwoSec\":{\"desc\":\"Intonation statements 2 sec\",\"filePath\":\"intoStatementsTwoSec\",\"isIntonation\":true,\"analysisType\":{\"type\":\"2sec\"}},\"intoStatementsManaged\":{\"desc\":\"Intonation statements managed\",\"filePath\":\"intoStatementsManaged\",\"isIntonation\":true,\"analysisType\":{\"type\":\"managed\"}}}"
                  }
                },
                "mistakesObjects": {},
                "hiddenMistakesObjects": {}
              },
              {
                "fieldType": "seq",
                "timerPos": 1089,
                "calibrationEventId": "6581",
                "rate": 100,
                "deltaTimeMsFromPrevSeq": "2883.00",
                "yvalues": [
                  "115",
                  "160",
                  "187",
                  "195",
                  "183",
                  "161",
                  "149",
                  "136",
                  "139",
                  "138",
                  "158",
                  "180",
                  "197",
                  "192",
                  "176",
                  "156",
                  "137",
                  "112",
                  "132",
                  "152",
                  "167",
                  "176",
                  "172",
                  "158",
                  "121",
                  "72",
                  "0"
                ],
                "yDrawvalues": "1910,2985,3816,3779,2934,2165,1952,1625,1725,1818,2657,3465,4055,3406,2678,2059,1595,1098,1927,2444,2877,3076,2727,2179,1188,309,0",
                "mistakes": [],
                "hiddenMistakes": [],
                "softVals": [
                  "1",
                  "1",
                  "0",
                  "0",
                  "0",
                  "1",
                  "1",
                  "1",
                  "1",
                  "1",
                  "1",
                  "0",
                  "0",
                  "0",
                  "1",
                  "1",
                  "1",
                  "1",
                  "1",
                  "1",
                  "1",
                  "1",
                  "1",
                  "1",
                  "1",
                  "1",
                  "1"
                ],
                "expectedSylsPerSecond": "0.00",
                "actualSylsPerSecond": "0.85",
                "timeLength": 3375,
                "features": [
                  {
                    "sampleYVal": 157,
                    "sampleYDrawVal": 2221
                  },
                  {
                    "sampleYVal": 189,
                    "sampleYDrawVal": 3350
                  },
                  {
                    "sampleYVal": 205,
                    "sampleYDrawVal": 4046
                  },
                  {
                    "sampleYVal": 190,
                    "sampleYDrawVal": 3352
                  },
                  {
                    "sampleYVal": 156,
                    "sampleYDrawVal": 2272
                  },
                  {
                    "sampleYVal": 145,
                    "sampleYDrawVal": 1964
                  },
                  {
                    "sampleYVal": 137,
                    "sampleYDrawVal": 1750
                  },
                  {
                    "sampleYVal": 125,
                    "sampleYDrawVal": 1440
                  },
                  {
                    "sampleYVal": 145,
                    "sampleYDrawVal": 1835
                  },
                  {
                    "sampleYVal": 143,
                    "sampleYDrawVal": 1977
                  },
                  {
                    "sampleYVal": 185,
                    "sampleYDrawVal": 3258
                  },
                  {
                    "sampleYVal": 198,
                    "sampleYDrawVal": 3780
                  },
                  {
                    "sampleYVal": 204,
                    "sampleYDrawVal": 4002
                  },
                  {
                    "sampleYVal": 181,
                    "sampleYDrawVal": 3047
                  },
                  {
                    "sampleYVal": 154,
                    "sampleYDrawVal": 2183
                  },
                  {
                    "sampleYVal": 142,
                    "sampleYDrawVal": 1917
                  },
                  {
                    "sampleYVal": 118,
                    "sampleYDrawVal": 1346
                  },
                  {
                    "sampleYVal": 114,
                    "sampleYDrawVal": 1330
                  },
                  {
                    "sampleYVal": 165,
                    "sampleYDrawVal": 2549
                  },
                  {
                    "sampleYVal": 162,
                    "sampleYDrawVal": 2502
                  },
                  {
                    "sampleYVal": 187,
                    "sampleYDrawVal": 3319
                  },
                  {
                    "sampleYVal": 177,
                    "sampleYDrawVal": 2944
                  },
                  {
                    "sampleYVal": 167,
                    "sampleYDrawVal": 2577
                  },
                  {
                    "sampleYVal": 136,
                    "sampleYDrawVal": 1770
                  },
                  {
                    "sampleYVal": 91,
                    "sampleYDrawVal": 828
                  },
                  {
                    "sampleYVal": 31,
                    "sampleYDrawVal": 108
                  }
                ],
                "recordOffset": 22034,
                "seqUrl": "1527724653075_20633_709",
                "seqDescription": {
                  "analysis": {
                    "analysisType": "1sec"
                  },
                  "completeCount": 3,
                  "currentSeqPos": 0,
                  "pbId": "1830",
                  "text": "שֶׁהִכְ",
                  "type": "word",
                  "word": {
                    "id": 1830,
                    "language": "he",
                    "cleanInput": "שֶׁהִכְרִיז",
                    "type": "word",
                    "data": "[{\"text\":\"\\u05e9\\u05c1\\u05b6\",\"sylType\":\"cv\",\"vowel\":\"E\",\"isContinous\":false},{\"text\":\"\\u05d4\\u05b4\\u05db\\u05b0\",\"sylType\":\"vc\",\"vowel\":\"I\",\"isContinous\":true},{\"text\":\"\\u05e8\\u05b4\\u05d9\\u05d6\",\"sylType\":\"cvc\",\"vowel\":\"I\",\"isContinous\":true}]",
                    "isNonesense": 0,
                    "audios": "{\"intoQuestionOneSec\":{\"desc\":\"Intonation question 1 sec\",\"filePath\":\"intoQuestionOneSec\",\"isIntonation\":true,\"analysisType\":{\"type\":\"1sec\"}},\"intoQuestionTwoSec\":{\"desc\":\"Intonation question 2 sec\",\"filePath\":\"intoQuestionTwoSec\",\"isIntonation\":true,\"analysisType\":{\"type\":\"2sec\"}},\"intoQuestionManaged\":{\"desc\":\"Intonation question managed\",\"filePath\":\"intoQuestionManaged\",\"isIntonation\":true,\"analysisType\":{\"type\":\"managed\"}},\"intoWonderOneSec\":{\"desc\":\"Intonation wounder 1 sec\",\"filePath\":\"intoWonderOneSec\",\"isIntonation\":true,\"analysisType\":{\"type\":\"1sec\"}},\"intoWonderTwoSec\":{\"desc\":\"Intonation wounder 2 sec\",\"filePath\":\"intoWonderTwoSec\",\"isIntonation\":true,\"analysisType\":{\"type\":\"2sec\"}},\"intoWonderManaged\":{\"desc\":\"Intonation wounder managed\",\"filePath\":\"intoWonderManaged\",\"isIntonation\":true,\"analysisType\":{\"type\":\"managed\"}},\"intoStatementsOneSec\":{\"desc\":\"Intonation statements 1 sec\",\"filePath\":\"intoStatementsOneSec\",\"isIntonation\":true,\"analysisType\":{\"type\":\"1sec\"}},\"intoStatementsTwoSec\":{\"desc\":\"Intonation statements 2 sec\",\"filePath\":\"intoStatementsTwoSec\",\"isIntonation\":true,\"analysisType\":{\"type\":\"2sec\"}},\"intoStatementsManaged\":{\"desc\":\"Intonation statements managed\",\"filePath\":\"intoStatementsManaged\",\"isIntonation\":true,\"analysisType\":{\"type\":\"managed\"}}}"
                  }
                },
                "mistakesObjects": {},
                "hiddenMistakesObjects": {}
              },
              {
                "fieldType": "seq",
                "timerPos": 1095,
                "calibrationEventId": "6581",
                "rate": 100,
                "deltaTimeMsFromPrevSeq": "2620.00",
                "yvalues": [
                  "100",
                  "142",
                  "176",
                  "181",
                  "183",
                  "177",
                  "168",
                  "154",
                  "135",
                  "143",
                  "153",
                  "173",
                  "189",
                  "197",
                  "182",
                  "164",
                  "148",
                  "124",
                  "121",
                  "157",
                  "176",
                  "177",
                  "157",
                  "121",
                  "68",
                  "0"
                ],
                "yDrawvalues": "1456,2387,3426,3150,3197,2806,2502,2029,1359,2031,2341,3162,3658,3858,2910,2289,1908,1296,1453,2829,3226,2970,2122,1068,198,0",
                "mistakes": [],
                "hiddenMistakes": [],
                "softVals": [
                  "1",
                  "1",
                  "1",
                  "0",
                  "0",
                  "0",
                  "1",
                  "1",
                  "1",
                  "1",
                  "1",
                  "1",
                  "0",
                  "0",
                  "0",
                  "1",
                  "1",
                  "1",
                  "1",
                  "1",
                  "1",
                  "0",
                  "1",
                  "1",
                  "1",
                  "1"
                ],
                "expectedSylsPerSecond": "0.00",
                "actualSylsPerSecond": "0.88",
                "timeLength": 3250,
                "features": [
                  {
                    "sampleYVal": 141,
                    "sampleYDrawVal": 1759
                  },
                  {
                    "sampleYVal": 178,
                    "sampleYDrawVal": 2904
                  },
                  {
                    "sampleYVal": 196,
                    "sampleYDrawVal": 3613
                  },
                  {
                    "sampleYVal": 180,
                    "sampleYDrawVal": 2980
                  },
                  {
                    "sampleYVal": 185,
                    "sampleYDrawVal": 3198
                  },
                  {
                    "sampleYVal": 163,
                    "sampleYDrawVal": 2458
                  },
                  {
                    "sampleYVal": 157,
                    "sampleYDrawVal": 2290
                  },
                  {
                    "sampleYVal": 131,
                    "sampleYDrawVal": 1562
                  },
                  {
                    "sampleYVal": 142,
                    "sampleYDrawVal": 1729
                  },
                  {
                    "sampleYVal": 145,
                    "sampleYDrawVal": 1991
                  },
                  {
                    "sampleYVal": 167,
                    "sampleYDrawVal": 2592
                  },
                  {
                    "sampleYVal": 188,
                    "sampleYDrawVal": 3362
                  },
                  {
                    "sampleYVal": 201,
                    "sampleYDrawVal": 3822
                  },
                  {
                    "sampleYVal": 194,
                    "sampleYDrawVal": 3544
                  },
                  {
                    "sampleYVal": 167,
                    "sampleYDrawVal": 2557
                  },
                  {
                    "sampleYVal": 147,
                    "sampleYDrawVal": 2050
                  },
                  {
                    "sampleYVal": 124,
                    "sampleYDrawVal": 1498
                  },
                  {
                    "sampleYVal": 106,
                    "sampleYDrawVal": 1106
                  },
                  {
                    "sampleYVal": 149,
                    "sampleYDrawVal": 2105
                  },
                  {
                    "sampleYVal": 177,
                    "sampleYDrawVal": 2929
                  },
                  {
                    "sampleYVal": 186,
                    "sampleYDrawVal": 3255
                  },
                  {
                    "sampleYVal": 170,
                    "sampleYDrawVal": 2657
                  },
                  {
                    "sampleYVal": 127,
                    "sampleYDrawVal": 1643
                  },
                  {
                    "sampleYVal": 75,
                    "sampleYDrawVal": 498
                  },
                  {
                    "sampleYVal": 31,
                    "sampleYDrawVal": 67
                  }
                ],
                "recordOffset": -1802,
                "seqUrl": "1527724683075_20633_709",
                "seqDescription": {
                  "analysis": {
                    "analysisType": "1sec"
                  },
                  "completeCount": 4,
                  "currentSeqPos": 0,
                  "pbId": "1830",
                  "text": "שֶׁהִכְ",
                  "type": "word",
                  "word": {
                    "id": 1830,
                    "language": "he",
                    "cleanInput": "שֶׁהִכְרִיז",
                    "type": "word",
                    "data": "[{\"text\":\"\\u05e9\\u05c1\\u05b6\",\"sylType\":\"cv\",\"vowel\":\"E\",\"isContinous\":false},{\"text\":\"\\u05d4\\u05b4\\u05db\\u05b0\",\"sylType\":\"vc\",\"vowel\":\"I\",\"isContinous\":true},{\"text\":\"\\u05e8\\u05b4\\u05d9\\u05d6\",\"sylType\":\"cvc\",\"vowel\":\"I\",\"isContinous\":true}]",
                    "isNonesense": 0,
                    "audios": "{\"intoQuestionOneSec\":{\"desc\":\"Intonation question 1 sec\",\"filePath\":\"intoQuestionOneSec\",\"isIntonation\":true,\"analysisType\":{\"type\":\"1sec\"}},\"intoQuestionTwoSec\":{\"desc\":\"Intonation question 2 sec\",\"filePath\":\"intoQuestionTwoSec\",\"isIntonation\":true,\"analysisType\":{\"type\":\"2sec\"}},\"intoQuestionManaged\":{\"desc\":\"Intonation question managed\",\"filePath\":\"intoQuestionManaged\",\"isIntonation\":true,\"analysisType\":{\"type\":\"managed\"}},\"intoWonderOneSec\":{\"desc\":\"Intonation wounder 1 sec\",\"filePath\":\"intoWonderOneSec\",\"isIntonation\":true,\"analysisType\":{\"type\":\"1sec\"}},\"intoWonderTwoSec\":{\"desc\":\"Intonation wounder 2 sec\",\"filePath\":\"intoWonderTwoSec\",\"isIntonation\":true,\"analysisType\":{\"type\":\"2sec\"}},\"intoWonderManaged\":{\"desc\":\"Intonation wounder managed\",\"filePath\":\"intoWonderManaged\",\"isIntonation\":true,\"analysisType\":{\"type\":\"managed\"}},\"intoStatementsOneSec\":{\"desc\":\"Intonation statements 1 sec\",\"filePath\":\"intoStatementsOneSec\",\"isIntonation\":true,\"analysisType\":{\"type\":\"1sec\"}},\"intoStatementsTwoSec\":{\"desc\":\"Intonation statements 2 sec\",\"filePath\":\"intoStatementsTwoSec\",\"isIntonation\":true,\"analysisType\":{\"type\":\"2sec\"}},\"intoStatementsManaged\":{\"desc\":\"Intonation statements managed\",\"filePath\":\"intoStatementsManaged\",\"isIntonation\":true,\"analysisType\":{\"type\":\"managed\"}}}"
                  }
                },
                "mistakesObjects": {},
                "hiddenMistakesObjects": {}
              },
              {
                "fieldType": "seq",
                "timerPos": 1101,
                "calibrationEventId": "6581",
                "rate": 80,
                "deltaTimeMsFromPrevSeq": "2895.00",
                "yvalues": [
                  "143",
                  "183",
                  "198",
                  "190",
                  "170",
                  "144",
                  "128",
                  "104",
                  "108",
                  "137",
                  "158",
                  "173",
                  "174",
                  "166",
                  "156",
                  "148",
                  "165",
                  "182",
                  "202",
                  "209",
                  "195",
                  "155",
                  "88",
                  "0"
                ],
                "yDrawvalues": "2600,3877,4053,3334,2461,1592,1285,803,1262,2108,2659,3078,2883,2536,2193,1970,2865,3434,4275,4366,3361,1661,242,0",
                "mistakes": [
                  {
                    "sampleOffset": 0,
                    "mistakeId": 1
                  }
                ],
                "hiddenMistakes": [
                  {
                    "sampleOffset": 2,
                    "mistakeId": 3
                  },
                  {
                    "sampleOffset": 12,
                    "mistakeId": 3
                  },
                  {
                    "sampleOffset": 19,
                    "mistakeId": 3
                  }
                ],
                "softVals": [
                  "1",
                  "0",
                  "0",
                  "0",
                  "1",
                  "1",
                  "1",
                  "1",
                  "1",
                  "1",
                  "1",
                  "1",
                  "1",
                  "1",
                  "1",
                  "1",
                  "1",
                  "0",
                  "0",
                  "0",
                  "0",
                  "1",
                  "1",
                  "1"
                ],
                "expectedSylsPerSecond": "0.00",
                "actualSylsPerSecond": "0.94",
                "timeLength": 3000,
                "features": [
                  {
                    "sampleYVal": 188,
                    "sampleYDrawVal": 3365
                  },
                  {
                    "sampleYVal": 210,
                    "sampleYDrawVal": 4209
                  },
                  {
                    "sampleYVal": 203,
                    "sampleYDrawVal": 3947
                  },
                  {
                    "sampleYVal": 176,
                    "sampleYDrawVal": 2889
                  },
                  {
                    "sampleYVal": 142,
                    "sampleYDrawVal": 1757
                  },
                  {
                    "sampleYVal": 136,
                    "sampleYDrawVal": 1585
                  },
                  {
                    "sampleYVal": 111,
                    "sampleYDrawVal": 1073
                  },
                  {
                    "sampleYVal": 84,
                    "sampleYDrawVal": 637
                  },
                  {
                    "sampleYVal": 146,
                    "sampleYDrawVal": 1975
                  },
                  {
                    "sampleYVal": 151,
                    "sampleYDrawVal": 2140
                  },
                  {
                    "sampleYVal": 179,
                    "sampleYDrawVal": 3014
                  },
                  {
                    "sampleYVal": 180,
                    "sampleYDrawVal": 3028
                  },
                  {
                    "sampleYVal": 174,
                    "sampleYDrawVal": 2841
                  },
                  {
                    "sampleYVal": 153,
                    "sampleYDrawVal": 2225
                  },
                  {
                    "sampleYVal": 139,
                    "sampleYDrawVal": 1855
                  },
                  {
                    "sampleYVal": 148,
                    "sampleYDrawVal": 2105
                  },
                  {
                    "sampleYVal": 177,
                    "sampleYDrawVal": 2965
                  },
                  {
                    "sampleYVal": 202,
                    "sampleYDrawVal": 3871
                  },
                  {
                    "sampleYVal": 217,
                    "sampleYDrawVal": 4549
                  },
                  {
                    "sampleYVal": 198,
                    "sampleYDrawVal": 3793
                  },
                  {
                    "sampleYVal": 173,
                    "sampleYDrawVal": 2665
                  },
                  {
                    "sampleYVal": 111,
                    "sampleYDrawVal": 990
                  },
                  {
                    "sampleYVal": 47,
                    "sampleYDrawVal": 121
                  }
                ],
                "recordOffset": 4545,
                "seqUrl": "1527724683075_20633_709",
                "seqDescription": {
                  "analysis": {
                    "analysisType": "1sec"
                  },
                  "completeCount": 0,
                  "currentSeqPos": 0,
                  "pbId": "1738",
                  "text": "הִנִּי",
                  "type": "word",
                  "word": {
                    "id": 1738,
                    "language": "he",
                    "cleanInput": "הִנִּיחַ",
                    "type": "word",
                    "data": "[{\"text\":\"\\u05d4\\u05b4\",\"sylType\":\"v\",\"vowel\":\"I\"},{\"text\":\"\\u05e0\\u05bc\\u05b4\\u05d9\",\"sylType\":\"cv\",\"vowel\":\"I\",\"isContinous\":true},{\"text\":\"\\u05d7\\u05b7\",\"sylType\":\"vc\",\"vowel\":\"A\",\"isContinous\":true}]",
                    "isNonesense": 0,
                    "audios": "[]"
                  }
                },
                "mistakesObjects": {
                  "gentleOnset": {
                    "titlei18Key": "MISTAKES.GENTLE_ONSET"
                  }
                },
                "hiddenMistakesObjects": {
                  "softPeak": {
                    "titlei18Key": "MISTAKES.SOFT_PEAK"
                  }
                }
              },
              {
                "fieldType": "seq",
                "timerPos": 1107,
                "calibrationEventId": "6581",
                "rate": 100,
                "deltaTimeMsFromPrevSeq": "2744.00",
                "yvalues": [
                  "109",
                  "152",
                  "177",
                  "190",
                  "196",
                  "195",
                  "178",
                  "137",
                  "100",
                  "83",
                  "109",
                  "146",
                  "180",
                  "188",
                  "178",
                  "156",
                  "151",
                  "147",
                  "157",
                  "170",
                  "186",
                  "189",
                  "182",
                  "136",
                  "71",
                  "0"
                ],
                "yDrawvalues": "1756,2809,3376,3724,3856,3636,2762,1351,696,666,1496,2466,3662,3505,2865,2054,2017,2013,2596,2964,3557,3516,3013,1209,132,0",
                "mistakes": [],
                "hiddenMistakes": [],
                "softVals": [
                  "1",
                  "1",
                  "0",
                  "0",
                  "0",
                  "0",
                  "0",
                  "1",
                  "1",
                  "1",
                  "1",
                  "1",
                  "0",
                  "0",
                  "0",
                  "1",
                  "1",
                  "1",
                  "1",
                  "1",
                  "0",
                  "0",
                  "0",
                  "1",
                  "1",
                  "1"
                ],
                "expectedSylsPerSecond": "0.00",
                "actualSylsPerSecond": "0.89",
                "timeLength": 3250,
                "features": [
                  {
                    "sampleYVal": 164,
                    "sampleYDrawVal": 2483
                  },
                  {
                    "sampleYVal": 174,
                    "sampleYDrawVal": 2864
                  },
                  {
                    "sampleYVal": 195,
                    "sampleYDrawVal": 3639
                  },
                  {
                    "sampleYVal": 197,
                    "sampleYDrawVal": 3783
                  },
                  {
                    "sampleYVal": 199,
                    "sampleYDrawVal": 3827
                  },
                  {
                    "sampleYVal": 193,
                    "sampleYDrawVal": 3485
                  },
                  {
                    "sampleYVal": 141,
                    "sampleYDrawVal": 1811
                  },
                  {
                    "sampleYVal": 100,
                    "sampleYDrawVal": 983
                  },
                  {
                    "sampleYVal": 78,
                    "sampleYDrawVal": 585
                  },
                  {
                    "sampleYVal": 88,
                    "sampleYDrawVal": 954
                  },
                  {
                    "sampleYVal": 136,
                    "sampleYDrawVal": 1764
                  },
                  {
                    "sampleYVal": 180,
                    "sampleYDrawVal": 3056
                  },
                  {
                    "sampleYVal": 198,
                    "sampleYDrawVal": 3737
                  },
                  {
                    "sampleYVal": 192,
                    "sampleYDrawVal": 3485
                  },
                  {
                    "sampleYVal": 160,
                    "sampleYDrawVal": 2403
                  },
                  {
                    "sampleYVal": 154,
                    "sampleYDrawVal": 2167
                  },
                  {
                    "sampleYVal": 148,
                    "sampleYDrawVal": 1989
                  },
                  {
                    "sampleYVal": 152,
                    "sampleYDrawVal": 2190
                  },
                  {
                    "sampleYVal": 172,
                    "sampleYDrawVal": 2873
                  },
                  {
                    "sampleYVal": 184,
                    "sampleYDrawVal": 3194
                  },
                  {
                    "sampleYVal": 195,
                    "sampleYDrawVal": 3598
                  },
                  {
                    "sampleYVal": 197,
                    "sampleYDrawVal": 3669
                  },
                  {
                    "sampleYVal": 161,
                    "sampleYDrawVal": 2390
                  },
                  {
                    "sampleYVal": 99,
                    "sampleYDrawVal": 706
                  },
                  {
                    "sampleYVal": 34,
                    "sampleYDrawVal": 54
                  }
                ],
                "recordOffset": 10431,
                "seqUrl": "1527724683075_20633_709",
                "seqDescription": {
                  "analysis": {
                    "analysisType": "1sec"
                  },
                  "completeCount": 1,
                  "currentSeqPos": 0,
                  "pbId": "1738",
                  "text": "הִנִּי",
                  "type": "word",
                  "word": {
                    "id": 1738,
                    "language": "he",
                    "cleanInput": "הִנִּיחַ",
                    "type": "word",
                    "data": "[{\"text\":\"\\u05d4\\u05b4\",\"sylType\":\"v\",\"vowel\":\"I\"},{\"text\":\"\\u05e0\\u05bc\\u05b4\\u05d9\",\"sylType\":\"cv\",\"vowel\":\"I\",\"isContinous\":true},{\"text\":\"\\u05d7\\u05b7\",\"sylType\":\"vc\",\"vowel\":\"A\",\"isContinous\":true}]",
                    "isNonesense": 0,
                    "audios": "[]"
                  }
                },
                "mistakesObjects": {},
                "hiddenMistakesObjects": {}
              },
              {
                "fieldType": "seq",
                "timerPos": 1113,
                "calibrationEventId": "6581",
                "rate": 100,
                "deltaTimeMsFromPrevSeq": "2883.00",
                "yvalues": [
                  "122",
                  "156",
                  "176",
                  "178",
                  "171",
                  "162",
                  "145",
                  "120",
                  "113",
                  "118",
                  "144",
                  "173",
                  "180",
                  "168",
                  "153",
                  "146",
                  "143",
                  "150",
                  "181",
                  "205",
                  "196",
                  "179",
                  "162",
                  "116",
                  "55",
                  "0"
                ],
                "yDrawvalues": "1909,2703,3164,3006,2641,2311,1726,1195,1361,1517,2327,3300,3253,2542,1987,1915,1871,2262,3689,4560,3525,2739,2199,788,52,0",
                "mistakes": [],
                "hiddenMistakes": [],
                "softVals": [
                  "1",
                  "1",
                  "1",
                  "0",
                  "1",
                  "1",
                  "1",
                  "1",
                  "1",
                  "1",
                  "1",
                  "1",
                  "0",
                  "1",
                  "1",
                  "1",
                  "1",
                  "1",
                  "0",
                  "0",
                  "0",
                  "0",
                  "1",
                  "1",
                  "1",
                  "1"
                ],
                "expectedSylsPerSecond": "0.00",
                "actualSylsPerSecond": "0.87",
                "timeLength": 3250,
                "features": [
                  {
                    "sampleYVal": 157,
                    "sampleYDrawVal": 2299
                  },
                  {
                    "sampleYVal": 173,
                    "sampleYDrawVal": 2756
                  },
                  {
                    "sampleYVal": 191,
                    "sampleYDrawVal": 3387
                  },
                  {
                    "sampleYVal": 173,
                    "sampleYDrawVal": 2784
                  },
                  {
                    "sampleYVal": 169,
                    "sampleYDrawVal": 2674
                  },
                  {
                    "sampleYVal": 156,
                    "sampleYDrawVal": 2198
                  },
                  {
                    "sampleYVal": 114,
                    "sampleYDrawVal": 1255
                  },
                  {
                    "sampleYVal": 103,
                    "sampleYDrawVal": 1158
                  },
                  {
                    "sampleYVal": 114,
                    "sampleYDrawVal": 1457
                  },
                  {
                    "sampleYVal": 140,
                    "sampleYDrawVal": 1913
                  },
                  {
                    "sampleYVal": 169,
                    "sampleYDrawVal": 2712
                  },
                  {
                    "sampleYVal": 197,
                    "sampleYDrawVal": 3699
                  },
                  {
                    "sampleYVal": 173,
                    "sampleYDrawVal": 2866
                  },
                  {
                    "sampleYVal": 154,
                    "sampleYDrawVal": 2212
                  },
                  {
                    "sampleYVal": 144,
                    "sampleYDrawVal": 1888
                  },
                  {
                    "sampleYVal": 145,
                    "sampleYDrawVal": 1885
                  },
                  {
                    "sampleYVal": 141,
                    "sampleYDrawVal": 1836
                  },
                  {
                    "sampleYVal": 165,
                    "sampleYDrawVal": 2556
                  },
                  {
                    "sampleYVal": 212,
                    "sampleYDrawVal": 4358
                  },
                  {
                    "sampleYVal": 217,
                    "sampleYDrawVal": 4607
                  },
                  {
                    "sampleYVal": 179,
                    "sampleYDrawVal": 3027
                  },
                  {
                    "sampleYVal": 163,
                    "sampleYDrawVal": 2410
                  },
                  {
                    "sampleYVal": 147,
                    "sampleYDrawVal": 1892
                  },
                  {
                    "sampleYVal": 59,
                    "sampleYDrawVal": 230
                  },
                  {
                    "sampleYVal": 17,
                    "sampleYDrawVal": 14
                  }
                ],
                "recordOffset": 16769,
                "seqUrl": "1527724683075_20633_709",
                "seqDescription": {
                  "analysis": {
                    "analysisType": "1sec"
                  },
                  "completeCount": 2,
                  "currentSeqPos": 0,
                  "pbId": "1738",
                  "text": "הִנִּי",
                  "type": "word",
                  "word": {
                    "id": 1738,
                    "language": "he",
                    "cleanInput": "הִנִּיחַ",
                    "type": "word",
                    "data": "[{\"text\":\"\\u05d4\\u05b4\",\"sylType\":\"v\",\"vowel\":\"I\"},{\"text\":\"\\u05e0\\u05bc\\u05b4\\u05d9\",\"sylType\":\"cv\",\"vowel\":\"I\",\"isContinous\":true},{\"text\":\"\\u05d7\\u05b7\",\"sylType\":\"vc\",\"vowel\":\"A\",\"isContinous\":true}]",
                    "isNonesense": 0,
                    "audios": "[]"
                  }
                },
                "mistakesObjects": {},
                "hiddenMistakesObjects": {}
              },
              {
                "fieldType": "seq",
                "timerPos": 1120,
                "calibrationEventId": "6581",
                "rate": 100,
                "deltaTimeMsFromPrevSeq": "2882.00",
                "yvalues": [
                  "125",
                  "161",
                  "180",
                  "192",
                  "192",
                  "181",
                  "156",
                  "145",
                  "137",
                  "118",
                  "131",
                  "153",
                  "165",
                  "168",
                  "168",
                  "160",
                  "157",
                  "150",
                  "153",
                  "181",
                  "208",
                  "217",
                  "194",
                  "175",
                  "139",
                  "66",
                  "0"
                ],
                "yDrawvalues": "2184,2940,3427,3742,3527,2862,1833,1687,1506,1107,1784,2486,2792,2705,2604,2288,2235,1999,2266,3553,4761,4770,3208,2578,1295,86,0",
                "mistakes": [],
                "hiddenMistakes": [],
                "softVals": [
                  "1",
                  "1",
                  "0",
                  "0",
                  "0",
                  "0",
                  "1",
                  "1",
                  "1",
                  "1",
                  "1",
                  "1",
                  "1",
                  "1",
                  "1",
                  "1",
                  "1",
                  "1",
                  "1",
                  "0",
                  "0",
                  "0",
                  "0",
                  "1",
                  "1",
                  "1",
                  "1"
                ],
                "expectedSylsPerSecond": "0.00",
                "actualSylsPerSecond": "0.85",
                "timeLength": 3375,
                "features": [
                  {
                    "sampleYVal": 160,
                    "sampleYDrawVal": 2309
                  },
                  {
                    "sampleYVal": 188,
                    "sampleYDrawVal": 3364
                  },
                  {
                    "sampleYVal": 189,
                    "sampleYDrawVal": 3440
                  },
                  {
                    "sampleYVal": 197,
                    "sampleYDrawVal": 3734
                  },
                  {
                    "sampleYVal": 193,
                    "sampleYDrawVal": 3478
                  },
                  {
                    "sampleYVal": 171,
                    "sampleYDrawVal": 2616
                  },
                  {
                    "sampleYVal": 145,
                    "sampleYDrawVal": 1778
                  },
                  {
                    "sampleYVal": 124,
                    "sampleYDrawVal": 1334
                  },
                  {
                    "sampleYVal": 131,
                    "sampleYDrawVal": 1361
                  },
                  {
                    "sampleYVal": 117,
                    "sampleYDrawVal": 1363
                  },
                  {
                    "sampleYVal": 151,
                    "sampleYDrawVal": 2085
                  },
                  {
                    "sampleYVal": 165,
                    "sampleYDrawVal": 2595
                  },
                  {
                    "sampleYVal": 175,
                    "sampleYDrawVal": 2894
                  },
                  {
                    "sampleYVal": 167,
                    "sampleYDrawVal": 2598
                  },
                  {
                    "sampleYVal": 167,
                    "sampleYDrawVal": 2567
                  },
                  {
                    "sampleYVal": 157,
                    "sampleYDrawVal": 2300
                  },
                  {
                    "sampleYVal": 155,
                    "sampleYDrawVal": 2183
                  },
                  {
                    "sampleYVal": 151,
                    "sampleYDrawVal": 2105
                  },
                  {
                    "sampleYVal": 177,
                    "sampleYDrawVal": 2933
                  },
                  {
                    "sampleYVal": 213,
                    "sampleYDrawVal": 4369
                  },
                  {
                    "sampleYVal": 222,
                    "sampleYDrawVal": 4838
                  },
                  {
                    "sampleYVal": 215,
                    "sampleYDrawVal": 4461
                  },
                  {
                    "sampleYVal": 173,
                    "sampleYDrawVal": 2759
                  },
                  {
                    "sampleYVal": 161,
                    "sampleYDrawVal": 2322
                  },
                  {
                    "sampleYVal": 84,
                    "sampleYDrawVal": 500
                  },
                  {
                    "sampleYVal": 27,
                    "sampleYDrawVal": 35
                  }
                ],
                "recordOffset": 23043,
                "seqUrl": "1527724683075_20633_709",
                "seqDescription": {
                  "analysis": {
                    "analysisType": "1sec"
                  },
                  "completeCount": 3,
                  "currentSeqPos": 0,
                  "pbId": "1738",
                  "text": "הִנִּי",
                  "type": "word",
                  "word": {
                    "id": 1738,
                    "language": "he",
                    "cleanInput": "הִנִּיחַ",
                    "type": "word",
                    "data": "[{\"text\":\"\\u05d4\\u05b4\",\"sylType\":\"v\",\"vowel\":\"I\"},{\"text\":\"\\u05e0\\u05bc\\u05b4\\u05d9\",\"sylType\":\"cv\",\"vowel\":\"I\",\"isContinous\":true},{\"text\":\"\\u05d7\\u05b7\",\"sylType\":\"vc\",\"vowel\":\"A\",\"isContinous\":true}]",
                    "isNonesense": 0,
                    "audios": "[]"
                  }
                },
                "mistakesObjects": {},
                "hiddenMistakesObjects": {}
              },
              {
                "fieldType": "seq",
                "timerPos": 1126,
                "calibrationEventId": "6581",
                "rate": 80,
                "deltaTimeMsFromPrevSeq": "2892.00",
                "yvalues": [
                  "151",
                  "183",
                  "189",
                  "186",
                  "183",
                  "174",
                  "159",
                  "134",
                  "109",
                  "125",
                  "154",
                  "177",
                  "187",
                  "178",
                  "161",
                  "154",
                  "158",
                  "179",
                  "198",
                  "195",
                  "189",
                  "162",
                  "112",
                  "54",
                  "0"
                ],
                "yDrawvalues": "3050,3743,3541,3280,3031,2612,2117,1343,982,1765,2627,3345,3526,2831,2229,2089,2434,3349,4096,3625,3296,2065,668,43,0",
                "mistakes": [
                  {
                    "sampleOffset": 0,
                    "mistakeId": 1
                  }
                ],
                "hiddenMistakes": [
                  {
                    "sampleOffset": 2,
                    "mistakeId": 3
                  },
                  {
                    "sampleOffset": 12,
                    "mistakeId": 3
                  },
                  {
                    "sampleOffset": 18,
                    "mistakeId": 3
                  },
                  {
                    "sampleOffset": 24,
                    "mistakeId": 7
                  }
                ],
                "softVals": [
                  "1",
                  "0",
                  "0",
                  "0",
                  "0",
                  "1",
                  "1",
                  "1",
                  "1",
                  "1",
                  "1",
                  "0",
                  "0",
                  "0",
                  "1",
                  "1",
                  "1",
                  "0",
                  "0",
                  "0",
                  "0",
                  "1",
                  "1",
                  "1",
                  "1"
                ],
                "expectedSylsPerSecond": "0.00",
                "actualSylsPerSecond": "0.92",
                "timeLength": 3125,
                "features": [
                  {
                    "sampleYVal": 198,
                    "sampleYDrawVal": 3729
                  },
                  {
                    "sampleYVal": 195,
                    "sampleYDrawVal": 3683
                  },
                  {
                    "sampleYVal": 188,
                    "sampleYDrawVal": 3422
                  },
                  {
                    "sampleYVal": 179,
                    "sampleYDrawVal": 3047
                  },
                  {
                    "sampleYVal": 179,
                    "sampleYDrawVal": 2904
                  },
                  {
                    "sampleYVal": 167,
                    "sampleYDrawVal": 2485
                  },
                  {
                    "sampleYVal": 150,
                    "sampleYDrawVal": 1978
                  },
                  {
                    "sampleYVal": 111,
                    "sampleYDrawVal": 1114
                  },
                  {
                    "sampleYVal": 116,
                    "sampleYDrawVal": 1410
                  },
                  {
                    "sampleYVal": 155,
                    "sampleYDrawVal": 2252
                  },
                  {
                    "sampleYVal": 176,
                    "sampleYDrawVal": 2965
                  },
                  {
                    "sampleYVal": 193,
                    "sampleYDrawVal": 3563
                  },
                  {
                    "sampleYVal": 178,
                    "sampleYDrawVal": 3001
                  },
                  {
                    "sampleYVal": 162,
                    "sampleYDrawVal": 2409
                  },
                  {
                    "sampleYVal": 137,
                    "sampleYDrawVal": 1764
                  },
                  {
                    "sampleYVal": 154,
                    "sampleYDrawVal": 2130
                  },
                  {
                    "sampleYVal": 174,
                    "sampleYDrawVal": 2836
                  },
                  {
                    "sampleYVal": 202,
                    "sampleYDrawVal": 3844
                  },
                  {
                    "sampleYVal": 207,
                    "sampleYDrawVal": 4120
                  },
                  {
                    "sampleYVal": 185,
                    "sampleYDrawVal": 3249
                  },
                  {
                    "sampleYVal": 184,
                    "sampleYDrawVal": 3109
                  },
                  {
                    "sampleYVal": 145,
                    "sampleYDrawVal": 1763
                  },
                  {
                    "sampleYVal": 51,
                    "sampleYDrawVal": 158
                  },
                  {
                    "sampleYVal": 30,
                    "sampleYDrawVal": 47
                  }
                ],
                "recordOffset": -549,
                "seqUrl": "1527724713077_20633_709",
                "seqDescription": {
                  "analysis": {
                    "analysisType": "1sec"
                  },
                  "completeCount": 4,
                  "currentSeqPos": 0,
                  "pbId": "1738",
                  "text": "הִנִּי",
                  "type": "word",
                  "word": {
                    "id": 1738,
                    "language": "he",
                    "cleanInput": "הִנִּיחַ",
                    "type": "word",
                    "data": "[{\"text\":\"\\u05d4\\u05b4\",\"sylType\":\"v\",\"vowel\":\"I\"},{\"text\":\"\\u05e0\\u05bc\\u05b4\\u05d9\",\"sylType\":\"cv\",\"vowel\":\"I\",\"isContinous\":true},{\"text\":\"\\u05d7\\u05b7\",\"sylType\":\"vc\",\"vowel\":\"A\",\"isContinous\":true}]",
                    "isNonesense": 0,
                    "audios": "[]"
                  }
                },
                "mistakesObjects": {
                  "gentleOnset": {
                    "titlei18Key": "MISTAKES.GENTLE_ONSET"
                  }
                },
                "hiddenMistakesObjects": {
                  "softPeak": {
                    "titlei18Key": "MISTAKES.SOFT_PEAK"
                  },
                  "tooLong": {
                    "titlei18Key": "MISTAKES.TOO_LONG"
                  }
                }
              },
              {
                "fieldType": "seq",
                "timerPos": 1132,
                "calibrationEventId": "6581",
                "rate": 80,
                "deltaTimeMsFromPrevSeq": "2689.00",
                "yvalues": [
                  "137",
                  "168",
                  "191",
                  "202",
                  "193",
                  "167",
                  "151",
                  "128",
                  "142",
                  "162",
                  "183",
                  "193",
                  "187",
                  "179",
                  "165",
                  "160",
                  "150",
                  "144",
                  "159",
                  "180",
                  "181",
                  "171",
                  "149",
                  "97",
                  "0"
                ],
                "yDrawvalues": "2233,3036,3917,4172,3408,2206,1902,1358,2085,2743,3582,3774,3290,2862,2347,2263,2008,1946,2592,3372,3142,2617,1793,553,0",
                "mistakes": [
                  {
                    "sampleOffset": 0,
                    "mistakeId": 1
                  }
                ],
                "hiddenMistakes": [
                  {
                    "sampleOffset": 3,
                    "mistakeId": 3
                  },
                  {
                    "sampleOffset": 11,
                    "mistakeId": 3
                  },
                  {
                    "sampleOffset": 20,
                    "mistakeId": 3
                  },
                  {
                    "sampleOffset": 24,
                    "mistakeId": 7
                  }
                ],
                "softVals": [
                  "1",
                  "1",
                  "0",
                  "0",
                  "0",
                  "1",
                  "1",
                  "1",
                  "1",
                  "1",
                  "0",
                  "0",
                  "0",
                  "0",
                  "1",
                  "1",
                  "1",
                  "1",
                  "1",
                  "0",
                  "0",
                  "1",
                  "1",
                  "1",
                  "1"
                ],
                "expectedSylsPerSecond": "0.00",
                "actualSylsPerSecond": "0.91",
                "timeLength": 3125,
                "features": [
                  {
                    "sampleYVal": 158,
                    "sampleYDrawVal": 2238
                  },
                  {
                    "sampleYVal": 195,
                    "sampleYDrawVal": 3627
                  },
                  {
                    "sampleYVal": 210,
                    "sampleYDrawVal": 4259
                  },
                  {
                    "sampleYVal": 204,
                    "sampleYDrawVal": 4017
                  },
                  {
                    "sampleYVal": 181,
                    "sampleYDrawVal": 3054
                  },
                  {
                    "sampleYVal": 144,
                    "sampleYDrawVal": 1866
                  },
                  {
                    "sampleYVal": 148,
                    "sampleYDrawVal": 1950
                  },
                  {
                    "sampleYVal": 120,
                    "sampleYDrawVal": 1472
                  },
                  {
                    "sampleYVal": 161,
                    "sampleYDrawVal": 2374
                  },
                  {
                    "sampleYVal": 181,
                    "sampleYDrawVal": 3073
                  },
                  {
                    "sampleYVal": 204,
                    "sampleYDrawVal": 4013
                  },
                  {
                    "sampleYVal": 190,
                    "sampleYDrawVal": 3464
                  },
                  {
                    "sampleYVal": 182,
                    "sampleYDrawVal": 3119
                  },
                  {
                    "sampleYVal": 165,
                    "sampleYDrawVal": 2509
                  },
                  {
                    "sampleYVal": 159,
                    "sampleYDrawVal": 2304
                  },
                  {
                    "sampleYVal": 152,
                    "sampleYDrawVal": 2137
                  },
                  {
                    "sampleYVal": 141,
                    "sampleYDrawVal": 1927
                  },
                  {
                    "sampleYVal": 150,
                    "sampleYDrawVal": 2114
                  },
                  {
                    "sampleYVal": 183,
                    "sampleYDrawVal": 3121
                  },
                  {
                    "sampleYVal": 187,
                    "sampleYDrawVal": 3275
                  },
                  {
                    "sampleYVal": 173,
                    "sampleYDrawVal": 2820
                  },
                  {
                    "sampleYVal": 151,
                    "sampleYDrawVal": 2130
                  },
                  {
                    "sampleYVal": 135,
                    "sampleYDrawVal": 1621
                  },
                  {
                    "sampleYVal": 61,
                    "sampleYDrawVal": 263
                  }
                ],
                "recordOffset": 5435,
                "seqUrl": "1527724713077_20633_709",
                "seqDescription": {
                  "analysis": {
                    "analysisType": "1sec"
                  },
                  "completeCount": 0,
                  "currentSeqPos": 0,
                  "pbId": "1084",
                  "text": "בְּמִקְ",
                  "type": "word",
                  "word": {
                    "id": 1084,
                    "language": "he",
                    "cleanInput": "בְּמִקְרִים",
                    "type": "word",
                    "data": "[{\"text\":\"\\u05d1\\u05b0\\u05bc\",\"sylType\":\"cv\",\"vowel\":\"E\",\"isPlosive\":true},{\"text\":\"\\u05de\\u05b4\\u05e7\\u05b0\",\"sylType\":\"cvc\",\"vowel\":\"I\",\"isContinous\":true,\"isPlosive\":true},{\"text\":\"\\u05e8\\u05b4\\u05d9\\u05dd\",\"sylType\":\"cvc\",\"vowel\":\"I\",\"isContinous\":true}]",
                    "isNonesense": 0,
                    "audios": ""
                  }
                },
                "mistakesObjects": {
                  "gentleOnset": {
                    "titlei18Key": "MISTAKES.GENTLE_ONSET"
                  }
                },
                "hiddenMistakesObjects": {
                  "softPeak": {
                    "titlei18Key": "MISTAKES.SOFT_PEAK"
                  },
                  "tooLong": {
                    "titlei18Key": "MISTAKES.TOO_LONG"
                  }
                }
              },
              {
                "fieldType": "seq",
                "timerPos": 1138,
                "calibrationEventId": "6581",
                "rate": 100,
                "deltaTimeMsFromPrevSeq": "2619.00",
                "yvalues": [
                  "120",
                  "152",
                  "177",
                  "192",
                  "184",
                  "165",
                  "144",
                  "139",
                  "133",
                  "151",
                  "175",
                  "198",
                  "207",
                  "198",
                  "179",
                  "161",
                  "155",
                  "150",
                  "149",
                  "158",
                  "174",
                  "179",
                  "173",
                  "155",
                  "93",
                  "0"
                ],
                "yDrawvalues": "1928,2539,3321,3736,3024,2184,1596,1658,1568,2399,3289,4238,4358,3587,2697,2117,2093,2021,2065,2441,3066,3070,2689,1993,452,0",
                "mistakes": [],
                "hiddenMistakes": [],
                "softVals": [
                  "1",
                  "1",
                  "0",
                  "0",
                  "0",
                  "1",
                  "1",
                  "1",
                  "1",
                  "1",
                  "1",
                  "0",
                  "0",
                  "0",
                  "0",
                  "1",
                  "1",
                  "1",
                  "1",
                  "1",
                  "1",
                  "0",
                  "1",
                  "1",
                  "1",
                  "1"
                ],
                "expectedSylsPerSecond": "0.00",
                "actualSylsPerSecond": "0.87",
                "timeLength": 3250,
                "features": [
                  {
                    "sampleYVal": 155,
                    "sampleYDrawVal": 2187
                  },
                  {
                    "sampleYVal": 179,
                    "sampleYDrawVal": 2974
                  },
                  {
                    "sampleYVal": 187,
                    "sampleYDrawVal": 3219
                  },
                  {
                    "sampleYVal": 190,
                    "sampleYDrawVal": 3369
                  },
                  {
                    "sampleYVal": 170,
                    "sampleYDrawVal": 2616
                  },
                  {
                    "sampleYVal": 143,
                    "sampleYDrawVal": 1743
                  },
                  {
                    "sampleYVal": 140,
                    "sampleYDrawVal": 1699
                  },
                  {
                    "sampleYVal": 137,
                    "sampleYDrawVal": 1620
                  },
                  {
                    "sampleYVal": 135,
                    "sampleYDrawVal": 1718
                  },
                  {
                    "sampleYVal": 171,
                    "sampleYDrawVal": 2710
                  },
                  {
                    "sampleYVal": 203,
                    "sampleYDrawVal": 3978
                  },
                  {
                    "sampleYVal": 208,
                    "sampleYDrawVal": 4181
                  },
                  {
                    "sampleYVal": 213,
                    "sampleYDrawVal": 4404
                  },
                  {
                    "sampleYVal": 182,
                    "sampleYDrawVal": 3117
                  },
                  {
                    "sampleYVal": 159,
                    "sampleYDrawVal": 2315
                  },
                  {
                    "sampleYVal": 158,
                    "sampleYDrawVal": 2204
                  },
                  {
                    "sampleYVal": 153,
                    "sampleYDrawVal": 2094
                  },
                  {
                    "sampleYVal": 151,
                    "sampleYDrawVal": 2151
                  },
                  {
                    "sampleYVal": 152,
                    "sampleYDrawVal": 2133
                  },
                  {
                    "sampleYVal": 176,
                    "sampleYDrawVal": 2879
                  },
                  {
                    "sampleYVal": 183,
                    "sampleYDrawVal": 3131
                  },
                  {
                    "sampleYVal": 182,
                    "sampleYDrawVal": 3064
                  },
                  {
                    "sampleYVal": 164,
                    "sampleYDrawVal": 2469
                  },
                  {
                    "sampleYVal": 134,
                    "sampleYDrawVal": 1552
                  },
                  {
                    "sampleYVal": 37,
                    "sampleYDrawVal": 127
                  }
                ],
                "recordOffset": 11386,
                "seqUrl": "1527724713077_20633_709",
                "seqDescription": {
                  "analysis": {
                    "analysisType": "1sec"
                  },
                  "completeCount": 1,
                  "currentSeqPos": 0,
                  "pbId": "1084",
                  "text": "בְּמִקְ",
                  "type": "word",
                  "word": {
                    "id": 1084,
                    "language": "he",
                    "cleanInput": "בְּמִקְרִים",
                    "type": "word",
                    "data": "[{\"text\":\"\\u05d1\\u05b0\\u05bc\",\"sylType\":\"cv\",\"vowel\":\"E\",\"isPlosive\":true},{\"text\":\"\\u05de\\u05b4\\u05e7\\u05b0\",\"sylType\":\"cvc\",\"vowel\":\"I\",\"isContinous\":true,\"isPlosive\":true},{\"text\":\"\\u05e8\\u05b4\\u05d9\\u05dd\",\"sylType\":\"cvc\",\"vowel\":\"I\",\"isContinous\":true}]",
                    "isNonesense": 0,
                    "audios": ""
                  }
                },
                "mistakesObjects": {},
                "hiddenMistakesObjects": {}
              },
              {
                "fieldType": "seq",
                "timerPos": 1144,
                "calibrationEventId": "6581",
                "rate": 100,
                "deltaTimeMsFromPrevSeq": "3011.00",
                "yvalues": [
                  "119",
                  "166",
                  "184",
                  "191",
                  "187",
                  "175",
                  "155",
                  "141",
                  "122",
                  "151",
                  "176",
                  "189",
                  "183",
                  "172",
                  "162",
                  "158",
                  "158",
                  "162",
                  "175",
                  "188",
                  "181",
                  "167",
                  "149",
                  "116",
                  "69",
                  "0"
                ],
                "yDrawvalues": "1972,3349,3542,3620,3328,2796,1999,1779,1377,2424,3261,3569,3048,2592,2233,2196,2285,2478,3043,3575,2959,2425,1773,910,222,0",
                "mistakes": [],
                "hiddenMistakes": [],
                "softVals": [
                  "1",
                  "1",
                  "0",
                  "0",
                  "0",
                  "1",
                  "1",
                  "1",
                  "1",
                  "1",
                  "1",
                  "0",
                  "0",
                  "1",
                  "1",
                  "1",
                  "1",
                  "1",
                  "1",
                  "0",
                  "0",
                  "1",
                  "1",
                  "1",
                  "1",
                  "1"
                ],
                "expectedSylsPerSecond": "0.00",
                "actualSylsPerSecond": "0.89",
                "timeLength": 3250,
                "features": [
                  {
                    "sampleYVal": 163,
                    "sampleYDrawVal": 2503
                  },
                  {
                    "sampleYVal": 197,
                    "sampleYDrawVal": 3704
                  },
                  {
                    "sampleYVal": 191,
                    "sampleYDrawVal": 3486
                  },
                  {
                    "sampleYVal": 198,
                    "sampleYDrawVal": 3776
                  },
                  {
                    "sampleYVal": 181,
                    "sampleYDrawVal": 3148
                  },
                  {
                    "sampleYVal": 159,
                    "sampleYDrawVal": 2364
                  },
                  {
                    "sampleYVal": 143,
                    "sampleYDrawVal": 1883
                  },
                  {
                    "sampleYVal": 135,
                    "sampleYDrawVal": 1756
                  },
                  {
                    "sampleYVal": 140,
                    "sampleYDrawVal": 1776
                  },
                  {
                    "sampleYVal": 170,
                    "sampleYDrawVal": 2600
                  },
                  {
                    "sampleYVal": 191,
                    "sampleYDrawVal": 3400
                  },
                  {
                    "sampleYVal": 188,
                    "sampleYDrawVal": 3238
                  },
                  {
                    "sampleYVal": 173,
                    "sampleYDrawVal": 2832
                  },
                  {
                    "sampleYVal": 160,
                    "sampleYDrawVal": 2284
                  },
                  {
                    "sampleYVal": 155,
                    "sampleYDrawVal": 2165
                  },
                  {
                    "sampleYVal": 161,
                    "sampleYDrawVal": 2316
                  },
                  {
                    "sampleYVal": 158,
                    "sampleYDrawVal": 2273
                  },
                  {
                    "sampleYVal": 170,
                    "sampleYDrawVal": 2697
                  },
                  {
                    "sampleYVal": 185,
                    "sampleYDrawVal": 3184
                  },
                  {
                    "sampleYVal": 191,
                    "sampleYDrawVal": 3445
                  },
                  {
                    "sampleYVal": 172,
                    "sampleYDrawVal": 2689
                  },
                  {
                    "sampleYVal": 149,
                    "sampleYDrawVal": 1981
                  },
                  {
                    "sampleYVal": 132,
                    "sampleYDrawVal": 1507
                  },
                  {
                    "sampleYVal": 76,
                    "sampleYDrawVal": 441
                  },
                  {
                    "sampleYVal": 31,
                    "sampleYDrawVal": 83
                  }
                ],
                "recordOffset": 17769,
                "seqUrl": "1527724713077_20633_709",
                "seqDescription": {
                  "analysis": {
                    "analysisType": "1sec"
                  },
                  "completeCount": 2,
                  "currentSeqPos": 0,
                  "pbId": "1084",
                  "text": "בְּמִקְ",
                  "type": "word",
                  "word": {
                    "id": 1084,
                    "language": "he",
                    "cleanInput": "בְּמִקְרִים",
                    "type": "word",
                    "data": "[{\"text\":\"\\u05d1\\u05b0\\u05bc\",\"sylType\":\"cv\",\"vowel\":\"E\",\"isPlosive\":true},{\"text\":\"\\u05de\\u05b4\\u05e7\\u05b0\",\"sylType\":\"cvc\",\"vowel\":\"I\",\"isContinous\":true,\"isPlosive\":true},{\"text\":\"\\u05e8\\u05b4\\u05d9\\u05dd\",\"sylType\":\"cvc\",\"vowel\":\"I\",\"isContinous\":true}]",
                    "isNonesense": 0,
                    "audios": ""
                  }
                },
                "mistakesObjects": {},
                "hiddenMistakesObjects": {}
              },
              {
                "fieldType": "seq",
                "timerPos": 1150,
                "calibrationEventId": "6581",
                "rate": 80,
                "deltaTimeMsFromPrevSeq": "2627.00",
                "yvalues": [
                  "137",
                  "169",
                  "189",
                  "189",
                  "172",
                  "150",
                  "139",
                  "124",
                  "133",
                  "156",
                  "186",
                  "199",
                  "200",
                  "193",
                  "178",
                  "162",
                  "154",
                  "137",
                  "146",
                  "164",
                  "177",
                  "178",
                  "169",
                  "135",
                  "74",
                  "0"
                ],
                "yDrawvalues": "2466,3205,3742,3428,2642,1951,1735,1325,1706,2650,3818,4064,3904,3432,2783,2240,2103,1642,2145,2732,3133,2998,2538,1306,175,0",
                "mistakes": [
                  {
                    "sampleOffset": 0,
                    "mistakeId": 1
                  }
                ],
                "hiddenMistakes": [
                  {
                    "sampleOffset": 12,
                    "mistakeId": 3
                  },
                  {
                    "sampleOffset": 21,
                    "mistakeId": 3
                  },
                  {
                    "sampleOffset": 24,
                    "mistakeId": 26
                  },
                  {
                    "sampleOffset": 25,
                    "mistakeId": 7
                  }
                ],
                "softVals": [
                  "1",
                  "1",
                  "0",
                  "0",
                  "1",
                  "1",
                  "1",
                  "1",
                  "1",
                  "1",
                  "0",
                  "0",
                  "0",
                  "0",
                  "0",
                  "1",
                  "1",
                  "1",
                  "1",
                  "1",
                  "0",
                  "0",
                  "1",
                  "1",
                  "1",
                  "1"
                ],
                "expectedSylsPerSecond": "0.00",
                "actualSylsPerSecond": "0.86",
                "timeLength": 3250,
                "features": [
                  {
                    "sampleYVal": 170,
                    "sampleYDrawVal": 2761
                  },
                  {
                    "sampleYVal": 194,
                    "sampleYDrawVal": 3588
                  },
                  {
                    "sampleYVal": 202,
                    "sampleYDrawVal": 3912
                  },
                  {
                    "sampleYVal": 181,
                    "sampleYDrawVal": 3099
                  },
                  {
                    "sampleYVal": 158,
                    "sampleYDrawVal": 2385
                  },
                  {
                    "sampleYVal": 138,
                    "sampleYDrawVal": 1869
                  },
                  {
                    "sampleYVal": 133,
                    "sampleYDrawVal": 1630
                  },
                  {
                    "sampleYVal": 115,
                    "sampleYDrawVal": 1219
                  },
                  {
                    "sampleYVal": 157,
                    "sampleYDrawVal": 2246
                  },
                  {
                    "sampleYVal": 181,
                    "sampleYDrawVal": 3114
                  },
                  {
                    "sampleYVal": 204,
                    "sampleYDrawVal": 4006
                  },
                  {
                    "sampleYVal": 205,
                    "sampleYDrawVal": 4032
                  },
                  {
                    "sampleYVal": 198,
                    "sampleYDrawVal": 3749
                  },
                  {
                    "sampleYVal": 183,
                    "sampleYDrawVal": 3156
                  },
                  {
                    "sampleYVal": 170,
                    "sampleYDrawVal": 2657
                  },
                  {
                    "sampleYVal": 155,
                    "sampleYDrawVal": 2202
                  },
                  {
                    "sampleYVal": 145,
                    "sampleYDrawVal": 1953
                  },
                  {
                    "sampleYVal": 134,
                    "sampleYDrawVal": 1757
                  },
                  {
                    "sampleYVal": 164,
                    "sampleYDrawVal": 2482
                  },
                  {
                    "sampleYVal": 178,
                    "sampleYDrawVal": 2936
                  },
                  {
                    "sampleYVal": 183,
                    "sampleYDrawVal": 3148
                  },
                  {
                    "sampleYVal": 175,
                    "sampleYDrawVal": 2852
                  },
                  {
                    "sampleYVal": 146,
                    "sampleYDrawVal": 1922
                  },
                  {
                    "sampleYVal": 80,
                    "sampleYDrawVal": 532
                  },
                  {
                    "sampleYVal": 36,
                    "sampleYDrawVal": 76
                  }
                ],
                "recordOffset": 23867,
                "seqUrl": "1527724713077_20633_709",
                "seqDescription": {
                  "analysis": {
                    "analysisType": "1sec"
                  },
                  "completeCount": 3,
                  "currentSeqPos": 0,
                  "pbId": "1084",
                  "text": "בְּמִקְ",
                  "type": "word",
                  "word": {
                    "id": 1084,
                    "language": "he",
                    "cleanInput": "בְּמִקְרִים",
                    "type": "word",
                    "data": "[{\"text\":\"\\u05d1\\u05b0\\u05bc\",\"sylType\":\"cv\",\"vowel\":\"E\",\"isPlosive\":true},{\"text\":\"\\u05de\\u05b4\\u05e7\\u05b0\",\"sylType\":\"cvc\",\"vowel\":\"I\",\"isContinous\":true,\"isPlosive\":true},{\"text\":\"\\u05e8\\u05b4\\u05d9\\u05dd\",\"sylType\":\"cvc\",\"vowel\":\"I\",\"isContinous\":true}]",
                    "isNonesense": 0,
                    "audios": ""
                  }
                },
                "mistakesObjects": {
                  "gentleOnset": {
                    "titlei18Key": "MISTAKES.GENTLE_ONSET"
                  }
                },
                "hiddenMistakesObjects": {
                  "softPeak": {
                    "titlei18Key": "MISTAKES.SOFT_PEAK"
                  },
                  "linkativity": {
                    "titlei18Key": "MISTAKES.LINKATIVITY"
                  },
                  "tooLong": {
                    "titlei18Key": "MISTAKES.TOO_LONG"
                  }
                }
              },
              {
                "fieldType": "seq",
                "timerPos": 1157,
                "calibrationEventId": "6581",
                "rate": 100,
                "deltaTimeMsFromPrevSeq": "2442.00",
                "yvalues": [
                  "134",
                  "171",
                  "186",
                  "184",
                  "173",
                  "163",
                  "158",
                  "137",
                  "162",
                  "188",
                  "203",
                  "208",
                  "204",
                  "193",
                  "180",
                  "175",
                  "168",
                  "167",
                  "174",
                  "187",
                  "196",
                  "195",
                  "189",
                  "181",
                  "155",
                  "112",
                  "64",
                  "0"
                ],
                "yDrawvalues": "2550,3350,3564,3212,2684,2326,2247,1566,2788,3784,4302,4334,3984,3360,2855,2756,2529,2557,2967,3549,3874,3646,3289,2952,1965,964,262,0",
                "mistakes": [],
                "hiddenMistakes": [],
                "softVals": [
                  "1",
                  "1",
                  "0",
                  "0",
                  "1",
                  "1",
                  "1",
                  "1",
                  "1",
                  "0",
                  "0",
                  "0",
                  "0",
                  "0",
                  "0",
                  "1",
                  "1",
                  "1",
                  "1",
                  "0",
                  "0",
                  "0",
                  "0",
                  "0",
                  "1",
                  "1",
                  "1",
                  "1"
                ],
                "expectedSylsPerSecond": "0.00",
                "actualSylsPerSecond": "0.81",
                "timeLength": 3500,
                "features": [
                  {
                    "sampleYVal": 180,
                    "sampleYDrawVal": 2989
                  },
                  {
                    "sampleYVal": 195,
                    "sampleYDrawVal": 3629
                  },
                  {
                    "sampleYVal": 191,
                    "sampleYDrawVal": 3490
                  },
                  {
                    "sampleYVal": 180,
                    "sampleYDrawVal": 3052
                  },
                  {
                    "sampleYVal": 162,
                    "sampleYDrawVal": 2413
                  },
                  {
                    "sampleYVal": 148,
                    "sampleYDrawVal": 1977
                  },
                  {
                    "sampleYVal": 151,
                    "sampleYDrawVal": 2079
                  },
                  {
                    "sampleYVal": 123,
                    "sampleYDrawVal": 1456
                  },
                  {
                    "sampleYVal": 191,
                    "sampleYDrawVal": 3434
                  },
                  {
                    "sampleYVal": 210,
                    "sampleYDrawVal": 4253
                  },
                  {
                    "sampleYVal": 211,
                    "sampleYDrawVal": 4310
                  },
                  {
                    "sampleYVal": 212,
                    "sampleYDrawVal": 4366
                  },
                  {
                    "sampleYVal": 199,
                    "sampleYDrawVal": 3764
                  },
                  {
                    "sampleYVal": 179,
                    "sampleYDrawVal": 3008
                  },
                  {
                    "sampleYVal": 167,
                    "sampleYDrawVal": 2583
                  },
                  {
                    "sampleYVal": 178,
                    "sampleYDrawVal": 2898
                  },
                  {
                    "sampleYVal": 162,
                    "sampleYDrawVal": 2438
                  },
                  {
                    "sampleYVal": 167,
                    "sampleYDrawVal": 2565
                  },
                  {
                    "sampleYVal": 186,
                    "sampleYDrawVal": 3266
                  },
                  {
                    "sampleYVal": 198,
                    "sampleYDrawVal": 3768
                  },
                  {
                    "sampleYVal": 197,
                    "sampleYDrawVal": 3747
                  },
                  {
                    "sampleYVal": 191,
                    "sampleYDrawVal": 3465
                  },
                  {
                    "sampleYVal": 182,
                    "sampleYDrawVal": 3101
                  },
                  {
                    "sampleYVal": 174,
                    "sampleYDrawVal": 2806
                  },
                  {
                    "sampleYVal": 123,
                    "sampleYDrawVal": 1456
                  },
                  {
                    "sampleYVal": 50,
                    "sampleYDrawVal": 343
                  },
                  {
                    "sampleYVal": 36,
                    "sampleYDrawVal": 98
                  }
                ],
                "recordOffset": -248,
                "seqUrl": "1527724743076_20633_709",
                "seqDescription": {
                  "analysis": {
                    "analysisType": "1sec"
                  },
                  "completeCount": 4,
                  "currentSeqPos": 0,
                  "pbId": "1084",
                  "text": "בְּמִקְ",
                  "type": "word",
                  "word": {
                    "id": 1084,
                    "language": "he",
                    "cleanInput": "בְּמִקְרִים",
                    "type": "word",
                    "data": "[{\"text\":\"\\u05d1\\u05b0\\u05bc\",\"sylType\":\"cv\",\"vowel\":\"E\",\"isPlosive\":true},{\"text\":\"\\u05de\\u05b4\\u05e7\\u05b0\",\"sylType\":\"cvc\",\"vowel\":\"I\",\"isContinous\":true,\"isPlosive\":true},{\"text\":\"\\u05e8\\u05b4\\u05d9\\u05dd\",\"sylType\":\"cvc\",\"vowel\":\"I\",\"isContinous\":true}]",
                    "isNonesense": 0,
                    "audios": ""
                  }
                },
                "mistakesObjects": {},
                "hiddenMistakesObjects": {}
              }
            ],
            "results": {
              "fieldType": "results",
              "woutActivityId": "20633",
              "woutIndex": "5",
              "userId": "709",
              "timerPos": 1157,
              "woutActivityShardGuid": "7686559e1f26579b417e0aceb05d474fc3606c0c",
              "wout": {
                "levelIndex": "26",
                "partIndex": 5,
                "part": {
                  "filterSettings": [
                    {
                      "filter": {
                        "lang": "he",
                        "syl": [],
                        "sylAmount": {
                          "exp": {
                            "val": 1,
                            "sign": "gt"
                          }
                        },
                        "selectedBank": "word",
                        "audioType": {
                          "filePath": "none",
                          "desc": "FILTERBUILDER.AUDIO_OPTION_NONE"
                        }
                      }
                    }
                  ],
                  "analysisDetails": [
                    {
                      "breathingEnabled": true,
                      "breathTimeMs": 2000,
                      "mistakesEnabled": true,
                      "mistakes": [
                        "gentleOnset",
                        "gentleOffset",
                        "volControlDown",
                        "volControlUp",
                        "symetric",
                        "intensePeak",
                        "sharpRise",
                        "sharpFall",
                        "shapeStart",
                        "shapeEnd",
                        "volControlStart",
                        "volControlEnd",
                        "notSoft",
                        "notHard",
                        "tooSoft",
                        "tooHard"
                      ],
                      "boundariesEnabled": true,
                      "boundariesGridEnabled": true,
                      "coloringEnabled": true,
                      "analysisType": "1sec",
                      "analyzeTypeSylLenMs": 1000,
                      "analyzeTypeNumOfSylsInSeq": 1,
                      "mistakesSkills": {
                        "gentleOnset": 2,
                        "gentleOffset": 2,
                        "softPeak": 1,
                        "tooShort": 1,
                        "tooLong": 1,
                        "breathMiss": 1,
                        "tooFast": 1,
                        "tooSlow": 1,
                        "tooSoft": 2,
                        "tooHard": 2,
                        "breathEarly": 1,
                        "intensePeak": 2
                      },
                      "configs": [
                        {
                          "analyzeTypeSylLenMs": 500,
                          "analyzeTypeNumOfSylsInSeq": 5,
                          "weight": 3
                        },
                        {
                          "analyzeTypeSylLenMs": 200,
                          "analyzeTypeNumOfSylsInSeq": 5,
                          "weight": 5
                        }
                      ],
                      "analyzeTypeNumOfSylsInSeqAuto": true
                    }
                  ],
                  "uiSettings": {
                    "repetition": 5,
                    "enableMirror": true,
                    "instructions": "בתרגול זה יש להפיק מילים בתבנית שניה להברה, ולשאוף אויר בין מילה למילה. \nעל המסך יופיעו מילים באורכים שונים. יש להקפיד לשמור על רצף קולי בעת הפקת המילה.. \n חשוב להיעזר בתבנית המטרה המוצללת שמופיעה על גבי המוניטור, על מנת להפיק את המילים בצורה הנכונה, בדגם הגלים .\nבתרגול זה עליכם ליישם בצורה נכונה את העקרונות שנלמדו בסרטונים קודמים, בדגש על מפתח פה שמשתנה במעבר בין הברה להברה.\n הסתכלו על דמותכם המופיעה על המסך לקבלת משוב אודות ביצוע התרגיל. \nכך, תוכלו לבסס ביצוע נכון של שינויים בעוצמת הקול בהתאם לתבנית המטרה המוצללת",
                    "showWordGoal": true,
                    "defaultVideoInstructionsId": 0,
                    "phraseAudioType": [],
                    "videoInstructions": {
                      "id": 211,
                      "type": "video",
                      "title": "instructions.1sec_pattern_syllable_long_words_error_mouth_posture.monitor.mp4",
                      "value": "https://s3-eu-west-1.amazonaws.com/nvtk-video-media/2016_05/הוראות/instructions.1sec_pattern_syllable_long_words_error_mouth_posture.monitor.mp4",
                      "tags": [],
                      "thumb": "http://www.depaul.edu/campustour/Pages/_layouts/spotlight/btn-videoplayoverlay.png"
                    },
                    "isRecordEnabled": true,
                    "isScreenShareEnabled": true
                  },
                  "wordGoal": 25,
                  "description": "תרגול: שניה להברה- מילים רב הברתיות-מפתח פה",
                  "name": "1-sec words",
                  "timeEstimation": 375,
                  "uiType": {
                    "type": "box5stars",
                    "icon": "icon-star"
                  },
                  "engineType": {
                    "type": "AdvancedEngine",
                    "icon": "icon-diamond"
                  },
                  "pressed": false,
                  "passScore": "30",
                  "type": "wout"
                },
                "totalLevels": 52,
                "totalParts": 10,
                "tp2uInstanceId": "1984fcbe7fa059f148897aea947062da8efe9df6",
                "tpId": "809",
                "woutTag": "תרגול: שניה להברה- מילים רב הברתיות-מפתח פה"
              },
              "requiredDuration": 0,
              "actualDuration": -1,
              "isLastPartInLevel": false,
              "isLastLevel": false,
              "requiredPassRate": "30",
              "overallRate": 64,
              "totalActualSequencesDone": 25,
              "totalRequiredSequences": 25,
              "isWoutPassed": true,
              "nolimit": false,
              "showWordGoal": true,
              "showDurationGoal": false,
              "isPracticeExpired": false
            }
          },
          {
            "title": "תרגול: שניה להברה- מילים רב הברתיות-מסגרת זמן הפקה",
            "config": {
              "filterSettings": [
                {
                  "filter": {
                    "lang": "he",
                    "syl": [],
                    "sylAmount": {
                      "exp": {
                        "val": 1,
                        "sign": "gt"
                      }
                    },
                    "selectedBank": "word",
                    "audioType": {
                      "filePath": "none",
                      "desc": "FILTERBUILDER.AUDIO_OPTION_NONE"
                    }
                  }
                }
              ],
              "analysisDetails": [
                {
                  "breathingEnabled": true,
                  "breathTimeMs": 2000,
                  "mistakesEnabled": true,
                  "mistakes": [
                    "gentleOnset",
                    "gentleOffset",
                    "volControlDown",
                    "volControlUp",
                    "symetric",
                    "intensePeak",
                    "sharpRise",
                    "sharpFall",
                    "shapeStart",
                    "shapeEnd",
                    "volControlStart",
                    "volControlEnd",
                    "notSoft",
                    "notHard",
                    "tooSoft",
                    "tooHard",
                    "tooSlow",
                    "tooFast"
                  ],
                  "boundariesEnabled": true,
                  "boundariesGridEnabled": true,
                  "coloringEnabled": true,
                  "analysisType": "1sec",
                  "analyzeTypeSylLenMs": 1000,
                  "analyzeTypeNumOfSylsInSeq": 1,
                  "mistakesSkills": {
                    "gentleOnset": 2,
                    "gentleOffset": 2,
                    "softPeak": 1,
                    "tooShort": 1,
                    "tooLong": 1,
                    "breathMiss": 1,
                    "tooFast": 2,
                    "tooSlow": 2,
                    "tooSoft": 2,
                    "tooHard": 2,
                    "breathEarly": 1,
                    "intensePeak": 2
                  },
                  "configs": [
                    {
                      "analyzeTypeSylLenMs": 500,
                      "analyzeTypeNumOfSylsInSeq": 5,
                      "weight": 3
                    },
                    {
                      "analyzeTypeSylLenMs": 200,
                      "analyzeTypeNumOfSylsInSeq": 5,
                      "weight": 5
                    }
                  ],
                  "analyzeTypeNumOfSylsInSeqAuto": true
                }
              ],
              "uiSettings": {
                "repetition": 5,
                "enableMirror": false,
                "instructions": "בתרגול זה יש להפיק מילים בתבנית שניה להברה, ולשאוף אויר בין מילה למילה.\nעל המסך יופיעו מילים באורכים שונים. יש להקפיד לשמור על רצף קולי בעת הפקת המילה.. \n חשוב להיעזר בתבנית המטרה המוצללת שמופיעה על גבי המוניטור, על מנת להפיק את המילים בצורה הנכונה, בדגם הגלים .\nבתרגול זה עליכם ליישם בצורה נכונה את העקרונות שנלמדו בסרטונים קודמים, תוך שמירה על מסגרת זמן של שניה לכל הברה במילה המופקת.",
                "showWordGoal": true,
                "defaultVideoInstructionsId": 0,
                "phraseAudioType": [],
                "videoInstructions": {
                  "id": 212,
                  "type": "video",
                  "title": "instructions.1sec_pattern_syllable_long_words_error_time_frame.monitor.mp4",
                  "value": "https://s3-eu-west-1.amazonaws.com/nvtk-video-media/2016_05/הוראות/instructions.1sec_pattern_syllable_long_words_error_time_frame.monitor.mp4",
                  "tags": [],
                  "thumb": "http://www.depaul.edu/campustour/Pages/_layouts/spotlight/btn-videoplayoverlay.png"
                },
                "isRecordEnabled": true,
                "isScreenShareEnabled": true
              },
              "wordGoal": 25,
              "description": "תרגול: שניה להברה- מילים רב הברתיות-מסגרת זמן הפקה",
              "name": "1-sec words",
              "timeEstimation": 375,
              "uiType": {
                "type": "box5stars",
                "icon": "icon-star"
              },
              "engineType": {
                "type": "AdvancedEngine",
                "icon": "icon-diamond"
              },
              "pressed": false,
              "passScore": "30",
              "jqyoui_pos": 5,
              "type": "wout"
            },
            "sequences": [
              {
                "fieldType": "seq",
                "timerPos": 1162,
                "calibrationEventId": "6581",
                "rate": 100,
                "deltaTimeMsFromPrevSeq": "-1.00",
                "yvalues": [
                  "108",
                  "162",
                  "189",
                  "190",
                  "178",
                  "158",
                  "137",
                  "96",
                  "128",
                  "165",
                  "176",
                  "167",
                  "135",
                  "91",
                  "0"
                ],
                "yDrawvalues": "1689,3258,3887,3556,2822,2056,1341,525,2193,3246,3424,2929,1703,548,0",
                "mistakes": [],
                "hiddenMistakes": [],
                "softVals": [
                  "1",
                  "1",
                  "0",
                  "0",
                  "0",
                  "1",
                  "1",
                  "1",
                  "1",
                  "1",
                  "1",
                  "1",
                  "1",
                  "1",
                  "1"
                ],
                "expectedSylsPerSecond": "0.00",
                "actualSylsPerSecond": "1.04",
                "timeLength": 1875,
                "features": [
                  {
                    "sampleYVal": 160,
                    "sampleYDrawVal": 2367
                  },
                  {
                    "sampleYVal": 194,
                    "sampleYDrawVal": 3603
                  },
                  {
                    "sampleYVal": 194,
                    "sampleYDrawVal": 3620
                  },
                  {
                    "sampleYVal": 184,
                    "sampleYDrawVal": 3306
                  },
                  {
                    "sampleYVal": 170,
                    "sampleYDrawVal": 2601
                  },
                  {
                    "sampleYVal": 123,
                    "sampleYDrawVal": 1283
                  },
                  {
                    "sampleYVal": 119,
                    "sampleYDrawVal": 1073
                  },
                  {
                    "sampleYVal": 110,
                    "sampleYDrawVal": 1321
                  },
                  {
                    "sampleYVal": 169,
                    "sampleYDrawVal": 2832
                  },
                  {
                    "sampleYVal": 187,
                    "sampleYDrawVal": 3555
                  },
                  {
                    "sampleYVal": 177,
                    "sampleYDrawVal": 3328
                  },
                  {
                    "sampleYVal": 145,
                    "sampleYDrawVal": 2255
                  },
                  {
                    "sampleYVal": 99,
                    "sampleYDrawVal": 1170
                  },
                  {
                    "sampleYVal": 74,
                    "sampleYDrawVal": 527
                  }
                ],
                "recordOffset": 3933,
                "seqUrl": "1527724830868_20633_709",
                "seqDescription": {
                  "analysis": {
                    "analysisType": "1sec"
                  },
                  "completeCount": 0,
                  "currentSeqPos": 0,
                  "pbId": "1715",
                  "text": "הָחוּט",
                  "type": "word",
                  "word": {
                    "id": 1715,
                    "language": "he",
                    "cleanInput": "הָחוּט",
                    "type": "word",
                    "data": "[{\"text\":\"\\u05d4\\u05b8\",\"sylType\":\"v\",\"vowel\":\"A\"},{\"text\":\"\\u05d7\\u05d5\\u05bc\\u05d8\",\"sylType\":\"cvc\",\"vowel\":\"U\",\"isContinous\":true,\"isPlosive\":true}]",
                    "isNonesense": 0,
                    "audios": "[]"
                  }
                },
                "mistakesObjects": {},
                "hiddenMistakesObjects": {}
              },
              {
                "fieldType": "seq",
                "timerPos": 1167,
                "calibrationEventId": "6581",
                "rate": 100,
                "deltaTimeMsFromPrevSeq": "2301.00",
                "yvalues": [
                  "100",
                  "132",
                  "157",
                  "191",
                  "203",
                  "179",
                  "151",
                  "135",
                  "106",
                  "138",
                  "167",
                  "180",
                  "178",
                  "146",
                  "115",
                  "71",
                  "0"
                ],
                "yDrawvalues": "1345,1914,2612,4108,4212,2684,1799,1386,779,2324,3117,3410,3180,1890,1127,344,0",
                "mistakes": [],
                "hiddenMistakes": [],
                "softVals": [
                  "1",
                  "1",
                  "1",
                  "0",
                  "0",
                  "0",
                  "1",
                  "1",
                  "1",
                  "1",
                  "1",
                  "0",
                  "0",
                  "1",
                  "1",
                  "1",
                  "1"
                ],
                "expectedSylsPerSecond": "0.00",
                "actualSylsPerSecond": "0.89",
                "timeLength": 2125,
                "features": [
                  {
                    "sampleYVal": 116,
                    "sampleYDrawVal": 1208
                  },
                  {
                    "sampleYVal": 162,
                    "sampleYDrawVal": 2375
                  },
                  {
                    "sampleYVal": 186,
                    "sampleYDrawVal": 3277
                  },
                  {
                    "sampleYVal": 210,
                    "sampleYDrawVal": 4268
                  },
                  {
                    "sampleYVal": 198,
                    "sampleYDrawVal": 3703
                  },
                  {
                    "sampleYVal": 146,
                    "sampleYDrawVal": 2047
                  },
                  {
                    "sampleYVal": 134,
                    "sampleYDrawVal": 1595
                  },
                  {
                    "sampleYVal": 117,
                    "sampleYDrawVal": 1075
                  },
                  {
                    "sampleYVal": 127,
                    "sampleYDrawVal": 1621
                  },
                  {
                    "sampleYVal": 172,
                    "sampleYDrawVal": 2786
                  },
                  {
                    "sampleYVal": 187,
                    "sampleYDrawVal": 3363
                  },
                  {
                    "sampleYVal": 183,
                    "sampleYDrawVal": 3406
                  },
                  {
                    "sampleYVal": 168,
                    "sampleYDrawVal": 2821
                  },
                  {
                    "sampleYVal": 119,
                    "sampleYDrawVal": 1690
                  },
                  {
                    "sampleYVal": 90,
                    "sampleYDrawVal": 850
                  },
                  {
                    "sampleYVal": 23,
                    "sampleYDrawVal": 38
                  }
                ],
                "recordOffset": 8221,
                "seqUrl": "1527724830868_20633_709",
                "seqDescription": {
                  "analysis": {
                    "analysisType": "1sec"
                  },
                  "completeCount": 1,
                  "currentSeqPos": 0,
                  "pbId": "1715",
                  "text": "הָחוּט",
                  "type": "word",
                  "word": {
                    "id": 1715,
                    "language": "he",
                    "cleanInput": "הָחוּט",
                    "type": "word",
                    "data": "[{\"text\":\"\\u05d4\\u05b8\",\"sylType\":\"v\",\"vowel\":\"A\"},{\"text\":\"\\u05d7\\u05d5\\u05bc\\u05d8\",\"sylType\":\"cvc\",\"vowel\":\"U\",\"isContinous\":true,\"isPlosive\":true}]",
                    "isNonesense": 0,
                    "audios": "[]"
                  }
                },
                "mistakesObjects": {},
                "hiddenMistakesObjects": {}
              },
              {
                "fieldType": "seq",
                "timerPos": 1171,
                "calibrationEventId": "6581",
                "rate": 100,
                "deltaTimeMsFromPrevSeq": "2561.00",
                "yvalues": [
                  "108",
                  "143",
                  "164",
                  "174",
                  "167",
                  "153",
                  "133",
                  "117",
                  "136",
                  "154",
                  "155",
                  "134",
                  "100",
                  "79",
                  "0"
                ],
                "yDrawvalues": "1338,2301,2856,2977,2422,1876,1289,1088,2152,2648,2543,1664,886,670,0",
                "mistakes": [],
                "hiddenMistakes": [],
                "softVals": [
                  "1",
                  "1",
                  "1",
                  "1",
                  "1",
                  "1",
                  "1",
                  "1",
                  "1",
                  "1",
                  "1",
                  "1",
                  "1",
                  "1",
                  "1"
                ],
                "expectedSylsPerSecond": "0.00",
                "actualSylsPerSecond": "1.04",
                "timeLength": 1875,
                "features": [
                  {
                    "sampleYVal": 134,
                    "sampleYDrawVal": 1491
                  },
                  {
                    "sampleYVal": 167,
                    "sampleYDrawVal": 2543
                  },
                  {
                    "sampleYVal": 177,
                    "sampleYDrawVal": 2921
                  },
                  {
                    "sampleYVal": 175,
                    "sampleYDrawVal": 2801
                  },
                  {
                    "sampleYVal": 160,
                    "sampleYDrawVal": 2262
                  },
                  {
                    "sampleYVal": 127,
                    "sampleYDrawVal": 1379
                  },
                  {
                    "sampleYVal": 106,
                    "sampleYDrawVal": 844
                  },
                  {
                    "sampleYVal": 144,
                    "sampleYDrawVal": 2097
                  },
                  {
                    "sampleYVal": 155,
                    "sampleYDrawVal": 2438
                  },
                  {
                    "sampleYVal": 169,
                    "sampleYDrawVal": 2916
                  },
                  {
                    "sampleYVal": 144,
                    "sampleYDrawVal": 2224
                  },
                  {
                    "sampleYVal": 110,
                    "sampleYDrawVal": 1256
                  },
                  {
                    "sampleYVal": 73,
                    "sampleYDrawVal": 714
                  },
                  {
                    "sampleYVal": 55,
                    "sampleYDrawVal": 399
                  }
                ],
                "recordOffset": 12952,
                "seqUrl": "1527724830868_20633_709",
                "seqDescription": {
                  "analysis": {
                    "analysisType": "1sec"
                  },
                  "completeCount": 2,
                  "currentSeqPos": 0,
                  "pbId": "1715",
                  "text": "הָחוּט",
                  "type": "word",
                  "word": {
                    "id": 1715,
                    "language": "he",
                    "cleanInput": "הָחוּט",
                    "type": "word",
                    "data": "[{\"text\":\"\\u05d4\\u05b8\",\"sylType\":\"v\",\"vowel\":\"A\"},{\"text\":\"\\u05d7\\u05d5\\u05bc\\u05d8\",\"sylType\":\"cvc\",\"vowel\":\"U\",\"isContinous\":true,\"isPlosive\":true}]",
                    "isNonesense": 0,
                    "audios": "[]"
                  }
                },
                "mistakesObjects": {},
                "hiddenMistakesObjects": {}
              },
              {
                "fieldType": "seq",
                "timerPos": 1176,
                "calibrationEventId": "6581",
                "rate": 80,
                "deltaTimeMsFromPrevSeq": "2750.00",
                "yvalues": [
                  "138",
                  "177",
                  "202",
                  "211",
                  "201",
                  "168",
                  "156",
                  "126",
                  "96",
                  "121",
                  "151",
                  "165",
                  "158",
                  "130",
                  "96",
                  "0"
                ],
                "yDrawvalues": "2548,3594,4424,4550,3652,2146,1973,1064,713,1813,2646,2924,2538,1642,920,0",
                "mistakes": [
                  {
                    "sampleOffset": 0,
                    "mistakeId": 1
                  }
                ],
                "hiddenMistakes": [
                  {
                    "sampleOffset": 3,
                    "mistakeId": 3
                  },
                  {
                    "sampleOffset": 11,
                    "mistakeId": 3
                  }
                ],
                "softVals": [
                  "1",
                  "0",
                  "0",
                  "0",
                  "0",
                  "1",
                  "1",
                  "1",
                  "1",
                  "1",
                  "1",
                  "1",
                  "1",
                  "1",
                  "1",
                  "1"
                ],
                "expectedSylsPerSecond": "0.00",
                "actualSylsPerSecond": "0.98",
                "timeLength": 2000,
                "features": [
                  {
                    "sampleYVal": 180,
                    "sampleYDrawVal": 2986
                  },
                  {
                    "sampleYVal": 207,
                    "sampleYDrawVal": 4111
                  },
                  {
                    "sampleYVal": 221,
                    "sampleYDrawVal": 4789
                  },
                  {
                    "sampleYVal": 207,
                    "sampleYDrawVal": 4194
                  },
                  {
                    "sampleYVal": 181,
                    "sampleYDrawVal": 2953
                  },
                  {
                    "sampleYVal": 146,
                    "sampleYDrawVal": 1834
                  },
                  {
                    "sampleYVal": 148,
                    "sampleYDrawVal": 1884
                  },
                  {
                    "sampleYVal": 82,
                    "sampleYDrawVal": 437
                  },
                  {
                    "sampleYVal": 130,
                    "sampleYDrawVal": 1719
                  },
                  {
                    "sampleYVal": 146,
                    "sampleYDrawVal": 2071
                  },
                  {
                    "sampleYVal": 177,
                    "sampleYDrawVal": 3138
                  },
                  {
                    "sampleYVal": 172,
                    "sampleYDrawVal": 3026
                  },
                  {
                    "sampleYVal": 150,
                    "sampleYDrawVal": 2431
                  },
                  {
                    "sampleYVal": 107,
                    "sampleYDrawVal": 1230
                  },
                  {
                    "sampleYVal": 63,
                    "sampleYDrawVal": 595
                  }
                ],
                "recordOffset": 17626,
                "seqUrl": "1527724830868_20633_709",
                "seqDescription": {
                  "analysis": {
                    "analysisType": "1sec"
                  },
                  "completeCount": 3,
                  "currentSeqPos": 0,
                  "pbId": "1715",
                  "text": "הָחוּט",
                  "type": "word",
                  "word": {
                    "id": 1715,
                    "language": "he",
                    "cleanInput": "הָחוּט",
                    "type": "word",
                    "data": "[{\"text\":\"\\u05d4\\u05b8\",\"sylType\":\"v\",\"vowel\":\"A\"},{\"text\":\"\\u05d7\\u05d5\\u05bc\\u05d8\",\"sylType\":\"cvc\",\"vowel\":\"U\",\"isContinous\":true,\"isPlosive\":true}]",
                    "isNonesense": 0,
                    "audios": "[]"
                  }
                },
                "mistakesObjects": {
                  "gentleOnset": {
                    "titlei18Key": "MISTAKES.GENTLE_ONSET"
                  }
                },
                "hiddenMistakesObjects": {
                  "softPeak": {
                    "titlei18Key": "MISTAKES.SOFT_PEAK"
                  }
                }
              },
              {
                "fieldType": "seq",
                "timerPos": 1181,
                "calibrationEventId": "6581",
                "rate": 100,
                "deltaTimeMsFromPrevSeq": "3011.00",
                "yvalues": [
                  "107",
                  "152",
                  "169",
                  "173",
                  "168",
                  "150",
                  "133",
                  "107",
                  "92",
                  "123",
                  "140",
                  "149",
                  "151",
                  "154",
                  "127",
                  "73",
                  "0"
                ],
                "yDrawvalues": "1675,2721,2887,2785,2456,1786,1487,894,788,1931,2251,2390,2478,2507,1455,329,0",
                "mistakes": [],
                "hiddenMistakes": [],
                "softVals": [
                  "1",
                  "1",
                  "1",
                  "1",
                  "1",
                  "1",
                  "1",
                  "1",
                  "1",
                  "1",
                  "1",
                  "1",
                  "1",
                  "1",
                  "1",
                  "1",
                  "1"
                ],
                "expectedSylsPerSecond": "0.00",
                "actualSylsPerSecond": "0.89",
                "timeLength": 2125,
                "features": [
                  {
                    "sampleYVal": 167,
                    "sampleYDrawVal": 2523
                  },
                  {
                    "sampleYVal": 174,
                    "sampleYDrawVal": 2758
                  },
                  {
                    "sampleYVal": 191,
                    "sampleYDrawVal": 3408
                  },
                  {
                    "sampleYVal": 176,
                    "sampleYDrawVal": 2774
                  },
                  {
                    "sampleYVal": 160,
                    "sampleYDrawVal": 2217
                  },
                  {
                    "sampleYVal": 127,
                    "sampleYDrawVal": 1465
                  },
                  {
                    "sampleYVal": 131,
                    "sampleYDrawVal": 1594
                  },
                  {
                    "sampleYVal": 89,
                    "sampleYDrawVal": 625
                  },
                  {
                    "sampleYVal": 131,
                    "sampleYDrawVal": 1730
                  },
                  {
                    "sampleYVal": 145,
                    "sampleYDrawVal": 2107
                  },
                  {
                    "sampleYVal": 154,
                    "sampleYDrawVal": 2401
                  },
                  {
                    "sampleYVal": 151,
                    "sampleYDrawVal": 2400
                  },
                  {
                    "sampleYVal": 158,
                    "sampleYDrawVal": 2620
                  },
                  {
                    "sampleYVal": 147,
                    "sampleYDrawVal": 2147
                  },
                  {
                    "sampleYVal": 86,
                    "sampleYDrawVal": 813
                  },
                  {
                    "sampleYVal": 24,
                    "sampleYDrawVal": 25
                  }
                ],
                "recordOffset": 22752,
                "seqUrl": "1527724830868_20633_709",
                "seqDescription": {
                  "analysis": {
                    "analysisType": "1sec"
                  },
                  "completeCount": 4,
                  "currentSeqPos": 0,
                  "pbId": "1715",
                  "text": "הָחוּט",
                  "type": "word",
                  "word": {
                    "id": 1715,
                    "language": "he",
                    "cleanInput": "הָחוּט",
                    "type": "word",
                    "data": "[{\"text\":\"\\u05d4\\u05b8\",\"sylType\":\"v\",\"vowel\":\"A\"},{\"text\":\"\\u05d7\\u05d5\\u05bc\\u05d8\",\"sylType\":\"cvc\",\"vowel\":\"U\",\"isContinous\":true,\"isPlosive\":true}]",
                    "isNonesense": 0,
                    "audios": "[]"
                  }
                },
                "mistakesObjects": {},
                "hiddenMistakesObjects": {}
              },
              {
                "fieldType": "seq",
                "timerPos": 1186,
                "calibrationEventId": "6581",
                "rate": 100,
                "deltaTimeMsFromPrevSeq": "2560.00",
                "yvalues": [
                  "121",
                  "157",
                  "179",
                  "194",
                  "198",
                  "185",
                  "163",
                  "155",
                  "123",
                  "99",
                  "130",
                  "167",
                  "185",
                  "181",
                  "166",
                  "143",
                  "112",
                  "92",
                  "0"
                ],
                "yDrawvalues": "1948,2692,3277,3812,3861,3056,2134,2102,1241,964,2056,3234,3590,3069,2360,1597,913,717,0",
                "mistakes": [],
                "hiddenMistakes": [],
                "softVals": [
                  "1",
                  "1",
                  "0",
                  "0",
                  "0",
                  "0",
                  "1",
                  "1",
                  "1",
                  "1",
                  "1",
                  "1",
                  "0",
                  "0",
                  "1",
                  "1",
                  "1",
                  "1",
                  "1"
                ],
                "expectedSylsPerSecond": "0.00",
                "actualSylsPerSecond": "0.80",
                "timeLength": 2375,
                "features": [
                  {
                    "sampleYVal": 151,
                    "sampleYDrawVal": 1994
                  },
                  {
                    "sampleYVal": 179,
                    "sampleYDrawVal": 2929
                  },
                  {
                    "sampleYVal": 193,
                    "sampleYDrawVal": 3448
                  },
                  {
                    "sampleYVal": 204,
                    "sampleYDrawVal": 3938
                  },
                  {
                    "sampleYVal": 200,
                    "sampleYDrawVal": 3826
                  },
                  {
                    "sampleYVal": 171,
                    "sampleYDrawVal": 2654
                  },
                  {
                    "sampleYVal": 158,
                    "sampleYDrawVal": 2252
                  },
                  {
                    "sampleYVal": 137,
                    "sampleYDrawVal": 1746
                  },
                  {
                    "sampleYVal": 107,
                    "sampleYDrawVal": 1122
                  },
                  {
                    "sampleYVal": 90,
                    "sampleYDrawVal": 1074
                  },
                  {
                    "sampleYVal": 163,
                    "sampleYDrawVal": 2546
                  },
                  {
                    "sampleYVal": 195,
                    "sampleYDrawVal": 3635
                  },
                  {
                    "sampleYVal": 192,
                    "sampleYDrawVal": 3526
                  },
                  {
                    "sampleYVal": 172,
                    "sampleYDrawVal": 2749
                  },
                  {
                    "sampleYVal": 142,
                    "sampleYDrawVal": 1801
                  },
                  {
                    "sampleYVal": 119,
                    "sampleYDrawVal": 1306
                  },
                  {
                    "sampleYVal": 96,
                    "sampleYDrawVal": 819
                  },
                  {
                    "sampleYVal": 69,
                    "sampleYDrawVal": 559
                  }
                ],
                "recordOffset": 27552,
                "seqUrl": "1527724830868_20633_709",
                "seqDescription": {
                  "analysis": {
                    "analysisType": "1sec"
                  },
                  "completeCount": 0,
                  "currentSeqPos": 0,
                  "pbId": "1931",
                  "text": "בְּגִיל",
                  "type": "word",
                  "word": {
                    "id": 1931,
                    "language": "he",
                    "cleanInput": "בְּגִיל",
                    "type": "word",
                    "data": "[{\"text\":\"\\u05d1\\u05bc\\u05b0\",\"sylType\":\"cv\",\"vowel\":\"E\",\"isPlosive\":true},{\"text\":\"\\u05d2\\u05b4\\u05d9\\u05dc\",\"sylType\":\"cvc\",\"vowel\":\"I\",\"isContinous\":true,\"isPlosive\":true}]",
                    "isNonesense": 0,
                    "audios": "[]"
                  }
                },
                "mistakesObjects": {},
                "hiddenMistakesObjects": {}
              },
              {
                "fieldType": "seq",
                "timerPos": 1192,
                "calibrationEventId": "6581",
                "rate": 100,
                "deltaTimeMsFromPrevSeq": "2781.00",
                "yvalues": [
                  "100",
                  "146",
                  "174",
                  "193",
                  "198",
                  "194",
                  "188",
                  "171",
                  "154",
                  "137",
                  "119",
                  "121",
                  "145",
                  "168",
                  "165",
                  "149",
                  "127",
                  "89",
                  "0"
                ],
                "yDrawvalues": "1675,2465,3189,3906,3811,3503,3277,2532,2044,1707,1296,1544,2381,2958,2410,1853,1127,381,0",
                "mistakes": [],
                "hiddenMistakes": [],
                "softVals": [
                  "1",
                  "1",
                  "1",
                  "0",
                  "0",
                  "0",
                  "0",
                  "1",
                  "1",
                  "1",
                  "1",
                  "1",
                  "1",
                  "1",
                  "1",
                  "1",
                  "1",
                  "1",
                  "1"
                ],
                "expectedSylsPerSecond": "0.00",
                "actualSylsPerSecond": "0.82",
                "timeLength": 2375,
                "features": [
                  {
                    "sampleYVal": 162,
                    "sampleYDrawVal": 2326
                  },
                  {
                    "sampleYVal": 174,
                    "sampleYDrawVal": 2696
                  },
                  {
                    "sampleYVal": 193,
                    "sampleYDrawVal": 3546
                  },
                  {
                    "sampleYVal": 199,
                    "sampleYDrawVal": 3742
                  },
                  {
                    "sampleYVal": 208,
                    "sampleYDrawVal": 4144
                  },
                  {
                    "sampleYVal": 188,
                    "sampleYDrawVal": 3344
                  },
                  {
                    "sampleYVal": 181,
                    "sampleYDrawVal": 3068
                  },
                  {
                    "sampleYVal": 155,
                    "sampleYDrawVal": 2256
                  },
                  {
                    "sampleYVal": 146,
                    "sampleYDrawVal": 1971
                  },
                  {
                    "sampleYVal": 117,
                    "sampleYDrawVal": 1476
                  },
                  {
                    "sampleYVal": 105,
                    "sampleYDrawVal": 1225
                  },
                  {
                    "sampleYVal": 139,
                    "sampleYDrawVal": 1902
                  },
                  {
                    "sampleYVal": 168,
                    "sampleYDrawVal": 2685
                  },
                  {
                    "sampleYVal": 184,
                    "sampleYDrawVal": 3172
                  },
                  {
                    "sampleYVal": 160,
                    "sampleYDrawVal": 2278
                  },
                  {
                    "sampleYVal": 133,
                    "sampleYDrawVal": 1557
                  },
                  {
                    "sampleYVal": 101,
                    "sampleYDrawVal": 759
                  },
                  {
                    "sampleYVal": 60,
                    "sampleYDrawVal": 267
                  }
                ],
                "recordOffset": 2759,
                "seqUrl": "1527724860869_20633_709",
                "seqDescription": {
                  "analysis": {
                    "analysisType": "1sec"
                  },
                  "completeCount": 1,
                  "currentSeqPos": 0,
                  "pbId": "1931",
                  "text": "בְּגִיל",
                  "type": "word",
                  "word": {
                    "id": 1931,
                    "language": "he",
                    "cleanInput": "בְּגִיל",
                    "type": "word",
                    "data": "[{\"text\":\"\\u05d1\\u05bc\\u05b0\",\"sylType\":\"cv\",\"vowel\":\"E\",\"isPlosive\":true},{\"text\":\"\\u05d2\\u05b4\\u05d9\\u05dc\",\"sylType\":\"cvc\",\"vowel\":\"I\",\"isContinous\":true,\"isPlosive\":true}]",
                    "isNonesense": 0,
                    "audios": "[]"
                  }
                },
                "mistakesObjects": {},
                "hiddenMistakesObjects": {}
              },
              {
                "fieldType": "seq",
                "timerPos": 1196,
                "calibrationEventId": "6581",
                "rate": 80,
                "deltaTimeMsFromPrevSeq": "2752.00",
                "yvalues": [
                  "146",
                  "171",
                  "183",
                  "188",
                  "192",
                  "185",
                  "163",
                  "140",
                  "148",
                  "165",
                  "170",
                  "175",
                  "170",
                  "153",
                  "124",
                  "71",
                  "0"
                ],
                "yDrawvalues": "2653,3064,3296,3432,3604,3083,2139,1633,2211,2756,2700,2864,2608,1977,1097,206,0",
                "mistakes": [
                  {
                    "sampleOffset": 0,
                    "mistakeId": 1
                  }
                ],
                "hiddenMistakes": [
                  {
                    "sampleOffset": 4,
                    "mistakeId": 3
                  },
                  {
                    "sampleOffset": 11,
                    "mistakeId": 3
                  },
                  {
                    "sampleOffset": 16,
                    "mistakeId": 7
                  }
                ],
                "softVals": [
                  "1",
                  "1",
                  "0",
                  "0",
                  "0",
                  "0",
                  "1",
                  "1",
                  "1",
                  "1",
                  "1",
                  "1",
                  "1",
                  "1",
                  "1",
                  "1",
                  "1"
                ],
                "expectedSylsPerSecond": "0.00",
                "actualSylsPerSecond": "0.92",
                "timeLength": 2125,
                "features": [
                  {
                    "sampleYVal": 179,
                    "sampleYDrawVal": 2932
                  },
                  {
                    "sampleYVal": 189,
                    "sampleYDrawVal": 3290
                  },
                  {
                    "sampleYVal": 189,
                    "sampleYDrawVal": 3328
                  },
                  {
                    "sampleYVal": 191,
                    "sampleYDrawVal": 3472
                  },
                  {
                    "sampleYVal": 196,
                    "sampleYDrawVal": 3608
                  },
                  {
                    "sampleYVal": 173,
                    "sampleYDrawVal": 2714
                  },
                  {
                    "sampleYVal": 140,
                    "sampleYDrawVal": 1760
                  },
                  {
                    "sampleYVal": 150,
                    "sampleYDrawVal": 2117
                  },
                  {
                    "sampleYVal": 160,
                    "sampleYDrawVal": 2420
                  },
                  {
                    "sampleYVal": 169,
                    "sampleYDrawVal": 2633
                  },
                  {
                    "sampleYVal": 175,
                    "sampleYDrawVal": 2800
                  },
                  {
                    "sampleYVal": 172,
                    "sampleYDrawVal": 2654
                  },
                  {
                    "sampleYVal": 157,
                    "sampleYDrawVal": 2269
                  },
                  {
                    "sampleYVal": 127,
                    "sampleYDrawVal": 1432
                  },
                  {
                    "sampleYVal": 97,
                    "sampleYDrawVal": 795
                  },
                  {
                    "sampleYVal": 24,
                    "sampleYDrawVal": 48
                  }
                ],
                "recordOffset": 7939,
                "seqUrl": "1527724860869_20633_709",
                "seqDescription": {
                  "analysis": {
                    "analysisType": "1sec"
                  },
                  "completeCount": 2,
                  "currentSeqPos": 0,
                  "pbId": "1931",
                  "text": "בְּגִיל",
                  "type": "word",
                  "word": {
                    "id": 1931,
                    "language": "he",
                    "cleanInput": "בְּגִיל",
                    "type": "word",
                    "data": "[{\"text\":\"\\u05d1\\u05bc\\u05b0\",\"sylType\":\"cv\",\"vowel\":\"E\",\"isPlosive\":true},{\"text\":\"\\u05d2\\u05b4\\u05d9\\u05dc\",\"sylType\":\"cvc\",\"vowel\":\"I\",\"isContinous\":true,\"isPlosive\":true}]",
                    "isNonesense": 0,
                    "audios": "[]"
                  }
                },
                "mistakesObjects": {
                  "gentleOnset": {
                    "titlei18Key": "MISTAKES.GENTLE_ONSET"
                  }
                },
                "hiddenMistakesObjects": {
                  "softPeak": {
                    "titlei18Key": "MISTAKES.SOFT_PEAK"
                  },
                  "tooLong": {
                    "titlei18Key": "MISTAKES.TOO_LONG"
                  }
                }
              },
              {
                "fieldType": "seq",
                "timerPos": 1202,
                "calibrationEventId": "6581",
                "rate": 100,
                "deltaTimeMsFromPrevSeq": "2751.00",
                "yvalues": [
                  "106",
                  "157",
                  "181",
                  "199",
                  "197",
                  "181",
                  "158",
                  "137",
                  "115",
                  "130",
                  "157",
                  "172",
                  "169",
                  "160",
                  "152",
                  "144",
                  "112",
                  "54",
                  "0"
                ],
                "yDrawvalues": "1890,3058,3488,4130,3740,2898,2093,1561,1203,1782,2686,3001,2515,2168,1935,1741,857,47,0",
                "mistakes": [],
                "hiddenMistakes": [],
                "softVals": [
                  "1",
                  "1",
                  "0",
                  "0",
                  "0",
                  "0",
                  "1",
                  "1",
                  "1",
                  "1",
                  "1",
                  "1",
                  "1",
                  "1",
                  "1",
                  "1",
                  "1",
                  "1",
                  "1"
                ],
                "expectedSylsPerSecond": "0.00",
                "actualSylsPerSecond": "0.80",
                "timeLength": 2375,
                "features": [
                  {
                    "sampleYVal": 171,
                    "sampleYDrawVal": 2730
                  },
                  {
                    "sampleYVal": 180,
                    "sampleYDrawVal": 3064
                  },
                  {
                    "sampleYVal": 201,
                    "sampleYDrawVal": 3896
                  },
                  {
                    "sampleYVal": 206,
                    "sampleYDrawVal": 4102
                  },
                  {
                    "sampleYVal": 189,
                    "sampleYDrawVal": 3414
                  },
                  {
                    "sampleYVal": 170,
                    "sampleYDrawVal": 2652
                  },
                  {
                    "sampleYVal": 140,
                    "sampleYDrawVal": 1827
                  },
                  {
                    "sampleYVal": 118,
                    "sampleYDrawVal": 1282
                  },
                  {
                    "sampleYVal": 125,
                    "sampleYDrawVal": 1477
                  },
                  {
                    "sampleYVal": 151,
                    "sampleYDrawVal": 2122
                  },
                  {
                    "sampleYVal": 175,
                    "sampleYDrawVal": 2878
                  },
                  {
                    "sampleYVal": 177,
                    "sampleYDrawVal": 2851
                  },
                  {
                    "sampleYVal": 161,
                    "sampleYDrawVal": 2273
                  },
                  {
                    "sampleYVal": 151,
                    "sampleYDrawVal": 1975
                  },
                  {
                    "sampleYVal": 148,
                    "sampleYDrawVal": 1908
                  },
                  {
                    "sampleYVal": 142,
                    "sampleYDrawVal": 1769
                  },
                  {
                    "sampleYVal": 49,
                    "sampleYDrawVal": 187
                  },
                  {
                    "sampleYVal": 27,
                    "sampleYDrawVal": 34
                  }
                ],
                "recordOffset": 12930,
                "seqUrl": "1527724860869_20633_709",
                "seqDescription": {
                  "analysis": {
                    "analysisType": "1sec"
                  },
                  "completeCount": 3,
                  "currentSeqPos": 0,
                  "pbId": "1931",
                  "text": "בְּגִיל",
                  "type": "word",
                  "word": {
                    "id": 1931,
                    "language": "he",
                    "cleanInput": "בְּגִיל",
                    "type": "word",
                    "data": "[{\"text\":\"\\u05d1\\u05bc\\u05b0\",\"sylType\":\"cv\",\"vowel\":\"E\",\"isPlosive\":true},{\"text\":\"\\u05d2\\u05b4\\u05d9\\u05dc\",\"sylType\":\"cvc\",\"vowel\":\"I\",\"isContinous\":true,\"isPlosive\":true}]",
                    "isNonesense": 0,
                    "audios": "[]"
                  }
                },
                "mistakesObjects": {},
                "hiddenMistakesObjects": {}
              },
              {
                "fieldType": "seq",
                "timerPos": 1207,
                "calibrationEventId": "6581",
                "rate": 80,
                "deltaTimeMsFromPrevSeq": "2758.00",
                "yvalues": [
                  "149",
                  "173",
                  "188",
                  "195",
                  "188",
                  "173",
                  "153",
                  "119",
                  "103",
                  "139",
                  "167",
                  "179",
                  "173",
                  "158",
                  "136",
                  "97",
                  "0"
                ],
                "yDrawvalues": "3054,3105,3584,3692,3165,2468,1883,1031,909,2265,2998,3161,2675,2052,1354,472,0",
                "mistakes": [
                  {
                    "sampleOffset": 0,
                    "mistakeId": 1
                  }
                ],
                "hiddenMistakes": [
                  {
                    "sampleOffset": 3,
                    "mistakeId": 3
                  },
                  {
                    "sampleOffset": 11,
                    "mistakeId": 3
                  },
                  {
                    "sampleOffset": 16,
                    "mistakeId": 7
                  }
                ],
                "softVals": [
                  "1",
                  "1",
                  "0",
                  "0",
                  "0",
                  "1",
                  "1",
                  "1",
                  "1",
                  "1",
                  "1",
                  "0",
                  "1",
                  "1",
                  "1",
                  "1",
                  "1"
                ],
                "expectedSylsPerSecond": "0.00",
                "actualSylsPerSecond": "0.94",
                "timeLength": 2125,
                "features": [
                  {
                    "sampleYVal": 190,
                    "sampleYDrawVal": 3324
                  },
                  {
                    "sampleYVal": 186,
                    "sampleYDrawVal": 3170
                  },
                  {
                    "sampleYVal": 190,
                    "sampleYDrawVal": 3327
                  },
                  {
                    "sampleYVal": 204,
                    "sampleYDrawVal": 3926
                  },
                  {
                    "sampleYVal": 172,
                    "sampleYDrawVal": 2605
                  },
                  {
                    "sampleYVal": 155,
                    "sampleYDrawVal": 2113
                  },
                  {
                    "sampleYVal": 143,
                    "sampleYDrawVal": 1885
                  },
                  {
                    "sampleYVal": 61,
                    "sampleYDrawVal": 440
                  },
                  {
                    "sampleYVal": 131,
                    "sampleYDrawVal": 1599
                  },
                  {
                    "sampleYVal": 170,
                    "sampleYDrawVal": 2677
                  },
                  {
                    "sampleYVal": 181,
                    "sampleYDrawVal": 3043
                  },
                  {
                    "sampleYVal": 186,
                    "sampleYDrawVal": 3209
                  },
                  {
                    "sampleYVal": 160,
                    "sampleYDrawVal": 2372
                  },
                  {
                    "sampleYVal": 151,
                    "sampleYDrawVal": 1997
                  },
                  {
                    "sampleYVal": 107,
                    "sampleYDrawVal": 860
                  },
                  {
                    "sampleYVal": 60,
                    "sampleYDrawVal": 231
                  }
                ],
                "recordOffset": 18061,
                "seqUrl": "1527724860869_20633_709",
                "seqDescription": {
                  "analysis": {
                    "analysisType": "1sec"
                  },
                  "completeCount": 4,
                  "currentSeqPos": 0,
                  "pbId": "1931",
                  "text": "בְּגִיל",
                  "type": "word",
                  "word": {
                    "id": 1931,
                    "language": "he",
                    "cleanInput": "בְּגִיל",
                    "type": "word",
                    "data": "[{\"text\":\"\\u05d1\\u05bc\\u05b0\",\"sylType\":\"cv\",\"vowel\":\"E\",\"isPlosive\":true},{\"text\":\"\\u05d2\\u05b4\\u05d9\\u05dc\",\"sylType\":\"cvc\",\"vowel\":\"I\",\"isContinous\":true,\"isPlosive\":true}]",
                    "isNonesense": 0,
                    "audios": "[]"
                  }
                },
                "mistakesObjects": {
                  "gentleOnset": {
                    "titlei18Key": "MISTAKES.GENTLE_ONSET"
                  }
                },
                "hiddenMistakesObjects": {
                  "softPeak": {
                    "titlei18Key": "MISTAKES.SOFT_PEAK"
                  },
                  "tooLong": {
                    "titlei18Key": "MISTAKES.TOO_LONG"
                  }
                }
              },
              {
                "fieldType": "seq",
                "timerPos": 1212,
                "calibrationEventId": "6581",
                "rate": 100,
                "deltaTimeMsFromPrevSeq": "2621.00",
                "yvalues": [
                  "124",
                  "146",
                  "164",
                  "172",
                  "155",
                  "135",
                  "107",
                  "87",
                  "131",
                  "182",
                  "200",
                  "197",
                  "160",
                  "144",
                  "145",
                  "141",
                  "119",
                  "71",
                  "0"
                ],
                "yDrawvalues": "1748,2131,2738,2900,2080,1598,1022,835,2347,4001,4158,3648,2069,1808,1939,1842,1036,248,0",
                "mistakes": [],
                "hiddenMistakes": [],
                "softVals": [
                  "1",
                  "1",
                  "1",
                  "1",
                  "1",
                  "1",
                  "1",
                  "1",
                  "1",
                  "0",
                  "0",
                  "0",
                  "1",
                  "1",
                  "1",
                  "1",
                  "1",
                  "1",
                  "1"
                ],
                "expectedSylsPerSecond": "0.00",
                "actualSylsPerSecond": "0.80",
                "timeLength": 2375,
                "features": [
                  {
                    "sampleYVal": 145,
                    "sampleYDrawVal": 1807
                  },
                  {
                    "sampleYVal": 163,
                    "sampleYDrawVal": 2363
                  },
                  {
                    "sampleYVal": 191,
                    "sampleYDrawVal": 3408
                  },
                  {
                    "sampleYVal": 168,
                    "sampleYDrawVal": 2605
                  },
                  {
                    "sampleYVal": 142,
                    "sampleYDrawVal": 1920
                  },
                  {
                    "sampleYVal": 119,
                    "sampleYDrawVal": 1456
                  },
                  {
                    "sampleYVal": 69,
                    "sampleYDrawVal": 594
                  },
                  {
                    "sampleYVal": 99,
                    "sampleYDrawVal": 1265
                  },
                  {
                    "sampleYVal": 198,
                    "sampleYDrawVal": 3729
                  },
                  {
                    "sampleYVal": 210,
                    "sampleYDrawVal": 4255
                  },
                  {
                    "sampleYVal": 205,
                    "sampleYDrawVal": 4017
                  },
                  {
                    "sampleYVal": 186,
                    "sampleYDrawVal": 3261
                  },
                  {
                    "sampleYVal": 123,
                    "sampleYDrawVal": 1570
                  },
                  {
                    "sampleYVal": 135,
                    "sampleYDrawVal": 1690
                  },
                  {
                    "sampleYVal": 143,
                    "sampleYDrawVal": 1930
                  },
                  {
                    "sampleYVal": 137,
                    "sampleYDrawVal": 1630
                  },
                  {
                    "sampleYVal": 96,
                    "sampleYDrawVal": 803
                  },
                  {
                    "sampleYVal": 23,
                    "sampleYDrawVal": 23
                  }
                ],
                "recordOffset": 22930,
                "seqUrl": "1527724860869_20633_709",
                "seqDescription": {
                  "analysis": {
                    "analysisType": "1sec"
                  },
                  "completeCount": 0,
                  "currentSeqPos": 0,
                  "pbId": "908",
                  "text": "מִסְפָּר",
                  "type": "word",
                  "word": {
                    "id": 908,
                    "language": "he",
                    "cleanInput": "מִסְפָּר",
                    "type": "word",
                    "data": "[{\"text\":\"\\u05de\\u05b4\\u05e1\\u05b0\",\"sylType\":\"cvc\",\"vowel\":\"I\",\"isContinous\":true},{\"text\":\"\\u05e4\\u05bc\\u05b8\\u05e8\",\"sylType\":\"cvc\",\"vowel\":\"A\",\"isContinous\":true,\"isPlosive\":true}]",
                    "isNonesense": 0,
                    "audios": ""
                  }
                },
                "mistakesObjects": {},
                "hiddenMistakesObjects": {}
              },
              {
                "fieldType": "seq",
                "timerPos": 1215,
                "calibrationEventId": "6581",
                "rate": 100,
                "deltaTimeMsFromPrevSeq": "2430.00",
                "yvalues": [
                  "128",
                  "160",
                  "187",
                  "189",
                  "157",
                  "130",
                  "81",
                  "0"
                ],
                "yDrawvalues": "1928,2808,3805,3466,1911,1319,447,0",
                "mistakes": [],
                "hiddenMistakes": [],
                "softVals": [
                  "1",
                  "1",
                  "0",
                  "0",
                  "1",
                  "1",
                  "1",
                  "1"
                ],
                "expectedSylsPerSecond": "0.00",
                "actualSylsPerSecond": "1.04",
                "timeLength": 1000,
                "features": [
                  {
                    "sampleYVal": 148,
                    "sampleYDrawVal": 1925
                  },
                  {
                    "sampleYVal": 200,
                    "sampleYDrawVal": 3787
                  },
                  {
                    "sampleYVal": 190,
                    "sampleYDrawVal": 3413
                  },
                  {
                    "sampleYVal": 170,
                    "sampleYDrawVal": 2598
                  },
                  {
                    "sampleYVal": 131,
                    "sampleYDrawVal": 1578
                  },
                  {
                    "sampleYVal": 101,
                    "sampleYDrawVal": 997
                  },
                  {
                    "sampleYVal": 66,
                    "sampleYDrawVal": 737
                  }
                ],
                "recordOffset": 27700,
                "seqUrl": "1527724860869_20633_709",
                "seqDescription": {
                  "analysis": {
                    "analysisType": "1sec"
                  },
                  "completeCount": 1,
                  "currentSeqPos": 0,
                  "pbId": "908",
                  "text": "מִסְפָּר",
                  "type": "word",
                  "word": {
                    "id": 908,
                    "language": "he",
                    "cleanInput": "מִסְפָּר",
                    "type": "word",
                    "data": "[{\"text\":\"\\u05de\\u05b4\\u05e1\\u05b0\",\"sylType\":\"cvc\",\"vowel\":\"I\",\"isContinous\":true},{\"text\":\"\\u05e4\\u05bc\\u05b8\\u05e8\",\"sylType\":\"cvc\",\"vowel\":\"A\",\"isContinous\":true,\"isPlosive\":true}]",
                    "isNonesense": 0,
                    "audios": ""
                  }
                },
                "mistakesObjects": {},
                "hiddenMistakesObjects": {}
              },
              {
                "fieldType": "seq",
                "timerPos": 1217,
                "calibrationEventId": "6581",
                "rate": 100,
                "deltaTimeMsFromPrevSeq": "125.00",
                "yvalues": [
                  "118",
                  "154",
                  "177",
                  "189",
                  "193",
                  "187",
                  "171",
                  "151",
                  "126",
                  "112",
                  "106",
                  "92",
                  "0"
                ],
                "yDrawvalues": "1743,2718,3269,3534,3523,3157,2451,1828,1221,1098,1080,797,0",
                "mistakes": [],
                "hiddenMistakes": [],
                "softVals": [
                  "1",
                  "1",
                  "0",
                  "0",
                  "0",
                  "0",
                  "1",
                  "1",
                  "1",
                  "1",
                  "1",
                  "1",
                  "1"
                ],
                "expectedSylsPerSecond": "0.00",
                "actualSylsPerSecond": "0.59",
                "timeLength": 1625,
                "features": [
                  {
                    "sampleYVal": 142,
                    "sampleYDrawVal": 2030
                  },
                  {
                    "sampleYVal": 185,
                    "sampleYDrawVal": 3140
                  },
                  {
                    "sampleYVal": 191,
                    "sampleYDrawVal": 3395
                  },
                  {
                    "sampleYVal": 197,
                    "sampleYDrawVal": 3633
                  },
                  {
                    "sampleYVal": 187,
                    "sampleYDrawVal": 3185
                  },
                  {
                    "sampleYVal": 178,
                    "sampleYDrawVal": 2923
                  },
                  {
                    "sampleYVal": 155,
                    "sampleYDrawVal": 2203
                  },
                  {
                    "sampleYVal": 128,
                    "sampleYDrawVal": 1414
                  },
                  {
                    "sampleYVal": 122,
                    "sampleYDrawVal": 1366
                  },
                  {
                    "sampleYVal": 98,
                    "sampleYDrawVal": 967
                  },
                  {
                    "sampleYVal": 120,
                    "sampleYDrawVal": 1245
                  },
                  {
                    "sampleYVal": 64,
                    "sampleYDrawVal": 416
                  }
                ],
                "recordOffset": -1100,
                "seqUrl": "1527724890869_20633_709",
                "seqDescription": {
                  "analysis": {
                    "analysisType": "1sec"
                  },
                  "completeCount": 2,
                  "currentSeqPos": 0,
                  "pbId": "908",
                  "text": "מִסְפָּר",
                  "type": "word",
                  "word": {
                    "id": 908,
                    "language": "he",
                    "cleanInput": "מִסְפָּר",
                    "type": "word",
                    "data": "[{\"text\":\"\\u05de\\u05b4\\u05e1\\u05b0\",\"sylType\":\"cvc\",\"vowel\":\"I\",\"isContinous\":true},{\"text\":\"\\u05e4\\u05bc\\u05b8\\u05e8\",\"sylType\":\"cvc\",\"vowel\":\"A\",\"isContinous\":true,\"isPlosive\":true}]",
                    "isNonesense": 0,
                    "audios": ""
                  }
                },
                "mistakesObjects": {},
                "hiddenMistakesObjects": {}
              },
              {
                "fieldType": "seq",
                "timerPos": 1222,
                "calibrationEventId": "6581",
                "rate": 80,
                "deltaTimeMsFromPrevSeq": "2429.00",
                "yvalues": [
                  "120",
                  "147",
                  "174",
                  "174",
                  "159",
                  "141",
                  "124",
                  "101",
                  "105",
                  "114",
                  "146",
                  "167",
                  "174",
                  "170",
                  "163",
                  "150",
                  "123",
                  "80",
                  "0"
                ],
                "yDrawvalues": "1617,2276,3230,2793,2159,1731,1446,1108,1330,1438,2367,2880,2920,2618,2359,1970,1294,367,0",
                "mistakes": [
                  {
                    "sampleOffset": 17,
                    "mistakeId": 11
                  }
                ],
                "hiddenMistakes": [
                  {
                    "sampleOffset": 18,
                    "mistakeId": 7
                  }
                ],
                "softVals": [
                  "1",
                  "1",
                  "1",
                  "1",
                  "1",
                  "1",
                  "1",
                  "1",
                  "1",
                  "1",
                  "1",
                  "1",
                  "1",
                  "1",
                  "1",
                  "1",
                  "1",
                  "1",
                  "1"
                ],
                "expectedSylsPerSecond": "0.00",
                "actualSylsPerSecond": "0.80",
                "timeLength": 2375,
                "features": [
                  {
                    "sampleYVal": 153,
                    "sampleYDrawVal": 2112
                  },
                  {
                    "sampleYVal": 176,
                    "sampleYDrawVal": 2834
                  },
                  {
                    "sampleYVal": 182,
                    "sampleYDrawVal": 3061
                  },
                  {
                    "sampleYVal": 163,
                    "sampleYDrawVal": 2421
                  },
                  {
                    "sampleYVal": 148,
                    "sampleYDrawVal": 2006
                  },
                  {
                    "sampleYVal": 133,
                    "sampleYDrawVal": 1686
                  },
                  {
                    "sampleYVal": 100,
                    "sampleYDrawVal": 1203
                  },
                  {
                    "sampleYVal": 98,
                    "sampleYDrawVal": 1173
                  },
                  {
                    "sampleYVal": 105,
                    "sampleYDrawVal": 1302
                  },
                  {
                    "sampleYVal": 138,
                    "sampleYDrawVal": 1843
                  },
                  {
                    "sampleYVal": 168,
                    "sampleYDrawVal": 2589
                  },
                  {
                    "sampleYVal": 174,
                    "sampleYDrawVal": 2806
                  },
                  {
                    "sampleYVal": 176,
                    "sampleYDrawVal": 2875
                  },
                  {
                    "sampleYVal": 167,
                    "sampleYDrawVal": 2557
                  },
                  {
                    "sampleYVal": 157,
                    "sampleYDrawVal": 2224
                  },
                  {
                    "sampleYVal": 135,
                    "sampleYDrawVal": 1735
                  },
                  {
                    "sampleYVal": 92,
                    "sampleYDrawVal": 930
                  },
                  {
                    "sampleYVal": 39,
                    "sampleYDrawVal": 72
                  }
                ],
                "recordOffset": 3080,
                "seqUrl": "1527724890869_20633_709",
                "seqDescription": {
                  "analysis": {
                    "analysisType": "1sec"
                  },
                  "completeCount": 3,
                  "currentSeqPos": 0,
                  "pbId": "908",
                  "text": "מִסְפָּר",
                  "type": "word",
                  "word": {
                    "id": 908,
                    "language": "he",
                    "cleanInput": "מִסְפָּר",
                    "type": "word",
                    "data": "[{\"text\":\"\\u05de\\u05b4\\u05e1\\u05b0\",\"sylType\":\"cvc\",\"vowel\":\"I\",\"isContinous\":true},{\"text\":\"\\u05e4\\u05bc\\u05b8\\u05e8\",\"sylType\":\"cvc\",\"vowel\":\"A\",\"isContinous\":true,\"isPlosive\":true}]",
                    "isNonesense": 0,
                    "audios": ""
                  }
                },
                "mistakesObjects": {
                  "tooSlow": {
                    "titlei18Key": "MISTAKES.TOO_SLOW"
                  }
                },
                "hiddenMistakesObjects": {
                  "tooLong": {
                    "titlei18Key": "MISTAKES.TOO_LONG"
                  }
                }
              },
              {
                "fieldType": "seq",
                "timerPos": 1227,
                "calibrationEventId": "6581",
                "rate": 100,
                "deltaTimeMsFromPrevSeq": "2751.00",
                "yvalues": [
                  "119",
                  "159",
                  "168",
                  "166",
                  "158",
                  "156",
                  "151",
                  "121",
                  "115",
                  "133",
                  "163",
                  "184",
                  "180",
                  "173",
                  "157",
                  "116",
                  "54",
                  "0"
                ],
                "yDrawvalues": "2253,2886,2731,2546,2283,2317,2207,1412,1475,2055,2915,3546,3005,2785,2150,918,55,0",
                "mistakes": [],
                "hiddenMistakes": [],
                "softVals": [
                  "1",
                  "1",
                  "1",
                  "1",
                  "1",
                  "1",
                  "1",
                  "1",
                  "1",
                  "1",
                  "1",
                  "0",
                  "0",
                  "1",
                  "1",
                  "1",
                  "1",
                  "1"
                ],
                "expectedSylsPerSecond": "0.00",
                "actualSylsPerSecond": "0.87",
                "timeLength": 2250,
                "features": [
                  {
                    "sampleYVal": 173,
                    "sampleYDrawVal": 2750
                  },
                  {
                    "sampleYVal": 178,
                    "sampleYDrawVal": 2919
                  },
                  {
                    "sampleYVal": 172,
                    "sampleYDrawVal": 2732
                  },
                  {
                    "sampleYVal": 161,
                    "sampleYDrawVal": 2446
                  },
                  {
                    "sampleYVal": 156,
                    "sampleYDrawVal": 2280
                  },
                  {
                    "sampleYVal": 156,
                    "sampleYDrawVal": 2350
                  },
                  {
                    "sampleYVal": 130,
                    "sampleYDrawVal": 1734
                  },
                  {
                    "sampleYVal": 104,
                    "sampleYDrawVal": 1270
                  },
                  {
                    "sampleYVal": 133,
                    "sampleYDrawVal": 1867
                  },
                  {
                    "sampleYVal": 156,
                    "sampleYDrawVal": 2315
                  },
                  {
                    "sampleYVal": 185,
                    "sampleYDrawVal": 3239
                  },
                  {
                    "sampleYVal": 188,
                    "sampleYDrawVal": 3343
                  },
                  {
                    "sampleYVal": 173,
                    "sampleYDrawVal": 2802
                  },
                  {
                    "sampleYVal": 157,
                    "sampleYDrawVal": 2392
                  },
                  {
                    "sampleYVal": 140,
                    "sampleYDrawVal": 1785
                  },
                  {
                    "sampleYVal": 55,
                    "sampleYDrawVal": 202
                  },
                  {
                    "sampleYVal": 27,
                    "sampleYDrawVal": 37
                  }
                ],
                "recordOffset": 8255,
                "seqUrl": "1527724890869_20633_709",
                "seqDescription": {
                  "analysis": {
                    "analysisType": "1sec"
                  },
                  "completeCount": 4,
                  "currentSeqPos": 0,
                  "pbId": "908",
                  "text": "מִסְפָּר",
                  "type": "word",
                  "word": {
                    "id": 908,
                    "language": "he",
                    "cleanInput": "מִסְפָּר",
                    "type": "word",
                    "data": "[{\"text\":\"\\u05de\\u05b4\\u05e1\\u05b0\",\"sylType\":\"cvc\",\"vowel\":\"I\",\"isContinous\":true},{\"text\":\"\\u05e4\\u05bc\\u05b8\\u05e8\",\"sylType\":\"cvc\",\"vowel\":\"A\",\"isContinous\":true,\"isPlosive\":true}]",
                    "isNonesense": 0,
                    "audios": ""
                  }
                },
                "mistakesObjects": {},
                "hiddenMistakesObjects": {}
              },
              {
                "fieldType": "seq",
                "timerPos": 1233,
                "calibrationEventId": "6581",
                "rate": 100,
                "deltaTimeMsFromPrevSeq": "2509.00",
                "yvalues": [
                  "127",
                  "173",
                  "193",
                  "198",
                  "188",
                  "175",
                  "167",
                  "159",
                  "163",
                  "186",
                  "203",
                  "210",
                  "201",
                  "182",
                  "165",
                  "159",
                  "158",
                  "169",
                  "187",
                  "198",
                  "185",
                  "150",
                  "113",
                  "66",
                  "0"
                ],
                "yDrawvalues": "2450,3506,3849,3808,3127,2589,2428,2163,2472,3672,4280,4421,3721,2769,2228,2244,2322,2838,3638,3947,3007,1588,820,158,0",
                "mistakes": [],
                "hiddenMistakes": [],
                "softVals": [
                  "1",
                  "1",
                  "0",
                  "0",
                  "0",
                  "1",
                  "1",
                  "1",
                  "1",
                  "0",
                  "0",
                  "0",
                  "0",
                  "0",
                  "1",
                  "1",
                  "1",
                  "1",
                  "0",
                  "0",
                  "0",
                  "1",
                  "1",
                  "1",
                  "1"
                ],
                "expectedSylsPerSecond": "0.00",
                "actualSylsPerSecond": "0.92",
                "timeLength": 3125,
                "features": [
                  {
                    "sampleYVal": 181,
                    "sampleYDrawVal": 2967
                  },
                  {
                    "sampleYVal": 197,
                    "sampleYDrawVal": 3680
                  },
                  {
                    "sampleYVal": 204,
                    "sampleYDrawVal": 3966
                  },
                  {
                    "sampleYVal": 196,
                    "sampleYDrawVal": 3557
                  },
                  {
                    "sampleYVal": 178,
                    "sampleYDrawVal": 2867
                  },
                  {
                    "sampleYVal": 166,
                    "sampleYDrawVal": 2467
                  },
                  {
                    "sampleYVal": 171,
                    "sampleYDrawVal": 2624
                  },
                  {
                    "sampleYVal": 148,
                    "sampleYDrawVal": 1900
                  },
                  {
                    "sampleYVal": 179,
                    "sampleYDrawVal": 2967
                  },
                  {
                    "sampleYVal": 207,
                    "sampleYDrawVal": 4120
                  },
                  {
                    "sampleYVal": 216,
                    "sampleYDrawVal": 4508
                  },
                  {
                    "sampleYVal": 212,
                    "sampleYDrawVal": 4333
                  },
                  {
                    "sampleYVal": 190,
                    "sampleYDrawVal": 3365
                  },
                  {
                    "sampleYVal": 163,
                    "sampleYDrawVal": 2356
                  },
                  {
                    "sampleYVal": 152,
                    "sampleYDrawVal": 2103
                  },
                  {
                    "sampleYVal": 163,
                    "sampleYDrawVal": 2390
                  },
                  {
                    "sampleYVal": 164,
                    "sampleYDrawVal": 2547
                  },
                  {
                    "sampleYVal": 182,
                    "sampleYDrawVal": 3129
                  },
                  {
                    "sampleYVal": 201,
                    "sampleYDrawVal": 3863
                  },
                  {
                    "sampleYVal": 206,
                    "sampleYDrawVal": 4075
                  },
                  {
                    "sampleYVal": 160,
                    "sampleYDrawVal": 2284
                  },
                  {
                    "sampleYVal": 119,
                    "sampleYDrawVal": 1180
                  },
                  {
                    "sampleYVal": 59,
                    "sampleYDrawVal": 276
                  },
                  {
                    "sampleYVal": 37,
                    "sampleYDrawVal": 101
                  }
                ],
                "recordOffset": 13161,
                "seqUrl": "1527724890869_20633_709",
                "seqDescription": {
                  "analysis": {
                    "analysisType": "1sec"
                  },
                  "completeCount": 0,
                  "currentSeqPos": 0,
                  "pbId": "1580",
                  "text": "בָּאָ",
                  "type": "word",
                  "word": {
                    "id": 1580,
                    "language": "he",
                    "cleanInput": "בָּאָרֶץ",
                    "type": "word",
                    "data": "[{\"text\":\"\\u05d1\\u05bc\\u05b8\",\"sylType\":\"cv\",\"vowel\":\"A\",\"isPlosive\":true},{\"text\":\"\\u05d0\\u05b8\",\"sylType\":\"v\",\"vowel\":\"A\"},{\"text\":\"\\u05e8\\u05b6\\u05e5\",\"sylType\":\"cvc\",\"vowel\":\"E\",\"isContinous\":true,\"isPlosive\":true}]",
                    "isNonesense": 0,
                    "audios": "{\"intoQuestionOneSec\":{\"desc\":\"Intonation question 1 sec\",\"filePath\":\"intoQuestionOneSec\",\"isIntonation\":true,\"analysisType\":{\"type\":\"1sec\"}},\"intoQuestionTwoSec\":{\"desc\":\"Intonation question 2 sec\",\"filePath\":\"intoQuestionTwoSec\",\"isIntonation\":true,\"analysisType\":{\"type\":\"2sec\"}},\"intoQuestionManaged\":{\"desc\":\"Intonation question managed\",\"filePath\":\"intoQuestionManaged\",\"isIntonation\":true,\"analysisType\":{\"type\":\"managed\"}},\"intoWonderOneSec\":{\"desc\":\"Intonation wounder 1 sec\",\"filePath\":\"intoWonderOneSec\",\"isIntonation\":true,\"analysisType\":{\"type\":\"1sec\"}},\"intoWonderTwoSec\":{\"desc\":\"Intonation wounder 2 sec\",\"filePath\":\"intoWonderTwoSec\",\"isIntonation\":true,\"analysisType\":{\"type\":\"2sec\"}},\"intoWonderManaged\":{\"desc\":\"Intonation wounder managed\",\"filePath\":\"intoWonderManaged\",\"isIntonation\":true,\"analysisType\":{\"type\":\"managed\"}},\"intoStatementsOneSec\":{\"desc\":\"Intonation statements 1 sec\",\"filePath\":\"intoStatementsOneSec\",\"isIntonation\":true,\"analysisType\":{\"type\":\"1sec\"}},\"intoStatementsTwoSec\":{\"desc\":\"Intonation statements 2 sec\",\"filePath\":\"intoStatementsTwoSec\",\"isIntonation\":true,\"analysisType\":{\"type\":\"2sec\"}},\"intoStatementsManaged\":{\"desc\":\"Intonation statements managed\",\"filePath\":\"intoStatementsManaged\",\"isIntonation\":true,\"analysisType\":{\"type\":\"managed\"}}}"
                  }
                },
                "mistakesObjects": {},
                "hiddenMistakesObjects": {}
              },
              {
                "fieldType": "seq",
                "timerPos": 1239,
                "calibrationEventId": "6581",
                "rate": 80,
                "deltaTimeMsFromPrevSeq": "2749.00",
                "yvalues": [
                  "147",
                  "174",
                  "190",
                  "193",
                  "179",
                  "159",
                  "144",
                  "156",
                  "185",
                  "200",
                  "198",
                  "190",
                  "171",
                  "151",
                  "138",
                  "131",
                  "149",
                  "169",
                  "182",
                  "181",
                  "162",
                  "138",
                  "99",
                  "49",
                  "0"
                ],
                "yDrawvalues": "2869,3190,3636,3559,2701,1990,1640,2377,3709,4124,3698,3240,2374,1717,1428,1327,2281,2958,3370,3059,2114,1419,561,42,0",
                "mistakes": [
                  {
                    "sampleOffset": 0,
                    "mistakeId": 1
                  }
                ],
                "hiddenMistakes": [
                  {
                    "sampleOffset": 3,
                    "mistakeId": 3
                  },
                  {
                    "sampleOffset": 9,
                    "mistakeId": 3
                  },
                  {
                    "sampleOffset": 18,
                    "mistakeId": 3
                  },
                  {
                    "sampleOffset": 24,
                    "mistakeId": 7
                  }
                ],
                "softVals": [
                  "1",
                  "1",
                  "0",
                  "0",
                  "0",
                  "1",
                  "1",
                  "1",
                  "0",
                  "0",
                  "0",
                  "0",
                  "1",
                  "1",
                  "1",
                  "1",
                  "1",
                  "1",
                  "0",
                  "0",
                  "1",
                  "1",
                  "1",
                  "1",
                  "1"
                ],
                "expectedSylsPerSecond": "0.00",
                "actualSylsPerSecond": "0.92",
                "timeLength": 3125,
                "features": [
                  {
                    "sampleYVal": 185,
                    "sampleYDrawVal": 3151
                  },
                  {
                    "sampleYVal": 197,
                    "sampleYDrawVal": 3617
                  },
                  {
                    "sampleYVal": 195,
                    "sampleYDrawVal": 3529
                  },
                  {
                    "sampleYVal": 191,
                    "sampleYDrawVal": 3397
                  },
                  {
                    "sampleYVal": 158,
                    "sampleYDrawVal": 2184
                  },
                  {
                    "sampleYVal": 148,
                    "sampleYDrawVal": 1902
                  },
                  {
                    "sampleYVal": 138,
                    "sampleYDrawVal": 1661
                  },
                  {
                    "sampleYVal": 175,
                    "sampleYDrawVal": 2853
                  },
                  {
                    "sampleYVal": 205,
                    "sampleYDrawVal": 4009
                  },
                  {
                    "sampleYVal": 207,
                    "sampleYDrawVal": 4126
                  },
                  {
                    "sampleYVal": 192,
                    "sampleYDrawVal": 3439
                  },
                  {
                    "sampleYVal": 175,
                    "sampleYDrawVal": 2789
                  },
                  {
                    "sampleYVal": 161,
                    "sampleYDrawVal": 2261
                  },
                  {
                    "sampleYVal": 128,
                    "sampleYDrawVal": 1366
                  },
                  {
                    "sampleYVal": 138,
                    "sampleYDrawVal": 1545
                  },
                  {
                    "sampleYVal": 121,
                    "sampleYDrawVal": 1190
                  },
                  {
                    "sampleYVal": 169,
                    "sampleYDrawVal": 2666
                  },
                  {
                    "sampleYVal": 185,
                    "sampleYDrawVal": 3176
                  },
                  {
                    "sampleYVal": 188,
                    "sampleYDrawVal": 3361
                  },
                  {
                    "sampleYVal": 177,
                    "sampleYDrawVal": 2865
                  },
                  {
                    "sampleYVal": 147,
                    "sampleYDrawVal": 1877
                  },
                  {
                    "sampleYVal": 110,
                    "sampleYDrawVal": 1050
                  },
                  {
                    "sampleYVal": 42,
                    "sampleYDrawVal": 119
                  },
                  {
                    "sampleYVal": 22,
                    "sampleYDrawVal": 22
                  }
                ],
                "recordOffset": 19181,
                "seqUrl": "1527724890869_20633_709",
                "seqDescription": {
                  "analysis": {
                    "analysisType": "1sec"
                  },
                  "completeCount": 1,
                  "currentSeqPos": 0,
                  "pbId": "1580",
                  "text": "בָּאָ",
                  "type": "word",
                  "word": {
                    "id": 1580,
                    "language": "he",
                    "cleanInput": "בָּאָרֶץ",
                    "type": "word",
                    "data": "[{\"text\":\"\\u05d1\\u05bc\\u05b8\",\"sylType\":\"cv\",\"vowel\":\"A\",\"isPlosive\":true},{\"text\":\"\\u05d0\\u05b8\",\"sylType\":\"v\",\"vowel\":\"A\"},{\"text\":\"\\u05e8\\u05b6\\u05e5\",\"sylType\":\"cvc\",\"vowel\":\"E\",\"isContinous\":true,\"isPlosive\":true}]",
                    "isNonesense": 0,
                    "audios": "{\"intoQuestionOneSec\":{\"desc\":\"Intonation question 1 sec\",\"filePath\":\"intoQuestionOneSec\",\"isIntonation\":true,\"analysisType\":{\"type\":\"1sec\"}},\"intoQuestionTwoSec\":{\"desc\":\"Intonation question 2 sec\",\"filePath\":\"intoQuestionTwoSec\",\"isIntonation\":true,\"analysisType\":{\"type\":\"2sec\"}},\"intoQuestionManaged\":{\"desc\":\"Intonation question managed\",\"filePath\":\"intoQuestionManaged\",\"isIntonation\":true,\"analysisType\":{\"type\":\"managed\"}},\"intoWonderOneSec\":{\"desc\":\"Intonation wounder 1 sec\",\"filePath\":\"intoWonderOneSec\",\"isIntonation\":true,\"analysisType\":{\"type\":\"1sec\"}},\"intoWonderTwoSec\":{\"desc\":\"Intonation wounder 2 sec\",\"filePath\":\"intoWonderTwoSec\",\"isIntonation\":true,\"analysisType\":{\"type\":\"2sec\"}},\"intoWonderManaged\":{\"desc\":\"Intonation wounder managed\",\"filePath\":\"intoWonderManaged\",\"isIntonation\":true,\"analysisType\":{\"type\":\"managed\"}},\"intoStatementsOneSec\":{\"desc\":\"Intonation statements 1 sec\",\"filePath\":\"intoStatementsOneSec\",\"isIntonation\":true,\"analysisType\":{\"type\":\"1sec\"}},\"intoStatementsTwoSec\":{\"desc\":\"Intonation statements 2 sec\",\"filePath\":\"intoStatementsTwoSec\",\"isIntonation\":true,\"analysisType\":{\"type\":\"2sec\"}},\"intoStatementsManaged\":{\"desc\":\"Intonation statements managed\",\"filePath\":\"intoStatementsManaged\",\"isIntonation\":true,\"analysisType\":{\"type\":\"managed\"}}}"
                  }
                },
                "mistakesObjects": {
                  "gentleOnset": {
                    "titlei18Key": "MISTAKES.GENTLE_ONSET"
                  }
                },
                "hiddenMistakesObjects": {
                  "softPeak": {
                    "titlei18Key": "MISTAKES.SOFT_PEAK"
                  },
                  "tooLong": {
                    "titlei18Key": "MISTAKES.TOO_LONG"
                  }
                }
              },
              {
                "fieldType": "seq",
                "timerPos": 1245,
                "calibrationEventId": "6581",
                "rate": 100,
                "deltaTimeMsFromPrevSeq": "2880.00",
                "yvalues": [
                  "114",
                  "150",
                  "176",
                  "186",
                  "185",
                  "170",
                  "145",
                  "119",
                  "115",
                  "155",
                  "181",
                  "189",
                  "186",
                  "183",
                  "177",
                  "161",
                  "134",
                  "122",
                  "154",
                  "170",
                  "179",
                  "179",
                  "158",
                  "128",
                  "59",
                  "0"
                ],
                "yDrawvalues": "1892,2543,3302,3435,3156,2370,1579,1130,1298,2806,3537,3521,3141,3010,2752,2140,1324,1241,2702,2982,3151,3013,2038,1280,64,0",
                "mistakes": [],
                "hiddenMistakes": [],
                "softVals": [
                  "1",
                  "1",
                  "1",
                  "0",
                  "0",
                  "1",
                  "1",
                  "1",
                  "1",
                  "1",
                  "0",
                  "0",
                  "0",
                  "0",
                  "0",
                  "1",
                  "1",
                  "1",
                  "1",
                  "1",
                  "0",
                  "0",
                  "1",
                  "1",
                  "1",
                  "1"
                ],
                "expectedSylsPerSecond": "0.00",
                "actualSylsPerSecond": "0.87",
                "timeLength": 3250,
                "features": [
                  {
                    "sampleYVal": 172,
                    "sampleYDrawVal": 2671
                  },
                  {
                    "sampleYVal": 180,
                    "sampleYDrawVal": 3020
                  },
                  {
                    "sampleYVal": 193,
                    "sampleYDrawVal": 3520
                  },
                  {
                    "sampleYVal": 190,
                    "sampleYDrawVal": 3392
                  },
                  {
                    "sampleYVal": 183,
                    "sampleYDrawVal": 3089
                  },
                  {
                    "sampleYVal": 154,
                    "sampleYDrawVal": 2039
                  },
                  {
                    "sampleYVal": 127,
                    "sampleYDrawVal": 1470
                  },
                  {
                    "sampleYVal": 104,
                    "sampleYDrawVal": 1038
                  },
                  {
                    "sampleYVal": 149,
                    "sampleYDrawVal": 2046
                  },
                  {
                    "sampleYVal": 187,
                    "sampleYDrawVal": 3295
                  },
                  {
                    "sampleYVal": 191,
                    "sampleYDrawVal": 3467
                  },
                  {
                    "sampleYVal": 187,
                    "sampleYDrawVal": 3280
                  },
                  {
                    "sampleYVal": 181,
                    "sampleYDrawVal": 2981
                  },
                  {
                    "sampleYVal": 180,
                    "sampleYDrawVal": 2939
                  },
                  {
                    "sampleYVal": 173,
                    "sampleYDrawVal": 2679
                  },
                  {
                    "sampleYVal": 125,
                    "sampleYDrawVal": 1325
                  },
                  {
                    "sampleYVal": 120,
                    "sampleYDrawVal": 1232
                  },
                  {
                    "sampleYVal": 128,
                    "sampleYDrawVal": 1584
                  },
                  {
                    "sampleYVal": 181,
                    "sampleYDrawVal": 3065
                  },
                  {
                    "sampleYVal": 173,
                    "sampleYDrawVal": 2806
                  },
                  {
                    "sampleYVal": 185,
                    "sampleYDrawVal": 3184
                  },
                  {
                    "sampleYVal": 173,
                    "sampleYDrawVal": 2833
                  },
                  {
                    "sampleYVal": 145,
                    "sampleYDrawVal": 1874
                  },
                  {
                    "sampleYVal": 79,
                    "sampleYDrawVal": 497
                  },
                  {
                    "sampleYVal": 29,
                    "sampleYDrawVal": 43
                  }
                ],
                "recordOffset": 25386,
                "seqUrl": "1527724890869_20633_709",
                "seqDescription": {
                  "analysis": {
                    "analysisType": "1sec"
                  },
                  "completeCount": 2,
                  "currentSeqPos": 0,
                  "pbId": "1580",
                  "text": "בָּאָ",
                  "type": "word",
                  "word": {
                    "id": 1580,
                    "language": "he",
                    "cleanInput": "בָּאָרֶץ",
                    "type": "word",
                    "data": "[{\"text\":\"\\u05d1\\u05bc\\u05b8\",\"sylType\":\"cv\",\"vowel\":\"A\",\"isPlosive\":true},{\"text\":\"\\u05d0\\u05b8\",\"sylType\":\"v\",\"vowel\":\"A\"},{\"text\":\"\\u05e8\\u05b6\\u05e5\",\"sylType\":\"cvc\",\"vowel\":\"E\",\"isContinous\":true,\"isPlosive\":true}]",
                    "isNonesense": 0,
                    "audios": "{\"intoQuestionOneSec\":{\"desc\":\"Intonation question 1 sec\",\"filePath\":\"intoQuestionOneSec\",\"isIntonation\":true,\"analysisType\":{\"type\":\"1sec\"}},\"intoQuestionTwoSec\":{\"desc\":\"Intonation question 2 sec\",\"filePath\":\"intoQuestionTwoSec\",\"isIntonation\":true,\"analysisType\":{\"type\":\"2sec\"}},\"intoQuestionManaged\":{\"desc\":\"Intonation question managed\",\"filePath\":\"intoQuestionManaged\",\"isIntonation\":true,\"analysisType\":{\"type\":\"managed\"}},\"intoWonderOneSec\":{\"desc\":\"Intonation wounder 1 sec\",\"filePath\":\"intoWonderOneSec\",\"isIntonation\":true,\"analysisType\":{\"type\":\"1sec\"}},\"intoWonderTwoSec\":{\"desc\":\"Intonation wounder 2 sec\",\"filePath\":\"intoWonderTwoSec\",\"isIntonation\":true,\"analysisType\":{\"type\":\"2sec\"}},\"intoWonderManaged\":{\"desc\":\"Intonation wounder managed\",\"filePath\":\"intoWonderManaged\",\"isIntonation\":true,\"analysisType\":{\"type\":\"managed\"}},\"intoStatementsOneSec\":{\"desc\":\"Intonation statements 1 sec\",\"filePath\":\"intoStatementsOneSec\",\"isIntonation\":true,\"analysisType\":{\"type\":\"1sec\"}},\"intoStatementsTwoSec\":{\"desc\":\"Intonation statements 2 sec\",\"filePath\":\"intoStatementsTwoSec\",\"isIntonation\":true,\"analysisType\":{\"type\":\"2sec\"}},\"intoStatementsManaged\":{\"desc\":\"Intonation statements managed\",\"filePath\":\"intoStatementsManaged\",\"isIntonation\":true,\"analysisType\":{\"type\":\"managed\"}}}"
                  }
                },
                "mistakesObjects": {},
                "hiddenMistakesObjects": {}
              },
              {
                "fieldType": "seq",
                "timerPos": 1251,
                "calibrationEventId": "6581",
                "rate": 80,
                "deltaTimeMsFromPrevSeq": "2769.00",
                "yvalues": [
                  "141",
                  "173",
                  "183",
                  "183",
                  "175",
                  "159",
                  "133",
                  "118",
                  "137",
                  "174",
                  "202",
                  "200",
                  "193",
                  "181",
                  "153",
                  "134",
                  "119",
                  "145",
                  "164",
                  "176",
                  "178",
                  "168",
                  "137",
                  "106",
                  "58",
                  "0"
                ],
                "yDrawvalues": "2879,3323,3356,3160,2663,2044,1316,1216,2088,3379,4451,3788,3497,2911,1747,1393,1250,2302,2762,3080,3014,2488,1373,745,74,0",
                "mistakes": [
                  {
                    "sampleOffset": 0,
                    "mistakeId": 1
                  }
                ],
                "hiddenMistakes": [
                  {
                    "sampleOffset": 10,
                    "mistakeId": 3
                  },
                  {
                    "sampleOffset": 20,
                    "mistakeId": 3
                  },
                  {
                    "sampleOffset": 24,
                    "mistakeId": 26
                  },
                  {
                    "sampleOffset": 25,
                    "mistakeId": 7
                  }
                ],
                "softVals": [
                  "1",
                  "1",
                  "0",
                  "0",
                  "1",
                  "1",
                  "1",
                  "1",
                  "1",
                  "1",
                  "0",
                  "0",
                  "0",
                  "0",
                  "1",
                  "1",
                  "1",
                  "1",
                  "1",
                  "1",
                  "0",
                  "1",
                  "1",
                  "1",
                  "1",
                  "1"
                ],
                "expectedSylsPerSecond": "0.00",
                "actualSylsPerSecond": "0.87",
                "timeLength": 3250,
                "features": [
                  {
                    "sampleYVal": 189,
                    "sampleYDrawVal": 3334
                  },
                  {
                    "sampleYVal": 190,
                    "sampleYDrawVal": 3445
                  },
                  {
                    "sampleYVal": 189,
                    "sampleYDrawVal": 3371
                  },
                  {
                    "sampleYVal": 182,
                    "sampleYDrawVal": 3058
                  },
                  {
                    "sampleYVal": 165,
                    "sampleYDrawVal": 2379
                  },
                  {
                    "sampleYVal": 145,
                    "sampleYDrawVal": 1860
                  },
                  {
                    "sampleYVal": 105,
                    "sampleYDrawVal": 1010
                  },
                  {
                    "sampleYVal": 121,
                    "sampleYDrawVal": 1445
                  },
                  {
                    "sampleYVal": 171,
                    "sampleYDrawVal": 2736
                  },
                  {
                    "sampleYVal": 205,
                    "sampleYDrawVal": 3993
                  },
                  {
                    "sampleYVal": 203,
                    "sampleYDrawVal": 3970
                  },
                  {
                    "sampleYVal": 200,
                    "sampleYDrawVal": 3822
                  },
                  {
                    "sampleYVal": 186,
                    "sampleYDrawVal": 3300
                  },
                  {
                    "sampleYVal": 169,
                    "sampleYDrawVal": 2542
                  },
                  {
                    "sampleYVal": 122,
                    "sampleYDrawVal": 1234
                  },
                  {
                    "sampleYVal": 112,
                    "sampleYDrawVal": 1085
                  },
                  {
                    "sampleYVal": 146,
                    "sampleYDrawVal": 2095
                  },
                  {
                    "sampleYVal": 165,
                    "sampleYDrawVal": 2486
                  },
                  {
                    "sampleYVal": 176,
                    "sampleYDrawVal": 2911
                  },
                  {
                    "sampleYVal": 178,
                    "sampleYDrawVal": 2938
                  },
                  {
                    "sampleYVal": 177,
                    "sampleYDrawVal": 2937
                  },
                  {
                    "sampleYVal": 146,
                    "sampleYDrawVal": 1851
                  },
                  {
                    "sampleYVal": 114,
                    "sampleYDrawVal": 1215
                  },
                  {
                    "sampleYVal": 66,
                    "sampleYDrawVal": 280
                  },
                  {
                    "sampleYVal": 32,
                    "sampleYDrawVal": 48
                  }
                ],
                "recordOffset": 1618,
                "seqUrl": "1527724920869_20633_709",
                "seqDescription": {
                  "analysis": {
                    "analysisType": "1sec"
                  },
                  "completeCount": 3,
                  "currentSeqPos": 0,
                  "pbId": "1580",
                  "text": "בָּאָ",
                  "type": "word",
                  "word": {
                    "id": 1580,
                    "language": "he",
                    "cleanInput": "בָּאָרֶץ",
                    "type": "word",
                    "data": "[{\"text\":\"\\u05d1\\u05bc\\u05b8\",\"sylType\":\"cv\",\"vowel\":\"A\",\"isPlosive\":true},{\"text\":\"\\u05d0\\u05b8\",\"sylType\":\"v\",\"vowel\":\"A\"},{\"text\":\"\\u05e8\\u05b6\\u05e5\",\"sylType\":\"cvc\",\"vowel\":\"E\",\"isContinous\":true,\"isPlosive\":true}]",
                    "isNonesense": 0,
                    "audios": "{\"intoQuestionOneSec\":{\"desc\":\"Intonation question 1 sec\",\"filePath\":\"intoQuestionOneSec\",\"isIntonation\":true,\"analysisType\":{\"type\":\"1sec\"}},\"intoQuestionTwoSec\":{\"desc\":\"Intonation question 2 sec\",\"filePath\":\"intoQuestionTwoSec\",\"isIntonation\":true,\"analysisType\":{\"type\":\"2sec\"}},\"intoQuestionManaged\":{\"desc\":\"Intonation question managed\",\"filePath\":\"intoQuestionManaged\",\"isIntonation\":true,\"analysisType\":{\"type\":\"managed\"}},\"intoWonderOneSec\":{\"desc\":\"Intonation wounder 1 sec\",\"filePath\":\"intoWonderOneSec\",\"isIntonation\":true,\"analysisType\":{\"type\":\"1sec\"}},\"intoWonderTwoSec\":{\"desc\":\"Intonation wounder 2 sec\",\"filePath\":\"intoWonderTwoSec\",\"isIntonation\":true,\"analysisType\":{\"type\":\"2sec\"}},\"intoWonderManaged\":{\"desc\":\"Intonation wounder managed\",\"filePath\":\"intoWonderManaged\",\"isIntonation\":true,\"analysisType\":{\"type\":\"managed\"}},\"intoStatementsOneSec\":{\"desc\":\"Intonation statements 1 sec\",\"filePath\":\"intoStatementsOneSec\",\"isIntonation\":true,\"analysisType\":{\"type\":\"1sec\"}},\"intoStatementsTwoSec\":{\"desc\":\"Intonation statements 2 sec\",\"filePath\":\"intoStatementsTwoSec\",\"isIntonation\":true,\"analysisType\":{\"type\":\"2sec\"}},\"intoStatementsManaged\":{\"desc\":\"Intonation statements managed\",\"filePath\":\"intoStatementsManaged\",\"isIntonation\":true,\"analysisType\":{\"type\":\"managed\"}}}"
                  }
                },
                "mistakesObjects": {
                  "gentleOnset": {
                    "titlei18Key": "MISTAKES.GENTLE_ONSET"
                  }
                },
                "hiddenMistakesObjects": {
                  "softPeak": {
                    "titlei18Key": "MISTAKES.SOFT_PEAK"
                  },
                  "linkativity": {
                    "titlei18Key": "MISTAKES.LINKATIVITY"
                  },
                  "tooLong": {
                    "titlei18Key": "MISTAKES.TOO_LONG"
                  }
                }
              },
              {
                "fieldType": "seq",
                "timerPos": 1257,
                "calibrationEventId": "6581",
                "rate": 80,
                "deltaTimeMsFromPrevSeq": "3007.00",
                "yvalues": [
                  "147",
                  "169",
                  "181",
                  "184",
                  "170",
                  "131",
                  "115",
                  "118",
                  "152",
                  "178",
                  "190",
                  "195",
                  "180",
                  "155",
                  "135",
                  "123",
                  "119",
                  "150",
                  "168",
                  "181",
                  "176",
                  "144",
                  "114",
                  "50",
                  "0"
                ],
                "yDrawvalues": "2546,2923,3249,3198,2408,1214,1208,1391,2627,3382,3605,3727,2809,1792,1347,1176,1363,2492,2884,3307,2841,1577,969,55,0",
                "mistakes": [
                  {
                    "sampleOffset": 0,
                    "mistakeId": 1
                  }
                ],
                "hiddenMistakes": [
                  {
                    "sampleOffset": 3,
                    "mistakeId": 3
                  },
                  {
                    "sampleOffset": 11,
                    "mistakeId": 3
                  },
                  {
                    "sampleOffset": 19,
                    "mistakeId": 3
                  },
                  {
                    "sampleOffset": 24,
                    "mistakeId": 7
                  }
                ],
                "softVals": [
                  "1",
                  "1",
                  "0",
                  "0",
                  "1",
                  "1",
                  "1",
                  "1",
                  "1",
                  "0",
                  "0",
                  "0",
                  "0",
                  "1",
                  "1",
                  "1",
                  "1",
                  "1",
                  "1",
                  "0",
                  "1",
                  "1",
                  "1",
                  "1",
                  "1"
                ],
                "expectedSylsPerSecond": "0.00",
                "actualSylsPerSecond": "0.92",
                "timeLength": 3125,
                "features": [
                  {
                    "sampleYVal": 170,
                    "sampleYDrawVal": 2597
                  },
                  {
                    "sampleYVal": 178,
                    "sampleYDrawVal": 2852
                  },
                  {
                    "sampleYVal": 186,
                    "sampleYDrawVal": 3211
                  },
                  {
                    "sampleYVal": 178,
                    "sampleYDrawVal": 2864
                  },
                  {
                    "sampleYVal": 152,
                    "sampleYDrawVal": 1973
                  },
                  {
                    "sampleYVal": 106,
                    "sampleYDrawVal": 1055
                  },
                  {
                    "sampleYVal": 117,
                    "sampleYDrawVal": 1272
                  },
                  {
                    "sampleYVal": 142,
                    "sampleYDrawVal": 1819
                  },
                  {
                    "sampleYVal": 184,
                    "sampleYDrawVal": 3170
                  },
                  {
                    "sampleYVal": 200,
                    "sampleYDrawVal": 3766
                  },
                  {
                    "sampleYVal": 194,
                    "sampleYDrawVal": 3539
                  },
                  {
                    "sampleYVal": 203,
                    "sampleYDrawVal": 3923
                  },
                  {
                    "sampleYVal": 150,
                    "sampleYDrawVal": 1915
                  },
                  {
                    "sampleYVal": 143,
                    "sampleYDrawVal": 1715
                  },
                  {
                    "sampleYVal": 137,
                    "sampleYDrawVal": 1581
                  },
                  {
                    "sampleYVal": 99,
                    "sampleYDrawVal": 827
                  },
                  {
                    "sampleYVal": 156,
                    "sampleYDrawVal": 2270
                  },
                  {
                    "sampleYVal": 161,
                    "sampleYDrawVal": 2380
                  },
                  {
                    "sampleYVal": 177,
                    "sampleYDrawVal": 2947
                  },
                  {
                    "sampleYVal": 185,
                    "sampleYDrawVal": 3237
                  },
                  {
                    "sampleYVal": 165,
                    "sampleYDrawVal": 2503
                  },
                  {
                    "sampleYVal": 125,
                    "sampleYDrawVal": 1492
                  },
                  {
                    "sampleYVal": 56,
                    "sampleYDrawVal": 267
                  },
                  {
                    "sampleYVal": 13,
                    "sampleYDrawVal": 11
                  }
                ],
                "recordOffset": 8021,
                "seqUrl": "1527724920869_20633_709",
                "seqDescription": {
                  "analysis": {
                    "analysisType": "1sec"
                  },
                  "completeCount": 4,
                  "currentSeqPos": 0,
                  "pbId": "1580",
                  "text": "בָּאָ",
                  "type": "word",
                  "word": {
                    "id": 1580,
                    "language": "he",
                    "cleanInput": "בָּאָרֶץ",
                    "type": "word",
                    "data": "[{\"text\":\"\\u05d1\\u05bc\\u05b8\",\"sylType\":\"cv\",\"vowel\":\"A\",\"isPlosive\":true},{\"text\":\"\\u05d0\\u05b8\",\"sylType\":\"v\",\"vowel\":\"A\"},{\"text\":\"\\u05e8\\u05b6\\u05e5\",\"sylType\":\"cvc\",\"vowel\":\"E\",\"isContinous\":true,\"isPlosive\":true}]",
                    "isNonesense": 0,
                    "audios": "{\"intoQuestionOneSec\":{\"desc\":\"Intonation question 1 sec\",\"filePath\":\"intoQuestionOneSec\",\"isIntonation\":true,\"analysisType\":{\"type\":\"1sec\"}},\"intoQuestionTwoSec\":{\"desc\":\"Intonation question 2 sec\",\"filePath\":\"intoQuestionTwoSec\",\"isIntonation\":true,\"analysisType\":{\"type\":\"2sec\"}},\"intoQuestionManaged\":{\"desc\":\"Intonation question managed\",\"filePath\":\"intoQuestionManaged\",\"isIntonation\":true,\"analysisType\":{\"type\":\"managed\"}},\"intoWonderOneSec\":{\"desc\":\"Intonation wounder 1 sec\",\"filePath\":\"intoWonderOneSec\",\"isIntonation\":true,\"analysisType\":{\"type\":\"1sec\"}},\"intoWonderTwoSec\":{\"desc\":\"Intonation wounder 2 sec\",\"filePath\":\"intoWonderTwoSec\",\"isIntonation\":true,\"analysisType\":{\"type\":\"2sec\"}},\"intoWonderManaged\":{\"desc\":\"Intonation wounder managed\",\"filePath\":\"intoWonderManaged\",\"isIntonation\":true,\"analysisType\":{\"type\":\"managed\"}},\"intoStatementsOneSec\":{\"desc\":\"Intonation statements 1 sec\",\"filePath\":\"intoStatementsOneSec\",\"isIntonation\":true,\"analysisType\":{\"type\":\"1sec\"}},\"intoStatementsTwoSec\":{\"desc\":\"Intonation statements 2 sec\",\"filePath\":\"intoStatementsTwoSec\",\"isIntonation\":true,\"analysisType\":{\"type\":\"2sec\"}},\"intoStatementsManaged\":{\"desc\":\"Intonation statements managed\",\"filePath\":\"intoStatementsManaged\",\"isIntonation\":true,\"analysisType\":{\"type\":\"managed\"}}}"
                  }
                },
                "mistakesObjects": {
                  "gentleOnset": {
                    "titlei18Key": "MISTAKES.GENTLE_ONSET"
                  }
                },
                "hiddenMistakesObjects": {
                  "softPeak": {
                    "titlei18Key": "MISTAKES.SOFT_PEAK"
                  },
                  "tooLong": {
                    "titlei18Key": "MISTAKES.TOO_LONG"
                  }
                }
              },
              {
                "fieldType": "seq",
                "timerPos": 1264,
                "calibrationEventId": "6581",
                "rate": 100,
                "deltaTimeMsFromPrevSeq": "2749.00",
                "yvalues": [
                  "130",
                  "155",
                  "178",
                  "180",
                  "163",
                  "137",
                  "133",
                  "111",
                  "110",
                  "141",
                  "166",
                  "179",
                  "176",
                  "163",
                  "140",
                  "122",
                  "114",
                  "138",
                  "156",
                  "169",
                  "172",
                  "164",
                  "138",
                  "84",
                  "0"
                ],
                "yDrawvalues": "1957,2535,3348,3090,2150,1362,1593,960,1122,2400,3068,3330,2938,2495,1645,1198,1123,2129,2598,2910,2830,2364,1409,361,0",
                "mistakes": [],
                "hiddenMistakes": [],
                "softVals": [
                  "1",
                  "1",
                  "0",
                  "0",
                  "1",
                  "1",
                  "1",
                  "1",
                  "1",
                  "1",
                  "1",
                  "0",
                  "1",
                  "1",
                  "1",
                  "1",
                  "1",
                  "1",
                  "1",
                  "1",
                  "1",
                  "1",
                  "1",
                  "1",
                  "1"
                ],
                "expectedSylsPerSecond": "0.00",
                "actualSylsPerSecond": "0.90",
                "timeLength": 3125,
                "features": [
                  {
                    "sampleYVal": 160,
                    "sampleYDrawVal": 2326
                  },
                  {
                    "sampleYVal": 180,
                    "sampleYDrawVal": 3088
                  },
                  {
                    "sampleYVal": 191,
                    "sampleYDrawVal": 3497
                  },
                  {
                    "sampleYVal": 173,
                    "sampleYDrawVal": 2781
                  },
                  {
                    "sampleYVal": 138,
                    "sampleYDrawVal": 1596
                  },
                  {
                    "sampleYVal": 136,
                    "sampleYDrawVal": 1560
                  },
                  {
                    "sampleYVal": 113,
                    "sampleYDrawVal": 1279
                  },
                  {
                    "sampleYVal": 108,
                    "sampleYDrawVal": 946
                  },
                  {
                    "sampleYVal": 140,
                    "sampleYDrawVal": 1937
                  },
                  {
                    "sampleYVal": 168,
                    "sampleYDrawVal": 2733
                  },
                  {
                    "sampleYVal": 182,
                    "sampleYDrawVal": 3189
                  },
                  {
                    "sampleYVal": 183,
                    "sampleYDrawVal": 3218
                  },
                  {
                    "sampleYVal": 170,
                    "sampleYDrawVal": 2746
                  },
                  {
                    "sampleYVal": 150,
                    "sampleYDrawVal": 2285
                  },
                  {
                    "sampleYVal": 108,
                    "sampleYDrawVal": 1037
                  },
                  {
                    "sampleYVal": 113,
                    "sampleYDrawVal": 1159
                  },
                  {
                    "sampleYVal": 133,
                    "sampleYDrawVal": 1681
                  },
                  {
                    "sampleYVal": 156,
                    "sampleYDrawVal": 2285
                  },
                  {
                    "sampleYVal": 171,
                    "sampleYDrawVal": 2802
                  },
                  {
                    "sampleYVal": 176,
                    "sampleYDrawVal": 2932
                  },
                  {
                    "sampleYVal": 166,
                    "sampleYDrawVal": 2567
                  },
                  {
                    "sampleYVal": 140,
                    "sampleYDrawVal": 1737
                  },
                  {
                    "sampleYVal": 117,
                    "sampleYDrawVal": 1146
                  },
                  {
                    "sampleYVal": 28,
                    "sampleYDrawVal": 71
                  }
                ],
                "recordOffset": 14100,
                "seqUrl": "1527724920869_20633_709",
                "seqDescription": {
                  "analysis": {
                    "analysisType": "1sec"
                  },
                  "completeCount": 0,
                  "currentSeqPos": 0,
                  "pbId": "1710",
                  "text": "הָחוֹ",
                  "type": "word",
                  "word": {
                    "id": 1710,
                    "language": "he",
                    "cleanInput": "הָחוֹרִים",
                    "type": "word",
                    "data": "[{\"text\":\"\\u05d4\\u05b8\",\"sylType\":\"v\",\"vowel\":\"A\"},{\"text\":\"\\u05d7\\u05d5\\u05b9\",\"sylType\":\"cv\",\"vowel\":\"O\",\"isContinous\":true},{\"text\":\"\\u05e8\\u05b4\\u05d9\\u05dd\",\"sylType\":\"cvc\",\"vowel\":\"I\",\"isContinous\":true}]",
                    "isNonesense": 0,
                    "audios": "[]"
                  }
                },
                "mistakesObjects": {},
                "hiddenMistakesObjects": {}
              },
              {
                "fieldType": "seq",
                "timerPos": 1267,
                "calibrationEventId": "6581",
                "rate": 100,
                "deltaTimeMsFromPrevSeq": "2619.00",
                "yvalues": [
                  "109",
                  "132",
                  "171",
                  "195",
                  "202",
                  "178",
                  "147",
                  "110",
                  "95",
                  "0"
                ],
                "yDrawvalues": "1334,1743,3309,4031,3969,2515,1536,704,616,0",
                "mistakes": [],
                "hiddenMistakes": [],
                "softVals": [
                  "1",
                  "1",
                  "1",
                  "0",
                  "0",
                  "0",
                  "1",
                  "1",
                  "1",
                  "1"
                ],
                "expectedSylsPerSecond": "0.00",
                "actualSylsPerSecond": "0.83",
                "timeLength": 1250,
                "features": [
                  {
                    "sampleYVal": 150,
                    "sampleYDrawVal": 1894
                  },
                  {
                    "sampleYVal": 161,
                    "sampleYDrawVal": 2283
                  },
                  {
                    "sampleYVal": 197,
                    "sampleYDrawVal": 3652
                  },
                  {
                    "sampleYVal": 203,
                    "sampleYDrawVal": 3907
                  },
                  {
                    "sampleYVal": 195,
                    "sampleYDrawVal": 3493
                  },
                  {
                    "sampleYVal": 148,
                    "sampleYDrawVal": 1835
                  },
                  {
                    "sampleYVal": 108,
                    "sampleYDrawVal": 899
                  },
                  {
                    "sampleYVal": 99,
                    "sampleYDrawVal": 885
                  },
                  {
                    "sampleYVal": 95,
                    "sampleYDrawVal": 635
                  }
                ],
                "recordOffset": 19805,
                "seqUrl": "1527724920869_20633_709",
                "seqDescription": {
                  "analysis": {
                    "analysisType": "1sec"
                  },
                  "completeCount": 1,
                  "currentSeqPos": 0,
                  "pbId": "1710",
                  "text": "הָחוֹ",
                  "type": "word",
                  "word": {
                    "id": 1710,
                    "language": "he",
                    "cleanInput": "הָחוֹרִים",
                    "type": "word",
                    "data": "[{\"text\":\"\\u05d4\\u05b8\",\"sylType\":\"v\",\"vowel\":\"A\"},{\"text\":\"\\u05d7\\u05d5\\u05b9\",\"sylType\":\"cv\",\"vowel\":\"O\",\"isContinous\":true},{\"text\":\"\\u05e8\\u05b4\\u05d9\\u05dd\",\"sylType\":\"cvc\",\"vowel\":\"I\",\"isContinous\":true}]",
                    "isNonesense": 0,
                    "audios": "[]"
                  }
                },
                "mistakesObjects": {},
                "hiddenMistakesObjects": {}
              },
              {
                "fieldType": "seq",
                "timerPos": 1268,
                "calibrationEventId": "6581",
                "rate": 100,
                "deltaTimeMsFromPrevSeq": "133.00",
                "yvalues": [
                  "134",
                  "161",
                  "176",
                  "163",
                  "131",
                  "121",
                  "98",
                  "0"
                ],
                "yDrawvalues": "2288,2942,3317,2415,1298,1386,826,0",
                "mistakes": [],
                "hiddenMistakes": [],
                "softVals": [
                  "1",
                  "1",
                  "1",
                  "1",
                  "1",
                  "1",
                  "1",
                  "1"
                ],
                "expectedSylsPerSecond": "0.00",
                "actualSylsPerSecond": "1.05",
                "timeLength": 1000,
                "features": [
                  {
                    "sampleYVal": 170,
                    "sampleYDrawVal": 2765
                  },
                  {
                    "sampleYVal": 167,
                    "sampleYDrawVal": 2918
                  },
                  {
                    "sampleYVal": 183,
                    "sampleYDrawVal": 3309
                  },
                  {
                    "sampleYVal": 143,
                    "sampleYDrawVal": 1925
                  },
                  {
                    "sampleYVal": 116,
                    "sampleYDrawVal": 1240
                  },
                  {
                    "sampleYVal": 119,
                    "sampleYDrawVal": 1425
                  },
                  {
                    "sampleYVal": 62,
                    "sampleYDrawVal": 466
                  }
                ],
                "recordOffset": 21144,
                "seqUrl": "1527724920869_20633_709",
                "seqDescription": {
                  "analysis": {
                    "analysisType": "1sec"
                  },
                  "completeCount": 2,
                  "currentSeqPos": 0,
                  "pbId": "1710",
                  "text": "הָחוֹ",
                  "type": "word",
                  "word": {
                    "id": 1710,
                    "language": "he",
                    "cleanInput": "הָחוֹרִים",
                    "type": "word",
                    "data": "[{\"text\":\"\\u05d4\\u05b8\",\"sylType\":\"v\",\"vowel\":\"A\"},{\"text\":\"\\u05d7\\u05d5\\u05b9\",\"sylType\":\"cv\",\"vowel\":\"O\",\"isContinous\":true},{\"text\":\"\\u05e8\\u05b4\\u05d9\\u05dd\",\"sylType\":\"cvc\",\"vowel\":\"I\",\"isContinous\":true}]",
                    "isNonesense": 0,
                    "audios": "[]"
                  }
                },
                "mistakesObjects": {},
                "hiddenMistakesObjects": {}
              },
              {
                "fieldType": "seq",
                "timerPos": 1276,
                "calibrationEventId": "6581",
                "rate": 100,
                "deltaTimeMsFromPrevSeq": "2618.00",
                "yvalues": [
                  "106",
                  "140",
                  "166",
                  "174",
                  "175",
                  "163",
                  "159",
                  "151",
                  "138",
                  "143",
                  "167",
                  "183",
                  "182",
                  "157",
                  "141",
                  "136",
                  "141",
                  "156",
                  "166",
                  "175",
                  "162",
                  "138",
                  "110",
                  "66",
                  "0"
                ],
                "yDrawvalues": "1406,2129,2917,2885,2782,2243,2175,1886,1474,2014,3024,3524,3275,2060,1641,1585,1870,2476,2742,3005,2259,1430,889,262,0",
                "mistakes": [],
                "hiddenMistakes": [],
                "softVals": [
                  "1",
                  "1",
                  "1",
                  "1",
                  "1",
                  "1",
                  "1",
                  "1",
                  "1",
                  "1",
                  "1",
                  "0",
                  "0",
                  "1",
                  "1",
                  "1",
                  "1",
                  "1",
                  "1",
                  "1",
                  "1",
                  "1",
                  "1",
                  "1",
                  "1"
                ],
                "expectedSylsPerSecond": "0.00",
                "actualSylsPerSecond": "0.90",
                "timeLength": 3125,
                "features": [
                  {
                    "sampleYVal": 146,
                    "sampleYDrawVal": 1778
                  },
                  {
                    "sampleYVal": 165,
                    "sampleYDrawVal": 2465
                  },
                  {
                    "sampleYVal": 182,
                    "sampleYDrawVal": 3028
                  },
                  {
                    "sampleYVal": 169,
                    "sampleYDrawVal": 2555
                  },
                  {
                    "sampleYVal": 170,
                    "sampleYDrawVal": 2583
                  },
                  {
                    "sampleYVal": 162,
                    "sampleYDrawVal": 2298
                  },
                  {
                    "sampleYVal": 149,
                    "sampleYDrawVal": 1921
                  },
                  {
                    "sampleYVal": 152,
                    "sampleYDrawVal": 1965
                  },
                  {
                    "sampleYVal": 129,
                    "sampleYDrawVal": 1335
                  },
                  {
                    "sampleYVal": 164,
                    "sampleYDrawVal": 2707
                  },
                  {
                    "sampleYVal": 180,
                    "sampleYDrawVal": 3077
                  },
                  {
                    "sampleYVal": 195,
                    "sampleYDrawVal": 3707
                  },
                  {
                    "sampleYVal": 171,
                    "sampleYDrawVal": 2849
                  },
                  {
                    "sampleYVal": 142,
                    "sampleYDrawVal": 1819
                  },
                  {
                    "sampleYVal": 126,
                    "sampleYDrawVal": 1416
                  },
                  {
                    "sampleYVal": 141,
                    "sampleYDrawVal": 1806
                  },
                  {
                    "sampleYVal": 145,
                    "sampleYDrawVal": 1943
                  },
                  {
                    "sampleYVal": 168,
                    "sampleYDrawVal": 2647
                  },
                  {
                    "sampleYVal": 174,
                    "sampleYDrawVal": 2834
                  },
                  {
                    "sampleYVal": 183,
                    "sampleYDrawVal": 3121
                  },
                  {
                    "sampleYVal": 145,
                    "sampleYDrawVal": 1875
                  },
                  {
                    "sampleYVal": 119,
                    "sampleYDrawVal": 1205
                  },
                  {
                    "sampleYVal": 73,
                    "sampleYDrawVal": 595
                  },
                  {
                    "sampleYVal": 22,
                    "sampleYDrawVal": 26
                  }
                ],
                "recordOffset": 26190,
                "seqUrl": "1527724920869_20633_709",
                "seqDescription": {
                  "analysis": {
                    "analysisType": "1sec"
                  },
                  "completeCount": 3,
                  "currentSeqPos": 0,
                  "pbId": "1710",
                  "text": "הָחוֹ",
                  "type": "word",
                  "word": {
                    "id": 1710,
                    "language": "he",
                    "cleanInput": "הָחוֹרִים",
                    "type": "word",
                    "data": "[{\"text\":\"\\u05d4\\u05b8\",\"sylType\":\"v\",\"vowel\":\"A\"},{\"text\":\"\\u05d7\\u05d5\\u05b9\",\"sylType\":\"cv\",\"vowel\":\"O\",\"isContinous\":true},{\"text\":\"\\u05e8\\u05b4\\u05d9\\u05dd\",\"sylType\":\"cvc\",\"vowel\":\"I\",\"isContinous\":true}]",
                    "isNonesense": 0,
                    "audios": "[]"
                  }
                },
                "mistakesObjects": {},
                "hiddenMistakesObjects": {}
              },
              {
                "fieldType": "seq",
                "timerPos": 1282,
                "calibrationEventId": "6581",
                "rate": 100,
                "deltaTimeMsFromPrevSeq": "2951.00",
                "yvalues": [
                  "111",
                  "151",
                  "170",
                  "170",
                  "163",
                  "164",
                  "151",
                  "140",
                  "126",
                  "127",
                  "153",
                  "168",
                  "178",
                  "184",
                  "174",
                  "154",
                  "144",
                  "132",
                  "137",
                  "146",
                  "159",
                  "166",
                  "166",
                  "150",
                  "100",
                  "59",
                  "0"
                ],
                "yDrawvalues": "1371,2642,2889,2648,2282,2425,1824,1519,1158,1429,2600,2896,3275,3408,2757,1911,1745,1388,1718,2002,2475,2641,2589,1881,563,100,0",
                "mistakes": [],
                "hiddenMistakes": [],
                "softVals": [
                  "1",
                  "1",
                  "1",
                  "1",
                  "1",
                  "1",
                  "1",
                  "1",
                  "1",
                  "1",
                  "1",
                  "1",
                  "0",
                  "0",
                  "1",
                  "1",
                  "1",
                  "1",
                  "1",
                  "1",
                  "1",
                  "1",
                  "1",
                  "1",
                  "1",
                  "1",
                  "1"
                ],
                "expectedSylsPerSecond": "0.00",
                "actualSylsPerSecond": "0.84",
                "timeLength": 3375,
                "features": [
                  {
                    "sampleYVal": 146,
                    "sampleYDrawVal": 1816
                  },
                  {
                    "sampleYVal": 181,
                    "sampleYDrawVal": 3068
                  },
                  {
                    "sampleYVal": 177,
                    "sampleYDrawVal": 2825
                  },
                  {
                    "sampleYVal": 167,
                    "sampleYDrawVal": 2498
                  },
                  {
                    "sampleYVal": 162,
                    "sampleYDrawVal": 2318
                  },
                  {
                    "sampleYVal": 158,
                    "sampleYDrawVal": 2170
                  },
                  {
                    "sampleYVal": 142,
                    "sampleYDrawVal": 1717
                  },
                  {
                    "sampleYVal": 134,
                    "sampleYDrawVal": 1476
                  },
                  {
                    "sampleYVal": 112,
                    "sampleYDrawVal": 964
                  },
                  {
                    "sampleYVal": 140,
                    "sampleYDrawVal": 1811
                  },
                  {
                    "sampleYVal": 161,
                    "sampleYDrawVal": 2551
                  },
                  {
                    "sampleYVal": 173,
                    "sampleYDrawVal": 2846
                  },
                  {
                    "sampleYVal": 192,
                    "sampleYDrawVal": 3645
                  },
                  {
                    "sampleYVal": 182,
                    "sampleYDrawVal": 3249
                  },
                  {
                    "sampleYVal": 147,
                    "sampleYDrawVal": 1973
                  },
                  {
                    "sampleYVal": 140,
                    "sampleYDrawVal": 1691
                  },
                  {
                    "sampleYVal": 135,
                    "sampleYDrawVal": 1574
                  },
                  {
                    "sampleYVal": 135,
                    "sampleYDrawVal": 1565
                  },
                  {
                    "sampleYVal": 148,
                    "sampleYDrawVal": 1919
                  },
                  {
                    "sampleYVal": 154,
                    "sampleYDrawVal": 2094
                  },
                  {
                    "sampleYVal": 169,
                    "sampleYDrawVal": 2609
                  },
                  {
                    "sampleYVal": 173,
                    "sampleYDrawVal": 2789
                  },
                  {
                    "sampleYVal": 160,
                    "sampleYDrawVal": 2350
                  },
                  {
                    "sampleYVal": 126,
                    "sampleYDrawVal": 1396
                  },
                  {
                    "sampleYVal": 63,
                    "sampleYDrawVal": 301
                  },
                  {
                    "sampleYVal": 36,
                    "sampleYDrawVal": 68
                  }
                ],
                "recordOffset": 2459,
                "seqUrl": "1527724950869_20633_709",
                "seqDescription": {
                  "analysis": {
                    "analysisType": "1sec"
                  },
                  "completeCount": 4,
                  "currentSeqPos": 0,
                  "pbId": "1710",
                  "text": "הָחוֹ",
                  "type": "word",
                  "word": {
                    "id": 1710,
                    "language": "he",
                    "cleanInput": "הָחוֹרִים",
                    "type": "word",
                    "data": "[{\"text\":\"\\u05d4\\u05b8\",\"sylType\":\"v\",\"vowel\":\"A\"},{\"text\":\"\\u05d7\\u05d5\\u05b9\",\"sylType\":\"cv\",\"vowel\":\"O\",\"isContinous\":true},{\"text\":\"\\u05e8\\u05b4\\u05d9\\u05dd\",\"sylType\":\"cvc\",\"vowel\":\"I\",\"isContinous\":true}]",
                    "isNonesense": 0,
                    "audios": "[]"
                  }
                },
                "mistakesObjects": {},
                "hiddenMistakesObjects": {}
              }
            ],
            "results": {
              "fieldType": "results",
              "woutActivityId": "20633",
              "woutIndex": "6",
              "userId": "709",
              "timerPos": 1282,
              "woutActivityShardGuid": "be6528b550908d38a2d61b1da121750508134a34",
              "wout": {
                "levelIndex": "26",
                "partIndex": 6,
                "part": {
                  "filterSettings": [
                    {
                      "filter": {
                        "lang": "he",
                        "syl": [],
                        "sylAmount": {
                          "exp": {
                            "val": 1,
                            "sign": "gt"
                          }
                        },
                        "selectedBank": "word",
                        "audioType": {
                          "filePath": "none",
                          "desc": "FILTERBUILDER.AUDIO_OPTION_NONE"
                        }
                      }
                    }
                  ],
                  "analysisDetails": [
                    {
                      "breathingEnabled": true,
                      "breathTimeMs": 2000,
                      "mistakesEnabled": true,
                      "mistakes": [
                        "gentleOnset",
                        "gentleOffset",
                        "volControlDown",
                        "volControlUp",
                        "symetric",
                        "intensePeak",
                        "sharpRise",
                        "sharpFall",
                        "shapeStart",
                        "shapeEnd",
                        "volControlStart",
                        "volControlEnd",
                        "notSoft",
                        "notHard",
                        "tooSoft",
                        "tooHard",
                        "tooSlow",
                        "tooFast"
                      ],
                      "boundariesEnabled": true,
                      "boundariesGridEnabled": true,
                      "coloringEnabled": true,
                      "analysisType": "1sec",
                      "analyzeTypeSylLenMs": 1000,
                      "analyzeTypeNumOfSylsInSeq": 1,
                      "mistakesSkills": {
                        "gentleOnset": 2,
                        "gentleOffset": 2,
                        "softPeak": 1,
                        "tooShort": 1,
                        "tooLong": 1,
                        "breathMiss": 1,
                        "tooFast": 2,
                        "tooSlow": 2,
                        "tooSoft": 2,
                        "tooHard": 2,
                        "breathEarly": 1,
                        "intensePeak": 2
                      },
                      "configs": [
                        {
                          "analyzeTypeSylLenMs": 500,
                          "analyzeTypeNumOfSylsInSeq": 5,
                          "weight": 3
                        },
                        {
                          "analyzeTypeSylLenMs": 200,
                          "analyzeTypeNumOfSylsInSeq": 5,
                          "weight": 5
                        }
                      ],
                      "analyzeTypeNumOfSylsInSeqAuto": true
                    }
                  ],
                  "uiSettings": {
                    "repetition": 5,
                    "enableMirror": false,
                    "instructions": "בתרגול זה יש להפיק מילים בתבנית שניה להברה, ולשאוף אויר בין מילה למילה.\nעל המסך יופיעו מילים באורכים שונים. יש להקפיד לשמור על רצף קולי בעת הפקת המילה.. \n חשוב להיעזר בתבנית המטרה המוצללת שמופיעה על גבי המוניטור, על מנת להפיק את המילים בצורה הנכונה, בדגם הגלים .\nבתרגול זה עליכם ליישם בצורה נכונה את העקרונות שנלמדו בסרטונים קודמים, תוך שמירה על מסגרת זמן של שניה לכל הברה במילה המופקת.",
                    "showWordGoal": true,
                    "defaultVideoInstructionsId": 0,
                    "phraseAudioType": [],
                    "videoInstructions": {
                      "id": 212,
                      "type": "video",
                      "title": "instructions.1sec_pattern_syllable_long_words_error_time_frame.monitor.mp4",
                      "value": "https://s3-eu-west-1.amazonaws.com/nvtk-video-media/2016_05/הוראות/instructions.1sec_pattern_syllable_long_words_error_time_frame.monitor.mp4",
                      "tags": [],
                      "thumb": "http://www.depaul.edu/campustour/Pages/_layouts/spotlight/btn-videoplayoverlay.png"
                    },
                    "isRecordEnabled": true,
                    "isScreenShareEnabled": true
                  },
                  "wordGoal": 25,
                  "description": "תרגול: שניה להברה- מילים רב הברתיות-מסגרת זמן הפקה",
                  "name": "1-sec words",
                  "timeEstimation": 375,
                  "uiType": {
                    "type": "box5stars",
                    "icon": "icon-star"
                  },
                  "engineType": {
                    "type": "AdvancedEngine",
                    "icon": "icon-diamond"
                  },
                  "pressed": false,
                  "passScore": "30",
                  "jqyoui_pos": 5,
                  "type": "wout"
                },
                "totalLevels": 52,
                "totalParts": 10,
                "tp2uInstanceId": "1984fcbe7fa059f148897aea947062da8efe9df6",
                "tpId": "809",
                "woutTag": "תרגול: שניה להברה- מילים רב הברתיות-מסגרת זמן הפקה"
              },
              "requiredDuration": 0,
              "actualDuration": -1,
              "isLastPartInLevel": false,
              "isLastLevel": false,
              "requiredPassRate": "30",
              "overallRate": 72,
              "totalActualSequencesDone": 25,
              "totalRequiredSequences": 25,
              "isWoutPassed": true,
              "nolimit": false,
              "showWordGoal": true,
              "showDurationGoal": false,
              "isPracticeExpired": false
            }
          }
        ];
export default parts;