import React from 'react';
import PropTypes from 'prop-types';
import CustomTooltip, { placementTypes } from '@components/CustomTooltip';

import { MenuItem, StyledChevronLeftIcon, ItemText } from './styles';

export default class ListBackButton extends React.Component {
  render() {
    const { text, onBackClick } = this.props;
    const hasTooltip = this.props.text.length > 19;

    const Component = (
      <MenuItem onClick={onBackClick}>
        <StyledChevronLeftIcon />
        <ItemText className='list_back_button_text'>{text}</ItemText>
      </MenuItem>
    );

    if (hasTooltip) {
      return (
        <CustomTooltip placement={placementTypes.TOP} text={text} tiny>
          {Component}
        </CustomTooltip>
      );
    }
    return Component;
  }
}

ListBackButton.propTypes = {
  text: PropTypes.string.isRequired,
  onBackClick: PropTypes.func.isRequired,
};
