import {styled} from "@mui/material";
import { Link } from 'react-router-dom';
import isPropValid from '@emotion/is-prop-valid';
import {
  getIconByResourceType,
  getIconsByType,
  iconTypes,
} from '@services/icons';
import courseTypes from '../../helpers/courseTypes';
const PlanIcon = getIconByResourceType(courseTypes.SKILL);
const BackIcon = getIconsByType(iconTypes.CHEVRON_LEFT);
const CloseIcon = getIconsByType(iconTypes.CLOSE);

export const Wrapper = styled('div')(
  {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'stretch',
    borderBottom: '1px solid',
    position: 'relative',
    gridArea: 'SkillPartHeader',
  },
  ({ theme, language }) => ({
      direction: language === 'en' ? 'ltr' :'rtl',
      backgroundColor: theme.palette.novotalk.$backgroundColor,
    borderColor: theme.palette.novotalk.$borderColorLight,
  })
);

export const Logo = styled('img')({
  width: '35px',
  height: '35px',
});

export const StyledPlanIcon = styled(PlanIcon, {
  shouldForwardProp: isPropValid,
})(({ theme }) => ({
  color: theme.palette.novotalk.$textColorDisabledLight,
  fontSize: 20,
  [theme.RTL ? 'marginLeft' : 'marginRight']: 8,
  marginBottom: -2,
  transform: theme.RTL ? 'rotateY(180deg)' : 'none',
}));

export const StyledCloseIcon = styled(CloseIcon)(({ theme }) => ({
  color: theme.palette.novotalk.$activeColor,
  fontSize: 30,
  marginRight: theme.RTL ? -5 : 3,
  marginLeft: theme.RTL ? 3 : -5,
  marginBottom: -3,
}));

export const StyledBackIcon = styled(BackIcon)(({ theme, language }) => ({
  marginRight: 6,
  marginBottom: -4,
  color: theme.palette.novotalk.$activeColor,
  fontSize: 30,
    transform: `rotate(${language === 'en' ? 0 : 180}deg)`
}));

export const OverviewWrapper = styled(Link)(
  {
    display: 'flex',
    padding: '10px',
    textDecoration: 'none',
    alignItems: 'center',
    width: 180,
  },
  ({ theme }) => ({
    '& > span': {
      direction: theme.RTL ? 'rtl' : 'ltr',
    },
    '&:hover': {
      backgroundColor: theme.palette.novotalk.$backgroundColorMedium,
    },
    '&:active': {
      backgroundColor: theme.palette.novotalk.$backgroundColorMedium,
    },
  })
);

export const SkillWrapper = styled('div')(
  {
    display: 'flex',
    padding: '10px 15px',
    alignItems: 'center',
  },
  ({ theme }) => ({
    direction: theme.RTL ? 'rtl' : 'ltr',
  })
);

export const PlanWrapper = styled(SkillWrapper)(
  {
    borderLeft: '1px solid',
    borderRight: '1px solid',
    width: 110,
    cursor: 'pointer',
    transition: 'all 0.8s',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  ({ theme, clicked }) => ({
    direction: theme.RTL ? 'rtl' : 'inherit',
    borderColor: theme.palette.novotalk.$borderColorLight,
    backgroundColor: clicked ? theme.palette.novotalk.$backgroundColorMedium : 'none',
  })
);
