import { useMemo, useEffect, useCallback } from 'react';
import { useLocation, useHistory, useParams } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';
import { ROUTES, TOAST_AUTO_CLOSE_TIME } from '@shared/constants';
import { BackButton } from '@components/BackButton';
import SectionLoading from '@components/SectionLoading';
import { Sidebar } from './components/sidebar';
import { Container, Main, LoadingOverlay } from './styles';
import { fetchUserPage } from '../actions';
import { USER_PAGE_TYPES } from '../consts';
import { UserActivityLog } from './components/user-activity-log';
import { UserInformation } from './components/UserInformation';
import { UserPersonalProgram } from './components/UserPersonalProgram/index.tsx';

/**
 * Wrapper component for all internal pages
 * Mounting: fetch user data by id
 */
export const UserPageWrapper = () => {
  const dispatch = useDispatch();
  const { pathname } = useLocation();
  const { push } = useHistory();
  const { id } = useParams();
  const { t } = useTranslation();

  const { loading, error } = useSelector((state) => state.get('userPage').toJS());

  const currentPageType = useMemo(() => pathname.split('/').at(-1), [pathname]);

  /**
   * Back to users list page
   */
  const goBackToUsers = () => {
    push(ROUTES.USERS.LIST);
  };

  useEffect(() => {
    if (error) {
      toast.error(t('Informative.Negative.usersNotLoad'), {
        autoClose: TOAST_AUTO_CLOSE_TIME,
        position: 'bottom-right',
      });
    }
  }, [error]);

  useEffect(() => {
    dispatch(fetchUserPage(id));
  }, []);

  /**
   * Render user page by type
   */
  const renderComponent = useCallback(() => {
    switch (currentPageType) {
      case USER_PAGE_TYPES.INFORMATION: return <UserInformation />;
      case USER_PAGE_TYPES.PERSONAL_PROGRAM: return <UserPersonalProgram />;
      case USER_PAGE_TYPES.LOG: return <UserActivityLog />;
      default: return null;
    }
  }, [currentPageType]);

  return (
    <Container>
      {loading && <LoadingOverlay><SectionLoading /></LoadingOverlay>}
      <Sidebar pageType={currentPageType} />
      <Main>
        <BackButton
          text={t('Actions.Navigation.backToUsers')}
          onClick={goBackToUsers}
          margin="0 0 29px 0"
        />
        {renderComponent()}
      </Main>
    </Container>
  );
};
