import React from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import _ from 'lodash';
import Text, { TEXT_COLOR, TEXT_SIZE } from '@components/Text';
import Container from '@components/Container';
import {
  InsightsContainer,
  TipContainer,
  getStyledIcon,
  SkillWrapper,
  IconWrapper,
  PerfectIcon,
  PerfectIconWrapper,
  PerfectWrapper,
} from './styles';
import { getPracticeTipsByType } from '@containers/User/helpers/monitorErrors';
import {useTranslation} from "react-i18next";

export const PracticeInsights = ({ insights }) => {
    const {t} = useTranslation()
  const getSubTitleText = (severity) => {
    switch (severity) {
      case 'PERFECT':
        return (
          <PerfectWrapper>
            <PerfectIconWrapper>
              <PerfectIcon />
            </PerfectIconWrapper>
            <Text>{t('Feedback.perfect')}</Text>
          </PerfectWrapper>
        );
      case 'SEVERE':
        return (
          <Text margin='0 0 12px'>
              {t('Feedback.goodJob')}
          </Text>
        );
      case 'LIGHT':
        return (
          <Text margin='0 0 12px'>{t('Feedback.veryGood')}</Text>
        );
      default:
        return null;
    }
  };

  const parsedInsights = getPracticeTipsByType(insights);
  return (
    <Container column alignItems='center'>
      <Text
        size={TEXT_SIZE.H2}
        weight='bold'
        textColor={TEXT_COLOR.DARK_BLUE}
        margin='0 0 24px 0'
      >
          {t('Informative.Statistical.practiceSummary')}
      </Text>
      <Text weight='bold' textColor={TEXT_COLOR.DARK_BLUE} margin='0 0 13px 0'>
          {t('Informative.Notifying.workedOn')}
      </Text>
      <InsightsContainer>
        {parsedInsights.map(({ type, tips, severity }) => (
          <SkillWrapper key={type}>
            <Text
              weight='bold'
              textColor={TEXT_COLOR.DARK_BLUE}
              margin='0 0 12px 0'
            >
              {_.isUndefined(t(`Monitors.List.${type}`))
                ? type
                : t(`Monitors.List.${type}`)}
            </Text>
            {getSubTitleText(severity)}
            {tips.map(({ errorId, iconName, text }) => {
              const TipIcon = getStyledIcon(iconName);
              return (
                <TipContainer className='tip-item' key={errorId}>
                  <IconWrapper>
                    <TipIcon id='tipIcon' />
                  </IconWrapper>
                  <Text size='H4' textColor={TEXT_COLOR.DARK_BLUE}>
                    {text}
                  </Text>
                </TipContainer>
              );
            })}
          </SkillWrapper>
        ))}
      </InsightsContainer>
    </Container>
  );
};

export default PracticeInsights;
