/**
 *
 * RoleManagementSection
 *
 */

import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import Text, { TEXT_SIZE, TEXT_COLOR, TEXT_WEIGHT } from '@components/Text';

import {
  Item,
  Input,
  ListContainer,
  AreaHeader,
  Title,
  StyledArrowRightIcon,
} from './styles';
import {withTranslation} from "react-i18next";
import {compose} from "redux";

class RoleManagementSection extends React.Component {
  constructor(props) {
    super(props);
    this.state = { filter: '' };
  }

  onFilter = (event) => {
    const { value } = event.target;
    this.setState({ filter: value });
  };

  renderItemsList = () => {
    const {
      items,
      filterItemBy,
      isItemsSelected,
      onItemSelect,
      getItemDisplay,
      getItemKey,
      customComponents,
    } = this.props;
    const { filter } = this.state;
    const filteredItems = _.isEmpty(filter)
      ? items
      : items.filter(filterItemBy(this.state.filter));
    const ItemsListContainer = customComponents.itemsList || ListContainer;
    const ItemContainer = customComponents.item || Item;

    return (
      <ItemsListContainer>
        {filteredItems.map((item) => (
          <ItemContainer
            isSelected={isItemsSelected(item)}
            onClick={() => onItemSelect(item)}
            className='item'
            key={getItemKey(item)}
          >
            {getItemDisplay(item)}
            {isItemsSelected(item) && <StyledArrowRightIcon />}
          </ItemContainer>
        ))}
      </ItemsListContainer>
    );
  };

  render() {
    const {
      titleMessage,
      showFilter,
      placeholderText,
      customComponents,
        t
    } = this.props;

    // get components
    const HeaderContainer = customComponents.header || AreaHeader;
    const HeaderTitleContainer = customComponents.headerTitle || Title;
    const Filter = customComponents.filter || Input;

    return (
      <div>
        <HeaderContainer>
          {titleMessage && (
            <HeaderTitleContainer>
              <Text
                textColor={TEXT_COLOR.ACTIVE}
                size={TEXT_SIZE.T2}
                weight={TEXT_WEIGHT.BOLD}
              >
                {t(titleMessage)}
              </Text>
            </HeaderTitleContainer>
          )}
          {showFilter && (
            <Filter
              placeholder={placeholderText ? t(placeholderText) : ''}
              onChange={this.onFilter}
              className='filterItems'
              value={this.state.filter}
            />
          )}
        </HeaderContainer>
        {this.renderItemsList()}
      </div>
    );
  }
}

RoleManagementSection.defaultProps = {
  titleMessage: null,
  isItemsSelected: () => false,
  getItemDisplay: (item) => item.toString(),
  getItemKey: (item) => item.id,
  showFilter: true,
  customComponents: {},
};

RoleManagementSection.propTypes = {
  items: PropTypes.array.isRequired,
  filterItemBy: PropTypes.func.isRequired,
  onItemSelect: PropTypes.func.isRequired,
  titleMessage: PropTypes.object,
  isItemsSelected: PropTypes.func,
  getItemDisplay: PropTypes.func,
  getItemKey: PropTypes.func,
  showFilter: PropTypes.bool,
  placeholderText: PropTypes.object,
  customComponents: PropTypes.shape({
    header: PropTypes.any,
    headerTitle: PropTypes.any,
    itemsList: PropTypes.any,
    item: PropTypes.any,
    filter: PropTypes.any,
  }),
};

export default compose(
    withTranslation()
)(RoleManagementSection);
