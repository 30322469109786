import {styled} from "@mui/material";
import { getIconsByType, iconTypes } from '@services/icons';

export const FeedbackIcon = getIconsByType(iconTypes.CREATE);

export const Wrapper = styled('div')({
  display: 'flex',
  justifyItems: 'center',
  alignItems: 'center',
  cursor: 'pointer',
  minHeight: '18px',
});

export const IconWrapper = styled('div')(
  {
    marginTop: -7,
  },
  ({ theme }) => ({
    [theme.RTL ? 'marginLeft' : 'marginRight']: 7,
    color: theme.palette.novotalk.$textColorHighlited,
  })
);
