import { VerticalLine, UserActivityLogWrapper } from "./styles";
import { ActivityPanel } from './components/activity-panel';
import { ActivityTable } from './components/activity-table';

/**
 * Component user activities
 */
export const UserActivityLog = () => (
  <UserActivityLogWrapper>
    <ActivityTable/>
    <VerticalLine />
    <ActivityPanel/>
  </UserActivityLogWrapper>
);
