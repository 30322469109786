import React from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { withRouter } from 'react-router-dom';
import { ROUTES } from '@shared/constants';
import Text, { TEXT_SIZE, TEXT_COLOR } from '@components/Text';
import Container from '@components/Container';
import TimeExpiredImage from './time_expired.svg';
import SkillsCompletedImage from './skills_completed.svg';
import {
  Wrapper,
  StyledImage,
  ButtonsContainer,
  StyledCTAButton,
} from './styles';
import {useTranslation} from "react-i18next";

export const UpgradeCourse = ({
  onClose,
  isExpired,
  userName,
  history,
}) => {
    const {t} = useTranslation()
  const body = isExpired ? (
    <Container column>
      <Text size={TEXT_SIZE.T3} textColor={TEXT_COLOR.DARK_BLUE}>
        {t("Feedback.timeFlies")}
      </Text>
      <Text size={TEXT_SIZE.T3} textColor={TEXT_COLOR.DARK_BLUE}>
        {t('Informative.Negative.trialEnded')}
      </Text>
      <Container column margin='20px 0 0 0'>
        <Text size={TEXT_SIZE.T3} textColor={TEXT_COLOR.DARK_BLUE}>
          {t("Informative.Notifying.offer")}
        </Text>
      </Container>
    </Container>
  ) : (
    <Container column>
      <Text size={TEXT_SIZE.H4} textColor={TEXT_COLOR.DARK_BLUE}>
        {t("Informative.Positive.trialFinished")}
      </Text>
      <Container column margin='25px 0 0 0'>
        <Text
          size={TEXT_SIZE.H4}
          textColor={TEXT_COLOR.DARK_BLUE}
          weight='bold'
        >
          {t("Feedback.exciting")}
        </Text>
        <Text size={TEXT_SIZE.H4} textColor={TEXT_COLOR.DARK_BLUE}>
          {t("Informative.Notifying.upgrade")}
        </Text>
      </Container>
    </Container>
  );

  return (
    <Wrapper>
      <Text size={TEXT_SIZE.T1} textColor={TEXT_COLOR.ACTIVE} weight='bold'>
        {t("Common.Greetings.hiUsername", { userName })}
      </Text>
      <StyledImage src={isExpired ? TimeExpiredImage : SkillsCompletedImage} />
      {body}
      <ButtonsContainer>
        <StyledCTAButton secondary onClick={onClose} margin='0 10px 0 0'>
          {t("Dates.notNow")}
        </StyledCTAButton>
        <StyledCTAButton
          action
          onClick={() => {
            history.push(ROUTES.BILLING);
            onClose();
          }}
        >
          {t("Actions.Instance.upgrade")}
        </StyledCTAButton>
      </ButtonsContainer>
    </Wrapper>
  );
};

UpgradeCourse.propTypes = {
  onClose: PropTypes.func,
  isExpired: PropTypes.bool,
  userName: PropTypes.string,
  history: PropTypes.object,
};


export default compose(withRouter)(UpgradeCourse);
