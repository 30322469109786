import React from 'react';
import PropTypes from 'prop-types';
import Text, { TEXT_SIZE, TEXT_COLOR } from '@components/Text';
import FeedbackList from '@components/FeedbackList';
import {
  Wrapper,
  HeaderContainer,
  EnterPracticeHeader,
  PracticeHeader,
  StyledImage,
  StyledCTAButton,
} from './styles';
import Image from './Chat.png';
import { useDispatch } from 'react-redux';
import { markFeedbackAsSeen } from '@containers/User/containers/CourseRouter/actions';
import {useTranslation} from "react-i18next";

const ClinicianFeedback = (props) => {
  const {
    practiceName,
    feedbacks,
    isEnteringPractice,
    onClose,
  } = props;
  const {t} = useTranslation()
  const { firstName, lastName } = feedbacks[0].publisherUser;
  const dispatch = useDispatch()
  const onCloseHandler = () => {
    const feedbackIds = feedbacks.map((feedback) => feedback.id)
    dispatch(markFeedbackAsSeen('POST', feedbackIds))

  }

  const header = isEnteringPractice ? (
    <EnterPracticeHeader>
      <Text
        size={TEXT_SIZE.T1}
        textColor={TEXT_COLOR.DARK_BLUE}
        weight='bold'
        className='popup-title'
      >
        {t('Common.Greetings.welcomeBack')}
      </Text>
      <Text size={TEXT_SIZE.T3} textColor={TEXT_COLOR.DARK_BLUE}>
        {t('Informative.Notifying.practiceFeedback', { firstName, lastName })}
      </Text>
    </EnterPracticeHeader>
  ) : (
    <PracticeHeader>
      <Text
        size={TEXT_SIZE.T1}
        textColor={TEXT_COLOR.DARK_BLUE}
        weight='bold'
        className='popup-title'
      >
        {t("Common.UI.feedback")}
      </Text>
      <Text
        size={TEXT_SIZE.T3}
        textColor={TEXT_COLOR.DARK_BLUE}
        textTransform='capitalize'
      >
        {practiceName}
      </Text>
    </PracticeHeader>
  );

  return (
    <Wrapper>
      <HeaderContainer>{header}</HeaderContainer>
      {isEnteringPractice && <StyledImage src={Image} />}
      <FeedbackList feedbacks={feedbacks} />
      {isEnteringPractice && (
        <StyledCTAButton onClick={onCloseHandler}>
          {t("Actions.Confirmations.gotIt")}
        </StyledCTAButton>
      )}
    </Wrapper>
  );
};

ClinicianFeedback.propTypes = {
  practiceName: PropTypes.string,
  feedbacks: PropTypes.array,
  isEnteringPractice: PropTypes.bool,
  onClose: PropTypes.func,
};

export default ClinicianFeedback;
