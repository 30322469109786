import React, { useState } from 'react';
import { WithMargin } from '@containers/App/containers/PopUp/popups/MediaEditor/styles';
import CustomTextInput from '@components/CustomTextInput';
import { Tags } from '@components/Tags';
import { MOCK_TAGS } from '@shared/constants';
import { useDispatch } from 'react-redux';
import { checkForExistingName } from '@containers/Admin/containers/Builder/containers/BuilderHeader/actions';
import { RESOURCES } from '@shared/Resources/types';
import {useTranslation} from "react-i18next";

export const TITLE_EXISTS_TEXT = 'This title already exists';

export const MediaEditorForm = ({
  handleInput,
  handleTagsChange,
  title,
  description,
  tags,
  existingName,
}) => {
    const {t} = useTranslation()
  const [originalTitle] = useState(title);

  const dispatch = useDispatch();

  const handleCheckExistingTitle = (title) => {
    if (!title) return;
    originalTitle !== title && dispatch(checkForExistingName(RESOURCES.MEDIA, title));
  }

  const inputError = existingName ? TITLE_EXISTS_TEXT : null;

  return (
    <span>
      <WithMargin marginTop={10} marginBottom={10}>
        <CustomTextInput
          name='title'
          label={t('Common.UI.title')}
          value={title || ''}
          onChange={handleInput}
          onBlur={({ target }) => target && handleCheckExistingTitle(target.value)}
          error={inputError}
        />
      </WithMargin>
      <WithMargin marginBottom={20}>
        <CustomTextInput
          name='description'
          label={t('Common.UI.description')}
          value={description || ''}
          onChange={handleInput}
        />
      </WithMargin>
      <WithMargin marginBottom={20}>
        <Tags
          handleTagsChange={handleTagsChange}
          value={tags}
          options={MOCK_TAGS}
          placeholder="Tags"
        />
      </WithMargin>
    </span>
  )
};
