/**
*
* CheckBox
*
*/
import { Component } from 'react';
import PropTypes from 'prop-types';
import { Label, Input, Box, CheckMark, ExtraLabel, CheckBoxWrapper } from './styles';

class CheckBox extends Component {
  constructor(props) {
    super(props);
    this.state = {
      checked: props.value,
      focus: false,
    };
  }

  componentDidUpdate(prevProps) {
    if (prevProps.value !== this.props.value) {
      this.setState({ checked: this.props.value });
    }
  }

  onChange = () => {
    const checked = !this.state.checked;
    this.setState({ checked });
    if (this.props.onChange) this.props.onChange(checked);
  }

  setFocus = (focus) => () => {
    this.setState({ focus });
  }

  toggleFocus = () => {
    const checked = !this.state.checked;
    this.setState({ checked });
    if (this.props.onChange) this.props.onChange(checked);
  }

  render() {
    const { label, className, isDisabled } = this.props;
    const { checked } = this.state;
    return (
      <CheckBoxWrapper className={className}>
        <Label className="switch" on={checked}>
          <Input
            value={checked}
            onFocus={this.setFocus(true)}
            onBlur={this.setFocus(false)}
            type="checkbox"
            onChange={this.onChange}
          />
          <Box
            on={checked}
            className="slider"
            isDisabled={isDisabled}
          >
            <CheckMark
              on={checked.toString()}
              isDisabled={isDisabled}
            />
          </Box>
        </Label>
        <ExtraLabel onClick={this.onChange}>
          {label}
        </ExtraLabel>
      </CheckBoxWrapper>
    );
  }
}

CheckBox.defaultProps = {
  value: false,
};

CheckBox.propTypes = {
  value: PropTypes.bool,
  onChange: PropTypes.func,
  label: PropTypes.any,
  className: PropTypes.string,
};

export default CheckBox;
