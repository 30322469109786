export const mockChatInfo4Users =  {
      type: 'CLINICAL',
      sessionId: 'sessionId',
      users: [
        {
          participantId: 'id1',
          name: 'Initiator 1',
          avatar: 'https://s3-eu-west-1.amazonaws.com/nvtk-user-avatar/defaults/2.svg',
          initiator: true,
          self: true,
        },
        {
          participantId: 'id2',
          name: 'User 1',
          avatar: 'https://s3-eu-west-1.amazonaws.com/nvtk-user-avatar/defaults/1.svg',
          initiator: true,
          self: false,
        },
        {
          participantId: 'id3',
          name: 'User 2',
          avatar: 'https://s3-eu-west-1.amazonaws.com/nvtk-user-avatar/defaults/3.svg',
          initiator: false,
          self: false,
        },
        {
          participantId: 'id4',
          name: 'User 3',
          avatar: 'https://s3-eu-west-1.amazonaws.com/nvtk-user-avatar/defaults/4.svg',
          initiator: false,
          self: false,
        },
    ]
  };

   export const mockChatInfo8Users = {
      type: 'CLINICAL',
      sessionId: 'sessionId',
      users: [
        {
          participantId: 'id1',
          name: 'Initiator 1',
          avatar: 'https://s3-eu-west-1.amazonaws.com/nvtk-user-avatar/defaults/2.svg',
          initiator: true,
          self: true,
        },
        {
          participantId: 'id2',
          name: 'User 1',
          avatar: 'https://s3-eu-west-1.amazonaws.com/nvtk-user-avatar/defaults/1.svg',
          initiator: true,
          self: false,
        },
        {
          participantId: 'id3',
          name: 'User 2',
          avatar: 'https://s3-eu-west-1.amazonaws.com/nvtk-user-avatar/defaults/3.svg',
          initiator: false,
          self: false,
        },
        {
          participantId: 'id4',
          name: 'User 3',
          avatar: 'https://s3-eu-west-1.amazonaws.com/nvtk-user-avatar/defaults/4.svg',
          initiator: false,
          self: false,
        },
      ],
  };