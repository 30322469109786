import React from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { withRouter } from 'react-router-dom';
import { ROUTES } from '~/shared/constants';
import Text, { TEXT_SIZE, TEXT_COLOR } from '@components/Text';
import Container from '@components/Container';
import { Wrapper, StyledInfoIcon, StyledCTAButton } from './styles';
import {withTranslation} from "react-i18next";

export const TrialBar = (props) => {
  const {
    history,
    daysLeft,
    t
  } = props;
  return (
    <Wrapper>
      <Container>
        <StyledInfoIcon />
        <Text textColor={TEXT_COLOR.DARK_BLUE} size={TEXT_SIZE.H4}>
          {t('Informative.Notifying.trialMessage')}
        </Text>
        <Text
          textColor={TEXT_COLOR.DARK_BLUE}
          size={TEXT_SIZE.H4}
          weight='bold'
          margin='0 5px'
        >
          {t("Dates.daysCount", { count: daysLeft })}
        </Text>
      </Container>
      <Container>
        <Text textColor={TEXT_COLOR.DARK_BLUE} size={TEXT_SIZE.H4}>
          {t('Informative.Confirming.interestedMessage')}
        </Text>
        <StyledCTAButton action onClick={() => history.push(ROUTES.BILLING)}>
          {t('Actions.Instance.upgrade')}
        </StyledCTAButton>
      </Container>
    </Wrapper>
  );
};

TrialBar.propTypes = {
  daysLeft: PropTypes.number,
  history: PropTypes.object,
};

export default compose(withRouter, withTranslation())(TrialBar);
