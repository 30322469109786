import {
  AvatarWrapper,
  Avatar,
  EmptyAvatar,
} from './styles';
import Text, { TEXT_COLOR } from "@components/Text";

const NO_NAME = 'No name';

export const AvatarUserWithName = ({
  url,
  fullName,
  participantId,
  currentUserId,
}) => {
  /**
   * Helper to get first letters from full name
   * @returns {Array<string>} - Returned value
   * @example
   * firstLettersName('Albert Flores'); // Returns: ['A', 'F']
   * firstLettersName('Angelina Markova'); // Returns: ['A', 'M']
   */
  const initialsHelper = () => fullName.split(' ').map((name) => name.at(0));
  return (
    <AvatarWrapper>
      {url
        ? <Avatar
            isCurrentUser={participantId === currentUserId}
            src={url}
            alt={`${fullName ?? NO_NAME}`}
          />
        : <EmptyAvatar isCurrentUser={participantId === currentUserId}>
            {initialsHelper()}
          </EmptyAvatar>
      }
      <Text size="H4" textColor={TEXT_COLOR.DARK_BLUE}>
        {fullName ?? NO_NAME}
      </Text>
    </AvatarWrapper>
  );
};
