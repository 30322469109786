import {styled} from "@mui/material";
import { getResponsiveCSS } from '@utils/cssHelpers';
import TextButton from '@components/TextButton';

export const Header = styled('div')(
  {
    width: '100%',
    padding: '13px 20px',
    marginBottom: 30,
    lineHeight: '1em',
  },
  ({ theme }) => ({
    backgroundColor: theme.palette.novotalk.$backgroundColorMedium,
  })
);

export const CourseName = styled('div')(
  {
    margin: '0 20px 30px',
  },
  ({ theme }) => ({
    marginRight: theme.RTL ? 40 : 20,
  })
);

export const DetailsContainer = styled('div')({
  width: '100%',
  display: 'flex',
  justifyContent: 'space-between',
  padding: '0 20px',
});

export const DetailsItem = styled('div')({
  marginBottom: 15,
  display: 'grid',
  gridTemplateColumns: '190px auto',
});

export const NextInvoiceWrapper = styled('div')({
  width: 390,
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-end',
});

export const NextInvoiceInfo = styled('div')(
  {
    width: '100%',
    marginBottom: 10,
    padding: 20,
    borderRadius: 8,
  },
  ({ theme }) => ({
    backgroundColor: theme.palette.novotalk.$backgroundColorMedium,
  })
);

export const BillingCycle = styled('div')(
  {
    marginBottom: 18,
    display: 'flex',
    justifyContent: 'space-between',
  },
  getResponsiveCSS('height', 66, 72),
  ({ theme }) => ({
    borderBottom: `1px solid ${theme.palette.novotalk.$settingsSeparatorColor}`,
  })
);

export const BillingDetails = styled('div')({
  display: 'flex',
  justifyContent: 'space-between',
});

export const SubscriptionTextButton = styled(TextButton)(({ theme }) => ({
  marginLeft: theme.RTL ? 30 : 0,
}));
