import {styled} from "@mui/material";
import isPropValid from '@emotion/is-prop-valid';
import {
  getIconsByType,
  getIconByResourceType,
  iconTypes,
} from '@services/icons';
import { RESOURCES } from '../../../../types';

const MediaIcon = getIconByResourceType(RESOURCES.MEDIA);
const PlayIcon = getIconsByType(iconTypes.PLAY);
const MiniOverviewIcon = getIconsByType(iconTypes.PLAY_CIRCLE);

export const StyledMediaIcon = styled(MediaIcon)(
  {
    fontSize: 24,
    marginTop: -3,
  },
  ({ theme }) => ({
    [theme.RTL ? 'marginLeft' : 'marginRight']: 5,
    color: theme.palette.novotalk.$questionIconColor,
  })
);

export const StyledMiniOverviewIcon = styled(MiniOverviewIcon)(
  {
    fontSize: 18,
    marginTop: -5,
  },
  ({ theme }) => ({
    [theme.RTL ? 'marginLeft' : 'marginRight']: 5,
    color: theme.palette.novotalk.$activeColor,
  })
);

export const StyledPlayIcon = styled(PlayIcon, {
  shouldForwardProp: isPropValid,
})(
  {
    position: 'absolute',
    zIndex: '10',
    fontSize: 48,
    top: 'calc(50% - 24px)',
    right: 'calc(50% - 24px)',
    padding: '12px 10px 12px 14px',
    borderRadius: 24,
  },
  ({ theme }) => ({
    color: theme.palette.novotalk.$backgroundColor,
    backgroundColor: theme.palette.novotalk.$playVideoIconBgColor,
  })
);

export const Wrapper = styled('div')(
  {
    width: '100%',
  },
  ({ miniOverview }) => ({
    minWidth: miniOverview ? 'none' : '800px',
    margin: miniOverview ? 0 : '10px 0 35px 0',
  }),
  ({ theme, isPollMiniOverview }) =>
    isPollMiniOverview && {
      marginBottom: 15,
      paddingBottom: 15,
      borderBottom: `1px solid ${theme.palette.novotalk.$resourcesTableBorderColor}`,
    }
);

export const DescriptionContainer = styled('div')({
  marginTop: 8,
  lineHeight: '19px',
});

export const VideoPlayer = styled('video')({
  width: '100%',
  position: 'relative',
  zIndex: -2,
});

export const VideoWrapper = styled('div')(
  {
    margin: '17px 0 10px 0',
    position: 'relative',
    cursor: 'pointer',
  },
  ({ styleOverride, miniOverview }) => ({
    ...styleOverride,
    width: miniOverview ? 220 : 390,
  })
);

export const AudioPlayer = styled('audio')(
  {
    display: 'block',
    margin: '17px 0 10px 0',
    height: 60,
  },
  ({ miniOverview }) => ({
    maxWidth: miniOverview ? 220 : 390,
  })
);

export const ImageWrapper = styled('div')(
  {
    margin: '17px 0 10px 0',
  },
  ({ miniOverview }) => ({
    width: miniOverview ? 220 : 390,
  })
);

export const Image = styled('img')({
  height: 'auto',
  maxWidth: '350px',
  borderRadius: 4,
  cursor: 'pointer',
});
