import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'redux';
import injectReducer from '@utils/injectReducer';
import injectSaga from '@utils/injectSaga';
import { withRouter } from 'react-router-dom';
import _ from 'lodash';
import Container from '@components/Container';
import Text, { TEXT_COLOR, TEXT_SIZE } from '@components/Text';
import CTAButton from '@components/CTAButton';
import TextButton from '@components/TextButton';
import CustomTextInput from '@components/CustomTextInput/index';
import { ROUTES } from '@shared/constants';
import { formHasErrors, getErrorMessageId } from '../validate';
import {
  ForgotPasswordComponentContainer,
  WithHeaderComponentContainer,
  InputContainer,
  ButtonContainer,
  TextContainer,
  StyledLink,
  ForgotPasswordScreenContainer,
  HeaderContainer,
  StyledLogo,
} from './styles';
import LogoImage from '../logoTop.svg';
import { onSendPasswordLink } from './actions';
import reducer from './reducer';
import saga from './saga';
import {withTranslation} from "react-i18next";

// TODO: Add Help-center route

export class ForgotPassword extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      form: {
        values: {
          email: '',
        },
        errors: {
          email: null,
        },
      },
    };
  }

  componentWillReceiveProps(newProps) {
    if (newProps.redirectToPage) {
      const { email } = this.state.form.values;
      this.props.history.push(newProps.redirectToPage, { email });
    }
  }

  onFocus = (event) => {
    const name = event.target.name;
    this.setState((prevState) => {
      const newForm = prevState.form;
      newForm.errors[name] = null;

      return {
        form: newForm,
      };
    });
  };

  onFieldUpdate = (event) => {
    const { value, name } = event.target;

    this.setState((prevState) => {
      const newForm = prevState.form;
      newForm.values[name] = value;
      return {
        form: newForm,
      };
    });
  };

  onSendButtonClick = () => {
    const { sendPasswordLink } = this.props;
    const { email } = this.state.form.values;

    const formErrors = this.getFormErrors();
    const newForm = _.cloneDeep(this.state.form);

    const loading = !formHasErrors(formErrors);
    newForm.errors = formErrors;

    this.setState({
      form: newForm,
      loading,
    });

    if (loading) {
      sendPasswordLink(email);
    }
  };

  getFormErrors = () => {
    const { values } = this.state.form;
    const newErrors = {};

    _.forEach(values, (fieldValue, fieldName) => {
      const messageId = getErrorMessageId(fieldName, fieldValue);
      const fieldError = messageId
        ? this.props.t(`Errors.${messageId}`)
        : null;
      newErrors[fieldName] = fieldError;
    });

    return newErrors;
  };

  render() {
    const {t} = this.props
    const { values, errors } = this.state.form;
    return (
      <ForgotPasswordScreenContainer>
        <WithHeaderComponentContainer>
          <HeaderContainer>
            <StyledLogo src={LogoImage} alt='logo' />
            <Text textColor={TEXT_COLOR.DARK_BLUE} size={TEXT_SIZE.H1}>
              {t('Authentication.Password.reset')}
            </Text>
          </HeaderContainer>
          <ForgotPasswordComponentContainer id='forgotPassword'>
            <TextContainer>
              <Text textColor={TEXT_COLOR.DISABLED} size={TEXT_SIZE.H4}>
                {t('Authentication.Email.enterEmail')}
              </Text>
              <Text textColor={TEXT_COLOR.DISABLED} size={TEXT_SIZE.H4}>
                {t('Authentication.Password.receiveLink')}
              </Text>
            </TextContainer>

            <InputContainer>
              <CustomTextInput
                id='email_input'
                label={t('Authentication.Email.email')}
                name='email'
                error={errors.email}
                value={values.email}
                onChange={this.onFieldUpdate}
                onFocus={this.onFocus}
              />
            </InputContainer>

            <ButtonContainer>
              <CTAButton
                id='send_button'
                expanded
                onClick={this.onSendButtonClick}
              >
                {t('Common.Statuses.send')}
              </CTAButton>
            </ButtonContainer>

            <Container column>
              <Text textColor={TEXT_COLOR.DARK_BLUE} size={TEXT_SIZE.H4}>
                {t('Authentication.Distribution.needHelp')}
              </Text>
              <StyledLink id='help_center_link' to={ROUTES.FORGOT_PASSWORD}>
                <TextButton>
                  <Text size={TEXT_SIZE.H4}>
                    {t('Authentication.Distribution.helpCenter')}
                  </Text>
                </TextButton>
              </StyledLink>
            </Container>
          </ForgotPasswordComponentContainer>
        </WithHeaderComponentContainer>
      </ForgotPasswordScreenContainer>
    );
  }
}

ForgotPassword.propTypes = {
  sendPasswordLink: PropTypes.func.isRequired,
  history: PropTypes.object,
};

export const mapStateToProps = (state) => {
  const serverError = state.getIn([
    'sendPasswordLink',
    'errors',
    'serverError',
  ]);
  const redirectToPage = state.getIn(['sendPasswordLink', 'redirectToPage']);
  return { serverError, redirectToPage };
};

export const mapDispatchToProps = (dispatch) => ({
  sendPasswordLink: (email) => {
    dispatch(onSendPasswordLink(email));
  },
});

const withConnect = connect(mapStateToProps, mapDispatchToProps);
const withSaga = injectSaga({ key: 'sendPasswordLink', saga });
const withReducer = injectReducer({ key: 'sendPasswordLink', reducer });

export default compose(
  withTranslation(),
  withConnect,
  withRouter,
  withReducer,
  withSaga
)(ForgotPassword);
