import {styled} from "@mui/material";
import { getResponsiveCSS } from '@utils/cssHelpers';
import { StyledTooltip } from '@components/CustomTooltip/styles';

export const ResetPasswordScreenContainer = styled('div')(
  {
    minHeight: '100vh',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  ({ theme }) => ({
    direction: theme.RTL ? 'rtl' : 'inherit',
  })
);

export const ResetPasswordComponentContainer = styled('div')(
  {
    paddingLeft: 68,
    paddingRight: 68,
    borderRadius: 4,
  },
  getResponsiveCSS('paddingTop', 26, 38),
  getResponsiveCSS('paddingBottom', 30, 48),
  ({ theme }) => ({
    border: `1px solid ${theme.palette.novotalk.$borderColorLight}`,
    backgroundColor: theme.palette.novotalk.$backgroundColorAuthScreens,
  })
);

export const WithHeaderComponentContainer = styled('div')(
  {
    padding: '30px 0',
  },
  ({ theme }) => ({
    width: theme.palette.novotalk.authFormsWidth,
  })
);

export const HeaderContainer = styled('div')(
  {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  getResponsiveCSS('marginBottom', 12, 18)
);

export const StyledLogo = styled('img')(
  {
    marginBottom: 60,
  },
  getResponsiveCSS('width', 300, 340)
);

export const TextContainer = styled('div')({
  textAlign: 'center',
  marginBottom: 26,
});

export const InputContainer = styled('div')({
  height: 106,
  position: 'relative',
});

export const InputContainerAutoHeight = styled('div')({
  height: '100%',
  minHeight: 104,
});

export const ButtonContainer = styled('div')(
  getResponsiveCSS('height', 44, 48, '& #submit_button')
);

export const HintContainer = styled('div')({
  position: 'relative',
  top: -5,
});

export const PasswordTooltip = styled(StyledTooltip)({
  left: '105%',
  top: 23,
  paddingLeft: 20,
  '&:after': {
    left: -6,
    top: 'calc(50% - 6px)',
    transform: 'rotate(-45deg)',
  },
});
