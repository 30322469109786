import {styled} from "@mui/material";
import { getIconByActivityType } from "@services/icons";

export const TitleWithTable = styled('div')({
  width: '60%',
  height: '100%',
});

export const TitleWithFilter = styled('div')({
  display: 'flex',
  justifyContent: 'space-between',
});

export const TableWrapper = styled('div')({
  fontSize: 13,
  display: 'flex',
});

export const VerticalLine = styled('div')({
  height: 'calc(100vh - 153px)',
  width: 1,
  marginLeft: 20
},
({ theme }) => ({
    backgroundColor: theme.palette.novotalk.$resourcesTableBorderColor
}));

export const UserActivityLogWrapper = styled('div')({
  display: 'flex',
  justifyContent: 'space-between',
},
({ theme }) => ({
  color: theme.palette.novotalk.$textColorDarkBlue
}));

export const getStyledIcon = (type) => styled(getIconByActivityType(type))({
  fontSize: 18,
},
({ theme }) => ({
  color: theme.palette.novotalk.$questionIconColor
}));
