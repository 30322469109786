/**
*
* QuestionFreeText
*
*/

import React from 'react';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { QUESTION_TYPES } from '../../types';
import DescriptionText from './components/DescriptionText';
import QuestionFreeText from './components/QuestionFreeText';

const DEBOUNCE_DURATION = 500;
class Question extends React.Component { // eslint-disable-line react/prefer-stateless-function
  render() {
    const { type, onChange, mapToField, parentType } = this.props;
    const debouncedOnChange = _.debounce(onChange, DEBOUNCE_DURATION);
    switch (type) {
      case QUESTION_TYPES.FREE_TEXT:
        return mapToField.includes('description') ? (
          <DescriptionText {...this.props} onChange={debouncedOnChange} parentType={parentType} />
        ) : (
          <QuestionFreeText {...this.props} onChange={debouncedOnChange} />
        );
      default: return null;
    }
  }
}

Question.propTypes = {
  type: PropTypes.string,
  onChange: PropTypes.func,
  mapToField: PropTypes.string,
  parentType: PropTypes.string,
};

export default Question;
