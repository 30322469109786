import { Component, createRef } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import _ from 'lodash';
import { withTranslation } from 'react-i18next';
import injectSaga from '@utils/injectSaga';
import injectReducer from '@utils/injectReducer';
import Text, { TEXT_COLOR, TEXT_SIZE } from '@components/Text';
import DSPHandler from '@shared/DSPHandler';
import VideoMonitor from '@shared/VideoMonitor';
// import ProductionDurationCounter from './components/ProductionDurationCounter';
import {
  ERROR_TYPES,
  LOCATION_TYPES,
  PROGRESS_TYPE,
  MONITOR_HANDLER_STATUS,
  STIMULUS_TYPE,
  STIMULUS_DIVISION_TYPE,
  PATTERN_TYPES,
  MONITOR_TYPES,
} from '@shared/Resources/Monitor/types';
import { openPopUp } from '@containers/App/containers/PopUp/actions';
import ChatService from '@services/chatService';
import { stopVideoRecording } from '@shared/VideoMonitor/actions';
import { getSecondsFromFormatTime } from '@utils/timeHelpers';
import { SubHeader } from '@containers/User/containers/CourseRouter/containers/MonitorHandler/components/SubHeader';
import SectionLoading from '@components/SectionLoading';
import { HiddenVideoPlaceholder } from '@shared/VideoMonitor/styles';
import {
  defineErrorsList,
  filterForStaticMonitorErrors,
} from '@containers/User/containers/CourseRouter/containers/MonitorHandler/helpers';
import RepetitionProgress from './components/RepetitionProgress';
import { MonitorFeedbackTooltip } from './components/MonitorFeedbackTooltip/index.tsx';
import StimuliModule from './containers/StimuliModule';
import {
  MirrorWrapper,
  MonitorOverlay,
  MonitorWrapper,
  VideoPauseIcon,
  MirrorWithVideoWrapper,
  StyledVideo,
  LargeMirrorWrapper,
  VideoWrapper,
  VideoOverlay,
  VideoControlButton,
  InhaleInstructionWrapper,
  MainWrapper,
  MonitorButton,
  ProgressWrapper, Wrapper,
} from './styles';
import {
  saveActivityProduction,
  setMonitorProduction,
  setMonitorSubProductionInProgressFlag,
  insertNewRepetitionIntoRepetitionResults,
  setErrorsInProductionResults,
  setMonitorErrors,
  setRepetitionInSequenceIndex,
  setMonitorActiveFlag,
  setExercisePausedFlag,
  setMicrophoneActiveFlag,
  resetExerciseData,
  advanceToNextSequence,
  advanceToNextSyllable,
  disconnectFromMirrorSession,
  resetRepetitionResults,
  setListOneSecondAmountsOfSyllables,
  setIntervalExercise,
} from './actions';
import saga from './saga';
import reducer from './reducer';
import { checkErrorsAndGetNames } from '../../../../helpers/monitorErrors';
import POPUP_TYPES from '../../../../../App/containers/PopUp/types';
import { tryingToExitExerciseAction } from '../Practice/actions';
import { FIELD_FOR_CHECK, TIMER_INTERVAL } from './consts';

export class MonitorHandler extends Component {
  constructor(props) {
    super(props);
    this.stream = null;
    this.eventListener = null;
    this.intervalExercise = null;
    this.videoRef = createRef();
    this.isVideoStarted = createRef();
    this.isVideoStarted.current = false;
    this.state = {
      localArchiveId: null,
      inhaling: false,
      syllableCount: 1,
      hideStartButton: false,
      productionCount: 0,
      repetitionPosition: 0,
      errorRepetitionPosition: [],
    };
  }

  componentDidMount() {
    const { currentExercise, updateExerciseProgressTime } = this.props;
    if (!_.isEmpty(currentExercise)) {
      this.resetExerciseData();
      if (currentExercise.monitor.progress.type === PROGRESS_TYPE.TIMER) {
        updateExerciseProgressTime(getSecondsFromFormatTime(currentExercise.monitor.progress.value));
      }
    }

    this.eventListener = window.addEventListener('message', (e) => {
      if (
        e.data === 'archiveIdWasCreated'
        && !this.props.monitorActive
        && !this.props.microphoneStarted
        && !this.props.exercisePaused
      ) {
        this.setState({ localArchiveId: ChatService.archiveId });
      }
    });

    this.eventListener = window.addEventListener('message', (e) => {
      if (e.data === 'archiveWasStopped') {
        this.setState({ localArchiveId: null });
      }
    });
  }

  checkProps = (field) => {
    const { currentExercise } = this.props;
    if (!currentExercise
      || !currentExercise.monitor
      || !currentExercise.monitor.progress
      || !currentExercise.monitor.progress[field]
    ) {
      return false;
    }
    return true;
  };

  componentDidUpdate(prevProps) {
    const {
      currentExercise,
      dispatchTryingExitExercise,
      exerciseProgressTime,
      updateExerciseProgressTime,
      startNoiseCalibration,
      exercisePaused,
      exerciseInProgress,
      syllableCount,
      dispatchSetMonitorActiveFlag,
      dispatchSetMicrophoneActiveFlag,
      isLesson,
    } = this.props;
    if (currentExercise?.monitor?.pattern?.type === PATTERN_TYPES.ONE_SECOND
      && syllableCount !== this.state.syllableCount) {
      this.setState({ syllableCount });
    }

    if (!_.isEqual(currentExercise, prevProps.currentExercise)) {
      this.resetExerciseData();
    }

    if (this.exitConditionHandler(prevProps)) {
      dispatchTryingExitExercise(false);
    }

    if (this.checkProps(FIELD_FOR_CHECK.VALUE)
      && prevProps.currentExercise.monitor.progress.value !== currentExercise.monitor.progress.value
    ) {
      if (currentExercise.monitor.progress.type === PROGRESS_TYPE.TIMER) {
        updateExerciseProgressTime(getSecondsFromFormatTime(currentExercise.monitor.progress.value));
      }
    }

    if (this.checkProps(FIELD_FOR_CHECK.TYPE)
      && currentExercise.monitor.progress.type === PROGRESS_TYPE.TIMER
      && exerciseProgressTime === 0
      && this.intervalExercise
    ) {
      this.endExercise();
      clearInterval(this.intervalExercise);
      this.intervalExercise = null;
    }

    if (!_.isEqual(startNoiseCalibration, prevProps.startNoiseCalibration)) {
      if (startNoiseCalibration && exerciseInProgress) {
        this.pauseExercise(false);
      }
    }

    if (this.state.localArchiveId && !this.props.monitorActive && this.props.exerciseInProgress && !this.props.exercisePaused && !isLesson) {
      dispatchSetMonitorActiveFlag(true);
      dispatchSetMicrophoneActiveFlag(true);
      this.setState({ isLoading: false });
    }
  }

  componentWillUnmount() {
    if (this.stream) {
      this.stream.getTracks().forEach((track) => {
        track.stop();
      });
    }
    this.stopMediaStream();
    const {
      dispatchDisconnectFromMirrorSession,
    } = this.props;

    const archiveId = this.state.localArchiveId
      ? this.state.localArchiveId
      : ChatService.archiveId;

    dispatchDisconnectFromMirrorSession(archiveId);
    if (this.eventListener) {
      window.removeEventListener(this.eventListener);
    }

    if (this.intervalExercise) {
      clearInterval(this.intervalExercise);
    }
  }

  exitConditionHandler = (prevProps) => {
    const {
      exerciseInProgress,
      popupDisplayed,
      exercisePaused,
      tryingToExit,
    } = this.props;

    const isTryingToExitFirstTime = tryingToExit && tryingToExit !== prevProps.tryingToExit;
    const isPopUpDisplayedFirstTime = popupDisplayed && popupDisplayed !== prevProps.popupDisplayed;
    const isExerciseNotPaused = exerciseInProgress && !exercisePaused;

    return (
      isExerciseNotPaused
      && (isPopUpDisplayedFirstTime || isTryingToExitFirstTime)
    );
  };

  stopMediaStream = () => {
    if (this.stream) {
      this.stream.getTracks().forEach((track) => {
        track.stop();
      });
    }
  };

  onVideoExerciseEnd = () => {
    const { endExercise } = this.props;
    // video ended - stop recording and end exercise
    endExercise();
  };

  setInhaling(value) {
    this.setState({ inhaling: value });
  }

  shouldEndSequence() {
    const {
      repetitionsInSequence,
      repetitionInSequenceIndex,
      stimuli,
      currentSequenceIndex,
      currentExercise,
    } = this.props;
    const division = currentExercise?.monitor?.stimulus?.config?.division;
    const stimuliType = currentExercise?.monitor?.stimulus?.type;
    const stimuliLength = stimuli.length;
    // check if this is the last repitition but not the last sequence
    return ((stimuliType === STIMULUS_TYPE.WORDS
      && (division === STIMULUS_DIVISION_TYPE.UNDIVIDED || (division === STIMULUS_DIVISION_TYPE.DIVIDED
        && repetitionInSequenceIndex < repetitionsInSequence + 1)))
      || stimuliType === STIMULUS_TYPE.SYLLABLES)
      && repetitionInSequenceIndex + 1 === repetitionsInSequence && ((stimuliLength && currentSequenceIndex < stimuliLength - 1));
  }

  isEndOfStimuliWord() {
    const {
      stimuli,
      currentSyllableIndex,
      currentExercise,
    } = this.props;
    const baseStimulus = currentExercise.monitor.pattern.type === PATTERN_TYPES.ONE_SECOND ? [...new Set(stimuli)] : stimuli;
    const notTheLastSyllable = currentSyllableIndex < baseStimulus.length - 1;
    const isLastSyllableInWord = notTheLastSyllable && baseStimulus[currentSyllableIndex].text !== baseStimulus[currentSyllableIndex + 1].text;
    const isLastSyllable = currentSyllableIndex === baseStimulus.length - 1;
    return (isLastSyllableInWord || isLastSyllable);
  }

  isUndividedWords() {
    const {
      currentExercise,
    } = this.props;
    const division = currentExercise?.monitor?.stimulus?.config?.division;
    const stimuliType = currentExercise?.monitor?.stimulus?.type;
    return stimuliType === STIMULUS_TYPE.WORDS && division === STIMULUS_DIVISION_TYPE.UNDIVIDED;
  }

  isLastSequence() {
    const {
      repetitionsInSequence,
      repetitionInSequenceIndex,
      stimuli,
      currentSequenceIndex,
      currentExercise,
    } = this.props;
    const stimuliType = currentExercise?.monitor?.stimulus?.type;
    const stimuliLength = stimuli.length;
    // there is only one sequence or this is the last sequence and last repitition
    return ((stimuliType !== STIMULUS_TYPE.WORDS
      && stimuliType !== STIMULUS_TYPE.SYLLABLES) || (repetitionInSequenceIndex === repetitionsInSequence
        && ((stimuliLength && currentSequenceIndex === stimuliLength - 1) || !stimuliLength)));
  }

  updateExerciseProgression = (type, payload) => {
    const {
      dispatchSetMonitorSubProductionInProgressFlag,
      dispatchInsertNewRepetitionIntoRepetitionResults,
      dispatchSetErrorsInProductionResults,
      dispatchSetMonitorErrors,
      repetitionInSequenceIndex,
      dispatchSetRepetitionInSequenceIndex,
      dispatchSetMonitorProduction,
      dispatchAdvanceToNextSequence,
      dispatchResetRepetitionResults,
      currentExercise,
      currentSequenceIndex,
      repetitionsInSequence,
    } = this.props;
    const { monitor } = currentExercise;
    const { pattern } = monitor;

    switch (type) {
      case MONITOR_HANDLER_STATUS.PRODUCTION_START:
        dispatchSetMonitorSubProductionInProgressFlag(true);
        if (!this.state.inhaling) {
          dispatchInsertNewRepetitionIntoRepetitionResults();
        }
        break;
      case MONITOR_HANDLER_STATUS.MAIN_MONITOR_RESULTS: {
        const { productionErrors } = payload.monitorResult;
        const parsedErrors = this.parsePayloadErrors(productionErrors);
        const hasErrorsToDisplay = _.find(
          parsedErrors,
          (error) => error.location !== 'inhalation' && error.displayed === true,
        );
        this.setState((prevState) => {
          const stateToChange = { productionCount: prevState.productionCount + 1 };
          if (repetitionsInSequence > 1) {
            let { repetitionPosition } = prevState;
            if (repetitionPosition === (repetitionsInSequence - 1)) {
              repetitionPosition = 0;
            } else {
              repetitionPosition += 1;
            }
            if (hasErrorsToDisplay) {
              const errorRepetitionPositions = this.state.errorRepetitionPosition;
              const errorPosition = repetitionPosition === 0 ? repetitionsInSequence - 1 : repetitionPosition - 1;
              // const errorPosition = repetitionPosition - 1;
              errorRepetitionPositions.push(errorPosition);
              stateToChange.errorRepetitionPosition = errorRepetitionPositions;
            } else if (repetitionPosition === 1) {
              stateToChange.errorRepetitionPosition = [];
            }

            stateToChange.repetitionPosition = repetitionPosition;
          }
          return stateToChange;
        });

        const stimuliType = currentExercise?.monitor?.stimulus?.type;

        if (repetitionsInSequence <= 1) {
          dispatchAdvanceToNextSequence();
        } else if (this.state.repetitionPosition >= (repetitionsInSequence - 1)) {
          dispatchAdvanceToNextSequence();
        }

        if (hasErrorsToDisplay) {
          dispatchSetErrorsInProductionResults();
        }
        if (!this.isLastSequence()) {
          // finished repetition in sequence
          dispatchSetRepetitionInSequenceIndex(repetitionInSequenceIndex + 1);
        }
        this.setInhaling(true); // finishes exhaling

        const divisionType = this.props.stimulus?.config?.division;
        const repetition = _.get(currentExercise, 'monitor.progress.repetition');
        if (stimuliType !== STIMULUS_TYPE.SYLLABLES && (stimuliType !== STIMULUS_TYPE.WORDS && divisionType === STIMULUS_DIVISION_TYPE.DIVIDED) && !repetition.on) {
          pattern.type !== PATTERN_TYPES.ONE_SECOND && dispatchAdvanceToNextSequence();
        }
        dispatchSetMonitorSubProductionInProgressFlag(false);
        dispatchSetMonitorErrors(parsedErrors);
        if (pattern.type === PATTERN_TYPES.ONE_SECOND && currentSequenceIndex !== (currentExercise.monitor.progress.value - 1)) {
          if (!this.props.isLesson) {
            const { productionErrors } = payload.monitorResult;
            const parsedErrors = this.parsePayloadErrors(productionErrors);
            this.saveProduction(parsedErrors, payload);
          }
        }
        if (pattern.type === PATTERN_TYPES.ONE_SECOND && currentSequenceIndex === (currentExercise.monitor.progress.value - 1)) {
          if (!this.props.isLesson) {
            const { productionErrors } = payload.monitorResult;

            const parsedErrors = this.parsePayloadErrors(productionErrors);
            this.saveProduction(parsedErrors, payload);
          }
        }
        break;
      }
      case MONITOR_HANDLER_STATUS.SINGLE_MONITOR_PRODUCTION_END: {
        if (!this.props.exerciseInProgress && !this.props.isLesson) {
          this.startExercise();
        }
        const { productionErrors } = payload.monitorResult;
        const { updatedProduction } = payload;
        const parsedErrors = this.parsePayloadErrors(productionErrors);
        if (pattern.type !== PATTERN_TYPES.ONE_SECOND || (pattern.type === PATTERN_TYPES.ONE_SECOND && this.props.isLesson)) {
          this.saveProduction(parsedErrors, payload);
        }
        this.setInhaling(false); // finishes inhaling
        dispatchSetMonitorSubProductionInProgressFlag(false);
        dispatchSetMonitorErrors(parsedErrors);
        dispatchSetMonitorProduction(updatedProduction);
        if (this.isUndividedWords()) {
          dispatchResetRepetitionResults();
        }
        break;
      }
      default:
        break;
    }
  };

  saveProduction = (parsedErrors, { monitorResult }) => {
    const {
      dispatchSaveActivityProduction,
      activityContext,
      stimuli,
      currentSequenceIndex,
      activeLogOpened,
    } = this.props;
    const {
      monitorType,
      patternType,
      speed,
      inhalationDuration,
      dspSignal,
      startTS,
      endTS,
    } = monitorResult;

    const stimulus = stimuli[currentSequenceIndex];
    const productionErrors = parsedErrors;

    let stimuliDisplayed = {};

    if (stimulus) {
      stimuliDisplayed = {
        id: stimulus.stimuliId,
        wordIndex: stimulus.wordIndex,
        syllableIndex: stimulus.syllableIndex,
      };
    } else {
      stimuliDisplayed = {
        id: '12345',
        wordIndex: 0,
        syllableIndex: 0,
      };
    }

    const payload = {
      activityContext,
      monitorType,
      patternType,
      speed,
      inhalationDuration: !inhalationDuration ? 1 : inhalationDuration,
      dspSignal,
      startTS: startTS / 1000,
      endTS: endTS / 1000,
      productionErrors,
      stimuliDisplayed,
      activeLogOpened,
    };
    dispatchSaveActivityProduction(payload);
  };

  parsePayloadErrors(payloadErrors) {
    if (!payloadErrors) return [];
    const volumeControlBeginningErrors = [
      ERROR_TYPES.sharpRiseBeginning,
      ERROR_TYPES.volumeRiseBeginning,
      ERROR_TYPES.shapeBeginning,
    ];
    const volumeControlEndErrors = [
      ERROR_TYPES.shapeEnd,
      ERROR_TYPES.volumeFallEnd,
      ERROR_TYPES.sharpFallEnd,
    ];
    let types = _.clone(this.props.currentExercise.monitor.errors.types);
    const hasVolumeControlBeginning = _.find(types, {
      type: ERROR_TYPES.volumeControlBeginning,
    });
    const hasVolumeControlEnd = _.find(types, {
      type: ERROR_TYPES.volumeControlEnd,
    });
    const volumeControlBeginning = {
      type: ERROR_TYPES.volumeControlBeginning,
      location: 'beginning',
      exists: false,
      displayed: false,
    };
    const volumeControlEnd = {
      type: ERROR_TYPES.volumeControlEnd,
      location: 'end',
      exists: false,
      displayed: false,
    };

    if (hasVolumeControlBeginning) {
      _.remove(types, { type: ERROR_TYPES.volumeControlBeginning });
      types = _.concat(
        types,
        volumeControlBeginningErrors.map((error) => ({ type: error })),
      );
    }
    if (hasVolumeControlEnd) {
      _.remove(types, { type: ERROR_TYPES.volumeControlEnd });
      types = _.concat(
        types,
        volumeControlEndErrors.map((error) => ({ type: error })),
      );
    }

    let parsedErrors = payloadErrors.map((error) => {
      const isErrorChecked = Boolean(_.find(types, { type: error.type }));
      const displayed = Boolean(error.exists && isErrorChecked);
      if (volumeControlBeginningErrors.includes(error.type)) {
        if (displayed) {
          volumeControlBeginning.displayed = true;
          volumeControlBeginning.exists = true;
        }
      }
      if (volumeControlEndErrors.includes(error.type)) {
        if (displayed) {
          volumeControlEnd.displayed = true;
          volumeControlEnd.exists = true;
        }
      }
      return {
        ...error,
        displayed,
      };
    });
    let nonGentleOnset = _.remove(parsedErrors, {
      type: ERROR_TYPES.nonGentleOnset,
    });
    let nonGentleOffset = _.remove(parsedErrors, {
      type: ERROR_TYPES.nonGentleOffset,
    });
    nonGentleOnset = nonGentleOnset.length > 0 ? nonGentleOnset[0] : null;
    nonGentleOffset = nonGentleOffset.length > 0 ? nonGentleOffset[0] : null;
    if (
      nonGentleOnset
      && nonGentleOnset.displayed
      && volumeControlBeginning.displayed
    ) {
      volumeControlBeginning.displayed = false;
    }
    if (
      nonGentleOffset
      && nonGentleOffset.displayed
      && volumeControlEnd.displayed
    ) {
      nonGentleOffset.displayed = false;
    }
    let displayedProductionErrors = _.remove(parsedErrors, {
      location: LOCATION_TYPES.PRODUCTION,
      displayed: true,
    });
    if (displayedProductionErrors.length > 1) {
      displayedProductionErrors = displayedProductionErrors.map((error) => {
        if (
          [
            ERROR_TYPES.tooShortProduction,
            ERROR_TYPES.tooLongProduction,
          ].includes(error.type)
        ) {
          return {
            ...error,
            displayed: false,
          };
        }
        return error;
      });
    }
    parsedErrors = _.concat(
      parsedErrors,
      volumeControlBeginning,
      volumeControlEnd,
      nonGentleOnset,
      nonGentleOffset,
      displayedProductionErrors,
    );
    return _.compact(parsedErrors);
  }

  startExercise = () => {
    const {
      isLesson, startExercise, monitorActive, microphoneStarted, dispatchSetMonitorActiveFlag, dispatchSetMicrophoneActiveFlag,
    } = this.props;
    this.setState({ isLoading: true });
    this.increaseExerciseProgressTime();
    if (!isLesson) {
      startExercise();
    }
    if (isLesson && !monitorActive && !microphoneStarted) {
      dispatchSetMonitorActiveFlag(true);
      dispatchSetMicrophoneActiveFlag(true);
      this.setState({ isLoading: false });
    }
  };

  endExercise = () => {
    const {
      dispatchSetMonitorActiveFlag,
      dispatchSetMicrophoneActiveFlag,
      endExercise,
    } = this.props;

    dispatchSetMonitorActiveFlag(false);
    dispatchSetMicrophoneActiveFlag(false);
    endExercise();
  };

  pauseExercise = (defaultPause = true) => {
    defaultPause && this.setState({ isLoading: true });
    if (this.checkProps(FIELD_FOR_CHECK.TYPE)
      && this.props.currentExercise.monitor.progress.type === PROGRESS_TYPE.TIMER
    ) {
      clearInterval(this.intervalExercise);
    }

    const {
      dispatchSetMonitorActiveFlag,
      dispatchSetMicrophoneActiveFlag,
      dispatchSetExercisePausedFlag,
    } = this.props;

    if (ChatService.archiveId) {
      dispatchSetMonitorActiveFlag(false);
      dispatchSetMicrophoneActiveFlag(false);
      dispatchSetExercisePausedFlag(true);

      this.setState({ localArchiveId: ChatService.archiveId });
    } else {
      dispatchSetMonitorActiveFlag(false);
      dispatchSetMicrophoneActiveFlag(false);
      dispatchSetExercisePausedFlag(true);
    }
    if (this.videoRef.current) this.videoRef.current.pause();
  };

  resumeExercise = () => {
    const {
      dispatchSetMonitorActiveFlag,
      dispatchSetMicrophoneActiveFlag,
      dispatchSetExercisePausedFlag,
      monitorActive,
      microphoneStarted,
    } = this.props;

    this.increaseExerciseProgressTime();

    if (!monitorActive && !microphoneStarted) {
      dispatchSetMonitorActiveFlag(true);
      dispatchSetMicrophoneActiveFlag(true);
      dispatchSetExercisePausedFlag(false);
    }
  };

  resetExerciseData = () => {
    const { dispatchResetExerciseData, currentExercise } = this.props;
    const repetition = _.get(currentExercise, 'monitor.progress.repetition');
    const repetitionsInSequence = repetition && repetition.on ? repetition.count : 1;
    dispatchResetExerciseData(repetitionsInSequence);
  };

  increaseExerciseProgressTime = () => {
    if (this.checkProps(FIELD_FOR_CHECK.TYPE)
      && this.props.currentExercise.monitor.progress.type === PROGRESS_TYPE.TIMER
    ) {
      this.intervalExercise = setInterval(() => {
        this.props.updateExerciseProgressTime(this.props.exerciseProgressTime - 1);
        this.props.dispatchSetIntervalExercise(this.intervalExercise);
      }, TIMER_INTERVAL);
    }
  };

  startWithMediaDevicesPermissions = async () => {
    this.setState({ hideStartButton: true });
    const { dispatchOpenPopUp, exercisePaused } = this.props;
    if (exercisePaused) {
      this.resumeExercise();
      this.setState({ hideStartButton: false });
      return;
    }

    try {
      const videoStream = await navigator.mediaDevices.getUserMedia({
        audio: true,
        video: true,
      });
      this.stream = videoStream;
      this.startExercise();
      this.setState({ hideStartButton: false });
    } catch (err) {
      dispatchOpenPopUp(POPUP_TYPES.MEDIA_DEVICES_PERMISSIONS);
    }
  };

  handleVideoControlButtonClick = () => {
    const {
      startExercise,
      exercisePaused,
      exerciseInProgress,
      dispatchSetExercisePausedFlag,
    } = this.props;
    if (exerciseInProgress) {
      if (exercisePaused) {
        // resume video - recording is not paused
        dispatchSetExercisePausedFlag(false);
        this.videoRef.current.play();
      } else {
        // pause video - recording is not paused
        dispatchSetExercisePausedFlag(true);
        this.videoRef.current.pause();
      }
    } else {
      // start video + exercise recording
      startExercise();
      this.videoRef.current.play();
    }
  };

  isProductionBySyllableCount = (pattern, stimulus) => {
    // relevant only for one second monitor with divided sentences or words
    if (pattern.type === PATTERN_TYPES.ONE_SECOND) {
      return (stimulus?.type === STIMULUS_TYPE.SENTENCES && stimulus?.config?.division === STIMULUS_DIVISION_TYPE.DIVIDED_TO_WORDS_SYLLABLES)
        || (stimulus?.type === STIMULUS_TYPE.WORDS);
    }
    return false;
  };

  renderMonitors() {
    const {
      microphoneStarted,
      currentExercise,
      activityContext,
      stimuli,
      repetitionsInSequence,
    } = this.props;
    const { stimulus } = currentExercise?.monitor;
    const { monitor } = currentExercise;
    const { pattern } = monitor;
    const listAmountsOfSyllables = [];
    if (stimuli && stimuli.length) {
      const division = currentExercise?.monitor?.stimulus?.config?.division;
      const isOneSecond = pattern.type === PATTERN_TYPES.ONE_SECOND;
      const isWords = stimulus.type === STIMULUS_TYPE.WORDS;
      const isSentences = stimulus.type === STIMULUS_TYPE.SENTENCES;
      const isDividedSentences = isSentences && division !== STIMULUS_DIVISION_TYPE.UNDIVIDED;
      if (isOneSecond && (isWords || isDividedSentences)) {
        const amountOfSeq = monitor.progress.type === PROGRESS_TYPE.TIMER ? stimuli.length : monitor.progress.value;
        const amountToCount = amountOfSeq * repetitionsInSequence;
        for (let i = 0; i < amountToCount; i += repetitionsInSequence) {
          if (stimuli[i]) {
            if (stimulus.type === STIMULUS_TYPE.WORDS) {
              listAmountsOfSyllables.push(stimuli[i].length);
            } else {
              const currentSeq = stimuli[i].find((stimuli) => stimuli.current);
              const wordsArray = currentSeq.text.trim().split(/\s+/);
              listAmountsOfSyllables.push(wordsArray.length);
            }
          }
        }
      }
    }

    const props = {
      monitor: monitor.monitor,
      pattern,
      updateMonitorResults: this.updateExerciseProgression,
      microphoneStarted,
      activityContext,
      listAmountsOfSyllables,
      amountOfSeq: monitor.progress.value,
    };

    if (this.isProductionBySyllableCount(pattern, stimulus)) {
      if (this.state.syllableCount < 1) return null;
      props.pattern.productionDuration = this.state.syllableCount;
    }
    const withMirror = monitor.monitor.mirror.on;
    if (monitor.type === 'NONE') return null;
    return (
      <Wrapper>
        {this.renderStaticMonitorsFeedback()}
        <MonitorWrapper withMirror={withMirror}>
          <DSPHandler {...props} />
          {this.renderMonitorsOverlay()}
          {this.renderMonitorsFeedbacks(listAmountsOfSyllables)}
          {this.renderInhaleInstruction()}
        </MonitorWrapper>
      </Wrapper>
    );
  }

  renderMonitorsOverlay() {
    const {
      exerciseInProgress,
      monitorActive,
      isLesson,
      exercisePaused,
    } = this.props;
    const { isLoading } = this.state;

    let handleMonitorButton = exerciseInProgress ? this.resumeExercise : this.startWithMediaDevicesPermissions;
    if (isLesson) handleMonitorButton = monitorActive ? this.pauseExercise : this.startWithMediaDevicesPermissions;

    return (
      <MonitorOverlay monitorActive={monitorActive}>
        {isLoading ? <SectionLoading />
          : (
            <MonitorButton
              isHide={this.state.hideStartButton || (this.props.exercisePaused && this.props.localArchiveId)}
              onClick={handleMonitorButton}
            >
              {this.props.t(`Actions.Instance.${exercisePaused ? 'continue' : 'start'}`)}
            </MonitorButton>
          )}

      </MonitorOverlay>
    );
  }

  // This function renders some of the toast errors that should be positioned absolutely according to the screen
  renderStaticMonitorsFeedback() {
    const {
      monitorErrors,
      exerciseInProgress,
      productionDuration,
      currentExercise: { monitor },
    } = this.props;
    if (!exerciseInProgress) return null;
    if (this.state.inhaling) {
      const currentMonitorType = monitor?.pattern?.type;
      const errorsToRender = defineErrorsList(currentMonitorType, monitorErrors);

      return (
        <>
          {errorsToRender.map((error) => (
            <MonitorFeedbackTooltip
              location={error.location}
              error={error.name}
              key={`${error.location}-${error.type}`}
              duration={productionDuration}

            />
          ))}
        </>
      );
    }
  }

  renderMonitorsFeedbacks(listAmountsOfSyllables) {
    const {
      monitorErrors,
      exerciseInProgress,
      production,
      productionDuration,
      currentExercise: { monitor },
      syllableCount,
      currentSequenceIndex,
    } = this.props;
    const currentMonitorType = monitor?.pattern?.type;

    if (!exerciseInProgress) return null;
    if (this.state.inhaling) {
      // display production errors
      let displayedErrors = monitorErrors.filter(
        (error) => error.location !== 'inhalation' && error.displayed,
      );

      // some cases where toast error should be positioned as absolute were moved to the separate function renderStaticMonitorsFeedback()
      // this filter function is used to find them and remove from array
      displayedErrors = filterForStaticMonitorErrors(currentMonitorType, displayedErrors);

      displayedErrors = checkErrorsAndGetNames(displayedErrors);

      const errorsToRender = defineErrorsList(currentMonitorType, monitorErrors);
      if (!errorsToRender.length && !displayedErrors.length) {
        return (
          <MonitorFeedbackTooltip
            location={LOCATION_TYPES.RIGHT_SIDE_MIDDLE}
            error="great"
          />
        );
      }

      return (
        <>
          {displayedErrors.map((error) => (
            <MonitorFeedbackTooltip
              location={error.location}
              error={error.name}
              key={`${error.location}-${error.type}`}
              duration={listAmountsOfSyllables?.[currentSequenceIndex] ?? productionDuration}
            />
          ))}
        </>
      );
    }
    // display inhalation errors
    if (production === 0) return null;
    let displayedErrors = monitorErrors.filter(
      (error) => error.location === 'inhalation' && error.displayed,
    );
    displayedErrors = checkErrorsAndGetNames(displayedErrors);
    if (displayedErrors.length === 0) {
      return (
        <MonitorFeedbackTooltip
          location="inhalation"
          error="great"
        />
      );
    }
    return (
      <>
        {displayedErrors.map((error) => (
          <MonitorFeedbackTooltip
            location={error.location}
            error={error.name}
            key={`${error.location}-${error.type}`}
          />
        ))}
      </>
    );
  }

  renderInhaleInstruction = () => (this.state.inhaling ? (
    <InhaleInstructionWrapper>
      <Text
        textColor={TEXT_COLOR.DARK}
        size={TEXT_SIZE.T2}
        textTransform="uppercase"
        weight="bold"
      >
        {this.props.t('Common.UI.inhale')}
      </Text>
    </InhaleInstructionWrapper>
  ) : null);

  handleClickInLesson = () => {
    const {
      startExercise,
      exercisePaused,
      dispatchSetExercisePausedFlag,
    } = this.props;
    // Start video first time
    if (!this.isVideoStarted.current) {
      startExercise();
      this.videoRef.current.play();
      this.isVideoStarted.current = true;
    }
    // Continue play video after pause
    if (exercisePaused) {
      dispatchSetExercisePausedFlag(false);
      this.videoRef.current.play();
    }
  };

  renderVideoControlButton = () => {
    const {
      exercisePaused,
      exerciseInProgress,
      isLesson,
    } = this.props;
    if (isLesson) {
      if (exerciseInProgress
          && this.isVideoStarted.current
          && !exercisePaused
      ) {
        return (
          <VideoControlButton
            onlyIcon
            onClick={this.handleVideoControlButtonClick}
          >
            <VideoPauseIcon />
          </VideoControlButton>
        );
      }
      return (
        <VideoControlButton onClick={this.handleClickInLesson}>
          {this.props.t(`Actions.Instance.${exercisePaused ? 'continue' : 'start'}`)}
        </VideoControlButton>
      );
    }
    if (exerciseInProgress && !exercisePaused) {
      return (
        <VideoControlButton
          onlyIcon
          onClick={this.handleVideoControlButtonClick}
        >
          <VideoPauseIcon />
        </VideoControlButton>
      );
    }
    return (
      <VideoControlButton onClick={this.handleVideoControlButtonClick}>
        {this.props.t(`Actions.Instance.${exercisePaused ? 'continue' : 'start'}`)}
      </VideoControlButton>
    );
  };

  renderMirror = () => {
    const {
      currentExercise: {
        monitor: { stimulus, monitor },
      },
      exercisePaused,
      exerciseInProgress,
      isLesson,
    } = this.props;
    const displayMirror = monitor.mirror.on;
    const withVideo = monitor.video.on;
    const videoUrl = withVideo ? monitor.video.url : '';

    const props = {
      displayMirror,
      isLesson,
    };

    if (!props.displayMirror && !props.isLesson && !withVideo) {
      return (
        <HiddenVideoPlaceholder shrinkedHeight id="publisher" />
      );
    }

    if (withVideo) {
      return (
        <MirrorWithVideoWrapper>
          <VideoWrapper>
            <StyledVideo
              src={videoUrl}
              onEnded={this.onVideoExerciseEnd}
              ref={this.videoRef}
            />
            <VideoOverlay
              exercisePaused={exercisePaused}
              exerciseInProgress={exerciseInProgress}
            >
              {this.renderVideoControlButton()}
            </VideoOverlay>
          </VideoWrapper>
          <LargeMirrorWrapper>
            <VideoMonitor {...props} />
          </LargeMirrorWrapper>
        </MirrorWithVideoWrapper>
      );
    }
    return (
      <MirrorWrapper displayMirror={displayMirror}>
        <VideoMonitor {...props} />
      </MirrorWrapper>
    );
  };

  renderStimuli() {
    const {
      currentSequenceIndex, currentExercise, dispatchSetListOneSecondAmountsOfSyllables,
    } = this.props;
    const { stimulus, progress } = currentExercise.monitor;
    const stimuliModuleProps = {
      currentSequence: currentSequenceIndex,
      ...stimulus,
      progress,
      currentExercise,
      setListOneSecondAmountsOfSyllables: dispatchSetListOneSecondAmountsOfSyllables,
      productionCount: this.state.productionCount,
    };
    return <StimuliModule {...stimuliModuleProps} />;
  }

  renderProgress() {
    const {
      subProductionInProgress,
      repetitionsResults,
      repetitionsInSequence,
    } = this.props;
    const {
      repetitionPosition, inhaling, errorRepetitionPosition, productionCount,
    } = this.state;
    if (!repetitionsInSequence || repetitionsInSequence <= 1) {
      return null;
    }
    // TODO: add handling for exercises without inhale monitor (controlled speech and farther)
    return (
      <ProgressWrapper>
        <RepetitionProgress
          productions={repetitionsResults}
          repetitions={repetitionsInSequence}
          repetitionPosition={repetitionPosition}
          disableCurrentIndicator={inhaling || !subProductionInProgress}
          errorRepetitionPosition={errorRepetitionPosition}
          productionCount={productionCount}
        />
      </ProgressWrapper>
    );
  }

  render() {
    const {
      currentExercise,
      exerciseInProgress,
      currentSequenceIndex,
    } = this.props;
    const {
      monitor: {
        monitor: { type: monitorType },
      },
    } = currentExercise;
    if (
      currentSequenceIndex
      === _.get(currentExercise, 'monitor.progress.value')
      && monitorType !== MONITOR_TYPES.NONE
      && exerciseInProgress
    ) {
      this.endExercise();
    }
    const { stimulus } = currentExercise.monitor;

    return (
      <MainWrapper>
        <SubHeader
          exerciseProgressTime={this.props.exerciseProgressTime}
          currentSequenceIndex={currentSequenceIndex}
          currentExercise={currentExercise}
          pauseExerciseHandler={this.pauseExercise}
        />
        {this.renderMirror()}
        {monitorType !== MONITOR_TYPES.NONE && ((stimulus?.type && (stimulus.type === STIMULUS_TYPE.PICTURES || stimulus.type === STIMULUS_TYPE.SPEECH_TOPICS))
          ? (
            <>
              {this.renderStimuli()}
              {this.renderMonitors()}
            </>
          )
          : (
            <>
              {this.renderMonitors()}
              {this.renderStimuli()}
            </>
          ))}
        {this.renderProgress()}
        <HiddenVideoPlaceholder id="screenRecordPublisher" />
      </MainWrapper>
    );
  }
}

MonitorHandler.propTypes = {
  currentExercise: PropTypes.object,
  exerciseInProgress: PropTypes.bool,
  popupDisplayed: PropTypes.bool,
  activityContext: PropTypes.object,
  dispatchSaveActivityProduction: PropTypes.func,
  stimuli: PropTypes.array,
  endExercise: PropTypes.func,
  startExercise: PropTypes.func,
  dispatchSetMonitorProduction: PropTypes.func,
  dispatchSetMonitorSubProductionInProgressFlag: PropTypes.func,
  dispatchInsertNewRepetitionIntoRepetitionResults: PropTypes.func,
  dispatchSetErrorsInProductionResults: PropTypes.func,
  dispatchSetMonitorErrors: PropTypes.func,
  dispatchSetRepetitionInSequenceIndex: PropTypes.func,
  dispatchSetMonitorActiveFlag: PropTypes.func,
  dispatchSetMicrophoneActiveFlag: PropTypes.func,
  dispatchSetExercisePausedFlag: PropTypes.func,
  dispatchResetExerciseData: PropTypes.func,
  dispatchAdvanceToNextSequence: PropTypes.func,
  dispatchOpenPopUp: PropTypes.func,
  dispatchDisconnectFromMirrorSession: PropTypes.func,
  production: PropTypes.number,
  monitorActive: PropTypes.bool,
  microphoneStarted: PropTypes.bool,
  exercisePaused: PropTypes.bool,
  subProductionInProgress: PropTypes.bool,
  monitorErrors: PropTypes.array,
  currentSequenceIndex: PropTypes.number,
  repetitionInSequenceIndex: PropTypes.number,
  repetitionsInSequence: PropTypes.number,
  repetitionsResults: PropTypes.array,
  isLesson: PropTypes.bool,
  productionDuration: PropTypes.number,
  connectedToMirror: PropTypes.bool,
  tryingToExit: PropTypes.bool,
  currentSyllableIndex: PropTypes.number,
  syllableCount: PropTypes.number,
};

export const mapStateToProps = (state) => {
  const popUpState = state.getIn(['popUp']).toJS();
  const popupDisplayed = popUpState.showing;
  const videoMirrorState = state.getIn(['videoMirror']);
  const connectedToMirror = _.get(videoMirrorState, 'connectedToMirror', false);
  const stimuliModule = state.getIn(['stimuliModule']);
  const { stimuli, syllableCount } = stimuliModule ? stimuliModule.toJS() : {};
  const { activityContext, activeLogOpened } = state.getIn(['course']).toJS();
  const currentPractice = state.getIn(['currentPractice']).toJS();
  const { tryingToExit } = currentPractice;
  const {
    production,
    monitorActive,
    microphoneStarted,
    exercisePaused,
    subProductionInProgress,
    monitorErrors,
    currentSequenceIndex,
    repetitionInSequenceIndex,
    repetitionsInSequence,
    repetitionsResults,
    startNoiseCalibration,
    currentSyllableIndex,
    listOneSecondAmountsOfSyllables,
  } = state.getIn(['monitorHandler']).toJS();
  return {
    popupDisplayed,
    activityContext,
    stimuli,
    syllableCount,
    production,
    monitorActive,
    microphoneStarted,
    exercisePaused,
    subProductionInProgress,
    monitorErrors,
    currentSequenceIndex,
    repetitionInSequenceIndex,
    repetitionsInSequence,
    repetitionsResults,
    connectedToMirror,
    tryingToExit,
    startNoiseCalibration,
    currentSyllableIndex,
    listOneSecondAmountsOfSyllables,
    activeLogOpened,
  };
};

export function mapDispatchToProps(dispatch) {
  return {
    dispatchSaveActivityProduction: (payload) => dispatch(saveActivityProduction(payload)),
    dispatchSetMonitorProduction: (production) => dispatch(setMonitorProduction(production)),
    dispatchSetMonitorSubProductionInProgressFlag: (value) => dispatch(setMonitorSubProductionInProgressFlag(value)),
    dispatchInsertNewRepetitionIntoRepetitionResults: () => dispatch(insertNewRepetitionIntoRepetitionResults()),
    dispatchSetErrorsInProductionResults: () => dispatch(setErrorsInProductionResults()),
    dispatchSetMonitorErrors: (errors) => dispatch(setMonitorErrors(errors)),
    dispatchSetRepetitionInSequenceIndex: (index) => dispatch(setRepetitionInSequenceIndex(index)),
    dispatchSetMonitorActiveFlag: (value) => dispatch(setMonitorActiveFlag(value)),
    dispatchSetMicrophoneActiveFlag: (value) => dispatch(setMicrophoneActiveFlag(value)),
    dispatchSetExercisePausedFlag: (value) => dispatch(setExercisePausedFlag(value)),
    dispatchResetExerciseData: (repetitionsInSequence) => dispatch(resetExerciseData(repetitionsInSequence)),
    dispatchAdvanceToNextSequence: () => dispatch(advanceToNextSequence()),
    dispatchAdvanceToNextSyllable: (currentSyllableIndex) => dispatch(advanceToNextSyllable(currentSyllableIndex)),
    dispatchResetRepetitionResults: () => dispatch(resetRepetitionResults()),
    dispatchOpenPopUp: (type) => dispatch(openPopUp(type)),
    dispatchDisconnectFromMirrorSession: (archiveId) => dispatch(disconnectFromMirrorSession(archiveId)),
    dispatchStopVideoRecording: () => dispatch(stopVideoRecording()),
    dispatchTryingExitExercise: (tryingToExit) => dispatch(tryingToExitExerciseAction(tryingToExit)),
    dispatchSetListOneSecondAmountsOfSyllables: (list) => dispatch(setListOneSecondAmountsOfSyllables(list)),
    dispatchSetIntervalExercise: (interval) => dispatch(setIntervalExercise(interval)),
  };
}

const withConnect = connect(mapStateToProps, mapDispatchToProps);
const withReducer = injectReducer({ key: 'monitorHandler', reducer });
const withSaga = injectSaga({ key: 'monitorHandler', saga });

export default compose(
  withSaga,
  withReducer,
  withConnect,
  withTranslation(),
)(MonitorHandler);
