import {styled} from "@mui/material";
import TextArea from '@components/TextArea';

export const Wrapper = styled('div')(
  {
    minWidth: '800px',
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    flexShrink: 0,
    position: 'relative',
  },
  ({ theme }) => ({
    margin: theme.RTL ? '10px 30px 30px 0' : '10px 0 30px 30px',
  })
);

export const StyledTextArea = styled(TextArea)({
  minWidth: 314,
  maxWidth: 314,
  height: '8em',
  minHeight: '8em',
  resize: 'vertical',
  marginTop: '2px',
});
