import {styled} from "@mui/material";

export const Wrapper = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  overflow: 'hidden',
});

export const MainPane = styled('div')(
  {},
  ({ theme, withNavBar }) =>
    withNavBar && {
      height: `calc(100vh - ${theme.palette.novotalk.navigationBarHeight}px)`,
      overflow: 'auto',
    }
);
