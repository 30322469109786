import {PATTERN_TYPES_TITLES} from "@shared/Resources/Monitor/types";
import i18n from "i18next";

export const keysValuesLabels = (types, labels) => {
    return Object.keys(types).map((value) => ({ value, label: labels[value] }));
}

export const translateMonitorTypes = () => {
    const translatedMonitorTypes = {}
    Object.keys(PATTERN_TYPES_TITLES).forEach((k) => {
        translatedMonitorTypes[k] = i18n.t(`Monitors.List.${k}`)
    })
    return translatedMonitorTypes
}