import { TableColumn } from '../TableUserLog';
import { UserLog } from '../types';
import { CellUserLog, TableRowUserLog } from '../styles';

type RowUserLogProps = {
  item: UserLog;
  spread: string;
  handleRowSelect: (item: UserLog) => void;
  config: TableColumn[];
  current: boolean;
};

export const RowUserLog = ({ item, spread, handleRowSelect, config, current }: RowUserLogProps) => {
  const rowProps = {
    spread,
    onClick: () => handleRowSelect(item),
    key: item.id,
    isHighlighted: false,
  };
  return (
    <TableRowUserLog className="row" {...rowProps}>
      {config.map(({ getDisplay }, colIndex) => (
        <CellUserLog
          current={current}
          className="cell"
          key={`Row${item.id}Column${colIndex}`}
          isLast={colIndex === config.length - 1}
        >
          {getDisplay && getDisplay(item, current)}
        </CellUserLog>
      ))}
    </TableRowUserLog>
  );
};
