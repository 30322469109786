import {styled} from "@mui/material";
import { getIconsByType, iconTypes } from '@services/icons';
import SharedInput from '@components/Input';

export const Title = styled('div')({
  gridArea: 'SubTitle',
  marginTop: 27,
  marginBottom: 8,
});

export const AnswersContainer = styled('div')({
  gridArea: 'Answers',
  marginBottom: 5,
});

export const AnswerContainer = styled('div')({
  marginBottom: 10,
  position: 'relative',
});

export const Input = styled(SharedInput)({
  height: 44,
  paddingRight: 44,
});

export const RemoveButton = styled(getIconsByType(iconTypes.CLOSE_CIRCLE))(
  {
    position: 'absolute',
    top: 10,
    right: 10,
    cursor: 'pointer',
    fontSize: 24,
  },
  ({ theme }) => ({
    color: theme.palette.novotalk.$textColorLightGray,
  })
);
