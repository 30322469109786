import {styled} from "@mui/material";
import BaseButton from '@components/Button';

export const Container = styled('div')({
  width: 750,
  margin: '0 auto',
});
export const Main = styled('div')(
  {
    display: 'grid',
    gridTemplateColumns: '250px 250px 50px 250px',
  },
  ({ roleCentric }) => ({
    gridTemplateAreas: roleCentric
      ? `
  'HEADER HEADER HEADER HEADER'
  'ROLES DYNAMIC_CONTENT CONTROLS USERS'
  `
      : `
  'HEADER HEADER HEADER HEADER'
  'USERS DYNAMIC_CONTENT CONTROLS ROLES'
  `,
  })
);

export const Roles = styled('div')({ gridArea: 'ROLES' });
export const Users = styled('div')({ gridArea: 'USERS' });
export const DynamicItems = styled('div')({ gridArea: 'DYNAMIC_CONTENT' });
export const Controls = styled('div')({ gridArea: 'CONTROLS', paddingTop: 136 });
export const ModeSelection = styled('div')({
  gridArea: 'HEADER',
  textAlign: 'center',
});
export const ToggleButton = styled(BaseButton)({
  width: 200,
  borderBottomLeftRadius: 0,
  borderBottomRightRadius: 0,
});
export const Button = styled(BaseButton)({
  marginTop: 15,
  fontSize: 10,
  marginLeft: -13,
});
