/**
 *
 * AnswersEditor
 *
 */

import React from 'react';
import PropTypes from 'prop-types';
import Text, { TEXT_SIZE, TEXT_COLOR, TEXT_WEIGHT } from '@components/Text';
import TextButton from '@components/TextButton';
import {
  Title,
  AnswersContainer,
  AnswerContainer,
  Input,
  RemoveButton,
} from './styles';
import { nanoid } from 'nanoid';
import { compose } from 'redux';
import { withTranslation } from 'react-i18next';

class AnswersEditor extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      answers: props.answers,
    };
  }

  addAnswer = () => {
    const { answers } = this.state;
    answers.push({
      id: nanoid(),
      value: '',
    });
    this.setState({ answers });
    this.props.onChange(answers);
  };

  formatNumber = (number) => {
    const numberStr = number.toString();
    return numberStr.length === 1 ? `0${numberStr}` : numberStr;
  };

  updateAnswerAtindex = (index) => (event) => {
    const { value } = event.target;
    const { answers } = this.state;
    answers[index].value = value;
    this.setState({ answers });
    this.props.onChange(answers);
  };

  removeAnswerAtIndex = (index) => () => {
    const { answers } = this.state;
    answers.splice(index, 1);
    this.setState({ answers });
    this.props.onChange(answers);
  };

  renderAnswers = () => {
    return this.state.answers.map((answer, index) => (
      <AnswerContainer key={answer.id}>
        <Input
          value={answer.value}
          onChange={this.updateAnswerAtindex(index)}
          placeholder={this.props.t('UI.answer', {
            number: this.formatNumber(index + 1),
          })}
        />
        <RemoveButton onClick={this.removeAnswerAtIndex(index)} />
      </AnswerContainer>
    ));
  };

  render() {
    return (
      <>
        <Title>
          <Text
            size={TEXT_SIZE.T4}
            textColor={TEXT_COLOR.DARK_BLUE}
            weight={TEXT_WEIGHT.BOLD}
            textTransform='uppercase'
          >
            {this.props.t('Common.UI.answers')}
          </Text>
        </Title>
        <AnswersContainer>
          {this.renderAnswers()}
          <TextButton onClick={this.addAnswer}>
            {this.props.t('Actions.Multiple.addAnswer')}
          </TextButton>
        </AnswersContainer>
      </>
    );
  }
}

AnswersEditor.propTypes = {
  answers: PropTypes.array,
  onChange: PropTypes.func,
};

export default compose(
    withTranslation()
)(AnswersEditor);
