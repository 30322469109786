import {
  put, takeLatest, all, fork, select, join,
} from 'redux-saga/effects';
import {
  putUsersLoadingStatus,
  setUsers,
  showUsersError,
  USERS_ACTIONS,
  putCountUsers,
  fetchUsers,
} from './actions';
import { getUsers, getCountUsers, setEntitiesAsCompletedRequest } from './services';
import { fetchUserPersonalProgram, putUserPageLoadingStatus } from '../UserPage/actions';
import { ROLES } from './consts';

export function* callFetchAllUsers({ payload: { roles, isUserPage } }) {
  try {
    yield put(putUsersLoadingStatus(true));

    const offset = yield select((state) => state.get('usersList').get('offset'));
    const order = yield select((state) => state.get('usersList').get('order'));

    const sortBy = yield select((state) => state.get('usersList').get('sortBy'));
    const res = yield fork(getUsers, roles, offset, isUserPage, sortBy, order);
    const { allUsers, errors } = yield join(res);

    if (errors) {
      yield put(showUsersError());
    } else {
      yield put(setUsers(allUsers));
    }
  } catch {
    yield put(showUsersError());
  } finally {
    yield put(putUsersLoadingStatus(false));
  }
}

export function* callFetchCountUsers({ roles }) {
  try {
    const res = yield fork(getCountUsers, roles);
    const { allUsersCount, errors } = yield join(res);

    if (errors) {
      yield put(showUsersError());
    } else {
      yield put(putCountUsers(allUsersCount));
    }
  } catch {
    yield put(showUsersError());
  }
}

export function* callSetEntitiesAsCompleted({ entitiesLists, userId }) {
  try {
    yield put(putUserPageLoadingStatus(true));
    const res = yield fork(setEntitiesAsCompletedRequest, entitiesLists);
    const { errors } = yield join(res);

    if (errors) {
      yield put(showUsersError());
    } else {
      yield put(fetchUserPersonalProgram(userId));
    }
  } catch {
    yield put(showUsersError());
  }
}

export function* callSetOrderUsers() {
  yield put(fetchUsers([ROLES.USER.TITLE], true));
}

function* watchCallFetchAllUsers() {
  yield takeLatest(USERS_ACTIONS.FETCH_ALL_USERS, callFetchAllUsers);
}

function* watchCallFetchCountUsers() {
  yield takeLatest(USERS_ACTIONS.FETCH_COUNT_USERS, callFetchCountUsers);
}
function* watchCallSetEntitiesAsCompleted() {
  yield takeLatest(USERS_ACTIONS.SET_ENTITIES_COMPLETED, callSetEntitiesAsCompleted);
}

function* watchCallSetOrderUsers() {
  yield takeLatest(USERS_ACTIONS.SET_ORDER, callSetOrderUsers);
}

export default function* usersListSaga() {
  yield all([
    fork(watchCallFetchAllUsers),
    fork(watchCallFetchCountUsers),
    fork(watchCallSetEntitiesAsCompleted),
    fork(watchCallSetOrderUsers),
  ]);
}
