import i18n, { Resource } from 'i18next';
import { initReactI18next } from 'react-i18next';
import en from './en.json';
import he from './he.json';

export enum LANGUAGE_NOVOTALK {
ENGLISH='EN',
HEBREW='HE'
}

const resources: Resource = {
  en: {
    translation: en,
  },
  he: {
    translation: he,
  },
};

i18n.use(initReactI18next).init({
  resources,
  supportedLngs: ['en', 'he'],
  fallbackLng: 'en',
  keySeparator: '.',
  interpolation: {
    escapeValue: false,
  },
});

export default i18n;
