import {styled} from "@mui/material";
import CustomTextInput from '@components/CustomTextInput';
import { getResponsiveCSS } from '@utils/cssHelpers';
import {
  getIconByResourceType,
  getIconsByType,
  iconTypes,
} from '@services/icons';
import { RESOURCES } from '@shared/Resources/types';

export const Container = styled('div')(
  {
    display: 'grid',
    gridTemplateAreas: `
  'Index Header Header'
  'Index Items Overview'
  'Index . .'
  `,
    gridTemplateColumns:
      'minmax(200px, 17.3%) minmax(560px, 44.6%) minmax(500px, 38.1%)',
    gridTemplateRows: '120px auto 20px',
  },
  ({ theme }) => ({
    height: `calc(100vh - ${theme.palette.novotalk.navigationBarHeight}px)`,
  })
);

export const LoaderWrapper = styled('div')({
  alignSelf: 'stretch',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
});

export const ResourcesIndex = styled('div')(
  {
    gridArea: 'Index',
    paddingTop: 52,
  },
  ({ theme }) => ({
    backgroundColor: theme.palette.novotalk.$resourcesListBgColor,
    borderRight: `1px solid ${theme.palette.novotalk.$resourcesListBorderColor}`,
  })
);

export const ResourcesItems = styled('div')(
  {
    gridArea: 'Items',
    paddingBottom: 10,
    minWidth: 560,
  },
  getResponsiveCSS('paddingLeft', 19, 44),
  getResponsiveCSS('paddingRight', 16, 22)
);

export const ResourcesOverviewContainer = styled('div')(
  {
    gridArea: 'Overview',
    overflowY: 'auto',
    minWidth: 500,
    paddingBottom: 5,
  },
  getResponsiveCSS('paddingLeft', 17, 22),
  getResponsiveCSS('paddingRight', 20, 40),
  ({ theme }) => ({
    borderLeft: `solid 1px ${theme.palette.novotalk.$resourcesTableBorderColor}`,
  })
);

export const IndexHeader = styled('div')({
  padding: '0 20px',
  marginBottom: 40,
});

export const IndexTitle = styled('div')({
  marginBottom: 14,
});

export const StyledCustomTextInput = styled(CustomTextInput)(
  {
    minHeight: 38,
    maxHeight: 38,
    borderRadius: 5,
    outline: 'none',
  },
  ({ theme }) => ({
    borderColor: theme.palette.novotalk.$backgroundColor,
  })
);

export const StyledSearchIcon = styled(getIconsByType(iconTypes.SEARCH))(
  {
    fontSize: 18,
    marginRight: 10,
  },
  ({ theme }) => ({
    color: theme.palette.novotalk.$textColorLightGray,
  })
);

export const IndexList = styled('div')({});

export const IndexItem = styled('div')(
  {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '0 24px',
    cursor: 'pointer',
  },
  getResponsiveCSS('height', 46, 50),
  ({ theme, isSelected }) => ({
    backgroundColor: isSelected
      ? theme.palette.novotalk.$resourcesListItemSelected
      : theme.palette.novotalk.$resourcesListBgColor,
  })
);

export const getStyledIcon = (resourceType) =>
  styled(getIconByResourceType(resourceType))({
    fontSize: resourceType === RESOURCES.STIMULUS ? 16 : 20,
    width: resourceType === RESOURCES.STIMULUS ? 16 : 20,
    marginLeft: resourceType === RESOURCES.STIMULUS ? 2 : 0,
    marginRight: resourceType === RESOURCES.STIMULUS ? 19 : 17,
    marginTop: -3,
  });
