import { styled } from '@mui/material';

type StyledTableHeaderProps ={
    spread:string
}

export const TableHeaderUsers = styled('div', {
  shouldForwardProp: (prop) => prop !== 'spread',
})<StyledTableHeaderProps>(({ spread, theme }) => ({
  display: 'grid',
  alignItems: 'center',
  padding: '0 16px',
  height: 43,
  gridTemplateColumns: spread,
  borderBottom: `solid 1px ${theme.palette.novotalk.$resourcesTableBorderColor}`,
  backgroundColor: theme.palette.novotalk.$backgroundColor,
  color: theme.palette.novotalk.$textColorDisabled,
}));

type StyledTableCellProps = {
    noPadding?:boolean, titlesUppercase?:boolean, isLast?:boolean, pointer?:boolean
}

export const CellUsers = styled('div', {
  shouldForwardProp: (prop) => prop !== 'noPadding' && prop !== 'titlesUppercase' && prop !== 'isLast' && prop !== 'pointer',
})<StyledTableCellProps>(({
  noPadding, titlesUppercase, isLast, pointer,
}) => ({
  overflow: 'hidden',
  display: 'flex',
  cursor: pointer ? 'pointer' : 'auto',
  '& span': {
    whiteSpace: 'pre',
    textOverflow: 'ellipsis',
    paddingRight: noPadding ? 0 : 10,
    textTransform: titlesUppercase && 'uppercase',
    justifyContent: isLast && 'end',
    '> span': {
      marginRight: isLast && 32,
    },
  },
}));

export const HeaderColumnUsers = styled(CellUsers)({
  display: 'flex',
  alignItems: 'baseline',
});

type TableRowUsersProps={
    spread: string
    isHighlighted:boolean
}

export const TableRowUsers = styled('div', {
  shouldForwardProp: (prop) => prop !== 'spread' && prop !== 'isHighlighted',
})<TableRowUsersProps>(({
  spread, isHighlighted, theme,
}) => ({
  display: 'grid',
  alignItems: 'center',
  padding: '8px 16px',
  minHeight: 47,
  cursor: 'pointer',
  position: 'relative',
  gridTemplateColumns: spread,
  borderBottom: `solid 1px ${theme.palette.novotalk.$resourcesTableBorderColor}`,
  backgroundColor: isHighlighted
    ? theme.palette.novotalk.$backgroundColorMedium
    : theme.palette.novotalk.$backgroundColor,
  '&:hover': {
    backgroundColor: isHighlighted
      ? theme.palette.novotalk.$activityUnreadBackgroundColorHover
      : theme.palette.novotalk.$activityReadBackgroundColorHover,
  },
  '&:active': {
    backgroundColor: isHighlighted
      ? theme.palette.novotalk.$activityUnreadBackgroundColorActive
      : theme.palette.novotalk.$activityReadBackgroundColorActive,
  },

}));

export const TableWrapperUsers = styled('div')(({ theme }) => ({
  width: '100%',
  color: theme.palette.novotalk.$textColorDarkBlue,
}));
export const NoItemsUsers = styled('div')({
  height: 100,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
});

export const TableListUsers = styled('div')({
  height: '70vh',
  overflow: 'scroll',
});

export const LoadingOverlayUsers = styled('div')({
  position: 'absolute',
  left: 0,
  backgroundColor: '#FFF',
  width: '100%',
  height: '90%',
  zIndex: 101,
  display: 'flex',
  alignContent: 'center',
  justifyContent: 'center',
  alignItems: 'center',
});
