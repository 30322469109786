import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import Text, { TEXT_COLOR, TEXT_SIZE } from '@components/Text';
import {
  Wrapper,
  VideoWrapper,
  Title,
  Name,
  StyledLessonIcon,
  StyledCTAButton,
  Description,
  DescriptionWrapper, TextWrapper, IconWrapper
} from './styles';
import {withTranslation} from "react-i18next";

class CourseVideo extends Component {
  constructor() {
    super();
    this.videoTypes = ['demo', 'lesson'];
  }

  generateButtonText(type) {
    const {t} = this.props
    switch (type) {
      case 'demo':
        return t('Actions.Confirmations.okGotIt');
      case 'lesson':
        return t('Actions.Instance.close');
        // no default
    }
    return null;
  }

  render() {
    const { videoName, type, onClose, description, t, i18n } = this.props;
    if (!this.videoTypes.includes(type)) {
      return null;
    }

    const title = t(`Common.UI.${type}`);
    const buttonText = this.generateButtonText(type);
    const dontWorry = type === 'demo'
      ? <Text size={TEXT_SIZE.H4} textColor={TEXT_COLOR.DISABLED}>{t('Informative.Notifying.dontWorry')}</Text>
      : null;
    const descriptionBlock = description
      ? (
        <Description>
          <Text size={TEXT_SIZE.T3}>{description}</Text>
        </Description>
      )
      : null;
    return (
      <Wrapper>
        <Title>
          <Text size={TEXT_SIZE.H1} textTransform="capitalize" weight="600">{title}</Text>
        </Title>
        <Name language={i18n.language}>
          <IconWrapper language={i18n.language}>
          <StyledLessonIcon />
          </IconWrapper>
          <TextWrapper language={i18n.language}>
          <Text size={TEXT_SIZE.H1} textTransform="capitalize">{videoName}</Text>
          </TextWrapper>
        </Name>
        <DescriptionWrapper language={i18n.language}>{descriptionBlock}</DescriptionWrapper>
        <VideoWrapper src={this.props.videoUrl} controls />
        <StyledCTAButton onClick={onClose} id="videoCloseButton" >
          <Text size={TEXT_SIZE.H4} textTransform="capitalize" textColor={TEXT_COLOR.REVERSED}>{buttonText}</Text>
        </StyledCTAButton>
        {dontWorry}
      </Wrapper>
    );
  }
}

CourseVideo.propTypes = {
  onClose: PropTypes.func,
  videoName: PropTypes.string,
  videoUrl: PropTypes.string,
  type: PropTypes.string,
  description: PropTypes.string,
};

export default compose(
  withTranslation(),
)(CourseVideo);
