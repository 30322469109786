export const Types = {
  EDIT_MEDIA_DATA: 'EDIT_MEDIA_DATA',
  SET_ERROR: 'SET_ERROR',
  SET_EDIT_STATUS: 'SET_EDIT_STATUS',
};

export const editStatus = {
  SENDING: 'SENDING',
  SENT: 'SENT',
  ERROR: 'ERROR',
  DEFAULT: 'DEFAULT',
};

export const editMediaData = ({ ...data }) => ({
  type: Types.EDIT_MEDIA_DATA,
  payload: data,
});

export const putError = (error) => ({
  type: Types.SET_ERROR,
  payload: { error },
});

export const putEditStatus = (status) => ({
  type: Types.SET_EDIT_STATUS,
  payload: { status },
});
