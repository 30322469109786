import gql from 'graphql-tag';
import { put, takeLatest, all, fork, join } from 'redux-saga/effects';
import { authenticationWrapper } from '@services/errorHandlingService';
import {
  TYPES,
  setRoleManagementData,
  getRoleManagementDataError,
  updateUserRoleError,
  addRoleToUserSuccess,
  getRoleManagementData,
  removeRoleFromUserSuccess,
} from './actions';
import { graphQuery, graphMutation } from '@services/commService';
import { roles, users } from './__fixtures__';

export const addUserRole = async (userId, roleId) => {
  if (process.env.USE_FIXTURES) return {};
  const mutation = gql`
    mutation GetOrCreateUserRole($userId: String!, $roleId: String!) {
      createUserRole(userId: $userId, roleId: $roleId) {
        id
      }
    }
  `;
  const res = await graphMutation(mutation, { userId, roleId });
  return res;
};

export const removeUserRole = async (userId, roleId) => {
  if (process.env.USE_FIXTURES) return {};
  const mutation = gql`
    mutation RemoveUserRole($userId: String!, $roleId: String!) {
      removeUserRole(userId: $userId, roleId: $roleId) {
        id
      }
    }
  `;
  const res = await graphMutation(mutation, { userId, roleId });
  return res;
};

export const getPageData = async () => {
  if (process.env.USE_FIXTURES) return { data: { roles, users } };
  const query = gql`
    {
      roles {
        id
        description
        roleId
      }
      users {
        userName
        id
        roles
      }
    }
  `;

  const result = await graphQuery(query);
  return result;
};

export function* callGetData() {
  yield authenticationWrapper(
    function* codeBlock() {
      const res = yield fork(getPageData);
      const result = yield join(res);
      if (result.errors) {
        yield put(getRoleManagementDataError(result.errors[0]));
      } else {
        yield put(setRoleManagementData(result.data));
      }
    },
    function* errorHandler(error) {
      yield put(getRoleManagementDataError(error));
    }
  );
}

export function* callAddUserRole(action) {
  yield authenticationWrapper(
    function* codeBlock() {
      const { user, role, screen } = action.payload;
      const res = yield fork(addUserRole, user.id, role.roleId);
      const result = yield join(res);
      if (result.errors) {
        yield put(updateUserRoleError(result.errors[0]));
      } else {
        yield put(addRoleToUserSuccess(screen));
        yield put(getRoleManagementData());
      }
    },
    function* errorHandler(error) {
      yield put(updateUserRoleError(error));
    }
  );
}

export function* callRemoveUserRole(action) {
  yield authenticationWrapper(
    function* codeBlock() {
      const { user, role } = action.payload;
      const res = yield fork(removeUserRole, user.id, role.roleId);
      const result = yield join(res);
      if (result.errors) {
        yield put(updateUserRoleError(result.errors[0]));
      } else {
        yield put(removeRoleFromUserSuccess());
        yield put(getRoleManagementData());
      }
    },
    function* errorHandler(error) {
      yield put(updateUserRoleError(error));
    }
  );
}

function* watchCallGetData() {
  yield takeLatest(TYPES.GET_ROLE_MANAGEMENT_PAGE_DATA, callGetData);
}
function* watchCallAddUserRole() {
  yield takeLatest(TYPES.ADD_ROLE_TO_USER, callAddUserRole);
}
function* watchCallRemoveUserRole() {
  yield takeLatest(TYPES.REMOVE_ROLE_FROM_USER, callRemoveUserRole);
}

export default function* builderDataRoleManagementSaga() {
  yield all([
    fork(watchCallGetData),
    fork(watchCallAddUserRole),
    fork(watchCallRemoveUserRole),
  ]);
}
