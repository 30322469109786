import {
  put,
  takeLatest,
  takeEvery,
  all,
  fork,
  join,
  select,
} from 'redux-saga/effects';
import { authenticationWrapper } from '@services/errorHandlingService';
import {
  TYPES,
  setOverview,
  setResources,
  onNetworkError,
  setExpanded,
  setUID,
  deleteItemSuccess,
  createNewResourceComplete,
  getResources,
  clearResourcesData
} from './actions';
import {
  getResourcesbyType,
  deleteResource,
  duplicateResource,
  copyResource,
} from './service/resourcesService';
import { getOverviewByTypeAndID } from './service/overviewService';
import { createTemporaryEntity } from './service/createNewEntity';
import { getUID } from '@services/authenticationService';
import { setRedirectUrl } from '@containers/App/components/Redirector/actions';
import { ROUTES } from '@shared/constants';
import _ from 'lodash';
import { toast } from 'react-toastify';
import { TOAST_AUTO_CLOSE_TIME } from '@shared/constants';
import { addNameToMedia } from './helpers';
import {t} from 'i18next'

let isDuplicating = false;

export function* callGetResources(action) {
  yield authenticationWrapper(
    function* codeBlock() {
      const { type, search, loadType } = action.payload;
      const { isTemplate, offset } = yield select((state) => state.get('resourceManager').toJS());
      const resourcesData = yield fork(getResourcesbyType, type, offset, isTemplate, search);
      const result = yield join(resourcesData);

      if (result.errors) {
        yield put(onNetworkError(result.errors[0]));
      } else {
        const { resources } = result;
        let formattedResources = addNameToMedia(resources, type);
        yield put(setResources(type, formattedResources, loadType));
      }
    },
    function* errorHandler(error) {
      yield put(onNetworkError(error));
    }
  );
}

export function* callGetOverview(action) {
  yield authenticationWrapper(
    function* codeBlock() {
      const { type, id, source } = action.payload;
      const res = yield fork(getOverviewByTypeAndID, type, id);
      const result = yield join(res);
      if (!_.isEmpty(result.errors)) {
        yield put(onNetworkError(result.errors[0]));
      } else {
        if (result?.overview?.monitor) {
          const { monitor } = result.overview.monitor;
          if (!monitor) {
            toast.error(t('UI.dataIncorrect'), {
              autoClose: TOAST_AUTO_CLOSE_TIME,
              position: 'bottom-right',
            });
          }
        }

        yield put(setOverview(type, result.overview, source));
      }
    },
    function* errorHandler(error) {
      yield put(onNetworkError(error));
    }
  );
}

export function* callGetExpandedChild(action) {
  yield authenticationWrapper(
    function* codeBlock() {
      const { type, id, source } = action.payload;
      const res = yield fork(getOverviewByTypeAndID, type, id);
      const result = yield join(res);
      if (!_.isEmpty(result.errors)) {
        yield put(onNetworkError(result.errors[0]));
      } else {
        yield put(setExpanded(result.overview, source));
      }
    },
    function* errorHandler(error) {
      yield put(onNetworkError(error));
    }
  );
}

export function* callGetUID(action) {
  yield authenticationWrapper(
    function* codeBlock() {
      const { type } = action.payload;
      const res = yield fork(getUID, type);
      const result = yield join(res);
      if (result.error) {
        yield put(onNetworkError(result.error));
      } else {
        yield put(setUID(type, result.id));
      }
    },
    function* errorHandler(error) {
      yield put(onNetworkError(error));
    }
  );
}

export function* callDeleteResource(action) {
  yield authenticationWrapper(
    function* codeBlock() {
      const { type, id } = action.payload;

      const res = yield fork(deleteResource, type, id);

      const result = yield join(res);

      if (result.error) {
        yield put(onNetworkError(result.error));
      } else {
        window.history.back();
        yield put(deleteItemSuccess(type, id));
      }
    },
    function* errorHandler(error) {
      yield put(onNetworkError(error));
    }
  );
}

export function* callDuplicateResource(action) {
  yield authenticationWrapper(
    function* codeBlock() {
      if (isDuplicating) return;
      isDuplicating = true;
      const { type, resource, userId } = action.payload;
      ;
      const res = yield fork(duplicateResource, type, resource, userId);
      const result = yield join(res);

      if (result.error) {
        yield put(onNetworkError(result.error));
      } else {
        yield put(getResources(type))
      }

      isDuplicating = false;
      yield true;
    },
    function* errorHandler(error) {
      yield put(onNetworkError(error));
      isDuplicating = false;
      yield true;
    }
  );
}

let creating = false;

export function* callCreateNew(action) {
  // FIX FOR KNOWN BOILERPLATE BUG OF MULTIPLE SAGA CALLS FOR ONE ACTION
  if (creating) return;
  creating = true;
  yield authenticationWrapper(
    function* codeBlock() {
      const { type, origin } = action.payload;
      const res = yield fork(createTemporaryEntity, type);
      const { errors, data } = yield join(res);
      if (errors) {
        yield put(onNetworkError(errors[0]));
        creating = false;
      } else {
        // save it to store
        yield put(createNewResourceComplete(type, origin, data.entityId, creating));
        // navigate to builder
        creating = false;
        yield put(setRedirectUrl(`${ROUTES.BUILDER}/${type}/${data.entityId}`));
      }
    },
    function* errorHandler(error) {
      yield put(onNetworkError(error));
      creating = false;
    }
  );
}

export function* callCopyResource({ payload: { type, resourceId }}) {
  yield authenticationWrapper(
    function* codeBlock() {
      const res = yield fork(copyResource, type, resourceId);
      const { errors } = yield join(res);

      if (errors) {
        yield put(onNetworkError(errors));
      } else {
        yield put(clearResourcesData());
        yield put(getResources(type));
      }
    },
    function* errorHandler(error) {
      yield put(onNetworkError(error));
    }
  );
}

function* watchCallGetResources() {
  yield takeLatest(TYPES.GET_RESOURCES, callGetResources);
}
function* watchCallGetOverview() {
  yield takeLatest(TYPES.GET_OVERVIEW, callGetOverview);
}
function* watchCallGetExpandedChild() {
  yield takeEvery(TYPES.GET_EXPANDED_CHILD, callGetExpandedChild);
}
function* watchCallGetUID() {
  yield takeLatest(TYPES.GET_UID, callGetUID);
}
function* watchCallDeleteResource() {
  yield takeLatest(TYPES.DELETE, callDeleteResource);
}
function* watchCallDuplicateResource() {
  yield takeLatest(TYPES.DUPLICATE, callDuplicateResource);
}
function* watchCallCreateNew() {
  yield takeLatest(TYPES.CREATE_NEW_RESOURCE, callCreateNew);
}
function* watchCallCopyResource() {
  yield takeLatest(TYPES.COPY, callCopyResource);
}

export default function* builderDataSaga() {
  yield all([
    fork(watchCallGetResources),
    fork(watchCallGetOverview),
    fork(watchCallGetExpandedChild),
    fork(watchCallGetUID),
    fork(watchCallDeleteResource),
    fork(watchCallDuplicateResource),
    fork(watchCallCreateNew),
    fork(watchCallCopyResource),
  ]);
}
