import format from 'date-fns/format';
import subHours from 'date-fns/sub_hours';
import moment from 'moment';

export const TIME_FORMAT = 'HH:mm DD/MM/YYYY';
export const DATE_FORMAT = 'YYYY-MM-DD';
export const USA_DATE_FORMAT = 'MM/DD/YY';
export const USA_TIME_FORMAT = 'hh:mm a';
export const MESSAGING_DATE_FORMAT = 'DD MMM YYYY';
export const MESSAGING_TIME_FORMAT = 'HH:mm';
export const RESOURCES_DATE_FORMAT = 'MM/DD/YYYY HH:mm';
export const USER_ACTIVITY_TIME_FORMAT = 'HH:mm:ss';
export const ACTIVITY_ITEM_DATE = 'M/D/YY HH:mm';
export const MINUTES_PER_HOUR = 60;
export const HOURS_PER_DAY = 24;

export const getTimeRange = (hoursAgo) => ([
  Number.parseInt(new Date().getTime() / 1000, 10),
  Number.parseInt(subHours(new Date(), hoursAgo).getTime() / 1000, 10),
]);

export const getFormatedDateFromFullDate = (fullDate) => format(fullDate, TIME_FORMAT);
export const getUSADateFromFullDate = (fullDate) => format(parseInt(fullDate), USA_DATE_FORMAT);
export const getUSATimeFromFullDate = (fullDate) => format(parseInt(fullDate), USA_TIME_FORMAT);

export const getMinutesSecondsFromMS = (milliseconds) => format(milliseconds, 'mm:ss');
export const getMinutesSecondsFromSeconds = (seconds) => format(seconds * 1000, 'mm:ss');
export const getHoursMinutesSecondsFromMilliseconds = (milliseconds) => format(milliseconds, USER_ACTIVITY_TIME_FORMAT);
export const getSecondsFromFormatTime = (formatTime) => formatTime
  .split(':')
  .reduce((acc, current, index) => acc + parseInt(index === 0 ? current * 60 : current), 0);

export const addMinutesToUSATime = (startTime, minutes) => moment(startTime, 'hh:mm a').add(Number(minutes), 'minutes').format('hh:mm a');

// we convert date format to MM/DD/YY and then we add days
export const addDaysToUSADate = (fullDate, days) => moment(format(parseInt(fullDate), USA_DATE_FORMAT), USA_DATE_FORMAT).add(Number(days), 'days').format(USA_DATE_FORMAT);

export const getDaysFromMinutes = (minutes) => (minutes / MINUTES_PER_HOUR / HOURS_PER_DAY);

export const overviewDateRange = () => ([
  parseInt(format(subHours(new Date(), 24), ('X')), 10),
  parseInt(format(subHours(new Date(), 168), ('X')), 10),
]);

export const activityItemDate = (timestamp) => (
  format(new Date(timestamp * 1000), ACTIVITY_ITEM_DATE)
);

export const getMessagingDate = (fullDate) => format(parseInt(fullDate), MESSAGING_DATE_FORMAT);
export const getMessagingTime = (fullDate) => format(parseInt(fullDate), MESSAGING_TIME_FORMAT);

export const sortByFullDates = (fullDate1, fullDate2) => {
  const date1 = new Date(fullDate1);
  const date2 = new Date(fullDate2);
  return (date2 - date1);
};

export const getResourcesDate = (fullDate) => format(new Date(parseInt(fullDate)), RESOURCES_DATE_FORMAT);

export const getMediaDate = (date) => format(date, RESOURCES_DATE_FORMAT);

export const dateToAge = (date) => new Date().getFullYear() - new Date(new Date(parseInt(date)).toISOString()).getUTCFullYear();
