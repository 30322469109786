import {styled} from "@mui/material";
import CTAButton from '@components/CTAButton';
import { getIconsByType, iconTypes } from '@services/icons';

export const Wrapper = styled('div')(
  {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: '40px 35px 25px',
    borderRadius: 4,
    width: 590,
  },
  ({ theme }) => ({
    backgroundColor: theme.palette.novotalk.$backgroundColor,
    direction: theme.RTL ? 'rtl' : 'inherit',
  })
);

const SuccessIcon = getIconsByType(iconTypes.CIRCLE_CHECKMARK_OVERFLOWED);

export const StyledSuccessIcon = styled(SuccessIcon)(
  {
    fontSize: 56,
    marginBottom: 25,
  },
  ({ theme }) => ({
    color: theme.palette.novotalk.$completedColor,
  })
);

const ErrorIcon = getIconsByType(iconTypes.CLOSE_CIRCLE_OUTLINE);

export const StyledErrorIcon = styled(ErrorIcon)(
  {
    fontSize: 56,
    marginBottom: 25,
  },
  ({ theme }) => ({
    color: theme.palette.novotalk.$navigationNotificationColor,
  })
);

export const HeaderContainer = styled('div')({
  marginBottom: 35,
  textAlign: 'center',
});

export const StyledButton = styled(CTAButton)({
  width: 200,
  height: 44,
});
