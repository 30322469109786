const TYPES = {
  SECONDARY: 'SECONDARY',
  PRIMARY: 'PRIMARY',
  ACTION: 'ACTION',
  OUTLINE: 'OUTLINE',
  OUTLINE_PRIMARY: 'OUTLINE_PRIMARY',
  CANCEL: 'CANCEL',
};

export default TYPES;
