import {styled} from "@mui/material";
import OverviewButton from '@components/OverviewButton';
import { isDesktop } from '@shared/constants/media-queries';
const isDesktopSize = isDesktop();

export const NoiseCalibrationButton = styled(OverviewButton)({
  marginTop: 15,
  marginLeft: 8,
  height: 32,
  width: isDesktopSize ? 106 : 90,
});
