import {styled} from "@mui/material";
import Button from '@components/Button';
import {Virtuoso} from "react-virtuoso";

export const Wrapper = styled('div')(
  {
    width: 1024,
    height: 685,
    padding: '30px 30px',
    display: 'grid',
    gridTemplateColumns: '400px auto',
    gridTemplateRows: '80px auto',
    gridAutoRows: '100%',
  },
  ({ theme }) => ({
    backgroundColor: theme.palette.novotalk.backgroundColor,
  })
);
export const SelectButton = styled(Button)({
  width: 200,
});
export const ItemButton = styled(Button)({});

export const ResourcesList = styled('div')({
  overflowY: 'auto',
  overflowX: 'hidden',
});

export const HeaderWrapper = styled('div')({
  gridColumn: '1 / 3',
  padding: '0px 8px',
});

export const OverviewSection = styled('div')({
  height: '100%',
  display: 'grid',
  gridTemplateRows: '515px auto',
});

export const SelectButtonWrapper = styled('div')({
  height: 50,
  display: 'flex',
  alignItems: 'flex-end',
  justifyContent: 'flex-end',
});

export const OverviewWrapper = styled('div')({
  overflowY: 'auto',
  overflowX: 'hidden',
  padding: '0 20px',
});

export const ResourceItem = styled('div')({
  display: 'grid',
  gridTemplateColumns: 'auto 100px',
  padding: 10,
  lineHeight: '30px',
});

export const VirtualizedResourcesList = styled(Virtuoso)({
    height: '580px!important',
    width: '100%',
})