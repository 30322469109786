/**
 *
 * ResourcesList
 *
 */

import { Component } from 'react';
import PropTypes from 'prop-types';
import Text, { TEXT_SIZE, TEXT_COLOR, TEXT_WEIGHT } from '@components/Text';
import {
  TableHeader,
  StyledArrowUpIcon,
  StyledArrowDownIcon,
  Cell,
} from './styles';
import { ORDERS } from '@shared/constants';
import {connect} from "react-redux";
import {changeFilterType, clearResourcesData, getResources} from "@containers/Admin/shared/ResourcesWrapper/actions";
import propTypes from "prop-types";
import {compose} from "redux";
import Proptypes from "prop-types";
import {withRouter} from "react-router-dom";
import VirtualizedResourcesList from './VirtualizedResourcesList'

class ResourcesList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      order: null,
    };
  }

  componentWillUnmount() {
    this.props.dispatchClearResourcesData()
  }

  sortItems = (items) => {
    const { order } = this.state;
    switch (order) {
      case ORDERS.DESC:
        items.sort((item1, item2) => {
          const name1 = item1.name.toLowerCase();
          const name2 = item2.name.toLowerCase();
          if (name1 === name2) {
            return 0;
          } else if (name1 > name2) {
            return -1;
          }
          return 1;
        });
        break;
      case ORDERS.ASC:
        items.sort((item1, item2) => {
          const name1 = item1.name.toLowerCase();
          const name2 = item2.name.toLowerCase();
          if (name1 === name2) {
            return 0;
          } else if (name1 > name2) {
            return 1;
          }
          return -1;
        });
        break;
      default:
        return items
    }
  };

  toggleOrder = () => {
    this.setState(({ order }) => ({
      order: order === ORDERS.ASC ? ORDERS.DESC : ORDERS.ASC,
    }));
  };

  renderHeader = (spread, columnNames) => {
    const Arrow =
      this.state.order === ORDERS.ASC ? StyledArrowDownIcon : StyledArrowUpIcon;
    return (
      <TableHeader spread={spread}>
        {columnNames.map((colName, index) => (
          <Cell key={`${index}${colName}`}>
            <Text
              size={TEXT_SIZE.T4}
              textColor={TEXT_COLOR.DISABLED}
              weight={TEXT_WEIGHT.BOLD}
            >
              {colName}
            </Text>
            {colName.toLowerCase() === 'name' && (
              <Arrow onClick={this.toggleOrder} />
            )}
          </Cell>
        ))}
      </TableHeader>
    );
  };

  render() {
    const { items, tableConf, selectedItem } = this.props;
    const spread = tableConf
      .map((col) => {
        if (col.width) {
          return typeof col.width === 'number' ? `${col.width}px` : col.width;
        }
        return 'auto';
      })
      .join(' ')
      .concat(' 30px');
    const columnNames = tableConf.map((col) => col.name);
    const displayData = tableConf.map(({ getDisplay, textOptions = {} }) => ({
      getDisplay,
      textOptions,
    }));
    this.sortItems(items);

    return (
      <div>
        {this.renderHeader(spread, columnNames)}
        <VirtualizedResourcesList
            items={items}
            spread={spread}
            displayData={displayData}
            selectedItem={selectedItem}
            resource={this.props.resource}
            fetchMore={this.props.dispatchLoadFilteredResources}
            onSelect={this.props.onSelect}
        />
      </div>
    );
  }
}

ResourcesList.defaultProps = {
  onSelect: () => {},
};

ResourcesList.propTypes = {
  history: Proptypes.object,
  items: PropTypes.array.isRequired,
  tableConf: PropTypes.array.isRequired,
  onSelect: PropTypes.func,
  selectedItem: PropTypes.object,
  dispatchLoadFilteredResources: propTypes.func,
  dispatchChangeFilterType: propTypes.func,
  dispatchClearResourcesData: propTypes.func,
};

const mapDispatchToProps = (dispatch) => {
  return {
    dispatchLoadFilteredResources: (type, search, loadType) => dispatch(getResources(type, search, loadType)),
    dispatchChangeFilterType: (isTemplate) => dispatch(changeFilterType(isTemplate)),
    dispatchClearResourcesData: () => dispatch(clearResourcesData())
  }
}

const mapStateToProps = state => {
  return {
    resource: state.toJS()?.resourceManager
  }
}

const withConnect = connect(mapStateToProps, mapDispatchToProps)

export default compose(withConnect, withRouter)(ResourcesList);
