import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { getMessagingDate } from '@utils/timeHelpers';
import TextButton from '@components/TextButton';
import Text, { TEXT_SIZE, TEXT_COLOR } from '@components/Text';
import {
  Header,
  DetailsContainer,
  DetailsItem,
  DetailValueText,
} from './styles';
import { compose } from 'redux';
import { withTranslation } from 'react-i18next';

export class UserDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  getDetailsItem = (title, value, additional = null) => (
    <DetailsItem>
      <Text
        size={TEXT_SIZE.T4}
        textColor={TEXT_COLOR.DISABLED_LIGHT}
        weight='bold'
        textTransform='uppercase'
      >
        {title}
      </Text>
      <DetailValueText size={TEXT_SIZE.T3} textColor={TEXT_COLOR.DARK_BLUE}>
        {value}
      </DetailValueText>
      {additional}
    </DetailsItem>
  );

  render() {
    const {
      user,
      onChangePassword,
        t
    } = this.props;
    const passChangeLink = (
      <TextButton onClick={onChangePassword}>
        <Text size={TEXT_SIZE.T4}>
            {t('Authentication.Password.changePassword')}
        </Text>
      </TextButton>
    );
    return (
      <div>
        <Header>
          <Text
            size={TEXT_SIZE.H5}
            textColor={TEXT_COLOR.DISABLED_LIGHT}
            weight='bold'
            textTransform='uppercase'
          >
              {t('Common.Billing.details')}
          </Text>
        </Header>
        <DetailsContainer>
          {this.getDetailsItem(t('Common.UI.name'), user.fullName)}
          {this.getDetailsItem(t('Authentication.Email.email'), user.email)}
          {this.getDetailsItem(
            t('Dates.birth'),
            getMessagingDate(user.birthdate)
          )}
          {this.getDetailsItem(
              t('Authentication.Password.password'),
            '********',
            passChangeLink
          )}
        </DetailsContainer>
      </div>
    );
  }
}

UserDetails.propTypes = {
  user: PropTypes.object,
  onChangePassword: PropTypes.func,
};

export default compose(
    withTranslation()
)(UserDetails);
