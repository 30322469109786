export const formatSectionsByTypeMap = (
  children,
  onView,
  onEdit,
  onMoveToCourseLevel,
  isUserPageProgram,
  createMiniOverview,
  readOnly,
) => children?.reduce((acc, current) => {
    if (!acc[current.type]) {
      acc[current.type] = {
        type: current.type,
        items: [],
        onView,
        onEdit,
        onMoveToCourseLevel,
        isUserPageProgram,
        createMiniOverview,
        readOnly,
      };
    }
    acc[current.type].items.push(current);
    return acc;
}, {});

export const detectCurrentCourseLevel = (children) => children &&
  children.some(({ hasPassed, isAccessible }) => !hasPassed && isAccessible);
