import _ from 'lodash';
import ChatService from '@services/chatService';

export const beforeunloadListener = (chatEnded, isInitiator) =>
  window.addEventListener('beforeunload', (event) => {
    if (!chatEnded && isInitiator) {
      const eventObj = event;
      eventObj.preventDefault();
      eventObj.returnValue = '';
    }
  });

export const unloadListener = (chatEnded, isInitiator) =>
  window.addEventListener('unload', () => {
    if (!chatEnded && isInitiator) {
      ChatService.emergencyEndChat();
    }
  });

export const archivingRemoveFunc = (item) => {
  const { archiving, ...other } = item;
  return other;
};

export const deepCompare = (arr1, arr2) => {
  if (arr1.length !== arr2.length) {
    return false;
  }
  const nArr1 = arr1.map(this.archivingRemoveFunc);
  const nArr2 = arr2.map(this.archivingRemoveFunc);

  return _.isEqual(
    _.sortBy(nArr1, 'participantId'),
    _.sortBy(nArr2, 'participantId')
  );
};
