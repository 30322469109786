import React from 'react';
import { RESOURCES } from './types';

// Overview
import OverviewCourse from './Course/Overview';
import OverviewMedia from './Media/Overview';
import OverviewLesson from './Lesson/Overview';
import OverviewExcercise from './Exercise/Overview';
import OverviewPoll from './Poll/Overview';
import OverviewPractice from './Practice/Overview';
import OverviewSkill from './Skill/Overview';

export const createOverView = (type, resource, additionalProps) => {
  switch (type) {
    case RESOURCES.COURSE: return <OverviewCourse resource={resource} {...additionalProps} />;
    case RESOURCES.EXERCISE: return <OverviewExcercise resource={resource} {...additionalProps} />;
    case RESOURCES.LESSON: return <OverviewLesson resource={resource} {...additionalProps} />;
    case RESOURCES.MEDIA: return <OverviewMedia resource={resource} {...additionalProps} />;
    case RESOURCES.MONITOR: return <div>{RESOURCES.MONITOR}</div>;
    case RESOURCES.POLL: return <OverviewPoll resource={resource} {...additionalProps} />;
    case RESOURCES.PRACTICE: return <OverviewPractice resource={resource} {...additionalProps} />;
    case RESOURCES.SKILL: return <OverviewSkill resource={resource} {...additionalProps} />;
    case RESOURCES.SPEECH_GOAL: return <div>{RESOURCES.SPEECH_GOAL}</div>;
    case RESOURCES.STIMULUS: return <div>{RESOURCES.STIMULUS}</div>;
    default: return null;
  }
};
