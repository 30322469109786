import React, { Component } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import Text, { TEXT_SIZE, TEXT_COLOR } from '@components/Text';
import ClickAwayListener from '@components/ClickAwayListener';
import TextButton from '@components/TextButton';
import CustomRangeDialog from './components/CustomRangeDialog';
import {
  Wrapper,
  StyledArrowUpIcon,
  StyledArrowDownIcon,
  MenuWrapper,
  MenuItem,
} from './styles';
import {withTranslation} from "react-i18next";
import {compose} from "redux";

export const OPTIONS = {
  DAYS7: {
    name: 'days7',
    value: 168,
  },
  HOURS48: {
    name: 'hours48',
    value: 48,
  },
  HOURS24: {
    name: 'hours24',
    value: 24,
  },
  CUSTOM: {
    name: 'custom',
  },
};

function getOption(hours) {
  const option = _.find(OPTIONS, (o) => o.value === hours);
  return option ? option : OPTIONS.CUSTOM;
}

class DateFilter extends Component {
  constructor(props) {
    super(props);
    this.state = {
      dialogOpen: false,
      isMenuOpened: false,
      choosenOption: getOption(props.hours),
    };
  }

  onSelect = (option) => () => {
    this.setState({
      isMenuOpened: false,
      choosenOption: option,
    });
    this.props.onRangeChange(option.value);
  };

  onCustomRangeChange = (hours) => {
    this.setState({
      dialogOpen: false,
      choosenOption: OPTIONS.CUSTOM,
    });
    this.props.onRangeChange(hours);
  };

  openCustomDialog = () => {
    this.setState({
      dialogOpen: true,
      isMenuOpened: false,
    });
  };

  closeCustomDialog = () => {
    this.setState({ dialogOpen: false });
  };

  openMenu = () => {
    this.setState({ isMenuOpened: true });
  };

  closeMenu = () => {
    this.setState({ isMenuOpened: false });
  };

  renderMenu = () => {
    const {t} = this.props
    const { isMenuOpened } = this.state;
    if (!isMenuOpened) return null;
    return (
      <MenuWrapper>
        <MenuItem onClick={this.onSelect(OPTIONS.DAYS7)}>
          <Text size={TEXT_SIZE.T3} textColor={TEXT_COLOR.DARK_BLUE}>
            {t('Dates.days7')}
          </Text>
        </MenuItem>
        <MenuItem onClick={this.onSelect(OPTIONS.HOURS48)}>
          <Text size={TEXT_SIZE.T3} textColor={TEXT_COLOR.DARK_BLUE}>
            {t('Dates.hours48')}
          </Text>
        </MenuItem>
        <MenuItem onClick={this.onSelect(OPTIONS.HOURS24)}>
          <Text size={TEXT_SIZE.T3} textColor={TEXT_COLOR.DARK_BLUE}>
            {t('Dates.hours24')}
          </Text>
        </MenuItem>
        <MenuItem onClick={this.openCustomDialog}>
          <Text size={TEXT_SIZE.T3} textColor={TEXT_COLOR.DARK_BLUE}>
            {t('Common.Statuses.custom')}
          </Text>
        </MenuItem>
      </MenuWrapper>
    );
  };

  render() {
    const { loading, t } = this.props;
    const { isMenuOpened, choosenOption, dialogOpen } = this.state;
    return (
      <Wrapper isLoading={loading}>
        <ClickAwayListener onClickAway={this.closeMenu}>
          <Text size={TEXT_SIZE.T3} textColor={TEXT_COLOR.ACTIVE}>
            {t('Common.UI.showLast')}
          </Text>
          <TextButton onClick={this.openMenu}>
            <Text
              size={TEXT_SIZE.T3}
              textColor={TEXT_COLOR.ACTIVE}
              margin="0 5px"
            >
              {t(`Dates.${choosenOption.name}`)}
            </Text>
            {isMenuOpened ? <StyledArrowUpIcon /> : <StyledArrowDownIcon />}
          </TextButton>
          {this.renderMenu()}
          <CustomRangeDialog
            setTimeRangeFilter={this.onCustomRangeChange}
            onClose={this.closeCustomDialog}
            isOpen={dialogOpen}
          />
        </ClickAwayListener>
      </Wrapper>
    );
  }
}

DateFilter.propTypes = {
  onRangeChange: PropTypes.func,
  loading: PropTypes.bool,
  hours: PropTypes.number,
};

export default compose(
    withTranslation()
)(DateFilter);