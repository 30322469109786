/**
*
* MonitorTypes
*
*/
import { Component } from 'react';
import MonitorImage from '@shared/Resources/Monitor/shared/MonitorImage';
import { MonitorTypesTitle } from '@shared/Resources/Monitor/shared/Titles';
import _ from 'lodash';
import PropTypes from 'prop-types';
import CheckBox from '@components/CheckBox';
import NumericSelector from '@components/NumericSelector';
import Text, { TEXT_COLOR, TEXT_SIZE } from '@components/Text';
import Media from '../../../../Media/Client';
import { MONITOR_TYPES } from '../../../types';
import {
  ExtraMonitor, ExtraMonitorsHeader, ExtraMonitorsWrapper, Header,
  InhalationIcon, InhaleContainer,
  LabelContainer,
  MirrorIcon, MonitorImageWrapper, MonitorType,
  SpeedometerIcon, TypesContainer,
  VideoIcon
} from './styles';
import { DEFAULT_DURATION_SECOND } from '../MonitorPatterns/consts';
import {withTranslation} from "react-i18next";
import {compose} from "redux";

class MonitorTypes extends Component {
  constructor(props) {
    super(props);
    this.state = {
      monitor: props.monitor,
    };
  }

  updateMonitor = (monitor) => {
    this.setState({ monitor });
    this.props.onChange(monitor);
  }

  monitorTypeSelected = (type) => () => {
    const { monitor } = this.state;
    monitor.type = type;

    if (type === MONITOR_TYPES.BREATHING || type === MONITOR_TYPES.LOUDNESS) {
      monitor.inhalation.on = true;
      monitor.inhalation.value = DEFAULT_DURATION_SECOND.TWO;
    }

    this.updateMonitor(monitor);
  }

  toggleExtraMonitor = (fieldToggled) => () => {
    const { monitor } = this.state;
    monitor[fieldToggled].on = !monitor[fieldToggled].on;
    this.updateMonitor(monitor);
  }

  updateInhalationDuration = (newDuration) => {
    const { monitor } = this.state;
    monitor.inhalation.value = newDuration;
    this.updateMonitor(monitor);
  }

  updateVideoUrl = (changedComponent) => {
    const id = changedComponent.value;

    const { monitor } = this.state;
    monitor.video = { on: true, id };
    this.updateMonitor(monitor);
  }

  renderMonitorType = (monitorType) => {
    const isSelected = monitorType === this.state.monitor.type;

    return (
      <MonitorType isSelected={isSelected} key={monitorType} className="monitorType" onClick={this.monitorTypeSelected(monitorType)}>
        <MonitorImageWrapper>
          <MonitorImage isSelected={isSelected} type={monitorType} />
        </MonitorImageWrapper>
        <Text size={TEXT_SIZE.T4} textColor={TEXT_COLOR[isSelected ? 'ACTIVE' : 'NORMAL']}>
          {_.capitalize(monitorType)}
        </Text>
      </MonitorType>
    );
  }

  renderHeader = () => {
    const showRequiredSign = this.state.monitor.type.length === 0;
    return (
      <Header>
        <MonitorTypesTitle showRequiredSign={showRequiredSign} />
      </Header>
    )
  };

  renderExtraMonitor = (monitorClassName, monitorField, Icon, extra = null) => (
    <ExtraMonitor>
      <CheckBox
        value={this.state.monitor[monitorField].on}
        className={monitorClassName}
        onChange={this.toggleExtraMonitor(monitorField)}
        label={
          <LabelContainer>
            <Icon />
            <Text textColor={TEXT_COLOR.DARK_BLUE} size={TEXT_SIZE.H4}>
              {this.props.t(`Common.UI.${monitorClassName}`)}
            </Text>
          </LabelContainer>
        }
      />
      {extra}
    </ExtraMonitor>
    );

  renderExtraMonitors = () => {
    const  {t} = this.props
    const { monitor } = this.state;
    return (
      <div>
        <ExtraMonitorsHeader>
          <Text textColor={TEXT_COLOR.DARK_BLUE} weight="bold">
            {t('UI.extraMonitors')}
          </Text>
          <Text size={TEXT_SIZE.T4} textColor={TEXT_COLOR.LIGHT_GRAY} margin="0 19px">
            {t('Informative.Notifying.extraSelect')}
          </Text>
        </ExtraMonitorsHeader>
        {/** extra features */}
        <ExtraMonitorsWrapper className="extraMonitorTypes">
          {this.renderExtraMonitor('extraMonitorInhale', 'inhalation', InhalationIcon, monitor.inhalation.on &&
            <InhaleContainer>
              <NumericSelector className="extraMonitorInhaleValue" value={monitor.inhalation.value||1} onChange={this.updateInhalationDuration} />
              <Text size={TEXT_SIZE.T4} textColor={TEXT_COLOR.DARK_BLUE} margin="0 8px">
                {t('Dates.secsCount', {
                  count: ''
                })}
              </Text>
            </InhaleContainer>
          )}
          {this.renderExtraMonitor('extraMonitorMirror', 'mirror', MirrorIcon)}
          {this.renderExtraMonitor('extraMonitorSpeedometer', 'speedometer', SpeedometerIcon)}
          {this.renderExtraMonitor('extraMonitorVideo', 'video', VideoIcon)}
          {monitor.video.on &&
            <Media className="extraMonitorVideoValue" onChange={this.updateVideoUrl} payload={{ value: monitor.video.url }} />
          }
        </ExtraMonitorsWrapper>
      </div>
    );
  }

  render() {
    return (
      <div className="resource-item">
        {this.renderHeader()}
        <TypesContainer className="monitorTypes">
          {_.values(MONITOR_TYPES).map(this.renderMonitorType)}
        </TypesContainer>
        {this.renderExtraMonitors()}
      </div>);
  }
}


MonitorTypes.propTypes = {
  onChange: PropTypes.func,
  monitor: PropTypes.shape({
    type: PropTypes.string,
    inhalation: PropTypes.shape({
      on: PropTypes.bool,
      value: PropTypes.number,
    }),
    mirror: PropTypes.shape({
      on: PropTypes.bool,
    }),
    speedometer: PropTypes.shape({
      on: PropTypes.bool,
    }),
    video: PropTypes.shape({
      on: PropTypes.bool,
      id: PropTypes.string,
      url: PropTypes.string,
    }),
  }),
};

export default compose(
    withTranslation()
)( MonitorTypes);
