export const Types = {
  BUILDER_REMOVE_COMPONENT: 'BUILDER_REMOVE_COMPONENT',
  BUILDER_DUPLICATE_COMPONENT: 'BUILDER_DUPLICATE_COMPONENT',
};

export const removeComponent = (id) => ({
  type: Types.BUILDER_REMOVE_COMPONENT,
  payload: { id },
});

export const duplicateComponent = (id) => ({
  type: Types.BUILDER_DUPLICATE_COMPONENT,
  payload: { id },
});
