import { practiceFull } from '../../__fixtures__/practiceFull';
import { graphMutation, graphQuery } from '@services/commService';

export const createEntity = async (info, _, lessonID) => {
  const { name, texts, media } = info;
  const { monitor, pattern, errors, progress, stimulus } = info.monitor;
  const mutation = `
  mutation($monitor:JSON, $pattern:JSON, $errors:JSON, $progress:JSON, $stimulus:JSON){
    createEntityLesson(
      name:"${name}",
      media:{
        demo:"${media.demo || null}",
        lesson:"${media.lesson}"
      },
      texts:{
        instructions:"""${texts.instructions}""",
        description:"""${texts.description}"""
      },
      monitor:{
        monitor:$monitor,
        pattern:$pattern,
        errors:$errors,
        progress:$progress,
        stimulus:$stimulus,
      },
       ){
        id
      }
    }
  `;
  return await graphMutation(
    mutation,
    { monitor, pattern, errors, progress, stimulus },
    true
  );
};
export const markLessonAsCompleted = async(id)=>{
  const mutation = `
  mutation{
    modifyLesson(
      id:"${id}"
      hasPassed:true,
       ){
        id
      }
    }
  `;
  return await graphMutation(mutation, {}, true);
}

export const editEntity = async (info, _, lessonID) => {
  const { name, texts, media } = info;
  const { monitor, pattern, errors, progress, stimulus } = info.monitor;
  const mutation = `
  mutation($monitor:JSON, $pattern:JSON, $errors:JSON, $progress:JSON, $stimulus:JSON, ){
    modifyLesson(
      id:"${info.id}",
      name:"${name}",
      media:{
        demo:"${media.demo}"
        lesson:"${media.lesson}"
      },
      texts:{
        instructions:"""${texts.instructions}""",
        description:"""${texts.description}"""
      },
      monitor:{
        monitor:$monitor,
        pattern:$pattern,
        errors:$errors,
        progress:$progress,
        stimulus:$stimulus,
      },
       ){
        id
      }
    }
  `;
  return await graphMutation(
    mutation,
    { monitor, pattern, errors, progress, stimulus },
    true
  );
};

export const deleteEntity = async (id) => {
  const mutation = `mutation{
    deleteLesson(
      id:"${id}"
    ){
      name
    }

  }`
  return await graphMutation(mutation, {}, true);
}

export const loadEntity = async (id) => {
  if (process.env.USE_FIXTURES) {
    return { data: { entity: [practiceFull] } };
  }
  const query = `
  {
    entity: entityLessons(id:"${id}",range:[0,1],fullData:true){
    id
    name
    createdAt
    media {
      demo
      demoId
      lesson
      lessonId
    }
    texts{
      instructions
      description
    }
    monitor {
      monitor
      pattern
      errors
      progress
      stimulus
    }
  }}
  `;
  return await graphQuery(query, true);
};
