import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';

// This HOC stores config at state, and gives decorated component "updateField" method
const ConfigUpdater = (WrappedComponent) => {
  class BaseWrapper extends React.PureComponent {
    constructor(props) {
      super(props);
      this.state = {
        config: _.cloneDeep(props.config),
      };
    }

    componentWillReceiveProps(nextProps) {
      // You don't have to do this check first, but it can help prevent an unneeded render
      if (!_.isEqual(nextProps.config, this.state.config)) {
        this.setState({ config: _.cloneDeep(nextProps.config) });
      }
    }

    updateField = (field) => (value) => {
      const { config } = this.state;
      _.set(config, field, value);
      this.setState({ config });
      this.props.onChange(config);
    }

    updateConfig = (config) => {
      this.setState({ config });
      this.props.onChange(config);
    }


    render() {
      const { config } = this.state;
      return (
        <div className="withConfgUpdater">
          <WrappedComponent
            {...this.props}
            config={config}
            updateField={this.updateField}
            updateConfig={this.updateConfig}
          />
        </div>
      );
    }
  }
  BaseWrapper.propTypes = {
    config: PropTypes.object,
    onChange: PropTypes.func,
  };

  return BaseWrapper;
};


export default ConfigUpdater;
