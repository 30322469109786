import {styled} from "@mui/material";

export const CheckboxGroupTitle = styled('div')({
  marginBottom: 13,
});

export const CheckboxGroupItem = styled('div')({
  height: 36,
});

export const CheckBoxLabel = styled('span')({
  display: 'inline-grid',
  height: '1em',
  position: 'relative',
  top: -2,
  columnGap: 10,
  alignItems: 'center',
  gridTemplateColumns: 'max-content max-content',
});
