import {styled} from "@mui/material";

export const PatternTypeContainer = styled('div')({}, ({ isDisabled }) => ({
  pointerEvents: isDisabled ? 'none' : null,
  opacity: isDisabled ? 0.4 : 1,
}));

export const SelectWrapper = styled('div')({
  margin: '20px 0',
});

export const DurationWrapper = styled('div')({
  marginTop: 16,
  display: 'flex',
  alignItems: 'center',
});

export const SelfManagedWrapper = styled('div')({
  marginTop: 16,
  marginLeft: 8,
});
