/**
*
* NumericSelector
*
*/
import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { ButtonWrapper, Button, Container, DirectInput } from './styles';
import { DURATION_VALUES } from '@shared/constants';

class NumericSelector extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      value: props.value,
    };
  }

  setValue = (valueInput) => {
    const { min, max } = this.props;
    const value = _.maxBy([_.min([max, valueInput]), min]);
    this.setState({ value });
    this.props.onChange(value);
  }

  increase = () => {
    const { value } = this.state;
    this.setValue(value + 1);
  }

  decrease = () => {
    const { value } = this.state;
    this.setValue(value - 1);
  }

  directInput = ({ target }) => {
    this.setValue(Number.parseInt(target.value, 10));
  }

  componentDidUpdate(prevProps) {
    if (prevProps.value !== this.props.value) {
      this.setState({ value: this.props.value });
    }
  }

  render() {
    const { min, max, disabled, margin } = this.props;
    const { value } = this.state;
    return (
      <Container disabled={disabled} margin={margin}>
        <ButtonWrapper disabled={value === min}>
          <Button disabled={value === min} className="decrease" onClick={this.decrease}>-</Button>
        </ButtonWrapper>
        <DirectInput min={min} max={max} value={value} type="number" className="directInput" onChange={this.directInput} />
        <ButtonWrapper disabled={value === max}>
          <Button disabled={value === max} className="increase" onClick={this.increase} >+</Button>
        </ButtonWrapper>
      </Container>
    );
  }
}

NumericSelector.defaultProps = {
  min: DURATION_VALUES.MIN,
  max: DURATION_VALUES.MAX,
  value: DURATION_VALUES.DEFAULT_VALUE,
};

NumericSelector.propTypes = {
  min: PropTypes.number,
  max: PropTypes.number,
  value: PropTypes.number,
  onChange: PropTypes.func,
  disabled: PropTypes.bool,
  margin: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),
};

export default NumericSelector;
