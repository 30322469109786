import { jsonCircularHandler } from './jsonHelper';

export const LOCAL_STORAGE_ITEM = {
  USER: 'user',
  USER_ROLES: 'userRoles',
  SESSION_ID: 'sessionId',
  EVENTS_LOG: 'eventsLog',
  LAST_EVENT: 'lastEvent',
  REFRESH_TIME: 'refresh',
};

export const getFromLocalStorage = (name) => JSON.parse(localStorage.getItem(name));

export const setToLocalStorage = (name, item) => {
  const json = jsonCircularHandler(item);
  localStorage.setItem(name, json);
};

export const removeFromLocalStorage = (name) => {
  localStorage.removeItem(name);
};
