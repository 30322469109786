import _ from 'lodash';
import ChatService, { ScreenSharingRoleEnum } from '@services/chatService';

export const getSelfAvatar = (sessionInfo) => {
  if (!sessionInfo || !sessionInfo.users) {
    return '';
  }
  const avatar = _.find(sessionInfo.users, { self: true }).avatar;
  return avatar || '';
};

export const getSelfUser = (sessionInfo) => {
  if (!sessionInfo || !sessionInfo.users) {
    return {};
  }
  const selfUser = _.find(sessionInfo.users, { self: true });
  return selfUser;
};
export const subscriberVideoWrapperStyleHelper = (numOfParticipants)=>{
  if(ChatService.screenSharingRole === ScreenSharingRoleEnum.SUBSCRIBER){
    return '14%'
  }
  return numOfParticipants<5?'48%':'30%'
}