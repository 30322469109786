import {styled} from "@mui/material";
import { getResponsiveCSS } from '@utils/cssHelpers';

export const Wrapper = styled('div')(
  {
    maxWidth: 900,
    margin: '60px auto',
  },
  ({ theme }) => ({
    direction: theme.RTL ? 'rtl' : 'inherit',
    maxHeight: `calc(100vh - ${theme.palette.novotalk.$navigationBarHeight}px)`,
  })
);

export const Header = styled('div')(
  getResponsiveCSS('marginBottom', 20, 30),
  ({ theme }) => ({
    marginRight: theme.RTL ? 26 : 0,
  })
);
