import { fromJS } from 'immutable';
import _ from 'lodash';
import { TYPES } from './actions';
import courseProgressConsts from '../../helpers/courseProgressConsts';

const skillpartOrder = {
  LESSON: 0,
  PRACTICE: 1,
  EXERCISE: 1,
  POLL: 2,
};

const { CURRENT, COMPLETED, LOCKED } = courseProgressConsts;
// The initial state of the Course router
export const initialState = fromJS({
  courses: [],
  loading: true,
  activityContext: {},
  activeLogOpened: null,
});

const addStatus = (status) => (entity) => ({
  ...entity,
  status,
});

const convertToLocation = (practice, practiceIndex) => (entity, skillpartIndex) => ({
  entity: practice ? { ...entity, isAccessible: practice.isAccessible } : entity,
  skillpartIndex: _.isUndefined(practiceIndex) ? skillpartIndex : practiceIndex,
  exerciseIndex: _.isUndefined(practiceIndex) ? undefined : skillpartIndex,
  skillpartName: practice ? practice.name : entity.name,
  skillpartType: practice ? practice.type.toLowerCase() : entity.type.toLowerCase(),
  paused: practice ? skillpartIndex > 0 : false,
});

const parseSkill = (skill, status) => {
  let locationInCourse = {
    skillpartType: null,
    skillpartName: '',
    skillpartIndex: null,
    paused: false,
  };
  const parsedSkill = _.cloneDeep(skill);
  parsedSkill.status = status;

  const mapWithStatus = addStatus(status);
  if (status === LOCKED || status === COMPLETED) {
    parsedSkill.Lessons = parsedSkill.Lessons.map(mapWithStatus);
    parsedSkill.Polls = parsedSkill.Polls.map(mapWithStatus);
    parsedSkill.Practices = parsedSkill.Practices.map((practice) => {
      const lockedPractice = _.cloneDeep(practice);
      lockedPractice.Exercises = lockedPractice.Exercises.map(mapWithStatus);
      return {
        ...lockedPractice,
        status,
      };
    });
  } else if (status === CURRENT) {
    const skillParts = [
      ...parsedSkill.Lessons.map(convertToLocation()),
      ..._.flatten(parsedSkill.Practices.map((practice, skillpartIndex) => practice.Exercises.map(convertToLocation(practice, skillpartIndex)))),
      ...parsedSkill.Polls.map(convertToLocation()),
    ];
    const currentSkillpart = skillParts.find((item) => !item.entity.hasPassed && item.entity.isAccessible);
    locationInCourse = _.omit(currentSkillpart, 'entity');

    parsedSkill.Lessons = parsedSkill.Lessons.map((lesson, index) => {
      const lessonStatus = getSkillpartStatus(lesson, index, currentSkillpart);
      return ({
        ...lesson,
        status: lessonStatus, // change to COMPLETED to bypass lesson
      });
    });
    parsedSkill.Practices = parsedSkill.Practices.map((practice, index) => {
      const updatedPractice = _.cloneDeep(practice);
      const practiceStatus = getSkillpartStatus(practice, index, currentSkillpart);
      if (practiceStatus === CURRENT) {
        updatedPractice.Exercises = updatedPractice.Exercises.map((exercise, exerciseIndex) => {
          const exerciseStatus = getSkillpartStatus(exercise, exerciseIndex, currentSkillpart);
          return ({
            ...exercise,
            status: exerciseStatus,
          });
        });
      } else {
        const mapExercisesWithStatus = addStatus(practiceStatus);
        updatedPractice.Exercises = updatedPractice.Exercises.map(mapExercisesWithStatus);
      }
      return {
        ...updatedPractice,
        status: practiceStatus, // change to CURRENT to get to practice
      };
    });
    parsedSkill.Polls = parsedSkill.Polls.map((poll, index) => {
      const pollStatus = getSkillpartStatus(poll, index, currentSkillpart);

      return ({
        ...poll,
        status: pollStatus,
      });
    });
  }
  return { parsedSkill, locationInCourse };
};

const getSkillpartStatus = (skillpart, index, { exerciseIndex }) => {
  if (skillpart.type !== 'EXERCISE') {
    if (skillpart.hasPassed) {
      return COMPLETED;
    } if (skillpart.isAccessible) {
      return CURRENT;
    }
    return LOCKED;
  }
  const currentPartIndex = exerciseIndex;
  if (index > currentPartIndex) {
    return LOCKED;
  } if (index < currentPartIndex) {
    return COMPLETED;
  }
  return CURRENT;
};

const getSkillStatus = (skill, skillIndex, course, previousSkillStatus) => {
  const hasPaidSubscription = !course.isDefault;

  if (hasPaidSubscription && skill.free) {
    return COMPLETED;
  }
  if (!skill.subscribed || (skillIndex > 0 && previousSkillStatus !== COMPLETED)) {
    return LOCKED;
  } if (skill.progress === 100) {
    return COMPLETED;
  }

  return CURRENT;
};

const parseCourses = (courses) => {
  let previousSkillStatus = null;
  const parsedCourses = courses.map((course, courseIndex) => {
    let locationInCourse = {
      skillpartType: null,
      skillpartName: '',
      skillpartIndex: null,
      paused: false,
    };
    const parsedSkills = course.Skills.map((skill, skillIndex) => {
      const status = getSkillStatus(skill, skillIndex, course, previousSkillStatus);
      previousSkillStatus = status;
      const parseResult = parseSkill(skill, status);

      if (status === CURRENT) {
        locationInCourse = parseResult.locationInCourse;
        locationInCourse.skillIndex = skillIndex;
        locationInCourse.skillName = skill.name;
        locationInCourse.path = `${courseIndex}/skill${skillIndex}/${locationInCourse.skillpartType}${locationInCourse.skillpartIndex}`;
        if (locationInCourse.skillpartType === 'practice') {
          locationInCourse.path = `${locationInCourse.path}/exercise${locationInCourse.exerciseIndex}`;
        }
      }
      return parseResult.parsedSkill;
    });
    return {
      ...course,
      locationInCourse,
      Skills: parsedSkills,
    };
  });
  return parsedCourses;
};

function courseRouterReducer(state = initialState, action) {
  switch (action.type) {
    case TYPES.GET_COURSES: {
      return state.set('loading', true);
    }
    case TYPES.SET_COURSES: {
      const parsedCourses = parseCourses(action.courses);
      return state.set('courses', fromJS(parsedCourses))
        .set('loading', false);
    }
    case TYPES.SET_ACTIVITY_CONTEXT:

      return state.set('activityContext', action.activityContext);
    case TYPES.MARK_EXERCISE_AS_COMPLETED: {
      const { location } = action;
      const {
        course, skill, practice, exercise,
      } = location;
      let courses = state.get('courses');
      courses = courses.toJS() ? courses.toJS() : courses;
      if (!courses) return state;
      const courseExerciseCount = courses[course].Skills[skill].Practices[practice].Exercises.length;
      const isLastExercise = courseExerciseCount === exercise + 1;
      if (courseExerciseCount) {
        courses[course].Skills[skill].Practices[practice].Exercises[exercise].status = COMPLETED;
        if (!isLastExercise) {
          courses[course].Skills[skill].Practices[practice].Exercises[exercise + 1].status = CURRENT;
        }
        return state.set('courses', fromJS(courses));
      }
      return state;
    }
    case TYPES.MARK_FEEDBACK_AS_SEEN:
      return state.set('loading', true);
    case TYPES.MARK_FEEDBACK_AS_SEEN_SUCCEED:
      return state.set('loading', false);
    case TYPES.MARK_FEEDBACK_AS_SEEN_FAILED:
      return state.set('loading', false);
    case TYPES.UPDATE_ACTIVITY_LOG_OPENED:

      return state.set('activeLogOpened', action.id);
    default:
      return state;
  }
}

export default courseRouterReducer;
