import gql from 'graphql-tag';
import { graphMutation, graphQuery } from '@services/commService';
import { ACTIVITY_TYPES } from '../constants';

export const getActivityProductions = async (exerciseId) => {
  const query = gql`
    query{
      activityProductions(context:{ACTIVITY_EXERCISE:"${exerciseId}" }){
        id
        startTS
        textStimuli{
          words {
            syllables{
              text
            }
          }
        }
        stimuliDisplayed{
          wordIndex
          syllableIndex
        }
        dspSignal
        productionLength
        activityCreated{
          at
        }
        productionErrors{
          type
          exists
          displayed
        }
        stimuliDisplayed {
          id
          wordIndex
          syllableIndex
        }
      }  
    }`;
  const res = await graphQuery(query);
  return res;
};

export const createFeedback = async ({ type, id }, text, dismiss) => {
  const mutation = gql`mutation{
    post:createFeedback(
      activity:{
        type:${type},
        id:"${id}",
      }
      isDismiss:${Boolean(dismiss)},
      text:"""${text}"""
      ) {
      id
      createdAt
      payload
      publisherUser{
        avatar
        fullName
      }
    }
  }`;
  const res = await graphMutation(mutation);

  return res;
};

export const editFeedback = async (id, text) => {
  const mutation = gql`mutation{
    post:editFeedback(
      id:"${id}"
      text:"""${text}"""
      ) {
      id
      createdAt
      payload
      publisherUser{
        avatar
        fullName
      }
    }
  }`;
  const res = await graphMutation(mutation);

  return res;
};

export const visit = async (type, id) => {
  const mutation = gql`
  mutation{
    visit(type:${type},id:"${id}"){
      type
      id
      visitorId
    }
  }`;
  const res = await graphMutation(mutation);
  return res;
};

export const visitAll = async (type, ids) => {
  return Promise.all(ids.map((id) => visit(type, id)))
}

export const getActivityGQLNode = (type) => {
  const gqlNodes = {
    [ACTIVITY_TYPES.ACTIVITY_PRACTICE]: 'activityPractices',
  };
  if (!gqlNodes[type]) throw new Error(`missing type ${type}`);
  return gqlNodes[type];
};

export const getUserActivityData = async (type, id) => {
  const gqlNode = getActivityGQLNode(type);
  const query = gql`
    query{
      activities:${gqlNode}(id:"${id}"){
        ParentSkill {
          name
        }
        activityCreated{
          at
        }
        id
        type
        userId
        entity{
          name
        }
        Threads {
          Posts {
            id
            createdAt
            payload
            publisherUser{
              id
              avatar
              fullName
            }
          }
        }
        user{
          fullName
          avatar
        }
        Exercises{
          id
          activityCreated{
            at
          }
          activityMediaAudio {
            publicUrls
          }
          activityMediaVideo {
            publicUrls
            mediaDuration
          }
          summary{
            errorsDistribution
            achievedMasteryLevel
            insights {
              type
              insights {
                severity
                error
              }
            }
            hasPassed
          }
          entity{
            name
            monitor {
              pattern
              errors
            }
          }
        }
        summary{
          achievedMasteryLevel
          insights {
            type
            insights {
              severity
              error
            }
          }
          hasPassed
        }
      }
    }`;
  const res = await graphQuery(query);
  return res;
};
