import gql from 'graphql-tag';
import { practiceFull } from '../../__fixtures__/practiceFull';
import { graphMutation, graphQuery } from "@services/commService";

export const createEntity = async (info, components, practiceID) => {
  const { name } = info;
  const children = components
    .filter((child) => !child.payload.mapToField)
    .map(({ payload, type }) => ({ type, id: payload.id }));
    
  const mutation = gql`
  mutation($children: [entityRelationInputType]) {
    createEntityPractice(
      name:"${name}",
      children:$children
       ){
        id 
      }
    }
  `;
  return await graphMutation(mutation, {children});
};
export const markPracticeAsCompleted = async(id)=>{
  const mutation = `
  mutation{
    modifyPractice(
      id:"${id}"
      hasPassed:true,
       ){
        id
      }
    }
  `;
  return await graphMutation(mutation, {}, true);
}

export const editEntity = async (info, components, practiceID) => {
  const { name } = info;
  const children = components
    .filter((child) => !child.payload.mapToField)
    .map(({ payload, type }) => ({ type, id: payload.id }));
    
  const mutation = gql`
  mutation($children: [entityRelationInputType]) {
    modifyPractice(
      id:"${info.id}",
      name:"${name}",
      children:$children
       ){
        id 
      }
    }
  `;
  return await graphMutation(mutation, {children});
};

export const deleteEntity = async(id)=>{
  
  const mutation = `mutation{
    deletePractice(
      id:"${id}"
    ){
      name
    }

  }`
  
  return await graphMutation(mutation,{}, true);
}

export const loadEntity = async (id) => {
  if (process.env.USE_FIXTURES) {
    return { data: { entity: [practiceFull] } };
  }
  
  const query = gql`
  {
    entity:entityPractices(id:"${id}",range:[0,1],fullData:true){
    id
    name
    createdAt
    duration
    children{
      id
      type
      payload
    }
  }}
  `;
  return await graphQuery(query);
};
