import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import _ from 'lodash';
import { withTranslation } from 'react-i18next';
import Container from '@components/Container';
import Text, { TEXT_COLOR } from '@components/Text';
import ProgressBar from '@components/ProgressBar';
import { openPopUp as openPopUpAction } from '@containers/App/containers/PopUp/actions';
import POPUP_TYPES from '@containers/App/containers/PopUp/types';
import { getSortedFeedbacks } from '@containers/User/helpers/feedbackHelpers';
import { ROUTES } from '@shared/constants';
import courseStatusConsts from '../../../../../../helpers/courseProgressConsts';
import {
  Wrapper,
  HeaderWrapper,
  TitleWrapper,
  Circle,
  PartsWrapper,
  PartWrapper,
  Title,
  ClockIconWrapper,
  StyledPracticeIcon,
  StyledClockIcon,
  StyledContainer,
  FeedbackWrapper,
  TipsWrapper,
  StyledCheckMarkIcon,
  IconWrapper,
} from './styles';
import TipsIndicator from '../../components/TipsIndicator';
import FeedbackIndicator from '../../components/FeedbackIndicator';

export class ExercisesPane extends Component {
  renderDuration(durationString) {
    return (
      <StyledContainer>
        <ClockIconWrapper>
          <StyledClockIcon />
        </ClockIconWrapper>
        <Text textColor={TEXT_COLOR.DISABLED_LIGHT} size="H4">
          {durationString}
        </Text>
      </StyledContainer>
    );
  }

  renderParts(exercises, currentExercise) {
    const { LOCKED, COMPLETED, CURRENT } = courseStatusConsts;
    const { exerciseInProgress } = this.props;
    return (
      <PartsWrapper>
        {exercises && exercises.map((exercise, index) => {
          const exerciseMasteryLevel = _.get(
            exercise,
            'monitor.errors.masteryLevel',
            '0',
          );
          const lastAchievedMasteryActivity = _.last(
            exercise.Activities,
            'summary.achievedMasteryLevel',
          );
          const lastAchievedMasteryLevel = _.get(
            lastAchievedMasteryActivity,
            'summary.achievedMasteryLevel',
            0,
          );
          const lastActivity = _.last(exercise.Activities);
          const insights = _.get(lastActivity, 'summary.insights', []);
          const hasTips = insights.length > 0;
          const isCurrent = exercise.id === currentExercise.id;
          const isMastered = exercise.hasPassed;
          const isLast = index === exercises.length - 1;
          const isLocked = (index > 0 ? !exercises[index - 1]?.hasPassed : false);
          let status;
          if (isLocked) {
            status = LOCKED;
          } else if (isMastered) {
            status = COMPLETED;
          } else {
            status = CURRENT;
          }

          const progressBarProps = {
            mastery: exerciseMasteryLevel,
            progress: status === LOCKED ? 0 : lastAchievedMasteryLevel,
          };
          const {
            match: {
              params: { courseIndex, skillIndex, skillPartIndex },
            },
            history,
          } = this.props;
          const path = `${ROUTES.COURSE_PAGE}/${courseIndex}/${skillIndex}/${skillPartIndex}/${index}`;
          return (
            <PartWrapper
              key={`${exercise.id}-${index}`}
              last={isLast}
              progress={status}
            >
              <TitleWrapper
                className="exerciseTitle"
                isLocked={isLocked}
                onClick={() => {
                  if (!isLocked && !exerciseInProgress) history.push(path);
                }}
              >
                <Circle progress={status}>
                  {status === COMPLETED && <StyledCheckMarkIcon />}
                </Circle>
                <Text
                  size="T3"
                  textTransform="capitalize"
                  margin={this.props.i18n.language === 'he' ? '-2px 22px 0 0' : '-2px 0 0 22px'}
                  weight={isCurrent ? 'bold' : 'normal'}
                  textColor={
                    isLocked ? TEXT_COLOR.DISABLED_LIGHT : TEXT_COLOR.DARK_BLUE
                  }
                >
                  {exercise.name}
                </Text>
              </TitleWrapper>
              <Container
                margin={this.props.i18n.language === 'he' ? '0 34px 0 0' : '0 0 0 34px'}
                alignItems="baseline"
              >
                <ProgressBar {...progressBarProps} width={150} height={7} />
                {lastAchievedMasteryLevel > 0 && (
                  <Text
                    size="T4"
                    textColor={
                      isMastered ? TEXT_COLOR.ACTIVE : TEXT_COLOR.DISABLED_LIGHT
                    }
                    margin="0 5px 0 0"
                  >
                    {`${lastAchievedMasteryLevel}% /`}

                  </Text>
                )}
                <Text
                  size="T4"
                  textColor={TEXT_COLOR.NORMAL}
                >
                  {`${exerciseMasteryLevel}%`}

                </Text>
              </Container>
              {hasTips && (
                <TipsWrapper
                  onClick={() => this.props.openPopUp(POPUP_TYPES.EXERCISE_TIPS, {
                    exerciseName: exercise.name,
                    progressBarProps,
                    insights,
                  })}
                >
                  <TipsIndicator />
                </TipsWrapper>
              )}
            </PartWrapper>
          );
        })}
      </PartsWrapper>
    );
  }

  render() {
    const {
      practice, currentExercise, openPopUp, t, i18n
    } = this.props;
    const { duration } = practice;
    const durationMessage = t('Dates.durationInMinutes', { duration });
    let feedbacks = [];

    if (practice.Activities) {
      feedbacks = getSortedFeedbacks(practice.Activities);
    }

    const hasFeedbacks = feedbacks.length > 0;

    return (
      <Wrapper language={i18n.language}>
        <HeaderWrapper>
          <Title>
            <IconWrapper>
            <StyledPracticeIcon />
            </IconWrapper>
            <Text
              textColor={TEXT_COLOR.DARK_BLUE}
              size="T2"
              textTransform="capitalize"
              weight={this.props.i18n.language === 'he' ? 'bold' : 'normal'}
            >
              {practice?.name}
            </Text>
          </Title>
          {duration && this.renderDuration(durationMessage)}
          {hasFeedbacks && (
            <FeedbackWrapper
              onClick={() => openPopUp(POPUP_TYPES.CLINICIAN_FEEDBACK, {
                practiceName: practice.name,
                feedbacks,
                isEnteringPractice: false,
              })}
            >
              <FeedbackIndicator />
            </FeedbackWrapper>
          )}
        </HeaderWrapper>
        {this.renderParts(practice.exercises, currentExercise)}
      </Wrapper>
    );
  }
}

ExercisesPane.propTypes = {
  practice: PropTypes.object,
  currentExercise: PropTypes.object,
  openPopUp: PropTypes.func,
  history: PropTypes.object,
  match: PropTypes.object,
};

export const mapStateToProps = (state) => {
  const { practice, currentExercise, exerciseInProgress } = state.getIn(['currentPractice']).toJS();
  return { practice, currentExercise, exerciseInProgress };
};

export function mapDispatchToProps(dispatch) {
  return {
    openPopUp: (type, props) => dispatch(openPopUpAction(type, props)),
  };
}

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default compose(
  withRouter,
  withTranslation(),
  withConnect,
)(ExercisesPane);
