const getMonth = require('date-fns/get_month');
const getYear = require('date-fns/get_year');

const activitySortResolver = (activity1, activity2) => activity2.timestamp - activity1.timestamp;

export const groupActivitiesToMonths = (activities) => {
  // sort activities by timestamps
  activities.sort(activitySortResolver);
  // greate the months map
  let currentMonth = { month: -1 }; // initial object
  const result = [];

  const createEmptyMonth = (monthIndex, year) => ({
    year,
    month: monthIndex,
    activities: [],
  });

  activities.forEach((activity) => {
    // change zero based index to human readable
    const monthIndex = getMonth(activity.timestamp * 1000) + 1;
    const year = getYear(activity.timestamp * 1000);

    if (currentMonth.month !== monthIndex) {
      currentMonth = createEmptyMonth(monthIndex, year);
      result.push(currentMonth);
    }
    currentMonth.activities.push(activity);
  });

  return result;
};
