import React from 'react';
import { StyledCTAButton } from '../../styles';
import { useSelector, useDispatch } from 'react-redux';
import { endChat, leaveChat } from '../../actions';
import { openPopUp } from '@containers/App/containers/PopUp/actions';
import POPUP_TYPES from '@containers/App/containers/PopUp/types';
import { popUpPropsHelper } from './helpers';
import { useLocation, useHistory } from 'react-router-dom';
import { VIDEO_CALL_CHAT_ENDED } from '../VideoCall/reducer';
import { useVideoCallContext } from '@shared/hooks/useVideoCallContext';
import {useTranslation} from "react-i18next";

export const StopBtn = () => {
  const {t} = useTranslation()
  const { stateContext, dispatchContext } = useVideoCallContext();
  const location = useLocation();
  const history = useHistory();
  const dispatch = useDispatch();
  const { sessionInfo } = useSelector((state) =>
    state.getIn(['videoChat']).toJS()
  );
  const messagesHandler = () => {
    return t(`Informative.Notifying.${stateContext?.selfUser?.initiator ? 'endConversation' : 'exitConversation'}`)
  };

  const handleEndChatClick = () => {
    const onConfirm = (result) => {
      if (result) {
        dispatchContext({ type: VIDEO_CALL_CHAT_ENDED, payload: true });
        const pathname = location.pathname;
        const sessionId = pathname.substring(pathname.lastIndexOf('/') + 1);
        dispatch(endChat(sessionInfo, sessionId));
        history.push('/');
      }
    };
    dispatch(openPopUp(POPUP_TYPES.CONFIRM, popUpPropsHelper(onConfirm)));
  };
  const handleLeaveChatClick = () => {
    history.push('/');
    dispatch(leaveChat());
  };
  return (
    <StyledCTAButton
      onClick={
        stateContext?.selfUser?.initiator
          ? handleEndChatClick
          : handleLeaveChatClick
      }
      disabled={stateContext.chatEnded}
    >
      {messagesHandler()}
    </StyledCTAButton>
  );
};