import {styled} from "@mui/material";
import { getIconsByType, iconTypes } from '@services/icons';

export const Wrapper = styled('div')(
  {
    gridArea: 'list',
    overflowY: 'auto',
  },
  ({ theme }) => ({
    borderRight: `1px solid ${theme.palette.novotalk.$borderColorLight}`,
  })
);

export const MenuItem = styled('div')(
  {
    display: 'flex',
    justifyContent: 'stretch',
    padding: 14,
  },
  ({ theme, isCurrent, newItem }) => ({
    borderBottom: `1px solid ${
      isCurrent
        ? theme.palette.novotalk.$transparentColor
        : theme.palette.novotalk.$messagingListItemBackgroundColorHover
    }`,
    backgroundColor:
      isCurrent || newItem
        ? theme.palette.novotalk.$messagingListItemBackgroundColorHover
        : theme.palette.novotalk.$backgroundColor,
    cursor: newItem ? 'auto' : 'pointer',
    '&:hover': {
      backgroundColor: theme.palette.novotalk.$messagingListItemBackgroundColorHover,
      borderBottom: `1px solid ${theme.palette.novotalk.$transparentColor}`,
    },
    '&:active': {
      backgroundColor: theme.palette.novotalk.$messagingListItemBackgroundColorActive,
      borderBottom: `1px solid ${theme.palette.novotalk.$transparentColor}`,
    },
  })
);

const NewPersonIcon = getIconsByType(iconTypes.PERSON);

export const StyledPersonIcon = styled(NewPersonIcon)(
  {
    padding: 18,
    borderRadius: '50%',
    fontSize: 56,
    marginRight: 14,
  },
  ({ theme }) => ({
    backgroundColor: theme.palette.novotalk.$newMessageIconBackgroundColor,
    color: theme.palette.novotalk.$questionIconColor,
  })
);

export const NewConversationText = styled('div')({
  alignSelf: 'center',
});

export const StyledAvatar = styled('img')({
  width: 56,
  height: 56,
  marginRight: 14,
  flexShrink: 0,
  borderRadius: '50%',
  objectFit: 'cover',
});

export const MessageInfoWrapper = styled('div')({
  display: 'flex',
  justifyContent: 'space-between',
  marginBottom: 6,
});

export const MessageTextWrapper = styled('div')(
  {
    marginRight: 20,
    whiteSpace: 'normal',
    display: '-webkit-box',
    WebkitLineClamp: 2,
    WebkitBoxOrient: 'vertical',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    lineHeight: '16px',
  },
  ({ theme }) => ({
    color: theme.palette.novotalk.$textColorDisabled,
  })
);

export const EndedBadge = styled('div')(
  {
    width: 66,
    height: 20,
    flexShrink: 0,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: 2,
  },
  ({ theme }) => ({
    backgroundColor: theme.palette.novotalk.$messagingEndedBadgeBackgroundColor,
  })
);

export const MessageWrapper = styled('div')({
  width: '100%',
});

export const MessageContentWrapper = styled('div')({
  display: 'flex',
  alignItems: 'flex-start',
  justifyContent: 'space-between',
});
