/* eslint-disable import/no-mutable-exports */
let ENV;
switch (window.location.hostname) {
  case 'app.novotalk.com':
    ENV = 'prod';
    break;

  case 'gt-nvnew-stg-client.novotalk.com':
    ENV = 'stg';
    break;

  case 'local.novotalk.com':
    ENV = 'local';
    break;

  case 'localhost':
    ENV = 'local';
    break;

  default:
    ENV = 'prod';
    break;
}
const API_URL = process.env.REACT_APP_API_URL
const WEB_URL = process.env.REACT_APP_WEB_URL
const REACT_APP_STRIPE_TOKENS = `${process.env.REACT_APP_STRIPE_TOKENS}`
const IS_MVP = process.env.REACT_APP_IS_MVP;

export const AUTH_ENDPOINTS = {
  BRIDGE_TOKEN_VALIDATION_ENDPOINT: '/validateBridgeToken',
  SIGN_IN: '/signin',
  SIGN_UP: '/signup',
  RECOVER_PASSWORD: '/recoverPassword',
  RESET_PASSWORD: '/resetPassword',
  CONFIRM_EMAIL: '/confirmEmail',
  GET_UID: '/getUID',
  RESEND_EMAIL_CONFIRMATION: '/resendEmailConfirmation',
  CHANGE_PASSWORD: '/changePassword',
  REFRESH_TOKEN_CALL: '/refreshToken',
  USER_ROLES_ENUM: '/rolesEnum',
  PROPERTIES: '/properties',
};

export const CLOUD_ENDPOINTS = {
  UPLOAD_MEDIA: '/uploadMedia',
  ADD_PACKET_TO_S3: '/addPacketToS3',
};

export const CHAT_ENDPOINTS = {
  INITIATE_CHAT: '/initiateChat',
  GET_CHAT_INFO: '/getChatInfo',
  SET_CONNECTION_ID: '/setConnectionId',
  END_CHAT: '/endChat',
  START_ACTIVITY_SESSION: '/startActivitySession',
  START_ACTIVITY_ARCHIVE: '/startActivityArchive',
  STOP_ACTIVITY_ARCHIVE: '/stopActivityArchive',
  END_ACTIVITY_SESSION: '/endActivitySession',
  LEAVE_ACTIVITY_SESSION: '/leaveActivitySession',
  GET_PARTICIPANT_ID_BY_CONNECTION_ID: '/getParticipantIdByConnectionId'
};

export const BILLING_ENDPOINTS = {
  SUBSCRIBE: '/subscribe',
  CANCEL_SUBSCRIPTION: '/cancelSubscription',
};

let INTERCOM_SERVER = `${API_URL}/intercom`;
let GRAPHQL_SERVER = `${API_URL}/graphql/graphql`;
let AUTH_SERVER = `${API_URL}/auth`;
let CLOUD_SERVER = `${API_URL}/cloud`;
let SESSION_SERVER = `${API_URL}`;
let OLD_API = `${API_URL}/api`;
let REACT_APP_SERVER_URL = API_URL;
let WEB_CLIENT = WEB_URL;
let EVENT_LOGGER_SERVER = `${API_URL}/eventLogger/`;
let CHAT_SERVER = `${API_URL}/chat`;
let BILLING_SERVER = `${API_URL}/billing`;
let STRIPE_TOKEN = REACT_APP_STRIPE_TOKENS;

if (process.env.REACT_APP_IS_LOCAL) {

  INTERCOM_SERVER = `${API_URL}:3003`;
  GRAPHQL_SERVER = `${API_URL}:3004/graphql`;
  AUTH_SERVER = `${API_URL}:3005`;
  CLOUD_SERVER = `${API_URL}:3006`;
  SESSION_SERVER = `${API_URL}:3007`;
  OLD_API = `${API_URL}/api`;
  REACT_APP_SERVER_URL = API_URL;
  WEB_CLIENT = WEB_URL;
  EVENT_LOGGER_SERVER = `${API_URL}:3030`;
  CHAT_SERVER = `${API_URL}:3008`;
  BILLING_SERVER = `${API_URL}:3009`;
  STRIPE_TOKEN = REACT_APP_STRIPE_TOKENS;
}

export const ENVIRONMENT = ENV;
export {
  INTERCOM_SERVER,
  GRAPHQL_SERVER,
  AUTH_SERVER,
  CLOUD_SERVER,
  SESSION_SERVER,
  OLD_API,
  REACT_APP_SERVER_URL,
  WEB_CLIENT,
  EVENT_LOGGER_SERVER,
  CHAT_SERVER,
  BILLING_SERVER,
  STRIPE_TOKEN,
  IS_MVP
};
