import {styled} from "@mui/material";
import { getIconsByType, iconTypes } from '@services/icons';
import { isDesktop, isTablet } from '@shared/constants/media-queries';
import { Virtuoso } from 'react-virtuoso';
const isDesktopSize = isDesktop();

export const TableRow = styled('div')(
  {
    display: 'grid',
    alignItems: 'center',
    padding: '8px 16px',
    minHeight: 47,
    cursor: 'pointer',
    position: 'relative',
  },
  ({ spread, theme, isHighlighted, isSelected }) => {
    if (isSelected) {
      return {
        gridTemplateColumns: spread,
        borderBottom: `solid 1px ${theme.palette.novotalk.$resourcesTableBorderColor}`,
        color: theme.palette.novotalk.$novotalkBlue,
        backgroundColor: theme.palette.novotalk.$resourcesTableBgColorHover,
        'svg': {
          color: theme.palette.novotalk.$novotalkBlue,
        },
      };
    }
    return {
      gridTemplateColumns: spread,
      borderBottom: `solid 1px ${theme.palette.novotalk.$resourcesTableBorderColor}`,
      backgroundColor: isHighlighted
        ? theme.palette.novotalk.$backgroundColorMedium
        : theme.palette.novotalk.$backgroundColor,
      '&:hover': {
        backgroundColor: isHighlighted
          ? theme.palette.novotalk.$activityUnreadBackgroundColorHover
          : theme.palette.novotalk.$activityReadBackgroundColorHover,
      },
      '&:active': {
        backgroundColor: isHighlighted
          ? theme.palette.novotalk.$activityUnreadBackgroundColorActive
          : theme.palette.novotalk.$activityReadBackgroundColorActive,
      },
    }
  }
);

export const TableHeader = styled('div')(
  {
    display: 'grid',
    alignItems: 'center',
    padding: '0 16px',
    height: 43,
  },
  ({ spread, theme }) => ({
    gridTemplateColumns: spread,
    borderBottom: `solid 1px ${theme.palette.novotalk.$resourcesTableBorderColor}`,
    backgroundColor: theme.palette.novotalk.$backgroundColor,
    color: theme.palette.novotalk.$textColorDisabled,
  })
);

export const Cell = styled('div')({
  overflow: 'hidden',
  display: 'flex',
  fontSize: isTablet() && 11,
  '& span': {
    whiteSpace: 'pre',
    textOverflow: 'ellipsis',
  },
},
  ({ noPadding, titlesUppercase, isLast }) => ({
    paddingRight: noPadding ? 0 : 10,
    textTransform: titlesUppercase && 'uppercase',
    justifyContent: isLast && 'end',
    '> span': {
      marginRight: isLast && 32,
    },
  })
);

export const HeaderColumn = styled(Cell)({
  display: 'flex',
  alignItems: 'baseline',
});

export const NoItems = styled('div')({
  height: 100,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
});

export const TableWrapper = styled('div')({
  width: '100%',
},
  ({ theme }) => ({
    color: theme.palette.novotalk.$textColorDarkBlue,
  })
);

export const TableList = styled('div')({
    height: '100%',
});

export const LoadingOverlay = styled('div')({
  position: 'absolute',
  left: 0,
  backgroundColor: '#FFF',
  width: '100%',
  height: '90%',
  zIndex: 101,
  display: 'flex',
  alignContent: 'center',
  justifyContent: 'center',
  alignItems: 'center',
});

export const StyledArrowRightIcon = styled(
  getIconsByType(iconTypes.FILLED_ARROW_RIGHT)
)({
  fontSize: 24,
  marginLeft: 10,
  position: 'absolute',
  right: 0,
});

export const VirtualizedList = styled(Virtuoso)({
  height: `${isDesktopSize ? 45 : 27}rem !important`,
});

export const VirtualizedLogList = styled(Virtuoso)({
  height: `63vh !important`,
});
