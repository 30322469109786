import { CALIBRATION_STATUS } from '@components/NoiseCalibration/consts';
import { fromJS } from 'immutable';
import { TYPES } from './actions';

export const initialState = fromJS({
  production: 0,
  monitorActive: false,
  microphoneStarted: false,
  exercisePaused: false,
  subProductionInProgress: false,
  monitorErrors: [],
  currentSequenceIndex: 0,
  currentSyllableIndex: 0,
  repetitionInSequenceIndex: 0,
  repetitionsInSequence: 1,
  repetitionsResults: [],
  startNoiseCalibration: false,
  calibrationStatus: CALIBRATION_STATUS.INITIAL,
  listOneSecondAmountsOfSyllables:[],
  intervalExercise: null,
});

function monitorHandlerReducer(state = initialState, action) {
  switch (action.type) {
    case TYPES.SET_MONITOR_SUB_PRODUCTION_IN_PROGRESS_FLAG:
      return state.set('subProductionInProgress', action.value);
    case TYPES.INSERT_NEW_REPETITION_INTO_REPETITIONS_RESULTS: {
      let repetitionsResults = state.get('repetitionsResults');
      if (repetitionsResults) {
        repetitionsResults = repetitionsResults.push({ hasErrors: false });
        return state.set('repetitionsResults', repetitionsResults);
      }
      return state;
    }
    case TYPES.SET_ERRORS_IN_PRODUCTION_RESULTS: {
      const repetitionInSequenceIndex = state.get('repetitionInSequenceIndex');
      let repetitionsResults = state.get('repetitionsResults');
      if (repetitionsResults) {
        repetitionsResults = repetitionsResults.toJS();
        if (repetitionsResults?.length && repetitionInSequenceIndex < repetitionsResults.length) {
          repetitionsResults[repetitionInSequenceIndex].hasErrors = true;
          return state.set('repetitionsResults', fromJS(repetitionsResults));
        }
      }
      return state;
    }
    case TYPES.SET_MONITOR_PRODUCTION:
      return state.set('production', action.production);
    case TYPES.SET_MONITOR_ACTIVE_FLAG:
      return state.set('monitorActive', action.value);

    case TYPES.SET_MICROPHONE_ACTIVE_FLAG:
      return state.set('microphoneStarted', action.value);
    case TYPES.SET_EXERCISE_PAUSED_FLAG:
      return state.set('exercisePaused', action.value);
    case TYPES.RESET_EXERCISE_DATA: {
      const repetitionsInSequence = action.repetitionsInSequence || 1;
      return initialState.set('repetitionsInSequence', repetitionsInSequence);
    }
    case TYPES.ADVANCE_TO_NEXT_SEQUENCE: {
      const currentSequenceIndex = state.get('currentSequenceIndex');
      
      return state
        .set('currentSequenceIndex', currentSequenceIndex + 1)
        .set('repetitionInSequenceIndex', 0)
        .set('repetitionsResults', fromJS([]));
    }
    case TYPES.RESET_REPETITION_RESULTS: {
      return state.set('repetitionInSequenceIndex', 0).set('repetitionsResults', fromJS([]));
    }
    case TYPES.ADVANCE_TO_NEXT_SYLLABLE: {
      return state
        .set('currentSyllableIndex', action.value)
    }
    case TYPES.SET_REPETITION_IN_SEQUENCE_INDEX:
      return state.set('repetitionInSequenceIndex', action.index);
    case TYPES.SET_MONITOR_ERRORS:
      return state.set('monitorErrors', fromJS(action.errors));
    case TYPES.SET_NOISE_CALIBRATION:
      return state.set('startNoiseCalibration', action.value);
    case TYPES.CHANGE_CALIBRATION_STATUS:
      return state.set('calibrationStatus', action.value);
      case TYPES.SET_LIST_ONE_SECOND_AMOUNT_OF_SYLLABLES:
        const production = state.get('production');
        if(production){
          const prevList = state.get('listOneSecondAmountsOfSyllables');
          return state.set('listOneSecondAmountsOfSyllables', [...prevList,...action.payload]);
        }
        return state.set('listOneSecondAmountsOfSyllables', action.payload);
      
    case TYPES.SET_INTERVAL_EXERCISE:
      return state.set('intervalExercise', action.interval);
    default:
      return state;
  }
}

export default monitorHandlerReducer;
