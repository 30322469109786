import {styled} from "@mui/material";

export const Wrapper = styled('div')(
  {
    overflow: 'hidden',
    transition: 'height 0.5s ease',
  },
  ({ isCollapsed, height }) => ({
    height: isCollapsed ? 0 : height,
  })
);
