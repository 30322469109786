import {styled} from "@mui/material";
import { getIconsByType, iconTypes } from '@services/icons';
import { Link } from 'react-router-dom';

export const MenuWrapper = styled('div')(
  {
    width: 360,
    position: 'absolute',
    top: 'calc(100% + 8px)',
    left: 'calc(-180px + 50%)',
    borderRadius: 4,
    zIndex: 200,
    cursor: 'auto',
    '&:before': {
      display: 'block',
      content: '""',
      width: 10,
      height: 10,
      position: 'absolute',
      left: 'calc(50% - 5px)',
      top: -5,
      zIndex: -1,
      transform: 'rotate(45deg)',
    },
  },
  ({ theme }) => ({
    direction: theme.RTL ? 'rtl' : 'initial',
    backgroundColor: theme.palette.novotalk.$backgroundColor,
    boxShadow: `0 4px 32px 0 ${theme.palette.novotalk.$navigationMessagesMenuColorShadow}`,
    '&:before': {
      backgroundColor: theme.palette.novotalk.$backgroundColor,
    },
  })
);

export const MenuItem = styled('div')(
  {
    display: 'flex',
    justifyContent: 'stretch',
    padding: 14,
    cursor: 'pointer',
  },
  ({ theme, unread }) => ({
    borderBottom: `1px solid ${theme.palette.novotalk.$backgroundColorLight}`,
    backgroundColor: unread
      ? theme.palette.novotalk.$messagingListItemBackgroundColorHover
      : theme.palette.novotalk.$backgroundColor,
    '&:hover': {
      backgroundColor: theme.palette.novotalk.$messagingListItemBackgroundColorHover,
    },
    '&:active': {
      backgroundColor: theme.palette.novotalk.$messagingListItemBackgroundColorActive,
    },
  })
);

export const StyledAvatar = styled('img')(
  {
    width: 52,
    height: 52,
    borderRadius: '50%',
    objectFit: 'cover',
  },
  ({ theme }) => ({
    [theme.RTL ? 'marginLeft' : 'marginRight']: 10,
  })
);

export const MessageInfoWrapper = styled('div')({
  display: 'flex',
  justifyContent: 'space-between',
  marginBottom: 8,
});

export const MessageTextWrapper = styled('div')({
  width: 250,
  whiteSpace: 'normal',
  display: '-webkit-box',
  WebkitLineClamp: 2,
  WebkitBoxOrient: 'vertical',
  textOverflow: 'ellipsis',
  overflow: 'hidden',
  lineHeight: '14px',
});

export const MenuFooter = styled('div')({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  padding: '12px 10px',
});

const NewItemIcon = getIconsByType(iconTypes.CREATE);

export const StyledNewItemIcon = styled(NewItemIcon)({
  width: 14,
  height: 14,
});

export const IconWrapper = styled('span')(
  {
    margin: '-5px 6px 0 6px',
  },
  ({ theme }) => ({
    color: theme.palette.novotalk.$textColorHighlited,
  })
);

export const StyledLink = styled(Link)({
  textDecoration: 'none',
  cursor: 'pointer',
});
