const {
  GRAPH_SIZE,
  CALIBRATION,
  SAMPLE,
} = require('../../../../../../constants');

const getLevel = (value, normalizeValues) =>
  (value - normalizeValues.calibration.soft) /
  (normalizeValues.calibration.hard - normalizeValues.calibration.soft);

const normalizePlotWithNormalizedValues = (normalizeValues) => (value) =>
  Math.max(
    normalizeValues.lineWidth / 2,
    Math.min(
      normalizeValues.graphHeight - normalizeValues.lineWidth / 2,
      normalizeValues.graphHeight -
        normalizeValues.heightPad -
        getLevel(value, normalizeValues) *
          normalizeValues.graphHeight *
          normalizeValues.heightFactor
    )
  );

const plotSegment = ({
  canvasCTX,
  prevEndX,
  prevEndY,
  ax,
  ay,
  bx,
  by,
  endx,
  endy,
  height,
}) => {
  // first stroke
  canvasCTX.beginPath();
  canvasCTX.moveTo(prevEndX, prevEndY);
  canvasCTX.bezierCurveTo(ax, ay, bx, by, endx, endy);
  canvasCTX.lineTo(endx, height);
  canvasCTX.stroke();
  canvasCTX.beginPath();
  canvasCTX.moveTo(prevEndX, height);
  canvasCTX.lineTo(prevEndX, prevEndY);
  canvasCTX.bezierCurveTo(ax, ay, bx, by, endx, endy);
  canvasCTX.lineTo(endx, height);
  canvasCTX.fill();
};

export const plotBufferToCanvasOldStyle = (
  canvasCTX,
  buffer,
  patternType,
  showImage,
  width,
  stepSize,
) => {
  // SKULL ICON
  // This is a plot from the old angular site
  // This is something we kept for legacy purposes
  // Treat this with caution and try not to use it outside the fluency shaping course
  // SKULL ICON
  // const t1 = (new Date()).getTime();
  const ctx = canvasCTX;
  const normalizeValues = {
    graphHeight: GRAPH_SIZE[patternType].height,
    lineWidth: 0,
    heightPad: 0,
    calibration: {
      soft: CALIBRATION.SOFT.draw,
      hard: CALIBRATION.HARD.draw,
    },
    heightFactor: 0.64,
  };
  const normalizePlot = normalizePlotWithNormalizedValues(normalizeValues);
  const { sampleWidth, height, imageKeepRatio } = GRAPH_SIZE[
    patternType
  ];
  const { imagePosition, lengthMS, lengthSamples } = SAMPLE[patternType];
  const bufLength = buffer ? buffer.length : 0;
  ctx.clearRect(0, 0, width, height);

  // draw image beneath plot
  if (showImage) {
    const image = document.getElementById('PatternImage');
    const targetImageWidth = Math.min(
      Math.ceil((lengthMS / lengthSamples) * sampleWidth),
      width
    );
    const targetImageScale = targetImageWidth / image.width;
    const targetImageHeight = imageKeepRatio
      ? Math.ceil(image.height * targetImageScale)
      : Math.min(image.height, height);

    ctx.drawImage(
      image,
      width * imagePosition,
      height - targetImageHeight,
      targetImageWidth,
      targetImageHeight
    );
  }

  ctx.strokeStyle = '#55c3e6';
  const grd = ctx.createLinearGradient(0, height, 0, 0);
  grd.addColorStop(1, CALIBRATION.HARD.color);
  grd.addColorStop(0.5, CALIBRATION.HARD.color);
  grd.addColorStop(0.4, CALIBRATION.SOFT.color);
  grd.addColorStop(0, CALIBRATION.SOFT.color);
  ctx.fillStyle = grd;
  // ctx.fillStyle = 'rgba(255,255,255,0.8)';

  if (bufLength > 0) {
    // draw 2sec beneath plot
    ctx.lineWidth = normalizeValues.lineWidth;
    let prevEndX = 0;
    let prevEndY = normalizePlot(0);
    switch (buffer.length) {
      case 1:
        plotSegment({
          canvasCTX: ctx,
          prevEndX,
          prevEndY,
          ax: Math.floor(stepSize * 0),
          ay: normalizePlot(buffer[0]),
          bx: Math.floor(stepSize * 0),
          by: normalizePlot(buffer[0]),
          endx: Math.floor(stepSize * 0),
          endy: normalizePlot(buffer[0]),
          height,
        });
        break;
      case 2:
        plotSegment({
          canvasCTX: ctx,
          prevEndX,
          prevEndY,
          ax: Math.floor(stepSize * 1),
          ay: normalizePlot(buffer[1]),
          bx: Math.floor(stepSize * 1),
          by: normalizePlot(buffer[1]),
          endx: Math.floor(stepSize * 1),
          endy: normalizePlot(buffer[1]),
          height,
        });
        break;
      case 3:
        plotSegment({
          canvasCTX: ctx,
          prevEndX,
          prevEndY,
          ax: Math.floor(stepSize * 1),
          ay: normalizePlot(buffer[1]),
          bx: Math.floor(stepSize * 2),
          by: normalizePlot(buffer[2]),
          endx: Math.floor(stepSize * 2),
          endy: normalizePlot(buffer[2]),
          height,
        });
        break;
      default:
        plotSegment({
          canvasCTX: ctx,
          prevEndX,
          prevEndY,
          ax: Math.floor(stepSize * 1),
          ay: normalizePlot(buffer[1]),
          bx: Math.floor(stepSize * 2),
          by: normalizePlot(buffer[2]),
          endx: Math.floor(stepSize * 3),
          endy: normalizePlot(buffer[3]),
          height,
        });
        break;
    }
    prevEndX = Math.floor(stepSize * 3);
    prevEndY = normalizePlot(buffer[3]);
    for (let i = 5; i < bufLength; i += 2) {
      const ax = Math.floor(stepSize * (i - 1));
      const ay = normalizePlot(buffer[i - 2] * 2 - buffer[i - 3]);
      const bx = ax;
      const by = normalizePlot(buffer[i - 1]);
      const endx = Math.floor(stepSize * i);
      const endy = normalizePlot(buffer[i]);

      plotSegment({
        canvasCTX: ctx,
        prevEndX,
        prevEndY,
        ax,
        ay,
        bx,
        by,
        endx,
        endy,
        height,
      });
      prevEndX = endx;
      prevEndY = endy;
    }
    if (buffer.length > 3) {
      ctx.beginPath();
      ctx.moveTo(prevEndX, prevEndY);
      ctx.lineTo(
        Math.floor(stepSize * bufLength),
        normalizePlot(buffer[bufLength - 1])
      );
      ctx.stroke();
      ctx.beginPath();
      ctx.moveTo(prevEndX, height);
      ctx.lineTo(prevEndX, prevEndY);
      ctx.lineTo(
        Math.floor(stepSize * bufLength),
        normalizePlot(buffer[bufLength - 1])
      );
      ctx.lineTo(Math.floor(stepSize * bufLength), height);
      ctx.fill();
    }
  }
  // const t2 = (new Date()).getTime();
  // console.log(t1, t2, t2 - t1);
};
