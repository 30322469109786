import React, {useEffect} from 'react';
import {
    SectionTitle
} from "@containers/Admin/containers/UserPage/user-page-wrapper/components/UserPersonalProgram/components/ListItemSection/styles";
import {
    SkillsItem
} from "@containers/Admin/containers/UserPage/user-page-wrapper/components/UserPersonalProgram/components/SkillsItem";
import {RESOURCES} from "@shared/Resources/types";
import {useTranslation} from "react-i18next";
import {IPractice} from "@containers/Admin/containers/UserPage/user-page-wrapper/components/UserPersonalProgram/types";

interface PracticesSectionProps {
    practices: IPractice[]
}

export const PracticesSection:React.FC<PracticesSectionProps> = ({practices}) => {
    const {t} = useTranslation()
    
    return (
        <>
            <SectionTitle extraSpacings>
                {t('Common.UI.practice')}
            </SectionTitle>
            {
                practices.map((practice) => (
                    <SkillsItem
                        key={practice.name}
                        resource={practice}
                        type={RESOURCES.PRACTICE}
                        isFirstElement={false}
                        isCurrentLevel={practice.isAccessible && !practice.hasPassed}
                    />
                ))
            }
        </>
    );
};