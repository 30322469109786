import {styled} from "@mui/material";

export const Wrapper = styled('div')(
  {
    width: 812,
    height: 685,
    overflowY: 'scroll',
    overflowX: 'hidden',
    padding: '15px 65px',
  },
  ({ theme }) => ({
    backgroundColor: theme.palette.novotalk.backgroundColor,
  })
);
