import {styled} from "@mui/material";
import { getIconsByType, iconTypes } from '@services/icons';

export const StyledDropdownIcon = styled(
  getIconsByType(iconTypes.FILLED_ARROW_DOWN)
)(
  {
    fontSize: 21,
  },
  ({ theme }) => ({
    color: theme.palette.novotalk.$textColorDisabled,
  })
);

export const Wrapper = styled('div')({
  display: 'flex',
  flexWrap: 'wrap',
  flexShrink: 0,
  position: 'relative',
  margin: '10px 0',
});

export const SelectsWrapper = styled('div')({
  width: '100%',
  display: 'flex',
});

export const Label = styled('div')({
  marginBottom: 6,
});

export const selectStyles = (error, theme) => ({
  indicatorsContainer: (provided) => ({
    ...provided,
    marginRight: 7,
  }),
  indicatorSeparator: () => {
    'none';
  },
  clearIndicator: () => ({
    display: 'none',
  }),
  singleValue: (provided) => ({
    ...provided,
    fontSize: 14,
    color: theme.palette.novotalk.$textColorDarkBlue,
  }),
  option: (provided, state) => ({
    ...provided,
    height: 44,
    paddingLeft: 15,
    display: 'flex',
    alignItems: 'center',
    fontSize: 14,
    color: theme.palette.novotalk.$textColorDarkBlue,
    backgroundColor: state.isSelected
      ? theme.palette.novotalk.$dropdownBackgroundColorSelected
      : theme.palette.novotalk.$backgroundColor,
    '&:hover': {
      backgroundColor: state.isSelected
        ? theme.palette.novotalk.$dropdownBackgroundColorSelected
        : theme.palette.novotalk.$dropdownBackgroundColorHover,
    },
  }),
  control: (provided) => ({
    ...provided,
    maxWidth: '120px',
    minWidth: '120px',
    height: 44,
    paddingLeft: 5,
    [theme.RTL ? 'marginLeft' : 'marginRight']: '10px',
    boxShadow: 'none',
    borderColor: error ? theme.palette.novotalk.$errorColor : theme.palette.novotalk.$inputBorderColor,
    '&:hover': {
      borderColor: error ? theme.palette.novotalk.$errorColor : theme.palette.novotalk.$inputBorderColorHover,
    },
  }),
  menu: (provided) => ({
    ...provided,
    maxWidth: '118px',
    minWidth: '118px',
    margin: '0 1px',
  }),
  menuList: (provided) => ({
    ...provided,
    paddingTop: 0,
    paddingBottom: 0,
  }),
});
