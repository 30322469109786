import _ from 'lodash';
import { graphMutation, graphQuery } from '@services/commService';
import {
  courses,
  excercises,
  lessons,
  mediaItems,
  polls,
  practice,
  skills,
} from '../__fixtures__';
import { RESOURCES } from '@shared/Resources/types';
import { BUILDER_TYPES } from '@containers/Admin/containers/Builder/editorObjects/editorObjectTypes';
import { getService } from '@containers/Admin/containers/Builder/service';
import {paginationLimit} from "@containers/Admin/shared/ResourcesWrapper/reducer";

const useFixtures = process.env.USE_FIXTURES;

export const getQueryString = (nodeName, additionalGQL = '', offset = 0, isTemplate = true, search = '') =>{
  const builderList =nodeName.includes('Course')? `
  isTemplate
  owner{
    id
    fullName
  }
  `:
  `
  isTemplate
  owner{
    id
    fullName
  }
  parentId
  `
  return `
{
  resources: ${nodeName} (latestVersions:true, range: [${offset}, ${paginationLimit}], isTemplate: ${isTemplate}, name:"${search}") {
  id
  name
 ${builderList}
  entityCreated{
    at
    by
  }
  entityUpdated{
    at
    by
  }
  ${additionalGQL}
}
}
`};

const getEntityListQuery = async (nodeName, additionalGQL = '', offset, isTemplate, search) => {
  const query = getQueryString(nodeName, additionalGQL, offset, isTemplate, search);
  return graphQuery(query, true);
};

const gqlMedia = async (offset = 0, search = '') => {
  if (useFixtures) return { errors: null, data: { resources: mediaItems } };
  const query = `
  {
    resources: media(
    range: [${offset}, ${paginationLimit}],
    title: "${search}"
    ){
      id
      description
      title
      type
      value
      entityCreated{
        at
      }
    }
  }
  `;
  return await graphQuery(query, true);
};
const gqlCourses = async (offset, isTemplate, search) => {
  if (useFixtures) return { errors: null, data: { resources: courses } };
  return await getEntityListQuery('resourceEntityCourses', '', offset, isTemplate, search);
};
const gqlExcercises = async (offset, isTemplate, search) => {
  if (useFixtures) return { errors: null, data: { resources: excercises } };
  return await getEntityListQuery('entityExercises', '', offset, isTemplate, search);
};
const gqlLessons = async (offset, isTemplate, search) => {
  if (useFixtures) return { errors: null, data: { resources: lessons } };
  return await getEntityListQuery('entityLessons', '', offset, isTemplate, search);
};
const gqlPolls = async (offset, isTemplate, search) => {
  if (useFixtures) return { errors: null, data: { resources: polls } };
  return await getEntityListQuery('entityPolls', '', offset, isTemplate, search);
};
const gqlPractices = async (offset, isTemplate, search) => {
  if (useFixtures) return { errors: null, data: { resources: practice } };
  return await getEntityListQuery('entityPractices', '', offset, isTemplate, search);
};
const gqlSkills = async (offset, isTemplate, search) => {
  if (useFixtures) return { errors: null, data: { resources: skills } };
  return await getEntityListQuery(
    'entitySkills',
    `
  contains{
    LESSON
    PRACTICE
    POLL
  }
  `,
      offset,
      isTemplate,
      search
  );
};

const getAsyncFunction = (type, offset, isTemplate, search) => {
  switch (type) {
    case RESOURCES.MEDIA:
      return () => gqlMedia(offset, search);
    case RESOURCES.COURSE:
      return () => gqlCourses(offset, isTemplate, search);
    case RESOURCES.EXERCISE:
      return () => gqlExcercises(offset, isTemplate, search);
    case RESOURCES.LESSON:
      return () => gqlLessons(offset, isTemplate, search);
    case RESOURCES.POLL:
      return () => gqlPolls(offset, isTemplate, search);
    case RESOURCES.PRACTICE:
      return () => gqlPractices(offset, isTemplate, search);
    case RESOURCES.SKILL:
      return () => gqlSkills(offset, isTemplate, search);
    default:
      return null;
  }
};

export const getResourcesbyType = async (type, offset, isTemplate, search) => {
  const getResources = getAsyncFunction(type, offset, isTemplate, search);
  if (!getResources) return { errors: null, resources: [] };
  const result = await getResources(offset, isTemplate);
  return {
    errors: result.errors,
    resources: _.get(result, 'data.resources', []),
  };
};

export const deleteResource = async (type, resourceId) => {
  const service = getService(type);
  return service.deleteEntity(resourceId);
};

export const duplicateResource = async (type, resource, userId) => {
  if (useFixtures) {
    const id = (Math.random() * 10000).toString();
    return { item: { ...resource, id } };
  }
  const isCourse = type === BUILDER_TYPES.COURSE;
  const mutationFunction = isCourse ? `duplicateCourse` : `duplicateEntity`;
  ;
  const mutation = `
  mutation{
    ${mutationFunction}(copiedFrom:"${resource.id}", owner:"${userId}" ) {
      id
      name
      createdBy
    }
  }
  `;
  const result = await graphMutation(mutation, {}, true);
  ;
  const { data } = result;
  ;
  const entity = isCourse ? data?.duplicateCourse : data?.duplicateEntity;
  ;
  return {
    item: { ...resource, id: entity.id, name: entity.name },
  };
};

export const copyResource = async (type, resourceId) => {
  const mutationName = type === RESOURCES.LESSON  ? `copyEntityLesson` : `copyEntityExercise`;
  const mutation = `
    mutation {
      ${mutationName}(id: "${resourceId}") {
        id
      }
    }
  `;
  return await graphMutation(mutation, {}, true);
};
