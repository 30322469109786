import { styled } from '@mui/material';
import { getIconsByType, iconTypes } from '@services/icons';

const PauseIcon = getIconsByType(iconTypes.PAUSE);
const BORDER_STYLE = '1px white solid';

export const StyledPauseIconCornerMonitor = styled(PauseIcon)(
  {
    cursor: 'pointer',
    fontSize: 24,
    padding: 4,
    borderRadius: 2,
  },
  ({ theme }) => ({
    color: theme.palette.novotalk.$textColorReversed,
    backgroundColor: theme.palette.novotalk.$buttonColorDisabled,
    '&:hover': {
      backgroundColor: theme.palette.novotalk.$pauseIconColorHover,
    },
    '&:active': {
      backgroundColor: theme.palette.novotalk.$pauseIconColorActive,
    },
  }),
);

export const StyledButton = styled('button')(
  {
    outline: 'none',
    padding: 0,
    position: 'absolute',
    borderRight: BORDER_STYLE,
    borderBottom: BORDER_STYLE,
    zIndex: 2,
  },
  ({ isRTL }) => (isRTL ? { bottom: 2, right: 2 } : { top: 2, left: 2 }),
);
