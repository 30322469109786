import {styled} from "@mui/material";
import CTAButton from '@components/CTAButton';
import { getResponsiveCSS } from '@utils/cssHelpers';
import { getIconByResourceType } from '@services/icons';
import Loader from './components/Loader';

export const BuilderHeaderContainer = styled('div')(
  {
    paddingTop: 26,
  },
  ({ hasLastSavedItem }) =>
    hasLastSavedItem && getResponsiveCSS('marginRight', 0, -150)
);

export const DetailsContainer = styled('div')({
  width: '100%',
  display: 'flex',
  justifyContent: 'space-between',
});

export const NameArea = styled('div')({
  display: 'flex',
  alignItems: 'center',
});

export const getStyledResourceIcon = (type) =>
  styled(getIconByResourceType(type))(
    {
      fontSize: 24,
      marginRight: 10,
    },
    ({ theme }) => ({
      color: theme.palette.novotalk.$textColorDarkBlue,
    })
  );

export const StyledLoader = styled(Loader)(
  {
    width: 44,
    margin: '0 -14px',
  },
  ({ theme }) => ({
    color: theme.palette.novotalk.$textColorLightGray,
  })
);

export const ControlsArea = styled('div')({
  display: 'flex',
  alignItems: 'center',
});

export const StyledCTAButton = styled(CTAButton)(
  {
    width: 160,
    height: 44,
    margin: '0 5px',
  },
  getResponsiveCSS('height', 44, 48)
);

export const LastSavedContainer = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  lineHeight: '16px',
  width: 130,
  marginLeft: 15,
  '& span': {
    whiteSpace: 'pre',
  },
});
