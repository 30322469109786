import {styled} from "@mui/material";
import Text from "@components/Text";
import { isDesktop } from "@shared/constants/media-queries";
const isDesktopSize = isDesktop();

export const ProgressBarWrapper = styled('div')({
  display: 'flex',
  alignItems: 'center',
  marginBottom: 50,
  marginTop: -8,
  'div:first-child': {
    marginRight: 10,
  },
  '> span': {
    minWidth: 40
  }
});

export const SuccessRateWrapper = styled('div')({
  marginTop: 22,
});

export const SuccessRateTitle = styled(Text)({
  fontSize: isDesktopSize ? 16 : 14,
  fontWeight: 700,
});

export const SuccessRatePercent = styled(Text)({
  fontSize: isDesktopSize ? 18 : 16,
  fontWeight: 700,
});
