import React from 'react';
import { RESOURCES } from './types';

// Lists
import ListCourse from './Course/List';
import ListExercise from './Exercise/List';
import ListLesson from './Lesson/List';
import ListMedia from './Media/List';
import ListMonitor from './Monitor/List';
import ListPoll from './Poll/List';
import ListPractice from './Practice/List';
import ListSkill from './Skill/List';
import ListSpeechGoal from './SpeechGoal/List';
import ListStimulus from './Stimulus/List';

export const createList = (type, extraProps = { items: [], onSelect: null, onPreview: null, selectedItem: null }) => {
  switch (type) {
    case RESOURCES.COURSE: return <ListCourse {...extraProps} />;
    case RESOURCES.EXERCISE: return <ListExercise {...extraProps} />;
    case RESOURCES.LESSON: return <ListLesson {...extraProps} />;
    case RESOURCES.MEDIA: return <ListMedia {...extraProps} />;
    case RESOURCES.MONITOR: return <ListMonitor {...extraProps} />;
    case RESOURCES.POLL: return <ListPoll {...extraProps} />;
    case RESOURCES.PRACTICE: return <ListPractice {...extraProps} />;
    case RESOURCES.SKILL: return <ListSkill {...extraProps} />;
    case RESOURCES.SPEECH_GOAL: return <ListSpeechGoal {...extraProps} />;
    case RESOURCES.STIMULUS: return <ListStimulus {...extraProps} />;
    default: return null;
  }
};

