import React from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import injectReducer from '@utils/injectReducer';
import injectSaga from '@utils/injectSaga';
import { filter } from 'lodash';
import SectionLoading from '@components/SectionLoading';
import Search from './components/Search';
import MediaItem from './components/MediaItem';
import {
  MediaViewerContainer,
  MediaPageHeader,
  MediaPageHeaderContent,
  MediaList,
} from './styles';
import { getMediaItems } from './actions';
import reducer from './reducer';
import saga from './saga';

export class MediaPage extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      filter: '',
    };
  }

  componentDidMount() {
    if (this.props.items.length === 0) {
      this.props.getItems();
    }
  }

  onSearch(e) {
    e.preventDefault();
    const searchFilter = e.target.value;
    this.setState({ filter: searchFilter });
  }

  getFilteredList() {
    const { items, onItemSelect, loadingItems } = this.props;
    if (loadingItems) return [];

    const list = filter(items, (item) => {
      const byTitle =
        item.title.toLowerCase().indexOf(this.state.filter.toLowerCase()) >= 0;
      const byTags = item.tags.find(
        (tag) => tag.toLowerCase().indexOf(this.state.filter.toLowerCase()) >= 0
      );
      return byTags || byTitle;
    });
    return list.map((item) => (
      <MediaItem onClick={() => onItemSelect(item)} key={item.id} {...item} />
    ));
  }

  render() {
    const { loadingItems } = this.props;

    const filteredList = this.getFilteredList();
    return (
      <MediaViewerContainer>
        <MediaPageHeader>
          <MediaPageHeaderContent>
            <Search
              onChange={(e) => this.onSearch(e)}
              showCount={filteredList.length}
            />
          </MediaPageHeaderContent>
        </MediaPageHeader>
        <MediaList>
          {loadingItems ? <SectionLoading /> : filteredList}
        </MediaList>
      </MediaViewerContainer>
    );
  }
}

MediaPage.propTypes = {
  getItems: PropTypes.func,
  onItemSelect: PropTypes.func,
  loadingItems: PropTypes.bool,
  items: PropTypes.array,
};

MediaPage.defaultProps = {
  items: [],
};

const mapStateToProps = (state) => {
  const items = state.getIn(['mediaPage', 'items']).toJS();
  const loadingItems = state.getIn(['mediaPage', 'loadingItems']);
  return { items, loadingItems };
};

export function mapDispatchToProps(dispatch) {
  return {
    getItems: () => dispatch(getMediaItems()),
  };
}

const withConnect = connect(mapStateToProps, mapDispatchToProps);
const withReducer = injectReducer({ key: 'mediaPage', reducer });
const withSaga = injectSaga({ key: 'mediaPage', saga });

export default compose(withReducer, withSaga, withConnect)(MediaPage);
