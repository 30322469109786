import {
    ERROR_TYPES,
    LOCATION_TYPES
} from '@shared/Resources/Monitor/types';
import _ from 'lodash';

export const parsePayloadErrors = (payloadErrors, monitorProps) => {

    if (!payloadErrors) return [];
    const volumeControlBeginningErrors = [
        ERROR_TYPES.sharpRiseBeginning,
        ERROR_TYPES.volumeRiseBeginning,
        ERROR_TYPES.shapeBeginning,
    ];
    const volumeControlEndErrors = [
        ERROR_TYPES.shapeEnd,
        ERROR_TYPES.volumeFallEnd,
        ERROR_TYPES.sharpFallEnd,
    ];
    let types = structuredClone(monitorProps.errors.types);
    const hasVolumeControlBeginning = _.find(types, {
        type: ERROR_TYPES.volumeControlBeginning,
    });
    const hasVolumeControlEnd = _.find(types, {
        type: ERROR_TYPES.volumeControlEnd,
    });
    const volumeControlBeginning = {
        type: ERROR_TYPES.volumeControlBeginning,
        location: 'beginning',
        exists: false,
        displayed: false,
    };
    const volumeControlEnd = {
        type: ERROR_TYPES.volumeControlEnd,
        location: 'end',
        exists: false,
        displayed: false,
    };

    if (hasVolumeControlBeginning) {
        _.remove(types, { type: ERROR_TYPES.volumeControlBeginning });
        types = _.concat(
            types,
            volumeControlBeginningErrors.map((error) => ({ type: error }))
        );
    }
    if (hasVolumeControlEnd) {
        _.remove(types, { type: ERROR_TYPES.volumeControlEnd });
        types = _.concat(
            types,
            volumeControlEndErrors.map((error) => ({ type: error }))
        );
    }

    let parsedErrors = payloadErrors.map((error) => {
        const isErrorChecked = Boolean(_.find(types, { type: error.type }));
        const displayed = Boolean(error.exists && isErrorChecked);
        if (volumeControlBeginningErrors.includes(error.type)) {
            if (displayed) {
                volumeControlBeginning.displayed = true;
                volumeControlBeginning.exists = true;
            }
        }
        if (volumeControlEndErrors.includes(error.type)) {
            if (displayed) {
                volumeControlEnd.displayed = true;
                volumeControlEnd.exists = true;
            }
        }
        return {
            ...error,
            displayed,
        };
    });
    let nonGentleOnset = _.remove(parsedErrors, {
        type: ERROR_TYPES.nonGentleOnset,
    });
    let nonGentleOffset = _.remove(parsedErrors, {
        type: ERROR_TYPES.nonGentleOffset,
    });
    nonGentleOnset = nonGentleOnset.length > 0 ? nonGentleOnset[0] : null;
    nonGentleOffset = nonGentleOffset.length > 0 ? nonGentleOffset[0] : null;
    if (
        nonGentleOnset &&
        nonGentleOnset.displayed &&
        volumeControlBeginning.displayed
    ) {
        volumeControlBeginning.displayed = false;
    }
    if (
        nonGentleOffset &&
        nonGentleOffset.displayed &&
        volumeControlEnd.displayed
    ) {
        nonGentleOffset.displayed = false;
    }
    let displayedProductionErrors = _.remove(parsedErrors, {
        location: LOCATION_TYPES.PRODUCTION,
        displayed: true,
    });
    if (displayedProductionErrors.length > 1) {
        displayedProductionErrors = displayedProductionErrors.map((error) => {
            if (
                [
                    ERROR_TYPES.tooShortProduction,
                    ERROR_TYPES.tooLongProduction,
                ].includes(error.type)
            ) {
                return {
                    ...error,
                    displayed: false,
                };
            }
            return error;
        });
    }
    parsedErrors = _.concat(
        parsedErrors,
        volumeControlBeginning,
        volumeControlEnd,
        nonGentleOnset,
        nonGentleOffset,
        displayedProductionErrors
    );
    return _.compact(parsedErrors);
}