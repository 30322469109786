import gql from 'graphql-tag';
import format from 'date-fns/format';
import differenceInHours from 'date-fns/difference_in_hours';
import differenceInMinutes from 'date-fns/difference_in_minutes';
import _ from 'lodash';
import { overviewDateRange } from '@utils/timeHelpers';
import { graphQuery } from '../commService';
import { groupActivitiesToMonths } from './activitiesHelpers';
import { ACTIVITY_TYPES } from '@containers/Admin/containers/ActivityPage/constants';

const parseTime = (time) => parseInt(format(time, 'X'), 10) * 1000;

const parseActivities = (activities) =>
  activities.map((activity) => {
    const parsedActivity = {
      id: activity.id,
      activityLevel: activity.tpLevel + 1,
      user: activity.activityWorkoutUser.userName,
      userId: activity.activityWorkoutUser.userId,
      stage: activity.activityWorkoutUser.stage,
      type: 'Clinical',
      lastExercise: activity.timestamp * 1000,
      feedbackTime:
        activity.posts.length > 0
          ? parseTime(activity.posts[0].createdAt)
          : null,
    };
    parsedActivity.feedbackGap = parsedActivity.feedbackTime
      ? differenceInHours(
          parsedActivity.feedbackTime,
          parsedActivity.lastExercise
        )
      : differenceInHours(new Date(), parsedActivity.lastExercise);

    parsedActivity.feedbackGapFull = parsedActivity.feedbackTime
      ? differenceInMinutes(
          parsedActivity.feedbackTime,
          parsedActivity.lastExercise
        )
      : Infinity;

    parsedActivity.feedback = parsedActivity.feedbackTime ? 1 : 0;
    return parsedActivity;
  });

const parseActivityWorkoutStatsObject = (activityWorkout) => ({
  
  name: activityWorkout.tpName,
  level: activityWorkout.tpLevel,
  totalDurationInSeconds: activityWorkout.timerPos,
  timestamp: activityWorkout.timestamp,
  analysisMap: activityWorkout.stats.analysisMap,
  insights: activityWorkout.stats.insights,
  videos: activityWorkout.stats.videos,
  parts: activityWorkout.parts,
  posts: activityWorkout.posts,
});

const queryActivityWorkouts = async (props) => {
  const params = _.map(props, (value, key) => {
    switch (key) {
      case 'range':
        return `range: [${value[0]}, ${value[1]}]`;
      case 'omitPractices':
        return `omitPractices: ${value}`;
      case 'omitEmpty':
        return `omitEmpty: ${value}`;
      case 'showStages':
        return `showStages: [${value}]`;
      case 'hasFeedback':
        return `hasFeedback:${value}`;
      case 'dateRange':
        return `dateRange: [${value[1]},${value[0]}]`;
      case 'directConnections':
        return `directConnections: ${value}`;
      default:
        return '';
    }
  }).join(',');

  const query = gql`
  {
    activityWorkouts (${params}){
    id
    tpId
    tpLevel
    hasFeedback
    havePoll
    timestamp
    posts {
      createdAt
      data
      publisherUser {
        userId
        userName
        userRoles {
          userRole
        }
      }
    }
    activityWorkoutUser {
      userName
      userId
      stage
      userLastWorkoutDate
      pic
    }
  }
}
  `;

  const res = await graphQuery(query);
  return res.data.activityWorkouts;
};

export const getParsedActivityWorkouts = async (props) => {
  const activityWorkouts = await queryActivityWorkouts(props);
  const parsedActivityWorkouts = parseActivities(activityWorkouts);

  return parsedActivityWorkouts;
};

export const getSuperviserOverviewActivityWorkouts = async () =>
  getParsedActivityWorkouts({
    range: [0, 200],
    omitPractices: true,
    showStages: [1, 2],
    hasFeedback: 0,
    omitEmpty: true,
    dateRange: overviewDateRange(),
  });

export const getSuperviserAllActivites = async (dateRange) =>
  getParsedActivityWorkouts({
    range: [0, 200],
    omitPractices: true,
    showStages: [1, 2, 3, 4],
    dateRange: [dateRange[0], dateRange[1]],
    omitEmpty: true,
  });

export const getAllActivities = async (userId) => {
  const query = gql`
  {
    activities(userId:${userId}) {
      name
      type
      level
      activityId
      timestamp
    }
  }
  `;

  const res = await graphQuery(query);
  const activities = res.data.activities;
  const months = groupActivitiesToMonths(activities.map((a) => a));
  return months;
};

const parseActivitySpeechGoalStatsObject = (speechGoal) => ({
  name: speechGoal.entitySpeechGoal.description,
  type: 'SPEECH_GOAL',
  level: speechGoal.entitySpeechGoal.level,
  activityId: speechGoal.id,
  timestamp: speechGoal.timestamp,
  report: {
    feeling: speechGoal.feeling,
    focus: speechGoal.focus,
    useOfTechniques: speechGoal.useOfTechniques,
    difficulty: speechGoal.difficulty,
    fluency: speechGoal.fluency,
  },
  simulations: speechGoal.activitySpeechGoalsSimulations,
});

const getActivitySpeechGoalStats = async (activityId) => {
  const query = gql`
  {
    activitySpeechGoals(id:"${activityId}") {
      id
      timestamp
      feeling
      focus
      useOfTechniques
      difficulty
      fluency
      entitySpeechGoal {
        description
        level
      }
      activitySpeechGoalsSimulations {
        id
        duration
        createdAt
        focus
        events{
          timestamp
          type
        }
      }
    }
  }`;
  const res = await graphQuery(query);
  return parseActivitySpeechGoalStatsObject(res.data.activitySpeechGoals[0]);
};

const getActivityWorkoutStats = async (activityId) => {
  const query = gql`
  {
    activityWorkouts(id:${Number.parseInt(activityId, 10)}){
      tpName
      tpLevel
      timerPos
      timestamp
      stats {
        analysisMap
        insights
        videos
      }
      parts
      posts {
        data
        createdAt
        publisherUser {
          userName
          pic
        }
      }
    }
  }
  `;
  const res = await graphQuery(query);
  return parseActivityWorkoutStatsObject(res.data.activityWorkouts[0]);
};

export const getActivityStats = async (activityId, activityType) => {
  switch (activityType) {
    case ACTIVITY_TYPES.SPEECH_GOAL:
      return getActivitySpeechGoalStats(activityId);
    case ACTIVITY_TYPES.WORKOUT:
      return getActivityWorkoutStats(activityId);
    default:
      return [];
  }
};
