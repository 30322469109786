import { components } from 'react-select';
import Text, { TEXT_SIZE, TEXT_COLOR } from '@components/Text';
import CheckBox from '@components/CheckBox';

export const Option = (props) => (
  <components.Option {...props}>
    <CheckBox
      isDisabled={props.isDisabled}
      value={props.data.checked}
      label={
        <Text
          size={TEXT_SIZE.H4}
          textColor={TEXT_COLOR.DARK_BLUE}
        >
          {props.label}
        </Text>
      }
    />
  </components.Option>
);
