import React from 'react';

const icon = () => (<svg width="160px" height="160px" viewBox="0 0 160 160" version="1.1" xmlns="http://www.w3.org/2000/svg">
  <g id="Project-Styleguide" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
    <g id="Illustrations" transform="translate(-310.000000, -232.000000)">
      <rect fill="#FFFFFF" x="0" y="0" width="1440" height="520"></rect>
      <g id="Group-8" transform="translate(310.000000, 232.000000)">
        <path d="M159.458962,79.8784377 C159.458962,123.911396 123.764152,159.607919 79.7311932,159.607919 C35.6965222,159.607919 2.84217094e-14,123.911396 2.84217094e-14,79.8784377 C2.84217094e-14,35.8454789 35.6965222,0.148956661 79.7311932,0.148956661 C123.764152,0.148956661 159.458962,35.8454789 159.458962,79.8784377" id="Fill-7" fill="#E4F9FF"></path>
        <path d="M101.638095,42.6392723 L82.9157838,25.2130551 C81.1197432,23.5420011 78.3375067,23.5420011 76.541466,25.2130551 L57.9064741,42.6392723 L101.638095,42.6392723" id="Fill-8" fill="#D97912"></path>
        <polyline id="Fill-9" fill="#F9E6C8" points="119.11054 42.6392723 40.3501338 42.6392723 40.3501338 76.3000535 66.0323167 95.8681648 93.4283574 95.8681648 119.11054 76.3000535 119.11054 42.6392723"></polyline>
        <path d="M99.9482076,58.2814339 L58.8550027,58.2814339 C57.9098983,58.2814339 57.1428571,57.5161049 57.1428571,56.5692884 C57.1428571,55.6224719 57.9098983,54.8571429 58.8550027,54.8571429 L99.9482076,54.8571429 C100.893312,54.8571429 101.660353,55.6224719 101.660353,56.5692884 C101.660353,57.5161049 100.893312,58.2814339 99.9482076,58.2814339" id="Fill-10" fill="#F0891A"></path>
        <path d="M95.8242265,67.8052434 L64.1757735,67.8052434 C63.4478894,67.8052434 62.8571429,67.0399144 62.8571429,66.0930979 C62.8571429,65.1462814 63.4478894,64.3809524 64.1757735,64.3809524 L95.8242265,64.3809524 C96.5521106,64.3809524 97.1428571,65.1462814 97.1428571,66.0930979 C97.1428571,67.0399144 96.5521106,67.8052434 95.8242265,67.8052434" id="Fill-11" fill="#F0891A"></path>
        <path d="M99.9482076,77.329053 L58.8550027,77.329053 C57.9098983,77.329053 57.1428571,76.5637239 57.1428571,75.6169074 C57.1428571,74.670091 57.9098983,73.9047619 58.8550027,73.9047619 L99.9482076,73.9047619 C100.893312,73.9047619 101.660353,74.670091 101.660353,75.6169074 C101.660353,76.5637239 100.893312,77.329053 99.9482076,77.329053" id="Fill-12" fill="#F0891A"></path>
        <path d="M95.8242265,86.8528625 L64.1757735,86.8528625 C63.4478894,86.8528625 62.8571429,86.0875334 62.8571429,85.140717 C62.8571429,84.1939005 63.4478894,83.4285714 64.1757735,83.4285714 L95.8242265,83.4285714 C96.5521106,83.4285714 97.1428571,84.1939005 97.1428571,85.140717 C97.1428571,86.0875334 96.5521106,86.8528625 95.8242265,86.8528625" id="Fill-13" fill="#F0891A"></path>
        <polyline id="Fill-14" fill="#D97912" points="40.5350455 76.4404494 40.3501338 76.3000535 40.3501338 58.9080792 30.0772606 68.4721241 30.0772606 68.4738363 40.5350455 76.4404494"></polyline>
        <polyline id="Fill-15" fill="#D97912" points="119.11054 58.9080792 119.11054 76.3000535 118.754414 76.5705725 118.756126 76.5705725 129.383414 68.4738363 129.383414 68.4721241 119.11054 58.9080792"></polyline>
        <polyline id="Fill-16" fill="#F4942D" points="30.0772606 123.262493 129.383414 123.262493 93.4283574 95.8681648 66.0323167 95.8681648 30.0772606 123.262493"></polyline>
        <polyline id="Fill-17" fill="#F0891A" points="30.0772606 123.262493 30.0772606 68.4738363 66.0323167 95.8681648 30.0772606 123.262493"></polyline>
        <polyline id="Fill-18" fill="#F0891A" points="129.383414 123.262493 129.383414 68.4738363 93.4283574 95.8681648 129.383414 123.262493"></polyline>
      </g>
    </g>
  </g>
</svg>);

export default icon;

