import {styled} from "@mui/material";
import { getIconsByType, iconTypes } from '@services/icons';

export const Wrapper = styled('div')(
  {
    position: 'relative',
  },
  ({ isLoading }) => ({
    pointerEvents: isLoading ? 'none' : 'all',
  })
);

const iconStyles = ({ theme }) => ({
  color: theme.palette.novotalk.$activeColor,
  fontSize: 13,
});

export const StyledArrowUpIcon = styled(
  getIconsByType(iconTypes.FILLED_ARROW_UP)
)(iconStyles);
export const StyledArrowDownIcon = styled(
  getIconsByType(iconTypes.FILLED_ARROW_DOWN)
)(iconStyles);

export const MenuWrapper = styled('div')(
  {
    width: 120,
    position: 'absolute',
    top: 30,
    left: 58,
    borderRadius: 4,
    zIndex: 1000,
  },
  ({ theme }) => ({
    backgroundColor: theme.palette.novotalk.$backgroundColor,
    boxShadow: `0px 4px 17px 0px ${theme.palette.novotalk.$navigationMenuColorShadow}`,
  })
);

export const MenuItem = styled('div')(
  {
    height: 40,
    display: 'flex',
    alignItems: 'center',
    padding: '0 14px',
    cursor: 'pointer',
  },
  ({ theme }) => ({
    borderBottom: `1px solid ${theme.palette.novotalk.$resourcesTableBorderColor}`,
    '&:hover': {
      backgroundColor: theme.palette.novotalk.$dropdownBackgroundColorHover,
    },
    '&:active': {
      backgroundColor: theme.palette.novotalk.$dropdownBackgroundColorSelected,
    },
  })
);
