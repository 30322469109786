import { graphQuery, graphMutation } from '@services/commService';

export const getConversations = async () => {
  const query = `
  {
    conversations {
      id
      Users {
        id
        fullName
        avatar
        self
        isMentor
      }
      Thread {
        id
        unread
        closed{
          at
          by
        }
        Posts (range: [0,1]) {
          id
          publisherUser{
            id
            fullName
            avatar
          }
          createdAt
          self
          payload 
        }
      }
    }
  }
  `;
  const res = await graphQuery(query, true);
  const { conversations } = res.data;
  return conversations;
};

export const getFullConversation = async (id) => {
  const query = `
  {
    conversations (id: "${id}") {
      id
      Users {
        id
        fullName
        avatar
        self
        isMentor
      }
      Thread {
        id
        unread
        closed{
          at
          by
        }
        Posts {
          id
          publisherUser{
            id
            fullName
            avatar
          }
          createdAt
          self
          seenBy
          payload
        }
      }
    }  
  }
  `;

  const res = await graphQuery(query, true);
  const { conversations } = res.data;
  return conversations[0];
};

export const getUsers = async () => {
  const query = `
  {
    users {
      id
      fullName
      avatar
      self
      isMentor
    }
  }
  `;

  const res = await graphQuery(query, true);
  const { users } = res.data;
  return users;
};

export const createConversation = async (participantIds) => {
  const variables = { participantIds };
  const mutation = `
  mutation(
    $participantIds: [String]!
    ){
      createConversation( 
      participantIds: $participantIds
    ) {
      id
      Thread {
        id
      }
    }
  }
`;
  const res = await graphMutation(mutation, variables, true);
  const conversation = res.data.createConversation;
  return {
    newThreadId: conversation.Thread.id,
    newConversationId: conversation.id,
  };
};

export const closeThread = async (threadId) => {
  const variables = { id: threadId };
  const mutation = `
  mutation(
    $id: String!
    ){
      closeThread( 
      id: $id
    ) {
      id
    }
  }
`;
  const res = await graphMutation(mutation, variables, true);
  const { id } = res.data.closeThread;
  return id;
};

export const createPost = async (threadId, textMessage) => {
  const variables = { threadId, payload: { text: textMessage } };
  const mutation = `
  mutation(
    $threadId: String!
    $payload: JSON!
    ){
      createPost( 
        threadId: $threadId
        payload: $payload
    ) {
      id
    }
  }
`;
  const res = await graphMutation(mutation, variables, true);
  const { id } = res.data.createPost;
  return id;
};

export const markPostsAsSeen = async (ids) => {
  const variables = { id: ids, type: 'POST' };
  const mutation = `
  mutation(
    $id: [String]!
    $type: ActivityEntityEnumType!
    ){
      visit( 
        id: $id
        type: $type
    ) {
      id
    }
  }
`;
  const res = await graphMutation(mutation, variables, true);
  const visits = res.data.visit;
  return visits;
};
