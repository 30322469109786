import React, {useEffect, useState} from 'react';
import {
    Item,
} from "@shared/Resources/shared/components/OverviewListItem/styles";
import Loading from "@components/SectionLoading/index.js";
import {ListItem, SubList} from './styles'
import {
    SkillsItem
} from "@containers/Admin/containers/UserPage/user-page-wrapper/components/UserPersonalProgram/components/SkillsItem";
import {
    ListItemSection
} from "@containers/Admin/containers/UserPage/user-page-wrapper/components/UserPersonalProgram/components/ListItemSection";
import {RESOURCES} from "@shared/Resources/types";
import {useCustomSelector} from "src/hooks/useCustomSelector";
import {ISkill} from "@containers/Admin/containers/UserPage/user-page-wrapper/components/UserPersonalProgram/types";
import {StyledArrowDownIcon, StyledArrowRightIcon} from "./styles";

interface PersonalProgramListItemProps {
    item: ISkill
    isExpandedAll: boolean
}

export const SkillsListItem: React.FC<PersonalProgramListItemProps> = ({item, isExpandedAll}) => {
    const [itemOpen, setItemOpen] = useState(false)
    const {overview: {resource}} = useCustomSelector('resourceManager')
    const onItemToggle = () => setItemOpen(prev => !prev)
    const notFinishedLessons = item?.Lessons.filter((l) => l.isAccessible && !l.hasPassed)
    const notFinishedPractices = item?.Practices.filter((p) => p.isAccessible && !p.hasPassed)
    const highlightedByDefault = !!(notFinishedLessons?.length || notFinishedPractices?.length)

    useEffect(() => {
        setItemOpen(isExpandedAll)
    }, [isExpandedAll])

    useEffect(() => {
        highlightedByDefault && setItemOpen(highlightedByDefault)
    }, [])

    return (
        <ListItem>
            {itemOpen
                ? <StyledArrowDownIcon highlighted={highlightedByDefault} />
                : <StyledArrowRightIcon highlighted={highlightedByDefault} />
            }
            <Item>
                <SkillsItem
                    skill={item}
                    type={RESOURCES.SKILL}
                    isExpandable={true}
                    isFirstElement={true}
                    onExpand={onItemToggle}
                    isCurrentLevel={highlightedByDefault}
                />
            </Item>
            {(itemOpen) && <SubList>
                {resource ? <ListItemSection skill={item}/> : <Loading/>}
            </SubList>}
        </ListItem>
    );
};