import {styled} from "@mui/material";
import { getIconsByType, iconTypes } from "@services/icons";

export const BackButtonContainer = styled('div')({},
  ({ margin }) => ({ margin }),
);

const BackIconBase = getIconsByType(iconTypes.CHEVRON_LEFT);

export const BackIcon = styled(BackIconBase)({
  fontSize: 24,
  marginRight: 5,
});
