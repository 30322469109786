import {styled} from "@mui/material";

export const Wrapper = styled('div')(
  {
    backgroundColor: '#EEFBFF',
    alignSelf: 'stretch',
    display: 'flex',
    padding: '10px',
    margin: '10px',
    alignItems: 'center',
    justifyContent: 'flex-start',
    flexWrap: 'wrap',
    width: '70%',
    placeSelf: 'center',
    position: 'relative',
  },
  (props) => ({
    gridArea: props.gridArea,
  })
);

export const Button = styled('div')(
  {
    border: '1px solid #979797',
    borderRadius: '3px',
    width: '90px',
    height: '30px',
    fontSize: '16px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  (props) => ({
    backgroundColor: props.selected ? props.theme.palette.novotalk.cardHeader : '#FFF',
  })
);

export const Filter = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  margin: '30px',
});

export const FilterButtons = styled('div')({
  display: 'flex',
});

export const FilterTitle = styled('div')({
  fontSize: '16px',
  fontWeight: '700',
  margin: '10px 0',
});

export const ResetFilters = styled('div')(
  {
    position: 'absolute',
    right: '8px',
    top: '-22px',
    cursor: 'pointer',
  },
  ({ theme }) => ({
    color: theme.palette.novotalk.$novotalkBlue,
  })
);
