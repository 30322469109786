import { ReactComponent as OneSecondOutlineSvg } from './pattern-outlines/OneSecondOutline.svg';
import { ReactComponent as TwoSecondOutlineSvg } from './pattern-outlines/TwoSecondsOutline.svg';
import { ReactComponent as ThreeSecondOutlineSvg } from './pattern-outlines/ThreeSecondsOutline.svg';
import { ReactComponent as FourSecondOutlineSvg } from './pattern-outlines/FourSecondsOutline.svg';
import { ReactComponent as FiveSecondOutlineSvg } from './pattern-outlines/FiveSecondsOutline.svg';
import { ReactComponent as SixSecondOutlineSvg } from './pattern-outlines/SixSecondsOutline.svg';

const OneSecondOutline = () => (<OneSecondOutlineSvg />);
const TwoSecondOutline = () => (<TwoSecondOutlineSvg />);
const ThreeSecondOutline = () => (<ThreeSecondOutlineSvg />);
const FourSecondOutline = () => (<FourSecondOutlineSvg />);
const FiveSecondOutline = () => (<FiveSecondOutlineSvg />);
const SixSecondOutline = () => (<SixSecondOutlineSvg />);

export const ONE_SECOND_OUTLINES = {
    1: <OneSecondOutline />,
    2: <TwoSecondOutline />,
    3: <ThreeSecondOutline />,
    4: <FourSecondOutline />,
    5: <FiveSecondOutline />,
    6: <SixSecondOutline />,
};
