const iconTypes = {
  ADD: "ADD",
  ADD_CIRCLE: "ADD_CIRCLE",
  ANALYTICS: "ANALYTICS",
  AVATAR: "AVATAR",
  BOLD_CHECKMARK: "BOLD_CHECKMARK",
  CARD: "CARD",
  CHECKBOX_EMPTY: "CHECKBOX_EMPTY",
  CHECKBOX_FULL: "CHECKBOX_FULL",
  CHECKMARK: "CHECKMARK",
  CHEVRON_DOWN: "CHEVRON_DOWN",
  CHEVRON_LEFT: "CHEVRON_LEFT",
  CHEVRON_RIGHT: "CHEVRON_RIGHT",
  CHEVRON_UP: "CHEVRON_UP",
  CIRCLE_CHECKMARK: "CIRCLE_CHECKMARK",
  CIRCLE_CHECKMARK_FILLED: "CIRCLE_CHECKMARK_FILLED",
  CIRCLE_CHECKMARK_OVERFLOWED: "CIRCLE_CHECKMARK_OVERFLOWED",
  CLIPBOARD: "CLIPBOARD",
  CLOCK: "CLOCK",
  CLOSE: "CLOSE",
  CLOSE_CIRCLE: "CLOSE_CIRCLE",
  CLOSE_CIRCLE_OUTLINE: "CLOSE_CIRCLE_OUTLINE",
  COMMENTS: "COMMENTS",
  CREATE: "CREATE",
  CUSTOM_MAIL: "CUSTOM_MAIL",
  DELETE: "DELETE",
  DOCUMENT: "DOCUMENT",
  DRAG: "DRAG",
  DUPLICATE: "DUPLICATE",
  EDIT: "EDIT",
  ERROR: "ERROR",
  ERROR_FILLED: "ERROR_FILLED",
  FEEDBACK: "FEEDBACK",
  FILLED_ARROW_DOWN: "FILLED_ARROW_DOWN",
  FILLED_ARROW_LEFT: "FILLED_ARROW_LEFT",
  FILLED_ARROW_RIGHT: "FILLED_ARROW_RIGHT",
  FILLED_ARROW_UP: "FILLED_ARROW_UP",
  FILM: "FILM",
  FULL_ARROW_RIGHT: "FULL_ARROW_RIGHT",
  HAMBURGER: "HAMBURGER",
  HAPPY: "HAPPY",
  HEADSET: "HEADSET",
  HELP: "HELP",
  HELP_FILLED: "HELP_FILLED",
  HOME: "HOME",
  HOURGLASS: "HOURGLASS",
  IMAGES: "IMAGES",
  INFINITE: "INFINITE",
  INSIGHTS_GENTLE: "INSIGHTS_GENTLE",
  INSIGHTS_LENGTH: "INSIGHTS_LENGTH",
  INSIGHTS_PATTERN: "INSIGHTS_PATTERN",
  INSIGHTS_VOLUME_CONTROL: "INSIGHTS_VOLUME_CONTROL",
  INSIGHTS_TOO_FLAT: "INSIGHTS_TOO_FLAT",
  INSIGHTS_TOO_DEEP: "INSIGHTS_TOO_DEEP",
  INSIGHTS_TOO_SLOW: "INSIGHTS_TOO_SLOW",
  INSIGHTS_TOO_FAST: "INSIGHTS_TOO_FAST",
  KIDS: "KIDS",
  LIST: "LIST",
  LOCATION: "LOCATION",
  LOCK: "LOCK",
  LOGOUT: "LOGOUT",
  MAIL: "MAIL",
  MENTOR_DASHBOARD: "MENTOR_DASHBOARD",
  MENU: "MENU",
  MESSAGE: "MESSAGE",
  MICROPHONE: "MICROPHONE",
  MONITOR_INHALE: "MONITOR_INHALE",
  MUSICAL_NOTE: "MUSICAL_NOTE",
  NOTIFICATION: "NOTIFICATION",
  PATTERN_TYPE: "PATTERN_TYPE",
  PAUSE: "PAUSE",
  PERSON: "PERSON",
  PLAY: "PLAY",
  PLAY_CIRCLE: "PLAY_CIRCLE",
  PLUS_SQUARE: "PLUS_SQUARE",
  POWER: "POWER",
  PRACTICE_ERRORS: "PRACTICE_ERRORS",
  PREVIEW: "PREVIEW",
  PRODUCTION_DURATION: "PRODUCTION_DURATION",
  PROGRESS: "PROGRESS",
  PULSE: "PULSE",
  QUOTE: "QUOTE",
  RADIO_OFF: "RADIO_OFF",
  RADIO_ON: "RADIO_ON",
  RECREATION: "RECREATION",
  REFRESH: "REFRESH",
  REPETITION: "REPETITION",
  RESOURCES: "RESOURCES",
  ROLES_MANAGEMENT: "ROLES_MANAGEMENT",
  SAD_FACE: "SAD_FACE",
  SAVE: "SAVE",
  SCREEN_SHARING: "SCREEN_SHARING",
  SEARCH: "SEARCH",
  SETTINGS: "SETTINGS",
  SOUND_OFF: "SOUND_OFF",
  SOUND_ON: "SOUND_ON",
  SPEEDOMETER: "SPEEDOMETER",
  SPEEDOMETER_FILLED: "SPEEDOMETER_FILLED",
  STAR: "STAR",
  STOP_SCREEN_SHARING: "STOP_SCREEN_SHARING",
  SWAP: "SWAP",
  SYNC: "SYNC",
  TEXT: "TEXT",
  THIN_ARROW_LEFT: "THIN_ARROW_LEFT",
  THIN_ARROW_RIGHT: "THIN_ARROW_RIGHT",
  THUMBS_DOWN: "THUMBS_DOWN",
  THUMBS_UP: "THUMBS_UP",
  TIP: "TIP",
  TRENDING_UP: "TRENDING_UP",
  TV: "TV",
  USER_RECORDS: "USER_RECORDS",
  VERSION: "VERSION",
  VIDEO_CHAT: "VIDEO_CHAT",
  VIDEO_DEMO: "VIDEO_DEMO",
  VIDEO_LESSON: "VIDEO_LESSON",
  VIDEO_OFF: "VIDEO_OFF",
  VIDEO_ON: "VIDEO_ON",
  VOLUME_HIGH: "VOLUME_HIGH",
  WARNING: "WARNING",

};

export default iconTypes;
