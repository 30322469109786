export const syllablesStimuli = {
    textStimuli: [
        {
            id: 'asdf',
            locale: 'en',
            type: 'WORD',
            syllableCount: 1,
            wordsCount: 1,
            text: 'yat',
            words: [{
                locale: 'en',
                text : "yat",
                isNonsense: true,
                syllables: [
                    {
                        text : "yat",
                        sylType : "cvc",
                        vowel : "shorta",
                        isContinous : false,
                        isSemiVowel : true,
                        isPlosive : true
                    }
                ]
            }]
        },
        {
            id: 'sfsdfg',
            locale: 'en',
            type: 'WORD',
            syllableCount: 1,
            wordsCount: 1,
            text: 'ig',
            words: [{
                locale: 'en',
                text : "ig",
                isNonsense: true,
                syllables: [
                    {
                        text : "ig",
                        sylType : "vc",
                        vowel : "shorti",
                        isPlosive : true
                    }
                ]
            }]
        },
    ]
}

export const wordsStimuli = {
    textStimuli: [
        {
            id: 'dsdfss',
            locale: 'en',
            type: 'WORD',
            syllableCount: 3,
            wordsCount: 1,
            text: 'somebody',
            words: [{
                locale: 'en',
                text : "somebody",
                isNonsense: false,
                syllables: [
                    {
                        "text":"some",
                        "vowel":"shorto",
                        "isContinous":true,
                        "sylType":"cvc"
                    },
                    {
                        "text":"bo",
                        "sylType":"cv",
                        "isPlosive":true,
                        "vowel":"shorto"
                    },{
                        "text":"dy",
                        "sylType":"cv",
                        "isPlosive":true,
                        "vowel":"longe"
                    }
                ]
            }]
        },
        {
            id: 'fsdfdg',
            locale: 'en',
            type: 'WORD',
            syllableCount: 4,
            wordsCount: 1,
            text: 'anybody',
            words: [{
                locale: 'en',
                text : "anybody",
                isNonsense: false,
                syllables: [
                    {
                        "text":"a",
                        "sylType":"v",
                        "vowel":"shorte"
                    },
                    {
                        "text":"ny",
                        "sylType":"cv",
                        "vowel":"longe",
                        "isContinous":true
                    },
                    {
                        "text":"bo",
                        "sylType":"cv",
                        "isPlosive":true,
                        "vowel":"shorto"
                    },
                    {
                        "text":"dy",
                        "sylType":"cv",
                        "isPlosive":true,
                        "vowel":"longe"
                    }
                ]
            }]
        },
    ]
}

export const sentenceStimuli = {
    textStimuli: [
        {
            id: 'dsdfss',
            locale: 'en',
            type: 'SENTENCE',
            syllableCount: 12,
            wordsCount: 7,
            text: 'can anybody find me somebody to love',
            words: [
                {
                    locale: 'en',
                    text : "can",
                    isNonsense: false,
                    syllables: [
                        {
                            "text":"can",
                            "sylType":"cvc",
                            "vowel":"shorta",
                            "isPlosive":true,
                            "isContinous":true
                        }
                    ]
                },
                {
                    locale: 'en',
                    text : "anybody",
                    isNonsense: false,
                    syllables: [
                        {
                            "text":"a",
                            "sylType":"v",
                            "vowel":"shorte"
                        },
                        {
                            "text":"ny",
                            "sylType":"cv",
                            "vowel":"longe",
                            "isContinous":true
                        },
                        {
                            "text":"bo",
                            "sylType":"cv",
                            "isPlosive":true,
                            "vowel":"shorto"
                        },
                        {
                            "text":"dy",
                            "sylType":"cv",
                            "isPlosive":true,
                            "vowel":"longe"
                        }
                    ]
                },
                {
                    locale: 'en',
                    text : "find",
                    isNonsense: true,
                    syllables: [
                        {
                            "text":"find",
                            "sylType":"cvc",
                            "vowel":"longi",
                            "isContinous":true,
                            "isPlosive":true,
                            "isClusterEnd":true
                        }
                    ]
                },
                {
                    locale: 'en',
                    text : "me",
                    isNonsense: false,
                    syllables: [
                        {
                            "text":"me",
                            "sylType":"cv",
                            "vowel":"longe",
                            "isContinous":true
                        }
                    ]
                },
                {
                    locale: 'en',
                    text : "somebody",
                    isNonsense: false,
                    syllables: [
                        {
                            "text":"some",
                            "vowel":"shorto",
                            "isContinous":true,
                            "sylType":"cvc"
                        },
                        {
                            "text":"bo",
                            "sylType":"cv",
                            "isPlosive":true,
                            "vowel":"shorto"
                        },
                        {
                            "text":"dy",
                            "sylType":"cv",
                            "isPlosive":true,
                            "vowel":"longe"
                        }
                    ]
                },
                {
                    locale: 'en',
                    text : "to",
                    isNonsense: false,
                    syllables: [
                        {
                            "text":"to",
                            "sylType":"cv",
                            "vowel":"longu",
                            "isPlosive":true
                        }
                    ]
                },
                {
                    locale: 'en',
                    text : "love",
                    isNonsense: false,
                    syllables: [
                        {
                            "text":"love",
                            "sylType":"cvc",
                            "isContinous":true,
                            "vowel":"shwa"
                        }
                    ]
                },
            ]
        },
        {
            id: 'ascvf',
            locale: 'en',
            type: 'SENTENCE',
            syllableCount: 12,
            wordsCount: 7,
            text: 'can anybody find me somebody to love',
            words: [
                {
                    locale: 'en',
                    text : "can",
                    isNonsense: false,
                    syllables: [
                        {
                            "text":"can",
                            "sylType":"cvc",
                            "vowel":"shorta",
                            "isPlosive":true,
                            "isContinous":true
                        }
                    ]
                },
                {
                    locale: 'en',
                    text : "anybody",
                    isNonsense: false,
                    syllables: [
                        {
                            "text":"a",
                            "sylType":"v",
                            "vowel":"shorte"
                        },
                        {
                            "text":"ny",
                            "sylType":"cv",
                            "vowel":"longe",
                            "isContinous":true
                        },
                        {
                            "text":"bo",
                            "sylType":"cv",
                            "isPlosive":true,
                            "vowel":"shorto"
                        },
                        {
                            "text":"dy",
                            "sylType":"cv",
                            "isPlosive":true,
                            "vowel":"longe"
                        }
                    ]
                },
                {
                    locale: 'en',
                    text : "find",
                    isNonsense: true,
                    syllables: [
                        {
                            "text":"find",
                            "sylType":"cvc",
                            "vowel":"longi",
                            "isContinous":true,
                            "isPlosive":true,
                            "isClusterEnd":true
                        }
                    ]
                },
                {
                    locale: 'en',
                    text : "me",
                    isNonsense: false,
                    syllables: [
                        {
                            "text":"me",
                            "sylType":"cv",
                            "vowel":"longe",
                            "isContinous":true
                        }
                    ]
                },
                {
                    locale: 'en',
                    text : "somebody",
                    isNonsense: false,
                    syllables: [
                        {
                            "text":"some",
                            "vowel":"shorto",
                            "isContinous":true,
                            "sylType":"cvc"
                        },
                        {
                            "text":"bo",
                            "sylType":"cv",
                            "isPlosive":true,
                            "vowel":"shorto"
                        },
                        {
                            "text":"dy",
                            "sylType":"cv",
                            "isPlosive":true,
                            "vowel":"longe"
                        }
                    ]
                },
                {
                    locale: 'en',
                    text : "to",
                    isNonsense: false,
                    syllables: [
                        {
                            "text":"to",
                            "sylType":"cv",
                            "vowel":"longu",
                            "isPlosive":true
                        }
                    ]
                },
                {
                    locale: 'en',
                    text : "love",
                    isNonsense: false,
                    syllables: [
                        {
                            "text":"love",
                            "sylType":"cvc",
                            "isContinous":true,
                            "vowel":"shwa"
                        }
                    ]
                },
            ]
        },
    ]
}
