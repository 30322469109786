/**
 *
 * FeedbackPost
 *
 */

import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';

import {
  getUSADateFromFullDate,
  getUSATimeFromFullDate,
} from '@utils/timeHelpers';
import Text, { TEXT_SIZE, TEXT_COLOR } from '@components/Text';
import SectionLoading from '@components/SectionLoading';
import {
  FeedbackPostContainer,
  FeedbackPostHeader,
  PostAvatar,
  PostDetails,
  PostText,
  ActionButtonsWrapper,
  IconWrapper,
  StyledTextArea,
} from './styles';
import { editFeedback } from '../../actions';
import {
  CancelIcon,
  EditIcon,
  SaveIcon,
} from '@shared/Resources/Skill/Builder/styles';

const FeedbackPost = ({ post, postsLoading }) => {
  const [editing, setEditing] = useState(false);
  const [loading, setLoading] = useState(false);
  const feedbackRef = useRef();
  const dispatch = useDispatch();
  const userId = useSelector((state) =>
    state.getIn(['app', 'authentication', 'user', 'id'])
  );
  const canEdit = userId === post.publisherUser.id;

  useEffect(() => {
    if (editing) {
      feedbackRef.current.value = post.payload.text;
    }
  }, [editing]);

  useEffect(() => {
    if (postsLoading.includes(post.id)) {
      setLoading(true);
    } else {
      setLoading(false);
    }
  }, [postsLoading, post]);

  function handleEditFeedback() {
    if (loading) return;
    setEditing(true);
  }
  function handleCancelFeedback() {
    setEditing(false);
  }
  function handleSaveFeedback() {
    setEditing(false);
    dispatch(editFeedback(post.id, feedbackRef.current.value));
  }

  return (
    <FeedbackPostContainer>
      <FeedbackPostHeader>
        <PostAvatar
          className='posterAvatar'
          src={post.publisherUser.avatar}
        />
        <PostDetails>
          <Text
            size={TEXT_SIZE.H4}
            textColor={TEXT_COLOR.DARK_BLUE}
            className='posterName'
          >
            {post.publisherUser.fullName}
          </Text>
          <Text
            size={TEXT_SIZE.H4}
            textColor={TEXT_COLOR.DISABLED_LIGHT}
            className='postedOn'
          >
            {`${getUSADateFromFullDate(
              +post.createdAt
            )}  ${getUSATimeFromFullDate(+post.createdAt)}`}
          </Text>
        </PostDetails>
        {canEdit &&
          (editing ? (
            <ActionButtonsWrapper>
              <IconWrapper>
                <SaveIcon onClick={handleSaveFeedback} />
              </IconWrapper>
              <IconWrapper>
                <CancelIcon onClick={handleCancelFeedback} />
              </IconWrapper>
            </ActionButtonsWrapper>
          ) : (
            <ActionButtonsWrapper>
              <IconWrapper>
                <EditIcon onClick={handleEditFeedback} />
              </IconWrapper>
            </ActionButtonsWrapper>
          ))}
      </FeedbackPostHeader>
      <PostText>
        {loading ? (
          <SectionLoading size={50} />
        ) : editing ? (
          <StyledTextArea ref={feedbackRef} />
        ) : (
          <Text
            size={TEXT_SIZE.H4}
            textColor={TEXT_COLOR.DARK_BLUE}
            className='postText'
          >
            {post.payload.text}
          </Text>
        )}
      </PostText>
    </FeedbackPostContainer>
  );
};

FeedbackPost.propTypes = {
  post: PropTypes.shape({
    createdAt: PropTypes.string,
    publisherUser: PropTypes.shape({
      avatar: PropTypes.string,
      fullName: PropTypes.string,
    }),
    text: PropTypes.string,
    id: PropTypes.string,
  }),
};

export default FeedbackPost;
