/* global onUpdateRecieved, a */
/* eslint no-undef: "error" */
const { ccallArrays } = require('./wasm-arrays');

// import { ccall } from '../../../Resources/DSP_WASM/dsp';

let DSPCallback;

const isWASMReady = () => WASM_READY; // eslint-disable-line no-undef

const getOriginalGraphicValue = () => (isWASMReady()
  ? ccall('getOriginalGraphicValue', 'number', ['null'], [null]) // eslint-disable-line no-undef
  : -1);

const addSampleData = (buffer) => (isWASMReady()
  ? ccallArrays('addSampleData', 'null', ['array'], [buffer])
  : undefined);

const calibrateCancel = () => (isWASMReady()
  ? ccall('calibrateCancel', 'null', ['null'], [null]) // eslint-disable-line no-undef
  : -1);

const calibrate = (buffer) => (isWASMReady()
  ? ccallArrays('calibrate', 'null', ['array'], [buffer])
  : undefined);

const setSampleStart = (numberOfSamples, selfManagePace) => {
  if (selfManagePace) {
    return isWASMReady()
      ? ccall('setSampleStart', 'null', ['number', 'object'], [numberOfSamples, selfManagePace.min, selfManagePace.max]) // eslint-disable-line no-undef
      : -1;
  }
  return isWASMReady()
    ? ccall('setSampleStart', 'null', ['number'], [numberOfSamples]) // eslint-disable-line no-undef
    : -1;
};

const setSampleEnd = () => (isWASMReady()
  ? ccall('setSampleEnd', 'null', ['null'], [null]) // eslint-disable-line no-undef
  : -1);

const setDSPCallback = (cb) => {
  // sets callback function to listen to DSP invocations
  DSPCallback = cb;
};

const setAnalysisType = (monitorCode, language) => (isWASMReady(language)
  ? ccall('setAnalysisType', 'null', ['number', 'number'], [monitorCode, language]) // eslint-disable-line no-undef
  : -1);

onUpdateRecieved = (type, payload, vect) => {
  DSPCallback(type, payload, vect);
};

module.exports = {
  getOriginalGraphicValue,
  addSampleData,
  setDSPCallback,
  setSampleStart,
  setSampleEnd,
  setAnalysisType,
  calibrate,
  calibrateCancel,
};
