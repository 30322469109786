import {styled} from "@mui/material";

const Button = styled('button')(
  {
    width: '100%',
    height: 34,
    borderRadius: 5,
    color: 'white',
    outline: 'none',
    cursor: 'pointer',
    transition: '0.3s ease all',
  },
  ({ theme, cta, disabled }) => ({
    backgroundColor: cta ? theme.palette.novotalk.ctaButtonColor : theme.palette.novotalk.textColorDisabled,
    opacity: disabled ? 0.6 : 1,
    '&:hover': {
      backgroundColor: theme.palette.novotalk.borderColorLight,
      transition: '0.3s ease all',
    },
  })
);

export default Button;
