import { graphQuery, graphMutation } from '@services/commService';
import { userListOffset } from './helpers';
import {ORDERS} from "@shared/constants";

export const getUsers = async (roles, offset, isUserPage, sortBy = 'FULL_NAME', order = ORDERS.ASC) => {
  const userRoles = isUserPage ? roles : ['USER'];
  const limit = isUserPage ? userListOffset : 0;
  const pagination = isUserPage ? `offset: ${offset}, limit: ${limit}` : '';
  const query = `
    {
      allUsers(${pagination}, roles: ${userRoles}, sort: {sortBy: ${sortBy}, direction: ${order}}){
        id
        roles
        fullName
        lastName
        avatar
        email
        userName
        createdAt
        birthdate
        state
        status
        lastPracticed {
          type,
          date,
          skill {
            id
            name
          }
        }
        country
        mentor {
          fullName
        }
        course {
          name
        }
      }
    }
  `;
  const res = await graphQuery(query, true);
  return res.data;
};

export const getUser = async (id) => {
  const query = `
  {
    user (id: "${id}") {
      fullName
      avatar
      information {
        email
        username
        startDate
        age
        state
        country
        mentors {
          label
          attached
        }
        courses {
          label
          attached
        }
      },
      personalProgram: null, // TODO write schema
      log: null, // TODO write schema
    }
  }
  `;
  const res = await graphQuery(query, true);
  return res.data;
};

export const getCountUsers = async (roles) => {
  const query = `
    {
      allUsersCount (roles: ${roles})
    }
  `;
  const res = await graphQuery(query, true);
  return res.data;
};

export const sendUserMentors = async (mentors, userId) => {
  const variables = { mentors, userId };
  const mutation = ``; //TODO write schema
  return await graphMutation(mutation, variables, true);
};

export const sendUserCourse = async (course, userId) => {
  const variables = { course, userId };
  const mutation = ``; //TODO write schema
  return await graphMutation(mutation, variables, true);
};

export const setEntitiesAsCompletedRequest = async(entitiesLists)=>{
  const variables = {entitiesLists};
  const mutation =`
  mutation($entitiesLists: JSON){
    setEntitiesAsCompleted(entitiesLists: $entitiesLists)
  }
  `
const res = await graphMutation(mutation, variables, true);
return res
}
