import { fromJS } from 'immutable';
import { ROUTES } from '@shared/constants';
import { Types } from './actions';

export const initialState = fromJS({
  values: {
    email: '',
  },
  errors: {
    serverError: null,
  },
  sending: false,
  redirectToPage: null,
});

const passwordReducer = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case Types.SEND_PASSWORD_LINK: {
      return state.setIn(['values'], fromJS({ ...payload }));
    }

    case Types.SEND_PASSWORD_LINK_FAIL: {
      return state.setIn(['errors', 'serverError'], fromJS(payload.error));
    }

    case Types.SEND_PASSWORD_LINK_SUCCESS: {
      return state.setIn(['redirectToPage'], fromJS(ROUTES.RESET_EMAIL_SENT));
    }

    default: {
      return state;
    }
  }
};

export default passwordReducer;
