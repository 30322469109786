import { Component } from 'react';
import _ from 'lodash';
import PropTypes from 'prop-types';
import MediaSelector from '../shared/MediaSelector';
import Text, { TEXT_COLOR, TEXT_SIZE } from '@components/Text';
import Media from '../../Poll/Client/components/Media';
import { getOverviewByTypeAndID } from '@containers/Admin/shared/ResourcesWrapper/service/overviewService';
import { MediaContainer, StyledMediaIcon } from './styles';
import { RESOURCES } from '@shared/Resources/types';
import { RequiredSign } from '@shared/Resources/Monitor/shared/Titles/styles';

const NOT_REQUIRED_TYPE = 'Demo';

class MediaClient extends Component {
  constructor(props) {
    super(props);
    this.state = {
      payload: props.payload,
    };
  }

  onPayloadChange = (payload) => {
    const newPayload = { ...payload, title: this.state.payload.title };
    this.setState({ payload: newPayload });
    this.props.onChange({
      valid: Boolean(payload.value) === Boolean(payload.id),
      value: _.get(payload, 'id', null),
      additionalData: _.get(payload, 'value', null),
    });
  };

  onItemSelect = async (item) => {
    const media = await getOverviewByTypeAndID(RESOURCES.MEDIA, item.id);
    this.onPayloadChange({ ...item, value: media.overview.value });
  };

  clearMedia = () => {
    this.onPayloadChange({});
  };

  renderMedia = (value) => (
    <div>
      <MediaContainer>
        <Media
          noHeader
          contentUrl={value}
          contentType={'video'}
          description={'Demo'}
        />
      </MediaContainer>
      <MediaSelector
        isVideoExisted
        onItemSelect={this.onItemSelect}
        onItemRemove={this.clearMedia}
      />
    </div>
  );

  renderSelection = () => <MediaSelector onItemSelect={this.onItemSelect} />;

  render() {
    const { payload } = this.state;
    const { className } = this.props;
    return (
      <div className={className || 'resource-item'}>
        {payload.title && (
          <>
            <StyledMediaIcon />
            <Text
              size={TEXT_SIZE.T2}
              weight='900'
              textColor={TEXT_COLOR.DARK_BLUE}
            >
              {payload.title}
            </Text>
            {!payload.value
              && payload.title !== NOT_REQUIRED_TYPE
              && <RequiredSign>*</RequiredSign>
            }
          </>
        )}
        {payload.value
          ? this.renderMedia(payload.value)
          : this.renderSelection()}
      </div>
    );
  }
}

MediaClient.propTypes = {
  payload: PropTypes.object,
  onChange: PropTypes.func,
  className: PropTypes.string,
};

export default MediaClient;
