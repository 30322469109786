import { useState, useEffect } from 'react';
import { MONITOR_TYPES, PROGRESS_TYPE } from '@shared/Resources/Monitor/types';
import { getMinutesSecondsFromSeconds, getSecondsFromFormatTime } from '@utils/timeHelpers';
import { SubHeaderWrapper } from '@containers/User/containers/CourseRouter/containers/MonitorHandler/styles';
import { ProgressBarWrapper } from './components/progress-bar-wrapper';

const TOTAL_PERCENT = 100;
const ZERO_PERCENT = 0;

export const SubHeader = ({
  exerciseProgressTime,
  currentSequenceIndex,
  currentExercise,
  pauseExerciseHandler,
}) => {
  const [progressBarPercent, setProgressBarPercent] = useState(ZERO_PERCENT);
  const [monitorType, setMonitorType] = useState(MONITOR_TYPES.NONE);
  const [progressTotalAmount, setProgressTotalAmount] = useState(0);
  const [progressType, setProgressType] = useState(PROGRESS_TYPE.NO_TYPE);
  const [currentActivity, setCurrentActivity] = useState(ZERO_PERCENT);

  const handleCurrentExerciseErrors = () => {
    if (!currentExercise.monitor || !currentExercise.monitor.monitor || !currentExercise.monitor.monitor.type) {
      setMonitorType(MONITOR_TYPES.NONE);
    } else {
      setMonitorType(currentExercise.monitor.monitor.type);
    }
    if (!currentExercise.monitor || !currentExercise.monitor.progress || !currentExercise.monitor.progress.type) {
      setProgressType(PROGRESS_TYPE.NO_TYPE);
    } else {
      setProgressType(currentExercise.monitor.progress.type);
    }
    if (!currentExercise.monitor || !currentExercise.monitor.progress || !currentExercise.monitor.progress.value) {
      setProgressTotalAmount(ZERO_PERCENT);
    } else {
      setProgressTotalAmount(currentExercise.monitor.progress.value);
    }
  };

  const calculateDataByType = () => {
    switch (progressType) {
      case PROGRESS_TYPE.SEQUENCES:
        setProgressBarPercent(currentSequenceIndex <= progressTotalAmount
          ? (currentSequenceIndex / progressTotalAmount) * TOTAL_PERCENT
          : TOTAL_PERCENT);
        setCurrentActivity(currentSequenceIndex);
        break;
      case PROGRESS_TYPE.TIMER:
        setProgressBarPercent(exerciseProgressTime / getSecondsFromFormatTime(progressTotalAmount) * TOTAL_PERCENT);
        setCurrentActivity(getMinutesSecondsFromSeconds(exerciseProgressTime));
        break;
      default:
        setProgressBarPercent(ZERO_PERCENT);
        setCurrentActivity(ZERO_PERCENT);
    }
  };

  useEffect(() => {
    calculateDataByType();
  }, [progressType, exerciseProgressTime, currentSequenceIndex]);

  useEffect(() => {
    if (currentExercise) {
      handleCurrentExerciseErrors();
    }
  }, [currentExercise]);

  return (
    <SubHeaderWrapper>
      <ProgressBarWrapper
        monitorType={monitorType}
        progressBarPercent={progressBarPercent}
        progressType={progressType}
        currentActivity={currentActivity}
        progressTotalAmount={progressTotalAmount}
        pauseExerciseHandler={pauseExerciseHandler}
      />
    </SubHeaderWrapper>
  );
};
