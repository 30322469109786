import { takeLatest, call, put, all, fork, join } from 'redux-saga/effects';
import { resetPassword } from '@services/authenticationService';
import {
  Types,
  onPasswordResetFail,
  onPasswordResetSuccess,
  onPasswordChangeSuccess,
} from './actions';

function* callPostResetPasswordData(action) {
  try {
    const { recoveryToken, password, isPasswordChange } = action.payload;
    const res = yield fork(resetPassword, recoveryToken, password);
    const { token } = yield join(res);
    if (isPasswordChange) {
      yield put(onPasswordChangeSuccess(token));
    } else {
      yield put(onPasswordResetSuccess());
    }
  } catch (error) {
    yield put(onPasswordResetFail(error.message));
  }
}

function* watchCallPostResetPasswordData() {
  yield takeLatest(Types.PASSWORD_RESET, callPostResetPasswordData);
}

export default function* watchLoginResetPasswordActions() {
  yield all([fork(watchCallPostResetPasswordData)]);
}
