import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { Wrapper, SortDownIcon, SortUpIcon } from './styles';

export const ORDER = {
  ASC: 'asc',
  DESC: 'desc',
};

const SortOrder = ({ order }) => (
  <Wrapper>
    {order === ORDER.ASC && <SortUpIcon className="up" />}
    {order === ORDER.DESC && <SortDownIcon className="down" />}
  </Wrapper>
);

SortOrder.propTypes = {
  order: PropTypes.string,
};

export default SortOrder;
