import ProgressBar from '@components/ProgressBar';
import { ProgressAndButtonWrapper } from '@containers/User/containers/CourseRouter/containers/MonitorHandler/styles';
import { MONITOR_TYPES, PROGRESS_TYPE } from '@shared/Resources/Monitor/types';
import { WITHOUT_PROGRESS_EMPTY } from '@shared/constants';
import { NoiseCalibration } from '@components/NoiseCalibration';
import { ProgressCounter } from '../progress-counter';
import { ProgressWrapper } from './styles';

const PROGRESS_BAR_PARAMS = {
  WIDTH: 160,
  HEIGHT: 12,
};

export const ProgressBarWrapper = ({
  monitorType,
  progressBarPercent,
  currentActivity,
  progressTotalAmount,
  progressType,
  pauseExerciseHandler,
}) => (monitorType !== MONITOR_TYPES.NONE
  ? (
    <ProgressAndButtonWrapper>
      <ProgressWrapper>
        {progressType !== PROGRESS_TYPE.TIMER
          && (
          <ProgressBar
            progress={progressBarPercent}
            height={PROGRESS_BAR_PARAMS.HEIGHT}
            width={PROGRESS_BAR_PARAMS.WIDTH}
            border
          />
          )}
        <ProgressCounter
          progressType={progressType}
          currentActivity={currentActivity}
          progressTotalAmount={progressTotalAmount}
        />
      </ProgressWrapper>
      <NoiseCalibration pauseExerciseHandler={pauseExerciseHandler} />
    </ProgressAndButtonWrapper>
  )
  : WITHOUT_PROGRESS_EMPTY);
