export const jsonCircularHandler = (objToConvert) => {
  let cache = [];
  const json = JSON.stringify(objToConvert, (key, value) => {
    if (typeof value === 'object' && value !== null) {
      if (cache.includes(value)) return;
      cache.push(value);
    }
    return value;
  });
  cache = null;
  return json;
};
