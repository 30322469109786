import React from 'react';
import { VideoCallContainer } from '../VideoCallContainer';
import { useSelector, useDispatch } from 'react-redux';
import {
  setVideoChatConnectionStatusToConnected,
  setVideoChatConnectionStatusToDisconnected,
  textMessageReceived,
  changeStreamProperty,
  getChatInfo,
  setChatId,
  connectToChat,
} from '@shared/VideoChat/actions';

export const VideoCallPreContainer = ({ children }) => {
  const { sessionInfo, loading, error } = useSelector((state) => {
    return state.getIn(['videoChat']).toJS();
  });
  const dispatch = useDispatch();

  const onSessionConnected = () => {
    dispatch(setVideoChatConnectionStatusToConnected());
  };

  const onSessionDisconnected = (contextCallback) => () => {
    dispatch(setVideoChatConnectionStatusToDisconnected());
    contextCallback();
  };

  const onMessageReceived = (message) => {
    dispatch(textMessageReceived(message));
  };

  const onStreamPropertyChanged = (event) => {
    const participantId = event.stream.name;
    const properties = {
      [event.changedProperty]: event.newValue,
      isInSession: true,
    };
    dispatch(changeStreamProperty(participantId, properties));
  };

  const onStreamCreated = (event) => {
    const participantId = event.stream.name;
    dispatch(
      changeStreamProperty(participantId, {
        isInSession: true,
        leftSession: false,
      })
    );
  };

  const onStreamDestroyed = (event) => {
    const participantId = event.stream.name;
    dispatch(
      changeStreamProperty(participantId, {
        isInSession: false,
        leftSession: true,
      })
    );
  };
  const setIdChatHandler = (chatId) => {
    dispatch(getChatInfo(chatId));
    dispatch(setChatId(chatId));
  };
  const connectToChartHandler = (contextCallback) => {
    const sessionDisconnected = onSessionDisconnected(contextCallback);
    dispatch(
      connectToChat(sessionInfo.users, {
        onMessageReceived: onMessageReceived,
        onSessionDisconnected: sessionDisconnected,
        onSessionConnected: onSessionConnected,
        onStreamPropertyChanged: onStreamPropertyChanged,
        onStreamDestroyed: onStreamDestroyed,
        onStreamCreated: onStreamCreated,
      })
    );
  };

  if (error) return <div>{error}</div>;
  return (
    <VideoCallContainer
      users={sessionInfo.users}
      loading={loading}
      setIdChatHandler={setIdChatHandler}
      connectToChartHandler={connectToChartHandler}
    >
      {children}
    </VideoCallContainer>
  );
};
