import React from 'react';
import PropTypes from 'prop-types';
import { Wrapper } from './styles';
import TYPES from './types';

const CTAButton = ({
  onClick, children, id, className, primary, secondary, action, expanded, outline, disabled, cancel,
}) => {
  let type;
  let classString;
  if (secondary) {
    classString = 'secondaryButton ';
    type = TYPES.SECONDARY;
  } else if (action) {
    classString = 'actionButton ';
    type = TYPES.ACTION;
  } else if (outline) {
    classString = 'outlineButton ';
    type = primary ? TYPES.OUTLINE_PRIMARY : TYPES.OUTLINE;
  } else if (cancel) {
    classString = 'cancelButton ';
    type = TYPES.CANCEL;
  } else {
    classString = 'ctaButton ';
    type = TYPES.PRIMARY;
  }
  const props = {
    onClick,
    id,
    className: classString + className,
    type,
    primary,
    expanded,
    outline,
    disabled,
  };
  return (
    <Wrapper {...props}>
      {children}
    </Wrapper>);
};

CTAButton.defaultProps = {
  id: '',
  className: '',
  children: 'OK',
  onClick: () => {},
  secondary: false,
  action: false,
  expanded: false,
};

CTAButton.propTypes = {
  id: PropTypes.string,
  className: PropTypes.string,
  children: PropTypes.node,
  onClick: PropTypes.func,
  primary: PropTypes.bool,
  secondary: PropTypes.bool,
  action: PropTypes.bool,
  expanded: PropTypes.bool,
  outline: PropTypes.bool,
  disabled: PropTypes.bool,
  cancel: PropTypes.bool,
};

export default CTAButton;
