import {styled} from "@mui/material";
import DropdownDatePicker from '@components/DropdownDatePicker';

export const Wrapper = styled('div')(
  {
    minWidth: '800px',
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    flexShrink: 0,
    position: 'relative',
  },
  ({ theme }) => ({
    margin: theme.RTL ? '10px 30px 30px 0' : '10px 0 30px 30px',
  })
);

export const StyledDatePicker = styled(DropdownDatePicker)(
  {
    margin: '2px 0',
  },
  ({ theme, miniOverview }) =>
    miniOverview && {
      '& > div > div > div': {
        minWidth: 96,
        maxWidth: 96,
        backgroundColor: theme.palette.novotalk.$backgroundColor,
        '& span': {
          fontSize: 13,
          verticalAlign: 2,
        },
      },
    }
);
