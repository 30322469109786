export const MONITOR_TYPES = {
  LOUDNESS: 'LOUDNESS',
  BREATHING: 'BREATHING',
  NONE: 'NONE',
};

export const ERROR_TYPES = {
  volumeControlBeginning: 'volumeControlBeginning',
  volumeControlEnd: 'volumeControlEnd',
  nonGentleOnset: 'nonGentleOnset',
  sharpRiseBeginning: 'sharpRiseBeginning',
  volumeRiseBeginning: 'volumeRiseBeginning',
  shapeBeginning: 'shapeBeginning',
  notGentleBeginning: 'notGentleBeginning',
  excessivePeak: 'excessivePeak',
  softPeak: 'softPeak',
  shapeEnd: 'shapeEnd',
  volumeFallEnd: 'volumeFallEnd',
  sharpFallEnd: 'sharpFallEnd',
  nonGentleOffset: 'nonGentleOffset',
  notGentleEnd: 'notGentleEnd',
  notLoudProduction: 'notLoudProduction',
  notGentleProduction: 'notGentleProduction',
  tooShortProduction: 'tooShortProduction',
  noPatternProduction: 'noPatternProduction',
  tooLongProduction: 'tooLongProduction',
  tooShortInhalation: 'tooShortInhalation',
  nonGentleOnsetOsm:'nonGentleOnsetOsm',
  SoftPeakOsm: 'SoftPeakOsm',
  noLinkingOsm: 'noLinkingOsm',
  tooFlatOsm: 'tooFlatOsm',
  tooFastOsm: 'tooFastOsm',
  tooSlowOsm: 'tooSlowOsm',
  nonGentleOnsetSmm: 'nonGentleOnsetSmm',
  noLinkingSmm: 'noLinkingSmm',
  tooFlatSmm: 'tooFlatSmm',
  tooFastSmm: 'tooFastSmm',
  tooSlowSmm: 'tooSlowSmm',
};

export const DSP_INVOCATION_TYPES = {
  ADD_SAMPLE_DATA: 'addSampleData',
  ORIGINAL_DSP_ENGINE_ANALYSIS_GRAPHIC: 'originalDSPEngineAnalysisGraphicChunkAvailable',
  END_CALIBRATION: 'endCalibration',
}

export const GROUPED_ERRORS = {
  // volumeControlBeginning sub errors
  [ERROR_TYPES.sharpRiseBeginning]: ERROR_TYPES.volumeControlBeginning,
  [ERROR_TYPES.volumeRiseBeginning]: ERROR_TYPES.volumeControlBeginning,
  [ERROR_TYPES.shapeBeginning]: ERROR_TYPES.volumeControlBeginning,
  // 'volumeControlEnd' sub errors
  [ERROR_TYPES.shapeEnd]: ERROR_TYPES.volumeControlEnd,
  [ERROR_TYPES.volumeFallEnd]: ERROR_TYPES.volumeControlEnd,
  [ERROR_TYPES.sharpFallEnd]: ERROR_TYPES.volumeControlEnd,
};

export const PATTERN_TYPES = {
  WAVES: 'WAVES',
  GENTLE_VOICE: 'GENTLE_VOICE',
  LOUD_VOICE: 'LOUD_VOICE',
  VOICE_TRANSITION: 'VOICE_TRANSITION',
  SYMMETRICAL_TRANSITION: 'SYMMETRICAL_TRANSITION',
  TWO_SECONDS: 'TWO_SECONDS',
  ONE_SECOND: 'ONE_SECOND',
  SELF_MANAGED: 'SELF_MANAGED',
  PACE_CHANGES: 'PACE_CHANGES',
  INTEGRATED_SPEECH: 'INTEGRATED_SPEECH',
  INHALE: 'INHALE',
  BREATHING: 'BREATHING',
};

export const VIDEO_CHAT_MONITOR_TYPES = {
  BREATHING: 'BREATHING',
  WAVES: 'WAVES',
  GENTLE_VOICE: 'GENTLE_VOICE',
  LOUD_VOICE: 'LOUD_VOICE',
  VOICE_TRANSITION: 'VOICE_TRANSITION',
  TWO_SECONDS: 'TWO_SECONDS',
  ONE_SECOND: 'ONE_SECOND',
  SELF_MANAGED: 'SELF_MANAGED'
};

export const PRODUCTION_ERRORS = {
  EXCESSIVE_PEAK: 'EXCESSIVE_PEAK',
};

export const PATTERN_TYPES_TITLES = {
  BREATHING: 'Breathing',
  WAVES: 'Waves',
  GENTLE_VOICE: 'Gentle voice',
  LOUD_VOICE: 'Loud voice',
  VOICE_TRANSITION: 'Voice transition',
  SYMMETRICAL_TRANSITION: 'Symmetrical transition',
  TWO_SECONDS: '2 seconds',
  ONE_SECOND: '1 second',
  SELF_MANAGED: 'Self-managed',
  PACE_CHANGES: 'Pace changes',
  INTEGRATED_SPEECH: 'Integrated speech',
  INHALE: 'Inhale',
};

export const EXTRA_MONITOR_FEATURES = {
  INHALATION: 'INHALATION',
  MIRROR: 'MIRROR',
  SPEEDOMETER: 'SPEEDOMETER', // TBD: do not implement
  VIDEO: 'VIDEO',
};

export const DIFFICULTY = {
  EASY: 'EASY',
  MEDIUM: 'MEDIUM',
  HARD: 'HARD',
};

export const STIMULUS_TYPE = {
  SYLLABLES: 'SYLLABLES',
  WORDS: 'WORDS',
  SENTENCES: 'SENTENCES',
  PARAGRAPHS: 'PARAGRAPHS',
  SPEECH_TOPICS: 'SPEECH_TOPICS',
  PICTURES: 'PICTURES',
  CUSTOM: 'CUSTOM',
};


export const PROGRESS_TYPE = {
  SEQUENCES: 'SEQUENCES',
  TIMER: 'TIMER',
  NO_TYPE: 'NO_TYPE'
};


export const STIMULUS_DIVISION_TYPE = {
  DIVIDED: 'DIVIDED',
  DIVIDED_TO_WORDS_SYLLABLES: 'DIVIDED_TO_WORDS_SYLLABLES',
  UNDIVIDED: 'UNDIVIDED',
};

export const STIMULUS_WORDS_COUNT = {
  SHORT: 'SHORT',
  MEDIUM: 'MEDIUM',
  LONG: 'LONG',
};

export const STIMULUS_TEXT_SIZE = {
  DEFAULT: 40,
  SENTENCE: 60,
  SPEECH_TOPIC: 120,
  PICTURE: 300,
  CUSTOM: 230,
  PARAGRAPH_SHORT: 180,
  PARAGRAPH_MEDIUM: 230,
  PARAGRAPH_LONG: 330,
};

export const STIMULUS_SYLLABLE_STRUCTURE = {
  v: 'v',
  cv: 'cv',
  vc: 'vc',
  cvc: 'cvc',
};

export const STIMULUS_CONSONANTS_FEATURES = {
  PLOSIVE: 'PLOSIVE',
  CONTINUANT: 'CONTINUANT',
  CLUSTER_START: 'CLUSTER_START',
  CLUSTER_END: 'CLUSTER_END',
};
export const STIMULUS_WORDS = {
  REAL: 'REAL',
  NONSENSE: 'NONSENSE',
};
export const STIMULUS_SPEECH_TOPICS_TYPES = {
  BASIC: 'BASIC',
  ADVANCED: 'ADVANCED',
  QUESTION: 'QUESTION'
};
export const STIMULUS_PICTURES_TYPES = {
  BASIC: 'BASIC',
  ADVANCED: 'ADVANCED',
};

export const LOCATION_TYPES = {
  PRODUCTION: 'production',
  BEGINNING: 'beginning',
  PEAK: 'peak',
  END: 'end',
  INHALATION: 'inhalation',
  RIGHT_SIDE_HIGH: 'RIGHT_SIDE_HIGH',
  RIGHT_SIDE_MIDDLE: 'RIGHT_SIDE_MIDDLE',
};

export const MONITOR_HANDLER_STATUS = {
  PRODUCTION_INIT: 'productionInit',
  PRODUCTION_START: 'productionStart',
  MAIN_MONITOR_RESULTS: 'mainMonitorResults',
  SINGLE_MONITOR_PRODUCTION_END: 'singleMonitorProductionEnd'
}

export const INTONATION = {
  FACT: 'FACT',
  QUESTION: 'QUESTION',
};
