import { fromJS } from 'immutable';
import { Types } from './actions';

export const initialState = fromJS({
  errors: {
    serverError: null,
  },
  sending: false,
  redirectToPage: null,
});

const login = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case Types.SIGN_IN: {
      return state.setIn(['errors', 'serverError'], null)
        .set('sending', true);
    }
    case Types.SIGN_IN_FAIL: {
      return state.set('sending', false)
        .setIn(['errors', 'serverError'], payload.error);
    }
    case Types.SIGN_IN_SUCCESS: {
      return state.set('sending', false);
    }
    default: {
      return state;
    }
  }
};

export default login;
