import { takeLatest, put, call, all, fork, join } from 'redux-saga/effects';
import { createMediaItem } from '@services/mediaService';
import {
  Types,
  setError,
  uploadStatus,
  setUploadStatus,
  setLoadingProgress,
} from './actions';

const useFixtures = process.env.USE_FIXTURES;

const wait = (ms) =>
  new Promise((resolve) => {
    setTimeout(() => resolve(), ms);
  });

function* mockUpload() {
  yield put(setUploadStatus(uploadStatus.SENDING));
  yield fork(wait, 1000);
  yield put(setLoadingProgress(25));
  yield fork(wait, 1000);
  yield put(setLoadingProgress(50));
  yield fork(wait, 1000);
  yield put(setLoadingProgress(75));
  yield fork(wait, 1000);
  yield put(setLoadingProgress(100));
  yield fork(wait, 1000);

  yield put(setUploadStatus(uploadStatus.SENT));
}

export function* callSendMediaData(action) {
  try {
    if (useFixtures) {
      yield fork(mockUpload);
    } else {
      yield put(setUploadStatus(uploadStatus.SENDING));
      const res = yield fork(createMediaItem, action.payload);
      const mediaItem = yield join(res);
      yield put(setUploadStatus(uploadStatus.SENT, mediaItem));
    }
  } catch (error) {
    yield put(setError(error));
  }
}

function* watchCallSendMediaData() {
  yield takeLatest(Types.SEND_MEDIA_DATA, callSendMediaData);
}

export default function* mediaUploadSaga() {
  yield all([fork(watchCallSendMediaData)]);
}
