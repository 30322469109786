import _ from 'lodash';
import {finalResolution} from "@utils/cssHelpers";

export const camelCaseToWords = (camelCase) => camelCase
  .replace(/([A-Z])/g, ' $1')
  .split(' ').map((word) => _.capitalize(word))
  .join(' ');

export const capitalizeScreamingCase = (camelCase) => camelCase
  .replace(/([_])/g, ' ')
  .split(' ').map((word) => _.capitalize(word))
  .join(' ');

/**
 * Helper to make plural by adding a suffix
 * @param {number} count - count
 * @param {string} noun - basic word
 * @param {string} suffix - specific suffix for ending
 * @returns {string} - returned result
 * @example
 * pluralizeText(1, 'exercise'); // Returns: '1 exercise'
 * pluralizeText(5, 'production'); // Returns: '5 productions'
 */
export const pluralizeText = (count, noun, suffix = 's') =>
  `${count} ${noun}${count !== 1 ? suffix : ''}`;

/**
 * Helper that works with string. Slices it according to the screen size, adds ellipsis at the end
 * @param text
 * @param maxLength
 * @param shrinkText
 * @returns {string}
 * @example
 * sliceText('A short sentence', 5, true); // Returns: 'A sho...'
 * sliceText('Word', 4, true); // Returns: 'Word'
 */
export const sliceText = (text, maxLength, shrinkText) => {
    if(!text || !text.length) return

    const screenWidth = window.innerWidth
    let calculatedStringLength = maxLength
    if(shrinkText && finalResolution > screenWidth) {
        const differenceInPixels = finalResolution - screenWidth
        const averageValue = (finalResolution + screenWidth) / 2
        const differenceInPercents = (differenceInPixels / averageValue) * 100
        calculatedStringLength = maxLength - (maxLength / 100 * differenceInPercents)
    }
    if(text.length < calculatedStringLength) return `${text}`
    return `${text.length > maxLength ? text.slice(0, calculatedStringLength) : text}${(maxLength !== calculatedStringLength || text.length !== maxLength || !text.length === calculatedStringLength) ? '...' : ''}`
}
