import {styled} from "@mui/material";
import { Link } from 'react-router-dom';
import isPropValid from '@emotion/is-prop-valid';
import {
  getIconsByType,
  iconTypes,
  getIconByResourceType,
} from '@services/icons';
import Container from '@components/Container';
import progressConsts from '../../helpers/courseProgressConsts';
import courseTypes from '../../helpers/courseTypes';

const arrowStyle = {
  fontSize: 18,
  margin: '0 -3px',
};

export const Clock = getIconsByType(iconTypes.CLOCK);
export const Lock = getIconsByType(iconTypes.LOCK);
export const Checkmark = styled(getIconsByType(iconTypes.BOLD_CHECKMARK))({
  fontSize: 12,
});
export const ArrowRight = styled(getIconsByType(iconTypes.THIN_ARROW_RIGHT))(
  arrowStyle
);
export const ArrowLeft = styled(getIconsByType(iconTypes.THIN_ARROW_LEFT))(
  arrowStyle
);
export const PollIcon = getIconByResourceType(courseTypes.POLL);
export const LessonIcon = getIconByResourceType(courseTypes.LESSON);
export const PracticeIcon = getIconByResourceType(courseTypes.PRACTICE);

export const Wrapper = styled('div', { shouldForwardProp: isPropValid })({
  display: 'flex',
  margin: '10px 0',
  alignItems: 'center',
  justifyContent: 'space-between',
  textDecoration: 'none',
});

export const IconWraper = styled('div')(
  {
    display: 'flex',
    alignItems: 'center',
    fontSize: '14px',
    marginTop: 1,
    height: 21,
  },
  ({ status, theme }) => {
    const { LOCKED, COMPLETED, CURRENT } = progressConsts;

    let color;
    switch (status) {
      case LOCKED:
        color = theme.palette.novotalk.$backgroundColorDark;
        break;
      case COMPLETED:
        color = theme.palette.novotalk.$completedColor;
        break;
      case CURRENT:
        color = theme.palette.novotalk.$novotalkBlue;
        break;
      default:
        color = theme.palette.novotalk.$textColorDisabled;
        break;
    }
    return {
      color,
      [theme.RTL ? 'marginLeft' : 'marginRight']: '10px',
      paddingBottom: status === LOCKED ? '1px' : 0,
    };
  }
);

export const ClockIconWrapper = styled('div')(
  {
    margin: '0 10px',
    display: 'flex',
  },
  ({ theme }) => ({
    color: theme.palette.novotalk.$textColorDisabled,
  })
);

export const DurationWrapper = styled('div')({
  minWidth: 100,
  maxWidth: 100,
  display: 'flex',
  alignItems: 'center',
});

export const TextWrapper = styled(Link, { shouldForwardProp: isPropValid })(
  ({ theme, status, isDisplayed, isLocked }) => {
    const { LOCKED, COMPLETED, CURRENT } = progressConsts;
    let color;
    let colorHover;
    switch (status) {
      case LOCKED:
        color = theme.palette.novotalk.$textColorDisabledLight;
        colorHover = theme.palette.novotalk.$textColorDisabledLight;
        break;
      case COMPLETED:
        color = isDisplayed ? theme.palette.novotalk.$activeColor : theme.palette.novotalk.$textColorDarkBlue;
        colorHover = theme.palette.novotalk.$activeColor;
        break;
      case CURRENT:
        color = isDisplayed ? theme.palette.novotalk.$activeColor : theme.palette.novotalk.$textColorDarkBlue;
        colorHover = theme.palette.novotalk.$activeColor;
        break;
      // no default
    }
    return {
      color,
      cursor: isLocked ? 'not-allowed' : 'pointer',
      pointerEvents: isLocked ? 'none' : 'auto',
      width: '100%',
      lineHeight: '21px',
      '&:hover': {
        color: colorHover,
      },
    };
  }
);

export const StyledContainer = styled(Container)({
  width: '100%',
});
