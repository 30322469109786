import { call, put, takeLatest, all, fork, join } from 'redux-saga/effects';
import { authenticationWrapper } from '@services/errorHandlingService';
import { setCurrentPoll, TYPES } from './actions';
import poll from './__fixtures__/poll';
import hebrewPoll from './__fixtures__/pollHebrew';
import hebrewCompletedPoll from './__fixtures__/completedPollHebrew';
import completedPoll from './__fixtures__/completedPoll';
import { savePoll, getCurrentPoll } from '@containers/User/containers/CourseRouter/services.js';

const useFixtures = process.env.USE_FIXTURES;

const getPollById = (id, hebrewMock) => {
  if (hebrewMock) {
    return id === 'completedPollh' ? hebrewCompletedPoll : hebrewPoll;
  }
  return id === 'completedPoll' ? completedPoll : poll;
};

export function* callGetCurrentPoll({ id, hebrewMock }) {
  yield authenticationWrapper(function* codeBlock() {
    const res = yield fork(getCurrentPoll, id);
    const currentPoll = useFixtures
      ? getPollById(id, hebrewMock)
      : yield join(res);
    yield put(setCurrentPoll(currentPoll));
  });
}

export function* callSavePoll(action) {
  yield authenticationWrapper(function* codeBlock() {
    if (!process.env.USE_FIXTURES) yield fork(savePoll, action.poll);
  });
}
function* watchCallGetCurrentPoll() {
  yield takeLatest(TYPES.GET_CURRENT_POLL, callGetCurrentPoll);
}
function* watchCallSavePoll() {
  yield takeLatest('SAVE_POLL', callSavePoll);
}

export default function* pollSaga() {
  yield all([fork(watchCallGetCurrentPoll), fork(watchCallSavePoll)]);
}
