import React from 'react';
import { toggleShowMonitorVideoChat } from '../../actions';
import {
    ControlsIconWrapperVideoChat,
    StyledImage
} from '../../styles';
import MonitorCloseIcon from './monitor-close-icon.svg';
import { useDispatch } from 'react-redux';

export const MonitorCloseBtn = () => {
    const dispatch = useDispatch();

    const handleToggleShowMonitor = () => {
        dispatch(toggleShowMonitorVideoChat(false, false))
    }

    return (
        <ControlsIconWrapperVideoChat onClick={handleToggleShowMonitor}>
            <StyledImage src={MonitorCloseIcon} />
        </ControlsIconWrapperVideoChat>
    );
};
