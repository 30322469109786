export const USER_PAGE_ACTIONS = {
  FETCH_ONE_USER: 'USER_PAGE_ACTIONS/FETCH_ONE_USER',
  SET_ONE_USER: 'USER_PAGE_ACTIONS/SET_ONE_USER',
  SET_LOADING_STATUS: 'USER_PAGE_ACTIONS/SET_LOADING_STATUS',
  SHOW_ERROR: 'USER_PAGE_ACTIONS/SHOW_ERROR',

  FETCH_USER_PAGE_MENTORS: 'USER_PAGE_ACTIONS/FETCH_USER_PAGE_MENTORS',
  SET_USER_PAGE_MENTORS: 'USER_PAGE_ACTIONS/SET_USER_PAGE_MENTORS',
  ATTACH_USER_PAGE_MENTOR: 'USER_PAGE_ACTIONS/ATTACH_USER_PAGE_MENTOR',
  ATTACH_USER_PAGE_COURSE: 'USER_PAGE_ACTIONS/ATTACH_USER_PAGE_COURSE',
  CHANGE_USER_STATUS: 'USER_PAGE_ACTIONS/CHANGE_USER_STATUS',

  FETCH_USER_ACTIVITY_LOG: 'USER_PAGE_ACTIONS/FETCH_USER_ACTIVITY_LOG',
  FETCH_USER_ACTIVITY_DETAIL: 'USER_PAGE_ACTIONS/FETCH_USER_ACTIVITY_DETAIL',
  FETCH_USER_ACTIVITY_DETAIL_BY_EXTERNAL_ID: 'FETCH_USER_ACTIVITY_DETAIL_BY_EXTERNAL_ID',
  SET_USER_ACTIVITY_LOG: 'USER_PAGE_ACTIONS/SET_USER_ACTIVITY_LOG',
  SET_USER_ACTIVITY_DETAIL: 'USER_PAGE_ACTIONS/SET_USER_ACTIVITY_DETAIL',
  REMOVE_USER_ACTIVITY_DETAIL: 'USER_PAGE_ACTIONS/REMOVE_USER_ACTIVITY_DETAIL',

  FETCH_USER_PERSONAL_PROGRAM: 'USER_PAGE_ACTIONS/FETCH_USER_PERSONAL_PROGRAM',
  MOVE_USER_TO_COURSE_LEVEL: 'USER_PAGE_ACTIONS/MOVE_USER_TO_COURSE_LEVEL',

  SHOW_TOAST: 'USER_PAGE_ACTIONS/SHOW_TOAST',
};

export const putUserPageLoadingStatus = (status) => ({
  type: USER_PAGE_ACTIONS.SET_LOADING_STATUS,
  payload: status,
});

export const showUserPageError = () => ({
  type: USER_PAGE_ACTIONS.SHOW_ERROR,
});

export const fetchUserPage = (id) => ({
  type: USER_PAGE_ACTIONS.FETCH_ONE_USER,
  payload: id,
});

export const putUserPage = (user) => ({
  type: USER_PAGE_ACTIONS.SET_ONE_USER,
  payload: user,
});

export const putUserPageMentors = (mentors) => ({
  type: USER_PAGE_ACTIONS.SET_USER_PAGE_MENTORS,
  payload: mentors,
});

export const putUserActivityLog = (activities) => ({
  type: USER_PAGE_ACTIONS.SET_USER_ACTIVITY_LOG,
  payload: activities,
});

export const putUserActivityDetail = (activity) => ({
  type: USER_PAGE_ACTIONS.SET_USER_ACTIVITY_DETAIL,
  payload: activity,
});

export const removeUserActivityDetail = () => ({
  type: USER_PAGE_ACTIONS.REMOVE_USER_ACTIVITY_DETAIL,
});

export const attachUserPageMentor = (mentorId, userId, isRemove) => ({
  type: USER_PAGE_ACTIONS.ATTACH_USER_PAGE_MENTOR,
  payload: { mentorId, userId, isRemove },
});

export const attachUserPageCourse = (courseId, userId) => ({
  type: USER_PAGE_ACTIONS.ATTACH_USER_PAGE_COURSE,
  payload: { courseId, userId },
});

export const moveUserToCourseLevel = (payload) => ({
  type: USER_PAGE_ACTIONS.MOVE_USER_TO_COURSE_LEVEL,
  payload,
});

export const showToast = (toastType) => ({
  type: USER_PAGE_ACTIONS.SHOW_TOAST,
  payload: toastType,
});

export const changeUserStatus = (status, userId) => ({
  type: USER_PAGE_ACTIONS.CHANGE_USER_STATUS,
  payload: { status, userId },
});

export const fetchMentors = () => ({
  type: USER_PAGE_ACTIONS.FETCH_USER_PAGE_MENTORS,
});

export const fetchUserActivityLog = (userId) => ({
  type: USER_PAGE_ACTIONS.FETCH_USER_ACTIVITY_LOG,
  payload: userId,
});

export const fetchUserActivityDetail = (userId, activityId, activityType) => ({
  type: USER_PAGE_ACTIONS.FETCH_USER_ACTIVITY_DETAIL,
  payload: { userId, activityId, activityType },
});
export const fetchUserActivityDetailByExternalId = (userId, externalId, activityType) => ({
  type: USER_PAGE_ACTIONS.FETCH_USER_ACTIVITY_DETAIL_BY_EXTERNAL_ID,
  payload: { userId, externalId, activityType },
});

export const fetchUserPersonalProgram = (userId) => ({
  type: USER_PAGE_ACTIONS.FETCH_USER_PERSONAL_PROGRAM,
  payload: userId,
});
