//import FlakeId from 'flake-idgen';
import intformat from 'biguint-format';
import _ from 'lodash';
//const flakeIdGen = new FlakeId();

const BASE_TYPE = 'base';

class EditorObject {
  parent = null;
  children = [];
  type = BASE_TYPE;
  id = undefined;
  payload = {};

  static fromJSON({
    type: JSONtype,
    id: JSONid,
    children: JSONchildren,
    payload: JSONpayload,
  }) {
    const result = new EditorObject(JSONtype, JSONid).setPayload(
      JSONpayload || {}
    );
    const jsonChildren = _.clone(JSONchildren) || [];
    while (jsonChildren.length > 0) {
      const jsonChild = jsonChildren.shift();
      const item = EditorObject.fromJSON(jsonChild);
      result.addChild(item);
    }
    return result;
  }

  constructor(type = BASE_TYPE, id = null) {
    this.type = type;
    this.id = id || null;
    //intformat(flakeIdGen.next(), 'hex');
    return this;
  }

  toJSON() {
    const hirarchy = {
      type: this.type,
      id: this.id,
      payload: this.payload,
    };
    if (this.children.length > 0) {
      hirarchy.children = this.children.map((child) => child.toJSON());
    }
    return hirarchy;
  }

  addChild(item) {
    if (!(item instanceof EditorObject)) throw new Error('Type mismatch');
    if (this.isNestedIn(item)) throw new Error('Circular graph is now allowed');
    if (item.parent) {
      item.parent.removeChild(item);
    }

    this.children.push(item);

    item.setParent(this);
    return this;
  }

  addChildToTop(item) {
    if (!(item instanceof EditorObject)) throw new Error('Type mismatch');
    if (this.isNestedIn(item)) throw new Error('Circular graph is now allowed');
    if (item.parent) {
      item.parent.removeChild(item);
    }

    this.children.splice(0, 0, item);

    item.setParent(this);
    return this;
  }

  isNestedIn(item, maxDepth = Infinity) {
    let itemsToCheck = [...item.children];
    let nextLevelChildren = [];
    let currentDepth = 0;

    while (itemsToCheck.length > 0 && currentDepth < maxDepth) {
      const itemToCheck = itemsToCheck.pop();

      if (itemToCheck === this) return true;
      nextLevelChildren.push(...itemToCheck.children);

      if (itemsToCheck.length === 0 && nextLevelChildren.length > 0) {
        itemsToCheck = [...nextLevelChildren];
        nextLevelChildren = [];
        currentDepth += 1;
      }
    }
    return false;
  }

  removeChild(item) {
    if (this.children.includes(item)) {
      const index = this.children.indexOf(item);
      this.children.splice(index, 1);
      item.setParent(null);
    }
    return this;
  }

  setPayload(payload) {
    this.payload = _.cloneDeep(payload);
    return this;
  }

  setChildren(children) {
    this.children.forEach((child) => this.removeChild(child));
    this.children = children;
    return this;
  }

  setParent(parent) {
    if (this.parent) this.parent.removeChild(this);
    this.parent = parent;
    return this;
  }

  getChildById(id) {
    return this.children.find((child) => child.id === id);
  }
}

export default EditorObject;
