import React from 'react';
import PropTypes from 'prop-types';
import { Global } from '@emotion/core';
import LatoEot from "../../assets/fonts/Lato/Lato-Regular.eot";
import LatoWoff from "../../assets/fonts/Lato/Lato-Regular.woff";
import LatoWoff2 from "../../assets/fonts/Lato/Lato-Regular.woff2";
import LatoTtf from "../../assets/fonts/Lato/Lato-Regular.ttf";

const GlobalStyles = ({ theme }) => (
  <Global
    styles={{
      'html, input, textarea, select, button': {
        fontFamily: theme.RTL
          ? '"Assistant", sans-serif'
          : '"Lato", "Assistant", sans-serif',
      },
      '@font-face': {
        fontFamily: "Lato",
        fontStyle: "normal",
        fontWeight: 400,
        src: [
          'local("Lato")',
          `url(${LatoEot})`,
          `url(${LatoWoff2}) format("woff2")`,
          `url(${LatoWoff}) format("woff")`,
          `url(${LatoTtf}) format("truetype")`,
        ],
      },
    }}
  />
);

GlobalStyles.propTypes = {
  theme: PropTypes.object,
};

export default GlobalStyles;
