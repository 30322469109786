import React from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import Text, { TEXT_COLOR, TEXT_SIZE } from '@components/Text';
import { openPopUp as openPopUpAction } from '../../actions';
import POPUP_TYPES from '../../types';
import {
  Wrapper,
  HeaderContainer,
  IconContainer,
  KidsIcon,
  LocationIcon,
  SubscribeMessageContainer,
  StyledButton,
} from './styles';
import {useTranslation} from "react-i18next";

export const TYPES = {
  AGE: 'AGE',
  COUNTRY: 'COUNTRY',
  STATE: 'STATE',
};

const SignUpUnavailable = ({ onClose, type }) => {
  const {t} = useTranslation()
  let headerText;
  let icon;
  switch (type) {
    case TYPES.AGE:
      headerText = t('Authentication.Distribution.ageRestriction');
      icon = <KidsIcon />;
      break;
    case TYPES.COUNTRY:
      headerText = t('Authentication.Distribution.countryRestriction');
      icon = <LocationIcon />;
      break;
    case TYPES.STATE:
      headerText = t('Authentication.Distribution.stateRestriction');
      icon = <LocationIcon />;
      break;
    default:
    // no-default
  }
  return (
    <Wrapper>
      <HeaderContainer>
        <Text textColor={TEXT_COLOR.ACTIVE} size={TEXT_SIZE.T1}>
          {headerText}
        </Text>
        <Text textColor={TEXT_COLOR.DARK_BLUE} size={TEXT_SIZE.T1}>
          {t('Authentication.Distribution.workingOnIt')}
        </Text>
      </HeaderContainer>
      <IconContainer>{icon}</IconContainer>
      <SubscribeMessageContainer>
        <Text textColor={TEXT_COLOR.DARK_BLUE} size={TEXT_SIZE.T3}>
          {t('Authentication.Distribution.willNotify')}
        </Text>
      </SubscribeMessageContainer>
      <StyledButton id='ok_button' onClick={onClose}>
        {t('Actions.Simple.ok')}
      </StyledButton>
    </Wrapper>
  );
};

SignUpUnavailable.propTypes = {
  onClose: PropTypes.func.isRequired,
  type: PropTypes.string.isRequired,
};

export const mapDispatchToProps = (dispatch) => ({
  openInfoPopUp: () =>
    dispatch(openPopUpAction(POPUP_TYPES.AGE_NOTIFICATION_INFO)),
});

const withConnect = connect(null, mapDispatchToProps);

export default compose(
    withConnect
)(SignUpUnavailable);
