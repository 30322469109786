import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { compose } from 'redux';
import { connect } from 'react-redux';
import Text from '@components/Text';
import SectionLoading from '@components/SectionLoading';
import CTAButton from '@components/CTAButton';
import { WithMargin } from '../../styles';
import { Wrapper, ButtonContainer } from './styles';
import { uploadStatus } from '../../actions';
import {withTranslation} from "react-i18next";

export class LoadingArea extends React.Component {
  renderLoadingInProgress = () => (
    <span>
      <Text margin='0 0 8px 0'>
        {this.props.t('Informative.Statuses.uploadingFile')}
      </Text>
      <WithMargin marginTop={20}>
        <SectionLoading size={50} />
      </WithMargin>
    </span>
  );

  renderLoadingFinished = () => {
    const { onLoadingFinishedButtonClick, status, error } = this.props;
    const errorMessage = _.get(error, 'response.body.message') || error;
    const message =
      status === uploadStatus.SENT ? (
              this.props.t('Informative.Positive.loadedSuccessfully')
      ) : (
        <div>
          {this.props.t('Errors.loadedWithError', {error: errorMessage})}
        </div>
      );
    return (
      <span>
        <Text margin='20px 0 8px 0'>{message}</Text>
        <ButtonContainer>
          <CTAButton
            action
            data-cy='media_page_loading_porgress_modal_ok_button'
            onClick={onLoadingFinishedButtonClick}
          >
            {this.props.t('Actions.Simple.ok').toUpperCase()}
          </CTAButton>
        </ButtonContainer>
      </span>
    );
  };

  render() {
    const { status } = this.props;

    return (
      <Wrapper data-cy='media_page_loading_porgress_modal'>
        {status === uploadStatus.SENT || status === uploadStatus.ERROR
          ? this.renderLoadingFinished()
          : this.renderLoadingInProgress()}
      </Wrapper>
    );
  }
}

LoadingArea.propTypes = {
  status: PropTypes.string,
  onLoadingFinishedButtonClick: PropTypes.func,
  error: PropTypes.object,
};

LoadingArea.defaultProps = {
  status: uploadStatus.SENDING,
  onLoadingFinishedButtonClick: () => {},
  error: null,
};

const mapStateToProps = (state) => {
  const status = state.getIn(['mediaUploadReducer', 'uploadStatus']);
  const error = state.getIn(['mediaUploadReducer', 'error']);
  return { status, error };
};

const withConnect = connect(mapStateToProps);

export default compose(withConnect, withTranslation())(LoadingArea);
