/**
 *
 * BuilderBreadcrumbs
 *
 */
import React from 'react';
import PropTypes from 'prop-types';
import Text, { TEXT_SIZE } from '@components/Text';
import { ROUTES } from '~/shared/constants';
import {
  Wrapper,
  ResourceButton,
  getStyledResourceIcon,
  StyledHomeIcon,
  StyledChevron,
} from './styles';
import {sliceText} from "@utils/textHelpers";
import {useTranslation} from "react-i18next";

const breadcrumbsMaxLength = 50

export const BuilderBreadcrumbs = ({ breadcrumbs, onNavigation }) => {
    const {t} = useTranslation()
    return (
        <Wrapper>
            <ResourceButton
                onClick={() => onNavigation(ROUTES.RESOURCES_MANAGER)}
                className='base'
            >
                <StyledHomeIcon />
                <Text size={TEXT_SIZE.T4}>
                    {t('UI.resourceManager')}
                </Text>
            </ResourceButton>
            {breadcrumbs.map(({ type, info }, index) => {
                const ResourceIcon = getStyledResourceIcon(type);
                const isCurrent = index === breadcrumbs.length - 1;
                return (
                    <React.Fragment key={type}>
                        <StyledChevron />
                        <ResourceButton
                            className='crumb'
                            isCurrent={isCurrent}
                            onClick={() => onNavigation(`${ROUTES.BUILDER}/${type}/${info.id}`)}
                        >
                            <ResourceIcon />
                            <Text
                                size={TEXT_SIZE.T4}
                                className='name'
                                textTransform='capitalize'
                            >
                                {sliceText(info.name, breadcrumbsMaxLength, true)}
                            </Text>
                        </ResourceButton>
                    </React.Fragment>
                );
            })}
        </Wrapper>
    );
}

BuilderBreadcrumbs.propTypes = {
  breadcrumbs: PropTypes.array,
  onNavigation: PropTypes.func,
};

export default BuilderBreadcrumbs;
