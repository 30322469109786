import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import _ from 'lodash';
import TextButton from '@components/TextButton';
import Text, { TEXT_SIZE } from '@components/Text';
import ClickAwayListener from '@components/ClickAwayListener';
import CustomTextInput, { PLACEMENT } from '@components/CustomTextInput';
import ListItem from './ListItem/index';
import ListBackButton from './ListBackButton/index';
import {
  TemplateMenuHeader,
  ChevronUpIcon,
  ChevronDownIcon,
  MenuContainer,
  MenuContainerHeader,
  ItemsListContainer,
  GroupNameContainer,
  StyledSearchIcon,
} from './styles';
import {withTranslation} from "react-i18next";

export class AutoFeedback extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isMenuShown: false,
      filter: '',
      currentPath: [],
    };

    this.timer = 0;
  }

  onButtonClick = () => {
    this.setState((prevState) => ({
      isMenuShown: !prevState.isMenuShown,
      currentPath: [],
      filter: '',
    }));
  };

  onFilterChange = (event) => {
    this.setState({ filter: event.target.value });
  };

  onMouseLeaveList = () => {
    this.timer = setTimeout(() => {
      this.setState({
        isMenuShown: false,
      });
    }, 5000);
  };

  onMouseEnterList = () => {
    clearTimeout(this.timer);
  };

  onListItemDrilldown = (text) => {
    this.setState({
      currentPath: [...this.state.currentPath, text],
      filter: '',
    });
  };

  onListItemSelected = (value) => {
    this.setState({
      isMenuShown: false,
      filter: '',
    });
    this.props.onSelect(value);
  };

  onListBackButtonClick = (event) => {
    event.nativeEvent.stopImmediatePropagation();
    let { currentPath } = this.state;
    if (currentPath.length === 1) {
      currentPath = [];
    } else {
      currentPath.pop();
    }

    this.setState({
      currentPath,
      filter: '',
    });
  };

  handleAwayClick = () => {
    this.setState({ isMenuShown: false });
  };

  renderItemsList = () => {
    const { currentPath, filter } = this.state;
    const { dataTree, storybook } = this.props;

    const newTree = _.get(dataTree, currentPath, dataTree);
    const keys = Object.keys(newTree);
    const filteredKeys = [];

    for (let i = 0; i < keys.length; i += 1) {
      const lowString = keys[i].toLowerCase();
      const lowFilter = filter.toLowerCase();

      if (lowString.includes(lowFilter)) {
        filteredKeys.push(keys[i]);
      }
    }

    const list = filteredKeys.map((key, index) => (
      <ListItem
        key={`${key}_${index}`}
        itemName={key}
        itemValue={newTree[key]}
        onListItemDrilldown={this.onListItemDrilldown}
        onListItemSelected={this.onListItemSelected}
        storybook={storybook}
      />
    ));

    return <ItemsListContainer>{list}</ItemsListContainer>;
  };

  renderCurrentPath = () => {
    const { currentPath } = this.state;
    const { groupName } = this.props;

    return (
      <span>
        {currentPath.length === 0 ? (
          <GroupNameContainer>
            <Text id='autofeedback_group_name' weight='bold'>
              {groupName}
            </Text>
          </GroupNameContainer>
        ) : (
          <ListBackButton
            text={currentPath[currentPath.length - 1]}
            onBackClick={this.onListBackButtonClick}
          />
        )}
      </span>
    );
  };

  renderMenuContainerHeader = () => (
    <MenuContainerHeader>
      <CustomTextInput
        id='autofeedback_filter_input'
        name='autofeedback-filter'
        placeholder='Search'
        type='text'
        onChange={this.onFilterChange}
        value={this.state.filter}
        Icon={StyledSearchIcon}
        iconPlacement={PLACEMENT.RIGHT}
        autofocus
      />
    </MenuContainerHeader>
  );

  render() {
    const { isMenuShown } = this.state;
    const {t} = this.props;
    const Icon = isMenuShown ? ChevronUpIcon : ChevronDownIcon;
    return (
      <div id='template_feedback_selection'>
        <ClickAwayListener onClickAway={this.handleAwayClick}>
          <TextButton onClick={this.onButtonClick}>
            <TemplateMenuHeader>
              <Text size={TEXT_SIZE.T4}>
                {t("Actions.Multiple.selectTemplate")}
              </Text>
              <Icon />
            </TemplateMenuHeader>
          </TextButton>
          {isMenuShown && (
            <MenuContainer
              onMouseLeave={this.onMouseLeaveList}
              onMouseEnter={this.onMouseEnterList}
            >
              {this.renderCurrentPath()}
              {this.renderMenuContainerHeader()}
              {this.renderItemsList()}
            </MenuContainer>
          )}
        </ClickAwayListener>
      </div>
    );
  }
}

AutoFeedback.defaultProps = {
  dataTree: {},
  groupName: '',
  storybook: false,
};

AutoFeedback.propTypes = {
  dataTree: PropTypes.object.isRequired,
  groupName: PropTypes.string.isRequired,
  storybook: PropTypes.bool,
  onSelect: PropTypes.func,
};

export default compose(withTranslation())(AutoFeedback);
