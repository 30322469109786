import React from 'react';
import {IconWrapper, ListItem, MoveBtn, NameContainer, NameText, getStyledIconByType} from './styles'
import {ProgressIcon, moveHereHelper} from "./helpers";
import {ILesson, IPractice, ISkill
} from "@containers/Admin/containers/UserPage/user-page-wrapper/components/UserPersonalProgram/types";
import {RESOURCES} from "@shared/Resources/types";
import {useTranslation} from "react-i18next";
import { useCustomSelector } from 'src/hooks/useCustomSelector';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';

interface BaseListItemProps {
    skill?: ISkill
    resource?: ILesson | IPractice
    type: string
    isExpandable?: boolean,
    onExpand?: () => void,
    isFirstElement: boolean
    isCurrentLevel?: boolean
}

export const SkillsItem: React.FC<BaseListItemProps> =
    ({
        skill,
         resource,
         type,
         isExpandable,
         onExpand,
         isFirstElement,
        isCurrentLevel
     }) => {
        const {overview} = useCustomSelector('resourceManager');
        const TypeIcon = getStyledIconByType(type);
        const {t} = useTranslation();
        const {id:userId} = useParams<{ id: string }>();
        const dispatch = useDispatch();
        const moveHandler =():void=>{
            const {resource:{Skills}} = overview
            const id = resource?.id ?? ''
            moveHereHelper(Skills,id,dispatch,userId)
        }
        return (
            <ListItem isFirstElement={isFirstElement}>
                {resource && !isFirstElement && <IconWrapper><ProgressIcon hasPassed={resource.hasPassed} isAccessible={resource.isAccessible} /></IconWrapper>}
                <TypeIcon isCurrentLevel={isCurrentLevel}/>
                <NameContainer>
                    <NameText
                        isCurrentLevel={isCurrentLevel}
                        isExpandable={isExpandable}
                        onClick={isExpandable ? onExpand : () => {
                        }}
                    >
                        {resource ? resource.name : skill?.name}
                    </NameText>
                </NameContainer>
                <MoveBtn
            onClick={moveHandler}
            className='moveButton'
            hideButton={isFirstElement||resource?.isAccessible}
          >
            {t('Actions.Navigation.moveHere')}
          </MoveBtn>
            </ListItem>
        );
    };