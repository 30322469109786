export const TYPES = {
  CONNECT_TO_MIRROR_SESSION: 'CONNECT_TO_MIRROR_SESSION',
  SET_MIRROR_STATUS_TO_CONNECTED: 'SET_MIRROR_STATUS_TO_CONNECTED',
  SET_MIRROR_STATUS_TO_DISCONNECTED: 'SET_MIRROR_STATUS_TO_DISCONNECTED',
  MIRROR_ERROR: 'MIRROR_ERROR',
  START_VIDEO_RECORDING: 'START_VIDEO_RECORDING',
  DISCONNECT_FROM_MIRROR_SESSION: 'DISCONNECT_FROM_MIRROR_SESSION',
  STOP_VIDEO_RECORDING: 'STOP_VIDEO_RECORDING',
};

export const connectToMirrorSession = (options) => ({
  type: TYPES.CONNECT_TO_MIRROR_SESSION,
  options,
});

export const setMirrorConnectionStatusToConnected = () => ({
  type: TYPES.SET_MIRROR_STATUS_TO_CONNECTED,
});

export const setMirrorConnectionStatusToDisconnected = () => ({
  type: TYPES.SET_MIRROR_STATUS_TO_DISCONNECTED,
});

export const onError = (error) => ({
  type: TYPES.MIRROR_ERROR,
  error,
});

export const startVideoRecording = (activityContext) => ({
  type: TYPES.START_VIDEO_RECORDING,
  activityContext,
});

export const disconnectFromMirrorSession = (archiveId) => ({
  type: TYPES.DISCONNECT_FROM_MIRROR_SESSION,
  archiveId
});

export const stopVideoRecording = () => ({
  type: TYPES.STOP_VIDEO_RECORDING,
});
