import {
  TYPES,
  QUESTION_TYPES,
  MEDIA_TYPES,
  BUILDER_TYPES as builderTypes } from '@shared/Resources/types';

export const EDITOR_MEDIA_TYPES = {
  ...MEDIA_TYPES,
};

export const EDITOR_QUESTION_TYPES = {
  ...QUESTION_TYPES,
};

export const BUILDER_TYPES = builderTypes;
export const EDITOR_OBJECT_TYPES = TYPES; // alias
