/* eslint-disable react/no-unescaped-entities */
import React from 'react';
import Text, { TEXT_COLOR, TEXT_SIZE } from '@components/Text';
import Container from '@components/Container';

const PrivacyPolicy = () => (
  <Container column margin="20px">
    <Container column margin="0 0 40px 0">
      <Text textColor={TEXT_COLOR.NORMAL} weight="bold" size={TEXT_SIZE.T1} >
        Privacy Policy
      </Text>
      <Text textColor={TEXT_COLOR.NORMAL} size={TEXT_SIZE.T3} >
            (Date of Last Revision: March 05, 2019)
      </Text>
    </Container>
    <Container column alignItems="flex-start">
      <Text textColor={TEXT_COLOR.NORMAL} margin="0 0 10px 0">
          Novotalk Ltd. (hereinafter referred to as: <b>“Novotalk”</b>, <b>“we”</b> or <b>“us”</b>) provides information regarding services and other content offered by Novotalk, including Novotalk’s unique teletherapy tutorial platform that utilizes technology designed to teach, implement, practice and preserve fluency shaping techniques and overcome speech disorders and impediments, and the ability for customers to access, utilize and purchase the same or contact Novotalk (hereinafter referred to as: the <b>“Services”</b>). Novotalk is committed to protecting your privacy and utilizing technology that gives you a powerful and safe online experience. The website Novotalk.com, and associated mobile application (collectively, the <b>“Site”</b>) is owned and operated by Novotalk.
      </Text>
      <Text textColor={TEXT_COLOR.NORMAL} margin="0 0 10px 0">
          Please read carefully through all sections of this Privacy Policy.  This Privacy Policy covers our treatment of Personal Data that we collect through your use of the Site and when you use Services provided on the Site.  This policy does not apply to the practices of companies that we do not own and/or control or to people that we do not employ or manage.
      </Text>
      <Text textColor={TEXT_COLOR.NORMAL} margin="0 0 10px 0">
          This Privacy Policy may be changed by us from time to time and the governing version will be posted on the Site.  We will notify you if we make material changes to the Privacy Policy or we will provide notice to you of our changes on our website landing page.  Please review this Privacy Policy on a regular basis as your use of the Site will be governed by the then-current Privacy Policy.
      </Text>
      <Text textColor={TEXT_COLOR.NORMAL} margin="0 0 10px 0">
          Novotalk recognizes the importance of protecting the privacy of our customers and the users of the Site.  As such, we will always ensure that we have a lawful basis for processing your Personal Data
      </Text>
      <Text textColor={TEXT_COLOR.NORMAL} margin="0 0 10px 0">
        <b>Notice to California, U.S.A. residents</b><br />
          Except as expressly identified below, we do not disclose a user’s personal data to any third party for such third party’s direct marketing purposes.  Thus, under California Civil Code Sections 1798.80-1798.84, Novotalk has no obligation to a California resident to provide further information regarding Novotalk’s use of the California resident’s personal data.  Any inquiries regarding the Terms of Use or, specifically, this Privacy Policy can be directed to the contact identified below.
      </Text>
      <Text textColor={TEXT_COLOR.NORMAL} margin="0 0 10px 0">
        <b>Notice to Utah, U.S.A. residents</b><br />
          Except as expressly identified below, Novotalk does not disclose a user’s personal data to any third party for such third party’s direct marketing purposes.
      </Text>
      <Text textColor={TEXT_COLOR.NORMAL} margin="0 0 10px 0">
        <b>HIPAA Compliance</b><br />
          Part of the Service may include Novotalk’s use and receipt of your Protected Health Information or PHI that is subject to the requirements of the Health Insurance Portability and Accountability Act of 1996 and its implementing regulations (“HIPAA”) and applicable state law. The term “Protected Health Information” or “PHI” refers to individually identifiable health information about your past, present or future physical or mental health or condition, the provision of health care to you or the past, present or future payment for such care. If any information collected on this Site or the Services constitutes PHI, then our Notice of Privacy Practices <Text textColor={TEXT_COLOR.HIGHLIGHTED} >[Note to Draft: Please hyperlink to Novotalk’s Notice of Privacy Practices]</Text> will apply.  In the event the terms of the Notice of Privacy Practices conflicts with the terms of this Privacy Policy, the terms of the Notice of Privacy Practices shall control.
      </Text>
      <Text textColor={TEXT_COLOR.NORMAL} margin="0 0 10px 0">
        <b>What this Privacy Policy Covers</b><br />
          This Privacy Policy covers our treatment of Personal Data that we collect when you are on the Site and when you use the Services.  This Privacy Policy does not apply to the practices of companies that we do not own and/or control or to people that we do not employ or manage.
      </Text>
      <Text textColor={TEXT_COLOR.NORMAL} margin="0 0 10px 0">
        <b>Information Collection and Use</b><br />
          Users browsing the Site without registering an account or affirmatively providing Personal Data to Novotalk do so anonymously, and you may generally use the Site without disclosing Personal Data.  However, as described below, we collect certain non-personally identifiable information and your use of certain features and functions of the Site or the Services may require you to submit Personal Data.<br /> <br />

          By registering for and using the Services, we may collect the following “Personal Data” from you:
        <ul>
          <li>
              Your name, address, email address, gender, age, date of birth, username, company, state or country of residence, and phone number (hereinafter referred to as <b>“Contact Information”</b>);
          </li>
          <li>
              A photograph that contains your image and video and audio files that contain your image and voice for purposes of providing instruction, training and treatment as part of the Services (<b>“User Information”</b>);
          </li>
              Your disfluency, previous treatments, age when you began to stutter, and other anamnestic information (<b>“Treatment Data”</b>);
          <li>
              Pages and products viewed, ads that you clicked on, emails from us that you opened, browser type, operating system, IP address and device information, your mobile operating system, a mobile device identifier embedded by us, or other commonly used mobile device identifier if you access the Site or the Services on a mobile device (hereinafter referred to as <b>“Analytical Information”</b>); and
          </li>
          <li>
              We may also collect publicly available information about you from third-party sources, such as the postal service for shipping address verification.
          </li>
        </ul>
      </Text>
      <Text textColor={TEXT_COLOR.NORMAL} margin="0 0 10px 0">
        <b>Payment and Financial Information</b> <br />
          Novotalk uses a third-party payment provider to process all transactions and payments for the Services offered through the Site.  This third-party payment provider will process all transactions and collect all payment information, and we do not collect or store any of your payment or financial information.  All payments and transactions will be governed by the terms of use and privacy policies of our third-party payment provider.
      </Text>
      <Text textColor={TEXT_COLOR.NORMAL} margin="0 0 10px 0">
        <b>How Your Personal Data May Be Used</b><br />
          We may use information we collect about you or that you provide to use, including Personal Data:
        <ul>
          <li>
              To fulfill any other purpose for which you provide it;
          </li>
          <li>
              To present the Site and its contents to you;
          </li>
          <li>
              To otherwise enhance user experience on the Site, with the Services and with Novotalk;
          </li>
          <li>
              To carry out our obligations and enforce our rights arising from any contracts entered into between you and us, including for billing and collection;
          </li>
          <li>
              To notify you about changes to the Site or the Services;
          </li>
          <li>
              In any other way we may describe when you provide the information; and
          </li>
          <li>
              For any other purpose with your consent.
          </li>
        </ul>
          We may also use information collected through the Site and the Services for research regarding the effectiveness of the Site and the Services and the business planning of Novotalk, its trusted affiliates, independent contractors and business partners.<br />

          We may use Analytical Information to improve the performance of our Site, improve the Services provided through the Site, develop new services and ideas, and better administer and troubleshoot our systems.<br />

          We may use Contact Information, User Information, Treatment Data, and other Personal Data to:
        <ul>
          <li>
              Provide you the Services on the Site, including analyzing your User Information and Treatment Data in the connection with the performance of the Services;
          </li>
          <li>
              Properly assess and configure the correct treatment assigned to you and tailor and personalize the content and treatment instructions we provide to you through the Services;
          </li>
          <li>
              Provide access to certain password protected areas of the Site;
          </li>
          <li>
              Fulfill your requests for information; and
          </li>
          <li>
              Contact you about Novotalk products or services and those of our affiliates, based on the preferences you have indicated.
          </li>
        </ul>
          We will give you the opportunity to “opt out” of receiving any unsolicited information from us or to limit the unsolicited information you receive from us to information regarding the Services or information you specifically request or information we determine you may find useful as a result of your use of the Site and the Services.<br />

          We may use information we collect from you or that you provide us, including Personal Data, in the aggregate and in an anonymized way for any legal purpose.  When we use your information in this manner, your information will be de-identified and aggregated with other information such that it will no longer be linked to you or contain any components personally identifiable to you.
      </Text>
      <Text textColor={TEXT_COLOR.NORMAL} margin="0 0 10px 0">
        <b>Information Sharing and Disclosure</b><br />
          Except as otherwise described in this Privacy Policy, we will not share your Personal Data with any other person or company.  We will share Personal Data about you to other companies or people when:
        <ul>
          <li>
              We need to share your information to provide the products or service you have requested;
          </li>
          <li>
              We need to send the information to the companies and contractors who work on behalf of Novotalk to provide the Services to you (unless we tell you differently, these companies and contractors do not have any right to use the Personal Data we provide to them beyond what is necessary to assist us);
          </li>
          <li>
              We find that your actions on the Site or the Services violate the Terms of Use or any of our usage guidelines for specific services or any agreement; and
          </li>
          <li>
              As required to respond to or initiate subpoenas, court orders, or legal process.
          </li>
        </ul>

            The Site and Services may provide features that allow you to communicate with other users of the Site and Services and provide content and information in a public format.  In the event you voluntarily share personal or sensitive information on a publicly available product or service review, on a message board, or in a chat room offered by the Site and the Services, please be advised that such information may be collected and used by the other users of the Site and the Services who have access to such reviews, message boards and chat rooms.  Novotalk shall have no liability resulting from the misuse of information you voluntarily share through a public format during your use of the Site and the Services.  Novotalk shall have no obligation to police the reviews, message boards and chat rooms, but has the ability to remove such information in its sole discretion.
      </Text>
      <Text textColor={TEXT_COLOR.NORMAL} margin="0 0 10px 0">
        <b>Use of Cookies</b><br />
          We use “cookies,” a small text file transferred to your device, along with similar technologies (e.g., internet tag technologies, web beacons, embedded scripts), to help provide you a better, more personalized user experience.  These technologies are used to:
        <ul>
          <li>
              Make the user experience more user friendly;
          </li>
          <li>
              Remember your preferences (e.g., browsing language, account login information); and
          </li>
          <li>
              Help us understand and improve how visitors use the Site and the Services, including which of our pages and products are viewed most frequently.
          </li>
        </ul>

          The Options/Settings section of most internet browsers will tell you how to manage cookies and other technologies that may be transferred to your device, including how to disable such technologies.  You can disable our cookies or all cookies through your browser settings.  Please be advised that disabling cookies through either method may impact many of the Site’s and the Service’s features.<br /><br />

          We may also allow third parties to place cookies on your device through the Site and the Services to:
        <ul>
          <li>
              Help us understand and improve how visitors use the Site, including which of our pages and products are viewed most frequently; and
          </li>
          <li>
              More effectively market our products and services and advertise other products and services that may be of interest to you.
          </li>
        </ul>

          The use of third-party cookies is not covered by our Privacy Policy.  We do not have access or control over these cookies.  If you continue to use the Site and the Services, we will assume you agree to the use of these cookies.
      </Text>
      <Text textColor={TEXT_COLOR.NORMAL} margin="0 0 10px 0">
        <b>Do Not Track</b><br />
          Some internet browsers incorporate a “Do Not Track” feature that signals to websites you visit that you do not want to have your online activity tracked.  Given that there is not a uniform way that browsers communicate the “Do Not Track” signal, the Site and the Services does not currently interpret, respond to or alter its practices when it receives “Do Not Track” signals.
      </Text>
      <Text textColor={TEXT_COLOR.NORMAL} margin="0 0 10px 0">
        <b>Our Practices for Deleting Personal Data</b><br />
          Other than as described in this Privacy Policy, we delete Personal Data that we have collected once it is no longer reasonably    necessary to fulfill the purpose for which it was collected unless a longer retention period is required or allow by law.<br /><br />

          With respect to information and data that has been anonymized and aggregated (and does not contain any personally identifiable components), Novotalk will retain such aggregated and anonymized information indefinitely.
      </Text>
      <Text textColor={TEXT_COLOR.NORMAL} margin="0 0 10px 0">
        <b>Ability to Review and Edit Your Information</b><br />
          You may review and approve the Contact Information about you that was stored in our database and obtained through your use of or registration on the Site and the Services.  Upon your written request, we will remove that information from our database or change or correct Personal Data that you state is erroneous within applicable regulatory or other legal requirements.  You should understand, however, that information about you in our database might come from a number of sources.
      </Text>
      <Text textColor={TEXT_COLOR.NORMAL} margin="0 0 10px 0">
        <b>Security</b><br />
          We will maintain reasonable safeguards to ensure the security, integrity and privacy of your Personal Data. However, no electronic storage method or data transmission over the internet can be guaranteed to be 100% secure.
      </Text>
      <Text textColor={TEXT_COLOR.NORMAL} margin="0 0 10px 0">
        <b>Children under the Age of 13</b><br />
          The Services may be obtained by users under the age of thirteen (13) years of age.  To comply with The Children's Online Privacy Protection Act of 1998 and its rules (hereinafter referred to as: <b>“COPPA”</b>), we are providing the following information related to the use of the Site by children under thirteen (13) years of age.<br /><br />

          We may collect the same Personal Data (including Contact Information, User Information, and Treatment Data) from children under thirteen (13) as identified above.  Before we collect or use the Personal Data of users under the age of 13, we will obtain verifiable consent from the child’s parent or guardian in accordance with the procedures outlined below.  Following receipt of such verifiable consent, information may be collected directly from the child or indirectly through cookies. We will collect and use the Personal Data from children under 13 in the same manner that we collect and use Personal Data for all of our users.  In particular, we will collect and use a user’s Personal Data to provide the Services, including to analyze the user’s Personal Data and identify treatment performance and progress, and to assess and tailor content and treatment instructions for the user.  We may also use user’s Personal Data, including children under the age of 13, in accordance with the other authorized uses provided under this Privacy Policy.<br /><br />

        <i><b>Verifiable Parental Consent</b></i><br />
          In order to use the Services offered through the Site, a user is required to set up and register an account with Novotalk.  During the account and registration process, each user is required to verify that they are thirteen (13) years of age or older.  In the event a user indicates that the user is a child under the age of 13, then we will require the child’s parent or guardian to provide verified consent before we complete the account registration and allow the child to begin using the Services.  To obtain verified parental consent from the child’s parent or guardian, we require a child under the age of 13 to provide their parent’s or guardian’s online contact information (e.g., an email address) so that we can send a direct message to the child’s parent or guardian and request their consent to their child’s use of the Services and our use and collection of their child’s Personal Data in connection with the Services.  If the parent or guardian provides their consent, then we will provide a confirmation message of the received consent to the parent or guardian, complete the child’s account registration and allow the child to begin using the Services as a user.<br /><br />

          If you are a parent or guardian and believe your child is using the Services provided on the Site and you have NOT received and email providing notice or seeking your consent, please contact us at support@novotalk.com to provide your consent or request that we remove your child’s account and any associated information.  We will not use a parent/guardian’s online contact information provided for consent purposes to market to the parent/guardian, unless the parent/guardian has expressly opted in to such marketing or has separately participated in an activity that allows for such contact.<br /><br />

        <i><b>Use of Children’s Personal Data</b></i><br />
          The Personal Data collected from children will be used in the same manner as described above with respect to Personal Data from other users. This includes using Contact Information, User Information, Treatment Data and other Personal Data to provide the Services by analyzing the user’s Personal Data, identifying treatment performance and progress, and assessing, tailoring and providing content and treatment instructions.  This will also include all other uses of Personal Data as set forth in this Privacy Policy.<br /><br />

        <i><b>Disclosure of Children’s Personal Data</b></i><br />
          In connection with providing the Services, we disclose user’s Personal Data to our trusted business associates and contractors that assist Novotalk in providing the Services.  These business associates and contractors include but are not limited to professionals and trained personnel contracted by Novotalk to manage and oversee a particular set of users of the Services and to administer and provide tailored treatment options, training and content for users.  Novotalk only discloses a specific user’s Personal Data to the particular associates and contractors in charge or administering the Services for that user and does not make all users’ Personal Data available generally.<br /><br />

          We do not share, sell, rent, or transfer children’s Personal Data (or any user’s Personal Data) except as described above or otherwise described in in this Privacy Policy.<br /><br />

        <i><b>Parent/Guardian Discretion</b></i><br />
          As a child’s parent or guardian, at any time, you may:
        <ul>
          <li>
              Refuse disclosure of your child’s Personal Data to third parties except as required for provision of the Services;
          </li>
          <li>
              Review your child’s Personal Data maintained by us;
          </li>
          <li>
              Require us to correct or delete the child’s Personal Data; and
          </li>
          <li>
              Refuse to permit us from further collecting or using the child’s Personal Data.
          </li>
        </ul>

          On receiving your request to review, change, or delete your child’s personal information, we will provide you with a description of the specific types or categories of personal information the Site or the Services along with the actual information collected from your child.  We will not require a child to disclose more information than is reasonably necessary to use the Services.<br /><br />

          You can review, change, or delete your child’s personal information by contacting us through one of the methods provided below. Please direct your inquiries about any operator’s privacy practices and use of children’s information to us at the contact information provided below.  To meet our obligations to protect Personal Data, we may require you to take certain steps or provide additional information to verify your identity before we provide any information or make corrections.<br /><br />

          Novotalk Ltd.<br />
          Attn: Novotalk Children’s Privacy Inquiry<br />
          747 Third Ave, 2nd Floor<br />
          New York, NY 10017<br />
          212.505.0405<br />
          support@novotalk.com<br />
      </Text>
      <Text textColor={TEXT_COLOR.NORMAL} margin="0 0 10px 0">
        <b>Changes to this Privacy Policy</b><br />
          We reserve the right to change, modify or otherwise update this Privacy Policy at any time.  These changes or updates will be effective immediately.  We may provide you notice of such changes when they are material, such notice may be given by posting on the Site, by electronic or conventional mail or by any other means by which you obtain notice of the changes or updates.
      </Text>
      <Text textColor={TEXT_COLOR.NORMAL} margin="0 0 10px 0">
        <b>Policies of Other Websites</b><br />
          The Site and the Services may contain links to third-party websites not owned or controlled by Novotalk.  Novotalk is not responsible for the privacy policies of any third-party websites which user may access through a third-party link.  Further, these third-party websites may have privacy policies that differ from this Privacy Policy.  Novotalk disclaims all responsibility for the privacy practices of such other third-party websites.  You should read the privacy policies of each third-party website you visit to determine what information that each third-party website may be collecting about you and how they intend to use such information.
      </Text>
      <Text textColor={TEXT_COLOR.NORMAL} margin="0 0 10px 0">
        <b>Questions</b><br />
          If you have any questions or comments about this Privacy Policy or the Site and the Services, please contact us by email at support@novotalk.com. You also may contact us in writing or by phone at:<br /><br />

          Novotalk Ltd.<br />
          Attn: Novotalk Privacy<br />
          747 Third Ave, 2nd Floor<br />
          New York, NY 10017<br />
          212.505.0405<br />
          support@novotalk.com<br />
      </Text>
      <Text textColor={TEXT_COLOR.NORMAL} margin="0 0 10px 0">

      </Text>
      <Text textColor={TEXT_COLOR.NORMAL} margin="0 0 10px 0">

      </Text>
    </Container>
  </Container>
);

export default PrivacyPolicy;
