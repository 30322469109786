import SortOrder from '@components/SortableTable/components/SortOrder';
import { TableColumn } from '../PendingTable';
import { CellPending, HeaderColumnPending, TableHeaderPending } from '../styles';

type HeaderPendingSortableTableProps = {
  spread: string;
  config: TableColumn[];
  orderHandler: (field: string) => void;
  order: string;
  sortFieldLocale: string;
};

export const HeaderPendingSortableTable = ({
  spread,
  config,
  orderHandler,
  order,
  sortFieldLocale,
}: HeaderPendingSortableTableProps) => (
  <TableHeaderPending spread={spread} className="tableHeader">
    {config.map(({ title, sortable, sortField }, index) => (
      <HeaderColumnPending key={`${title}`} className="headerCell" isLast={index === config.length - 1}>
        <CellPending
          noPadding
          titlesUppercase
          pointer
          onClick={() => {
            orderHandler(sortField);
          }}
        >
          {title}
        </CellPending>
        {sortable && sortField === sortFieldLocale && <SortOrder order={order} />}
      </HeaderColumnPending>
    ))}
  </TableHeaderPending>
);
