import {styled} from "@mui/material";

export const PasswordStrengthDescription = styled('ul')(
  {
    position: 'relative',
    fontSize: 12,
  },
  ({ theme }) => ({
    color: theme.palette.novotalk.$errorColor,
  })
);
