import {styled} from "@mui/material";

export const Wrapper = styled('div')(
  {
    gridArea: 'conversationHeader',
    padding: '0 20px',
    display: 'flex',
    alignItems: 'center',
    position: 'relative',
  },
  ({ theme }) => ({
    borderBottom: `1px solid ${theme.palette.novotalk.$borderColorLight}`,
  })
);

export const StyledInput = styled('input')(
  {
    width: '100%',
    marginBottom: -2,
    border: 'none',
    padding: '0 5px',
    fontSize: 14,
    outline: 'none',
  },
  ({ theme }) => ({
    color: theme.palette.novotalk.$textColor,
    '&:placeholder': {
      color: theme.palette.novotalk.$borderColorTooltip,
    },
  })
);

export const UsersListWrapper = styled('div')(
  {
    minWidth: 240,
    position: 'absolute',
    left: 20,
    top: 44,
    borderRadius: 4,
    zIndex: 200,
    overflow: 'hidden',
  },
  ({ theme }) => ({
    backgroundColor: theme.palette.novotalk.$backgroundColor,
    boxShadow: `0 4px 32px 0 ${theme.palette.novotalk.$navigationMessagesMenuColorShadow}`,
  })
);

export const ListHeader = styled('div')(
  {
    height: 43,
    margin: '0 14px',
    display: 'flex',
    alignItems: 'center',
  },
  ({ theme }) => ({
    borderBottom: `1px solid ${theme.palette.novotalk.$messagingBorderColor}`,
  })
);

export const ListItem = styled('div')(
  {
    padding: '0 14px',
    height: 48,
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer',
  },
  ({ theme }) => ({
    '&:hover': {
      backgroundColor: theme.palette.novotalk.$dropdownBackgroundColorHover,
    },
    '&:active': {
      backgroundColor: theme.palette.novotalk.$dropdownBackgroundColorSelected,
    },
  })
);

export const StyledAvatar = styled('img')({
  width: 36,
  height: 36,
  marginRight: 18,
  objectFit: 'cover',
  borderRadius: '50%',
});
