import {STIMULUS_TYPE} from "@shared/Resources/Monitor/types";

export const calculateLoaderHeight = (type, decreasedScreenSize) => {
    switch (type) {
        case STIMULUS_TYPE.SPEECH_TOPICS:
            return 150
        case STIMULUS_TYPE.PICTURES:
            return decreasedScreenSize ? 310 : 330
        case STIMULUS_TYPE.PARAGRAPHS:
            return 158
        case STIMULUS_TYPE.SENTENCES:
            return 98
        default:
            return 50
    }
}

export const calculateWrapperHeight = (type, decreasedScreenSize) => {
    switch (type) {
        case STIMULUS_TYPE.WORDS:
        case STIMULUS_TYPE.SYLLABLES:
            return 40
        case STIMULUS_TYPE.SENTENCES:
            return 60
        case STIMULUS_TYPE.PARAGRAPHS:
            return decreasedScreenSize ? 90 : 120
        default:
            return 90
    }
}