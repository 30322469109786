import SortOrder from '@components/SortableTable/components/SortOrder';
import { TableColumn } from '../TableUserLog';
import { CellUserLog, HeaderColumnUserLog, TableHeaderUserLog } from '../styles';

type HeaderUserLogSortableTableProps = {
  spread: string;
  config: TableColumn[];
  orderHandler: (field: string) => void;
  order: string;
  sortFieldLocale: string;
};

export const HeaderUserLogSortableTable = ({
  spread,
  config,
  orderHandler,
  order,
  sortFieldLocale,
}: HeaderUserLogSortableTableProps) => (
  <TableHeaderUserLog spread={spread} className="tableHeader">
    {config.map(({ title, sortable, sortField }, index) => (
      <HeaderColumnUserLog key={`${title}`} className="headerCell" isLast={index === config.length - 1}>
        <CellUserLog
          noPadding
          titlesUppercase
          current={false}
          onClick={() => {
            orderHandler(sortField);
          }}
        >
          {title}
        </CellUserLog>
        {sortable && sortField === sortFieldLocale && <SortOrder order={order} />}
      </HeaderColumnUserLog>
    ))}
  </TableHeaderUserLog>
);
