import { CLIENT_VERSION } from '@shared/constants';
import Version from '../Version';
import {useTranslation} from "react-i18next";

const ClientVersion = ({ showIcon }) => {
  const {t} = useTranslation()
  return (
    <Version
      label={t('UI.clientVersion')}
      loading={false}
      value={CLIENT_VERSION}
      showIcon={showIcon}
    />
  );
};

export default ClientVersion;
