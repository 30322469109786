import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import _ from 'lodash';

export const AuthenticationRoute = ({
  component: Component,
  user,
  ...rest
}) => (
  <Route
    {...rest}
    render={(props) => {
      if (user.token) {
        const pathname = user.defaultPage;
        return (
          <Redirect
            to={{
              pathname,
            }}
          />
        );
      }
      return <Component {...props} />;
    }}
  />
);

AuthenticationRoute.propTypes = {
  location: PropTypes.object,
  component: PropTypes.func,
  user: PropTypes.object,
};

export const mapStateToProps = (state) => {
  const user = state.getIn(['app', 'authentication', 'user']).toJS();
  return { user };
};

const withConnect = connect(mapStateToProps);

export default compose(withConnect)(AuthenticationRoute);
