import gql from 'graphql-tag';
import { graphMutation, graphQuery } from '@services/commService';

export const createEntity = async (info, components, pollID) => {
  const { name, description } = info;
  const children = components
    .filter((child) => !child.payload.mapToField)
    .map(({ id, payload, type }) => ({ id, type, payload }));
  const mutation = gql`
  mutation($children: [PollElementInput]) {
    createdEntity:createEntityPoll(
      name:"${name}",
      description:"""${description}""",
      children:$children
       ){
        id 
        description
        name
        children {
          id
        }
        createdAt
      }
    }
  `;
  return await graphMutation(mutation, { children });
};

export const editEntity = async (info, components, pollID) => {
  const { name, description } = info;
  const children = components
    .filter((child) => !child.payload.mapToField)
    .map(({ id, payload, type }) => ({ id, type, payload }));
  const mutation = gql`
  mutation($children: [PollElementInput]) {
    modifyPoll(
      id:"${info.id}",
      name:"${name}",
      description:"""${description}""",
      children:$children
       ){
        id 
        description
        name
        children {
          id
        }
        createdAt
      }
    }
  `;
  return await graphMutation(mutation, { children });
};
export const deleteEntity = async(id)=>{
  
  const mutation = `mutation{
    deletePoll(
      id:"${id}"
    ){
      name
    }

  }`
  
  return await graphMutation(mutation,{}, true);
}

export const loadEntity = async (id) => {
  const query = gql`
  {
    entity:entityPolls(id:"${id}",range:[0,1],fullData:true){
    id
    name
    createdAt
    description
    children{
      id
      type
      payload
    }
  }}
  `;
  return await graphQuery(query);
};
