import {styled} from "@mui/material";
import { getResponsiveCSS } from '@utils/cssHelpers';
import CTAButton from '@components/CTAButton';
import TextArea from '@components/TextArea';

export const Wrapper = styled('div')({
  marginBottom: 50,
});

export const HeaderContainer = styled('div')({
  marginBottom: 8,
  width: '100%',
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
});

export const StyledTextArea = styled(TextArea)({
  height: 180,
});

export const PostButtonContainer = styled('div')(
  getResponsiveCSS('marginTop', 10, 15)
);

export const StyledCTAButton = styled(CTAButton)({
  height: 44,
});
