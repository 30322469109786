const map = {
  Symmetry:
  {
    'Symmetric production. ': 'Yout production was symmetric',
    'Non-symmetric production': 'Try producing the pattern symmetrically. Make sure that the length of voice production at both the beginning and end of  the pattern \nare similar',
  },
  'Voice transition':
  {
    'Correct transition': 'your transition between gentle and loud voice was good and accurate',
    'Non correct transition': 'Make sure to begin with gentle voice production, then gradually move to loud voice and finish by producing gentle voice. ',
  },
  Duration:
  {
    'Appropriate duration': 'Your production was in the correct lenght',
    'Non appropriate duration': 'Make sure to produce the vowels for 2 seconds, as seen on the Monitor',
  },
  'Gentle Onset':
  {
    'Gentle onset': 'The vowels were produced using a gentle onset',
    'Non gentle onset': 'Make sure to start sound production with a gentle onset to ensure that there is no straining during voice production.',
  },
  'Gentle offset':
  {
    'Gentle offset': 'Good! you produced the pattenr using a gentle offset',
    'Non gentle offset': 'Make sure you finish the sound production with a gentle offset and ensure that there is no straining in you voice.  Gentle offset will help you begin the next syllable gently as well',
  },
  'Interruptions of airflow':
  {
    'Good productions': 'Very good, you are producing the vowels smoothly, with no interruptions',
    'Non light contact': 'Make sure that there is no interruption to airflow when producing a vowel sound, even by some sort of minimal obstruction. Voice must  be moving constantly',
  },
  'Sharp rise':
  {
    'Correct rise': 'You manage to control your rise of volume and to imitate the pattern, good job',
    'Sharp rise': 'Your rise in voice was too sharp. It can result in voice strain and lack of breath. It may be caused by a lack of a good control of your sound production. Make sure that the rise in voice is not sudden and that you fell you control the amount o air you release.',
  },
  'Sharp drop':
  {
    'Correct drop': 'You manage to control your drop of volume and to imitate the pattern, good job',
    'Sharp drop': 'Your drop in voice was too sharp. It may be caused by a lack of a good control of your sound production towards the end of the pattern. Make sure that the drop in voice is not sudden and that you feel you control the amount o air you release towards the end of the pattern.',
  },
  'Shape up':
  {
    'Correct shape up': 'You mange to imitate the pattern shape, good job',
    'Convex rise': "Your pattern shpae while going up in the pattern wasn't as needed, try to produce a gradual pattern, as appears on the shaded pattern. ",
  },
  'Shape down':
  {
    'Convex drop': 'You mange to imitate the pattern shape, good job',
    'Pttern shape': "Your pattern shpae while going down in the wasn't as needed, try to produce a concave shaped pattern, as appears on the shaded pattern. ",
  },
  'Volume control':
  {
    'Coorect production': 'You are producing the pattern correctly and mange to control your vocal voluce throughout the patern, great job! ',
    'Sharp peaks on the Monitor  ': 'It seems like you are having touble with maintaing your vocal volume throughout the pattern production. Try to control the air you release by inhaling correctly before the production using your diaphragm and release the air slowly and in control.',
  },
  'Intense peak':
  {
    'Correct peak intensity': 'The peak intensity was exactly as needed, good job!',
    'Intense peak': 'The loud voice was too strong. It may be because the voice is too loud or is produced for too long a time, try to control you volume loudness. ',
  },
  'Soft peak':
  {
    'Correct peak intensity': 'The peak intensity was exactly as needed, good job!',
    'Soft peak': 'Try to increase your vocal volume at the center of the pattern, and to use loud voice. Loud voice  is colored orange on the monitor',
  },
  'Light contact':
  {
    'Good production': 'Good production',
    'Non light contact': "Your consonants production wasn't gentle enough. In order to produce the light contact. try to move the articulators as minimal as possible when producing the consonants and say it gently and quickly so it is barely heard.",
  },
  'Mouth opening':
  {
    Mouth: 'Your mouth opening is as needed and it helps you produce the needed vouwel correctly',
    'Exaggerated open mouth': 'Make sure to use exaggerated open mouth during vowel production. The vowels in this pattern should be emphasized and lengthened. ',
  },
  Fast:
  {
    'Correct speed': 'Your rate was great, good job! ',
    'Too fast': 'Your pattern was too fast, Try to remain within the boundries of approximately 2 seconds.  It is very important to practice the techniques in the slow rate in order to build muscle memory which is necessary for faster, fluent and spontaneous speech',
  },
  Slow:
  {
    'Correct speed': 'Your pace was great, good job! ',
    'Too slow': 'Your pattern was too slow. It is very important to try to remain within the 2 seconds rate since it will get faster along the way',
  },
  'Consonant prolongation':
  {
    'Correct production': 'You are producing the consonants exactly as needed, good job',
    'Prolonging consonants': 'You are prolonging the consonants, and it might interfere with the airflow and make it difficult to maintain fluent speech. Try to produce the consonants quickly and gently and then move gradualy to producing the vowel',
  },
  Inhalation:
  {
    'Correct inhalation': 'You are breathing correctly between each production, very good!',
    'Short inhalation': 'Make sure that you inhale for the full 2 seconds between each production. This is very important for the coordination and synchronization of breathing and speaking.Two second inhalation also prevents tiring and increases the effect of the exercise',
  },
  'Syllable production':
  {
    'Wave pattern': 'You are producing the wave patten exactly as needed, great!',
    'Impropper wave': 'Make sure to produce the wave pattern correctly: Inhale fully, follow this with a gentle onset and gentle voice, gradualy move to a loud voice using larger mouth opening and control of abdominal breathing. Return to gentle voice at the end of the pattern. Watch the Monitor and keep to the pattern.',
  },
  'Inhalation Between each syllable':
  {
    'correct inhalation': 'You are breathing correctly between each syllable, great job! ',
    'Between each syllable': 'Make sure to inhale using diaphragmatic breathing before producing each new syllable',
  },
};

export default map;
