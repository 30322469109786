import {MEDIA_TYPES, QUESTION_TYPES, TYPES} from '@shared/Resources/types';

const poll = {
    "id" : "59aaa23434400000",
    "description" : "Video chat summary",
    "name" : "Video chat summary",
    "version" : 2,
    "children" : [ 

        {
            "id" : "59aaa27d2e000000",
            "type" : TYPES.QUESTION,
            "payload" : {
                "title" : "Question",
                "type" : QUESTION_TYPES.RATING,
                "questionText" : "Please rate patient's fluency",
                "config": {
                    minimum: {
                        value: 1,
                        text: "Poor"
                    },
                    maximum: {
                        value: 5,
                        text: "Excellent"
                    },
                    isOptional: {
                        text: "Non relevant"
                    } 
                }
            }
        }, 
        {
            "id" : "59aaa27d4e000000",
            "type" : TYPES.QUESTION,
            "payload" : {
                "type" : QUESTION_TYPES.RATING,
                "questionText" : "Please rate patient's control of techniques",
                "config": {
                    minimum: {
                        value: 1,
                        text: "Poor"
                    },
                    maximum: {
                        value: 5,
                        text: "Excellent"
                    },
                    isOptional: {
                        text: "Non relevant"
                    } 
                }
            }
        },
        {
            "id" : "59aaa27d9e000000",
            "type" : TYPES.QUESTION,
            "payload" : {
                "type" : QUESTION_TYPES.FREE_TEXT,
                "questionText" : "Additional comments",
            }
        }, 
  
    ],
}

export default poll;
