import { graphQuery } from '@services/commService';
import {
  STIMULUS_TYPE,
} from '@shared/Resources/Monitor/types';

export const getStimuli = async (parameters) => {
  const { type, config, entity } = parameters;

  // const sequences = progress.type === PROGRESS_TYPE.TIMER ? DEFAULT_TIMER_PROGRESS : progress.value
  if (type === STIMULUS_TYPE.PICTURES) {
    const { picturesType } = config;
    const { BASIC, ADVANCED } = picturesType;
    const pictureTypes = [];
    BASIC && pictureTypes.push('picBasic');
    ADVANCED && pictureTypes.push('picAdvanced');

    let argumentsString = '';

    const variables = {
      type: '[pictureTypes]',
    };
    argumentsString = `
        type: [${pictureTypes}]
        `;
    const query = `
    query{
      randomPictureStimuli(
          ${argumentsString}
          )
            {
                id
                text
                title
                media
                __typename
            }
        }
    `;

    const res = await graphQuery(query, true, variables);

    const { randomPictureStimuli } = res.data;

    return randomPictureStimuli;
  }
  if (type === STIMULUS_TYPE.SPEECH_TOPICS) {
    const { speechTopicsType } = config;
    let argumentsString = '';
    const topicTypes = [];
    const { BASIC, ADVANCED, QUESTION } = speechTopicsType;
    BASIC && topicTypes.push('topicBasic');
    ADVANCED && topicTypes.push('topicAdvanced');
    QUESTION && topicTypes.push('question');

    const variables = {
      locale: 'EN',
      type: topicTypes,
    };
    argumentsString = `
        type: [${topicTypes}] 
        `;
    const query = `
    query{
      randomSpeechStimuli(
          ${argumentsString}
          )
            {
                id
                text
                __typename
            }
        }
    `;

    const res = await graphQuery(query, true, variables);

    const { randomSpeechStimuli } = res.data;

    return randomSpeechStimuli;
  }
  if (type === STIMULUS_TYPE.CUSTOM) {
    return [];
  }
  let argumentsString = '';
  const variables = {};
  if (type === STIMULUS_TYPE.SYLLABLES) {
    variables.config = {
      ...config,
      syllableCount: {
        min: 1,
        max: 1,
      },
      wordType: {
        NONSENSE: true,
      },
    };
    variables.entity = entity;
    argumentsString = `
        type: ${type}
        entity: $entity
        config: $config
        `;
  } else if (
    type === STIMULUS_TYPE.WORDS
    || type === STIMULUS_TYPE.SENTENCES
    || type === STIMULUS_TYPE.PARAGRAPHS
  ) {
    variables.config = config;
    variables.entity = entity;
    argumentsString = `
        type: ${type}
        entity: $entity
        config: $config
        `;
  }
  const query = `
  query($config: JSON, $entity: TextStimuliSyllableEntityType){
    randomTextStimuli(
          ${argumentsString}
          )
            {
                id
                current
                text
            }
        }
    `;
  const res = await graphQuery(query, true, variables);
  const { randomTextStimuli } = res.data;
  return randomTextStimuli;
};
