import {
  put,
  takeLatest,
  all,
  fork,
  join,
  takeEvery,
} from 'redux-saga/effects';
import { toast } from 'react-toastify';
import { t } from 'i18next';
import { authenticationWrapper } from '@services/errorHandlingService';
import { TOAST_AUTO_CLOSE_TIME } from '@shared/constants';
import {
  getUserActivityData,
  visit,
  createFeedback,
  editFeedback,
  getActivityProductions,
} from './services';
import {
  Types,
  setUserActivity,
  getUserActivityError,
  setExerciseProductions,
  getExerciseProductionsError,
  postFeedbackSuccess,
  postFeedbackError,
  editFeedbackSuccess,
} from './actions';
import { getUserPendingPracticeDetails } from '../UserPage/services';

export function* callGetUserActivity(action) {
  yield authenticationWrapper(function* codeBlock() {
    const { type, id } = action.payload;
    try {
      const res = yield fork(getUserActivityData, type, id);
      const gqlResult = yield join(res);
      if (gqlResult.errors) throw new Error(gqlResult.errors[0]);
      if (gqlResult.data.activities.length === 0) {
        window.history.back();
        return toast.error(t('Informative.Negative.noActivityFound'), {
          autoClose: TOAST_AUTO_CLOSE_TIME,
          position: 'bottom-right',
        });
      }

      const result = yield fork(visit, type, id);
      const visitResult = yield join(result);
      if (visitResult.errors) throw new Error(gqlResult.errors[0]);

      const { userId } = gqlResult.data.activities[0];
      const userActivities = yield fork(getUserPendingPracticeDetails, userId, id, type);
      const activ = yield join(userActivities);
      const { userActivityCharts, errors } = activ;
      if (errors) throw new Error(activ.errors[0]);
      yield put(setUserActivity({
        ...gqlResult.data.activities[0],
        ...{ practiceErrors: userActivityCharts.length ? userActivityCharts[0].charts : userActivityCharts.charts },
      }));
    } catch (error) {
      yield put(getUserActivityError(error.message));
    }
  });
}

export function* callPostFeedback(action) {
  yield authenticationWrapper(function* codeBlock() {
    try {
      const { activity, text, dismiss } = action.payload;

      const res = yield fork(createFeedback, activity, text, dismiss);
      const gqlResult = yield join(res);
      if (gqlResult.errors) throw new Error(gqlResult.errors[0]);

      yield put(postFeedbackSuccess(gqlResult.data.post));
    } catch (error) {
      yield put(postFeedbackError(error.message));
    }
  });
}

export function* callEditFeedback(action) {
  yield authenticationWrapper(function* codeBlock() {
    try {
      const { id, text } = action.payload;

      const res = yield fork(editFeedback, id, text);
      const gqlResult = yield join(res);
      if (gqlResult.errors) throw new Error(gqlResult.errors[0]);

      yield put(editFeedbackSuccess(gqlResult.data.post));
    } catch (error) {
      yield put(postFeedbackError(error.message));
    }
  });
}

export function* callGetActivityProductions(action) {
  yield authenticationWrapper(function* codeBlock() {
    const { activityId } = action.payload;
    try {
      const res = yield fork(getActivityProductions, activityId);
      const gqlResult = yield join(res);
      if (gqlResult.errors) throw new Error(gqlResult.errors[0]);

      yield put(
        setExerciseProductions(activityId, gqlResult.data.activityProductions),
      );
    } catch (error) {
      yield put(getExerciseProductionsError(error.message));
    }
  });
}

function* watchCallGetUserActivity() {
  yield takeLatest(Types.GET_USER_ACTIVITY, callGetUserActivity);
}
function* watchCallPostFeedback() {
  yield takeLatest(Types.POST_FEEDBACK, callPostFeedback);
}
function* watchCallEditFeedback() {
  yield takeEvery(Types.EDIT_FEEDBACK, callEditFeedback);
}
function* watchCallGetActivityProductions() {
  yield takeLatest(Types.GET_EXERCISE_PRODUCTIONS, callGetActivityProductions);
}

export default function* pendingDashboardActiveDataSaga() {
  yield all([
    fork(watchCallGetUserActivity),
    fork(watchCallPostFeedback),
    fork(watchCallEditFeedback),
    fork(watchCallGetActivityProductions),
  ]);
}
