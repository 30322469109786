import {styled} from "@mui/material";
import { getIconsByType, iconTypes } from '@services/icons';
import { getFlexibleSize, getResponsiveCSS } from '@utils/cssHelpers';
import Text from '@components/Text';

export const getStyledIcon = (iconName) => getIconsByType(iconTypes[iconName]);

export const IconWrapper = styled('div')({
  margin: '14px auto',
  width: 54,
  '@media(min-width: 1280px)': {
    marginTop: getFlexibleSize(14, 38),
    marginBottom: getFlexibleSize(14, 16),
    width: getFlexibleSize(54, 64),
  },
  '@media(min-width: 1920px)': {
    width: 64,
    marginTop: 38,
    marginBottom: 16,
  },
});

export const RecreationIcon = getIconsByType(iconTypes.RECREATION);

export const RecreationIconWrapper = styled('div')({
  width: 130,
  margin: '0 auto 10px',
  '@media(min-width: 1280px)': {
    width: getFlexibleSize(130, 154),
  },
  '@media(min-width: 1920px)': {
    width: 154,
  },
});

export const Wrapper = styled('div')(
  {
    width: 820,
    minHeight: 210,
    padding: '38px 30px 34px 30px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    borderRadius: 4,
    '@media(min-width: 1280px)': {
      width: getFlexibleSize(820, 900),
      minHeight: getFlexibleSize(210, 240),
      paddingBottom: getFlexibleSize(34, 32),
    },
    '@media(min-width: 1920px)': {
      width: 900,
      minHeight: 240,
      paddingBottom: 32,
    },
  },
  ({ theme }) => ({
    direction: theme.RTL ? 'rtl' : 'ltr',
    backgroundColor: theme.palette.novotalk.$backgroundColor,
    color: theme.palette.novotalk.$textColorDark,
  })
);

export const HeaderContainer = styled('div')(
  {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    '& span.popup-title': {
      marginBottom: 18,
    },
    '& div.popup-progress': {
      alignSelf: 'flex-start',
      marginBottom: 25,
    },
  },
  ({ theme }) =>
    getResponsiveCSS(
      theme.RTL ? 'marginRight' : 'marginLeft',
      72,
      102,
      '& div.popup-progress'
    )
);

export const BreakMessageContainer = styled('div')(
  {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    marginBottom: 24,
    textAlign: 'center',
    lineHeight: '21px',
    borderTop: '1px solid',
    padding: '26px 121px 0 121px',
    '@media(min-width: 1280px)': {
      lineHeight: getFlexibleSize(21, 24),
      marginBottom: getFlexibleSize(24, 34),
      paddingLeft: getFlexibleSize(121, 151),
      paddingRight: getFlexibleSize(121, 151),
    },
    '@media(min-width: 1920px)': {
      lineHeight: '24px',
      marginBottom: 34,
      padding: '26px 151px 0 151px',
    },
  },
  ({ theme }) => ({
    borderColor: theme.palette.novotalk.$borderColorLight,
  })
);

export const TipsBlockContainer = styled('div')(
  {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    borderTop: '1px solid',
    paddingTop: 30,
    '& span.popup-failure-text': {
      margin: '-5px 0 12px 0',
    },
    '@media(min-width: 1280px)': {
      paddingTop: getFlexibleSize(30, 38),
      '& span.popup-failure-text': {
        marginBottom: getFlexibleSize(12, 17),
      },
    },
    '@media(min-width: 1920px)': {
      paddingTop: 38,
      '& span.popup-failure-text': {
        marginBottom: 17,
      },
    },
  },
  ({ theme }) => ({
    borderColor: theme.palette.novotalk.$borderColorLight,
  })
);

export const TipsBlockHeaderText = styled(Text)(({ theme }) => ({
  fontWeight: theme.RTL ? 'bold' : 'normal',
}));

export const TipsListContainer = styled('div')({
  display: 'flex',
  justifyContent: 'center',
  marginBottom: 34,
  '@media(min-width: 1280px)': {
    marginBottom: getFlexibleSize(34, 50),
  },
  '@media(min-width: 1920px)': {
    marginBottom: 50,
  },
});

export const TipContainer = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  flex: '1 0px',
  maxWidth: 264,
  padding: '0 20px',
  textAlign: 'center',
  lineHeight: '1.2em',
  '@media(min-width: 1280px)': {
    maxWidth: getFlexibleSize(264, 302),
  },
  '@media(min-width: 1920px)': {
    maxWidth: 302,
  },
});

export const ButtonsContainer = styled('div')({
  display: 'flex',
  justifyContent: 'center',
  '& > *': {
    margin: '0 7px',
    width: 180,
    height: 44,
  },
  '& div.popup-button-single': {
    width: 220,
  },
  '@media(min-width: 1280px)': {
    '& > *': {
      height: getFlexibleSize(44, 48),
    },
  },
  '@media(min-width: 1920px)': {
    '& > *': {
      height: 48,
    },
  },
});

export const TipsTitle = styled('p')({
  fontSize: 15,
  margin: '0 0 6px',
  weight: 'bold',
},
({ theme }) => ({
  color: theme.palette.novotalk.$textColorDarkBlue,
})
)
