import {
  ERROR_TYPES,
} from '../../../types';

export const defaultErrors = {
  on: false,
  masteryLevel: 40,
  inhalation: {
    tooShort: ERROR_TYPES.tooShortInhalation,
  },
  production: {
    tooShort: ERROR_TYPES.tooShortProduction,
    tooLong: ERROR_TYPES.tooLongProduction,
    notLoud: ERROR_TYPES.notLoudProduction,
    notGentle: ERROR_TYPES.notGentleProduction,
    noPattern: ERROR_TYPES.noPatternProduction,
    nonGentleOnsetOsm: ERROR_TYPES.nonGentleOnsetOsm,
    SoftPeakOsm: ERROR_TYPES.SoftPeakOsm,
    noLinkingOsm: ERROR_TYPES.noLinkingOsm,
    tooFlatOsm: ERROR_TYPES.tooFlatOsm,
    tooFastOsm: ERROR_TYPES.tooFastOsm,
    tooSlowOsm: ERROR_TYPES.tooSlowOsm,
    nonGentleOnsetSmm: ERROR_TYPES.nonGentleOnsetSmm,
    noLinkingSmm: ERROR_TYPES.noLinkingSmm,
    tooFlatSmm: ERROR_TYPES.tooFlatSmm,
    tooFastSmm: ERROR_TYPES.tooFastSmm,
    tooSlowSmm: ERROR_TYPES.tooSlowSmm,
  },
  specific: {
    beginning: {
      notGentle: ERROR_TYPES.notGentleBeginning,
      nonGentleOnset: ERROR_TYPES.nonGentleOnset,
      volumeControl: ERROR_TYPES.volumeControlBeginning,
    },
    peak: {
      softPeak: ERROR_TYPES.softPeak,
      excessivePeak: ERROR_TYPES.excessivePeak,
    },
    end: {
      notGentle: ERROR_TYPES.notGentleEnd,
      volumeControl: ERROR_TYPES.volumeControlEnd,
      nonGentleOffset: ERROR_TYPES.nonGentleOffset,
    },
  },
};
