import { TooltipStyled } from './styles';

/**
 * Tooltip component
 */
export const Tooltip = ({
  children,
  hasArrow,
  content,
  placement,
  padding,
}) => (
  <TooltipStyled
    title={content}
    arrow={hasArrow}
    placement={placement}
    padding={padding}
  >
    {children}
  </TooltipStyled>
);
