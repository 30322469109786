import { useSelector, useDispatch, RootStateOrAny } from 'react-redux';
// import Select from '@components/Select';
import {
  MenuItem, Select, FormControl, SelectChangeEvent,
} from '@mui/material';
import { changeUserStatus } from '@containers/Admin/containers/UserPage/actions';
import { formatUserStatus } from '@containers/Admin/containers/UserPage/helpers';
import { SELECTOR_WIDTH, USER_STATUS_OPTIONS } from './consts';
import { UserStatus } from './types';

interface SelectStatusProps {
  isClinicalMentor: boolean;
}

/**
* Select for status
* @param {boolean} isClinicalMentor - Value for checking current user is supervisor
* @returns {React.ReactElement} React element
*/
export const SelectStatus = ({ isClinicalMentor }: SelectStatusProps) => {
  const dispatch = useDispatch();
  const { userStatus, userData: { id } } = useSelector((state: RootStateOrAny) => state.get('userPage').toJS()); // FIXME replace to typing store

  /**
  * Send status to backend by userID
  */
  const sendUserStatus = (event:SelectChangeEvent<UserStatus>): void => {
    dispatch(changeUserStatus(event.target.value, id));
  };

  const currentStatusHelper = ():UserStatus | undefined => USER_STATUS_OPTIONS.find(({ value }: UserStatus) => value === userStatus);

  if (isClinicalMentor) return userStatus && <>{formatUserStatus(userStatus)}</>;

  return (
    <div>
      {userStatus && (
      <FormControl sx={{ width: SELECTOR_WIDTH }} size="small">
        <Select
          value={currentStatusHelper()}
          onChange={sendUserStatus}
          renderValue={() => currentStatusHelper()?.label}
        >
          {USER_STATUS_OPTIONS.map((option) => (
            <MenuItem key={option.value} value={option.value} selected={option?.value === userStatus}>
              {option.label}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      )}
    </div>
  );
};
