export const novotalkBlue = '#23B7E5';
export const novotalkOrange = '#F0891A';
export const novotalkOrangeHover = '#FD9C34';

export const novotalkColors = {
    // colors
    $novotalkBlue: novotalkBlue,
    $completedColor: '#7ED321',
    $completedColorHover: '#A1D964',
    $activeColor: novotalkBlue,
    $errorColor: '#F0166D',
    $warningColor: novotalkOrange,
    $transparentColor: 'rgba(0,0,0,0)',
    white: '#fff',

    // text colors
    $textColor: '#000',
    $textColorDark: '#2B2B2B',
    $textColorDarkBlue: '#1A173B',
    $textColorHighlited: '#21C4EA',
    $textColorDisabled: '#9C9C9C',
    $textColorDisabledLight: '#97A2AB',
    $textColorLightGray: '#CCCFD4',
    $textColorDarkGray: '#828282',
    $textColorReversed: '#FFF',
    $passwordHintWeakColor: '#E34139',
    $passwordHintMediumColor: novotalkOrange,
    $passwordHintStrongColor: '#7ED321',

    // element colors
    $borderColorLight: '#EBEDF8',
    $borderColorMedium: '#D8D8D8',
    $borderColorLightBlue: 'rgba(35,183,229,0.16)',
    $borderColorFeedbackItem: 'rgba(204,207,212,0.26)',
    $borderColorTooltip: '#F3F3F3',
    $backgroundColor: '#FFF',
    $backgroundColorLight: '#F7F7F7',
    $backgroundColorMedium: '#F5F8FA',
    $backgroundColorMediumHover: '#EFF3F5',
    $backgroundColorDark: '#C7CDD9',
    $backgroundColorPracticeSummary: 'rgba(126,211,33,0.08)',
    $backgroundColorFeedbackItem: 'rgba(204,207,212,0.1)',
    $backgroundColorAuthScreens: '#FDFDFD',

    // button colors
    $ctaButtonColor: novotalkOrange,
    $ctaButtonColorHover: '#FD9C34',
    $ctaButtonColorActive: '#ED8515',
    $secondaryButtonColor: '#9C9C9C',
    $secondaryButtonColorHover: '#AAAAAA',
    $secondaryButtonColorActive: '#919191',
    $actionButtonColor: novotalkBlue,
    $actionButtonColorHover: '#38C6F2',
    $actionButtonColorActive: '#1EB1DE',
    $outlineButtonColor: novotalkBlue,
    $outlineButtonColorReversed: '#FFF',
    $outlineButtonColorActive: '#1EB1DE',
    $buttonColorDisabled: '#CCCFD4',
    $seperatorColor: '#FFAB4F',
    $seperatorColorDisabled: '#B7BABF',
    $toggleButtonBorderColor: 'rgba(156,156,156,0.7)',
    $toggleButtonBgColorHover: '#F2F2F2',
    $toggleButtonBgColorActive: '#E7E7E7',

    // select elements colors
    $selectStandartColor: '#B0B0B0',
    $selectStandartBgColorHover: 'rgba(156,156,156,0.06)',
    $selectStandartBgColorActive: 'rgba(35,183,229,0.06)',
    $selectBackgroundColorDisabled: 'rgba(216,216,216,0.4)',
    $selectEnabledColor: novotalkBlue,
    $selectEnabledBgColorHover: 'rgba(35,183,229,0.1)',
    $selectEnabledBgColorActive: 'rgba(35,183,229,0.15)',

    // dropdown/input elements colors
    $inputBorderColor: 'rgba(156,156,156,0.7)',
    $inputBorderColorHover: '#9C9C9C',
    $inputBorderColorActive: novotalkBlue,
    $inputBackgroundColorDisabled: 'rgba(216, 216, 216, 0.3)',
    $dropdownBackgroundColorHover: 'rgba(151,162,171,0.1)',
    $dropdownBackgroundColorSelected: 'rgba(35,183,229,0.08)',

    // video chat colors
    $videoChatBackgound: 'rgba(3,9,18,0.7)',
    $videoChatWaitingBackground: '#B3B3B3',
    $videoChatEndedBackground: '#282828',
    $videoChatSubscriberBackground: '#000',
    $videoChatControlsBackground: 'rgba(35, 183, 229, 0.25)',
    $videoChatControlsBackgroundHover: 'rgba(35, 183, 229, 0.4)',
    $videoChatControlsBackgroundActive: 'rgba(35, 183, 229, 0.6)',
    $videoChatGradient: 'linear-gradient(180deg, rgba(0,0,0,0) 0%, rgba(0,0,0,0.7) 100%)',
    $selfChatBubble: '#DBFAC0',
    $otherChatBubble: '#EBFAFF',

    // resources manager/builder colors
    $widgetMenuBorderColor: '#E5E7E9',
    $widgetBarBackgroundColor: 'rgba(204,207,212,0.08)',
    $widgetBarItemColor: '#CCCCCC',
    $widgetBarItemColorHover: '#B8B8B9',
    $widgetBarItemColorActive: '#A6A6A6',
    $dragIconColor: '#C6CDD2',
    $dragIconColorHover: '#B3BBC3',
    $dragIconColorActive: '#A1ABB3',
    $resourcesListBgColor: '#F3F5F7',
    $resourcesListBorderColor: '#EEF1F5',
    $resourcesListItemSelected: '#E7EDF2',
    $resourcesInputBgColor: '#F7F7F8',
    $resourcesTableBorderColor: '#E6EAEE',
    $resourcesTableBgColorHover: 'rgba(204,207,212,0.08)',
    $resourcesTableBgColorActive: 'rgba(204,207,212,0.24)',
    $resourcesTableBgColorSelected: 'rgba(204,207,212,0.16)',


    // dashboards colors
    $statusDoneBackgroundColor: 'rgba(126,211,33,0.15)',
    $statusFailedBackgroundColor: 'rgba(240,137,26,0.15)',
    $statusDismissedBackgroundColor: 'rgba(156,156,156,0.15)',
    $activitySkillsOverviewBorderColor: '#EFF0F2',
    $activityUnreadBackgroundColorHover: '#EEF3F5',
    $activityUnreadBackgroundColorActive: '#E9EFF1',
    $activityReadBackgroundColorHover: 'rgba(204,207,212,0.08)',
    $activityReadBackgroundColorActive: 'rgba(204,207,212,0.24)',

    // other colors
    $questionIconColor: '#CCCFD4',
    $playVideoIconBgColor: 'rgba(255,255,255,0.2)',
    $uncompletedProgressColor: novotalkOrange,
    $navigationBarColor: '#23B7E5',
    $navigationHelpIconColor: '#00799F',
    $navigationNotificationColor: '#FF5050',
    $navigationIconColorHover: 'rgba(0,97,127,0.2)',
    $navigationIconColorActive: 'rgba(0,97,127,0.4)',
    $navigationMenuColorShadow: 'rgba(14,33,44,0.1)',
    $navigationMessagesMenuColorShadow: 'rgba(40,40,40,0.14)',
    $navigationMenuColorHover: '#F4F5F6',
    $shadowTooltipColor: 'rgba(0,0,0,0.08)',
    $monitorOverlayColor: 'rgba(0,0,0,0.3)',
    $circularProgressEmptyColor: '#F0F1F3',
    $pauseIconColorHover: '#D4D7DD',
    $pauseIconColorActive: '#BCBEC2',
    $repetitionCurrentIconColor: '#E7E9EC',
    $monitorFeedbackErrorColor: '#F75F00',
    $monitorFeedbackCorrectColor: '#7ED321',
    $trialBorderColor: 'rgba(241,202,181,0.4)',
    $trialBackgroundColor: '#FFE8DB',
    $trialBackgroundHoverColor: '#FFEFE6',
    $newMessageIconBackgroundColor: 'rgba(151,162,171,0.1)',
    $messagingListItemBackgroundColorHover: 'rgba(204,207,212,0.16)',
    $messagingListItemBackgroundColorActive: 'rgba(204,207,212,0.3)',
    $messagingEndedBadgeBackgroundColor: 'rgba(240,137,26,0.12)',
    $messagingBorderColor: '#E6EAEE',
    $messagingSelfMessageColor: '#F3F3F5',
    $messagingOthersMessageColor: 'rgba(35,183,229,0.18)',
    $messagingClosedBackgroundColor: 'rgba(151,162,171,0.08)',
    $settingsSeparatorColor: 'rgba(151,162,171,0.2)',
    $monitorTypesImgBackgroundColor: '#F2F3F4',
    $monitorTypesImgBorderColor: '#EBECEE',
    $monitorTypesBorderColor: 'rgba(204,207,212,0.4)',
    $monitorTypesBorderColorHover: 'rgba(35,183,229,0.45)',
    $numericSelectorColor: '#F4F5F5',
    $numericSelectorColorHover: '#F2F2F2',
    $numericSelectorColorActive: '#EFEFEF',
    $errorSelectorBorderColor: '#D0EFFA',
    $errorSelectorColor: 'rgba(35,183,229,0.1)',
    $errorSelectorColorHover: 'rgba(35,183,229,0.18)',
    $errorSelectorColorActive: 'rgba(35,183,229,0.24)',
    $blueLightBackgroundColor: '#EFF3F6',

    // practice errors colors
    $mainError: '#920000',
    $firstAdditionalError: '#FED36D',
    $secondAdditionalError: '#8AE0B5',
    $otherError: '#88BAFF',

    // old color theme
    textColor: '#000',
    textColorReversed: '#FFF',
    textColorDisabled: '#888888',
    textColorDark: '#363636',
    textColorHighlited: novotalkBlue,
    backgroundColor: '#FFF',
    backgroundColorLight: '#FCFCFC',
    backgroundColorMedium: '#F0F0F0',
    backgroundColorMediumHover: '#E1E1E1',
    ctaButtonColor: novotalkOrange,
    ctaButtonColorHover: '#D8760C',
    ctaButtonColorActive: '#BB6407',
    secondaryButtonColor: '#696A6B',
    secondaryButtonColorHover: '#464646',
    secondaryButtonColorActive: '#363636',
    actionButtonColor: novotalkBlue,
    actionButtonColorHover: '#1297C7',
    actionButtonColorActive: '#0077A7',
    borderColorLight: '#CBCBCB',
    sublistBackground: '#F7F7F7',
    borderColorDark: '#261E9A',
    popoverButtonColor: novotalkBlue,
    cardHeader: '#DEF7FF',
    masteryFail: novotalkOrange,
    masteryPass: '#47DA7D',
    autoFeedbackAdd: '#4c91ff',
    resourcesPanel: '#006686',
    errorColor: '#D0021B',
    warningColor: '#F49E31',
    selfChatBubble: '#EFF7FF',
    otherChatBubble: '#DBECFF',
    chatTextColor: '#5E7694',

    multiSelectButtonColor: '#D8D8D8',
    multiSelectButtonColorSelected: '#696868',
    radioButtonColor: '#979797',
    avatarBorder: '#A8A8A8',
    buttonFocusColor: '#9ecaed',

    //sizes
    trialBarHeight: 70,
    navigationBarHeight: 70,
    skillPartHeaderHeight: 70,
    courseOverviewHeaderHeight: 140,
    courseOverviewProgressBarHeight: 100,
    authFormsWidth: 481,
    videoChatMessengerWidth: 375,
}

export type ThemeColorsType = typeof novotalkColors