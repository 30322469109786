import React from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import injectReducer from '@utils/injectReducer';
import injectSaga from '@utils/injectSaga';
import CTAButton from '@components/CTAButton';
import Text from '@components/Text';
import CustomTextInput from '@components/CustomTextInput';
import CustomDropzoneComponent from './components/CustomDropzoneComponent/index';
import { Tags } from '@components/Tags';
import LoadingArea from './components/LoadingArea';
import { ModalContainer, WithMargin, HeaderContainer } from './styles';
import { sendMediaData, setUploadStatus, uploadStatus } from './actions';
import reducer from './reducer';
import saga from './saga';
import { MOCK_TAGS } from '@shared/constants';
import { getResources } from '@containers/Admin/shared/ResourcesWrapper/actions';
import { RESOURCES } from '@shared/Resources/types';
import { checkForExistingName } from '@containers/Admin/containers/Builder/containers/BuilderHeader/actions';
import { TITLE_EXISTS_TEXT } from '@containers/App/containers/PopUp/popups/MediaEditor/components/form';
import {withTranslation} from "react-i18next";

export class MediaUpload extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isItemLoading: false,
      uploadedFiles: [],
      tags: [],
      title: '',
      description: '',
    };
  }

  onUploadButtonClick = () => {
    const { title, description, uploadedFiles, tags: tagsState } = this.state;
    const { sendUploadedData } = this.props;

    this.setState({ isItemLoading: true });
    const tags = tagsState.map(({ value }) => value)
    sendUploadedData({ title, description, uploadedFiles, tags });
  };

  onLoadingFinishedButtonClick = () => {
    const {
      resetUploadStatus,
      onClose,
      onItemUploaded,
      sentItem,
      dispatchLoadResources,
    } = this.props;

    this.setState({
      isItemLoading: false,
      uploadedFiles: [],
      tags: [],
      description: '',
      title: '',
    });

    if (onItemUploaded) onItemUploaded(sentItem);
    resetUploadStatus(uploadStatus.DEFAULT);
    dispatchLoadResources(RESOURCES.MEDIA);
    onClose();
  };

  handleAddedFile = (file) => {
    this.setState({ uploadedFiles: [file] });
  };

  handleRemovedFile = () => {
    this.setState({ uploadedFiles: [] });
  };

  handleInput = ({ target: { name, value } }) => {
    this.setState({ [name]: value });
  };

  handleCheckExistingTitle = (title) => {
    if (!title) return;
    const { checkExistingName } = this.props;
    checkExistingName(RESOURCES.MEDIA, title);
  }

  inputError = () => {
    return this.props.existingName ? TITLE_EXISTS_TEXT : null;
  }

  renderTextInputs = () => {
    const { title, description, tags } = this.state;

    return (
      <span>
        <WithMargin marginTop={10} marginBottom={10}>
          <CustomTextInput
            name='title'
            label={this.props.t('Common.UI.title')}
            value={title}
            onChange={this.handleInput}
            onBlur={({ target }) => target && this.handleCheckExistingTitle(target.value)}
            error={this.inputError()}
          />
        </WithMargin>
        <WithMargin marginBottom={20}>
          <CustomTextInput
            name='description'
            label={this.props.t('Common.UI.description')}
            value={description}
            onChange={this.handleInput}
          />
        </WithMargin>

        <WithMargin marginBottom={20}>
          <Tags
            handleTagsChange={this.handleTagsChange}
            value={tags}
            options={MOCK_TAGS}
            placeholder="Tags"
          />
        </WithMargin>
      </span>
    );
  };

  handleTagsChange = (option) => {
    this.setState({ tags: option });
  };

  isUploadDisabled = () => {
    const { uploadedFiles, title } = this.state;
    const { existingName } = this.props;
    return !uploadedFiles.length || !title.length || existingName;
  };

  renderUploadButton = () => (
    <WithMargin marginTop={10}>
      <CTAButton
        action
        expanded
        data-cy='media_page_modal_upload_button'
        disabled={this.isUploadDisabled()}
        onClick={this.onUploadButtonClick}
      >
        {this.props.t('Actions.Instance.upload')}
      </CTAButton>
    </WithMargin>
  );

  render() {
    const { uploadedFiles, isItemLoading } = this.state;
    return (
      <ModalContainer>
        <HeaderContainer>
          <Text size='H1' weight='200' margin='20px 0 50px 0'>
            {this.props.t('Actions.Files.uploadMedia')}
          </Text>
        </HeaderContainer>
        {isItemLoading ? (
          <LoadingArea
            onLoadingFinishedButtonClick={this.onLoadingFinishedButtonClick}
          />
        ) : (
          <div>
            <CustomDropzoneComponent
              uploadedFiles={uploadedFiles}
              handleAddedFile={this.handleAddedFile}
              handleRemovedFile={this.handleRemovedFile}
            />
            {this.renderTextInputs()}
            {this.renderUploadButton()}
          </div>
        )}
      </ModalContainer>
    );
  }
}

MediaUpload.defaultProps = {
  resetUploadStatus: () => {},
  setStatusToSending: () => {},
  sendUploadedData: () => {},
};

MediaUpload.propTypes = {
  onClose: PropTypes.func,
  sentItem: PropTypes.object,
  sendUploadedData: PropTypes.func.isRequired,
  resetUploadStatus: PropTypes.func.isRequired,
  onItemUploaded: PropTypes.func,
};

const mapStateToProps = (state) => {
  const jsState = state.getIn(['mediaUploadReducer'])
    ? state.getIn(['mediaUploadReducer']).toJS()
    : {};
  const { error, sentItem } = jsState;
  const { existingName } = state.getIn(['nameChecker']).toJS();
  return { error, sentItem, existingName };
};

export const mapDispatchToProps = (dispatch) => ({
  sendUploadedData: (data) => dispatch(sendMediaData(data)),
  resetUploadStatus: (status) => dispatch(setUploadStatus(status)),
  dispatchLoadResources: (type) => dispatch(getResources(type)),
  checkExistingName: (type, name) => dispatch(checkForExistingName(type, name)),
});

const withConnect = connect(mapStateToProps, mapDispatchToProps);
const withReducer = injectReducer({ key: 'mediaUploadReducer', reducer });
const withSaga = injectSaga({ key: 'mediaUploadReducer', saga });

export default compose(
  withConnect,
  withReducer,
  withSaga,
    withTranslation()
)(MediaUpload);
