import { SummaryActivityLog } from '../summary';
import { TitleWithRating } from './styles';
import Text from "@components/Text";
import {useTranslation} from "react-i18next";

export const VideoChatSummary = ({ summary }) => {
    const {t} = useTranslation()
    return (
        <>
            <TitleWithRating>
                <Text size="H4" textTransform="uppercase">
                    {t('Video.videoChatSummary')}
                </Text>
                <Text size="H4">
                    {t('UI.chatRating')}
                </Text>
            </TitleWithRating>
            <SummaryActivityLog summary={summary} />
        </>
    );
}
