import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {
  Wrapper, StyledPlayIcon, StyledPauseIcon,
  StyledTrack,
} from './styles';

class AudioPlayer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isPlaying: false,
      trackValue: 0,
    };
    this.audioRef = React.createRef();
    this.trackRef = React.createRef();
  }

  componentDidMount() {
    const { getAudioRef } = this.props;
    const audioElement = this.audioRef.current;
    const trackElement = this.trackRef.current;
    if (audioElement && trackElement) {
      trackElement.value = 0;

      audioElement.oncanplay = () => {
        getAudioRef(audioElement);
        trackElement.max = parseInt(audioElement.duration, 10);
      };

      trackElement.addEventListener('click', () => {
        audioElement.currentTime = trackElement.value;
        const { trackValue } = this.state;
        if (trackValue !== trackElement.value) {
          this.setState({ trackValue: trackElement.value });
        }
      });

      audioElement.addEventListener('timeupdate', () => {
        trackElement.value = parseInt(audioElement.currentTime, 10);
        const { trackValue } = this.state;
        if (trackValue !== trackElement.value) {
          this.setState({ trackValue: trackElement.value });
        }
      });

      audioElement.addEventListener('play', () => this.setState({ isPlaying: true }));
      audioElement.addEventListener('pause', () => this.setState({ isPlaying: false }));
    }
  }

  clickHandler = () => {
    const audioElement = this.audioRef.current;
    const { isPlaying } = this.state;
    if (isPlaying) {
      audioElement.pause();
    } else {
      audioElement.play();
    }
  }

  render() {
    const { src, className } = this.props;
    const { isPlaying, trackValue } = this.state;
    const Button = isPlaying ? StyledPauseIcon : StyledPlayIcon;
    const trackElement = this.trackRef.current;
    const position = trackElement && trackElement.max ? (trackValue / trackElement.max) * 100 : 0;
    return (
      <Wrapper className={className || 'audioplayer'}>
        <Button onClick={this.clickHandler} className="audioplayer-button" />
        <StyledTrack type="range" step="1" ref={this.trackRef} position={position} className="audioplayer-track" />
        <audio src={src} ref={this.audioRef} className="audioplayer-source" />
      </Wrapper>
    );
  }
}

AudioPlayer.propTypes = {
  src: PropTypes.string,
  getAudioRef: PropTypes.func,
  className: PropTypes.string,
};

export default AudioPlayer;
