import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import Text, { TEXT_SIZE, TEXT_COLOR, TEXT_WEIGHT } from '@components/Text';
import { TitleContainer } from './styles';
import { RESOURCES } from '@shared/Resources/types';
import { DescriptionArea } from '@shared/Resources/shared/components/OverviewComponents/styles';
import OverviewSection from '@shared/Resources/shared/components/OverviewSection';
import {useTranslation} from "react-i18next";

const SkillOverview = ({
  resource: { texts, free, children },
  onView,
  onEdit,
  onExpand,
  readOnly,
}) => {
  const {t} = useTranslation()
  const sectionsByTypeMap = children.reduce((acc, current) => {
    if (!acc[current.type]) {
      acc[current.type] = {
        type: current.type,
        items: [],
        onView,
        onEdit,
        onExpand,
        readOnly,
        parentType: RESOURCES.SKILL,
      };
    }
    acc[current.type].items.push(current);
    return acc;
  }, {});
  return (
    <div>
      <DescriptionArea>
        <TitleContainer>
          <Text
            size={TEXT_SIZE.H4}
            textColor={TEXT_COLOR.DARK_BLUE}
            weight={TEXT_WEIGHT.BOLD}
          >
            <span className='descriptionTitle'>
              {t('Common.UI.description')}
            </span>
          </Text>
          {free && (
            <Text
              size={TEXT_SIZE.H4}
              textColor={TEXT_COLOR.DARK_BLUE}
              weight={TEXT_WEIGHT.BOLD}
            >
              <span className='isFree'>
                {t('UI.freeSkill')}
              </span>
            </Text>
          )}
        </TitleContainer>
        <Text
          size={TEXT_SIZE.T4}
          textColor={TEXT_COLOR.DARK_BLUE}
          className='description'
        >
          {_.get(texts, 'description', '')}
        </Text>
      </DescriptionArea>
      {_.keys(sectionsByTypeMap).map((type) => (
        <OverviewSection key={type} {...sectionsByTypeMap[type]} />
      ))}
    </div>
  );
};

SkillOverview.propTypes = {
  readOnly: PropTypes.bool,
  resource: PropTypes.object,
  onEdit: PropTypes.func,
  onView: PropTypes.func,
  onExpand: PropTypes.func,
};

export default SkillOverview;
