import React from 'react';
import SubscriberVideo from '../SubscriberVideo';
import VideoCall from '../VideoCall';

export const placeholdersHandler = (
  size,
  maxHeight,
  users,
  numOfParticipants,
  paddingBottom,
) => {
  let userCount = 0;
  const subscribers = users.filter((user) => !user.self);
  const placeholders = subscribers.map((user) => {
    userCount += 1;
    const props = {
      numOfParticipants,
      user,
      userCount,
      size,
      maxHeight,
      paddingBottom
    };
    return <VideoCall.SubscriberVideo {...props} />;
  });

  return placeholders;
};
