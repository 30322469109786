import {styled} from "@mui/material";
import { isDesktop } from "@shared/constants/media-queries";
const isDesktopSize = isDesktop();

export const PracticeErrorsCardWrapper = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
  maxWidth: !isDesktopSize && 437,
  minHeight: isDesktopSize ? 415 : 392,
  marginBottom: 40,
  padding: `10px ${isDesktopSize ? 30 : 10}px`,
  borderRadius: '0px 5px 5px 0px',
},
({ theme }) => ({
  border: `1px solid ${theme.palette.novotalk.$borderColorMedium}`,
  color: theme.palette.novotalk.$textColorDarkBlue,
}));

export const PieChartWithListErrors = styled('div')({
  display: 'flex',
  justifyContent: `space-${isDesktopSize ? 'around' : 'between'}`,
  alignItems: 'center',
  minWidth: 250,
});
