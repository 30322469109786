import { takeLatest, call, put, all, fork } from 'redux-saga/effects';
import { forgotPassword } from '@services/authenticationService';
import {
  Types,
  onSendPasswordLinkFail,
  onSendPasswordLinkSuccess,
} from './actions';

function* callPostResetPasswordData(action) {
  try {
    const { email } = action.payload;

    yield call(forgotPassword, email);
    yield put(onSendPasswordLinkSuccess());
  } catch (error) {
    yield put(onSendPasswordLinkFail(error.message));
  }
}

function* watchCallPostResetPasswordData() {
  yield takeLatest(Types.SEND_PASSWORD_LINK, callPostResetPasswordData);
}

export default function* watchLoginForgotPasswordActions() {
  yield all([fork(watchCallPostResetPasswordData)]);
}
