export const TYPES = {
  POPUP_CLOSE: 'POPUP_CLOSE',
  POPUP_OPEN: 'POPUP_OPEN',
};

export function closePopUp() {
  return {
    type: TYPES.POPUP_CLOSE,
  };
}

export function openPopUp(type, props) {
  return {
    type: TYPES.POPUP_OPEN,
    payload: { type, props },
  };
}

