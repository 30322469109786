import {styled} from "@mui/material";
import CTAButton from '@components/CTAButton';
import { getResponsiveCSS } from '@utils/cssHelpers';

export const OptionsWrapper = styled('div')({
  display: 'flex',
  justifyContent: 'center',
  margin: '10px 0',
});

export const StyledCTAButton = styled(CTAButton)(
  {
    width: 160,
    margin: '0 5px',
  },
  getResponsiveCSS('height', 44, 48)
);
