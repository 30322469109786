import {styled} from "@mui/material";

export const TextSelectCenter = styled('div')({
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)'
});

export const ActivityPanelWrapper = styled('div')({
  padding: '90px 15px 40px',
  position: 'relative',
  width: '40%',
  maxWidth: 680,
  overflowY: 'auto',
  height: 'calc(100vh - 153px)',
});

export const ActivityPanelContent = styled('div')({
  display: 'flex',
  flexDirection: 'column',
});
