import { useTranslation } from 'react-i18next';
import { ReactElement, useCallback, useEffect, useMemo, useState } from 'react';
import _ from 'lodash';
import SectionLoading from '@components/SectionLoading';
import { TableWrapperUserLog, LoadingOverlayUserLog, NoItemsUserLog, TableListUserLog } from './styles';
import { sortUserLogNestedHandler } from './helpers';
import { UserLog } from './types';
import { RowUserLog } from './components/RowUserLog';
import { HeaderUserLogSortableTable } from './components/HeaderUserLogSortableTable';

export enum ORDERS {
  ASC = 'asc',
  DESC = 'desc',
}
export type TableColumn = {
  title: string;
  getDisplay: (data: UserLog, current: boolean) => ReactElement;
  width: string;
  sortable: boolean;
  sortField: string;
};

type TableUserLogProps = {
  config: TableColumn[];
  onRowSelect: (activity: UserLog) => void;
  userLogs: UserLog[];
  loading: boolean;
};

const calculateSpread = (config: TableColumn[]): string => config.map(({ width }) => width || 'auto').join(' ');

export const TableUserLog = ({ config, onRowSelect, userLogs, loading }: TableUserLogProps) => {
  const [localSortedList, setLocalSortedList] = useState<UserLog[]>([]);
  const [currentId, setCurrentId] = useState<string>('');
  const [order, setOrder] = useState<ORDERS>(ORDERS.DESC);
  const [sortField, setSortField] = useState<string>(config && config.length ? config[2].sortField : '');
  const { t } = useTranslation();
  const spread = useMemo(() => calculateSpread(config), [config]);
  useEffect(() => {
    const sortedArray = sortUserLogNestedHandler(sortField, [...userLogs], order);
    setLocalSortedList(sortedArray);
  }, [sortField, order]);
  useEffect(() => {
    const sortedArray = sortUserLogNestedHandler(sortField, [...userLogs], order);
    setLocalSortedList(sortedArray);
  }, [userLogs]);

  const sortFieldHandler = useCallback(
    (field: string) => {
      if (field === sortField) {
        const ord = order === ORDERS.ASC ? ORDERS.DESC : ORDERS.ASC;
        setOrder(ord);
      } else {
        setSortField(field);
        setOrder(ORDERS.ASC);
      }
    },
    [order, sortField],
  );
  const onRowSelectModified = useCallback(
    (item: UserLog) => {
      setCurrentId(item.id);
      onRowSelect(item);
    },
    [currentId, onRowSelect],
  );
  return (
    <TableWrapperUserLog>
      {loading && (
        <LoadingOverlayUserLog>
          <SectionLoading />
        </LoadingOverlayUserLog>
      )}
      <HeaderUserLogSortableTable
        spread={spread}
        config={config}
        orderHandler={sortFieldHandler}
        order={order}
        sortFieldLocale={sortField}
      />
      <TableListUserLog>
        {!localSortedList.length ? (
          <NoItemsUserLog>{t('Informative.Negative.noItems')}</NoItemsUserLog>
        ) : (
          <div>
            {localSortedList.map((item: UserLog) => (
              <RowUserLog
                spread={spread}
                handleRowSelect={onRowSelectModified}
                current={currentId === item.id}
                config={config}
                item={item}
              />
            ))}
          </div>
        )}
      </TableListUserLog>
    </TableWrapperUserLog>
  );
};
