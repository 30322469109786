import { styled } from '@mui/material';

export const VideoPlaceholder = styled('div')(
  {
    width: '100%',
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  ({ theme }) => ({
    backgroundColor: theme.palette.novotalk.$videoChatWaitingBackground,
  }),
);

export const HiddenVideoPlaceholder = styled('div')(({theme, shrinkedHeight}) => ({
    minWidth: `${shrinkedHeight ? 0 : 1}!important`,
    width: `${shrinkedHeight ? 0 : 1}!important`,
    minHeight: `${shrinkedHeight ? 0 : 48}!important`,
    height: `${shrinkedHeight ? 0 : 'inherit'}!important`,
    marginBottom: shrinkedHeight ? '8px!important' : 0,
    zIndex: -10,
    backgroundColor: theme.palette.novotalk.$videoChatWaitingBackground,
}))