import React from 'react';
import PropTypes from 'prop-types';
import { MEDIA_TYPES } from '../../types';
import MediaClient from '../../Poll/Client/components/Media';

import { Wrapper } from './styles';

const MediaMiniOverview = ({ value, title, innerSection }) => (
  <Wrapper innerSection={innerSection}>
    <MediaClient
      {... {
        contentUrl: value,
        contentType: MEDIA_TYPES.VIDEO,
        description: title,
        id: title }}
      miniOverview
    />
  </Wrapper>);

MediaMiniOverview.propTypes = {
  value: PropTypes.string,
  title: PropTypes.string.isRequired,
  innerSection: PropTypes.bool,
};

export default MediaMiniOverview;
