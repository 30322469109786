import { fromJS } from 'immutable';
import { TYPES } from './actions';

export const initialState = fromJS({
  pendingVideoChatInfo: {},
});


function pollingServiceReducer(state = initialState, action) {
  switch (action.type) {
    case TYPES.SET_PENDING_VIDEO_CHAT_INFO:
      return state.setIn(['pendingVideoChatInfo'], fromJS(action.pendingVideoChatInfo));

    default:
      return state;
  }
}

export default pollingServiceReducer;
