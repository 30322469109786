import {styled} from "@mui/material";
import { getResponsiveCSS } from '@utils/cssHelpers';

export const Wrapper = styled('div')(getResponsiveCSS('marginBottom', 40, 70));

export const RangeSelection = styled('div')({
  margin: '0 40px',
});

export const HeaderControlsWrapper = styled('div')({
  display: 'flex',
  alignItems: 'center',
});

export const TableWrapper = styled('div')({
  minHeight: 100,
  display: 'flex',
  justifyContent: 'center',
});

export const PreviewIconWrapper = styled('span')(
  {
    display: 'inline-block',
    cursor: 'pointer',
    margin: '-2px 8px 0',
    fontSize: '15px',
  },
  ({ theme }) => ({
    color: theme.palette.novotalk.$textColorLightGray,
  })
);
