import {sim1,sim2,sim3} from '../components/SpeechGoalStatsSimulation/__fixtures__';
const fixture = {
    name: "Speech goal name",
    type:'SPEECH_GOAL',
    level: 15,
    activityId:"Some id",
    timeStamp:1521461212,
    report: {
      "feeling": 1,
      "focus": [
        "SPEECH_GOALS_FOCUS_BREATHING",
        "SPEECH_GOALS_FOCUS_GENTLE_ONSET",
        "SPEECH_GOALS_FOCUS_LINKAGE",
        "SPEECH_GOALS_FOCUS_WAVE_PATTERN",
        "SPEECH_GOALS_FOCUS_LIGHT_CONTACT",
        "SPEECH_GOALS_FOCUS_EXAGGERATED_VOWELS"],
      "useOfTechniques": 4,
      "difficulty": 2,
      "fluency": 5
    },
    simulations: [sim1,sim2,sim3],
  };

  export default fixture;
