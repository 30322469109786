import {styled} from "@mui/material";
import { getIconsByType, iconTypes } from '@services/icons';
import { getColor } from '@components/Text';

export const TableRow = styled('div')(
  {
    display: 'grid',
    height: 47,
    alignItems: 'center',
    cursor: 'pointer',
    justifyContent: 'stretch',
    paddingLeft: 10,
    '& span': {
      lineHeight: '13px',
    },
  },
  ({ spread, isSelected, theme }) => ({
    gridTemplateColumns: spread,
    borderBottom: `1px solid ${theme.palette.novotalk.$resourcesTableBorderColor}`,
    backgroundColor: isSelected
      ? theme.palette.novotalk.$resourcesTableBgColorSelected
      : theme.palette.novotalk.$backgroundColor,
    '&:hover': {
      backgroundColor: theme.palette.novotalk.$resourcesTableBgColorHover,
    },
    '&:active': {
      backgroundColor: theme.palette.novotalk.$resourcesTableBgColorActive,
    },
  })
);

export const TableHeader = styled(TableRow)(
  {
    paddingTop: 13,
    alignItems: 'start',
    height: 47,
    cursor: 'auto',
    '& span': {
      lineHeight: 'normal',
    },
  },
  ({ theme }) => ({
    '&:hover': {
      backgroundColor: theme.palette.novotalk.$backgroundColor,
    },
    '&:active': {
      backgroundColor: theme.palette.novotalk.$backgroundColor,
    },
  })
);

const headerArrowStyles = ({ theme }) => ({
  fontSize: 24,
  margin: '0 3px',
  color: theme.palette.novotalk.$textColorDisabled,
  cursor: 'pointer',
});

export const StyledArrowUpIcon = styled(
  getIconsByType(iconTypes.FILLED_ARROW_UP)
)(headerArrowStyles);
export const StyledArrowDownIcon = styled(
  getIconsByType(iconTypes.FILLED_ARROW_DOWN)
)(headerArrowStyles);

export const Cell = styled('div')(
  {
    paddingLeft: 10,
    display: 'flex',
    alignItems: 'center',
  },
  ({ withEllipsis, theme, color }) =>
    withEllipsis && {
      display: 'block',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      '& span': {
        whiteSpace: 'pre',
      },
      // making ellipsis the same color as the whole text
      color: getColor(theme, color),
    }
);

export const StyledArrowRightIcon = styled(
  getIconsByType(iconTypes.FILLED_ARROW_RIGHT)
)({
  fontSize: 24,
  marginLeft: 10,
},
  ({ theme }) => ({
    color: theme.palette.novotalk.$activeColor,
  })
);