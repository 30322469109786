import { ORDERS } from './PendingTable';

export const sortNestedHandler = (prop: string, arr: any[], order:ORDERS): any[] => {
  const sortValues = order === ORDERS.ASC ? [-1, 1] : [1, -1];
  const propList = prop.split('.');
  const len = propList.length;
  arr.sort((a, b) => {
    for (let i = 0; i < len; i++) {
      a = a[propList[i]] || ' ';
      b = b[propList[i]] || ' ';
    }
    if (a < b) {
      return sortValues[0];
    }
    if (a > b) {
      return sortValues[1];
    }
    return 0;
  });
  return arr;
};
