import React from 'react';
import Text, {TEXT_COLOR, TEXT_SIZE, TEXT_WEIGHT} from "@components/Text";
import {Cell} from "@shared/Resources/shared/components/ResourcesList/styles";

export const renderRowData = (displayData, item, isSelected) => {
    return displayData.map(({getDisplay, textOptions}, index) => {
        const {size, color, weight, withEllipsis} = textOptions;
        const currentColor = isSelected
            ? TEXT_COLOR.ACTIVE
            : color || TEXT_COLOR.DISABLED;
        return (
            <Cell
                key={`${item.id}-${index}`}
                withEllipsis={withEllipsis}
                color={currentColor}
            >
                <Text
                    size={size || TEXT_SIZE.T5}
                    textColor={currentColor}
                    weight={weight || TEXT_WEIGHT.NORMAL}
                    withEllipsis={withEllipsis}
                >
                    {getDisplay(item, isSelected)}
                </Text>
            </Cell>
        );
    })
};