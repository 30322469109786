import { fromJS } from 'immutable';
import { TYPES } from './actions';
import { types as appTypes } from '../../actions';

export const initialState = fromJS({
  user: {},
  course: {},
  loadingPasswordChange: false,
  changePasswordResponse: null,
  changePasswordResponseError: null,
});

function settingsReducer(state = initialState, action) {
  switch (action.type) {
    case TYPES.SET_USER_INFO: {
      return state
        .set('user', fromJS(action.userData.user))
        .set('course', action.userData.course);
    }
    case TYPES.CHANGE_PASSWORD:
      return state
        .set('loadingPasswordChange', true)
        .set('changePasswordResponse', null)
        .set('changePasswordResponseError', null);
    case TYPES.SET_CHANGE_PASSWORD_RESPONSE:
      return state
        .set('loadingPasswordChange', false);
    case TYPES.SET_CHANGE_PASSWORD_RESPONSE_FAIL:
      return state
        .set('loadingPasswordChange', false)
        .set('changePasswordResponseError', action.error);
    case appTypes.LOG_OUT:
      return state.set('user', fromJS({})).set('course', fromJS({}));
    default:
      return state;
  }
}

export default settingsReducer;
