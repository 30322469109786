import {
  IconError,
  IconWithText,
  ErrorLabel,
  ErrorPercent,
  PracticeErrorWrapper,
} from '../../styles';

export const ErrorNameCard = ({ index, label, value }) => (
  <PracticeErrorWrapper>
    <IconWithText>
      <IconError order={index} />
      <ErrorLabel isMainError={index === 0}>
        {label}
      </ErrorLabel>
    </IconWithText>
    <ErrorPercent isMainError={index === 0}>
      {`${value}%`}
    </ErrorPercent>
  </PracticeErrorWrapper>
);
