import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Container from '@components/Container';

import {
  StyledCorrectIcon,
  StyledErrorIcon,
  StyledCurrentIcon,
  StyledFutureIcon,
} from './styles';

class RepetitionProgress extends Component {
  renderIndicator = (index) => {
    const {
      repetitions, repetitionPosition, errorRepetitionPosition, productionCount,
    } = this.props;

    if (index === repetitionPosition) {
      return StyledCurrentIcon;
    }
    const includesError = errorRepetitionPosition.includes(index);
    const beforeCurrent = index < repetitionPosition;
    const lastPosition = repetitionPosition === 0 && (index === repetitions - 1);
    const errorOnLastPosition = (includesError && lastPosition);
    if ((includesError) && beforeCurrent || (errorOnLastPosition && productionCount !== 0)) {
      return StyledErrorIcon;
    }
    if (beforeCurrent || (lastPosition && productionCount !== 0)) {
      return StyledCorrectIcon;
    }
    return StyledFutureIcon;
  };

  render() {
    const indicators = new Array(this.props.repetitions).fill(0);
    return (
      <Container>
        {indicators.map((indicator, index) => {
          const Icon = this.renderIndicator(index);
          return <Icon key={index} />;
        })}
      </Container>
    );
  }
}

RepetitionProgress.propTypes = {
  productions: PropTypes.arrayOf(PropTypes.object),
  repetitions: PropTypes.number,
  disableCurrentIndicator: PropTypes.bool,
};

export default RepetitionProgress;
