/**
 * Create the store with dynamic reducers
 */

import { createStore, applyMiddleware, compose } from 'redux';
import { fromJS } from 'immutable';
import createSagaMiddleware from 'redux-saga';
import { routerMiddleware } from 'connected-react-router';
import { createLogger } from 'redux-logger';
import _ from 'lodash';
import { toast } from 'react-toastify';
import createReducer from './reducers';
import { eventLogger } from '@services/eventLoggerService';
import userFixtures from '@containers/App/__fixtures__/user';
import {
  setToLocalStorage,
  LOCAL_STORAGE_ITEM,
  removeFromLocalStorage,
  getFromLocalStorage,
} from './utils/localStorageHelper';
import rootSaga from './utils/root-saga';
import { TOAST_AUTO_CLOSE_TIME } from './shared/constants';
import {t} from 'i18next'

const sagaMiddleware = createSagaMiddleware({
  onError: (error, { sagaStack }) => {
    toast.error(t('Informative.Negative.refreshPage'), {
      autoClose: TOAST_AUTO_CLOSE_TIME,
      position: 'bottom-right',
    });
  },
});

const getStoredUser = () => {
  const storedUserString = getFromLocalStorage(LOCAL_STORAGE_ITEM.USER);

  return storedUserString ? storedUserString : {};
};

/* eslint no-unused-vars: [1, { "args": "after-used" }] */
export default function configureStore(initialState = {}, history) {
  // Create the store with three middlewares
  // 1. sagaMiddleware: Makes redux-sagas work
  // 2. routerMiddleware: Syncs the location/URL path to the state

  const middlewares = [sagaMiddleware, routerMiddleware(history)];

  // 3. Redux logger:  in charge of logging redux events ONLY IN DEVELEOPMENT
  if (process.env.NODE_ENV === 'development') {
    const logger = createLogger();
    middlewares.push(logger);
  }

  middlewares.push(eventLogger);

  const enhancers = [applyMiddleware(...middlewares)];

  // If Redux DevTools Extension is installed use it, otherwise use Redux compose
  /* eslint-disable no-underscore-dangle */
  const composeEnhancers =
    process.env.NODE_ENV !== 'production' &&
    typeof window === 'object' &&
    window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
      ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({
          // TODO Try to remove when `react-router-redux` is out of beta, LOCATION_CHANGE should not be fired more than once after hot reloading
          // Prevent recomputing reducers for `replaceReducer`
          shouldHotReload: false,
        })
      : compose;

  let storedUser = getStoredUser();

  // for dev-mode
  if (process.env.USE_FIXTURES) {
    if (!storedUser.firstName && !storedUser.lastName) {
      if (_.isEmpty(storedUser)) {
        removeFromLocalStorage(LOCAL_STORAGE_ITEM.USER);
      }
      storedUser = userFixtures;
      setToLocalStorage(LOCAL_STORAGE_ITEM.USER, userFixtures);
    }
  }

  const modifiedInitialState = {
    app: {
      authentication: { user: storedUser },
      serverProperties: {
        loading: false,
        properties: [],
      },
    },
    currentPractice: fromJS({
      loading: true,
      practice: {},
      currentExercise: {},
      currentPracticeActivity: {},
      currentExerciseActivity: {},
      feedbacks: [],
    }),
  };

  /* eslint-enable */
  const store = createStore(
    createReducer(history),
    fromJS(modifiedInitialState),
    composeEnhancers(...enhancers),
  );

  // Extensions
  sagaMiddleware.run(rootSaga);
  store.runSaga = () => {};
  store.injectedReducers = {}; // Reducer registry
  store.injectedSagas = {}; // Saga registry

  // Make reducers hot reloadable, see http://mxs.is/googmo
  /* istanbul ignore next */
  if (module.hot) {
    module.hot.accept('./reducers', () => {
      store.replaceReducer(createReducer(store.injectedReducers));
    });
  }

  // Move to initial redux initialazation in configureStore.js

  return store;
}
