import { useMemo } from 'react';
import {
  PracticeErrorsCardWrapper,
  PieChartWithListErrors,
} from './styles';
import { formatErrors } from './helpers';
import { SuccessRate } from './components/success-rate';
import { PracticeErrorsPieChart } from './components/pie-chart';
import { ErrorsList } from './components/errors-list';
import { ErrorTypes } from './components/error-types';

/**
 * Card for one practice error
 */
export const PracticeErrorsCard = ({
  data: {
    errors,
    exercises,
    productionsWithErrors,
    productionsWithoutErrors,
    subType,
}}) => {
  const formattedErrors = useMemo(() => formatErrors(errors), [errors]);
  return (
    <PracticeErrorsCardWrapper>
      <ErrorTypes
        patternType={subType}
        exercises={exercises}
        productions={{ productionsWithErrors, productionsWithoutErrors }}
      />
      <SuccessRate productions={{ productionsWithErrors, productionsWithoutErrors }} />
      {errors &&
        <PieChartWithListErrors>
          <PracticeErrorsPieChart formattedErrors={formattedErrors} />
          <ErrorsList formattedErrors={formattedErrors} />
        </PieChartWithListErrors>
      }
    </PracticeErrorsCardWrapper>
  );
};
