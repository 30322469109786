import { styled } from '@mui/material';
import {
  IoIosCheckboxOutline,
  IoIosHelpCircle,
  IoLogoYoutube,
  IoIosMenu,
  IoIosClose,
  IoMdCloseCircle,
  IoIosCloseCircleOutline,
  IoIosPlay,
  IoIosPause,
  IoMdMusicalNote,
  IoIosStar,
  IoMdSad,
  IoMdCreate,
  IoMdTrash,
  IoMdAdd,
  IoIosChatboxes,
  IoMdRadioButtonOn,
  IoMdArrowDropup,
  IoMdArrowDropright,
  IoMdArrowDropleft,
  IoMdArrowDropdown,
  IoIosPerson,
  IoIosLock,
  IoMdTime,
  IoIosStats,
  IoMdDesktop,
  IoMdRadioButtonOff,
  IoMdSquareOutline,
  IoMdAlert,
  IoMdEye,
  IoIosVolumeHigh,
  IoIosImage,
  IoMdDocument,
  IoIosArrowRoundForward,
  IoIosDocument,
  IoIosTv,
  IoIosPlayCircle,
  IoIosFilm,
  IoIosArrowRoundBack,
  IoMdVideocam,
  IoMdMic,
  IoMdMicOff,
  IoMdPower,
  IoMdBulb,
  IoMdSpeedometer,
  IoMdVolumeHigh,
  IoMdTrendingUp,
  IoMdHeadset,
  IoIosHourglass,
  IoMdHappy,
  IoMdText,
  IoMdClipboard,
  IoMdRefresh,
  IoIosCheckmarkCircleOutline,
  IoMdCheckmarkCircleOutline,
  IoIosCheckmarkCircle,
  IoIosMore,
  IoMdPerson,
  IoMdCard,
  IoMdMail,
  IoMdNotifications,
  IoIosSettings,
  IoMdLogOut,
  IoMdHome,
  IoMdPulse,
  IoMdSwap,
  IoIosSpeedometer,
  IoIosAnalytics,
  IoIosWarning,
  IoMdSync,
  IoMdInfinite,
  IoMdQuote,
  IoMdMenu,
  IoMdMicrophone,
  IoMdImages,
  IoMdAddCircle,
  IoIosKeypad,
  IoMdInformationCircle,
  IoIosCopy,
  IoMdList,
  IoMdCalendar,
  IoMdSearch,
  IoMdSchool,
  IoMdImage,
  IoMdArrowDropdownCircle,
  IoMdChatbubbles,
  IoMdSave,
  IoIosGitBranch,
  IoMdTv,
  IoMdCloseCircleOutline,
  IoMdPeople,
} from 'react-icons/io';
import {
  FiAlertCircle,
  FiChevronDown,
  FiChevronLeft,
  FiChevronRight,
  FiChevronUp,
  FiThumbsDown,
  FiThumbsUp,
  FiList,
  FiPlusSquare,
  FiFilm,
  FiYoutube,
  FiMail,
  FiHelpCircle,
  FiCheck,
} from 'react-icons/fi';
import { MdDashboard } from 'react-icons/md';
import { GoArrowRight } from 'react-icons/go';
import {
  BsFillFileEarmarkPersonFill,
  BsFillQuestionCircleFill,
} from 'react-icons/bs';

import { IconType } from 'react-icons';
import {
  RESOURCES,
  TYPES,
  QUESTION_TYPES,
  MEDIA_TYPES,
} from '@shared/Resources/types';
import { ACTIVITY_TYPES } from '@containers/Admin/containers/ActivityPage/constants';
import { PRODUCTION_ERRORS } from '@shared/Resources/Monitor/types';
import CustomIcons from './customIcons';
import generalIconTypes from './generalIconTypes';

// override sanitize.css rule of svg { fill: currentColor} for Feather icons
const noFill = { fill: 'none' };
const {
  FeedbackSVG,
  MonitorSVG,
  StimulusSVG,
  CreateSVG,
  RecreationSVG,
  KidsSVG,
  MailSVG,
  InhalationIcon,
  VideoOffSVG,
  VideoChatSVG,
  InsightsGentleSVG,
  InsightsLengthSVG,
  InsightsPatternSVG,
  InsightsTooFlat,
  InsightsTooDeep,
  InsightsTooSlowTooFast,
  InsightsVolumeControlSVG,
  LocationSVG,
} = CustomIcons;

export const getIconByResourceType = (type) => {
  switch (type) {
    // media types
    case MEDIA_TYPES.VIDEO:
      return IoLogoYoutube;
    case MEDIA_TYPES.AUDIO:
      return IoIosVolumeHigh;
    case MEDIA_TYPES.IMAGE:
      return IoIosImage;
    // question types
    case TYPES.QUESTION:
      return IoIosHelpCircle;
    case TYPES.MEDIA:
      return IoMdImage;
    case TYPES.TEXT:
      return IoMdInformationCircle;
    // Questions types
    case QUESTION_TYPES.FREE_TEXT:
      return IoMdList;
    case QUESTION_TYPES.SINGLE_SELECT:
      return IoMdRadioButtonOn;
    case QUESTION_TYPES.MULTI_SELECT:
      return IoMdCheckmarkCircleOutline;
    case QUESTION_TYPES.DATE:
      return IoMdCalendar;
    case QUESTION_TYPES.DROP_DOWN:
      return IoMdArrowDropdownCircle;
    // Course types
    case RESOURCES.POLL:
      return IoIosStats;
    case RESOURCES.COURSE:
      return IoMdSchool;
    case RESOURCES.SKILL:
      return IoMdRadioButtonOn;
    case RESOURCES.LESSON:
      return IoMdDesktop;
    case RESOURCES.PRACTICE:
      return IoIosDocument;
    case RESOURCES.MONITOR:
      return MonitorSVG;
    case RESOURCES.STIMULUS:
      return StimulusSVG;
    case RESOURCES.SPEECH_GOAL:
      return IoMdChatbubbles;
    case RESOURCES.EXERCISE:
      return IoIosTv;
    case RESOURCES.VIDEO_CHAT:
      return IoMdVideocam;
    default:
      console.error('unknown icon type', type);
      return IoIosHelpCircle;
  }
};

export const getIconByActivityType = (type) => {
  switch (type) {
    case ACTIVITY_TYPES.ACTIVITY_PRACTICE:
    case TYPES.PRACTICE:
      return IoIosDocument;
    case ACTIVITY_TYPES.ACTIVITY_LESSON:
    case TYPES.LESSON:
      return IoMdDesktop;
    case ACTIVITY_TYPES.ACTIVITY_EXERCISE:
    case TYPES.EXERCISE:
      return IoIosTv;
    case ACTIVITY_TYPES.ACTIVITY_CHAT:
      return IoMdVideocam;
    case generalIconTypes.FILLED_ARROW_RIGHT:
      return IoMdArrowDropright;
    default:
      console.error('unknown icon type', type);
      return IoIosHelpCircle;
  }
};

export const getIconByError = (errorType) => {
  switch (errorType) {
    case PRODUCTION_ERRORS.ExcessivePeak:
      return IoIosClose;
    default:
      return IoIosClose;
  }
};

export const getIconsByType = (type) => {
  switch (type) {
    // Arrows
    case generalIconTypes.CHEVRON_UP:
      return styled(FiChevronUp)(noFill);
    case generalIconTypes.CHEVRON_DOWN:
      return styled(FiChevronDown)(noFill);
    case generalIconTypes.CHEVRON_LEFT:
      return styled(FiChevronLeft)(noFill);
    case generalIconTypes.CHEVRON_RIGHT:
      return styled(FiChevronRight)(noFill);
    case generalIconTypes.FILLED_ARROW_UP:
      return IoMdArrowDropup;
    case generalIconTypes.FILLED_ARROW_RIGHT:
      return IoMdArrowDropright;
    case generalIconTypes.FILLED_ARROW_LEFT:
      return IoMdArrowDropleft;
    case generalIconTypes.FILLED_ARROW_DOWN:
      return IoMdArrowDropdown;
    case generalIconTypes.FULL_ARROW_RIGHT:
      return GoArrowRight;
    case generalIconTypes.THIN_ARROW_RIGHT:
      return IoIosArrowRoundForward;
    case generalIconTypes.THIN_ARROW_LEFT:
      return IoIosArrowRoundBack;
    // Insights
    case generalIconTypes.INSIGHTS_GENTLE:
      return InsightsGentleSVG;
    case generalIconTypes.INSIGHTS_LENGTH:
      return InsightsLengthSVG;
    case generalIconTypes.INSIGHTS_PATTERN:
      return InsightsPatternSVG;
    case generalIconTypes.INSIGHTS_VOLUME_CONTROL:
      return InsightsVolumeControlSVG;
    case generalIconTypes.INSIGHTS_TOO_FLAT:
      return InsightsTooFlat;
    case generalIconTypes.INSIGHTS_TOO_DEEP:
      return InsightsTooDeep;
    case generalIconTypes.INSIGHTS_TOO_SLOW:
      return InsightsTooSlowTooFast;
    case generalIconTypes.INSIGHTS_TOO_FAST:
      return InsightsTooSlowTooFast;
    // ------
    case generalIconTypes.CLOSE:
      return IoIosClose;
    case generalIconTypes.CLOSE_CIRCLE:
      return IoMdCloseCircle;
    case generalIconTypes.CLOSE_CIRCLE_OUTLINE:
      return IoIosCloseCircleOutline;
    case generalIconTypes.HAMBURGER:
      return IoIosMenu;
    case generalIconTypes.ERROR:
      return styled(FiAlertCircle)(noFill);
    case generalIconTypes.ERROR_FILLED:
      return IoMdAlert;
    case generalIconTypes.THUMBS_DOWN:
      return styled(FiThumbsDown)(noFill);
    case generalIconTypes.THUMBS_UP:
      return styled(FiThumbsUp)(noFill);
    case generalIconTypes.PLAY:
      return IoIosPlay;
    case generalIconTypes.PLAY_CIRCLE:
      return IoIosPlayCircle;
    case generalIconTypes.PAUSE:
      return IoIosPause;
    case generalIconTypes.LIST:
      return styled(FiList)(noFill);
    case generalIconTypes.MUSICAL_NOTE:
      return IoMdMusicalNote;
    case generalIconTypes.SEARCH:
      return IoMdSearch;
    case generalIconTypes.STAR:
      return IoIosStar;
    case generalIconTypes.PLUS_SQUARE:
      return styled(FiPlusSquare)(noFill);
    case generalIconTypes.SAD_FACE:
      return IoMdSad;
    case generalIconTypes.CHECKMARK:
      return styled(FiCheck)(noFill);
    case generalIconTypes.BOLD_CHECKMARK:
      return styled(FiCheck)({ fill: 'none', strokeWidth: 5 });
    case generalIconTypes.CIRCLE_CHECKMARK:
      return IoIosCheckmarkCircleOutline;
    case generalIconTypes.CIRCLE_CHECKMARK_OVERFLOWED:
      return IoMdCheckmarkCircleOutline;
    case generalIconTypes.CIRCLE_CHECKMARK_FILLED:
      return IoIosCheckmarkCircle;
    case generalIconTypes.EDIT:
      return IoMdCreate;
    case generalIconTypes.DRAG:
      return IoIosKeypad;
    case generalIconTypes.DELETE:
      return IoMdTrash;
    case generalIconTypes.DUPLICATE:
      return IoIosCopy;
    case generalIconTypes.ADD:
      return IoMdAdd;
    case generalIconTypes.VIDEO_DEMO:
      return styled(FiFilm)(noFill);
    case generalIconTypes.VIDEO_LESSON:
      return styled(FiYoutube)(noFill);
    case generalIconTypes.MAIL:
      return styled(FiMail)(noFill);
    case generalIconTypes.COMMENTS:
      return IoIosChatboxes;
    case generalIconTypes.RADIO_ON:
      return IoMdRadioButtonOn;
    case generalIconTypes.RADIO_OFF:
      return IoMdRadioButtonOff;
    case generalIconTypes.HELP:
      return styled(FiHelpCircle)(noFill);
    case generalIconTypes.HELP_FILLED:
      return IoIosHelpCircle;
    case generalIconTypes.AVATAR:
      return IoIosPerson;
    case generalIconTypes.LOCK:
      return IoIosLock;
    case generalIconTypes.CLOCK:
      return IoMdTime;
    case generalIconTypes.FEEDBACK:
      return FeedbackSVG;
    case generalIconTypes.CHECKBOX_EMPTY:
      return IoMdSquareOutline;
    case generalIconTypes.CHECKBOX_FULL:
      return IoIosCheckboxOutline;
    case generalIconTypes.PREVIEW:
      return IoMdEye;
    case generalIconTypes.DOCUMENT:
      return IoMdDocument;
    case generalIconTypes.POWER:
      return IoMdPower;
    case generalIconTypes.SOUND_ON:
      return IoMdMic;
    case generalIconTypes.SOUND_OFF:
      return IoMdMicOff;
    case generalIconTypes.VIDEO_ON:
      return IoMdVideocam;
    case generalIconTypes.VIDEO_OFF:
      return VideoOffSVG;
    case generalIconTypes.TV:
      return IoIosTv;
    case generalIconTypes.FILM:
      return IoIosFilm;
    case generalIconTypes.TIP:
      return IoMdBulb;
    case generalIconTypes.SPEEDOMETER:
      return IoMdSpeedometer;
    case generalIconTypes.VOLUME_HIGH:
      return IoMdVolumeHigh;
    case generalIconTypes.TRENDING_UP:
      return IoMdTrendingUp;
    case generalIconTypes.HEADSET:
      return IoMdHeadset;
    case generalIconTypes.HOURGLASS:
      return IoIosHourglass;
    case generalIconTypes.HAPPY:
      return IoMdHappy;
    case generalIconTypes.TEXT:
      return IoMdText;
    case generalIconTypes.CLIPBOARD:
      return IoMdClipboard;
    case generalIconTypes.REFRESH:
      return IoMdRefresh;
    case generalIconTypes.CREATE:
      return CreateSVG;
    case generalIconTypes.PROGRESS:
      return CreateSVG;
    case generalIconTypes.REPETITION:
      return IoIosMore;
    case generalIconTypes.PRODUCTION_DURATION:
      return IoMdTime;
    case generalIconTypes.PATTERN_TYPE:
      return CustomIcons.PatternTypeSVG;
    case generalIconTypes.MESSAGE:
      return IoMdMail;
    case generalIconTypes.NOTIFICATION:
      return IoMdNotifications;
    case generalIconTypes.SETTINGS:
      return IoIosSettings;
    case generalIconTypes.LOGOUT:
      return IoMdLogOut;
    case generalIconTypes.RECREATION:
      return RecreationSVG;
    case generalIconTypes.MONITOR_INHALE:
      const icon: IconType = styled(InhalationIcon);
      return icon;
    case generalIconTypes.KIDS:
      return KidsSVG;
    case generalIconTypes.CUSTOM_MAIL:
      return MailSVG;
    case generalIconTypes.PERSON:
      return IoMdPerson;
    case generalIconTypes.VIDEO_CHAT:
      return VideoChatSVG;
    case generalIconTypes.CARD:
      return IoMdCard;
    case generalIconTypes.HOME:
      return IoMdHome;
    case generalIconTypes.PULSE:
      return IoMdPulse;
    case generalIconTypes.SWAP:
      return IoMdSwap;
    case generalIconTypes.SPEEDOMETER_FILLED:
      return IoIosSpeedometer;
    case generalIconTypes.ANALYTICS:
      return IoIosAnalytics;
    case generalIconTypes.WARNING:
      return IoIosWarning;
    case generalIconTypes.SYNC:
      return IoMdSync;
    case generalIconTypes.INFINITE:
      return IoMdInfinite;
    case generalIconTypes.QUOTE:
      return IoMdQuote;
    case generalIconTypes.MENU:
      return IoMdMenu;
    case generalIconTypes.MICROPHONE:
      return IoMdMicrophone;
    case generalIconTypes.IMAGES:
      return IoMdImages;
    case generalIconTypes.ADD_CIRCLE:
      return IoMdAddCircle;
    case generalIconTypes.LOCATION:
      return LocationSVG;
    case generalIconTypes.SAVE:
      return IoMdSave;
    case generalIconTypes.VERSION:
      return IoIosGitBranch;
    case generalIconTypes.SCREEN_SHARING:
      return IoMdTv;
    case generalIconTypes.STOP_SCREEN_SHARING:
      return IoMdCloseCircleOutline;
    case generalIconTypes.ROLES_MANAGEMENT:
      return IoMdPeople;
    case generalIconTypes.RESOURCES:
      return IoMdList;
    case generalIconTypes.USER_RECORDS:
      return BsFillFileEarmarkPersonFill;
    case generalIconTypes.MENTOR_DASHBOARD:
      return MdDashboard;
    case generalIconTypes.PRACTICE_ERRORS:
      return BsFillQuestionCircleFill;
    default:
      console.error('unknown icon type', type);
      return IoIosHelpCircle;
  }
};

export const iconTypes = generalIconTypes;
