import React, { Component } from 'react';
import Text from '@components/Text';
import { compose } from 'redux';
import { Wrapper, FeedbackIcon, IconWrapper } from './styles';
import {withTranslation} from "react-i18next";

class FeedbackIndicator extends Component {
  render() {
    const { t } = this.props;
    return (
      <Wrapper>
        <IconWrapper>
          <FeedbackIcon />
        </IconWrapper>
        <Text size='H4' textColor='highlighted' textTransform='capitalize'>
          {t('Common.UI.feedback')}
        </Text>
      </Wrapper>
    );
  }
}

export default compose(withTranslation())(FeedbackIndicator);
