import {styled} from "@mui/material";
import { iconTypes, getIconsByType } from '@services/icons';

export const InstructionsArea = styled('div')(
  {
    paddingBottom: 15,
    marginBottom: 15,
  },
  ({ theme }) => ({
    borderBottom: `1px solid ${theme.palette.novotalk.$resourcesTableBorderColor}`,
  })
);

export const TitleContainer = styled('div')({
  marginBottom: 5,
});

export const StyledDocumentIcon = styled(getIconsByType(iconTypes.DOCUMENT))(
  {
    marginRight: 8,
    marginTop: -4,
    marginLeft: -2,
    fontSize: 18,
  },
  ({ theme }) => ({
    color: theme.palette.novotalk.$textColorHighlited,
  })
);
