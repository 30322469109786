import React from 'react';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'redux';
import injectReducer from '@utils/injectReducer';
import injectSaga from '@utils/injectSaga';
import { IoIosArrowBack, IoIosArrowForward } from 'react-icons/io';
import SectionLoading from '@components/SectionLoading';
import RoleManagementSection from '@shared/RoleManagementSection';
// TODO: FIX REDUCER LOGIC (ON ADD ROLE)
import {
  getRoleManagementData,
  selectUser,
  selectRole,
  addRoleToUser,
  removeRoleFromUser,
  clearSelected,
} from './actions';
import reducer from './reducer';
import saga from './saga';
import {
  Container,
  Main,
  ModeSelection,
  Button,
  ToggleButton,
  Users,
  Roles,
  DynamicItems,
  Controls,
} from './styles';
import { withTranslation } from 'react-i18next';

export const MODE = {
  ROLE_CENTRIC: 'ROLE_CENTRIC',
  USER_CENTRIC: 'USER_CENTRIC',
};

class RoleManagement extends React.Component {
  // eslint-disable-line react/prefer-stateless-function
  constructor(props) {
    super(props);
    this.state = { filter: '', mode: MODE.USER_CENTRIC };
  }
  componentDidMount() {
    this.props.getData();
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevState.mode !== this.state.mode) {
      this.props.dispatchClearSelected();
    }
  }

  onChange = (event) => {
    const { value } = event.target;
    this.setState({ filter: value });
  };

  getRoleById = (roleId) => {
    const { roles } = this.props;
    return roles.find((role) => role.roleId === roleId);
  };

  toggleModeTo = (mode) => () => this.setState({ mode });
  selectRole = (role) => this.props.selectRole(role);
  selectUser = (user) => this.props.selectUser(user);

  filterUsers = (users) => {
    const { filter } = this.state;
    return _.isEmpty(filter)
      ? users
      : users.filter(
          (user) =>
            user.userName.includes(filter) || user.id.toString() === filter
        );
  };
  renderHeader = (mode) => (
    <ModeSelection>
      <ToggleButton
        onClick={this.toggleModeTo(MODE.USER_CENTRIC)}
        className='togglToUsersCentric'
        disabled={mode === MODE.USER_CENTRIC}
      >
        {this.props.t('UI.userCentric')}
      </ToggleButton>

      <ToggleButton
        onClick={this.toggleModeTo(MODE.ROLE_CENTRIC)}
        className='togglToRolesCentric'
        disabled={mode === MODE.ROLE_CENTRIC}
      >
        {this.props.t('UI.roleCentric')}
      </ToggleButton>
    </ModeSelection>
  );

  renderControls = (selectedRole, selectedUser) => (
    <Controls>
      <Button
        onClick={() => {
          this.props.addRoleToUser(selectedRole, selectedUser, this.state.mode);
        }}
        id='addRoleButton'
        disabled={selectedRole === null || selectedUser === null}
      >
        {this.props.t('Actions.Instance.add')}
        <div>
          <IoIosArrowBack />
        </div>
      </Button>
      <Button
        id='removeRoleButton'
        onClick={() => {
          this.props.removeRoleFromUser(selectedRole, selectedUser);
        }}
        disabled={selectedRole === null || selectedUser === null}
      >
        {this.props.t('Actions.Instance.remove')}
        <div>
          <IoIosArrowForward />
        </div>
      </Button>
    </Controls>
  );
  renderUsers = (users, selectedUser) => (
    <Users>
      <RoleManagementSection
        items={users}
        titleMessage={this.props.t('Common.Roles.users')}
        onItemSelect={this.selectUser}
        filterItemBy={(filter) => (user) =>
          user.userName.includes(filter) || user.id.toString() === filter}
        getItemKey={(user) => user.id}
        isItemsSelected={(user) => selectedUser && user.id === selectedUser.id}
        getItemDisplay={(user) => user.userName}
      />
    </Users>
  );

  renderRoleUsers = (users, selectedRole, selectedUser) => {
    const roleUsers = selectedRole
      ? users.filter((user) =>
          user.roles.find((role) => role === selectedRole.roleId)
        )
      : [];
    return (
      <DynamicItems>
        <RoleManagementSection
          className='roleUsers'
          items={roleUsers}
          titleMessage={this.props.t('UI.roleUsers')}
          onItemSelect={(user) => {
            this.selectUser(user);
          }}
          noItemsMessage={this.props.t('Informative.Negative.noUserSelected')}
          showFilter={false}
          getItemKey={(user) => user.id}
          isItemsSelected={(user) =>
            selectedUser && user.id === selectedUser.id
          }
          getItemDisplay={(user) => user.userName}
        />
      </DynamicItems>
    );
  };

  renderUserRoles = (selectedUser, selectedRole) => {
    return (
      <DynamicItems>
        <RoleManagementSection
          className='userRoles'
          items={selectedUser ? selectedUser.roles : []}
          titleMessage={this.props.t('UI.titleUserRoles')}
          onItemSelect={(userRole) => {
            this.selectRole(this.getRoleById(userRole));
          }}
          noItemsMessage={this.props.t('Informative.Negative.noRoleSelected')}
          showFilter={false}
          getItemKey={(userRole) => userRole.userRole}
          isItemsSelected={(userRole) => {
            return selectedRole && userRole === selectedRole.roleId;
          }}
          getItemDisplay={(userRole) => {
            const role = this.getRoleById(userRole);
            const description = role.description;
            return description;
          }}
        />
      </DynamicItems>
    );
  };

  renderRoles = (roles, selectedRole) => {
    return (
      <Roles>
        <RoleManagementSection
          items={roles}
          titleMessage={this.props.t('Common.Roles.roles')}
          onItemSelect={this.selectRole}
          filterItemBy={(filter) => (role) =>
            role.description.includes(filter) || role.id.toString() === filter}
          getItemKey={(role) => role.id}
          isItemsSelected={(role) =>
            selectedRole && role.id === selectedRole.id
          }
          getItemDisplay={(role) => role.description}
        />
      </Roles>
    );
  };

  renderDynamicItems = (mode, users, selectedUser, selectedRole) =>
    mode === MODE.USER_CENTRIC
      ? this.renderUserRoles(selectedUser, selectedRole)
      : this.renderRoleUsers(users, selectedRole, selectedUser);

  render() {
    const { loading, users, roles, selectedUser, selectedRole } = this.props;
    const { mode } = this.state;
    if (loading) return <SectionLoading />;
    return (
      <Container>
        <Main roleCentric={mode === MODE.ROLE_CENTRIC}>
          {this.renderHeader(mode)}
          {this.renderUsers(users, selectedUser)}
          {this.renderDynamicItems(mode, users, selectedUser, selectedRole)}
          {this.renderControls(selectedRole, selectedUser)}
          {this.renderRoles(roles, selectedRole)}
        </Main>
      </Container>
    );
  }
}

RoleManagement.propTypes = {
  loading: PropTypes.bool,
  users: PropTypes.array,
  roles: PropTypes.array,

  selectedUser: PropTypes.object,
  selectedRole: PropTypes.object,

  getData: PropTypes.func,
  selectUser: PropTypes.func,
  selectRole: PropTypes.func,
  addRoleToUser: PropTypes.func,
  removeRoleFromUser: PropTypes.func,
};

const mapStateToProps = (state) => {
  const { loading, users, roles, selectedUser, selectedRole } = state
    .getIn(['roleManagement'])
    .toJS();
  return { loading, users, roles, selectedUser, selectedRole };
};

export function mapDispatchToProps(dispatch) {
  return {
    getData: () => dispatch(getRoleManagementData()),
    selectUser: (user) => dispatch(selectUser(user)),
    selectRole: (role) => dispatch(selectRole(role)),
    addRoleToUser: (role, user, screen) =>
      dispatch(addRoleToUser(role, user, screen)),
    removeRoleFromUser: (role, user) =>
      dispatch(removeRoleFromUser(role, user)),
    dispatchClearSelected: () => dispatch(clearSelected()),
  };
}
const withReducer = injectReducer({ key: 'roleManagement', reducer });
const withConnect = connect(mapStateToProps, mapDispatchToProps);
const withSaga = injectSaga({ key: 'builder', saga });

export default compose(
    withReducer,
    withConnect,
    withSaga,
    withTranslation()
)(RoleManagement);

// export default RoleManagement;
