import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Text, { TEXT_SIZE, TEXT_COLOR } from '@components/Text';
import Container from '@components/Container';
import TextButton from '@components/TextButton';
import { Wrapper, StyledNewItemIcon, IconWrapper } from './styles';
import { withRouter } from 'react-router-dom';
import { BackButton } from '@components/BackButton';
import {compose} from "redux";
import {withTranslation} from "react-i18next";

export class ListHeader extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  onBack = () => {
    this.props.history.goBack();
  };

  render() {
    const {startNewConversation, t} = this.props;
    return (
      <Wrapper>
        <BackButton
          onClick={this.onBack}
          text={t('Actions.Navigation.back')}
        />
        <Text size={TEXT_SIZE.T2} textColor={TEXT_COLOR.NORMAL}>
          {t('Common.Messaging.messages')}
        </Text>
        <Container margin='-2px 15px 0 48px'>
          <IconWrapper>
            <StyledNewItemIcon />
          </IconWrapper>
          <TextButton onClick={startNewConversation}>
            <Text size={TEXT_SIZE.T4}>
              {t('Common.Messaging.newMessage')}
            </Text>
          </TextButton>
        </Container>
      </Wrapper>
    );
  }
}

ListHeader.propTypes = {
  startNewConversation: PropTypes.func,
};

export default compose(
    withRouter,
    withTranslation()
)(ListHeader);
