import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'redux';
import injectReducer from '@utils/injectReducer';
import injectSaga from '@utils/injectSaga';
import { withRouter } from 'react-router-dom';
import _ from 'lodash';
import CTAButton from '@components/CTAButton';
import ErrorMessage from '@components/ErrorMessage';
import Text, { TEXT_COLOR, TEXT_SIZE } from '@components/Text';
import CustomTextInput from '@components/CustomTextInput/index';
import {
  formHasErrors,
  getErrorMessageId,
  fieldTypes,
  getPasswordStrength,
  checkStrengthPassword,
} from '../validate';
import { PasswordHint } from '../PasswordHint';
import {
  ResetPasswordComponentContainer,
  WithHeaderComponentContainer,
  InputContainer,
  ButtonContainer,
  TextContainer,
  ResetPasswordScreenContainer,
  HeaderContainer,
  HintContainer,
  StyledLogo,
  PasswordTooltip,
  InputContainerAutoHeight,
} from './styles';
import LogoImage from '../logoTop.svg';
import { onPasswordReset, onClearRedirect } from './actions';
import reducer from './reducer';
import saga from './saga';
import { logOut } from '../../../actions';
import {withTranslation} from "react-i18next";

export class ResetPassword extends Component {
  constructor(props) {
    super(props);
    props.signOut();
    this.state = {
      loading: false,
      authError: false,
      form: {
        values: {
          password: '',
          confirmPassword: '',
        },
        errors: {
          password: null,
          confirmPassword: null,
        },
      },
      passwordStrength: -1,
    };
  }

  componentWillReceiveProps(newProps) {
    if (newProps.redirectToPage) {
      this.props.history.push(newProps.redirectToPage);
    }
  }

  componentWillUnmount() {
    this.props.clearRedirect();
  }
  onFocus = (event) => {
    const name = event.target.name;

    this.setState((prevState) => {
      const newForm = prevState.form;
      newForm.errors[name] = null;

      return {
        form: newForm,
      };
    });
  };

  onFieldUpdate = (event) => {
    const { value, name } = event.target;
    const { firstName, lastName } = this.props;

    if (name === 'password') {
      const strength = value.length
        ? getPasswordStrength(value, _.compact([firstName, lastName]))
        : -1;
      this.setState({ passwordStrength: strength });
    }
    this.setState((prevState) => {
      const newForm = prevState.form;
      newForm.values[name] = value;
      return {
        form: newForm,
      };
    });
  };

  onSubmitButtonClick = () => {
    const { resetPassword } = this.props;
    const { values } = this.state.form;

    const formErrors = this.getFormErrors();
    const newForm = _.cloneDeep(this.state.form);
    const redirectionReason = _.get(this.props.location, 'state.reason', null);
    const isPasswordChange =
      redirectionReason && redirectionReason !== 'passwordExpired';
    const loading = !formHasErrors(formErrors);
    newForm.errors = formErrors;

    this.setState({
      form: newForm,
      loading,
      authError: false,
    });

    if (loading) {
      const recoveryToken = this.props.match && this.props.match.params.token;
      if (!recoveryToken) {
        this.setState({
          authError: true,
        });
      } else {
        resetPassword(recoveryToken, values.password, isPasswordChange);
      }
    }
  };

  getFormErrors = () => {
    const {
      passwordStrength,
      form: { values },
    } = this.state;
    const {
      match: {
        params: { roleIndicator },
      },
    } = this.props;
    const newErrors = {};

    _.forEach(values, (fieldValue, fieldName) => {
      let messageId = null;
      const confirmField =
        fieldName === fieldTypes.confirmPassword ? values.password : null;
      const options = {
        isWeak:
          roleIndicator &&
          fieldName === fieldTypes.password &&
          passwordStrength !== 2,
      };

      messageId = getErrorMessageId(
        fieldName,
        fieldValue,
        confirmField,
        options
      );
      const fieldError = messageId
        ? this.props.t(`Errors.${messageId}`)
        : null;
      newErrors[fieldName] = fieldError;
    });

    return newErrors;
  };

  getErrorMessage = () => {
    let error;

    if (this.state.authError) {
      error = this.props.t(`Errors.authError`)
    } else if (this.props.serverError) {
      error = this.props.t(`Errors.serverError`)
    } else {
      return null;
    }
    return <ErrorMessage type={'CUSTOM'} customText={error} />;
  };

  getPasswordHint = () => {
    const {
      passwordStrength,
      form: { errors, values: { password } },
    } = this.state;
    const errorsList = checkStrengthPassword(password);
    if (!password.length || errors.password) return null;
    return (
      <HintContainer>
        <PasswordHint strength={passwordStrength} errors={errorsList} />
      </HintContainer>
    );
  };

  getPasswordTooltip = () => {
    const {
      match: {
        params: { roleIndicator },
      },
    } = this.props;
    const { passwordStrength } = this.state;
    if (!roleIndicator || passwordStrength === 2) return null;
    return (
      <PasswordTooltip>
        <Text size={TEXT_SIZE.T4} textColor={TEXT_COLOR.DARK_BLUE}>
          {this.props.t('Authentication.Password.passwordTooltip')}
        </Text>
      </PasswordTooltip>
    );
  };

  render() {
    const { values, errors } = this.state.form;
    const {t} = this.props
    const redirectionReason = _.get(this.props.location, 'state.reason', null);
    let formText = t('Authentication.Password.enterNew');
    let header = t('Authentication.Password.reset');
    if (redirectionReason === 'passwordExpired') {
      formText = t('Authentication.Password.resetRules');
      header = t('Authentication.Password.expired');
    }

    const commonProps = {
      type: 'password',
      onChange: this.onFieldUpdate,
      onFocus: this.onFocus,
    };

    return (
      <ResetPasswordScreenContainer>
        <WithHeaderComponentContainer>
          <HeaderContainer>
            <StyledLogo src={LogoImage} alt='logo' />
            <Text textColor={TEXT_COLOR.DARK_BLUE} size={TEXT_SIZE.H1}>
              {header}
            </Text>
          </HeaderContainer>
          <ResetPasswordComponentContainer>
            <TextContainer>
              <Text textColor={TEXT_COLOR.DISABLED} size={TEXT_SIZE.H4}>
                {formText}
              </Text>
            </TextContainer>

            <InputContainerAutoHeight>
              <CustomTextInput
                type="password"
                {...commonProps}
                id='password'
                label={t('Authentication.Password.newPassword')}
                name='password'
                error={errors.password}
                value={values.password}
                autofocus
              />
              {this.getPasswordHint()}
              {this.getPasswordTooltip()}
            </InputContainerAutoHeight>

            <InputContainer>
              <CustomTextInput
                type="password"
                {...commonProps}
                id='confirm-password'
                label={t('Authentication.Password.confirm')}
                name='confirmPassword'
                error={errors.confirmPassword}
                value={values.confirmPassword}
              />
            </InputContainer>

            <ButtonContainer>
              <CTAButton
                id='submit_button'
                expanded
                onClick={this.onSubmitButtonClick}
              >
                {t('Actions.Instance.submit')}
              </CTAButton>
            </ButtonContainer>
            {this.getErrorMessage()}
          </ResetPasswordComponentContainer>
        </WithHeaderComponentContainer>
      </ResetPasswordScreenContainer>
    );
  }
}

ResetPassword.defaultProps = {
  resetPassword: () => {},
};

ResetPassword.propTypes = {
  resetPassword: PropTypes.func.isRequired,
  // from react-router
  match: PropTypes.object,
  history: PropTypes.object,
  location: PropTypes.object,
  // from redux store
  serverError: PropTypes.string,
  firstName: PropTypes.string,
  lastName: PropTypes.string,
  signOut: PropTypes.func,
};

export const mapStateToProps = (state) => {
  const serverError = state.getIn(['resetPassword', 'errors', 'serverError']);
  const redirectToPage = state.getIn(['resetPassword', 'redirectToPage']);
  const { firstName, lastName } = state
    .getIn(['app', 'authentication', 'user'])
    .toJS();
  return { serverError, redirectToPage, firstName, lastName };
};

export const mapDispatchToProps = (dispatch) => ({
  resetPassword: (recoveryToken, password, isPasswordChange) => {
    dispatch(onPasswordReset(recoveryToken, password, isPasswordChange));
  },
  signOut: () => {
    dispatch(logOut());
  },
  clearRedirect: () => {
    dispatch(onClearRedirect());
  },
});

const withConnect = connect(mapStateToProps, mapDispatchToProps);
const withSaga = injectSaga({ key: 'resetPassword', saga });
const withReducer = injectReducer({ key: 'resetPassword', reducer });

export default compose(
  withConnect,
  withRouter,
  withReducer,
  withSaga,
    withTranslation()
)(ResetPassword);
