import { fromJS } from 'immutable';

// The initial state of the PopUp
export const initialState = fromJS({
  type: null,
  props: null,
  showing: false,
});


function popUpReducer(state = initialState, action) {
  switch (action.type) {
    case 'POPUP_CLOSE':
      return state
        .set('showing', false)
        .set('props', null)
        .set('type', null);
    case 'POPUP_OPEN':
      return state
        .set('showing', true)
        .set('props', action.payload.props)
        .set('type', action.payload.type);
    default:
      return state;
  }
}

export default popUpReducer;
