import {styled} from "@mui/material";
import {
  getIconByResourceType,
  getIconsByType,
  iconTypes,
} from '@services/icons';

export const ShownOverview = styled('div')({
  margin: '0 34px',
});

export const InstructionsSection = styled('div')({
    textAlign: 'left',
  marginBottom: 16,
});

export const SubEntityContainer = styled('div')(
  {
    height: 47,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  ({ theme }) => ({
    borderBottom: `solid 1px ${theme.palette.novotalk.$widgetMenuBorderColor}`,
    '&:last-child': {
      borderBottom: 'none',
    },
  })
);

export const getStyledResourceIcon = (type) =>
  styled(getIconByResourceType(type))(
    {
      fontSize: 23,
      marginRight: 10,
      marginTop: -2,
    },
    ({ theme }) => ({
      color: theme.palette.novotalk.$textColorLightGray,
    })
  );

export const EditIcon = getIconsByType(iconTypes.EDIT);
export const CancelIcon = getIconsByType(iconTypes.CLOSE_CIRCLE_OUTLINE);
export const SaveIcon = getIconsByType(iconTypes.SAVE);

export const EditButton = styled('div')(
  {
    justifySelf: 'flex-end',
    display: 'flex',
    alignItems: 'center',
    fontSize: 23,
    cursor: 'pointer',
  },
  ({ theme }) => ({
    color: theme.palette.novotalk.$dragIconColor,
    '&:hover': {
      color: theme.palette.novotalk.$dragIconColorHover,
    },
    '&:active': {
      color: theme.palette.novotalk.$dragIconColorActive,
    },
  })
);
