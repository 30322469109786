
import React from 'react';
import {
    StimuliButton,
    PictureStimuliWrapper,
    PictureWrapper,
    StimuliImage, StimuliImageButtonWrapper,
} from './styles';
import { StimuliText } from './styles';
import {
    StimuliPratsWrapper,
    Syllable,
} from './../styles'
import PropTypes from 'prop-types';

export const PictureStimuli = ({ text, src, handleGetStimuli, message }) =>
(
    <PictureStimuliWrapper>
        <StimuliPratsWrapper>
            <Syllable>
                <StimuliText>
                    {text}
                </StimuliText>
            </Syllable>
        </StimuliPratsWrapper>
        <PictureWrapper>
            <StimuliImage
                src={src}
            />
        </PictureWrapper>
        <StimuliImageButtonWrapper>
        <StimuliButton
            onClick={handleGetStimuli}
        >
            {message}
        </StimuliButton>
        </StimuliImageButtonWrapper>
    </PictureStimuliWrapper>
)


PictureStimuli.propTypes = {
    text: PropTypes.string,
    src: PropTypes.string,
    handleGetStimuli: PropTypes.func,
    message: PropTypes.string,
};