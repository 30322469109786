import {styled} from "@mui/material";

export const LoaderWrapper = styled('div')(
  {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: '-webkit-fill-available',
    minHeight: 100,
  },
  ({ theme }) => ({
    '& circle': {
      stroke: theme.palette.novotalk.$activeColor,
      fill: 'none',
      strokeLinecap: 'round',
    },
  })
);

export const LoaderCentralizer = styled('div')({
  position: 'absolute',
top: '50%',
left: '50%',
transform: 'translate(-50%, -50%)'
});
