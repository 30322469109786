export const TYPES = {
  GET_CURRENT_LESSON: 'GET_CURRENT_LESSON',
  SET_CURRENT_LESSON: 'SET_CURRENT_LESSON',
  START_ACTIVITY_LESSON: 'START_ACTIVITY_LESSON',
  END_ACTIVITY_LESSON: 'END_ACTIVITY_LESSON',
  END_ACTIVITY_LESSON_LOGOUT: 'END_ACTIVITY_LESSON_LOGOUT',
  SET_ACTIVITY_LESSON: 'SET_ACTIVITY_LESSON',
  RESET_ACTIVITY_LESSON: 'RESET_ACTIVITY_LESSON',
  RESET_LESSON: 'RESET_LESSON',
  SET_ERROR_LESSON: 'SET_ERROR_LESSON',
};

export function getCurrentLesson(id) {
  return {
    type: TYPES.GET_CURRENT_LESSON,
    id,
  };
}

export function setCurrentLesson(lesson) {
  return {
    type: TYPES.SET_CURRENT_LESSON,
    lesson,
  };
}

export function startActivityLesson(entityLessonInfo, activityContext) {
  return {
    type: TYPES.START_ACTIVITY_LESSON,
    entityLessonInfo,
    activityContext,
  };
}


export function endActivityLesson(activityLessonId, isLogout) {
  return {
    type: TYPES.END_ACTIVITY_LESSON,
    activityLessonId,
    isLogout
  };
}

export function setActivityLesson(activity) {
  return {
    type: TYPES.SET_ACTIVITY_LESSON,
    activity,
  };
}

export function resetActivityLesson() {
  return {
    type: TYPES.RESET_ACTIVITY_LESSON,
  };
}

export function resetLesson() {
  return {
    type: TYPES.RESET_LESSON,
  };
}
export function setLessonError(error) {
  return{
    type:TYPES.SET_ERROR_LESSON,
    error
  }
}
