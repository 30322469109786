/**
*
* MonitorPatterns
*
*/
import React from 'react';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import { compose } from 'redux';
import { connect } from 'react-redux';
import Text, { TEXT_SIZE, TEXT_COLOR } from '@components/Text';
import Select from '@components/Select';
import NumericSelector from '@components/NumericSelector';
import { PatternTypesTitle, ProductionDurationTitle, SelfManagedPaceTitle } from '@shared/Resources/Monitor/shared/Titles';
import Slider from '@components/Slider';
import {
  STIMULUS_DIVISION_TYPE, STIMULUS_TYPE, MONITOR_TYPES, PATTERN_TYPES, PATTERN_TYPES_TITLES,
} from '@shared/Resources/Monitor/types';
import {
  PatternTypeContainer, DurationWrapper, SelfManagedWrapper,
  SelectWrapper,
} from './styles';
import { defaultSelfManagePace, DEFAULT_DURATION_SECOND } from './consts';

class MonitorPatterns extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      pattern: { ...props.pattern },
      selected: '',
    };
  }

  componentDidUpdate(prevProps, prevState) {
    // Change production duration on two seconds if monitor type is "Loudness", pattern type is "2 seconds" and production duration does not equal two
    if (this.isTwoSecondsMonitorLoudness() || this.isOneSecondMonitor()) {
      const updatedDuration = this.state.pattern.type === PATTERN_TYPES.TWO_SECONDS ? DEFAULT_DURATION_SECOND.TWO : DEFAULT_DURATION_SECOND.ONE;
      this.setState({
        pattern: {
          ...this.state.pattern,
          productionDuration: updatedDuration,
        },
      });
    }
    if (prevProps.type !== this.props.type) {
      this.setState({
        selected: this.calculateSelectedMonitor(),
      });
      this.updatePatternType();
    }
  }

  componentDidMount() {
    if (this.props.pattern.type) {
      const options = _.keys(PATTERN_TYPES).map((value) => ({ value, label: PATTERN_TYPES_TITLES[value] }));
      const optionToSet = options.find((opt) => opt.value === this.props.pattern.type);
      this.setState({
        selected: optionToSet,
      });
    }
  }

  isTwoSecondsMonitorLoudness() {
    const { pattern } = this.state;
    const { monitor } = this.props;
    return pattern.type === PATTERN_TYPES.TWO_SECONDS
      && pattern.productionDuration !== DEFAULT_DURATION_SECOND.TWO
      && monitor.type === MONITOR_TYPES.LOUDNESS;
  }

  isOneSecondMonitor() {
    const { pattern } = this.state;
    return pattern.type === PATTERN_TYPES.ONE_SECOND && pattern.productionDuration !== DEFAULT_DURATION_SECOND.ONE
      && this.oneSecondMonitorDisabled();
  }

  setPattern = (pattern) => {
    const options = _.keys(PATTERN_TYPES).map((value) => ({ value, label: PATTERN_TYPES_TITLES[value] }));
    this.setState({ pattern, selected: options.find(({ value }) => value === pattern.type) });
    this.props.onChange(pattern);
  };

  oneSecondMonitorDisabled = () => {
    const { pattern } = this.state;
    const { stimulusType } = this.props;
    return (pattern.type === PATTERN_TYPES.ONE_SECOND
      && (stimulusType.type === STIMULUS_TYPE.SYLLABLES || stimulusType.type === STIMULUS_TYPE.WORDS
        || (stimulusType.type === STIMULUS_TYPE.SENTENCES
          && stimulusType.config.division === STIMULUS_DIVISION_TYPE.DIVIDED_TO_WORDS_SYLLABLES)));
  };

  updatePatternType = () => {
    const { pattern } = this.state;
    const { type } = this.props;
    pattern.type = type === MONITOR_TYPES.BREATHING ? PATTERN_TYPES.BREATHING : PATTERN_TYPES.WAVES;
    this.setPattern(pattern);
  };

  updatePatternField = (field, extractValue = false) => (value) => {
    const { pattern } = this.state;
    pattern[field] = extractValue ? value.value : value;
    if (field === 'type') {
      switch (pattern[field]) {
        case PATTERN_TYPES.SELF_MANAGED: {
          pattern.selfManagePace = defaultSelfManagePace;
          pattern.productionDuration = DEFAULT_DURATION_SECOND.SIX;
          break;
        }
        case PATTERN_TYPES.ONE_SECOND: {
          if (this.oneSecondMonitorDisabled()) {
            pattern.productionDuration = DEFAULT_DURATION_SECOND.ONE;
          }
          break;
        }
        case PATTERN_TYPES.TWO_SECONDS: {
          pattern.productionDuration = DEFAULT_DURATION_SECOND.TWO;
          break;
        }
        default: {
          pattern.selfManagePace = undefined;
          pattern.productionDuration = DEFAULT_DURATION_SECOND.FOUR;
          break;
        }
      }
    }
    this.setPattern(pattern);
  };

  calculateSelectedMonitor = () => {
    const options = this.calculateOptions();

    let selectedMonitor = '';

    if (this.props.type === MONITOR_TYPES.BREATHING) {
      selectedMonitor = options.find(({ value }) => value === PATTERN_TYPES.BREATHING);
    }

    if (this.props.type === MONITOR_TYPES.LOUDNESS) {
      selectedMonitor = options.find(({ value }) => value === PATTERN_TYPES.WAVES);
    }

    return selectedMonitor;
  };

  calculateOptions = () => {
    let options = _.keys(PATTERN_TYPES).map((value) => ({ value, label: PATTERN_TYPES_TITLES[value] }));

    if (this.props.type === MONITOR_TYPES.BREATHING) {
      options = options.filter((o) => o.value === PATTERN_TYPES.BREATHING);
    } else if (this.props.type === MONITOR_TYPES.LOUDNESS) {
      options = options.filter((o) => o.value !== PATTERN_TYPES.BREATHING);
    }
    return options;
  };

  renderPatternContainer = () => {
    const selectOptions = this.calculateOptions();
    const { selected } = this.state;
    const { disabledTypes } = this.props;

    return (
      <PatternTypeContainer>
        <PatternTypesTitle />
        <SelectWrapper>
          <Select
            isDisabled={disabledTypes}
            className="patternTypeSelect"
            value={selected}
            options={selectOptions}
            onChange={this.updatePatternField('type', true)}
            width={240}
          />
        </SelectWrapper>
      </PatternTypeContainer>
    );
  };

  renderProductionDurationSelection = () => {
    const { pattern } = this.state;
    const { disabledTypes } = this.props;
    const disabledMonitorTypes = pattern.type === PATTERN_TYPES.TWO_SECONDS || this.oneSecondMonitorDisabled();
    return (
      <>
        <ProductionDurationTitle />
        <DurationWrapper>
          <NumericSelector
            value={this.state.pattern.productionDuration}
            className="productionDuration"
            onChange={this.updatePatternField('productionDuration')}
            disabled={disabledMonitorTypes && !disabledTypes}
          />
          <Text size={TEXT_SIZE.T4} textColor={TEXT_COLOR.DARK_BLUE} margin="0 8px">
            {this.props.t('Dates.sec')}
          </Text>
        </DurationWrapper>
      </>
    );
  };

  renderSelfManagePaceSelection = () => {
    const { pattern } = this.state;
    return (
      <>
        <SelfManagedPaceTitle />
        <SelfManagedWrapper>
          <Slider
            className="speechPaceSelector"
            onChange={this.updatePatternField('selfManagePace')}
            value={pattern.selfManagePace}
            minValue={1}
            maxValue={6}
            step={1}
          />
        </SelfManagedWrapper>
      </>
    );
  };

  renderExtraConfig = () => {
    const { pattern } = this.state;
    const showSelfManagePace = pattern.type === PATTERN_TYPES.SELF_MANAGED;
    return showSelfManagePace
      ? this.renderSelfManagePaceSelection()
      : this.renderProductionDurationSelection();
  };

  render() {
    return (
      <div className="resource-item">
        {this.renderPatternContainer()}
        {this.renderExtraConfig()}
      </div>
    );
  }
}

MonitorPatterns.propTypes = {
  disabledTypes: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
  pattern: PropTypes.shape({
    type: PropTypes.string.isRequired,
    productionDuration: PropTypes.number,
    selfManagePace: PropTypes.shape({
      min: PropTypes.number,
      max: PropTypes.number,
    }),
  }).isRequired,
  type: PropTypes.string,
};

const mapStateToProps = (state) => {
  const { type } = state.getIn(['builder', 'info', 'monitor', 'monitor'])?.toJS();
  return { type };
};

const withConnect = connect(mapStateToProps, null);

export default compose(
  withConnect,
  withTranslation(),
)(MonitorPatterns);
