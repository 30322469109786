import React from 'react';
import PropTypes from 'prop-types';
import injectSaga from '@utils/injectSaga';
import { connect } from 'react-redux';
import { compose } from 'redux';
import saga from './saga';
import {
  getResources,
  getOverview,
  getExpanded,
  duplicateItem,
  deleteItem,
  createNewResource,
  copyItem,
} from './actions';

/**
 * This wrapper allow access to resource management of our app
 * it allows all component to load resources and overviews
 * ----------
 */
const ResourcesWrapper = (WrappedComponent) => {
  class ResourcesBaseComponent extends React.PureComponent {
    render() {
      return (
        <div className='withResources'>
          <WrappedComponent {...this.props} />
        </div>
      );
    }
  }

  ResourcesBaseComponent.propTypes = {
    loadingResources: PropTypes.bool,
    resources: PropTypes.array,
    overview: PropTypes.object,
    popup: PropTypes.object,
    type: PropTypes.string,
    itemId: PropTypes.string,
    dispatchLoadResources: PropTypes.func,
    dispatchLoadItem: PropTypes.func,
    dispatchExpandItem: PropTypes.func,
    dispatchDuplicateItem: PropTypes.func,
    dispatchDeleteItem: PropTypes.func,
    dispatchCreateNew: PropTypes.func,
  };

  const mapStateToProps = (state) =>
    state.get('resourceManager') ? state.get('resourceManager').toJS() : {};

  const mapDispatchToProps = (dispatch) => ({
    dispatchLoadResources: (type) =>
      dispatch(getResources(type)),
    dispatchLoadItem: (type, id, source) =>
      dispatch(getOverview(type, id, source)),
    dispatchExpandItem: ({ type, id }, source) =>
      dispatch(getExpanded(type, id, source)),
    dispatchDuplicateItem: (resource, userId) =>
      dispatch(duplicateItem(resource.type, resource,userId)),
    dispatchDeleteItem: ({ type, id }) =>
      dispatch(deleteItem(type, id)),
    dispatchCreateNew: (type) =>
      dispatch(createNewResource(type)),
    dispatchCopyItem: ({ type, id }) =>
      dispatch(copyItem(type, id)),
  });

  const withConnect = connect(mapStateToProps, mapDispatchToProps);
  const withSaga = injectSaga({ key: 'resourceManager', saga });

  return compose(withConnect, withSaga)(ResourcesBaseComponent);
};

export default ResourcesWrapper;
