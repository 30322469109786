import {styled} from "@mui/material";
import CTAButton from '@components/CTAButton';
import { getResponsiveCSS } from '@utils/cssHelpers';

export const Wrapper = styled('div')(
  {
    width: 575,
    height: 420,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: '34px 30px 30px',
    borderRadius: 4,
    textAlign: 'center',
  },
  ({ theme }) => ({
    backgroundColor: theme.palette.novotalk.$backgroundColor,
  })
);

export const StyledImage = styled('img')({
  width: 96,
  margin: 20,
});

export const ButtonsContainer = styled('div')({
  flexGrow: 1,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'flex-end',
});

export const StyledCTAButton = styled(CTAButton)(
  {
    margin: '0 6px',
  },
  getResponsiveCSS('width', 160, 180),
  getResponsiveCSS('height', 44, 48)
);
