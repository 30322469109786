import { useEffect } from 'react';
import { useSelector, useDispatch, RootStateOrAny } from 'react-redux';
import { useParams } from 'react-router-dom';
import { nanoid } from 'nanoid';
import { RESOURCES } from '@shared/Resources/types';
import { getResources } from '@containers/Admin/shared/ResourcesWrapper/actions';
import { userInformationFields } from './helpers.tsx';
import { InformationWrapper } from './styles';
import { TitleUserPage } from '../../styles';
import { fetchUserPage } from '../../../actions';
import { ItemWithLabel } from './components/ItemWithLabel/index.tsx';
import { SelectMentors } from './components/SelectMentors/index.tsx';
import { SelectCourse } from './components/SelectCourse/index.tsx';
import { SelectStatus } from './components/SelectStatus/index.tsx';
import { ItemFields } from './types.ts';
import {useTranslation} from "react-i18next";
/**
 * User Information Page
 */
export const UserInformation = () => {
  const {t} = useTranslation()
  const dispatch = useDispatch();
  const { id } = useParams<{ id: string }>();

  const { userData } = useSelector((state: RootStateOrAny) => state.get('userPage').toJS()); // FIXME replace to typing store
  const { roles } = useSelector((state: RootStateOrAny) => state.getIn(['app', 'authentication', 'user']).toJS()); // FIXME replace to typing store

  const isClinicalMentor = roles.includes('20') || roles.includes('10');

  useEffect(() => {
    dispatch(getResources(RESOURCES.COURSE));
    dispatch(fetchUserPage(id));
  }, []);

  return (
    <>
      <TitleUserPage>
          {t('UI.userInformation' as any)}
      </TitleUserPage>
      <InformationWrapper>
        {userData
          ? userInformationFields(
            userData,
            <SelectMentors isClinicalMentor={isClinicalMentor} />,
            <SelectCourse isClinicalMentor={isClinicalMentor} />,
            <SelectStatus isClinicalMentor={isClinicalMentor} />,
          ).map(({ label, content }: ItemFields) => (
            <ItemWithLabel
              key={nanoid()}
              label={label}
            >
              {content}
            </ItemWithLabel>
          ))
          : t('UI.noInformation' as any)}
      </InformationWrapper>
    </>
  );
};
