import React, { useEffect, useState, useMemo } from 'react';
import Text, { TEXT_COLOR, TEXT_SIZE } from '@components/Text';
import {
  SelfVideoWrapper,
  SelfAvatar,
  SelfUserInfo,
  UserControls,
  VideoChatEndedPlaceholder,
  SelectWrapperMonitorDropdown
} from '../../styles';
import Select from '@components/Select';
import { useSelector, useDispatch } from 'react-redux';
import { getSelfAvatar } from '../../helpers';
import VideoCall from '../VideoCall';
import { useVideoCallContext } from '@shared/hooks/useVideoCallContext';
import { monitorTypes } from '@shared/VideoChat/monitorTypes';
import { VIDEO_CHAT_MONITOR_TYPES } from '@shared/Resources/Monitor/types';
import { setMonitorPropsVideoChat, toggleShowMonitorVideoChat } from '@shared/VideoChat/actions';
import {keysValuesLabels, translateMonitorTypes} from './../../helpers/keysValuesLabels';
import {useTranslation} from "react-i18next";

export const SelfVideo = () => {
  const {t} = useTranslation()
  const { stateContext } = useVideoCallContext();
  const [avatar, setAvatar] = useState('');
  const { sessionInfo, monitorProps, openDropdown } = useSelector((state) =>
    state.getIn(['videoChat']).toJS()
  );
  const dispatch = useDispatch();

  const selectOptions = keysValuesLabels(VIDEO_CHAT_MONITOR_TYPES, translateMonitorTypes());
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const selected = useMemo(() => selectOptions.find(({ value }) => value === monitorProps?.monitor?.type), [selectOptions]);

  const onMonitorTypeSelection = (option) => {
    const { value } = option;
    let selectedMonitorProps = {}
    switch (value) {
      case VIDEO_CHAT_MONITOR_TYPES.BREATHING: {
        selectedMonitorProps = monitorTypes.breathing;
        break;
      }
      case VIDEO_CHAT_MONITOR_TYPES.WAVES: {
        selectedMonitorProps = monitorTypes.waves;
        break;
      }
      case VIDEO_CHAT_MONITOR_TYPES.GENTLE_VOICE: {
        selectedMonitorProps = monitorTypes.gentleVoice;
        break;
      }
      case VIDEO_CHAT_MONITOR_TYPES.LOUD_VOICE: {
        selectedMonitorProps = monitorTypes.loudVoice;
        break;
      }
      case VIDEO_CHAT_MONITOR_TYPES.VOICE_TRANSITION: {
        selectedMonitorProps = monitorTypes.voiceTransition;
        break;
      }
      case VIDEO_CHAT_MONITOR_TYPES.TWO_SECONDS: {
        selectedMonitorProps = monitorTypes.twoSeconds;
        break;
      }
      case VIDEO_CHAT_MONITOR_TYPES.ONE_SECOND: {
        selectedMonitorProps = monitorTypes.oneSecond;
        break;
      }
      case VIDEO_CHAT_MONITOR_TYPES.SELF_MANAGED: {
        selectedMonitorProps = monitorTypes.selfManaged;
        break;
      }
      default: {
        break;
      }
    }

    if (monitorProps?.pattern?.type !== selectedMonitorProps.pattern.type) {
      dispatch(setMonitorPropsVideoChat(selectedMonitorProps))
      if (monitorProps?.monitor) {
        dispatch(toggleShowMonitorVideoChat(false, false));
      }
    }
  }

  useEffect(() => {
    setAvatar(getSelfAvatar(sessionInfo));
  }, []);
  if (stateContext.chatEnded) {
    return (
      <VideoChatEndedPlaceholder>
        <Text size={TEXT_SIZE.H1} textColor={TEXT_COLOR.REVERSED}>
          {t('Informative.Notifying.videoChatEnded')}
        </Text>
      </VideoChatEndedPlaceholder>
    );
  }



  return (
    <>
      <SelfVideoWrapper>
        <VideoCall.Publisher />
        <SelfUserInfo>
          <SelfAvatar src={avatar} />
          <Text textColor={TEXT_COLOR.REVERSED} size={TEXT_SIZE.T2}>
            {t('Common.UI.you')}
          </Text>
        </SelfUserInfo>
        <UserControls>
          <VideoCall.VideoChatMonitor />
          {openDropdown && <SelectWrapperMonitorDropdown>
            <Select
              className='patternTypeSelect'
              value={selected}
              options={selectOptions}
              onChange={onMonitorTypeSelection}
              width={170}
            />
          </SelectWrapperMonitorDropdown>}
          <VideoCall.VideoBtn />
          <VideoCall.AudioBtn />
          <VideoCall.ScreenSharingBtn />
        </UserControls>
      </SelfVideoWrapper>
    </>
  );
};
