import {styled} from "@mui/material";

export const AvatarWrapper = styled('div')({
  display: 'flex',
  alignItems: 'center',
  margin: '0 25px 20px 0',
});

export const Avatar = styled('div')({
  height: 30,
  width: 30,
  borderRadius: '50%',
  objectFit: 'cover',
  filter: "drop-shadow(0px 2px 4px rgba(0, 0, 0, 0.5))",
  border: "2px solid #D26F7B",
  display: 'block',
}, ({ isCurrentUser }) => ({
  filter: isCurrentUser && "drop-shadow(0px 2px 4px rgba(0, 0, 0, 0.5))",
  border: isCurrentUser && "2px solid #D26F7B",
}));

export const EmptyAvatar = styled('div')({
  minWidth: 30,
  minHeight: 30,
  borderRadius: '50%',
  marginRight: 10,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  fontSize: 14,
}, ({ theme, isCurrentUser }) => ({
  backgroundColor: theme.palette.novotalk.$textColorDisabled,
  color: theme.palette.novotalk.$textColorReversed,
  filter: isCurrentUser && "drop-shadow(0px 2px 4px rgba(0, 0, 0, 0.5))",
  border: isCurrentUser && "2px solid #D26F7B",
}));
