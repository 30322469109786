const users = [
  {
    "fullName": "mentor one",
    "avatar": "https://s3-eu-west-1.amazonaws.com/nvtk-user-avatar/noavatar.jpg",
    "id": "5c52a1ccadf8f5001075f7bb",
    "__typename": "User"
  },
  {
    "fullName": "user two",
    "avatar": "https://s3-eu-west-1.amazonaws.com/nvtk-user-avatar/noavatar.jpg",
    "id": "5c52a184adf8f5001075f7b2",
    "__typename": "User"
  },
  {
    "fullName": "user one",
    "avatar": "https://s3-eu-west-1.amazonaws.com/nvtk-user-avatar/noavatar.jpg",
    "id": "5c52a144adf8f5001075f7a9",
    "__typename": "User"
  }
];

export const pageData = {
  "activityHistory":[
    {
      "id": "5c688c49b39ccf001019b7e6",
      "visits":[],
      "feedbackStatus": {
        type:"DISMISSED",
        payload: {
          text:"Some reason1",
          isDismiss:true
        }
      },
      "skill": {
        "name":"SKILL NAME"
      },
      "entity": {
        "type":"PRACTICE",
        "name":"SOME PRACTICE",
      },
      "type": "ACTIVITY_PRACTICE",
      "summary": {
        "hasPassed": true
      },
      "activityCreated": {
        "at": "Sat Feb 16 2019 22:18:49 GMT+0000 (UTC)"
      },
      "posts": [],
      "user": {
        "fullName": "user two",
        "avatar": "https://s3-eu-west-1.amazonaws.com/nvtk-user-avatar/noavatar.jpg"
      }
    },
    {
      "id": "5c688c57b39ccf001019b7e8",
      "visits":[],
      "feedbackStatus": {
        type:"DISMISSED",
        payload: {
          isDismiss:true,
          text:"Some reason2"
        }
      },
      "skill": {
        "name":"SKILL NAME"
      },
      "entity": {
        "type":"PRACTICE",
        "name":"SOME PRACTICE",
      },
      "type": "ACTIVITY_PRACTICE",
      "summary": {
        "hasPassed": true
      },
      "activityCreated": {
        "at": "Sat Feb 16 2019 22:19:03 GMT+0000 (UTC)"
      },
      "posts": [],
      "user": {
        "fullName": "user one",
        "avatar": "https://s3-eu-west-1.amazonaws.com/nvtk-user-avatar/noavatar.jpg"
      }
    },
    {
      "id": "5c6ac39fb39ccf001019d789",
      "feedbackStatus": {
        type:"DISMISSED",
        payload: {
          isDismiss:true,
          text:"Some reason1"
        }
      },
      "visits":[],
      "skill": {
        "name":"SKILL NAME"
      },
      "entity": {
        "type":"PRACTICE",
        "name":"SOME PRACTICE",
      },
      "type": "ACTIVITY_PRACTICE",
      "summary": {
        "hasPassed": true
      },
      "activityCreated": {
        "at": "Mon Feb 18 2019 14:39:27 GMT+0000 (UTC)"
      },
      "posts": [],
      "user": {
        "fullName": "user one",
        "avatar": "https://s3-eu-west-1.amazonaws.com/nvtk-user-avatar/noavatar.jpg"
      }
    },
  ],
  "activities": [
    {
      "id": "5c688c49b39ccf001019b7e6",
      "visits":[],
      "skill": {
        "name":"SKILL NAME"
      },
      "entity": {
        "type":"PRACTICE",
        "name":"SOME PRACTICE",
      },
      "type": "ACTIVITY_PRACTICE",
      "summary": {
        "hasPassed": true
      },
      "activityCreated": {
        "at": "Sat Feb 16 2019 22:18:49 GMT+0000 (UTC)"
      },
      "posts": [],
      "user": {
        "fullName": "user one",
        "avatar": "https://s3-eu-west-1.amazonaws.com/nvtk-user-avatar/noavatar.jpg"
      }
    },
    {
      "id": "5c688c57b39ccf001019b7e8",
      "visits":[],
      "skill": {
        "name":"SKILL NAME"
      },
      "entity": {
        "type":"PRACTICE",
        "name":"SOME PRACTICE",
      },
      "type": "ACTIVITY_PRACTICE",
      "summary": {
        "hasPassed": true
      },
      "activityCreated": {
        "at": "Sat Feb 16 2019 22:19:03 GMT+0000 (UTC)"
      },
      "posts": [],
      "user": {
        "fullName": "user one",
        "avatar": "https://s3-eu-west-1.amazonaws.com/nvtk-user-avatar/noavatar.jpg"
      }
    },
    {
      "id": "5c6ac39fb39ccf001019d789",
      "visits":[],
      "skill": {
        "name":"SKILL NAME"
      },
      "entity": {
        "type":"PRACTICE",
        "name":"SOME PRACTICE",
      },
      "type": "ACTIVITY_PRACTICE",
      "summary": {
        "hasPassed": true
      },
      "activityCreated": {
        "at": "Mon Feb 18 2019 14:39:27 GMT+0000 (UTC)"
      },
      "posts": [],
      "user": {
        "fullName": "user one",
        "avatar": "https://s3-eu-west-1.amazonaws.com/nvtk-user-avatar/noavatar.jpg"
      }
    },
    {
      "id": "5c6c24df682ff00011c61b53",
      "visits":[],
      "skill": {
        "name":"SKILL NAME"
      },
      "entity": {
        "type":"PRACTICE",
        "name":"SOME PRACTICE",
      },
      "type": "ACTIVITY_PRACTICE",
      "summary": {
        "hasPassed": true
      },
      "activityCreated": {
        "at": "Tue Feb 19 2019 15:46:39 GMT+0000 (UTC)"
      },
      "posts": [],
      "user": {
        "fullName": "user one",
        "avatar": "https://s3-eu-west-1.amazonaws.com/nvtk-user-avatar/noavatar.jpg"
      }
    },
    {
      "id": "5c659216b39ccf001019b6d4",
      "visits":[],
      "skill": {
        "name":"SKILL NAME"
      },
      "entity": {
        "type":"PRACTICE",
        "name":"SOME PRACTICE",
      },
      "type": "ACTIVITY_PRACTICE",
      "summary": {
        "hasPassed": true
      },
      "activityCreated": {
        "at": "Thu Feb 14 2019 16:06:46 GMT+0000 (UTC)"
      },
      "posts": [
        {
          "text": "the greatest feedback!",
          "createdAt": "Wed Feb 20 2019 11:50:54 GMT+0000 (UTC)"
        },
        {
          "text": "the greatest feedback!",
          "createdAt": "Wed Feb 20 2019 11:51:46 GMT+0000 (UTC)"
        },
        {
          "text": "the greatest feedback!",
          "createdAt": "Wed Feb 20 2019 11:53:53 GMT+0000 (UTC)"
        },
        {
          "text": "the greatest feedback!",
          "createdAt": "Wed Feb 20 2019 11:54:33 GMT+0000 (UTC)"
        },
        {
          "text": "the greatest feedback!",
          "createdAt": "Wed Feb 20 2019 11:54:45 GMT+0000 (UTC)"
        }
      ],
      "user": {
        "fullName": "user two",
        "avatar": "https://s3-eu-west-1.amazonaws.com/nvtk-user-avatar/noavatar.jpg"
      }
    },
    {
      "id": "5c6e8d8ce86b400016b2f4b9",
      "visits":[],
      "skill": {
        "name":"SKILL NAME"
      },
      "entity": {
        "type":"PRACTICE",
        "name":"SOME PRACTICE",
      },
      "type": "ACTIVITY_PRACTICE",
      "summary": {
        "hasPassed": true
      },
      "activityCreated": {
        "at": "Thu Feb 21 2019 11:37:48 GMT+0000 (UTC)"
      },
      "posts": [],
      "user": {
        "fullName": "user one",
        "avatar": "https://s3-eu-west-1.amazonaws.com/nvtk-user-avatar/noavatar.jpg"
      }
    },
    {
      "id": "5c729e713608ff0012c04136",
      "visits":[],
      "skill": {
        "name":"SKILL NAME"
      },
      "entity": {
        "type":"PRACTICE",
        "name":"SOME PRACTICE",
      },
      "type": "ACTIVITY_PRACTICE",
      "summary": {
        "hasPassed": true
      },
      "activityCreated": {
        "at": "Sun Feb 24 2019 13:38:57 GMT+0000 (UTC)"
      },
      "posts": [
        {
          "text": "Feedback to user one by user one",
          "createdAt": "Tue Feb 26 2019 15:19:36 GMT+0000 (UTC)"
        },
        {
          "text": "Feedback 2 to user one by user one",
          "createdAt": "Tue Feb 26 2019 16:19:36 GMT+0000 (UTC)"
        }
      ],
      "user": {
        "fullName": "user one",
        "avatar": "https://s3-eu-west-1.amazonaws.com/nvtk-user-avatar/noavatar.jpg"
      }
    },
    {
      "id": "5c7417433608ff0012c05505",
      "visits":[],
      "skill": {
        "name":"SKILL NAME"
      },
      "entity": {
        "type":"PRACTICE",
        "name":"SOME PRACTICE",
      },
      "type": "ACTIVITY_PRACTICE",
      "summary": {
        "hasPassed": true
      },
      "activityCreated": {
        "at": "Mon Feb 25 2019 16:26:43 GMT+0000 (UTC)"
      },
      "posts": [],
      "user": {
        "fullName": "user one",
        "avatar": "https://s3-eu-west-1.amazonaws.com/nvtk-user-avatar/noavatar.jpg"
      }
    },
    {
      "id": "5c767a59ca2e53001693a32e",
      "visits":[],
      "skill": {
        "name":"SKILL NAME"
      },
      "entity": {
        "type":"PRACTICE",
        "name":"SOME PRACTICE",
      },
      "type": "ACTIVITY_PRACTICE",
      "summary": {
        "hasPassed": true
      },
      "activityCreated": {
        "at": "Wed Feb 27 2019 11:54:01 GMT+0000 (UTC)"
      },
      "posts": [],
      "user": {
        "fullName": "user one",
        "avatar": "https://s3-eu-west-1.amazonaws.com/nvtk-user-avatar/noavatar.jpg"
      }
    },
    {
      "id": "5c7ae5bcca2e53001693bf27",
      "visits":[],
      "skill": {
        "name":"SKILL NAME"
      },
      "entity": {
        "type":"PRACTICE",
        "name":"SOME PRACTICE",
      },
      "type": "ACTIVITY_PRACTICE",
      "summary": {
        "hasPassed": true
      },
      "activityCreated": {
        "at": "Sat Mar 02 2019 20:21:16 GMT+0000 (UTC)"
      },
      "posts": [],
      "user": {
        "fullName": "user one",
        "avatar": "https://s3-eu-west-1.amazonaws.com/nvtk-user-avatar/noavatar.jpg"
      }
    },
    {
      "id": "5c7b8b16ca2e53001693c3d1",
      "visits":[],
      "skill": {
        "name":"SKILL NAME"
      },
      "entity": {
        "type":"PRACTICE",
        "name":"SOME PRACTICE",
      },
      "type": "ACTIVITY_PRACTICE",
      "summary": {
        "hasPassed": true
      },
      "activityCreated": {
        "at": "Sun Mar 03 2019 08:06:46 GMT+0000 (UTC)"
      },
      "posts": [],
      "user": {
        "fullName": "user one",
        "avatar": "https://s3-eu-west-1.amazonaws.com/nvtk-user-avatar/noavatar.jpg"
      }
    }
  ],
  "activeUsers": users, 
  "posts": []
}