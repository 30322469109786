import React, { Component } from 'react';
import PropTypes from 'prop-types';
import injectReducer from '@utils/injectReducer';
import injectSaga from '@utils/injectSaga';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { connectToMirrorSession, disconnectFromMirrorSession } from './actions';
import reducer from './reducer';
import saga from './saga';
import { HiddenVideoPlaceholder, VideoPlaceholder } from './styles';
import ChatService from '@services/chatService';
import { EmptyMirror } from './icons/empty-mirror';

export class VideoMonitor extends Component {
  componentDidMount() {
    if (!this.props.isLesson) {
      this.connectToMirror();
    }
  }

  componentDidUpdate(prevProps) {
    const { connectedToMirror, displayMirror, isLesson } = this.props;
    if (prevProps.connectedToMirror !== connectedToMirror
      && prevProps.displayMirror !== displayMirror
      && !isLesson
    ) {
      this.connectToMirror();
    }
  }

  componentWillUnmount() {
    const {
      connectedToMirror,
      dispatchDisconnectFromMirrorSession,
    } = this.props;
    if (connectedToMirror) {
      dispatchDisconnectFromMirrorSession(ChatService.archiveId);
    }
  }

  connectToMirror() {
    const {
      connectedToMirror,
      displayMirror,
      dispatchConnectToMirrorSession,
    } = this.props;

    if (!connectedToMirror && displayMirror) {
      dispatchConnectToMirrorSession();
    }
  }

  render() {
    const { error, displayMirror } = this.props;
    if (error) return <EmptyMirror />;
    if (!displayMirror) return <HiddenVideoPlaceholder id='publisher' />;
    return <VideoPlaceholder id='publisher' />;
  }
}

VideoMonitor.propTypes = {
  error: PropTypes.string,
  connectedToMirror: PropTypes.bool,
  displayMirror: PropTypes.bool,
  dispatchConnectToMirrorSession: PropTypes.func,
  dispatchDisconnectFromMirrorSession: PropTypes.func,
};

export const mapStateToProps = (state) => {
  const { error, connectedToMirror } = state.getIn(['videoMirror'])
    ? state.getIn(['videoMirror']).toJS()
    : {};
  const { activityContext } = state.getIn(['course']).toJS();
  return { error, connectedToMirror, activityContext };
};

export function mapDispatchToProps(dispatch) {
  return {
    dispatchConnectToMirrorSession: () => dispatch(connectToMirrorSession()),
    dispatchDisconnectFromMirrorSession: (archiveId) =>
      dispatch(disconnectFromMirrorSession(archiveId)),
  };
}

const withConnect = connect(mapStateToProps, mapDispatchToProps);
const withReducer = injectReducer({ key: 'videoMirror', reducer });
const withSaga = injectSaga({ key: 'videoMirror', saga });

export default compose(
  withConnect,
  withReducer,
  withSaga
)(VideoMonitor);
