import {styled} from "@mui/material";
import { getIconByResourceType } from '@services/icons';
import SharedInput from '@components/Input';

export const QuestionContainer = styled('div')({
  display: 'grid',
  gridTemplateAreas: `
    'Title .'
    'Question TypeSelect'
    'SubTitle .'
    'Answers Description'
  `,
  gridTemplateColumns: 'minmax(auto, 580px) 325px',
  gridColumnGap: 20,
});

export const Title = styled('div')({
  gridArea: 'Title',
  marginBottom: 8,
});

export const QuestionInput = styled(SharedInput)({
  gridArea: 'Question',
  height: 44,
});

export const SelectWrapper = styled('div')({
  gridArea: 'TypeSelect',
});

export const OptionsWrapper = styled('div')({
  display: 'flex',
  alignItems: 'center',
});

export const getStyledOptionIcon = (type) =>
  styled(getIconByResourceType(type))(
    {
      marginRight: 10,
      fontSize: 26,
    },
    ({ theme }) => ({
      color: theme.palette.novotalk.$textColorLightGray,
    })
  );

export const InfoText = styled('div')({
  gridArea: 'Description',
  width: 240,
});
