import React, {useEffect} from 'react';
import {useDispatch} from "react-redux";
import {fetchUserPersonalProgram} from "@containers/Admin/containers/UserPage/actions";
import {ResourceTitle, TitleUserPersonalProgram, UserProgramLayout} from "./styles";
import {useParams} from "react-router-dom";
import {useTranslation} from "react-i18next";
import {SkillsList} from "./components/SkillsList/index";
import {useCustomSelector} from "src/hooks/useCustomSelector";

export const UserPersonalProgram = () => {
    const {overview: {resource}} = useCustomSelector('resourceManager')
    const {t} = useTranslation()
    const {id} = useParams<{ id: string }>();
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(fetchUserPersonalProgram(id));
    }, [])

    return (
        <>
            <TitleUserPersonalProgram>
                {t('UI.personalProgram')}
            </TitleUserPersonalProgram>
            <UserProgramLayout>
                {resource?.name && <ResourceTitle>{resource?.name}</ResourceTitle>}
                <SkillsList />
            </UserProgramLayout>
        </>
    );
};