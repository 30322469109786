import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import _ from 'lodash';
import Text from '@components/Text';
import { Wrapper, SkillsWrapper } from './styles';
import CourseSkill from './containers/CourseSkill';
import Sidebar from '../../components/Sidebar';
import { USE_PAID_SYSTEM_VERSION } from '~/shared/constants';
import { isMvpVersion } from '@services/isMvpService';
import {withTheme} from "@mui/styles";
import {withTranslation} from "react-i18next";

class CourseMainPane extends Component {
  constructor(props) {
    super(props);
    this.state = {};
    this.wrapperRef = React.createRef();
  }

  componentDidMount() {
    const wrapper = this.wrapperRef.current;
    const courseProgress = document.getElementById('courseProgress');
    wrapper.addEventListener('scroll', () => {
      if (wrapper.scrollTop > 0) {
        const { theme } = this.props;
        courseProgress.style.boxShadow = `0 15px 20px -10px ${theme.palette.novotalk.$borderColorLight}`;
      } else {
        courseProgress.style.boxShadow = 'none';
      }
    });
  }

  render() {
    const { Skills, locationInCourse, resumeCourse, courseIndex, isTrial } = this.props;
    const { skillName, skillpartName, exerciseIndex } = locationInCourse;
    const withSidebar =
      skillName &&
      skillName.length !== 0 &&
      skillpartName &&
      skillpartName.length !== 0;
    if (!Skills || Skills.length === 0) {
      return (
        <Wrapper ref={this.wrapperRef}>
          <Text size="H2">{this.props.t('Informative.Negative.noCourseDefined')}</Text>
        </Wrapper>
      );
    }
    const firstPaidId = _.find(Skills, (skill) => !skill.free).id;

    return (
      <Wrapper
        ref={this.wrapperRef}
        withSidebar={withSidebar}
        isTrial={isTrial}
      >
        <SkillsWrapper>
          {Skills.map((skill, index) => (
            <CourseSkill
              key={skill.id}
              skill={skill}
              {...skill}
              skillIndex={index}
              exerciseIndex={exerciseIndex}
              courseIndex={courseIndex}
              hasUpgradeMessage={
                USE_PAID_SYSTEM_VERSION && isTrial && skill.id === firstPaidId
              }
            />
          ))}
        </SkillsWrapper>
        {!isMvpVersion && withSidebar && (
          <Sidebar
            locationInCourse={locationInCourse}
            resumeCourse={resumeCourse}
          />
        )}
      </Wrapper>
    );
  }
}

CourseMainPane.propTypes = {
  Skills: PropTypes.array,
  locationInCourse: PropTypes.object,
  theme: PropTypes.object,
  resumeCourse: PropTypes.func,
  courseIndex: PropTypes.number,
  isTrial: PropTypes.bool,
};

export default compose(withTranslation(), withTheme)(CourseMainPane);
