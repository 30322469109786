export const Types = {
  SEND_PASSWORD_LINK: 'SEND_PASSWORD_LINK',
  SEND_PASSWORD_LINK_SUCCESS: 'SEND_PASSWORD_LINK_SUCCESS',
  SEND_PASSWORD_LINK_FAIL: 'SEND_PASSWORD_LINK_FAIL',
};

export const onSendPasswordLink = (email) => ({
  type: Types.SEND_PASSWORD_LINK,
  payload: { email },
});

export const onSendPasswordLinkSuccess = () => ({
  type: Types.SEND_PASSWORD_LINK_SUCCESS,
});

export const onSendPasswordLinkFail = (error) => ({
  type: Types.SEND_PASSWORD_LINK_FAIL,
  payload: { error },
});

