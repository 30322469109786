import React from 'react';
import Text, { TEXT_COLOR, TEXT_SIZE } from '@components/Text';
import PropTypes from 'prop-types';
import {
  Avatar,
  ClinicianIndicator,
  UserInfo,
  StyledAvatarIcon,
  StyledSubscriberSoundOffIcon,
  SubscriberVideoOffIconWrapper,
  StyledSubscriberVideoOffIcon,
} from '../../styles';
import VideoCall from '../VideoCall';
import {useTranslation} from "react-i18next";

const SubscriberVideo = ({
  user,
  numOfParticipants,
  userCount,
  size,
  maxHeight,
  paddingBottom
}) => {
  const {t} = useTranslation()
  const { name, avatar, initiator, hasVideo, hasAudio, leftSession } = user;
  const clinicianIndicator = initiator ? (
    <ClinicianIndicator numOfParticipants={numOfParticipants}>
      <StyledAvatarIcon />
      <Text textColor={TEXT_COLOR.REVERSED} size={TEXT_SIZE.H4}>
        {t('Common.UI.clinician')}
      </Text>
    </ClinicianIndicator>
  ) : null;
  return (
    <VideoCall.Subscriber
      key={name}
      numOfParticipants={numOfParticipants}
      hidden={leftSession}
      size={size}
      maxHeight={maxHeight}
      paddingBottom={paddingBottom}
      userCount={userCount}
      participantId={user.participantId}
    >
      {!hasAudio && <StyledSubscriberSoundOffIcon />}
      {!hasVideo && (
        <SubscriberVideoOffIconWrapper>
          <StyledSubscriberVideoOffIcon />
        </SubscriberVideoOffIconWrapper>
      )}
      <UserInfo>
        <Avatar src={avatar} />
        <Text textColor={TEXT_COLOR.REVERSED} size={TEXT_SIZE.H4}>
          {name}
        </Text>
      </UserInfo>
      {clinicianIndicator}
    </VideoCall.Subscriber>
  );
};

SubscriberVideo.propTypes = {
  numOfParticipants: PropTypes.number,
  name: PropTypes.string,
  avatar: PropTypes.string,
  initiator: PropTypes.bool,
  id: PropTypes.string,
  hasVideo: PropTypes.bool,
  hasAudio: PropTypes.bool,
};

export default SubscriberVideo;
