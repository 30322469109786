import {styled} from "@mui/material";
import CTAButton from '@components/CTAButton';
import TextAreaBase from '@components/TextArea';
export const TextArea = styled(TextAreaBase)(
  {
    width: '100%',
    height: 220,
  },
  ({ error, theme }) =>
    error && {
      borderColor: theme.palette.novotalk.errorColor,
    }
);
export const Wrapper = styled('div')(
  {
    display: 'flex',
    width: 570,
    height: 556,
    flexDirection: 'column',
    alignItems: 'center',
    padding: '45px 50px',
    borderRadius: 5,
  },
  ({ theme }) => ({
    backgroundColor: theme.palette.novotalk.textColorReversed,
  })
);

export const Body = styled('div')({
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  textAlign: 'center',
  marginBottom: 27,
  height: 270,
});
export const Title = styled('div')({
  marginBottom: 15,
});
export const SubTitle = styled('div')({
  marginBottom: 38,
});

export const Buttons = styled('div')({
  width: '100%',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  textAlign: 'center',
});

export const CancelButton = styled(CTAButton)(
  {
    height: 54,
    width: 221,
    borderRadius: 0,
    padding: '0 10px',
    margin: '0 15px',
  },
  ({ theme }) => ({
    backgroundColor: theme.palette.novotalk.textColorReversed,
    color: theme.palette.novotalk.textColorHighlited,
    border: `solid 1px ${theme.palette.novotalk.textColorHighlited}`,
    '&:hover': {
      backgroundColor: theme.palette.novotalk.textColorReversed,
    },
  })
);
export const ConfirmButton = styled(CancelButton)({}, ({ theme }) => ({
  backgroundColor: theme.palette.novotalk.textColorHighlited,
  color: theme.palette.novotalk.textColorReversed,
  border: `solid 1px ${theme.palette.novotalk.textColorReversed}`,
  '&:hover': {
    backgroundColor: theme.palette.novotalk.textColorHighlited,
  },
}));
