import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import _ from 'lodash';
import { withTranslation } from 'react-i18next';
import Text, { TEXT_COLOR, TEXT_SIZE } from '@components/Text';
import SectionLoading from '@components/SectionLoading';
import { getExerciseTips } from '@containers/User/helpers/monitorErrors';
import { markLessonAsCompleted } from '@containers/User/containers/CourseRouter/actions';
import { LoaderSmallCentralized } from '@components/Loader';

import {
  Wrapper,
  HeaderContainer,
  TipsBlockContainer,
  TipsListContainer,
  TipContainer,
  ButtonsContainer,
  getStyledIcon,
  IconWrapper,
  RecommendationText,
  StyledCTAButton,
  LoaderWrapper,
} from './styles';

export class LessonEnd extends Component {
  constructor() {
    super();
    this.eventListener = null;
    this.state = {
      showLoading: true,
    };
  }

  componentDidMount() {
    this.eventListener = window.addEventListener('message', (e) => {
      if (e.data === 'archiveWasStopped' && this.state.showLoading) {
        this.setState({ showLoading: false });
      }
    });
  }

  componentWillUnmount() {
    if (this.eventListener) {
      window.removeEventListener(this.eventListener);
    }
  }

  getTipsBlock = () => {
    const { isLastLesson, currentLessonActivity, t } = this.props;
    const insights = _.get(currentLessonActivity, 'summary.insights', []);
    const tipsList = getExerciseTips(insights);

    if (tipsList.length === 0) return null;
    return (
      <TipsBlockContainer isLastLesson={isLastLesson}>
        <Text size={TEXT_SIZE.T3} textColor={TEXT_COLOR.DARK_BLUE}>
          {t('Informative.Statistical.performanceTips')}
        </Text>
        <TipsListContainer>
          {tipsList
            .slice(0, 3)
            .map(({
              errorId, iconName, text, errorName,
            }) => {
              const TipIcon = getStyledIcon(iconName);
              return (
                <TipContainer key={errorId}>
                  <IconWrapper>
                    <TipIcon />
                  </IconWrapper>
                  <Text
                    size={TEXT_SIZE.T3}
                    textColor={TEXT_COLOR.DARK_BLUE}
                    weight="bold"
                    margin="0 0 6px"
                  >
                    {errorName}
                  </Text>
                  <Text size={TEXT_SIZE.T4} textColor={TEXT_COLOR.DARK_BLUE}>
                    {text}
                  </Text>
                </TipContainer>
              );
            })}
        </TipsListContainer>
      </TipsBlockContainer>
    );
  };

  getButtons = () => {
    const {
      isLastLesson, currentLesson, dispatchMarkExerciseAsCompleted, t,
    } = this.props;
    const { localLoading } = this.state;
    dispatchMarkExerciseAsCompleted(currentLesson?.lesson);
    if (localLoading) {
      return <LoaderSmallCentralized />;
    }
    return (
      <ButtonsContainer>
        <StyledCTAButton
          onClick={this.handleClick('secondary')}
          className="popup-button-single"
          secondary
        >
          <Text size={TEXT_SIZE.T3}>
            {isLastLesson ? t('Actions.Confirmations.practice') : t('Actions.Navigation.backToOverview')}
          </Text>
        </StyledCTAButton>
        <StyledCTAButton
          onClick={this.handleClick('primary')}
          className="popup-button-single"
        >
          <Text size={TEXT_SIZE.T3}>
            {isLastLesson ? t('Actions.Confirmations.wait') : t('Actions.Navigation.nextLesson')}
          </Text>
        </StyledCTAButton>
      </ButtonsContainer>
    );
  };

  handleClick = (button) => () => {
    const {
      onClose,
      redirectToOverview,
      goToNextLesson,
      goToPractice,
      isLastLesson,
      resetExerciseData,
    } = this.props;

    onClose();
    resetExerciseData();
    if (isLastLesson && button === 'secondary') {
      goToPractice();
    } else if (!isLastLesson && button === 'primary') {
      goToNextLesson();
    } else {
      redirectToOverview();
    }
  };

  render() {
    const { isLastLesson, t } = this.props;
    const { showLoading } = this.state;

    if (showLoading) {
      return (
        <LoaderWrapper>
          <SectionLoading />
        </LoaderWrapper>
      );
    }

    return (
      <Wrapper>
        <HeaderContainer>
          <Text
            size={TEXT_SIZE.T1}
            textColor={TEXT_COLOR.DARK_BLUE}
            weight="bold"
            className="popup-title"
          >
            {t('Feedback.great')}
          </Text>
        </HeaderContainer>
        {this.getTipsBlock()}
        {isLastLesson && (
        <RecommendationText>
          <Text size={TEXT_SIZE.T2} textColor={TEXT_COLOR.DARK_BLUE}>
            {t('Informative.Positive.completed')}
          </Text>
          <Text
            size={TEXT_SIZE.T2}
            textColor={TEXT_COLOR.DARK_BLUE}
            weight="bold"
          >
            {t('Informative.Recommending.recommendation')}
          </Text>
        </RecommendationText>
        )}
        {this.getButtons()}
      </Wrapper>
    );
  }
}

LessonEnd.propTypes = {
  onClose: PropTypes.func.isRequired,
  currentLessonActivity: PropTypes.object,
  redirectToOverview: PropTypes.func,
  goToNextLesson: PropTypes.func,
  goToPractice: PropTypes.func,
  isLastLesson: PropTypes.bool,
  resetExerciseData: PropTypes.func,
};

export const mapStateToProps = (state) => {
  const currentLesson = state.getIn(['currentLesson']).toJS();
  const { currentLessonActivity } = currentLesson;
  return { currentLessonActivity, currentLesson };
};
export function mapDispatchToProps(dispatch) {
  return {
    dispatchMarkExerciseAsCompleted: (lesson) => dispatch(markLessonAsCompleted(lesson)),
  };
}

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default compose(withConnect, withTranslation())(LessonEnd);
