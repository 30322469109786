import React from 'react';
import Text from '@components/Text';
import { WithMargin } from '@containers/App/containers/PopUp/popups/MediaEditor/styles';
import SectionLoading from '@components/SectionLoading';
import {useTranslation} from "react-i18next";

export const Pending = () => {
    const {t} = useTranslation()
    return (
        <span>
    <Text margin='0 0 8px 0'>
      {t('Informative.Statuses.savingFile')}
    </Text>
    <WithMargin marginTop={20}>
      <SectionLoading size={50} />
    </WithMargin>
  </span>
    );
}
