
export const selectStyles = ({
  indicatorSeparator: () => {
    'none';
  },
  control: (provided) => ({
    ...provided,
    minWidth: '200px',
    boxShadow: 'none',
    borderColor: '#979797',
  }),
});

