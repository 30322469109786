import {styled} from "@mui/material";

export const Wrapper = styled('div')(
  {
    display: 'grid',
    gridTemplateColumns: '396px auto',
    gridTemplateRows: '58px auto 110px',
    gridTemplateAreas: `
    "listHeader conversationHeader"
    "list conversation"
    "list message"
  `,
  },
  ({ theme }) => ({
    height: `calc(100vh - ${theme.palette.novotalk.navigationBarHeight}px)`,
  })
);
