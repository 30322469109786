import {styled} from "@mui/material";

export const NoMonitorTypeWrapper = styled('div')(
  {
    display: 'flex',
    alignItems: 'center',
    borderRadius: 4,
    lineHeight: '13px',
  },
  ({ theme, miniOverview }) => ({
    padding: miniOverview ? 0 : 10,
    backgroundColor: theme.palette.novotalk.$monitorTypesImgBackgroundColor,
    border: `1px solid ${theme.palette.novotalk.$monitorTypesImgBorderColor}`,
  })
);
