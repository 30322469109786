import React from 'react';
import { VideoChatEndedPlaceholder } from '../../styles';
import Text, { TEXT_SIZE, TEXT_COLOR } from '@components/Text';
import {useTranslation} from "react-i18next";

export const ChatEnded = () => {
    const {t} = useTranslation()
  return (
    <VideoChatEndedPlaceholder>
      <Text size={TEXT_SIZE.H1} textColor={TEXT_COLOR.REVERSED}>
        {t('Video.videoChatEnded')}
      </Text>
    </VideoChatEndedPlaceholder>
  );
};
