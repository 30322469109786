import {styled} from "@mui/material";
import { getIconByResourceType } from '@services/icons';
import { getResponsiveCSS } from '@utils/cssHelpers';
import { PRACTICE } from '../../types';

export const NameContainer = styled('div')(
  getResponsiveCSS('marginBottom', 18, 24)
);

export const Title = styled('div')({
  display: 'flex',
  alignItems: 'center',
  marginBottom: 5,
});

export const StyledExerciseIcon = styled(getIconByResourceType(PRACTICE))(
  {
    fontSize: 16,
    marginRight: 5,
    marginTop: -2,
    marginLeft: -2,
  },
  ({ theme }) => ({
    color: theme.palette.novotalk.$textColorDisabled,
  })
);
