import React, { Component } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { withRouter } from 'react-router-dom';
import { withTheme } from '@mui/styles';
import Select from 'react-select';
import injectReducer from '@utils/injectReducer';
import injectSaga from '@utils/injectSaga';
import CTAButton from '@components/CTAButton';
import TextButton from '@components/TextButton';
import Text, { TEXT_COLOR, TEXT_SIZE } from '@components/Text';
import CustomTextInput from '@components/CustomTextInput/index';
import DropdownDatePicker from '@components/DropdownDatePicker';
import CustomTooltip, { placementTypes } from '@components/CustomTooltip/index';
import { ROUTES, USE_PAID_SYSTEM_VERSION } from '@shared/constants';
import { ENVIRONMENT } from '@services/servers';
import ClientVersion from '@components/ClientVersion';
import ServerVersion from '@components/ServerVersion';
import SectionLoading from '@components/SectionLoading';
import ErrorMessage from '@components/ErrorMessage';
import { LANGUAGE_NOVOTALK } from '../../../../../i18n/i18n';
import { PasswordHint } from '../PasswordHint';
import {
  formHasErrors,
  getErrorMessageId,
  fieldTypes,
  checkStrengthPassword,
} from '../validate';
import { TYPES } from '../../PopUp/popups/SignUpUnavailable';
import { openPopUp as openPopUpAction } from '../../PopUp/actions';
import POPUP_TYPES from '../../PopUp/types';
import { onSignUp } from './actions';

import {
  SignUpComponentContainer,
  DoubleInputContainer,
  DoubleInputItemContainer,
  InputContainer,
  SignUpButtonContainer,
  UnderButtonTextContainer,
  SignInTextContainer,
  ScreenContainer,
  TrialHeaderContainer,
  MainAreaContainer,
  ScreenHeaderTextContainer,
  ScreenHeaderLinkContainer,
  ListHeaderContainer,
  ListContainer,
  ListItemContainer,
  BirthdateContainer,
  DatePickerContainer,
  DateTooltipContainer,
  StyledCheckmarkIcon,
  PasswordsWrapper,
  StyledPasswordMessage,
  StyledLink,
  StyledLogo,
  ServerErrorMessage,
  LocationContainer,
  Label,
  StyledDropdownIcon,
  countrySelectStyles,
  VersionsTextContainer,
  languageSelectStyles,
} from './styles';
import LogoImage from './logo.svg';
import reducer from './reducer';
import saga from './saga';
import {withTranslation} from "react-i18next";
import {countries, states} from "@containers/App/containers/Authentication/SignUp/countries";

export class SignUp extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      visibility: {
        password: false,
        confirmPassword: false,
      },
      form: {
        values: {
          firstName: '',
          lastName: '',
          email: '',
          birthdate: null,
          password: '',
          confirmPassword: '',
          country: 'US',
          state: 'AL',
          language: null,
        },
        errors: {
          firstName: null,
          lastName: null,
          email: null,
          birthdate: null,
          password: null,
          confirmPassword: null,
          language: null,
        },
      },
    };
  }

  componentWillReceiveProps(newProps) {
    if (newProps.redirectToPage) {
      const { email } = this.state.form.values;
      this.props.history.push(newProps.redirectToPage, { email });
    }
  }

  onFieldUpdate = (event) => {
    const { name, value } = event.target;

    this.setState((prevState) => {
      const newForm = prevState.form;
      newForm.values[name] = value;

      return {
        form: newForm,
      };
    });
  };

  onDateUpdate = (date) => {
    // date is a null or a Date object

    this.setState((prevState) => {
      const newForm = prevState.form;
      newForm.values.birthdate = date ? new Date(date).toISOString() : null;
      if (date) {
        newForm.errors.birthdate = null;
      }

      return {
        form: newForm,
      };
    });
  };

  onFocus = (event) => {
    const { name } = event.target;

    this.setState((prevState) => {
      const newForm = prevState.form;
      newForm.errors[name] = null;

      return {
        form: newForm,
      };
    });
  };

  localeOnFocus = () => {
    this.setState((prevState) => {
      const newForm = prevState.form;
      newForm.errors.language = null;

      return {
        form: newForm,
      };
    });
  };

  onSignUpButtonClick = () => {
    const { onSignUpClick } = this.props;
    const {
      firstName,
      lastName,
      email,
      password,
      birthdate,
      country,
      language,
    } = this.state.form.values;
    const state = country === 'US' ? this.state.form.values.state : null;
    const userInfo = {
      firstName,
      lastName,
      email,
      password,
      birthdate,
      country,
      state,
      locale: language,
    };
    const formErrors = this.getFormErrors();

    const errors = checkStrengthPassword(password);
    const newForm = _.cloneDeep(this.state.form);
    let loading = !formHasErrors(formErrors);

    if (!formErrors.password && errors.length) {
      formErrors.password = this.props.t('Errors.hintError');
    }

    newForm.errors = formErrors;

    if (
      loading
      && this.availableCountries
      && !this.availableCountries.includes(country)
    ) {
      loading = false;
      this.signUpPopUpHelper({ ...userInfo, available: false }, TYPES.COUNTRY);
    } else if (
      loading
      && country === 'US'
      && this.availableStates
      && !this.availableStates.includes(state)
    ) {
      loading = false;
      this.signUpPopUpHelper({ ...userInfo, available: false }, TYPES.STATE);
    } else if (loading && errors.length) {
      loading = false;
    }

    this.setState({
      form: newForm,
      loading,
    });

    if (loading) {
      onSignUpClick({
        ...userInfo,
        available: true,
      });
    }
  };

  getFormErrors = () => {
    const { values } = this.state.form;
    const newErrors = {};
    _.forEach(values, (fieldValue, fieldName) => {
      let messageId = null;
      let confirmField;
      switch (fieldName) {
        case fieldTypes.password:
          confirmField = values.confirmPassword;
          break;
        case fieldTypes.confirmPassword:
          confirmField = values.password;
          break;
        default:
          confirmField = null;
          break;
      }

      messageId = getErrorMessageId(fieldName, fieldValue, confirmField);
      const fieldError = messageId
        ? this.props.t(`Errors.${messageId}`)
        : null;
      newErrors[fieldName] = fieldError;
    });

    return newErrors;
  };

  getPasswordHint = () => {
    const { password } = this.state.form.values;
    if (!password.length) return null;
    const errors = checkStrengthPassword(password);
    return <PasswordHint errors={errors} />;
  };

  signUpPopUpHelper = (userInfo, errorType) => {
    const { onSignUpClick, openPopUp } = this.props;

    onSignUpClick(userInfo);
    openPopUp(POPUP_TYPES.SIGN_UP_UNAVAILABLE, {
      type: errorType,
      hideCloseIcon: true,
    });
  };

  renderTrialHeader = () => (
    <TrialHeaderContainer>
      <StyledLogo src={LogoImage} alt="logo" />
      <ScreenHeaderTextContainer>
        <Text textColor={TEXT_COLOR.DARK_BLUE} size={TEXT_SIZE.H1}>
          {this.props.t(`Authentication.Distribution.${
              USE_PAID_SYSTEM_VERSION ? '7daysTrial' : 'experience'
          }`)}
        </Text>
      </ScreenHeaderTextContainer>
      <ScreenHeaderLinkContainer>
        {USE_PAID_SYSTEM_VERSION && (
          <>
            <Text textColor={TEXT_COLOR.DISABLED} size={TEXT_SIZE.H4}>
              {this.props.t('Common.UI.or')}
            </Text>
            <StyledLink id="purchase_link" to="/">
              <TextButton>
                <Text size={TEXT_SIZE.H4}>
                  {this.props.t('Authentication.Distribution.purchaseNow')}
                </Text>
              </TextButton>
            </StyledLink>
          </>
        )}
      </ScreenHeaderLinkContainer>
    </TrialHeaderContainer>
  );

  renderTrialList = () => {
    const items = _.range(1, 6).map((itemNumber) => (
      <ListItemContainer key={`message${itemNumber}`}>
        <StyledCheckmarkIcon />
        <Text textColor={TEXT_COLOR.DARK_BLUE} size={TEXT_SIZE.H4}>
          {this.props.t(`Authentication.Distribution.trialListItem${itemNumber}`)}
        </Text>
      </ListItemContainer>
    ));

    return (
      <ListContainer>
        <ListHeaderContainer>
          <Text textColor={TEXT_COLOR.NORMAL} size={TEXT_SIZE.T2} weight="900">
            {this.props.t('Authentication.Distribution.included')}
          </Text>
        </ListHeaderContainer>
        {items}
      </ListContainer>
    );
  };

  renderNameInputs = () => {
    const { values, errors } = this.state.form;

    return (
      <DoubleInputContainer>
        <DoubleInputItemContainer>
          <CustomTextInput
            id="signup_firstname"
            label={this.props.t('Common.UI.firstName')}
            name="firstName"
            value={values.firstName}
            error={errors.firstName}
            onChange={this.onFieldUpdate}
            onFocus={this.onFocus}
            type="text"
          />
        </DoubleInputItemContainer>
        <DoubleInputItemContainer>
          <CustomTextInput
            id="signup_lastname"
            label={this.props.t('Common.UI.lastName')}
            name="lastName"
            value={values.lastName}
            error={errors.lastName}
            onChange={this.onFieldUpdate}
            onFocus={this.onFocus}
            type="text"
          />
        </DoubleInputItemContainer>
      </DoubleInputContainer>
    );
  };

  renderEmail = () => {
    const { values, errors } = this.state.form;

    return (
      <InputContainer>
        <CustomTextInput
          id="signup_email"
          label={this.props.t('Authentication.Email.email')}
          name="email"
          value={values.email}
          error={errors.email}
          onChange={this.onFieldUpdate}
          onFocus={this.onFocus}
          type="email"
        />
      </InputContainer>
    );
  };

  renderPasswords = () => {
    const { values, errors } = this.state.form;
    const errorMessageText = errors.password;
    const hasError = errorMessageText ? '' : null;
    const showMatchTextError = values.confirmPassword.length > 0 && values.password !== values.confirmPassword;
    const commonProps = {
      type: 'password',
      error: hasError,
      onChange: this.onFieldUpdate,
      onFocus: this.onFocus,
    };

    return (
      <PasswordsWrapper>
        <DoubleInputContainer>
          <DoubleInputItemContainer>
            <CustomTextInput
              {...commonProps}
              id="password_input_signup"
              label={this.props.t('Authentication.Password.password')}
              name="password"
              value={values.password}
            />
          </DoubleInputItemContainer>
          <DoubleInputItemContainer>
            <CustomTextInput
              {...commonProps}
              id="confirm_password_signup"
              label={this.props.t('Authentication.Password.confirm')}
              name="confirmPassword"
              value={values.confirmPassword}
            />
            {showMatchTextError
              && (
              <Text textColor={TEXT_COLOR.ERROR} size={TEXT_SIZE.H4}>
                {this.props.t('Errors.passwordsNoMatch')}
              </Text>
              )}
          </DoubleInputItemContainer>
        </DoubleInputContainer>
        <StyledPasswordMessage>
          {this.getPasswordHint()}
        </StyledPasswordMessage>
        <StyledPasswordMessage />
      </PasswordsWrapper>
    );
  };

  renderBirthdate = () => {
    const { birthdate } = this.state.form.errors;
    return (
      <BirthdateContainer>
        <DatePickerContainer>
          <DropdownDatePicker
            handleChange={(date) => this.onDateUpdate(date)}
            error={Boolean(birthdate)}
            errorMessage={birthdate}
            label={this.props.t('Common.UI.birthDate')}
            noFuture
          />
        </DatePickerContainer>
        <DateTooltipContainer>
          <CustomTooltip
            text={this.props.t('Informative.Notifying.provideBirthdate')}
            placement={
              this.props.i18n.language === 'he' ? placementTypes.LEFT : placementTypes.RIGHT
            }
          />
        </DateTooltipContainer>
      </BirthdateContainer>
    );
  };

  renderLanguage = () => {
    const {
      theme,
    } = this.props;
    const { language } = this.state.form.values;
    const { language: languageError } = this.state.form.errors;
    const languageOptions = _.map(LANGUAGE_NOVOTALK, (value, key) => ({
      label: key,
      value,
    }));

    return (
      <LocationContainer>
        <DoubleInputContainer>
          <DoubleInputItemContainer>
            <Label>
              <Text
                textColor={TEXT_COLOR.DARK_BLUE}
                size={TEXT_SIZE.H4}
                weight="bold"
              >
                { // TODO:Put translatable text
                  'Language'
}
              </Text>
            </Label>
            <Select
              id="signup_language"
              value={languageOptions.find(
                (option) => option.value === language,
              )}
              options={languageOptions}
              onChange={(option) => this.onFieldUpdate({
                target: {
                  name: 'language',
                  value: option.value || 'EN',
                },
              })}
              onFocus={this.localeOnFocus}
              isClearable={false}
              maxMenuHeight={200}
              components={{ DropdownIndicator: () => <StyledDropdownIcon /> }}
              styles={languageSelectStyles(theme, languageError)}
            />
            {// TODO:Put translatable text in customText
              languageError && (
              <ErrorMessage type="CUSTOM" customText="Please select a language" />
              )
}
          </DoubleInputItemContainer>
        </DoubleInputContainer>
      </LocationContainer>
    );
  };

  renderLocation = () => {
    const {theme} = this.props;
    const { country, state } = this.state.form.values;
    let countriesOptions = [], statesOptions = []

    for(const [key, value] of Object.entries(countries)) {
      countriesOptions = [...countriesOptions, {
        label: value,
        value: key
      }]
    }

    for(const [key, value] of Object.entries(states)) {
      statesOptions = [...statesOptions, {
        label: value,
        value: key
      }]
    }

    return (
      <LocationContainer>
        <DoubleInputContainer>
          <DoubleInputItemContainer>
            <Label>
              <Text
                textColor={TEXT_COLOR.DARK_BLUE}
                size={TEXT_SIZE.H4}
                weight="bold"
              >
                {this.props.t('Common.UI.country')}
              </Text>
            </Label>
            <Select
              id="signup_country"
              value={countriesOptions.find(
                (option) => option.value === country,
              )}
              options={countriesOptions}
              onChange={(option) => this.onFieldUpdate({
                target: {
                  name: 'country',
                  value: option.value || 'US',
                },
              })}
              isClearable={false}
              maxMenuHeight={200}
              components={{ DropdownIndicator: () => <StyledDropdownIcon /> }}
              styles={countrySelectStyles(theme)}
            />
          </DoubleInputItemContainer>
          <DoubleInputItemContainer>
            <Label>
              <Text
                textColor={TEXT_COLOR.DARK_BLUE}
                size={TEXT_SIZE.H4}
                weight="bold"
              >
                {this.props.t('Common.UI.state')}
              </Text>
            </Label>
            <Select
              id="signup_state"
              value={
                country === 'US'
                  ? statesOptions.find((option) => option.value === state)
                  : {}
              }
              options={statesOptions}
              isDisabled={country !== 'US'}
              onChange={(option) => this.onFieldUpdate({
                target: {
                  name: 'state',
                  value: option.value || 'AL',
                },
              })}
              isClearable={false}
              maxMenuHeight={200}
              components={{ DropdownIndicator: () => <StyledDropdownIcon /> }}
              styles={countrySelectStyles(theme)}
            />
          </DoubleInputItemContainer>
        </DoubleInputContainer>
      </LocationContainer>
    );
  };

  renderSignUpButton = () => {
    const serverError = this.props.serverError ? (
      <ServerErrorMessage type="CUSTOM" customText={this.props.serverError} />
    ) : null;
    return (
      <SignUpButtonContainer>
        <CTAButton
          id="signup_button"
          onClick={this.onSignUpButtonClick}
          expanded
        >
          {this.props.t('Authentication.Distribution.signMe')}
        </CTAButton>
        {serverError}
      </SignUpButtonContainer>
    );
  };

  renderUnderButtonText = () => (
    <UnderButtonTextContainer>
      <Text textColor={TEXT_COLOR.DISABLED} size={TEXT_SIZE.H5}>
        {this.props.t('Informative.Notifying.agree')}
        <StyledLink id="terms_of_use_link" to={ROUTES.TERMS_OF_USE}>
          <TextButton>
            <Text size={TEXT_SIZE.H5} textColor={TEXT_COLOR.HIGHLIGHTED}>
              {this.props.t('Billing.Agreement.terms')}
            </Text>
          </TextButton>
        </StyledLink>
        {this.props.t('Informative.Notifying.read')}
        <StyledLink id="privacy_policy_link" to={ROUTES.PRIVACY_POLICY}>
          <TextButton>
            <Text size={TEXT_SIZE.H5} textColor={TEXT_COLOR.HIGHLIGHTED}>
              {this.props.t('Common.UI.privacyPolicy')}
            </Text>
          </TextButton>
        </StyledLink>
      </Text>
    </UnderButtonTextContainer>
  );

  renderSignInText = () => (
    <SignInTextContainer>
      <Text textColor={TEXT_COLOR.DISABLED} size={TEXT_SIZE.H4}>
        {this.props.t('Authentication.Distribution.haveAccount')}
      </Text>
      <StyledLink id="back_to_signin_link" to={ROUTES.SIGN_IN}>
        <TextButton>
          <Text size={TEXT_SIZE.H4} margin="0 5px">
            {this.props.t('Actions.Auth.signIn')}
          </Text>
        </TextButton>
      </StyledLink>
    </SignInTextContainer>
  );

  renderVersions = () => (
    <VersionsTextContainer>
      <ClientVersion />
      <ServerVersion />
    </VersionsTextContainer>
  );

  render() {
    if (this.props.loading) {
      return <SectionLoading />;
    }
    return (
      <ScreenContainer>
        {this.renderTrialHeader()}
        <MainAreaContainer>
          {USE_PAID_SYSTEM_VERSION && this.renderTrialList()}

          <SignUpComponentContainer className="signUp">
            {this.renderNameInputs()}

            {this.renderEmail()}

            {this.renderPasswords()}

            {this.renderBirthdate()}

            {this.renderLocation()}
            {this.renderLanguage()}
            {this.renderSignUpButton()}

            {this.renderUnderButtonText()}

            {this.renderSignInText()}

            {ENVIRONMENT !== 'prod' && this.renderVersions()}
          </SignUpComponentContainer>
        </MainAreaContainer>
      </ScreenContainer>
    );
  }
}

SignUp.defaultProps = {
  onSignUpClick: () => {},
  openPopUp: () => {},
};

SignUp.propTypes = {
  onSignUpClick: PropTypes.func.isRequired,
  openPopUp: PropTypes.func.isRequired,
  history: PropTypes.object,
  serverError: PropTypes.string,
  theme: PropTypes.object,
};

export const mapStateToProps = (state) => {
  const serverError = state.getIn(['signUp', 'errors', 'serverError']);
  const redirectToPage = state.getIn(['signUp', 'redirectToPage']);
  const loading = state.getIn(['signUp', 'loading']);
  return { serverError, redirectToPage, loading };
};

export const mapDispatchToProps = (dispatch) => ({
  onSignUpClick: (signInfo) => {
    const {
      firstName,
      lastName,
      email,
      password,
      birthdate,
      country,
      state,
      available,
      locale,
    } = signInfo;
    dispatch(
      onSignUp(
        firstName,
        lastName,
        email,
        password,
        birthdate,
        country,
        state,
        available,
        locale,
      ),
    );
  },
  openPopUp: (type, props) => dispatch(openPopUpAction(type, props)),
});

const withConnect = connect(mapStateToProps, mapDispatchToProps);
const withSaga = injectSaga({ key: 'signUp', saga });
const withReducer = injectReducer({ key: 'signUp', reducer });

export default compose(
  withTranslation(),
  withConnect,
  withReducer,
  withSaga,
  withRouter,
  withTheme,
)(SignUp);
