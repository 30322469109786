import React from 'react';
import PropTypes from 'prop-types';
import Text, { TEXT_SIZE, TEXT_COLOR } from '@components/Text';

import {
  Wrapper,
  Header,
  SubHeader,
  MainText,
  ButtonsContainer,
  StyledCTAButton,
} from './styles';
import {useTranslation} from "react-i18next";

export const CancelSubscription = (props) => {
  const {onCancelSubscription, onClose} = props;
  const {t} = useTranslation()

  return (
    <Wrapper>
      <Header>
        <Text size={TEXT_SIZE.H1} textColor={TEXT_COLOR.DARK_BLUE}>
            {t('Subscription.cancel')}
        </Text>
      </Header>
      <SubHeader>
        <Text
          size={TEXT_SIZE.T2}
          textColor={TEXT_COLOR.DARK_BLUE}
          weight='bold'
        >
            {t('Subscription.cancelAction')}
        </Text>
      </SubHeader>
      <MainText>
        <Text size={TEXT_SIZE.H4} textColor={TEXT_COLOR.DARK_BLUE}>
            {t('Subscription.keepUp')}
        </Text>
      </MainText>
      <ButtonsContainer>
        <StyledCTAButton
          secondary
          onClick={() => {
            onCancelSubscription();
            onClose();
          }}
        >
            {t('Subscription.cancelConfirmation')}
        </StyledCTAButton>
        <StyledCTAButton onClick={onClose}>
            {t('Subscription.keepPlan')}
        </StyledCTAButton>
      </ButtonsContainer>
    </Wrapper>
  );
};

CancelSubscription.propTypes = {
  onCancelSubscription: PropTypes.func,
  onClose: PropTypes.func,
};

export default CancelSubscription;
