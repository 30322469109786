import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import TextButton, { TYPES } from '@components/TextButton';
import Text, { TEXT_SIZE } from '@components/Text';
import {
  openPopUp,
  closePopUp,
} from '@containers/App/containers/PopUp/actions';
import POPUP_TYPES from '@containers/App/containers/PopUp/types';
import { NoContentOptionsSection, StyledCTAButton } from './styles';
import {useTranslation} from "react-i18next";

export const MediaSelector = ({
  dispatchOpenPopup,
  onItemSelect,
  isVideoExisted,
  onItemRemove,
}) => {
  const {t} = useTranslation()
  return (
      <NoContentOptionsSection>
        {!isVideoExisted && (
            <StyledCTAButton
                outline
                primary
                onClick={dispatchOpenPopup(POPUP_TYPES.MEDIA_UPLOAD, onItemSelect)}
                className='uploadButton'
            >
              <Text size={TEXT_SIZE.H5}>
                {t('Actions.Instance.upload')}
              </Text>
            </StyledCTAButton>
        )}
        <StyledCTAButton
            onClick={dispatchOpenPopup(POPUP_TYPES.MEDIA_VIEWER, onItemSelect)}
            className='selectExistingButton'
        >
          <Text size={TEXT_SIZE.H5}>
            {t(`Actions.Multiple.${isVideoExisted ? 'selectOther' : 'selectExisting'}`)}
          </Text>
        </StyledCTAButton>
        {isVideoExisted && (
            <TextButton
                type={TYPES.PRIMARY}
                onClick={onItemRemove}
                className='removeButton'
                margin='0 17px'
                underline
            >
              <Text size={TEXT_SIZE.H4}>
                {t('Video.removeVideo')}
              </Text>
            </TextButton>
        )}
      </NoContentOptionsSection>
  );
}

function mapDispatchToProps(dispatch) {
  return {
    dispatchOpenPopup: (popupType, onItemSelect) => () => {
      const selectAndClose = (...args) => {
        onItemSelect(...args);
        dispatch(closePopUp());
      };

      dispatch(
        openPopUp(popupType, {
          onItemUploaded: selectAndClose,
          onItemSelect: selectAndClose,
        })
      );
    },
  };
}

MediaSelector.propTypes = {
  dispatchOpenPopup: PropTypes.func,
  onItemSelect: PropTypes.func,
  isVideoExisted: PropTypes.bool,
  onItemRemove: PropTypes.func,
};

export default connect(null, mapDispatchToProps)(MediaSelector);
