export const Types = {
  BUILDER_SAVE: 'BUILDER_SAVE',
  BUILDER_SAVE_COMPLETED: 'BUILDER_SAVE_COMPLETED',
  BUILDER_SAVE_FAILED: 'BUILDER_SAVE_FAILED',

  BUILDER_UPDATE_ENTITY: 'BUILDER_UPDATE_ENTITY',
  BUILDER_UPDATE_ENTITY_COMPLETED: 'BUILDER_UPDATE_ENTITY_COMPLETED',
  BUILDER_UPDATE_ENTITY_FAILED: 'BUILDER_UPDATE_ENTITY_FAILED',

  BUILDER_LOAD: 'BUILDER_LOAD',
  BUILDER_LOAD_COMPLETED: 'BUILDER_LOAD_COMPLETED',
  BUILDER_LOAD_FAILED: 'BUILDER_LOAD_FAILED',

  BUILDER_UPDATE_FIELD: 'BUILDER_UPDATE_FIELD',
  BUILDER_ADD_COMPONENT: 'BUILDER_ADD_COMPONENT',
  BUILDER_UPDATE_COMPONENT_PAYLOAD: 'BUILDER_UPDATE_COMPONENT_PAYLOAD',
  BUILDER_UPDATE_COMPONENT_INDEX: 'BUILDER_UPDATE_COMPONENT_INDEX',
  BUILDER_RESET: 'BUILDER_RESET',
  BUILDER_CLEAR_BREAD_CRUMBS: 'BUILDER_CLEAR_BREAD_CRUMBS',

  BUILDER_EDIT:'BUILDER_EDIT',
  BUILDER_EDIT_COMPLETED:'BUILDER_EDIT_COMPLETED',
  BUILDER_EDIT_FAILED:'BUILDER_EDIT_FAILED'
};

export const clearBreadcrumbs = () => ({
  type: Types.BUILDER_CLEAR_BREAD_CRUMBS,
  payload: { },
});

export const save = (type, info, components) => ({
  type: Types.BUILDER_SAVE,
  payload: { type, info, components },
});
export const edit = (type, info, components) =>({
  type: Types.BUILDER_EDIT,
  payload:{type, info, components}
});

export const update = (type, info, components)=>({
  type: Types.BUILDER_UPDATE_ENTITY,
  payload: {type, info, components}
})

export const load = (type, id) => ({
  type: Types.BUILDER_LOAD,
  payload: { type, id },
});

export const loadCompleted = (type, data) => ({
  type: Types.BUILDER_LOAD_COMPLETED,
  payload: { type, data },
});

export const loadFailed = (error) => ({
  type: Types.BUILDER_LOAD_FAILED,
  payload: { error },
});

export const saveCompleted = () => ({
  type: Types.BUILDER_SAVE_COMPLETED,
  payload: { },
});

export const saveFailed = (error) => ({
  type: Types.BUILDER_SAVE_FAILED,
  payload: { error },
});

export const updateField = (fieldName, value, valid = true, additionalData = {}) => ({
  type: Types.BUILDER_UPDATE_FIELD,
  payload: { fieldName, value, valid, additionalData },
});

export const addComponent = (type, customPayload) => ({
  type: Types.BUILDER_ADD_COMPONENT,
  payload: { type, customPayload },
});

export const updateComponentPayload = (id, newPayload) =>({
  type: Types.BUILDER_UPDATE_COMPONENT_PAYLOAD,
  payload: { id, newPayload },
});

export const updateComponentIndex = (id, index) => ({
  type: Types.BUILDER_UPDATE_COMPONENT_INDEX,
  payload: { id, index },
});

export const resetBuilder = () => ({
  type: Types.BUILDER_RESET,
});

