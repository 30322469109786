import styled from "@emotion/styled";
import Text from "@components/Text/index.js";
import {pxToRem} from "@services/theme/utils";
import {getIconByResourceType} from "@services/icons/index.js";
import OverviewButton from "@components/OverviewButton";
import { getResponsiveCSS } from '@utils/cssHelpers';

export const ListItem = styled('div', {
    shouldForwardProp: props => props !== 'isFirstElement',
})<{ isFirstElement?: boolean }>(({isFirstElement, theme}) => ({
    display: 'grid',
    alignItems: 'center',
    minHeight: 54,
    gridTemplate: `'${(!isFirstElement) ? 'status' : ''} icon name info buttons'`,
    gridTemplateColumns: `${(!isFirstElement) ? pxToRem(26) : ''} ${pxToRem(26)} auto max-content max-content`,
    paddingLeft: !isFirstElement ? pxToRem(20) : 0,
    paddingRight: pxToRem(20),
    borderBottom: `1px solid ${theme.palette.novotalk.$resourcesTableBorderColor}`,
    '&:last-child': {
        border: 'none',
    },
    '&:hover': {
        backgroundColor: !isFirstElement ? theme.palette.novotalk.$resourcesTableBgColorHover : '',
    },
}))

export const NameContainer = styled('div')({
    gridArea: 'name',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    marginRight: pxToRem(20),
});

export const NameText = styled(Text, {
    shouldForwardProp: props => props !== 'isExpandable' && props !== 'isCurrentLevel'
})<{ isExpandable?: boolean, isCurrentLevel?: boolean }>({
        whiteSpace: 'nowrap',
    fontSize: pxToRem(14),
    },
    ({isExpandable, isCurrentLevel, theme}) => ({
        color: isCurrentLevel ?  theme.palette.novotalk.$novotalkBlue : theme.palette.novotalk.$textColorDarkBlue,
        cursor: isExpandable ? 'pointer' : 'auto',
    })
);

export const IconWrapper = styled('div')({
    gridArea: 'status',
    display: 'flex',
    alignItems: 'center',
    marginTop: pxToRem(1),
    height: 21,
    width: 40,
});

export const getStyledIconByType = (type: string) => styled(getIconByResourceType(type), {
    shouldForwardProp: props => props !== 'isCurrentLevel',
})<{ isCurrentLevel?: boolean }>(({theme, isCurrentLevel}) => ({
        gridArea: 'icon',
        fontSize: pxToRem(19),
        marginTop: 0,
        width: 26,
        paddingRight: pxToRem(7),
        color: isCurrentLevel ? theme.palette.novotalk.$novotalkBlue : theme.palette.novotalk.$textColorLightGray,
        cursor: 'auto',
    })
);

export const MoveBtn = styled(OverviewButton,{
    shouldForwardProp: props => props !== 'hideButton'
})<{hideButton?:boolean}>(({hideButton})=>({
    height: 32,
    display: hideButton ? 'none':'',
    
}),
{...getResponsiveCSS('marginLeft', 6, 8, '&:last-of-type')}
)
