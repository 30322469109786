export const Types = {
  SIGN_UP: 'SIGN_UP',
  SIGN_UP_FAIL: 'SIGN_UP_FAIL',
  SIGN_UP_SUCCESS: 'SIGN_UP_SUCCESS',
  LOADING_SWITCH: 'LOADING_SWITCH',
};

export const onSignUp = (
  firstName,
  lastName,
  email,
  password,
  birthdate,
  country,
  state,
  available,
  locale,
) => ({
  type: Types.SIGN_UP,
  payload: {
    firstName,
    lastName,
    email,
    password,
    birthdate,
    country,
    state,
    available,
    locale,
  },
});

export const onSignUpSuccess = (available) => ({
  type: Types.SIGN_UP_SUCCESS,
  available,
});

export const onSignUpFail = (error) => ({
  type: Types.SIGN_UP_FAIL,
  payload: { error },
});
export const onLoadingSwitch = (loading) => ({
  type: Types.LOADING_SWITCH,
  payload: loading,
});
