import { BUILDER_TYPES } from '../editorObjects/editorObjectTypes';
import { createTemporaryEntity } from "@containers/Admin/shared/ResourcesWrapper/service/createNewEntity";
import * as pollService from './poll';
import * as practiceService from './practice';
import * as exerciseService from './exercise';
import * as lessonService from './lesson';
import * as skillService from './skill';
import * as courseService from './course';
import * as mediaService from './media'
import { MEDIA_TYPES } from '@shared/Resources/types';

export const newEntity = (type) => createTemporaryEntity(type);

export const getService = (type) => {
  switch (type) {
    case BUILDER_TYPES.POLL: return pollService;
    case BUILDER_TYPES.PRACTICE: return practiceService;
    case BUILDER_TYPES.EXERCISE: return exerciseService;
    case BUILDER_TYPES.LESSON: return lessonService;
    case BUILDER_TYPES.SKILL: return skillService;
    case BUILDER_TYPES.COURSE: return courseService;
    case MEDIA_TYPES.VIDEO:
    case MEDIA_TYPES.AUDIO:
    case MEDIA_TYPES.IMAGE:
       return mediaService;
    default: throw new Error(`Missing type - ${type}`);
  }
};

export const saveEntity = (type, info, components) => { 
  const service = getService(type);
  return service.createEntity(info, components);
};
export const modifyEntity = (type, info, components)=>{
  const service = getService(type);
  return service.editEntity(info, components);
}

export const loadEntity = (type, id) => {
  const service = getService(type);
  return service.loadEntity(id);
};
