import { useSelector, useDispatch } from 'react-redux';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { MONITOR_TYPES, PROGRESS_TYPE } from '@shared/Resources/Monitor/types';
import {
  setExercisePausedFlag,
  setMicrophoneActiveFlag,
  setMonitorActiveFlag,
} from '@containers/User/containers/CourseRouter/containers/MonitorHandler/actions';
import { StyledButton, StyledPauseIconCornerMonitor } from './styles';

export const PauseButton = () => {
  const dispatch = useDispatch();
  const { i18n } = useTranslation();

  const { exercisePaused, monitorActive, intervalExercise } = useSelector((state) => state.get('monitorHandler').toJS());
  const { monitor: practiceMonitor } = useSelector((state) => state.getIn(['currentPractice', 'currentExercise']) ?? {});
  const { monitor: lessonMonitor } = useSelector((state) => state.getIn(['currentLesson', 'lesson']) ?? {});
  const isRTL = useMemo(() => i18n.language === 'he' && (practiceMonitor?.monitor.type === MONITOR_TYPES.BREATHING), [i18n]);

  const pauseExercise = () => {
    if (practiceMonitor?.progress.type === PROGRESS_TYPE.TIMER
      || lessonMonitor?.progress.type === PROGRESS_TYPE.TIMER
    ) {
      clearInterval(intervalExercise);
    }

    dispatch(setMonitorActiveFlag(false));
    dispatch(setMicrophoneActiveFlag(false));
    dispatch(setExercisePausedFlag(true));
  };

  return !exercisePaused
    && monitorActive
    && (
      <StyledButton
        type="submit"
        id="pauseMonitorButton"
        onClick={pauseExercise}
        isRTL={isRTL}
      >
        <StyledPauseIconCornerMonitor />
      </StyledButton>
    );
};
