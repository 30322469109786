import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import _ from 'lodash';
import {withTheme} from "@mui/styles";
import injectSaga from '@utils/injectSaga';
import injectReducer from '@utils/injectReducer';
import Text, { TEXT_SIZE, TEXT_COLOR } from '@components/Text';
import CustomTextInput from '@components/CustomTextInput';
import StripeBadge from './powered_by_stripe.png';
import StripeForm from './components/StripeForm';
import PaymentSuccess from './components/PaymentSuccess';
import PaymentFailure from './components/PaymentFailure';

import {
  ScreenWrapper,
  StyledHeader,
  ContentWrapper,
  OptionsWrapper,
  TriggerWrapper,
  TriggerText,
  StyledTrigger,
  PriceWrapper,
  CouponInputWrapper,
  CouponButton,
  FormHeader,
  AdvantagesList,
  AdvantagesTitle,
  AdvantagesItem,
  StyledCheckmarkIcon,
  BadgesList,
  Badge,
} from './styles';
import { billingAction, billingResetAction, TYPES, getPlans } from './actions';
import saga from './saga';
import reducer from './reducer';
import { CHARGE_FREQUENCIES } from './constants';
import {withTranslation} from "react-i18next";

export class Billing extends Component {
  constructor(props) {
    super(props);
    this.state = {
      chargeFrequency: CHARGE_FREQUENCIES.ANNUALLY,
      fluencyShapingCourseId: '5a1aa0e4d2800000',
      couponValue: '',
      couponError: null,
    };
  }

  componentDidMount() {
    this.props.dispatchGetPlans(this.state.fluencyShapingCourseId);
  }

  componentDidUpdate(prevProps) {
    const { couponError } = this.props;
    if (prevProps.couponError !== couponError) {
      if (this.state.couponError !== couponError) {
        this.setState({ couponError }); // eslint-disable-line
      }
    }
  }

  handleCouponInput = (e) => {
    this.setState({ couponValue: e.target.value });
  };

  handleCouponSubmit = () => {
    const couponValue = this.state.couponValue.trim();
    const {t} = this.props;
    if (couponValue) {
      this.props.dispatchGetPlans(
        this.state.fluencyShapingCourseId,
        couponValue
      );
    } else {
      this.setState({ couponError: t('Billing.Informative.couponError') });
    }
  };

  handlePayment = (stripeToken) => {
    const { onBilling, plans } = this.props;
    const { chargeFrequency, couponValue } = this.state;
    const couponCode = couponValue.length > 0 ? couponValue : null;
    const displayedPlan = _.find(plans, { chargeFrequency });
    onBilling(stripeToken, couponCode, displayedPlan.planId);
  };

  triggerPrice = () => {
    this.setState(({ chargeFrequency }) => ({
      chargeFrequency:
        chargeFrequency === CHARGE_FREQUENCIES.ANNUALLY
          ? CHARGE_FREQUENCIES.MONTHLY
          : CHARGE_FREQUENCIES.ANNUALLY,
    }));
  };

  calculatePricePerWeek = () => {
    const { plans, discountedPlans } = this.props;
    if (plans.length === 0) return null;
    const { chargeFrequency } = this.state;
    const plansToDisplay = _.isEmpty(discountedPlans) ? plans : discountedPlans;

    const displayedPlan = _.find(plansToDisplay, { chargeFrequency });

    let pricePerWeek;
    if (chargeFrequency === CHARGE_FREQUENCIES.MONTHLY) {
      const price = displayedPlan.price.amount / 100;
      pricePerWeek = parseInt(price / 4, 10);
    } else if (chargeFrequency === CHARGE_FREQUENCIES.ANNUALLY) {
      const price = displayedPlan.price.amount / 100;
      pricePerWeek = parseInt(price / 48, 10);
    }
    if (displayedPlan.price.currency === 'usd') pricePerWeek += '$';
    return pricePerWeek;
  };

  renderPaymentOptions = () => {
    const { chargeFrequency } = this.state;
    const {t} = this.props;
    return (
      <OptionsWrapper id='payment-options-form'>
        <TriggerWrapper>
          <TriggerText
            isActive={chargeFrequency === CHARGE_FREQUENCIES.MONTHLY}
            onClick={this.triggerPrice}
          >
            <Text size={TEXT_SIZE.T3}>
              {t('Billing.Informative.monthly')}
            </Text>
          </TriggerText>
          <StyledTrigger
            chargeFrequency={chargeFrequency}
            onClick={this.triggerPrice}
          />
          <TriggerText
            isActive={chargeFrequency === CHARGE_FREQUENCIES.ANNUALLY}
            onClick={this.triggerPrice}
          >
            <Text size={TEXT_SIZE.T3}>
              {t('Billing.Informative.billingInfo')}
            </Text>
          </TriggerText>
        </TriggerWrapper>
        <PriceWrapper>
          <Text textColor={TEXT_COLOR.DARK_BLUE} id='payment-price-value'>
            {this.calculatePricePerWeek()}
          </Text>
          <Text size={TEXT_SIZE.H4} textColor={TEXT_COLOR.DISABLED}>
            {t('Billing.Informative.weekly')}
          </Text>
        </PriceWrapper>
        {this.renderCouponArea()}
      </OptionsWrapper>
    );
  };

  renderCouponArea = () => {
    const {t, discountedPlans} = this.props;
    const { couponValue, couponError } = this.state;
    if (discountedPlans.length > 0 && couponError === null) {
      return (
        <div>
          {t('Billing.Informative.couponApply', { coupon: couponValue })}
        </div>
      );
    }

    return (
      <CouponInputWrapper>
        <CustomTextInput
          id='payment-coupon-input'
          label={t('Billing.Informative.coupon')}
          name='coupon'
          value={couponValue}
          onChange={this.handleCouponInput}
          error={couponError}
        />
        <CouponButton
          id='payment-coupon-button'
          onClick={this.handleCouponSubmit}
          hasError={couponError}
        >
          {t('Actions.Instance.apply')}
        </CouponButton>
      </CouponInputWrapper>
    );
  };

  renderPaymentArea = () => {
    const {t, theme, user} = this.props;
    return (
      <div>
        <FormHeader>
          <Text size={TEXT_SIZE.T2} textColor={TEXT_COLOR.NORMAL} weight='900'>
            {t('Billing.Credentials.details')}
          </Text>
        </FormHeader>
        <StripeForm
          submitPayment={this.handlePayment}
          formatMessage={t}
          loading={this.props.loading}
        />
      </div>
    );
  };

  renderAdvantage = (advantageId) => {
    return (
        <AdvantagesItem key={advantageId}>
          <StyledCheckmarkIcon />
          <Text size={TEXT_SIZE.H4} textColor={TEXT_COLOR.DARK_BLUE}>
            {this.props.t(`Billing.Advantages.advantagesListItem${advantageId}`)}
          </Text>
        </AdvantagesItem>
    )
  }

  renderAdvantagesList = () => {
    const {t} = this.props;
    return (
      <AdvantagesList>
        <AdvantagesTitle>
          <Text
            size={TEXT_SIZE.T2}
            textColor={TEXT_COLOR.DARK_BLUE}
            weight='900'
          >
            {t('Billing.Informative.fullCourse')}
          </Text>
        </AdvantagesTitle>
        {Array(8).map((item, i) => (
            this.renderAdvantage(i)
        ))}
      </AdvantagesList>
    );
  };

  renderBadgesList = () => (
    <BadgesList>
      <Badge src={StripeBadge} />
    </BadgesList>
  );

  render() {
    const {plans, t, billingState, onBillingReset} = this.props;
    let component;

    if (!plans) return <div>loading</div>;
    switch (billingState) {
      case TYPES.BILLING_SUCCESS:
        component = <PaymentSuccess />;
        break;
      case TYPES.BILLING_FAILURE:
        component = <PaymentFailure onBillingReset={onBillingReset} />;
        break;
      default:
        component = (
          <ScreenWrapper>
            <StyledHeader>
              <Text size={TEXT_SIZE.H1} textColor={TEXT_COLOR.DARK_BLUE}>
                {t('Billing.Informative.upgrade')}
              </Text>
            </StyledHeader>
            <ContentWrapper>
              <div>
                {this.renderPaymentOptions()}
                {this.renderPaymentArea()}
              </div>
              <div>
                {this.renderAdvantagesList()}
                {this.renderBadgesList()}
              </div>
            </ContentWrapper>
          </ScreenWrapper>
        );
    }
    return component;
  }
}

Billing.propTypes = {
  theme: PropTypes.object,
  user: PropTypes.string,
  onBilling: PropTypes.func,
  onBillingReset: PropTypes.func,
  billingState: PropTypes.string,
  plans: PropTypes.array,
  discountedPlans: PropTypes.array,
  dispatchGetPlans: PropTypes.func,
  couponError: PropTypes.string,
};

export const mapStateToProps = (state) => {
  const { firstName, lastName } = state
    .getIn(['app', 'authentication', 'user'])
    .toJS();
  const {
    billingState,
    plans,
    discountedPlans,
    couponError,
    loading,
  } = state.get('billing') ? state.get('billing').toJS() : {};
  return {
    user: `${firstName} ${lastName}`,
    billingState,
    plans,
    discountedPlans,
    couponError,
    loading,
  };
};

export const mapDispatchToProps = (dispatch) => ({
  onBilling: (stripeToken, couponCode, planId) =>
    dispatch(billingAction(stripeToken, couponCode, planId)),
  onBillingReset: () => dispatch(billingResetAction()),
  dispatchGetPlans: (entityId, couponCode) =>
    dispatch(getPlans(entityId, couponCode)),
});

const withConnect = connect(mapStateToProps, mapDispatchToProps);
const withSaga = injectSaga({ key: 'billing', saga });
const withReducer = injectReducer({ key: 'billing', reducer });

export default compose(
  withTranslation(),
  withConnect,
  withReducer,
  withSaga,
  withTheme
)(Billing);
