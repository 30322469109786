import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  PopupText,
  StyledCheckmarkIcon,
  StyledTooltip,
} from '@containers/User/containers/CourseRouter/containers/MonitorHandler/components/MonitorFeedbackTooltip/styles';

interface MonitorFeedbackTooltipProps {
  error: string;
  location: string;
  duration?: number;
  key?: string;
  isVideoChat?: boolean;
}

export const MonitorFeedbackTooltip: React.FC<MonitorFeedbackTooltipProps> = ({
  error,
  duration,
  location,
  key,
  isVideoChat,
}) => {
  const { t } = useTranslation();
  const [width, setWidth] = useState(0);
  const [isError, _] = useState(error !== 'great');
  const tooltipRef = useRef<HTMLDivElement | null>(null);
  const props = {
    location,
    isError,
    duration,
    key,
    width,
    isVideoChat,
  };

  useEffect(() => {
    setWidth(tooltipRef?.current?.offsetWidth || 0);
  }, []);

  return (
    <StyledTooltip ref={tooltipRef} {...props}>
      {!isError && <StyledCheckmarkIcon />}
      <PopupText>{error === 'great' ? t('Feedback.great') : error}</PopupText>
    </StyledTooltip>
  );
};
