import React, { Component } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { connect } from 'react-redux';
import ErrorIcon from '@components/ErrorIcon';
import SectionLoading from '@components/SectionLoading';
import { getIconsByType, iconTypes } from '@services/icons';
import { ERROR_TYPES } from '@shared/Resources/Monitor/types';
import Text, {
  TEXT_COLOR,
  TEXT_SIZE,
  TEXT_WEIGHT,
} from '@components/Text';
import { ErrorName } from '@components/ErrorName';
import Collapse from '@components/Collapse';
import { openPopUp } from '@containers/App/containers/PopUp/actions';
import POPUP_TYPES from '../../../../../App/containers/PopUp/types';

import EndOfExerciseInsight from './components/EndOfExerciseInsight';
import ActivityProduction from './components/ActivityProduction';

import {
  Wrapper,
  Header,
  InnerHeader,
  ErrorIconWrapper,
  Progress,
  StyledProgressBar,
  CollapseButton,
  IconWrapper,
  Body,
  ExerciseInfo,
  InfoContainer,
  StyledAudioPlayer,
  VideoButtonContainer,
  VideoButton,
  StyledVideoIcon,
  InsightsWrapper,
  ProductionWrapper,
  ProductionsHeader,
} from './styles';
import { isMvpVersion } from '@services/isMvpService';
import {withTranslation} from "react-i18next";
import {compose} from "redux";

const CollapseIcon = getIconsByType(iconTypes.CHEVRON_DOWN);
const ExpandIcon = getIconsByType(iconTypes.CHEVRON_UP);
const titleProps = {
  textColor: TEXT_COLOR.DARK_BLUE,
  size: TEXT_SIZE.H4,
  weight: TEXT_WEIGHT.BOLDER,
};
const infoProps = {
  size: TEXT_SIZE.H4,
  textColor: TEXT_COLOR.DARK_GRAY,
};
const regularTextProps = {
  size: TEXT_SIZE.H4,
  textColor: TEXT_COLOR.DARK_BLUE,
};

export class ExerciseItem extends Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
      playingProductionId: null,
      productionErrors: 0,
    };
    this.audioRef = null;
    this.stopInterval = null;
  }

  calculateProductionErrors = () => {
    const { productions: { items } } = this.props;

    return items.reduce((acc, { productionErrors }) =>
      productionErrors.some(({ displayed }) => displayed) ? acc + 1 : acc
      , 0)
  }

  componentDidUpdate(prevProps) {
    const { productions: { items } } = this.props;
    if (!_.isEqual(prevProps.productions.items, items)) {
      this.setState({ productionErrors: this.calculateProductionErrors() });
    }
  }

  onVideoClicked = (activityMediaVideo) => () => {
    console.log('onVideoClicked', activityMediaVideo);
    this.props.dispatchOpenPopup(POPUP_TYPES.MEDIA, {
      description: '',
      contentUrl: activityMediaVideo.publicUrls[0],
      contentType: 'video',
    });
  };

  onAudioStopped = () => {
    this.setState({ playingProductionId: null });
  };

  onPlayClicked = (production) => {
    const { at } = this.props.exercise.activityCreated;
    if (this.stopInterval) clearInterval(this.stopInterval);
    this.stopInterval = null;

    if (this.audioRef) {
      if (production.id === this.state.playingProductionId) {
        this.stopAudio();
      } else {
        const offset = production.startTS - new Date(at).getTime() / 1000;
        this.audioRef.currentTime = offset;
        this.audioRef.play();
        this.setState({ playingProductionId: production.id });
        this.stopInterval = setTimeout(
          this.stopAudio,
          production.productionLength * 1000
        );
      }
    }
  };

  getMostCommonError = () => {
    const {
      exercise: {
        summary,
      },
    } = this.props;
    if(!summary?.errorDistribution) {
      return null
    }
    const mostCommonError = _.maxBy(
      _.keys(summary.errorDistribution),
      (key) => summary.errorDistribution[key]
    );
    return mostCommonError;
  };

  getExerciseLengthFromProductions = (activity) => {
    if (!activity || !activity.mediaDuration ||  !activity.mediaDuration.length) {
      return { minutes: '00', seconds: '00' };
    }
    const items = activity.mediaDuration
    const sumOfSeconds = items.reduce((sum, a) => sum + (+a), 0);
    const minutesOfExercise = Math.floor(Math.floor(sumOfSeconds) / 60);
    const secondsOfExercise = sumOfSeconds - minutesOfExercise * 60;
    return {
      minutes: `${minutesOfExercise}`,
      seconds: `${Math.floor(secondsOfExercise)}`.padStart(2, '0'),
    }
  };

  setAudioElement = (element) => {
    this.audioRef = element;
  };

  toggleOpen = () => {
    const { open } = this.state;
    const { productions, exercise } = this.props;
    const newOpen = !open;
    this.setState({ open: newOpen });
    if (newOpen && !productions.items) {
      this.props.onGetProductions(exercise.id);
    }
  };

  stopAudio = () => {
    this.audioRef.pause();
    this.setState({ playingProductionId: null });
  };

  renderInfo = (
    items,
    summary,
    activityMediaAudio,
    activityMediaVideo
  ) => {
    const {t} = this.props
    const exerciseLengthValues =this.getExerciseLengthFromProductions(activityMediaVideo);
    const shortInhalesCount = summary?.errorDistribution?.[ERROR_TYPES.tooShortInhalation];
    return (
      <ExerciseInfo hasPassed={summary?.hasPassed}>
        <InfoContainer>
          <Text {...titleProps}>
            {t('UI.exerciseLength')}
          </Text>
          <Text {...infoProps}>
            {exerciseLengthValues&&<div className='exerciseLength'>
              {t('Dates.minutesSecondsAgo', {
                minutes:exerciseLengthValues.minutes,
                seconds:exerciseLengthValues.seconds
              })}
            </div>
              }
          </Text>
        </InfoContainer>
        <InfoContainer>
          <Text {...titleProps}>
            {t('Stimuli.shortInhalation')}
          </Text>
          {shortInhalesCount ? (
            <Text
              {...infoProps}
              className='shortInhales'
            >{`${shortInhalesCount}/${items.length}`}</Text>
          ) : (
            <Text {...infoProps}>-</Text>
          )}
        </InfoContainer>
        <InfoContainer>
          <Text {...titleProps}>
            {t('Errors.productionWithErrors')}
          </Text>
          <Text
            {...infoProps}
            className='productionWithError'
          >{`${this.state.productionErrors}/${items.length}`}</Text>
        </InfoContainer>
        {!isMvpVersion && !summary?.hasPassed && (
          <InfoContainer>
            <Text {...titleProps}>
              {t('Common.UI.attempts')}
            </Text>
            <Text {...infoProps} className='attempts'>
              {3}
            </Text>
          </InfoContainer>
        )}
        <InfoContainer>
          <Text {...titleProps}>
            {t('Common.UI.audio')}
          </Text>
          <StyledAudioPlayer
            src={_.get(activityMediaAudio, ['publicUrls', '0'], null)}
            getAudioRef={this.setAudioElement}
            className='exerciseAudio'
          />
        </InfoContainer>
        <VideoButtonContainer>
          <VideoButton
            outline
            onClick={this.onVideoClicked(activityMediaVideo)}
            className='exerciseVideo'
            disabled={!activityMediaVideo}
          >
            <StyledVideoIcon />
            <Text size={TEXT_SIZE.H4}>
              {t('Common.UI.video')}
            </Text>
          </VideoButton>
        </VideoButtonContainer>
      </ExerciseInfo>
    );
  };

  renderHeader = () => {
    const { open } = this.state;
    const {
      exercise: { entity, summary },
      exerciseIndex,
    } = this.props;
    const mostCommonError = this.getMostCommonError();
    const progressProps = {
      progress: summary?.achievedMasteryLevel,
      mastery: entity.monitor.errors.masteryLevel,
      height: 7,
    };

    return (
      <Header className='header' open={open} onClick={this.toggleOpen}>
        <InnerHeader>
          <Text
            size={TEXT_SIZE.H4}
            textColor={TEXT_COLOR.DARK_BLUE}
            className='exerciseName'
          >
            {`${exerciseIndex.padStart(2, '0')}. ${entity.name}`}
          </Text>
          <IconWrapper className='icon'>
            {mostCommonError && (
              <React.Fragment>
                <ErrorIconWrapper>
                  <ErrorIcon
                    className='commonErrorIcon'
                    errorType={mostCommonError}
                  />
                </ErrorIconWrapper>
                <ErrorName
                  className='commonErrorName'
                  errorType={mostCommonError}
                  additionalTextProps={{
                    textColor: TEXT_COLOR.DARK_BLUE,
                    size: TEXT_SIZE.T4,
                  }}
                />
              </React.Fragment>
            )}
          </IconWrapper>
          <Progress>
            <StyledProgressBar className='masteryStats' {...progressProps} />
            <Text
              size={TEXT_SIZE.T4}
              textColor={
                TEXT_COLOR[
                progressProps.progress < progressProps.mastery
                  ? 'DISABLED'
                  : 'ACTIVE'
                ]
              }
            >
              {`${progressProps.progress || 0}% `}
            </Text>
            <Text size={TEXT_SIZE.T4} textColor={TEXT_COLOR.DARK_BLUE}>
              {` / ${progressProps.mastery}%`}
            </Text>
          </Progress>
          <CollapseButton className='toggleOpen'>
            {open ? <ExpandIcon /> : <CollapseIcon />}
          </CollapseButton>
        </InnerHeader>
      </Header>
    );
  };

  renderProductions = (items, activityMediaAudio) => (
    <ProductionWrapper>
      <div>
        <Text {...titleProps}>
          <div className='productionsHeaderTitle'>
            {this.props.t('Common.UI.productions')}
          </div>
        </Text>
      </div>
      <ProductionsHeader className='productionsHeader'>
        <span />
        <Text {...regularTextProps}>
          <div className='productionsHeaderNumber'>
            {this.props.t('Common.UI.hash')}
          </div>
        </Text>
        <Text {...regularTextProps}>
          <div className='productionsHeaderStimulus'>
            {this.props.t('Common.UI.stimulus')}
          </div>
        </Text>
        <Text {...regularTextProps}>
          <div className='productionsHeaderErrors'>
            {this.props.t('Common.UI.errors')}
          </div>
        </Text>
        <Text {...regularTextProps}>
          <div className='productionsHeaderLength'>
            {this.props.t('UI.productionsLength')}
          </div>
        </Text>
        <Text {...regularTextProps}>
          <div className='productionsHeaderPattern'>
            {this.props.t('UI.productionsPattern')}
          </div>
        </Text>
      </ProductionsHeader>
      <div>
        {items.map((production, index) => (
          <ActivityProduction
            className='exerciseProduction'
            key={production.id}
            index={index}
            onPlayClicked={this.onPlayClicked}
            isPlaying={production.id === this.state.playingProductionId}
            production={production}
            exerciseAudio={_.get(activityMediaAudio, ['publicUrls', '0'], null)}
          />
        ))}
      </div>
    </ProductionWrapper>
  );

  renderBody = () => {
    const {
      exercise: { summary, activityMediaAudio, activityMediaVideo },
      productions: { loading, items },
    } = this.props;
    return (
      <Body className='body'>
        {loading || !items ? (
          <SectionLoading className='loadingProductionInfo' />
        ) : (
          <div>
            {this.renderInfo(
              items,
              summary,
              activityMediaAudio,
              activityMediaVideo
            )}
            <InsightsWrapper>
              <EndOfExerciseInsight
                className='exerciseSummary'
                summary={summary}
              />
            </InsightsWrapper>
            {this.renderProductions(items, activityMediaAudio)}
          </div>
        )}
      </Body>
    );
  };

  render() {
    const { open } = this.state;
    return (
      <Wrapper>
        {this.renderHeader()}

        <Collapse isCollapsed={!open}>{open && this.renderBody()}</Collapse>
      </Wrapper>
    );
  }
}

ExerciseItem.propTypes = {
  exercise: PropTypes.shape({
    id: PropTypes.string,
    startTS: PropTypes.number,
    activityCreated: PropTypes.shape({
      at: PropTypes.string,
    }),
    activityMediaAudio: PropTypes.shape({
      publicUrls: PropTypes.arrayOf(PropTypes.string),
    }),
    activityMediaVideo: PropTypes.shape({
      publicUrls: PropTypes.arrayOf(PropTypes.string),
    }),
    summary: PropTypes.shape({
      achievedMasteryLevel: PropTypes.number,
      hasPassed: PropTypes.bool,
      errorDistribution: PropTypes.object,
      insights: PropTypes.arrayOf(
        PropTypes.shape({
          type: PropTypes.string,
          insights: PropTypes.arrayOf(
            PropTypes.shape({
              severity: PropTypes.string,
              error: PropTypes.string,
            })
          ),
        })
      ),
    }),
    entity: PropTypes.shape({
      name: PropTypes.string,
    }),
  }),
  productions: PropTypes.shape({
    loading: PropTypes.bool,
    items: PropTypes.arrayOf(
      PropTypes.shape({
        stimuliDisplayed: PropTypes.arrayOf(
          PropTypes.shape({
            wordIndex: PropTypes.number,
            syllableIndex: PropTypes.number,
          })
        ),
      })
    ),
  }),
  onGetProductions: PropTypes.func,
  dispatchOpenPopup: PropTypes.func,
  exerciseIndex: PropTypes.string,
};

function mapDispatchToProps(dispatch) {
  return {
    dispatchOpenPopup: (popupType, options) =>
      dispatch(openPopUp(popupType, options)),
  };
}
const withConnect = connect(null, mapDispatchToProps);
export default compose(
    withConnect,
    withTranslation()
)(ExerciseItem);
