import {styled} from "@mui/material";
import {
  getIconsByType,
  iconTypes,
  getIconByResourceType,
} from '@services/icons';
import { RESOURCES } from '~/shared/Resources/types';
import { getFlexibleSize } from '~/utils/cssHelpers';

export const PracticeIcon = styled(getIconByResourceType(RESOURCES.PRACTICE))(
  {
    fontSize: 30,
  },
  ({ theme }) => ({
    marginRight: theme.RTL ? -5 : 7,
    marginLeft: theme.RTL ? 7 : -5,
  })
);

export const RestartIcon = styled(getIconsByType(iconTypes.REFRESH))(
  {
    fontSize: 22,
    marginTop: -3,
  },
  ({ theme }) => ({
    [theme.RTL ? 'marginLeft' : 'marginRight']: 5,
  })
);

export const CheckmarkIcon = styled(
  getIconsByType(iconTypes.CIRCLE_CHECKMARK_OVERFLOWED)
)(
  {
    fontSize: 30,
    position: 'absolute',
  },
  ({ theme }) => ({
    [theme.RTL ? 'right' : 'left']: -36,
    color: theme.palette.novotalk.$completedColor,
  })
);

export const Wrapper = styled('div')(
  {
    maxHeight: 'calc(100vh - 55px)',
    overflow: 'auto',
    gridArea: 'PracticeMainPane',
  },
  ({ theme }) => ({
    direction: theme.RTL ? 'rtl' : 'ltr',
    color: theme.palette.novotalk.$textColorDark,
    backgroundColor: theme.palette.novotalk.$backgroundColor,
  })
);

export const MainPartContainer = styled('div')(
  {
    width: '100%',
    padding: '30px 20px 22px 54px',
    '@media(min-width: 1280px)': {
      paddingTop: getFlexibleSize(30, 68),
      paddingRight: getFlexibleSize(20, 136),
      paddingLeft: getFlexibleSize(54, 266),
    },
    '@media(min-width: 1920px)': {
      padding: '68px 136px 22px 266px',
    },
  },
  ({ theme }) => ({
    backgroundColor: theme.palette.novotalk.$backgroundColorPracticeSummary,
  })
);

export const OptionalPartContainer = styled('div')({
  width: '100%',
  padding: '46px 102px 30px 50px',
  '& > div:first-of-type': {
    marginBottom: 24,
  },
  '@media(min-width: 1280px)': {
    paddingTop: getFlexibleSize(46, 38),
    paddingRight: getFlexibleSize(102, 130),
    paddingLeft: getFlexibleSize(50, 264),
  },
  '@media(min-width: 1920px)': {
    padding: '38px 130px 30px 264px',
  },
});

export const HeaderContainer = styled('div')({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  padding: '10px 0',
});

export const StartOverContainer = styled('div')(
  {
    cursor: 'pointer',
  },
  ({ theme }) => ({
    color: theme.palette.novotalk.$textColorHighlited,
  })
);

export const CompletingContainer = styled('div')({
  paddingTop: 28,
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  textAlign: 'center',
  '@media(min-width: 1280px)': {
    paddingTop: getFlexibleSize(28, 23),
  },
  '@media(min-width: 1920px)': {
    paddingTop: 23,
  },
});

export const CompletingTitleContainer = styled('div')({
  marginBottom: 15,
  position: 'relative',
  '@media(min-width: 1280px)': {
    marginBottom: getFlexibleSize(15, 9),
  },
  '@media(min-width: 1920px)': {
    marginBottom: 9,
  },
});

export const ButtonsContainer = styled('div')({
  display: 'flex',
  justifyContent: 'center',
  '& > div.ctaButton, & > div.secondaryButton': {
    width: 180,
    height: 44,
    margin: '24px 6px 8px 6px',
    '@media(min-width: 1280px)': {
      marginTop: getFlexibleSize(24, 30),
      marginBottom: getFlexibleSize(8, 14),
    },
    '@media(min-width: 1920px)': {
      margin: '30px 6px 14px 6px',
    },
  },
});

export const FinishDateContainer = styled('div')({
  '@media(min-width: 1280px)': {
    marginTop: getFlexibleSize(0, -7),
    marginLeft: getFlexibleSize(0, 35),
  },
  '@media(min-width: 1920px)': {
    margin: '-7px 0 0 35px',
  },
});
