import React, { Component } from 'react';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { getItemStyle } from './utils';
import { EditorObjectBody } from './components/EditableBuilderComponent/styles';
import { getEditorComponent } from '../../editorObjects/editorComponents';
import {
  PRACTICE,
  LESSON,
  POLL,
} from '@shared/Resources/types';
import DraggableHeader from './components/EditableBuilderComponent';

class DraggableList extends Component {
  constructor(props) {
    super(props);

    this.onDragEnd = this.onDragEnd.bind(this);
  }

  onDragEnd(result) {
    if (!result.destination || !result.source) {
      return;
    }
    const { items } = this.props;
    const index = result.source.index;
    if (!items || !items[index] || !items[index].payload) {
      return;
    }
    const sourceId = items[index].id
      ? items[index].id
      : items[index].payload._id;
    this.props.onIndexChange(sourceId, result.destination.index);
  }

  hasBody(item) {
    const courseTypes = { PRACTICE, LESSON, POLL };
    const type = item.type;
    return !(courseTypes[type] && item.payload && item.payload.id);
  }
  render() {
    return (
      <DragDropContext
        onDragEnd={this.onDragEnd}
        onDragUpdate={this.onDragUpdate}
      >
        <Droppable droppableId='droppable' type='ITEMS'>
          {(provided, snapshot) => (
            <div ref={provided.innerRef}>
              {this.props.items.map((item, index) => (
                <Draggable
                  key={item.id ? item.id : item.payload._id}
                  draggableId={item.id ? item.id : item.payload._id}
                  index={index}
                >
                  {(provided, snapshot) => (
                    <div
                      ref={provided.innerRef}
                      {...provided.draggableProps}
                      style={getItemStyle(
                        snapshot.isDragging,
                        provided.draggableProps.style
                      )}
                    >
                      <span {...provided.dragHandleProps}>
                        <DraggableHeader
                          hasBody={this.hasBody(item)}
                          onEditResource={this.props.onEditResource}
                          widget={item}
                        />
                      </span>
                      {this.hasBody(item) && (
                        <EditorObjectBody>
                          {getEditorComponent(item.type, { ...item })}
                        </EditorObjectBody>
                      )}
                    </div>
                  )}
                </Draggable>
              ))}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>
    );
  }
}

export default DraggableList;
