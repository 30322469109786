import {styled} from "@mui/material";
import Text from '@components/Text';
import { getResponsiveCSS } from '@utils/cssHelpers';
import DismissButton from '@containers/Admin/shared/DismissButton';
import { getIconsByType, iconTypes } from '@services/icons';
import Slider from 'react-slick';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { isDesktop } from '@shared/constants/media-queries';

export const SubTitleWrapper = styled('div')({
  marginBottom: 10,
});

export const Wrapper = styled('div')({
  display: 'flex',
  alignItems: 'stretch',
  justifyContent: 'stretch',
  height: '100%',
});

export const MainSection = styled('div')(
  {
    padding: '50px 40px 40px 0',
    margin: '0 auto',
    position: 'relative',
    minWidth: 900,
    height: 'calc(100vh - 70px)',
    overflowY: 'auto',
    '&::-webkit-scrollbar': {
      width: 0,
    },
  },
  getResponsiveCSS('width', 900, 1225),
  ({ theme }) => ({
    backgroundColor: theme.palette.novotalk.$backgroundColor,
  })
);

export const PostsSection = styled('div')(
  {
    padding: '50px 0 40px',
    maxHeight: '100%',
    display: 'flex',
    flexDirection: 'column',
    height: 'calc(100vh - 70px)',
    overflowY: 'auto',
  },
  getResponsiveCSS('width', 290, 375),
  getResponsiveCSS('paddingLeft', 20, 24),
  getResponsiveCSS('paddingRight', 20, 24),
  ({ theme }) => ({
    backgroundColor: theme.palette.novotalk.$backgroundColorMedium,
  })
);

export const ActivityHeader = styled('div')(
  {
    display: 'grid',
    gridTemplateColumns: 'repeat(6, 1fr)',
    columnGap: 30,
    marginBottom: 40,
  },
  getResponsiveCSS('paddingBottom', 15, 25),
  ({ theme }) => ({
    borderBottom: `1px solid ${theme.palette.novotalk.$textColorLightGray}`,
  })
);

export const HeaderTitle = styled('div')(
  {
    gridColumn: '1/7',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  getResponsiveCSS('marginBottom', 36, 56)
);

export const StyledDismissButton = styled(DismissButton)({
  height: 32,
  width: 106,
  minWidth: 106,
});

export const HeaderSectionTitleWrapper = styled('div')({
  marginBottom: 4,
});

export const TextWithSpaces = styled(Text)({
  whiteSpace: 'pre',
});

export const UserInfoContainer = styled('div')({
  marginTop: 6,
});

export const UserAvatar = styled('img')(
  {
    borderRadius: '50%',
    marginRight: 10,
    objectFit: 'cover',
  },
  getResponsiveCSS('height', 24, 40),
  getResponsiveCSS('width', 24, 40)
);

export const SectionWrapper = styled('div')({
  marginBottom: 40,
});

export const SliderView = styled(Slider)({
  display: 'flex',
  justifyContent: 'space-around',
  marginLeft: 20,
    '& > .slick-arrow': {
        width: 30,
        height: 30,
    },
    '& > .slick-prev': {
      left: -15,
    },
    '& > .slick-next': {
        right: -15,
    },
    '& > .slick-arrow::after, .slick-arrow::before': {
        color: 'grey',
        fontSize: 30,
    }
});

export const StyledArrowRightIcon = styled(
  getIconsByType(iconTypes.FILLED_ARROW_RIGHT)
)({
  position: 'relative',
  width: 80,
  height: 80,
  margin: 'auto',
},
({ theme }) => ({
  color: theme.palette.novotalk.$buttonColorDisabled,
    fill: theme.palette.novotalk.$buttonColorDisabled,
    opacity: 1,
}));

export const StyledArrowLeftIcon = styled(
  getIconsByType(iconTypes.FILLED_ARROW_LEFT)
)({
  position: 'relative',
  width: 80,
  height: 80,
  margin: 'auto',
},
({ theme }) => ({
  color: theme.palette.novotalk.$buttonColorDisabled,
    fill: theme.palette.novotalk.$buttonColorDisabled,
    opacity: 1,
}));

export const SliderItem = styled('div')({
  padding: '0 20px',
  maxWidth: isDesktop() && 450,
});
