import React from 'react';
const icon = () => (
  <svg width="0.8em" height="0.8em" viewBox="0 0 20 20" >
    <g id="Exercise-Editor" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g id="Exercise---Initial-State" transform="translate(-257.000000, -849.000000)" fillRule="nonzero">
        <g id="Group-14" transform="translate(257.000000, 849.000000)">
          <rect id="Combined-Shape" fill="none" x="0" y="0" width="20" height="20" rx="2"></rect>
          <path d="M4,14 C5.73868819,13.6459131 6.78953565,13.145067 7.15254237,12.4974619 C8.88157566,9.41286126 8.87828463,6 10,6 C11.1444074,6 10.890121,9.28679748 12.6666667,12.4974619 C13.0087685,13.1157259 14.1198796,13.6165719 16,14" id="Line-5" stroke="currentColor" strokeWidth="2" strokeLinecap="square"></path>
        </g>
      </g>
    </g>
  </svg>);
export default icon;
