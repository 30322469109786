import React from 'react';
import PropTypes from 'prop-types';
import Select from 'react-select';

export const Tags = ({
  options,
  value,
  handleTagsChange,
  placeholder
}) => (
  <Select
    isMulti
    placeholder={placeholder}
    value={value}
    onChange={handleTagsChange}
    options={options}
  />
);

Tags.propTypes = {
  placeholder: PropTypes.string.isRequired,
  options: PropTypes.array.isRequired,
  value: PropTypes.array.isRequired,
  handleTagsChange: PropTypes.func.isRequired,
};
