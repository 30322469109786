import { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'redux';
import _ from 'lodash';
import ListHeader from './components/ListHeader';
import MessagesList from './components/MessagesList';
import UserSearch from './components/UserSearch';
import ConversationHeader from './components/ConversationHeader';
import ConversationArea from './components/ConversationArea';
import MessageArea from './components/MessageArea';
import { Wrapper } from './styles';
import { getConversationsAction, getFullConversationAction, getUsersAction, startNewConversationAction,
  setConversationPartnerAction, sendMessageAction, closeConversationAction, markPostsAsSeenAction,
  setFullConversationAction } from './actions';

export class Messaging extends Component {
  componentDidMount() {
    const {
      conversations,
      getConversations,
      getUsers,
      currentConversation,
      isNewConversationStarted,
      getFullConversation,
      history,
    } = this.props;

    if (_.isEmpty(conversations)) {
      getConversations();
    } else if (_.isEmpty(currentConversation) && !isNewConversationStarted) {
      const indexSelectedUser = history.location.state?.index;
      this.chooseConversation(conversations[indexSelectedUser]);
    } else if (!_.isEmpty(currentConversation) && !isNewConversationStarted) {
      getFullConversation(currentConversation.id);
    }

    getUsers();
  }

  componentDidUpdate(prevProps) {
    const { conversations, currentConversation, fetchingFullConversation, isNewConversationStarted } = this.props;
    if (!fetchingFullConversation && _.isEmpty(currentConversation) && !isNewConversationStarted) {
      this.chooseConversation(conversations[0]);
    }
    if (!fetchingFullConversation && prevProps.fetchingFullConversation) {
      this.setPostsAsSeen(currentConversation);
    }
  }

  componentWillUnmount() {
    this.props.setFullConversation({});
  }

  setPostsAsSeen(conversation) {
    const { markPostsAsSeen } = this.props;
    const selfUserId = _.find(conversation.Users, { self: true }).id;
    const unseenPostIds = conversation.Thread.Posts.filter((post) => (!post.seenBy.includes(selfUserId))).map((post) => post.id);
    if (unseenPostIds.length > 0) markPostsAsSeen(unseenPostIds);
  }

  chooseConversation = (conversation) => {
    if (!conversation) return;
    const { getFullConversation, setConversationPartner } = this.props;
    getFullConversation(conversation.id);
    setConversationPartner(conversation.Users.find((user) => !user.self));
  }

  handleUserSelect = (conversationPartner) => {
    const { conversations, setConversationPartner } = this.props;
    const prevConversations = conversations.filter(({ Users }) => Users.some((user) => user.id === conversationPartner.id));
    if (prevConversations.length) {
      const Open = prevConversations.find(({ Thread }) => !Thread.closed);
      if (Open) {
        this.chooseConversation(Open);
        return;
      }
    }
    setConversationPartner(conversationPartner);
  }

  handleStartConversation = () => {
    const { isNewConversationStarted, startNewConversation } = this.props;
    if (!isNewConversationStarted) {
      startNewConversation();
    }
  }

  handleMessageSending = (textMessage) => {
    const { sendMessage, isNewConversationStarted,
      conversationPartner: { id }, currentConversation } = this.props;
    const threadId = isNewConversationStarted ? null : currentConversation.Thread.id;
    const conversationId = isNewConversationStarted ? null : currentConversation.id;
    sendMessage(id, threadId, textMessage, conversationId);
  }

  handleEndConversation = () => {
    const { currentConversation, closeConversation } = this.props;
    closeConversation(currentConversation.id, currentConversation.Thread.id);
  }

  render() {
    const { conversations, currentConversation, users, isNewConversationStarted, fetchingConversations,
      conversationPartner, canEndConversation, setConversationPartner } = this.props;
    if (fetchingConversations) {
      return null;
    }

    const renderConversation = () => {
      if(!isNewConversationStarted && _.isEmpty(conversationPartner)) {
        return <></>
      }
      if(isNewConversationStarted && _.isEmpty(conversationPartner)) {
        return <UserSearch users={users} selectUser={this.handleUserSelect} />
      }
      return <ConversationHeader
          currentConversation={currentConversation}
          isNewConversationStarted={isNewConversationStarted}
          selectedUser={conversationPartner}
          canEndConversation={canEndConversation}
          onEndConversation={this.handleEndConversation}
          removeConversationPartner={() => setConversationPartner({})}
      />
    }

    return (
      <Wrapper>
        <ListHeader startNewConversation={this.handleStartConversation} />
        <MessagesList
          conversations={conversations}
          currentConversation={currentConversation}
          isNewConversationStarted={isNewConversationStarted}
          chooseConversation={this.chooseConversation}
        />
        {renderConversation()}
        {!isNewConversationStarted && !_.isEmpty(conversationPartner) &&
          <ConversationArea currentConversation={currentConversation} />
        }
        {!_.isEmpty(conversationPartner) && <MessageArea
          currentConversation={currentConversation}
          conversationPartner={conversationPartner}
          sendMessage={this.handleMessageSending}
        />}
      </Wrapper>
    );
  }
}

Messaging.propTypes = {
  conversations: PropTypes.array,
  currentConversation: PropTypes.object,
  users: PropTypes.array,
  getConversations: PropTypes.func,
  getFullConversation: PropTypes.func,
  getUsers: PropTypes.func,
  isNewConversationStarted: PropTypes.bool,
  fetchingFullConversation: PropTypes.bool,
  fetchingConversations: PropTypes.bool,
  startNewConversation: PropTypes.func,
  conversationPartner: PropTypes.object,
  setConversationPartner: PropTypes.func,
  canEndConversation: PropTypes.bool,
  sendMessage: PropTypes.func,
  closeConversation: PropTypes.func,
  markPostsAsSeen: PropTypes.func,
  setFullConversation: PropTypes.func,
};

export const mapStateToProps = (state) => {
  const { conversations, currentConversation, users, isNewConversationStarted,
    fetchingFullConversation, fetchingConversations, conversationPartner } = state.getIn(['messaging']).toJS();
  const canEndConversation = state.getIn(['app', 'authentication', 'user', 'experimentalFeatures']);
  return { conversations,
    currentConversation,
    users,
    isNewConversationStarted,
    fetchingFullConversation,
    fetchingConversations,
    conversationPartner,
    canEndConversation,
  };
};

export const mapDispatchToProps = (dispatch) => ({
  getConversations: () => dispatch(getConversationsAction()),
  getFullConversation: (id) => dispatch(getFullConversationAction(id)),
  getUsers: () => dispatch(getUsersAction()),
  startNewConversation: () => dispatch(startNewConversationAction()),
  setConversationPartner: (user) => dispatch(setConversationPartnerAction(user)),
  sendMessage: (participantIds, threadId, textMessage, conversationId) =>
    dispatch(sendMessageAction(participantIds, threadId, textMessage, conversationId)),
  closeConversation: (conversationId, threadId) => dispatch(closeConversationAction(conversationId, threadId)),
  markPostsAsSeen: (ids) => dispatch(markPostsAsSeenAction(ids)),
  setFullConversation: (conversation) => dispatch(setFullConversationAction(conversation)),
});

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default compose(
  withConnect,
)(Messaging);
