import React from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { withRouter } from 'react-router-dom';
import Container from '@components/Container';
import CTAButton from '@components/CTAButton';
import Text, { TEXT_SIZE, TEXT_COLOR } from '@components/Text';
import { ROUTES } from '@shared/constants';
import {
  ScreenWrapper,
  StyledHeader,
  ContentWrapper,
  StyledImage,
  StyledCheckmarkIcon,
} from './styles';
import Image from './course_novotalk.svg';
import {useTranslation} from "react-i18next";

export const PaymentSuccess = (props) => {
  const {history} = props;
  const {t} = useTranslation()
  return (
    <ScreenWrapper>
      <StyledHeader>
        <StyledCheckmarkIcon />
        <Text size={TEXT_SIZE.T1} textColor={TEXT_COLOR.DARK_BLUE}>
          {t("Billing.Informative.paymentSuccessful")}
        </Text>
      </StyledHeader>
      <ContentWrapper>
        <Text size={TEXT_SIZE.H4} textColor={TEXT_COLOR.DARK_BLUE}>
          {t("Billing.Agreement.extended")}
        </Text>
        <Container column margin='40px 0'>
          <StyledImage src={Image} />
          <Text size={TEXT_SIZE.T2} textColor={TEXT_COLOR.DARK_BLUE}>
            {t("Billing.Informative.flow")}
          </Text>
        </Container>
        <Container margin='0 0 20px 0'>
          <Text size={TEXT_SIZE.T1} textColor={TEXT_COLOR.ACTIVE} weight='bold'>
            {t("Informative.Confirming.start")}
          </Text>
        </Container>
        <CTAButton action onClick={() => history.push(ROUTES.COURSE_PAGE)}>
          {t("Actions.Navigation.course")}
        </CTAButton>
      </ContentWrapper>
    </ScreenWrapper>
  );
};

PaymentSuccess.propTypes = {
  history: PropTypes.object,
};

export default compose(withRouter)(PaymentSuccess);
