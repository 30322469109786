const regExpForError = /([a-z])([A-Z])/g;
/**
 * Convert camel case to default case with uppercase for first letters
 * @param {error} string - error name
 * @returns {string} - formatted word
 * @example formatTypeError('nonGentleOnset')
 * @returns 'Non Gentle Onset'
 */
const formatTypeError = (error) => error.charAt(0).toUpperCase() + error.slice(1).replace(regExpForError, '$1 $2');

/**
 * Format and sort error
 * @param {errors} object - object of errors
 * @returns {Array<{ label: string, value: number }>} - formatted error
 * @example formatErrors({ "notLoudProduction": "55.80" })
 * @returns [{label: 'Non Loud Production', value: 56}]
 */
export const formatErrors = (errors) => Object.entries(errors)
  .map(([key, value]) => ({ label: formatTypeError(key), value: Math.round(value) }))
  .sort((a, b) => b.value - a.value);

/**
 * Calculate percent for other errors
 * @param {errors} object - object of errors
 * @returns {number} - percent
 * @example calculateOtherErrorsPercent([{ label: 'Non Gentle Onset', value: 60 }])
 * @returns 60
 */
export const calculateOtherErrorsPercent = (errors) => errors
  .slice(3)
  .reduce(( sum, { value }) => sum + value, 0);

/**
 * Format visible percents
 * @param {errors} object - object of errors
 * @returns {Array<number>} - array percent
 * @example formatVisiblePercents([{label: 'Non Gentle Onset', value: 60}, {label: 'Non Loud Onset', value: 20}])
 * @returns [60, 20]
 */
export const formatVisiblePercents = (errors) => [
  ...errors.slice(0, 3).map(({ value }) => value),
  errors.slice(3, errors.length).reduce(( sum, { value }) => sum + value, 0)
];

/**
 * Format visible errors
 * @param {errors} Array - object of errors
 * @returns {Array<{ label: string, value: number }>} - object of formatted errors
 * @example formatVisibleErrors([{label: 'Non Gentle Onset', value: 100}, {label: 'Non Loud Onset', value: 0}])
 * @returns [{label: 'Non Gentle Onset', value: 100}]
 */
export const formatVisibleErrors = (errors) => errors
  .slice(0, 3)
  .reduce((errors, error) => error.value !== 0 ? [...errors, error] : errors, []);

/**
 * Format practice type
 * @param {type} string - practice type
 * @returns {string} - formatted practice type
 * @example formatPracticeType("LOUD_VOICE")
 * @returns "Loud voice"
 */
export const formatPracticeType = (type) => type && (type?.length && type[0].toUpperCase() + type.slice(1).toLowerCase()).split("_").join(" ")
