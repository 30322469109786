import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Select from 'react-select';
import Text from '@components/Text';
import { selectStyles } from './styles';
import { withTranslation } from 'react-i18next';
import { compose } from 'redux';

class UsersFilter extends Component {
  constructor(props) {
    super(props);

    this.state = {
      selectedUser: '',
      focused: false,
    };
  }

  handleChange = (selectedUser) => {
    this.setState({ selectedUser });
    // selectedOption can be null when the `x` (close) button is clicked
    if (selectedUser) {
      this.props.setUsersFilter(selectedUser.value);
    } else {
      this.props.setUsersFilter('');
    }
  };
  render() {
    const { selectedUser, focused } = this.state;
    const placeholder = focused ? null : (
      <Text id='selectPlaceholder'>
        {this.props.t('UI.allUsers')}
      </Text>
    );

    return (
      <div>
        <Select
          id='usersFilterSelect'
          value={selectedUser}
          onChange={this.handleChange}
          onFocus={() => this.setState({ focused: true })}
          onBlur={() => this.setState({ focused: false })}
          options={this.props.usersOptions}
          placeholder={placeholder}
          styles={selectStyles}
          isClearable
        />
      </div>
    );
  }
}

UsersFilter.propTypes = {
  usersOptions: PropTypes.arrayOf(PropTypes.object),
  setUsersFilter: PropTypes.func,
};

export default compose(
    withTranslation()
)(UsersFilter)
