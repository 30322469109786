import {styled} from "@mui/material";
import { getIconByResourceType } from '@services/icons';

export const Wrapper = styled('div')({
  marginTop: 30,
});

export const Title = styled('div')({
  marginBottom: 12,
});

const getBorderSvg = (color) => {
  const strokeColor = color[0] === '#' ? `%23${color.slice(1)}` : color;
  return `
    <svg width='100%' height='100%' xmlns='http://www.w3.org/2000/svg'>
      <rect width='100%' height='100%' rx='8' ry='8' style='fill: none; stroke: ${strokeColor}; stroke-width: 1; stroke-dasharray: 4 3'/>
    </svg>
  `;
};

export const WidgetsContainer = styled('div')(
  {
    height: 140,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: 8,
  },
  ({ theme }) => ({
    backgroundColor: theme.palette.novotalk.$widgetBarBackgroundColor,
    backgroundImage: `url("data:image/svg+xml;utf8,${getBorderSvg(
      theme.palette.novotalk.$textColorLightGray
    )}")`,
  })
);

export const WidgetContainer = styled('button')(
  {
    margin: '0 50px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    cursor: 'pointer',
    outline: 'none',
  },
  ({ theme }) => ({
    color: theme.palette.novotalk.$widgetBarItemColor,
    '&:hover': {
      color: theme.palette.novotalk.$widgetBarItemColorHover,
    },
    '&:active': {
      color: theme.palette.novotalk.$widgetBarItemColorActive,
    },
  })
);

export const getStyledResourceIcon = (type) =>
  styled(getIconByResourceType(type))({
    fontSize: 32,
    marginBottom: 3,
  });
