import {styled} from "@mui/material";
import {pxToRem} from "@services/theme/utils";
import {getIconsByType, iconTypes} from "@services/icons";

const iconStyles = {
    gridArea: 'EXPAND',
    marginLeft: 5,
    fontSize: 24,
    cursor: 'pointer',
};

export const ListItem = styled('div',)({
        display: 'grid',
        gridTemplate: `
    'EXPAND ITEM'
    'SUB_LIST SUB_LIST'
  `,
        alignItems: 'center',
    },
    ({theme}) => ({
        gridTemplateColumns: '34px auto',
        borderBottom: `1px solid ${theme.palette.novotalk.$resourcesTableBorderColor}`,
    })
);

export const SubList = styled('div',)({
        gridArea: 'SUB_LIST',
        marginTop: pxToRem(10),
        minHeight: 50,
        borderRadius: pxToRem(4),
        padding: `20px 0 0 60px`,
        '& > div': {
            marginBottom: pxToRem(24),
        },
        '& > div:last-of-type': {
            marginBottom: 0,
        },
        '& > div:last-of-type > div:last-of-type > div': {
            borderBottom: 'none',
        },
    },
);

interface IIcon {
    highlighted?: boolean
}

export const StyledArrowRightIcon = styled(
    getIconsByType(iconTypes.FILLED_ARROW_RIGHT)
)<IIcon>(iconStyles, ({ theme, highlighted }) => ({
    color: highlighted ? theme.palette.novotalk.$activeColor : theme.palette.novotalk.$textColorLightGray,
}));

export const StyledArrowDownIcon = styled(
    getIconsByType(iconTypes.FILLED_ARROW_DOWN)
)<IIcon>(iconStyles, ({ theme, highlighted }) => ({
    color: highlighted ? theme.palette.novotalk.$activeColor : theme.palette.novotalk.$textColorLightGray,
}));