import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { openPopUp } from '@containers/App/containers/PopUp/actions';
import withResources from '@containers/Admin/shared/ResourcesWrapper';
import POPUP_TYPES from '@containers/App/containers/PopUp/types';
import { OptionsWrapper, StyledCTAButton } from './styles';
import {withTranslation} from "react-i18next";

class EntitySelectionOptions extends React.Component {
  selectExisting = () => {
    this.props.dispatchOpenPopup(POPUP_TYPES.SELECT_RESOURCE, {
      resourceType: this.props.entityType,
      onItemSelected: this.props.onItemSelect,
      hideCloseIcon: true,
    });
  };

  render = () => (
    <OptionsWrapper>
      <StyledCTAButton onClick={this.selectExisting} className='selectExisting'>
        {this.props.t('Actions.Multiple.selectExisting')}
      </StyledCTAButton>
    </OptionsWrapper>
  );
}

EntitySelectionOptions.propTypes = {
  entityType: PropTypes.string,
  onItemSelect: PropTypes.func,
  dispatchOpenPopup: PropTypes.func,
};

const mapDispatchToProps = (dispatch) => ({
  dispatchOpenPopup: (type, params) => dispatch(openPopUp(type, params)),
});

const withConnect = connect(null, mapDispatchToProps);

export default compose(
    withConnect,
    withResources,
    withTranslation()
)(EntitySelectionOptions);
