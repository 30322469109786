import { takeLatest, put, all, fork, join } from 'redux-saga/effects';
import { editMedia } from '@services/mediaService';
import {
  Types,
  putError,
  editStatus,
  putEditStatus,
} from './actions';

export function* callSendMediaData(action) {
  try {
    yield put(putEditStatus(editStatus.SENDING));
    const res = yield fork(editMedia, action.payload);
    const result = yield join(res);
    yield put(putEditStatus(editStatus.SENT, result));
  } catch (error) {
    yield put(putError(error));
  }
}

function* watchCallEditMediaData() {
  yield takeLatest(Types.EDIT_MEDIA_DATA, callSendMediaData);
}

export default function* mediaEditSaga() {
  yield all([fork(watchCallEditMediaData)]);
}
