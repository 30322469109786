import {styled} from "@mui/material";
import { getIconsByType, iconTypes } from '@services/icons';
import ToggleButton from '@components/ToggleButton';

const iconSize = 26;

const selectStyles = {
  fontSize: iconSize + 8,
  zIndex: 2,
  padding: 4,
  borderRadius: (iconSize + 8) / 2,
};

export const IconWrapper = styled('span')(
  {
    display: 'inline-block',
    width: iconSize + 8,
    height: iconSize + 8,
    verticalAlign: 2,
    position: 'relative',
  },
  ({ theme, isDisabled }) => {
    if (isDisabled) {
      return {
        '&:before': {
          display: 'block',
          content: '""',
          position: 'absolute',
          left: `calc(50% - ${(iconSize - 6) / 2}px)`,
          top: `calc(50% - ${(iconSize - 6) / 2}px)`,
          width: iconSize - 6,
          height: iconSize - 6,
          borderRadius: (iconSize - 6) / 2,
          backgroundColor: theme.palette.novotalk.$selectBackgroundColorDisabled,
        },
      };
    }
    return {};
  }
);

export const RadioOffIcon = styled(getIconsByType(iconTypes.RADIO_OFF), {
  shouldForwardProp: (prop) => ['RTL', 'isDisabled'].indexOf(prop) === -1,
})(selectStyles, ({ theme, isDisabled }) => {
  if (isDisabled) {
    return {
      color: theme.palette.novotalk.$selectStandartColor,
    };
  }
  return {
    color: theme.palette.novotalk.$selectStandartColor,
    '&:hover': {
      backgroundColor: theme.palette.novotalk.$selectStandartBgColorHover,
    },
    '&:active': {
      color: theme.palette.novotalk.$selectEnabledColor,
      backgroundColor: theme.palette.novotalk.$selectStandartBgColorActive,
    },
  };
});

export const RadioOnIcon = styled(getIconsByType(iconTypes.RADIO_ON), {
  shouldForwardProp: (prop) => ['RTL', 'isDisabled'].indexOf(prop) === -1,
})(selectStyles, ({ theme, isDisabled }) => {
  if (isDisabled) {
    return {
      color: theme.palette.novotalk.$selectStandartColor,
    };
  }
  return {
    color: theme.palette.novotalk.$selectEnabledColor,
    '&:hover': {
      backgroundColor: theme.palette.novotalk.$selectEnabledBgColorHover,
    },
    '&:active': {
      backgroundColor: theme.palette.novotalk.$selectEnabledBgColorActive,
    },
  };
});

export const Wrapper = styled('div')(
  {
    minWidth: '800px',
    width: '100%',
    position: 'relative',
  },
  ({ theme }) => ({
    margin: theme.RTL ? '10px 30px 30px 0' : '10px 0 30px 30px',
  })
);

export const AnswerWrapper = styled('div')(
  {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  ({ isDisabled }) => isDisabled && { pointerEvents: 'none' }
);

export const SelectWrapper = styled('div')(
  {
    display: 'flex',
  },
  ({ theme }) => ({
    [theme.RTL ? 'marginRight' : 'marginLeft']: -8,
    flexDirection: 'inherit',
  })
);

export const StyledToggleButton = styled(ToggleButton)(
  {
    margin: '10px 40px',
  },
  ({ theme }) => ({
    [theme.RTL ? 'marginRight' : 'marginLeft']: '100px',
  })
);

export const RadioButtonsWrapper = styled('div')(
  {
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%',
  },
  ({ length }) => ({
    maxWidth: length * 60,
  })
);
