import { fromJS } from 'immutable';
import { types } from './actions';

export const initialState = fromJS({
  errors: null,
});

function errorHandlingReducer(state = initialState, action) {
  switch (action.type) {
    case types.UNAUTHORIZED_CONNECTION_ERROR : {
      const newState = state.set('errors', action.reason);
      return newState;
    }
    default:
      return state;
  }
}

export default errorHandlingReducer;
