export const EmptyMirror = () => (
  <svg width="200" height="150" viewBox="0 0 200 150" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect width="200" height="150" fill="black"/>
    <path opacity="0.4" d="M57.702 107.333C61.6065 105.556 72.5665 101.932 77.156 99.4018C79.622 98.5129 84.006 93.6582 84.006 93.6582C84.006 93.6582 85.0335 90.1026 84.828 87.6411C84.3485 85.795 80.718 75.8804 80.718 75.8804C80.033 76.701 79.419 76.7718 78.8 76.154C77.9095 74.1027 73.183 63.983 74.416 60.5642C74.7585 58.513 77.156 58.9232 77.156 58.9232C74.0735 49.6925 74.964 40.5984 74.964 40.5984C74.964 40.5984 74.984 38.5893 76.608 36.4959C75.6545 36.6701 74.142 37.5899 74.142 37.5899C74.8443 34.3825 78.252 31.8463 78.252 31.8463C78.2926 31.761 77.8901 31.4486 75.512 32.1198C75.923 31.3595 80.081 29.7529 83.732 29.1113C84.7697 26.551 87.2343 24.3529 89.76 23.0942C89.112 23.9511 88.938 24.7352 88.938 24.7352C88.938 24.7352 93.2253 22.5231 100.172 23.0942C94.7606 23.6412 93.5961 24.7352 93.5961 24.7352C93.5961 24.7352 114.009 21.7951 122.092 34.8549C124.147 37.4532 128.874 50.1027 124.832 59.1967C125.86 58.7865 126.065 57.9659 127.298 59.4702C127.367 63.5044 124.147 76.701 122.092 76.4275C121.339 76.701 120.996 75.6069 120.996 75.6069C118.051 83.6753 117.16 86.0001 117.16 86.0001C116.612 87.1625 116.133 91.0599 116.886 92.0172C117.229 93.2479 123.188 98.3078 123.188 98.3078L157.986 112.803C168.124 122.376 164.288 149.726 164.288 149.726L36.0559 150C36.0559 150 35.2339 123.538 39.8919 117.18C41.3304 115.47 45.8515 111.778 57.702 107.333Z" fill="url(#paint0_linear_949_5)"/>
      <defs>
      <linearGradient id="paint0_linear_949_5" x1="36" y1="23" x2="36" y2="150" gradientUnits="userSpaceOnUse">
        <stop offset="0.6666" stop-color="white"/>
        <stop offset="1" stop-color="white" stop-opacity="0"/>
      </linearGradient>
    </defs>
  </svg>
);