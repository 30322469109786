import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import {
  MenuItemContainer, MenuItem, ItemText,
  StyledChevronRightIcon, StyledAddIcon,
} from './styles';

export class ListItem extends React.Component {
  onClick = (event) => {
    event.nativeEvent.stopImmediatePropagation();
    const {
      onListItemDrilldown, onListItemSelected, itemValue,
      itemName,
    } = this.props;
    if (_.isObject(itemValue)) {
      onListItemDrilldown(itemName);
    } else {
      onListItemSelected(itemValue);
    }
  }

  render() {
    const { itemName, itemValue } = this.props;
    const isGroup = _.isObject(itemValue);

    return (
      <MenuItemContainer className="list_item_wrap">
        <MenuItem onClick={this.onClick}>
          <ItemText>{itemName}</ItemText>
          { isGroup ? <StyledChevronRightIcon /> : <StyledAddIcon /> }
        </MenuItem>
      </MenuItemContainer>
    );
  }
}

ListItem.defaultProps = {
  storybook: false,
};

ListItem.propTypes = {
  itemName: PropTypes.string.isRequired,
  itemValue: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object,
  ]),
  onListItemSelected: PropTypes.func.isRequired,
  onListItemDrilldown: PropTypes.func.isRequired,
};

export default ListItem;
