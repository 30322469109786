import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import _ from 'lodash';
import differenceInSeconds from 'date-fns/difference_in_seconds';
import Text, { TEXT_COLOR, TEXT_SIZE } from '@components/Text';
import CTAButton from '@components/CTAButton';
import {
  Wrapper,
  Title,
  StyledPollIcon,
  DescriptionWrapper,
  SaveWrapper,
  PollBodyWrapper,
  CurrentUserData,
  Avatar,
} from './styles';
import getComponent from './pollComponents';
import { openPopUp as openPopUpAction } from '@containers/App/containers/PopUp/actions';
import POPUP_TYPES from '@containers/App/containers/PopUp/types';
import { ROUTES } from '@shared/constants';
import { TYPES } from '../../types';
import { savePoll as savePollAction } from './actions';
import { getCourses } from '@containers/User/containers/CourseRouter/actions';
import ChatService from '@services/chatService';
import {withTranslation} from "react-i18next";

export class PollClient extends Component {
  constructor(props) {
    super(props);
    this.questionIndex = 0;
    this.state = {
      validateOnChange: false,
      isResetting: true,
    };
  }

  setContext(context) {
    this.setState({ context });
  }

  componentDidMount() {
    const { isSummaryPoll } = this.props;
    if (isSummaryPoll) this.generatePollContext();
    this.resetPoll();
  }

  getEmptyQuestions = () => {
    const questions = {};
    let questionIndex = 1;
    this.props.poll.children.forEach((component) => {
      if (component.type === TYPES.QUESTION) {
        questions[component.id] = {
          index: questionIndex,
          answer: null,
        };
        questionIndex += 1;
      }
    });
    return questions;
  };

  getQuestionIndex = (questionId) => this.state.questions[questionId].index;

  updateAnswer = (questionId, answer) => {
    const { questions } = this.state;
    const { getAnswers, isSummaryPoll, user: { participantId } } = this.props;
    const newState = { questions };

    questions[questionId].answer = answer;
    if (isSummaryPoll) getAnswers(newState, participantId);
    this.setState(newState);
  }

  handleSave = () => {
    const { questions, startTime } = this.state;
    const { savePoll, savePollOverride, pollSubject, t } = this.props;

    const answersToSave = [];
    const timeSpent = differenceInSeconds(new Date(), startTime);
    const subject = pollSubject.id && pollSubject.type ? pollSubject : null;
    const pollToSave = {
      context: this.generatePollContext(),
      answers: answersToSave,
      subject,
      timeSpent,
    };

    _.forEach(questions, (question, id) => {
      answersToSave.push({
        id,
        payload: question.answer,
      });
    });

    if (savePollOverride) {
      savePollOverride(pollToSave);
    } else {
      savePoll(pollToSave);
    }

    this.props.openPopUp({
      titleText: t('Informative.Positive.questionnaire'),
      bodyText: t('Informative.Positive.questionnaireSaved'),
      buttonText: t('Informative.Positive.questionnaireSaved'),
      onClose: () => t('Actions.Navigation.backToOverview'),
      hideCloseIcon: true,
      disableBackdrop: true,
    });
  };

  generatePollContext() {
    const {
      poll: {
        id,
        version,
      },
      user: {
        participantId,
      },
      activityContext,
      isSummaryPoll,
      chatId,
    } = this.props;
    const context = activityContext ? _.clone(activityContext) : {};

    context.POLL = { id, version };
    if (isSummaryPoll) {
      context.ACTIVITY_CHAT = chatId || ChatService.chatId;
      context.USER = participantId;
    }
    this.setContext(context);
    return context;
  }

  redirectToOverview = async () => {
    const { history, dispatchGetCourses } = this.props;
    await history.push(ROUTES.COURSE_PAGE);
    await dispatchGetCourses();
  };

  resetPoll = () => {
    this.setState({ isResetting: true }, () => {
      const questions = this.getEmptyQuestions();
      const startTime = new Date();
      this.setState({
        isResetting: false,
        questions,
        startTime,
      });
    });
  };

  renderPollComponents({ children, activity = {} }) {
    const { isSummaryPoll } = this.props;
    if (this.state.isResetting) return null;
    const isDisabled = !_.isEmpty(activity);
    const userAnswers = activity.userAnswers || {};
    const required = !!isSummaryPoll;
    return children.map((component) =>
      getComponent(
        component,
        () => this.getQuestionIndex(component.id),
        (answer) => this.updateAnswer(component.id, answer),
        this.state.validateOnChange,
        isDisabled,
        userAnswers[component.id],
        false,
        required,
      )
    );
  }

  isDisabled = () => {
    const { questions } = this.state;
    let hasEmptyAnswers = false;
    _.forEach(questions, (question) => {
      if (!question.answer) hasEmptyAnswers = true;
    });
    return hasEmptyAnswers;
  };

  renderSaveButton() {
    const { activity } = this.props.poll;
    return activity ? null : (
      <SaveWrapper>
        <CTAButton
          onClick={this.handleSave}
          disabled={this.isDisabled()}
        >
          <Text size='T2' textColor={TEXT_COLOR.REVERSED} weight='900'>
            {this.props.t('Actions.Instance.save')}
          </Text>
        </CTAButton>
      </SaveWrapper>
    );
  }

  renderTitle(poll) {
    return (
      <>
        <Title>
          <StyledPollIcon />
          <Text
            size='T0'
            textTransform='capitalize'
            textColor={TEXT_COLOR.DARK_BLUE}
            weight='900'
          >
            {poll.name}
          </Text>
        </Title>
        <DescriptionWrapper>
          <Text size='T3' textColor={TEXT_COLOR.DISABLED} weight='900'>
            {poll.description}
          </Text>
        </DescriptionWrapper>
      </>
    );
  }

  renderUser = () => {
    const { user: { name, avatar } } = this.props;
    return (
      <CurrentUserData>
        <Avatar src={avatar} />
        <Text size={TEXT_SIZE.H4} textColor={TEXT_COLOR.DARK_BLUE}>{name}</Text>
      </CurrentUserData>
    )
  }

  render() {
    const {
      poll,
      hideTitle,
      savePollOverride,
      isSummaryPoll,
    } = this.props;
    return (
      <Wrapper>
        {!hideTitle && this.renderTitle(poll)}
        <PollBodyWrapper>
          {isSummaryPoll && this.renderUser()}
          {this.renderPollComponents(poll)}
          {!isSummaryPoll && this.renderSaveButton(savePollOverride)}
        </PollBodyWrapper>
      </Wrapper>
    );
  }
}

PollClient.propTypes = {
  poll: PropTypes.object,
  activityContext: PropTypes.object,
  openPopUp: PropTypes.func,
  savePoll: PropTypes.func,
  history: PropTypes.object,
  hideTitle: PropTypes.bool,
  savePollOverride: PropTypes.func,
  pollSubject: PropTypes.object,
  isSummaryPoll: PropTypes.bool,
  getQuestions: PropTypes.func,
  user: PropTypes.object,
};

export const mapStateToProps = (state) => {
  const res = {};
  if (state.getIn(['currentPoll'])) {
    res['poll'] = state.getIn(['currentPoll']).toJS().poll;
    res['pollSubject'] = state.getIn(['currentPoll']).toJS().pollSubject;
  }
  if (state.getIn(['course'])) {
    res['activityContext'] = state.getIn(['course']).toJS().activityContext;
  }

  return { ...res };
};

export function mapDispatchToProps(dispatch) {
  return {
    openPopUp: (props) => dispatch(openPopUpAction(POPUP_TYPES.ALERT, props)),
    savePoll: (poll) => dispatch(savePollAction(poll)),
    dispatchGetCourses: () => dispatch(getCourses()),
  };
}

const withConnect = connect(mapStateToProps, mapDispatchToProps, null, {
  forwardRef: true,
});

export default compose(withRouter, withConnect, withTranslation())(PollClient);
