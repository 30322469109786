export function getSuperviserOverviewActivityWorkouts() {
  return {
    type: 'GET_SUPERVISOR_OVERVIEW_ACTIVITY_WORKOUTS',
  };
}

export function setSuperviserOverviewActivityWorkouts(activities) {
  return {
    type: 'SET_SUPERVISOR_OVERVIEW_ACTIVITY_WORKOUTS',
    activities,
  };
}

export function getSuperviserAllActivityWorkouts(timeRange) {
  return {
    type: 'GET_SUPERVISOR_ALL_ACTIVITY_WORKOUTS',
    timeRange,
  };
}

export function setSuperviserAllActivityWorkouts(activities) {
  return {
    type: 'SET_SUPERVISOR_ALL_ACTIVITY_WORKOUTS',
    activities,
  };
}

export function setSuperviserAllActivityWorkoutsFilterDefaults(activities) {
  return {
    type: 'SET_SUPERVISOR_ALL_ACTIVITY_WORKOUTS_FILTER_DEFAULTS',
    activities,
  };
}

export function setSuperviserActivitiesStageFilter(stagesFilter) {
  return {
    type: 'SET_SUPERVISOR_ACTIVITIES_STAGE_FILTER',
    stagesFilter,
  };
}

export function setSuperviserActivitiesUsersFilter(usersFilter) {
  return {
    type: 'SET_SUPERVISOR_ACTIVITIES_USERS_FILTER',
    usersFilter,
  };
}

export function setSuperviserActivitiesFeedbackFilter(feedbackFilter) {
  return {
    type: 'SET_SUPERVISOR_ACTIVITIES_FEEDBACK_FILTER',
    feedbackFilter,
  };
}

export function setSuperviserActivitiesFeedbackGapSlider(feedbackGapSlider) {
  return {
    type: 'SET_SUPERVISOR_ACTIVITIES_FEEDBACK_GAP_SLIDER',
    feedbackGapSlider,
  };
}

export function setSuperviserActivitiesTimeRangeFilter(timeRange) {
  return {
    type: 'SET_SUPERVISOR_ACTIVITIES_TIME_RANGE_FILTER',
    timeRange,
  };
}

export function resetSupervisorActivitiesFilters() {
  return {
    type: 'RESET_SUPERVISOR_ACTIVITIES_FILTERS',
  };
}
