import {styled} from "@mui/material";

export const ToggleGroupTitle = styled('div')({
  marginBottom: 16,
});

export const ToggleWrapper = styled('div')({
  display: 'flex',
});

export const ToggleItem = styled('div')(
  {
    width: 140,
    height: 72,
    padding: '10px 0',
    borderRadius: 4,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    textAlign: 'center',
    marginRight: 12,
    border: '1px solid',
    cursor: 'pointer',
  },
  ({ theme, isSelected }) => ({
    backgroundColor: isSelected
      ? theme.palette.novotalk.$errorSelectorColor
      : theme.palette.novotalk.$backgroundColor,
    borderColor: isSelected ? theme.palette.novotalk.$activeColor : theme.palette.novotalk.$textColorLightGray,
    '&:hover': {
      borderColor: theme.palette.novotalk.$monitorTypesBorderColorHover,
    },
    '&:active': {
      borderColor: theme.palette.novotalk.$activeColor,
    },
  })
);
