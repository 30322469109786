export const users = [
  {userId:100,userRoles:[{userRole:40},{userRole:20}],userName:'user1'},
  {userId:200,userRoles:[{userRole:20}],userName:'user2'},
  {userId:300,userRoles:[],userName:'user3'},
  {userId:400,userRoles:[],userName:'user4'},
  {userId:500,userRoles:[],userName:'user5'},
  {userId:600,userRoles:[],userName:'user6'},
  {userId:700,userRoles:[],userName:'user7'},
  {userId:800,userRoles:[],userName:'user8'},
  {userId:900,userRoles:[],userName:'user9'},
  {userId:1000,userRoles:[],userName:'user10'},
]

export const roles = [
{id:10,	description:"Clinical mentor-HEB"},
{id:11,	description:"Emotional Mentor-HEB"},
{id:20,	description:"Clinical mentor-EN"},
{id:21,	description:"Emotional Mentor-EN"},
{id:30,	description:"Supervisor"},
{id:40,	description:"Tech admin"},
{id:41,	description:"Management admin"},
]