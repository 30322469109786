import {styled} from "@mui/material";
import CTAButton from '@components/CTAButton';
import { getIconsByType, iconTypes } from '@services/icons';
import { getResponsiveCSS } from '@utils/cssHelpers';

export const ScreenWrapper = styled('div')(
  {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    textAlign: 'center',
  },
  getResponsiveCSS('marginTop', 100, 130)
);

export const StyledHeader = styled('div')(
  {
    display: 'flex',
    alignItems: 'center',
  },
  getResponsiveCSS('marginBottom', 20, 30)
);

const ErrorIcon = getIconsByType(iconTypes.CLOSE_CIRCLE_OUTLINE);

export const StyledErrorIcon = styled(ErrorIcon)(
  {
    marginRight: 6,
    fontSize: 28,
  },
  ({ theme }) => ({
    color: theme.palette.novotalk.$navigationNotificationColor,
  })
);

export const ContentWrapper = styled('div')(
  {
    width: 460,
    padding: '77px 30px 30px',
    marginBottom: 20,
    borderRadius: 8,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  ({ theme }) => ({
    boxShadow: `0 4px 28px ${theme.palette.novotalk.$shadowTooltipColor}`,
  })
);

export const StyledCTAButton = styled(CTAButton)(
  getResponsiveCSS('width', 180, 210),
  getResponsiveCSS('height', 44, 48)
);

export const StyledFooter = styled('div')({
  width: 220,
  margin: '0 auto',
  marginBottom: 20,
});
