import React from 'react';
import PropTypes from 'prop-types';
import Text, { TEXT_COLOR, TEXT_SIZE } from '@components/Text';
import { StyledErrorIcon, ErrorWrapper } from './styles';
import {useTranslation} from "react-i18next";

export const ERROR_MESSAGE_TYPES = {
  CHOOSE: 'CHOOSE',
  ENTER: 'ENTER',
  FORMATTED: 'FORMATTED',
  CUSTOM: 'CUSTOM',
};

const ErrorMessage = ({ type, customText, formatMessageObject, className }) => {
  const {t} = useTranslation()
  let messageText;
  switch (type) {
    case ERROR_MESSAGE_TYPES.CHOOSE:
      messageText = t('Informative.Demanding.chooseAnswer');
      break;
    case ERROR_MESSAGE_TYPES.ENTER:
      messageText = t('Informative.Demanding.enterAnswer');
      break;

    case ERROR_MESSAGE_TYPES.FORMATTED:
      messageText = formatMessageObject.toString();
      break;

    case ERROR_MESSAGE_TYPES.CUSTOM:
      messageText = customText;
      break;

    default:
      messageText = '';
      break;
  }

  return (
    <ErrorWrapper className={className || 'error-message'}>
      <StyledErrorIcon />
      <Text size={TEXT_SIZE.H4} textColor={TEXT_COLOR.ERROR}>
        {messageText}
      </Text>
    </ErrorWrapper>
  );
};

ErrorMessage.propTypes = {
  type: PropTypes.string,
  formatMessageObject: PropTypes.object,
  customText: PropTypes.string,
  className: PropTypes.string,
};

export default ErrorMessage;
