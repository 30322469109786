import React, { Component } from 'react';
import Text from '@components/Text';
import { compose } from 'redux';
import { Wrapper, TipIcon, IconWrapper } from './styles';
import {withTranslation} from "react-i18next";

class TipsIndicator extends Component {
  render() {
    return (
      <Wrapper>
        <IconWrapper>
          <TipIcon />
        </IconWrapper>
        <Text size='T4' textColor='highlighted'>
          {this.props.t('Common.UI.tips')}
        </Text>
      </Wrapper>
    );
  }
}

export default compose(
    withTranslation()
)(TipsIndicator);
