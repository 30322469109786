export const TYPES = {
  GET_CHAT_INFO: 'GET_CHAT_INFO',
  SET_CHAT_INFO: 'SET_CHAT_INFO',
  INITIATE_CHAT: 'INITIATE_CHAT',
  VIDEO_CHAT_ERROR: 'VIDEO_CHAT_ERROR',
  CLEAR_VIDEO_CHAT_ERROR: 'CLEAR_VIDEO_CHAT_ERROR',
  CONNECT_TO_CHAT: 'CONNECT_TO_CHAT',
  END_CHAT: 'END_CHAT',
  LEAVE_CHAT: 'LEAVE_CHAT',
  TOGGLE_CHAT_VIDEO: 'TOGGLE_CHAT_VIDEO',
  TOGGLE_CHAT_AUDIO: 'TOGGLE_CHAT_AUDIO',
  SEND_TEXT_MESSAGE: 'SEND_TEXT_MESSAGE',
  TEXT_MESSAGE_RECEIVED: 'TEXT_MESSAGE_RECEIVED',
  SET_VIDEO_CHAT_CONNECTION_STATUS_TO_CONNECTED:
    'SET_VIDEO_CHAT_CONNECTION_STATUS_TO_CONNECTED',
  SET_VIDEO_CHAT_CONNECTION_STATUS_TO_DISCONNECTED:
    'SET_VIDEO_CHAT_CONNECTION_STATUS_TO_DISCONNECTED',
  SET_CHAT_ID: 'SET_CHAT_ID',
  CHANGE_STREAM_PROPERTY: 'CHANGE_STREAM_PROPERTY',
  SET_SHOW_MONITOR_VIDEO_CHAT: 'SET_SHOW_MONITOR_VIDEO_CHAT',
  SET_MONITOR_PROPS_VIDEO_CHAT: 'SET_MONITOR_PROPS_VIDEO_CHAT',
  SET_SHOW_MONITOR_SETTINGS: 'SET_SHOW_MONITOR_SETTINGS'
};

export const setMonitorPropsVideoChat = (value) => ({
  type: TYPES.SET_MONITOR_PROPS_VIDEO_CHAT,
  value
})

export const toggleShowMonitorSettings = (value) => ({
  type: TYPES.SET_SHOW_MONITOR_SETTINGS,
  value
})

export const toggleShowMonitorVideoChat = (value, showDropdown) => ({
  type: TYPES.SET_SHOW_MONITOR_VIDEO_CHAT,
  value,
  showDropdown,
})

export const getChatInfo = (chatId) => ({
  type: TYPES.GET_CHAT_INFO,
  chatId,
});

export const setChatInfo = (sessionInfo) => ({
  type: TYPES.SET_CHAT_INFO,
  sessionInfo,
});

export const onError = (error) => ({
  type: TYPES.VIDEO_CHAT_ERROR,
  error,
});

export const clearVideoChatError = () => ({
  type: TYPES.CLEAR_VIDEO_CHAT_ERROR
});

export const connectToChat = (users, options) => ({
  type: TYPES.CONNECT_TO_CHAT,
  users,
  options,
});

export const endChat = (sessionInfo, sessionId) => {
  return {
    type: TYPES.END_CHAT,
    sessionInfo,
    sessionId,
  };
};

export const leaveChat = (sessionId) => {
  return {
    type: TYPES.LEAVE_CHAT,
    sessionId,
  };
};

export const toggleChatVideo = () => ({
  type: TYPES.TOGGLE_CHAT_VIDEO,
});

export const toggleChatAudio = () => ({
  type: TYPES.TOGGLE_CHAT_AUDIO,
});

export const sendTextMessage = (message) => ({
  type: TYPES.SEND_TEXT_MESSAGE,
  message,
});

export const textMessageReceived = (message) => ({
  type: TYPES.TEXT_MESSAGE_RECEIVED,
  message,
});

export const setVideoChatConnectionStatusToConnected = () => ({
  type: TYPES.SET_VIDEO_CHAT_CONNECTION_STATUS_TO_CONNECTED,
});

export const setVideoChatConnectionStatusToDisconnected = () => ({
  type: TYPES.SET_VIDEO_CHAT_CONNECTION_STATUS_TO_DISCONNECTED,
});

export const setChatId = (chatId) => ({
  type: TYPES.SET_CHAT_ID,
  chatId,
});

export const changeStreamProperty = (participantId, properties) => ({
  type: TYPES.CHANGE_STREAM_PROPERTY,
  participantId,
  properties,
});
