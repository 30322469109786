import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { startMicrophoneCalibration, stopMicrophone } from '@shared/DSPHandler/services/MicrophoneService';
import POPUP_TYPES from '@containers/App/containers/PopUp/types';
import { openPopUp, closePopUp } from '@containers/App/containers/PopUp/actions';
import { setNoiseCalibration, changeCalibrationStatus } from '@containers/User/containers/CourseRouter/containers/MonitorHandler/actions';
import { NoiseCalibrationButton } from './styles';
import { CALIBRATION_STATUS } from './consts';

const SHOW_CALIBRATION_TIME = 5000;

export const NoiseCalibration = ({ pauseExerciseHandler }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const pauseExerciseWithCalibration = (value) => {
    dispatch(setNoiseCalibration(value));
  };

  const openCalibrationPopUp = () => {
    stopMicrophone();
    pauseExerciseHandler(false);
    pauseExerciseWithCalibration(true);
    dispatch(changeCalibrationStatus(CALIBRATION_STATUS.ACTIVATE));

    startMicrophoneCalibration();

    setTimeout(() => {
      pauseExerciseWithCalibration(false);
      dispatch(changeCalibrationStatus(CALIBRATION_STATUS.INACTIVE));
      dispatch(closePopUp());
    }, SHOW_CALIBRATION_TIME);

    dispatch(openPopUp(POPUP_TYPES.NOISE_CALIBRATION, {
      titleText: t('Informative.Demanding.remainSilent'),
      hideCloseIcon: true,
      disableBackdrop: true,
    }));
  };

  return (
    <NoiseCalibrationButton
      full
      onClick={openCalibrationPopUp}
    >
      {t('Common.UI.calibration')}
    </NoiseCalibrationButton>
  );
};
