import { fromJS } from 'immutable';
import { types } from './actions';
import { Types as signInActionTypes } from './containers/Authentication/SignIn/actions';
import { Types as resetPasswordTypes } from './containers/Authentication/ResetPassword/actions';
import { ROUTES } from '@shared/constants';
import {
  setToLocalStorage,
  LOCAL_STORAGE_ITEM,
  removeFromLocalStorage,
  getFromLocalStorage,
} from '../../utils/localStorageHelper';

export const initialState = fromJS({
  authentication: {
    validating: false,
    user: {},
  },
  theme: 'novotalks',
  userRoles: {},
  serverProperties: {
    loading: false,
    properties: [],
  },
  error:''
});

const rolesHelper = (roles) => {
  if (!roles || !roles.length) {
    return ROUTES.COURSE_PAGE;
  }
  const rolesEnum = getFromLocalStorage(LOCAL_STORAGE_ITEM.USER_ROLES);
  if (!rolesEnum) {
    return ROUTES.COURSE_PAGE;
  } // eslint-disable-line
  const role = roles[0];
  if (!role) {
    return ROUTES.COURSE_PAGE;
  }
  switch (role) {
    case rolesEnum.CLINICAL_MENTOR_HEB:
    case rolesEnum.EMOTIONAL_MENTOR_HEB:
    case rolesEnum.CLINICAL_MENTOR_EN:
    case rolesEnum.EMOTIONAL_MENTOR_EN:
    case rolesEnum.DEFAULT_MENTOR:
      return ROUTES.PENDING_DASHBOARD;
    case rolesEnum.SUPERVISOR:
      return ROUTES.RESOURCES_MANAGER;
    case rolesEnum.TECH_ADMIN:
    case rolesEnum.MANAGEMENT_ADMIN:
      return ROUTES.PENDING_DASHBOARD;
    default:
      return ROUTES.COURSE_PAGE;
  }
};

const saveUserToLocalStorage = (user) => {
  // patch until we get the default page from api
  user.defaultPage = rolesHelper(user.roles);
  setToLocalStorage(LOCAL_STORAGE_ITEM.USER, user);
};
const saveuserRolesToLocalStorage = (userRoles) => {
  setToLocalStorage(LOCAL_STORAGE_ITEM.USER_ROLES, userRoles);
};

const updateTokenInLocalStorage = (token) => {
  if (localStorage.user) {
    const user = JSON.parse(localStorage.user);
    user.token = token;
    saveUserToLocalStorage(user);
  }
};

const updateLocaleInLocalStorage = (locale) => {
  if (localStorage.user) {
    const user = JSON.parse(localStorage.user);
    user.locale = locale.toUpperCase();
    saveUserToLocalStorage(user);
  }
};

function appReducer(state = initialState, action) {
  switch (action.type) {
    case types.VALIDATE_BRIDGE_TOKEN:
      return state.setIn(['authentication', 'validating'], true);
    case types.VALIDATE_BRIDGE_TOKEN_FAILED:
      return state
        .setIn(['authentication', 'validating'], false)
        .setIn(['authentication', 'error'], action.error);
    case types.VALIDATE_BRIDGE_TOKEN_FINISHED: {
      const guestUser = {
        firstName: 'Guest',
        lastName: 'Guest',
        avatar: '',
        token: action.finalToken,
      };

      // clear url and save final token
      saveUserToLocalStorage(guestUser);
      Location.search = '';

      return state
        .setIn(['authentication', 'validating'], false)
        .setIn(['authentication', 'user'], fromJS(guestUser));
    }
    case signInActionTypes.SIGN_IN_SUCCESS:
      saveUserToLocalStorage(action.payload);
      const defaultPage = rolesHelper(action.payload.roles);
      return state
        .setIn(['authentication', 'user'], fromJS(action.payload))
        .setIn(['authentication', 'user', 'defaultPage'], defaultPage);
    case resetPasswordTypes.PASSWORD_CHANGE_SUCCESS:
      updateTokenInLocalStorage(action.token);

      return state.setIn(
        ['authentication', 'user', 'token'],
        fromJS(action.token),
      );
    case types.CHANGE_LOCALE:
      updateLocaleInLocalStorage(action.locale)
          return state.setIn(
              ['authentication', 'user', 'locale'],
              fromJS(action.locale),
          )
    case 'SET_THEME':
      return state.set('theme', action.theme);
    case types.CLEAR_TOKEN:
      removeFromLocalStorage(LOCAL_STORAGE_ITEM.USER);
      return state.setIn(['authentication', 'user'], fromJS({}));
    case types.SET_REFRESH_TOKEN:
      const user = {
        ...state.getIn(['authentication', 'user']).toJS(),
        token: action.tokens.token,
        refreshToken: action.tokens.refreshToken,
      };
      saveUserToLocalStorage(user);
      return state.setIn(['authentication', 'user'], fromJS(user));
    case types.SET_USER_ROLES:
      saveuserRolesToLocalStorage(action.userRoles);
      return state.set('userRoles', action.userRoles);
    case types.SET_APP_ERROR:
      return state.set('error', action.error)
    case types.GET_SERVER_PROPERTIES:
      return state.setIn(['serverProperties', 'loading'], true);
    case types.SET_SERVER_PROPERTIES:
      return state.set(
        'serverProperties',
        fromJS({
          properties: action.properties,
          loading: false,
        }),
      );
    default:
      return state;
  }
}

export default appReducer;
