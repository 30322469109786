export const VIDEO_CALL_CHAT_ENDED = 'VIDEO_CALL_CHAT_ENDED';
export const VIDEO_CALL_CHAT_CONNECTION_STARTED =
  'VIDEO_CALL_CHAT_CONNECTION_STARTED';
export const VIDEO_CALL_SUB_WRAPPER_DIMENSIONS =
  'VIDEO_CALL_SUB_WRAPPER_DIMENSIONS';
export const VIDEO_CALL_SELF_USER = 'VIDEO_CALL_SELF_USER';
export const VIDEO_CALL_NUM_OF_PARTICIPANTS = 'VIDEO_CALL_NUM_OF_PARTICIPANTS';

export const initialVideoCallState = {
  chatEnded: false,
  chatConnectionStarted: false,
  subWrapperDimensions: null,
  selfUser: null,
  numOfParticipants: 0,
};

export const videoCallReducer = (state, action) => {
  switch (action.type) {
    case VIDEO_CALL_CHAT_ENDED:
      return { ...state, chatEnded: action.payload };
    case VIDEO_CALL_CHAT_CONNECTION_STARTED:
      return { ...state, chatConnectionStarted: action.payload };
    case VIDEO_CALL_SUB_WRAPPER_DIMENSIONS:
      return { ...state, subWrapperDimensions: action.payload };
    case VIDEO_CALL_SELF_USER:
      return { ...state, selfUser: action.payload };
    case VIDEO_CALL_NUM_OF_PARTICIPANTS:
      return { ...state, numOfParticipants: action.payload };
    default:
      return state;
  }
};
