import {styled} from "@mui/material";
import CTAButton from '@components/CTAButton';
import { getFlexibleSize, getResponsiveCSS } from '@utils/cssHelpers';
import { getIconsByType, iconTypes } from '@services/icons';
import ChatService, { ScreenSharingRoleEnum } from '@services/chatService';
import { subscriberVideoWrapperStyleHelper } from './helpers';
import OverviewButton from '@components/OverviewButton';

const AvatarIcon = getIconsByType(iconTypes.PERSON);
const SoundOnIcon = getIconsByType(iconTypes.SOUND_ON);
const SoundOffIcon = getIconsByType(iconTypes.SOUND_OFF);
const VideoOnIcon = getIconsByType(iconTypes.VIDEO_ON);
const VideoOffIcon = getIconsByType(iconTypes.VIDEO_OFF);
const ScreenSharingOnIcon = getIconsByType(iconTypes.SCREEN_SHARING);
const ScreenSharingOffIcon = getIconsByType(iconTypes.STOP_SCREEN_SHARING);

export const PERCENT_OF_WRAPPER_WIDTH = 0.96;
export const PERCENT_OF_WRAPPER_HEIGHT = 0.96;
export const GAP_SUBS = 10;
export const PERCENT_OF_WRAPPER_HEIGHT_WITH_SCREEN_SHARING = 0.25;
export const PERCENT_OF_WRAPPER_HEIGHT_SCREEN_SH = 0.7;
export const MONITOR_HEIGHT = '310px';

export const textChatShadow = '0 2px 10px 0 rgba(0,0,0,0.07)';

export const Wrapper = styled('div')(
  {
    maxWidth: '100%',
    display: 'grid',
    overflow: 'hidden',
    gridTemplateAreas: `
  'otherVideos selfVideo'
  'otherVideos textChat'
  `,
  },
  ({ theme, initiator }) => ({
    gridTemplateColumns: `auto ${theme.palette.novotalk.videoChatMessengerWidth}px`,
    gridTemplateRows: `${initiator ? '280px' : '200px'} `,
    '@media(min-width: 1280px)': {
      gridTemplateRows: `${initiator ? getFlexibleSize(280, 310) : getFlexibleSize(200, 230)
        } auto`,
    },
    '@media(min-width: 1920px)': {
      gridTemplateRows: `${initiator ? '310px' : '230px'} auto`,
    },
    height: `calc(100vh - ${theme.palette.novotalk.navigationBarHeight}px)`,
  })
);

export const MonitorWrapper = styled('div')(
  {
    position: 'relative',
    flexShrink: 0,
    display: 'flex',
    flexDirection: 'row'
  }
);

export const HeaderMonitor = styled('div')(
  {
    display: 'flex',
    direction: 'row',
    justifyContent: 'space-between',
    position: 'relative',
    alignItems: 'center',
    left: 35,
    bottom: 25
  }
)

export const ButtonsWrapper = styled('div')(
  {
    display: 'flex',
  }
)

export const MonitorWrapperVideoChat = styled('div')(
  {
    position: 'relative',
    zIndex: 999,
    flexShrink: 0,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center'
  }
);

export const MonitorVideoWrapper = styled('div')(
  {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    border: '10px #030912b3 solid',
    background: 'white',
    padding: '40px 40px 20px 40px',
    position: 'absolute',
    bottom: '0vh',
    width: 'calc(100% - 375px)',
    borderRadius: 15
  }
);

export const SelfVideoWrapper = styled('div')(
  {
    gridArea: 'selfVideo',
    display: 'flex',
    position: 'relative',
    width: '100%',
  },
  getResponsiveCSS('height', 200, 230)
);

export const OtherVideosContainer = styled('div')(
  {
    gridArea: 'otherVideos',
    position: 'relative',
  },
  ({ theme }) => ({
    backgroundColor: theme.palette.novotalk.$videoChatBackgound,
  })
);

export const ScreenSharingWrapper = styled('div')(
  ({ heightOfWrapper, widthOfWrapper }) => {
    const isScreenSharing =
      ChatService.screenSharingRole === ScreenSharingRoleEnum.SUBSCRIBER;
    return {
      visibility: isScreenSharing ? 'visible' : 'hidden',
      width: isScreenSharing ? widthOfWrapper * PERCENT_OF_WRAPPER_WIDTH : 0,
      height: isScreenSharing
        ? heightOfWrapper * PERCENT_OF_WRAPPER_HEIGHT_SCREEN_SH
        : 0,
    };
  }
);

export const SubscribersSubWrapper = styled('div')(
  ({ stylesObject }) => {
    return stylesObject
  }
);

export const SubscribersWrapper = styled('div')(
  ({ showMonitor }) => {
    let height = showMonitor ? `calc(100% - ${MONITOR_HEIGHT})` : '100%';
    return {
      display: 'flex',
      flexWrap: 'wrap',
      justifyContent: 'center',
      alignItems: 'center',
      position: 'relative',
      width: '100%',
      height,
      padding: 0,
    }
  });

const calculateProperties = (numOfParticipants, theme) => {
  let sizes;
  if (ChatService.screenSharingRole === ScreenSharingRoleEnum.SUBSCRIBER) {
    return {
      height: 200,
      width: 200,
    };
  }
  switch (numOfParticipants) {
    case 1:
      sizes = {
        height: '100%',
        width: '100%',
      };
      break;
    case 2:
      sizes = {
        height: '100%',
        width: '50%',
      };
      break;
    case 3:
      sizes = {
        height: '100%',
        width: 'calc(100% / 3)',
      };
      break;
    case 4:
      sizes = {
        height: '50%',
        ...getResponsiveCSS('width', 400, 550),
        [`@media(max-width: ${800 + theme.palette.novotalk.videoChatMessengerWidth}px)`]: {
          width: '50%',
        },
      };
      break;
    case 5:
    case 6:
      sizes = {
        height: '50%',
        width: 'calc(100% / 3)',
      };
      break;
    case 7:
    case 8:
      sizes = {
        height: '50%',
        width: '25%',
      };
      break;
    // no default
  }
  return sizes;
};
export const SubscriberVideoWrapper = styled('div')(
  ({ hidden, sizes, maxHeight, numOfParticipants, paddingBottom }) => {
    const resStyles = {
      display: hidden ? 'none' : 'initial',
      width: sizes.width,
      height: sizes.height,
      maxHeight,
      paddingBottom
    }
    if (numOfParticipants > 1) {
      return { ...resStyles, flexBasis: subscriberVideoWrapperStyleHelper(numOfParticipants) }
    }
    return resStyles;
  }
);

export const UserInfo = styled('div')(
  {
    display: 'flex',
    alignItems: 'center',
    position: 'absolute',
    zIndex: 100,
  },
  getResponsiveCSS('left', 12, 20),
  getResponsiveCSS('bottom', 9, 20)
);

export const SelfUserInfo = styled('div')({
  display: 'flex',
  alignItems: 'center',
  position: 'absolute',
  zIndex: 100,
  left: 14,
  bottom: 10,
});

export const UserControls = styled('div')(
  {
    display: 'flex',
    alignItems: 'center',
    position: 'absolute',
    bottom: 12,
    right: 10,
    zIndex: 100,
  },
  getResponsiveCSS('bottom', 12, 16)
);

export const Avatar = styled('img')(
  {
    objectFit: 'cover',
    borderRadius: '50%',
    boxShadow: '0 0 12px 0 rgba(0,0,0,0.16)',
    border: '1px solid',
    marginRight: 10,
  },
  getResponsiveCSS('width', 40, 56),
  getResponsiveCSS('height', 40, 56),
  ({ theme }) => ({
    borderColor: theme.palette.novotalk.$textColorReversed,
  })
);

export const SelfAvatar = styled(Avatar)(
  getResponsiveCSS('width', 40, 48),
  getResponsiveCSS('height', 40, 48)
);

export const ChatAvatar = styled('img')({
  objectFit: 'cover',
  borderRadius: '50%',
  width: 48,
  height: 48,
});

export const StyledAvatarIcon = styled(AvatarIcon)(
  {
    fontSize: 20,
    marginTop: -4,
    marginRight: 8,
  },
  getResponsiveCSS('fontSize', 20, 32),
  ({ theme }) => ({
    color: theme.palette.novotalk.$textColorReversed,
  })
);

export const VideoPlaceholder = styled('div')(
  {
    width: '100%',
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  ({ theme }) => ({
    background: theme.palette.novotalk.$videoChatGradient,
    backgroundColor: theme.palette.novotalk.$videoChatWaitingBackground,
  })
);

export const VideoChatEndedPlaceholder = styled('div')(
  {
    width: '100%',
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  ({ theme }) => ({
    background: theme.palette.novotalk.$videoChatGradient,
    backgroundColor: theme.palette.novotalk.$videoChatEndedBackground,
  })
);

export const LoadingWrapper = styled('div')({
  width: '100%',
  height: '100vh',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
});

export const ClinicianIndicator = styled('div')(
  {
    position: 'absolute',
    zIndex: 100,
  },
  getResponsiveCSS('bottom', 18, 33),
  getResponsiveCSS('right', 10, 30),
  ({ numOfParticipants }) => {
    if (numOfParticipants > 6) {
      return {
        '@media(max-width: 1290px)': {
          top: 9,
          left: 10,
          right: 'auto',
          bottom: 'auto',
        },
      };
    }
    return {};
  }
);

export const Container = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
});

export const StyledCTAButton = styled(CTAButton)({
  margin: '16px auto',
  width: 240,
});

const controlIconStyle = {
  fontSize: 24,
};

const controlIconColor = ({ theme }) => ({
  color: theme.palette.novotalk.$textColorReversed,
});

export const StyledScreenSharingOnIcon = styled(ScreenSharingOnIcon)(
  controlIconStyle,
  controlIconColor
);
export const StyledScreenSharingOffIcon = styled(ScreenSharingOffIcon)(
  controlIconStyle,
  controlIconColor
);

export const StyledSoundOnIcon = styled(SoundOnIcon)(
  controlIconStyle,
  controlIconColor
);
export const StyledSoundOffIcon = styled(SoundOffIcon)(
  controlIconStyle,
  controlIconColor
);
export const StyledVideoOnIcon = styled(VideoOnIcon)(
  controlIconStyle,
  controlIconColor,
  {
    marginLeft: 2,
    marginBottom: -1,
  }
);

export const StyledVideoOffIcon = styled(VideoOffIcon)(
  controlIconStyle,
  controlIconColor,
  {
    width: 21,
    height: 21,
    marginLeft: 2,
  }
);

export const ControlsIconWrapper = styled('div')(
  {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: 36,
    height: 36,
    borderRadius: '50%',
    marginRight: 10,
    cursor: 'pointer',
  },
  ({ theme }) => ({
    backgroundColor: theme.palette.novotalk.$videoChatControlsBackground,
    '&:hover': {
      backgroundColor: theme.palette.novotalk.$videoChatControlsBackgroundHover,
    },
    '&:active': {
      backgroundColor: theme.palette.novotalk.$videoChatControlsBackgroundActive,
    },
  })
);

export const ControlsIconWrapperVideoChat = styled('div')(
  {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: 36,
    height: 36,
    borderRadius: '50%',
    marginRight: 10,
    cursor: 'pointer',
  },
  ({ theme }) => ({
    '&:hover': {
      backgroundColor: theme.palette.novotalk.$videoChatControlsBackgroundHover,
    },
    '&:active': {
      backgroundColor: theme.palette.novotalk.$videoChatControlsBackgroundActive,
    },
  })
);

export const StyledScreenSharingBtnWrapper = styled(ControlsIconWrapper)(() => {
  const isSharingSubscriber =
    ChatService.screenSharingRole === ScreenSharingRoleEnum.SUBSCRIBER;
  return {
    display: isSharingSubscriber ? 'none' : 'flex',
  };
});

export const StyledSubscriberSoundOffIcon = styled(SoundOffIcon)(
  {
    position: 'absolute',
    top: 18,
    right: 19,
    fontSize: 27,
    zIndex: 100,
  },
  ({ theme }) => ({
    color: theme.palette.novotalk.$textColorReversed,
  })
);

export const SubscriberVideoOffIconWrapper = styled('div')(
  {
    position: 'relative',
    width: '100%',
    height: '100%',
    display: 'flex',
  },
  ({ theme }) => ({
    backgroundColor: theme.palette.novotalk.$videoChatSubscriberBackground,
  })
);

export const StyledSubscriberVideoOffIcon = styled(AvatarIcon)(
  {
    margin: 'auto',
    zIndex: 100,
    opacity: 0.08,
  },
  getResponsiveCSS('fontSize', 125, 157),
  ({ theme }) => ({
    color: theme.palette.novotalk.$textColorReversed,
  })
);

export const TextChatWrapper = styled('div')({
  gridArea: 'textChat',
  alignSelf: 'stretch',
  position: 'relative',
});

export const InputWrapper = styled('div')(
  {
    position: 'absolute',
    width: '100%',
    bottom: 0,
    height: 56,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    paddingRight: 14,
    borderTop: '1px solid',
  },
  ({ theme }) => ({
    borderColor: theme.palette.novotalk.$borderColorLight,
  })
);

export const ChatInput = styled('input')({
  padding: '10px 19px',
  marginBottom: -3,
  width: 300,
  '&:focus': {
    outline: 'none',
  },
});

export const HistoryWrapper = styled('div')({
  position: 'absolute',
  top: 0,
  bottom: 56,
  width: '100%',
  overflow: 'auto',
  '&::-webkit-scrollbar': {
    // for chrome
    display: 'none',
  },
  scrollbarWidth: 'none', // for firefox
});

export const MessagesHistory = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
  padding: '20px 14px 0',
});

export const ChatBubble = styled('div')(
  {
    display: 'flex',
    flexDirection: 'column',
    width: 280,
    padding: '18px 20px',
    borderRadius: 8,
    wordBreak: 'break-word',
    position: 'relative',
  },
  ({ theme, self }) => ({
    backgroundColor: self ? theme.palette.novotalk.$selfChatBubble : theme.palette.novotalk.$otherChatBubble,
    [self ? 'marginRight' : 'marginLeft']: 20,
    ':after': {
      content: '""',
      position: 'absolute',
      [self ? 'right' : 'left']: -5,
      bottom: 19,
      width: 10,
      height: 10,
      backgroundColor: self ? theme.palette.novotalk.$selfChatBubble : theme.palette.novotalk.$otherChatBubble,
      transform: 'rotate(45deg)',
    },
  })
);

export const MessageWrapper = styled('div')(
  {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'flex-end',
    marginBottom: 20,
  },
  ({ self }) => ({
    flexDirection: self ? 'row-reverse' : 'row',
  })
);

export const SelectWrapper = styled('div')({
  gridArea: 'TypeSelect',
});

export const SelectWrapperMonitorDropdown = styled('div')({
  gridArea: 'TypeSelect',
  position: 'absolute',
  top: 20,
  right: 185
});

export const DurationWrapper = styled('div')({
  minWidth: 100,
  maxWidth: 130,
  display: 'flex',
  alignItems: 'center',
});

export const SettingsWrapper = styled('div')({
  border: '0.7px gray solid',
  borderRadius: 5,
  display: 'flex',
  flexDirection: 'row',
  position: 'absolute',
  alignItems: 'center',
  justifyContent: 'space-between',
  padding: 7,
  top: 10,
  right: 100,
  width: 230,
  backgroundColor: 'white',
  zIndex: 999
});

export const ProgressWrapper = styled('div')({
  display: 'flex',
  flexShrink: 0,
  alignItems: 'center',
  flexDirection: 'column',
  marginTop: 30,
});

export const StyledImage = styled('img')({
  width: 96,
  margin: 20,
});

export const StyledOverviewButton = styled(OverviewButton)(
  ({ hideButton, disabled }) => ({
    height: 32,
    display: hideButton && 'none',
    disabled: disabled && true,
  }),
  getResponsiveCSS('marginLeft', 6, 8, '&:last-of-type')
);

export const InhaleInstructionWrapper = styled('div')({
  bottom: 20,
  textAlign: 'center',
  position: 'absolute',
  width: '100%',
  zIndex: '10',
  height: 30,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
});

export const MonitorWithErrors = styled('div')({
  position: 'relative',
})
