import _ from 'lodash';

export const getFeedbacks = (activities) =>
  _.flattenDeep(
    activities.map(({ Threads }) => Threads.map(({ Posts }) => Posts)),
  );

export const getSortedFeedbacks = (activities) => {
  return getFeedbacks(activities).sort(
    (a, b) => Date.parse(b.createdAt) - Date.parse(a.createdAt),
  );
};

export const getPracticeFeedbacks = (feedbacks, name) => feedbacks.filter((feedback) =>
  feedback.practiceOfFeedback === name && !feedback?.payload?.isDismiss)

export const getLatestFeedback = (activities) => {
  if (!activities.length) return [];
  const feedbacks = getFeedbacks(activities);
  if (!feedbacks.length) return [];
  return [feedbacks[0]];
};

export const getNewFeedbacks = (activities) => {
  if (!activities.length) return [];
  const feedbacks = getFeedbacks(activities);
  if (!feedbacks.length) return [];
  return feedbacks;
}
