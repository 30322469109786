import React, { Component } from 'react';
import PropTypes from 'prop-types';
import differenceInHours from 'date-fns/difference_in_hours';
import differenceInMinutes from 'date-fns/difference_in_minutes';
import format from 'date-fns/format';
import CustomTooltip, { placementTypes } from '@components/CustomTooltip';
import {
  Wrapper,
  ActivityLevel,
  User,
  Stage,
  Type,
  LastExercise,
  FeedbackTime,
  FeedbackIndicator,
  timeOrange,
  timeRed,
  IndicatorIcon,
} from './styles';
import { withTranslation } from 'react-i18next';
import { compose } from 'redux';

class Activity extends Component {
  timeDiffToColor(time, fromTime) {
    const hoursDiff = differenceInHours(fromTime, time);
    let color;
    switch (true) {
      case hoursDiff > 24:
        color = timeRed;
        break;
      case hoursDiff > 18:
        color = timeOrange;
        break;
      default:
        color = null;
        break;
    }
    return color;
  }

  timeDiff(fromTime, toTime) {
    const {t} = this.props
    const minDiff = differenceInMinutes(fromTime, toTime);
    if (minDiff < 0) {
      console.error('time diff error', fromTime, toTime);
      return null;
    }
    let hours = parseInt(minDiff / 60, 10);

    if (minDiff < 60) {
      return t('Dates.durationMinutes', {minutes: minDiff})
    } else if (hours < 72) {
      let minutes = minDiff % 60;
      minutes = (minutes < 10 ? '0' : '') + minutes;
      return t('Dates.hoursMinutes', {hours: hours, minutes: minutes})
    }

    const days = parseInt(hours / 24, 10);
    hours = parseInt(hours % 24, 10);
    if (hours === 0) {
      return this.props.t('Dates.daysAmount', {days: days})
    }
    return this.props.t('Dates.daysAndHours', {days: days, hours: hours})
  }

  timeFromNow(date) {
    return this.timeDiff(new Date(), format(date));
  }

  renderExerciseTime(exerciseTime) {
    return (
      <span className='exerciseTime'>
        {this.timeFromNow(exerciseTime)}
        {this.props.t('Dates.ago')}
      </span>
    );
  }

  renderFeedbackTime(timeDiff) {
    if (!timeDiff) return null;
    return (
      <span className='feedbackTime'>
        {timeDiff}
        {this.props.t('UI.afterExercise')}
      </span>
    );
  }

  renderFeedback(hasFeedback, timeDiff) {
    const { lastExercise, feedbackTime, displayFull } = this.props;
    const tooltipTitle = format(feedbackTime, 'HH:mm DD/MM/YYYY');
    return hasFeedback ? (
      <CustomTooltip text={tooltipTitle} placement={placementTypes.BOTTOM} tiny>
        <FeedbackTime
          className='feedbackWrapper'
          show={displayFull}
          color={this.timeDiffToColor(lastExercise, feedbackTime)}
        >
          {this.renderFeedbackTime(timeDiff)}
        </FeedbackTime>
      </CustomTooltip>
    ) : (
      <FeedbackTime className='feedbackTime' show={displayFull}>
        {'---'}
      </FeedbackTime>
    );
  }

  render() {
    const {
      activityLevel,
      user,
      stage,
      type,
      lastExercise,
      feedbackTime,
      displayFull,
      id,
      userId,
      feedback,
    } = this.props;
    const timeDiff = feedbackTime
      ? this.timeDiff(feedbackTime, lastExercise)
      : null;
    const hasFeedback = feedback === 1;
    const url = `https://web.novotalk.com/#/activities/${userId}?waid=${id}`;
    return (
      <Wrapper
        className='supervisorActivity'
        onClick={() => window.open(url, '_blank')}
      >
        <FeedbackIndicator show={displayFull} hasFeedback={hasFeedback}>
          <IndicatorIcon />
        </FeedbackIndicator>
        <ActivityLevel>{`Level ${activityLevel}`}</ActivityLevel>
        <User>{user}</User>
        <Stage>{stage}</Stage>
        <Type>{type}</Type>
        <CustomTooltip
          text={format(lastExercise, 'HH:mm DD/MM/YYYY')}
          placement={placementTypes.BOTTOM}
          tiny
        >
          <LastExercise
            className='exerciseWrapper'
            color={
              feedbackTime ? '' : this.timeDiffToColor(lastExercise, new Date())
            }
          >
            {this.renderExerciseTime(lastExercise)}
          </LastExercise>
        </CustomTooltip>
        {this.renderFeedback(hasFeedback, timeDiff)}
      </Wrapper>
    );
  }
}

Activity.propTypes = {
  id: PropTypes.number.isRequired,
  activityLevel: PropTypes.number.isRequired,
  user: PropTypes.string.isRequired,
  userId: PropTypes.number.isRequired,
  stage: PropTypes.number.isRequired,
  type: PropTypes.string.isRequired,
  lastExercise: PropTypes.number.isRequired,
  feedbackTime: PropTypes.number,
  displayFull: PropTypes.bool,
  feedback: PropTypes.number,
};

export default compose(
    withTranslation()
)(Activity)
