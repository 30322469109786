export const USERS_ACTIONS = {
  FETCH_ALL_USERS: 'USERS_ACTIONS/FETCH_ALL_USERS',
  FETCH_COUNT_USERS: 'USERS_ACTIONS/FETCH_COUNT_ALL_USERS',
  SET_OFFSET: 'USERS_ACTIONS/SET_OFFSET',
  SET_USERS:'USERS_ACTIONS/SET_USERS',
  SET_COUNT_USERS:'USERS_ACTIONS/SET_COUNT_USERS',
  SET_LOADING_STATUS: 'USERS_ACTIONS/SET_LOADING_STATUS',
  SET_PAGE_NUMBER: 'USERS_ACTIONS/SET_PAGE_NUMBER',
  SHOW_ERROR: 'USERS_ACTIONS/SHOW_ERROR',
  SET_ENTITIES_COMPLETED:'SET_ENTITIES_COMPLETED',
  SET_ORDER:'SET_ORDER',
  SET_SORT_BY:'SET_SORT_BY'
};

export const setEntitiesAsCompleted = (entitiesLists,userId) =>({
type:USERS_ACTIONS.SET_ENTITIES_COMPLETED,
entitiesLists,
userId
})

export const putUsersLoadingStatus = (status) => ({
  type: USERS_ACTIONS.SET_LOADING_STATUS,
  payload: status
});

export const setUsers = (list) => ({
  type: USERS_ACTIONS.SET_USERS,
  payload: list,
});

export const showUsersError = () => ({
  type: USERS_ACTIONS.SHOW_ERROR,
});

export const fetchUsers = (roles, isUserPage) => ({
  type: USERS_ACTIONS.FETCH_ALL_USERS,
  payload: { roles, isUserPage }
});

export const fetchCountUsers = (roles) => ({
  type: USERS_ACTIONS.FETCH_COUNT_USERS,
  roles,
});

export const putCountUsers = (count) => ({
  type: USERS_ACTIONS.SET_COUNT_USERS,
  payload: count,
});

export const putUsersOffset = (page) => ({
  type: USERS_ACTIONS.SET_OFFSET,
  payload: page
});

export const putUsersPageNumber = (pageNumber) => ({
  type: USERS_ACTIONS.SET_PAGE_NUMBER,
  payload: pageNumber
});

export const putOrder = (order) => ({
  type: USERS_ACTIONS.SET_ORDER,
  payload: order
})

export const putSortBy = (sortBy) => ({
  type: USERS_ACTIONS.SET_SORT_BY,
  payload: sortBy
})
