import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { withRouter } from 'react-router-dom';
import injectSaga from '@utils/injectSaga';
import injectReducer from '@utils/injectReducer';
import IdleTimer from 'react-idle-timer';
import Container from '@components/Container';
import ClickAwayListener from '@components/ClickAwayListener';
import Text, { TEXT_SIZE, TEXT_COLOR } from '@components/Text';
import { ROUTES } from '@shared/constants';
import MessagesMenu from './components/MessagesMenu';
import {
  Wrapper,
  Logo,
  StyledLocaleToggle,
  HelpMenuWrapper,
  StyledHelpIcon,
  StyledHelpMenuContactIcon,
  UserInfoContainer,
  StyledAvatar,
  StyledChevronDown,
  StyledChevronUp,
  UserPanelWrapper,
  UserMenuContainer,
  StyledMenuItem,
  StyledSettingsIcon,
  StyledLogOutIcon,
  StyledRolesManagementIcon,
  StyledResourcesIcon,
  StyledRecordIcon,
  StyledUsersIcon,
  StyledMentorDashboard,
  //  NotificationIconWrapper,  StyledExerciseAreaTab, StyledNotificationIcon,
} from './styles';
import LogoImage from './logo_sm.png';
import { ENVIRONMENT } from '@services/servers';
// import POPUP_TYPES from '../PopUp/types';
// import { openPopUp as openPopUpAction } from '../PopUp/actions';
import { logOut as logOutAction } from '../../actions';
import { startNewConversationAction } from '../Messaging/actions';
import saga from '../Messaging/saga';
import reducer from '../Messaging/reducer';
import ServerVersion from '@components/ServerVersion';
import ClientVersion from '@components/ClientVersion';
import { openPopUp } from "@containers/App/containers/PopUp/actions";
import POPUP_TYPES from "@containers/App/containers/PopUp/types";
import ChatService from '@services/chatService';
import { withTranslation } from 'react-i18next';

const WATCHER_ACTIONS = {
  ACTION: 'ACTION',
  PAUSE: 'PAUSE',
};

const ACTIVITY_TIMES = {
  IDLE_TIME: 1000 * 15 * 60,
  LOGOUT_TIME: 1000 * 60
}

class NavigationBar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isUserMenuShown: false,
      isHelpMenuShown: false,
    };
    this.userActivityWatcher = React.createRef();
    // BroadcastChannel is used to communicate with other browser tabs with app,
    // so user can be active on another tab
    this.watchingChannel = new BroadcastChannel('activity_watchers_channel');
    this.watchingChannel.onmessage = this.handleWatchingChannelMessage(this.userActivityWatcher);
  }

  componentDidUpdate(prevProps) {
    if (this.userActivityWatcher.current) {
      if (this.props.connectedToChat && !prevProps.connectedToChat) {
        this.userActivityWatcher.current.pause();
        this.watchingChannel.postMessage(WATCHER_ACTIONS.PAUSE);
      } else if (!this.props.connectedToChat && prevProps.connectedToChat) {
        this.userActivityWatcher.current.reset();
      }
    }
  }

  componentWillUnmount() {
    if (this.userActivityWatcher.current && this.props.connectedToChat) {
      this.watchingChannel.postMessage(WATCHER_ACTIONS.ACTION);
    }
  }

  handleWatchingChannelMessage = (watcher) => (ev) => {
    if (watcher.current) {
      switch (ev.data) {
        case WATCHER_ACTIONS.ACTION:
          watcher.current.reset();
          break;
        case WATCHER_ACTIONS.PAUSE:
          watcher.current.pause();
          break;
        default:
      }
    }
  };

  handleLogOut = () => {
    const {
      exerciseInProgress,
      lessonInProgress,
      currentLessonActivity,
      logOut,
    } = this.props;
    const logOutData = {
      exerciseInProgress,
      lessonInProgress,
    }
    if (currentLessonActivity) {
      logOutData.activityLessonId = currentLessonActivity.id
    }
    if (ChatService) {
      logOutData.archiveID = ChatService.archiveId
    }

    this.toggleUserMenu();
    logOut(logOutData);
  };

  handleSettingsOpening = () => {
    const { history } = this.props;
    history.push(ROUTES.SETTINGS);
  };

  navigateRolesManagement = () => {
    const { history } = this.props;
    history.push(ROUTES.ROLES_MANAGEMENT);
  };

  navigateResources = () => {
    const { history } = this.props;
    history.push(ROUTES.RESOURCES_MANAGER);
  };

  navigateHomePage = () => {
    const { history, defaultPage } = this.props;
    history.push(defaultPage);
  };

  navigateUsers = () => {
    const { history } = this.props;
    history.push(ROUTES.USERS.LIST);
  };

  navigateMentorDashboard = () => {

    const { history } = this.props;
    history.push(ROUTES.PENDING_DASHBOARD);
  }

  toggleHelpMenu = () => {
    this.setState((prevState) => ({
      isHelpMenuShown: !prevState.isHelpMenuShown,
    }));
  };

  toggleUserMenu = () => {
    this.setState((prevState) => ({
      isUserMenuShown: !prevState.isUserMenuShown,
    }));
  };

  redirectToMessaging = () => {
    const { history, startNewConversation } = this.props;
    history.push(ROUTES.MESSAGING);
    startNewConversation();
  };

  userInactivityDialog = () => {
    const { dispatchOpenPopUp, t } = this.props;
    const logOutTimeOut = setTimeout(() => {
      this.handleLogOut()
    }, ACTIVITY_TIMES.LOGOUT_TIME)
    dispatchOpenPopUp(POPUP_TYPES.CONFIRM, {
      titleText: t('Informative.Notifying.userInactive'),
      bodyText: t('Informative.Confirming.inactivityQuestion'),
      confirmText: t('Actions.Auth.logOut'),
      cancelText: t('Actions.Instance.continue'),
      onConfirm: (finishLesson) => {
        if (finishLesson) {
          this.handleLogOut()
        }
      },
      onClose: () => {
        clearTimeout(logOutTimeOut)
      }
    });
  }

  renderUserActivityWatcher = () => {

    // checks activity of user, if user is inactive, logout dialog is shown, and logs out after 1 minute
    return (
      <IdleTimer
        ref={this.userActivityWatcher}
        element={document}
        onIdle={this.userInactivityDialog}
        onAction={() => this.watchingChannel.postMessage(WATCHER_ACTIONS.ACTION)}
        throttle={1000 * 60}
        timeout={ACTIVITY_TIMES.IDLE_TIME}
      />
    );
  };

  renderHelpMenu = () => {
    const {t} = this.props;
    const { isHelpMenuShown } = this.state;

    return (
      <ClickAwayListener
        onClickAway={() => {
          this.state.isHelpMenuShown && this.toggleHelpMenu();
        }}
      >
        <HelpMenuWrapper
          isActive={isHelpMenuShown}
          onClick={this.toggleHelpMenu}
        >
          <StyledHelpIcon />
          {isHelpMenuShown && (
            <UserMenuContainer className="navigation-menu">
              <StyledMenuItem onClick={this.redirectToMessaging}>
                <StyledHelpMenuContactIcon />
                <Text size={TEXT_SIZE.H4} textColor={TEXT_COLOR.DISABLED_LIGHT}>
                  {t('Actions.General.contactUs')}
                </Text>
              </StyledMenuItem>
              {ENVIRONMENT !== 'prod' && (
                <>
                  <StyledMenuItem>
                    <ServerVersion showIcon />
                  </StyledMenuItem>
                  <StyledMenuItem>
                    <ClientVersion showIcon />
                  </StyledMenuItem>
                </>
              )}
            </UserMenuContainer>
          )}
        </HelpMenuWrapper>
      </ClickAwayListener>
    );
  };

  renderUserInfo = () => {
    const {user, t} = this.props;
    const { isUserMenuShown } = this.state;
    const isSupervisor = user.roles.includes('30');
    const isClinicalMentor = user.roles.includes('20') || user.roles.includes('10');

    return (
      <ClickAwayListener
        onClickAway={() => {
          this.state.isUserMenuShown && this.toggleUserMenu();
        }}
      >
        <UserPanelWrapper
          onClick={() => {
            this.toggleUserMenu();
          }}
        >
          <UserInfoContainer>
            <StyledAvatar img={user.avatar} />
            <Text
              size={TEXT_SIZE.T3}
              textColor={TEXT_COLOR.REVERSED}
              margin="0 10px 0 0"
            >
              {`${user.firstName} ${user.lastName}`}
            </Text>
            {isUserMenuShown ? <StyledChevronUp /> : <StyledChevronDown />}
          </UserInfoContainer>
          {isUserMenuShown && (
            <UserMenuContainer>
              {isClinicalMentor && (
                <StyledMenuItem onClick={this.navigateMentorDashboard}>
                  <StyledMentorDashboard />
                  <Text size={TEXT_SIZE.H4} textColor={TEXT_COLOR.DISABLED_LIGHT}>
                    {t('UI.mentorDashboard')}
                  </Text>
                </StyledMenuItem>
              )}
              {isSupervisor && (
                <>
                  <StyledMenuItem onClick={this.navigateResources}>
                    <StyledResourcesIcon />
                    <Text size={TEXT_SIZE.H4} textColor={TEXT_COLOR.DISABLED_LIGHT}>
                      {t('Common.UI.resources')}
                    </Text>
                  </StyledMenuItem>
                  <StyledMenuItem onClick={this.navigateRolesManagement}>
                    <StyledRolesManagementIcon />
                    <Text size={TEXT_SIZE.H4} textColor={TEXT_COLOR.DISABLED_LIGHT}>
                      {t('UI.rolesManagement')}
                    </Text>
                  </StyledMenuItem>
                </>
              )}
              {(isSupervisor || isClinicalMentor) && (
                <StyledMenuItem onClick={this.navigateUsers}>
                  <StyledUsersIcon />
                  <Text size={TEXT_SIZE.H4} textColor={TEXT_COLOR.DISABLED_LIGHT}>
                    {t('UI.userRecords')}
                  </Text>
                </StyledMenuItem>
              )}
              <StyledMenuItem onClick={this.handleSettingsOpening}>
                <StyledSettingsIcon />
                <Text size={TEXT_SIZE.H4} textColor={TEXT_COLOR.DISABLED_LIGHT}>
                  {t('Common.UI.settings')}
                </Text>
              </StyledMenuItem>
              <StyledMenuItem onClick={this.handleLogOut}>
                <StyledLogOutIcon />
                <Text size={TEXT_SIZE.H4} textColor={TEXT_COLOR.DISABLED_LIGHT}>
                  {t('Actions.Auth.logOut')}
                </Text>
              </StyledMenuItem>
            </UserMenuContainer>
          )}
        </UserPanelWrapper>
      </ClickAwayListener>
    );
  };

  renderRecordIcon = () => {
    const { recordingVideo, connectedToMirror } = this.props;
    if (recordingVideo || connectedToMirror) return <StyledRecordIcon />
  }

  render() {
    return (
      <Wrapper>
        {this.renderUserActivityWatcher()}
        <Container>
          <Logo onClick={this.navigateHomePage} src={LogoImage} />
        </Container>
        <Container>
            <Container margin="0 10px 0 0">
              <StyledLocaleToggle />
            </Container>
          <MessagesMenu />
          {this.renderRecordIcon()}
          {this.renderUserInfo()}
        </Container>
      </Wrapper>
    );
  }
}

NavigationBar.propTypes = {
  intl: PropTypes.object,
  history: PropTypes.object,
  user: PropTypes.object,
  logOut: PropTypes.func,
  defaultPage: PropTypes.string,
  startNewConversation: PropTypes.func,
  connectedToChat: PropTypes.bool,
  dispatchOpenPopUp: PropTypes.func,
};

export const mapStateToProps = (state) => {
  const defaultPage = state.getIn(['app', 'authentication', 'user', 'defaultPage']);
  const { connectedToChat } = state.get('videoChat') ? state.getIn(['videoChat']).toJS() : false;
  const { currentLessonActivity, lessonInProgress } = state.get('currentLesson') ? state.getIn(['currentLesson']).toJS() : false;
  const { exerciseInProgress } = state.get('currentPractice') ? state.getIn(['currentPractice']).toJS() : false;
  const { recordingVideo, connectedToMirror } = state.get('videoMirror') ? state.getIn(['videoMirror']).toJS() : false;
  return {
    defaultPage,
    connectedToChat,
    currentLessonActivity,
    lessonInProgress,
    exerciseInProgress,
    recordingVideo,
    connectedToMirror,
  };
};

export function mapDispatchToProps(dispatch) {
  return {
    logOut: (data) => dispatch(logOutAction(data)),
    startNewConversation: () => dispatch(startNewConversationAction()),
    dispatchOpenPopUp: (type, props) => dispatch(openPopUp(type, props)),
  };
}

const withConnect = connect(mapStateToProps, mapDispatchToProps);
const withReducer = injectReducer({ key: 'messaging', reducer });
const withSaga = injectSaga({ key: 'messaging', saga });

export default compose(
  withReducer,
  withRouter,
  withSaga,
  withConnect,
  withTranslation()
)(NavigationBar);
