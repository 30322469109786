import React from 'react';
import Proptypes from 'prop-types';
import { Pie } from 'react-chartjs-2';
import { ChartColors } from '~/services/theme/colors';

const options = {
  legend: {
    display: false,
    onClick: null,
  },
  tooltips: {
    callbacks: {
       label: null,
    }
  },
  elements: {
    arc: {
      borderWidth: 0,
    }
  },
  animation: {
    duration: 0,
  },
  maintainAspectRatio: true,
  responsive: true,
};

const convertObjectToChartData = (obj, colors) => ({
  labels: Object.keys(obj),
  datasets: [
    {
      data: Object.keys(obj).map((key) => obj[key]),
      backgroundColor: colors,
      hoverBackgroundColor: colors,
    },
  ],
});

const PieWrapper = ({ width, height, data, colors }) => (
  <Pie
    width={width}
    height={height}
    data={convertObjectToChartData(data, colors)}
    options={options}
  />
);

PieWrapper.propTypes = {
  width: Proptypes.number,
  height: Proptypes.number,
  data: Proptypes.object,
  colors: Proptypes.array,
};

PieWrapper.defaultProps = {
  width: 50,
  height: 40,
  colors: ChartColors,
};

export default PieWrapper;
