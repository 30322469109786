import React from 'react';
import PropTypes from 'prop-types';
import withConfigUpdater from '@shared/Resources/Monitor/Client/shared/HOC/ConfigUpdater';
import { STIMULUS_TYPE } from '../../../../../types';
import SyllablesConfig from './components/SyllablesConfig';
import WordsConfig from './components/WordsConfig';
import { SentencesConfig } from './components/SentencesConfig';
import ParagraphsConfig from './components/ParagraphsConfig';
import SpeechTopicsConfig from './components/SpeechTopicsConfig';
import PicturesConfig from './components/PicturesConfig';
import CustomConfig from './components/CustomConfig';

const Stimulusconfig = ({ type, config, updateConfig, updateField }) => {
  const props = {
    updateConfig,
    updateField,
    config,
  };
  switch (type) {
    case STIMULUS_TYPE.SYLLABLES : return <SyllablesConfig {...props} />;
    case STIMULUS_TYPE.WORDS : return <WordsConfig {...props} />;
    case STIMULUS_TYPE.SENTENCES : return <SentencesConfig {...props} />;
    case STIMULUS_TYPE.PARAGRAPHS : return <ParagraphsConfig {...props} />;
    case STIMULUS_TYPE.SPEECH_TOPICS : return <SpeechTopicsConfig {...props} />;
    case STIMULUS_TYPE.PICTURES : return <PicturesConfig {...props} />;
    case STIMULUS_TYPE.CUSTOM : return <CustomConfig {...props} />;
    default : throw new Error(`Missing ${type} stimulus type`);
  }
};


Stimulusconfig.propTypes = {
  type: PropTypes.string,
  updateConfig: PropTypes.func,
  updateField: PropTypes.func,
  config: PropTypes.object,
};

export default withConfigUpdater(Stimulusconfig);
