import { fromJS } from 'immutable';
import { USERS_ACTIONS } from './actions';
import {ORDERS} from "@shared/constants";

export const initialState = fromJS({
  list: [],
  loading: false,
  offset: 0,
  count: 0,
  pageNumber: 1,
  error: false,
  order: ORDERS.ASC,
  sortBy: 'FULL_NAME'
});

export const usersReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case USERS_ACTIONS.SET_LOADING_STATUS: {
      return state.set('loading', payload)
    }
    case USERS_ACTIONS.SHOW_ERROR: {
      return state.set('error', true)
    }
    case USERS_ACTIONS.SET_OFFSET: {
      return state.set('offset', payload);
    }
    case USERS_ACTIONS.SET_USERS: {
      return state.set('list', payload);
    }
    case USERS_ACTIONS.SET_COUNT_USERS: {
      return state.set('count', payload);
    }
    case USERS_ACTIONS.SET_ORDER: {
      return state.set('order', payload);
    }
    case USERS_ACTIONS.SET_SORT_BY: {
      return state.set('sortBy', payload);
    }
    case USERS_ACTIONS.SET_PAGE_NUMBER: {
      return state.set('pageNumber', payload);
    }
    default: {
      return state;
    }
  }
};
