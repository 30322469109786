import { LOG_STATUS } from './consts';

export const getIndexesOfCurrentEntity = (type, pathname) => {
  const indexOfWordSkill = pathname.indexOf('skill');
  const widthOfWordSkill = 'skill'.length;
  const indexOfSkill = pathname.substring((indexOfWordSkill + widthOfWordSkill), pathname.indexOf('/', indexOfWordSkill));

  const indexOfWordEntity = pathname.indexOf(type);
  const widthOfWordEntity = type.length;
  const indexOfEndChart = pathname.indexOf('/', indexOfWordEntity);
  let indexOfEntity = '';
  if (indexOfEndChart === -1) {
    indexOfEntity = pathname.substring((indexOfWordEntity + widthOfWordEntity));
  } else {
    indexOfEntity = pathname.substring((indexOfWordEntity + widthOfWordEntity), indexOfEndChart);
  }

  return { indexOfSkill, indexOfEntity };
};

export const getIndexAndTypeOfPractice = (pathname, courses, type) => {
  const { indexOfSkill, indexOfEntity } = getIndexesOfCurrentEntity(type, pathname);

  let idEntity = '';
  let typeEntity = '';
  if (type === 'lesson') {
    idEntity = courses[0].Skills[+indexOfSkill].Lessons[+indexOfEntity].id;
    typeEntity = courses[0].Skills[+indexOfSkill].Lessons[+indexOfEntity].type;
  } else if (type === 'practice') {
    idEntity = courses[0].Skills[+indexOfSkill].Practices[+indexOfEntity].id;
    typeEntity = courses[0].Skills[+indexOfSkill].Practices[+indexOfEntity].type;
  }

  return { idEntity, typeEntity };
};

export const typeLoggerHelper = (pathname, prevPathname) => {
  const isAnyofOne = (type) => [pathname, prevPathname].some((path) => path.includes(type));

  if (prevPathname.includes('lesson') && pathname.includes('practice')) {
    return 'lesson_to_practice';
  }
  if (isAnyofOne('practice')) {
    return 'practice';
  } if (isAnyofOne('lesson')) {
    return 'lesson';
  }
};

export const loggerHelper = (pathname, prevPathname, callback, courses) => {
  const type = typeLoggerHelper(pathname, prevPathname);

  const conditionList = [pathname.includes(type), prevPathname.includes(type), pathname !== prevPathname, pathname.includes('/', pathname.indexOf(type))];

  if (conditionList[0] && !conditionList[1]) {
    const { idEntity, typeEntity } = getIndexAndTypeOfPractice(pathname, courses, type);
    callback([idEntity], [typeEntity], LOG_STATUS.OPEN);
  } else if (!conditionList[0] && conditionList[1]) {
    const { idEntity, typeEntity } = getIndexAndTypeOfPractice(prevPathname, courses, type);

    callback([idEntity], [typeEntity], LOG_STATUS.CLOSE);
  } else if (conditionList[0] && conditionList[1] && conditionList[2] && !conditionList[3]) {
    const { idEntity: firstIdEntity, typeEntity: firstTypeEntity } = getIndexAndTypeOfPractice(prevPathname, courses, type);
    const { idEntity: secondIdEntity, typeEntity: secondTypeEntity } = getIndexAndTypeOfPractice(pathname, courses, type);

    callback([firstIdEntity, secondIdEntity], [firstTypeEntity, secondTypeEntity], LOG_STATUS.MOVE_BETWEEN);
  } else if (type === 'lesson_to_practice') {
    const { idEntity: firstIdEntity, typeEntity: firstTypeEntity } = getIndexAndTypeOfPractice(prevPathname, courses, 'lesson');
    const { idEntity: secondIdEntity, typeEntity: secondTypeEntity } = getIndexAndTypeOfPractice(pathname, courses, 'practice');

    callback([firstIdEntity, secondIdEntity], [firstTypeEntity, secondTypeEntity], LOG_STATUS.MOVE_BETWEEN);
  }
};
