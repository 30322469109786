import { put, takeLatest, all, fork, join } from 'redux-saga/effects';
import { authenticationWrapper } from '@services/errorHandlingService';
import { getUserData, changePassword, cancelSubscription } from './services';
import {
  setUserInfoAction,
  setChangePasswordResponseAction,
  setChangePasswordResponseFailAction,
  TYPES,
} from './actions';
import { REQUEST_TEXT_ERROR } from '@shared/constants';

export function* callGetUserData() {
  yield authenticationWrapper(function* codeBlock() {
    const res = yield fork(getUserData);
    const userData = yield join(res);
    yield put(setUserInfoAction(userData));
  });
}

export function* callChangePassword({ oldPassword, newPassword }) {
  yield authenticationWrapper(function* codeBlock() {
    const res = yield fork(changePassword, oldPassword, newPassword);
    const response = yield join(res);
    if (response.data) {
      yield put(setChangePasswordResponseAction())
    } else if (!response.data && response.error.message) {
      yield put(setChangePasswordResponseFailAction(response.error.message));
    } else if (!response.data && !response.error.message) {
      yield put(setChangePasswordResponseFailAction(REQUEST_TEXT_ERROR));
    }
  });
}

export function* callCancelSubscription({ planId }) {
  yield authenticationWrapper(function* codeBlock() {
    yield fork(cancelSubscription, planId);
  });
}

function* watchCallGetUserData() {
  yield takeLatest(TYPES.GET_USER_INFO, callGetUserData);
}
function* watchCallChangePassword() {
  yield takeLatest(TYPES.CHANGE_PASSWORD, callChangePassword);
}
function* watchCallCancelSubscription() {
  yield takeLatest(TYPES.CANCEL_SUBSCRIPTION, callCancelSubscription);
}

export default function* settingsSaga() {
  yield all([
    fork(watchCallGetUserData),
    fork(watchCallChangePassword),
    fork(watchCallCancelSubscription),
  ]);
}
