import { ReactElement, ReactNode } from 'react';
import { ItemWithLabelWrapper, ItemTitle } from './styles';

interface ItemWithLabelProps {
  label: ReactElement
  children: ReactNode
}

/**
 * Wrapper for fields in User Information Page
 */
export const ItemWithLabel = ({ children, label }: ItemWithLabelProps) => (
  <ItemWithLabelWrapper>
    <ItemTitle>
      {label}
    </ItemTitle>
    {children}
  </ItemWithLabelWrapper>
);
