/**
*
* MediaOverview
*
*/

import React from 'react';
import PropTypes from 'prop-types';
import Text from '@components/Text';
import { Container, MediaArea } from './styles';
import { TitleContainer, DescriptionArea } from '@shared/Resources/shared/components/OverviewComponents/styles';
import Media from '../../Poll/Client/components/Media';
import {useTranslation} from "react-i18next";

const MediaOverview = ({ resource: { description, value, type } }) => {
    const {t} = useTranslation()
    return (
        <Container>
            <DescriptionArea>
                <TitleContainer>
                    <Text size="H4" weight="bold">
                        {t('Common.UI.description')}
                    </Text>
                </TitleContainer>
                <Text size="H4" className="description">
                    {description || '-'}
                </Text>
            </DescriptionArea>
            <MediaArea>
                <TitleContainer>
                    <Text size="H4" weight="bold">
                        {t('Common.UI.file')}
                    </Text>
                </TitleContainer>
                <Media
                    className="mediaPreview"
                    noHeader
                    contentUrl={value}
                    contentType={type}
                    description="File"
                />
            </MediaArea>
        </Container>
    );
}

MediaOverview.propTypes = {
  resource: PropTypes.object.isRequired,
};

export default MediaOverview;
