import styled from "@emotion/styled";
import {pxToRem} from "@services/theme/utils";

export const SectionTitle = styled('div', {
    shouldForwardProp: props => props !== 'extraSpacings'
})<{ extraSpacings?: boolean }>
(({theme, extraSpacings}) => ({
    borderBottom: `1px solid ${theme.palette.novotalk.$resourcesTableBorderColor}`,
    marginTop: extraSpacings ? pxToRem(20) : 0,
    paddingBottom: pxToRem(12),
}))

export const SectionsWrapper = styled('div')({})