import React, { Component } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { Wrapper, StyledTextArea } from './styles';
import { StyledQuestionIcon } from '../../styles';
import PollQuestionTitle from '../PollQuestionTitle';
import MiniOverviewHeader from '../../shared/MiniOverviewHeader';
import { QUESTION_TYPES } from '../../../../types';

export class FreeTextQuestion extends Component {
  constructor(props) {
    super(props);

    this.state = {
      answer: null,
    };
    this.debouncedUpdateAnswer = _.debounce((answer) => props.updateAnswer(answer), 1000);
  }


  componentWillUnmount() {
    this.debouncedUpdateAnswer.cancel();
  }

  handleChange = (event) => {
    event.persist();
    this.debouncedUpdateAnswer(event.target.value);
    this.setState({ answer: event.target.value });
  }

  render() {
    const {
      questionIndex,
      questionText,
      checkAnswers,
      isDisabled,
      userAnswer,
      miniOverview,
      required,
    } = this.props;
    const { answer } = this.state;
    const error = checkAnswers && !answer && !isDisabled;
    const titleProps = {
      type: 'ENTER',
      questionIndex,
      questionText,
      error,
      required,
    };
    const textAreaProps = {
      disabled: isDisabled,
      error,
      onChange: this.handleChange,
      value: userAnswer,
    };

    return miniOverview ? (
      <MiniOverviewHeader type={QUESTION_TYPES.FREE_TEXT} questionText={questionText} />
    ) : (
      <Wrapper>
        <StyledQuestionIcon />
        <PollQuestionTitle {...titleProps} />
        <StyledTextArea {...textAreaProps} />
      </Wrapper>
    );
  }
}

FreeTextQuestion.propTypes = {
  isDisabled: PropTypes.bool,
  questionIndex: PropTypes.number,
  questionText: PropTypes.string,
  updateAnswer: PropTypes.func,
  checkAnswers: PropTypes.bool,
  userAnswer: PropTypes.string,
  miniOverview: PropTypes.bool,
};

export default FreeTextQuestion;
