import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Wrapper, Cell, Metric, MetricExplanation, Hours } from './styles';
import {withTranslation} from "react-i18next";
import {compose} from "redux";

class OverviewSummary extends Component {
  render() {
    const { metrics, t } = this.props;

    return (
      <Wrapper gridArea={this.props.gridArea}>
        {metrics.map((metric) => {
          const hours = metric.hours
            ? (<Hours>
                {t('Dates.hrs')}
            </Hours>)
            : null;
          return (
            <Cell key={metric.key}>
              <Metric red={metric.red}>
                {metric.value}
                {hours}
              </Metric>
              <MetricExplanation>
                {t(`Informative.Notifying.${metric.key}`)}
              </MetricExplanation>
            </Cell>
          );
        })}
      </Wrapper>
    );
  }
}

OverviewSummary.propTypes = {
  metrics: PropTypes.arrayOf(PropTypes.object),
  gridArea: PropTypes.string,
};

export default compose(
    withTranslation()
)(OverviewSummary)
