/**
 *
 * QuestionInfoDummyDate
 *
 */

import React from 'react';
import Text, { TEXT_SIZE, TEXT_COLOR, TEXT_WEIGHT } from '@components/Text';
import DropdownDatePicker from '@components/DropdownDatePicker';
import { Title, DateContainer } from './styles';
import {useTranslation} from "react-i18next";

function QuestionInfoDummyDate() {
    const {t} = useTranslation()
  return (
    <React.Fragment>
      <Title>
        <Text
          size={TEXT_SIZE.T4}
          textColor={TEXT_COLOR.DARK_BLUE}
          weight={TEXT_WEIGHT.BOLD}
          textTransform='uppercase'
        >
            {t('Dates.date')}
        </Text>
      </Title>
      <DateContainer>
        <DropdownDatePicker isDisabled />
      </DateContainer>
    </React.Fragment>
  );
}

QuestionInfoDummyDate.propTypes = {};

export default QuestionInfoDummyDate;
