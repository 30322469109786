import {styled} from "@mui/material";
import { getIconsByType, iconTypes } from '@services/icons';
import { getResponsiveCSS } from '@utils/cssHelpers';

export const MailIcon = getIconsByType(iconTypes.CUSTOM_MAIL);

export const Wrapper = styled('div')(
  {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    borderRadius: 4,
  },
  getResponsiveCSS('width', 540, 575),
  getResponsiveCSS('paddingTop', 35, 35),
  getResponsiveCSS('paddingBottom', 40, 38),
  getResponsiveCSS('paddingLeft', 75, 92),
  getResponsiveCSS('paddingRight', 75, 92),
  ({ theme }) => ({
    backgroundColor: theme.palette.novotalk.$backgroundColor,
  })
);

export const IconContainer = styled('div')({
  '& > svg': {
    width: 140,
    height: 140,
  },
  marginBottom: 25,
});

export const TitleContainer = styled('div')({
  textAlign: 'center',
  marginBottom: 15,
});

export const TextContainer = styled('div')({
  textAlign: 'center',
  letterSpacing: '-0.5px',
});
