import {styled} from "@mui/material";

export const Wrapper = styled('div')(
  {
    display: 'flex',
    justifyItems: 'center',
    justifyContent: 'flex-start',
    flexDirection: 'column',
    alignItems: 'center',
    borderBottom: '1px solid',
    position: 'absolute',
    width: 340,
    transition: 'all 0.8s',
    zIndex: 100,
    overflow: 'auto',
    borderRight: '1px solid',
  },
  ({ theme, show }) => ({
    direction: theme.RTL ? 'rtl' : 'ltr',
    minHeight: `calc(100vh - ${theme.palette.novotalk.skillPartHeaderHeight}px - ${theme.palette.novotalk.navigationBarHeight}px)`,
    maxHeight: `calc(100vh - ${theme.palette.novotalk.skillPartHeaderHeight}px - ${theme.palette.novotalk.navigationBarHeight}px)`,
    top: theme.palette.novotalk.skillPartHeaderHeight,
    backgroundColor: theme.palette.novotalk.$backgroundColor,
    borderColor: theme.palette.novotalk.$textColorDisabledLight,
    left: show ? '-1px' : '-390px',
  })
);

export const ContentWrapper = styled('div')({
  padding: '0 25px',
  width: '100%',
});

export const DescriptionWrapper = styled(ContentWrapper)({
  display: 'flex',
  flexDirection: 'column',
  padding: 25,
});
