import {styled} from "@mui/material";
import { getIconsByType, iconTypes } from '@services/icons';
import isPropValid from '@emotion/is-prop-valid';

const ErrorIcon = getIconsByType(iconTypes.ERROR_FILLED);
export const StyledErrorIcon = styled(ErrorIcon, {
  shouldForwardProp: isPropValid,
})(
  {
    fontSize: '18px',
  },
  ({ theme }) => ({
    margin: theme.RTL ? '-2px -2px 0 3px' : '-2px 3px 0 -2px',
    color: theme.palette.novotalk.$errorColor,
  })
);

export const ErrorWrapper = styled('div')({
  letterSpacing: '-0.2px',
  marginTop: -4,
});
