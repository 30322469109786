import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { withRouter } from 'react-router-dom';
import _ from 'lodash';
import { withTranslation } from 'react-i18next';
import injectReducer from '@utils/injectReducer';
import injectSaga from '@utils/injectSaga';
import SectionLoading from '@components/SectionLoading';
import { ROUTES } from '@shared/constants';
import { stopVideoRecording } from '@shared/VideoMonitor/actions';
import { getCourses } from '@containers/User/containers/CourseRouter/actions';
import { Wrapper, LoadingOverlay } from './styles';
import {
  getCurrentPractice, resetPractice, setCurrentExercise, resetCurrentExercise,
} from './actions';
import reducer from './reducer';
import saga from './saga';
import SkillPartHeader from '../../../../components/SkillPartHeader';
import PracticeMainPane from './containers/PracticeMainPane';
import ExercisesPane from './containers/ExercisesPane';
import PracticeSummary from './containers/PracticeSummary';
import POPUP_TYPES from '../../../../../App/containers/PopUp/types';
import { openPopUp as openPopUpAction } from '../../../../../App/containers/PopUp/actions';
import { getNewFeedbacks } from '../../../../helpers/feedbackHelpers';

export class Practice extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showPracticeSummary: false,
      justFinished: false,
    };

    this.practiceNavigationConsts = {
      NEXT_PRACTICE: 'NEXT_PRACTICE',
      CURRENT_PRACTICE: 'CURRENT_PRACTICE',
      PREVIOUS_PRACTICE: 'PREVIOUS_PRACTICE',
    };
  }

  componentDidMount() {
    const {
      dispatchGetCurrentPractice,
      skillpartIndex,
      skill,
    } = this.props;

    if (!_.isEmpty(skill)) {
      dispatchGetCurrentPractice(skill.Practices[skillpartIndex].id);
    }
  }

  componentDidUpdate(prevProps) {
    const {
      loading,
      skillpartIndex,
      skill,
      practice,
      dispatchGetCurrentPractice,
      exerciseIndex,
      dispatchSetCurrentExercise,
      openPopUp,
    } = this.props;

    if (prevProps.practice.hasPassed !== practice.hasPassed) {
      this.setState({ showPracticeSummary: practice.hasPassed });

      if (!practice.hasPassed) {
        const redirectToPreviousPractice = skillpartIndex === 0
          ? null
          : () => this.navigateBetweenPractices(
            this.practiceNavigationConsts.PREVIOUS_PRACTICE,
          );

        openPopUp(POPUP_TYPES.PRACTICE_OVERVIEW, {
          practice,
          redirectToPreviousPractice,
          hideCloseIcon: true,
          disableBackdrop: true,
        });
      }
    }

    if (!_.isEmpty(skill)
      && practice.id !== skill.Practices[skillpartIndex].id
      && !loading
    ) {
      dispatchGetCurrentPractice(skill.Practices[skillpartIndex].id);
    }

    if (!loading && !_.isEmpty(practice)) {
      dispatchSetCurrentExercise(practice.exercises[exerciseIndex || 0]);
    }

    if (!loading
      && !_.isEmpty(this.props.practice)
      && !_.isEmpty(this.props.skill)
      && (prevProps.practice.id !== this.props.practice.id || !_.isEqual(this.props.skill, prevProps.skill))
    ) {
      this.startPractice();
    }
  }

  componentWillUnmount() {
    this.props.dispatchResetActivityPractice();
    this.props.dispatchResetCurrentExercise();
  }

  getAndShowFeedback = () => {
    const { practice, openPopUp } = this.props;
    const latestFeedbacks = getNewFeedbacks(practice.Activities);
    const feedbacks = latestFeedbacks.filter((fb) => !fb.seenBy.includes(this.props.user.id) && !fb?.payload?.isDismiss);
    if (practice.hasPassed) {
      this.setState({ showPracticeSummary: true });
    }

    if (feedbacks.length > 0) {
      openPopUp(POPUP_TYPES.CLINICIAN_FEEDBACK, {
        practiceName: practice.name,
        feedbacks,
        isEnteringPractice: true,
      });
    }
  };

  startPractice = () => {
    const { practice } = this.props;

    if (practice.hasPassed) {
      this.setState({ showPracticeSummary: true });
    }
    this.getAndShowFeedback();
  };

  restartPractice = () => {
    this.navigateBetweenPractices(
      this.practiceNavigationConsts.CURRENT_PRACTICE,
    );
    this.setState({
      showPracticeSummary: false,
      justFinished: false,
    });
  };

  navigateBetweenPractices = (direction) => {
    const {
      NEXT_PRACTICE,
      CURRENT_PRACTICE,
      PREVIOUS_PRACTICE,
    } = this.practiceNavigationConsts;

    const {
      match: {
        params: { courseIndex, skillIndex },
      },
      history,
      skillpartIndex,
      dispatchGetCourses,
    } = this.props;

    if (direction === NEXT_PRACTICE) {
      dispatchGetCourses();
    }

    let path = `${ROUTES.COURSE_PAGE}/${courseIndex}/${skillIndex}/practice`;
    switch (direction) {
      case NEXT_PRACTICE:
        path += `${skillpartIndex + 1}`;
        break;
      case CURRENT_PRACTICE:
        path += `${skillpartIndex}`;
        break;
      case PREVIOUS_PRACTICE:
        path += `${skillpartIndex - 1}`;
        break;
      default:
        break;
    }
    history.push(path, { nextPath: true });
  };

  finishPractice = () => {
    const { dispatchGetCurrentPractice, skillpartIndex, skill } = this.props;
    dispatchGetCurrentPractice(skill.Practices[skillpartIndex].id);
    this.setState({
      showPracticeSummary: true,
      justFinished: true,
    });
  };

  backToCoursePage = () => {
    const { history } = this.props;
    // will be used to dispatchGetCourses when going back to overview
    history.push(ROUTES.COURSE_PAGE, { getCourses: 'getCourses' });
  };

  render() {
    const {
      skill, practice, loading, skillpartIndex, i18n,
    } = this.props;
    const { showPracticeSummary, justFinished } = this.state;
    const isLastPractice = skill.Practices.length - 1 === skillpartIndex;
    const practiceSummaryProps = {
      practice,
      skill,
      justFinished,
      backToCoursePage: this.backToCoursePage,
      restartPractice: this.restartPractice,
      goToNextPractice: isLastPractice
        ? null
        : () => {
          this.navigateBetweenPractices(
            this.practiceNavigationConsts.NEXT_PRACTICE,
          );
        },
    };

    return (
      <Wrapper language={i18n.language}>
        <SkillPartHeader skill={skill} />
        {loading && <LoadingOverlay><SectionLoading /></LoadingOverlay>}
        {showPracticeSummary ? (
          <PracticeSummary {...practiceSummaryProps} />
        ) : (
          <PracticeMainPane finishPractice={this.finishPractice} />
        )}
        <ExercisesPane />
      </Wrapper>
    );
  }
}

Practice.propTypes = {
  dispatchGetCurrentPractice: PropTypes.func,
  dispatchSetCurrentExercise: PropTypes.func,
  dispatchGetCourses: PropTypes.func,
  dispatchResetActivityPractice: PropTypes.func,
  dispatchStopVideoRecording: PropTypes.func,
  dispatchResetCurrentExercise: PropTypes.func,
  skill: PropTypes.object,
  practice: PropTypes.object,
  loading: PropTypes.bool,
  openPopUp: PropTypes.func,
  history: PropTypes.object,
  skillpartIndex: PropTypes.number,
  exerciseIndex: PropTypes.number,
  match: PropTypes.object,
};

export const mapStateToProps = (state) => {
  const currentPractice = state.getIn(['currentPractice']).toJS();
  const user = state.getIn(['app', 'authentication', 'user']).toJS();
  const { practice, loading, currentExercise } = currentPractice;
  return {
    practice, loading, currentExercise, user,
  };
};

export function mapDispatchToProps(dispatch) {
  return {
    dispatchGetCurrentPractice: (id) => dispatch(getCurrentPractice(id)),
    dispatchSetCurrentExercise: (exercise) => dispatch(setCurrentExercise(exercise)),
    dispatchResetActivityPractice: () => dispatch(resetPractice()),
    dispatchStopVideoRecording: () => stopVideoRecording(),
    dispatchGetCourses: () => dispatch(getCourses()),
    openPopUp: (type, props) => dispatch(openPopUpAction(type, props)),
    dispatchResetCurrentExercise: () => dispatch(resetCurrentExercise()),
  };
}

const withConnect = connect(mapStateToProps, mapDispatchToProps);
const withReducer = injectReducer({ key: 'currentPractice', reducer });
const withSaga = injectSaga({ key: 'currentPractice', saga });

export default compose(
  withReducer,
  withRouter,
  withSaga,
  withConnect,
  withTranslation(),
)(Practice);
