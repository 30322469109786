import { call, put, takeLatest, all, fork, join } from 'redux-saga/effects';
import _ from 'lodash';
import { TYPES, setPendingVideoChatInfo } from './actions';
import { graphQuery } from '@services/commService';
import ChatService from '@services/chatService';

import pendingVideoChatInfoMock from './__fixtures__/videoChatSessionInfo';

const useFixtures = process.env.USE_FIXTURES;

const getPendingEvents = async () => {
  // TODO:  update query when the node has arguments
  const query = `
    {
      events {
        type
        id
        createdBy
      } 
    }
  `;
  const res = await graphQuery(query, true);
  const result = _.get(res, 'data.events', []);
  return result;
};

const getPendingVideoChatInfo = async () => {
  const events = await getPendingEvents();
  const pendingVideoChatInfo = _.find(events, { type: 'VIDEO_CHAT' });
  return pendingVideoChatInfo;
};

const checkInvitation = async () => {
  if (useFixtures) {
    const showPopup = false;
    return showPopup ? pendingVideoChatInfoMock : null;
  }
  const pendingVideoChatInfo = await getPendingVideoChatInfo();
  return pendingVideoChatInfo;
};

export function* callCheckInvitation() {
  try{
  const res = yield fork(checkInvitation);
  const pendingVideoChatInfo = yield join(res);
  if (pendingVideoChatInfo) {
    const response = yield fork(
      ChatService.getChatInfo,
      pendingVideoChatInfo.id
    );
    const sessionInfo = yield join(response);
    const selfUser = _.find(sessionInfo.users, { self: true });
    if (!selfUser.initiator) {
      yield put(setPendingVideoChatInfo(pendingVideoChatInfo));
    }
  } else {
    yield put(setPendingVideoChatInfo(null));
  }
} catch(e){
  yield put(setPendingVideoChatInfo(null));
}
}

function* watchCallCheckInvitation() {
  yield takeLatest(TYPES.CHECK_FOR_WAITING_VIDEO_CALL, callCheckInvitation);
}

export default function* pollingServiceSaga() {
  yield all([fork(watchCallCheckInvitation)]);
}
