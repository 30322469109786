const map = {
  'Wave exercise': {
    'Non continious air release': 'it is important not to stop the flow of air during exhalation. All syllables of a word should be produced on a single breath.',
    'Loud voice - more air is released': 'You are not going low enough at the low points of the pattern,try to control the amount of air you release and use less air at the end and begining of each syllable. ',
    'Gentle voice - less air is released': 'Make sure to increase your amount of air released to the high points of the pattern, so you will produce loud voice',
    'touch baseline': 'The wave pattern should only touch the baseline at the beginning and end of the wave pattern.',
  },
  'short words': {
    'Light contact for consonants': 'Produce the consonants using light contact',
    'Vowels - exaggerated open mouth': 'Make sure to use exaggerated open mouth during articulation of the vowel',
    'Match the shaded pattern': 'Try to match the sound production and follow the goal of the shaded pattern',
    'Continuous voicing': 'Continuous voicing for the entire word, inhalation only at the end of the word. Continous voicing is a major factor in maintaining fluency',
    '1 sec pattern': `make sure that you inhale fully, follow this with a gentle onset and gentle voice, gradual transition to a loud voice using larger mouth 
    opening and control of abdominal breathing. Return to gentle voice at the end of the pattern. Watch the Monitor and keep to the pattern`,

  },
  '1 second per syllable -errors': {
    'Too gentle': `Make sure that the pattern does not reach the baseline.Make sure that there is sufficient vocal volume at the end of the syllable. It is very 
    difficult and takes effort to raise the volume once it has dropped too low in an incorrect place in the word`,
    'Too loud': `Make sure that loud voice is not too loud at the end of a syllable or is produced for too long. Continuous use of a loud voice results in 
    vocal strain and too much effort. If voice is too loud , it is far more difficult to start the next syllable using a gentle voice.`,
    'Mouth opening': 'You can control the amount of air you release by changing mouth openings. ',
    linking: 'Try not to go down to the baseline - the vocal volume should not reach the baseline at the end of each syllable, only at the end of the word. ',
    'Too fast': 'Your productions were too fast, Pay attention to the shaded pattern on the Monitor and try to follow it.  ',
    'Too slow': 'Your productions were too slow and lasted more than necessary. Pay attention to the shaded pattern on the Monitor and try to follow it.  ',
  },


  'Advanced  breathing exercise': {
    'Inhalation - too short': ' Watch the  progress bars and counter on the Monitor.',
    'Inhalation - too long': `Make sure that you the diaphragm moves quickly and sharply outwards for inhalation. You can then control the amount of air inhaled 
    without straining the body. Watch the progress bars and counter on the Monitor`,
    'Exhalation - too short': `Make sure that the abdomen is slowly pushed inwards in a controlled manner. The diaphragm controls the breathing mechanism. Watch
    the progress bars and counter on the Monitor`,
    'Exhalation - too long': 'Watch the progress bars and counter on the Monitor',
    'Use of chest breathing': 'Make sure that you are breathing using the diaphragm and not from the chest , which is more strained and unnatural for speech.',
    'Volume control': 'Make sure that vocal volume is relatively quiet, this will enable you to keep the volume steady and constant ',
  },
  'Multi syllable words': {
    Linkage: 'Your should reach the baseline only at the beginning and end of the word and not in the middle of a word.',
    'Wave pattern': 'Make sure to always use the wave pattern for each syllable of the word',
    'Length of inhalation ': 'Make sure to inhale enough air using diaphragmatic breathing',
    'Syllable production length': 'Watch the progress bars on the Monitor to make sure that you are within the 1 second per syllable pattern',
    'Air calculation': `Try to divide the amount of air used equally between all the syllables of the word. This ensures that you have enough air to reach the end of
    the word`,
  },
  '1 second per syllable - pattern- sentences': {
    'Sentense makes sense': 'It is important to maintain the meaning of the sentence. Do not stop to breathe in the middle of a word, this will impact the meaning of the utterance. Stop to breathe in a logical place so that the meaning of the sentence is not changed in any way.',
    'Integration of breathing and speech': 'Watch the monitor and remember to inhale between each sentence. Do not stop to breathe in the middle of a word.',
  },
  '1 second per syllable - without visual support related to inhalation ': {
    'Inhaling mid word': 'Do not inhale mid word as this will impact the meaning of the word and the entire sentence. Inhale only at the end of a word',
    'Planning when to breathe': 'Try to plan at which point and after how many syllables you are going to inhale. Generally at the 1 second rate, 4-5 syllables is the optimum number. Do not inhale mid word as this will impact the meaning of the word and the entire sentence. Inhale only at the end of a word. Drop to the baseline only at the end of all of the words which are said on the same breath. ',
  },
  '1 second per syllable - spontaneous speech': {
    'Too many syllables said on one breath': 'You are pronouncing too many syllables on one breath, which may cause inhaling in the middle of a word because you find that you have run out of breath. Inhale at the end of a word. Say less syllables if necessary and make sure to have inhaled quickly and deeply so that there is sufficient air.',
    'Chest breathing': `You are using chest breathing, which demands more effort and less air enters the body. This might make it harder for you to speak for an extended amount of time is and far less
    effective than diaphragmatic breathing.`,
  },
  'Intonation and spontaneous speech - 1 second per syllable ': {
    'Integration of breathing and speech': `Make sure that you integrate diaphragmatic breathing, mouth opening and light contact when producing words in the utterance. Make sure
    to use the 1 second per syllable pattern and don't speak to quickly or slowly. `,
    'Touching the baseline': 'The wave pattern should only touch the baseline at the beginning and end of the wave pattern. Breaks in the voice during sound production are shown by the wave touching the baseline.  A drop in vocal volume should only appear at the end of the pattern',
    'Not touching the baseline': 'The wave stays above the baseline ( only to 2/3  way down) showing airflow was continous through the sound production',
    'Minimal volume': 'A drop to minimal volume only occurs at the beginning and end of the whole word and not at any time during the word',
    'Touching the baseline mid word': 'The baseline is only reached at the beginning and end of the word and not in the middle of a word.',
  },
};
export default map;
