import {styled} from "@mui/material";
import CTAButton from '@components/CTAButton';
import ArrowBoxBase from '@components/ArrowBox';
import CustomTextInput from '@components/CustomTextInput';
import {
  getIconByResourceType,
  getIconsByType,
  iconTypes,
} from '@services/icons';
import { RESOURCES } from '@shared/Resources/types';
import { getResponsiveCSS } from '@utils/cssHelpers';

export const ArrowBox = styled(ArrowBoxBase)(
  {
    padding: '5px 15px',
    width: 140,
  },
  ({ theme }) => ({
    borderColor: theme.palette.novotalk.borderColorLight,
    '&:before': {
      borderBottomColor: theme.palette.novotalk.borderColorLight,
    },
  })
);

export const Wrapper = styled('div')({
  gridArea: 'Header',
  display: 'grid',
  gridTemplateColumns: '54% auto',
  paddingTop: 50,
  alignItems: 'start',
});

export const ResourcesItemsHeader = styled('div')(
  {
    paddingLeft: 20,
    paddingRight: 16,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  getResponsiveCSS('paddingLeft', 19, 44)
);

export const getStyledHeaderIcon = (type) =>
  styled(getIconByResourceType(type))(
    {
      fontSize: type === RESOURCES.STIMULUS ? 24 : 28,
      width: type === RESOURCES.STIMULUS ? 24 : 28,
      marginRight: 12,
    },
    ({ theme }) => ({
      color: theme.palette.novotalk.$textColorDarkBlue,
    })
  );

export const StyledCustomTextInput = styled(CustomTextInput)(
  {
    minHeight: 38,
    maxHeight: 38,
    outline: 'none',
  },
  getResponsiveCSS('width', 172, 322),
  getResponsiveCSS('maxHeight', 38, 42),
  ({ theme }) => ({
    backgroundColor: theme.palette.novotalk.$resourcesInputBgColor,
    borderColor: theme.palette.novotalk.$resourcesInputBgColor,
  })
);

export const StyledSearchIcon = styled(getIconsByType(iconTypes.SEARCH))(
  {
    fontSize: 18,
    marginRight: 10,
  },
  ({ theme }) => ({
    color: theme.palette.novotalk.$textColorLightGray,
  })
);

export const ButtonContainer = styled('div')({
  padding: '0 20px',
  display: 'flex',
  justifyContent: 'flex-end',
  position: 'relative',
});

export const StyledCTAButton = styled(CTAButton)(
  getResponsiveCSS('width', 140, 180),
  getResponsiveCSS('height', 44, 48)
);

export const StyledAddIcon = styled(getIconsByType(iconTypes.ADD))({
  fontSize: 13,
  marginRight: 3,
  marginBottom: -2,
});

export const ResourceItem = styled('div')(
  {
    lineHeight: '16px',
    height: 30,
    cursor: 'pointer',
  },
  ({ theme }) => ({
    color: theme.palette.novotalk.textColor,
  })
);

export const getStyledMenuIcon = (type) =>
  styled(getIconByResourceType(type))(
    {
      fontSize: type === RESOURCES.STIMULUS ? 12 : 16,
      width: type === RESOURCES.STIMULUS ? 12 : 16,
      marginRight: type === RESOURCES.STIMULUS ? 8 : 10,
      marginLeft: type === RESOURCES.STIMULUS ? 2 : 0,
      marginTop: -2,
    },
    ({ theme }) => ({
      color: theme.palette.novotalk.$textColorDarkBlue,
    })
  );

export const ArrowBoxContainer = styled('div')({
  width: 0,
  position: 'relative',
  right: 55,
  top: -10,
});
