import { fromJS } from 'immutable';
import { EditorObjectsFactory } from '@containers/Admin/containers/Builder/editorObjects/editorObjectsFactory';
import { setRoot } from "@containers/Admin/containers/Builder/reducers/rootObject";
import { nanoid } from 'nanoid';

export const reduceAddComponent = (state, action, rootObject) => {
  const { type, customPayload } = action.payload;
  const newComponent = EditorObjectsFactory.createObject(type);
  const newRootObject = EditorObjectsFactory.createFromJSON(rootObject);

  if (customPayload) newComponent.setPayload(customPayload);

  newRootObject.addChild(newComponent);
  newRootObject.children.forEach((child, index) => {
    if (!child.id && child.payload.id) {
      this[index].id = child.payload.id;
    }
    if (child.type === 'QUESTION' || child.type === 'TEXT') {
      child.id = nanoid();
      child.payload.id = nanoid();
    }
  }, newRootObject.children);
  setRoot(newRootObject);
  return state
    .setIn(['editState', 'dirty'], true)
    .set('components', fromJS(newRootObject.children));
};

export const reduceUpdateComponentIndex = (state, action, rootObject) => {
  const { id, index: targetIndex } = action.payload;

  const sourceIndex = rootObject.children.findIndex((child) => {
    const childId = child.id ? child.id : child.payload._id;
    return childId === id;
  });
  const child = rootObject.children[sourceIndex];

  if (sourceIndex > targetIndex) {
    rootObject.children.splice(targetIndex, 0, child);
    rootObject.children.splice(sourceIndex + 1, 1);
  }
  if (sourceIndex < targetIndex) {
    rootObject.children.splice(targetIndex + 1, 0, child);
    rootObject.children.splice(sourceIndex, 1);
  }
  return state
    .setIn(['editState', 'dirty'], true)
    .set('components', fromJS(rootObject.children));
};

export const reduceUpdateComponentPayload = (state, action, rootObject) => {
  const { id, newPayload } = action.payload;
  const objectToUpdate = rootObject.getChildById(id);
  if (objectToUpdate) {
    objectToUpdate.setPayload(newPayload);
    objectToUpdate.id = newPayload.id;
  }
  return state
    .setIn(['editState', 'dirty'], true)
    .set('components', fromJS(rootObject.children));
};

export const reduceRemoveComponent = (state, action, rootObject) => {
  const { id } = action.payload;
  
  const childToRemove = rootObject.getChildById(id);
  
  if (childToRemove) {
    
    rootObject.removeChild(childToRemove);
  } else {
    
    throw new Error(`Object with id (${id}) doesn't exist`);
  }
  
  return state
    .setIn(['editState', 'dirty'], true)
    .set('components', fromJS(rootObject.children));
};

export const reduceDuplicateComponent = (state, action, rootObject) => {
  const { id } = action.payload;
  const sourceIndex = rootObject.children.findIndex((child) => child.id === id);
  const childToDuplicate = rootObject.getChildById(id);
  if (childToDuplicate) {
    const newComponent = EditorObjectsFactory.createObject(
      childToDuplicate.type
    );
    newComponent.setPayload(childToDuplicate.payload);
    rootObject.addChild(newComponent);
    newComponent.id = nanoid();
    rootObject.children.splice(sourceIndex + 1, 0, newComponent);
    rootObject.children.pop();
  } else {
    throw new Error(`Object with id (${id}) doesn't exist`);
  }
  return state
    .setIn(['editState', 'dirty'], true)
    .set('components', fromJS(rootObject.children));
};

export const reduceCreateNewResource = (state, action) => {
  const { type, creating, resourceID } = action.payload;
  return state
    .set('type', type)
    .set('creating', creating)
    .set('id', resourceID)
}
