import {styled} from "@mui/material";
import TextAreaBase from '@components/TextArea';

export const Wrapper = styled('div')({
  marginBottom: 20,
});

export const TextArea = styled(TextAreaBase)({
  width: '100%',
  height: 90,
  marginTop: 10,
  padding: '14px 18px',
  resize: 'none',
  borderRadius: 8,
});
