import { styled } from '@mui/material';
import { isDesktop } from '@shared/constants/media-queries';
import Text from '@components/Text';

export const ItemWithLabelWrapper = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  marginBottom: isDesktop() ? 90 : 35,
});

export const ItemTitle = styled(Text)(
  {
    margin: '0 0 2px 0',
    display: 'block',
    textTransform: 'uppercase',
  },
  ({ theme }) => ({
    color: theme.palette.novotalk.$textColorDisabled,
  }),
);
