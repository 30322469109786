import {styled} from "@mui/material";
import { getIconsByType, iconTypes } from '@services/icons';

const AddIcon = getIconsByType(iconTypes.ADD);

const BUTTON_SIZE = 48;

export const PlusIcon = styled(AddIcon)(
  {
    position: 'relative',
    fontSize: 41,
    strokeWidth: 15,
  },
  ({ theme }) => ({
    color: theme.palette.novotalk.$textColorReversed,
  })
);

export const WidgetsContainerPopover = styled('div')(
  {
    position: 'absolute',
    height: 0,
    width: 0,
    right: 24,
  },
  ({ open }) => ({
    display: open ? 'inline' : 'none',
  })
);

export const WidgetsSelectionContainer = styled('div')({
  height: 0,
  position: 'relative',
});

export const WidgetsButton = styled('button')(
  {
    width: BUTTON_SIZE,
    height: BUTTON_SIZE,
    borderRadius: BUTTON_SIZE,
    lineHeight: 0,
    outline: 'none',
    cursor: 'pointer',
    padding: 0,
  },
  ({ theme }) => ({
    backgroundColor: theme.palette.novotalk.actionButtonColor,
    '&:hover': {
      backgroundColor: theme.palette.novotalk.actionButtonColorHover,
    },
    '&:active': {
      backgroundColor: theme.palette.novotalk.actionButtonColorActive,
    },
  })
);
