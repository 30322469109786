import {styled} from "@mui/material";
import {getIconsByType, iconTypes} from "@services/icons/index.js";
import Text, {TEXT_WEIGHT} from "@components/Text/index.js";
import {pxToRem} from "@services/theme/utils";

export const TitleUserPersonalProgram = styled(Text)(({theme}) => ({
        marginBottom: pxToRem(30),
        display: 'block',
        fontSize: pxToRem(26),
        fontWeight: TEXT_WEIGHT.BOLD,
    })
)

export const UserProgramLayout = styled('div')({
    maxWidth: pxToRem(630),
});

export const WarningIcon = styled(getIconsByType(iconTypes.ERROR_FILLED))({
        width: pxToRem(56),
        height: pxToRem(56),
    },
    ({theme}) => ({
        color: theme.palette.novotalk.$ctaButtonColorActive
    })
);

export const SkillsList = styled('div')({
    '&:last-of-type': {
        marginBottom: pxToRem(10),
    },
})

export const ExpandButtonContainer = styled('div')({
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    marginBottom: pxToRem(25),
});

export const ButtonText = styled(Text)({
    fontSize: pxToRem(14),
})

export const ResourceTitle = styled(Text)({
    fontWeight: TEXT_WEIGHT.BOLD,
    fontSize: pxToRem(20),
})