import {styled} from "@mui/material";
import { getIconsByType, iconTypes } from '@services/icons';

export const PlayIcon = getIconsByType(iconTypes.PLAY_CIRCLE);
export const PauseIcon = getIconsByType(iconTypes.PAUSE);

export const PlayButton = styled('button')(
  {
    outline: 'none',
    borderRadius: '50%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: 0,
  },
  ({ theme, isPlaying }) => ({
    width: isPlaying ? 22 : 26,
    height: isPlaying ? 22 : 26,
    fontSize: isPlaying ? 14 : 26,
    margin: isPlaying ? 2 : 0,
    color: theme.palette.novotalk.$activeColor,
    border: isPlaying ? `1px solid ${theme.palette.novotalk.$activeColor}` : 'none',
  })
);

export const Wrapper = styled('div')(
  {
    display: 'grid',
    gridTemplateColumns: '6% 50px auto 16% 17% 205px',
    padding: '10px 0',
  },
  ({ theme }) => ({
    borderTop: `solid 1px ${theme.palette.novotalk.$widgetMenuBorderColor};`,
  })
);

export const StimuliWrapper = styled('div')({});

export const WordWrapper = styled('span')({
  marginRight: 5,
});

export const SyllableWrapper = styled('span')({
  marginRight: 5,
});

export const ErrorsWrapper = styled('div')({
  marginTop: 4,
});

export const ErrorItem = styled('div')({
  display: 'flex',
  alignItems: 'center',
  marginBottom: 5,
});

export const ErrorIconWrapper = styled('div')(
  {
    width: 18,
    display: 'flex',
    alignItems: 'center',
  },
  ({ theme }) => ({
    '& svg circle': {
      stroke: theme.palette.novotalk.$warningColor,
    },
    '& svg path': {
      fill: theme.palette.novotalk.$warningColor,
    },
  })
);

export const StyledCheckmarkIcon = styled(getIconsByType(iconTypes.CHECKMARK))(
  {
    fontSize: 21,
  },
  ({ theme }) => ({
    color: theme.palette.novotalk.$completedColor,
  })
);
