import { useTranslation } from 'react-i18next';
import Text from '@components/Text';
import { ACTIVITY_MEDIA_TYPES } from '@shared/Resources/types';
import { ActivityTitle, SkillTitle } from './styles';

/**
 * Lesson activity panel component
 */
export const LessonActivityPanel = ({ activity }) => {
  const { t } = useTranslation();
  const media = activity.media
    .filter(({ type }) => type === ACTIVITY_MEDIA_TYPES.VIDEO)
    .map(({ publicUrls }) => {
      if (publicUrls?.length > 0) {
        return <video controls src={publicUrls[0]} />;
      }
      return null;
    });

  return (
    <>
      <ActivityTitle size="H3">
        {t('UI.typeAndTitle', {
          type: activity.type.split('_').at(1),
          title: activity.lesson.name,
        })}
      </ActivityTitle>
      <SkillTitle size="H4">
        {t('UI.skillTitle', {
          title: activity.skill.name,
        })}
      </SkillTitle>
      <Text size="H4" margin="0 0 29px 0" textTransform="uppercase">
        {t('UI.lessonRecording')}
      </Text>
      {media}
    </>
  );
};
