import React, { createContext, useReducer } from 'react';
import { SelfContainer } from '../SelfContainer';
import { OtherVideos } from '../OtherVideos';
import { TextChatWrapper } from '../TextChatWrapper';
import { VideoBtn } from '../VideoBtn';
import SubscriberVideo from '../SubscriberVideo';
import { SubscribersSubContainer } from '../SubscribersSubContainer';
import { Subscriber } from '../Subscriber';
import { SubAndSharingContainer } from '../SubAndSharContainer';
import { SelfVideo } from '../SelfVideo';
import { ScreenSharingBtn } from '../ScreenSharingBtn';
import { StopBtn } from '../StopBtn';
import { ScreenSharing } from '../ScreenSharing';
import { Publisher } from '../Publisher';
import { ChatEnded } from '../ChatEnded';
import { AudioBtn } from '../AudioBtn';
import { VideoChatMonitorBtn } from '../VideoChatMonitorBtn';
import { videoCallReducer, initialVideoCallState } from './reducer';
import { VideoCallPreContainer } from '../VideoCallPreContainer';

export const VideoCallContext = createContext();

const VideoCall = ({ children }) => {
  const [stateContext, dispatchContext] = useReducer(
    videoCallReducer,
    initialVideoCallState
  );

  const value = { stateContext, dispatchContext };
  return (
    <VideoCallContext.Provider value={value}>
      {children}
    </VideoCallContext.Provider>
  );
};
VideoCall.Container = VideoCallPreContainer;
VideoCall.SelfContainer = SelfContainer;
VideoCall.OtherVideos = OtherVideos;
VideoCall.TextChatWrapper = TextChatWrapper;
VideoCall.VideoBtn = VideoBtn;
VideoCall.SubscriberVideo = SubscriberVideo;
VideoCall.SubscribersSubContainer = SubscribersSubContainer;
VideoCall.Subscriber = Subscriber;
VideoCall.SubAndSharingContainer = SubAndSharingContainer;
VideoCall.SelfVideo = SelfVideo;
VideoCall.ScreenSharingBtn = ScreenSharingBtn;
VideoCall.ScreenSharing = ScreenSharing;
VideoCall.Publisher = Publisher;
VideoCall.ChatEnded = ChatEnded;
VideoCall.AudioBtn = AudioBtn;
VideoCall.VideoChatMonitor = VideoChatMonitorBtn;
VideoCall.StopBtn = StopBtn

export default VideoCall;
