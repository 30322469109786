import React from 'react';
import PropTypes from 'prop-types';
import Text, { TEXT_SIZE, TEXT_COLOR, TEXT_WEIGHT } from '@components/Text';
import { NameContainer, Title, StyledExerciseIcon } from './styles';
import EditorComponentWrapper from '@shared/Resources/shared/components/BuilderComponentWrapper';
import EntitySelectionOptions from '@shared/Resources/shared/components/EntitySelectionOptions';
import { EXERCISE } from '../../types';
import {withTranslation} from "react-i18next";
import {compose} from "redux";

class EditorExercise extends React.Component {
  // eslint-disable-line react/prefer-stateless-function
  constructor(props) {
    super(props);
    this.state = {
      payload: props.payload,
    };
  }

  onItemSelect = (payload) => {
    this.setState({ payload });
    this.props.submitPayload(payload);
  };

  renderEntity = (payload) => (
    <div className='entityOverview'>
      <NameContainer>
        <Text
          size={TEXT_SIZE.T1}
          textColor={TEXT_COLOR.DARK_BLUE}
          weight={TEXT_WEIGHT.BOLD}
        >
          {payload.name}
        </Text>
      </NameContainer>
      <Title>
        <StyledExerciseIcon />
        <Text
          size={TEXT_SIZE.T4}
          textColor={TEXT_COLOR.DISABLED}
          weight={TEXT_WEIGHT.BOLD}
          textTransform='uppercase'
        >
          {this.props.t('Common.UI.instructions')}
        </Text>
      </Title>
      <div className='instructions'>
        <Text size={TEXT_SIZE.H4} textColor={TEXT_COLOR.DARK_BLUE}>
          {payload.texts.instructions}
        </Text>
      </div>
    </div>
  );

  render() {
    const { payload } = this.state;
    
    return (
      <div>
        {payload && payload._id || payload.id ? (
          this.renderEntity(payload)
        ) : (
          <EntitySelectionOptions
            entityType={EXERCISE}
            onItemSelect={this.onItemSelect}
          />
        )}
      </div>
    );
  }
}

EditorExercise.propTypes = {
  payload: PropTypes.object,
  submitPayload: PropTypes.func,
};

export default EditorComponentWrapper(compose(withTranslation())(EditorExercise));
