import React from 'react';
import { SubscribersSubWrapper } from '../../styles';
import { styleWrapperHelper } from './helpers';
import { useVideoCallContext } from '@shared/hooks/useVideoCallContext';

export const SubscribersSubContainer = ({ children }) => {
  const { stateContext } = useVideoCallContext();
  const { subWrapperDimensions, numOfParticipants } = stateContext;
  return (
    <SubscribersSubWrapper
      stylesObject={styleWrapperHelper(
        subWrapperDimensions.height,
        subWrapperDimensions.width,
        numOfParticipants
      )}
    >
      {children}
    </SubscribersSubWrapper>
  );
};
