import {styled} from "@mui/material";
import CTAButton from '@components/CTAButton';
import SharedInput from '@components/Input';

export const MediaContainer = styled('div')({
  width: 535,
});

export const TitleContainer = styled('div')({
  width: 535,
  display: 'flex',
  justifyContent: 'space-between',
  '& > span.optional-text': {
    fontStyle: 'italic',
  },
});

export const Input = styled(SharedInput)({
  width: 535,
  height: 44,
  marginTop: 8,
});

export const ContentInfo = styled('div')({
  margin: '0 20px',
});

export const ContentOptionsSection = styled('div')({
  marginTop: 20,
  display: 'flex',
  alignItems: 'center',
});

export const VideoWrapper = styled('div')({
  width: 535,
  borderRadius: 6,
  overflow: 'hidden',
  flexShrink: 0,
});

export const VideoPlayer = styled('video')({
  width: '100%',
});

export const AudioPlayer = styled('audio')({
  height: 60,
  maxWidth: 300,
});

export const Image = styled('img')({
  width: 535,
});

export const StyledCTAButton = styled(CTAButton)({
  width: 120,
  height: 32,
  borderRadius: 4,
  fontSize: 12,
});
