import {styled} from "@mui/material";
import TextAreaBase from '@components/TextArea';
import { getIconByResourceType } from '@services/icons';
import { RESOURCES } from '@shared/Resources/types';

export const StyledDocumentIcon = styled(
  getIconByResourceType(RESOURCES.PRACTICE)
)(
  {
    display: 'inline-block',
    marginTop: -4,
    marginRight: 8,
    fontSize: 24,
  },
  ({ theme }) => ({
    color: theme.palette.novotalk.$activeColor,
  })
);

export const TextArea = styled(TextAreaBase)({
  width: 785,
  maxWidth: 785,
  height: 120,
  marginTop: 20,
  padding: '14px 18px',
  resize: 'vertical',
});
