import {styled} from "@mui/material";

const Input = styled('input')(
  {
    borderRadius: 4,
    resize: 'none',
    padding: '0 8px',
    fontSize: 14,
  },
  ({ theme, error, width, height, margin }) => ({
    width: width || '100%',
    height: height || 34,
    margin: margin || 0,
    color: theme.palette.novotalk.$textColorDarkBlue,
    border: `1px solid ${error ? theme.palette.novotalk.$errorColor : theme.palette.novotalk.$inputBorderColor}`,
    backgroundColor: theme.palette.novotalk.$backgroundColor,
    '&:focus': {
      border: `1px solid ${theme.palette.novotalk.$inputBorderColorActive}`,
    },
    '&::placeholder': {
      color: theme.palette.novotalk.$textColorDisabledLight,
    },
  })
);

export default Input;
