import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { withRouter } from 'react-router-dom';
import Text from '@components/Text';
import { Wrapper, ContentWrapper, DescriptionWrapper } from './styles';
import SkillPart from '../SkillPart';

export class PlanPane extends Component {
  renderSkillParts() {
    const { skill, match } = this.props;
    const { Lessons, Practices, Polls } = skill;
    const currentSkillpartInfo = match.params.skillPartIndex;
    if (!currentSkillpartInfo) return null;
    const skillpartIndex = parseInt(currentSkillpartInfo.match(/\d+/g)[0], 10);
    const skillpartType = currentSkillpartInfo.match(/[a-zA-Z]+/g)[0];
    const commonProps = {
      courseIndex: parseInt(match.params.courseIndex, 10),
      skillIndex: parseInt(match.params.skillIndex.match(/\d+/g)[0], 10),
      duration: null,
      close: this.props.close,
    };
    const skillpartsCollections = [Lessons, Practices, Polls];

    return skillpartsCollections.map((collection) =>
      collection.map((skillpart, index) => {
        const currentlyDisplayed =
          skillpartType.toUpperCase() === skillpart.type &&
          skillpartIndex === index;
        const props = {
          key: skillpart.id,
          skillpartIndex: index,
          ...skillpart,
          currentlyDisplayed,
        };
        return <SkillPart {...props} {...commonProps} />;
      })
    );
  }

  render() {
    const { skill, display } = this.props;
    const { name, description } = skill;
    return (
      <Wrapper show={display} id='planPane'>
        <DescriptionWrapper>
          <Text
            textTransform='uppercase'
            textColor='highlighted'
            margin='0 0 10px'
          >
            {name}
          </Text>
          <Text size='T3' textColor='disabled'>
            {description}
          </Text>
        </DescriptionWrapper>
        <ContentWrapper id='planParts'>
          {this.renderSkillParts()}
        </ContentWrapper>
      </Wrapper>
    );
  }
}

PlanPane.propTypes = {
  skill: PropTypes.object,
  display: PropTypes.bool,
  close: PropTypes.func,
  match: PropTypes.object,
};

export default compose(withRouter)(PlanPane);
