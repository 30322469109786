import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import TextButton from '@components/TextButton';
import Text, { TEXT_SIZE, TEXT_COLOR } from '@components/Text';
import Container from '@components/Container';
import {
  ScreenWrapper,
  StyledHeader,
  ContentWrapper,
  StyledErrorIcon,
  StyledCTAButton,
  StyledFooter,
} from './styles';
import {useTranslation} from "react-i18next";

const PaymentFailure = (props) => {
  const {t} = useTranslation()
  const {onBillingReset} = props;
  return (
    <ScreenWrapper>
      <StyledHeader>
        <StyledErrorIcon />
        <Text size={TEXT_SIZE.T1} textColor={TEXT_COLOR.DARK_BLUE}>
          {t("Errors.somethingWentWrong")}
        </Text>
      </StyledHeader>
      <ContentWrapper>
        <Container margin='0 0 76px 0'>
          <Text size={TEXT_SIZE.T1} textColor={TEXT_COLOR.HIGHLIGHTED}>
            {t("Errors.tryAgain")}
          </Text>
        </Container>
        <StyledCTAButton action onClick={onBillingReset}>
          {t("Actions.Navigation.failedPurchase")}
        </StyledCTAButton>
      </ContentWrapper>
      <StyledFooter>
        <Text size={TEXT_SIZE.T3} textColor={TEXT_COLOR.DISABLED}>
          {t('Billing.Informative.encounter')}
        </Text>
        <Link to=''>
          <TextButton underline>
            <Text size={TEXT_SIZE.T3} margin='0 5px'>
              {t('Billing.Informative.encounter')}
              {t("Actions.General.contactUs")}
            </Text>
          </TextButton>
        </Link>
      </StyledFooter>
    </ScreenWrapper>
  );
};

PaymentFailure.propTypes = {
  onBillingReset: PropTypes.func,
};

export default PaymentFailure;
