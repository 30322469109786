import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Select from 'react-select';
import _ from 'lodash';
import { Wrapper, selectStyles } from './styles';
import { ALL_STAGES_KEY } from '../../../../reducer';
import { withTranslation } from 'react-i18next';
import { compose } from 'redux';

class StagesFilter extends Component {
  constructor(props) {
    super(props);
    this.setStageFilter = this.setStageFilter.bind(this);
  }

  setStageFilter(options) {
    const { stagesFilter, stageOptions, setStageFilter, t } = this.props;
    const newFilter = options.map((option) => option.value);
    const selectedStages = _.without(newFilter, ALL_STAGES_KEY);
    if ((!stagesFilter.includes(ALL_STAGES_KEY) && newFilter.includes(ALL_STAGES_KEY)) ||
         _.isEmpty(_.difference(_.without(stageOptions, ALL_STAGES_KEY), selectedStages)) ||
         selectedStages.length <= 0) {
      setStageFilter([ALL_STAGES_KEY]);
    } else {
      setStageFilter(selectedStages);
    }
  }

  getSelectOptions = (stgArr) => (
    stgArr.map((stage) => ({
      value: stage,
      label: stage === ALL_STAGES_KEY ? t('UI.allStages') : stage,
    }))
  );

  render() {
    const { stagesFilter, stageOptions } = this.props;
    return (
      <Wrapper>
        <Select
          id="stages-filter"
          className="multi-select"
          key="stage"
          isMulti
          name="stages"
          value={this.getSelectOptions(stagesFilter)}
          options={this.getSelectOptions(stageOptions)}
          onChange={this.setStageFilter}
          styles={selectStyles}
        />
      </Wrapper>
    );
  }
}

StagesFilter.propTypes = {
  stagesFilter: PropTypes.array,
  stageOptions: PropTypes.array,
  setStageFilter: PropTypes.func,
};

export default compose(
    withTranslation()
)(StagesFilter)
