import React, { Component } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { Wrapper, StyledDatePicker } from './styles';
import { StyledQuestionIcon } from '../../styles';
import PollQuestionTitle from '../PollQuestionTitle';
import { QUESTION_TYPES } from '../../../../types';
import MiniOverviewHeader from '../../shared/MiniOverviewHeader';

export class DateQuestion extends Component {
  constructor(props) {
    super(props);

    this.state = {
      answer: null,
    };
  }

  handleChange(date) {
    const { updateAnswer } = this.props;
    this.setState({ answer: date });
    updateAnswer(date);
  }

  render() {
    const { questionIndex, questionText, checkAnswers, isDisabled, userAnswer, miniOverview } = this.props;
    const { answer } = this.state;
    const error = checkAnswers && !answer && !isDisabled;
    const titleProps = {
      questionIndex,
      questionText,
      error,
    };
    const datepickerProps = {
      isDisabled,
      error,
      userAnswer,
      handleChange: (date) => this.handleChange(date),
    };

    return miniOverview ? (
      <MiniOverviewHeader type={QUESTION_TYPES.DATE} questionText={questionText}>
        <StyledDatePicker isDisabled miniOverview />
      </MiniOverviewHeader>
    ) : (
      <Wrapper>
        <StyledQuestionIcon />
        <PollQuestionTitle {...titleProps} />
        <StyledDatePicker {...datepickerProps} />
      </Wrapper>
    );
  }
}

DateQuestion.propTypes = {
  isDisabled: PropTypes.bool,
  questionIndex: PropTypes.number,
  questionText: PropTypes.string,
  updateAnswer: PropTypes.func,
  checkAnswers: PropTypes.bool,
  userAnswer: PropTypes.string,
  miniOverview: PropTypes.bool,
};

export default DateQuestion;
