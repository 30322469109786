import {Components} from "@mui/material"
import {isDesktop} from "@shared/constants/media-queries";
export const getOverrides = (): Partial<Components> => {
    return {
        MuiMobileStepper: {
            styleOverrides: {
                root: {
                    width: '100%',
                    backgroundColor: 'white',
                }
            },
        },
        MuiPaginationItem: {
            styleOverrides: {
                root: {
                    fontFamily: 'Lato, sans-serif',
                    color: '#9C9C9C',
                    '&.Mui-selected': {
                        backgroundColor: '#FFF !important',
                        color: '#23B7E5',
                        cursor: 'default',
                        pointerEvents: 'none',
                    },
                }
            }
        },
        MuiPagination: {
            styleOverrides: {
                root: {
                    '& li:nth-of-type(1)': {
                        '& button': {
                            '&:before': {
                                content: `'Prev'`,
                                color: '#9C9C9C',
                            },
                            '& svg': {
                                visibility: 'hidden',
                            },
                        }
                    },
                    '& li:last-child': {
                        '& button': {
                            '&:before': {
                                content: `'Next'`,
                                color: '#9C9C9C',
                            },
                            '& svg': {
                                visibility: 'hidden',
                            },
                        }
                    }
                }
            }
        },
        MuiTooltip: {
            styleOverrides: {
                arrow: {
                    "&:before": {
                        border: '1px solid #9C9C9C',
                    },
                    color: '#fff'
                },
                tooltip: {
                    backgroundColor: '#fff',
                    color: '#1F1F1F',
                    boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
                    fontSize: isDesktop() ? 14 : 12,
                    borderRadius: 8,
                    border: '1px solid #9C9C9C',
                }
            }
        }
    }
}