import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import Text, { TEXT_SIZE, TEXT_COLOR } from '@components/Text';
import {
  MiniOverviewWrapper,
  SplitContainer,
  MiniOverviewTitle,
  MiniOverviewText,
  getStyledTypeIcon,
  MiniOverviewQuestionIcon,
} from '../../styles';
import {useTranslation} from "react-i18next";

const MiniOverviewHeader = ({ children, type, questionText }) => {
  const {t} = useTranslation()
  const StyledTypeIcon = getStyledTypeIcon(type);
  const messageName = `${_.camelCase(type)}Title`;
  return (
    <MiniOverviewWrapper>
      <SplitContainer>
        <MiniOverviewTitle>
          <StyledTypeIcon />
          <Text size={TEXT_SIZE.H4} textColor={TEXT_COLOR.DARK_BLUE}>
            {t(`Common.UI.${[messageName]}`)}
          </Text>
        </MiniOverviewTitle>
        <MiniOverviewQuestionIcon />
      </SplitContainer>
      <MiniOverviewText>
        <Text size={TEXT_SIZE.T4} textColor={TEXT_COLOR.DARK_BLUE}>
          {questionText}
        </Text>
      </MiniOverviewText>
      {children}
    </MiniOverviewWrapper>
  );
};

MiniOverviewHeader.propTypes = {
  children: PropTypes.node,
  type: PropTypes.string,
  questionText: PropTypes.string,
};

export default MiniOverviewHeader;
