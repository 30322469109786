import { activityContext } from './activityContext';

export const selfManaged = {
  monitor: {
    type: "LOUDNESS",
    inhalation: {
      on: true,
      value: 1
    },
    video: {
      on: false,
      url: null,
      id: null
    },
    mirror: {
      on: false
    },
    speedometer: {
      on: false
    }
  },
  pattern: {
    type: "SELF_MANAGED",
    productionDuration: 6,
    selfManagePace: {
        min: 3,
        max: 5,
    },
  },
  errors: {
      on: true,
      masteryLevel: 100,
      production: {
        tooFastSmm: {
              on: true,
              type: "EASY"
          },
          tooSlowSmm: {
              on: true,
              type: "EASY"
          },
          nonGentleOnsetSmm: {
              on: true,
              type: "EASY"
          },
          noLinkingSmm: {
              on: true,
              type: "EASY"
          },
          tooFlatSmm: {
              on: true,
              type: "EASY"
          }
      },
      specific: {
          beginning: {
              nonGentleOnset: {
                  on: true,
                  type: "EASY"
              },
              notGentle: {
                  on: false,
                  type: "EASY"
              },
              volumeControl: {
                  on: false,
                  type: "EASY"
              }
          },
          peak: {
              notLoud: {
                  on: false,
                  type: "EASY"
              },
              softPeak: {
                  on: true,
                  type: "EASY"
              },
              execessivePeak: {
                  on: false,
                  type: "EASY"
              }
          },
          end: {
              volumeControl: {
                  on: false,
                  type: "EASY"
              },
              nonGentleOffset: {
                  on: true,
                  type: "EASY"
              }
          }
      },
      types: [
          {
              type: "nonGentleOnsetSmm",
              difficulty: "EASY"
          },
          {
              type: "tooFlatSmm",
              difficulty: "EASY"
          },
          {
              type: "noLinkingSmm",
              difficulty: "EASY"
          },
          {
              type: "tooFastSmm",
              difficulty: "EASY"
          },
          {
              type: "tooSlowSmm",
              difficulty: "EASY"
          }
      ]
  },
  updateMonitorResults: () => { },
  activityContext
};
