import React from 'react';
import PropTypes from 'prop-types';
import { PasswordStrengthDescription } from './styles';

export const PasswordHint = ({ errors }) => (
  <div>
    <PasswordStrengthDescription>
      {errors.map((error) => <li>{error}</li>)}
    </PasswordStrengthDescription>
  </div>
);

PasswordHint.propTypes = {
  errors: PropTypes.arrayOf(PropTypes.string),
};
