import {styled} from "@mui/material";
import { getResponsiveCSS } from '@utils/cssHelpers';
import ErrorMessage from '@components/ErrorMessage';

export const ScreenContainer = styled('div')({
  minHeight: '100vh',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  padding: '30px 0',
});

export const SignInComponentContainer = styled('div')(({ theme }) => ({
  direction: theme.RTL ? 'rtl' : 'inherit',
  width: theme.palette.novotalk.authFormsWidth,
}));

export const SignInInputAreaContainer = styled('div')(
  {
    width: '100%',
    borderRadius: 4,
    boxSizing: 'border-box',
    padding: '33px 67px 20px',
  },
  ({ theme }) => ({
    border: `1px solid ${theme.palette.novotalk.$borderColorLight}`,
    backgroundColor: theme.palette.novotalk.$backgroundColorAuthScreens,
  })
);

export const SignInHeaderContainer = styled('div')(
  {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  getResponsiveCSS('marginBottom', 12, 18)
);

export const StyledLogo = styled('img')(
  {
    marginBottom: 60,
  },
  getResponsiveCSS('width', 300, 340)
);

export const InputContainer = styled('div')(
  {
    position: 'relative',
  },
  getResponsiveCSS('height', 112, 110)
);

export const SignInButtonContainer = styled('div')(
  getResponsiveCSS('height', 44, 48, '& #signin_button'),
  getResponsiveCSS('marginTop', 6, 15),
  getResponsiveCSS('marginBottom', 20, 15)
);

export const UnderButtontTextContainer = styled('div')(
  {
    textAlign: 'center',
  },
  getResponsiveCSS('marginBottom', 0, 4)
);

export const LinkContainer = styled('span')(
  {
    position: 'absolute',
    bottom: 12,
  },
  getResponsiveCSS('bottom', 12, 6),
  ({ theme }) => ({
    [theme.RTL ? 'left' : 'right']: 0,
  })
);

export const linkStyles = {
  textDecoration: 'none',
};

export const ServerErrorMessage = styled(ErrorMessage)(
  {
    textAlign: 'center',
  },
  getResponsiveCSS('marginTop', 3, 6)
);
