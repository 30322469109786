import _ from 'lodash';
import intformat from 'biguint-format';
//import FlakeId from 'flake-idgen';
import stringify from 'json-stringify-safe';
import { EVENT_LOGGER_SERVER } from '../servers';
import { postRequest } from '../commService';
import {
  setToLocalStorage,
  LOCAL_STORAGE_ITEM,
  removeFromLocalStorage,
  getFromLocalStorage,
} from '@utils/localStorageHelper';

const useFixtures = process.env.USE_FIXTURES;
//const flakeIdGen = new FlakeId();

const sendingLogsInterval = 60000; // 1 minute
let timerId;
let eventLogs = [];
let sessionId;
let lastEvent;

const sendEventLogs = async () => {
  if (eventLogs.length > 0) {
    try {
      if (!useFixtures)
        await postRequest(EVENT_LOGGER_SERVER, '', {
          events: stringify(eventLogs),
        });
      eventLogs = [];
    } catch (error) {
      console.error('Error sending event logs to server', error);
    }
    saveEventLogsToLocalStorage(eventLogs);
  }
};

const saveEventLogsToLocalStorage = (events) => {
  if(!process.env.REACT_APP_IS_LOCAL){
    return
  }
  if(events.length<100){
  setToLocalStorage(LOCAL_STORAGE_ITEM.EVENTS_LOG, events)
  }
  setToLocalStorage(LOCAL_STORAGE_ITEM.SESSION_ID, sessionId);
  setToLocalStorage(LOCAL_STORAGE_ITEM.LAST_EVENT, lastEvent);
};

const setTimer = () => setInterval(sendEventLogs, sendingLogsInterval);

const getUnloggedActions = () => {
  const eventsLog = getFromLocalStorage(LOCAL_STORAGE_ITEM.EVENTS_LOG);

  const storageEvents = eventsLog ? eventsLog : [];
  if (storageEvents && storageEvents.length > 0) {
    return storageEvents;
  }
  return [];
};

const saveAction = (action) => {
  if (eventLogs.length === 0) {
    const unloggedEvents = getUnloggedActions();
    if (!_.isEmpty(unloggedEvents)) {
      eventLogs = eventLogs.concat(unloggedEvents);
    }
  }

  const timestamp = Math.floor(new Date() / 1000);
  const timeSinceLastEvent = timestamp - lastEvent;

  // 30 min of inactivity triggers new session id
  if (!sessionId || timeSinceLastEvent > 1800) {
    sessionId = 'test';
    //sessionId = intformat(flakeIdGen.next(), 'hex');
  }

  eventLogs = eventLogs.concat({
    timestamp,
    sessionId,
    action,
  });
  lastEvent = timestamp;

  saveEventLogsToLocalStorage(eventLogs);
};

export const eventLogger = (store) => (next) => (action) => {
  const state = store.getState();
  const userData = state.getIn(['app', 'authentication', 'user']).toJS();
  const authenticatedUser = Boolean(userData && userData.token);
  if (authenticatedUser) {
    sessionId = getFromLocalStorage(LOCAL_STORAGE_ITEM.SESSION_ID);
    lastEvent = getFromLocalStorage(LOCAL_STORAGE_ITEM.LAST_EVENT) || 0;

    if (!timerId) timerId = setTimer();
    saveAction(action);

    if (action.type === 'LOG_OUT') {
      clearInterval(timerId);
      sendEventLogs();
      removeFromLocalStorage(LOCAL_STORAGE_ITEM.SESSION_ID);
    }
  }
  return next(action);
};
