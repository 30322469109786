import React from 'react';
import PropTypes from 'prop-types';
import { AnimatedBarContainer } from './styles';

const AnimatedBar = ({ duration, isRTL }) => (<AnimatedBarContainer duration={duration} isRTL={isRTL} />);

AnimatedBar.propTypes = {
  duration: PropTypes.string,
};

export default AnimatedBar;
