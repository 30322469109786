/**
*
* MonitorErrors
*
*/
import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { camelCaseToWords } from '@utils/textHelpers';
import Text, { TEXT_SIZE, TEXT_COLOR } from '@components/Text';
import Slider from '@components/Slider';
import ToggleSwitch from '@components/ToggleSwitch';
import ErrorDifficultySelector from './components/ErrorDifficultySelector';
import { ErrorsTitle } from '@shared/Resources/Monitor/shared/Titles';
import { defaultErrors } from './consts';
import {
  ErrorsSection, ErrorsSectionPart, ErrorsSectionTitle,
  SpecificErrors, SpecificPosition, StyledInput,
  TitleWrapper, ToggleWrapper, MasteryLevelContainer,
} from './styles';
import { DIFFICULTY } from '../../../types';
import {withTranslation} from "react-i18next";
import {compose} from "redux";
import {t} from 'i18next'

const getSharedFormattedText = (messageName) => <span className={messageName}> {t(`Errors.${messageName}`)}</span>;
const getFormattedText = (messageName) => <span className={messageName}> {t(`Errors.${messageName}`)}</span>

class MonitorErrors extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      errors: { ...props.errors, types: props.errors.types || [] },
    };
  }

  setField = (pathToField) => (value) => {
    const { errors } = this.state;
    let newValue = value;
    if (pathToField === 'masteryLevel') {
      if (_.isNaN(newValue)) {
        return;
      } else if (newValue.length === 0 || +newValue < 0) {
        newValue = 0;
      } else if (+newValue > 100) {
        newValue = 100;
      }
    }
    _.set(errors, pathToField, newValue);
    this.updateErrors(errors);
  }

  setError = (errorType) => ({ on, difficulty }) => {
    const { errors } = this.state;

    _.remove(errors.types, ({ type }) => type === errorType);
    if (on) {
      errors.types.push({ type: errorType, difficulty });
    }
    this.updateErrors(errors);
  }


  getErrorValue = (errorType) => {
    const { errors } = this.state;
    const foundError = errors.types.find(({ type }) => type === errorType);
    return foundError ? { on: true, difficulty: foundError.difficulty } : { on: false, difficulty: DIFFICULTY.EASY };
  }

  updateErrors = (errors) => {
    this.setState({ errors });
    this.props.onChange(errors);
  }

  render() {
    const { errors } = this.state;
    return (
      <div className="resource-item">
        {/** Main title + Toggle */}
        <TitleWrapper>
          <ErrorsTitle />
          <ToggleWrapper>
            <ToggleSwitch
              showNames
              names={[this.props.t('Actions.Simple.off'), this.props.t('Actions.Simple.on')]}
              value={errors.on}
              onChange={this.setField('on')}
              className="errorsToggle"
            />
          </ToggleWrapper>
        </TitleWrapper>
        <ErrorsSection className="errorsSection" isDisabled={!errors.on}>
          {/** Inhalation errors */}
          <ErrorsSectionPart>
            <ErrorsSectionTitle>
              <Text textColor={TEXT_COLOR.DARK_BLUE} weight="bold" >
                {getSharedFormattedText('inhalationErrors')}
              </Text>
              <Text size={TEXT_SIZE.T4} textColor={TEXT_COLOR.DISABLED} margin="0 17px">
                {getFormattedText('inhalationErrorSubTitle')}
              </Text>
            </ErrorsSectionTitle>
            {_.keys(defaultErrors.inhalation).map((errorBuilderName) => {
              const errorType = defaultErrors.inhalation[errorBuilderName];
              return (<ErrorDifficultySelector
                label={camelCaseToWords(errorBuilderName)}
                value={this.getErrorValue(errorType)}
                onChange={this.setError(errorType)}
                key={errorType}
                className={`inhalationError ${errorType}`}
              />);
            }
            )}
          </ErrorsSectionPart>
          {/** Production errors */}
          <ErrorsSectionPart>
            <ErrorsSectionTitle>
              <Text textColor={TEXT_COLOR.DARK_BLUE} weight="bold">
                {getSharedFormattedText('productionErrors')}
              </Text>
              <Text size={TEXT_SIZE.T4} textColor={TEXT_COLOR.DISABLED} margin="0 17px">
                {getFormattedText('productionErrorSubTitle')}
              </Text>
            </ErrorsSectionTitle>
            {_.keys(defaultErrors.production).map((errorBuilderName) => {
              const errorType = defaultErrors.production[errorBuilderName];
              return (<ErrorDifficultySelector
                label={camelCaseToWords(errorBuilderName)}
                value={this.getErrorValue(errorType)}
                onChange={this.setError(errorType)}
                key={errorType}
                className={`productionError ${errorType}`}
              />);
            }
            )}
          </ErrorsSectionPart>
          {/** Specific Errors */}
          <ErrorsSectionPart>
            <ErrorsSectionTitle>
              <Text textColor={TEXT_COLOR.DARK_BLUE} weight="bold">
                {getSharedFormattedText('specificErrors')}
              </Text>
            </ErrorsSectionTitle>
            <SpecificErrors>
              {_.keys(defaultErrors.specific).map((errorPosition) =>
                (<SpecificPosition key={errorPosition}>
                  <ErrorsSectionTitle>
                    <Text size={TEXT_SIZE.H4} textColor={TEXT_COLOR.DARK_BLUE} weight="bold" className={`${errorPosition}Title`}>
                      {_.capitalize(errorPosition)}
                    </Text>
                  </ErrorsSectionTitle>
                  {_.keys(defaultErrors.specific[errorPosition]).map((errorBuilderName) => {
                    const errorType = defaultErrors.specific[errorPosition][errorBuilderName];
                    return (<ErrorDifficultySelector
                      label={camelCaseToWords(errorBuilderName)}
                      value={this.getErrorValue(errorType)}
                      onChange={this.setError(errorType)}
                      key={errorType}
                      className={`${errorPosition}Error ${errorType}`}
                    />);
                  }
                  )}
                </SpecificPosition>))}
            </SpecificErrors>
          </ErrorsSectionPart>
          {/** Mastery */}
          <ErrorsSectionTitle>
            <Text textColor={TEXT_COLOR.DARK_BLUE} weight="bold">
              {getSharedFormattedText('masteryLevel')}
            </Text>
          </ErrorsSectionTitle>
          <MasteryLevelContainer>
            <Slider
              value={Number.parseInt(errors.masteryLevel, 10)}
              onChange={this.setField('masteryLevel')}
              className="masterySlider"
              noLabels
            />
            <StyledInput
              value={Number.parseInt(errors.masteryLevel, 10)}
              onChange={(ev) => this.setField('masteryLevel')(ev.target.value)}
              width={45}
              height={44}
            />
            <Text size={TEXT_SIZE.T2} textColor={TEXT_COLOR.DARK_BLUE}>%</Text>
          </MasteryLevelContainer>
        </ErrorsSection>
      </div>
    );
  }
}

MonitorErrors.propTypes = {
  onChange: PropTypes.func,
  errors: PropTypes.shape({
    on: PropTypes.bool,
    masteryLevel: PropTypes.number,
    types: PropTypes.arrayOf(PropTypes.shape({
      type: PropTypes.string,
      difficulty: PropTypes.string,
    })),
    // production: PropTypes.object,
    // specific: PropTypes.object,
    // inhalation: PropTypes.object,
  }),
};

export default compose(
    withTranslation()
)(MonitorErrors);
