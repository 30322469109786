export const Types = {
  GET_MEDIA_ITEMS: 'GET_MEDIA_ITEMS',
  SET_MEDIA_ITEMS: 'SET_MEDIA_ITEMS',
};

export function getMediaItems() {
  return {
    type: Types.GET_MEDIA_ITEMS,
  };
}

export function setMediaItems(items) {
  return {
    type: Types.SET_MEDIA_ITEMS,
    items,
  };
}
