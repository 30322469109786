import { takeLatest, call, put, all, fork, join } from 'redux-saga/effects';
import { confirmEmail } from '@services/authenticationService';
import { Types, confirmEmailSuccess, confirmEmailFailure } from './actions';

function* callConfirmEmail(action) {
  try {
    const { token } = action;
    const res = yield fork(confirmEmail, token);
    const email = yield join(res);
    yield put(confirmEmailSuccess(email));
  } catch (error) {
    yield put(confirmEmailFailure(error.message));
  }
}

function* watchCallConfirmEmail() {
  yield takeLatest(Types.CONFIRM_EMAIL, callConfirmEmail);
}

export default function* watchLoginConfirmEmailActions() {
  yield all([fork(watchCallConfirmEmail)]);
}
