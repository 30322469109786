import React from 'react';

const icon = () => (<svg width="17px" height="15px" viewBox="0 0 17 15" version="1.1" xmlns="http://www.w3.org/2000/svg">
  <g id="Mobile" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
    <g id="Practice-In-Progress" transform="translate(-1145.000000, -422.000000)" fill="currentColor">
      <g id="Group-6" transform="translate(1145.000000, 422.000000)">
        <g id="Group-7">
          <g id="Group-5">
            <path d="M13.6649643,15 C13.5849949,15 13.5058929,14.9713626 13.4425332,14.9157882 L9.99686735,11.9018811 L2.36317347,11.9018811 C1.0601148,11.9018811 0,10.8019366 0,9.44993825 L0,2.45194287 C0,1.09994452 1.0601148,0 2.36317347,0 L14.6368265,0 C15.9398852,0 17,1.09994452 17,2.45194287 L17,9.44993825 C17,10.8019366 15.9398852,11.9018811 14.6368265,11.9018811 L14.0098648,11.9018811 L14.0098648,14.6420325 C14.0098648,14.7813714 13.9321071,14.9079129 13.8102883,14.9666195 C13.7638852,14.9889925 13.7142296,15 13.6649643,15 Z M2.36317347,0.715934922 C1.4406199,0.715934922 0.68997449,1.49460364 0.68997449,2.45194287 L0.68997449,9.44993825 C0.68997449,10.4072775 1.4406199,11.1859462 2.36317347,11.1859462 L10.1228929,11.1859462 C10.2042066,11.1859462 10.2829617,11.2158365 10.345324,11.2702028 L13.3198903,13.8720893 L13.3198903,11.5439137 C13.3198903,11.3462261 13.4743648,11.1859462 13.6649643,11.1859462 L14.6368265,11.1859462 C15.5593801,11.1859462 16.3100255,10.4072775 16.3100255,9.44993825 L16.3100255,2.45194287 C16.3100255,1.49460364 15.5593801,0.715934922 14.6368265,0.715934922 L2.36317347,0.715934922 Z" id="Fill-1"></path>
          </g>
          <path d="M12,4.71263061 C12,4.60381138 11.921516,4.53619187 11.7643414,4.50971794 L9.65265658,4.18775378 L8.70614964,2.18082399 C8.65291475,2.06025662 8.58413797,2 8.50002582,2 C8.41591367,2 8.34718852,2.06025662 8.293902,2.18082399 L7.34734342,4.18775378 L5.23555533,4.50971794 C5.07853565,4.53619187 5,4.60381138 5,4.71263061 C5,4.77440312 5.03505964,4.84500027 5.10517891,4.92436793 L6.63644343,6.48578853 L6.27469407,8.6911916 C6.26906594,8.73239132 6.26627769,8.76184289 6.26627769,8.77943804 C6.26627769,8.84121055 6.28099344,8.89334633 6.31042495,8.93606193 C6.33985646,8.9787234 6.38400372,9 6.44291837,9 C6.49341664,9 6.5494914,8.98245899 6.6111943,8.94710627 L8.49997418,7.90606897 L10.388909,8.94710627 C10.4478753,8.98240485 10.50395,9 10.5571333,9 C10.6133629,9 10.6560644,8.97877754 10.6854959,8.93606193 C10.7148758,8.89340047 10.7295916,8.84121055 10.7295916,8.77943804 C10.7295916,8.74121596 10.7281974,8.71181853 10.7253576,8.6911916 L10.3635566,6.48578853 L11.8905871,4.92436793 C11.9635979,4.84792377 12,4.77732662 12,4.71263061 L12,4.71263061 Z" id="Shape"></path>
        </g>
      </g>
    </g>
  </g>
</svg>);

export default icon;

