import React from 'react';
import PropTypes from 'prop-types';
import Text, { TEXT_SIZE, TEXT_COLOR } from '@components/Text';
import { MONITOR_TYPES } from '../../types';
import { NoMonitorTypeWrapper } from './styles';
import BreathingImg from '../images/breathing_standart.png';
import BreathingSelectedImg from '../images/breathing_selected.png';
import LoudnessImg from '../images/loudness_standart.png';
import LoudnessSelectedImg from '../images/loudness_selected.png';
import {useTranslation} from "react-i18next";

const MonitorImage = ({ type, isSelected, miniOverview }) => {
  const {t} = useTranslation()
  let MonitorImg;
  switch (type) {
    case MONITOR_TYPES.LOUDNESS:
      MonitorImg = (
        <img src={isSelected ? LoudnessSelectedImg : LoudnessImg} alt='' />
      );
      break;
    case MONITOR_TYPES.BREATHING:
      MonitorImg = (
        <img src={isSelected ? BreathingSelectedImg : BreathingImg} alt='' />
      );
      break;
    default:
      MonitorImg = (
        <NoMonitorTypeWrapper miniOverview={miniOverview}>
          <Text
            size={TEXT_SIZE.H5}
            textColor={TEXT_COLOR[isSelected ? 'HIGHLIGHTED' : 'LIGHT_GRAY']}
          >
            {t('UI.monitorMissing')}
          </Text>
        </NoMonitorTypeWrapper>
      );
  }
  return MonitorImg;
};

MonitorImage.propTypes = {
  type: PropTypes.string,
  isSelected: PropTypes.bool,
  miniOverview: PropTypes.bool,
};

export default MonitorImage;
