import React from 'react';
import {StyledCustomTextInput} from "@containers/Admin/containers/ResourceManager/components/ResourceHeader/styles";
import {StyledSearchIcon} from "@containers/Admin/containers/ResourceManager/styles";
import {useTranslation} from "react-i18next";
import {Wrapper} from "./styles";

interface Props {
    valueText: string
    setValueText: () => void
}

export const Search:React.FC<Props> = ({valueText, setValueText}) => {
    const {t} = useTranslation()
    return (
        <Wrapper>
            <StyledCustomTextInput
                sx={{width: '380px!important'}}
                placeholder={t('Actions.Navigation.search')}
                onChange={setValueText}
                value={valueText}
                Icon={() => <StyledSearchIcon />}
                name='inner-search-panel'
            />
        </Wrapper>
    );
};