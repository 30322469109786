import React, { useState } from 'react';
import { setMonitorPropsVideoChat, toggleShowMonitorVideoChat } from '@shared/VideoChat/actions';
import NumericSelector from '@components/NumericSelector';
import Text, { TEXT_SIZE, TEXT_COLOR } from '@components/Text';
import { useDispatch, useSelector } from 'react-redux';
import { StyledOverviewButton, DurationWrapper, SettingsWrapper } from './../../styles';
import { PATTERN_TYPES } from '@shared/Resources/Monitor/types';
import {useTranslation} from "react-i18next";

const DEFAULT_PRODUCTION_DURATION = 1;

export const MonitorSettings = () => {
    const {t} = useTranslation()
    const { monitorProps } = useSelector((state) =>
        state.getIn(['videoChat']).toJS()
    );

    const [productionDuration, setProductionDuration] = useState(monitorProps?.pattern?.productionDuration ?? DEFAULT_PRODUCTION_DURATION);

    const dispatch = useDispatch();

    const isDisabledMonitorTypes = monitorProps?.pattern?.type && (monitorProps.pattern.type === PATTERN_TYPES.TWO_SECONDS || monitorProps.pattern.type === PATTERN_TYPES.ONE_SECOND);

    const onHandleSetProductionDuration = (value) => {
        setProductionDuration(value)
    }

    const onUpdateProductionDuration = () => {
        const updatedMonitorProps = monitorProps;
        if (monitorProps?.pattern?.productionDuration) {
            updatedMonitorProps.pattern.productionDuration = productionDuration;
        }
        dispatch(toggleShowMonitorVideoChat(false, false));
        dispatch(setMonitorPropsVideoChat(updatedMonitorProps))
    }

    return (
        <SettingsWrapper>
            <DurationWrapper>
                <NumericSelector
                    disabled={isDisabledMonitorTypes}
                    value={productionDuration}
                    className="productionDuration"
                    onChange={onHandleSetProductionDuration}
                />
                <Text size={TEXT_SIZE.T4} textColor={TEXT_COLOR.DARK_BLUE} margin="0 8px">
                    {t('Dates.secs')}
                </Text>
            </DurationWrapper>
            <StyledOverviewButton
                disabled={isDisabledMonitorTypes}
                onClick={onUpdateProductionDuration}
                className='viewItemButton'
            >
                {t('Actions.Instance.apply')}
            </StyledOverviewButton>
        </SettingsWrapper>
    );
};
