import {styled} from "@mui/material";

export const Wrapper = styled('div')(
  {
    backgroundColor: '#FFF',
    display: 'grid',
    paddingBottom: '40px',
    gridTemplateColumns: 'minmax(1280px, 1920px)',
    gridTemplateAreas: `
  "SkillPartHeader"
  "PollPane"
  `,
  },
  ({ theme }) => ({
    gridTemplateRows: `${theme.palette.novotalk.skillPartHeaderHeight}px auto`,
  })
);
