/**
 *
 * PendingFeedbackHistory
 *
 */

import React from 'react';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import { compose } from 'redux';
import { getResourcesDate } from '@utils/timeHelpers';
import Text, { TEXT_SIZE, TEXT_COLOR, TEXT_WEIGHT } from '@components/Text';
import SectionLoading from '@components/SectionLoading';
import { getIconsByType, iconTypes } from '@services/icons';
import Input from '@containers/Admin/containers/PendingDashboard/shared/SharedComponents/Input';
import {
  getTitle,
  getCellText,
  getActivityTypeTitle,
  getStatusText,
  STATUS_TYPES,
} from '@containers/Admin/containers/PendingDashboard/shared/SharedComponents';
import { SectionHeader } from '@containers/Admin/containers/PendingDashboard/shared/SharedComponents/styles';
import DateFilter from './containers/DateFilter';
import {
  Wrapper,
  RangeSelection,
  HeaderControlsWrapper,
  TableWrapper,
  PreviewIconWrapper,
} from './styles';
import Timestamp from '../../components/Timestamp';
import { PendingTable } from '../PendingTable/PendingTable';

const PreviewIcon = getIconsByType(iconTypes.DOCUMENT);

class PendingFeedbackHistory extends React.Component {
  constructor(props) {
    super(props);
    this.state = { filter: '' };
  }

  onPreviewClicked = ({ type, payload }) => {
    this.props.onFeedbackPreview(type, payload);
  };

  onFilterChange = (event) => {
    const { value } = event.target;
    this.setState({ filter: value });
  };

  getPreviewIcon = (status) => (
    <PreviewIconWrapper
      onClick={(event) => {
        event.stopPropagation();
        this.onPreviewClicked(status);
      }}
      className="preview"
    >
      <PreviewIcon />
    </PreviewIconWrapper>
  );

  getTableConfig = () => [
    { title: '', getDisplay: getActivityTypeTitle, width: '11.3%' },
    {
      title: getTitle('Common.UI.singleSkill'),
      getDisplay: (activity) => getCellText(activity.skill?.name),
      width: '14.3%',
      sortable: true,
      sortField: 'skill.name',
    },
    {
      title: getTitle('UI.activityName'),
      getDisplay: (activity) => getCellText(activity.entity?.name),
      width: '14.3%',
      sortable: true,
      sortField: 'entity.name',
    },
    {
      title: getTitle('Common.Roles.user'),
      getDisplay: (activity) => getCellText(activity.user?.fullName),
      width: '14.3%',
      sortable: true,
      sortField: 'user.fullName',
    },
    {
      title: getTitle('Common.UI.status'),
      getDisplay: (activity) => (
        <span>
          {getStatusText(
            activity?.feedbackStatus?.type?.toLowerCase(),
            activity?.feedbackStatus?.type === 'DISMISSED'
              ? STATUS_TYPES.DISMISSED
              : STATUS_TYPES.DONE,
          )}
          {this.getPreviewIcon(activity?.feedbackStatus)}
        </span>
      ),
      width: '14.3%',
      sortable: true,
      sortField: 'feedbackStatus.type',
    },
    {
      title: getTitle('UI.feedbackTime'),
      getDisplay: (activity) => getCellText(getResourcesDate(activity.activityCreated?.at)),
      width: '14.3%',
      sortable: true,
      sortField: 'timestamp',
    },
    {
      title: getTitle('Dates.timeSinceFeedback'),
      getDisplay: (activity) => (
        <Timestamp timestamp={activity.activityCreated?.at} />
      ),
      width: '17%',
      sortable: true,
      sortField: 'timestamp',
    },
  ];

  render() {
    const {
      activities,
      loading,
      onHoursFilterChange,
      hours,
      onActivitySelect,
      t,
    } = this.props;
    const { filter } = this.state;
    const filteredActivies = _.isEmpty(filter)
      ? activities
      : activities.filter((activity) => activity.user.fullName.toLowerCase().includes(filter.toLowerCase()));
    const tableProps = {
      items: filteredActivies,
      onRowSelect: onActivitySelect,
      config: this.getTableConfig(),
    };
    const dateFilterProps = {
      loading,
      hours,
      onRangeChange: onHoursFilterChange,
    };
    return (
      <Wrapper>
        <SectionHeader className="header">
          <Text
            size={TEXT_SIZE.T0}
            textColor={TEXT_COLOR.DARK_BLUE}
            weight={TEXT_WEIGHT.BOLD}
            textTransform="capitalize"
          >
            {t('UI.feedbackHistory')}
          </Text>
          <HeaderControlsWrapper>
            <RangeSelection className="rangeSelection">
              <DateFilter {...dateFilterProps} />
            </RangeSelection>
            <Input
              id="pending-feedback-filter"
              name="pending-feedback-filter"
              className="filter"
              value={filter}
              onChange={this.onFilterChange}
              placeholder={t('Common.Placeholders.searchOrFilter')}
            />
          </HeaderControlsWrapper>
        </SectionHeader>
        <TableWrapper>
          {loading ? (
            <SectionLoading className="loading" />
          ) : (
            <PendingTable config={tableProps.config} filter={this.state.filter} onRowSelect={tableProps.onRowSelect} activities={tableProps.items} />
          )}
        </TableWrapper>
      </Wrapper>
    );
  }
}

PendingFeedbackHistory.propTypes = {
  activities: PropTypes.array,
  loading: PropTypes.bool,
  onHoursFilterChange: PropTypes.func,
  onFeedbackPreview: PropTypes.func,
  onActivitySelect: PropTypes.func,
  hours: PropTypes.number,
};

export default compose(
  withTranslation(),
)(PendingFeedbackHistory);
