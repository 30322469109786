import {styled} from "@mui/material";
import { iconTypes, getIconsByType } from '@services/icons';

const CheckMarkBase = getIconsByType(iconTypes.CHECKMARK);

export const CheckBoxWrapper = styled('span')({
  display: 'inline-flex',
  alignItems: 'center',
});

export const CheckMark = styled(CheckMarkBase)(
  {
    fontSize: 14,
  },
  ({ theme, on, isDisabled }) => ({
    // we're passing string to the svg instead of boolean
    // because of a react warning for passing non string values to dom element
    opacity: on === 'true' ? 1 : 0,
    color: isDisabled ? theme.palette.novotalk.$selectStandartColor : theme.palette.novotalk.$selectEnabledColor,
  })
);

export const ExtraLabel = styled('span')({
  marginLeft: 12,
  marginRight: 16,
  cursor: 'pointer',
  display: 'flex',
  alignItems: 'center',
});

export const Label = styled('label')(
  {
    display: 'inline-block',
    position: 'relative',
    width: 32,
    height: 32,
    margin: -7,
    borderRadius: '50%',
    flexShrink: 0,
  },
  ({ theme, on }) => ({
    '&:hover': {
      backgroundColor: on
        ? theme.palette.novotalk.$selectEnabledBgColorHover
        : theme.palette.novotalk.$selectStandartBgColorHover,
    },
    '&:active': {
      backgroundColor: on
        ? theme.palette.novotalk.$selectEnabledBgColorActive
        : theme.palette.novotalk.$selectStandartBgColorActive,
    },
  })
);

export const Input = styled('input')({
  position: 'absolute',
  visibility: 'hidden',
});

export const Box = styled('span')(
  {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    margin: 7,
    border: '1px solid',
    borderRadius: 2,
    cursor: 'pointer',
  },
  ({ theme, on, isDisabled }) => {
    let borderColor;
    let backgroundColor;
    if (isDisabled) {
      backgroundColor = 'rgba(216, 216, 216, .4)';
      borderColor = theme.palette.novotalk.$selectStandartColor;
    } else {
      borderColor = on ? theme.palette.novotalk.$selectEnabledColor : theme.palette.novotalk.$selectStandartColor;
    }
    return {
      borderColor,
      backgroundColor,
      '&:active': {
        borderColor: theme.palette.novotalk.$selectEnabledColor,
      },
    };

  }
);
