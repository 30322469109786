import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { ROUTES } from '@shared/constants';

export const NotFoundRoute = ({ user, ...rest }) => (
  <Route
    {...rest}
    render={() => (
      <Redirect
        to={{ pathname: user.token ? user.defaultPage : ROUTES.SIGN_IN }}
      />
    )}
  />
);

NotFoundRoute.propTypes = {
  location: PropTypes.object,
  component: PropTypes.func,
  user: PropTypes.object,
};

export const mapStateToProps = (state) => {
  const user = state.getIn(['app', 'authentication', 'user']).toJS();
  return { user };
};

const withConnect = connect(mapStateToProps);

export default compose(withConnect)(NotFoundRoute);
