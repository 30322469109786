import { takeLatest, call, put, all, fork, join } from 'redux-saga/effects';
import { billSubscribe, getPlans } from './service';
import {
  TYPES,
  billingSuccessAction,
  billingFailAction,
  setPlans,
  setInvalidCouponError,
  billingLoadingHandleAction,
} from './actions';

function* callBillSubscribe(action) {
  try {
    yield put(billingLoadingHandleAction(true));
    const { stripeToken, couponCode, planId } = action.payload;
    const res = yield fork(billSubscribe, stripeToken, couponCode, planId);
    const { message } = yield join(res);
    yield put(billingSuccessAction(message));
    yield put(billingLoadingHandleAction(false));
  } catch (error) {
    yield put(billingFailAction(error.message));
    yield put(billingLoadingHandleAction(false));
  }
}

function* callGetPlans(action) {
  try {
    const { entityId, couponCode } = action;
    const res = yield fork(getPlans, entityId, couponCode);
    const plans = yield join(res);
    if (couponCode && plans.length === 0) {
      yield put(setInvalidCouponError('Missing coupon'));
    } else {
      yield put(setPlans(plans, Boolean(couponCode)));
    }
  } catch (error) {
    yield put(billingFailAction(error.message));
  }
}

function* watchCallBillSubscribe() {
  yield takeLatest(TYPES.BILLING_START, callBillSubscribe);
}
function* watchCallGetPlans() {
  yield takeLatest(TYPES.GET_PLANS, callGetPlans);
}
export default function* watchBillingActions() {
  yield all([fork(watchCallBillSubscribe), fork(watchCallGetPlans)]);
}
