import _ from 'lodash';
import { graphQuery } from '@services/commService';

export const checkExistingEntityName = async (type, name) => {
  if (process.env.USE_FIXTURES) return false;
  const query = `
    {
      exists(name:"${name}", type:"${type}")
    }
    `;
  const res = await graphQuery(query, true);
  if (res.errors) throw new Error(res.errors[0]);
  return res.data.exists;
};

export const checkExistingMediaTitle = async (title) => {
  const query = `
    {
      exists(title:"${title}", type:"${'MEDIA'}")
    }
  `;
  const res = await graphQuery(query, true);
  if (res.errors) throw new Error(res.errors[0]);
  if (!(res || res.data || res.data.media)) throw new Error(res.errors[0]);

  return res.data.exists;
};
