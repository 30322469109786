import { fromJS } from 'immutable';
import { TYPES } from './actions';

// The initial state of the Poll
export const initialState = fromJS({
  poll: {},
  pollSubject: {
    id: '',
    type: null,
  },
  loading: true,
});


function pollReducer(state = initialState, action) {
  switch (action.type) {
    case TYPES.GET_CURRENT_POLL:
      return state
        .set('loading', true);
    case TYPES.SET_CURRENT_POLL:
      return state
        .set('poll', action.poll)
        .set('loading', false);
    case TYPES.SET_POLL_SUBJECT:
      return state
        .setIn(['pollSubject', 'id'], action.id)
        .setIn(['pollSubject', 'type'], action.subjectType);
    default:
      return state;
  }
}

export default pollReducer;
