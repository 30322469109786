import {styled} from "@mui/material";
import { getResponsiveCSS } from '@utils/cssHelpers';

export const PLACEMENT = {
  LEFT: 'LEFT',
  RIGHT: 'RIGHT',
};

export const StyledLabel = styled('label')({
  display: 'block',
  marginBottom: 8,
});

export const InputContainer = styled('span')({
  width: '100%',
  position: 'relative',
  display: 'inline-block',
  marginBottom: 5,
});

export const IconContainer = styled('div')(
  {
    position: 'absolute',
    top: 0,
    bottom: 0,
    width: 30,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  ({ placement }) => ({
    [placement === PLACEMENT.LEFT ? 'left' : 'right']: 0,
  })
);

export const StyledInput = styled('input')(
  {
    width: '100%',
    padding: '0px 15px',
    boxSizing: 'border-box',
    borderRadius: 3,
    fontSize: 14,
    letterSpacing: '-0.2px',
  },
  getResponsiveCSS('height', 44, 48),
  ({ theme, error, iconPlacement }) => ({
    paddingLeft: iconPlacement === PLACEMENT.LEFT ? 40 : 15,
    paddingRight: iconPlacement === PLACEMENT.RIGHT ? 40 : 15,
    color: theme.palette.novotalk.$textColorDarkBlue,
    border: `1px solid ${error ? theme.palette.novotalk.$errorColor : theme.palette.novotalk.$inputBorderColor}`,
    backgroundColor: theme.palette.novotalk.$backgroundColor,
    '&:focus': {
      border: `1px solid ${theme.palette.novotalk.$inputBorderColorActive}`,
    },
    '&::placeholder': {
      color: theme.palette.novotalk.$textColorDisabled,
    },
  })
);
