import React from 'react';
import PropTypes from 'prop-types';
import { TEXT_SIZE, TEXT_COLOR } from '@components/Text';
import {
  MonitorTypesStyledIcon, PatternTypesStyledIcon, ProductionDurationStyledIcon, ErrorsStyledIcon,
  ProgressStyledIcon, RepetitionStyledIcon, StimulusStyledIcon, RequiredSign,
} from './styles';
import { getFormattedTextCurry } from '../utils/formattedText';

const getFormattedText = getFormattedTextCurry();

export const MonitorTypesTitle = ({ miniOverview, showRequiredSign }) => (
  <>
    <MonitorTypesStyledIcon miniOverview={miniOverview} />
    {getFormattedText('UI.monitorTypes', {
      size: TEXT_SIZE[miniOverview ? 'H4' : 'T2'],
      textColor: TEXT_COLOR.DARK_BLUE,
      weight: miniOverview ? 'bold' : 900,
    })}
    {!miniOverview && showRequiredSign && <RequiredSign>*</RequiredSign>}
  </>
);
MonitorTypesTitle.propTypes = { miniOverview: PropTypes.bool };

export const PatternTypesTitle = ({ miniOverview }) => (
  <React.Fragment>
    <PatternTypesStyledIcon miniOverview={miniOverview} />
    {getFormattedText('UI.patternType', {
      size: TEXT_SIZE[miniOverview ? 'H4' : 'T2'],
      textColor: TEXT_COLOR.DARK_BLUE,
      weight: miniOverview ? 'bold' : 900,
    })}
  </React.Fragment>
);
PatternTypesTitle.propTypes = { miniOverview: PropTypes.bool };

export const ProductionDurationTitle = ({ miniOverview }) => (
  <React.Fragment>
    <ProductionDurationStyledIcon miniOverview={miniOverview} />
    {getFormattedText('UI.productionDuration', {
      size: miniOverview ? TEXT_SIZE.H4 : 16,
      textColor: TEXT_COLOR.DARK_BLUE,
      weight: 'bold',
    })}
  </React.Fragment>
);
ProductionDurationTitle.propTypes = { miniOverview: PropTypes.bool };

export const SelfManagedPaceTitle = () => (
  <React.Fragment>
    <ProductionDurationStyledIcon />
    {getFormattedText('UI.selfManagedPace', { size: 16, weight: 'bold', textColor: TEXT_COLOR.DARK_BLUE })}
  </React.Fragment>
);

export const ErrorsTitle = ({ miniOverview }) => (
  <React.Fragment>
    <ErrorsStyledIcon miniOverview={miniOverview} />
    {getFormattedText('Common.UI.errors', {
      size: TEXT_SIZE[miniOverview ? 'H4' : 'T2'],
      textColor: TEXT_COLOR.DARK_BLUE,
      weight: miniOverview ? 'bold' : 900,
    })}
  </React.Fragment>
);
ErrorsTitle.propTypes = { miniOverview: PropTypes.bool };

export const ProgressTitle = ({ miniOverview }) => (
  <React.Fragment>
    <ProgressStyledIcon miniOverview={miniOverview} />
    {getFormattedText('Common.UI.progress', {
      size: TEXT_SIZE[miniOverview ? 'H4' : 'T2'],
      textColor: TEXT_COLOR.DARK_BLUE,
      weight: miniOverview ? 'bold' : 900,
    })}
  </React.Fragment>
);
ProgressTitle.propTypes = { miniOverview: PropTypes.bool };

export const RepetitionTitle = ({ miniOverview }) => (
  <React.Fragment>
    <RepetitionStyledIcon miniOverview={miniOverview} />
    {getFormattedText('UI.selfManagedPace', {
      size: TEXT_SIZE[miniOverview ? 'H4' : 'T2'],
      textColor: TEXT_COLOR.DARK_BLUE,
      weight: miniOverview ? 'bold' : 900,
    })}
  </React.Fragment>
);
RepetitionTitle.propTypes = { miniOverview: PropTypes.bool };

export const StimulusTitle = ({ miniOverview }) => (
  <React.Fragment>
    <StimulusStyledIcon miniOverview={miniOverview} />
    {getFormattedText('Stimuli.stimulusType', {
      size: TEXT_SIZE[miniOverview ? 'H4' : 'T2'],
      textColor: TEXT_COLOR.DARK_BLUE,
      weight: miniOverview ? 'bold' : 900,
    })}
  </React.Fragment>
);
StimulusTitle.propTypes = { miniOverview: PropTypes.bool };
