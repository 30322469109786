import { components } from 'react-select';
import { HideTags, SelectValue } from './styles';

export const ValueContainer = ({ children, ...props }) => {
  const { getValue, hasValue } = props;
  const selectedOptionsLength = getValue().length;

  if (!hasValue) {
    return (
      <components.ValueContainer {...props}>
        {children}
      </components.ValueContainer>
    );
  }

  return (
    <components.ValueContainer {...props}>
      <SelectValue>
        {`${selectedOptionsLength} Mentors Selected`}
      </SelectValue>
      <HideTags>{children}</HideTags>
    </components.ValueContainer>
  );
};
