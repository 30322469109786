import { fromJS } from 'immutable';
import { TYPES } from './actions';

export const initialState = fromJS({
  error: null,
  connectedToMirror: false,
  recordingVideo: false,
});

function chatReducer(state = initialState, action) {
  switch (action.type) {
    case TYPES.SET_MIRROR_STATUS_TO_CONNECTED:
      return state.set('connectedToMirror', true);
    case TYPES.SET_MIRROR_STATUS_TO_DISCONNECTED:
      return state.set('connectedToMirror', false);
    case TYPES.MIRROR_ERROR:
      return state.set('error', action.error);
    case TYPES.START_VIDEO_RECORDING:
      return state.set('recordingVideo', true).set('connectedToMirror', true);
    case TYPES.STOP_VIDEO_RECORDING:
      return state.set('recordingVideo', false);
    case TYPES.DISCONNECT_FROM_MIRROR_SESSION:
      return state.set('connectedToMirror', false).set('recordingVideo', false);
    default:
      return state;
  }
}

export default chatReducer;
