import gql from 'graphql-tag';
import { graphQuery, graphMutation } from '@services/commService';

export const getEntityCourses = async () => {
  const query = `
    {
      entityCourses{
        id
        name
        type
        isDefault
        Subscription{
          type
        }
        Skills{
          id
          name
          texts{
            description
          }
          free
          type
          progress
          subscribed
          Lessons{
            id
            type
            name
            hasPassed
            isAccessible
          }
          Practices{
            id
            name
            type
            duration
            hasPassed
            isAccessible
            Exercises{
              hasPassed
              type
            }
          }
          Polls{
            id
            type
            name
            hasPassed
            isAccessible
          }
        }
      }
    }
  `;
  const res = await graphQuery(query, true);
  if (res?.data.error) {
    throw new Error(res.data.error);
  } else {
    const { entityCourses } = res?.data ?? { entityCourses: [] };
    return entityCourses;
  }
};

export const getEntityLesson = async (lessonId) => {
  const query = `
    {
    entityLessons(id:"${lessonId}") {
      id
      name
      type
      hasPassed
      texts{
        instructions
        description
      }
      media{
        demo
        demoId
        lesson
        lessonId
      }
      monitor{
        monitor
        pattern
        errors
        progress
        stimulus
      }
      Activities{
        id
        activityCreated{
          at
        }
        summary{
          achievedMasteryLevel
          insights{
            type
            insights{
              severity
              error
            }
          }
          hasPassed
        }
      }
    }
  }
`;
  const res = await graphQuery(query, true);
  const { entityLessons } = res.data;
  return entityLessons[0];
};

export const getEntitySkill = async (skillId) => {
  const query = `
  {
    entitySkills(id: "${skillId}" ) {
      id
      name
      type
      texts{
        description
      }
      progress
      Practices{
        name
        duration
      }
      Lessons{
        name
      }
      Polls{
        name
      }
    }
  }
`;
  const res = await graphQuery(query, true);
  const { entitySkills } = res.data;
  return entitySkills[0];
};

export const getUserFeedbacks = async () => {
  const query = `
  { 
    feedbacks { 
      id
      createdAt
      payload   
      publisherUser { 
        firstName
        lastName
      } seenBy
      practiceOfFeedback }
  }`;
  const res = await graphQuery(query, true);
  const { feedbacks } = res.data;
  return feedbacks;
};

export const getEntityPractice = async (practiceId) => {
  const query = `
  {
    entityPractices(id:"${practiceId}") {
      id
      name
      type
      duration
      hasPassed
      Activities {
        id
        activityCreated {
          at
        }
        Threads {
          Posts{
            publisherUser{
              firstName
              lastName
              avatar
            }
            id
            createdAt
            payload
            seenBy
          }
        }
        summary{
          achievedMasteryLevel
          insights{
            type
            insights{
              severity
              error
            }
          }
          hasPassed
          passedAt
        }
        Exercises{
          type
          id
          entityInfo{
            id
          }
          activityCreated{
            at
          }
          summary{
            achievedMasteryLevel
            insights {
              type
              insights{
                severity
                error
              }
            }
            hasPassed
          }

        }
      }
      Exercises {
        id
        name
        type
        texts {
          instructions
        }
        monitor {
          monitor
          pattern
          errors
          progress
          stimulus
        }
        masteryLevel
        media {
          demo
          demoId
        }
        hasPassed
        Activities {
          id
          activityCreated {
            at
          }
          summary {
            achievedMasteryLevel
            insights {
              type
              insights{
                severity
                error
              }
            }
            hasPassed
          }
        }
      }
    }
  }
`;
  const res = await graphQuery(query, true);
  const { entityPractices } = res.data;
  return entityPractices[0];
};

export const createActivityPractice = async (activityContext) => {
  const variables = { context: activityContext };
  const mutation = `
  mutation(
    $context: ActivityContextInput
    ){
    createActivityPractice(
      context: $context
    ) {
      id
      activityCreated{
        at
      }
      Exercises{
        id
      }
      Threads {
        Posts{
          id
        }
      }
    }
  }
`;
  const res = await graphMutation(mutation, variables, true);
  const { createActivityPractice: activityPractice } = res.data;
  return activityPractice;
};

export const createActivityExercise = async (activityContext) => {
  const variables = { context: activityContext };
  const mutation = `
  mutation(
    $context: ActivityContextInput
    ){
      createActivityExercise(
      context: $context
    ) {
      id
      activityCreated{
        at
      }
      summary{
        achievedMasteryLevel
         insights {
           type
           insights{
             severity
             error
           }
         }
         hasPassed
      }
    }
  }
`;
  const res = await graphMutation(mutation, variables, true);
  const { createActivityExercise: activityExercise } = res.data;
  return activityExercise;
};

export const getActivityExercise = async (activityId) => {
  const query = `
  {
    activityExercises(id: "${activityId}"){
      id
      activityCreated{
        at
      }
      summary{
        achievedMasteryLevel
        insights{
          type
          insights{
            severity
            error
          }
        }
        hasPassed
      }
    }
  }
`;
  const res = await graphQuery(query, true);
  const activityExercise = res.data.activityExercises[0];
  return activityExercise;
};

export const createActivityLesson = async (activityContext) => {
  const variables = { context: activityContext };
  const mutation = `
  mutation(
    $context: ActivityContextInput
    ){
      createActivityLesson(
      context: $context
    ) {
      id
      activityCreated{
        at
      }
      summary{
        achievedMasteryLevel
         insights {
           type
           insights{
             severity
             error
           }
         }
         hasPassed
      }
    }
  }
`;
  const res = await graphMutation(mutation, variables, true);
  const { createActivityLesson: activityLesson } = res.data;
  return activityLesson;
};

export const getActivityLesson = async (activityId) => {
  const query = `
  {
    activityLessons(id: "${activityId}"){
      id
      activityCreated{
        at
      }
      summary{
        achievedMasteryLevel
        insights{
          type
          insights{
            severity
            error
          }
        }
        hasPassed
      }
    }
  }
`;
  const res = await graphQuery(query, true);
  const activityLesson = res.data.activityLessons[0];
  return activityLesson;
};

export const createActivityProduction = async ({
  activityContext,
  monitorType,
  patternType,
  speed,
  inhalationDuration,
  dspSignal,
  startTS,
  endTS,
  productionErrors,
  stimuliDisplayed,
  activeLogOpened,
}) => {
  const speedMutation = speed ? 'speed: $speed' : '';
  const speedVariable = speed ? '$speed: [Float]' : '';
  const variables = {
    context: { ...activityContext, ACTIVITY_LOG: activeLogOpened },
    dspSignal,
    productionErrors,
    stimuliDisplayed,
    monitorType,
    patternType,
    startTS,
    endTS,
    inhalationDuration,
  };
  if (speed) variables.speed = speed;

  const mutation = `
  mutation(
    $context: ActivityContextInput!
    $monitorType: ProductionMonitorTypeEnum!
    $patternType: ProductionPatternTypeEnum
    ${speedVariable}
    $inhalationDuration: Int!
    $dspSignal: [Float]!
    $startTS: Float!
    $endTS: Float!
    $productionErrors: [ActivityProductionErrorInput]
    $stimuliDisplayed : [ProductionStimuliInfoInput]
    ){
      createActivityProduction(
        context: $context
        monitorType: $monitorType
        patternType: $patternType
        ${speedMutation}
        inhalationDuration: $inhalationDuration
        dspSignal: $dspSignal
        startTS: $startTS
        endTS: $endTS
        productionErrors: $productionErrors
        stimuliDisplayed: $stimuliDisplayed
      ){
        id
      }
  }
`;

  const res = await graphMutation(mutation, variables, true);
  const { id } = res.data.createActivityProduction;
  return id;
};

export const callActivityLog = async (id, type, status, skillName, activityId) => {
  const mutation = gql`
  mutation{
    updateActivityLog(externalId: "${id}",type:${type}, status:${status}, skillName:"${skillName}", activityId:"${activityId}") {
      id
    }
  }
  `;

  const res = await graphMutation(mutation);

  return res?.data?.updateActivityLog?.id;
};

export const getCurrentPoll = async (id) => {
  const query = `
  {
    entityPolls(id: "${id}") {
      id
      description
      name
      children{
        id
        type
        payload
      }
    }
  }
  `;
  const res = await graphQuery(query, true);
  const { entityPolls } = res.data;
  return entityPolls[0];
};

export const savePoll = async (poll) => {
  const {
    answers, context, subject, timeSpent,
  } = poll;
  const variables = { answers, context, timeSpent };

  const subjectMutation = subject ? 'subject: $subject,' : '';
  const subjectVariable = subject ? '$subject: ActivityPollSubjectInput,' : '';
  if (subject) variables.subject = subject;

  const mutation = `
  mutation(
    $context: ActivityContextInput,
    ${subjectVariable}
    $answers:  [ActivityPollAnswerInput]
    ){
    createActivityPoll(
      context: $context,
      timeSpent: ${timeSpent},
      ${subjectMutation}
      answers: $answers
    ) {
      id
    }
  }
  `;
  const res = await graphMutation(mutation, variables, true);
  return res.id;
};
