import {styled} from "@mui/material";
import { getIconsByType, iconTypes } from '@services/icons';
import { getResponsiveCSS } from '@utils/cssHelpers';
import Slider from '@components/Slider';

export const Section = styled('div')(
  {
    paddingBottom: 15,
    marginBottom: 15,
    '&:last-of-type': {
      marginBottom: 0,
    },
  },
  ({ theme }) => ({
    borderBottom: `1px solid ${theme.palette.novotalk.$resourcesTableBorderColor}`,
  })
);

export const SectionContent = styled('div')({
  marginLeft: 22,
  marginTop: 12,
});

export const ExtraFeatures = styled('div')(
  {
    paddingTop: 14,
    marginTop: 14,
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-between',
  },
  ({ theme, noBorder }) => ({
    borderTop: noBorder
      ? 'none'
      : `1px solid ${theme.palette.novotalk.$resourcesTableBorderColor}`,
  })
);

export const ExtraFeaturesTitle = styled('div')({
  width: '100%',
  marginBottom: 14,
  '&:last-of-type': {
    marginBottom: 0,
  },
});

export const TitleWrapper = styled('div')({
  display: 'flex',
  alignItems: 'center',
});

export const SelectedMonitorType = styled('div')(
  {
    display: 'inline-flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: '10px 10px 6px',
    borderRadius: 4,
    textAlign: 'center',
  },
  ({ theme }) => ({
    border: `1px solid ${theme.palette.novotalk.$activeColor}`,
  })
);

export const MonitorImageWrapper = styled('div')({
  width: 60,
  height: 60,
  display: 'flex',
  justifyContent: 'stretch',
  alignItems: 'stretch',
  marginBottom: 5,
  '& img': {
    width: '100%',
  },
});

const iconStyles = ({ theme }) => ({
  fontSize: 18,
  marginRight: 8,
  marginLeft: -2,
  marginTop: -2,
  color: theme.palette.novotalk.$activeColor,
});

export const InhalationIcon = styled(getIconsByType(iconTypes.SWAP))(
  {
    fontSize: 14,
    marginRight: 10,
    borderRadius: 2,
  },
  ({ theme }) => ({
    color: theme.palette.novotalk.$textColorReversed,
    backgroundColor: theme.palette.novotalk.$activeColor,
  })
);

export const MirrorIcon = styled(getIconsByType(iconTypes.PERSON))(iconStyles);

export const VideoIcon = styled(getIconsByType(iconTypes.PLAY_CIRCLE))(
  iconStyles
);

export const SplitContainer = styled('div')({
  width: '100%',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
});

export const ErrorGroup = styled(ExtraFeatures)({
  lineHeight: '14px',
  '&:first-of-type': {
    paddingTop: 0,
    marginTop: 0,
    borderTop: 'none',
  },
});

export const DetailsList = styled('div')({
  textAlign: 'right',
  lineHeight: '14px',
});

export const ListItem = styled('div')({
  marginBottom: 14,
  '&:last-of-type': {
    marginBottom: 0,
  },
});

export const SpecificErrorsWrapper = styled('div')(
  getResponsiveCSS('width', 278, 378)
);

export const MasteryContainer = styled(SplitContainer)({
  flexWrap: 'wrap',
});

export const MasteryTitle = styled('div')({
  marginRight: 20,
});

export const MasteryLevelContainer = styled('div')({
  display: 'flex',
  alignItems: 'center',
});

export const StyledSlider = styled(Slider)({
  marginTop: 5,
  marginRight: 10,
});
