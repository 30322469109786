import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'redux';
import injectReducer from '@utils/injectReducer';
import {
  Backdrop,
  DisplayArea,
  CloseIconWrapper,
  StyledCloseIcon,
  ClicksStopper,
} from './styles';
import { closePopUp } from './actions';
import reducer from './reducer';
import { getComponentByType } from './popups';

const CLOSE_SOURCE = {
  BUTTON: 'BUTTON',
  BACKDROP: 'BACKDROP',
  INTERNAL: 'INTERNAL',
};

export class PopUp extends Component {
  close = (source = CLOSE_SOURCE.BUTTON) => (event) => {
    const {
      dispatchClosePopUp,
      props: { onClose, disableBackdrop },
    } = this.props;
    if (event && event.stopPropagation) event.stopPropagation();
    if (disableBackdrop && source === CLOSE_SOURCE.BACKDROP) return;
    if (onClose) onClose();
    dispatchClosePopUp();
  };

  preventBubbling = (event) => {
    event.stopPropagation();
  };

  render() {
    // NOTICE that props.onClose will be overriden, and will always be called via close
    const { props, showing, type } = this.props;
    if (!showing) return null;
    const InnerComponent = getComponentByType(type);

    const closeIcon = !props.hideCloseIcon && (
      <CloseIconWrapper
        className='popup-button-close'
        onClick={this.close(CLOSE_SOURCE.BUTTON)}
      >
        <StyledCloseIcon />
      </CloseIconWrapper>
    );

    return (
      <Backdrop onClick={this.close(CLOSE_SOURCE.BACKDROP)}>
        <DisplayArea className='popup'>
          {closeIcon}
          <ClicksStopper onClick={this.preventBubbling}>
            <InnerComponent
              {...props}
              onClose={this.close(CLOSE_SOURCE.INTERNAL)}
            />
          </ClicksStopper>
        </DisplayArea>
      </Backdrop>
    );
  }
}
PopUp.defaultProps = {
  props: {},
};

PopUp.propTypes = {
  dispatchClosePopUp: PropTypes.func,
  props: PropTypes.object,
  type: PropTypes.string,
  showing: PropTypes.bool,
};

export const mapStateToProps = (state) => {
  const popUpState = state.getIn(['popUp']).toJS();
  const { showing, props, type } = popUpState;
  return { showing, props, type };
};

export function mapDispatchToProps(dispatch) {
  return {
    dispatchClosePopUp: () => dispatch(closePopUp()),
  };
}

const withConnect = connect(mapStateToProps, mapDispatchToProps);
const withReducer = injectReducer({ key: 'popUp', reducer });

export default compose(withReducer, withConnect)(PopUp);
