import { RESOURCES } from '@shared/Resources/types';
export const overview = {
  id: 'someExcerciseID2',
  name: 'Some loveley excercise',
  media: {
    demo: 'https://s3-eu-west-1.amazonaws.com/nvtk-video-media/03_01%20Hebrew%20Low.mp4',
  },
  masteryLevel: 88,
  instructions: "Some TestPract",
  type: RESOURCES.EXERCISE,
  monitor: 'someMonitorID',
  entityCreated: {
    at: 'Thu Aug 02 2018 18:05:31 GMT+0000 (UTC)',
    by: 'User McUser',
  },
  entityUpdated: {
    at: 'Thu Aug 09 2018 08:46:00 GMT+0000 (UTC)',
    by: 'Useriko McUserico',
  },
  usedIn: [
    { id: 1, type: RESOURCES.COURSE, name: 'Some course 1' },
    { id: 2, type: RESOURCES.POLL, name: 'Some poll 2' },
  ],
};



export const practiceFull = {
  id: "1234",
  version: 1,
  duration: 15,
  name: "My full practice",
  children: [
    {
      type:RESOURCES.EXERCISE,
      payload: overview
    }
  ]
};
