import {styled} from "@mui/material";

export const RangeWrapper = styled('div')(
  {
    position: 'relative',
    '& .input-range': {
      width: '100%',
      height: 0,
      padding: '25px 0',
    },
    '& .input-range__label': {
      display: 'none',
    },
    '& .input-range__track': {
      height: 2,
      borderRadius: 0,
    },
    '& .input-range__slider': {
      width: 16,
      height: 16,
      marginTop: -9,
      borderRadius: '50%',
    },
  },
  ({ theme, width }) => ({
    width,
    '& .input-range__track--background': {
      backgroundColor: theme.palette.novotalk.$textColorLightGray,
    },
    '& .input-range__track--active': {
      backgroundColor: theme.palette.novotalk.$activeColor,
    },
    '& .input-range__slider': {
      border: `4px solid ${theme.palette.novotalk.$activeColor}`,
      backgroundColor: theme.palette.novotalk.$backgroundColor,
    },
  }),
  ({ disabled }) =>
    disabled && {
      '& .input-range__slider': {
        width: 0,
        height: 14,
        margin: '-8px 0 0 0',
        borderRadius: 0,
        borderWidth: 0,
        borderRightWidth: 2,
        cursor: 'auto',
      },
      '& .input-range__track': {
        cursor: 'auto',
      },
    }
);

export const LabelContainer = styled('div')(
  {
    position: 'absolute',
    width: 1,
    height: 8,
    top: 19,
    '&:last-of-type': {
      left: 'auto',
      right: 0,
    },
  },
  ({ theme, isValueChoosen, position }) => ({
    left: position,
    backgroundColor: isValueChoosen
      ? theme.palette.novotalk.$activeColor
      : theme.palette.novotalk.$textColorLightGray,
  })
);

export const StyledLabel = styled('div')({
  position: 'absolute',
  top: 16,
  left: -10,
  width: 21,
  textAlign: 'center',
  lineHeight: '15px',
});
