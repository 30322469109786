import {styled} from "@mui/material";
import { getIconsByType, iconTypes } from '@services/icons';

const iconSize = 21;

export const Option = styled('label')({
  minHeight: 36,
  display: 'flex',
  alignItems: 'center',
  position: 'relative',
  cursor: 'pointer',
  userSelect: 'none',
});

export const RadioInput = styled('input')({
  position: 'absolute',
  opacity: 0,
});

const selectStyles = ({ theme }) => ({
  fontSize: iconSize + 8,
  zIndex: 2,
  padding: 4,
  borderRadius: '50%',
  marginLeft: theme.RTL ? 4 : -4,
  marginRight: theme.RTL ? -4 : 4,
});

export const RadioOffIcon = styled(getIconsByType(iconTypes.RADIO_OFF))(
  selectStyles,
  ({ theme }) => ({
    color: theme.palette.novotalk.$selectStandartColor,
    '&:hover': {
      backgroundColor: theme.palette.novotalk.$selectStandartBgColorHover,
    },
    '&:active': {
      color: theme.palette.novotalk.$selectEnabledColor,
      backgroundColor: theme.palette.novotalk.$selectStandartBgColorActive,
    },
  })
);

export const RadioOnIcon = styled(getIconsByType(iconTypes.RADIO_ON))(
  selectStyles,
  ({ theme }) => ({
    color: theme.palette.novotalk.$selectEnabledColor,
    '&:hover': {
      backgroundColor: theme.palette.novotalk.$selectEnabledBgColorHover,
    },
    '&:active': {
      backgroundColor: theme.palette.novotalk.$selectEnabledBgColorActive,
    },
  })
);
