/**
 *
 * ResourcesManager
 *
 */

import { Component } from 'react';
import Proptypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { withRouter } from 'react-router-dom';
import _, { debounce } from 'lodash';
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { PLACEMENT } from '@components/CustomTextInput';
import Text, { TEXT_SIZE, TEXT_COLOR } from '@components/Text';
import { ROUTES } from '@shared/constants';
import { createList } from '@shared/Resources/listFactory';
import {
  RESOURCES,
  CREATABLE_RESOURCES,
  TYPES_MEDIA,
} from '@shared/Resources/types';
import FilteredList from '@shared/RoleManagementSection';
import ResourceOverview from '@shared/ResourceOverview';
import withResources from '@containers/Admin/shared/ResourcesWrapper';
import {
  changeFilterType, clearResourcesData,
  getResources, resetOffset,
  setSearchText,
} from '@containers/Admin/shared/ResourcesWrapper/actions';
import ResourceHeader from './components/ResourceHeader';
import {
  Container,
  ResourcesIndex,
  ResourcesItems,
  ResourcesOverviewContainer,
  IndexHeader,
  IndexTitle,
  StyledCustomTextInput,
  StyledSearchIcon,
  IndexList,
  IndexItem,
  getStyledIcon,
} from './styles';
import POPUP_TYPES from '../../../App/containers/PopUp/types';
import { openPopUp } from '../../../App/containers/PopUp/actions';
import { SOURCE_TYPE } from './consts';

const getTypeUrl = (type, id = null) => {
  const typePath = `${type.toLowerCase()}`;
  const idPath = id ? `/${id}` : '';
  return `${ROUTES.RESOURCES_MANAGER}/${typePath}${idPath}`;
};

class ResourceManager extends Component {
  componentDidMount() {
    const { urlType } = this.getStatusObjects();
    this.loadResourcesList(urlType);
  }

  componentDidUpdate(prevProps) {
    const { searchText, dispatchClearResourcesData } = this.props;
    if (prevProps.searchText.length > searchText.length) {
      dispatchClearResourcesData();
    }
    if (this.props.searchText !== prevProps.searchText) {
      this.debouncedSearch();
    }
    if (
      !_.get(this.props, 'overview.loading', false)
      && this.isUrlMismatchProps()
    ) {
      this.matchPropsToUrl();
    }
  }

  componentWillUnmount() {
    const { dispatchClearResourcesData } = this.props;
    dispatchClearResourcesData();
  }

  debouncedSearch = debounce(async () => {
    this.props.dispatchResetOffset();
    this.props.dispatchLoadFilteredResources(this.props.type, this.props.searchText);
  }, 300);

  onResourceSelected = ({ id }) => this.loadResourceOverview(id);

  onResourcesPreview = (item) => {
    this.props.dispatchOpenPopup(POPUP_TYPES.MEDIA, {
      contentType: item.type,
      contentUrl: item.value,
      description: item.title,
    });
  };

  getStatusObjects = () => ({
    urlType: _.get(
      this.props.match,
      'params.type',
      RESOURCES.COURSE,
    ).toUpperCase(),
    urlId: _.get(this.props.match, 'params.id', null),
    propsType: _.get(this.props, 'type', '').toUpperCase(),
    propsId: _.get(this.props, 'overview.resource.id', null),
  });

  handleFilterInput = (value) => {
    this.props.dispatchSetSearchText(value);
  };

  loadResourcesList = (resourceType) => {
    this.props.dispatchChangeFilterType(true);
    this.props.history.push(getTypeUrl(resourceType));
    this.props.dispatchLoadFilteredResources(resourceType);
    this.props.dispatchSetSearchText('');
  };

  loadResourceOverview = (id) => {
    const {
      urlType, urlId, propsId, propsType,
    } = this.getStatusObjects();
    if (urlId !== id) {
      this.props.history.push(getTypeUrl(urlType, id));
      this.props.dispatchLoadItem(urlType, id);
    } else if (propsType === RESOURCES.MEDIA) {
      this.props.history.push(getTypeUrl(propsType));
    } else if (!propsId) {
      this.props.dispatchLoadItem(urlType, id);
    }
  };

  isUrlMismatchProps = () => {
    const {
      urlType, urlId, propsType, propsId,
    } = this.getStatusObjects();
    const itemIdMismatch = propsId !== urlId;
    const typeMismatch = propsType !== urlType;
    return itemIdMismatch || typeMismatch;
  };

  matchPropsToUrl = () => {
    const {
      urlType, urlId, propsType, propsId,
    } = this.getStatusObjects();
    const itemIdMismatch = propsId !== urlId;
    const typeMismatch = propsType !== urlType;

    if (typeMismatch) {
      this.props.dispatchLoadFilteredResources(urlType);
    } else if (itemIdMismatch && urlId) {
      this.loadResourceOverview(urlId);
    }
  };

  capitalize = (val) => _.startCase(_.toLower(val));

  createNewResource = (resource) => {
    this.props.dispatchCreateNew(resource);
  };

  editResource = ({ type, id }) => {
    if (TYPES_MEDIA.includes(type)) {
      return this.props.dispatchOpenPopup(POPUP_TYPES.MEDIA_EDITOR);
    }
    this.props.history.push(`${ROUTES.BUILDER}/${type}/${id}`);
  };

  openConfirmPopup = (source) => (resource) => {
    const {
      dispatchDeleteItem,
      dispatchCopyItem,
      dispatchDuplicateItem,
      dispatchOpenPopup,
      t,
    } = this.props;

    let onConfirmCallback;
    let popupType;
    let titleText;
    let confirmMessage;
    let bodyText;

    switch (source) {
      case SOURCE_TYPE.DELETE:
        onConfirmCallback = dispatchDeleteItem;
        popupType = POPUP_TYPES.CONFIRM;
        titleText = t('Informative.Confirming.areYouSure');
        bodyText = t('Informative.Negative.actionUndone');
        confirmMessage = t('Actions.Confirmations.confirmDelete').toUpperCase();
        break;
      case SOURCE_TYPE.COPY:
        onConfirmCallback = dispatchCopyItem;
        popupType = POPUP_TYPES.CONFIRM;
        titleText = t('Informative.Confirming.areYouSure');
        bodyText = t('Informative.Notifying.makeCopy');
        confirmMessage = t('Actions.Confirmations.confirmCopy').toUpperCase();
        break;
      case SOURCE_TYPE.DUPLICATE:
        onConfirmCallback = dispatchDuplicateItem;
        popupType = POPUP_TYPES.DUPLICATE;
        titleText = t('Informative.Demanding.chooseCopyOwner');
        confirmMessage = t('Actions.Instance.duplicate').toUpperCase();
        break;
      default:
        break;
    }

    dispatchOpenPopup(popupType, {
      hideCloseIcon: true,
      onConfirm: (confirm, userId) => confirm && onConfirmCallback(resource, userId),
      titleText,
      confirmText: confirmMessage,
      cancelText: t('Actions.Simple.no').toUpperCase(),
      bodyText: source !== SOURCE_TYPE.DUPLICATE && bodyText,
    });
  };

  openOverviewPopup = (resource) => {
    const { dispatchExpandItem, dispatchOpenPopup } = this.props;
    this.props.dispatchLoadItem(resource.type, resource.id, 'popup');

    dispatchOpenPopup(POPUP_TYPES.RESOURCE_OVERVIEW, {
      type: resource.type,
      onExpand: (item) => dispatchExpandItem(item, 'popup'),
      onEdit: this.editResource,
    });
  };

  renderResources = (currentResourceType) => {
    const IndexFilter = (props) => (
      <StyledCustomTextInput
        {...props}
        Icon={StyledSearchIcon}
        iconPlacement={PLACEMENT.RIGHT}
        name="resources-filter-input"
        id="resources-filter-input"
      />
    );
    const isItemsSelected = (resourceType) => resourceType === currentResourceType;

    return (
      <ResourcesIndex id="resourcesPanel">
        <FilteredList
          items={_.values(CREATABLE_RESOURCES)}
          titleMessage={this.props.t('Common.UI.resources')}
          onItemSelect={this.loadResourcesList}
          isItemsSelected={isItemsSelected}
          filterItemBy={(filter) => (resourceType) => resourceType.toLowerCase().includes(filter.toLowerCase())}
          getItemKey={(resourceType) => resourceType}
          getItemDisplay={(resourceType) => {
            const Icon = getStyledIcon(resourceType);
            return (
              <Text
                size={TEXT_SIZE.T3}
                textColor={
                  TEXT_COLOR[
                    isItemsSelected(resourceType) ? 'ACTIVE' : 'DISABLED_LIGHT'
                  ]
                }
              >
                <Icon />
                <span>{this.props.t(`Common.UI.${resourceType === 'SPEECH_GOAL' ? 'speechGoal' : resourceType.toLowerCase()}`)}</span>
              </Text>
            );
          }}
          placeholderText={this.props.t('Common.Placeholders.searchResources')}
          customComponents={{
            header: IndexHeader,
            headerTitle: IndexTitle,
            itemsList: IndexList,
            item: IndexItem,
            filter: IndexFilter,
          }}
        />
      </ResourcesIndex>
    );
  };

  renderResourceItems = () => {
    const { type, resources, overview } = this.props;
    const items = resources.filter(({ name }) => name);
    let selectedItem = null;
    if (overview) selectedItem = overview.resource ? overview.resource : null;
    return (
      <ResourcesItems id="resourceItemsPanel">
        {createList(type, {
          items,
          onSelect: this.onResourceSelected,
          onPreview: this.onResourcesPreview,
          selectedItem,
        })}
      </ResourcesItems>
    );
  };

  render() {
    if (!this.props.type) return null;
    const {
      dispatchExpandItem, type, overview, t, searchText,
    } = this.props;
    const hasResource = overview && (overview.resource || overview.loading);
    return (
      <Container>
        {this.renderResources(type)}
        <ResourceHeader
          type={type}
          onNewResource={this.createNewResource}
          filterValue={searchText}
          onFilterInput={this.handleFilterInput}
        />
        {this.renderResourceItems()}
        <ResourcesOverviewContainer>
          {hasResource ? (
            <ResourceOverview
              loading={overview.loading}
              type={this.props.type}
              resource={overview.resource}
              error={overview.errorData}
              onCopy={this.openConfirmPopup('copy')}
              onDuplicate={this.openConfirmPopup('duplicate')}
              onDelete={this.openConfirmPopup('delete')}
              onEdit={this.editResource}
              onView={this.openOverviewPopup}
              onExpand={dispatchExpandItem}
            />
          ) : (
            <div id="noResource">
              {t('Informative.Negative.noResource')}
            </div>
          )}
        </ResourcesOverviewContainer>
      </Container>
    );
  }
}

ResourceManager.defaultProps = {
  resources: [],
};

ResourceManager.propTypes = {
  match: Proptypes.object,
  history: Proptypes.object, // from react router

  // redux state properties
  loadingResources: Proptypes.bool,
  resources: Proptypes.array,
  overview: Proptypes.shape({
    loading: Proptypes.bool,
    resource: Proptypes.object,
  }),
  type: Proptypes.string,
  searchText: Proptypes.string,

  dispatchLoadResources: Proptypes.func,
  dispatchLoadItem: Proptypes.func,
  dispatchExpandItem: Proptypes.func,
  dispatchDuplicateItem: Proptypes.func,
  dispatchDeleteItem: Proptypes.func,
  dispatchCreateNew: Proptypes.func,

  dispatchOpenPopup: Proptypes.func,
  dispatchChangeFilterType: Proptypes.func,
  dispatchLoadFilteredResources: Proptypes.func,
  dispatchSetSearchText: Proptypes.func,
  dispatchClearResourcesData: PropTypes.func,
  dispatchResetOffset: PropTypes.func,
};

export function mapDispatchToProps(dispatch) {
  return {
    dispatchOpenPopup: (type, props) => dispatch(openPopUp(type, props)),
    dispatchChangeFilterType: () => dispatch(changeFilterType()),
    dispatchLoadFilteredResources: (type, search) => dispatch(getResources(type, search)),
    dispatchSetSearchText: (text) => dispatch(setSearchText(text)),
    dispatchClearResourcesData: () => dispatch(clearResourcesData()),
    dispatchResetOffset: () => dispatch(resetOffset()),
  };
}

const mapStateToProps = (state) => {
  const searchText = state.getIn(['resourceManager', 'searchText']);
  return { searchText };
};

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default compose(
  withConnect,
  withRouter,
  withResources,
  withTranslation(),
)(ResourceManager);
