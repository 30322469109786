import {
  MONITOR_TYPES,
  EXTRA_MONITOR_FEATURES,
  PATTERN_TYPES,
  DIFFICULTY,
  STIMULUS_TYPE,
  PROGRESS_TYPE
} from '../types';

//for each error, value should be null or DIFFICULTY
export const monitorConfiguration = {
  monitor: {
    type: MONITOR_TYPES.LOUDNESS,
    inhalation: { on: false ,value:0 },
    video: {on: false, url:"https://s3-eu-west-1.amazonaws.com/nvtk-video-media/הדגמת כיול.mp4", id:"3"},
    mirror: { on: false },
    speedometer:{ on: false },
  },
  pattern: {
    type:'GENTLE_VOICE',
    selfManagePace:{min:1,max:2},
    productionDuration: 1,
  },
  errors: {
    on: true,
    masteryLevel: 40,
    types: [],
    // production: {
    //   tooShort: { on: true , type:DIFFICULTY.EASY},
    //   tooLong: { on: true , type:DIFFICULTY.EASY},
    //   notLoud: { on: true , type:DIFFICULTY.EASY},
    //   notGentle: { on: true , type:DIFFICULTY.EASY},
    //   noPattern: { on: true , type:DIFFICULTY.EASY},
    // },
    // specific: {
    //   beginning:{
    //     nonGentleOnset: { on: true , type:DIFFICULTY.EASY},
    //     notGentle: { on: false , type:DIFFICULTY.EASY},
    //     volumeControl: { on: true , type:DIFFICULTY.EASY},
    //   },
    //   peak: {
    //     notLoud: { on: true , type:DIFFICULTY.EASY},
    //     softPeak: { on: false , type:DIFFICULTY.EASY},
    //     excessivePeak: { on: true , type:DIFFICULTY.EASY},
    //   },
    //   end: {
    //     volumeControl: { on: true , type:DIFFICULTY.EASY},
    //     nonGentleOffset: { on: true , type:DIFFICULTY.EASY},
    //   }
    // } 
  },
  progress: {
    type:PROGRESS_TYPE.SEQUENCES,
    value:30,
    repetition: {
      on: true,
      count:1
    },
  },
  stimulus:{
    type: STIMULUS_TYPE.SYLLABLES,
    config:{
      syllableStructure:{c:true,cv:true},
      consonantsFeatures:{PLOSIVE:true},
    }
  }
};