import React from 'react';
import PropTypes from 'prop-types';
import { TEXT_SIZE, TEXT_COLOR } from '@components/Text';
import RadioButtonsGroup from '@components/RadioButtonsGroup';
import { Wrapper, Title } from './styles';
import { getFormattedTextCurry } from '@shared/Resources/Monitor/shared/utils/formattedText';
import { STIMULUS_DIVISION_TYPE } from '../../../../../../../../types';
const getFormattedText = getFormattedTextCurry();

const StimulusDivision = ({
  value,
  onChange,
  dividedValue,
  dividedMessage,
}) => (
  <Wrapper className='division'>
    <Title margin='0 0 5px 0'>
      {getFormattedText('Common.UI.division', {
        weight: 'bold',
        textColor: TEXT_COLOR.DARK_BLUE,
        size: TEXT_SIZE.H4,
      })}
    </Title>
    <RadioButtonsGroup
      value={value}
      className='divisionOptions'
      onChange={onChange}
      options={[
        {
          value: dividedValue,
          label: getFormattedText('Common.Statuses.divided', {
            textColor: TEXT_COLOR.DARK_BLUE,
            size: TEXT_SIZE.H4,
          }),
        },
        {
          value: STIMULUS_DIVISION_TYPE.UNDIVIDED,
          label: getFormattedText('Common.Statuses.undivided', {
            textColor: TEXT_COLOR.DARK_BLUE,
            size: TEXT_SIZE.H4,
          }),
        },
      ]}
    />
  </Wrapper>
);

StimulusDivision.propTypes = {
  value: PropTypes.string,
  onChange: PropTypes.func,
  dividedValue: PropTypes.string,
  dividedMessage: PropTypes.string,
};
export default StimulusDivision;
