import {styled} from "@mui/material";
import { novotalkOrange, novotalkOrangeHover } from '@services/theme/themeColors';
import _ from 'lodash';

export const ProgressBarWrapper = styled('div')({
  marginRight: 10,
  position: 'relative',
  },
  ({ theme, border, height, width, isPracticeError }) => ({
    height,
    width,
    cursor: isPracticeError && 'pointer',
    borderRadius: Math.ceil(height / 2),
    border: border ? (isPracticeError ? '1px solid #979797' : '2px solid') : 'none',
    backgroundColor: isPracticeError ? novotalkOrange : theme.palette.novotalk.$backgroundColorDark,
    borderColor: isPracticeError ? theme.palette.novotalk.$radioButtonColor : theme.palette.novotalk.$backgroundColorDark,
    transition: '0.3s ease all',
    '&:hover': {
      backgroundColor: isPracticeError && novotalkOrangeHover,
      transition: '0.3s ease all',
    }
  })
);

export const Progress = styled('div')(
  ({ theme, progress, completed, mastery, isPracticeError }) => {
    let backgroundColor;
    if (_.isUndefined(mastery) && !isPracticeError) {
      backgroundColor = completed ? theme.palette.novotalk.$completedColor : theme.palette.novotalk.$activeColor;
    } else if (isPracticeError){
      backgroundColor = theme.palette.novotalk.$completedColor;
    } else {
      backgroundColor = progress < mastery ? theme.palette.novotalk.$uncompletedProgressColor : theme.palette.novotalk.$completedColor;
    }
    return {
      height: '100%',
      width: `${progress}%`,
      backgroundColor,
      borderRadius: '10px 0 0 10px',
      transition: '0.3s ease all',
      '&:hover': {
        backgroundColor: isPracticeError && theme.palette.novotalk.$completedColorHover,
        transition: '0.3s ease all',
      }
    };
  }
);

export const PracticeErrorsProgress = styled('div')(({ height, practiceErrorsPercents }) => ({
  position: 'absolute',
  top: 0,
  right: 0,
  height,
  width: `${practiceErrorsPercents}%`,
  borderRadius: Math.ceil(height / 2),
}));

export const Mastery = styled('div')(
  {
    position: 'absolute',
    zIndex: 1,
    top: 1,
  },
  ({ theme, mastery, progress, height }) => ({
    [theme.RTL ? 'borderLeft' : 'borderRight']: '2px solid',
    width: `${mastery}%`,
    borderColor:
      progress > mastery
        ? theme.palette.novotalk.$textColorReversed
        : theme.palette.novotalk.$textColorDisabledLight,
    height: height - 2,
  })
);
