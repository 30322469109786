import React from 'react';
import PropTypes from 'prop-types';
import {styled} from "@mui/material";
import CustomTextInput, { PLACEMENT } from '@components/CustomTextInput';
import { getResponsiveCSS } from '@utils/cssHelpers';
import { getIconsByType, iconTypes } from '@services/icons';
import {useTranslation} from "react-i18next";

export const StyledSearchIcon = styled(getIconsByType(iconTypes.SEARCH))(
  {
    fontSize: 18,
    marginRight: 10,
  },
  ({ theme }) => ({
    color: theme.palette.novotalk.$textColorLightGray,
  })
);

const StyledCustomTextInput = styled(CustomTextInput)(
  {
    minHeight: 38,
    width: '100%',
    outline: 'none',
  },
  getResponsiveCSS('maxHeight', 38, 42),
  ({ fullWidth }) => !fullWidth && getResponsiveCSS('width', 230, 322),
  ({ theme }) => ({
    backgroundColor: theme.palette.novotalk.$resourcesInputBgColor,
    borderColor: theme.palette.novotalk.$resourcesInputBgColor,
  })
);

const Input = ({ placeholder, ...props }) => {
    const {t} = useTranslation()
    return (
        <StyledCustomTextInput
            {...props}
            placeholder={t(placeholder)}
            Icon={() => <StyledSearchIcon />}
            iconPlacement={PLACEMENT.RIGHT}
        />
    );
}

Input.propTypes = {
  fullWidth: PropTypes.bool,
  placeholder: PropTypes.object,
};

export default Input;
