import { AvatarUserWithName } from '../../../avatar-user-with-name';
import { ParticipantsWrapper } from './styles';
import Text from "@components/Text";
import {useTranslation} from "react-i18next";


export const Participants = ({ participants, currentUserId }) => {
    const {t} = useTranslation()
    return (
        <div>
            <Text size="H4" textTransform="uppercase">
                {t('Common.UI.participants')}
            </Text>
            <ParticipantsWrapper>
                {participants.map(({ id, fullName, avatar_url: avatarUrl }) => (
                    <AvatarUserWithName
                        key={id}
                        url={avatarUrl}
                        fullName={fullName}
                        participantId={id}
                        currentUserId={currentUserId}
                    />
                ))}
            </ParticipantsWrapper>
        </div>
    );
}
