import {styled} from "@mui/material";
import { css } from 'emotion';
import { getIconsByType, iconTypes } from '@services/icons';

const videoIcon = getIconsByType(iconTypes.VIDEO_CHAT);

export const StyledVideoIcon = styled(videoIcon)({
  marginTop: -20,
  marginBottom: 32,
});

export const alertButtonCSS = css({
  width: 240,
  height: 52,
  borderRadius: 26,
  marginTop: -18,
  marginBottom: -12,
});
