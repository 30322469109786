import {styled} from "@mui/material";

export const QuestionText = styled('div')(
  {
    minHeight: 44,
  },
  ({ theme }) => ({
    color: theme.palette.novotalk.$textColorDarkBlue,
  })
);

export const RequiredSign = styled('span')({
    fontSize: 20,
    fontWeight: 900,
    marginLeft: '5px',
  },
  ({ theme }) => ({
    color: theme.palette.novotalk.$errorColor,
  })
);
