import {styled} from "@mui/material";
import ProgressBar from '@components/ProgressBar';
import AudioPlayer from '@components/AudioPlayer';
import CTAButton from '@components/CTAButton';
import { getResponsiveCSS } from '@utils/cssHelpers';
import { getIconsByType, iconTypes } from '@services/icons';

export const Wrapper = styled('div')({
  marginBottom: 12,
});

export const Header = styled('div')(
  {
    width: '100%',
    cursor: 'pointer',
    padding: '0 20px',
    position: 'relative',
  },
  getResponsiveCSS('height', 48, 52),
  ({ theme, open }) => ({
    borderRadius: open ? '4px 4px 0 0 ' : 4,
    backgroundColor: open
      ? theme.palette.novotalk.$backgroundColorMediumHover
      : theme.palette.novotalk.$backgroundColorMedium,
    '&:hover': {
      backgroundColor: theme.palette.novotalk.$backgroundColorMediumHover,
    },
  })
);

export const InnerHeader = styled('div')({
  display: 'grid',
  alignItems: 'center',
  gridTemplateColumns: 'auto 180px 26% 24px',
  height: '100%',
});

export const IconWrapper = styled('div')({
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  display: 'flex',
  alignItems: 'center',
});

export const ErrorIconWrapper = styled('div')({
  marginRight: 7,
  marginTop: -3,
  width: 21,
});

export const CollapseButton = styled('button')(
  {
    cursor: 'pointer',
    outline: 'none',
    fontSize: 21,
    marginTop: -5,
  },
  ({ theme }) => ({
    color: theme.palette.novotalk.$textColorDisabledLight,
  })
);

export const Body = styled('div')(
  {
    padding: '30px 30px 10px',
    borderRadius: '0 0 4px 4px',
    marginBottom: 5,
  },
  ({ theme }) => ({
    backgroundColor: theme.palette.novotalk.$backgroundColor,
    border: `solid 1px ${theme.palette.novotalk.$widgetMenuBorderColor}`,
    borderTop: 'none',
  })
);

export const ExerciseInfo = styled('div')(
  {
    display: 'grid',
    paddingBottom: 26,
    marginBottom: 26,
  },
  getResponsiveCSS('columnGap', 40, 65),
  ({ theme, hasPassed }) => ({
    gridTemplateColumns: `repeat(${hasPassed ? 4 : 5}, auto) 1fr`,
    borderBottom: `solid 1px ${theme.palette.novotalk.$widgetMenuBorderColor}`,
  })
);

export const InfoContainer = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  lineHeight: '27px',
});

export const StyledAudioPlayer = styled(AudioPlayer)({
  position: 'relative',
  top: 2,
  '& .audioplayer-track': getResponsiveCSS('width', 96, 140),
});

export const Progress = styled('div')({
  display: 'flex',
  alignItems: 'center',
});

export const StyledProgressBar = styled(ProgressBar)(
  getResponsiveCSS('width', 120, 160)
);

export const VideoButtonContainer = styled('div')({
  justifySelf: 'end',
  alignSelf: 'center',
});

export const VideoButton = styled(CTAButton)(
  getResponsiveCSS('width', 100, 140),
  getResponsiveCSS('height', 38, 44)
);

export const StyledVideoIcon = styled(getIconsByType(iconTypes.FILM))({
  fontSize: 16,
  margin: '0 5px',
});

export const InsightsWrapper = styled('div')(
  {
    paddingBottom: 26,
    marginBottom: 26,
  },
  ({ theme }) => ({
    borderBottom: `solid 1px ${theme.palette.novotalk.$widgetMenuBorderColor}`,
  })
);

export const ProductionWrapper = styled('div')({});

export const ProductionsHeader = styled('div')({
  display: 'grid',
  gridTemplateColumns: '6% 50px auto 16% 17% 205px',
  marginTop: 15,
  marginBottom: 8,
});
