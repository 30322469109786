import {styled} from "@mui/material";
import ButtonBase from '@components/Button';

export const Wrapper = styled('div')(
  {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: '45px 75px',
    borderRadius: '9px',
  },
  ({ theme }) => ({
    backgroundColor: theme.palette.novotalk.textColorReversed,
  })
);

const InnerLine = styled('div')({
  width: 416,
  color: '#8A96A0',
  marginBottom: 15,
});

export const Body = styled(InnerLine)({
  marginBottom: 40,
});

export const Title = styled(InnerLine)({
  marginBottom: 30,
  textAlign: 'center',
});

export const Button = styled(ButtonBase)(
  {
    height: 54,
    width: 221,
    borderRadius: 4,
    boxShadow: '0 10px 20px 0 rgba(0,80,255,0.21)',
  },
  ({ theme }) => ({
    backgroundColor: theme.palette.novotalk.actionButtonColor,
  })
);
