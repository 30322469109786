const SIZE_DESKTOP = { min: 1281 };
const SIZE_TABLET = { min: 768, max: 1281 };
const SIZE_MOBILE = { min: 0, max: 767 };

export const isMobile = () =>
  window.matchMedia(`(${SIZE_MOBILE.min}px <= width <= ${SIZE_MOBILE.max}px)`).matches;

export const isTablet = () =>
  window.matchMedia(`(${SIZE_TABLET.min}px <= width <= ${SIZE_TABLET.max}px)`).matches;

export const isDesktop = () =>
  window.matchMedia(`(width >= ${SIZE_DESKTOP.min}px)`).matches;
