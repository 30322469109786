import {styled} from "@mui/material";
import { getIconByResourceType } from '@services/icons';

export const WidgetsContainer = styled('div')(
  {
    display: 'flex',
    padding: '0 14px',
    height: 48,
    alignItems: 'center',
    cursor: 'pointer',
    '&:last-of-type': {
      borderBottom: 'none',
    },
  },
  ({ theme }) => ({
    borderBottom: `1px solid ${theme.palette.novotalk.$widgetMenuBorderColor}`,
    '&:hover': {
      backgroundColor: theme.palette.novotalk.$dropdownBackgroundColorHover,
    },
    '&:active': {
      backgroundColor: theme.palette.novotalk.$dropdownBackgroundColorSelected,
    },
  })
);

export const getStyledIcon = (type) =>
  styled(getIconByResourceType(type))(
    {
      marginTop: -2,
      fontSize: 23,
    },
    ({ theme }) => ({
      color: theme.palette.novotalk.$textColorDarkBlue,
    })
  );
