import React, { useEffect, useContext } from 'react';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import {
  getChatInfo,
  setChatId,
  connectToChat,
  setVideoChatConnectionStatusToConnected,
  setVideoChatConnectionStatusToDisconnected,
  textMessageReceived,
  changeStreamProperty,
} from '../../actions';
import _ from 'lodash';
import { VideoCallContext } from '../VideoCall';
import {
  VIDEO_CALL_CHAT_CONNECTION_STARTED,
  VIDEO_CALL_CHAT_ENDED,
  VIDEO_CALL_SELF_USER,
} from '../VideoCall/reducer';
import { beforeunloadListener, unloadListener } from './helpers';
import ChatService from '@services/chatService';
import { Wrapper, LoadingWrapper } from '../../styles';
import SectionLoading from '@components/SectionLoading';
import { useVideoCallContext } from '@shared/hooks/useVideoCallContext';

export const VideoCallContainer = ({
  children,
  users,
  loading,
  setIdChatHandler,
  connectToChartHandler,
}) => {
  const { stateContext, dispatchContext } = useVideoCallContext();
  const { chatId } = useParams();

  useEffect(() => {
    setIdChatHandler(chatId);
    return () => {
      if (
        !stateContext.chatEnded &&
        stateContext.selfUser &&
        stateContext.selfUser.initiator
      ) {
        ChatService.emergencyEndChat();
      }
    };
  }, []);

  useEffect(() => {
    if (users.length) {
      const selfUser = _.find(users, { self: true });
      if (selfUser) {
        dispatchContext({ type: VIDEO_CALL_SELF_USER, payload: selfUser });
        beforeunloadListener(stateContext.chatEnded, selfUser.initiator);
        unloadListener(stateContext.chatEnded, selfUser.initiator);
      }
    }
  }, [users.length]);

  useEffect(() => {
    const selfElement = document.getElementById('publisher');
    if (!loading && !stateContext.chatConnectionStarted && selfElement) {
      dispatchContext({
        type: VIDEO_CALL_CHAT_CONNECTION_STARTED,
        payload: true,
      });
      connectToChartHandler(() =>
        dispatchContext({ type: VIDEO_CALL_CHAT_ENDED, payload: true })
      );
    }
  });

  if (
    loading ||
    !users ||
    _.isEmpty(users) ||
    !chatId ||
    !stateContext ||
    !stateContext.selfUser
  ) {
    return (
      <LoadingWrapper>
        <SectionLoading />
      </LoadingWrapper>
    );
  }

  return (
    <Wrapper initiator={stateContext.selfUser.initiator}>{children}</Wrapper>
  );
};
