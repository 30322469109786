import { fromJS } from 'immutable';
import { Types } from './actions';

// The initial state of the Component
export const initialState = fromJS({
  error: null,
  errorFeedback: null,
  type: null,
  activity: null,
  loadingActivity: true,
  postingFeedback: false,
  loadedProductions: {},
  postsLoading: [],
});

function activityPageReducer(state = initialState, action) {
  switch (action.type) {
    // ACTIVITY ACTIONS
    case Types.GET_USER_ACTIVITY:
      return state.set('loadingActivity', true);
    case Types.SET_USER_ACTIVITY: {
      const { activity } = action.payload;
      const { Exercises: exercises, ...rest } = activity;
      const formatActivity = { exercises, ...rest }
      return state
        .set('activity', fromJS(formatActivity))
        .set('type', activity.type)
        .set('loadingActivity', false);
    }
    case Types.GET_USER_ACTIVITY_ERROR: {
      const { error } = action.payload;
      return state.set('error', error).set('loadingActivity', false);
    }
    // FEEDBACK ACTIONS
    case Types.POST_FEEDBACK_ERROR: {
      const { error } = action.payload;
      return state.set('errorFeedback', error).set('postingFeedback', false);
    }
    case Types.POST_FEEDBACK_SUCCESS: {
      const { createdPost } = action.payload;
      const thread = state.getIn(['activity', 'Threads'])?.first()?.toJS() ?? {
        Posts: [],
      };
      return state
        .setIn(
          ['activity', 'Threads'],
          fromJS([
            {
              Posts: [createdPost, ...thread.Posts],
            },
          ]),
        )
        .set('postingFeedback', false)
        .set('errorFeedback', null);
    }
    case Types.POST_FEEDBACK:
      return state.set('postingFeedback', true);
    case Types.EDIT_FEEDBACK:
      return state.set('postsLoading', [
        ...state.get('postsLoading'),
        action.payload.id,
      ]);
    case Types.EDIT_FEEDBACK_SUCCESS: {
      const { editedPost } = action.payload;
      return state
        .setIn(
          ['activity', 'Threads'],
          fromJS([
            {
              Posts: state
                .getIn(['activity', 'Threads'])
                .first()
                .get('Posts')
                .toJS()
                .map((post) => {
                  if (post.id === editedPost.id) {
                    return editedPost;
                  }
                  return post;
                }),
            },
          ]),
        )
        .set(
          'postsLoading',
          state.get('postsLoading').filter((id) => id !== editedPost.id),
        );
    }
    // PRODUCTIONS ACTIONS
    case Types.GET_EXERCISE_PRODUCTIONS: {
      const { activityId } = action.payload;
      return state.setIn(['loadedProductions', activityId], { loading: true });
    }
    case Types.GET_EXERCISE_PRODUCTIONS_ERROR: {
      const { error } = action.payload;
      return state.set('error', error);
    }
    case Types.SET_EXERCISE_PRODUCTIONS: {
      const { activityId, productions } = action.payload;
      return state.setIn(['loadedProductions', activityId], {
        loading: false,
        items: productions,
      });
    }

    default:
      return state;
  }
}

export default activityPageReducer;
