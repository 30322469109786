import {styled} from "@mui/material";
import { Link } from 'react-router-dom';
import { getResponsiveCSS } from '@utils/cssHelpers';

export const ScreenContainer = styled('div')(
  {
    minHeight: '100vh',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  ({ theme }) => ({
    direction: theme.RTL ? 'rtl' : 'inherit',
  })
);

export const WithHeaderComponentContainer = styled('div')(
  {
    textAlign: 'center',
  },
  ({ theme }) => ({
    width: theme.palette.novotalk.authFormsWidth,
  })
);

export const HeaderContainer = styled('div')({
  marginBottom: 18,
});

export const MainContainer = styled('div')(
  {
    paddingLeft: 56,
    paddingRight: 56,
    borderRadius: 4,
  },
  getResponsiveCSS('paddingTop', 28, 34),
  getResponsiveCSS('paddingBottom', 32, 38),
  getResponsiveCSS('marginBottom', 24, 18),
  ({ theme }) => ({
    border: `1px solid ${theme.palette.novotalk.$borderColorLight}`,
    backgroundColor: theme.palette.novotalk.$backgroundColorAuthScreens,
  })
);

export const TextContainer = styled('div')(
  getResponsiveCSS('marginBottom', 22, 18)
);

export const LinkContainer = styled('div')({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'center',
  alignItems: 'baseline',
});

export const StyledSignUpLink = styled(Link)({
  textDecoration: 'none',
  padding: '0 5px',
});
