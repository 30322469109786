import {styled} from "@mui/material";
export const timeRed = '#D0021B';
export const timeOrange = '#C37F0E';

export const Wrapper = styled('div')(
  {
    backgroundColor: '#EEFBFF',
    alignSelf: 'stretch',
    display: 'flex',
    padding: '10px',
    margin: '10px',
    alignItems: 'center',
    justifyContent: 'space-between',
    height: '120px',
    width: '70%',
    placeSelf: 'center',
  },
  (props) => ({
    gridArea: props.gridArea,
  })
);

export const Cell = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
  alignItems: 'center',
  height: '100px',
});

export const Metric = styled('div')(
  {
    fontSize: '30px',
    margin: '5px',
  },
  (props) => ({
    color: props.red ? '#D0021B' : '#000',
  })
);

export const MetricExplanation = styled('div')({
  fontSize: '16px',
  maxWidth: '250px',
  textAlign: 'center',
  height: '40px',
  display: 'flex',
  alignItems: 'center',
});

export const Hours = styled('span')({
  color: '#000',
  fontSize: '20px',
  margin: '0 5px',
});
