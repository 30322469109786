import gql from 'graphql-tag';
import { practiceFull } from '../../__fixtures__/practiceFull';
import { graphMutation, graphQuery } from '@services/commService';

export const createEntity = async (info, components = [], skillID) => {
  const { name, free, id: entityId, texts: { description } } = info;
  const children = components
    .filter((child) => !child.payload.mapToField)
    .map(({ payload, type }) => ({ type, id: payload.id }));
  const mutation = gql`
  mutation($children: [entityRelationInputType]!) {
    createEntitySkill(
      name:"${name}",
      texts:{description:"""${description}"""},
      free:${free || false},
      children:$children
       ){
        id 
      }
    }
  `;
  return await graphMutation(mutation, { children });
};

export const editEntity = async (info, components = [], skillID) => {
  const { name, free, id: entityId, texts: { description } } = info;
  const children = components
    .filter((child) => !child.payload.mapToField)
    .map(({ payload, type }) => ({ type, id: payload.id }));
  const mutation = gql`
  mutation($children: [entityRelationInputType]!) {
    modifySkill(
      id:"${info.id}",
      name:"${name}",
      texts:{description:"""${description}"""},
      free:${free || false},
      children:$children
       ){
        id 
      }
    }
  `;
  return await graphMutation(mutation, { children });
};

export const deleteEntity = async(id)=>{
  
  const mutation = `mutation{
    deleteSkill(
      id:"${id}"
    ){
      name
    }

  }`
  
  return await graphMutation(mutation,{}, true);
}

export const loadEntity = async (id) => {
  if (process.env.USE_FIXTURES) {
    return { data: { entity: [practiceFull] } };
  }
  const query = `
  {
    entity:entitySkills(id:"${id}",range:[0,1],fullData:true){
    id
    name
    createdAt
    texts{
      description
    }
    free
    children{
      type
      payload
    }
  }}
  `;
  return await graphQuery(query, true);
};
