import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Select from 'react-select';
import { useDispatch, useSelector } from 'react-redux';
import Text, { TEXT_COLOR, TEXT_SIZE } from '@components/Text';
import { fetchUsers } from '@containers/Admin/containers/UsersList/actions';
import SectionLoading from '@components/SectionLoading';
import {
  Wrapper,
  Title,
  Body,
  Buttons,
  ConfirmButton,
  CancelButton,
  SelectUserWrapper,
  LoaderWrapper,
} from './styles';

const onButtonClicked = (onClose, onConfirm, result, userId) => () => {
  onConfirm(result, userId);
  onClose();
};

const Duplicate = ({
  titleText,
  bodyText,
  confirmText,
  cancelText,
  onClose,
  onConfirm,
}) => {
  const { list, loading } = useSelector((state) => state.getIn(['usersList']).toJS());
  const [optionsList, setOptionsList] = useState([]);
  const [chosenUser, setChosenUser] = useState();
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(fetchUsers(['USER'], false));
  }, []);
  useEffect(() => {
    if (list?.length) {
      setOptionsList(list.map((user, index) => ({ value: user.id, label: `${index} ${user.fullName}` })));
    }
  }, [list]);
  return (
    <Wrapper>
      <Title>
        <Text size={TEXT_SIZE.T0} textColor={TEXT_COLOR.DARK_BLUE} weight="900">
          {titleText}
        </Text>
      </Title>
      <SelectUserWrapper>
        {!loading ? (
          <Select
            options={optionsList}
            onChange={(option) => {
              if (option) {
                setChosenUser(option.value);
              }
            }}
          />
        ) : (
          <LoaderWrapper>
            <SectionLoading size={50} />
          </LoaderWrapper>
        )}
      </SelectUserWrapper>
      {bodyText && (
      <Body>
        <Text size={TEXT_SIZE.T3} textColor={TEXT_COLOR.DISABLED}>
          {bodyText}
        </Text>
      </Body>
      )}
      <Buttons>
        <ConfirmButton
          className="confirmButton"
          onClick={onButtonClicked(onClose, onConfirm, true, chosenUser)}
          disabled={!chosenUser}
        >
          {confirmText}
        </ConfirmButton>
        <CancelButton
          cancel
          className="cancelButton"
          onClick={onButtonClicked(onClose, onConfirm, false)}
        >
          {cancelText}
        </CancelButton>
      </Buttons>
    </Wrapper>
  );
};

Duplicate.propTypes = {
  onClose: PropTypes.func,

  onConfirm: PropTypes.func,
  titleText: PropTypes.node,
  bodyText: PropTypes.node,
  confirmText: PropTypes.node,
  cancelText: PropTypes.node,
};

export default Duplicate;
