import {styled} from "@mui/material";
import { getIconsByType, iconTypes } from '@services/icons';

const LockIcon = getIconsByType(iconTypes.LOCK);
const ChevronDown = getIconsByType(iconTypes.CHEVRON_DOWN);
const ChevronUp = getIconsByType(iconTypes.CHEVRON_UP);

const chevronStyle = {
  fontSize: 20,
};

export const StyledChevronDown = styled(ChevronDown)(chevronStyle);
export const StyledChevronUp = styled(ChevronUp)(chevronStyle);
export const StyledLockIcon = styled(LockIcon)({
  fontSize: 21,
});

export const Wrapper = styled('div')(
  {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    border: '1px solid',
    borderRadius: '4px',
    marginBottom: 22,
    flexShrink: '0',
    position: 'relative',
  },
  ({ theme }) => ({
    borderColor: theme.palette.novotalk.$borderColorLight,
  })
);

export const CardWrapper = styled('div')({
  minWidth: '700px',
  display: 'grid',
  gridTemplateColumns: '45% 55%',
  gridTemplateRows: 'auto auto',
  gridTemplateAreas: `
  "description description"
  "learn practice"
  `,
});

export const Header = styled('div')(
  {
    minWidth: '668px',
    borderRadius: '4px 4px 0 0',
    padding: '12px 25px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    cursor: 'pointer',
  },
  ({ theme, collapsed, isFree }) =>
    isFree
      ? {
          backgroundColor: theme.palette.novotalk.$trialBackgroundColor,
          border: `1px solid ${theme.palette.novotalk.$trialBorderColor}`,
          '&:hover': {
            backgroundColor: theme.palette.novotalk.$trialBackgroundHoverColor,
          },
          margin: -1,
        }
      : {
          backgroundColor: collapsed
            ? theme.palette.novotalk.$backgroundColorMediumHover
            : theme.palette.novotalk.$backgroundColorMedium,
          '&:hover': {
            backgroundColor: theme.palette.novotalk.$backgroundColorMediumHover,
          },
        }
);

export const ToggleWrapper = styled('div')(
  {
    marginTop: -5,
  },
  ({ theme }) => ({
    color: theme.palette.novotalk.$textColorDisabledLight,
    [theme.RTL ? 'marginRight' : 'marginLeft']: 20,
  })
);

export const Description = styled('div')(
  {
    gridArea: 'description',
    width: '80%',
    margin: '20px 25px 5px 25px',
  },
  ({ theme }) => ({
    color: theme.palette.novotalk.$textColorDarkBlue,
  })
);

export const LearnWrapper = styled('div')(
  {
    gridArea: 'learn',
    margin: '20px 25px',
    overflowY: 'hidden',
  },
  ({ theme }) => ({
    [theme.RTL
      ? 'borderLeft'
      : 'borderRight']: `1px solid ${theme.palette.novotalk.$borderColorLight}`,
  })
);

export const PracticeWrapper = styled('div')(
  {
    gridArea: 'practice',
    overflowY: 'hidden',
  },
  ({ theme }) => ({
    margin: theme.RTL ? '20px 0 20px 25px' : '20px 25px 20px 0',
  })
);

export const LockWrapper = styled('span')(
  {
    marginTop: -5,
  },
  ({ theme, show }) => ({
    color: theme.palette.novotalk.$textColorDisabledLight,
    display: show ? '' : 'none',
    [theme.RTL ? 'marginLeft' : 'marginRight']: 10,
  })
);

export const UpgradeMessage = styled('div')({
  lineHeight: '13px',
  alignSelf: 'baseline',
});
