import { Component } from 'react';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import { compose } from 'redux';
import { setSampleStart, setSampleEnd } from '@shared/DSPHandler/services/NTDSPService';
import {
  PATTERN_TYPES, MONITOR_TYPES, MONITOR_HANDLER_STATUS, LOCATION_TYPES, ERROR_TYPES,
} from '@shared/Resources/Monitor/types';
import CTAButton from '@components/CTAButton';
import { MonitorContainer, MonitorOverlay } from './styles';
import {
  GRAPH_SIZE,
  SAMPLE,
  START_SAMPLE_THRESHOLD_VALUE,
  END_SAMPLE_THRESHOLD_VALUE,
  EXPECTED_ERRORS,
  SAMPLES_IN_SEC,
} from './constants';
import WavesMonitor from './containers/WavesMonitor';
import FillMonitor from './components/FillMonitor';

const ONE_SECOND = 1000;
const PAUSE_IN_MILLISECOND = 500;
class Monitor extends Component {
  constructor(props) {
    super(props);
    const {
      patternType, monitorNumber, inhalationTime, duration, selfManagePace,
    } = props;
    const widthMonitor = Math.round(duration * GRAPH_SIZE[patternType].sampleWidth * SAMPLES_IN_SEC);
    const sampleStartPosition = Math.round(
      (widthMonitor) * SAMPLE[patternType].imagePosition,
    );
    const pauseCorrection = (widthMonitor / this.props.duration) * (750 / 1000);
    const sampleEndPosition = sampleStartPosition + Math.round(
      (widthMonitor + pauseCorrection) / GRAPH_SIZE[patternType].sampleWidth,
    );
    const tooShortPosition = (sampleStartPosition
      + (sampleEndPosition - sampleStartPosition) * SAMPLE[patternType].strictness.tooShort);
    const tooLongPosition = sampleStartPosition
      + (sampleEndPosition - sampleStartPosition) * SAMPLE[patternType].strictness.tooLong;
    const { gracePeriod, silenceSamplesToAdd } = SAMPLE[patternType];
    this.finishProductionInterval = null;
    this.count = 0;
    this.state = {
      patternType,
      displayBuffer: [],
      waitingForStart: false,
      waitingForEnd: false,
      ended: false,
      sent: false,
      sampleStartPosition,
      sampleEndPosition,
      tooShortPosition,
      tooLongPosition,
      monitorNumber,
      gracePeriod,
      monitorReset: false,
      silenceSamplesToAdd,
      inhalationTime,
      duration,
      widthMonitor,
      selfManagePace,
      //      refreshSamples: GRAPH_SIZE[patternType].refreshSamples,
    };
    this.getLengthErrors = this.getLengthErrors.bind(this);
  }

  componentWillReceiveProps(nextProps) {
    // FIXME: refactor to use https://reactjs.org/docs/react-component.html#static-getderivedstatefromprops
    const {
      patternType,
      monitorNumber,
      waitingForStart,
      displayBuffer,
      sampleStartPosition,
      waitingForEnd,
      tooShortPosition,
      tooLongPosition,
      ended,
      sent,
      startTS,
      endTS,
      gracePeriod,
      monitorReset,
      silenceSamplesToAdd,
      selfManagePace,
    } = this.state;
    const {
      dspSignal,
      handleMonitorCommunication,
      monitorErrors,
      currentMonitor,
    } = nextProps;
    if (sent || currentMonitor !== monitorNumber) return;
    if (this.props.duration !== nextProps.duration) {
      const widthMonitor = Math.round(nextProps.duration * GRAPH_SIZE[nextProps.patternType].sampleWidth * SAMPLES_IN_SEC);
      const sampleStartPosition = Math.round(
        (widthMonitor) * SAMPLE[nextProps.patternType].imagePosition,
      );
      const sampleEndPosition = sampleStartPosition + Math.round(
        widthMonitor / GRAPH_SIZE[nextProps.patternType].sampleWidth,
      );
      const tooShortPosition = sampleStartPosition
        + (sampleEndPosition - sampleStartPosition) * SAMPLE[nextProps.patternType].strictness.tooShort;
      const tooLongPosition = sampleStartPosition
        + (sampleEndPosition - sampleStartPosition) * SAMPLE[nextProps.patternType].strictness.tooLong;
      const { gracePeriod, silenceSamplesToAdd } = SAMPLE[nextProps.patternType];

      this.setState({
        patternType: nextProps.patternType,
        displayBuffer: [],
        waitingForStart: false,
        waitingForEnd: false,
        ended: false,
        sent: false,
        sampleStartPosition,
        sampleEndPosition,
        tooShortPosition,
        tooLongPosition,
        monitorNumber: nextProps.monitorNumber,
        gracePeriod,
        silenceSamplesToAdd,
        inhalationTime: nextProps.inhalationTime,
        duration: nextProps.duration,
        widthMonitor,
        monitorReset: false,
      });
      this.getLengthErrors = this.getLengthErrors.bind(this);
      return;
    }
    if (ended && !sent) {
      // check that all errors expected have been handled
      const monitorErrorTypes = (monitorErrors || []).map((monitor) => monitor.type);
      const errorsMarked = _.intersection(monitorErrorTypes, EXPECTED_ERRORS[patternType]);
      const expectedErrors = EXPECTED_ERRORS[patternType] ?? [];

      if (_.isEqual(errorsMarked.sort(), expectedErrors.sort())) {
        // add to short and too long which are calculated here and are in the state
        const errorsFromDSP = monitorErrors.filter((monitorError) => _.indexOf(errorsMarked, monitorError.type) > -1);
        const errors = _.union(errorsFromDSP, this.getLengthErrors());

        handleMonitorCommunication(MONITOR_HANDLER_STATUS.SINGLE_MONITOR_PRODUCTION_END, {
          patternType,
          monitorNumber,
          dspSignal: displayBuffer,
          startTS,
          endTS,
          errors,
        });
      }
      this.setState({ sent: true });
      return;
    }
    //
    const lastResult = dspSignal[dspSignal.length - 1];
    const passedThreshold = patternType === PATTERN_TYPES.INHALE
      ? lastResult < END_SAMPLE_THRESHOLD_VALUE
      : lastResult > START_SAMPLE_THRESHOLD_VALUE;
    if (!waitingForStart && displayBuffer.length === sampleStartPosition) {
      if (!monitorReset) handleMonitorCommunication(MONITOR_HANDLER_STATUS.PRODUCTION_INIT, true);
      this.setState({
        waitingForStart: true,
      });
    } else if (waitingForStart && passedThreshold) {
      if (!monitorReset) handleMonitorCommunication(MONITOR_HANDLER_STATUS.PRODUCTION_START, true);
      // add from pre threshold to allow gentle onsets
      const numberOfSamplesToActuallyAdd = Math.min(
        silenceSamplesToAdd,
        dspSignal.length - 1,
      );
      for (
        let sampleToAdd = 0;
        sampleToAdd < numberOfSamplesToActuallyAdd;
        sampleToAdd += 1
      ) {
        const positionToAdd = dspSignal.length - (numberOfSamplesToActuallyAdd - sampleToAdd);
        displayBuffer.push(dspSignal[positionToAdd]);
      }
      if (patternType !== PATTERN_TYPES.INHALE) {
        setSampleStart(numberOfSamplesToActuallyAdd, selfManagePace);
      }
      this.setState({
        waitingForEnd: true,
        waitingForStart: false,
        startTS: new Date().getTime(),
      });
      displayBuffer.push(lastResult);
      this.setState({ displayBuffer });
    } else if (!waitingForStart) {
      if (waitingForEnd && !passedThreshold) {
        if (displayBuffer.length > gracePeriod) {
          if (patternType !== PATTERN_TYPES.INHALE) {
            this.pauseAndEndProductionHelper(lastResult);
          } else {
            this.setState({
              ended: true,
              waitingForEnd: false,
              endTS: new Date().getTime(),
              tooShortStatus: displayBuffer.length < tooShortPosition,
              tooLongStatus: displayBuffer.length > tooLongPosition,
            });
          }
        } else if (patternType !== PATTERN_TYPES.INHALE) {
          // reset the monitor to initial state
          this.setState({
            displayBuffer: [],
            waitingForStart: false,
            waitingForEnd: false,
            ended: false,
            sent: false,
            monitorReset: true,
          });
        } else if (patternType === PATTERN_TYPES.INHALE) {
          displayBuffer.push(lastResult);
          this.setState({ displayBuffer });
        }
      } else {
        if (this.finishProductionInterval) {
          this.count = 0;
        }
        displayBuffer.push(lastResult);
        this.setState({ displayBuffer });
      }
    }
  }

  getLengthErrors() {
    const { tooShortStatus, tooLongStatus, patternType } = this.state;
    return [
      {
        type: patternType === PATTERN_TYPES.INHALE ? ERROR_TYPES.tooShortInhalation : ERROR_TYPES.tooShortProduction,
        location: LOCATION_TYPES.RIGHT_SIDE_MIDDLE,
        exists: tooShortStatus,
      },
      {
        type: ERROR_TYPES.tooLongProduction,
        location: LOCATION_TYPES.RIGHT_SIDE_MIDDLE,
        exists: tooLongStatus,
      },
    ];
  }

  renderMonitor = () => {
    const {
      patternType,
      displayBuffer,
      monitorNumber,
      waitingForEnd,
      ended,
      inhalationTime,
      duration,
      widthMonitor,
    } = this.state;
    const { oneStepDuration } = this.props;

    switch (patternType) {
      case PATTERN_TYPES.VOICE_TRANSITION:
      case PATTERN_TYPES.LOUD_VOICE:
      case PATTERN_TYPES.TWO_SECONDS:
      case PATTERN_TYPES.SYMMETRICAL_TRANSITION:
      case PATTERN_TYPES.ONE_SECOND:
      case PATTERN_TYPES.SELF_MANAGED:
      case PATTERN_TYPES.PACE_CHANGES:
      case PATTERN_TYPES.INTEGRATED_SPEECH:
      case PATTERN_TYPES.GENTLE_VOICE:
      case PATTERN_TYPES.WAVES: {
        const props = {
          height: `${GRAPH_SIZE[patternType].height}px`,
          LTR: GRAPH_SIZE[patternType].LTR,
          buffer: ended ? _.concat(displayBuffer, [0]) : displayBuffer, // pad array with zeros to fade graph to zero
          patternType,
          monitorNumber,
          ended,
          duration,
          widthMonitor,
          stepSize: widthMonitor / (duration * ONE_SECOND / oneStepDuration),
        };
        return <WavesMonitor {...props} />;
      }
      case MONITOR_TYPES.BREATHING: {
        const height = `${GRAPH_SIZE[patternType].height}px`;
        const finalizeDuration = duration === 1 ? 1 : duration - 1;
        return (
          <FillMonitor
            duration={`${finalizeDuration}s`}
            animating={waitingForEnd}
            widthMonitor={widthMonitor}
            height={height}
          />
        );
      }
      case PATTERN_TYPES.INHALE: {
        const width = '100%';
        const { height } = GRAPH_SIZE[patternType];
        return (
          <FillMonitor
            isInhalation
            duration={`${inhalationTime}s`}
            animating={waitingForEnd}
            width={width}
            height={height}
          />
        );
      }
      default:
        return null;
    }
  };

  resumeMonitor = () => {
    const {
      dispatchSetMonitorActiveFlag,
      dispatchSetMicrophoneActiveFlag,
      dispatchSetExercisePausedFlag,
    } = this.props;

    dispatchSetMonitorActiveFlag(true);
    dispatchSetMicrophoneActiveFlag(true);
    dispatchSetExercisePausedFlag(false);
  };

  localSetSampleEnd() {
    clearInterval(this.finishProductionInterval);
    this.count = 0;
    setSampleEnd();
  }

  pauseAndEndProductionHelper(lastResult) {
    const { displayBuffer, tooShortPosition, tooLongPosition } = this.state;
    // const { exercisePaused } = this.props;
    if (!this.finishProductionInterval) {
      this.finishProductionInterval = setInterval(() => {
        if (!this.props.exercisePaused) {
          if (this.count) {
            this.setState({
              ended: true,
              waitingForEnd: false,
              endTS: new Date().getTime(),
              tooShortStatus: displayBuffer.length < tooShortPosition,
              tooLongStatus: displayBuffer.length > tooLongPosition,
            });
            this.localSetSampleEnd();
          }
          this.count += 1;
        }
      }, PAUSE_IN_MILLISECOND);
    }
    displayBuffer.push(lastResult);
    this.setState({ displayBuffer });
  }

  renderMonitorsOverlay = () => {
    const {
      monitorActive,
      exercisePaused,
    } = this.props;

    return (
      <MonitorOverlay monitorActive={monitorActive}>
        <CTAButton
          onClick={this.resumeMonitor}
        >
          {this.props.t(`Actions.Instance.${exercisePaused ? 'continue' : 'start'}`)}
        </CTAButton>
      </MonitorOverlay>
    );
  };

  render() {
    const {
      videoChatActive,
    } = this.props;

    return (
      <MonitorContainer>
        {videoChatActive && this.renderMonitorsOverlay()}
        {this.renderMonitor()}
      </MonitorContainer>
    );
  }
}

Monitor.propTypes = {
  patternType: PropTypes.string,
  dspSignal: PropTypes.array,
  handleMonitorCommunication: PropTypes.func,
  monitorNumber: PropTypes.number,
  monitorErrors: PropTypes.array,
  currentMonitor: PropTypes.number,
};

export default compose(
  withTranslation(),
)(Monitor);
