import {styled} from "@mui/material";
import { getIconsByType, iconTypes } from '@services/icons';
import CTAButton from '@components/CTAButton';
import { getResponsiveCSS } from '@utils/cssHelpers';
import { CHARGE_FREQUENCIES } from './constants';

export const ScreenWrapper = styled('div')(
  {
    maxWidth: 1460,
    margin: '0 auto',
    padding: '40px 50px',
  },
  getResponsiveCSS('paddingTop', 40, 50)
);

export const StyledHeader = styled('div')(
  getResponsiveCSS('marginBottom', 30, 40)
);

export const ContentWrapper = styled('div')(
  {
    display: 'grid',
    gridTemplateColumns: '680px 1fr',
  },
  getResponsiveCSS('gridGap', 120, 230)
);

export const OptionsWrapper = styled('div')(
  {
    paddingTop: 30,
    paddingBottom: 30,
    borderRadius: 8,
  },
  getResponsiveCSS('width', 380, 460),
  getResponsiveCSS('paddingLeft', 38, 77),
  getResponsiveCSS('paddingRight', 38, 77),
  getResponsiveCSS('marginBottom', 30, 40),

  ({ theme }) => ({
    boxShadow: `0 4px 28px ${theme.palette.novotalk.$shadowTooltipColor}`,
  })
);

export const TriggerWrapper = styled('div')({
  display: 'flex',
  alignItems: 'center',
  marginBottom: 32,
});

export const TriggerText = styled('span')(
  {
    margin: 14,
    cursor: 'pointer',
  },
  ({ theme, isActive }) => ({
    color: isActive ? theme.palette.novotalk.$textColorDarkBlue : theme.palette.novotalk.$textColorDisabled,
    pointerEvents: isActive ? 'none' : 'all',
  })
);

export const StyledTrigger = styled('div')(
  {
    width: 44,
    height: 24,
    position: 'relative',
    borderRadius: 12,
    cursor: 'pointer',
    '&:after': {
      display: 'block',
      content: '""',
      width: 20,
      height: 20,
      position: 'absolute',
      borderRadius: '50%',
      top: 2,
    },
  },
  ({ theme, chargeFrequency }) => ({
    backgroundColor: theme.palette.novotalk.$textColorDisabled,
    '&:after': {
      [chargeFrequency === CHARGE_FREQUENCIES.ANNUALLY ? 'right' : 'left']: 3,
      backgroundColor: theme.palette.novotalk.$backgroundColor,
    },
  })
);

export const PriceWrapper = styled('div')({
  marginBottom: 32,
  marginLeft: 105,
  '& #payment-price-value': {
    fontSize: 34,
    marginRight: 5,
  },
});

export const CouponInputWrapper = styled('div')({
  display: 'flex',
  alignItems: 'flex-end',
  position: 'relative',
  '& > span > div:first-of-type': {
    width: 210,
    height: 38,
    marginRight: 8,
    marginBottom: 0,
  },
});

export const CouponButton = styled(CTAButton)(
  {
    width: 88,
    height: 38,
    borderRadius: 4,
    fontSize: 13,
    position: 'absolute',
    right: 0,
  },
  ({ hasError }) => ({
    bottom: hasError ? 20 : 0,
  })
);

export const FormHeader = styled('div')({
  marginBottom: 20,
});

export const AdvantagesList = styled('div')(
  getResponsiveCSS('marginBottom', 30, 48)
);

export const AdvantagesTitle = styled('div')(
  {
    marginBottom: 32,
  },
  getResponsiveCSS('width', 310, 430)
);

export const AdvantagesItem = styled('div')({
  display: 'flex',
  marginBottom: 20,
});

const CheckmarkIcon = getIconsByType(iconTypes.BOLD_CHECKMARK);

export const StyledCheckmarkIcon = styled(CheckmarkIcon)(
  {
    marginTop: 2,
    marginRight: 10,
    fontSize: 16,
  },
  ({ theme }) => ({
    color: theme.palette.novotalk.$activeColor,
  })
);

export const BadgesList = styled('div')({
  display: 'flex',
  flexWrap: 'wrap',
  justifyContent: 'flex-start',
});

export const BadgesText = styled('div')({
  width: '100%',
  textAlign: 'center',
});

export const Badge = styled('img')({
  width: 119,
  height: 26,
  cursor: 'pointer',
});
