import {styled} from "@mui/material";

export const Container = styled('span')(
  {
    height: 32,
    fontSize: 14,
    display: 'grid',
    gridTemplateColumns: '24px 30px 24px',
    justifyItems: 'stretch',
    alignItems: 'stretch',
  },
  ({ disabled, margin }) => ({
    margin: margin || 0,
    pointerEvents: disabled ? 'none' : null,
    opacity: disabled ? 0.4 : 1,
  })
);

export const ButtonWrapper = styled('div')(
  {
    overflow: 'hidden',
    '&:first-of-type': {
      borderRadius: '2px 0 0 2px',
      borderRight: 'none',
    },
    '&:last-of-type': {
      borderLeft: 'none',
      borderRadius: '0 2px 2px 0',
    },
  },
  ({ theme, disabled }) => ({
    border: `solid ${theme.palette.novotalk.$monitorTypesBorderColor} 1px`,
    backgroundColor: disabled
      ? theme.palette.novotalk.$backgroundColor
      : theme.palette.novotalk.$numericSelectorColor,
  })
);

export const Button = styled('button')(
  {
    width: '100%',
    height: 32,
    outline: 'none',
    cursor: 'pointer',
    lineHeight: '30px',
    textAlign: 'center',
  },
  ({ theme, disabled }) => ({
    opacity: disabled ? 0.25 : 1,
    color: theme.palette.novotalk.$activeColor,
    backgroundColor: theme.palette.novotalk.$numericSelectorColor,
    '&:hover': {
      backgroundColor: theme.palette.novotalk.$numericSelectorColorHover,
    },
    '&:active': {
      backgroundColor: theme.palette.novotalk.$numericSelectorColorActive,
    },
  })
);

export const DirectInput = styled('input')(
  {
    textAlign: 'center',
    lineHeight: '30px',
    '&::-webkit-inner-spin-button, &::-webkit-outer-spin-button': {
      WebkitAppearance: 'none',
      margin: 0,
    },
  },
  ({ theme }) => ({
    color: theme.palette.novotalk.$textColorDarkBlue,
    border: `solid ${theme.palette.novotalk.$monitorTypesBorderColor} 1px`,
  })
);
