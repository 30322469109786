import { call, put, takeLatest, all, fork, join } from 'redux-saga/effects';
import {
  getSuperviserOverviewActivityWorkouts,
  getSuperviserAllActivites,
} from '@services/activitiesService';
import { getTimeRange } from '@utils/timeHelpers';
import { authenticationWrapper } from '@services/errorHandlingService';
import {
  setSuperviserOverviewActivityWorkouts,
  setSuperviserAllActivityWorkouts,
  setSuperviserAllActivityWorkoutsFilterDefaults,
} from './actions';
import {
  mockOverviewActivities,
  mockAllActivities,
} from './__fixtures__/supervisorMock';

const useFixtures = process.env.USE_FIXTURES;

export function* callGetSuperviserOverviewActivityWorkouts() {
  yield authenticationWrapper(function* codeBlock() {
    const res = yield fork(getSuperviserOverviewActivityWorkouts);
    const activities = useFixtures ? mockOverviewActivities : yield join(res);
    yield put(setSuperviserOverviewActivityWorkouts(activities));
  });
}

export function* callGetSuperviserAllActivityWorkouts(action) {
  yield authenticationWrapper(function* codeBlock() {
    const res = yield fork(
      getSuperviserAllActivites,
      getTimeRange(action.timeRange)
    );
    const activities = useFixtures ? mockAllActivities : yield join(res);
    yield put(setSuperviserAllActivityWorkouts(activities));
    yield put(setSuperviserAllActivityWorkoutsFilterDefaults(activities));
  });
}

function* watchCallGetSuperviserOverviewActivityWorkouts() {
  yield takeLatest(
    'GET_SUPERVISOR_OVERVIEW_ACTIVITY_WORKOUTS',
    callGetSuperviserOverviewActivityWorkouts
  );
}
function* watchCallGetSuperviserAllActivityWorkouts() {
  yield takeLatest(
    'GET_SUPERVISOR_ALL_ACTIVITY_WORKOUTS',
    callGetSuperviserAllActivityWorkouts
  );
}
function* watchCallGetSuperviserAllActivityWorkoutsFilter() {
  yield takeLatest(
    'SET_SUPERVISOR_ACTIVITIES_TIME_RANGE_FILTER',
    callGetSuperviserAllActivityWorkouts
  );
}

export default function* getSupervisorDashboardDataSaga() {
  yield all([
    fork(watchCallGetSuperviserOverviewActivityWorkouts),
    fork(watchCallGetSuperviserAllActivityWorkouts),
    fork(watchCallGetSuperviserAllActivityWorkoutsFilter),
  ]);
}
