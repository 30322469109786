import {
  call, put, takeLatest, all, fork, join,
} from 'redux-saga/effects';
import _ from 'lodash';
import { authenticationWrapper } from '@services/errorHandlingService';
import {
  startVideoRecording,
  disconnectFromMirrorSession,
  stopVideoRecording,
} from '@shared/VideoMonitor/actions';
import { types, clearToken } from '@containers/App/actions';
import {
  TYPES, setCurrentLesson, setActivityLesson, setLessonError,
} from './actions';
import { lessons } from './__fixtures__/lessons';
import { setActivityContext } from '../../actions';
import {
  getEntityLesson,
  createActivityLesson, getActivityLesson,
} from '../../services';

const useFixtures = process.env.USE_FIXTURES;

const getLessonById = (id) => {
  const lesson = _.find(lessons, { id });
  return lesson;
};

export function* callGetCurrentLesson(action) {
  yield authenticationWrapper(function* codeBlock() {
    const res = yield fork(getEntityLesson, action.id);
    const lesson = useFixtures ? getLessonById(action.id) : yield join(res);
    yield put(setCurrentLesson(lesson));
  });
}

export function* callStartActivityLesson({
  entityLessonInfo,
  activityContext,
}) {
  try {
    const context = _.clone(activityContext);
    context.LESSON = entityLessonInfo;
    const res = yield fork(createActivityLesson, context);
    const activityLesson = useFixtures
      ? { id: 'activityLessonId' }
      : yield join(res);
    yield put(setActivityLesson(activityLesson));
    context.ACTIVITY_LESSON = activityLesson.id;
    yield put(setActivityContext(context));
    yield put(
      startVideoRecording({ ...context, deepestChildType: 'ACTIVITY_LESSON' }),
    );
  } catch (e) {
    yield put(setLessonError(e.message));
  }
}

export function* callEndActivityLesson({ activityLessonId, isLogout }) {
  try {
    if (!activityLessonId) return;
    const res = yield fork(getActivityLesson, activityLessonId);
    const activityLesson = useFixtures
      ? { id: 'activityLessonId' }
      : yield join(res);
    yield put(setActivityLesson(activityLesson));
    yield put(disconnectFromMirrorSession());
    yield put(stopVideoRecording());
    if (isLogout) {
      yield put(clearToken());
    }
  } catch (e) {
    yield put(setLessonError(e.message));
  }
}

function* watchCallGetCurrentLesson() {
  yield takeLatest(TYPES.GET_CURRENT_LESSON, callGetCurrentLesson);
}
function* watchCallStartActivityLesson() {
  yield takeLatest(TYPES.START_ACTIVITY_LESSON, callStartActivityLesson);
}
function* watchCallEndActivityLesson() {
  yield takeLatest(TYPES.END_ACTIVITY_LESSON, callEndActivityLesson);
}
function* watchCallEndActivityLessonAfterLogout() {
  yield takeLatest(types.LOG_OUT, callEndActivityLesson);
}
export default function* lessonSaga() {
  yield all([
    fork(watchCallGetCurrentLesson),
    fork(watchCallStartActivityLesson),
    fork(watchCallEndActivityLesson),
    fork(watchCallEndActivityLessonAfterLogout),
  ]);
}
