export const Types = {
  GET_USER_ACTIVITY: 'GET_USER_ACTIVITY',
  SET_USER_ACTIVITY: 'SET_USER_ACTIVITY',
  GET_USER_ACTIVITY_ERROR: 'GET_USER_ACTIVITY_ERROR',

  GET_EXERCISE_PRODUCTIONS: 'GET_EXERCISE_PRODUCTIONS',
  SET_EXERCISE_PRODUCTIONS: 'SET_EXERCISE_PRODUCTIONS',
  GET_EXERCISE_PRODUCTIONS_ERROR: 'GET_EXERCISE_PRODUCTIONS_ERROR',

  POST_FEEDBACK_ERROR: 'POST_FEEDBACK_ERROR',
  POST_FEEDBACK_SUCCESS: 'POST_FEEDBACK_SUCCESS',
  POST_FEEDBACK: 'POST_FEEDBACK',

  EDIT_FEEDBACK: 'EDIT_FEEDBACK',
  EDIT_FEEDBACK_SUCCESS: 'EDIT_FEEDBACK_SUCCESS',
};

export const getExerciseProductions = (activityId) => ({
  type: Types.GET_EXERCISE_PRODUCTIONS,
  payload: { activityId },
});

export const getExerciseProductionsError = (error) => ({
  type: Types.GET_EXERCISE_PRODUCTIONS_ERROR,
  payload: { error },
});

export const setExerciseProductions = (activityId, productions) => ({
  type: Types.SET_EXERCISE_PRODUCTIONS,
  payload: { activityId, productions },
});

export function getUserActivity(type, id) {
  return {
    type: Types.GET_USER_ACTIVITY,
    payload: { type, id },
  };
}

export function getUserActivityError(error) {
  return {
    type: Types.GET_USER_ACTIVITY_ERROR,
    payload: { error },
  };
}

export function setUserActivity(activity) {
  return {
    type: Types.SET_USER_ACTIVITY,
    payload: { activity },
  };
}

export const postFeedback = (activity, text, dismiss) => ({
  type: Types.POST_FEEDBACK,
  payload: { activity, text, dismiss },
});

export const postFeedbackError = (error) => ({
  type: Types.POST_FEEDBACK_ERROR,
  payload: { error },
});

export const postFeedbackSuccess = (createdPost) => ({
  type: Types.POST_FEEDBACK_SUCCESS,
  payload: { createdPost },
});

export const editFeedback = (id, text) => ({
  type: Types.EDIT_FEEDBACK,
  payload: { id, text },
});

export const editFeedbackSuccess = (editedPost) => ({
  type: Types.EDIT_FEEDBACK_SUCCESS,
  payload: { editedPost },
});
