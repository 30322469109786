import React from 'react';

const icon = (props) => (<svg width="14px" height="14px" viewBox="0 0 14 14" version="1.1" xmlns="http://www.w3.org/2000/svg" {...props}>
  <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
    <g id="Create" fill="currentColor">
      <g id="Combined-Shape">
        <path d="M4.375,7.61979297 L10.283543,1.71127501 L12.288725,3.71643191 L6.38020703,9.625 L4.375,9.625 L4.375,7.61979297 Z M13.8396273,2.16557973 L12.8503333,3.15482355 L10.8451514,1.14966665 L11.8344203,0.160372698 C12.0482505,-0.0534575661 12.3691589,-0.0534575661 12.5829891,0.160372698 L13.8396273,1.41701088 C14.0534576,1.63084114 14.0534576,1.95174946 13.8396273,2.16557973 Z M12.4444323,12.4444323 L12.4444323,6.5625 L14,6.5625 L14,12.4444323 C14,13.3000729 13.3000729,14 12.4444323,14 L1.55556771,14 C0.699927083,14 0,13.3000729 0,12.4444323 L0,1.55556771 C0,0.699927083 0.699927083,8.46545056e-15 1.55556771,8.46545056e-15 L7.4375,8.46545056e-15 L7.4375,1.55556771 L1.55556771,1.55556771 L1.55556771,12.4444323 L12.4444323,12.4444323 Z" id="path-1"></path>
      </g>
    </g>
  </g>
</svg>);

export default icon;

