import { fromJS } from 'immutable';
import { TYPES } from './actions';

export const initialState = fromJS({
  billingState: '',
  plans: [],
  discountedPlans: [],
  couponError: null,
  loading: false,
});

const billingReducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case TYPES.BILLING_SUCCESS:
      return state.set('billingState', TYPES.BILLING_SUCCESS);
    case TYPES.BILLING_FAILURE:
      return state.set('billingState', TYPES.BILLING_FAILURE);
    case TYPES.BILLING_RESET:
      return state.set('billingState', '');
    case TYPES.SET_PLANS: {
      const { plans, discounted } = action;
      if (discounted) {
        return state.set('discountedPlans', plans).set('couponError', null);
      }
      return state.set('plans', plans);
    }
    case TYPES.SET_INVALID_COUPON_ERROR:
      return state.set('couponError', action.error);
    case TYPES.BILLING_LOADING_HANDLE:
      return state.set('loading', action.loading);
    default: {
      return state;
    }
  }
};

export default billingReducer;
