import React from 'react';
import { TbWaveSine } from 'react-icons/tb';

const icon = () => (<TbWaveSine 
style={{borderRadius: '50%',
  border: '1px solid #23B7E5',
  padding: 5,
  width:'100%',
  height:'100%',
  color:'#23B7E5' }}/>);

export default icon;