export const TYPES = {
  // local actions
  ROLE_MANAGEMENT_PAGE_SELECT_USER: 'ROLE_MANAGEMENT_PAGE_SELECT_USER',
  ROLE_MANAGEMENT_PAGE_SELECT_ROLE: 'ROLE_MANAGEMENT_PAGE_SELECT_ROLE',
  // sage actions
  GET_ROLE_MANAGEMENT_PAGE_DATA: 'GET_ROLE_MANAGEMENT_PAGE_DATA',
  GET_ROLE_MANAGEMENT_PAGE_DATA_ERROR: 'GET_ROLE_MANAGEMENT_PAGE_DATA_ERROR',
  SET_ROLE_MANAGEMENT_PAGE_DATA: 'SET_ROLE_MANAGEMENT_PAGE_DATA',
  ADD_ROLE_TO_USER: 'ADD_ROLE_TO_USER',
  ADD_ROLE_TO_USER_SUCCESS: 'ADD_ROLE_TO_USER_SUCCESS',
  REMOVE_ROLE_FROM_USER: 'REMOVE_ROLE_FROM_USER',
  REMOVE_ROLE_FROM_USER_SUCCESS: 'REMOVE_ROLE_FROM_USER_SUCCESS',
  UPDATE_USER_ROLE_ERROR: 'UPDATE_USER_ROLE_ERROR',
  CLEAR_SELECTED: 'CLEAR_SELECTED',
};

export const getRoleManagementData = () => ({
  type: TYPES.GET_ROLE_MANAGEMENT_PAGE_DATA,
  payload: {},
});

export const getRoleManagementDataError = (error) => ({
  type: TYPES.GET_ROLE_MANAGEMENT_PAGE_DATA,
  payload: { error },
});

export const setRoleManagementData = (payload) => ({
  type: TYPES.SET_ROLE_MANAGEMENT_PAGE_DATA,
  payload,
});

export const selectUser = (user) => ({
  type: TYPES.ROLE_MANAGEMENT_PAGE_SELECT_USER,
  payload: { user },
});

export const selectRole = (role) => ({
  type: TYPES.ROLE_MANAGEMENT_PAGE_SELECT_ROLE,
  payload: { role },
});

export const addRoleToUser = (role, user, screen) => ({
  type: TYPES.ADD_ROLE_TO_USER,
  payload: { role, user, screen },
});
export const addRoleToUserSuccess = (screen) => ({
  type: TYPES.ADD_ROLE_TO_USER_SUCCESS,
  screen,
});

export const removeRoleFromUser = (role, user) => ({
  type: TYPES.REMOVE_ROLE_FROM_USER,
  payload: { role, user },
});
export const removeRoleFromUserSuccess = () => ({
  type: TYPES.REMOVE_ROLE_FROM_USER_SUCCESS,
});

export const updateUserRoleError = (error) => ({
  type: TYPES.UPDATE_USER_ROLE_ERROR,
  payload: { error },
});
export const clearSelected = () => ({
  type: TYPES.CLEAR_SELECTED,
});
