import {styled} from "@mui/material";

export const Header = styled('div')({
  marginBottom: 16,
});

export const Insight = styled('div')({
  display: 'flex',
  alignItems: 'baseline',
  marginBottom: 14,
  '&:last-of-type': {
    marginBottom: 0,
  },
});

export const IconWrapper = styled('div')({
  display: 'inline-block',
  width: 36,
  marginRight: 15,
  flexShrink: 0,
});
