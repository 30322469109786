import { useState } from 'react';
import ChatService, { ScreenSharingRoleEnum } from '@services/chatService';
import {
  StyledScreenSharingOnIcon,
  StyledScreenSharingOffIcon,
  StyledScreenSharingBtnWrapper,
} from '../../styles';

export const ScreenSharingBtn = () => {
  const [screenSharing, setScreenSharing] = useState(false);

  return (
    <StyledScreenSharingBtnWrapper>
      {(screenSharing && ChatService.screenSharingRole === ScreenSharingRoleEnum.PUBLISHER)
        ? <StyledScreenSharingOffIcon onClick={() => ChatService.stopScreenSharing(setScreenSharing)} />
        : <StyledScreenSharingOnIcon onClick={() => ChatService.screenSharing(setScreenSharing)} />
      }
    </StyledScreenSharingBtnWrapper>
  );
};
