import {styled} from "@mui/material";

export const Wrapper = styled('div')(
  {
    backgroundColor: '#FFF',
    display: 'flex',
    padding: '30px 6px 6px',
    alignItems: 'center',
    justifyContent: 'center',
  },
  (props) => ({
    gridArea: props.gridArea,
  })
);

export const Tab = styled('div')(
  {
    color: '#000',
    fontSize: '30px',
    padding: '0 10px',
    fontWeight: '200',
    textTransform: 'capitalize',
    cursor: 'pointer',
    margin: '0 15px',
  },
  (props) => ({
    fontWeight: props.selected ? '700' : '400',
    borderBottom: props.selected
      ? `4px solid ${props.theme.palette.novotalk.$novotalkBlue}`
      : 'none',
  })
);
