import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Text, { TEXT_SIZE, TEXT_COLOR, TEXT_WEIGHT } from '@components/Text';
import { Wrapper, Title } from './styles';

// eslint-disable-next-line react/prefer-stateless-function
class Instructions extends Component {
  render() {
    const { title, text, miniOverview } = this.props;
    return (
      <Wrapper miniOverview={miniOverview}>
        <Title>
          <Text
            size={TEXT_SIZE[miniOverview ? 'H4' : 'T3']}
            textColor={TEXT_COLOR.DARK_BLUE}
            weight={miniOverview ? TEXT_WEIGHT.BOLD : '900'}
            textTransform={miniOverview ? '' : 'uppercase'}
          >
            {title}
          </Text>
        </Title>
        <Text
          size={TEXT_SIZE[miniOverview ? 'T4' : 'T3']}
          textColor={TEXT_COLOR[miniOverview ? 'DARK_BLUE' : 'DISABLED']}
        >
          {text}
        </Text>
      </Wrapper>
    );
  }
}

Instructions.propTypes = {
  title: PropTypes.string,
  text: PropTypes.string,
  miniOverview: PropTypes.bool,
};

export default Instructions;
