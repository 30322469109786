import React from 'react';
const icon = () =>
  (<svg width="0.8em" height="0.8em" viewBox="0 0 14 14" version="1.1" xmlns="http://www.w3.org/2000/svg" >
    <g id="Symbols" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g id="Resource-Menu" transform="translate(-22.000000, -353.000000)">
        <g id="Group-3" transform="translate(22.000000, 353.000000)">
          <rect id="Rectangle" stroke="currentColor" fillRule="nonzero" x="0.5" y="0.5" width="13" height="13" rx="1"></rect>
          <path d="M9.82191793,8.0390625 L9.18362321,6.37076172 C9.13465,6.24277344 8.99675529,6.15677734 8.84191351,6.15677734 C8.83939283,6.15677734 8.83687215,6.15677734 8.83437398,6.15685547 C8.67647137,6.1596875 8.53916183,6.25146484 8.4956801,6.38318359 L7.49451997,9.41564453 L6.31625957,3.28072266 C6.2881945,3.13462891 6.14861183,3 5.96106873,3 C5.77352563,3 5.65289308,3.1128125 5.61166196,3.25654297 L4.23926417,8.0390625 L2,8.0390625 L2,8.66404297 L4.5206801,8.66404297 C4.68605472,8.66404297 4.8301386,8.56632813 4.87008688,8.42705078 L5.89114237,4.86890625 L7.04624402,10.8833594 C7.07390399,11.0274414 7.21272144,11.1161719 7.38077428,11.1244531 C7.38770615,11.1248047 7.39459301,11.125 7.40145736,11.125 C7.56118296,11.125 7.70317377,11.0527148 7.74762326,10.9180859 L8.86716533,7.52712891 L9.22026309,8.45001953 C9.26916879,8.5778125 9.40688345,8.66404297 9.56204031,8.66404297 C9.56204031,8.66404297 10.956193,8.72029879 11.4945028,8.58727147 C11.7972996,8.5124442 11.9443509,8.40052526 11.9866979,8.29728317 C12.042776,8.1605648 11.9152408,8.0390625 11.7226233,8.0390625 L9.82191793,8.0390625 Z" id="Shape" fill="currentColor"></path>
        </g>
      </g>
    </g>
  </svg>);

export default icon;
