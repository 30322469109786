import {styled} from "@mui/material";

const CONFIG = {
  fillColor: ({ theme }) => theme.palette.novotalk.$backgroundColor,
  arrowHeight: 10,
  arrowWidth: 8,
  borderWidth: 1,
};

const ArrowBox = styled('div')`
  z-index: 9999;
  position: relative;
  background: ${CONFIG.fillColor};
  width: 160px;
  left: -80px;
  top: 70px;
  box-shadow: 0px 4px 17px 0px
    ${({ theme }) => theme.palette.novotalk.$navigationMessagesMenuColorShadow};
  border-radius: 4px;

  :after {
    bottom: 100%;
    left: 50%;
    border: solid transparent;
    content: ' ';
    height: 0;
    width: 0;
    position: absolute;
    pointer-events: none;
    border-left: ${CONFIG.arrowWidth - CONFIG.borderWidth}px solid transparent;
    margin-left: -${CONFIG.arrowWidth - CONFIG.borderWidth}px;
    border-bottom: ${CONFIG.arrowHeight - CONFIG.borderWidth}px solid
      ${CONFIG.fillColor};
    border-right: ${CONFIG.arrowWidth - CONFIG.borderWidth}px solid transparent;
  }
`;
export default ArrowBox;
