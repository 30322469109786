import React from 'react';
import PropTypes from 'prop-types';
import Text, { TEXT_COLOR, TEXT_SIZE } from '@components/Text';
import {
  Wrapper,
  Title,
  Body,
  Buttons,
  ConfirmButton,
  CancelButton,
} from './styles';

const onButtonClicked = (onClose, onConfirm, result) => () => {
  onConfirm(result);
  onClose();
};

const Confirm = ({
  titleText,
  bodyText,
  confirmText,
  cancelText,
  onClose,
  onConfirm,
  icon,
}) => (
  <Wrapper>
    {icon}
    <Title>
      <Text size={TEXT_SIZE.T0} textColor={TEXT_COLOR.DARK_BLUE} weight='900'>
        {titleText}
      </Text>
    </Title>
    {bodyText && (
      <Body>
        <Text size={TEXT_SIZE.T3} textColor={TEXT_COLOR.DISABLED}>
          {bodyText}
        </Text>
      </Body>
    )}
    <Buttons>
      <ConfirmButton
        className='confirmButton'
        onClick={onButtonClicked(onClose, onConfirm, true)}
      >
        {confirmText}
      </ConfirmButton>
      <CancelButton
        cancel
        className='cancelButton'
        onClick={onButtonClicked(onClose, onConfirm, false)}
      >
        {cancelText}
      </CancelButton>
    </Buttons>
  </Wrapper>
);

Confirm.propTypes = {
  onClose: PropTypes.func,

  onConfirm: PropTypes.func,
  titleText: PropTypes.node,
  bodyText: PropTypes.node,
  confirmText: PropTypes.node,
  cancelText: PropTypes.node,
};

export default Confirm;
