import { toast } from 'react-toastify';
import { TOAST_AUTO_CLOSE_TIME } from '@shared/constants';
import { TOAST_TYPES, USER_STATUS } from './consts';
import { ACTIVITY_TYPES } from '@shared/Resources/types';
import {useTranslation} from "react-i18next";

/**
* Show toast by type
* @param {string} toastType - toast type
*/
export const showToast = (toastType) => {
  const {t} = useTranslation()
  switch (toastType) {
    case TOAST_TYPES.SHOW_SUCCESS_CHANGED_LEVEL: {
      return toast.success(
          t('UI.levelChanged'), {
          autoClose: TOAST_AUTO_CLOSE_TIME,
          position: 'bottom-right',
        });
    }
    case TOAST_TYPES.SHOW_ERROR_CHANGED_LEVEL: {
      return toast.error(
        t('UI.levelNotChanged'), {
          autoClose: TOAST_AUTO_CLOSE_TIME,
          position: 'bottom-right',
        });
    }
    case TOAST_TYPES.SHOW_SUCCESS_ATTACHED: {
      return toast.success(
        t('UI.attachedSuccessfully'), {
          autoClose: TOAST_AUTO_CLOSE_TIME,
          position: 'bottom-right',
        });
    }
    case TOAST_TYPES.SHOW_ERROR_ATTACHED: {
      return toast.error(
        t('UI.attachedUnsuccessfully'), {
          autoClose: TOAST_AUTO_CLOSE_TIME,
          position: 'bottom-right',
        });
    }
    default: return null
  }
}

/**
* Format user status
* @param {boolean} status - user status from store
* @returns {string} User status by text
*/
export const formatUserStatus = (status) => status === USER_STATUS.ACTIVE ? USER_STATUS.ACTIVE : USER_STATUS.INACTIVE;

export const formatMentorsToOptions = (mentors) => mentors.map(({ id, fullName }) => ({ label: fullName, value: id }));

export const formatActivities = (activities) => activities
  .filter(({ type }) => type !== ACTIVITY_TYPES.ACTIVITY_EXERCISE)
  .map((activity) => ({ ...activity, duration: Math.round(activity.duration) }));
