import {styled} from "@mui/material";
import { getIconsByType, iconTypes } from '@services/icons';

const ChevronLeftIcon = getIconsByType(iconTypes.CHEVRON_LEFT);

export const StyledChevronLeftIcon = styled(ChevronLeftIcon)({
  fontSize: 27,
  marginRight: 10,
});

export const MenuItem = styled('div')({
  height: 56,
  padding: '16px 0 16px 16px',
  display: 'flex',
});

export const ItemText = styled('div')({
  width: 187,
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  fontWeight: 'bold',
});
