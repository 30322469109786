import React from 'react';
import PropTypes from 'prop-types';
import Text from '@components/Text';
import { compose } from 'redux';
import _ from 'lodash';
import { Input, SearchContainer } from './styles';
import {withTranslation} from "react-i18next";

class Search extends React.PureComponent {
  static propTypes = {
    onChange: PropTypes.func,
    showCount: PropTypes.number,
  };

  static defaultProps = {
    onChange: _.noop,
    showCount: 0,
  };

  render() {
    const { onChange, showCount, t } = this.props;
    return (
      <SearchContainer>
        <Input
          onChange={onChange}
          data-cy='media_upload_filter_input'
          placeholder={t('Actions.Navigation.search')}
        />
        <Text>{`(${showCount > 0 ? showCount : '-'})`}</Text>
      </SearchContainer>
    );
  }
}

export default compose(withTranslation())(Search);
