import _ from 'lodash';
import { BUILDER_TYPES } from '../editorObjects/editorObjectTypes';

const getConvertFunction = (type) => {
  switch (type) {
    case BUILDER_TYPES.EXERCISE : return (info) => ({
      type,
      payload: { ...info, media: info.additionalData.media },
    });
    case BUILDER_TYPES.LESSON : return (info) => ({
      type,
      payload: { ...info, media: info.additionalData.media },
    });
    case BUILDER_TYPES.PRACTICE : return (info) => ({
      type,
      payload: info,
    });
    case BUILDER_TYPES.POLL : return (info) => ({
      type,
      payload: info,
    });
    case BUILDER_TYPES.SKILL : return (info, children) => ({
      type,
      payload: { ...info, children },
    });
    default: return (info) => info;
  }
};

export const createdEntityToEditorComponent = (type, info, components) => {
  const convertFunction = getConvertFunction(type);
  const createdEditorComponent = convertFunction(info, components);
  if (createdEditorComponent.payload) createdEditorComponent.payload.title = _.capitalize(type);
  return createdEditorComponent;
};
