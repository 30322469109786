import { styled } from '@mui/material';

export const FillMonitorContainer = styled('div')(({ height, isRTL, isInhalation }) => ({
  width: '100%',
  height,
  marginBottom: '20px',

  transform: isInhalation ? `rotate(${!isRTL ? 180 : 0}deg)` : `rotate(${!isRTL ? 0 : 180}deg)`,
}));
export const WrapperProgressMonitor = styled('div')(({ widthMonitor, isInhalation }) => ({
  position: 'relative',
  background: !isInhalation && '#E2E5EB',
  border: !isInhalation && '2px solid #7B7C80',
  height: '100%',
  width: widthMonitor,
  margin: '0 auto',
}));
