import Text from "@components/Text";
import { SummaryWrapper, SummaryColumn } from './styles';
import {useTranslation} from "react-i18next";

const SUMMARY_TITLES = ['fluency', 'controlOfTechniques', 'comments'];
const SUMMARY_DATA = ['fluency', 'techniques', 'comments'];

export const SummaryActivityLog = ({ summary }) => {
    const {t} = useTranslation()
    return (
        <SummaryWrapper>
            <SummaryColumn>
                {SUMMARY_TITLES.map((text) =>
                    <Text key={text} size="H4">
                        {t(`Common.UI.${text}`)}
                    </Text>
                )}
            </SummaryColumn>
            <SummaryColumn>
                {SUMMARY_DATA.map((data) =>
                    <Text key={data} size="H4">
                        {summary[data]}
                    </Text>
                )}
            </SummaryColumn>
        </SummaryWrapper>
    )
}
