import {styled} from "@mui/material";

export const StimulusTypesContainer = styled('div')({
  display: 'flex',
  marginTop: 20,
  marginBottom: 25,
});

export const StimulusType = styled('div')(
  {
    marginRight: 6,
    borderRadius: 4,
    padding: 12,
    textAlign: 'center',
    cursor: 'pointer',
  },
  ({ theme, isSelected }) => ({
    border: `1px solid ${
      isSelected ? theme.palette.novotalk.$activeColor : theme.palette.novotalk.$backgroundColor
    }`,
    '&:hover': {
      border: `1px solid ${theme.palette.novotalk.$monitorTypesBorderColorHover}`,
    },
    '&:active': {
      border: `1px solid ${theme.palette.novotalk.$activeColor}`,
    },
  })
);

export const ConfigSection = styled('div')({
  marginBottom: 10,
});
