import {styled} from "@mui/material";
import isPropValid from '@emotion/is-prop-valid';
import {
  getIconsByType,
  getIconByResourceType,
  iconTypes,
} from '@services/icons';
import { RESOURCES } from '../../../types';

const iconStyles = ({ theme }) => ({
  fontSize: 21,
  marginTop: -3,
  marginLeft: -2,
  marginRight: 10,
  color: theme.palette.novotalk.$activeColor,
});

const iconMiniOverviewStyles = ({ theme }) => ({
  fontSize: 18,
  marginTop: -3,
  marginLeft: -2,
  marginRight: 6,
  color: theme.palette.novotalk.$activeColor,
});

const forwardPropsSettings = {
  shouldForwardProp: isPropValid,
};

export const MonitorTypesStyledIcon = styled(
  getIconsByType(iconTypes.PULSE),
  forwardPropsSettings
)(({ miniOverview }) => (miniOverview ? iconMiniOverviewStyles : iconStyles));

export const PatternTypesStyledIcon = styled(
  getIconsByType(iconTypes.ANALYTICS),
  forwardPropsSettings
)(({ miniOverview }) => (miniOverview ? iconMiniOverviewStyles : iconStyles));

export const ProductionDurationStyledIcon = styled(
  getIconsByType(iconTypes.CLOCK),
  forwardPropsSettings
)(({ miniOverview, theme }) =>
  miniOverview
    ? iconMiniOverviewStyles
    : {
        fontSize: 19,
        marginTop: -2,
        marginRight: 8,
        marginLeft: -2,
        color: theme.palette.novotalk.$textColorLightGray,
      }
);

export const ErrorsStyledIcon = styled(
  getIconsByType(iconTypes.WARNING),
  forwardPropsSettings
)(({ miniOverview }) => (miniOverview ? iconMiniOverviewStyles : iconStyles));

export const ProgressStyledIcon = styled(
  getIconsByType(iconTypes.SYNC),
  forwardPropsSettings
)(({ miniOverview }) => (miniOverview ? iconMiniOverviewStyles : iconStyles));

export const RepetitionStyledIcon = styled(
  getIconsByType(iconTypes.REPETITION),
  forwardPropsSettings
)(
  ({ miniOverview }) => (miniOverview ? iconMiniOverviewStyles : iconStyles),
  ({ miniOverview }) => ({
    fontSize: miniOverview ? 20 : 23,
    marginRight: miniOverview ? 4 : 8,
  })
);

export const StimulusStyledIcon = styled(
  getIconByResourceType(RESOURCES.STIMULUS),
  forwardPropsSettings
)(
  ({ miniOverview }) => (miniOverview ? iconMiniOverviewStyles : iconStyles),
  ({ miniOverview }) => ({
    width: miniOverview ? 13 : 15,
    marginTop: miniOverview ? -4 : -5,
    marginRight: miniOverview ? 8 : 12,
    marginLeft: 0,
  })
);

export const RequiredSign = styled('span')({
  fontSize: '23px',
  fontWeight: 900,
  marginLeft: '5px',
},
  ({ theme }) => ({
    color: theme.palette.novotalk.$errorColor,
  })
);
