import React from 'react';
import Text, { TEXT_COLOR, TEXT_SIZE } from '@components/Text';
import { Slider, SwitchWrapper } from '@components/Switch/styles';
import PropTypes from 'prop-types';

export const Switch = ({ showNames, checked, names, onChange }) => {
    return (
        <SwitchWrapper>
            {showNames &&
                <Text
                    size={TEXT_SIZE.H4}
                    textColor={TEXT_COLOR[checked ? 'LIGHT_GRAY' : 'DARK_BLUE']}
                >
                    {names?.[0]}
                </Text>
            }
            <Slider
                on={checked}
                className="slider"
                onClick={onChange}
                withMargin={showNames}
            />
            {showNames &&
                <Text
                    size={TEXT_SIZE.H4}
                    textColor={TEXT_COLOR[checked ? 'DARK_BLUE' : 'LIGHT_GRAY']}
                >
                    {names[1]}
                </Text>
            }
        </SwitchWrapper>
    );
}

Switch.propTypes = {
  showNames: PropTypes.bool,
  checked: PropTypes.bool,
  names: PropTypes.array,
  onChange: PropTypes.func,
};
