import React from 'react';
import PropTypes from 'prop-types';
import EditorComponentWrapper from '@shared/Resources/shared/components/BuilderComponentWrapper';
import EntitySelectionOptions from '@shared/Resources/shared/components/EntitySelectionOptions';
import { POLL } from '../../types';

export const EditorPoll = ({ submitPayload }) => (
  <EntitySelectionOptions entityType={POLL} onItemSelect={submitPayload} />
);

EditorPoll.propTypes = {
  submitPayload: PropTypes.func,
};

export default EditorComponentWrapper(EditorPoll);
