import _ from 'lodash';
import zxcvbn from 'zxcvbn';

const EMAIL_REGEX = /^[a-z0-9._\-+]+@[a-z0-9.-]+\.[a-z]{2,6}$/i;

const isUserName = (email) => {
  const parts = email.split('_');
  return (parts.length >= 3)
  && parts[0].length === 2
  && parts[1].length === 3
  && !_.isNaN(parts[2]);
};

export const isEmailValid = (email, userNameAlsoValid) => {
  if (userNameAlsoValid && isUserName(email)) return true;
  return EMAIL_REGEX.test(email);
};

const HAS_SPACES_REGEX = /\s/;
export const hasSpaces = new RegExp(HAS_SPACES_REGEX);

export const getPasswordStrength = (password, formValues) => {
  const additionalPatterns = ['novotalk', ...formValues];
  // score defines password's strength as an integer from 0 to 4
  const { score } = zxcvbn(password, additionalPatterns);
  switch (score) {
    case 0:
    case 1:
      return 0;
    case 2:
      return 1;
    default:
      return 2;
  }
};

export const formHasErrors = (formErrors) => (
  _.reduce(formErrors, (result, fieldError) => (
    result || !_.isNull(fieldError)
  ), false)
);

export const errorTypes = {
  noEmail: 'noEmail',
  invalidEmail: 'invalidEmail',
  noName: 'noName',
  noPassword: 'noPassword',
  noBirthdate: 'noBirthdate',
  passwordsNoMatch: 'passwordsNoMatch',
  weakPassword: 'weakPassword',
  noLanguage: 'noLanguage',
};

export const fieldTypes = {
  firstName: 'firstName',
  lastName: 'lastName',
  email: 'email',
  password: 'password',
  confirmPassword: 'confirmPassword',
  birthdate: 'birthdate',
  language: 'language',
};

export const getErrorMessageId = (fieldName, fieldValue, confirmField = null, options = { userNameAlsoValid: false }) => {
  const {
    firstName, lastName, password, confirmPassword, email, birthdate, language,
  } = fieldTypes;
  switch (fieldName) {
    case firstName:
    case lastName:
      return fieldValue === '' ? errorTypes.noName : null;
    case password:
    case confirmPassword:
      if (fieldValue === '' || confirmField === '') {
        return errorTypes.noPassword;
      } if (fieldName === password && options.isWeak) {
        return errorTypes.weakPassword;
      } if (confirmField && fieldValue !== confirmField) {
        return errorTypes.passwordsNoMatch;
      }
      return null;
    case email:
      if (fieldValue === '') {
        return errorTypes.noEmail;
      } if (!isEmailValid(fieldValue, options.userNameAlsoValid)) {
        return errorTypes.invalidEmail;
      }
      return null;
    case birthdate:
      return fieldValue ? null : errorTypes.noBirthdate;
    case language:
      return fieldValue ? null : errorTypes.noLanguage;
    default:
      return null;
  }
};

export const checkStrengthPassword = (password) => {
  const errors = [];

  if (password.length < 8) {
    errors.push('Be at least 8 characters');
  }
  if (password.search(/[a-z]/) === -1) {
    errors.push('At least one lowercase letter');
  }
  if (password.search(/[A-Z]/) === -1) {
    errors.push('At least one uppercase letter');
  }
  if (password.search(/\d/) === -1) {
    errors.push('At least one number');
  }
  if (password.search(/[!@#$%^&*()`'"|?></]/) === -1) {
    errors.push('At least one special character !@#$%^&*()`\'"|?></');
  }
  if (password.search(/\s/) !== -1) {
    errors.push('Password has spaces');
  }
  if (password.search(/[^a-zA-Z0-9!@#$%^&*()`'"|?></\s]/) !== -1) {
    errors.push('Password must contain only english letters');
  }
  return errors;
};
