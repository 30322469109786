import React from 'react';
import PropTypes from 'prop-types';
import Text, { TEXT_COLOR } from '@components/Text';
import { Wrapper, Title, Body, StyledCTAButton } from './styles';

const Alert = ({
  titleText,
  bodyText,
  buttonText,
  onClose,
  width,
  buttonProps,
}) => (
  <Wrapper width={width}>
    <div dir='auto'>
      <Title>
        <Text size='T0' textColor={TEXT_COLOR.DARK_BLUE} weight='900'>
          {titleText}
        </Text>
      </Title>
      <Body>
        <Text size='T3' textColor={TEXT_COLOR.DISABLED}>
          {bodyText}
        </Text>
      </Body>
    </div>
    <StyledCTAButton onClick={onClose} {...buttonProps}>
      <Text size='T2' textColor={TEXT_COLOR.REVERSED} weight='900'>
        {buttonText}
      </Text>
    </StyledCTAButton>
  </Wrapper>
);

Alert.defaultProps = {
  buttonText: 'close',
};

Alert.propTypes = {
  onClose: PropTypes.func,
  titleText: PropTypes.node,
  bodyText: PropTypes.node,
  buttonText: PropTypes.node,
  width: PropTypes.number,
  buttonProps: PropTypes.object,
};

export default Alert;
