export const EXPECTED_ERRORS = {
  INHALE: [],
  BREATHING: [],
  GENTLE_VOICE: [
    'notGentleProduction',
  ],
  LOUD_VOICE: [
    'notLoudProduction',
  ],
  WAVES: [],
  ONE_SECOND: [
    'nonGentleOnsetOsm',
    'SoftPeakOsm',
    'noLinkingOsm',
    'tooFlatOsm',
    'tooFastOsm',
    'tooSlowOsm',
  ],
  TWO_SECONDS: [
    'nonGentleOnset',
    'sharpRiseBeginning',
    'volumeRiseBeginning',
    'shapeBeginning',
    'excessivePeak',
    'softPeak',
    'shapeEnd',
    'volumeFallEnd',
    'sharpFallEnd',
    'nonGentleOffset',
  ],
  VOICE_TRANSITION: [
    'notGentleBeginning',
    'notGentleEnd',
    'softPeak',
  ],
  SELF_MANAGED: [
    'tooFastSmm',
    'tooSlowSmm',
    'nonGentleOnsetSmm',
    'noLinkingSmm',
    'tooFlatSmm',
  ],
};

export const SAMPLES_IN_SEC = 7.2;

export const GRAPH_SIZE = {
  TWO_SECONDS: {
    height: 120,
    width: 260,
    refreshSamples: 0,
    sampleWidth: 18, // HERE STEP OF CANVAS IN MONITOR (TO CHANGE SPEED OF MONITOR)
    LTR: true,
    background: 'rgba(255,255,255,0)',
    image: 'TWO_SECONDS',
    imageKeepRatio: false,
  },
  WAVES: {
    height: 120,
    width: 520,
    refreshSamples: 0,
    sampleWidth: 18,
    LTR: true,
    background: 'rgba(255,255,255,0)',
    imageKeepRatio: false,
  },
  GENTLE_VOICE: {
    height: 120,
    width: 520,
    background: 'linear-gradient(270deg, #C4F1FF 0%, #DCF7FF 26.54%, #F7FDFF 49.02%, #DCF7FF 73.42%, #C4F1FF 100%)',
    refreshSamples: 0,
    sampleWidth: 18,
    LTR: true,
    imageKeepRatio: false,
  },
  LOUD_VOICE: {
    height: 120,
    width: 260,
    background: 'linear-gradient(270deg, rgba(255,155,40,0.63) 0%, rgba(255,156,42,0.52) 17.75%, rgba(255,160,50,0.16) 49.58%, rgba(255,156,42,0.52) 80.05%, rgba(255,160,50,0.63) 100%);',
    refreshSamples: 0,
    sampleWidth: 18,
    LTR: true,
    imageKeepRatio: false,
  },
  VOICE_TRANSITION: {
    height: 120,
    width: 520,
    background: 'linear-gradient(270deg, #C4F1FF 0%, #DCF7FF 32.49%,rgba(255,155,40,0.63) 32.50%, rgba(255,156,42,0.52) 42.75%, rgba(255,160,50,0.16) 49.58%, rgba(255,156,42,0.52) 57.25%, rgba(255,160,50,0.63) 67.49%, #DCF7FF 67.50%, #C4F1FF 100%);',
    refreshSamples: 0,
    sampleWidth: 18,
    LTR: true,
    imageKeepRatio: false,
  },
  BREATHING: {
    height: 120,
    width: 630,
    refreshSamples: 0,
    sampleWidth: 15,
    LTR: true,
    background: 'rgb(197,251,206)',
    imageKeepRatio: false,
  },
  INHALE: {
    height: '30px',
    width: 630,
    refreshSamples: 0,
    sampleWidth: 38,
    LTR: false,
    background: 'rgb(197,251,206)',
    imageKeepRatio: false,
  },
  SYMMETRICAL_TRANSITION: {
    height: 120,
    width: 630,
    refreshSamples: 0,
    sampleWidth: 38,
    LTR: false,
    background: 'linear-gradient(270deg, #C4F1FF 0%, #DCF7FF 32.49%,rgba(255,155,40,0.63) 32.50%, rgba(255,156,42,0.52) 42.75%, rgba(255,160,50,0.16) 49.58%, rgba(255,156,42,0.52) 57.25%, rgba(255,160,50,0.63) 67.49%, #DCF7FF 67.50%, #C4F1FF 100%);',
    imageKeepRatio: false,
  },
  ONE_SECOND: {
    height: 120,
    width: 260,
    refreshSamples: 0,
    sampleWidth: 18, // HERE STEP OF CANVAS IN MONITOR (TO CHANGE SPEED OF MONITOR)
    LTR: true,
    background: 'rgba(255,255,255,0)',
    image: 'ONE_SECOND',
    imageKeepRatio: false,
  },
  SELF_MANAGED: {
    height: 120,
    width: 630,
    refreshSamples: 0,
    sampleWidth: 18,
    LTR: true,
    background: '#F5FBED',
    imageKeepRatio: false,
  },
  PACE_CHANGES: {
    height: 120,
    width: 630,
    refreshSamples: 0,
    sampleWidth: 38,
    LTR: false,
    background: 'linear-gradient(270deg, #C4F1FF 0%, #DCF7FF 32.49%,rgba(255,155,40,0.63) 32.50%, rgba(255,156,42,0.52) 42.75%, rgba(255,160,50,0.16) 49.58%, rgba(255,156,42,0.52) 57.25%, rgba(255,160,50,0.63) 67.49%, #DCF7FF 67.50%, #C4F1FF 100%);',
    imageKeepRatio: false,
  },
  INTEGRATED_SPEECH: {
    height: 120,
    width: 630,
    refreshSamples: 0,
    sampleWidth: 38,
    LTR: false,
    background: 'linear-gradient(270deg, #C4F1FF 0%, #DCF7FF 32.49%,rgba(255,155,40,0.63) 32.50%, rgba(255,156,42,0.52) 42.75%, rgba(255,160,50,0.16) 49.58%, rgba(255,156,42,0.52) 57.25%, rgba(255,160,50,0.63) 67.49%, #DCF7FF 67.50%, #C4F1FF 100%);',
    imageKeepRatio: false,
  },
};

export const CALIBRATION = {
  HARD: {
    yVal: 220,
    draw: 4600,
    color: '#EDA632',
  },
  SOFT: {
    yVal: 51,
    draw: 135,
    color: '#65C8EA',
  },
};
export const SAMPLE = {
  TWO_SECONDS: {
    lengthSamples: 125,
    lengthMS: 2000,
    imagePosition: 0,
    strictness: {
      tooShort: 0.75,
      tooLong: 1.3,
    },
    gracePeriod: 5, // amount of time in samples where production doesn't "count"
    silenceSamplesToAdd: 2,
  },
  BREATHING: {
    lengthSamples: 140,
    lengthMS: 5000,
    imagePosition: 0,
    strictness: {
      tooShort: 0.75,
      tooLong: 1.3,
    },
    gracePeriod: 4, // amount of time in samples where production doesn't "count"
    silenceSamplesToAdd: 1,
  },
  GENTLE_VOICE: {
    lengthSamples: 125,
    lengthMS: 4000,
    imagePosition: 0,
    strictness: {
      tooShort: 0.75,
      tooLong: 1.3,
    },
    gracePeriod: 4, // amount of time in samples where production doesn't "count"
    silenceSamplesToAdd: 1,
  },
  WAVES: {
    lengthSamples: 125,
    lengthMS: 4000,
    imagePosition: 0,
    strictness: {
      tooShort: 0.75,
      tooLong: 1.3,
    },
    gracePeriod: 4, // amount of time in samples where production doesn't "count"
    silenceSamplesToAdd: 1,
  },
  LOUD_VOICE: {
    lengthSamples: 125,
    lengthMS: 2000,
    imagePosition: 0,
    strictness: {
      tooShort: 0.75,
      tooLong: 1.3,
    },
    gracePeriod: 4, // amount of time in samples where production doesn't "count"
    silenceSamplesToAdd: 1,
  },
  VOICE_TRANSITION: {
    lengthSamples: 125,
    lengthMS: 4000,
    imagePosition: 0,
    strictness: {
      tooShort: 0.75,
      tooLong: 1.3,
    },
    gracePeriod: 4, // amount of time in samples where production doesn't "count"
    silenceSamplesToAdd: 1,
  },
  INHALE: {
    lengthSamples: 140,
    lengthMS: 2000,
    imagePosition: 0,
    strictness: {
      tooShort: 0.75,
      tooLong: Infinity,
    },
    gracePeriod: 4, // amount of time in samples where production doesn't "count"
    silenceSamplesToAdd: 1,
  },
  SYMMETRICAL_TRANSITION: {
    lengthSamples: 125,
    lengthMS: 2000,
    imagePosition: 0,
    strictness: {
      tooShort: 0.75,
      tooLong: 1.3,
    },
    gracePeriod: 5,
    silenceSamplesToAdd: 2,
  },
  ONE_SECOND: {
    lengthSamples: 20,
    lengthMS: 1000,
    imagePosition: 0,
    strictness: {
      tooShort: 0.75,
      tooLong: 1.3,
    },
    gracePeriod: 5, // amount of time in samples where production doesn't "count"
    silenceSamplesToAdd: 2,
  },
  SELF_MANAGED: {
    lengthSamples: 125,
    lengthMS: 2000,
    imagePosition: 0,
    strictness: {
      tooShort: 0.75,
      tooLong: 1.3,
    },
    gracePeriod: 5,
    silenceSamplesToAdd: 2,
  },
  PACE_CHANGES: {
    lengthSamples: 125,
    lengthMS: 2000,
    imagePosition: 0,
    strictness: {
      tooShort: 0.75,
      tooLong: 1.3,
    },
    gracePeriod: 5,
    silenceSamplesToAdd: 2,
  },
  INTEGRATED_SPEECH: {
    lengthSamples: 125,
    lengthMS: 2000,
    imagePosition: 0,
    strictness: {
      tooShort: 0.75,
      tooLong: 1.3,
    },
    gracePeriod: 5, // amount of time in samples where production doesn't "count"
    silenceSamplesToAdd: 2,
  },
};

export const START_SAMPLE_THRESHOLD_VALUE = 700;
export const END_SAMPLE_THRESHOLD_VALUE = 700;
