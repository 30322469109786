export const nobotalkBlue = '#23B7E5';
export const errorBack = '#f2dede';
export const errorText = '#a94442';
export const successBack = '#d6e9c6';
export const successText = '#3c763d';
export const separatorColor = '#BBB';
export const ChartColors = [
  '#3366CC',
  '#DC3912',
  '#FF9900',
  '#109618',
  '#990099',
  '#3B3EAC',
  '#0099C6',
  '#DD4477',
  '#66AA00',
  '#B82E2E',
  '#316395',
  '#994499',
  '#22AA99',
  '#AAAA11',
  '#6633CC',
  '#E67300',
  '#8B0707',
  '#329262',
  '#5574A6',
  '#3B3EAC',
  '#0074D9',
  '#FF4136',
  '#2ECC40',
  '#FF851B',
  '#7FDBFF',
  '#B10DC9',
  '#FFDC00',
  '#001f3f',
  '#39CCCC',
  '#01FF70',
  '#85144b',
  '#F012BE',
  '#3D9970',
  '#111111',
  '#AAAAAA',

];

export const BAR_COLOR_VAL = '#69C3E5';
export const BAR_COLOR_SOFT_VAL = '#FF9A39';
