import {styled} from "@mui/material";
import { getIconsByType, iconTypes } from '@services/icons';

export const MediaItemContainer = styled('div')(
  {
    padding: 10,
    display: 'flex',
    textAlign: 'center',
    cursor: 'pointer',
  },
  ({ theme }) => ({
    backgroundColor: theme.palette.novotalk.$backgroundColor,
    '&:hover': {
      backgroundColor: theme.palette.novotalk.$backgroundColorMedium,
    },
  })
);

export const MediaItemInfo = styled('div')({
  margin: '0 20px',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start',
});

const Icon = getIconsByType(iconTypes.VIDEO_DEMO);

export const StyledIcon = styled(Icon)(
  {
    margin: 'auto',
    fontSize: 20,
  },
  ({ theme }) => ({
    color: theme.palette.novotalk.$textColorReversed,
  })
);

export const IconWrapper = styled('div')(
  {
    width: 40,
    height: 40,
    borderRadius: '50%',
    display: 'flex',
  },
  ({ theme }) => ({
    backgroundColor: theme.palette.novotalk.$backgroundColorDark,
  })
);
