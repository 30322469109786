import React from 'react';
import Text from '@components/Text';
import {t} from 'i18next'

export const getFormattedTextCurry = () =>
  (messageName, textProps = {}, overrideClassName = null, values = {}) => {
    return (
          <Text {...textProps} className={overrideClassName || messageName}>
              {t(messageName, values)}
          </Text>);
  }
