import {styled} from "@mui/material";

const TextArea = styled('textarea')(
  {
    width: '100%',
    height: 34,
    borderWidth: 1,
    borderStyle: 'solid',
    borderRadius: 4,
    resize: 'none',
    padding: '7px 10px',
    outline: 'none',
    fontSize: 14,
    lineHeight: '21px',
  },
  ({ theme }) => ({
    backgroundColor: theme.palette.novotalk.$backgroundColor,
  }),
  ({ theme, error, disabled }) => {
    if (disabled) {
      return {
        borderColor: theme.palette.novotalk.$inputBorderColor,
        color: theme.palette.novotalk.$inputBorderColor,
        backgroundColor: theme.palette.novotalk.$inputBackgroundColorDisabled,
        pointerEvents: 'none',
      };
    } else if (error) {
      return {
        borderColor: theme.palette.novotalk.$errorColor,
      };
    }
    return {
      borderColor: theme.palette.novotalk.$inputBorderColor,
      color: theme.palette.novotalk.$textColorDarkBlue,
      '&:hover': {
        borderColor: theme.palette.novotalk.$inputBorderColorHover,
      },
      '&:focus': {
        borderColor: theme.palette.novotalk.$activeColor,
      },
    };
  }
);

export default TextArea;
