import {styled} from "@mui/material";
import { getIconsByType, iconTypes } from '@services/icons';
import { STIMULUS_TYPE } from '../../types';

export const Wrapper = styled('div')(
  {
    borderRadius: 4,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    border: '1px solid',
  },
  ({ theme, isSelected, miniOverview }) => ({
    width: miniOverview ? 60 : 88,
    height: miniOverview ? 60 : 72,
    borderColor: isSelected
      ? theme.palette.novotalk.$errorSelectorBorderColor
      : theme.palette.novotalk.$monitorTypesImgBorderColor,
    backgroundColor: isSelected
      ? theme.palette.novotalk.$errorSelectorColor
      : theme.palette.novotalk.$monitorTypesImgBackgroundColor,
    color: isSelected ? theme.palette.novotalk.$activeColor : theme.palette.novotalk.$textColorLightGray,
  })
);

const getStyledIcon = (type) =>
  styled(getIconsByType(type))({
    fontSize: type === iconTypes.INFINITE ? 32 : 28,
  });

export const Icons = {
  [STIMULUS_TYPE.SYLLABLES]: getStyledIcon(iconTypes.TEXT),
  [STIMULUS_TYPE.WORDS]: getStyledIcon(iconTypes.INFINITE),
  [STIMULUS_TYPE.SENTENCES]: getStyledIcon(iconTypes.QUOTE),
  [STIMULUS_TYPE.PARAGRAPHS]: getStyledIcon(iconTypes.MENU),
  [STIMULUS_TYPE.SPEECH_TOPICS]: getStyledIcon(iconTypes.MICROPHONE),
  [STIMULUS_TYPE.PICTURES]: getStyledIcon(iconTypes.IMAGES),
  [STIMULUS_TYPE.CUSTOM]: getStyledIcon(iconTypes.ADD_CIRCLE),
};
