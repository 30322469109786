export const TYPES = {
  GET_STIMULI: 'GET_STIMULI',
  SET_STIMULI: 'SET_STIMULI',
  RESET_STIMULI: 'RESET_STIMULI',
  UPDATE_SYLLABLE_COUNT: 'UPDATE_SYLLABLE_COUNT',
  TOGGLE_LOADING_STIMULI: 'TOGGLE_LOADING_STIMULI',
};

export const getStimuli = (parameters) => ({
  type: TYPES.GET_STIMULI,
  parameters,
});

export const setStimuli = (stimuli, parameters) => ({
  type: TYPES.SET_STIMULI,
  stimuli,
  parameters,
});

export const resetStimuli = () => ({
  type: TYPES.RESET_STIMULI,
});
export const toggleLoadingStimuli = (loading) => ({
  type: TYPES.TOGGLE_LOADING_STIMULI,
  loading,
});
