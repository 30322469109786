import {styled} from "@mui/material";

export const Title = styled('div')({
  gridArea: 'SubTitle',
  marginTop: 27,
});

export const DateContainer = styled('div')({
  gridArea: 'Answers',
  '& #monthSelect > div, & #daySelect > div': {
    minWidth: 90,
    maxWidth: 90,
  },
  '& #yearSelect > div': {
    minWidth: 120,
    maxWidth: 120,
  },
});
