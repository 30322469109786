/**
*
* MonitorDuration
*
*/
import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import Text, { TEXT_SIZE, TEXT_COLOR } from '@components/Text';
import RadioButtonsGroup from '@components/RadioButtonsGroup';
import NumericSelector from '@components/NumericSelector';
import ToggleSwitch from '@components/ToggleSwitch';
import { ProgressTitle, RepetitionTitle } from '@shared/Resources/Monitor/shared/Titles';
import { PROGRESS_TYPE } from '../../../types';
import { OptionWrapper, InnerContainer, RepetitionWrapper, Timer } from './styles';
import {withTranslation} from "react-i18next";
import {compose} from "redux";

const MAX_REPETITION = 9;

class MonitorDuration extends React.Component {
  constructor(props) {
    super(props);
    const durationClone = _.cloneDeep(props.duration)
    if (!durationClone.value) {
      durationClone.value = 1;
    }

    this.state = {
      duration: durationClone
    };
  }

  onTimerChange = ({ target: { value } }) => {
    const { duration } = this.state;
    _.set(duration, 'value', value);
    this.setState({ duration });
    this.props.onChange(duration);
  }

  updateDurationField = (field) => (value) => {
    const { duration } = this.state;
    _.set(duration, field, value);
    this.setState({ duration });
    this.props.onChange(duration);
  }

  renderSequences = () => (
    <OptionWrapper>
      <Text size={TEXT_SIZE.H4} textColor={TEXT_COLOR.DARK_BLUE}>
          {this.props.t('Common.UI.sequences')}
      </Text>
      <NumericSelector
        className="sequenceInput"
        max={150}
        value={this.state.duration.value}
        onChange={this.updateDurationField('value')}
        disabled={this.state.duration.type !== PROGRESS_TYPE.SEQUENCES}
      />
    </OptionWrapper>)


  renderTimer = () => (
    <OptionWrapper>
      <Text size={TEXT_SIZE.H4} textColor={TEXT_COLOR.DARK_BLUE} className="timerTitle">
          {this.props.t('UI.timer')}
      </Text>
      <Timer
        value={this.state.duration.value}
        onChange={this.onTimerChange}
        type="time"
        className="timerInput"
        disabled={this.state.duration.type !== PROGRESS_TYPE.TIMER}
      />
      <Text size={TEXT_SIZE.T4} textColor={TEXT_COLOR.LIGHT_GRAY}>
          {this.props.t('Dates.secs')}
      </Text>
    </OptionWrapper>
  );

  render() {
    const { duration } = this.state;
    return (
      <div className="resource-item">
        {/** Progress */}
        <ProgressTitle />
        <InnerContainer>
          <RadioButtonsGroup
            value={duration.type}
            className="durationTypeSelect"
            options={[
              { value: PROGRESS_TYPE.SEQUENCES, label: this.renderSequences() },
              { value: PROGRESS_TYPE.TIMER, label: this.renderTimer() },
            ]}
            onChange={this.updateDurationField('type')}
          />
        </InnerContainer>

        {/** Repetition */}
        <RepetitionTitle />
        <InnerContainer>
          <RepetitionWrapper>
            <ToggleSwitch
              showNames
              names={[this.props.t('Actions.Simple.off'), this.props.t('Actions.Simple.on')]}
              value={duration.repetition.on}
              onChange={this.updateDurationField('repetition.on')}
              className="repetitionToggle"
            />
            <NumericSelector
              className="repetitionInput"
              value={duration.repetition.count}
              onChange={this.updateDurationField('repetition.count')}
              disabled={!this.state.duration.repetition.on}
              margin="0 18px"
              max={MAX_REPETITION}
            />
          </RepetitionWrapper>
        </InnerContainer>
      </div>);
  }
}

MonitorDuration.propTypes = {
  onChange: PropTypes.func,
  duration: PropTypes.shape({
    type: PropTypes.string,
    value: PropTypes.number,
    repetition: PropTypes.shape({
      on: PropTypes.bool,
      count: PropTypes.number,
    }),
  }),
};

export default compose(
    withTranslation()
)(MonitorDuration);
