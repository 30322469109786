import React from 'react';
import PropTypes from 'prop-types';
import { WavesMonitorContainer } from './styles';
import StaticBar from '../../components/StaticBar';
import Canvas from './containers/Canvas';

const WavesMonitor = ({
  height,
  LTR,
  buffer,
  patternType,
  monitorNumber,
  duration,
  widthMonitor,
  stepSize,
}) => (
  <WavesMonitorContainer height={height} LTR={LTR}>
    <StaticBar>
      <Canvas
        buffer={buffer}
        patternType={patternType}
        monitorNumber={monitorNumber}
        duration={duration}
        widthMonitor={widthMonitor}
        stepSize={stepSize}
      />
    </StaticBar>
  </WavesMonitorContainer>
);

WavesMonitor.propTypes = {
  height: PropTypes.string,
  LTR: PropTypes.bool,
  buffer: PropTypes.array,
  patternType: PropTypes.string,
  monitorNumber: PropTypes.number,
  widthMonitor: PropTypes.number
};

export default WavesMonitor;
