import {styled} from "@mui/material";

export const Wrapper = styled('div')(
  {
    backgroundColor: '#FFF',
    display: 'grid',
    gridTemplateColumns: 'minmax(950px, 1544px) auto',
    gridTemplateAreas: `
  "SkillPartHeader ExercisesPane"
  "PracticeMainPane ExercisesPane"
  `,
    '@media(min-width: 1920px)': {
      margin: '0 auto',
    },
  },
  ({ theme, language }) => ({
      direction: language === 'en' ? 'ltr' :'rtl',
      gridTemplateRows: `${theme.palette.novotalk.skillPartHeaderHeight}px auto`,
    backgroundColor: theme.palette.novotalk.$backgroundColor,
  })
);

export const LoadingOverlay = styled('div')({
  position: 'fixed',
  backgroundColor: '#FFF',
  width: '100%',
  height: '100%',
  zIndex: 101,
  display: 'flex',
  alignContent: 'center',
  justifyContent: 'center',
  alignItems: 'center',
});
