/**
*
* DismissActivity
*
*/

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import Text, { TEXT_COLOR } from '@components/Text';
import ErrorMessage from '@components/ErrorMessage';
import { Wrapper, Title, SubTitle, Body, Buttons, TextArea,
  ConfirmButton, CancelButton } from './styles';
import {withTranslation} from "react-i18next";
import {compose} from "redux";

class DismissActivity extends Component {
  constructor(props) {
    super(props);
    this.state = {
      input: '',
      error: false,
    };
  }
  onInputChange = (e) => {
    const { value } = e.target;
    this.setState({ input: value });
  }

  onCancel = () => {
    const { onClose } = this.props;
    onClose();
  };

  onConfirm = () => {
    const { onClose, onConfirm } = this.props;
    const { input } = this.state;
    if (_.isEmpty(input)) {
      this.setState({ error: true });
    } else {
      onConfirm(input);
      onClose();
    }
  }

  render() {
    const { error } = this.state;
    const {t} = this.props
    return (<Wrapper>
      <Title>
        <Text textColor={TEXT_COLOR.HIGHLIGHTED} size="H3" weight="600">
          {t('Informative.Confirming.dismissActivity')}
        </Text>
      </Title>
      <SubTitle>
        <Text textColor={TEXT_COLOR.DISABLED_LIGHT} size="H4" >
          {t('Informative.Demanding.dismissActivityReason')}
        </Text>
      </SubTitle>
      <Body>
        <TextArea
          error={error}
          className="dismissReasonInput"
          onChange={this.onInputChange}
        />
        {error && <ErrorMessage
          className="noInputError"
          type={'CUSTOM'}
          customText={t('UI.noInputError')}
        />}
      </Body>
      <Buttons>
        <CancelButton className="cancelButton" onClick={this.onCancel} >
          <Text size="H5" >
            {t('Actions.Instance.cancel')}
          </Text>
        </CancelButton>
        <ConfirmButton className="confirmButton" onClick={this.onConfirm} >
          <Text size="H5" >
            {t('Actions.Confirmations.confirm')}
          </Text>
        </ConfirmButton>
      </Buttons>
    </Wrapper>);
  }
}
DismissActivity.propTypes = {
  onClose: PropTypes.func,
  onConfirm: PropTypes.func,
};

export default compose(
    withTranslation()
)(DismissActivity);
