import React from 'react';
import { Route } from 'react-router-dom';
import PropTypes from 'prop-types';

export const UnprotectedRoute = ({ component: Component, ...rest }) => (
  <Route
    {...rest}
    render={(props) => (<Component {...props} />)}
  />);

UnprotectedRoute.propTypes = {
  location: PropTypes.object,
  component: PropTypes.func,
};


export default UnprotectedRoute;
