export const TYPES = {
  CHECK_FOR_EXISTING_NAME: 'CHECK_FOR_EXISTING_NAME',
  CHECK_FOR_EXISTING_NAME_COMPLETE: 'CHECK_FOR_EXISTING_NAME_COMPLETE',
  CHECK_FOR_EXISTING_NAME_ERROR: 'CHECK_FOR_EXISTING_NAME_ERROR',
};

export const checkForExistingName = (type, name) => ({
  type: TYPES.CHECK_FOR_EXISTING_NAME,
  payload: { type, name },
});

export const checkForExistingNameComplete = (nameExists) => ({
  type: TYPES.CHECK_FOR_EXISTING_NAME_COMPLETE,
  payload: { nameExists },
});

export const checkForExistingNameError = (error) => ({
  type: TYPES.CHECK_FOR_EXISTING_NAME_ERROR,
  payload: { error },
});
