import {styled} from "@mui/material";
import { RESOURCES } from '@shared/Resources/types';
import { getResponsiveCSS } from '@utils/cssHelpers';

export const DescriptionArea = styled('div')({
  marginBottom: 10,
  lineHeight: '19px',
});

export const TitleContainer = styled('div')(
  {
    paddingBottom: 8,
  },
  ({ parentType }) =>
    parentType === RESOURCES.SKILL && getResponsiveCSS('paddingTop', 10, 20),
  ({ theme }) => ({
    borderBottom: `1px solid ${theme.palette.novotalk.$resourcesTableBorderColor}`,
  })
);

export const ExpandButtonContainer = styled('div')({
  display: 'flex',
  justifyContent: 'flex-end',
  alignItems: 'center',
  marginBottom: 25,
});

export const SkillsList = styled('div')({
  '&:last-of-type': {
    marginBottom: 10,
  },
})
