import { call, put, takeLatest, all, fork, join } from 'redux-saga/effects';
import { authenticationWrapper } from '@services/errorHandlingService';
import { getAllMediaItems } from '@services/mediaService';
import { Types, setMediaItems } from './actions';
import itemsFixture from './__fixtures__';

const useFixtures = process.env.USE_FIXTURES;

export function* callGetMediaItems() {
  yield authenticationWrapper(function* codeBlock() {
    const res = yield fork(getAllMediaItems);
    const items = useFixtures ? itemsFixture : yield join(res);
    yield put(setMediaItems(items));
  });
}
function* watchCallGetMediaItems() {
  yield takeLatest(Types.GET_MEDIA_ITEMS, callGetMediaItems);
}

export default function* getPendingDashboardDataSaga() {
  yield all([fork(watchCallGetMediaItems)]);
}
