import { RESOURCE_MANAGER } from '@shared/Resources/types';

export const TYPES = {
  GET_RESOURCES: 'RESOURCES_MANAGER_GET_RESOURCES',
  SET_RESOURCES: 'RESOURCES_MANAGER_SET_RESOURCES',

  GET_OVERVIEW: 'RESOURCES_MANAGER_GET_OVERVIEW',
  SET_OVERVIEW: 'RESOURCES_MANAGER_SET_OVERVIEW',

  SET_EXPANDED_CHILD: 'RESOURCES_MANAGER_SET_EXPANDED_CHILD',
  GET_EXPANDED_CHILD: 'RESOURCES_MANAGER_GET_EXPANDED_CHILD',

  SET_UID: 'RESOURCES_MANAGER_SET_UID',
  GET_UID: 'RESOURCES_MANAGER_GET_UID',

  DUPLICATE: 'RESOURCES_MANAGER_DUPLICATE',

  COPY: 'RESOURCES_MANAGER_COPY',

  DELETE: 'RESOURCES_MANAGER_DELETE',
  DELETE_SUCCESS: 'RESOURCES_MANAGER_DELETE_SUCCESS',

  NETWORK_ERROR: 'RESOURCES_MANAGER_NETWORK_ERROR',
  DATA_ERROR: 'RESOURCES_MANAGER_DATA_ERROR',

  CREATE_NEW_RESOURCE: 'CREATE_NEW_RESOURCE',
  CREATE_NEW_RESOURCE_COMPLETE: 'CREATE_NEW_RESOURCE_COMPLETE',

  CHANGE_FILTER_TYPE: 'CHANGE_FILTER_TYPE',

  CLEAR_RESOURCES_DATA: 'CLEAR_RESOURCES_DATA',

  SET_SEARCH_TEXT: 'SET_SEARCH_TEXT',
  RESET_OFFSET: 'RESET_OFFSET'
};

export const createNewResource = (type, origin = RESOURCE_MANAGER) => ({
  type: TYPES.CREATE_NEW_RESOURCE,
  payload: { type, origin },
});

export const createNewResourceComplete = (type, origin, resourceID, creating) => ({
  type: TYPES.CREATE_NEW_RESOURCE_COMPLETE,
  payload: { type, origin, resourceID, creating },
});

export const deleteItem = (type, id) => ({
  type: TYPES.DELETE,
  payload: { type, id },
});

export const deleteItemSuccess = (type, id) => ({
  type: TYPES.DELETE_SUCCESS,
  payload: { type, id },
});

export const duplicateItem = (type, resource, userId) =>({
  type: TYPES.DUPLICATE,
  payload: { type, resource, userId },
});

export const copyItem = (type, resourceId) =>({
  type: TYPES.COPY,
  payload: { type, resourceId },
});

export const getUID = (type) => ({
  type: TYPES.GET_UID,
  payload: { type },
});

export const setUID = (type, id) => ({
  type: TYPES.GET_UID,
  payload: { type, id },
});

export const onNetworkError = (error) => ({
  type: TYPES.NETWORK_ERROR,
  payload: { error },
});

export const onDataError = () => ({
  type: TYPES.DATA_ERROR,
});

export const getResources = (type, search, loadType) => ({
  type: TYPES.GET_RESOURCES,
  payload: { type, search, loadType },
});

export const setResources = (type, resources, loadType) => ({
  type: TYPES.SET_RESOURCES,
  payload: { type, resources, loadType },
});

export const getOverview = (type, id, source) => ({
  type: TYPES.GET_OVERVIEW,
  payload: { type, id, source },
});

export const setOverview = (type, resourceOverview, source) =>({
  type: TYPES.SET_OVERVIEW,
  payload: { type, resourceOverview, source },
});

export const setExpanded = (expadedChildOverview, source) => ({
  type: TYPES.SET_EXPANDED_CHILD,
  payload: { expadedChildOverview, source },
});

export const getExpanded = (type, id, source) => ({
  type: TYPES.GET_EXPANDED_CHILD,
  payload: { type, id, source },
});

export const changeFilterType = (isTemplate) => ({
  type: TYPES.CHANGE_FILTER_TYPE,
  payload: isTemplate,
});

export const clearResourcesData = () => ({
  type: TYPES.CLEAR_RESOURCES_DATA,
});

export const setSearchText = (text) => ({
  type: TYPES.SET_SEARCH_TEXT,
  payload: text
})

export const resetOffset = () => ({
  type: TYPES.RESET_OFFSET,
})