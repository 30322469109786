import {styled} from "@mui/material";
import {BIG_SCREEN_BREAKPOINT} from "@containers/App/consts";

const NORMAL = 'normal';
const HIGHLIGHTED = 'highlighted';
const REVERSED = 'reversed';
const DISABLED = 'disabled';
const DISABLED_LIGHT = 'disabled_light';
const LIGHT_GRAY = 'light_gray';
const DARK_GRAY = 'dark_gray';
const DARK = 'dark';
const DARK_BLUE = 'dark_blue';
const COMPLETED = 'completed';
const ACTIVE = 'active';
const INFO = 'info';
const ERROR = 'error';
const WARNING = 'warning';
const H0 = 'H0';
const H1 = 'H1';
const H2 = 'H2';
const H3 = 'H3';
const H4 = 'H4';
const H5 = 'H5';
const H6 = 'H6';
const H7 = 'H7';
const TL0 = 'TL0';
const T0 = 'T0';
const T1 = 'T1';
const T2 = 'T2';
const T3 = 'T3';
const T4 = 'T4';
const T5 = 'T5';
const T6 = 'T6';

export const TEXT_COLOR = {
  NORMAL,
  HIGHLIGHTED,
  REVERSED,
  DISABLED,
  DISABLED_LIGHT,
  LIGHT_GRAY,
  DARK_GRAY,
  DARK,
  DARK_BLUE,
  INFO,
  COMPLETED,
  ACTIVE,
  ERROR,
  WARNING,
};
export const TEXT_SIZE = {
  H0,
  H1,
  H2,
  H3,
  H4,
  H5,
  H6,
  H7,
  TL0,
  T0,
  T1,
  T2,
  T3,
  T4,
  T5,
  T6
};
export const TEXT_WEIGHT = {
  NORMAL: 'normal',
  BOLD: 'bold',
  BOLDER: 'bolder',
};

// TODO: add formatMessage to the component
export const getColor = (theme, textColor) => {
  let color;
  switch (textColor) {
    case TEXT_COLOR.NORMAL:
      color = theme.palette.novotalk.$textColor;
      break;
    case TEXT_COLOR.HIGHLIGHTED:
      color = theme.palette.novotalk.$textColorHighlited;
      break;
    case TEXT_COLOR.REVERSED:
      color = theme.palette.novotalk.$textColorReversed;
      break;
    case TEXT_COLOR.DISABLED:
      color = theme.palette.novotalk.$textColorDisabled;
      break;
    case TEXT_COLOR.DISABLED_LIGHT:
      color = theme.palette.novotalk.$textColorDisabledLight;
      break;
    case TEXT_COLOR.LIGHT_GRAY:
      color = theme.palette.novotalk.$textColorLightGray;
      break;
    case TEXT_COLOR.DARK_GRAY:
      color = theme.palette.novotalk.$textColorDarkGray;
      break;
    case TEXT_COLOR.DARK:
      color = theme.palette.novotalk.$textColorDark;
      break;
    case TEXT_COLOR.DARK_BLUE:
      color = theme.palette.novotalk.$textColorDarkBlue;
      break;
    case TEXT_COLOR.COMPLETED:
      color = theme.palette.novotalk.$completedColor;
      break;
    case TEXT_COLOR.ACTIVE:
      color = theme.palette.novotalk.$activeColor;
      break;
    case TEXT_COLOR.ERROR:
      color = theme.palette.novotalk.$errorColor;
      break;
    case TEXT_COLOR.WARNING:
      color = theme.palette.novotalk.$warningColor;
      break;
    case TEXT_COLOR.INFO:
      color = theme.palette.novotalk.$novotalkBlue;
      break;
    default:
      return null;
  }
  return color;
};

const getSize = (size) => {
  let fontSize;
  switch (size) {
    case H0:
      fontSize = '26px';
      break;
    case H1:
      fontSize = '24px';
      break;
    case H2:
      fontSize = '20px';
      break;
    case H3:
      fontSize = '18px';
      break;
    case H4:
      fontSize = '14px';
      break;
    case H5:
      fontSize = '12px';
      break;
    case H6:
      fontSize = '10px';
      break;
    case H7:
      fontSize = '8px';
      break;
    case TL0:
      fontSize = '23px';
      break;
    case T0:
      fontSize = '21px';
      break;
    case T1:
      fontSize = '19px';
      break;
    case T2:
      fontSize = '17px';
      break;
    case T3:
      fontSize = '15px';
      break;
    case T4:
      fontSize = '13px';
      break;
    case T5:
      fontSize = '11px';
      break;
    case T6:
      fontSize = '25px';
      break;
    default:
      fontSize = '16px';
      break;
  }
  return fontSize;
};

export const TextSizeWrapper = styled('div')(
    ({theme}) => ({
      fontSize: 25,
          [theme.breakpoints.down(BIG_SCREEN_BREAKPOINT)]: {
        fontSize: 19
      }
    })
)

const Text = styled('span')(
  ({ theme, textColor, size, margin, hoverOn, display = 'inline', withEllipsis, textAlign }) => ({
    color: getColor(theme, textColor),
    fontSize: getSize(size),
    whiteSpace: withEllipsis ? 'pre' : 'pre-line',
    overflow: 'hidden',
    textOverflow: withEllipsis && 'ellipsis',
    textAlign: textAlign ?? 'inherit',
    margin,
    '&:hover': {
      color: hoverOn ? theme.palette.novotalk.borderColorDark : '',
    },
    display,
  }),

  ({ textTransform }) => {
    switch (textTransform) {
      case 'capitalizeFirst':
        return {
          display: 'inline-block',
          '&:first-letter': {
            textTransform: 'uppercase',
          },
        };
      default:
        return { textTransform };
    }
  },

  ({ weight }) => {
    if (!weight) {
      return {};
    } else if (
      weight >= 500 ||
      weight === TEXT_WEIGHT.BOLD ||
      weight === TEXT_WEIGHT.BOLDER
    ) {
      return {
        fontWeight: weight,
        letterSpacing: '0.3px',
      };
    }
    return { fontWeight: weight };
  },
);

export default Text;
