import React from 'react';
import PropTypes from 'prop-types';
import { getFlexibleSize } from '@utils/cssHelpers';
import ResourcesList from '@shared/Resources/shared/components/ResourcesList';
import { getDateDisplay } from '@shared/Resources/shared/components/ListWrappers';
import { listInnerEntityNameHelper } from '@shared/Resources/helpers';
import {useTranslation} from "react-i18next";

const PracticeList = (props) => {
  const {t} = useTranslation()
  const tableConf = [
    {
      getDisplay: (item) => listInnerEntityNameHelper(item, t),
      name: t('Common.UI.name'),
      textOptions: {
        size: 'H4',
        color: 'DARK_BLUE',
        withEllipsis: true,
      },
    },
    {
      getDisplay: (item) => getDateDisplay(item.entityCreated),
      name: t('Common.UI.created'),
      textOptions: {
        withEllipsis: true,
      },
      width: getFlexibleSize(95, 140),
    },
    {
      getDisplay: (item) => getDateDisplay(item.entityUpdated),
      name: t('Common.UI.modified'),
      textOptions: {
        withEllipsis: true,
      },
      width: getFlexibleSize(95, 120),
    },
    {
      getDisplay: (item) => item.isTemplate ? t('Common.UI.template')  : `${t('Common.UI.ownBy')} ${item.owner?.fullName}`,
      name: t('Common.UI.type'),
      textOptions: {
        withEllipsis: true,
      },
      width: getFlexibleSize(95, 120),
    },
  ];
  return <ResourcesList {...props} tableConf={tableConf} />;
};

export default PracticeList;
