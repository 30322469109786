import { Component, createRef } from 'react';
import PropTypes from 'prop-types';
import { PATTERN_TYPES } from '@shared/Resources/Monitor/types';
import {
  CanvasContainer, CanvasElement, PatternImage, WavesSvg,
} from './styles';
import { plotBufferToCanvasOldStyle } from './services/PlotHandlerService';
import { GRAPH_SIZE } from '../../../../constants';
import TwoSecondsOutline from './TwoSecondsOutline.svg';
import { WAVES_OUTLINE } from './pattern-outlines';
import { ONE_SECOND_OUTLINES } from './one-second-patterns';
import { PauseButton } from '../../../../../../../../components/PauseButton';

const CANVAS_ID_PREFIX = 'canvasAmplitude';
const images = {
  ONE_SECOND: null,
  TWO_SECONDS: TwoSecondsOutline,
  GENTLE_VOICE: null,
  LOUD_VOICE: null,
  VOICE_TRANSITION: null,
};

class Canvas extends Component {
  constructor(props) {
    super(props);
    const { buffer, monitorNumber, patternType } = props;
    const canvasID = `${CANVAS_ID_PREFIX}${monitorNumber}`;
    this.widthCanvas = createRef();
    this.state = {
      buffer,
      canvasID,
      patternType,
    };
  }

  componentDidMount() {
    this.plotBuffer();
  }

  componentDidUpdate() {
    this.plotBuffer();
  }

  plotBuffer = () => {
    const { buffer, stepSize } = this.props;
    const { canvasID, patternType } = this.state;
    const { image } = GRAPH_SIZE[patternType];
    const showImage = image ? images[image] !== null : false;
    const canvas = document.getElementById(canvasID);

    if (canvas) {
      const ctx = canvas.getContext('2d');
      if (ctx && buffer) {
        plotBufferToCanvasOldStyle(
          ctx,
          buffer,
          patternType,
          showImage,
          this.widthCanvas.current?.offsetWidth,
          stepSize,
        );
      }
    }
  };

  renderPattern = (patternType, duration, url) => {
    switch (patternType) {
      case PATTERN_TYPES.WAVES:
        return <WavesSvg>{WAVES_OUTLINE[duration]}</WavesSvg>;
      case PATTERN_TYPES.ONE_SECOND:
        return <WavesSvg>{ONE_SECOND_OUTLINES[duration]}</WavesSvg>;
      default:
        return this.renderImage(url);
    }
  };

  renderImage = (imageURL) => (imageURL
    ? <PatternImage id="PatternImage" src={imageURL} />
    : null);

  render() {
    const { patternType, widthMonitor, duration } = this.props;
    const { canvasID } = this.state;
    const { height, background, image } = GRAPH_SIZE[patternType];
    const imageURL = images[image];
    return (
      <CanvasContainer
        ref={this.widthCanvas}
        width={widthMonitor}
        background={background}
        id="monitorCanvas"
      >
        <CanvasElement
          id={canvasID}
          width={widthMonitor ?? '100%'}
          height={height}
        />
        <PauseButton />
        {this.renderPattern(patternType, duration, imageURL)}
      </CanvasContainer>
    );
  }
}
Canvas.propTypes = {
  buffer: PropTypes.array,
  patternType: PropTypes.string,
  monitorNumber: PropTypes.number,
  widthMonitor: PropTypes.number,
  duration: PropTypes.number,
};

export default Canvas;
