import {styled} from "@mui/material";
import { getIconsByType, iconTypes } from '@services/icons';
import CTAButton from '@components/CTAButton';
import { getResponsiveCSS } from '@utils/cssHelpers';

export const Wrapper = styled('div')(
  {
    gridArea: 'TrialBar',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    margin: '20px 50px -10px',
    paddingLeft: 20,
    borderRadius: 4,
    border: '1px solid',
  },
  getResponsiveCSS('paddingRight', 20, 28),
  ({ theme }) => ({
    height: theme.palette.novotalk.trialBarHeight - 10,
    backgroundColor: theme.palette.novotalk.$trialBackgroundColor,
    borderColor: theme.palette.novotalk.$trialBorderColor,
  })
);

const InfoIcon = getIconsByType(iconTypes.ERROR);

export const StyledInfoIcon = styled(InfoIcon)(
  {
    fontSize: 22,
    minWidth: 22,
    marginRight: 12,
  },
  ({ theme }) => ({
    color: theme.palette.novotalk.$textColorDarkBlue,
  })
);

export const StyledCTAButton = styled(CTAButton)({
  width: 140,
  height: 40,
  marginLeft: 20,
});
