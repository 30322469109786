import {styled} from "@mui/material";

export const HideTags = styled('div')({
  '> div > div': {
    opacity: 0,
    height: 0,
    padding: 0,
    display: 'none'
  },
});

export const SelectValue = styled('div')({
  fontSize: 14,
});
