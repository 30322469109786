import { put } from 'redux-saga/effects';
import _ from 'lodash';
import { errors } from './errors';
import { loginAfterUnauthorizedConnection } from './actions';
import { logOut, refreshToken } from '@containers/App/actions';
import { onDataError } from '@containers/Admin/shared/ResourcesWrapper/actions';

/**
 * Function gets an error and returns an action
 * @param {*} error
 */
export const handleRequestErrors = (error) => {
  // normal connection error
  if (_.get(error, ['networkError', 'statusCode']) === 401) {
    throw new Error(errors.UNAUTHORIZED_CONNECTION_INVALID_TOKEN);
  } else if (_.get(error, ['networkError', 'statusCode']) >= 400) {
    throw new Error(errors.CONNECTION_ERROR);
  } else if (error.toString().includes('TokenExpiredError')) {
    throw new Error(errors.TOKEN_EXPIRED);
  } else if (error.toString().includes('JsonWebTokenError')) {
    throw new Error(errors.WRONG_REFRESH_TOKEN);
  } else if (error.toString().includes('Cannot read properties')) {
    throw new Error(errors.WRONG_DATA);
  }
};

function getActionsFromError(error) {
  let returnedActions;
  switch (error.message) {
    case errors.UNAUTHORIZED_CONNECTION_INVALID_TOKEN:
      returnedActions = [
        loginAfterUnauthorizedConnection(error.message),
        logOut(),
      ];
      break;
    case errors.TOKEN_EXPIRED:
      returnedActions = [refreshToken()];
      break;
    case errors.WRONG_DATA:
      returnedActions = [onDataError()];
      break;
    case errors.WRONG_REFRESH_TOKEN:
      returnedActions = [logOut()];
      break;
    default:
      console.error(error);
  }
  return returnedActions;
}

export function* authenticationWrapper(
  codeBlockGenerator,
  errorHandlerGenerator
) {
  let returnedValue = null;
  try {
    returnedValue = yield codeBlockGenerator();
  } catch (error) {
    const authenticationErrorActions = getActionsFromError(error);
    if (authenticationErrorActions) {
      for (
        let index = 0;
        index < authenticationErrorActions.length;
        index += 1
      ) {
        yield put(authenticationErrorActions[index]);
      }
    } else if (errorHandlerGenerator) {
      yield errorHandlerGenerator();
    }
  }
  return returnedValue;
}
