import {styled} from "@mui/material";
import { getIconsByType, iconTypes } from '@services/icons';

const IncreaseRangeIcon = getIconsByType(iconTypes.FILLED_ARROW_UP);
const DecreaseRangeIcon = getIconsByType(iconTypes.FILLED_ARROW_DOWN);

export const CustomWrapper = styled('div')({
  display: 'flex',
  flexDirection: 'row',
});

export const CustomSectionWrapper = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-evenly',
  width: '50%',
  padding: '10px',
});

export const CustomPickerWrapper = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
  width: '50%',
  padding: '50px',
  alignItems: 'center',
});

export const CustomRangeInput = styled('input')({
  border: '1px solid #979797',
  borderRadius: '3px',
  width: '45px',
  height: '30px',
  textAlign: 'center',
});

export const arrowStyle = { fontSize: '35px', cursor: 'pointer' };

export const StyledIncreaseRangeIcon = styled(IncreaseRangeIcon)(arrowStyle);
export const StyledDecreaseRangeIcon = styled(DecreaseRangeIcon)(arrowStyle);

export const TextButtonWrapper = styled('div')({
  padding: '10px 20px',
});
