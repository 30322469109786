/**
*
* ResourceOverviewPopup
*
*/

import React from 'react';
import PropTypes from 'prop-types';
import withResources from '../../../../../Admin/shared/ResourcesWrapper';
import SectionLoading from '@components/SectionLoading';
import Text from '@components/Text';
import ResourceOverview from '@shared/ResourceOverview';
import {
  Wrapper,
  SelectButton,
  ItemButton,
  HeaderWrapper,
  ResourcesList,
  OverviewSection,
  ResourceItem,
  OverviewWrapper,
  SelectButtonWrapper,
  VirtualizedResourcesList
} from './styles';
import {connect} from "react-redux";
import {compose} from "redux";
import {
  clearResourcesData,
  getResources,
  resetOffset,
  setSearchText
} from "@containers/Admin/shared/ResourcesWrapper/actions";
import { withTranslation } from 'react-i18next';
import {Search} from './Search'
const SOURCE = 'popup';
import {debounce} from "lodash";

class ResourceSelectionPopup extends React.PureComponent {
  componentDidMount() {
    const { dispatchLoadFilteredResources, resourceType, searchText } = this.props;
    dispatchLoadFilteredResources(resourceType, searchText);
  }

  componentDidUpdate(prevProps, prevState) {
    const {searchText, dispatchClearResourcesData} = this.props
    if(prevProps.searchText.length > searchText.length) {
      dispatchClearResourcesData()
    }
    if(prevProps.searchText !== searchText) {
      this.searchWithDebounce()
    }
  }

  componentWillUnmount() {
    const {dispatchClearResourcesData, dispatchResetOffset} = this.props
    dispatchClearResourcesData()
  }

  onResourceSelected = ({ id }) => () => {
    const { dispatchLoadItem, resourceType } = this.props;
    dispatchLoadItem(resourceType, id, SOURCE);
  }

  onSelect = () => {
    const { popup: { resource } } = this.props;
    this.props.onItemSelected(resource);
    this.props.onClose();
  }

  searchWithDebounce = debounce( async () => {
    this.props.dispatchResetOffset()
    this.props.dispatchLoadFilteredResources(this.props.resourceType, this.props.searchText)
  }, 300)

  handleInput = (e) => {
    this.props.dispatchSetSearchText(e.target.value)
  }

  renderNoResourceSelected = () => (<Text className="noOverview">
    {this.props.t('Informative.Negative.noResource')}
  </Text>)

  renderResource = (resource) => {
    return resource?.isTemplate&&(
    <ResourceItem className="resourceItem" key={resource.id}>
      <Text className="name">{resource.name || resource.title}</Text>
      <ItemButton className="selectResourceItem" onClick={this.onResourceSelected(resource)} >
        {this.props.t('Actions.Instance.select')}
      </ItemButton>
    </ResourceItem>)}

  renderLoadingPopup = () => (<Wrapper>
    <div className="loadingResources">
      <Text>
        {this.props.t('Informative.Statuses.loadingResources')}
      </Text>
      <SectionLoading />
    </div>
  </Wrapper>)

  renderOverviewSection = (loading, resource) => {
    const { onClose, onEdit, onExpand, resourceType } = this.props;
    return (
      <React.Fragment>
        <OverviewWrapper>
          <ResourceOverview
            className="overview"
            readOnly
            disableEdit
            loading={loading}
            onEdit={(...args) => {
              onClose();
              onEdit(...args);
            }}
            onExpand={onExpand}
            type={resourceType}
            resource={resource}
          />
        </OverviewWrapper>
        <SelectButtonWrapper>
          <SelectButton className="selectResource" onClick={this.onSelect}>
            {this.props.t('Actions.Instance.select')}
          </SelectButton>
        </SelectButtonWrapper>
      </React.Fragment>
    );
  }

  render() {
    const { items, searchText, popup, resourceType, dispatchLoadFilteredResources } = this.props;
    if (!popup) return this.renderLoadingPopup();
    const { loading, resource } = popup;
    return (
      <Wrapper>
        <HeaderWrapper>
          <Text size="H1" className="headerText">
            {this.props.t('Actions.Multiple.selectResource', {resourceType: resourceType})}
          </Text>
          <Search valueText={searchText} setValueText={this.handleInput} />
        </HeaderWrapper>
        <ResourcesList className="resourcesList">
          <VirtualizedResourcesList
              data={items}
              endReached={(lastItemIndex) => {
                const {type, isLastPage, offset} = this.props.resource
                !isLastPage && lastItemIndex + 1 === offset && dispatchLoadFilteredResources(type, this.props.searchText, 'scroll')
              }}
              itemContent={(_, item) => this.renderResource(item)}
          />
        </ResourcesList>
        <OverviewSection className="overviewSection">
          {
            ((!loading && !resource) || (resource && resource.type !== resourceType))
              ? this.renderNoResourceSelected()
              : this.renderOverviewSection(loading, resource)
          }
        </OverviewSection>
      </Wrapper>
    );
  }
}

ResourceSelectionPopup.propTypes = {
  resourceType: PropTypes.string, // gets from popup object
  onItemSelected: PropTypes.func, // gets from popup object
  searchText: PropTypes.string,
  items: PropTypes.array,

  popup: PropTypes.shape({
    loading: PropTypes.bool,
    resource: PropTypes.object,
  }),
  loadingResources: PropTypes.bool,
  resources: PropTypes.array,
  onEdit: PropTypes.func,
  onClose: PropTypes.func,
  onExpand: PropTypes.func,
  dispatchClearResourcesData: PropTypes.func,
  dispatchLoadItem: PropTypes.func,
  dispatchLoadFilteredResources: PropTypes.func,
  dispatchSetSearchText: PropTypes.func,
  dispatchResetOffset: PropTypes.func
};

const mapStateToProps = state => {
  const searchText = state.toJS()?.resourceManager.searchText //.getIn(['resourceManager', 'searchText'])
  const items = state.toJS()?.resourceManager.resources //.getIn(['resourceManager', 'resources'])

  return {
    searchText,
    items,
    resource: state.toJS()?.resourceManager
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    dispatchClearResourcesData: () => dispatch(clearResourcesData()),
    dispatchLoadFilteredResources: (type, search, loadType) => dispatch(getResources(type, search, loadType)),
    dispatchSetSearchText: (text) => dispatch(setSearchText(text)),
    dispatchResetOffset: () => dispatch(resetOffset())
  }
}

const withConnect = connect(mapStateToProps, mapDispatchToProps)

export default compose(
    withResources,
    withConnect,
    withTranslation()
)(ResourceSelectionPopup)