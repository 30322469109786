import {
  call, put, takeLatest, all, fork, join, takeLeading, takeEvery, select,
} from 'redux-saga/effects';
import { authenticationWrapper } from '@services/errorHandlingService';
// import { courseMock } from './__fixtures__/courseMock';
// import { courseMock as cypressMock } from './__fixtures__/courseMockCypress';
// import { hebrewCourseMock } from './__fixtures__/courseMockHebrew';
import ChatService from '@services/chatService';
import { visitAll } from '@containers/Admin/containers/ActivityPage/services';
import { closePopUp } from '@containers/App/containers/PopUp/actions';
import { markExerciseAsCompleted } from '@containers/Admin/containers/Builder/service/exercise';
import { markLessonAsCompleted } from '@containers/Admin/containers/Builder/service/lesson';
import { markPracticeAsCompleted } from '@containers/Admin/containers/Builder/service/practice';
import { getCurrentPractice } from '@containers/User/containers/CourseRouter/containers/Practice/actions';
import {
  markFeedbackAsSeenFailed, markFeedbackAsSeenSucceed, setCourses, TYPES, updateActiveLogOpened,
} from './actions';
import { callActivityLog, getEntityCourses } from './services';
import { LOG_STATUS } from './consts';
import { resetActivityLesson } from './containers/Lesson/actions';

const useFixtures = process.env.USE_FIXTURES;

export function* callGetCourses() {
  let mock;
  yield authenticationWrapper(function* codeBlock() {
    const res = yield fork(getEntityCourses);
    const courses = useFixtures ? mock : yield join(res);
    yield put(setCourses(courses));
  });
}

function* callMarkFeedback(action) {
  try {
    const { postType, ids } = action;
    const res = yield call(visitAll, postType, ids);
    if (res) {
      yield put(markFeedbackAsSeenSucceed());
      yield put(closePopUp());
    } else {
      yield put(markFeedbackAsSeenFailed());
    }
  } catch (e) {
    yield put(markFeedbackAsSeenFailed());
  }
}

export function* callDisconnectFromMirrorSession() {
  yield fork(ChatService.disconnectFromMirrorSession);
}

function* callMarkExerciseAsCompleted(action) {
  try {
    const exerciseId = action.exercise?.id;
    yield call(markExerciseAsCompleted, exerciseId);
    yield put(getCurrentPractice(action.practice?.id));
    if (action.isLast) {
      const practiceId = action.practice?.id;
      yield call(markPracticeAsCompleted, practiceId);
    }
  } catch (e) {
    throw new Error(e.message);
  }
}

function* callMarkLessonAsCompleted(action) {
  try {
    const { id } = action.lesson;
    yield call(markLessonAsCompleted, id);
  } catch (e) {
    throw new Error(e.message);
  }
}
function* callUpdateActivityLog({
  id, typeEntity, status, skillName,
}) {
  try {
    let activityId = '';

    if (typeEntity[0] === 'LESSON') {
      const state = yield select((st) => st.get('currentLesson').toJS());

      const idEntity = state?.currentLessonActivity?.id;
      if (idEntity) {
        activityId = idEntity;
        yield put(resetActivityLesson());
      }
    } else if (typeEntity[0] === 'PRACTICE') {
      const state = yield select((st) => st.get('currentPractice').toJS());
      const idEntity = state?.currentPracticeActivity?.id;

      if (idEntity) {
        activityId = idEntity;
      }
    }

    let openLogId = '';
    if (status === LOG_STATUS.MOVE_BETWEEN) {
      yield call(callActivityLog, id[0], typeEntity[0], LOG_STATUS.CLOSE, null, activityId);
      openLogId = yield call(callActivityLog, id[1], typeEntity[1], LOG_STATUS.OPEN, skillName, activityId);
      yield put(updateActiveLogOpened(openLogId));
    } else {
      openLogId = yield call(callActivityLog, id[0], typeEntity[0], status, skillName, activityId);
      if (status === LOG_STATUS.OPEN) {
        yield put(updateActiveLogOpened(openLogId));
      }
    }
  } catch (e) {
    throw new Error(e.message);
  }
}

function* watchCallGetCourses() {
  yield takeLatest(TYPES.GET_COURSES, callGetCourses);
}
function* watchCallMarkFeedback() {
  yield takeLatest(TYPES.MARK_FEEDBACK_AS_SEEN, callMarkFeedback);
}
function* watchCallMarkExerciseAsCompleted() {
  yield takeLeading(TYPES.MARK_EXERCISE_AS_COMPLETED, callMarkExerciseAsCompleted);
}
function* watchCallMarkLessonAsCompleted() {
  yield takeLeading(TYPES.MARK_LESSON_AS_COMPLETED, callMarkLessonAsCompleted);
}
function* watchCallUpdateActivityLog() {
  yield takeEvery(TYPES.UPDATE_ACTIVITY_LOG, callUpdateActivityLog);
}
export default function* courseSaga() {
  yield all([fork(watchCallGetCourses), fork(watchCallMarkFeedback), fork(watchCallMarkExerciseAsCompleted), fork(watchCallMarkLessonAsCompleted), fork(watchCallUpdateActivityLog)]);
}
