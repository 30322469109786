import {styled} from "@mui/material";
import CTAButton from '@components/CTAButton';
import { getResponsiveCSS } from '@utils/cssHelpers';

export const Wrapper = styled('div')(
  {
    padding: '30px 50px',
    textAlign: 'center',
    borderRadius: 4,
  },
  getResponsiveCSS('width', 580, 630),
  ({ theme }) => ({
    backgroundColor: theme.palette.novotalk.$backgroundColor,
    direction: theme.RTL ? 'rtl' : 'inherit',
  })
);

export const Header = styled('div')(getResponsiveCSS('marginBottom', 20, 30));

export const SubHeader = styled('div')({
  marginBottom: 10,
});

export const MainText = styled('div')({
  width: 400,
  margin: '0 auto 20px',
  lineHeight: '21px',
});

export const ButtonsContainer = styled('div')({
  display: 'flex',
  justifyContent: 'center',
});

export const StyledCTAButton = styled(CTAButton)(
  {
    width: 180,
    margin: '0 6px',
  },
  getResponsiveCSS('height', 44, 48)
);
