import { call, put, takeLatest, all, fork, join } from 'redux-saga/effects';
import { authenticationWrapper } from '@services/errorHandlingService';
import {
  getCurrentPoll,
  savePoll,
} from '@containers/User/containers/CourseRouter/services';
import { TYPES } from './actions';
import { setCurrentPoll } from '@containers/User/containers/CourseRouter/containers/Poll/actions';
import poll from './__fixtures__/poll';
import hebrewPoll from './__fixtures__/pollHebrew';

const useFixtures = process.env.USE_FIXTURES;

const getPoll = (hebrewMock) => (hebrewMock ? hebrewPoll : poll);

export function* callGetChatSummaryPoll({ hebrewMock }) {
  yield authenticationWrapper(function* codeBlock() {
    const res = yield fork(getCurrentPoll);
    const currentPoll =
      true || useFixtures ? getPoll(hebrewMock) : yield join(res);
    yield put(setCurrentPoll(currentPoll));
  });
}

function* watchCallGetChatSummaryPoll() {
  yield takeLatest(TYPES.GET_CHAT_SUMMARY_POLL, callGetChatSummaryPoll);
}

export default function* videoChatSummarySaga() {
  yield all([fork(watchCallGetChatSummaryPoll)]);
}
