import { graphMutation} from '@services/commService';
export const deleteEntity = async(id)=>{
  
    const mutation = `mutation{
      deleteMedia(
        id:"${id}"
      ){
        title
      }
  
    }`
    
    return await graphMutation(mutation,{}, true);
  }