import React, { Component } from 'react';
import PropTypes from 'prop-types';

class ClickAwayListener extends Component {
  constructor(props) {
    super(props);
    this.wrapperRef = React.createRef();
  }

  componentDidMount() {
    document.addEventListener('click', this.handleClickOutside);
  }

  componentWillUnmount() {
    document.removeEventListener('click', this.handleClickOutside);
  }

  handleClickOutside = (event) => {
    const { onClickAway, isDisabled } = this.props;
    if (isDisabled) return;
    if (this.wrapperRef && !this.wrapperRef.current.contains(event.target)) {
      onClickAway();
    } else if (this.wrapperRef.current === event.target) {
      event.stopPropagation();
    }
  };

  render() {
    const { children } = this.props;
    return <div ref={this.wrapperRef}>{children}</div>;
  }
}

ClickAwayListener.propTypes = {
  onClickAway: PropTypes.func,
  children: PropTypes.element.isRequired,
  isDisabled: PropTypes.bool,
};

export default ClickAwayListener;
