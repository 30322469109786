import {styled} from "@mui/material";
import isPropValid from '@emotion/is-prop-valid';
import { getIconsByType, iconTypes } from '@services/icons';
import { Link } from 'react-router-dom';

import LocaleToggle from '@containers/LocaleToggle';
import { keyframes } from '@emotion/core';

const MessageIcon = getIconsByType(iconTypes.MESSAGE);
const NotificationIcon = getIconsByType(iconTypes.NOTIFICATION);
const HelpIcon = getIconsByType(iconTypes.HELP_FILLED);
const SettingsIcon = getIconsByType(iconTypes.SETTINGS);
const LogOutIcon = getIconsByType(iconTypes.LOGOUT);
const ChevronDown = getIconsByType(iconTypes.CHEVRON_DOWN);
const ChevronUp = getIconsByType(iconTypes.CHEVRON_UP);
const RolesManagement = getIconsByType(iconTypes.ROLES_MANAGEMENT);
const Resources = getIconsByType(iconTypes.RESOURCES);
const RecordIcon = getIconsByType(iconTypes.RADIO_ON);
const Users = getIconsByType(iconTypes.USER_RECORDS);
const MentorDashboard = getIconsByType(iconTypes.MENTOR_DASHBOARD);

const commonMenuIconStyles = [
  {
    fontSize: 20,
  },
  ({ theme }) => ({
    color: theme.palette.novotalk.$textColorDisabledLight,
    marginLeft: theme.RTL ? 12 : 0,
    marginRight: theme.RTL ? 12 : 6,
  })
];

const iconStyles = [
  {
    fontSize: 24,
    margin: 'auto',
  },
  ({ theme }) => ({
    color: theme.palette.novotalk.$textColorReversed,
  }),
];

const chevronStyles = [
  {
    fontSize: 16,
  },
  ({ theme }) => ({
    color: theme.palette.novotalk.$textColorReversed,
  }),
];

const notificationStyles = [
  {
    position: 'relative',
    '&:after': {
      position: 'absolute',
      height: 14,
      width: 14,
      borderRadius: '50%',
      top: 3,
      right: 1,
      fontSize: 10,
      textAlign: 'center',
    },
  },
  ({ theme, newItems }) => ({
    '&:after': {
      display: newItems ? 'block' : 'none',
      content: `"${newItems}"`,
      color: theme.palette.novotalk.$textColorReversed,
      backgroundColor: theme.palette.novotalk.$navigationNotificationColor,
    },
  }),
];

const iconWrapperStyles = [
  {
    display: 'flex',
    width: 36,
    height: 36,
    borderRadius: 18,
    cursor: 'pointer',
  },
  ({ theme, isActive }) => ({
    '&:hover': {
      backgroundColor: isActive ? 'none' : theme.palette.novotalk.$navigationIconColorHover,
    },
    '&:active': {
      backgroundColor: isActive ? 'none' : theme.palette.novotalk.$navigationIconColorActive,
    },
  }),
];

const recordIconAnimation = keyframes`
    0% {
      opacity: 1;
    }
    100% {
      opacity: .1;
    }
`;

export const StyledMessageIcon = styled(MessageIcon, { shouldForwardProp: isPropValid })(
  ...iconStyles,
  ({ theme, isActive }) => ({
    color: isActive ? theme.palette.novotalk.$navigationHelpIconColor : theme.palette.novotalk.$textColorReversed,
  })
);

export const StyledNotificationIcon = styled(NotificationIcon)(
  ...iconStyles
);

export const StyledHelpIcon = styled(HelpIcon)(
  {
    fontSize: 25,
    cursor: 'pointer',
    margin: 'auto',
  },
  ({ theme }) => ({
    color: theme.palette.novotalk.$navigationHelpIconColor,
  })
);

export const MessageIconWrapper = styled('div')(
  ...iconWrapperStyles,
  ...notificationStyles,
  {
    marginRight: 4,
  }
);

export const NotificationIconWrapper = styled('div')(
  ...iconWrapperStyles,
  ...notificationStyles,
  {
    marginRight: 12,
    '&:after': {
      right: 7,
    },
  }
);

export const HelpMenuWrapper = styled('div')(
  ...iconWrapperStyles,
  {
    marginRight: 14,
    position: 'relative',
    '& .navigation-menu': {
      top: 45,
    },
  }
);

export const StyledHelpMenuContactIcon = styled(MessageIcon)(
  ...commonMenuIconStyles,
);

export const StyledSettingsIcon = styled(SettingsIcon)(
  ...commonMenuIconStyles,
);

export const StyledLogOutIcon = styled(LogOutIcon)(
  ...commonMenuIconStyles,
);

export const StyledRolesManagementIcon = styled(RolesManagement)(
  ...commonMenuIconStyles,
);

export const StyledResourcesIcon = styled(Resources)(
  ...commonMenuIconStyles,
);

export const StyledUsersIcon = styled(Users)(
  ...commonMenuIconStyles,
);

export const StyledMentorDashboard = styled(MentorDashboard)(
  ...commonMenuIconStyles,
);

export const StyledRecordIcon = styled(RecordIcon)(
  {
    fontSize: 25,
    animation: `${recordIconAnimation} 1s ease-out infinite`,
  },
  ({ theme }) => ({
    color: theme.palette.novotalk.$navigationNotificationColor,
  })
);

export const StyledChevronDown = styled(ChevronDown)(
  ...chevronStyles,
);

export const StyledChevronUp = styled(ChevronUp)(
  ...chevronStyles,
);

export const Wrapper = styled('div')(
  {
    display: 'flex',
    padding: '6px 10px 6px 18px',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  ({ theme }) => ({
    height: theme.palette.novotalk.navigationBarHeight,
    backgroundColor: theme.palette.novotalk.$navigationBarColor,
  })
);

export const Logo = styled('img')({
  width: 128,
  cursor: 'pointer',
});

export const StyledExerciseAreaTab = styled(Link)(
  {
    marginRight: 42,
    textDecoration: 'none',
    '&:hover, &:active': {
      textDecoration: 'underline',
    },
  },
  ({ theme }) => ({
    '&:hover, &:active': {
      textDecorationColor: theme.palette.novotalk.$textColorReversed,
    },
  })
);

export const StyledLocaleToggle = styled(LocaleToggle)({},
  ({ theme }) => ({
    color: theme.palette.novotalk.textColorReversed,
  })
);

export const UserPanelWrapper = styled('div')({
  position: 'relative',
});

export const UserInfoContainer = styled('div')(
  {
    display: 'flex',
    alignItems: 'center',
    padding: '6px 8px',
    borderRadius: 4,
    cursor: 'pointer',
  },
  ({ theme }) => ({
    '&:hover': {
      backgroundColor: theme.palette.novotalk.$navigationIconColorHover,
    },
    '&:active': {
      backgroundColor: theme.palette.novotalk.$navigationIconColorActive,
    },
  })
);

export const StyledAvatar = styled('div')(
  {
    maxWidth: 36,
    minWidth: 36,
    height: 36,
    marginRight: 8,
    borderRadius: 18,
  },
  ({ img }) => ({
    background: `center / cover no-repeat url(${img})`,
  })
);

export const UserMenuContainer = styled('div')(
  {
    position: 'absolute',
    width: 170,
    top: 52,
    left: -8,
    borderRadius: 4,
    zIndex: 200,
    overflow: 'hidden',
  },
  ({ theme }) => ({
    direction: theme.RTL ? 'rtl' : 'initial',
    backgroundColor: theme.palette.novotalk.$backgroundColor,
    boxShadow: `0 1px 16px 0 ${theme.palette.novotalk.$navigationMenuColorShadow}`,
  })
);

export const StyledMenuItem = styled('div')(
  {
    height: 40,
    display: 'flex',
    alignItems: 'center',
    padding: '0 7px',
    cursor: 'pointer',
  },
  ({ theme }) => ({
    '&:hover': {
      backgroundColor: theme.palette.novotalk.$navigationMenuColorHover,
    },
    '&:active > span, &:active > svg': {
      color: theme.palette.novotalk.$activeColor,
    },
  })
);
