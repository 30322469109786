import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import CheckBoxGroup from '../../shared/components/CheckBoxGroup';
import {
  STIMULUS_SPEECH_TOPICS_TYPES,
} from '../../../../../../../types';


const speechTopicsTypes = _.values(STIMULUS_SPEECH_TOPICS_TYPES);

const SyllablesConfig = ({ config, updateConfig }) => (
  <CheckBoxGroup
    config={config}
    onChange={updateConfig}
    groupName="speechTopicsType"
    groupValues={speechTopicsTypes}
  />
);

SyllablesConfig.propTypes = {
  config: PropTypes.object,
  updateConfig: PropTypes.func,
};

export default SyllablesConfig;
