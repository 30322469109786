import { Component } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import Text, { TEXT_COLOR } from '@components/Text';
import PlanPane from '../PlanPane';
import {
  Wrapper,
  PlanWrapper,
  OverviewWrapper,
  StyledPlanIcon,
  StyledCloseIcon,
  SkillWrapper,
  StyledBackIcon,
} from './styles';
import {sliceText} from "@utils/textHelpers";
import {withTranslation} from "react-i18next";

const maxNameLength = 70

class SkillPartHeader extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showPlan: false,
    };
  }

  render() {
    const {t, i18n} = this.props
    const { name } = this.props.skill;
    const { showPlan } = this.state;
    const planToggleIcon = showPlan ? <StyledCloseIcon /> : <StyledPlanIcon />;
    return (
      <Wrapper language={i18n.language} id='skillPartHeader'>
        <OverviewWrapper to='/course'>
          <StyledBackIcon language={i18n.language} />
          <Text size='T3' textColor={TEXT_COLOR.DISABLED_LIGHT}>
              {t('UI.courseOverview')}
          </Text>
        </OverviewWrapper>
        <PlanWrapper
          id='planButton'
          onClick={() => this.setState({ showPlan: !showPlan })}
          clicked={showPlan}
        >
          {planToggleIcon}
          <Text
            size='T3'
            textColor={showPlan ? TEXT_COLOR.ACTIVE : TEXT_COLOR.DISABLED_LIGHT}
          >
              {t('Common.Billing.plan')}
          </Text>
        </PlanWrapper>
        <PlanPane
          skill={this.props.skill}
          display={showPlan}
          close={() => this.setState({ showPlan: false })}
        />
        <SkillWrapper>
          <Text size='T3' textColor={TEXT_COLOR.DISABLED_LIGHT}>
              {t('Common.UI.singleSkill')}
          </Text>
          <Text
            size='T3'
            textColor={TEXT_COLOR.ACTIVE}
            textTransform='uppercase'
            margin='0 10px'
            weight={this.props.i18n.language === 'he' ? 'bold' : 'normal'}
          >
              {sliceText(name, maxNameLength, true)}
          </Text>
          {/* <ProgressBar progress={progress} height={7} /> */}
          {/* <Text size="H4" textColor={TEXT_COLOR.DARK_BLUE}>{progress}%</Text> */}
        </SkillWrapper>
      </Wrapper>
    );
  }
}

SkillPartHeader.propTypes = {
  skill: PropTypes.object,
};


export default compose(
    withTranslation()
)(SkillPartHeader);
