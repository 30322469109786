import {styled} from "@mui/material";
import { getIconsByType, iconTypes } from '@services/icons';
import {theme} from '@services/theme'

const iconStyles = ({ theme }) => ({
  fontSize: 21,
  color: theme.palette.novotalk.$textColorDisabled,
});

export const StyledDropdownUpIcon = styled(getIconsByType(iconTypes.FILLED_ARROW_UP))(
  iconStyles
);

export const StyledDropdownDownIcon = styled(
  getIconsByType(iconTypes.FILLED_ARROW_DOWN)
)(iconStyles);

export const getSelectStyles = (width) => ({
  indicatorsContainer: (provided) => ({
    ...provided,
    marginRight: 7,
  }),
  indicatorSeparator: () => {
    'none';
  },
  clearIndicator: () => ({
    display: 'none',
  }),
  singleValue: (provided) => ({
    ...provided,
    fontSize: '14px',
    display: 'inline-flex',
    alignItems: 'center',
    color: theme.palette.novotalk.$textColorDarkBlue,
  }),
  option: (provided, state) => ({
    ...provided,
    height: 44,
    paddingLeft: 15,
    display: 'flex',
    alignItems: 'center',
    fontSize: 14,
    color: theme.palette.novotalk.$textColorDarkBlue,
    backgroundColor: state.isSelected
      ? theme.palette.novotalk.$dropdownBackgroundColorSelected
      : theme.palette.novotalk.$backgroundColor,
    '&:hover': {
      backgroundColor: state.isSelected
        ? theme.palette.novotalk.$dropdownBackgroundColorSelected
        : theme.palette.novotalk.$dropdownBackgroundColorHover,
    },
  }),
  control: (provided) => ({
    ...provided,
    width,
    minHeight: 44,
    paddingLeft: 5,
    boxShadow: 'none',
    borderColor: theme.palette.novotalk.$inputBorderColor,
    '&:hover': {
      borderColor: theme.palette.novotalk.$inputBorderColorHover,
    },
  }),
  menu: (provided) => ({
    ...provided,
    width: width - 2,
    margin: '8px 1px 0',
  }),
  menuList: (provided) => ({
    ...provided,
    paddingTop: 0,
    paddingBottom: 0,
  }),
});
