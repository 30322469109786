import {styled} from "@mui/material";
import CTAButton from '@components/CTAButton';

export const ClosedArea = styled('div')(
  {
    gridArea: 'message',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  ({ theme }) => ({
    borderTop: `1px solid ${theme.palette.novotalk.$borderColorLight}`,
    backgroundColor: theme.palette.novotalk.$messagingClosedBackgroundColor,
  })
);

export const InputArea = styled('div')(
  {
    gridArea: 'message',
    width: '100%',
    padding: '15px 20px',
    display: 'flex',
    justifyContent: 'stretch',
    alignItems: 'flex-start',
  },
  ({ theme, disabled }) => ({
    borderTop: `1px solid ${theme.palette.novotalk.$borderColorLight}`,
    backgroundColor: disabled
      ? theme.palette.novotalk.$selectBackgroundColorDisabled
      : theme.palette.novotalk.$backgroundColor,
  })
);

export const StyledTextArea = styled('textarea')(
  {
    marginRight: 20,
    flexGrow: 1,
    border: 'none',
    fontSize: 14,
    lineHeight: '21px',
    outline: 'none',
    resize: 'none',
  },
  ({ theme }) => ({
    direction: theme.RTL ? 'rtl' : 'inherit',
    color: theme.palette.novotalk.$textColor,
    '&:placeholder': {
      color: theme.palette.novotalk.$borderColorTooltip,
    },
  })
);

export const StyledCTAButton = styled(CTAButton)({
  width: 140,
  height: 44,
});
