import React from 'react';
import { TitleUserPage, TitleWithFilterWrapper } from "./styles";
import { TEXT_COLOR } from "@components/Text";
import Select from "@components/Select";
import { USER_ACTIVITIES } from "@containers/Admin/containers/UserPage/consts";

const ALL_ACTIVITIES = 'All';

export const TitleWithFilter = ({ handleSetActivitiesList, activityLog }) => {
  /**
   * Select value and filter activities
   * @params {string} value - selected value
   */
  const selectAndFilterActivities = ({ value }) => {
    if (value === ALL_ACTIVITIES) {
      handleSetActivitiesList(activityLog);
    } else {
      const filteredActivities = activityLog.filter(({ type }) => type === value);
      handleSetActivitiesList(filteredActivities);
    }
  }
  return (
    <TitleWithFilterWrapper>
      <TitleUserPage
        size="H0"
        textColor={TEXT_COLOR.DARK_BLUE}
        weight={700}
      >
        User Activity Log
      </TitleUserPage>
      <div>
        <Select
          defaultValue={USER_ACTIVITIES.at(0)}
          onChange={selectAndFilterActivities}
          options={USER_ACTIVITIES}
          width={138}
        />
      </div>
    </TitleWithFilterWrapper>
  );
};
