import {styled} from "@mui/material";
import Text from "@components/Text";
import { isDesktop } from "@shared/constants/media-queries";

export const TextSelectCenter = styled('div')({
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
})

export const DetailLogPanelWrapper = styled('div')({
  paddingLeft: 20,
})

export const ActivityTitle = styled(Text)({
  marginBottom: 6,
})

export const SkillTitle = styled(Text)({
  marginBottom: isDesktop() ? 26 : 16,
})
