import {styled} from "@mui/material";
import { getIconsByType, iconTypes } from '@services/icons';

const SortUpIcon = getIconsByType(iconTypes.FILLED_ARROW_UP);
const SortDownIcon = getIconsByType(iconTypes.FILLED_ARROW_DOWN);

export const StyledSortUpIcon = styled(SortUpIcon)({
  position: 'absolute',
  top: '-10px',
});

export const StyledSortDownIcon = styled(SortDownIcon)({
  position: 'absolute',
  top: '-7px',
});

const cellWidth = '200px';
const smallFieldWidth = '85px';

export const Wrapper = styled('div')(
  {
    backgroundColor: '#FFF',
    display: 'flex',
    flexDirection: 'column',
    padding: '10px',
    alignItems: 'center',
    justifyContent: 'center',
    width: '70%',
    placeSelf: 'center',
  },
  (props) => ({
    gridArea: props.gridArea,
  })
);

export const Header = styled('div')({
  alignSelf: 'stretch',
  display: 'flex',
  padding: '6px',
  alignItems: 'center',
  justifyContent: 'space-around',
  height: '50px',
  margin: '3px 0',
});

export const Cell = styled('div')(
  {
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer',
  },
  (props) => ({
    width: props.small ? smallFieldWidth : cellWidth,
    minWidth: props.small ? smallFieldWidth : '',
  })
);

export const IconWrapper = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  position: 'relative',
});
