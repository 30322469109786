import React from 'react';
import PropTypes from 'prop-types';
import { TEXT_SIZE, TEXT_COLOR } from '@components/Text';
import CTAButton from '@components/CTAButton';
import permissionsScreenshot from './permissionsScreenshot.png';
import {
  Wrapper,
  StyledMicIcon,
  StyledVideoIcon,
  StyledText,
  StyledImg,
  IconsWrapper,
} from './styles';
import {compose} from "redux";
import {useTranslation, withTranslation} from "react-i18next";

export const MediaDevicesPermissions = ({
  onClose,
}) => {
    const {t} = useTranslation()
    return (
        <Wrapper>
            <StyledText
                size={TEXT_SIZE.H1}
                textColor={TEXT_COLOR.DARK_BLUE}
                weight='200'
            >
                {t('UI.beforeStart')}
            </StyledText>
            <IconsWrapper>
                <StyledMicIcon />
                <StyledVideoIcon />
            </IconsWrapper>
            <StyledText size={TEXT_SIZE.T3} textColor={TEXT_COLOR.DARK_BLUE}>
                {t('Informative.Notifying.sessionsRecord')}
            </StyledText>
            <StyledText
                size={TEXT_SIZE.T3}
                textColor={TEXT_COLOR.DARK_BLUE}
                weight='bold'
            >
                {t('Informative.Demanding.allowCameraMicrophonePermission')}
            </StyledText>
            <StyledImg src={permissionsScreenshot} />
            <CTAButton onClick={onClose}>
                {t('Actions.Confirmations.gotIt')}
            </CTAButton>
        </Wrapper>
    );
}

MediaDevicesPermissions.propTypes = {
  onClose: PropTypes.func,
};

export default MediaDevicesPermissions;
