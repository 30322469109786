import {styled} from "@mui/material";
import OverviewButton from '@components/OverviewButton';
import Text from '@components/Text';
import { getResponsiveCSS } from '@utils/cssHelpers';

export const MetadataHeader = styled('div')(
  {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    paddingTop: 5,
    paddingBottom: 15,
    marginBottom: 12,
  },
  ({ theme }) => ({
    borderBottom: `1px solid ${theme.palette.novotalk.$resourcesTableBorderColor}`,
  })
);

export const HeaderText = styled(Text)({
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  whiteSpace: 'pre',
});

export const MetadataButtons = styled('div')({
  display: 'flex',
  justifyContent: 'flex-end',
});

const buttonsStyles = {
  marginLeft: 8,
  height: 32,
};

export const HeaderDuplicateButton = styled(OverviewButton)(
  buttonsStyles,
  getResponsiveCSS('width', 90, 106)
);

export const HeaderButton = styled(OverviewButton)(
  buttonsStyles,
  getResponsiveCSS('width', 72, 84)
);

export const HeaderEditButton = styled(OverviewButton)(buttonsStyles, {
  width: 68,
});

export const MetadataSection = styled('div')(
  {
    paddingBottom: 14,
    marginBottom: 14,
    display: 'flex',
  },
  ({ theme }) => ({
    borderBottom: `1px solid ${theme.palette.novotalk.$resourcesTableBorderColor}`,
  })
);

export const MedaDataLine = styled('div')({
  width: '50%',
  paddingRight: 10,
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  lineHeight: '19px',
  '& > span': {
    whiteSpace: 'pre',
  },
});

export const UsedInSection = styled(MetadataSection)(
  {
    flexDirection: 'column',
    lineHeight: '19px',
  },
  ({ withoutBorder }) =>
    withoutBorder && {
      borderBottom: 'none',
    }
);

export const UsedInTitle = styled('div')({
  marginBottom: 8,
});

export const MedaDataUsedIn = styled('p')(
  {
    cursor: 'pointer',
    margin: 0,
    marginBottom: 6,
    '&:last-of-type': {
      marginBottom: 0,
    },
  },
  ({ theme, readOnly }) => ({
    color: theme.palette.novotalk.$textColorHighlited,
    pointerEvents: readOnly ? 'none' : null,
  })
);

export const MedaDataUsedInIcon = styled('span')({
  display: 'inline-block',
  fontSize: 18,
  marginRight: 8,
});
