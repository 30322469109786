export const Types = {
  SEND_MEDIA_DATA: 'SEND_MEDIA_DATA',
  SET_ERROR: 'SET_ERROR',
  SET_LOADING_PROGRESS: 'SET_LOADING_PROGRESS',
  SET_UPLOAD_STATUS: 'SET_UPLOAD_STATUS',
};

export const sendMediaData = ({ ...data }) => ({
  type: Types.SEND_MEDIA_DATA,
  payload: data,
});

export const setError = (error) => ({
  type: Types.SET_ERROR,
  payload: { error },
});

export const setLoadingProgress = (value) => ({
  type: Types.SET_LOADING_PROGRESS,
  payload: { value },
});

export const uploadStatus = {
  SENDING: 'SENDING',
  SENT: 'SENT',
  ERROR: 'ERROR',
  DEFAULT: 'DEFAULT',
};

export const setUploadStatus = (status, sentItem = null) => ({
  type: Types.SET_UPLOAD_STATUS,
  payload: { status, sentItem },
});
