import CourseView from './CourseVideo';
import MediaUpload from './MediaUpload';
import MediaViewerModal from './MediaViewerModal';
import Media from './Media';
import Alert from './Alert';
import Confirm from './Confirm';
import ResourceOverviewPopup from './ResourceOverviewPopup';
import ResourceSelection from './ResourceSelection';
import SignUpUnavailable from './SignUpUnavailable';
import AgeNotificationInfo from './AgeNotificationInfo';
import FeedbackPreview from './FeedbackPreview';
import DismissActivity from './DismissActivity';
import VideoChatSummary from './VideoChatSummary';
import PracticeOverview from './PracticeOverview';
import ExerciseExit from './ExerciseExit';
import ExerciseTips from './ExerciseTips';
import ExerciseEnd from './ExerciseEnd';
import ClinicianFeedback from './ClinicianFeedback';
import LessonEnd from './LessonEnd';
import UpgradeCourse from './UpgradeCourse';
import ChangePassword from './ChangePassword';
import ChangePasswordConfirmation from './ChangePasswordConfirmation';
import CancelSubscription from './CancelSubscription';
import MediaDevicesPermissions from './MediaDevicesPermissions';
import MediaEditor from './MediaEditor';
import Duplicate from './Duplicate'
import NoiseCalibration from './NoiseCalibration';

import POPUP_TYPES from '../types';

export const getComponentByType = (type) => {
  switch (type) {
    case POPUP_TYPES.COURSE_VIDEO: return CourseView;
    case POPUP_TYPES.MEDIA_UPLOAD: return MediaUpload;
    case POPUP_TYPES.MEDIA_EDITOR: return MediaEditor;
    case POPUP_TYPES.MEDIA_VIEWER: return MediaViewerModal;
    case POPUP_TYPES.MEDIA: return Media;
    case POPUP_TYPES.ALERT: return Alert;
    case POPUP_TYPES.CONFIRM: return Confirm;
    case POPUP_TYPES.RESOURCE_OVERVIEW: return ResourceOverviewPopup;
    case POPUP_TYPES.SIGN_UP_UNAVAILABLE: return SignUpUnavailable;
    case POPUP_TYPES.AGE_NOTIFICATION_INFO: return AgeNotificationInfo;
    case POPUP_TYPES.FEEDBACK_PREVIEW: return FeedbackPreview;
    case POPUP_TYPES.CONFIRM_DISMISS_ACTIVITY: return DismissActivity;
    case POPUP_TYPES.VIDEO_CHAT_SUMMARY: return VideoChatSummary;
    case POPUP_TYPES.SELECT_RESOURCE: return ResourceSelection;
    case POPUP_TYPES.PRACTICE_OVERVIEW: return PracticeOverview;
    case POPUP_TYPES.EXERCISE_EXIT: return ExerciseExit;
    case POPUP_TYPES.EXERCISE_TIPS: return ExerciseTips;
    case POPUP_TYPES.EXERCISE_END: return ExerciseEnd;
    case POPUP_TYPES.CLINICIAN_FEEDBACK: return ClinicianFeedback;
    case POPUP_TYPES.LESSON_END: return LessonEnd;
    case POPUP_TYPES.UPGRADE_COURSE: return UpgradeCourse;
    case POPUP_TYPES.CHANGE_PASSWORD: return ChangePassword;
    case POPUP_TYPES.CHANGE_PASSWORD_CONFIRMATION: return ChangePasswordConfirmation;
    case POPUP_TYPES.CANCEL_SUBSCRIPTION: return CancelSubscription;
    case POPUP_TYPES.MEDIA_DEVICES_PERMISSIONS: return MediaDevicesPermissions;
    case POPUP_TYPES.DUPLICATE: return Duplicate
    case POPUP_TYPES.NOISE_CALIBRATION: return NoiseCalibration;
    default: return null;
  }
};
