import {styled} from "@mui/material";

export const Wrapper = styled('div')({
  height: 38,
  position: 'relative',
});

export const selectStyles = {
  container: (provided) => ({
    ...provided,
    position: 'absolute',
  }),
  indicatorSeparator: () => {
    'none';
  },
  singleValue: (provided) => ({
    ...provided,
    fontSize: '14px',
  }),
  option: (provided) => ({
    ...provided,
    fontSize: '14px',
  }),
  control: (provided) => ({
    ...provided,
    width: 200,
  }),
  menu: (provided) => ({
    ...provided,
    width: 200,
  }),
  dropdownIndicator: (provided) => ({
    ...provided,
    padding: '4px',
  }),
};
