import React from 'react';
import {SectionsWrapper} from "./styles";
import {
    ISkill
} from "@containers/Admin/containers/UserPage/user-page-wrapper/components/UserPersonalProgram/types";
import {
    LessonsSection
} from "@containers/Admin/containers/UserPage/user-page-wrapper/components/UserPersonalProgram/components/LessonsSection";
import {
    PracticesSection
} from "@containers/Admin/containers/UserPage/user-page-wrapper/components/UserPersonalProgram/components/PracticesSection";

interface ListItemSectionProps {
    skill: ISkill
}

export const ListItemSection: React.FC<ListItemSectionProps> = ({skill}) => {

    return (
        <SectionsWrapper>
            {!!skill?.Lessons?.length && <LessonsSection lessons={skill.Lessons}/>}
            {!!skill?.Practices?.length && <PracticesSection practices={skill.Practices}/>}
        </SectionsWrapper>
    );
};