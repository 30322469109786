/* eslint-disable react/no-array-index-key */
/**
 *
 * ActivityProduction
 *
 */
import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import ErrorIcon from '@components/ErrorIcon';
import { ErrorName } from '@components/ErrorName';

import Text, {
  TEXT_SIZE,
  TEXT_COLOR,
} from '@components/Text';

import { isMvpVersion } from '@services/isMvpService';
import {
  Wrapper,
  StimuliWrapper,
  WordWrapper,
  SyllableWrapper,
  PlayButton,
  PlayIcon,
  PauseIcon,
  ErrorsWrapper,
  ErrorItem,
  ErrorIconWrapper,
  StyledCheckmarkIcon,
} from './styles';

const ActivityProduction = ({
  onPlayClicked,
  isPlaying,
  index,
  production,
}) => {
  const { t } = useTranslation();
  const visibleErrors = production.productionErrors.filter(
    ({ displayed }) => displayed,
  );
  return (
    <Wrapper>
      <div>
        {!isMvpVersion
          && (
          <PlayButton
            isPlaying={isPlaying}
            onClick={() => onPlayClicked(production)}
            className="playProduction"
          >
            {isPlaying ? <PauseIcon /> : <PlayIcon />}
          </PlayButton>
          )}
      </div>
      <div>
        <Text
          className="productionIndex"
          size={TEXT_SIZE.H4}
          textColor={TEXT_COLOR.DARK_BLUE}
        >
          {index + 1}
        </Text>
      </div>
      <StimuliWrapper>
        {production.textStimuli
          && production.textStimuli.words
          && production.textStimuli.words.map((word, wordIndex) => (
            <WordWrapper
              key={`${word.text} ${wordIndex}`}
              className="productionWord"
            >
              {word.syllables.map((syllable, syllableIndex) => {
                const current = syllableIndex
                  === production.stimuliDisplayed[0].syllableIndex
                  && wordIndex === production.stimuliDisplayed[0].wordIndex;
                return (
                  <SyllableWrapper
                    key={`${syllableIndex} ${wordIndex} ${syllable.text}`}
                  >
                    <Text
                      current={current}
                      textColor={
                        current ? TEXT_COLOR.INFO : TEXT_COLOR.DISABLED
                      }
                      className="productionSyllable"
                    >
                      {syllable.text}
                    </Text>
                  </SyllableWrapper>
                );
              })}
            </WordWrapper>
          ))}
      </StimuliWrapper>
      <ErrorsWrapper>
        {visibleErrors.length > 0
          && visibleErrors.map(({ type }, errorIndex) => (
            <ErrorItem>
              <ErrorIconWrapper>
                <ErrorIcon errorType={type} />
              </ErrorIconWrapper>
              <ErrorName
                errorType={type}
                additionalTextProps={{
                  size: TEXT_SIZE.T4,
                  textColor: TEXT_COLOR.WARNING,
                  margin: '0 5px',
                }}
                key={`${type} ${errorIndex}`}
                className="productionError"
              />
            </ErrorItem>
          ))}
        {visibleErrors.length === 0
          && production.productionErrors.length > 0 && <StyledCheckmarkIcon />}
      </ErrorsWrapper>
      <div>
        <Text size={TEXT_SIZE.H4} textColor={TEXT_COLOR.DARK_BLUE}>
          {t('Dates.seconds')}
          {(Math.floor(production.productionLength * 100) / 100).toString()}
        </Text>
      </div>
      <div>
        <div className="productionPattern">TBD</div>
      </div>
    </Wrapper>
  );
};

ActivityProduction.propTypes = {
  index: PropTypes.number,
  onPlayClicked: PropTypes.func,
  isPlaying: PropTypes.bool,
  production: PropTypes.shape({
    id: PropTypes.string,
    textStimuli: PropTypes.shape({
      words: PropTypes.arrayOf(
        PropTypes.shape({
          text: PropTypes.string,
          syllables: PropTypes.arrayOf(
            PropTypes.shape({
              text: PropTypes.string,
            }),
          ),
        }),
      ),
    }),
    stimuliDisplayed: PropTypes.arrayOf(
      PropTypes.shape({
        wordIndex: PropTypes.number,
        syllableIndex: PropTypes.number,
      }),
    ),
    startTS: PropTypes.number,
    endTS: PropTypes.number,
    dspSignal: PropTypes.arrayOf(PropTypes.number),
    productionLength: PropTypes.number,
    activityCreated: PropTypes.shape({
      at: PropTypes.string,
    }),
    productionErrors: PropTypes.arrayOf(
      PropTypes.shape({
        type: PropTypes.string,
        exists: PropTypes.bool,
        displayed: PropTypes.bool,
      }),
    ),
  }),
};

export default ActivityProduction;
