import { getRequest } from '../commService';
import { AUTH_ENDPOINTS, AUTH_SERVER } from '../servers';

export const getServerProperties = async () => {
  try {
    const response = await getRequest(
      AUTH_SERVER,
      AUTH_ENDPOINTS.PROPERTIES,
      {},
    );
    return response;
  } catch (error) {
    throw new Error(error.message);
  }
};
