import {styled} from "@mui/material";
import {
  getIconByResourceType,
  getIconsByType,
  iconTypes,
} from '@services/icons';
import { getResponsiveCSS } from '@utils/cssHelpers';

export const DragIcon = getIconsByType(iconTypes.DRAG);

export const DuplicateIcon = styled(getIconsByType(iconTypes.DUPLICATE))({
  fontSize: 19,
  marginLeft: 4,
});

export const EditIcon = getIconsByType(iconTypes.EDIT);
export const DeleteIcon = getIconsByType(iconTypes.DELETE);

const HEADER_HEIGHT = 52;

export const EditableComponentConatainer = styled('div')(
  ({ selected, hasBody }) => ({
    marginBottom: hasBody ? 30 : 10,
    opacity: selected ? 0.2 : 1,
  })
);

const iconProps = ({ theme }) => ({
  fontSize: 23,
  cursor: 'pointer',
  display: 'flex',
  alignItems: 'center',
  color: theme.palette.novotalk.$dragIconColor,
  '&:hover': {
    color: theme.palette.novotalk.$dragIconColorHover,
  },
  '&:active': {
    color: theme.palette.novotalk.$dragIconColorActive,
  },
});

export const DragIconContainer = styled('div')(iconProps, {
  cursor: 'grab',
  height: HEADER_HEIGHT,
});

export const IconContainer = styled('div')(iconProps);

export const getStyledResourceIcon = (type) =>
  styled(getIconByResourceType(type))(
    {
      fontSize: 19,
    },
    ({ theme }) => ({
      color: theme.palette.novotalk.$textColorDarkBlue,
    })
  );

export const EditorObjectHeader = styled('div')(
  {
    height: HEADER_HEIGHT,
    display: 'grid',
    gridTemplateRows: `${HEADER_HEIGHT}px`,
    alignItems: 'center',
    padding: '0 0 0 18px',
  },
  ({ theme, isSkill, hasBody }) => ({
    borderRadius: hasBody ? '4px 4px 0 0' : 4,
    gridTemplateColumns: `36px ${isSkill ? '' : '28px'} auto 34px 24px`,
    backgroundColor: theme.palette.novotalk.$backgroundColorMedium,
  })
);

export const EditorObjectBody = styled('div')(
  {
    padding: '0 20px',
    borderRadius: '0 0 4px 4px',
  },
  getResponsiveCSS('paddingTop', 20, 24),
  getResponsiveCSS('paddingBottom', 20, 24),
  ({ theme }) => ({
    backgroundColor: theme.palette.novotalk.$backgroundColor,
    border: `${theme.palette.novotalk.$widgetMenuBorderColor} solid 1px`,
    borderTop: 'none',
  })
);
