import {styled} from "@mui/material";

export const DifficultySelection = styled('button')(
  {
    width: 64,
    height: 30,
    padding: '0 0 5px',
    outline: 'none',
    cursor: 'pointer',
    textAlign: 'center',
    '&:last-child': {
      borderRadius: '0 4px 4px 0',
      borderLeft: 'none',
    },
    '&:first-of-type': {
      borderRadius: '4px 0 0 4px',
      borderRight: 'none',
    },
  },
  ({ theme, isSelected }) => ({
    border: `solid 1px ${
      isSelected ? theme.palette.novotalk.$activeColor : theme.palette.novotalk.$errorSelectorBorderColor
    }`,
    backgroundColor: isSelected
      ? theme.palette.novotalk.$activeColor
      : theme.palette.novotalk.$errorSelectorColor,
    '&:hover': {
      backgroundColor: isSelected
        ? theme.palette.novotalk.$activeColor
        : theme.palette.novotalk.$errorSelectorColorHover,
    },
    '&:active': {
      backgroundColor: theme.palette.novotalk.$errorSelectorColorActive,
    },
  })
);

export const LabelContainer = styled('div')({
  height: 36,
  display: 'grid',
  gridTemplateColumns: '160px auto',
  alignItems: 'center',
  lineHeight: '16px',
});

export const SelectionContainer = styled('div')({
  display: 'flex',
});
