export const TYPES = {
  GET_CURRENT_POLL: 'GET_CURRENT_POLL',
  SET_CURRENT_POLL: 'SET_CURRENT_POLL',
  SET_POLL_SUBJECT: 'SET_POLL_SUBJECT',
};

export function getCurrentPoll(id, hebrewMock) {
  return {
    type: TYPES.GET_CURRENT_POLL,
    id,
    hebrewMock,
  };
}

export function setCurrentPoll(poll) {
  return {
    type: TYPES.SET_CURRENT_POLL,
    poll,
  };
}

export function setPollSubject(id, subjectType) {
  return {
    type: TYPES.SET_POLL_SUBJECT,
    id,
    subjectType,
  };
}
