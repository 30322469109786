import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { withRouter } from 'react-router-dom';
import _ from 'lodash';
import { differenceInDays } from 'date-fns';
import TrialBar from './components/TrialBar';
import Header from './components/Header';
import CourseProgress from './components/CourseProgress';
import CourseMainPane from './containers/CourseMainPane';
import { Wrapper } from './styles';
import POPUP_TYPES from '../../../../../App/containers/PopUp/types';
import { openPopUp as openPopUpAction } from '../../../../../App/containers/PopUp/actions';
import progressConsts from '../../../../helpers/courseProgressConsts';
import { USE_PAID_SYSTEM_VERSION } from '@shared/constants';

export class Course extends Component {
  constructor(props) {
    super(props);
    this.hasPaidSubscription =
      USE_PAID_SYSTEM_VERSION &&
      props.Subscription.some((item) => item.type === 'PAID_COURSE');
  }

  componentDidMount() {
    const { Skills, openPopUp, userName } = this.props;
    if (USE_PAID_SYSTEM_VERSION) {
      if (!this.hasPaidSubscription) {
        const isExpired = this.getDaysLeft() === 0;
        const isCompleted = !Skills.some(
          (skill) => skill.free && skill.status !== progressConsts.COMPLETED,
        );
        if (isExpired || isCompleted) {
          openPopUp({ userName, isExpired });
        }
      }
    }
  }

  getDaysLeft = () => {
    const { Subscription } = this.props;
    if (!Subscription.length) {
      return 0;
    }
    const expirationDate = new Date(Subscription[0].expirationDate);
    const today = new Date();
    return expirationDate > today
      ? differenceInDays(expirationDate, today) + 1
      : 0;
  };

  resumeCourse = () => {
    const { locationInCourse, history } = this.props;
    history.push(locationInCourse.path);
  };

  render() {
    const { Skills, locationInCourse, courseIndex, name } = this.props;
    const props = {
      Skills,
      locationInCourse,
      resumeCourse: this.resumeCourse,
    };

    return (
      <Wrapper>
        {USE_PAID_SYSTEM_VERSION && !this.hasPaidSubscription && (
          <TrialBar daysLeft={this.getDaysLeft()} />
        )}
        <Header {...props} courseName={name} />
        <CourseProgress {..._.omit(props, ['resumeCourse'])} />
        <CourseMainPane
          {...props}
          isTrial={!this.hasPaidSubscription}
          courseIndex={courseIndex}
        />
      </Wrapper>
    );
  }
}

Course.propTypes = {
  locationInCourse: PropTypes.object,
  Skills: PropTypes.arrayOf(PropTypes.object),
  Subscription: PropTypes.arrayOf(PropTypes.object),
  history: PropTypes.object,
  courseIndex: PropTypes.number,
  userName: PropTypes.string,
  openPopUp: PropTypes.func,
  name: PropTypes.string,
};

export const mapStateToProps = (state) => {
  const { firstName, lastName } = state
    .getIn(['app', 'authentication', 'user'])
    .toJS();
  return { userName: `${firstName} ${lastName}` };
};

export function mapDispatchToProps(dispatch) {
  return {
    openPopUp: (props) =>
      dispatch(openPopUpAction(POPUP_TYPES.UPGRADE_COURSE, props)),
  };
}

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default compose(withRouter, withConnect)(Course);
