/**
*
* EndOfExerciseInsight
*
*/
import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import Text, { TEXT_COLOR, TEXT_SIZE, TEXT_WEIGHT } from '@components/Text';
import { InsightText } from '@components/InsightText';
import ErrorIcon from '@components/ErrorIcon';
import { Header, Insight, IconWrapper } from './styles';
import {useTranslation} from "react-i18next";

const extractInsights = (typeInsights) => _.uniqBy(
  typeInsights?.reduce((accInsights, { insights }) => accInsights.concat(insights), []),
  ({ error }) => error
);

const EndOfExerciseInsight = ({ summary }) => {
    const {t} = useTranslation()
  const insightErrors = extractInsights(summary?.insights);
  return (
    <div>
      <Header>
        <Text size={TEXT_SIZE.H4} weight={TEXT_WEIGHT.BOLD} textColor={TEXT_COLOR.DARK_BLUE}>
            <span className="endOfExerciseTitle">
                {t('Informative.Notifying.exerciseInsights')}
            </span>
        </Text>
      </Header>
      <div>
        {insightErrors.map((insightError) => (
          <Insight className="insight" key={insightError.error}>
            <IconWrapper>
              <ErrorIcon errorType={insightError.error} />
            </IconWrapper>
            <InsightText additionalTextProps={{ size: TEXT_SIZE.H4, textColor: TEXT_COLOR.DARK_BLUE }} insight={insightError} />
          </Insight>
        ))}
      </div>
    </div>);
};

EndOfExerciseInsight.propTypes = {
  summary: PropTypes.shape({
    insights: PropTypes.arrayOf(PropTypes.shape({
      type: PropTypes.string,
      insights: PropTypes.arrayOf(PropTypes.shape({
        severity: PropTypes.string,
        error: PropTypes.string,
      })),
    })),
  }),
};

export default EndOfExerciseInsight;
