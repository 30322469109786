import courseTypes from '../../../../../helpers/courseTypes';

export const practices = [
            {
                id: 'p1',
                type: courseTypes.PRACTICE,
                duration: 10,
                name: 'breathing beginner',
                children: [
                    {
                        id: 'e1',
                        name: 'basics',
                        demo: 'https://s3-eu-west-1.amazonaws.com/nvtk-video-media/03_01%20Hebrew%20Low.mp4',
                        masteryLevel: 30,
                    },
                    {
                        id: 'e2',
                        name: 'increasing breath capacity',
                        demo: 'https://s3-eu-west-1.amazonaws.com/nvtk-video-media/03_01%20Hebrew%20Low.mp4',
                        masteryLevel: 40
                    },
                    {
                        id: 'e3',
                        name: 'volume control',
                        demo: 'https://s3-eu-west-1.amazonaws.com/nvtk-video-media/03_01%20Hebrew%20Low.mp4',
                        masteryLevel: 50,
                    },                       
                ],
            },
            {
                id: 'p2',
                type: courseTypes.PRACTICE,
                duration: 15,
                name: 'breathing advanced',
                children: [
                    {
                        id: 'e1',
                        name: 'basics',
                        demo: 'https://s3-eu-west-1.amazonaws.com/nvtk-video-media/03_01%20Hebrew%20Low.mp4',
                        masteryLevel: 40
                    },
                    {
                        id: 'e2',
                        name: 'increasing breath capacity',
                        demo: 'https://s3-eu-west-1.amazonaws.com/nvtk-video-media/03_01%20Hebrew%20Low.mp4',
                        masteryLevel: 50
                    },
                    {
                        id: 'e3',
                        name: 'volume control',
                        demo: 'https://s3-eu-west-1.amazonaws.com/nvtk-video-media/03_01%20Hebrew%20Low.mp4',
                        masteryLevel: 60
                    },                       
                ],
                activity: {
                    id: "activityId",
                    createdAt: 'Wed Dec 02 2018 13:52:30 GMT+0200',
                    exercises: [
                       { 
                         id: 'e1', 
                         achievedMasteryLevel: 50, 
                         fails: 0,
                         tips: [
                            {
                              id:'tip1'
                            },
                            { 
                              id: 'tip3'
                            }
                         ] 
                      },
                      { 
                        id: 'e2', 
                        achievedMasteryLevel: 50, 
                        fails: 0,
                        tips: [
                            {
                             id:'tip4'
                            },
                            { 
                             id: 'tip6'
                            }
                        ] 
                     },
                     { 
                        id: 'e3', 
                        achievedMasteryLevel: 51, 
                        fails: 0,
                        tips: [
                            {
                             id:'tip3'
                            },
                            { 
                             id: 'tip2'
                            }
                        ] 
                     }
                    ],
                    feedback: [
                      {
                        id: 'postId1',
                        publisherName: 'John Smith',
                        publisherAvatar: 'https://s3-eu-west-1.amazonaws.com/nvtk-user-avatar/defaults/2.svg',
                        createdAt: 'Wed Dec 05 2018 13:52:30 GMT+0200',
                        data: 'Lorem ipsum dolor amet cloud bread shoreditch before they sold out hot chicken brunch, health goth retro actually narwhal gentrify ugh XOXO mlkshk. Roof party sartorial slow-carb lomo.'
                       },
                     {
                        id: 'postId2',
                        publisherName: 'John Smith',
                        publisherAvatar: 'https://s3-eu-west-1.amazonaws.com/nvtk-user-avatar/defaults/3.svg',
                        createdAt: 'Wed Dec 05 2018 13:54:30 GMT+0200',
                        data: 'Lorem ipsum dolor amet cloud bread shoreditch before they sold out hot chicken brunch, health goth retro actually narwhal gentrify ugh XOXO mlkshk. Roof party sartorial slow-carb lomo.'
                       }
                    ]
                }

            },
            {
                id: 'p3',
                duration: 20,
                name: 'breathing expert',
                type: courseTypes.PRACTICE,
                children: [
                    {
                        id: 'e1',
                        name: 'basics',
                        demo: 'https://s3-eu-west-1.amazonaws.com/nvtk-video-media/03_01%20Hebrew%20Low.mp4',
                        masteryLevel: 30
                        
                    },
                    {
                        id: 'e2',
                        name: 'increasing breath capacity',
                        demo: 'https://s3-eu-west-1.amazonaws.com/nvtk-video-media/03_01%20Hebrew%20Low.mp4',
                        masteryLevel: 40
                    },                       
                ],
            },
      
            {
                id: 'p4',
                duration: 10,
                name: 'relaxation - beginner',
                type: courseTypes.PRACTICE,
                children: [
                    {
                        id: 'e1',
                        name: 'basics',
                        demo: 'https://s3-eu-west-1.amazonaws.com/nvtk-video-media/03_01%20Hebrew%20Low.mp4',
                        masteryLevel: 30
                    }
                ]
            },
            {
                id: 'p5',
                duration: 10,
                name: 'voice production - beginner',
                type: courseTypes.PRACTICE,
                children: [
                    {
                        id: 'e1',
                        name: 'basics',
                        demo: 'https://s3-eu-west-1.amazonaws.com/nvtk-video-media/03_01%20Hebrew%20Low.mp4',
                        masteryLevel: 30
                    }
                ]

            },
            {
                id: 'p6',
                duration: 10,
                name: '2 seconds pattern - beginner',
                type: courseTypes.PRACTICE,
                children: [
                    {
                        id: 'e1',
                        name: 'basics',
                        demo: 'https://s3-eu-west-1.amazonaws.com/nvtk-video-media/03_01%20Hebrew%20Low.mp4',
                        masteryLevel: 30
                    }
                ]

            },
            {
                id: 'p7',
                duration:10,
                name: '1 second pattern - beginner',
                type: courseTypes.PRACTICE,
                children: [
                    {
                        id: 'e1',
                        name: 'basics',
                        demo: 'https://s3-eu-west-1.amazonaws.com/nvtk-video-media/03_01%20Hebrew%20Low.mp4',
                        masteryLevel: 30
                    }
                ]

            },
            {
                id: 'p8',
                duration:10,
                name: '1 sec with intonation - beginner',
                type: courseTypes.PRACTICE,
                children: [
                    {
                        id: 'e1',
                        name: 'basics',
                        demo: 'https://s3-eu-west-1.amazonaws.com/nvtk-video-media/03_01%20Hebrew%20Low.mp4',
                        masteryLevel: 30
                    }
                ]

            },
            {
                id: 'p9',
                duration:10,
                name: 'self managed speech rate - beginner',
                type: courseTypes.PRACTICE,
                children: [
                    {
                        id: 'e1',
                        name: 'basics',
                        demo: 'https://s3-eu-west-1.amazonaws.com/nvtk-video-media/03_01%20Hebrew%20Low.mp4',
                        masteryLevel: 30
                    }
                ]

            },
            {
                id: 'p10',
                duration:10,
                name: 'pace changes - beginner',
                type: courseTypes.PRACTICE,
                children: [
                    {
                        id: 'e1',
                        name: 'basics',
                        demo: 'https://s3-eu-west-1.amazonaws.com/nvtk-video-media/03_01%20Hebrew%20Low.mp4',
                        masteryLevel: 30
                    }
                ]

            },
            {
                id: 'p11',
                duration:10,
                name: 'integrated speech - beginner',
                type: courseTypes.PRACTICE,
                children: [
                    {
                        id: 'e1',
                        name: 'basics',
                        demo: 'https://s3-eu-west-1.amazonaws.com/nvtk-video-media/03_01%20Hebrew%20Low.mp4',
                        masteryLevel: 30
                    }
                ]

            },
            {
                id: 'p1h',
                duration: 10,
                name: 'מונפרר סוברט',
                type: courseTypes.PRACTICE,
                children: [
                    {
                        id: 'e1',
                        name: 'קרהשק סכעיט',
                        demo: 'https://s3-eu-west-1.amazonaws.com/nvtk-video-media/03_01%20Hebrew%20Low.mp4',
                        masteryLevel: 30
                    },
                    {
                        id: 'e2',
                        name: 'שלושע ותלברו',
                        demo: 'https://s3-eu-west-1.amazonaws.com/nvtk-video-media/03_01%20Hebrew%20Low.mp4',
                        masteryLevel: 40
                   },
                    {
                        id: 'e3',
                        name: 'היושבב שערש',
                        demo: 'https://s3-eu-west-1.amazonaws.com/nvtk-video-media/03_01%20Hebrew%20Low.mp4',
                        masteryLevel: 50
                    },                       
                ]
            },
            {
                id: 'p2h',
                duration: 15,
                name: 'אדנדום סילקוף',
                type: courseTypes.PRACTICE,
                children: [
                    {
                        id: 'e1',
                        name: 'כלרשט מיחוצים',
                        demo: 'https://s3-eu-west-1.amazonaws.com/nvtk-video-media/03_01%20Hebrew%20Low.mp4',
                        masteryLevel: 30
                    },
                    {
                        id: 'e2',
                        name: 'שערש שמחויט',
                        demo: 'https://s3-eu-west-1.amazonaws.com/nvtk-video-media/03_01%20Hebrew%20Low.mp4',
                        masteryLevel: 40
                    },
                    {
                        id: 'e3',
                        name: 'קרהשק סכעיט דז',
                        demo: 'https://s3-eu-west-1.amazonaws.com/nvtk-video-media/03_01%20Hebrew%20Low.mp4',
                        masteryLevel: 50
                    },                       
                ]
            },
            {
                id: 'p3h',
                duration: 20,
                name: 'בלאסטיק מונופץ',
                type: courseTypes.PRACTICE,
                children: [
                    {
                        id: 'e1',
                        name: 'מונפרד אדנדום',
                        demo: 'https://s3-eu-west-1.amazonaws.com/nvtk-video-media/03_01%20Hebrew%20Low.mp4',
                        masteryLevel: 30
                        
                    },
                    {
                        id: 'e2',
                        name: 'קרהשק סכעיט ד',
                        demo: 'https://s3-eu-west-1.amazonaws.com/nvtk-video-media/03_01%20Hebrew%20Low.mp4',
                        masteryLevel: 40
                    },
                    {
                        id: 'e3',
                        name: 'ערש שמחויט',
                        demo: 'https://s3-eu-west-1.amazonaws.com/nvtk-video-media/03_01%20Hebrew%20Low.mp4',
                        masteryLevel: 50
                    },                       
                ]
            },
      
            {
                id: 'p4h',
                duration: 10,
                name: 'להאמית קרהשק',
                type: courseTypes.PRACTICE,
                children: [
                    {
                        id: 'e1',
                        name: 'נמרגי שהכים תוק',
                        demo: 'https://s3-eu-west-1.amazonaws.com/nvtk-video-media/03_01%20Hebrew%20Low.mp4',
                        masteryLevel: 30
                    }
                ]
            },
            {
                id: 'p5h',
                duration: 10,
                name: 'מרגשי ומרגשח',
                type: courseTypes.PRACTICE,
                children: [
                    {
                        id: 'e1',
                        name: 'נמרגי שהכים תוק',
                        demo: 'https://s3-eu-west-1.amazonaws.com/nvtk-video-media/03_01%20Hebrew%20Low.mp4',
                        masteryLevel: 30
                    }
                ]
            },
        ]