import {styled} from "@mui/material";
import CTAButton from '@components/CTAButton';
import { getResponsiveCSS } from '@utils/cssHelpers';
import { StyledTooltip } from '@components/CustomTooltip/styles';

export const Wrapper = styled('div')({
    padding: '30px 0',
    borderRadius: 4,
  },
  getResponsiveCSS('width', 440, 480),
  ({ theme }) => ({
    backgroundColor: theme.palette.novotalk.$backgroundColor,
  })
);

export const ContentContainer = styled('div')({
  width: 345,
  margin: '0 auto',
});

export const Header = styled('div')({
  width: '100%',
  marginBottom: 30,
  textAlign: 'center',
});

export const InputContainer = styled('div')({
    position: 'relative',
    '& .error-message': {
      marginTop: -8,
    },
  },
  getResponsiveCSS('height', 98, 104)
);

export const InputContainerAutoHeight = styled('div')({
  position: 'relative',
  height: '100%',
  minHeight: 104,
});

export const HintContainer = styled('div')({
  position: 'relative',
  top: -8,
});

export const PasswordTooltip = styled(StyledTooltip)({
  left: '105%',
  top: 23,
  paddingLeft: 20,
  '&:after': {
    left: -6,
    top: 'calc(50% - 6px)',
    transform: 'rotate(-45deg)',
  },
});

export const StyledCTAButton = styled(CTAButton)(
  getResponsiveCSS('height', 44, 48)
);
