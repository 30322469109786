import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import {Input, HiddenInput, InputContainer} from './styles';
import {withTranslation} from "react-i18next";
import {compose} from "redux";

export class ExpandingInput extends React.Component {
  constructor(props) {
    super(props);
    this.nameInputRef = React.createRef();
  }

  onChange = (event) => {
    const { value } = event.target;
    this.props.onChange(value);
  }

  acceptChanges = () => {
    const { onEditModeChange } = this.props;
    onEditModeChange(false);
  }

  handleStartEditing = () => {
    const { onEditModeChange } = this.props;
    onEditModeChange(true);
  }

  checkForNewLine = (event) => {
    if (event.key === 'Enter') {
      this.nameInputRef.current.blur();
      this.acceptChanges();
    }
  }

  render = () => {
    const { error, editMode, value, type, t } = this.props;
    return (
      <InputContainer>
        <HiddenInput isEditModeOn={editMode} error={error}>{value}</HiddenInput>
        <Input
          ref={this.nameInputRef}
          className="innerInput"
          onChange={this.onChange}
          onKeyDown={this.checkForNewLine}
          onFocus={this.handleStartEditing}
          onBlur={this.acceptChanges}
          value={value}
          error={error}
          placeholder={t('Common.Placeholders.newType', {
            type: _.startCase(type?.toLowerCase()),
          })}
        />
      </InputContainer>
    );
  }
}

ExpandingInput.propTypes = {
  value: PropTypes.string,
  editMode: PropTypes.bool,
  onEditModeChange: PropTypes.func,
  onChange: PropTypes.func,
  error: PropTypes.bool,
  type: PropTypes.string,
};

export default compose(
    withTranslation()
)(ExpandingInput);
