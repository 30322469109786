import React from 'react';
import Text, { TEXT_SIZE } from '@components/Text';
import TextButton from '@components/TextButton';
import { useStripe, useElements, CardElement } from '@stripe/react-stripe-js';
import {
  FormButton,
  StyledLink,
  UpperWrapper,
  CardElementWrapper,
  FormButtonWrapper,
} from './styles';
import { ROUTES } from '@shared/constants';
import {useTranslation} from "react-i18next";

export default function CheckoutForm({
  submitPayment,
  loading,
}) {
  const {t} = useTranslation()
  const stripe = useStripe();
  const elements = useElements();

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (elements == null) {
      return;
    }
    const cardElement = elements.getElement(CardElement);
    const result = await stripe.createToken(cardElement);
    submitPayment(result.token);
  };

  return (
    <form onSubmit={handleSubmit}>
      <UpperWrapper>
        <CardElementWrapper>
          <CardElement />
        </CardElementWrapper>
        <FormButtonWrapper>
          <FormButton
            id='payment-form-button'
            onClick={handleSubmit}
            disabled={loading}
          >
            {t('Billing.Agreement.complete')}
          </FormButton>
        </FormButtonWrapper>
      </UpperWrapper>
      <StyledLink id='payment-terms-link' to={ROUTES.TERMS_OF_USE}>
        <TextButton>
          <Text size={TEXT_SIZE.H5}>
            {t('Billing.Agreement.terms')}
          </Text>
        </TextButton>
      </StyledLink>
    </form>
  );
}
