import React from 'react';
import { ScreenSharingWrapper } from '../../styles';
import { useVideoCallContext } from '@shared/hooks/useVideoCallContext';

export const ScreenSharing = ({ children }) => {
  const { stateContext } = useVideoCallContext();
  return (
    <ScreenSharingWrapper
      heightOfWrapper={stateContext.subWrapperDimensions.height}
      widthOfWrapper={stateContext.subWrapperDimensions.width}
      id='ScreenSharingWrapper'
    >
      {children}
    </ScreenSharingWrapper>
  );
};
