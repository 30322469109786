import ChatService, { ScreenSharingRoleEnum } from '@services/chatService';
export const sizesHelper =(size,numOfParticipants)=>{
    const isScreenSharingSubscriber =
      ChatService.screenSharingRole === ScreenSharingRoleEnum.SUBSCRIBER;
     const participantDivider = numOfParticipants===1? numOfParticipants:2
     return {
        width: isScreenSharingSubscriber
        ? size / 6.5
        : (size / participantDivider),
      height: isScreenSharingSubscriber
        ? size / 6.5
        : (size / participantDivider),
    }
}

