/**
*
* ToggleSwitch
*
*/
import React from 'react';
import PropTypes from 'prop-types';
import Text, { TEXT_SIZE, TEXT_COLOR, TEXT_WEIGHT } from '../Text';
import { Container } from './styles';
import { Switch } from '@components/Switch';

class ToggleSwitch extends React.Component {
  constructor(props) {
    super(props);
  }

  onChange = () => {
    if (this.props.onChange) this.props.onChange(!this.props.value);
  }

  render() {
    const { showNames, names, title, value } = this.props;
    return (
      <Container showNames={showNames}>
        {title && (
          <Text
            className='contextText'
            size={TEXT_SIZE.H4}
            textColor={TEXT_COLOR.DARK_BLUE}
            weight={TEXT_WEIGHT.BOLD}
          >
            {title}
          </Text>
        )}
        <Switch
          showNames={showNames}
          checked={value}
          names={names}
          onChange={this.onChange}
        />
      </Container>
    );
  }
}

ToggleSwitch.defaultProps = {
  value: false,
  showNames: false,
};

ToggleSwitch.propTypes = {
  showNames: PropTypes.bool,
  value: PropTypes.bool,
  onChange: PropTypes.func,
  names: PropTypes.array,
};

export default ToggleSwitch;
