import PropTypes from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import injectReducer from '@utils/injectReducer';
import { withRouter } from 'react-router-dom';
import reducer from './reducer';
import { removeRedirectUrl as removeRedirectUrlAction } from './actions';

export const Redirector = ({
  url,
  stateToPass,
  removeRedirectUrl,
  history,
}) => {
  if (url) {
    history.push(url, stateToPass);
    removeRedirectUrl();
  }
  return null;
};

Redirector.propTypes = {
  url: PropTypes.string,
  stateToPass: PropTypes.object,
  removeRedirectUrl: PropTypes.func,
};

export const mapStateToProps = (state) => {
  const { url, stateToPass } = state.getIn(['redirector']).toJS();
  return { url, stateToPass };
};

export function mapDispatchToProps(dispatch) {
  return {
    removeRedirectUrl: () => dispatch(removeRedirectUrlAction()),
  };
}

const withConnect = connect(mapStateToProps, mapDispatchToProps);
const withReducer = injectReducer({ key: 'redirector', reducer });

export default compose(withReducer, withRouter, withConnect)(Redirector);
