import {styled} from "@mui/material";
import Text from "@components/Text";
import { isDesktop } from "@shared/constants/media-queries";
import { COLORS_LIST } from "../pie-chart";

const calculateFontSizes = (isMainError) => {

  const isDesktopSize = isDesktop();
  if (isMainError && isDesktopSize) {
    return 16;
  } else if ((isMainError && !isDesktopSize) || (!isMainError && isDesktopSize)) {
    return 14;
  } else if (!isMainError && !isDesktopSize) {
    return 12;
  }
}

export const PracticeErrorWrapper = styled('div')({
  marginBottom: 10,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  minWidth: 180,
  'div:second-child': {
    marginRight: 10,
  }
});

export const IconError = styled('div')({
  width: 15,
  height: 15,
  marginRight: 9,
},
({ order }) => ({
  backgroundColor: COLORS_LIST[order],
}));

export const IconWithText = styled('div')({
  display: 'flex',
  alignItems: 'center',
});

export const ErrorLabel = styled(Text)({},
({ theme, isMainError }) => {
  const fontSize = calculateFontSizes(isMainError);
  return {
    fontSize,
    color: isMainError && theme.palette.novotalk.$mainError,
    fontWeight: isMainError && 700,
  }
});

export const ErrorPercent = styled(Text)({},
({ isMainError }) => {
  const fontSize = calculateFontSizes(isMainError);
  return {
    fontSize,
    fontWeight: 700,
    paddingRight: 8,
  }
});

export const SectionTitle = styled(Text)({
  fontSize: isDesktop() ? 16 : 14,
  fontWeight: 700,
  marginBottom: 5,
});

export const ListPracticerErrors = styled('div')({
  display: 'flex',
  justifyContent: 'space-between',
  flexDirection: 'column',
  width: '60%',
  maxWidth: 250,
});
