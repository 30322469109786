import _ from 'lodash';
import { fromJS } from 'immutable';
import { TYPES } from './actions';
import { MODE } from './index';
// The initial state of the Component
export const initialState = fromJS({
  loading: true,
  users: [],
  roles: [],
  selectedUser: null,
  selectedRole: null,
  selectedUserRole: null,
  selectedRoleUser: null,
});

const updateUserFromState = (state, user) => {
  const users = state.get('users').toJS();
  users.find((storedUser) => storedUser.id === user.id).userRoles =
    user.userRoles;
  return users;
};

function activityPageReducer(state = initialState, action) {
  switch (action.type) {
    case TYPES.GET_ROLE_MANAGEMENT_PAGE_DATA:
      return state.set('loading', true);
    case TYPES.SET_ROLE_MANAGEMENT_PAGE_DATA: {
      const { users, roles } = action.payload;
      return state
        .set('users', fromJS(users))
        .set('roles', fromJS(roles))
        .set('loading', false);
    }
    case TYPES.ROLE_MANAGEMENT_PAGE_SELECT_USER:
      return state.set('selectedUser', fromJS(action.payload.user));
    case TYPES.ROLE_MANAGEMENT_PAGE_SELECT_ROLE:
      return state.set('selectedRole', fromJS(action.payload.role));
    case TYPES.ADD_ROLE_TO_USER: {
      const { role, user } = action.payload;
      const modifiedUser = _.cloneDeep(user);

      if (!user.roles.find((uRole) => uRole === role.roleId))
        modifiedUser.roles.push(role.roleId);
      return state
        .set('selectedUser', fromJS(modifiedUser))
        .set('users', fromJS(updateUserFromState(state, modifiedUser)))
        .set('selectedUserRole', fromJS(role));
    }
    case TYPES.ADD_ROLE_TO_USER_SUCCESS: {
      if (action.screen === MODE.USER_CENTRIC) {
        return state
          .set('selectedUserRole', null)
          .set('selectedRoleUser', null)
          .set('selectedRole', null);
      } else {
        return state
          .set('selectedUserRole', null)
          .set('selectedRoleUser', null)
          .set('selectedUser', null);
      }
    }
    case TYPES.REMOVE_ROLE_FROM_USER_SUCCESS: {
      return state.set('selectedUserRole', null).set('selectedRoleUser', null);
    }
    case TYPES.CLEAR_SELECTED: {
      return state
        .set('selectedUser', null)
        .set('selectedRole', null)
        .set('selectedUserRole', null)
        .set('selectedRoleUser', null);
    }
    case TYPES.REMOVE_ROLE_FROM_USER: {
      const { role, user } = action.payload;
      const modifiedUser = _.cloneDeep(user);
      modifiedUser.roles = user.roles.filter((uRole) => uRole !== role.roleId);
      return state
        .set('selectedUser', fromJS(modifiedUser))
        .set('users', fromJS(updateUserFromState(state, modifiedUser)));
    }
    default:
      return state;
  }
}

export default activityPageReducer;
