import React from 'react';
import PropTypes from 'prop-types';
import { Wrapper, Icons } from './styles';

const StimulusImage = ({ type, isSelected, miniOverview }) => {
  const Icon = Icons[type];
  return (
    <Wrapper isSelected={isSelected} miniOverview={miniOverview}>
      <Icon />
    </Wrapper>
  );
};

StimulusImage.propTypes = {
  type: PropTypes.string,
  isSelected: PropTypes.bool,
  miniOverview: PropTypes.bool,
};

export default StimulusImage;
