import { ORDERS } from './TableUserLog';

export const columnNameTranslator = (prop:string):string => {
  switch (prop) {
    case 'skill?.name':
      return 'skillName';
    case 'startTime':
      return 'startDate';
    default:
      return prop;
  }
};

export const sortUserLogNestedHandler = (prop: string, arr: any[], order:ORDERS): any[] => {
  const sortValues = order === ORDERS.ASC ? [-1, 1] : [1, -1];
  const propList = columnNameTranslator(prop).split('.');
  const len = propList.length;
  arr.sort((a, b) => {
    for (let i = 0; i < len; i++) {
      a = a[propList[i]] || ' ';
      b = b[propList[i]] || ' ';
    }
    if (propList.includes('startDate')) {
      a = new Date(a).getTime();
      b = new Date(b).getTime();
    }
    if (a < b) {
      return sortValues[0];
    }
    if (a > b) {
      return sortValues[1];
    }
    return 0;
  });
  return arr;
};
