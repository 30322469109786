import {styled} from "@mui/material";

const DismissButton = styled('button')(
  {
    height: 30,
    display: 'block',
    marginLeft: 'auto',
    borderRadius: 4,
    outline: 'none',
    border: '1px solid',
    cursor: 'pointer',
    textTransform: 'uppercase',
    fontSize: 12,
  },
  ({ theme, disabled }) => ({
    pointerEvents: disabled ? 'none' : 'all',
    borderColor: disabled
      ? theme.palette.novotalk.$textColorLightGray
      : theme.palette.novotalk.$textColorDisabledLight,
    color: disabled ? theme.palette.novotalk.$textColorLightGray : theme.palette.novotalk.$textColorDisabledLight,
    '&:hover': {
      backgroundColor: theme.palette.novotalk.$secondaryButtonColorHover,
      borderColor: theme.palette.novotalk.$secondaryButtonColorHover,
      color: theme.palette.novotalk.$textColorReversed,
    },
    '&:active': {
      backgroundColor: theme.palette.novotalk.$secondaryButtonColorActive,
      borderColor: theme.palette.novotalk.$secondaryButtonColorActive,
      color: theme.palette.novotalk.$textColorReversed,
    },
  })
);

export default DismissButton;
