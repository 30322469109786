export const Types = {
  SIGN_IN: 'SIGN_IN',
  SIGN_IN_SUCCESS: 'SIGN_IN_SUCCESS',
  SIGN_IN_FAIL: 'SIGN_IN_FAIL',
};

export const signInSuccess = (payload) => ({
  type: Types.SIGN_IN_SUCCESS,
  payload,
});
export const signInFail = (error) => ({
  type: Types.SIGN_IN_FAIL,
  payload: { error },
});
export const onSignIn = (email, password) => ({
  type: Types.SIGN_IN,
  payload: { email, password },
});
