export const TYPES = {
  SET_REDIRECT_URL: 'SET_REDIRECT_URL',
  REMOVE_REDIRECT_URL: 'REMOVE_REDIRECT_URL',
};

export const setRedirectUrl = (url, stateToPass) => ({
  type: TYPES.SET_REDIRECT_URL,
  url,
  stateToPass,
});

export const removeRedirectUrl = () => ({
  type: TYPES.REMOVE_REDIRECT_URL,
});

