import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { compose } from 'redux';
import _ from 'lodash';
import { differenceInSeconds } from 'date-fns';
import { withTranslation } from 'react-i18next';
import injectReducer from '@utils/injectReducer';
import injectSaga from '@utils/injectSaga';

import { ROUTES } from '@shared/constants';
import SectionLoading from '@components/SectionLoading';
import {
  getUSADateFromFullDate,
  getUSATimeFromFullDate,
  addMinutesToUSATime,
  addDaysToUSADate,
  getDaysFromMinutes,
} from '@utils/timeHelpers';
import Text, {
  TEXT_SIZE,
  TEXT_WEIGHT,
  TEXT_COLOR,
} from '@components/Text';
import { BackButton } from '@components/BackButton';
import { openPopUp } from '../../../App/containers/PopUp/actions';
import POPUP_TYPES from '../../../App/containers/PopUp/types';

import AutoFeedbackTextArea from './containers/AutoFeedbackTextArea';
import ExerciseItem from './containers/ExerciseItem';
import FeedbackPost from './containers/FeedbackPost';

import {
  getUserActivity,
  postFeedback,
  getExerciseProductions,
} from './actions';
import { ACTIVITY_TYPES, MONGO_ID_LENGTH } from './constants';
import reducer from './reducer';
import saga from './saga';
import {
  Wrapper,
  MainSection,
  ActivityHeader,
  HeaderTitle,
  StyledDismissButton,
  HeaderSectionTitleWrapper,
  TextWithSpaces,
  PostsSection,
  UserInfoContainer,
  UserAvatar,
  SectionWrapper,
  SubTitleWrapper,
  SliderView,
  StyledArrowRightIcon,
  StyledArrowLeftIcon,
  SliderItem,
} from './styles';
import {
  getStatusText,
  STATUS_TYPES,
} from '../PendingDashboard/shared/SharedComponents';
import { PracticeErrorsCard } from '../UserPage/user-page-wrapper/components/user-activity-log/components/activity-panels/components/practice-errors-card';

class ActivityPage extends Component {
  componentDidMount() {
    const { activityType, activityId, activity } = this.props.match.params;
    if (!this.validateActivityType(activityType)) return;
    if (!this.validateActivityId(activityId)) return;

    if (!activity || activity.id !== activityId) {
      this.props.dispatchGetUserActivity(activityType, activityId);
    }
  }

  onPostClicked = (text) => {
    this.props.dispatchPostFeedback(this.props.activity, text);
  };

  onBackClicked = () => {
    this.props.history.push(ROUTES.PENDING_DASHBOARD);
  };

  onDismiss = () => {
    const { dispatchDismissActivity, dispatchOpenPopup, activity } = this.props;
    dispatchOpenPopup(POPUP_TYPES.CONFIRM_DISMISS_ACTIVITY, {
      onConfirm: (reason) => dispatchDismissActivity(activity, reason),
      hideCloseIcon: true,
    });
  };

  onGetProductions = (activityId) => {
    this.props.dispatchGetExerciseProductions(activityId);
  };

  getErrorMessage = () => {
    const { activityType, activityId } = this.props.match.params;
    if (!this.validateActivityType(activityType)) { return this.props.t('Informative.Negative.invalidActivityType', { type: activityType }); }
    if (!this.validateActivityId(activityId)) { return this.props.t('Informative.Negative.invalidActivityId'); }
    return this.props.error;
  };

  getActivityDuration = () => {
    const {
      activity: { activityCreated, exercises },
    } = this.props;
    if (exercises.length === 0) return;
    const sortedExercisesByDate = exercises.sort(
      (ex1, ex2) => new Date(ex2.activityCreated.at) - new Date(ex1.activityCreated.at),
    );
    const latestExerciseDate = new Date(parseInt(sortedExercisesByDate[sortedExercisesByDate.length - 1].activityCreated.at));
    const practiceDate = new Date(parseInt(activityCreated.at));
    return differenceInSeconds(latestExerciseDate, practiceDate);
  };

  getProductionForExercise = ({ id }) => this.props.loadedProductions[id] || { loading: true };

  validateActivityId = (activityId) => activityId.length === MONGO_ID_LENGTH;

  validateActivityType = (activityType) => Boolean(ACTIVITY_TYPES[activityType]);

  headerSectionTitle = (message) => (
    <HeaderSectionTitleWrapper>
      <Text
        size={TEXT_SIZE.H5}
        textColor={TEXT_COLOR.DISABLED}
        weight={TEXT_WEIGHT.BOLD}
      >
        <span>{message}</span>
      </Text>
    </HeaderSectionTitleWrapper>
  );

  renderPosts = () => {
    const {
      activity: { Threads },
      postsLoading,
      postingFeedback,
    } = this.props;
    const posts = _.get(Threads[0], 'Posts', []);
    return (
      <PostsSection>
        <AutoFeedbackTextArea
          className="feedbackInput"
          disabled={postingFeedback}
          onPostClicked={this.onPostClicked}
          hasError={!!this.props.errorFeedback}
        />
        {posts.length > 0 && (
          <>
            {this.renderSubTitle('UI.previousFeedback')}
            {posts.map((post) => (
              <FeedbackPost
                className="feedbackPost"
                key={`${post.id}`}
                post={post}
                postsLoading={postsLoading}
              />
            ))}
          </>
        )}
      </PostsSection>
    );
  };

  renderError = (errorMsg) => (
    <div>
      <Text className="errorMessage">{errorMsg}</Text>
    </div>
  );

  renderHeader = () => {
    const {
      activity: {
        Threads,
        summary,
        user,
        entity,
        ParentSkill: {
          name: skillName,
        },
        type,
        activityCreated,
      },
      t,
    } = this.props;
    const statusMessage = t(`Common.Statuses.${summary.hasPassed ? 'complete' : 'incomplete'}`);
    const durationInMinutes = this.getActivityDuration();
    const minutes = Math.floor(durationInMinutes / 60)
      .toString()
      .padStart(2, '0');
    const days = Math.floor(getDaysFromMinutes(minutes));
    let startDate = 'N \\ A';
    let startTime = '';
    let endTime = '';
    let endDate = '';

    if (activityCreated) {
      startDate = getUSADateFromFullDate(activityCreated.at);
      startTime = getUSATimeFromFullDate(activityCreated.at);
      endDate = addDaysToUSADate(activityCreated.at, days);
      endTime = addMinutesToUSATime(startTime, minutes);
    }
    const textProps = {
      size: TEXT_SIZE.H4,
      textColor: TEXT_COLOR.DARK_BLUE,
    };
    const posts = _.get(Threads[0], 'Posts', []);
    return (
      <ActivityHeader className="title">
        <HeaderTitle>
          <Text
            size={TEXT_SIZE.TL0}
            textColor={TEXT_COLOR.DARK_BLUE}
            weight={TEXT_WEIGHT.BOLD}
            className="activityName"
          >
            {entity.name}
          </Text>
          <StyledDismissButton
            className="dismissButton"
            onClick={this.onDismiss}
            disabled={posts.length > 0}
          >
            {t('Actions.Instance.dismiss')}
          </StyledDismissButton>
        </HeaderTitle>
        <div className="parentSkill">
          {this.headerSectionTitle(t('Common.UI.singleSkill').toUpperCase())}
          <Text {...textProps} className="activitySkillName">
            {skillName}
          </Text>
        </div>
        <div>
          {this.headerSectionTitle(t('UI.activityType').toUpperCase())}
          <Text {...textProps} className="activityType">
            {_.capitalize(type.replace('ACTIVITY_', ''))}
          </Text>
        </div>
        <div>
          {this.headerSectionTitle(t('Common.UI.status'))}
          {getStatusText(
            <div className="activityStatus">
              {statusMessage}
            </div>,
            summary.hasPassed ? STATUS_TYPES.DONE : STATUS_TYPES.FAILED,
          )}
        </div>
        <div className="activityStartTime">
          {this.headerSectionTitle(t('UI.startTime').toUpperCase())}
          <TextWithSpaces
            {...textProps}
            className="startTime"
          >
            {`${startDate} ${startTime}`}

          </TextWithSpaces>
        </div>
        <div className="activityLength">
          {this.headerSectionTitle(t('UI.endTime').toUpperCase())}
          <TextWithSpaces
            {...textProps}
            className="endTime"
          >
            {`${endDate} ${endTime}`}

          </TextWithSpaces>
        </div>
        {user && (
          <UserInfoContainer className="user">
            <UserAvatar className="userAvatar" src={user.avatar} />
            <TextWithSpaces
              {...textProps}
              weight={TEXT_WEIGHT.BOLD}
              className="userName"
            >
              {user.fullName}
            </TextWithSpaces>
          </UserInfoContainer>
        )}
      </ActivityHeader>
    );
  };

  renderExercises = () => {
    const {
      activity: { exercises },
    } = this.props;
    const exerciseItems = [...exercises].reverse();

    return (
      <SectionWrapper>
        {this.renderSubTitle('Common.UI.exercise')}
        {exerciseItems.map((exercise, index) => (
          <ExerciseItem
            key={`${exercise.id}_${index}`}
            exerciseIndex={String(index + 1)}
            className="exercise"
            exercise={exercise}
            onGetProductions={this.onGetProductions}
            productions={this.getProductionForExercise(exercise)}
          />
        ))}
      </SectionWrapper>
    );
  };

  renderSubTitle = (message) => (
    <SubTitleWrapper>
      <Text
        weight={TEXT_WEIGHT.BOLD}
        size={TEXT_SIZE.T1}
        textColor={TEXT_COLOR.DARK_BLUE}
      >
        {this.props.t(message)}
      </Text>
    </SubTitleWrapper>
  );

  renderPracticeErrors = () => {
    const { activity: { practiceErrors } } = this.props;
    if (!practiceErrors) return null;
    const settings = {
      infinite: false,
      speed: 1000,
      slidesToShow: practiceErrors.length >= 2 ? 2 : 1,
      slidesToScroll: 1,
      centerPadding: 30,
      arrows: true,
    };
    return (
      <SliderView {...settings}>
        {practiceErrors.map((error) => (
          <SliderItem>
            <PracticeErrorsCard data={error} />
          </SliderItem>
        ))}
      </SliderView>
    );
  };

  renderSummary = () => {
    const { summary } = this.props.activity;
    return (
      <SectionWrapper>
        {this.renderSubTitle('UI.practiceOverview')}
        {summary.hasPassed
          ? this.renderPracticeErrors()
          : (
            <Text className="noSummaryForIncomplete">
              {this.props.t('Informative.Negative.practiceOverview')}
            </Text>
          )}
      </SectionWrapper>
    );
  };

  render() {
    const errorMsg = this.getErrorMessage();
    if (errorMsg) return this.renderError(errorMsg);

    const { loadingActivity } = this.props;
    return loadingActivity ? (
      <SectionLoading className="pageLoading" />
    ) : (
      <Wrapper>
        <MainSection>
          <BackButton
            onClick={this.onBackClicked}
            text={this.props.t('Actions.Navigation.backToDashboard')}
            margin="0 0 8px -8px"
          />
          {this.renderHeader()}
          {this.renderSummary()}
          {this.renderExercises()}
        </MainSection>
        {this.renderPosts()}
      </Wrapper>
    );
  }
}

ActivityPage.propTypes = {
  error: PropTypes.string,
  loadingActivity: PropTypes.bool,
  postingFeedback: PropTypes.bool,
  activity: PropTypes.shape({
    entity: PropTypes.shape({
      name: PropTypes.string,
    }),
    Threads: PropTypes.arrayOf(
      PropTypes.shape({
        Posts: PropTypes.arrayOf(
          PropTypes.shape({
            payload: PropTypes.object,
            createdAt: PropTypes.string,
          }),
        ),
      }),
    ),
    user: PropTypes.shape({
      fullName: PropTypes.string,
    }),
    summary: PropTypes.shape({
      achievedMasteryLevel: PropTypes.number,
      insights: PropTypes.array,
      hasPassed: PropTypes.bool,
    }),
    Exercises: PropTypes.array,
    ParentSkill: PropTypes.shape({
      name: PropTypes.string,
    }),
  }),
  loadedProductions: PropTypes.object,
  dispatchGetUserActivity: PropTypes.func,
  dispatchDismissActivity: PropTypes.func,
  dispatchPostFeedback: PropTypes.func,
  dispatchOpenPopup: PropTypes.func,
  dispatchGetExerciseProductions: PropTypes.func,

  // from router
  match: PropTypes.object,
  history: PropTypes.object,
};

function mapDispatchToProps(dispatch) {
  return {
    dispatchGetUserActivity: (activityType, activityId) => dispatch(getUserActivity(activityType, activityId)),
    dispatchOpenPopup: (popupType, options) => dispatch(openPopUp(popupType, options)),
    dispatchPostFeedback: (activity, text) => dispatch(postFeedback(activity, text)),
    dispatchDismissActivity: (activity, reason) => dispatch(postFeedback(activity, reason, true)),
    dispatchGetExerciseProductions: (id) => dispatch(getExerciseProductions(id)),
  };
}

const mapStateToProps = (state) => state.get('activityPage').toJS();

const withReducer = injectReducer({ key: 'activityPage', reducer });
const withSaga = injectSaga({ key: 'activityPage', saga });
const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default compose(
  withReducer,
  withConnect,
  withRouter,
  withSaga,
  withTranslation(),
)(ActivityPage);
