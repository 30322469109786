export const ExerciseFields = [
  {
    type: "QUESTION",
    id: null,
    payload: {
      type: "FREE_TEXT",
      mandatory: true,
      questionText: "Instructions",
      mapToField: "texts.instructions",
      value: "",
    },
  },
  {
    type: "MEDIA",
    id: null,
    payload: {
      contentType: "video",
      url: "",
      description: "",
      mandatory: true,
      title: "Demo",
      mapToField: "media.demo",
      value: null,
      id: null,
    },
  },
  {
    type: "MONITOR",
    id: null,
    payload: {
      type: "MONITOR",
      mandatory: true,
      mapToField: "monitor",
      config: {
        monitor: {
          type: "",
          inhalation: {
            on: false,
            value: 0,
          },
          video: {
            on: false,
            url: null,
            id: null,
          },
          mirror: {
            on: false,
          },
          speedometer: {
            on: false,
          },
        },
        pattern: {
          type: "WAVES",
          productionDuration: 4,
        },
        errors: {
          on: false,
          masteryLevel: 0,
          production: {
            tooShort: {
              on: false,
              type: "EASY",
            },
            tooLong: {
              on: false,
              type: "EASY",
            },
            notLoud: {
              on: false,
              type: "EASY",
            },
            notGentle: {
              on: false,
              type: "EASY",
            },
            noPatern: {
              on: false,
              type: "EASY",
            },
          },
          specific: {
            beginning: {
              nonGentleOnset: {
                on: false,
                type: "EASY",
              },
              notGentle: {
                on: false,
                type: "EASY",
              },
              volumeControl: {
                on: false,
                type: "EASY",
              },
            },
            peak: {
              notLoud: {
                on: false,
                type: "EASY",
              },
              softPeak: {
                on: false,
                type: "EASY",
              },
              execessivePeak: {
                on: false,
                type: "EASY",
              },
            },
            end: {
              volumeControl: {
                on: false,
                type: "EASY",
              },
              nonGentleOffset: {
                on: false,
                type: "EASY",
              },
            },
          },
        },
        progress: {
          type: "SEQUENCES",
          value: 1,
          repetition: {
            on: false,
            count: 1,
          },
        },
        stimulus: {
          type: "SYLLABLES",
          config: {
            syllableStructure: {
              cv: false,
              vc: false,
              cvc: false,
            },
          },
        },
      },
    },
  },
];

export const LessonFields = [
  {
    type: "QUESTION",
    id: null,
    payload: {
      type: "FREE_TEXT",
      mandatory: true,
      questionText: "Description",
      mapToField: "texts.description",
      value: "",
    },
  },
  {
    type: "QUESTION",
    id: null,
    payload: {
      type: "FREE_TEXT",
      mandatory: true,
      questionText: "Instructions",
      mapToField: "texts.instructions",
      value: "",
    },
  },
  {
    type: "MEDIA",
    id: null,
    payload: {
      contentType: "video",
      url: "",
      description: "",
      mandatory: true,
      title: "Demo",
      mapToField: "media.demo",
      value: null,
      id: null,
    },
  },
  {
    type: "MEDIA",
    id: null,
    payload: {
      contentType: "video",
      url: "",
      description: "",
      mandatory: true,
      title: "Lesson",
      mapToField: "media.lesson",
      value: null,
      id: null,
    },
  },
  {
    type: "MONITOR",
    id: null,
    payload: {
      type: "MONITOR",
      mandatory: true,
      mapToField: "monitor",
      config: {
        monitor: {
          type: "",
          inhalation: {
            on: false,
            value: 0,
          },
          video: {
            on: false,
            url: null,
            id: null,
          },
          mirror: {
            on: false,
          },
          speedometer: {
            on: false,
          },
        },
        pattern: {
          type: "WAVES",
          productionDuration: 4,
        },
        errors: {
          on: false,
          masteryLevel: 0,
          inhalation: {
            tooShort: {
              on: false,
            },
          },
          production: {
            tooShort: {
              on: false,
              type: "EASY",
            },
            tooLong: {
              on: false,
              type: "EASY",
            },
            notLoud: {
              on: false,
              type: "EASY",
            },
            notGentle: {
              on: false,
              type: "EASY",
            },
            noPattern: {
              on: false,
              type: "EASY",
            },
          },
          specific: {
            beginning: {
              nonGentleOnset: {
                on: false,
                type: "EASY",
              },
              volumeControl: {
                on: false,
                type: "EASY",
              },
            },
            peak: {
              softPeak: {
                on: false,
                type: "EASY",
              },
              excessivePeak: {
                on: false,
                type: "EASY",
              },
            },
            end: {
              volumeControl: {
                on: false,
                type: "EASY",
              },
              nonGentleOffset: {
                on: false,
                type: "EASY",
              },
            },
          },
        },
        progress: {
          type: "SEQUENCES",
          value: 1,
          repetition: {
            on: false,
            count: 0,
          },
        },
        stimulus: {
          type: "SYLLABLES",
          config: {
            division: "DIVIDED",
            syllableCount: {
              min: 1,
              max: 1,
            },
            syllableStructure: {
              c: false,
              cv: false,
              vc: false,
              cvc: false,
            },
            consonantsFeatures: {
              PLOSIVE: false,
            },
          },
        },
      },
    },
  },
];

export const SkillFields = [
  {
    type: "QUESTION",
    id: null,
    payload: {
      type: "FREE_TEXT",
      mandatory: true,
      questionText: "Description",
      mapToField: "texts.description",
      value: "",
    },
  },
];

export const CourseFields = [
  {
    type: "QUESTION",
    id: null,
    payload: {
      type: "FREE_TEXT",
      mandatory: true,
      questionText: "Description",
      mapToField: "texts.description",
      value: "",
    },
  },
];

export const PollFields = [
  {
    type: "QUESTION",
    id: 0,
    payload: {
      type: "FREE_TEXT",
      mandatory: true,
      questionText: "Description",
      mapToField: "description",
      value: "",
    },
  },
];
