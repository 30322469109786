/**
*
* ResourceOverviewPopup
*
*/

import React from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { Wrapper } from './styles';
import ResourceOverview from '@shared/ResourceOverview';

export const ResourceOverviewPopup = ({ onEdit, onClose, resource, loading, onExpand, type }) =>
  (<Wrapper>
    <ResourceOverview
      readOnly
      loading={loading}
      onEdit={(...args) => {
        onClose();
        onEdit(...args);
      }}
      onExpand={onExpand}
      type={type}
      resource={resource}
    />
  </Wrapper>);

ResourceOverviewPopup.propTypes = {
  type: PropTypes.string,
  onEdit: PropTypes.func,
  onClose: PropTypes.func,
  onExpand: PropTypes.func,
  resource: PropTypes.object,
  loading: PropTypes.bool,
};

const mapStateToProps = (state) => {
  const { popup } = state.get('resourceManager') ? state.get('resourceManager').toJS() : {};
  return popup;
};

const withConnect = connect(mapStateToProps);

export default compose(withConnect)(ResourceOverviewPopup);
