import {styled} from "@mui/material";
import Container from '@components/Container';
import {
  getIconsByType,
  getIconByResourceType,
  iconTypes,
} from '@services/icons';
import { getFlexibleSize, getResponsiveCSS } from '~/utils/cssHelpers';
import courseTypes from '../../../../../../helpers/courseTypes';
import courseStatusConsts from '../../../../../../helpers/courseProgressConsts';

const PracticeIcon = getIconByResourceType(courseTypes.PRACTICE);
const ClockIcon = getIconsByType(iconTypes.CLOCK);
const { LOCKED, COMPLETED } = courseStatusConsts;

export const StyledPracticeIcon = styled(PracticeIcon)(
  {
    fontSize: 30,
      minWidth: 20,
  },
  ({ theme }) => ({
    marginRight: theme.RTL ? -5 : 6,
    marginLeft: theme.RTL ? 6 : -5,
    color: theme.palette.novotalk.$textColorDarkBlue,
  })
);

export const StyledClockIcon = styled(ClockIcon)(
  {
    fontSize: 17,
    display: 'flex',
  },
  ({ theme }) => ({
    color: theme.palette.novotalk.$textColorDisabledLight,
  })
);

export const StyledCheckMarkIcon = styled(
  getIconsByType(iconTypes.BOLD_CHECKMARK)
)(
  {
    position: 'absolute',
    left: 2,
    top: 2,
    width: 12,
    height: 12,
  },
  ({ theme }) => ({
    color: theme.palette.novotalk.$textColorReversed,
  })
);

export const StyledContainer = styled(Container)(
  {
    marginBottom: 5,
  },
  ({ theme }) => ({
    [theme.RTL ? 'marginRight' : 'marginLeft']: 30,
  })
);

export const Wrapper = styled('div')(
  {
    padding: '70px 0 25px',
    borderLeft: '1px solid',
    position: 'relative',
    zIndex: '100',
    overflowY: 'auto',
    gridArea: 'ExercisesPane',
  },
  getResponsiveCSS('width', 335, 380),
  ({ theme, language }) => ({
    direction: theme.RTL ? 'rtl' : 'ltr',
    minHeight: `calc(100vh - ${theme.palette.novotalk.navigationBarHeight}px)`,
    maxHeight: `calc(100vh - ${theme.palette.novotalk.navigationBarHeight}px)`,
    backgroundColor: theme.palette.novotalk.$backgroundColor,
    borderColor: theme.palette.novotalk.$borderColorLight,
    paddingLeft: theme.RTL ? 10 : 28,
    [language === 'en' ? 'borderLeft' : 'borderRight']: `1px solid ${theme.palette.novotalk.$borderColorLight}`,
      ...getResponsiveCSS('paddingRight', theme.RTL ? 28 : 10, 55),
  })
);

export const HeaderWrapper = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start',
  height: 170,
  '@media(min-width: 1280px)': {
    marginLeft: getFlexibleSize(0, 4),
    height: getFlexibleSize(170, 210),
  },
  '@media(min-width: 1920px)': {
    marginLeft: 4,
    height: 210,
  },
});

export const Title = styled('div')({
  display: 'flex',
});

export const ClockIconWrapper = styled('div')(({ theme }) => ({
  [theme.RTL ? 'marginLeft' : 'marginRight']: 8,
  color: theme.palette.novotalk.textColor,
}));

export const PartsWrapper = styled('div')({
  display: 'flex',
  flexDirection: 'column',
});

export const PartWrapper = styled('div')(
  {
    display: 'flex',
    flexDirection: 'column',
    paddingBottom: 60,
    position: 'relative',
  },
  getResponsiveCSS('minHeight', 104, 116),
  getResponsiveCSS('width', 150, 160, '& div.progressBar'),
  ({ theme, last, progress }) =>
    !last && {
      '&:after': {
        content: '""',
        display: 'block',
        backgroundColor:
          progress === COMPLETED ? theme.palette.novotalk.$activeColor : theme.palette.novotalk.$borderColorLight,
        width: 2,
        position: 'absolute',
        top: 18,
        bottom: 0,
        [theme.RTL ? 'right' : 'left']: 9,
        zIndex: -1,
      },
    }
);

export const TitleWrapper = styled('div')(
  {
    display: 'flex',
    alignItems: 'flex-start',
  },
  ({ isLocked }) => ({
    cursor: isLocked ? 'not-allowed' : 'pointer',
  })
);

const circleSize = 20;

export const Circle = styled('div')(
  {
    borderRadius: '50%',
    border: '2px solid',
    minWidth: circleSize,
    maxWidth: circleSize,
    maxHeight: circleSize,
    minHeight: circleSize,
    display: 'inline-block',
    position: 'relative',
    textAlign: 'center',
  },
  ({ theme, progress }) => {
    switch (progress) {
      case COMPLETED:
        return {
          borderColor: theme.palette.novotalk.$activeColor,
          backgroundColor: theme.palette.novotalk.$activeColor,
        };
      case LOCKED:
        return {
          borderColor: theme.palette.novotalk.$borderColorLight,
          backgroundColor: theme.palette.novotalk.$backgroundColor,
        };
      default:
        return {
          borderColor: theme.palette.novotalk.$activeColor,
          backgroundColor: theme.palette.novotalk.$backgroundColor,
        };
    }
  }
);

export const FeedbackWrapper = styled('div')(({ theme }) => ({
  [theme.RTL ? 'marginRight' : 'marginLeft']: 32,
}));

export const TipsWrapper = styled('div')(({ theme }) => ({
  [theme.RTL ? 'marginRight' : 'marginLeft']: 42,
}));

export const IconWrapper = styled('div')({
    width: 30,
    height: 30
})
