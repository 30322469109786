/**
 *
 * PollQuestionTitle
 *
 */

import React from 'react';
import PropTypes from 'prop-types';
import Text from '@components/Text';
import ErrorMessage from '@components/ErrorMessage';
import { QuestionText, RequiredSign } from './styles';

const PollQuestionTitle = ({
  questionIndex,
  questionText,
  error,
  type = 'CHOOSE',
  required,
}) => {
  const index = questionIndex ? `${questionIndex}. ` : '';
  return (
    <QuestionText>
      <Text className='question' size='T3' weight='900'>
        {`${index}${questionText}`}
      </Text>
      {required && <RequiredSign>*</RequiredSign>}
      {error && <ErrorMessage className='errorMessage' type={type} />}
    </QuestionText>
  );
};

PollQuestionTitle.propTypes = {
  questionIndex: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  questionText: PropTypes.string,
  error: PropTypes.bool,
  type: PropTypes.string,
};

export default PollQuestionTitle;
