import React from 'react';
import PropTypes from 'prop-types';
import { LoaderWrapper, LoaderCentralizer } from './styles';

const SectionLoading = ({ size = 75 }) => {
  const dashWidth = 6;
  const radius = size / 2;
  const dashLength = radius * Math.PI * 2;

  return (
    <LoaderCentralizer>
      <LoaderWrapper>
        <svg width={size} height={size} viewBox={`0 0 ${size} ${size}`}>
          <circle
            cx={radius}
            cy={radius}
            r={radius - dashWidth}
            strokeWidth={dashWidth}
            strokeDasharray={dashLength}
            strokeDashoffset={0}
          >
            <animate
              attributeName='stroke-dashoffset'
              from='0'
              to={-2 * dashLength}
              dur='2.5s'
              keySplines='0.1 0.8 0.2 1'
              repeatCount='indefinite'
              fill='freeze'
            />
            <animateTransform
              attributeName='transform'
              attributeType='XML'
              type='rotate'
              from={`0 ${radius} ${radius}`}
              to={`360 ${radius} ${radius}`}
              dur='2.5s'
              repeatCount='indefinite'
              fill='freeze'
            />
          </circle>
        </svg>
      </LoaderWrapper>
    </LoaderCentralizer>
  );
};

SectionLoading.propTypes = {
  size: PropTypes.number,
};

export default SectionLoading;
