export const Types = {
  PASSWORD_RESET: 'PASSWORD_RESET',
  PASSWORD_RESET_FAIL: 'PASSWORD_RESET_FAIL',
  PASSWORD_RESET_SUCCESS: 'PASSWORD_RESET_SUCCESS',
  PASSWORD_CHANGE_SUCCESS: 'PASSWORD_CHANGE_SUCCESS',
  CLEAR_REDIRECT: 'CLEAR_REDIRECT',
};

export const onPasswordReset = (recoveryToken, password, isPasswordChange) => ({
  type: Types.PASSWORD_RESET,
  payload: { recoveryToken, password, isPasswordChange },
});

export const onPasswordResetSuccess = () => ({
  type: Types.PASSWORD_RESET_SUCCESS,
});

export const onPasswordResetFail = (error) => ({
  type: Types.PASSWORD_RESET_FAIL,
  payload: { error },
});

export const onPasswordChangeSuccess = (token) => ({
  type: Types.PASSWORD_CHANGE_SUCCESS,
  token,
});
export const onClearRedirect = () => ({
  type: Types.CLEAR_REDIRECT,
});
