import {styled} from "@mui/material";
import { getIconsByType, iconTypes } from '@services/icons';
import { Link } from 'react-router-dom';
import {
  CardNumberElement,
  CardExpiryElement,
  CardCVCElement,
  PostalCodeElement,
} from 'react-stripe-elements';
import CTAButton from '@components/CTAButton';
import { getResponsiveCSS } from '@utils/cssHelpers';

export const StyledForm = styled('form')({
  display: 'flex',
  flexFlow: 'row wrap',
  justifyContent: 'space-between',
});

export const StyledLabel = styled('label')(
  {
    display: 'flex',
    flexDirection: 'column',
    '& .custom-tooltip-wrapper': {
      display: 'inline-block',
      marginLeft: 5,
    },
    '& .custom-tooltip-icon': {
      fontSize: 21,
      marginTop: -3,
    },
  },
  getResponsiveCSS('height', 98, 106),
  ({ theme }) => ({
    '& .custom-tooltip-icon': {
      color: theme.palette.novotalk.$inputBorderColor,
    },
  })
);

export const textStyles = (theme) => ({
  base: {
    fontSize: 14,
    color: theme.palette.novotalk.$textColorDarkBlue,
    '::placeholder': {
      color: theme.palette.novotalk.$inputBorderColor,
    },
  },
  invalid: {
    color: theme.palette.novotalk.$errorColor,
  },
});

const cardFieldStyles = ({ theme, hasError }) => ({
  width: 155,
  height: 44,
  padding: 13,
  marginTop: 7,
  marginBottom: 4,
  borderRadius: 4,
  cursor: 'text',
  border: `1px solid ${hasError ? theme.palette.novotalk.$errorColor : theme.palette.novotalk.$inputBorderColor}`,
  '&:hover': {
    borderColor: theme.palette.novotalk.$inputBorderColorHover,
  },
  '&.StripeElement--focus': {
    borderColor: theme.palette.novotalk.$inputBorderColorActive,
  },
  '&.StripeElement--invalid': {
    borderColor: theme.palette.novotalk.$errorColor,
  },
  '@media(min-width: 1920px)': {
    height: 48,
    padding: 15,
  },
});

export const StyledCardNumberElement = styled(CardNumberElement, {
  shouldForwardProp: (prop) => prop !== 'hasError',
})(cardFieldStyles, {
  width: 330,
});

export const StyledCardExpiryElement = styled(CardExpiryElement, {
  shouldForwardProp: (prop) => prop !== 'hasError',
})(cardFieldStyles);
export const StyledCardCVCElement = styled(CardCVCElement, {
  shouldForwardProp: (prop) => prop !== 'hasError',
})(cardFieldStyles);
export const StyledPostalCodeElement = styled(PostalCodeElement, {
  shouldForwardProp: (prop) => prop !== 'hasError',
})(cardFieldStyles);

export const CardNumberContainer = styled('span')({
  position: 'relative',
});

const CardIcon = getIconsByType(iconTypes.CARD);

export const StyledCardIcon = styled(CardIcon)(
  {
    position: 'absolute',
    top: 15,
    right: 12,
    fontSize: 28,
  },
  ({ theme }) => ({
    color: theme.palette.novotalk.$inputBorderColor,
  })
);

export const FormFooter = styled('div')(
  {
    display: 'flex',
    alignItems: 'baseline',
    paddingTop: 30,
    marginTop: 14,
  },
  ({ theme }) => ({
    borderTop: `1px solid ${theme.palette.novotalk.$borderColorLight}`,
  })
);

export const FormButton = styled(CTAButton)(
  {
    width: 210,
    borderRadius: 22,
    marginRight: 20,
  },
  getResponsiveCSS('height', 44, 48)
);

export const StyledLink = styled(Link)({
  textDecoration: 'none',
  padding: '0 5px',
});

export const UpperWrapper = styled('div')({
  padding: 20,
  borderRadius: 20,
  boxShadow: 'rgba(149, 157, 165, 0.2) 0px 8px 24px',
});
export const CardElementWrapper = styled('div')({
  padding: 20,
  borderRadius: 20,
  border: '1px solid grey',
});
export const FormButtonWrapper = styled('div')({
  paddingTop: 20,
});
