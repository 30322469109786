import React from 'react';
import {
    SectionTitle
} from "@containers/Admin/containers/UserPage/user-page-wrapper/components/UserPersonalProgram/components/ListItemSection/styles";
import {
    SkillsItem
} from "@containers/Admin/containers/UserPage/user-page-wrapper/components/UserPersonalProgram/components/SkillsItem";
import {RESOURCES} from "@shared/Resources/types";
import {useTranslation} from "react-i18next";
import {ILesson} from "@containers/Admin/containers/UserPage/user-page-wrapper/components/UserPersonalProgram/types";

interface LessonsSectionProps {
    lessons: ILesson[]
}

export const LessonsSection:React.FC<LessonsSectionProps> = ({lessons}) => {
    const {t} = useTranslation()

    return (
        <>
            <SectionTitle>
                {t('Common.UI.lesson')}
            </SectionTitle>
            {
                lessons.map((lesson) => (
                    <SkillsItem
                        key={lesson.name}
                        resource={lesson}
                        type={RESOURCES.LESSON}
                        isFirstElement={false}
                        isCurrentLevel={lesson.isAccessible && !lesson.hasPassed}
                    />
                ))
            }
        </>
    );
};