import React from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { openPopUp } from '@containers/App/containers/PopUp/actions';
import POPUP_TYPES from '@containers/App/containers/PopUp/types';
import MediaViewer from '@shared/MediaViewer';
import {
  MediaPageContainer,
  StyledButton,
  UploadContainer,
  InputWrapper,
} from './styles';
import {withTranslation} from "react-i18next";

class MediaPage extends React.PureComponent {
  getHiddenInput = () => (
    <InputWrapper
      id='file-input'
      name='name'
      type='file'
      ref={(input) => (this.inputElement = input)}
    />
  );

  showItem = (item) => {
    this.props.dispatchOpenPopup(POPUP_TYPES.MEDIA, {
      description: item.title,
      contentUrl: item.value,
      contentType: item.type,
    });
  };

  openModal = () => this.props.dispatchOpenPopup(POPUP_TYPES.MEDIA_UPLOAD);

  render() {
    return (
      <MediaPageContainer data-cy='media_page'>
        <UploadContainer>
          <StyledButton
            data-cy='media_page_upload_button'
            onClick={this.openModal}
          >
            {this.props.t('Actions.Instance.upload')}
          </StyledButton>
        </UploadContainer>
        <MediaViewer
          style={{ gridArea: 'Viewer' }}
          onItemSelect={this.showItem}
        />
      </MediaPageContainer>
    );
  }
}

MediaPage.propTypes = {
  dispatchOpenPopup: PropTypes.func,
};

export function mapDispatchToProps(dispatch) {
  return {
    dispatchOpenPopup: (type, props) => dispatch(openPopUp(type, props)),
  };
}

const withConnect = connect(null, mapDispatchToProps);

export default compose(
    withConnect,
    withTranslation()
)(MediaPage);
