const startResolution = 1280;
export const finalResolution = 1920;

export const getFlexibleSize = (size1, size2) => {
  // get calc css function for sizes for in-between screen resolutions,
  // based on straight line equation y = a + bx
  // sizes should be in pixels
  const a = size1 - ((startResolution / (finalResolution - startResolution)) * (size2 - size1));
  const b = ((size2 - size1) / (finalResolution - startResolution)) * 100; // in percents
  return `calc(${a}px + ${b.toFixed(2)}vw)`;
};

export const getResponsiveCSS = (property, size1, size2, selector) => {
  const styles = {};
  if (selector) {
    styles[selector] = {
      [property]: size1,
    };
    if (Math.abs(size2 - size1) > 4) {
      styles[`@media(min-width: ${startResolution}px)`] = {
        [selector]: {
          [property]: getFlexibleSize(size1, size2),
        },
      };
    }
    styles[`@media(min-width: ${finalResolution}px)`] = {
      [selector]: {
        [property]: size2,
      },
    };
  } else {
    styles[property] = size1;
    if (Math.abs(size2 - size1) > 3) {
      styles[`@media(min-width: ${startResolution}px)`] = {
        [property]: getFlexibleSize(size1, size2),
      };
    }
    styles[`@media(min-width: ${finalResolution}px)`] = {
      [property]: size2,
    };
  }
  return styles;
};
