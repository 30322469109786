import {styled} from "@mui/material";
import CTAButton from '@components/CTAButton';

export const Wrapper = styled('div')(
  {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: '40px 120px',
    borderRadius: '9px',
  },
  ({ theme }) => ({
    backgroundColor: theme.palette.novotalk.textColorReversed,
  })
);

export const VideoWrapper = styled('video')({
  display: 'flex',
  maxWidth: '700px',
});

export const ImageWrapper = styled('img')({
  display: 'flex',
  maxWidth: '700px',
});

export const Title = styled('div')({
  minWidth: '700px',
  maxWidth: '700px',
  display: 'flex',
  alignItems: 'center',
  marginBottom: '20px',
  justifyContent: 'center',
});

export const StyledCTAButton = styled(CTAButton)({
  margin: '35px 0 10px',
});

export const AudioPlayer = styled('audio')({
  marginTop: '10px',
  height: 60,
  maxWidth: 300,
});
