import insights from '../containers/ActivityInsights/__fixtures__';
import videos from '../containers/ActivityVideos/__fixtures__';
import parts from '../containers/ActivityStatsPart/__fixtures__';

const activityData = {
  name: 'Course - Basics',
  type: 'WORKOUT',
  level: 41,
  activityId: 19170,
  timestamp: 1521987867,
  totalDurationInSeconds: 1425,
  analysisMap: {
    breath: {
      total: 40,
      mistakeCount: 6,
      mistakes: {
        'MISTAKES.TOO_SHORT': 4,
        'MISTAKES.TOO_LONG': 2,
      },
    },
    '2sec': {
      total: 8,
      mistakeCount: 7,
      mistakes: {
        'MISTAKES.TOO_SHORT': 2,
        'MISTAKES.TOO_LONG': 5,
      },
    },
  },
  insights,
  videos: videos.videos,
  parts
};

export default activityData;
