import {styled} from "@mui/material";

export const Wrapper = styled('div')(
  {
    display: 'grid',
    gridTemplateColumns: 'minmax(950px, auto)',
    gridTemplateAreas: `
  "SkillPartHeader"
  "LessonMainPane"
  `,
  },
  ({ theme }) => ({
    gridTemplateRows: `${theme.palette.novotalk.skillPartHeaderHeight}px auto`,
    backgroundColor: theme.palette.novotalk.$backgroundColor,
  })
);
