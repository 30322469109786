import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'redux';
import _ from 'lodash';
import injectReducer from '@utils/injectReducer';
import injectSaga from '@utils/injectSaga';
import SectionLoading from '@components/SectionLoading';
import { Wrapper } from './styles';
import { getCurrentLesson, resetLesson, resetActivityLesson } from './actions';
import reducer from './reducer';
import saga from './saga';
import SkillPartHeader from '../../../../components/SkillPartHeader';
import LessonMainPane from './containers/LessonMainPane';

export class Lesson extends Component {
  componentDidMount() {
    const { skill, skillpartIndex, dispatchGetCurrentLesson } = this.props;
    if (!_.isEmpty(skill)) {
      dispatchGetCurrentLesson(skill.Lessons[skillpartIndex].id);
    }
  }

  componentDidUpdate(prevProps) {
    const {
      skill,
      skillpartIndex,
      dispatchGetCurrentLesson,
      lesson,
      loading,
    } = this.props;
    if (
      !_.isEmpty(skill) &&
      lesson?.id !== skill?.Lessons[skillpartIndex]?.id &&
      loading
    ) {
      dispatchGetCurrentLesson(skill.Lessons[skillpartIndex].id);
    }
    if (prevProps.skillpartIndex !== skillpartIndex) {
      dispatchGetCurrentLesson(skill.Lessons[skillpartIndex].id);
    }
  }

  componentWillUnmount() {
    this.props.dispatchResetLesson();
    this.props.dispatchResetActivityLesson();
  }

  render() {
    const { skill, loading, skillpartIndex } = this.props;
    if (loading) {
      return <SectionLoading />;
    }
    const isLastLesson =
      _.get(skill, 'Lessons.length', 0) - 1 === skillpartIndex;
    const lessonMainPaneProps = {
      isLastLesson,
      skillpartIndex,
    };

    return (
      <Wrapper>
        <SkillPartHeader skill={skill} />
        <LessonMainPane {...lessonMainPaneProps} />
      </Wrapper>
    );
  }
}

Lesson.propTypes = {
  dispatchGetCurrentLesson: PropTypes.func,
  dispatchResetLesson: PropTypes.func,
  dispatchResetActivityLesson: PropTypes.func,
  skill: PropTypes.object,
  lesson: PropTypes.object,
  loading: PropTypes.bool,
  skillpartIndex: PropTypes.number,
};

export const mapStateToProps = (state) => {
  const { loading, lesson } = state.getIn(['currentLesson']).toJS();
  return { loading, lesson };
};

export function mapDispatchToProps(dispatch) {
  return {
    dispatchGetCurrentLesson: (id) => dispatch(getCurrentLesson(id)),
    dispatchResetLesson: () => dispatch(resetLesson()),
    dispatchResetActivityLesson: () => dispatch(resetActivityLesson()),
  };
}

const withConnect = connect(mapStateToProps, mapDispatchToProps);
const withReducer = injectReducer({ key: 'currentLesson', reducer });
const withSaga = injectSaga({ key: 'currentLesson', saga });

export default compose(
  withReducer,
  withSaga,
  withConnect
)(Lesson);
