import {styled} from "@mui/material";
import isPropValid from '@emotion/is-prop-valid';
import { getIconByResourceType } from '@services/icons';

export const getStyledTypeIcon = (type) =>
  styled(getIconByResourceType(type), {
    shouldForwardProp: isPropValid,
  })(
    {
      fontSize: 16,
      marginTop: -2,
      marginRight: 6,
      flexShrink: 0,
    },
    ({ theme, isSelected }) => ({
      color: isSelected ? theme.palette.novotalk.$textColorActive : theme.palette.novotalk.$textColorLightGray,
    })
  );

export const CountWrapper = styled('div')({
  display: 'flex',
  alignItems: 'center',
  overflow: 'visible',
});
