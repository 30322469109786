import { styled } from '@mui/material';
import { Link } from 'react-router-dom';
import { getIconsByType, iconTypes } from '@services/icons';
import ErrorMessage from '@components/ErrorMessage';
import { getResponsiveCSS } from '@utils/cssHelpers';
import { USE_PAID_SYSTEM_VERSION } from '@shared/constants';

const CheckmarkIcon = getIconsByType(iconTypes.BOLD_CHECKMARK);

const iconProperties = [
  {
    fontSize: 16,
    marginTop: -2,
  },
  ({ theme }) => ({
    color: theme.palette.novotalk.$activeColor,
    [theme.RTL ? 'marginLeft' : 'marginRight']: 8,
  }),
];

export const StyledCheckmarkIcon = styled(CheckmarkIcon)(...iconProperties);

export const ScreenContainer = styled('div')(
  {
    padding: '40px 0',
  },
  ({ theme }) => ({
    direction: theme.RTL ? 'rtl' : 'inherit',
  }),
);

export const TrialHeaderContainer = styled('div')(
  {
    textAlign: 'center',
  },
  getResponsiveCSS('height', 225, 240),
);

export const StyledLogo = styled('img')(
  {
    marginBottom: 15,
  },
  getResponsiveCSS('width', 460, 495),
);

export const ScreenHeaderTextContainer = styled('div')({
  letterSpacing: '-0.35px',
});

export const ScreenHeaderLinkContainer = styled('div')({
  letterSpacing: '-0.2px',
});

export const MainAreaContainer = styled('div')({
  display: 'grid',
  gridTemplateColumns: USE_PAID_SYSTEM_VERSION ? '1fr 1fr' : 'inherit',
  gridTemplateAreas: '"list form"',
  gridColumnGap: USE_PAID_SYSTEM_VERSION ? 19 : 'inherit',
});

export const ListContainer = styled('div')(
  {
    gridArea: 'list',
    justifySelf: 'end',
    width: 400,
  },
  getResponsiveCSS('width', 400, 442),
  getResponsiveCSS('marginTop', 22, 41),
);

export const ListHeaderContainer = styled('div')({
  marginBottom: 25,
  letterSpacing: '-0.24px',
});

export const ListItemContainer = styled('div')({
  marginBottom: 16,
  letterSpacing: '-0.19px',
});

export const SignUpComponentContainer = styled('div')(
  {
    gridArea: 'form',
    justifySelf: 'start',
    borderRadius: 4,
  },
  getResponsiveCSS('width', 441, 501),
  getResponsiveCSS('paddingTop', 18, 35),
  getResponsiveCSS('paddingBottom', 18, 26),
  getResponsiveCSS('paddingLeft', 30, 38),
  getResponsiveCSS('paddingRight', 30, 38),
  ({ theme }) => ({
    border: `1px solid ${theme.palette.novotalk.$borderColorLight}`,
    backgroundColor: theme.palette.novotalk.$backgroundColorAuthScreens,
  }),
);

export const InputContainer = styled('div')(
  getResponsiveCSS('height', 101, 110),
);

export const DoubleInputContainer = styled('div')(
  {
    display: 'flex',
    justifyContent: 'space-between',
    position: 'relative',
  },
  getResponsiveCSS('height', 101, 110),
);

export const DoubleInputItemContainer = styled('div')(
  getResponsiveCSS('width', 181, 200),
);

export const BirthdateContainer = styled('div')(
  {
    position: 'relative',
    marginTop: -10,
    width: 382,
    '& div.error-message': {
      marginTop: 0,
    },
  },
  getResponsiveCSS('height', 101, 110),
);

export const DatePickerContainer = styled('div')(
  {
    '& #monthSelect>div, & #daySelect>div': {
      minWidth: 94,
      maxWidth: 94,
    },
    '& #yearSelect>div': {
      minWidth: 128,
      maxWidth: 128,
    },
    '& #monthSelect>div:first-of-type, & #daySelect>div:first-of-type': {
      minWidth: 96,
      maxWidth: 96,
      ...getResponsiveCSS('height', 44, 48),
    },
    '& #yearSelect>div:first-of-type': {
      minWidth: 130,
      maxWidth: 130,
      ...getResponsiveCSS('height', 44, 48),
    },
  },
  ({ theme }) => ({
    '& #monthSelect>div:first-of-type, & #daySelect>div:first-of-type': {
      [theme.RTL ? 'marginLeft' : 'marginRight']: 14,
    },
    '& #yearSelect>div:first-of-type': {
      [theme.RTL ? 'marginLeft' : 'marginRight']: 0,
    },
  }),
);

export const DateTooltipContainer = styled('div')(
  {
    position: 'absolute',
  },
  getResponsiveCSS('top', 38, 40),
  ({ theme }) => ({
    [theme.RTL ? 'left' : 'right']: 0,
  }),
);

export const LocationContainer = styled('div')({
  marginTop: -3,
});

export const Label = styled('div')({
  marginBottom: 6,
});

export const StyledDropdownIcon = styled(
  getIconsByType(iconTypes.FILLED_ARROW_DOWN),
)(
  {
    fontSize: 21,
  },
  ({ theme }) => ({
    color: theme.palette.novotalk.$textColorDisabled,
  }),
);

export const countrySelectStyles = (theme) => ({
  indicatorsContainer: (provided) => ({
    ...provided,
    marginRight: 7,
  }),
  indicatorSeparator: () => {
    'none';
  },
  clearIndicator: () => ({
    display: 'none',
  }),
  singleValue: (provided) => ({
    ...provided,
    fontSize: 14,
    color: theme.palette.novotalk.$textColorDarkBlue,
  }),
  option: (provided, state) => ({
    ...provided,
    height: 44,
    paddingLeft: 15,
    display: 'flex',
    alignItems: 'center',
    fontSize: 14,
    color: theme.palette.novotalk.$textColorDarkBlue,
    backgroundColor: state.isSelected
      ? theme.palette.novotalk.$dropdownBackgroundColorSelected
      : theme.palette.novotalk.$backgroundColor,
    '&:hover': {
      backgroundColor: state.isSelected
        ? theme.palette.novotalk.$dropdownBackgroundColorSelected
        : theme.palette.novotalk.$dropdownBackgroundColorHover,
    },
  }),
  control: (provided) => ({
    ...provided,
    height: 44,
    paddingLeft: 5,
    boxShadow: 'none',
    borderColor: theme.palette.novotalk.$inputBorderColor,
    '&:hover': {
      borderColor: theme.palette.novotalk.$inputBorderColorHover,
    },
  }),
  menu: (provided) => ({
    ...provided,
    width: 'calc(100% - 2px)',
    margin: '0 1px',
  }),
  menuList: (provided) => ({
    ...provided,
    paddingTop: 0,
    paddingBottom: 0,
  }),
});

export const languageSelectStyles = (theme, error) => ({
  indicatorsContainer: (provided) => ({
    ...provided,
    marginRight: 7,
  }),
  indicatorSeparator: () => {
    'none';
  },
  clearIndicator: () => ({
    display: 'none',
  }),
  singleValue: (provided) => ({
    ...provided,
    fontSize: 14,
    color: theme.palette.novotalk.$textColorDarkBlue,
  }),
  option: (provided, state) => ({
    ...provided,
    height: 44,
    paddingLeft: 15,
    display: 'flex',
    alignItems: 'center',
    fontSize: 14,
    color: theme.palette.novotalk.$textColorDarkBlue,
    backgroundColor: state.isSelected
      ? theme.palette.novotalk.$dropdownBackgroundColorSelected
      : theme.palette.novotalk.$backgroundColor,
    '&:hover': {
      backgroundColor: state.isSelected
        ? theme.palette.novotalk.$dropdownBackgroundColorSelected
        : theme.palette.novotalk.$dropdownBackgroundColorHover,
    },
  }),
  control: (provided) => ({
    ...provided,
    height: 44,
    paddingLeft: 5,
    boxShadow: 'none',
    borderColor: error ? theme.palette.novotalk.$errorColor : theme.palette.novotalk.$inputBorderColor,
    '&:hover': {
      borderColor: theme.palette.novotalk.$inputBorderColorHover,
    },
  }),
  menu: (provided) => ({
    ...provided,
    width: 'calc(100% - 2px)',
    margin: '0 1px',
  }),
  menuList: (provided) => ({
    ...provided,
    paddingTop: 0,
    paddingBottom: 0,
  }),
});

export const SignUpButtonContainer = styled('div')(
  {
    lineHeight: '18px',
  },
  getResponsiveCSS('height', 44, 48, '& #signup_button'),
  getResponsiveCSS('marginBottom', 26, 20),
);

export const UnderButtonTextContainer = styled('div')(
  {
    letterSpacing: '-0.16px',
    lineHeight: '17px',
    textAlign: 'center',
    paddingBottom: 19,
  },
  getResponsiveCSS('marginBottom', 16, 22),
  ({ theme }) => ({
    borderBottom: `1px solid ${theme.palette.novotalk.$questionIconColor}`,
  }),
);

export const SignInTextContainer = styled('div')({
  letterSpacing: '-0.16px',
  textAlign: 'center',
});

export const PasswordsWrapper = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  position: 'relative',
});

export const StyledPasswordMessage = styled('div')(
  {
    position: 'relative',
  },
  getResponsiveCSS('bottom', 23, 6),
);

export const ServerErrorMessage = styled(ErrorMessage)(
  {
    textAlign: 'center',
  },
  getResponsiveCSS('marginTop', 3, 6),
);

export const StyledLink = styled(Link)({
  textDecoration: 'none',
});

export const VersionsTextContainer = styled('div')({
  textAlign: 'center',
});
