export const Types = {
  GO_TO_ACTIVITY: 'GO_TO_ACTIVITY',
  GET_USER_ACTIVITIES: 'GET_USER_ACTIVITIES',
  GET_USER_ACTIVITIES_AND_FULL_ACTIVITY_DATA: 'GET_USER_ACTIVITIES_AND_FULL_ACTIVITY_DATA',

  SET_FULL_ACTIVITY_DATA: 'SET_FULL_ACTIVITY_DATA',
  SET_USER_ACTIVITIES: 'SET_USER_ACTIVITIES',

  GET_USER_ACTIVITIES_ERROR: 'GET_USER_ACTIVITIES_ERROR',
  GET_FULL_ACTIVITY_DATA_ERROR: 'GET_FULL_ACTIVITY_DATA_ERROR',
  ACTIVITY_ITEM_SELECTED: 'ACTIVITY_ITEM_SELECTED',
  CREATE_NEW_POST_ERROR: 'CREATE_NEW_POST_ERROR',

  CREATE_POST_SUCCESS: 'CREATE_POST_SUCCESS',
  CREATE_POST: 'CREATE_POST',
};

export function goToActivity(userId, activityId) {
  return {
    type: Types.GO_TO_ACTIVITY,
    activityId,
    userId,
  };
}

export function activityItemSelected(userId, activityId, activityType) {
  return {
    type: Types.ACTIVITY_ITEM_SELECTED,
    activityId,
    userId,
    activityType,
  };
}

export function getUserActivities(userId) {
  return {
    type: Types.GET_USER_ACTIVITIES,
    userId,
  };
}

export function getUserActivitiesAndFullActivity(userId, activityId) {
  return {
    type: Types.GET_USER_ACTIVITIES_AND_FULL_ACTIVITY_DATA,
    userId,
    activityId,
  };
}

export function setUserActivities(months, userId) {
  return {
    type: Types.SET_USER_ACTIVITIES,
    months,
    userId,
  };
}

export function setFullActivityData(activityData, activityId) {
  return {
    type: Types.SET_FULL_ACTIVITY_DATA,
    activityData,
    activityId,
  };
}

export function getUserActivitiesError(error) {
  return {
    type: Types.GET_USER_ACTIVITIES_ERROR,
    error,
  };
}

export function getFullActivityDataError(error) {
  return {
    type: Types.GET_FULL_ACTIVITY_DATA_ERROR,
    error,
  };
}

export function createNewPostError(error) {
  return {
    type: Types.CREATE_NEW_POST_ERROR,
    error,
  };
}

export function setCreatedPost(createdPost) {
  return {
    type: Types.CREATE_POST_SUCCESS,
    createdPost,
  };
}

export const createPost = (parentType, parentId, text) => ({
  type: Types.CREATE_POST,
  payload: { parentType, parentId, text },
});
