import React from 'react';
import { SubscriberVideoWrapper } from '../../styles';
import { sizesHelper } from './helpers';
export const Subscriber = ({
  children,
  name,
  numOfParticipants,
  isHidden,
  size,
  maxHeight,
  userCount,
  participantId,
  paddingBottom
}) => {
  return (
    <SubscriberVideoWrapper
      key={name}
      sizes={sizesHelper(size, numOfParticipants)}
      hidden={isHidden}
      maxHeight={maxHeight}
      id={participantId}
      numOfParticipants={numOfParticipants}
      paddingBottom={paddingBottom}
    >
      {children}
    </SubscriberVideoWrapper>
  );
};
