import { styled } from '@mui/material';
import CTAButton from '@components/CTAButton';

export const Wrapper = styled('div')(
  {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: '30px 30px 18px',
    borderRadius: 4,
    width: 575,
  },
  ({ theme }) => ({
    backgroundColor: theme.palette.novotalk.$backgroundColor,
  }),
);

export const Body = styled('div')({
  minWidth: 300,
  maxWidth: 300,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  textAlign: 'center',
  marginBottom: 30,
});

export const Title = styled('div')({
  marginBottom: 7,
});

export const Buttons = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
});

export const CancelButton = styled(CTAButton)({});

export const ConfirmButton = styled(CTAButton)({
  width: 240,
  height: 52,
  borderRadius: 26,
  marginBottom: 16,
});
export const SelectUserWrapper = styled('div')({
  width: '90%', paddingTop: '5%', paddingBottom: '5%',
});

export const LoaderWrapper = styled('div')({
  marginBottom: 70,
});
