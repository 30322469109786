export const FREE_TEXT = 'FREE_TEXT';
export const SINGLE_SELECT = 'SINGLE_SELECT';
export const MULTI_SELECT = 'MULTI_SELECT';
export const DATE = 'DATE';
export const DROP_DOWN = 'DROP_DOWN';
export const RATING = 'RATING';
export const TEXT = 'TEXT';
export const MEDIA = 'MEDIA';
export const QUESTION = 'QUESTION';
export const SKILL = 'SKILL';
export const POLL = 'POLL';
export const PRACTICE = 'PRACTICE';
export const COURSE = 'COURSE';
export const LESSON = 'LESSON';
export const EXERCISE = 'EXERCISE';
export const SPEECH_GOAL = 'SPEECH_GOAL';
export const MONITOR = 'MONITOR';
export const STIMULUS = 'STIMULUS';
// lower case because of db legacy
export const VIDEO = 'video';
export const AUDIO = 'audio';
export const IMAGE = 'image';
export const VIDEO_CHAT = 'VIDEO_CHAT';
export const WORKOUT = 'WORKOUT';
export const RESOURCE_MANAGER = 'RESOURCE_MANAGER';
export const ACTIVITY_PRACTICE = 'ACTIVITY_PRACTICE';
export const ACTIVITY_LESSON = 'ACTIVITY_LESSON';
export const ACTIVITY_EXERCISE = 'ACTIVITY_EXERCISE';
export const ACTIVITY_CHAT = 'ACTIVITY_CHAT';

export const ACTIVITY_TYPES = {
  ACTIVITY_PRACTICE,
  ACTIVITY_LESSON,
  ACTIVITY_EXERCISE,
  ACTIVITY_CHAT,
  WORKOUT,
  SPEECH_GOAL,
};

export const QUESTION_TYPES = {
  FREE_TEXT,
  SINGLE_SELECT,
  MULTI_SELECT,
  DATE,
  DROP_DOWN,
  RATING,
};

// types for the editor
export const BUILDER_TYPES = {
  POLL,
  PRACTICE,
  EXERCISE,
  COURSE,
  SKILL,
  LESSON,
  SPEECH_GOAL,
  MEDIA,
  STIMULUS,
};

export const MEDIA_TYPES = {
  VIDEO,
  AUDIO,
  IMAGE,
};

export const TYPES = {
  POLL,
  TEXT,
  MEDIA,
  QUESTION,
  EXERCISE,
  PRACTICE,
  LESSON,
  SKILL,
  MONITOR,
  COURSE,
};

export const COURSE_TYPES = {
  COURSE,
  SKILL,
  LESSON,
  PRACTICE,
  EXERCISE,
  POLL,
};

export const RESOURCES = {
  COURSE,
  SKILL,
  LESSON,
  PRACTICE,
  EXERCISE,
  MEDIA,
  POLL,
  MONITOR,
  STIMULUS,
  SPEECH_GOAL,
  VIDEO_CHAT,
};

export const CREATABLE_RESOURCES = {
  COURSE,
  SKILL,
  LESSON,
  PRACTICE,
  EXERCISE,
  MEDIA,
  POLL,
  STIMULUS,
  SPEECH_GOAL,
};

export const TYPES_MEDIA = [
  AUDIO,
  VIDEO,
  IMAGE,
];

export const ACTIVITY_MEDIA_TYPES = {
  VIDEO: 'VIDEO',
  AUDIO: 'AUDIO',
};
