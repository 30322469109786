import {styled} from "@mui/material";
import { getIconByResourceType } from '@services/icons';
import CTAButton from '@components/CTAButton';
import courseTypes from '../../../../../User/helpers/courseTypes';

const LessonIcon = getIconByResourceType(courseTypes.LESSON);

export const StyledLessonIcon = styled(LessonIcon)({
  color: 'initial',
  fontSize: 40,
});

export const TextWrapper = styled('div')(({language}) => ({
    order: language === 'he' ? 1 : 2
}))

export const IconWrapper = styled('div')(({language}) => ({
    order: language === 'he' ? 2 : 1,
    [language === 'he' ? 'marginLeft' : 'marginRight']: '10px'
}))

export const DescriptionWrapper = styled('div')(({language}) => ({
    display: 'flex',
    justifyContent: language === 'he' ? 'flex-end' : 'flex-start',
    width: 700,
}))

export const Wrapper = styled('div')(
  {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: '40px 120px',
    borderRadius: '9px',
  },
  ({ theme }) => ({
    backgroundColor: theme.palette.novotalk.textColorReversed,
  })
);

export const VideoWrapper = styled('video')({
  display: 'flex',
  maxWidth: '700px',
});

export const Name = styled('div')(({language}) => ({
  minWidth: '700px',
  maxWidth: '700px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: language === 'he' ? 'flex-end' : 'flex-start',
  marginBottom: '20px',
}));

export const Title = styled(Name)({
  justifyContent: 'center',
});

export const StyledCTAButton = styled(CTAButton)({
  margin: '35px 0 10px',
});

export const Description = styled('div')({
  display: 'flex',
  marginBottom: 20,
});
