import { AUTH_SERVER, AUTH_ENDPOINTS } from '../servers';
import { postRequest, getRequest } from '../commService';
import {
  getFromLocalStorage,
  LOCAL_STORAGE_ITEM,
} from '../../utils/localStorageHelper';

export const getFinalToken = async (bridgeToken) => {
  let token;
  await postRequest(
    AUTH_SERVER,
    AUTH_ENDPOINTS.BRIDGE_TOKEN_VALIDATION_ENDPOINT,
    { token: bridgeToken },
  )
    .then((response) => {
      token = response.body.token;
    })
    .catch((error) => {
      throw new Error(`Token validation error - ${error} `);
    });
  return token;
};

export const getUID = async () =>
  // try {
  //   const result = await postRequest(AUTH_SERVER,
  //     AUTH_ENDPOINTS.GET_UID);
  //   return result.uid;
  // } catch (error) {
  //   throw (new Error(`Token validation error - ${error} `));
  // }
  'SOME_ID';
export const signIn = async (email, password) => {
  try {
    const response = await postRequest(AUTH_SERVER, AUTH_ENDPOINTS.SIGN_IN, {
      email,
      password,
    });
    return response.body;
  } catch (error) {
    const errorMessage = error.status >= 500
      ? 'Internal server error'
      : 'Sign in error - incorrect Email/Password  ';
    throw new Error(errorMessage);
  }
};

export const signUp = async (
  firstName,
  lastName,
  email,
  password,
  birthdate,
  country,
  state,
  locale,
) => {
  try {
    const response = await postRequest(AUTH_SERVER, AUTH_ENDPOINTS.SIGN_UP, {
      firstName,
      lastName,
      email,
      password,
      birthdate,
      country,
      state,
      locale,
    });
    return response.body;
  } catch (error) {
    const errorMessage = error.status >= 500 ? 'Internal server error' : 'Sign Up error';
    throw new Error(`${errorMessage}: ${error.response.body.message}`);
  }
};

export const refreshTokenCall = async () => {
  try {
    const user = getFromLocalStorage(LOCAL_STORAGE_ITEM.USER);
    if (user) {
      const refresh = user.refreshToken;
      const response = await postRequest(
        AUTH_SERVER,
        AUTH_ENDPOINTS.REFRESH_TOKEN_CALL,
        { refreshToken: refresh },
      );
      return response.body;
    }
    return {};
  } catch (error) {
    console.log(error);
  }
};

export const forgotPassword = async (email) => {
  try {
    const response = await postRequest(
      AUTH_SERVER,
      AUTH_ENDPOINTS.RECOVER_PASSWORD,
      { email },
    );
    return response.body;
  } catch (error) {
    throw new Error(error.message);
  }
};

export const resendEmailConfirmation = async (email) => {
  try {
    const response = await postRequest(
      AUTH_SERVER,
      AUTH_ENDPOINTS.RESEND_EMAIL_CONFIRMATION,
      { email },
    );
    return response.body;
  } catch (error) {
    throw new Error(error.message);
  }
};

export const resetPassword = async (token, password) => {
  try {
    const response = await postRequest(
      AUTH_SERVER,
      AUTH_ENDPOINTS.RESET_PASSWORD,
      { token, password },
    );
    return response.body;
  } catch (error) {
    throw new Error(error.message);
  }
};

export const confirmEmail = async (token) => {
  try {
    const response = await postRequest(
      AUTH_SERVER,
      AUTH_ENDPOINTS.CONFIRM_EMAIL,
      { token },
      'json',
    );
    return response.body.email;
  } catch (error) {
    throw new Error(error.message);
  }
};

export const getRolesEnum = async () => {
  try {
    const response = await getRequest(
      AUTH_SERVER,
      AUTH_ENDPOINTS.USER_ROLES_ENUM,
      {},
    );
    return response.userRolesEnum;
  } catch (error) {
    throw new Error(error.message);
  }
};
