import { useMemo } from 'react';
import {
  ProgressBarWrapper,
  SuccessRateWrapper,
  SuccessRateTitle,
  SuccessRatePercent,
} from './styles';
import ProgressBar from '@components/ProgressBar';
import {useTranslation} from "react-i18next";
import {t} from "i18next";

const PROGRESS_BAR = {
  WIDTH: 255,
  HEIGHT: 10,
};

const showTitles = (productions) => [
    t('UI.productionsCount', {
      count: productions.productionsWithoutErrors
    }),
    t('UI.productionsWithErrorsCount', {
      count: productions.productionsWithErrors
    }),
  ];

/**
 * Progress bar with errors
 */
export const SuccessRate = ({ productions }) => {
  const {t} = useTranslation()
  const productionsWithoutErrorsPercent = useMemo(() => Math.floor(
    productions.productionsWithoutErrors * 100 / (productions.productionsWithErrors + productions.productionsWithoutErrors
  )), [productions.productionsWithErrors, productions.productionsWithoutErrors]);

  return (
    <SuccessRateWrapper>
      <SuccessRateTitle>
        {t('Informative.Statistical.totalSuccessRate')}
      </SuccessRateTitle>
      <ProgressBarWrapper>
        <ProgressBar
          border
          isPracticeError
          width={PROGRESS_BAR.WIDTH}
          height={PROGRESS_BAR.HEIGHT}
          progress={productionsWithoutErrorsPercent}
          titles={showTitles(productions)}
        />
        <SuccessRatePercent>
          {`${productionsWithoutErrorsPercent}%`}
        </SuccessRatePercent>
      </ProgressBarWrapper>
    </SuccessRateWrapper>
  );
};
