import React from 'react';
import PropTypes from 'prop-types';
import Text, { TEXT_SIZE, TEXT_COLOR, TEXT_WEIGHT } from '@components/Text';
import { RESOURCES } from '../../types';
import { createMiniOverview } from '../../miniOverviewFactory';
import { DescriptionArea } from '@shared/Resources/shared/components/OverviewComponents/styles';
import { InstructionsArea, TitleContainer, StyledDocumentIcon } from './styles';
import {useTranslation} from "react-i18next";

const renderText = (titleMessage, text) => {
    const {t} = useTranslation()

    return (
        <InstructionsArea>
            <TitleContainer>
                <StyledDocumentIcon />
                <Text size={TEXT_SIZE.H4} textColor={TEXT_COLOR.DARK_BLUE}>
                    {t(titleMessage)}
                </Text>
            </TitleContainer>
            <Text size={TEXT_SIZE.H4} textColor={TEXT_COLOR.DARK_BLUE}>
                {text}
            </Text>
        </InstructionsArea>
    );
}

const LessonOverview = ({
  resource: {
    texts: { description, instructions },
    media: { demo, lesson },
    monitor,
  },
}) => {
    const {t} = useTranslation()
    return (
        <div>
            <DescriptionArea>
                <Text
                    size={TEXT_SIZE.H4}
                    textColor={TEXT_COLOR.DARK_BLUE}
                    weight={TEXT_WEIGHT.BOLD}
                >
                    {t('Actions.Instance.preview')}
                </Text>
            </DescriptionArea>
            {renderText(t('Common.UI.description'), description)}
            {renderText(t('Common.UI.instructions'), instructions)}
            {createMiniOverview(RESOURCES.MEDIA, {
                value: demo,
                title: t('Common.UI.demo'),
            })}
            {createMiniOverview(RESOURCES.MEDIA, {
                value: lesson,
                title: t('UI.lessonVideo'),
            })}
            {createMiniOverview(RESOURCES.MONITOR, monitor)}
        </div>
    );
}

LessonOverview.propTypes = {
  resource: PropTypes.object.isRequired,
};

export default LessonOverview;
