import twoSecs from './2seconds';
import onesecond from './1second';
import alternating from './alternating';
import breathing from './breathing';
import closure from './closure';
import integrated from './integrated';
import overall from './overall';
import selfManaged from './selfManaged';
import technicalIssues from './technicalIssues';
import voiceControl from './voiceControl';

const map = {
  '2sec': twoSecs,
  '1sec': onesecond,
  alternating,
  breathing,
  closure,
  integrated,
  overall,
  selfManaged,
  technicalIssues,
  voiceControl,
};

export default map;
