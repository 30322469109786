import {styled} from "@mui/material";
import { getIconsByType, iconTypes } from '@services/icons';
import { getFlexibleSize } from '@utils/cssHelpers';

export const Clock = styled(getIconsByType(iconTypes.CLOCK))(
  {
    marginTop: -4,
    fontSize: 17,
  },
  ({ theme }) => ({
    [theme.RTL ? 'marginLeft' : 'marginRight']: 3,
  })
);

export const BulletIcon = styled(getIconsByType(iconTypes.CIRCLE_CHECKMARK))(
  {
    width: 30,
    height: 30,
    marginTop: -3,
    fontSize: 21,
  },
  ({ theme }) => ({
    [theme.RTL ? 'marginLeft' : 'marginRight']: 8,
    color: theme.palette.novotalk.$textColorDisabledLight,
  })
);

export const Wrapper = styled('div')(
  {
    width: 498,
    padding: '38px 0',
    borderRadius: 3,
    '@media(min-width: 1280px)': {
      width: getFlexibleSize(498, 575),
    },
    '@media(min-width: 1920px)': {
      width: 575,
    },
  },
  ({ theme }) => ({
    direction: theme.RTL ? 'rtl' : 'ltr',
    backgroundColor: theme.palette.novotalk.$backgroundColor,
  })
);

export const HeadingContainer = styled('div')(
  {
    borderBottom: '1px solid',
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    marginBottom: 40,
    padding: '0 40px',
    textAlign: 'center',
    '& .practice-duration': {
      paddingTop: 15,
      '@media (min-width: 1280px)': {
        paddingBottom: getFlexibleSize(0, 12),
      },
      '@media (min-width: 1920px)': {
        paddingBottom: 12,
      },
    },
  },
  ({ theme }) => ({
    borderColor: theme.palette.novotalk.$borderColorLight,
  })
);

export const ButtonWrapper = styled('div')(
  {
    padding: '0 18px',
    position: 'relative',
    top: 24,
    '& div.ctaButton': {
      width: 200,
      height: 44,
    },
  },
  ({ theme }) => ({
    backgroundColor: theme.palette.novotalk.$backgroundColor,
  })
);

export const ExercisesListContainer = styled('div')({
    padding: '0 40px',
  display: 'inline-block',
});

export const ExerciseContainer = styled('div')({
  paddingTop: 17,
    display: 'flex',
});

export const PreviousPracticeRedirectionWrapper = styled('div')(
  {
    display: 'flex',
    flexDirection: 'column',
    paddingTop: 20,
    marginTop: 20,
    width: '100%',
    alignItems: 'center',
    justifyContent: 'center',
  },
  ({ theme }) => ({
    borderTop: `1px solid ${theme.palette.novotalk.$borderColorLight}`,
  })
);
