import {styled} from "@mui/material";
import { getResponsiveCSS } from '@utils/cssHelpers';

export const PageWrapper = styled('div')(
  {
    display: 'grid',
    gridTemplateColumns: 'minmax(988px, auto) minmax(292px, 332px)',
  },
  ({ theme }) => ({
    height: `calc(100vh - ${theme.palette.novotalk.navigationBarHeight}px)`,
    backgroundColor: theme.palette.novotalk.$backgroundColor,
  })
);

export const Wrapper = styled('div')(
  {
    overflowY: 'auto',
  },
  getResponsiveCSS('paddingLeft', 50, 40),
  getResponsiveCSS('paddingRight', 20, 44),
  getResponsiveCSS('paddingTop', 30, 36),
  getResponsiveCSS('paddingBottom', 30, 36)
);
