import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'redux';
import _ from 'lodash';
import injectReducer from '@utils/injectReducer';
import injectSaga from '@utils/injectSaga';
import SectionLoading from '@components/SectionLoading';
import { Wrapper } from './styles';
import { getCurrentPoll as getCurrentPollAction } from './actions';
import reducer from './reducer';
import saga from './saga';
import SkillPartHeader from '../../../../components/SkillPartHeader';
import PollClient from '@shared/Resources/Poll/Client';

export class Poll extends Component {
  componentDidMount() {
    const { skill, skillpartIndex, getCurrentPoll } = this.props;
    getCurrentPoll(skill.Polls[skillpartIndex].id);
  }

  componentDidUpdate(prevProps) {
    const { skill, skillpartIndex, getCurrentPoll } = this.props;

    if (
      prevProps.skillpartIndex !== skillpartIndex ||
      !_.isEqual(prevProps.skill, skill)
    ) {
      getCurrentPoll(skill.Polls[skillpartIndex].id);
    }
  }

  render() {
    const { skill, loading, user } = this.props;
    if (loading) {
      return <SectionLoading />;
    }
    return (
      <Wrapper>
        <SkillPartHeader skill={skill} />
        <PollClient user={user} />
      </Wrapper>
    );
  }
}

Poll.propTypes = {
  getCurrentPoll: PropTypes.func,
  skill: PropTypes.object,
  loading: PropTypes.bool,
  skillpartIndex: PropTypes.number,
};

export const mapStateToProps = (state) => {
  const user = state.getIn(['app', 'authentication', 'user']).toJS();
  const { loading } = state.getIn(['currentPoll']).toJS();
  return {
    loading,
    ...(user && { user }),
  };
};

export function mapDispatchToProps(dispatch) {
  return {
    getCurrentPoll: (id, hebrewMock) =>
      dispatch(getCurrentPollAction(id, hebrewMock)),
  };
}

const withConnect = connect(mapStateToProps, mapDispatchToProps);
const withReducer = injectReducer({ key: 'currentPoll', reducer });
const withSaga = injectSaga({ key: 'currentPoll', saga });

export default compose(withReducer, withSaga, withConnect)(Poll);
