import React from 'react';
import Text, { TEXT_SIZE, TEXT_COLOR } from '@components/Text';
import { ACTIVITY_TYPES, RESOURCES } from '@shared/Resources/types';
import { getStyledActivityIcon, StatusWrapper } from './styles';
import {t} from 'i18next'

export const getTitle = (message) => {
  return (
      <Text
          size={TEXT_SIZE.T5}
          textColor={TEXT_COLOR.DISABLED}
          textTransform='uppercase'
      >
        {t(message)}
      </Text>
  );
}

export const getCellText = (data, highlighted = false) => {
  return (
    <Text
      size={TEXT_SIZE.H4}
      textColor={TEXT_COLOR[highlighted && 'DISABLED']}
      textTransform='capitalize'
    >
      {Array.isArray(data) && data.join(', ')}
      {typeof data === 'string' && data.toLowerCase()}
    </Text>
  )
};

export const STATUS_TYPES = {
  DONE: 'Done',
  FAILED: 'Failed',
  DISMISSED: 'Dismissed',
  INCOMPLETE: 'Incomplete',
  NO: 'No',
  YES: 'Yes',
  SENT: 'Sent',
};

export const getStatusText = (text, type, userActivityPage) => {
  let textColor;
  switch (type) {
    case STATUS_TYPES.DONE:
      textColor = TEXT_COLOR.COMPLETED;
      break;
    case STATUS_TYPES.FAILED:
      textColor = TEXT_COLOR.WARNING;
      break;
    case STATUS_TYPES.DISMISSED:
      textColor = TEXT_COLOR.DISABLED;
      break;
    default:
      textColor = TEXT_COLOR.NORMAL;
  }
  if (userActivityPage) {
    return (
      <StatusWrapper type={STATUS_TYPES.DISMISSED}>
        <Text
          size={TEXT_SIZE.H5}
          textColor={TEXT_COLOR.DISABLED}
          textTransform='capitalize'
        >
          {text}
        </Text>
      </StatusWrapper>
    );
  }
  return (
    <StatusWrapper type={type}>
      <Text
        size={TEXT_SIZE.H5}
        textColor={textColor}
        textTransform='capitalize'
      >
        {text}
      </Text>
    </StatusWrapper>
  );
};

export const getActivityTypeTitle = (item) => {
  const ActivityIcon = getStyledActivityIcon(item.type);
  let text;
  switch (item.type) {
    case ACTIVITY_TYPES.ACTIVITY_PRACTICE:
      text = RESOURCES.PRACTICE;
      break;
    default:
      text = 'UNKNOWN';
  }

  return (
    <div>
      <ActivityIcon />
      <Text size={TEXT_SIZE.H5} textColor={TEXT_COLOR.DISABLED} margin='0 5px'>
        {text}
      </Text>
    </div>
  );
};
