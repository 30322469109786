/**
 *
 * PendingSummary
 *
 */

import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { TEXT_SIZE, TEXT_COLOR } from '@components/Text';
import { Wrapper, Section, CountText, LabelText } from './styles';
import {compose} from "redux";
import {withTranslation} from "react-i18next";

class PendingSummary extends PureComponent {
  getSection = (className, countValue) => (
    <Section className={className}>
      <CountText className='count' textColor={TEXT_COLOR.ACTIVE}>
        {countValue}
      </CountText>
      <LabelText
        size={TEXT_SIZE.H5}
        textColor={TEXT_COLOR.DISABLED}
        textTransform='uppercase'
      >
          {this.props.t(`Informative.Notifying.${className}`)}
      </LabelText>
    </Section>
  );

  render() {
    const { requireFeedback, newMessages } = this.props;
    return (
      <Wrapper>
        {this.getSection('requireFeedback', requireFeedback)}
        {this.getSection('newMessages', newMessages)}
      </Wrapper>
    );
  }
}

PendingSummary.propTypes = {
  requireFeedback: PropTypes.number,
  newMessages: PropTypes.number,
};

export default compose(
    withTranslation()
)(PendingSummary);
