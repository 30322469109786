import React, { Component } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import Text, { TEXT_COLOR } from '@components/Text';
import {
  Wrapper,
  AnswerWrapper,
  CheckboxEmptyIcon,
  CheckboxFullIcon,
  SelectWrapper,
  IconWrapper,
} from './styles';
import { StyledQuestionIcon } from '../../styles';
import PollQuestionTitle from '../PollQuestionTitle';
import MiniOverviewHeader from '../../shared/MiniOverviewHeader';
import { QUESTION_TYPES } from '../../../../types';

export class MultipleSelectQuestion extends Component {
  constructor(props) {
    super(props);

    this.state = {
      selectedAnswers: [],
    };
  }

  handleClick(answerId) {
    const { selectedAnswers } = this.state;
    const { updateAnswer } = this.props;

    if (selectedAnswers.includes(answerId)) {
      _.pull(selectedAnswers, answerId);
    } else {
      selectedAnswers.push(answerId);
    }
    this.setState({ selectedAnswers });
    const answersToUpdate = selectedAnswers.length > 0 ? selectedAnswers : null;
    updateAnswer(answersToUpdate)
  }

  renderOptions = () => {
    const {
      questionIndex,
      answers,
      isDisabled,
      userAnswer = [],
      miniOverview,
    } = this.props;
    const { selectedAnswers } = this.state;

    return (
      <SelectWrapper
        id={`single_select_${questionIndex}`}
        miniOverview={miniOverview}
      >
        {answers.map((answer) => {
          let icon;
          if (
            selectedAnswers.includes(answer.id) ||
            (isDisabled && userAnswer.includes(answer.id))
          ) {
            icon = <CheckboxFullIcon isDisabled={isDisabled} />;
          } else {
            icon = (
              <CheckboxEmptyIcon
                isDisabled={isDisabled}
                miniOverview={miniOverview}
              />
            );
          }
          return (
            <AnswerWrapper
              isDisabled={isDisabled}
              key={answer.id}
              onClick={() => this.handleClick(answer.id)}
              aria-checked={
                selectedAnswers.includes(answer.id) ||
                userAnswer.includes(answer.id)
              }
              aria-disabled={isDisabled}
              miniOverview={miniOverview}
            >
              <IconWrapper isDisabled={isDisabled} miniOverview={miniOverview}>
                {icon}
              </IconWrapper>
              <Text textColor={TEXT_COLOR.DARK_BLUE} size='H4'>
                {answer.value}
              </Text>
            </AnswerWrapper>
          );
        })}
      </SelectWrapper>
    );
  };

  render() {
    const {
      questionIndex,
      questionText,
      checkAnswers,
      isDisabled,
      miniOverview,
    } = this.props;
    const { selectedAnswers } = this.state;
    const error = checkAnswers && selectedAnswers.length === 0 && !isDisabled;
    const titleProps = {
      questionIndex,
      questionText,
      error,
    };

    return miniOverview ? (
      <MiniOverviewHeader
        type={QUESTION_TYPES.MULTI_SELECT}
        questionText={questionText}
      >
        {this.renderOptions()}
      </MiniOverviewHeader>
    ) : (
      <Wrapper>
        {!this.props.withoutIcon && <StyledQuestionIcon />}
        <PollQuestionTitle {...titleProps} />
        {this.renderOptions()}
      </Wrapper>
    );
  }
}

MultipleSelectQuestion.propTypes = {
  isDisabled: PropTypes.bool,
  questionIndex: PropTypes.number,
  questionText: PropTypes.string,
  answers: PropTypes.array,
  updateAnswer: PropTypes.func,
  checkAnswers: PropTypes.bool,
  userAnswer: PropTypes.arrayOf(PropTypes.string),
  withoutIcon: PropTypes.bool,
  miniOverview: PropTypes.bool,
};

export default MultipleSelectQuestion;
