import {styled} from "@mui/material";
import CTAButton from '@components/CTAButton';

export const NoContentOptionsSection = styled('div')({
  display: 'flex',
  alignItems: 'baseline',
  marginTop: 20,
});

export const StyledCTAButton = styled(CTAButton)({
  width: 120,
  height: 32,
  borderRadius: 4,
  marginRight: 10,
});
