import {styled} from "@mui/material";

export const Wrapper = styled('div')(
  {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    position: 'sticky',
    top: 0,
  },
  ({ theme }) => ({
    padding: theme.RTL ? '15px 10px 0 5px' : '15px 5px 0 10px',
    backgroundColor: theme.palette.novotalk.$backgroundColor,
  })
);

export const CurrentLesson = styled('div')(
  {
    display: 'flex',
    flexDirection: 'column',
    borderRadius: '5px',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: '20px',
    height: 210,
    width: 340,
    margin: '18px',
  },
  ({ theme }) => ({
    boxShadow: `0 0 20px 5px ${theme.palette.novotalk.$borderColorLight}`,
  })
);
