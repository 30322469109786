import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import CTAButton from '@components/CTAButton';
import { LoadingArea } from './components/loading-area';
import { ModalContainer, WithMargin } from './styles';
import { editMediaData, putEditStatus, editStatus } from './actions';
import { RESOURCES } from '@shared/Resources/types';
import { getOverview, getResources } from '@containers/Admin/shared/ResourcesWrapper/actions';
import { MediaPreview } from '@containers/App/containers/PopUp/popups/MediaEditor/components/media-preview';
import { MediaEditorForm } from '@containers/App/containers/PopUp/popups/MediaEditor/components/form';
import {useTranslation} from "react-i18next";

const MediaEditor = ({ onItemUploaded, onClose }) => {
  const {t} = useTranslation()
  const dispatch = useDispatch();

  const sentItem = useSelector((state) => state.getIn(['editMedia']).toJS());
  const { overview: { resource: {
    value: file,
    tags,
    title,
    description,
    type,
    id,
  } } } = useSelector((state) => state.getIn(['resourceManager']).toJS());
  const { existingName } = useSelector((state) => state.getIn(['nameChecker']).toJS());

  const [mediaData, setMediaData] = useState({
    isItemLoading: false,
    file,
    tags,
    title,
    description,
    type,
    id,
  });

  const onSave = () => {
    const tags = mediaData.tags.map(({ value }) => value);

    setMediaData({ isItemLoading: true });
    dispatch(editMediaData({
      tags,
      title: mediaData.title,
      description: mediaData.description,
      type: mediaData.type,
      id: mediaData.id
    }));
  };

  const onLoadingFinishedButtonClick = () => {
    dispatch(getOverview(RESOURCES.MEDIA, mediaData.id, null));
    dispatch(getResources(RESOURCES.MEDIA));

    if (onItemUploaded) onItemUploaded(sentItem);
    dispatch(putEditStatus(editStatus.DEFAULT))
    onClose();
  };

  const handleInput = ({ target: { name, value } }) => {
    setMediaData((prevMediaData) => ({
      ...prevMediaData,
      [name]: value,
    }));
  };

  const handleTagsChange = (option) => {
    setMediaData((prevMediaData) => ({
      ...prevMediaData,
      tags: option,
    }));


  };

  const isUploadDisabled = !mediaData.title?.length || existingName;

  return (
    <ModalContainer>
      {mediaData.isItemLoading
        ? <LoadingArea
          onLoadingFinishedButtonClick={onLoadingFinishedButtonClick}
        />
        : <>
          <MediaPreview
            file={mediaData.file}
            type={mediaData.type}
          />
          <MediaEditorForm
            tags={mediaData.tags}
            description={mediaData.description}
            title={mediaData.title}
            handleInput={handleInput}
            handleTagsChange={handleTagsChange}
            existingName={existingName}
          />
          <WithMargin marginTop={10}>
            <CTAButton
              action
              expanded
              data-cy='media_page_modal_edit_button'
              disabled={isUploadDisabled}
              onClick={onSave}
            >
              {t('Actions.Instance.save')}
            </CTAButton>
          </WithMargin>
        </>
      }
    </ModalContainer>
  );
}

MediaEditor.propTypes = {
  onClose: PropTypes.func,
  onItemUploaded: PropTypes.func,
};

export default MediaEditor;
