import React from 'react';
import Text, { TEXT_SIZE, TEXT_COLOR, TEXT_WEIGHT } from '@components/Text';
import PropTypes from 'prop-types';
import EditorComponentWrapper from '@shared/Resources/shared/components/BuilderComponentWrapper';
import MediaSelector from '../shared/MediaSelector';
import { MEDIA_TYPES } from '../../types';
import {
  MediaContainer,
  TitleContainer,
  Input,
  ContentOptionsSection,
  VideoWrapper,
  VideoPlayer,
  AudioPlayer,
  Image,
  ContentInfo,
  StyledCTAButton,
} from './styles';
import {compose} from "redux";
import {withTranslation} from "react-i18next";

class EditorMedia extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      payload: props.payload,
    };
  }

  onChange = (event) => {
    if (!event.target) return;
    const { payload } = this.state;
    payload.description = event.target.value;
    this.setState({ payload });
    this.props.submitPayload(payload);
  };

  onItemSelect = (item) => {
    const { payload } = this.state;
    payload.title = item.title;
    payload.url = item.value;
    payload.type = item.type;

    payload.id = item.id;
    this.setState({ payload });
    this.props.submitPayload(payload);
  };


  clearMedia = () => {
    const { payload } = this.state;
    payload.id = null;
    this.setState({ payload: {} });
  };

  renderContent = () => {
    switch (this.state.payload.type) {
      case MEDIA_TYPES.VIDEO:
        return (
          <VideoWrapper>
            <VideoPlayer controls>
              <source type='video/mp4' src={this.state.payload.url} />
            </VideoPlayer>
          </VideoWrapper>
        );
      case MEDIA_TYPES.AUDIO:
        return (
          <AudioPlayer controls>
            <source src={this.state.payload.url} />
          </AudioPlayer>
        );
      case MEDIA_TYPES.IMAGE:
        return <Image src={this.state.payload.url} />;
      default:
        return null;
    }
  };

  renderContentOptions = () => (
    <ContentOptionsSection>
      {this.renderContent()}
      <ContentInfo>
        <StyledCTAButton
          onClick={this.clearMedia}
          className='clearCurrentContentButton'
        >
          {this.props.t('Informative.Multiple.selectOther')}
        </StyledCTAButton>
      </ContentInfo>
    </ContentOptionsSection>
  );

  renderNoContentOptions = () => (
    <MediaSelector onItemSelect={this.onItemSelect} />
  );

  render() {
    const {
      payload: { description , url },
    } = this.state;
    return (
      <MediaContainer>
        <TitleContainer>
          <Text
            size={TEXT_SIZE.T4}
            textColor={TEXT_COLOR.DARK_BLUE}
            weight={TEXT_WEIGHT.BOLD}
            textTransform='uppercase'
          >
            {this.props.t('UI.title')}
          </Text>
          <Text
            size={TEXT_SIZE.H4}
            textColor={TEXT_COLOR.DISABLED}
            className='optional-text'
          >
            {this.props.t('Common.Statuses.optional')}
          </Text>
        </TitleContainer>
        <Input
          className='descriptionInput'
          autoFocus
          value={description}
          onChange={this.onChange}
          placeholder={this.props.t('Common.Placeholders.titleHere')}
          error={!description || description.length === 0}
        />
        {url
          ? this.renderContentOptions()
          : this.renderNoContentOptions()
        }
      </MediaContainer>
    );
  }
}

EditorMedia.propTypes = {
  payload: PropTypes.object,
  submitPayload: PropTypes.func,
};

export default EditorComponentWrapper(
    compose(
        withTranslation()
    )(EditorMedia)
);
