import React, { Component } from 'react';
import PropTypes from 'prop-types';
import TextButton from '@components/TextButton';
import Text, { TEXT_SIZE, TEXT_COLOR, TEXT_WEIGHT } from '@components/Text';
import {RESOURCES, SPEECH_GOAL} from '@shared/Resources/types';
import {
  TitleContainer,
  ExpandButtonContainer,
  SkillsList,
} from '../../../OverviewComponents/styles';
import { createMiniOverview } from '@shared/Resources/miniOverviewFactory';
import {withTranslation} from "react-i18next";
import {compose} from "redux";

class OverviewSectionPartial extends Component {
  constructor(props) {
    super(props);
    this.state = {
      areItemsExpanded: false,
      areItemsCollapsed: false,
    };
  }

  toggleExpandItems = () => {
    this.setState(({ areItemsExpanded }) => ({
      areItemsExpanded: !areItemsExpanded,
      areItemsCollapsed: areItemsExpanded,
    }));
  };

  handleItemStateChange = (isOpened) => {
    const { areItemsExpanded, areItemsCollapsed } = this.state;
    if (isOpened && areItemsCollapsed) {
      this.setState({
        areItemsCollapsed: false,
      });
    }
    if (!isOpened && areItemsExpanded) {
      this.setState({
        areItemsExpanded: false,
      });
    }
  };

  transformType = (type) => type === SPEECH_GOAL ? 'speechGoal' : type.toLowerCase()

  renderTitle = () => {
    const { type, parentType, isInnerList, t } = this.props;
    if (type === RESOURCES.SKILL) return null;
    const itemName = type.toLowerCase();
    return (
      <TitleContainer parentType={parentType}>
        <Text
          size={TEXT_SIZE[isInnerList ? 'T3' : 'H4']}
          textColor={TEXT_COLOR.DARK_BLUE}
          weight={TEXT_WEIGHT[isInnerList ? 'NORMAL' : 'BOLD']}
        >
          <span
            className={`${itemName}Title`}
          >
            {t(`Common.UI.${this.transformType(type)}`)}
          </span>
        </Text>
      </TitleContainer>
    );
  };

  renderExpandButton = () => {
    const { isInnerList, type, t } = this.props;
    const { areItemsExpanded } = this.state;
    if (isInnerList || type !== RESOURCES.SKILL) return null;
    return (
      <ExpandButtonContainer>
        <TextButton onClick={this.toggleExpandItems}>
          <Text size={TEXT_SIZE.H4}>
            {t(`Actions.Multiple.${areItemsExpanded ? 'collapseAll' : 'expandAll'}`)}
          </Text>
        </TextButton>
      </ExpandButtonContainer>
    );
  };

  render() {
    const {
      items,
      type,
      onView,
      onEdit,
      onExpand,
      flatChildren,
      readOnly,
      isInnerList,
      isUserPageProgram,
      onMoveToCourseLevel,
    } = this.props;
    const { areItemsExpanded, areItemsCollapsed } = this.state;

    if (items?.length === 0) return null;
    const itemName = type.toLowerCase();
    return (
      <SkillsList>
        {this.renderTitle()}
        {this.renderExpandButton()}
        {items?.map((item) => (
          <div className={itemName}>
            {createMiniOverview(
              item.type,
              { ...item },
              { onView, onEdit, onExpand, readOnly, onMoveToCourseLevel },
              !flatChildren,
              isInnerList,
              areItemsExpanded,
              areItemsCollapsed,
              this.handleItemStateChange,
              isUserPageProgram,
            )}
          </div>
        ))}
      </SkillsList>
    );
  }
}

OverviewSectionPartial.defaultProps = {
  flatChildren: false,
};

OverviewSectionPartial.propTypes = {
  readOnly: PropTypes.bool,
  flatChildren: PropTypes.bool,
  items: PropTypes.array,
  type: PropTypes.string,
  onView: PropTypes.func,
  onEdit: PropTypes.func,
  onExpand: PropTypes.func,
  isInnerList: PropTypes.bool,
  isUserPageProgram: PropTypes.bool,
  parentType: PropTypes.string,
};

export default compose(
    withTranslation()
)(OverviewSectionPartial);
