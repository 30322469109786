export const TYPES = {
  GET_CURRENT_PRACTICE: 'GET_CURRENT_PRACTICE',
  SET_CURRENT_PRACTICE: 'SET_CURRENT_PRACTICE',
  SET_CURRENT_EXERCISE: 'SET_CURRENT_EXERCISE',
  SET_ACTIVITY_CONTEXT: 'SET_ACTIVITY_CONTEXT',
  START_ACTIVITY_PRACTICE: 'START_ACTIVITY_PRACTICE',
  CONTINUE_ACTIVITY_PRACTICE: 'CONTINUE_ACTIVITY_PRACTICE',
  SET_ACTIVITY_PRACTICE: 'SET_ACTIVITY_PRACTICE',
  SET_ACTIVITY_EXERCISE: 'SET_ACTIVITY_EXERCISE',
  START_EXERCISE: 'START_EXERCISE',
  END_EXERCISE: 'END_EXERCISE',
  RESET_ACTIVITY_EXERCISE: 'RESET_ACTIVITY_EXERCISE',
  RESET_PRACTICE: 'RESET_PRACTICE',
  RESET_CURRENT_EXERCISE: 'RESET_CURRENT_EXERCISE',
  TRYING_EXIT_EXERCISE: 'TRYING_EXIT_EXERCISE',
  PRACTICE_ERROR: 'PRACTICE_ERROR',
  GET_USER_FEEDBACKS: 'GET_USER_FEEDBACKS',
  SET_USER_FEEDBACKS: 'SET_USER_FEEDBACKS',
  SET_PRACTICE_ERROR: 'SET_PRACTICE_ERROR',
};

export function getUserFeedbacks() {
  return {
    type: TYPES.GET_USER_FEEDBACKS,
  };
}

export function setUserFeedbacks(feedbacks) {
  return {
    type: TYPES.SET_USER_FEEDBACKS,
    feedbacks,
  };
}

export function getCurrentPractice(id) {
  return {
    type: TYPES.GET_CURRENT_PRACTICE,
    id,
  };
}

export function setCurrentPractice(practice) {
  return {
    type: TYPES.SET_CURRENT_PRACTICE,
    practice,
  };
}

export function setCurrentExercise(exercise) {
  return {
    type: TYPES.SET_CURRENT_EXERCISE,
    exercise,
  };
}

export function startActivityPractice(
  entityPracticeInfo,
  entityExerciseInfo,
  activityContext,
  activityLogId,
) {
  return {
    type: TYPES.START_ACTIVITY_PRACTICE,
    entityPracticeInfo,
    entityExerciseInfo,
    activityContext,
    activityLogId,
  };
}

export function continueActivityPractice(entityExerciseInfo, activityContext) {
  return {
    type: TYPES.CONTINUE_ACTIVITY_PRACTICE,
    entityExerciseInfo,
    activityContext,
  };
}

export function setActivityPractice(activity) {
  return { type: TYPES.SET_ACTIVITY_PRACTICE, activity };
}

export function setActivityExercise(activity) {
  return { type: TYPES.SET_ACTIVITY_EXERCISE, activity };
}

export function practiceError() {
  return { type: TYPES.PRACTICE_ERROR };
}

export const startActivityExercise = () => ({
  type: TYPES.START_EXERCISE,
});

export const endActivityExercise = (activityExerciseId) => ({
  type: TYPES.END_EXERCISE,
  activityExerciseId,
});

export const resetActivityExercise = () => ({
  type: TYPES.RESET_ACTIVITY_EXERCISE,
});

export const resetPractice = () => ({
  type: TYPES.RESET_PRACTICE,
});

export const tryingToExitExerciseAction = (tryingToExit) => ({
  type: TYPES.TRYING_EXIT_EXERCISE,
  tryingToExit,
});

export const resetCurrentExercise = () => ({
  type: TYPES.RESET_CURRENT_EXERCISE,
});
export const setPracticeError = (error) => ({
  type: TYPES.SET_PRACTICE_ERROR,
  error,
});
