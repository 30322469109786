import {styled} from "@mui/material";

export const FeedbackListContainer = styled('div')({
  marginBottom: 10,
  width: '100%',
});

export const FeedbackContainer = styled('div')(
  {
    display: 'flex',
    lineHeight: '1.2em',
    padding: '20px 20px 38px 20px',
    border: '1px solid',
    borderRadius: 4,
    marginBottom: 20,
  },
  ({ theme }) => ({
    backgroundColor: theme.palette.novotalk.$backgroundColorFeedbackItem,
    borderColor: theme.palette.novotalk.$borderColorFeedbackItem,
  })
);

export const FeedbackHeaderContainer = styled('div')({
  display: 'flex',
  margin: '2px 0 15px 0',
  whiteSpace: 'pre',
});

export const FeedbackTextContainer = styled('div')(
  {
    lineHeight: '21px',
    fontSize: 14,
  },
  ({ theme }) => ({
    color: theme.palette.novotalk.$textColorDarkBlue,
  })
);

export const AvatarContainer = styled('div')(
  {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    maxWidth: 56,
    minWidth: 56,
    height: 56,
    overflow: 'hidden',
    borderRadius: 28,
    '& img': {
      width: '105%',
    },
  },
  ({ theme }) => ({
    [theme.RTL ? 'marginLeft' : 'marginRight']: 20,
  })
);
