import React from 'react';
import PropTypes from 'prop-types';
import ReactDOMServer from 'react-dom/server';
import Text, { TEXT_SIZE, TEXT_COLOR } from '@components/Text';
import DropzoneComponent from 'react-dropzone-component';
import {
  DropArea,
  UploadItem,
  EmptyAreaWrapper,
  Chip,
  ChipText,
  StyledCloseIcon,
} from './styles';
import {withTranslation} from "react-i18next";
import {compose} from "redux";

class CustomDropzoneComponent extends React.Component {
  constructor(props) {
    super(props);

    this.dropzoneEventHandlers = {
      addedfile: this.props.handleAddedFile,
    };

    this.dropzoneConfig = {
      postUrl: 'no-url',
      dropzoneSelector: '#selector',
    };

    this.dropzoneDjsConfig = {
      previewTemplate: ReactDOMServer.renderToStaticMarkup(<span></span>),
      autoProcessQueue: false,
      autoQueue: false,
      uploadMultiple: false,
    };
  }

  renderEmptyArea = () => (
    <EmptyAreaWrapper>
      <Text textColor={TEXT_COLOR.DISABLED} margin='0 5px 0 0'>
        {this.props.t('Actions.Files.dropHere')}
      </Text>
      <Text textColor={TEXT_COLOR.DISABLED}>
        {this.props.t('Actions.Files.dropZone')}
      </Text>
    </EmptyAreaWrapper>
  );

  renderUploadedList = () => {
    const { uploadedFiles, handleRemovedFile } = this.props;

    return uploadedFiles.map((item, index) => (
      <UploadItem key={`${item.name}_${index}`}>
        <Chip>
          <ChipText size={TEXT_SIZE.T3} textColor={TEXT_COLOR.REVERSED}>
            {item.name}
          </ChipText>
          <StyledCloseIcon onClick={() => handleRemovedFile(index)} />
        </Chip>
      </UploadItem>
    ));
  };

  render() {
    const { uploadedFiles } = this.props;
    const emptyArea = uploadedFiles.length === 0;

    return (
      <span>
        <DropzoneComponent
          config={this.dropzoneConfig}
          eventHandlers={this.dropzoneEventHandlers}
          djsConfig={this.dropzoneDjsConfig}
        />
        <DropArea
          data-cy='media_upload_dropzone'
          emptyArea={emptyArea}
          id='selector'
        >
          {uploadedFiles.length === 0
            ? this.renderEmptyArea()
            : this.renderUploadedList()}
        </DropArea>
      </span>
    );
  }
}

CustomDropzoneComponent.propTypes = {
  handleAddedFile: PropTypes.func.isRequired,
  handleRemovedFile: PropTypes.func.isRequired,
  uploadedFiles: PropTypes.array.isRequired,
};

export default compose(
    withTranslation()
)(CustomDropzoneComponent);
