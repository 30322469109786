import {styled} from "@mui/material";
import { getIconByResourceType } from '@services/icons';
import { RESOURCES } from '@shared/Resources/types';

const MediaIcon = getIconByResourceType(RESOURCES.MEDIA);

export const StyledMediaIcon = styled(MediaIcon)(
  {
    fontSize: 24,
    marginTop: -3,
  },
  ({ theme }) => ({
    [theme.RTL ? 'marginLeft' : 'marginRight']: 8,
    color: theme.palette.novotalk.$activeColor,
  })
);

export const MediaContainer = styled('div')({
  width: 400,
  display: 'inline-block',
  marginTop: 10,
  marginRight: 20,
  '& > div': {
    margin: 0,
  },
});
