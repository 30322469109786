import {styled} from "@mui/material";
import isPropValid from '@emotion/is-prop-valid';
import { getIconsByType, iconTypes } from '@services/icons';

const iconSize = 26;

const selectStyles = {
  fontSize: iconSize + 8,
  zIndex: 2,
  padding: 4,
  borderRadius: '50%',
};

export const IconWrapper = styled('span')(
  {
    display: 'inline-block',
    width: iconSize + 8,
    height: iconSize + 8,
    verticalAlign: 2,
    position: 'relative',
  },
  ({ theme }) => ({
    [theme.RTL ? 'marginLeft' : 'marginRight']: 5,
  }),
  ({ theme, isDisabled, miniOverview }) => {
    if (isDisabled && !miniOverview) {
      return {
        '&:before': {
          display: 'block',
          content: '""',
          position: 'absolute',
          left: `calc(50% - ${(iconSize - 6) / 2}px)`,
          top: `calc(50% - ${(iconSize - 6) / 2}px)`,
          width: iconSize - 6,
          height: iconSize - 6,
          borderRadius: (iconSize - 6) / 2,
          backgroundColor: theme.palette.novotalk.$selectBackgroundColorDisabled,
        },
      };
    } else if (miniOverview) {
      return {
        width: 22,
        height: 22,
        verticalAlign: 1,
      };
    }
    return {};
  }
);

export const RadioOffIcon = styled(getIconsByType(iconTypes.RADIO_OFF), {
  shouldForwardProp: isPropValid,
})(
  selectStyles,
  ({ theme, isDisabled }) => {
    if (isDisabled) {
      return {
        color: theme.palette.novotalk.$selectStandartColor,
      };
    }
    return {
      color: theme.palette.novotalk.$selectStandartColor,
      '&:hover': {
        backgroundColor: theme.palette.novotalk.$selectStandartBgColorHover,
      },
      '&:active': {
        color: theme.palette.novotalk.$selectEnabledColor,
        backgroundColor: theme.palette.novotalk.$selectStandartBgColorActive,
      },
    };
  },
  ({ miniOverview }) =>
    miniOverview && {
      fontSize: 22,
      padding: 0,
    }
);

export const RadioOnIcon = styled(getIconsByType(iconTypes.RADIO_ON), {
  shouldForwardProp: isPropValid,
})(selectStyles, ({ theme, isDisabled }) => {
  if (isDisabled) {
    return {
      color: theme.palette.novotalk.$selectStandartColor,
    };
  }
  return {
    color: theme.palette.novotalk.$selectEnabledColor,
    '&:hover': {
      backgroundColor: theme.palette.novotalk.$selectEnabledBgColorHover,
    },
    '&:active': {
      backgroundColor: theme.palette.novotalk.$selectEnabledBgColorActive,
    },
  };
});

export const Wrapper = styled('div')(
  {
    minWidth: '800px',
    width: '100%',
    position: 'relative',
  },
  ({ theme }) => ({
    margin: theme.RTL ? '10px 30px 30px 0' : '10px 0 30px 30px',
  })
);

export const AnswerWrapper = styled('div')(
  {
    marginTop: -2,
  },
  ({ isDisabled, miniOverview }) => ({
    pointerEvents: isDisabled ? 'none' : 'all',
    marginBottom: miniOverview ? 10 : 5,
  })
);

export const SelectWrapper = styled('div')(
  ({ theme, miniOverview }) =>
    !miniOverview && {
      [theme.RTL ? 'marginRight' : 'marginLeft']: -8,
    }
);
