import {styled} from "@mui/material";
import { getIconsByType, iconTypes } from '@services/icons';

export const SortUpIcon = styled(getIconsByType(iconTypes.FILLED_ARROW_UP))({
  gridArea: 'UP',
});
export const SortDownIcon = styled(getIconsByType(iconTypes.FILLED_ARROW_DOWN))(
  {
    gridArea: 'DOWN',
  }
);
export const Wrapper = styled('div')(
  {
    display: 'inline-grid',
    gridTemplateAreas: `
  'UP'
  'DOWN'
  `,
    gridTemplateRows: '7px 7px',
    alignItems: 'center',
    margin: '0 3px',
  },
  ({ theme }) => ({
    color: theme.palette.novotalk.$textColorDisabled,
  })
);
