
export const lessons = [
                {
                    id: 'l1',
                    name: 'basics',
                    status: 'COMPLETED',
                    demo: 'https://s3-eu-west-1.amazonaws.com/nvtk-video-media/03_01%20Hebrew%20Low.mp4',
                    lesson: 'https://s3-eu-west-1.amazonaws.com/nvtk-video-media/03_02%20Hebrew%20Low.mp4',
                    instructions: 'You will learn how to breathe diaphragmatic breathing correctly. It will serve you later on in the course',
                    monitor: '',
                    firstVisit: true
                },
                {
                    id: 'l2',
                    name: 'increasing breath capacity',
                    status: 'COMPLETED',
                    demo: 'https://s3-eu-west-1.amazonaws.com/nvtk-video-media/03_01%20Hebrew%20Low.mp4',
                    lesson: 'https://s3-eu-west-1.amazonaws.com/nvtk-video-media/03_02%20Hebrew%20Low.mp4',
                    instructions: 'You will learn to increase your breathing capacity, as a preparation fro the next stages in the course',
                    monitor: '',
                    firstVisit: false
                },
                {
                    id: 'l3',
                    name: 'volume control',
                    status: 'COMPLETED',
                    demo: 'https://s3-eu-west-1.amazonaws.com/nvtk-video-media/03_01%20Hebrew%20Low.mp4',
                    lesson: 'https://s3-eu-west-1.amazonaws.com/nvtk-video-media/03_02%20Hebrew%20Low.mp4',
                    instructions: 'You will learn to control your vocal production along with breathing correctly',
                    monitor: '',
                    firstVisit: false
                },
                {
                    id: 'l4',
                    name: 'relaxation of the upper body',
                    status: 'COMPLETED',
                    demo: 'https://s3-eu-west-1.amazonaws.com/nvtk-video-media/03_01%20Hebrew%20Low.mp4',
                    lesson: 'https://s3-eu-west-1.amazonaws.com/nvtk-video-media/03_02%20Hebrew%20Low.mp4',
                    instructions: 'You will learn how to relax your upper body muscles, that will help you relax and prepare your body for the exercises',
                    monitor: '',
                    firstVisit: false
                },
                {
                    id: 'l5',
                    name: 'face muscle relaxation',
                    status: 'CURRENT',
                    demo: 'https://s3-eu-west-1.amazonaws.com/nvtk-video-media/03_01%20Hebrew%20Low.mp4',
                    lesson: 'https://s3-eu-west-1.amazonaws.com/nvtk-video-media/03_02%20Hebrew%20Low.mp4',
                    instructions: 'You will learn how to relax your upper face muscles that are involved with voice production',
                    monitor: '',
                    firstVisit: true
                },
                {
                    id: 'l6',
                    name:'gentle voice',
                    status: 'LOCKED',
                    demo: 'https://s3-eu-west-1.amazonaws.com/nvtk-video-media/03_01%20Hebrew%20Low.mp4',
                    lesson: 'https://s3-eu-west-1.amazonaws.com/nvtk-video-media/03_02%20Hebrew%20Low.mp4',
                    instructions: 'You will learn how to produce gentle voice, that will prepare you for the 2 seconds per syllable pattern',
                    monitor: '',
                    firstVisit: true
                },
                {
                    id: 'l7',
                    name:'loud voice',
                    status: 'LOCKED',
                    demo: 'https://s3-eu-west-1.amazonaws.com/nvtk-video-media/03_01%20Hebrew%20Low.mp4',
                    lesson: 'https://s3-eu-west-1.amazonaws.com/nvtk-video-media/03_02%20Hebrew%20Low.mp4',
                    instructions: 'You will learn how to produce loud voice, that will prepare you for the 2 seconds per syllable pattern',
                    monitor: '',
                    firstVisit: true
                },
                {
                    id: 'l8',
                    name:'transition between voices',
                    status: 'LOCKED',
                    demo: 'https://s3-eu-west-1.amazonaws.com/nvtk-video-media/03_01%20Hebrew%20Low.mp4',
                    lesson: 'https://s3-eu-west-1.amazonaws.com/nvtk-video-media/03_02%20Hebrew%20Low.mp4',
                    instructions: 'You will learn how to move from gentle to loud voice and back to gentle voice, as a preparation for the 2 second per syllable pattern',
                    monitor: '',
                    firstVisit: true
                },
                {
                    id: 'l9',
                    name:'transition symmetry',
                    status: 'LOCKED',
                    demo: 'https://s3-eu-west-1.amazonaws.com/nvtk-video-media/03_01%20Hebrew%20Low.mp4',
                    lesson: 'https://s3-eu-west-1.amazonaws.com/nvtk-video-media/03_02%20Hebrew%20Low.mp4',
                    instructions: 'You will learn how to produce a symmetric pattern, as a preparation for the 2 second per syllable pattern',
                    monitor: '',
                    firstVisit: true
                },
                {
                    id: 'l10',
                    name: 'vowel production',
                    status: 'LOCKED',
                    demo: 'https://s3-eu-west-1.amazonaws.com/nvtk-video-media/03_01%20Hebrew%20Low.mp4',
                    lesson: 'https://s3-eu-west-1.amazonaws.com/nvtk-video-media/03_02%20Hebrew%20Low.mp4',
                    instructions: '',
                    monitor: '',
                    firstVisit: true
                },
                {
                    id: 'l11',
                    name: 'consonants production',
                    status: 'LOCKED',
                    demo: 'https://s3-eu-west-1.amazonaws.com/nvtk-video-media/03_01%20Hebrew%20Low.mp4',
                    lesson: 'https://s3-eu-west-1.amazonaws.com/nvtk-video-media/03_02%20Hebrew%20Low.mp4',
                    instructions: '',
                    monitor: '',
                    firstVisit: true
                },
                {
                    id: 'l12',
                    name: 'one syllable words',
                    status: 'LOCKED',
                    demo: 'https://s3-eu-west-1.amazonaws.com/nvtk-video-media/03_01%20Hebrew%20Low.mp4',
                    lesson: 'https://s3-eu-west-1.amazonaws.com/nvtk-video-media/03_02%20Hebrew%20Low.mp4',
                    instructions: '',
                    monitor: '',
                    firstVisit: true
                },                                
                {
                    id: 'l13',
                    name: 'multi syllable words',
                    status: 'LOCKED',
                    demo: 'https://s3-eu-west-1.amazonaws.com/nvtk-video-media/03_01%20Hebrew%20Low.mp4',
                    lesson: 'https://s3-eu-west-1.amazonaws.com/nvtk-video-media/03_02%20Hebrew%20Low.mp4',
                    instructions: '',
                    monitor: '',
                    firstVisit: true
                },                
                {
                    id: 'l14',
                    name: 'vowel production',
                    status: 'LOCKED',
                    demo: 'https://s3-eu-west-1.amazonaws.com/nvtk-video-media/03_01%20Hebrew%20Low.mp4',
                    lesson: 'https://s3-eu-west-1.amazonaws.com/nvtk-video-media/03_02%20Hebrew%20Low.mp4',
                    instructions: '',
                    monitor: '',
                    firstVisit: true
                },
                {
                    id: 'l15',
                    name: 'free speech',
                    status: 'LOCKED',
                    demo: 'https://s3-eu-west-1.amazonaws.com/nvtk-video-media/03_01%20Hebrew%20Low.mp4',
                    lesson: 'https://s3-eu-west-1.amazonaws.com/nvtk-video-media/03_02%20Hebrew%20Low.mp4',
                    instructions: '',
                    monitor: '',
                    firstVisit: true
                },
                {
                    id: 'l16',
                    name:'one syllable words',
                    status: 'LOCKED',
                    demo: 'https://s3-eu-west-1.amazonaws.com/nvtk-video-media/03_01%20Hebrew%20Low.mp4',
                    lesson: 'https://s3-eu-west-1.amazonaws.com/nvtk-video-media/03_02%20Hebrew%20Low.mp4',
                    instructions: '',
                    monitor: '',
                    firstVisit: true
                },
                {
                    id: 'l17',
                    name:'multi syllable words',
                    status: 'LOCKED',
                    demo: 'https://s3-eu-west-1.amazonaws.com/nvtk-video-media/03_01%20Hebrew%20Low.mp4',
                    lesson: 'https://s3-eu-west-1.amazonaws.com/nvtk-video-media/03_02%20Hebrew%20Low.mp4',
                    instructions: '',
                    monitor: '',
                    firstVisit: true
                },
                {
                    id: 'l18',
                    name:'sentences',
                    status: 'LOCKED',
                    demo: 'https://s3-eu-west-1.amazonaws.com/nvtk-video-media/03_01%20Hebrew%20Low.mp4',
                    lesson: 'https://s3-eu-west-1.amazonaws.com/nvtk-video-media/03_02%20Hebrew%20Low.mp4',
                    instructions: '',
                    monitor: '',
                    firstVisit: true
                },
                {
                    id: 'l19',
                    name:'free speech',
                    status: 'LOCKED',
                    demo: 'https://s3-eu-west-1.amazonaws.com/nvtk-video-media/03_01%20Hebrew%20Low.mp4',
                    lesson: 'https://s3-eu-west-1.amazonaws.com/nvtk-video-media/03_02%20Hebrew%20Low.mp4',
                    instructions: '',
                    monitor: '',
                    firstVisit: true
                },
                {
                    id: 'l20',
                    name:'intonation',
                    status: 'LOCKED',
                    demo: 'https://s3-eu-west-1.amazonaws.com/nvtk-video-media/03_01%20Hebrew%20Low.mp4',
                    lesson: 'https://s3-eu-west-1.amazonaws.com/nvtk-video-media/03_02%20Hebrew%20Low.mp4',
                    instructions: '',
                    monitor: '',
                    firstVisit: true
                },
                {
                    id: 'l21',
                    name:'words',
                    status: 'LOCKED',
                    demo: 'https://s3-eu-west-1.amazonaws.com/nvtk-video-media/03_01%20Hebrew%20Low.mp4',
                    lesson: 'https://s3-eu-west-1.amazonaws.com/nvtk-video-media/03_02%20Hebrew%20Low.mp4',
                    instructions: '',
                    monitor: '',
                    firstVisit: true
                },
                {
                    id: 'l22',
                    name:'sentences',
                    status: 'LOCKED',
                    demo: 'https://s3-eu-west-1.amazonaws.com/nvtk-video-media/03_01%20Hebrew%20Low.mp4',
                    lesson: 'https://s3-eu-west-1.amazonaws.com/nvtk-video-media/03_02%20Hebrew%20Low.mp4',
                    instructions: '',
                    monitor: '',
                    firstVisit: true
                },
                {
                    id: 'l23',
                    name:'free speech',
                    status: 'LOCKED',
                    demo: 'https://s3-eu-west-1.amazonaws.com/nvtk-video-media/03_01%20Hebrew%20Low.mp4',
                    lesson: 'https://s3-eu-west-1.amazonaws.com/nvtk-video-media/03_02%20Hebrew%20Low.mp4',
                    instructions: '',
                    monitor: '',
                    firstVisit: true
                },
                {
                    id: 'l24',
                    name:'intonation',
                    status: 'LOCKED',
                    demo: 'https://s3-eu-west-1.amazonaws.com/nvtk-video-media/03_01%20Hebrew%20Low.mp4',
                    lesson: 'https://s3-eu-west-1.amazonaws.com/nvtk-video-media/03_02%20Hebrew%20Low.mp4',
                    instructions: '',
                    monitor: '',
                    firstVisit: true
                },
                {
                    id: 'l25',
                    name:'self managed slow',
                    status: 'LOCKED',
                    demo: 'https://s3-eu-west-1.amazonaws.com/nvtk-video-media/03_01%20Hebrew%20Low.mp4',
                    lesson: 'https://s3-eu-west-1.amazonaws.com/nvtk-video-media/03_02%20Hebrew%20Low.mp4',
                    instructions: '',
                    monitor: '',
                    firstVisit: true
                },
                {
                    id: 'l26',
                    name:'self managed regular',
                    status: 'LOCKED',
                    demo: 'https://s3-eu-west-1.amazonaws.com/nvtk-video-media/03_01%20Hebrew%20Low.mp4',
                    lesson: 'https://s3-eu-west-1.amazonaws.com/nvtk-video-media/03_02%20Hebrew%20Low.mp4',
                    instructions: '',
                    monitor: '',
                    firstVisit: true
                },
                {
                    id: 'l27',
                    name:'self managed slow',
                    status: 'LOCKED',
                    demo: 'https://s3-eu-west-1.amazonaws.com/nvtk-video-media/03_01%20Hebrew%20Low.mp4',
                    lesson: 'https://s3-eu-west-1.amazonaws.com/nvtk-video-media/03_02%20Hebrew%20Low.mp4',
                    instructions: '',
                    monitor: '',
                    firstVisit: true
                },
                {
                    id: 'l28',
                    name:'self managed regular',
                    status: 'LOCKED',
                    demo: 'https://s3-eu-west-1.amazonaws.com/nvtk-video-media/03_01%20Hebrew%20Low.mp4',
                    lesson: 'https://s3-eu-west-1.amazonaws.com/nvtk-video-media/03_02%20Hebrew%20Low.mp4',
                    instructions: '',
                    monitor: '',
                    firstVisit: true
                },
                {
                    id: 'l1h',
                    name: 'צש בליא, מנסוטו צמלח',
                    status: 'COMPLETED',
                    demo: 'https://s3-eu-west-1.amazonaws.com/nvtk-video-media/03_01%20Hebrew%20Low.mp4',
                    lesson: 'https://s3-eu-west-1.amazonaws.com/nvtk-video-media/03_02%20Hebrew%20Low.mp4',
                    instructions: 'צש בליא, מנסוטו צמלח לביקו ננבי, צמוקו בלוקריה שיצמה ברורק. קולורס מונפרד אדנדום סילקוף, מרגשי ומרגשח. עמחליף קולורס מונפרד אדנדום סילקוף, מרגשי ומרגשח. עמחליף קולורס מונפרד אדנדום סילקוף, מרגשי ומרגשח. עמחליף מוסן מנת. להאמית קרהשק סכעיט דז מא, מנכם למטכין נשואי מנורך.',
                    monitor: '',
                    firstVisit: true
                },
                {
                    id: 'l2h',
                    name: 'קולורס מונפרד אדנדום ס',
                    status: 'COMPLETED',
                    demo: 'https://s3-eu-west-1.amazonaws.com/nvtk-video-media/03_01%20Hebrew%20Low.mp4',
                    lesson: 'https://s3-eu-west-1.amazonaws.com/nvtk-video-media/03_02%20Hebrew%20Low.mp4',
                    instructions: 'צש בליא, מנסוטו צמלח לביקו ננבי, צמוקו בלוקריה שיצמה ברורק. קולורס מונפרד אדנדום סילקוף, מרגשי ומרגשח. עמחליף קולורס מונפרד אדנדום סילקוף, מרגשי ומרגשח. עמחליף קולורס מונפרד אדנדום סילקוף, מרגשי ומרגשח. עמחליף מוסן מנת. להאמית קרהשק סכעיט דז מא, מנכם למטכין נשואי מנורך.',
                    monitor: '',
                    firstVisit: false
                },
                {
                    id: 'l3h',
                    name: 'קולורס מונפרד אדנדום ס',
                    status: 'COMPLETED',
                    demo: 'https://s3-eu-west-1.amazonaws.com/nvtk-video-media/03_01%20Hebrew%20Low.mp4',
                    lesson: 'https://s3-eu-west-1.amazonaws.com/nvtk-video-media/03_02%20Hebrew%20Low.mp4',
                    instructions: 'צש בליא, מנסוטו צמלח לביקו ננבי, צמוקו בלוקריה שיצמה ברורק. קולורס מונפרד אדנדום סילקוף, מרגשי ומרגשח. עמחליף קולורס מונפרד אדנדום סילקוף, מרגשי ומרגשח. עמחליף קולורס מונפרד אדנדום סילקוף, מרגשי ומרגשח. עמחליף מוסן מנת. להאמית קרהשק סכעיט דז מא, מנכם למטכין נשואי מנורך.',
                    monitor: '',
                    firstVisit: false
                },
                {
                    id: 'l4h',
                    name: 'מנכם למטכין נשואי מנורך',
                    status: 'COMPLETED',
                    demo: 'https://s3-eu-west-1.amazonaws.com/nvtk-video-media/03_01%20Hebrew%20Low.mp4',
                    lesson: 'https://s3-eu-west-1.amazonaws.com/nvtk-video-media/03_02%20Hebrew%20Low.mp4',
                    instructions: 'צש בליא, מנסוטו צמלח לביקו ננבי, צמוקו בלוקריה שיצמה ברורק. קולורס מונפרד אדנדום סילקוף, מרגשי ומרגשח. עמחליף קולורס מונפרד אדנדום סילקוף, מרגשי ומרגשח. עמחליף קולורס מונפרד אדנדום סילקוף, מרגשי ומרגשח. עמחליף מוסן מנת. להאמית קרהשק סכעיט דז מא, מנכם למטכין נשואי מנורך.',
                    monitor: '',
                    firstVisit: false
                },
                {
                    id: 'l5h',
                    name: 'להאמית קרהשק סכעיט דז מא',
                    status: 'CURRENT',
                    demo: 'https://s3-eu-west-1.amazonaws.com/nvtk-video-media/03_01%20Hebrew%20Low.mp4',
                    lesson: 'https://s3-eu-west-1.amazonaws.com/nvtk-video-media/03_02%20Hebrew%20Low.mp4',
                    instructions: 'צש בליא, מנסוטו צמלח לביקו ננבי, צמוקו בלוקריה שיצמה ברורק. קולורס מונפרד אדנדום סילקוף, מרגשי ומרגשח. עמחליף קולורס מונפרד אדנדום סילקוף, מרגשי ומרגשח. עמחליף קולורס מונפרד אדנדום סילקוף, מרגשי ומרגשח. עמחליף מוסן מנת. להאמית קרהשק סכעיט דז מא, מנכם למטכין נשואי מנורך.',
                    monitor: '',
                    firstVisit: false
                },
                {
                    id: 'l6h',
                    name:'גולר מונפרר סוברט ',
                    status: 'LOCKED',
                    demo: 'https://s3-eu-west-1.amazonaws.com/nvtk-video-media/03_01%20Hebrew%20Low.mp4',
                    lesson: 'https://s3-eu-west-1.amazonaws.com/nvtk-video-media/03_02%20Hebrew%20Low.mp4',
                    instructions: 'צש בליא, מנסוטו צמלח לביקו ננבי, צמוקו בלוקריה שיצמה ברורק. קולורס מונפרד אדנדום סילקוף, מרגשי ומרגשח. עמחליף קולורס מונפרד אדנדום סילקוף, מרגשי ומרגשח. עמחליף קולורס מונפרד אדנדום סילקוף, מרגשי ומרגשח. עמחליף מוסן מנת. להאמית קרהשק סכעיט דז מא, מנכם למטכין נשואי מנורך.',
                    monitor: '',
                    firstVisit: true
                },
                {
                    id: 'l7h',
                    name:'להאמית קרהשק סכעיט ',
                    status: 'LOCKED',
                    demo: 'https://s3-eu-west-1.amazonaws.com/nvtk-video-media/03_01%20Hebrew%20Low.mp4',
                    lesson: 'https://s3-eu-west-1.amazonaws.com/nvtk-video-media/03_02%20Hebrew%20Low.mp4',
                    instructions: 'צש בליא, מנסוטו צמלח לביקו ננבי, צמוקו בלוקריה שיצמה ברורק. קולורס מונפרד אדנדום סילקוף, מרגשי ומרגשח. עמחליף קולורס מונפרד אדנדום סילקוף, מרגשי ומרגשח. עמחליף קולורס מונפרד אדנדום סילקוף, מרגשי ומרגשח. עמחליף מוסן מנת. להאמית קרהשק סכעיט דז מא, מנכם למטכין נשואי מנורך.',
                    monitor: '',
                    firstVisit: true
                },
    ]
