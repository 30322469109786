import React from 'react';
import PropTypes from 'prop-types';
import { TEXT_SIZE, TEXT_COLOR } from '@components/Text';
import Input from '@components/Input';
import { getFormattedTextCurry } from '@shared/Resources/Monitor/shared/utils/formattedText';
import { CustomStimulusTitle, InputContainer } from './styles';

const getFormattedText = getFormattedTextCurry();

const SyllablesConfig = ({ config, updateField }) => (
  <React.Fragment>
    <CustomStimulusTitle>
      {getFormattedText('Stimuli.stimulusText', { textColor: TEXT_COLOR.DARK_BLUE, weight: 'bold' })}
      {getFormattedText('Informative.Notifying.productionNotChange', { size: TEXT_SIZE.T4, textColor: TEXT_COLOR.LIGHT_GRAY, margin: '0 0 -1px 0' })}
    </CustomStimulusTitle>
    <Input
      className="stimulusTextInput"
      value={config.customText}
      onChange={({ target: { value } }) => updateField('customText')(value)}
      width={390}
      height={44}
    />
    <InputContainer>
    </InputContainer>
  </React.Fragment>
);

SyllablesConfig.propTypes = {
  config: PropTypes.object,
  updateField: PropTypes.func,
};

export default SyllablesConfig;
