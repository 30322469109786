import {styled} from "@mui/material";
import CTAButton from '@components/CTAButton';
import { getIconsByType, iconTypes } from '@services/icons';
import { getResponsiveCSS } from '@utils/cssHelpers';

export const KidsIcon = getIconsByType(iconTypes.KIDS);
export const LocationIcon = getIconsByType(iconTypes.LOCATION);

export const Wrapper = styled('div')(
  {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    paddingLeft: 35,
    paddingRight: 35,
    borderRadius: 4,
    width: 590,
    letterSpacing: '-0.2px',
  },
  getResponsiveCSS('paddingTop', 40, 48),
  getResponsiveCSS('paddingBottom', 26, 16),
  ({ theme }) => ({
    backgroundColor: theme.palette.novotalk.$backgroundColor,
    direction: theme.RTL ? 'rtl' : 'inherit',
  })
);

export const HeaderContainer = styled('div')(
  {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    textAlign: 'center',
  },
  getResponsiveCSS('marginBottom', 20, 14)
);

export const IconContainer = styled('div')(
  getResponsiveCSS('width', 140, 170, '& > svg'),
  getResponsiveCSS('height', 140, 170, '& > svg'),
  getResponsiveCSS('marginBottom', 17, 22)
);

export const SubscribeMessageContainer = styled('div')(
  getResponsiveCSS('marginBottom', 18, 16)
);

export const StyledButton = styled(CTAButton)(
  {
    width: '200px',
    height: 44,
  },
  getResponsiveCSS('marginBottom', 17, 15)
);
