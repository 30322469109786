import {styled} from "@mui/material";
import { getIconByResourceType } from '@services/icons';
import isPropValid from '@emotion/is-prop-valid';
import { TYPES, COURSE_TYPES } from '../../types';

const PollIcon = getIconByResourceType(COURSE_TYPES.POLL);
export const StyledPollIcon = styled(PollIcon, {
  shouldForwardProp: isPropValid,
})(
  {
    fontSize: 32,
  },
  ({ theme }) => ({
    margin: theme.RTL ? '-14px -4px 0 5px' : '-14px 5px 0 -4px',
    color: theme.palette.novotalk.$textColorDarkBlue,
  })
);

const QuestionIcon = getIconByResourceType(TYPES.QUESTION);
export const StyledQuestionIcon = styled(QuestionIcon)(
  {
    position: 'absolute',
    top: 0,
    fontSize: 24,
  },
  ({ theme }) => ({
    [theme.RTL ? 'right' : 'left']: -32,
    color: theme.palette.novotalk.$questionIconColor,
  })
);

export const MiniOverviewQuestionIcon = styled(QuestionIcon)(
  {
    fontSize: 20,
  },
  ({ theme }) => ({
    color: theme.palette.novotalk.$questionIconColor,
  })
);

const typeIconStyles = ({ theme }) => ({
  fontSize: 18,
  marginLeft: -2,
  marginTop: -2,
  marginRight: 5,
  color: theme.palette.novotalk.$activeColor,
});

export const getStyledTypeIcon = (type) =>
  styled(getIconByResourceType(type))(typeIconStyles);

export const Wrapper = styled('div')(
  {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    maxHeight: 'calc(100vh - 182px)',
    gridArea: 'PollPane',
  },
  ({ theme }) => ({
    width: '100%',
    direction: theme.RTL ? 'rtl' : 'inherit',
    backgroundColor: theme.palette.novotalk.$backgroundColor,
  })
);

export const MiniOverviewWrapper = styled('div')({
  marginBottom: 40,
});

export const SplitContainer = styled('div')({
  width: '100%',
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  marginBottom: 10,
});

export const MiniOverviewTitle = styled('div')({
  display: 'flex',
  alignItems: 'center',
});

export const MiniOverviewText = styled('div')({
  lineHeight: '19px',
  marginBottom: 14,
});

export const PollBodyWrapper = styled('div')(
  {
    width: '100%',
    maxWidth: '950px',
    padding: 30,
    border: '1px solid',
    borderRadius: 4,
  },
  ({ theme }) => ({
    borderColor: theme.palette.novotalk.$borderColorLight,
  })
);

export const Title = styled('div')({
  minWidth: '950px',
  maxWidth: '950px',
  marginTop: '40px',
});

export const DescriptionWrapper = styled('div')({
  minWidth: '950px',
  maxWidth: '950px',
  marginBottom: '20px',
});

export const SaveWrapper = styled('div')({
  minWidth: '950px',
  maxWidth: '950px',
  marginTop: '20px',
});

export const CurrentUserData = styled('div')({
  display: 'flex',
  alignItems: 'center',
  padding: '0 0 20px',
});

export const Avatar = styled('img')({
  width: 32,
  height: 32,
  borderRadius: '50%',
  margin: '0 10px',
});
