import React from 'react';
import { useTranslation } from 'react-i18next';
import { ROUTES } from '@shared/constants';
import Text, { TEXT_COLOR, TEXT_SIZE } from '@components/Text';
import TextButton from '@components/TextButton';

import {
  ScreenContainer,
  WithHeaderComponentContainer,
  HeaderContainer,
  MainContainer,
  TextContainer,
  LinkContainer,
  StyledSignUpLink,
} from './styles';

export const VerifyEmailSent = ({ history, dispatchResendConfirmEmail }) => {
  const email = history.location.state && history.location.state.email;
  const { t } = useTranslation();

  return (
    <ScreenContainer>
      <WithHeaderComponentContainer>
        <HeaderContainer>
          <Text textColor={TEXT_COLOR.DARK_BLUE} size={TEXT_SIZE.H1}>
            {t('Authentication.Email.checkEmail')}
          </Text>
        </HeaderContainer>
        <MainContainer>
          <TextContainer>
            <Text textColor={TEXT_COLOR.DARK_BLUE} size={TEXT_SIZE.H4}>
              {t('Authentication.Email.verificationSent', {
                email,
              })}
            </Text>
          </TextContainer>
          <LinkContainer>
            <Text textColor={TEXT_COLOR.DISABLED} size={TEXT_SIZE.H4}>
              {t('Authentication.Email.notReceive')}
            </Text>
            <TextButton
              id="resend_email_link"
              onClick={() => dispatchResendConfirmEmail(email)}
            >
              <Text size={TEXT_SIZE.H4} margin="0 5px">
                {t('Authentication.Email.resend')}
              </Text>
            </TextButton>
          </LinkContainer>
        </MainContainer>
        <LinkContainer>
          <Text textColor={TEXT_COLOR.DARK_BLUE} size={TEXT_SIZE.H4}>
            {t('Actions.Navigation.backTo')}
          </Text>
          <StyledSignUpLink id="sign_in_link" to={ROUTES.SIGN_IN}>
            <TextButton>
              <Text size={TEXT_SIZE.H4}>
                {t('Actions.Auth.signIn')}
              </Text>
            </TextButton>
          </StyledSignUpLink>
        </LinkContainer>
      </WithHeaderComponentContainer>
    </ScreenContainer>
  );
};
