import {styled} from "@mui/material";

export const TitleWithTable = styled('div')({
  width: '60%',
  height: '100%',
});

export const TitleWithFilter = styled('div')({
  display: 'flex',
  justifyContent: 'space-between',
});

export const TableWrapper = styled('div')({
  fontSize: 13,
  display: 'flex',
});
