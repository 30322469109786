/**
 *
 * QuestionFreeText
 *
 */

import React from 'react';
import PropTypes from 'prop-types';
import Text, { TEXT_SIZE, TEXT_COLOR } from '@components/Text';
import { StyledDocumentIcon, TextArea } from './styles';

class QuestionFreeText extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      valid: props.value ? props.value.length > 0 : false,
      value: props.value || '',
    };
  }

  onChange = (event) => {
    const { value } = event.target;
    const valid = value.length > 0;
    this.setState({ valid, value });
    this.props.onChange({ valid, value });
  };

  render() {
    const { valid, value } = this.state;
    return (
      <div className='resource-item'>
        <div>
          <StyledDocumentIcon />
          <Text
            size={TEXT_SIZE.T2}
            textColor={TEXT_COLOR.DARK_BLUE}
            weight='900'
          >
            {this.props.questionText}
          </Text>
        </div>
        <TextArea value={value} error={!valid} onChange={this.onChange} />
      </div>
    );
  }
}

QuestionFreeText.propTypes = {
  questionText: PropTypes.string,
  value: PropTypes.string,
  onChange: PropTypes.func,
};

export default QuestionFreeText;
