import media from "../../../containers/MediaPage/__fixtures__";
import { coursesList } from "../data/course";
import { excercisesList } from "../data/excercise";
import { lessonsList } from "../data/lesson";
import { pollsList } from "../data/poll";
import { practiceList } from "../data/practice";
import { skillsList } from "../data/skill";

export const mediaItems = media;
export const courses = coursesList;
export const excercises = excercisesList;
export const lessons = lessonsList;
export const polls = pollsList;
export const practice = practiceList;
export const skills = skillsList;