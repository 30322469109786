import {styled} from "@mui/material";
import Input from '@components/Input';

export const OptionWrapper = styled('span')({
  display: 'inline-grid',
  gridTemplateColumns: '84px 86px auto',
  alignItems: 'center',
  height: 38,
});

export const RepetitionWrapper = styled('span')({
  display: 'flex',
  alignItems: 'center',
});

export const InnerContainer = styled('div')({
  paddingTop: 20,
  '&:first-of-type': {
    marginBottom: 30,
  },
});

export const Timer = styled(Input)(
  {
    width: 78,
    height: 32,
    padding: 0,
    textAlign: 'center',
    '&::-webkit-inner-spin-button, &::-webkit-outer-spin-button, &::-webkit-clear-button': {
      WebkitAppearance: 'none',
      margin: 0,
    },
  },
  ({ disabled }) => ({
    opacity: disabled ? 0.25 : 1,
    pointerEvents: disabled ? 'none' : 'all',
  })
);
