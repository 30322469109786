import { Component } from 'react';
import _ from 'lodash';
import PropTypes from 'prop-types';
import io from 'socket.io-client';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { SESSION_SERVER, ENVIRONMENT } from '@services/servers';
import {
  getFromLocalStorage,
  LOCAL_STORAGE_ITEM,
} from '@utils/localStorageHelper';

const ioOptions = {
  transports: ['websocket'],
  forceNew: true,
  reconnection: false,
  path: ENVIRONMENT === 'local' ? '/socket.io' : '/session/socket.io',
};

export class SocketManager extends Component {
  constructor(props) {
    super(props);
    this.state = {
      connected: false,
      connecting: false,
    };
    this.socket = null;
  }

  componentDidMount() {
    this.tryToConnect();
  }

  shouldComponentUpdate(nextProps) {
    const userChanged = !_.isEqual(this.props.user, nextProps.user);
    return userChanged;
  }

  componentDidUpdate() {
    this.tryToConnect();
  }

  onRegistered = (sessionID) =>
    console.log(`Registered succeffuly with socketID ${sessionID}`);

  onConnect = () => {
    const {
      user: { token },
    } = this.props;
    const sessionId = getFromLocalStorage(LOCAL_STORAGE_ITEM.SESSION_ID);

    this.setState({ connected: true, connecting: false });
    this.socket.emit('register', {
      sessionId,
      token,
    });
  };

  connect() {
    this.setState({ connecting: true });
    this.socket = io(SESSION_SERVER, ioOptions);
    this.socket.on('connect', this.onConnect);
    this.socket.on('registered', this.onRegistered);
  }

  tryToConnect() {
    const { user } = this.props;
    const { connected, connecting } = this.state;
    if (!connected && !connecting && user && user.token) {
      this.connect();
    }
  }

  render() {
    return null;
  }
}

SocketManager.propTypes = {
  user: PropTypes.object,
};

const mapStateToProps = (state) => {
  const user = state.getIn(['app', 'authentication', 'user']).toJS();
  return { user };
};

const withConnect = connect(mapStateToProps);

export default compose(withConnect)(SocketManager);
