import {styled} from "@mui/material";

export const UploadContainer = styled('div')({
  width: '100%',
  height: 0,
  display: 'flex',
  justifyContent: 'flex-end',
});

export const MediaPageContainer = styled('div')({
  width: 800,
  margin: '0 auto',
});

export const StyledButton = styled('button')({
  width: 130,
  height: 40,
  margin: '10px 0',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  borderRadius: 4,
  backgroundColor: '#23B7E5',
  color: '#FFF',
  textTransform: 'uppercase',
  cursor: 'pointer',
});

export const InputWrapper = styled('input')({
  display: 'none',
});
