import React from 'react';
import PropTypes from 'prop-types';
import Text, { TEXT_SIZE, TEXT_COLOR, TEXT_WEIGHT } from '@components/Text';
import { RESOURCES } from '../../types';
import { createMiniOverview } from '../../miniOverviewFactory';
import { DescriptionArea } from '@shared/Resources/shared/components/OverviewComponents/styles';
import { InstructionsArea, TitleContainer, StyledDocumentIcon } from './styles';
import {useTranslation} from "react-i18next";

const ExcerciseOverview = ({
  resource: {
    texts: { instructions },
    media: { demo },
    monitor,
  },
}) => {
  const {t} = useTranslation()
  return (
      <div>
        <DescriptionArea>
          <Text
              size={TEXT_SIZE.H4}
              textColor={TEXT_COLOR.DARK_BLUE}
              weight={TEXT_WEIGHT.BOLD}
          >
            {t('Actions.Instance.preview')}
          </Text>
        </DescriptionArea>
        <InstructionsArea>
          <TitleContainer>
            <StyledDocumentIcon />
            <Text size={TEXT_SIZE.H4} textColor={TEXT_COLOR.DARK_BLUE}>
              {t('Common.UI.instructions').toString().toUpperCase()}
            </Text>
          </TitleContainer>
          <Text size={TEXT_SIZE.H4} textColor={TEXT_COLOR.DARK_BLUE}>
            {instructions}
          </Text>
        </InstructionsArea>
        {createMiniOverview(RESOURCES.MEDIA, {
          title: t('Common.UI.demo'),
          value: demo,
        })}
        {createMiniOverview(RESOURCES.MONITOR, monitor)}
      </div>
  );
}

ExcerciseOverview.propTypes = {
  resource: PropTypes.object.isRequired,
};

export default ExcerciseOverview;
