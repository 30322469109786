import {styled} from "@mui/material";
import { getIconsByType, iconTypes } from '@services/icons';

export const Wrapper = styled('div')(
  {
    display: 'flex',
    justifyContent: 'space-around',
    alignItems: 'center',
    gridArea: 'listHeader',
  },
  ({ theme }) => ({
    borderBottom: `1px solid ${theme.palette.novotalk.$borderColorLight}`,
    borderRight: `1px solid ${theme.palette.novotalk.$borderColorLight}`,
  })
);

const NewItemIcon = getIconsByType(iconTypes.CREATE);

export const StyledNewItemIcon = styled(NewItemIcon)({
  width: 14,
  height: 14,
});

export const IconWrapper = styled('span')(
  {
    margin: '-2px 6px 0 6px',
  },
  ({ theme }) => ({
    color: theme.palette.novotalk.$textColorHighlited,
  })
);
