import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import Text, { TEXT_COLOR } from '@components/Text';
import Container from '@components/Container';
import {
  getUSADateFromFullDate,
  getUSATimeFromFullDate,
} from '@utils/timeHelpers';
import {
  FeedbackListContainer,
  FeedbackContainer,
  AvatarContainer,
  FeedbackHeaderContainer,
  FeedbackTextContainer,
} from './styles';
import {useTranslation} from "react-i18next";

const FeedbackList = ({
  feedbacks,
  withTitle,
}) => {
  const {t, i18n} = useTranslation()
  const isRTL = i18n.language === 'he';
  const sortedFeedbacks = useMemo(() => feedbacks.sort((feedbackFirst, feedbackSecond) =>
    feedbackSecond.createdAt - feedbackFirst.createdAt
  ), [feedbacks]);
  return (
    <Container column alignItems="flex-start">
      {withTitle && (
        <Container margin="0 0 10px 0">
          <Text size="T3" weight="bold" textColor={TEXT_COLOR.DARK_BLUE}>
            {t("UI.clinicianFeedbackList")}
          </Text>
        </Container>
      )}
      <FeedbackListContainer>
        {sortedFeedbacks.map(({ id, createdAt, payload, publisherUser }) => (
          <FeedbackContainer className="feedback-item" key={id}>
            <AvatarContainer>
              <img src={publisherUser.avatar} alt={publisherUser.firstName} />
            </AvatarContainer>
            <Container column alignItems="flex-start">
              <FeedbackHeaderContainer>
                <Text
                  size="H4"
                  textColor={TEXT_COLOR.DARK_BLUE}
                  margin={isRTL ? '0 0 0 30px' : '0 30px 0 0'}
                  weight={isRTL ? 'bold' : 'normal'}
                >
                  {`${publisherUser.firstName} ${publisherUser.lastName}`}
                </Text>
                <Text
                  size="H4"
                  textColor={TEXT_COLOR.DISABLED_LIGHT}
                  weight={isRTL ? 'bold' : 'normal'}
                >
                  {getUSADateFromFullDate(createdAt)}
                  {'  '}
                  {getUSATimeFromFullDate(createdAt)}
                </Text>
              </FeedbackHeaderContainer>
              <FeedbackTextContainer>{payload.text}</FeedbackTextContainer>
            </Container>
          </FeedbackContainer>
        ))}
      </FeedbackListContainer>
    </Container>
  );
};

FeedbackList.propTypes = {
  feedbacks: PropTypes.array,
  withTitle: PropTypes.bool,
};

export default FeedbackList;
