import { put, takeLatest, all, fork, join } from 'redux-saga/effects';
import { authenticationWrapper } from '@services/errorHandlingService';
import {
  Types,
  saveCompleted,
  saveFailed,
  loadCompleted,
  loadFailed,
} from './actions';
import { saveEntity, loadEntity, modifyEntity } from './service';
import { createNewResource } from '@containers/Admin/shared/ResourcesWrapper/actions';


export function* callSave(action) {
  yield authenticationWrapper(
    function* codeBlock() {
      const { type, info, components } = action.payload;
      ;
      const res = yield fork(saveEntity, type, info, components);
      const result = yield join(res);
      if (result.errors) {
        yield put(saveFailed(result.errors[0]));
      } else {
        yield put(saveCompleted());
      }
    },
    function* errorHandler(error) {
      yield put(saveFailed(error));
    }
  );
}

export function* callEdit(action) {
  yield authenticationWrapper(
    function* codeBlock() {
      const { type, info, components } = action.payload;
      
      const res = yield fork(modifyEntity, type, info, components);
      
      const result = yield join(res);
      
      if (result.errors) {
        yield put(saveFailed(result.errors[0]));
      } else {
        yield put(saveCompleted());
      }
    },
    function* errorHandler(error) {
      yield put(saveFailed(error));
    }
  );
}

// const useFixtures = process.env.USE_FIXTURES;
export function* callLoad(action) {
  yield authenticationWrapper(
    function* codeBlock() {
      const { id, type } = action.payload;
      const res = yield fork(loadEntity, type, id);
      const { errors, data } = yield join(res);
      if (errors) {
        yield put(loadFailed(errors[0]));
      } else if (!data.entity || data.entity.length === 0) {
        yield put(loadFailed(`Entity not found - ${type} : #${id}`));
        yield put(createNewResource(type));
      } else {
        yield put(loadCompleted(type, data.entity[0]));
      }
    },
    function* errorHandler(error) {
      yield put(loadFailed(error));
    }
  );
}

function* watchCallSave() {
  yield takeLatest(Types.BUILDER_SAVE, callSave);
}
function* watchCallEdit() {
  yield takeLatest(Types.BUILDER_EDIT, callEdit);
}
function* watchCalload() {
  yield takeLatest(Types.BUILDER_LOAD, callLoad);
}

export default function* builderDataBuilderSaga() {
  yield all([
      fork(watchCallSave),
      fork(watchCalload),
      fork(watchCallEdit)
  ]);
}
