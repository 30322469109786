import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import OverviewSection from '@shared/Resources/shared/components/OverviewSection';
import { createMiniOverview } from '@shared/Resources/miniOverviewFactory';
import { RESOURCES } from '@shared/Resources/types';

const CourseOverview = (props) => {
  const courseSectionsData = useMemo(() => ({
    items: props.resource.children,
    type: 'SKILL',
    ...props
  }), [props])
  return (
    <>
      {createMiniOverview(RESOURCES.COURSE, null, { isDefault: props.resource.isDefault })}
      <OverviewSection {...courseSectionsData} />
    </>
  );
};

CourseOverview.propTypes = {
  readOnly: PropTypes.bool,
  resource: PropTypes.object,
  onEdit: PropTypes.func,
  onView: PropTypes.func,
  onExpand: PropTypes.func,
};

export default CourseOverview;
