import {styled} from "@mui/material";
import { getIconsByType, iconTypes } from '@services/icons';
import { TypeSelection, TypeSelectionSquare } from '../../shared/styles';

export const LabelContainer = styled('span')({
  display: 'inline-grid',
  gridTemplateColumns: '24px max-content',
  alignItems: 'center',
  position: 'relative',
});

export const MonitorType = TypeSelection;

export const Header = styled('div')({
  marginBottom: 15,
});

export const MonitorImageWrapper = TypeSelectionSquare;

export const TypesContainer = styled('div')({
  display: 'flex',
  marginBottom: 30,
});

export const ExtraMonitorsWrapper = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start',
});

export const ExtraMonitorsHeader = styled('div')({
  marginBottom: 20,
});

export const ExtraMonitor = styled('div')({
  position: 'relative',
  height: 22,
  marginBottom: 20,
  '&:last-of-type': {
    marginBottom: 0,
  },
});

export const InhaleContainer = styled('span')({
  position: 'absolute',
  left: '100%',
  top: 'calc(50% - 16px)',
  display: 'flex',
  alignItems: 'center',
});

const iconStyles = ({ theme }) => ({
  fontSize: 20,
  marginTop: -2,
  color: theme.palette.novotalk.$textColorLightGray,
});

export const InhalationIcon = styled(getIconsByType(iconTypes.SWAP))(
  {
    fontSize: 15,
    borderRadius: 2,
  },
  ({ theme }) => ({
    color: theme.palette.novotalk.$textColorReversed,
    backgroundColor: theme.palette.novotalk.$textColorLightGray,
  })
);

export const MirrorIcon = styled(getIconsByType(iconTypes.PERSON))(iconStyles);

export const SpeedometerIcon = styled(
  getIconsByType(iconTypes.SPEEDOMETER_FILLED)
)(iconStyles, {
  fontSize: 18,
  marginTop: 0,
});

export const VideoIcon = styled(getIconsByType(iconTypes.PLAY_CIRCLE))(
  iconStyles
);
