export const Types = {
  RESEND_CONFIRM_EMAIL: 'RESEND_CONFIRM_EMAIL',
  RESEND_CONFIRM_EMAIL_SUCCESS: 'RESEND_CONFIRM_EMAIL_SUCCESS',
  RESEND_CONFIRM_EMAIL_FAILURE: 'RESEND_CONFIRM_EMAIL_FAILURE',
};

export const resendConfirmEmail = (email) => ({
  type: Types.RESEND_CONFIRM_EMAIL,
  email,
});

export const resendConfirmEmailSuccess = (response) => ({
  type: Types.RESEND_CONFIRM_EMAIL_SUCCESS,
  response,
});

export const resendConfirmEmailFailure = (error) => ({
  type: Types.RESEND_CONFIRM_EMAIL_FAILURE,
  error,
});

