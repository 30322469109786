import { RESOURCES } from '@shared/Resources/types';

// db
export const lesson = {
  id: 'l1',
  name: 'basics',
  type: RESOURCES.LESSON,
  media: {
    demo: 'someMediaID',
    lesson: 'someMediaID',
  },
  texts: {
    instructions: 'You will learn how to breathe diaphragmatic breathing correctly. It will serve you later on in the course',
  },
  monitor: 'someMonitorID',
};

export const gql = `
{
  //common
  type
  id
  createdBy
  name

  // unique 
  instructions
  mediaDemo
  mediaLesson
  monitor

  //----- edges:
  entityCreated{
    by
    at
  }
  entityUpdated {
    by
    at
  }
  //array
  usedIn{
    type
    id
  }
}
`;

export const lessonsList = [
  { id: 1, name: 'Intro to 2 Seconds Pattern', created: 'Thu Aug 09 2018 08:46:00 GMT+0000 (UTC)', updated: 'Thu SEP 06 2018 08:46:00 GMT+0000 (UTC)' },
  { id: 2, name: '2 Seconds Pattern', created: 'Thu Aug 16 2018 18:46:30 GMT+0000 (UTC)', updated: 'Thu SEP 13 2018 18:46:30 GMT+0000 (UTC)' },
  { id: 3, name: 'Advanced 2 Second Pattern', created: 'Thu Aug 23 2018 02:46:54 GMT+0000 (UTC)', updated: 'Thu SEP 20 2018 02:46:54 GMT+0000 (UTC)' },
  { id: 4, name: '1 Second Pattern', created: 'Thu Aug 30 2018 23:46:15 GMT+0000 (UTC)', updated: 'Thu SEP 27 2018 23:46:15 GMT+0000 (UTC)' },
  { id: 5, name: '2 Second Pattern', created: 'Thu Aug 30 2018 23:46:15 GMT+0000 (UTC)', updated: 'Thu SEP 27 2018 23:46:15 GMT+0000 (UTC)' },
  { id: 6, name: 'Advanced 1 Second Pattern', created: 'Thu Aug 30 2018 23:46:15 GMT+0000 (UTC)', updated: 'Thu SEP 27 2018 23:46:15 GMT+0000 (UTC)' },
];

/**
 {
  id: 'l1',
  name: 'basics',
  status: 'COMPLETED',
  demo: 'https://s3-eu-west-1.amazonaws.com/nvtk-video-media/03_01%20Hebrew%20Low.mp4',
  lesson: 'https://s3-eu-west-1.amazonaws.com/nvtk-video-media/03_02%20Hebrew%20Low.mp4',
  instructions: 'You will learn how to breathe diaphragmatic breathing correctly. It will serve you later on in the course',
  monitor: '',
  firstVisit: false
}
*/
