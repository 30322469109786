/**
 *
 * ResourceManagerHeader
 *
 */

import { Component } from 'react';
import Proptypes from 'prop-types';
import _ from 'lodash';
import Container from '@components/Container';
import Text, { TEXT_SIZE, TEXT_COLOR, TEXT_WEIGHT } from '@components/Text';
import { PLACEMENT } from '@components/CustomTextInput';
import ClickAwayListener from '@components/ClickAwayListener';
import {
  Wrapper,
  ResourcesItemsHeader,
  getStyledHeaderIcon,
  StyledCustomTextInput,
  StyledSearchIcon,
  ButtonContainer,
  StyledCTAButton,
  StyledAddIcon,
  ArrowBoxContainer,
  ArrowBox,
  ResourceItem,
  getStyledMenuIcon,
} from './styles';
import {CREATABLE_RESOURCES, RESOURCES, SPEECH_GOAL} from '@shared/Resources/types';
import POPUP_TYPES from '@containers/App/containers/PopUp/types';
import { openPopUp } from '@containers/App/containers/PopUp/actions';
import { connect } from 'react-redux';
import { compose } from 'redux';
import ToggleSwitch from '@components/ToggleSwitch';
import { changeFilterType, getResources } from '@containers/Admin/shared/ResourcesWrapper/actions';
import { EXCLUDED_TYPES } from './consts';
import {withTranslation} from "react-i18next";

export class ResourceHeader extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isOpen: false,
    };
  }

  componentWillUnmount() {
    this.props.dispatchChangeFilterType(false);
  }

  onResourceSelected = (resource) => {
    const { onNewResource, dispatchOpenPopup } = this.props;
    this.toggleMenu();
    if (resource === RESOURCES.MEDIA) {
      return dispatchOpenPopup(POPUP_TYPES.MEDIA_UPLOAD);
    }
    onNewResource(resource);
  };

  toggleMenu = () => {
    this.setState({ isOpen: !this.state.isOpen });
  };

  hideMenu = () => {
    this.setState({ isOpen: false });
  };

  handleInput = (event) => {
    const { value } = event.target;
    const { onFilterInput } = this.props;
    onFilterInput(value);
  };

  toggleFilterType = () => {
    const {
      dispatchLoadResources,
      dispatchChangeFilterType,
      resourceType,
      isTemplate,
    } = this.props;

    dispatchChangeFilterType(isTemplate);
    dispatchLoadResources(resourceType);
  }

  renderResourceItem = (resource) => {
    const ResourceIcon = getStyledMenuIcon(resource);
    return (
      <ResourceItem
        key={resource}
        className='resourceItem'
        onClick={() => this.onResourceSelected(resource)}
      >
        <Text size='H5'>
          <ResourceIcon />
          {_.capitalize(resource.replace('_', ' '))}
        </Text>
      </ResourceItem>
    );
  };

  transformType = (type) => type === SPEECH_GOAL ? 'speechGoal' : type.toLowerCase()

  render() {
    const {
      type,
      filterValue,
      isTemplate,
      resourceType,
      t
    } = this.props;
    const { isOpen } = this.state;

    const StyledHeaderIcon = getStyledHeaderIcon(type);
    const resources = _.values(CREATABLE_RESOURCES);

    return (
      <Wrapper id='resourcesHeader'>
        <ResourcesItemsHeader>
          <Container>
            <StyledHeaderIcon />
            <Text
              id='title'
              size={TEXT_SIZE.H0}
              textColor={TEXT_COLOR.DARK_BLUE}
              weight={TEXT_WEIGHT.BOLD}
            >
              {t(`Common.UI.${this.transformType(type)}`)}
            </Text>
          </Container>
          {EXCLUDED_TYPES[resourceType] !== resourceType && (
            <ToggleSwitch
              showNames
              names={[t('Common.UI.owned'), t('Common.UI.template')]}
              value={isTemplate}
              onChange={this.toggleFilterType}
              className="typeToggle"
            />
          )}
          <StyledCustomTextInput
            placeholder={`${t('Actions.Navigation.search')} ${t(`Common.UI.${this.transformType(type)}`)}`}
            onChange={this.handleInput}
            value={filterValue}
            Icon={() => <StyledSearchIcon />}
            iconPlacement={PLACEMENT.RIGHT}
            name='resources-filter-input'
            id='items-filter-input'
          />
        </ResourcesItemsHeader>
        <ClickAwayListener onClickAway={this.hideMenu}>
          <ButtonContainer>
            <StyledCTAButton
              isOpen={isOpen}
              id='newResourceButton'
              onClick={this.toggleMenu}
            >
              <StyledAddIcon />
              {t('Common.Statuses.new').toUpperCase()}
            </StyledCTAButton>
            {isOpen && (
              <ArrowBoxContainer>
                <ArrowBox>{resources.map(this.renderResourceItem)}</ArrowBox>
              </ArrowBoxContainer>
            )}
          </ButtonContainer>
        </ClickAwayListener>
      </Wrapper>
    );
  }
}

ResourceHeader.propTypes = {
  type: Proptypes.string,
  onNewResource: Proptypes.func,
  filterValue: Proptypes.string,
  onFilterInput: Proptypes.func,
  isTemplate: Proptypes.bool,
  resourceType: Proptypes.bool,
  dispatchOpenPopup: Proptypes.func,
  dispatchLoadResources: Proptypes.func,
  dispatchChangeFilterType: Proptypes.func,
};

const mapStateToProps = (state) => {
  const { isTemplate, type: resourceType } = state.get('resourceManager') ? state.get('resourceManager').toJS() : {};
  return { isTemplate, resourceType };
};

const mapDispatchToProps = (dispatch) => ({
  dispatchOpenPopup: (type, props) => dispatch(openPopUp(type, props)),
  dispatchLoadResources: (type) => dispatch(getResources(type)),
  dispatchChangeFilterType: (isTemplate) => dispatch(changeFilterType(isTemplate)),
});

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default compose(
    withTranslation(),
    withConnect
)(ResourceHeader);
