import { useMemo } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { FillMonitorContainer, WrapperProgressMonitor } from './styles';
import StaticBar from '../StaticBar';
import AnimatedBar from './components/AnimatedBar';
import { PauseButton } from '../../../../../../components/PauseButton';

const FillMonitor = ({
  duration,
  animating,
  widthMonitor,
  height,
  isInhalation,
}) => {
  const { location } = useHistory();

  const { i18n } = useTranslation();
  const isRTL = useMemo(() => i18n.language === 'he', [i18n]);
  const isLesson = useMemo(() => location?.pathname?.includes('lesson'), [location]);
  const renderGradientBar = useMemo(
    () => (animating ? <AnimatedBar duration={duration} isRTL={isRTL && !isLesson} /> : null),
    [animating, duration, isRTL],
  );

  return (
    <FillMonitorContainer
      duration={duration}
      height={height}
      isRTL={isRTL && !isLesson}
      isInhalation={isInhalation}
    >
      <StaticBar>
        <WrapperProgressMonitor
          widthMonitor={widthMonitor}
          isInhalation={isInhalation}
        >
          {!isInhalation && <PauseButton />}
          {renderGradientBar}
        </WrapperProgressMonitor>
      </StaticBar>
    </FillMonitorContainer>
  );
};

FillMonitor.propTypes = {
  duration: PropTypes.string,
  animating: PropTypes.bool,
  widthMonitor: PropTypes.string,
  height: PropTypes.string,
  isInhalation: PropTypes.bool,
};

export default FillMonitor;
