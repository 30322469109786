import {styled} from "@mui/material";
import { getResponsiveCSS } from '@utils/cssHelpers';
import Text from '@components/Text';

export const Header = styled('div')(
  {
    width: '100%',
    padding: '13px 20px',
    marginBottom: 30,
    lineHeight: '1em',
  },
  ({ theme }) => ({
    backgroundColor: theme.palette.novotalk.$backgroundColorMedium,
  })
);

export const DetailsContainer = styled('div')(
  {
    padding: '0 20px',
    display: 'grid',
    gridTemplateColumns: '285px auto',
    gridGap: 40,
    justifyItems: 'start',
  },
  getResponsiveCSS('marginBottom', 20, 40)
);

export const DetailsItem = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  lineHeight: '21px',
});

export const DetailValueText = styled(Text)(({ theme }) => ({
  marginRight: theme.RTL ? 20 : 0,
}));
