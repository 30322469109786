import { RESOURCES } from '../../types';
import {monitorConfiguration} from '../../Monitor/__fixtures__'

export const overview = {
  id: 'lessonId1',
  name: 'basics',
  type: RESOURCES.LESSON,
  texts: {
    instructions: 'Pellentesque orci lectus, consectetur vel posuere posuere, rutrum eu ipsum. Aliquam eget odio sed ligula iaculis consequat at eget orci.',
    description: 'Pellentesque orci lectus, consectetur vel posuere posuere, rutrum eu ipsum. Aliquam eget odio sed ligula iaculis consequat at eget orci.',
  },
  media: {
    demo: {
      "id":"aaaa",
      "description" : "My video for the poll",
      "title" : "נשימה חדש.mp4",
      "value" : "https://s3-eu-west-1.amazonaws.com/nvtk-video-media-stg/2018_07/VideoForLesson1.mp4",
    },
    lesson: {
      "id":"aaaa",
      "description" : "My video for the poll",
      "title" : "נשימה חדש.mp4",
      "value" : "https://s3-eu-west-1.amazonaws.com/nvtk-video-media-stg/2018_07/VideoForLesson1.mp4",
    },
  },
  monitor: monitorConfiguration,
  entityCreated: {
    at: 'Thu Aug 02 2018 18:05:31 GMT+0000 (UTC)',
    by: 'User McUser',
  },
  entityUpdated: {
    at: 'Thu Aug 09 2018 08:46:00 GMT+0000 (UTC)',
    by: 'Useriko McUserico',
  },
  usedIn: [
    { id: 1, type: RESOURCES.COURSE, name: 'Some course 1' },
    { id: 2, type: RESOURCES.POLL, name: 'Some poll 2' },
  ]
};