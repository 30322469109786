import {styled} from "@mui/material";

export const Wrapper = styled('div')({
    width: '100%',
    height: '100%',
    padding: 40,
    position: 'relative',
  },
  ({ theme }) => ({
    backgroundColor: theme.palette.novotalk.$backgroundColor,
  })
);

export const PaginationWrapper = styled('div')({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    paddingTop: 30,
    width: '100%',
    '>:first-of-type': {
      flexGrow: 1,
    },
    '>:last-child': {
      flexGrow: 2,
    },
  },
)

export const MainTitlePage = styled('div')({
  textTransform: 'uppercase',
  fontSize: 24,
  margin: '0 0 20px 14px',
  display: 'block',
},
  ({ theme }) => ({
    color: theme.palette.novotalk.$novotalkBlue,
  })
);

export const TotalCountUser = styled('div')({
    fontSize: 14,
  },
  ({ theme }) => ({
    color: theme.palette.novotalk.$textColorDisabled,
  })
);
