import {styled} from "@mui/material";
import { getIconsByType, iconTypes } from '@services/icons';
import Text from '@components/Text';

export const DropArea = styled('div')((props) => ({
  border: props.emptyArea ? '1px dashed #23B7E5' : '1px solid transparent',
  borderRadius: 5,
  height: 150,
  cursor: 'pointer',
}));

export const UploadItem = styled('div')({
  marginTop: 45,
  textAlign: 'center',
});

export const Chip = styled('div')({
  height: 50,
  margin: '0 auto',
  padding: '0 10px',
  display: 'inline-flex',
  alignItems: 'center',
  borderRadius: 25,
  backgroundColor: '#23B7E5',
});

export const ChipText = styled(Text)({
  maxWidth: 200,
  overflow: 'hidden',
  textOverflow: 'ellipsis',
});

const CloseIcon = getIconsByType(iconTypes.CLOSE_CIRCLE);

export const StyledCloseIcon = styled(CloseIcon)(
  {
    fontSize: 26,
    margin: 4,
    cursor: 'pointer',
  },
  ({ theme }) => ({
    color: theme.palette.novotalk.$textColorReversed,
  })
);

export const EmptyAreaWrapper = styled('div')({
  marginTop: 60,
  pointerEvents: 'none',
  textAlign: 'center',
  zIndex: -9999,
});
