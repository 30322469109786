import {styled} from "@mui/material";

const innerHeader = 60;

export const MediaViewerContainer = styled('div')(
  {
    width: '100%',
    margin: '0 auto',
  },
  ({ theme }) => ({
    gridTemplateRows: `${innerHeader}px calc(100vh - ${
      theme.palette.novotalk.navigationBarHeight + innerHeader
    }px)`,
  })
);

export const MediaPageHeader = styled('div')({
  height: innerHeader,
  display: 'block',
  padding: '10px 0',
});
export const MediaPageHeaderContent = styled('div')({
  display: 'flex',
  flexDirection: 'row',
  width: '100%',
  margin: 'auto',
  justifyContent: 'space-between',
});

export const MediaItemThumbnail = styled('video')({
  width: '250',
  cursor: 'pointer',
  maxHeight: '150',
});

export const MediaList = styled('div')(
  {
    gridArea: 'MediaList',
    width: '100%',
    margin: '0 auto',
    borderRadius: 4,
    boxShadow: '0 0 1px 1px #DDD',
    overflow: 'auto',
    height: 470,
  },
);
