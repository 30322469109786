import React, { Component } from 'react';
import _ from 'lodash';
import { compose } from 'redux';
import PropTypes from 'prop-types';
import Text, { TEXT_COLOR, TEXT_SIZE } from '@components/Text';
import TextButton from '@components/TextButton';
import {
  TextChatWrapper,
  ChatInput,
  MessagesHistory,
  ChatBubble,
  MessageWrapper,
  InputWrapper,
  HistoryWrapper,
  textChatShadow,
  ChatAvatar,
} from '../../styles';
import {withTranslation} from "react-i18next";

class TextChat extends Component {
  constructor(props) {
    super(props);
    this.handleKeyPress = this.handleKeyPress.bind(this);
    this.inputRef = React.createRef();
    this.historyRef = React.createRef();
    this.historyWrapperRef = React.createRef();
  }

  componentDidMount() {
    const history = this.historyWrapperRef.current;
    const selfVideo = document.getElementById('selfVideoContainer');
    history.addEventListener('scroll', () => {
      if (history.scrollTop > 0) {
        selfVideo.style.boxShadow = textChatShadow;
      } else {
        selfVideo.style.boxShadow = 'none';
      }
    });
  }

  componentDidUpdate() {
    if (this.historyRef.current.lastElementChild)
      this.historyRef.current.lastElementChild.scrollIntoView();
  }

  handleKeyPress(e) {
    if (e.key === 'Enter') {
      this.sendMessage();
    }
  }

  sendMessage = () => {
    const message = this.inputRef.current.value.trim();
    if (message.length > 0) {
      this.props.sendTextMessage(message);
    }
    this.inputRef.current.value = '';
  };

  renderMessagesHistory() {
    const { textMessages, sessionInfo } = this.props;
    return textMessages.map((message) => {
      const user = _.find(sessionInfo.users, {
        participantId: message.participantId,
      });
      return user ? (
        <MessageWrapper
          key={`${message.participantId}-${message.timestamp}`}
          self={user.self}
        >
          <ChatAvatar src={user.avatar} />
          <ChatBubble self={user.self}>
            {!user.self && (
              <Text
                size={TEXT_SIZE.H4}
                color={TEXT_COLOR.DARK_BLUE}
                weight={'normal'}
              >
                {user.name}:
              </Text>
            )}
            <Text size={TEXT_SIZE.H4} color={TEXT_COLOR.DARK_BLUE}>
              {message.message}
            </Text>
          </ChatBubble>
        </MessageWrapper>
      ) : undefined;
    });
  }

  render() {
const {t} =this.props
    const placeholder = t(`Common.Placeholders.${this.props.enabled ? "text": "chatClosed"}`)

    return (
      <TextChatWrapper>
        <HistoryWrapper ref={this.historyWrapperRef}>
          <MessagesHistory ref={this.historyRef} id='history'>
            {this.renderMessagesHistory()}
          </MessagesHistory>
        </HistoryWrapper>
        <InputWrapper>
          <ChatInput
            placeholder={placeholder}
            onKeyPress={this.handleKeyPress}
            ref={this.inputRef}
            disabled={!this.props.enabled}
          />
          <TextButton onClick={this.sendMessage} disabled={!this.props.enabled}>
            {t('Common.Statuses.send').toUpperCase()}
          </TextButton>
        </InputWrapper>
      </TextChatWrapper>
    );
  }
}

TextChat.propTypes = {
  textMessages: PropTypes.array,
  sessionInfo: PropTypes.object,
  sendTextMessage: PropTypes.func,
  enabled: PropTypes.bool,
};

export default compose(withTranslation())(TextChat);
