
import FeedbackSVG from './Feedback';
import MonitorSVG from './Monitor';
import StimulusSVG from './Stimulus';
import CreateSVG from './Create';
import PatternTypeSVG from './PatternType';
import RecreationSVG from './Recreation';
import InhalationIcon from './InhalationIcon';
import KidsSVG from './Kids';
import MailSVG from './Mail';
import VideoOffSVG from './VideoOff';
import VideoChatSVG from './VideoChat';
import InsightsGentleSVG from './InsightsGentle';
import InsightsLengthSVG from './InsightsLength';
import InsightsPatternSVG from './InsightsPattern';
import InsightsVolumeControlSVG from './InsightsVolumeControl';
import LocationSVG from './Location';
import InsightsTooFlat from './InsightsTooFlat'
import InsightsTooDeep from './InsightsTooDeep'
import InsightsTooSlowTooFast from './InsightsTooSlowTooFast'

const allIcons = {
  FeedbackSVG,
  MonitorSVG,
  StimulusSVG,
  CreateSVG,
  PatternTypeSVG,
  RecreationSVG,
  InhalationIcon,
  KidsSVG,
  MailSVG,
  VideoOffSVG,
  VideoChatSVG,
  InsightsGentleSVG,
  InsightsLengthSVG,
  InsightsPatternSVG,
  InsightsVolumeControlSVG,
  LocationSVG,
  InsightsTooFlat,
  InsightsTooDeep,
  InsightsTooSlowTooFast
};

export default allIcons;
