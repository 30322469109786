/**
 *
 * PollOverview
 *
 */

import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import Text, { TEXT_SIZE, TEXT_COLOR, TEXT_WEIGHT } from '@components/Text';
import { TitleContainer, DescriptionArea } from './styles';

import PollComponents from '../Client/pollComponents';
import {withTranslation} from "react-i18next";
import {compose} from "redux";

class PollOverview extends PureComponent {
  render() {
    const {resource: { description, children }, t} = this.props;
    return (
      <div>
        <DescriptionArea>
          <TitleContainer>
            <Text
              size={TEXT_SIZE.H4}
              textColor={TEXT_COLOR.DARK_BLUE}
              weight={TEXT_WEIGHT.BOLD}
            >
              {t('Common.UI.description')}
            </Text>
          </TitleContainer>
          <Text size={TEXT_SIZE.T4} textColor={TEXT_COLOR.DARK_BLUE}>
            {description}
          </Text>
        </DescriptionArea>
        {children.map((child) => (
          <div key={child.id}>
            {PollComponents(child, null, null, null, true, undefined, true)}
          </div>
        ))}
      </div>
    );
  }
}

PollOverview.propTypes = {
  resource: PropTypes.object.isRequired,
};

export default compose(withTranslation())(PollOverview);
