const map = {
  'Self managed speech rate': {
    General: 'Self managed rate sounds a little more natural than the 2 and 1 second rates. There is no specific measure, it is a range of rates in which you will find a rate which is comfortable for you to use for an extended period of time. Not too fast and not too slow.',
    'Gentle onset': 'Very important to use the wave pattern and, in particular, gentle onset.',
    'Vocal control': 'It is important to maintain vocal control as you learned and practised throughout the course. Use diaphragmatic breathing and mouth opening to control rises and falls in vocal volume. ',
    'Wave pattern': 'It is very important to always use the wave pattern - no matter which speech rate is being used. Start the wave pattern with gentle onset and prolong the first syllable slightly at the beginning of each vocalization. End each syllable with gentle offset. The Monitor provides feedback as to the correct wave pattern. It is there to help you maintain and achieve the correct pattern.',
    'Correct breathing': 'Try to use correct diaphragmatic breathing so that there is enough air to produce the necessary number of syllables without losing sense of the words or the meaning of the entire sentence. \n',
    'incorrect place of inhalation': 'The self managed speech rate is faster than previous rates and therefore more syllables can be produced on one breath. Be sure not to \nstop to breathe mid word or in an innapropriate place within an utterance or sentence. This may well impact the meaning of the utterance.',
    planning: 'Try to plan how many syllables to say on one breath so as not to change the meaning of the utterance by pausing in the wrong place. It is important to consider how much air you have on one breath and how many syllables and words can be said on that \none breath.Too many words on one breath may also result in vocal strain.  ',
  },
  'Self managed speech rate - \nIntonation during\nspontaneous speech': {
    'use of intonation': 'Intonation adds melody to  human speech and allows the speaker to convey specific intentions or feelings. Subtle voice changes carry \n meaning and provide additional information to the listener. Intonation can change the utterance into a question, statement, comment, \namazement etc. ',
    'Basic speech tasks': 'Make sure that breathing and speech are correctly coordinated. Make sure to use the wave pattern and gente onset.',
    'Complex speech tasks': 'Make sure that you have planned out what it is you want to say and how you want to say it - content and order. Remember to make sure  that breathing and speech are correctly coordinated. Make sure to use the wave pattern and gente onset. Maintain the meaning of what is being said. ',
  },
};
export default map;
