import { useEffect, useState } from 'react';
import { useSelector, useDispatch, RootStateOrAny } from 'react-redux';
import {
  Checkbox,
  FormControl, MenuItem, Select,
} from '@mui/material';
import {
  attachUserPageMentor,
} from '@containers/Admin/containers/UserPage/actions';
import { Mentor } from './types';
import { SELECTOR_WIDTH } from './consts';
import {useTranslation} from "react-i18next";

interface SelectMentorsProps {
  isClinicalMentor: boolean
}

/**
* Select for mentors
* @param {boolean} isClinicalMentor - Value for checking current user is supervisor
* @returns {React.ReactElement} React element
*/
export const SelectMentors = ({ isClinicalMentor }: SelectMentorsProps) => {
  const {t} = useTranslation()
  const dispatch = useDispatch();
  const {
    userMentors,
    allMentors,
    userData: { id },
  } = useSelector((state: RootStateOrAny) => state.get('userPage').toJS()); // FIXME replace to typing store

  const [options, setOptions] = useState<Array<Mentor>>([]);

  const checkedHandler = (event:React.ChangeEvent, option:Mentor):void => {
    dispatch(attachUserPageMentor(option.value, id, option.checked));
  };
  const renderValueHandler = ():string => options
    .filter((option) => option.checked)
    .map((option) => option.label)
    .join(', ');

  /**
  * Add field "checked" to each mentors
  * @returns {Array<Mentor>} Mentors with changed field "checked"
  */
  const checkUserMentorsInAllMentors = (): void => {
    const optionsToSet: Array<Mentor> = [];
    const userMentorsIds = userMentors.map(({ value }: Mentor) => value);
    allMentors.forEach(({ value, label }: Mentor) => {
      optionsToSet.push({ value, label, checked: !!userMentorsIds.includes(value) });
    });
    setOptions(optionsToSet);
  };

  useEffect(() => {
    checkUserMentorsInAllMentors();
  }, [userMentors.length]);

  if (isClinicalMentor) {
    return userMentors.length
      ? userMentors.map(({ label, value }: Mentor) => <div key={value}>{label}</div>)
      : 'No mentors';
  }
  return (
    <div>
      <FormControl sx={{ width: SELECTOR_WIDTH }} size="small">
        <Select
          multiple
          value={options as any}
          renderValue={() => renderValueHandler()}
        >
          {options.map((option) => (
            <MenuItem key={option.value} value={option.value} selected={option.checked}>
              <Checkbox checked={option.checked} onChange={(event) => checkedHandler(event, option)} />
              {option.label}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </div>
  );
};
