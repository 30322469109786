import { takeLatest, call, put, all, fork, join } from 'redux-saga/effects';
import { resendEmailConfirmation } from '@services/authenticationService';
import {
  Types,
  resendConfirmEmailSuccess,
  resendConfirmEmailFailure,
} from './actions';

function* callResendConfirmEmail(action) {
  try {
    const { email } = action;
    const res = yield fork(resendEmailConfirmation, email);
    const result = yield join(res);
    yield put(resendConfirmEmailSuccess(result));
  } catch (error) {
    yield put(resendConfirmEmailFailure(error.message));
  }
}

function* watchCallResendConfirmEmail() {
  yield takeLatest(Types.RESEND_CONFIRM_EMAIL, callResendConfirmEmail);
}

export default function* watchLoginActions() {
  yield all([fork(watchCallResendConfirmEmail)]);
}
