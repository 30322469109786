import { RESOURCES,TYPES,MEDIA_TYPES,QUESTION_TYPES } from '../../types';
export const overview = {
  name:"My poll",
  id:1,
  description: 'Pellentesque orci lectus, consectetur vel posuere posuere, rutrum eu ipsum. Aliquam eget odio sed ligula iaculis consequat at eget orci. Mauris molestie sit amet metus mattis varius. Donec sit amet ligula eget nisi Pellentesque orci lectus, consectetur vel posuere posuere, rutrum eu ipsum. Aliquam eget odio sed ligula iaculis consequat at eget orci. Mauris molestie sit amet metus mattis varius. ',
  entityCreated: {
    at: 'Thu Aug 02 2018 18:05:31 GMT+0000 (UTC)',
    by: 'User McUser',
  },
  entityUpdated: {
    at: 'Thu Aug 09 2018 08:46:00 GMT+0000 (UTC)',
    by: 'Useriko McUserico',
  },
  usedIn: [
    { id: 1, type: RESOURCES.COURSE, name: 'Some course 1' },
    { id: 2, type: RESOURCES.POLL, name: 'Some poll 2' },
  ],
  children:[ 
    {
        "id" : "59aaa36117c00000",
        "type" : TYPES.TEXT,
        "payload" : {
            "title" : "Instructions",
            "text" : "Instructions for my excellent poll",
        }
    }, 
    {
        "id" : "59aaa34945800000",
        "type" : TYPES.MEDIA,
        "payload" : {
            "title" : "Media",
            "description" : "My video for the poll",
            "contentTitle" : "נשימה חדש.mp4",
            "contentUrl" : "https://s3-eu-west-1.amazonaws.com/nvtk-video-media-stg/2018_07/VideoForLesson1.mp4",
            "contentType" : MEDIA_TYPES.VIDEO
        }
    }, 
    {
        "id" : "59aad0e2dc400000",
        "type" : TYPES.MEDIA,
        "payload" : {
            "title" : "Media",
            "description" : "My audio for the poll",
            "contentTitle" : "cute cat audio",
            "contentUrl" : "https://s3-eu-west-1.amazonaws.com/nvtk-video-media-stg/cuteCat (1).mp3",
            "contentType" : MEDIA_TYPES.AUDIO
        }
    }, 
    {
        "id" : "59aad1c74dc00000",
        "type" : TYPES.MEDIA,
        "payload" : {
            "title" : "Media",
            "description" : "My image for the poll",
            "contentTitle" : "cat image",
            "contentUrl" : "https://s3-eu-west-1.amazonaws.com/nvtk-video-media-stg/Grey Cat Looking.jpg",
            "contentType" : MEDIA_TYPES.IMAGE
        }
    }, 
    {
        "id" : "59aaa36118c00000",
        "type" : TYPES.TEXT,
        "payload" : {
            "title" : "Instructions",
            "text" : "Instructions for my excellent poll",
        }
    }, 
    {
        "id" : "59aaa27d9e000000",
        "type" : TYPES.QUESTION,
        "payload" : {
            "title" : "Question",
            "type" : QUESTION_TYPES.FREE_TEXT,
            "questionText" : "Free text question?",
            "answers" : []
        }
    }, 
    {
        "id" : "59aaa285cb400000",
        "type" : TYPES.QUESTION,
        "payload" : {
            "title" : "Question",
            "type" : QUESTION_TYPES.SINGLE_SELECT,
            "questionText" : "Single select question?",
            "answers" : [ 
                {
                    "id" : "59aaa2927d000000",
                    "value" : "1st answer"
                }, 
                {
                    "id" : "59aaa29984c00000",
                    "value" : "2nd answer"
                }, 
                {
                    "id" : "59aaa307e7c00000",
                    "value" : "3rd answer"
                }, 
                {
                    "id" : "59aaa30be7000000",
                    "value" : "4th answer"
                }
            ]
        }
    }, 
    {
        "id" : "59aaa314b4c00000",
        "type" : TYPES.QUESTION,
        "payload" : {
            "title" : "Question",
            "type" : QUESTION_TYPES.MULTI_SELECT,
            "questionText" : "Multi select question?",
            "answers" : [ 
                {
                    "id" : "59aaa2927d000000",
                    "value" : "1st answer"
                }, 
                {
                    "id" : "59aaa29984c00000",
                    "value" : "2nd answer"
                }, 
                {
                    "id" : "59aaa307e7c00000",
                    "value" : "3rd answer"
                }, 
                {
                    "id" : "59aaa30be7000000",
                    "value" : "4th answer"
                }
            ]
        }
    }, 
    {
        "id" : "59aaa318be400000",
        "type" : TYPES.QUESTION,
        "payload" : {
            "title" : "Question",
            "type" : QUESTION_TYPES.DROP_DOWN,
            "questionText" : "Drop down question?",
            "answers" : [ 
                {
                    "id" : "59aaa2927d000000",
                    "value" : "1st answer"
                }, 
                {
                    "id" : "59aaa29984c00000",
                    "value" : "2nd answer"
                }, 
                {
                    "id" : "59aaa307e7c00000",
                    "value" : "3rd answer"
                }, 
                {
                    "id" : "59aaa30be7000000",
                    "value" : "4th answer"
                }
            ]
        }
    }, 
    {
        "id" : "59aaa32225800000",
        "type" : TYPES.QUESTION,
        "payload" : {
            "title" : "Question",
            "type" : QUESTION_TYPES.DATE,
            "questionText" : "Date question?",
            "answers" : []
        }
    },
  ]
};