import {styled} from "@mui/material";

const OverviewButton = styled('button')(
  {
    borderRadius: 4,
    fontSize: 12,
    textTransform: 'uppercase',
    cursor: 'pointer',
    outline: 'none',
  },
  ({ theme, full, margin, width, height }) => ({
    margin: margin || 0,
    width: width || 'auto',
    height: height || 'auto',
    color: full ? theme.palette.novotalk.$textColorReversed : theme.palette.novotalk.$textColorDisabledLight,
    border: full ? 'none' : `1px solid ${theme.palette.novotalk.$textColorDisabledLight}`,
    backgroundColor: full ? theme.palette.novotalk.$actionButtonColor : 'transparent',
    '&:hover': {
      backgroundColor: full
        ? theme.palette.novotalk.$actionButtonColorHover
        : theme.palette.novotalk.$toggleButtonBgColorHover,
    },
    '&:active': {
      backgroundColor: full
        ? theme.palette.novotalk.$actionButtonColorActive
        : theme.palette.novotalk.$toggleButtonBgColorActive,
    },
  })
);

export default OverviewButton;
