import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { ThemeProvider, StyledEngineProvider } from '@mui/material/styles';
import 'sanitize.css/sanitize.css';

// Import root app
import App from '@containers/App';

// Load the favicon, the manifest.json file and the .htaccess file
/* eslint-disable import/no-unresolved, import/extensions */

// import '!file-loader?name=[name].[ext]!./images/favicon.ico';
// import '!file-loader?name=[name].[ext]!./images/favicon-16x16.png';
// import '!file-loader?name=[name].[ext]!./images/favicon-32x32.png';
// import '!file-loader?name=[name].[ext]!./manifest.json';
// import 'file-loader?name=[name].[ext]!./.htaccess';

/* eslint-enable import/no-unresolved, import/extensions */

import configureStore from './configureStore';

import {createBrowserHistory} from "history";
import {theme} from "@services/theme";
import i18n from "./i18n/i18n";

const initialState = {};

export const history = createBrowserHistory();

export const store = configureStore(initialState, history);

console.info(`i18n ${i18n.isInitialized ? "initialized" : "not initialized"}`);

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
        <StyledEngineProvider injectFirst>
          <ThemeProvider theme={theme}>
            <App />
          </ThemeProvider>
        </StyledEngineProvider>
    </Provider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
