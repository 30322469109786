const map = {
  'Integration of slower pace every few words': {
    'Difficulty transitioning between differerent speech rates': 'Integrating a slower pace every few words makes speech sound more natural. The Speech Monitor will indicate when to speak using the self managed speech rate and when to transition to a slower rate marked blue). Slow down your speech rate for the word marked by using gentle onset and light contact. Once you have completed the word continue using the self managed speech rate. ',
  },
  'Integration of words into normal speech rate': {
    'Difficulty integrating words into normal rate of speech': "Integration of words into normal rate of speech allows for fluent speech over a period of time without straining or tiring. Slowing down helps prevent blocks. Make sure that you use the wave pattern and don't drop down to the baseline. Use normal rate of speech and then slow down  after every few words and  produce a word using gentle onset and light contact. Having said and completed the word more slowly, return to normal speech rate. Words to be said slowly are marked blue on the Monitor.",
  },
  'Integration of words into normal speech rate2': {
    'Complex speech tasks': 'Remember to plan out what and how you want to speak by controlling the speed of your speech. Speak at a rate which is comfortable for you and then slow down every few words. Remember to use correct נreathing, the wave pattern , gentle onset and light contact. Inhale between words and not mid word or mid sentence so that the meaning of the utterance is not effected.',
  },
};
export default map;
