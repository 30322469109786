import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import CheckBoxGroup from '../../shared/components/CheckBoxGroup';
import {
  STIMULUS_SYLLABLE_STRUCTURE,
  STIMULUS_CONSONANTS_FEATURES,
} from '../../../../../../../types';


const syllableStructure = _.values(STIMULUS_SYLLABLE_STRUCTURE);
const consonantsFeatures = _.values(STIMULUS_CONSONANTS_FEATURES);

const SyllablesConfig = ({ config, updateConfig }) => (
  <React.Fragment>
    <CheckBoxGroup
      config={config}
      onChange={updateConfig}
      groupName="syllableStructure"
      groupValues={syllableStructure}
      capitalize={false}
    />
    <CheckBoxGroup
      config={config}
      onChange={updateConfig}
      groupName="consonantsFeatures"
      groupValues={consonantsFeatures}
    />
  </React.Fragment>
);

SyllablesConfig.propTypes = {
  config: PropTypes.object,
  updateConfig: PropTypes.func,
};

export default SyllablesConfig;
