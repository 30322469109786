import {styled} from "@mui/material";

export const TYPES = {
  PRIMARY: 'PRIMARY',
  SECONDARY: 'SECONDARY',
  ACTION: 'ACTION',
};

const getSchema = (theme, type) => {
  let schema;
  switch (type) {
    case TYPES.PRIMARY:
      schema = {
        regular: theme.palette.novotalk.$ctaButtonColor,
        hover: theme.palette.novotalk.$ctaButtonColorHover,
        active: theme.palette.novotalk.$ctaButtonColorActive,
      };
      break;
    default:
      schema = {
        regular: theme.palette.novotalk.$actionButtonColor,
        hover: theme.palette.novotalk.$actionButtonColorHover,
        active: theme.palette.novotalk.$actionButtonColorActive,
      };
  }
  return schema;
};

const TextButton = styled('span')(
  ({ theme, disabled, underline, margin = 0, type }) => {
    if (disabled) {
      return {
        margin,
        pointerEvents: 'none',
        textDecoration: underline ? 'underline' : 'none',
        color: theme.palette.novotalk.$actionButtonColor,
        '&:hover': {
          color: theme.palette.novotalk.$actionButtonColorHover,
        },
        '&:active': {
          color: theme.palette.novotalk.$actionButtonColorActive,
        },
      };
    }
    const schema = getSchema(theme, type);
    return {
      margin,
      cursor: 'pointer',
      outline: 'none',
      textDecoration: underline ? 'underline' : 'none',
      color: schema.regular,
      '&:hover': {
        color: schema.hover,
      },
      '&:active': {
        color: schema.active,
      },
    };
  }
);

export default TextButton;
