import { Component } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import _ from 'lodash';
import { withTranslation } from 'react-i18next';
import Container from '@components/Container';
import CTAButton from '@components/CTAButton';
import Text, { TEXT_COLOR, TEXT_SIZE } from '@components/Text';
import ProgressBar from '@components/ProgressBar';
import { getExerciseTips } from '@containers/User/helpers/monitorErrors';
import { markExerciseAsCompleted } from '@containers/User/containers/CourseRouter/actions';
import { resetExerciseData } from '@containers/User/containers/CourseRouter/containers/MonitorHandler/actions';
import { isMvpVersion } from '@services/isMvpService';
import {
  getCurrentPractice,
  setCurrentExercise,
} from '@containers/User/containers/CourseRouter/containers/Practice/actions';
import { LoaderSmallCentralized } from '@components/Loader';
import {
  Wrapper,
  HeaderContainer,
  BreakMessageContainer,
  TipsBlockContainer,
  TipsBlockHeaderText,
  TipsListContainer,
  TipContainer,
  ButtonsContainer,
  RecreationIconWrapper,
  RecreationIcon,
  getStyledIcon,
  IconWrapper,
  TipsTitle,
} from './styles';

export class ExerciseEnd extends Component {
  constructor() {
    super();
    this.eventListener = null;
    this.state = {
      updatedMasteryLevel: false,
      isLoading: true,
    };
    this.buttonActionTypes = {
      BACK_TO_OVERVIEW: 'BACK_TO_OVERVIEW',
      RESTART_EXERCISE: 'RESTART_EXERCISE',
      NEXT_EXERCISE: 'NEXT_EXERCISE',
    };
  }

  componentDidMount() {
    this.eventListener = window.addEventListener('message', (e) => {
      if (e.data === 'archiveWasStopped' && this.state.isLoading) {
        this.setState({ isLoading: false });
      }
    });
  }

  componentWillUnmount() {
    if (this.eventListener) {
      window.removeEventListener(this.eventListener);
    }
  }

  componentDidUpdate(prevProps) {
    const {
      dispatchMarkExerciseAsCompleted, currentExerciseActivity, exerciseLocation, isLastExercise, currentExercise, practice,
    } = this.props;
    const updatedMasteryLevel = _.get(
      currentExerciseActivity,
      'summary.updatedMasteryLevel',
      false,
    );
    if (prevProps.currentExerciseActivity?.summary?.updatedMasteryLevel && prevProps.currentExerciseActivity.summary.updatedMasteryLevel !== updatedMasteryLevel) {
      this.setUpdatedMasteryLevel(updatedMasteryLevel);
    }
    // This is the case where an action that performs practice update is dispatched. This condition works only with already
    // finished courses that user has decided to pass one more time
    // conditions:
    // 1 - currentExerciseActivity?.summary?.achievedMasteryLevel >= currentExercise?.masteryLevel
    // This part of condition checks if current exercise activity level is enough to make it mastered
    // 2 - currentExercise?.Activities?.[currentExercise.Activities.length - 1]?.summary?.achievedMasteryLevel < currentExercise?.masteryLevel
    // This part checks if the latest exercise activity masteryLevel is enough to pass
    if (currentExerciseActivity?.summary?.achievedMasteryLevel >= currentExercise?.masteryLevel
        && currentExercise?.Activities?.[currentExercise.Activities.length - 1]?.summary?.achievedMasteryLevel < currentExercise?.masteryLevel) {
      this.props.dispatchGetCurrentPractice(practice?.id);
    }

    // This is the case where an action that performs mutation to the exact exercise in a course is dispatched
    // conditions:
    // 1 - _.isEqual(prevProps.practice, practice) - here previous practice object is compared with a current one with deep comparison.
    // This part of condition is important because inside dispatchMarkExerciseAsCompleted() practice object is updated, what
    // causes an infinite rerender, because current component relies on practice prop.
    // Deep comparison helps to prevent infinite rerender in case previous practice does not really differ from new practice.
    // 2 - !practice.exercises[practice.exercises.length - 1].hasPassed - here is performed a check for the last exercise
    // in a course. If it is not finished, it makes the course not completely finished.
    if (_.isEqual(prevProps.practice, practice) && !practice.exercises[practice.exercises.length - 1].hasPassed && currentExerciseActivity?.summary?.achievedMasteryLevel >= currentExercise?.monitor?.errors?.masteryLevel) {
      dispatchMarkExerciseAsCompleted(exerciseLocation, isLastExercise, currentExercise, practice);
    }
  }

  setUpdatedMasteryLevel(updatedMasteryLevel) {
    this.setState({
      updatedMasteryLevel,
    });
  }

  getTipsBlock = (needsBreak, isFailure) => {
    const { t } = this.props;
    if (needsBreak && isFailure) {
      return (
        <BreakMessageContainer>
          <RecreationIconWrapper>
            <RecreationIcon />
          </RecreationIconWrapper>
          <Text size={TEXT_SIZE.T3} textColor={TEXT_COLOR.DARK_BLUE}>
            {t('Informative.Notifying.useHelp')}
          </Text>
          <Text size={TEXT_SIZE.T3} textColor={TEXT_COLOR.DARK_BLUE}>
            {t('Informative.Confirming.takeBreak')}
          </Text>
        </BreakMessageContainer>
      );
    }

    const insights = _.get(
      this.props.currentExerciseActivity,
      'summary.insights',
      [],
    );
    const tipsList = getExerciseTips(insights);

    if (tipsList.length === 0) return null;
    return (
      <TipsBlockContainer>
        <TipsBlockHeaderText
          size={TEXT_SIZE.T3}
          textColor={TEXT_COLOR.DARK_BLUE}
        >
          {isFailure ? t('Feedback.betterNextTime') : t('Informative.Statistical.motivationTip')}
          {/* {isFailure ? messages.failTipsText : messages.successTipsText} */}
        </TipsBlockHeaderText>
        <TipsListContainer>
          {tipsList.map(({
            errorId,
            iconName,
            text,
            errorName,
          }) => {
            const TipIcon = getStyledIcon(iconName);
            return (
              <TipContainer key={errorId}>
                <IconWrapper>
                  <TipIcon />
                </IconWrapper>
                <TipsTitle>
                  {errorName}
                </TipsTitle>
                <Text size={TEXT_SIZE.T4} textColor={TEXT_COLOR.DARK_BLUE}>
                  {text}
                </Text>
              </TipContainer>
            );
          })}
        </TipsListContainer>
        {isFailure && (
          <Text
            size={TEXT_SIZE.T2}
            textColor={TEXT_COLOR.DARK_BLUE}
            className="popup-failure-text"
          >
            {t('Actions.Confirmations.tryAgainTip')}
            {/* {messages.tryAgainText} */}
          </Text>
        )}
      </TipsBlockContainer>
    );
  };

  getButtons = (isFailure, needsBreak) => {
    const {
      isLastExercise,
      t,
    } = this.props;
    const { isLoading } = this.state;
    const {
      RESTART_EXERCISE,
      BACK_TO_OVERVIEW,
      NEXT_EXERCISE,
    } = this.buttonActionTypes;
    if (isLoading) {
      return (

        <LoaderSmallCentralized />

      );
    }
    if (isFailure) {
      return (
        <ButtonsContainer>
          <CTAButton
            onClick={this.handleClick(
              needsBreak ? RESTART_EXERCISE : BACK_TO_OVERVIEW,
            )}
            secondary
          >
            {needsBreak ? t('Actions.Confirmations.tryAgainTip') : t('Actions.Navigation.backToOverview')}
          </CTAButton>
          <CTAButton
            onClick={this.handleClick(
              needsBreak ? BACK_TO_OVERVIEW : RESTART_EXERCISE,
            )}
          >
            {needsBreak ? t('Actions.Confirmations.backTomorrow') : t('Actions.Confirmations.tryAgain')}
          </CTAButton>
        </ButtonsContainer>
      );
    }
    return (
      <ButtonsContainer>
        <CTAButton
          onClick={this.handleClick(NEXT_EXERCISE)}
          className="popup-button-single"
          disabled={isLoading}
        >
          <Text size={TEXT_SIZE.T3}>
            {isLastExercise ? t('Actions.Confirmations.endPractice') : t('Actions.Navigation.nextExercise')}
          </Text>
        </CTAButton>
      </ButtonsContainer>
    );
  };

  handleClick = (button) => () => {
    const {
      onClose,
      redirectToOverview,
      goToNextStep,
      updateExerciseProgressTime,
    } = this.props;
    const {
      RESTART_EXERCISE,
      BACK_TO_OVERVIEW,
      NEXT_EXERCISE,
    } = this.buttonActionTypes;

    onClose();
    switch (button) {
      case BACK_TO_OVERVIEW:
        redirectToOverview();
        break;
      case NEXT_EXERCISE:
        goToNextStep();
        updateExerciseProgressTime();
        break;
      case RESTART_EXERCISE:
        this.props.dispatchSetCurrentExercise({});
        this.resetExerciseData();
        break;
      default:
    }
  };

  resetExerciseData = () => {
    const { dispatchResetExerciseData, currentExercise, updateExerciseProgressTime } = this.props;
    const repetition = _.get(currentExercise, 'monitor.progress.repetition');
    const repetitionsInSequence = repetition && repetition.on ? repetition.count : 1;
    dispatchResetExerciseData(repetitionsInSequence);
    updateExerciseProgressTime();
  };

  render() {
    const {
      currentExerciseActivity, currentExercise, isExerciseWithVideo, t,
    } = this.props;
    const { monitor, Activities, hasPassed } = currentExercise;
    const masteryLevel = _.get(monitor, 'errors.masteryLevel', 0);
    const achievedMasteryLevel = _.get(
      currentExerciseActivity,
      'summary.achievedMasteryLevel',
      0,
    );
    const isFailure = achievedMasteryLevel < masteryLevel;
    const needsBreak = !isMvpVersion && !hasPassed && Activities.length > 2;
    if (isExerciseWithVideo) {
      return (
        <Wrapper>
          <HeaderContainer>
            <Text
              size={TEXT_SIZE.T1}
              textColor={TEXT_COLOR.DARK_BLUE}
              weight="bold"
              className="popup-title"
            >
              {t('Informative.Positive.success')}
            </Text>
          </HeaderContainer>
          {this.getButtons(false, needsBreak)}
        </Wrapper>
      );
    }
    return (
      <Wrapper>

        <HeaderContainer>
          <Text
            size={TEXT_SIZE.T1}
            textColor={TEXT_COLOR.DARK_BLUE}
            weight="bold"
            className="popup-title"
          >
            {isFailure ? t('Informative.Negative.masteryTip') : t('Informative.Positive.success')}
          </Text>
          <Container className="popup-progress" alignItems="baseline">
            <Text
              size={TEXT_SIZE.H4}
              textColor={TEXT_COLOR.DARK_BLUE}
              margin="0 4px 0 0"
              className="popup-progress-text"
            >
              {t('Informative.Demanding.requiredMasteryLevel')}
            </Text>
            <ProgressBar
              width={300}
              height={10}
              progress={achievedMasteryLevel}
              mastery={masteryLevel}
            />
            <Text
              size={TEXT_SIZE.H4}
              textColor={
                  achievedMasteryLevel >= masteryLevel
                    ? TEXT_COLOR.ACTIVE
                    : TEXT_COLOR.DISABLED_LIGHT
                }
              margin="0 5px"
              className="popup-progress-text"
            >
              {`${achievedMasteryLevel}%`}

            </Text>
            <Text
              size={TEXT_SIZE.H4}
              textColor={TEXT_COLOR.DARK_BLUE}
              className="popup-progress-text"
            >
              {`/ ${masteryLevel}%`}

            </Text>
          </Container>
        </HeaderContainer>

        {this.getTipsBlock(needsBreak, isFailure)}
        {this.getButtons(isFailure, needsBreak)}

      </Wrapper>
    );
  }
}

ExerciseEnd.propTypes = {
  onClose: PropTypes.func.isRequired,
  currentExercise: PropTypes.object,
  redirectToOverview: PropTypes.func,
  currentExerciseActivity: PropTypes.object,
  goToNextStep: PropTypes.func,
  isLastExercise: PropTypes.bool,
  dispatchMarkExerciseAsCompleted: PropTypes.func,
  dispatchResetExerciseData: PropTypes.func,
  updateExerciseProgressTime: PropTypes.func,
  exerciseLocation: PropTypes.object,
  dispatchGetCurrentPractice: PropTypes.func,
  dispatchSetCurrentExercise: PropTypes.func,
};

export const mapStateToProps = (state) => {
  const currentPractice = state.getIn(['currentPractice']).toJS();
  const {
    currentExerciseActivity,
    practice,
    currentExercise,
  } = currentPractice;
  return { currentExerciseActivity, practice, currentExercise };
};

export function mapDispatchToProps(dispatch) {
  return {
    dispatchMarkExerciseAsCompleted: (location, isLastExercise, currentExercise, practice) => dispatch(markExerciseAsCompleted(location, isLastExercise, currentExercise, practice)),
    dispatchResetExerciseData: (repetitionsInSequence) => dispatch(resetExerciseData(repetitionsInSequence)),
    dispatchGetCurrentPractice: (id) => dispatch(getCurrentPractice(id)),
    dispatchSetCurrentExercise: (exercise) => dispatch(setCurrentExercise(exercise)),
  };
}

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default compose(withConnect, withTranslation())(ExerciseEnd);
