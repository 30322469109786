import {
  put, takeLatest, all, fork, join, select,
} from 'redux-saga/effects';
import { COURSE } from '@shared/Resources/types';
import { setOverview } from '@containers/Admin/shared/ResourcesWrapper/actions';
import {
  putUserPageLoadingStatus,
  showUserPageError,
  USER_PAGE_ACTIONS,
  putUserPage,
  showToast,
  fetchUserPage,
  putUserPageMentors,
  putUserActivityLog,
  putUserActivityDetail,
} from './actions';
import {
  sendUserCourse,
  sendUserMentors,
  sendUserStatus,
  moveUserToCourseLevel,
  getAllMentors,
  getUserActivityLog,
  getUserInformation,
  getUserActivityDetail,
  getUserPersonalProgram,
  getUserActivitiesDetailByExternalId,
} from './services';
import { TOAST_TYPES } from './consts';
import { formatMentorsToOptions, formatActivities } from './helpers';

const IS_MOCK_DATA = true; // TODO Remove it when will be implement backend

export function* callFetchOneUser(action) {
  try {
    const id = action.payload;
    yield put(putUserPageLoadingStatus(true));

    const res = yield fork(getUserInformation, id);
    const mentorsData = yield fork(getAllMentors);

    const { userData, errors } = yield join(res);
    const { allMentors, errors: mentorsErrors } = yield join(mentorsData);

    if (errors || mentorsErrors) {
      yield put(showUserPageError());
    } else {
      yield put(putUserPageMentors(formatMentorsToOptions(allMentors)));
      yield put(putUserPage(userData));
    }
  } catch {
    yield put(showUserPageError());
  } finally {
    yield put(putUserPageLoadingStatus(false));
  }
}

export function* callAttachUserPageCourse({ payload: { courseId, userId } }) {
  try {
    const { userData } = yield select((state) => state.get('userPage').toJS());
    const res = yield fork(sendUserCourse, courseId, userId);
    const { errors } = yield join(res);

    if (errors) {
      yield put(showToast(TOAST_TYPES.SHOW_ERROR_ATTACHED));
    } else {
      yield put(fetchUserPage(userData.id));
      yield put(showToast(TOAST_TYPES.SHOW_SUCCESS_ATTACHED));
    }
  } catch {
    yield put(showToast(TOAST_TYPES.SHOW_ERROR_ATTACHED));
  }
}

export function* callAttachUserPageMentors({ payload: { mentorId, userId, isRemove } }) {
  try {
    const { userData } = yield select((state) => state.get('userPage').toJS());

    const res = yield fork(sendUserMentors, userId, mentorId, isRemove);
    const { errors } = yield join(res);

    if (errors) {
      yield put(showToast(TOAST_TYPES.SHOW_ERROR_ATTACHED));
    } else {
      yield put(fetchUserPage(userData.id));
      yield put(showToast(TOAST_TYPES.SHOW_SUCCESS_ATTACHED));
    }
  } catch {
    yield put(showToast(TOAST_TYPES.SHOW_ERROR_ATTACHED));
  }
}

export function* callMoveUserToCourseLevel({ payload }) {
  try {
    if (IS_MOCK_DATA) { // TODO Remove it when will be implement backend
      yield put(showToast(TOAST_TYPES.SHOW_SUCCESS_CHANGED_LEVEL));
      yield put(fetchUserPage(payload.userId));
    } else {
      const res = yield fork(moveUserToCourseLevel, payload);
      const { errors } = yield join(res);

      if (errors) {
        yield put(showToast(TOAST_TYPES.SHOW_ERROR_ATTACHED));
      } else {
        yield put(showToast(TOAST_TYPES.SHOW_SUCCESS_CHANGED_LEVEL));
        yield put(fetchUserPage(payload.userId));
      }
    }
  } catch {
    yield put(showToast(TOAST_TYPES.SHOW_ERROR_ATTACHED));
  }
}

export function* callChangeUserStatus({ payload: { userId, status } }) {
  try {
    const { userData } = yield select((state) => state.get('userPage').toJS());
    const res = yield fork(sendUserStatus, userId, status);
    const { errors } = yield join(res);

    if (errors) {
      yield put(showToast(TOAST_TYPES.SHOW_ERROR_ATTACHED));
    } else {
      yield put(fetchUserPage(userData.id));
      yield put(showToast(TOAST_TYPES.SHOW_SUCCESS_ATTACHED));
    }
  } catch {
    yield put(showToast(TOAST_TYPES.SHOW_ERROR_ATTACHED));
  }
}

export function* callFetchUserActivityLog(action) {
  try {
    const id = action.payload;

    yield put(putUserPageLoadingStatus(true));

    const res = yield fork(getUserActivityLog, id);
    const { activityLogs } = yield join(res);

    yield put(putUserActivityLog(formatActivities(activityLogs)));
  } catch {
    yield put(showUserPageError());
  } finally {
    yield put(putUserPageLoadingStatus(false));
  }
}

export function* callFetchUserActivityDetail({ payload: { userId, activityId, activityType } }) {
  try {
    const res = yield fork(getUserActivityDetail, userId, activityId, activityType);
    const { userActivitiesDetail, errors } = yield join(res);
    if (errors) {
      yield put(showUserPageError());
    } else {
      yield put(putUserActivityDetail(userActivitiesDetail));
    }
  } catch {
    yield put(showUserPageError());
  }
}

export function* callFetchUserActivityDetailByExternalId({ payload: { userId, externalId, activityType } }) {
  try {
    const res = yield fork(getUserActivitiesDetailByExternalId, userId, externalId, activityType);
    const { userActivitiesDetailByExternalId, errors } = yield join(res);
    if (errors) {
      yield put(showUserPageError());
    } else {
      yield put(putUserActivityDetail(userActivitiesDetailByExternalId));
    }
  } catch {
    yield put(showUserPageError());
  }
}

export function* callFetchUserPersonalProgram({ payload }) {
  try {
    yield put(putUserPageLoadingStatus(true));

    const res = yield fork(getUserPersonalProgram, payload);
    const { entityCoursesForUser, errors } = yield join(res);

    if (errors) {
      yield put(showUserPageError());
    } else {
      yield put(setOverview(COURSE, entityCoursesForUser.length > 0 && entityCoursesForUser[0], null));
    }
  } catch {
    yield put(showUserPageError());
  } finally {
    yield put(putUserPageLoadingStatus(false));
  }
}

function* watchCallFetchUser() {
  yield takeLatest(USER_PAGE_ACTIONS.FETCH_ONE_USER, callFetchOneUser);
}

function* watchCallAttachUserPageCourse() {
  yield takeLatest(USER_PAGE_ACTIONS.ATTACH_USER_PAGE_COURSE, callAttachUserPageCourse);
}

function* watchCallAttachUserPageMentors() {
  yield takeLatest(USER_PAGE_ACTIONS.ATTACH_USER_PAGE_MENTOR, callAttachUserPageMentors);
}

function* watchCallMoveUserToCourseLevel() {
  yield takeLatest(USER_PAGE_ACTIONS.MOVE_USER_TO_COURSE_LEVEL, callMoveUserToCourseLevel);
}

function* watchCallChangeUserStatus() {
  yield takeLatest(USER_PAGE_ACTIONS.CHANGE_USER_STATUS, callChangeUserStatus);
}

function* watchCallFetchUserActivityLog() {
  yield takeLatest(USER_PAGE_ACTIONS.FETCH_USER_ACTIVITY_LOG, callFetchUserActivityLog);
}

function* watchCallFetchUserActivityDetailByExternalId() {
  yield takeLatest(USER_PAGE_ACTIONS.FETCH_USER_ACTIVITY_DETAIL_BY_EXTERNAL_ID, callFetchUserActivityDetailByExternalId);
}

function* watchCallFetchUserActivityDetail() {
  yield takeLatest(USER_PAGE_ACTIONS.FETCH_USER_ACTIVITY_DETAIL, callFetchUserActivityDetail);
}

function* watchCallFetchUserPersonalProgram() {
  yield takeLatest(USER_PAGE_ACTIONS.FETCH_USER_PERSONAL_PROGRAM, callFetchUserPersonalProgram);
}

export default function* userPageSaga() {
  yield all([
    fork(watchCallFetchUser),
    fork(watchCallFetchUserActivityLog),
    fork(watchCallFetchUserActivityDetail),
    fork(watchCallFetchUserPersonalProgram),
    fork(watchCallAttachUserPageCourse),
    fork(watchCallAttachUserPageMentors),
    fork(watchCallMoveUserToCourseLevel),
    fork(watchCallChangeUserStatus),
    fork(watchCallFetchUserActivityDetailByExternalId),
  ]);
}
