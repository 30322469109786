import React, { Component } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import Text, { TEXT_SIZE, TEXT_COLOR } from '@components/Text';
import {withTheme} from "@mui/styles";
import Select from 'react-select';
import {
  Wrapper,
  selectStyles,
  StyledDropdownIcon,
  AnswerWrapper,
  RadioOffIcon,
} from './styles';
import { StyledQuestionIcon } from '../../styles';
import PollQuestionTitle from '../PollQuestionTitle';
import { QUESTION_TYPES } from '../../../../types';
import MiniOverviewHeader from '../../shared/MiniOverviewHeader';
import {t} from 'i18next'

export class DropdownQuestion extends Component {
  constructor(props) {
    super(props);

    this.state = {
      selectedAnswer: '',
      options: [],
    };
  }

  componentWillMount() {
    const { userAnswer, isDisabled, answers } = this.props;
    const options = answers.map((answer) => ({
      label: answer.value,
      value: answer.id,
    }));
    let selectedAnswer;
    if (isDisabled && userAnswer) {
      selectedAnswer = _.find(options, { value: userAnswer });
    }
    this.setState({ options, selectedAnswer });
  }

  handleChange = (answer) => {
    const { updateAnswer } = this.props;
    const selectedAnswer = answer ? answer.value : null;
    updateAnswer(selectedAnswer)
    this.setState({ selectedAnswer: answer });
  };

  renderOptions = () => {
    const { questionIndex, answers } = this.props;
    return (
      <div id={`dropdown_preview_${questionIndex}`}>
        {answers.map((answer) => (
          <AnswerWrapper key={answer.id}>
            <RadioOffIcon />
            <Text textColor={TEXT_COLOR.DARK_BLUE} size={TEXT_SIZE.H4}>
              {answer.value}
            </Text>
          </AnswerWrapper>
        ))}
      </div>
    );
  };

  render() {
    const {
      questionIndex,
      questionText,
      theme,
      checkAnswers,
      id,
      isDisabled,
      miniOverview,
    } = this.props;

    const { selectedAnswer, options } = this.state;
    const error = checkAnswers && !selectedAnswer && !isDisabled;
    const titleProps = {
      questionIndex,
      questionText,
      error,
    };
    const placeholder = (
      <Text size='H4' textColor='disabled'>
        {t('Actions.Multiple.selectAnswer')}
      </Text>
    );
    const styles = selectStyles(error, theme);

    return miniOverview ? (
      <MiniOverviewHeader
        type={QUESTION_TYPES.DROP_DOWN}
        questionText={questionText}
      >
        {this.renderOptions()}
      </MiniOverviewHeader>
    ) : (
      <Wrapper>
        <StyledQuestionIcon />
        <PollQuestionTitle {...titleProps} />
        <Select
          isDisabled={isDisabled}
          id={`dropdownQuestion_${id}`}
          isSearchable={false}
          value={selectedAnswer}
          onChange={this.handleChange}
          options={options}
          placeholder={placeholder}
          styles={styles}
          isClearable
          menuPlacement='auto'
          components={{ DropdownIndicator: () => <StyledDropdownIcon /> }}
        />
      </Wrapper>
    );
  }
}

DropdownQuestion.propTypes = {
  isDisabled: PropTypes.bool,
  questionIndex: PropTypes.number,
  questionText: PropTypes.string,
  answers: PropTypes.array,
  theme: PropTypes.object,
  updateAnswer: PropTypes.func,
  checkAnswers: PropTypes.bool,
  userAnswer: PropTypes.string,
  id: PropTypes.string,
  miniOverview: PropTypes.bool,
};

export default withTheme(DropdownQuestion);
