import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import _ from 'lodash';
import {withTheme} from "@mui/styles";
import Text, { TEXT_COLOR, TEXT_SIZE } from '@components/Text';
import ErrorMessage from '@components/ErrorMessage';
import Select from 'react-select';
import isValid from 'date-fns/is_valid';
import getDaysInMonth from 'date-fns/get_days_in_month';
import getDate from 'date-fns/get_date';
import getMonth from 'date-fns/get_month';
import getYear from 'date-fns/get_year';
import {
  Wrapper,
  selectStyles,
  SelectsWrapper,
  Label,
  StyledDropdownIcon,
} from './styles';
import {withTranslation} from "react-i18next";

class DropdownDatePicker extends Component {
  constructor(props) {
    super(props);

    this.state = {
      selectedDay: null,
      selectedMonth: null,
      selectedYear: null,
      days: this.generateDays(),
      months: this.generateMonths(),
      years: this.generateYears(),
    };
  }

  componentWillMount() {
    const { userAnswer, isDisabled } = this.props;
    const { days, months, years } = this.state;
    const selectedDate = new Date(userAnswer);
    if (isDisabled && userAnswer && isValid(selectedDate)) {
      const day = getDate(selectedDate);
      const month = getMonth(selectedDate);
      const year = getYear(selectedDate);
      const selectedDay = _.find(days, { value: day });
      const selectedMonth = _.find(months, { value: month + 1 });
      const selectedYear = _.find(years, { value: year });
      this.setState({ selectedDay, selectedMonth, selectedYear });
    }
  }

  componentDidUpdate(prevProps, prevState) {
    const { selectedDay, selectedMonth, selectedYear } = this.state;
    const {
      selectedDay: prevDay,
      selectedMonth: prevMonth,
      selectedYear: prevYear,
    } = prevState;
    if (
      !_.isEqual(selectedDay, prevDay) ||
      !_.isEqual(selectedMonth, prevMonth) ||
      !_.isEqual(selectedYear, prevYear)
    ) {
      this.updateAnswer();
    }
  }

  updateAnswer() {
    const { selectedDay, selectedMonth, selectedYear } = this.state;
    const selectedDate =
      selectedDay && selectedMonth && selectedYear
        ? new Date(
            selectedYear.value,
            selectedMonth.value - 1,
            selectedDay.value
          )
        : null;
    this.props.handleChange(selectedDate);
  }

  handleDayChange = (day) => {
    this.setState({ selectedDay: day });
  };

  handleMonthChange = (month) => {
    const { selectedYear } = this.state;
    let { selectedDay } = this.state;
    const days = month
      ? this.generateDays(_.get(month, 'value'), _.get(selectedYear, 'value'))
      : this.generateDays();
    if (_.get(selectedDay, 'value') > days.length) selectedDay = _.last(days);
    this.setState({ selectedMonth: month, days, selectedDay });
  };

  handleYearChange = (year) => {
    const { selectedMonth } = this.state;
    let { selectedDay } = this.state;
    const days = year
      ? this.generateDays(_.get(selectedMonth, 'value'), _.get(year, 'value'))
      : this.generateDays();
    if (_.get(selectedDay, 'value') > days.length) selectedDay = _.last(days);
    this.setState({ selectedYear: year, days, selectedDay });
  };

  generateDays(month = null, year = null) {
    const days = [];
    let day = 1;
    const lastDay =
      month && year ? getDaysInMonth(new Date(year, month - 1)) : 31;
    while (day <= lastDay) {
      days.push({ label: day, value: day });
      day += 1;
    }
    return days;
  }

  generateMonths() {
    const {t} = this.props

    const months = [
      { label: t('Dates.january'), value: 1 },
      { label: t('Dates.february'), value: 2 },
      { label: t('Dates.march'), value: 3 },
      { label: t('Dates.april'), value: 4 },
      { label: t('Dates.may'), value: 5 },
      { label: t('Dates.june'), value: 6 },
      { label: t('Dates.july'), value: 7 },
      { label: t('Dates.august'), value: 8 },
      { label: t('Dates.september'), value: 9 },
      { label: t('Dates.october'), value: 10 },
      { label: t('Dates.november'), value: 11 },
      { label: t('Dates.december'), value: 12 },
    ];
    return months;
  }

  generateYears() {
    const years = [];
    const currentYear = new Date().getFullYear();
    let startYear = 1950;
    const endYear = this.props.noFuture ? currentYear : currentYear + 30;
    while (startYear < endYear) {
      years.push({ label: startYear, value: startYear });
      startYear += 1;
    }
    return years;
  }

  render() {
    const {
      selectedDay,
      selectedMonth,
      selectedYear,
      days,
      months,
      years,
    } = this.state;
    const {
      error,
      theme,
      isDisabled,
      label,
      errorMessage,
      className,
      t
    } = this.props;

    const dayPlaceholder = (
      <Text size='H4' textColor='disabled'>
        {t('Dates.day')}
      </Text>
    );
    const monthPlaceholder = (
      <Text size='H4' textColor='disabled'>
        {t('Dates.month')}
      </Text>
    );
    const yearPlaceholder = (
      <Text size='H4' textColor='disabled'>
        {t('Dates.year')}
      </Text>
    );
    const styles = selectStyles(error, theme);
    const commonSelectProps = {
      isSearchable: false,
      styles,
      menuPlacement: 'auto',
      isClearable: false,
      isDisabled,
    };
    return (
      <Wrapper className={className}>
        {label && (
          <Label>
            <Text
              textColor={TEXT_COLOR.DARK_BLUE}
              size={TEXT_SIZE.H4}
              weight='bold'
            >
              {label}
            </Text>
          </Label>
        )}
        <SelectsWrapper>
          <Select
            id='monthSelect'
            value={selectedMonth}
            onChange={this.handleMonthChange}
            options={months}
            placeholder={monthPlaceholder}
            components={{ DropdownIndicator: () => <StyledDropdownIcon /> }}
            {...commonSelectProps}
          />
          <Select
            id='daySelect'
            value={selectedDay}
            onChange={this.handleDayChange}
            options={days}
            placeholder={dayPlaceholder}
            components={{ DropdownIndicator: () => <StyledDropdownIcon /> }}
            {...commonSelectProps}
          />
          <Select
            id='yearSelect'
            value={selectedYear}
            onChange={this.handleYearChange}
            options={years}
            placeholder={yearPlaceholder}
            components={{ DropdownIndicator: () => <StyledDropdownIcon /> }}
            {...commonSelectProps}
          />
        </SelectsWrapper>
        {errorMessage && (
          <ErrorMessage type={'CUSTOM'} customText={errorMessage} />
        )}
      </Wrapper>
    );
  }
}

DropdownDatePicker.propTypes = {
  isDisabled: PropTypes.bool,
  handleChange: PropTypes.func,
  theme: PropTypes.object,
  error: PropTypes.bool,
  userAnswer: PropTypes.string,
  errorMessage: PropTypes.string,
  label: PropTypes.node,
  className: PropTypes.string,
  noFuture: PropTypes.bool,
};
export default compose(withTranslation(), withTheme)(DropdownDatePicker);
