import React from 'react';
import PropTypes from 'prop-types';
import Text, { TEXT_COLOR, TEXT_SIZE } from '@components/Text';
import {
  Wrapper,
  StyledSuccessIcon,
  StyledErrorIcon,
  HeaderContainer,
  StyledButton,
} from './styles';
import {useTranslation} from "react-i18next";

const ChangePasswordConfirmation = ({ onClose, isChangeSuccessful, textError }) => {
    const {t} = useTranslation()
  const headerText = t(`UI.${isChangeSuccessful ? 'passwordChanged' : 'passwordNotChanged'}`)
  const Icon = isChangeSuccessful ? StyledSuccessIcon : StyledErrorIcon;
  return (
    <Wrapper>
      <Icon />
      <HeaderContainer>
        <Text textColor={TEXT_COLOR.DARK_BLUE} size={TEXT_SIZE.T0}>
          {headerText}
        </Text>
        <br />
        <Text textColor={TEXT_COLOR.LIGHT_GRAY} size={TEXT_SIZE.T2}>
          {textError}
        </Text>
      </HeaderContainer>
      <StyledButton id='change-password-confirmation-button' onClick={onClose}>
        {t('Actions.Navigation.backToSettings')}
      </StyledButton>
    </Wrapper>
  );
};

ChangePasswordConfirmation.propTypes = {
  onClose: PropTypes.func.isRequired,
  isChangeSuccessful: PropTypes.bool,
  textError: PropTypes.string,
};

export default ChangePasswordConfirmation;
