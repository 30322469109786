import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import _ from 'lodash';
import Text, { TEXT_COLOR, TEXT_SIZE } from '@components/Text';
import CTAButton from '@components/CTAButton';
import TextButton from '@components/TextButton';
import injectReducer from '@utils/injectReducer';
import injectSaga from '@utils/injectSaga';
import { ROUTES } from '@shared/constants';
import CustomTextInput from '@components/CustomTextInput/index';
import { formHasErrors, getErrorMessageId, errorTypes } from '../validate';
import { withTranslation } from 'react-i18next';

import {
  SignInComponentContainer,
  SignInInputAreaContainer,
  SignInHeaderContainer,
  InputContainer,
  SignInButtonContainer,
  UnderButtontTextContainer,
  ScreenContainer,
  linkStyles,
  LinkContainer,
  StyledLogo,
  ServerErrorMessage,
} from './styles';
import LogoImage from '../logoTop.svg';

import { onSignIn } from './actions';
import reducer from './reducer';
import saga from './saga';
import ClientVersion from '@components/ClientVersion';
import ServerVersion from '@components/ServerVersion';
import { ENVIRONMENT } from '@services/servers';

class SignIn extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      visibility: {
        password: false,
      },
      form: {
        values: {
          email: '',
          password: '',
        },
        errors: {
          email: null,
          password: null,
        },
      },
    };
    this.throttledLogin = _.throttle(props.onSignInClick, 1000);
  }

  onFieldUpdate = (event) => {
    const { name, value } = event.target;

    this.setState((prevState) => {
      const newForm = prevState.form;
      newForm.values[name] = value;

      return {
        form: newForm,
      };
    });
  };

  onFocus = (event) => {
    const name = event.target.name;
    this.setState((prevState) => {
      const newForm = prevState.form;
      newForm.errors[name] = null;

      return {
        form: newForm,
      };
    });
  };

  onLoginButtonClick = () => {
    const { values } = this.state.form;

    const formErrors = this.getFormErrors();
    const newForm = _.cloneDeep(this.state.form);

    const loading = !formHasErrors(formErrors);
    newForm.errors = formErrors;

    this.setState({
      form: newForm,
      loading,
    });

    if (loading) {
      this.throttledLogin(values.email, values.password);
    }
  };

  getFieldError = (messageId) => {
    if (
      !messageId ||
      messageId === errorTypes.simplePassword ||
      messageId === errorTypes.weakPassword
    ) {
      return null;
    }
    return this.props.t(`Errors.${messageId}`)
  };

  getFormErrors = () => {
    const { values } = this.state.form;
    const newErrors = {};

    _.forEach(values, (fieldValue, fieldName) => {
      const messageId = getErrorMessageId(fieldName, fieldValue, null, {
        userNameAlsoValid: true,
      });
      newErrors[fieldName] = this.getFieldError(messageId);
    });

    return newErrors;
  };

  renderHeader = () => (
    <SignInHeaderContainer>
      <StyledLogo src={LogoImage} alt="logo" />
      <Text textColor={TEXT_COLOR.DARK_BLUE} size={TEXT_SIZE.H1}>
        {this.props.t('Actions.Auth.signIn')}
      </Text>
    </SignInHeaderContainer>
  );

  renderInputs = () => {
    const { email, password } = this.state.form.values;

    return (
      <div>
        <InputContainer>
          <CustomTextInput
            onEnter={this.onLoginButtonClick}
            id="signin_email"
            label={this.props.t('Authentication.Email.username')}
            name="email"
            value={email}
            error={this.state.form.errors.email}
            onChange={this.onFieldUpdate}
            onFocus={this.onFocus}
          />
        </InputContainer>
        <InputContainer>
          <CustomTextInput
            onEnter={this.onLoginButtonClick}
            type="password"
            id="signin_password"
            label={this.props.t('Authentication.Password.password')}
            name="password"
            value={password}
            error={this.state.form.errors.password}
            onChange={this.onFieldUpdate}
            onFocus={this.onFocus}
          />
          <LinkContainer>
            <Link
              id="signin_forgot_password"
              style={linkStyles}
              to={ROUTES.FORGOT_PASSWORD}
            >
              <TextButton>
                <Text size={TEXT_SIZE.H4}>
                  {this.props.t('Authentication.Password.forgot')}
                </Text>
              </TextButton>
            </Link>
          </LinkContainer>
        </InputContainer>
      </div>
    );
  };

  renderButton = () => {
    const serverError = this.props.serverError ? (
      <ServerErrorMessage type="CUSTOM" customText={this.props.serverError} />
    ) : null;

    return (
      <SignInButtonContainer>
        <CTAButton
          id="signin_button"
          onClick={this.onLoginButtonClick}
          expanded
        >
          {this.props.t('Actions.Auth.signIn')}
        </CTAButton>
        {serverError}
      </SignInButtonContainer>
    );
  };

  renderUnderButtonContainer = () => (
    <UnderButtontTextContainer>
      <Text textColor={TEXT_COLOR.DARK_BLUE} size={TEXT_SIZE.H4}>
        {this.props.t('Authentication.Distribution.newToTheApp')}
      </Text>
      <Link
        id="newtonovotalk_signup_button"
        style={linkStyles}
        to={ROUTES.SIGN_UP}
      >
        <TextButton>
          <Text size={TEXT_SIZE.H4}>
            {this.props.t('Authentication.Distribution.freeTrial')}
          </Text>
        </TextButton>
      </Link>
      {ENVIRONMENT !== 'prod' && (
        <>
          <ClientVersion />
          <ServerVersion />
        </>
      )}
    </UnderButtontTextContainer>
  );

  render() {
    return (
      <ScreenContainer>
        <SignInComponentContainer className="signIn">
          {this.renderHeader()}
          <SignInInputAreaContainer>
            {this.renderInputs()}

            {this.renderButton()}

            {this.renderUnderButtonContainer()}
          </SignInInputAreaContainer>
        </SignInComponentContainer>
      </ScreenContainer>
    );
  }
}

SignIn.defaultProps = {
  onSignInClick: () => {},
};

SignIn.propTypes = {
  onSignInClick: PropTypes.func.isRequired,
  serverError: PropTypes.string,
};

export const mapStateToProps = (state) => {
  const serverError = state.getIn(['signIn', 'errors', 'serverError']);
  const sending = state.getIn(['signIn', 'sending']);
  return { serverError, sending };
};

export const mapDispatchToProps = (dispatch) => ({
  onSignInClick: (email, password) => {
    dispatch(onSignIn(email, password));
  },
});

const withConnect = connect(mapStateToProps, mapDispatchToProps);
const withSaga = injectSaga({ key: 'signIn', saga });
const withReducer = injectReducer({ key: 'signIn', reducer });

export default compose(
  withConnect,
  withReducer,
  withSaga,
    withTranslation()
)(SignIn);
