import React from 'react';
import { MEDIA_TYPES } from '@shared/Resources/types';
import { Audio, Image, Video } from './styles';

export const MediaPreview = ({ type, file }) => {
  const renderMediaPreview = () => {
    switch (type) {
      case MEDIA_TYPES.VIDEO:
        return <Video controls type='video/mp4' src={file} />
      case MEDIA_TYPES.AUDIO:
        return <Audio controls src={file} />
      case MEDIA_TYPES.IMAGE:
        return <Image src={file} />;
      default:
        return null;
    }
  };
  return renderMediaPreview();
};
