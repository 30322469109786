import React from 'react';
import PropTypes from 'prop-types';
import { StaticBarContainer } from './styles';

const StaticBar = ({ children }) => (<StaticBarContainer>{children}</StaticBarContainer>);

StaticBar.propTypes = {
  children: PropTypes.node,
};

export default StaticBar;
