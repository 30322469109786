import {styled} from "@mui/material";

export const Section = styled('div')(
  {
    paddingBottom: 15,
    marginBottom: 15,
    '&:last-of-type': {
      marginBottom: 0,
    },
  },
  ({ theme }) => ({
    borderBottom: `1px solid ${theme.palette.novotalk.$resourcesTableBorderColor}`,
  })
);

export const SplitContainer = styled('div')({
  width: '100%',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
});
