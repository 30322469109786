import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import _ from 'lodash';
import Text, { TEXT_COLOR } from '@components/Text';
import Container from '@components/Container';
import CTAButton from '@components/CTAButton';
import { Wrapper, Title } from './styles';
import {sliceText} from "@utils/textHelpers";
import {withTranslation} from "react-i18next";

const maxTitleLength = 70
const maxSubtitleLength = 50

class Header extends Component {
  renderCTA() {
    if (!this.props.locationInCourse) return <Container />;
    const { skillName, skillpartName, paused } = this.props.locationInCourse;
    const buttonText = paused
      ? this.props.t('Actions.Instance.resume')
      : this.props.t('Actions.Instance.start')
    if (skillName && skillpartName) {
      return (
        <Container>
          <Container margin='0 20px' column alignItems='flex-end'>
            <Text
              textColor={TEXT_COLOR.HIGHLIGHTED}
              size='T3'
              textTransform='uppercase'
              weight={this.props.i18n.language === 'he' ? 'bold' : 'normal'}
              textAlign='right'
            >
              {sliceText(skillName, maxTitleLength, true)}
            </Text>
            <Text
              textColor={TEXT_COLOR.DISABLED}
              size='T3'
              textTransform='capitalize'
            >
              {sliceText(skillpartName, maxSubtitleLength, true)}
            </Text>
          </Container>
          <CTAButton onClick={this.props.resumeCourse}>
            <Text
              textColor={TEXT_COLOR.REVERSED}
              size='T2'
              weight='bold'
              textTransform='capitalize'
            >
              {buttonText}
            </Text>
          </CTAButton>
        </Container>
      );
    }
    return <Container />;
  }

  render() {
    const { courseName } = this.props;

    return (
      <Wrapper>
        <Container>
          <Title>{sliceText(courseName, maxSubtitleLength, true)}</Title>
        </Container>
        {this.renderCTA()}
      </Wrapper>
    );
  }
}

Header.propTypes = {
  locationInCourse: PropTypes.object,
  resumeCourse: PropTypes.func,
  courseName: PropTypes.string,
};


export default compose(
    withTranslation()
)(Header);
