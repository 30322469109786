import {styled} from "@mui/material";
import { getIconsByType, iconTypes } from '@services/icons';
import { getFlexibleSize, getResponsiveCSS } from '@utils/cssHelpers';
import CTAButton from '@components/CTAButton';

export const getStyledIcon = (icon) =>
  styled(getIconsByType(iconTypes[icon]))(
    {
      display: 'block',
      padding: 10,
      borderRadius: '50%',
      border: '1px solid',
    },
    ({ theme }) => ({
      color: theme.palette.novotalk.$textColorHighlited,
      borderColor: theme.palette.novotalk.$textColorHighlited,
    })
  );

export const IconWrapper = styled('div')(
  {
    margin: '0 auto 15px',
  },
  getResponsiveCSS('width', 54, 62),
  getResponsiveCSS('marginTop', 14, 38)
);

export const RecreationIcon = getIconsByType(iconTypes.RECREATION);

export const RecreationIconWrapper = styled('div')({
  width: 130,
  margin: '0 auto 10px',
  '@media(min-width: 1280px)': {
    width: getFlexibleSize(130, 154),
  },
  '@media(min-width: 1920px)': {
    width: 154,
  },
});

export const Wrapper = styled('div')(
  {
    width: 820,
    minHeight: 210,
    padding: '38px 30px 34px 30px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    borderRadius: 4,
    '@media(min-width: 1280px)': {
      width: getFlexibleSize(820, 900),
      minHeight: getFlexibleSize(210, 240),
      paddingBottom: getFlexibleSize(34, 32),
    },
    '@media(min-width: 1920px)': {
      width: 900,
      minHeight: 240,
      paddingBottom: 32,
    },
  },
  ({ theme }) => ({
    backgroundColor: theme.palette.novotalk.$backgroundColor,
    color: theme.palette.novotalk.$textColorDark,
  })
);

export const HeaderContainer = styled('div')(
  {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    '& span.popup-title': {
      marginBottom: 18,
    },
  },
  ({ theme }) => ({
    borderBottom: `1px solid ${theme.palette.novotalk.$borderColorLight}`,
  })
);

export const TipsBlockContainer = styled('div')(
  {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    paddingTop: 20,
    '@media(min-width: 1280px)': {
      paddingTop: getFlexibleSize(30, 38),
    },
    '@media(min-width: 1920px)': {
      paddingTop: 38,
    },
  },
  ({ theme, isLastLesson }) => ({
    borderBottom: isLastLesson
      ? `1px solid ${theme.palette.novotalk.$borderColorLight}`
      : 'none',
  })
);

export const TipsListContainer = styled('div')({
  display: 'flex',
  justifyContent: 'center',
  marginBottom: 34,
  '@media(min-width: 1280px)': {
    marginBottom: getFlexibleSize(34, 50),
  },
  '@media(min-width: 1920px)': {
    marginBottom: 50,
  },
});

export const TipContainer = styled('div')({
  flex: '1 0px',
  maxWidth: 264,
  padding: '0 20px',
  textAlign: 'center',
  lineHeight: '1.2em',
  display: 'flex',
  flexDirection: 'column',
  '@media(min-width: 1280px)': {
    maxWidth: getFlexibleSize(264, 302),
  },
  '@media(min-width: 1920px)': {
    maxWidth: 302,
  },
});

export const RecommendationText = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  marginBottom: 30,
  marginTop: 25,
  lineHeight: '26px',
});

export const ButtonsContainer = styled('div')({
  display: 'flex',
  justifyContent: 'center',
});

export const StyledCTAButton = styled(CTAButton)(
  {
    width: 180,
    margin: '0 7px',
  },
  getResponsiveCSS('height', 44, 48)
);

export const LoaderWrapper = styled('div')({
  alignSelf: 'stretch',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
});
