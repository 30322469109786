import {styled} from "@mui/material";
import { getIconsByType, iconTypes } from '@services/icons';

export const Wrapper = styled('div')({
  display: 'flex',
  alignItems: 'center',
});

const iconStyles = ({ theme }) => ({
  fontSize: 21,
  marginLeft: -3,
  color: theme.palette.novotalk.$textColorDarkBlue,
});

export const StyledPlayIcon = styled(getIconsByType(iconTypes.PLAY))(
  iconStyles
);
export const StyledPauseIcon = styled(getIconsByType(iconTypes.PAUSE))(
  iconStyles
);

export const StyledTrack = styled('input')(
  {
    margin: '0 10px',
    width: 96,
    position: 'relative',
    WebkitAppearance: 'none',
    outline: 'none',
    flexShrink: 0,
  },
  ({ theme, position }) => ({
    '&:before': {
      display: 'block',
      content: '""',
      width: `${position}%`,
      height: 2,
      position: 'absolute',
      left: 0,
      borderRadius: 2,
      backgroundColor: theme.palette.novotalk.$activeColor,
    },
    '&::-webkit-slider-runnable-track': {
      height: 2,
      borderRadius: 2,
      backgroundColor: theme.palette.novotalk.$backgroundColorDark,
    },
    '&::-webkit-slider-thumb': {
      height: 10,
      minWidth: 4,
      maxWidth: 4,
      marginTop: -4,
      borderRadius: 2,
      backgroundColor: theme.palette.novotalk.$activeColor,
      WebkitAppearance: 'none',
    },
  })
);
