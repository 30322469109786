const grid = 6;
export const getItemStyle = (isDragging, draggableStyle) => {
  return {
    userSelect: 'none',
    padding: grid * 2,
    margin: `0 0 ${grid}px 0`,
    textAlign: 'right',
    ...draggableStyle,
  };
};
