import OverviewSectionPartial from './components/OverviewSectionPartial';
import Text, { TEXT_COLOR } from '@components/Text';
import { OverflowSkillsList } from './styles';

const OverviewSection = (props) => (
  <>
    {props.isUserPageProgram &&
      <Text
        size="H2"
        textColor={TEXT_COLOR.DARK_BLUE}
        weight={700}
      >
        {props.courseName}
      </Text>
    }
    <OverflowSkillsList>
      <OverviewSectionPartial {...props} />
    </OverflowSkillsList>
  </>
);

export default OverviewSection;
