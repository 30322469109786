import {styled} from "@mui/material";

export const Wrapper = styled('div')(
  {
    display: 'flex',
    justifyContent: 'space-between',
    padding: '0 50px',
    justifyItems: 'center',
    gridArea: 'Header',
  },
  ({ theme }) => ({
    direction: theme.RTL ? 'rtl' : 'inherit',
  })
);

export const Title = styled('div')(
  {
    fontSize: 21,
    fontWeight: 'bold',
  },
  ({ theme }) => ({
    color: theme.palette.novotalk.$textColorDarkBlue,
  })
);
