import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { sendTextMessage } from '../../actions';
import TextChat from '../TextChat';
import { useVideoCallContext } from '@shared/hooks/useVideoCallContext';

export const TextChatWrapper = () => {
  const { sessionInfo, textMessages, connectedToChat } = useSelector((state) =>
    state.getIn(['videoChat']).toJS()
  );
  const { stateContext } = useVideoCallContext();
  const dispatch = useDispatch();
  return (
    <TextChat
      textMessages={textMessages}
      sessionInfo={sessionInfo}
      sendTextMessage={(message) => dispatch(sendTextMessage(message))}
      enabled={connectedToChat && !stateContext.chatEnded}
    />
  );
};
