import React, { Component } from 'react';
import PropTypes from 'prop-types';
import InputRange from 'react-input-range';
import ToggleButton from '@components/ToggleButton';
import _ from 'lodash';
import './styles.css';
import { Filter, FilterButtons, FilterTitle } from '../../styles';
import { Wrapper } from './styles';
import { withTranslation } from 'react-i18next';
import { compose } from 'redux';

class FeedbackFilter extends Component {
  constructor(props) {
    super(props);
    const { maxValue, minValue } = props;
    this.feedbackFilterOptions = ['All', 'Yes', 'No'];

    this.state = {
      filterMin: minValue,
      filterMax: maxValue,
    };
  }

  componentWillReceiveProps(nextProps) {
    const {
      maxValue,
      minValue,
      feedbackFilter,
      minWithFeedback,
      maxWithFeedback,
      filterMin,
      filterMax,
    } = nextProps;
    if (
      this.props.feedbackFilter !== feedbackFilter ||
      this.props.minValue !== minValue ||
      this.props.maxValue !== maxValue
    ) {
      const sliderMin = feedbackFilter === 'Yes' ? minWithFeedback : minValue;
      const sliderMax = feedbackFilter === 'Yes' ? maxWithFeedback : maxValue;
      this.setState({ filterMin: sliderMin, filterMax: sliderMax });
    }
    if (
      filterMin !== this.state.filterMin ||
      filterMax !== this.state.filterMax
    ) {
      this.setState({ filterMin, filterMax });
    }
  }

  changeRange(changedValue) {
    const sliderValue = {
      filterMin: changedValue.min,
      filterMax: changedValue.max,
    };
    this.setState({ ...sliderValue });
    const updateStateWithRange = () => {
      this.props.setFeedbackSlider(sliderValue);
    };
    const debounced = _.debounce(updateStateWithRange, 250, { maxWait: 1000 });
    debounced();
  }

  renderButton(feedback) {
    const selected = this.props.feedbackFilter === feedback;
    return (
      <ToggleButton
        key={feedback}
        isSelected={selected}
        onClick={() => this.props.setFeedbackFilter(feedback)}
      >
        {feedback}
      </ToggleButton>
    );
  }

  render() {
    const {
      minValue,
      maxValue,
      feedbackFilter,
      minWithFeedback,
      maxWithFeedback,
        t
    } = this.props;
    const sliderMin = feedbackFilter === 'Yes' ? minWithFeedback : minValue;
    const sliderMax = feedbackFilter === 'Yes' ? maxWithFeedback : maxValue;

    return (
      <Wrapper>
        <Filter>
          <FilterTitle>
            {t('Common.UI.feedback')}
          </FilterTitle>
          <FilterButtons>
            {this.feedbackFilterOptions.map((feedback) =>
              this.renderButton(feedback)
            )}
          </FilterButtons>
        </Filter>
        <Filter>
          <FilterTitle>
            {t('Dates.sliderTitle')}
          </FilterTitle>
          <InputRange
            formatLabel={(value) => {
              if (value >= 72) {
                return t('Dates.daysAmount', {days: parseInt(value / 24, 10)})
              }
              return t('Dates.hoursAmount', {hours: value})
            }}
            maxValue={sliderMax}
            minValue={sliderMin}
            value={{ min: this.state.filterMin, max: this.state.filterMax }}
            onChange={(changedValue) => this.changeRange(changedValue)}
            disabled={feedbackFilter === 'No'}
          />
        </Filter>
      </Wrapper>
    );
  }
}

FeedbackFilter.propTypes = {
  maxValue: PropTypes.number,
  minValue: PropTypes.number,
  minWithFeedback: PropTypes.number,
  maxWithFeedback: PropTypes.number,
  filterMin: PropTypes.number,
  filterMax: PropTypes.number,
  feedbackFilter: PropTypes.string,
  setFeedbackFilter: PropTypes.func,
  setFeedbackSlider: PropTypes.func,
};

export default compose(
    withTranslation()
)(FeedbackFilter)