export const TYPES = {
  GET_COURSES: 'GET_COURSES',
  SET_COURSES: 'SET_COURSES',
  SET_ACTIVITY_CONTEXT: 'SET_ACTIVITY_CONTEXT',
  MARK_EXERCISE_AS_COMPLETED: 'MARK_EXERCISE_AS_COMPLETED',
  MARK_LESSON_AS_COMPLETED: 'MARK_LESSON_AS_COMPLETED',
  MARK_FEEDBACK_AS_SEEN: 'MARK_FEEDBACK_AS_SEEN',
  MARK_FEEDBACK_AS_SEEN_SUCCEED: 'MARK_FEEDBACK_AS_SEEN_SUCCEED',
  MARK_FEEDBACK_AS_SEEN_FAILED: 'MARK_FEEDBACK_AS_SEEN_FAILED',
  UPDATE_ACTIVITY_LOG: 'UPDATE_ACTIVITY_LOG',
  UPDATE_ACTIVITY_LOG_OPENED: 'UPDATE_ACTIVITY_LOG_OPENED',
};

export function getCourses() {
  return {
    type: TYPES.GET_COURSES,
  };
}

export function setCourses(courses) {
  return {
    type: TYPES.SET_COURSES,
    courses,
  };
}

export function setActivityContext(activityContext) {
  return {
    type: TYPES.SET_ACTIVITY_CONTEXT,
    activityContext,
  };
}

export function markExerciseAsCompleted(location, isLast, exercise, practice) {
  return {
    type: TYPES.MARK_EXERCISE_AS_COMPLETED,
    location,
    isLast,
    exercise,
    practice,
  };
}
export function markLessonAsCompleted(lesson) {
  return {
    type: TYPES.MARK_LESSON_AS_COMPLETED,
    lesson,
  };
}
export function markFeedbackAsSeen(postType, ids) {
  return {
    type: TYPES.MARK_FEEDBACK_AS_SEEN,
    postType,
    ids,
  };
}
export function markFeedbackAsSeenSucceed() {
  return {
    type: TYPES.MARK_FEEDBACK_AS_SEEN_SUCCEED,
  };
}

export function markFeedbackAsSeenFailed() {
  return {
    type: TYPES.MARK_FEEDBACK_AS_SEEN_FAILED,
  };
}
export function updateActivityLog(id, typeEntity, status, skillName) {
  return {
    type: TYPES.UPDATE_ACTIVITY_LOG,
    id,
    typeEntity,
    status,
    skillName,
  };
}

export function updateActiveLogOpened(id) {
  return {
    type: TYPES.UPDATE_ACTIVITY_LOG_OPENED,
    id,
  };
}
