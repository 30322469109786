import React from 'react';
import PropTypes from 'prop-types';
import Text, { TEXT_COLOR, TEXT_SIZE } from '@components/Text';
import ErrorMessage from '@components/ErrorMessage';
import {
  StyledLabel,
  InputContainer,
  IconContainer,
  StyledInput,
} from './styles';

class CustomTextInput extends React.Component {
  handleKeyPress = (event) => {
    if (event.key === 'Enter' && this.props.onEnter) {
      this.props.onEnter();
    }
  };

  render() {
    const {
      className,
      type,
      error,
      label,
      value,
      name,
      id,
      placeholder,
      Icon,
      iconPlacement,
      onChange,
      onFocus,
      onBlur,
      autofocus,
    } = this.props;

    return (
      <span>
        {label && (
          <StyledLabel htmlFor={id}>
            <Text
              textColor={TEXT_COLOR.DARK_BLUE}
              size={TEXT_SIZE.H4}
              weight='bold'
            >
              {label}
            </Text>
          </StyledLabel>
        )}
        <InputContainer>
          {Icon && (
            <IconContainer placement={iconPlacement}>
              <Icon />
            </IconContainer>
          )}
          <StyledInput
            onKeyPress={this.handleKeyPress}
            id={id}
            className={className}
            type={type}
            name={name}
            error={error !== null}
            value={value}
            placeholder={placeholder}
            onChange={onChange}
            onFocus={onFocus}
            onBlur={onBlur}
            autoFocus={autofocus}
            iconPlacement={iconPlacement}
          />
        </InputContainer>
        {error && <ErrorMessage type={'CUSTOM'} customText={error} />}
      </span>
    );
  }
}

CustomTextInput.defaultProps = {
  className: '',
  type: 'text',
  error: null,
  id: null,
  placeholder: '',
};

CustomTextInput.propTypes = {
  className: PropTypes.string,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  value: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  onEnter: PropTypes.func,
  name: PropTypes.string.isRequired,
  type: PropTypes.string,
  error: PropTypes.string,
  id: PropTypes.string,
  onFocus: PropTypes.func,
  onBlur: PropTypes.func,
  autofocus: PropTypes.bool,
  Icon: PropTypes.oneOfType([PropTypes.func, PropTypes.object]),
  iconPlacement: PropTypes.string,
};

export default CustomTextInput;
export { PLACEMENT } from './styles';
