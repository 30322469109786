import {styled} from "@mui/material";
import { getIconsByType, iconTypes } from '@services/icons';

const fieldWidth = '200px';
const smallFieldWidth = '85px';
export const timeRed = '#D0021B';
export const timeOrange = '#C37F0E';
export const IndicatorIcon = getIconsByType(iconTypes.RADIO_ON);

export const Wrapper = styled('div')({
  backgroundColor: '#F2F2F2',
  alignSelf: 'stretch',
  display: 'flex',
  padding: '6px',
  alignItems: 'center',
  justifyContent: 'space-around',
  height: '50px',
  margin: '3px 0',
  cursor: 'pointer',
});

export const ActivityLevel = styled('div')({
  width: fieldWidth,
});

export const User = styled('div')({
  width: fieldWidth,
});

export const Stage = styled('div')({
  width: smallFieldWidth,
});

export const Type = styled('div')({
  width: fieldWidth,
});

export const LastExercise = styled('div')(
  {
    width: fieldWidth,
    position: 'relative',
  },
  (props) => ({
    color: props.color,
  })
);

export const FeedbackTime = styled('div')(
  {
    width: fieldWidth,
    position: 'relative',
  },
  (props) => ({
    display: props.show ? '' : 'none',
    color: props.color,
  })
);

export const FeedbackIndicator = styled('div')(
  {
    minWidth: smallFieldWidth,
    textAlign: 'center',
  },
  (props) => ({
    display: props.show ? '' : 'none',
    color: props.hasFeedback ? '#519507' : '#860313',
  })
);

export const Tooltip = styled('span')({
  fontSize: '14px',
  padding: '2px 5px',
  position: 'absolute',
  zIndex: '100',
  backgroundColor: 'transparent',
  color: 'transparent',
  left: '10px',
  borderRadius: '3px',
  '&:hover': {
    border: '1px solid #F2F2F2',
    backgroundColor: '#EEFBFF',
    color: '#000',
    boxShadow: '5px 5px 5px #d2d2d2',
  },
});
