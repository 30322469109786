import React, { Component } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import SectionLoading from '@components/SectionLoading';
import Activity from './components/Activity';
import {
  Wrapper,
  Header,
  Cell,
  StyledSortUpIcon,
  StyledSortDownIcon,
  IconWrapper,
} from './styles';
import {withTranslation} from "react-i18next";
import {compose} from "redux";

class Activities extends Component {
  constructor(props) {
    super(props);
    const { overview, activities } = props;
    this.overviewSortKeys = [
      'activityLevel',
      'user',
      'stage',
      'type',
      'lastExercise',
    ];
    this.activitiesSortKeys = [
      'feedback',
      'activityLevel',
      'user',
      'stage',
      'type',
      'lastExercise',
      'feedbackTime',
    ];
    const sortKey = overview ? 'lastExercise' : 'feedbackTime';

    const sortedActivities = this.getSortedActivities(
      activities,
      sortKey,
      'desc'
    );
    this.state = {
      activities: sortedActivities,
      overview,
      sortKeys: overview ? this.overviewSortKeys : this.activitiesSortKeys,
      key: '',
      sortDirection: 'desc',
    };
  }

  componentWillReceiveProps(nextProps) {
    const { overview, activities } = nextProps;
    const sortKey = overview ? 'lastExercise' : 'feedbackTime';
    const sortDirection = overview ? 'asc' : 'desc';
    const sortedActivities = this.getSortedActivities(
      activities,
      sortKey,
      sortDirection
    );
    const newState = {
      key: sortKey,
      sortDirection,
      activities: sortedActivities,
    };
    const changedView = this.props.overview !== overview;
    const changedData = !_.isEqual(this.props.activities, activities);
    if (changedData || changedView) {
      if (changedView) {
        newState.overview = overview;
        newState.sortKeys = overview
          ? this.overviewSortKeys
          : this.activitiesSortKeys;
      }
      this.setState(newState);
    }
  }

  getSortedActivities(activities, newSortKey, sortDirection) {
    let sortKey = newSortKey;
    let sortedActivities;
    if (newSortKey === 'feedbackTime') {
      sortKey = 'feedbackGapFull';
      sortedActivities = _.sortBy(activities, 'lastExercise');
    }
    sortedActivities = _.sortBy(activities, sortKey);
    if (sortDirection === 'desc')
      sortedActivities = _.reverse(sortedActivities);

    return sortedActivities;
  }

  handleSortClick(newSortKey) {
    const { activities, sortDirection, key } = this.state;
    const newSortDirection =
      key === newSortKey && sortDirection === 'asc' ? 'desc' : 'asc';
    const sortedActivities = this.getSortedActivities(
      activities,
      newSortKey,
      newSortDirection
    );
    this.setState({
      activities: sortedActivities,
      key: newSortKey,
      sortDirection: newSortDirection,
    });
  }

  renderSortIcon(iconKey) {
    const { key, sortDirection } = this.state;

    const reveresedIcon = iconKey === 'lastExercise';
    if (key === iconKey) {
      let icon = null;
      if (sortDirection === 'asc') {
        icon = reveresedIcon ? <StyledSortDownIcon /> : <StyledSortUpIcon />;
      } else {
        icon = reveresedIcon ? <StyledSortUpIcon /> : <StyledSortDownIcon />;
      }
      return <IconWrapper>{icon}</IconWrapper>;
    }
    return null;
  }

  renderSortHeader() {
    return (
      <Header>
        {this.state.sortKeys.map((key) => (
          <Cell
            key={key}
            className='headerKey'
            small={key === 'feedback' || key === 'stage'}
            onClick={() => this.handleSortClick(key)}
          >
            {this.props.t(`Common.UI.${key}`)}
            {this.renderSortIcon(key)}
          </Cell>
        ))}
      </Header>
    );
  }

  render() {
    if (this.props.loading) return <SectionLoading />;
    const { activities, overview } = this.state;
    return (
      <Wrapper gridArea={this.props.gridArea}>
        {this.renderSortHeader()}
        {activities.map((activity) => (
          <Activity {...activity} key={activity.id} displayFull={!overview} />
        ))}
      </Wrapper>
    );
  }
}

Activities.propTypes = {
  gridArea: PropTypes.string,
  activities: PropTypes.arrayOf(PropTypes.object),
  overview: PropTypes.bool,
  loading: PropTypes.bool,
};

export default compose(
    withTranslation()
)(Activities)
