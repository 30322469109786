import React from 'react';
import { TYPES } from './types';

import Question from './Question/Client';
import MediaClient from './Media/Client';
import { MonitorConfig } from './Monitor/Client';

class ResourcesFactoryClass {
  createComponent(type, payload, onChange, parentType) {
    switch (type) {
      case TYPES.QUESTION:return <Question onChange={onChange} {...payload} parentType={parentType} />;
      case TYPES.MEDIA:return <MediaClient onChange={onChange} payload={payload} />;
      case TYPES.MONITOR:return <MonitorConfig onChange={onChange} payload={payload} />;
      default: return null;
    }
  }
}

const ResourcesFactory = new ResourcesFactoryClass();

export default ResourcesFactory;
