const map = {
  'Diaphragmatic breathing': {
    'Correct breathing': 'Well done! you are carrying out the diaphragmatic breathing correctly',
    'Upper chest breathing': "Make sure that when you breathe the upper chest area remains stable, only the stomach area should  move inwards and outwards. It helps to practise lying down and putting one hand on your chest and the ther on your stomach. The hand on your chest shouldn't move at all, only the hand on your stomach moves.",
    'Upper chest breathing2': " It helps to practise lying down and putting one hand on your chest and the other on your stomach. The hand on your chest shouldn't move at all, only the hand on your stomach moves.",
    'Upward movements of the shoulders': "Make sure that when you breathe your shoulders don't move. Only the stomach area moves inwards and outwards.",
    'Head raising': 'Make sure that when you breathe your head stays still. Only the stomach area moves inwards and outwards',
  },
  'Increasing breath capacity': {
    'Correct length of exhalation ': 'Well done, the length of exhalation  is correct',
    'Too long exhalation': 'Note that the exhalation is too long and may result in unnecessary effort',
    'Too short exhalation': "Note that the exhalation is too short and won't result in an increased breath capacity",
    'Correct length of inhalation ': 'Well done, the length of inhalation  is correct',
    'Too long inhalation': 'Note that the inhalation is too long, try practising breathing in for a shorter amount of time',
    'Too short inhalation': 'Note that the inhalation is too short, you are probably not breathing in enough air. Breathe in deeply from the diaphragm to ensure that you \nhave inhaled enough air.',
    'steady voice': 'No necessity to give feedback',
    'Unsteady voice': 'Try keeping up the same vocal intensity throughout the sound production. Make sure that you inhale sufficient air  before producing\n any sounds',
    'Correct breathing': 'Well done! you are carrying out the diaphragmatic breathing correctly',
    'chest breathing': "Make sure that when you breathe the upper chest area remains stable, only the stomach area should  move inwards and outwards. It  helps to practise lying down and putting one hand on your chest and the other on your stomach. The hand on your chest shouldn't move at all, only the hand on your stomach moves.\n",
    'shoulders moving': "Make sure that when you breathe your shoulders don't move. Only the stomach area moves inwards and outwards.",
    'Raising your head': 'Make sure that when you breathe your head stays still. Only the stomach area moves inwards and outwards',
    'Relaxed posture': ' You are maintaining a correct and relaxed body posture',
    'Tense posture': 'Make sure that you relax your neck muscles. Be careful not to jerk your head backwards.',
    'Match the shaded pattern': 'Try to produce a sound similar to the goal of the shaded pattern',
    'Too short': 'Make sure that you produce the sound for the defined amount of time',
    Sound: 'Make sure that you produce the ssssss sound, and not a differernt sound, as this is the optimum sound for receiving correct feedback as to your breathing capacity and sound production ',
    'no inhalation': 'Make sure that you inhale between each sound that is produced',
    'Relaxed posture2': 'Well done! You are maintaining a correct and relaxed body posture',
  },
  Waves: {
    'Continuous voice production ': 'Make sure that voice production is continuous in a smooth, wave like movement between gentle then loud and then gentle voice',
    'Short inhalation': 'Make sure that you breathe in from the diaphragm and take in the correct amount of air. Keep within the markers on the Monitor',
  },
};
export default map;
