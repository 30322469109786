export const types = {
  VALIDATE_BRIDGE_TOKEN: 'VALIDATE_BRIDGE_TOKEN',
  VALIDATE_BRIDGE_TOKEN_FINISHED: 'VALIDATE_BRIDGE_TOKEN_FINISHED',
  VALIDATE_BRIDGE_TOKEN_FAILED: 'VALIDATE_BRIDGE_TOKEN_FAILED',
  SET_THEME: 'SET_THEME',
  LOG_OUT: 'LOG_OUT',
  CLEAR_TOKEN: 'CLEAR_TOKEN',
  REFRESH_TOKEN: 'REFRESH_TOKEN',
  SET_REFRESH_TOKEN: 'SET_REFRESH_TOKEN',
  SET_USER_ROLES: 'SET_USER_ROLES',
  GET_USER_ROLES: 'GET_USER_ROLES',
  SET_SERVER_PROPERTIES: 'SET_SERVER_PROPERTIES',
  GET_SERVER_PROPERTIES: 'GET_SERVER_PROPERTIES',
  SET_APP_ERROR:'SET_APP_ERROR',
  CHANGE_LOCALE: 'CHANGE_LOCALE',
};

export function validateBridgeToken(bridgeToken) {
  return {
    type: types.VALIDATE_BRIDGE_TOKEN,
    bridgeToken,
  };
}

export function onBridgeTokenValidationFinished(finalToken) {
  return {
    type: types.VALIDATE_BRIDGE_TOKEN_FINISHED,
    finalToken,
  };
}

export function onBridgeTokenValidationFail(error) {
  return {
    type: types.VALIDATE_BRIDGE_TOKEN_FAILED,
    error,
  };
}

export function setTheme(theme) {
  return {
    type: types.SET_THEME,
    theme,
  };
}

export function logOut(data) {
  return {
    type: types.LOG_OUT,
    data,
  };
}

export function clearToken() {
  return {
    type: types.CLEAR_TOKEN,
  };
}
export function refreshToken() {
  return {
    type: types.REFRESH_TOKEN,
  };
}
export function setRefreshToken(tokens) {
  return {
    type: types.SET_REFRESH_TOKEN,
    tokens,
  };
}
export function setUserRoles(roles) {
  return {
    type: types.SET_USER_ROLES,
    userRoles: roles,
  };
}

export function getUserRoles() {
  return {
    type: types.GET_USER_ROLES,
  };
}
export function setServerProperties(properties) {
  return {
    type: types.SET_SERVER_PROPERTIES,
    properties,
  };
}

export function getServerProperties() {
  return {
    type: types.GET_SERVER_PROPERTIES,
  };
}
export function setAppError(error){
  return {
    type: types.SET_APP_ERROR,
    error
  }
}

export const changeLocale = (locale) => ({
  type: types.CHANGE_LOCALE,
  locale,
});