import { breathing } from '@shared/VideoChat/monitorTypes/breathing'
import { waves } from '@shared/VideoChat/monitorTypes/waves'
import { gentleVoice } from '@shared/VideoChat/monitorTypes/gentleVoice'
import { loudVoice } from '@shared/VideoChat/monitorTypes/loudVoice'
import { voiceTransition } from '@shared/VideoChat/monitorTypes/voiceTransition'
import { twoSeconds } from '@shared/VideoChat/monitorTypes/twoSeconds'
import { oneSecond } from '@shared/VideoChat/monitorTypes/oneSecond'
import { selfManaged } from '@shared/VideoChat/monitorTypes/selfManaged'

export const monitorTypes = {
    breathing,
    waves,
    gentleVoice,
    loudVoice,
    voiceTransition,
    twoSeconds,
    oneSecond,
    selfManaged,
};
