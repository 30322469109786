import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';

import Text, { TEXT_SIZE, TEXT_COLOR } from '@components/Text';
import CheckBox from '@components/CheckBox';
import { DIFFICULTY } from '../../../../../types';
import { DifficultySelection, LabelContainer, SelectionContainer } from './styles';

class ErrorDifficultySelector extends React.Component {
  constructor(props) {
    super(props);
    this.state = { ...props.value };
  }

  setDifficulty = (difficulty) => () => {
    this.setState({ difficulty }, () => {
      this.props.onChange(this.state);
    });
  }

  setOn = (on) => {
    this.setState({ on }, () => {
      this.props.onChange(this.state);
    });
  }

  setValueField = (field) => (fieldValue) => {
    const { value } = this.state;
    value[field] = fieldValue;
    this.setState({ value });
    this.props.onChange(value);
  }

  renderDifficulties = (currentDifficulty) => (
    <SelectionContainer>
      {_.map(DIFFICULTY, (difficulty) => (
        <DifficultySelection
          onClick={this.setDifficulty(difficulty)}
          key={difficulty}
          className="difficulty"
          isSelected={difficulty === currentDifficulty}
        >
          <Text size={TEXT_SIZE.T4} textColor={TEXT_COLOR[difficulty === currentDifficulty ? 'REVERSED' : 'ACTIVE']}>{_.capitalize(difficulty)}</Text>
        </DifficultySelection>
      ))}
    </SelectionContainer>
  );

  render() {
    const { label, isDisabled, noDifficulty } = this.props;
    const { on, difficulty } = this.state;
    return (
      <LabelContainer className="container" isDisabled={isDisabled}>
        <CheckBox
          className="toggle"
          value={on}
          onChange={this.setOn}
          label={<Text size={TEXT_SIZE.H4} className="errorLabel" textColor={TEXT_COLOR.DARK_BLUE}>{label}</Text>}
        />
        {on && !noDifficulty && this.renderDifficulties(difficulty)}
      </LabelContainer>);
  }
}


ErrorDifficultySelector.propTypes = {
  label: PropTypes.string,
  onChange: PropTypes.func,
  isDisabled: PropTypes.bool,
  noDifficulty: PropTypes.bool,
  value: PropTypes.shape({
    on: PropTypes.bool,
    difficulty: PropTypes.string,
  }),
};

export default ErrorDifficultySelector;
