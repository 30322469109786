import _ from 'lodash';
import { fromJS } from 'immutable';
import { TYPES } from './actions';

export const initialState = fromJS({
  conversations: [],
  currentConversation: {},
  users: [],
  isNewConversationStarted: false,
  fetchingFullConversation: false,
  fetchingConversations: true,
  conversationPartner: {},
});

function messagingReducer(state = initialState, action) {
  switch (action.type) {
    case TYPES.SET_CONVERSATIONS:
      return state.set('conversations', action.conversations)
        .set('fetchingConversations', false);
    case TYPES.GET_FULL_CONVERSATION:
      return state.set('fetchingFullConversation', true);
    case TYPES.SET_FULL_CONVERSATION:
      return state.set('currentConversation', action.currentConversation)
        .set('isNewConversationStarted', false)
        .set('fetchingFullConversation', false);
    case TYPES.SET_USERS:
      return state.set('users', action.users.filter((user) => !user.self));
    case TYPES.START_NEW_CONVERSATION: {
      const userId = _.get(action, 'payload.user.id', null)
      const existingConversation = state
        .get('conversations')
        .find(({ Users }) => Users.some(({ id }) => id === userId));
      return state
        .set('isNewConversationStarted', !existingConversation)
        .set('conversationPartner', _.get(action, 'payload.user', {}))
        .set('currentConversation', existingConversation);
      }
    case TYPES.SET_CONVERSATION_PARTNER:
      return state.set('conversationPartner', action.user);
    default:
      return state;
  }
}

export default messagingReducer;
