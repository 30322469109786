import {styled} from "@mui/material";
import CTAButton from '@components/CTAButton';
import Text from '@components/Text';
import { isDesktop as isDesktopCheck } from '@shared/constants/media-queries';
import {BIG_SCREEN_BREAKPOINT} from "@containers/App/consts";

const isDesktop = isDesktopCheck();

export const StimuliImage = styled('img')({
    alt: "no image",
    height: 170
})

export const StimuliImageButtonWrapper = styled("div")({
    marginTop: 15,
    marginBottom: 20,
})

export const StimuliButton = styled(CTAButton)({
    margin: '0 auto',
});

export const PictureWrapper = styled('div')({
    marginTop: 15,
    })

export const StimuliText = styled(Text)(

    ({ theme, isCurrent, isUndivided }) => ({
        display: 'flex',
        alignItems: 'flex-end',
        color: isUndivided ? theme.palette.novotalk.$novotalkBlue : isCurrent ? theme.palette.novotalk.$novotalkBlue : theme.palette.novotalk.$textColorDisabled,
        fontWeight: !isUndivided && isCurrent ? 800 : 400,
        fontSize: 25,
        lineHeight: '30px',
        [theme.breakpoints.down(BIG_SCREEN_BREAKPOINT)]: {
            fontSize: 19,
            lineHeight: '23px'
        },
    })
);

export const PictureStimuliWrapper = styled('div')(({theme}) =>({
        display: 'flex',
        justifyContent: 'flex-end',
        flexDirection: 'column',
        alignItems: 'center',
        position: 'relative',
        height: 330,
        [theme.breakpoints.down(BIG_SCREEN_BREAKPOINT)]: {
            height: 310,
        }
    }
))