import React from 'react';

const icon = (props) => (
  <svg {...props} version="1.1" width="26px" height="25px" viewBox="0 0 26 25" xmlns="http://www.w3.org/2000/svg">
    <defs>
      <path id="path-1" d="m20.429 18.296v0.0028228l3.7472 3.7472 0.36041 0.36068-1.7234 1.7234-2.984-2.984c-0.23804 0.16898-0.53024 0.26916-0.84521 0.26916h-17.538c-0.79509 0-1.4451-0.63839-1.4451-1.4161v-14.12c0-0.77768 0.65-1.4103 1.4451-1.4103h1.7063l-2.1707-2.1707 1.7236-1.7236 3.8942 3.8942h0.013232l13.817 13.827zm5.5714 2.4072-16.029-16.234h9.0122c0.79509 0 1.4451 0.63259 1.4451 1.4103v4.9446l5.5714-5.6469v15.526z" />
    </defs>
    <g id="Project-Styleguide" fill="none" fillRule="evenodd">
      <g id="Styleguide" transform="translate(-957 -2730)">
        <g id="Icons/Videocam-Off" transform="translate(957 2730)" fillRule="evenodd">
          <mask id="mask-2" fill="none">
            <use href="#path-1" />
          </mask>
          <use id="Combined-Shape" fill="currentColor" href="#path-1" />
          <g id="Colors/Grey-Blue" fill="currentColor" mask="url(#mask-2)">
            <rect id="Rectangle" width="26" height="26" />
          </g>
        </g>
      </g>
    </g>
  </svg>
);

export default icon;

