import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'redux';
import injectReducer from '@utils/injectReducer';
import injectSaga from '@utils/injectSaga';
import { withRouter } from 'react-router-dom';
import { ROUTES } from '@shared/constants';
import Text, { TEXT_COLOR, TEXT_SIZE } from '@components/Text';
import TextButton from '@components/TextButton';
import Container from '@components/Container';
import { logOut } from '../../../actions';

import {
  ScreenContainer,
  WithHeaderComponentContainer,
  HeaderContainer,
  MainContainer,
  StyledLink,
  LinkContainer,
} from './styles';
import saga from './saga';
import reducer from './reducer';
import { confirmEmail } from './actions';
import { withTranslation } from 'react-i18next';

class ConfirmEmail extends Component {
  componentDidMount() {
    const { token } = this.props.match.params;
    if (token) {
      this.props.confirmEmail(token);
    }
  }

  render() {
    const { email, error, t } = this.props;
    const header = error ? null : t('Authentication.Email.confirmationVerified');
    const message = error ? (
      <Container column id='errorWrapper'>
        <Text textColor={TEXT_COLOR.DARK_BLUE} size={TEXT_SIZE.H4}>
          {t('Authentication.Email.confirmationFailed')}
        </Text>
      </Container>
    ) : (
      <Container column>
        <Text
          textColor={TEXT_COLOR.DARK_BLUE}
          size={TEXT_SIZE.H4}
          weight='bold'
          id='emailWrapper'
        >
          {t('Authentication.Email.verifyingText', {email: email})}
        </Text>
        <Text
          textColor={TEXT_COLOR.DARK_BLUE}
          size={TEXT_SIZE.H4}
          margin='20px 0 0 0'
        >
          {t('Authentication.Email.signInInstruction')}
        </Text>
      </Container>
    );

    return (
      <ScreenContainer>
        <WithHeaderComponentContainer>
          <HeaderContainer>
            <Text textColor={TEXT_COLOR.DARK_BLUE} size={TEXT_SIZE.H1}>
              {header}
            </Text>
          </HeaderContainer>
          <MainContainer>{message}</MainContainer>
          <LinkContainer>
            <Text textColor={TEXT_COLOR.DARK_BLUE} size={TEXT_SIZE.H4}>
              {t('Actions.Navigation.backTo')}

            </Text>
            <StyledLink id='sign_in_link' to={ROUTES.SIGN_IN}>
              <TextButton>
                <Text size={TEXT_SIZE.H4}>
                  {t('Actions.Auth.signIn')}
                </Text>
              </TextButton>
            </StyledLink>
          </LinkContainer>
        </WithHeaderComponentContainer>
      </ScreenContainer>
    );
  }
}

ConfirmEmail.propTypes = {
  match: PropTypes.object,
  confirmEmail: PropTypes.func,
  signOut: PropTypes.func,
  email: PropTypes.string,
  error: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
};

export const mapStateToProps = (state) => {
  const email = state.getIn(['confirmEmail', 'email']);
  const error = state.getIn(['confirmEmail', 'error']);
  return { email, error };
};

export const mapDispatchToProps = (dispatch) => ({
  confirmEmail: (token) => {
    dispatch(confirmEmail(token));
  },
  signOut: () => {
    dispatch(logOut());
  },
});

const withConnect = connect(mapStateToProps, mapDispatchToProps);
const withSaga = injectSaga({ key: 'confirmEmail', saga });
const withReducer = injectReducer({ key: 'confirmEmail', reducer });

export default compose(
  withConnect,
  withRouter,
  withReducer,
  withSaga,
  withTranslation()
)(ConfirmEmail);
