import {styled} from "@mui/material";
import { getIconsByType, iconTypes } from '@services/icons';

export const TipIcon = getIconsByType(iconTypes.ERROR_FILLED);

export const Wrapper = styled('div')(
  {
    display: 'flex',
    justifyItems: 'center',
    alignItems: 'center',
    cursor: 'pointer',
    minHeight: '18px',
  },
  ({ theme }) => ({
    color: theme.palette.novotalk.$textColorHighlited,
  })
);

export const IconWrapper = styled('div')(
  {
    fontSize: '15px',
    fontWeight: '700',
    display: 'flex',
    marginBottom: -2,
  },
  ({ theme }) => ({
    [theme.RTL ? 'marginLeft' : 'marginRight']: 5,
  })
);
