import React from 'react';
import DateQuestion from './components/DateQuestion';
import DropdownQuestion from './components/DropdownQuestion';
import FreeTextQuestion from './components/FreeTextQuestion';
import Instructions from './components/Instructions';
import Media from './components/Media';
import MultipleSelectQuestion from './components/MultipleSelectQuestion';
import SingleSelectQuestion from './components/SingleSelectQuestion';
import RatingQuestion from './components/RatingQuestion';
import { QUESTION_TYPES, TYPES } from '../../types';

const getComponent = (
  component, getQuestionIndex, updateAnswer, checkAnswers,
  isDisabled = false, userAnswer, miniOverview = false, required,
) => {
  if (component.type === TYPES.TEXT) {
    return <Instructions key={component.id} id={component.id} {...component.payload} miniOverview={miniOverview} />;
  } else if (component.type === TYPES.MEDIA) {
    return <Media key={component.id} id={component.id} {...component.payload} miniOverview={miniOverview} withDescription />;
  } else if (component.type === TYPES.QUESTION) {
    const props = {
      ...component.payload,
      response: component.response,
      key: component.id,
      id: component.id,
      questionIndex: getQuestionIndex && getQuestionIndex(),
      updateAnswer,
      checkAnswers,
      isDisabled,
      userAnswer,
      miniOverview,
      required,
    };
    switch (component.payload.type) {
      case QUESTION_TYPES.DATE:
        return <DateQuestion {...props} />;
      case QUESTION_TYPES.DROP_DOWN:
        return <DropdownQuestion {...props} />;
      case QUESTION_TYPES.FREE_TEXT:
        return <FreeTextQuestion {...props} />;
      case QUESTION_TYPES.MULTI_SELECT:
        return <MultipleSelectQuestion {...props} />;
      case QUESTION_TYPES.SINGLE_SELECT:
        return <SingleSelectQuestion {...props} />;
      case QUESTION_TYPES.RATING:
        return <RatingQuestion {...props} />;
      default:
        return null;
    }
  }
  return null;
};


export default getComponent;
