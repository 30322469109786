import {styled} from "@mui/material";
import { Link } from 'react-router-dom';

export const ScreenContainer = styled('div')(
  {
    minHeight: '100vh',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  ({ theme }) => ({
    direction: theme.RTL ? 'rtl' : 'inherit',
  })
);

export const WithHeaderComponentContainer = styled('div')(({ theme }) => ({
  width: theme.palette.novotalk.authFormsWidth,
}));

export const HeaderContainer = styled('div')({
  marginBottom: 18,
  letterSpacing: '0.2px',
});

export const MainContainer = styled('div')(
  {
    padding: '30px',
    marginBottom: 25,
    borderRadius: 4,
    textAlign: 'center',
  },
  ({ theme }) => ({
    border: `1px solid ${theme.palette.novotalk.$borderColorLight}`,
    backgroundColor: theme.palette.novotalk.$backgroundColorAuthScreens,
  })
);

export const LinkContainer = styled('div')({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'center',
  alignItems: 'baseline',
});

export const StyledLink = styled(Link)({
  textDecoration: 'none',
  padding: '0 5px',
});
