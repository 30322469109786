import {styled} from "@mui/material";

export const Input = styled('input')({
  background: 'white',
  border: '1px solid #e3e3e3',
  borderRadius: '5px',
  padding: 10,
  marginRight: 10,
});

export const SearchContainer = styled('div')({
  display: 'flex',
  alignItems: 'center',
});
