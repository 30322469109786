import { RESOURCES } from '../../types';
// import { metaData } from '../../__fixtures__';

export const overview = {
  id: 'skillId1',
  name: 'breathing',
  texts:{
    description: 'Pellentesque orci lectus, consectetur vel posuere posuere, rutrum eu ipsum. Aliquam eget odio sed ligula iaculis consequat at eget orci. Mauris molestie sit amet metus mattis varius. Donec sit amet ligula eget nisi Pellentesque orci lectus, consectetur vel posuere posuere, rutrum eu ipsum. Aliquam eget odio sed ligula iaculis consequat at eget orci. Mauris molestie sit amet metus mattis varius.'
  },
  type: RESOURCES.SKILL,
  contains:{
    LESSON:1,
    POLL:2,
    PRACTICE:4
  },
  entityCreated: {
    at: 'Thu Aug 02 2018 18:05:31 GMT+0000 (UTC)',
    by: 'User McUser',
  },
  entityUpdated: {
    at: 'Thu Aug 09 2018 08:46:00 GMT+0000 (UTC)',
    by: 'Useriko McUserico',
  },
  usedIn: [
    { id: 1, type: RESOURCES.COURSE, name: 'Some course 1' },
    { id: 2, type: RESOURCES.POLL, name: 'Some poll 2' },
  ],
  children: [
    {
      id: 'l1',
      payload:{name: 'Vowel production in 2 sec pattern',type: RESOURCES.LESSON},
      type: RESOURCES.LESSON,
    },
    {
      id: 'l2',
      payload:{name: 'Consonants production in 2 sec pattern',type: RESOURCES.LESSON},
      type: RESOURCES.LESSON,
    },
    {
      id: 'l3',
      payload:{name: 'One syllable words in 2 sec pattern',type: RESOURCES.LESSON},
      type: RESOURCES.LESSON,
    },
    {
      id: 'l4',
      payload:{name: 'Multi syllable words in 2 sec pattern',type: RESOURCES.LESSON},
      type: RESOURCES.LESSON,
    },
    {
      id: 'l5',
      type:"A",
      payload:{name: 'Sentences in 2 sec pattern',type: RESOURCES.LESSON},
      type: RESOURCES.LESSON,
    },
    {
      id: 'p1',
      duration: 10,
      payload:{name: 'breathing beginner',type: RESOURCES.PRACTICE},
      type: RESOURCES.PRACTICE,
    },
    {
      id: 'p2',
      duration: 15,
      payload:{name: 'breathing advanced',type: RESOURCES.PRACTICE},
      type: RESOURCES.PRACTICE,
    },
    {
      id: 'p3',
      duration: 20,
      payload:{name: 'breathing expert',type: RESOURCES.PRACTICE},
      type: RESOURCES.PRACTICE,
    },
    {
      id: 'pl1',
      payload:{name: 'My poll 1',type: RESOURCES.POLL},
      type: RESOURCES.POLL,
    },
    {
      id: 'pl2',
      payload:{name: 'My poll 2',type: RESOURCES.POLL},
      type: RESOURCES.POLL,
    },
  ],
};
export const expendedOverview = {
  id: '1',
  name: 'breathing',
  description: 'Pellentesque orci lectus, consectetur vel posuere posuere, rutrum eu ipsum. Aliquam eget odio sed ligula iaculis consequat at eget orci. Mauris molestie sit amet metus mattis varius. Donec sit amet ligula eget nisi Pellentesque orci lectus, consectetur vel posuere posuere, rutrum eu ipsum. Aliquam eget odio sed ligula iaculis consequat at eget orci. Mauris molestie sit amet metus mattis varius.',
  type: RESOURCES.SKILL,
  contains:{
    LESSON:1,
    POLL:2,
    PRACTICE:4
  },
  entityCreated: {
    at: 'Thu Aug 02 2018 18:05:31 GMT+0000 (UTC)',
    by: 'User McUser',
  },
  entityUpdated: {
    at: 'Thu Aug 09 2018 08:46:00 GMT+0000 (UTC)',
    by: 'Useriko McUserico',
  },
  usedIn: [
    { id: 1, type: RESOURCES.COURSE, name: 'Some course 1' },
    { id: 2, type: RESOURCES.POLL, name: 'Some poll 2' },
  ],
  children: [
    {
      id: 'l1',
      name: 'Vowel production in 2 sec pattern',
      type: RESOURCES.LESSON,
    },
    {
      id: 'l2',
      name: 'Consonants production in 2 sec pattern',
      type: RESOURCES.LESSON,
    },
    {
      id: 'l3',
      name: 'One syllable words in 2 sec pattern',
      type: RESOURCES.LESSON,
    },
    {
      id: 'l4',
      name: 'Multi syllable words in 2 sec pattern',
      type: RESOURCES.LESSON,
    },
    {
      id: 'l5',
      name: 'Sentences in 2 sec pattern',
      type: RESOURCES.LESSON,
    },
    {
      id: 'p1',
      duration: 10,
      name: 'breathing beginner',
      type: RESOURCES.PRACTICE,
      children:[
        {
          id:'666',
          name:'some excersize',
          type:RESOURCES.EXERCISE
        },
        {
          id:'777',
          name:'another excersize',
          type:RESOURCES.EXERCISE
        }
      ]
    },
    {
      id: 'p2',
      duration: 15,
      name: 'breathing advanced',
      type: RESOURCES.PRACTICE,
      children:[
        {
          id:'666',
          name:'some excersize',
          type:RESOURCES.EXERCISE
        },
        {
          id:'777',
          name:'another excersize',
          type:RESOURCES.EXERCISE
        }
      ]
    },
    {
      id: 'p3',
      duration: 20,
      name: 'breathing expert',
      type: RESOURCES.PRACTICE,
      children:[
        {
          id:'666',
          name:'some excersize',
          type:RESOURCES.EXERCISE
        },
        {
          id:'777',
          name:'another excersize',
          type:RESOURCES.EXERCISE
        }
      ]
    },
    {
      id: 'pl1',
      name: 'My poll 1',
      type: RESOURCES.POLL,
    },
    {
      id: 'pl2',
      name: 'My poll 2',
      type: RESOURCES.POLL,
    },
  ],
};