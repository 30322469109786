import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import Text, { TEXT_SIZE, TEXT_COLOR } from '@components/Text';
import CheckBox from '@components/CheckBox';
import { capitalizeScreamingCase } from '@utils/textHelpers';
import { ConfigSection } from '../../../../../styles';
import withUpdateField from '@shared/Resources/Monitor/Client/shared/HOC/ConfigUpdater';
import { CheckboxGroupTitle, CheckboxGroupItem, CheckBoxLabel } from './styles';
import { getFormattedTextCurry } from '@shared/Resources/Monitor/shared/utils/formattedText';

const getFormattedText = getFormattedTextCurry();

const getShownText = (checkboxValue, useMessages, capitalize) => {
  if (useMessages) {
    const { message } = checkboxValue;
    return (<React.Fragment>
      {getFormattedText(message, { textColor: TEXT_COLOR.DARK_BLUE, size: TEXT_SIZE.H4 }, 'wordCountType')}
      {getFormattedText(`${message}Description`, { size: TEXT_SIZE.H6, textColor: TEXT_COLOR.DISABLED_LIGHT },
        'wordCountDescription')}
    </React.Fragment>);
  }
  return (<Text
    size={TEXT_SIZE.H4}
    textColor={TEXT_COLOR.DARK_BLUE}
    className="optionLabel"
  >
    {capitalize ? capitalizeScreamingCase(checkboxValue) : checkboxValue}
  </Text>);
};

const CheckBoxGroup = ({ config, updateField, groupName, groupValues, capitalize, useMessages }) => (
  <ConfigSection className={groupName}>
    <CheckboxGroupTitle>
      {getFormattedText(`Stimuli.${groupName}`, { weight: 'bold', textColor: TEXT_COLOR.DARK_BLUE, size: TEXT_SIZE.H4 })}
    </CheckboxGroupTitle>
    {groupValues.map((checkboxValue) => {
      const value = useMessages ? checkboxValue.value : checkboxValue;
      const text = getShownText(checkboxValue, useMessages, capitalize);
      return (<CheckboxGroupItem key={value}>
        <CheckBox
          onChange={updateField(`${groupName}.${value}`)}
          value={_.get(config, `${groupName}.${value}`, false)}
          label={<CheckBoxLabel>{text}</CheckBoxLabel>}
        />
      </CheckboxGroupItem>);
    })
    }
  </ConfigSection>
);

CheckBoxGroup.defaultProps = {
  capitalize: true,
  useMessages: false,
};

CheckBoxGroup.propTypes = {
  config: PropTypes.object,
  updateField: PropTypes.func,
  groupName: PropTypes.string,
  groupValues: PropTypes.array,
  capitalize: PropTypes.bool,
  useMessages: PropTypes.bool,
};


export default withUpdateField(CheckBoxGroup);
