import { Fragment, useMemo } from 'react';
import { SectionTitle, ListPracticerErrors } from './styles';
import { OtherErrorsWithTooltip } from './components/errors-with-tooltip';
import { ErrorNameCard } from './components/error-name-card';
import { formatVisibleErrors } from '../../helpers';
import {useTranslation} from "react-i18next";

/**
 * List errors
 */
export const ErrorsList = ({ formattedErrors }) => {
  const visibleErrors = useMemo(() => formatVisibleErrors(formattedErrors), [formattedErrors]);
  const {t} = useTranslation()
  return (
    <ListPracticerErrors>
      {visibleErrors.map(({ label, value }, index) => (
        <Fragment key={label}>
          {index === 0 && <SectionTitle>{t('UI.mainError')}</SectionTitle>}
          {index === 1 && <SectionTitle>{t('UI.moreErrors')}</SectionTitle>}
          <ErrorNameCard
            index={index}
            value={value}
            label={label}
          />
        </Fragment>
      ))}
      {formattedErrors?.length > 2 && formattedErrors[3]?.value && <OtherErrorsWithTooltip formattedErrors={formattedErrors} />}
    </ListPracticerErrors>
  );
};
