import React, { Component } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import Text, { TEXT_SIZE, TEXT_COLOR } from '@components/Text';
import {
  Wrapper,
  MessageWrapper,
  StyledAvatar,
  Message,
  NewMessagesIndicator,
  SeenIndicator,
  StyledCheckmarkIcon,
} from './styles';
import {StyledPersonIcon} from "@containers/App/containers/Messaging/components/MessagesList/styles";
import {withTranslation} from "react-i18next";
import {compose} from "redux";

class ConversationArea extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  parsePosts = (posts, users) => {
    const selfUser = _.find(users, { self: true });
    const otherUsers = _.compact(
      users.map((user) => {
        if (user.self) return null;
        return user.id;
      })
    );
    const parsedPosts = posts.map((post) => {
      const unread = !post.self && !post.seenBy?.includes(selfUser.id);
      const seen =
        selfUser.isMentor && _.difference(otherUsers, post.seenBy).length === 0;
      return {
        ...post,
        unread,
        seen,
      };
    });
    return parsedPosts;
  };

  render() {
    const {currentConversation, t} = this.props;
    if (_.isEmpty(currentConversation)) return null;

    const posts = this.parsePosts(
      currentConversation.Thread.Posts,
      currentConversation.Users
    );
    const lastSeenIndex = posts.findIndex((post) => post.seen);
    return (
      <Wrapper>
        {posts.map((post, index) => {
          const isFirstUnread =
            post.unread &&
            (index === posts.length - 1 || !posts[index + 1].unread);
          const isQueue =
            index !== posts.length - 1 &&
            post.self === posts[index + 1].self &&
            !isFirstUnread;
          const isLastSeen = index === lastSeenIndex;
          return (
            <div key={post.id}>
              {isFirstUnread && (
                <NewMessagesIndicator>
                  <Text
                    size={TEXT_SIZE.T3}
                    textColor={TEXT_COLOR.DISABLED}
                    margin='0 24px'
                  >
                    {t('Informative.Notifying.newMessages')}
                  </Text>
                </NewMessagesIndicator>
              )}
              <MessageWrapper self={post.self} isQueue={isQueue}>
                {!isQueue && (post.publisherUser?.avatar ? <StyledAvatar src={post.publisherUser.avatar} /> : <StyledPersonIcon />)}
                <Message self={post.self} isQueue={isQueue}>
                  <Text size={TEXT_SIZE.H4} textColor={TEXT_COLOR.DARK_BLUE}>
                    {post.payload.text}
                  </Text>
                </Message>
              </MessageWrapper>
              {isLastSeen && (
                <SeenIndicator>
                  <StyledCheckmarkIcon />
                  <Text
                    size={TEXT_SIZE.T5}
                    textColor={TEXT_COLOR.DISABLED_LIGHT}
                  >
                    {t('Common.Statuses.seen')}
                  </Text>
                </SeenIndicator>
              )}
            </div>
          );
        })}
      </Wrapper>
    );
  }
}

ConversationArea.propTypes = {
  currentConversation: PropTypes.object,
};

export default compose(
    withTranslation()
)(ConversationArea);
