import React, { useMemo } from 'react';
import {
  SidebarWrapper,
  AvatarWrapper,
  Avatar,
  SidebarMenu,
  Tab,
  StyledArrowRightIcon,
  EmptyAvatar,
} from './styles';
import Text, { TEXT_COLOR } from '@components/Text';
import { ROUTES } from '@shared/constants';
import { generatePath, useParams, useHistory } from 'react-router-dom';
import { NO_NAME, TABS } from '../../../consts';
import { useSelector } from 'react-redux';

/**
* Sidebar for all internal pages on the right side
* @param {string} pageType - page type
*/
export const Sidebar = ({ pageType }) => {
  const { id } = useParams();
  const { push } = useHistory();

  const { avatar, fullName } = useSelector((state) => state.getIn(['userPage', 'userData']));

   /**
   * Handle navigation to user page by type
   * @param {number} pageType - Selected type page
   */
  const goToPage = (pageType) => () => {
    push(generatePath(ROUTES.USERS.PAGE, { id, pageType }));
  }

  const firstLettersName = useMemo(() =>
    fullName && fullName.split(' ').map((name) => name.at(0))
  , [fullName]);

  return (
    <SidebarWrapper>
      <AvatarWrapper>
        {avatar
          ? <Avatar src={avatar} alt={`${fullName ?? NO_NAME}`} />
          : <EmptyAvatar>{firstLettersName}</EmptyAvatar>
        }
        <Text size="H1" textColor={TEXT_COLOR.DARK_BLUE}>
          {fullName ?? NO_NAME}
        </Text>
      </AvatarWrapper>
      <SidebarMenu>
        {TABS.map(({ type, title }) => (
          <Tab
            to={generatePath(ROUTES.USERS.PAGE, { id, pageType: type })}
            onClick={goToPage(type)}
            active={pageType === type}
          >
          {title}
          {pageType === type && <StyledArrowRightIcon />}
        </Tab>
        ))}
      </SidebarMenu>
    </SidebarWrapper>
  );
};
