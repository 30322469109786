import {
  takeLatest,
  call,
  put,
  takeEvery,
  all,
  fork,
  join,
} from 'redux-saga/effects';
import {
  getFinalToken,
  refreshTokenCall,
} from '@services/authenticationService';
import {
  types,
  onBridgeTokenValidationFinished,
  onBridgeTokenValidationFail,
  setRefreshToken,
  setUserRoles,
  setServerProperties,
  clearToken,
  setAppError,
} from './actions';
import { getRolesEnum } from '@services/authenticationService';
import { getServerProperties as fetchServerProperties } from '@services/propertiesService';
import { closePopUp } from "@containers/App/containers/PopUp/actions";
import { tryingToExitExerciseAction } from '@containers/User/containers/CourseRouter/containers/Practice/actions';
import { endActivityLesson } from '@containers/User/containers/CourseRouter/containers/Lesson/actions';

const useFixtures = process.env.USE_FIXTURES;

function* validateBridgeTokenSaga(action) {
  try {
    if (!useFixtures) {
      const res = yield fork(getFinalToken, action.bridgeToken);
      const token = yield join(res);
      if (token) {
        yield put(onBridgeTokenValidationFinished(token));
      }
    } else {
      yield put(onBridgeTokenValidationFinished('token'));
    }
  } catch (error) {
    yield put(onBridgeTokenValidationFail(error));
    yield put(setAppError(error.message))
  }
}
function* refreshTokenSaga() {
  try {
    const res = yield fork(refreshTokenCall);
    const tokens = yield join(res);
    if (tokens) {
      yield put(setRefreshToken(tokens));
    }
  } catch (error) {
    yield put(setAppError(error.message))
  }
}

function* getuserRoles() {
  try {
    const res = yield fork(getRolesEnum);
    const rolesEnum = yield join(res);
    if (rolesEnum) {
      yield put(setUserRoles(rolesEnum));
    } else {
      yield put(setUserRoles({}));
    }
  } catch (error) {
    yield put(setUserRoles({}));
    yield put(setAppError(error.message))
  }
}

function* getServerProperties() {
  try {
    const properties = yield call(fetchServerProperties);
    yield put(setServerProperties(properties));
  } catch (error) {
    yield put(setServerProperties([]));
    yield put(setAppError(error.message))
  }
}

function* closePopUps() {
  yield put(closePopUp())
}
function* stopRecordVideoAndSound(action) {
  try {
  if(!action.data){
    yield put(clearToken());
  }else{
  const {
    exerciseInProgress,
    lessonInProgress,
    activityLessonId,
  } = action.data;
    if (exerciseInProgress) {
      yield put(tryingToExitExerciseAction(true));
    }
    if (activityLessonId && lessonInProgress) {
      yield put(endActivityLesson(activityLessonId, true));
    } else {
      yield put(clearToken());
    }}
  } catch (error) {
    yield put(setAppError(error.message))
  }
}

function* watchValidateBridgeTokenSaga() {
  yield takeLatest(types.VALIDATE_BRIDGE_TOKEN, validateBridgeTokenSaga);
}
function* watchRefreshTokenSaga() {
  yield takeEvery(types.REFRESH_TOKEN, refreshTokenSaga);
}
function* watchGetuserRoles() {
  yield takeLatest(types.GET_USER_ROLES, getuserRoles);
}
function* watchGetServerProperties() {
  yield takeLatest(types.GET_SERVER_PROPERTIES, getServerProperties);
}
function* watchLogOut() {
  yield takeLatest(types.LOG_OUT, closePopUps);
}
function* watchLogoutAndStopRecord() {
  yield takeLatest(types.LOG_OUT, stopRecordVideoAndSound);
}

export default function* getPendingDashboardDataAppSaga() {
  yield all([
    fork(watchValidateBridgeTokenSaga),
    fork(watchRefreshTokenSaga),
    fork(watchGetuserRoles),
    fork(watchGetServerProperties),
    fork(watchLogOut),
    fork(watchLogoutAndStopRecord),
  ]);
}
