import {styled} from "@mui/material";

const InhalationIcon = styled('div')({
  display: 'inline-block',
  background: 'linear-gradient(to right, #777777 0%,#ffffff 100%)',
  width: 15,
  height: 15,
});

export default InhalationIcon;
