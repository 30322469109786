import { Component, createRef } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import Text, { TEXT_SIZE, TEXT_COLOR } from '@components/Text';
import { MEDIA_TYPES } from '../../../../types';
import {
  Wrapper,
  DescriptionContainer,
  VideoPlayer,
  AudioPlayer,
  Image,
  StyledMediaIcon,
  StyledMiniOverviewIcon,
  StyledPlayIcon,
  VideoWrapper,
  ImageWrapper,
} from './styles';
import { openPopUp as openPopUpAction } from '@containers/App/containers/PopUp/actions';
import POPUP_TYPES from '@containers/App/containers/PopUp/types';
import {withTranslation} from "react-i18next";

export class Media extends Component {
  constructor(props) {
    super(props);
    this.videoRef = createRef();
    this.canvasRef = createRef();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.contentUrl !== this.props.contentUrl) {
      this.videoRef.current?.load();
    }
  }

  captureThumbnail() {
    const canvas = this.canvasRef;
    const video = this.videoRef;
    canvas.width = video.width;
    canvas.height = video.height;
    canvas.getContext('2d').drawImage(video, 0, 0, video.width, video.height);
  }

  openPopUp(props) {
    this.props.openPopUp(props);
  }

  renderMedia() {
    const {
      contentUrl,
      contentType,
      description,
      styleOverride,
      miniOverview,
      t
    } = this.props;
    const props = {
      description,
      contentUrl,
      contentType,
    };
    if (!contentUrl) {
      return (
        <ImageWrapper className='noMediaContainer'>
          {t('UI.noMedia')}
        </ImageWrapper>
      );
    }

    switch (contentType) {
      case MEDIA_TYPES.VIDEO:
        return (
          <VideoWrapper
            styleOverride={styleOverride}
            onClick={() => this.openPopUp(props)}
            miniOverview={miniOverview}
          >
            <VideoPlayer
              ref={this.videoRef}
              width={miniOverview ? '220' : '390'}
              height={miniOverview ? '120' : '220'}
              onLoadedData={() => this.captureThumbnail()}
            >
              <source type='video/mp4' src={contentUrl} />
            </VideoPlayer>
            <StyledPlayIcon />
          </VideoWrapper>
        );
      case MEDIA_TYPES.AUDIO:
        return (
          <AudioPlayer
            styleOverride={styleOverride}
            controls
            miniOverview={miniOverview}
          >
            <source src={contentUrl} />
          </AudioPlayer>
        );
      case MEDIA_TYPES.IMAGE:
        return (
          <ImageWrapper
            styleOverride={styleOverride}
            miniOverview={miniOverview}
          >
            <Image src={contentUrl} onClick={() => this.openPopUp(props)} />
          </ImageWrapper>
        );
      default:
        return null;
    }
  }

  render() {
    const { description, noHeader, miniOverview, withDescription } = this.props;
    const isPollMiniOverview = miniOverview && withDescription;
    const Icon = miniOverview ? StyledMiniOverviewIcon : StyledMediaIcon;
    return (
      <Wrapper
        miniOverview={miniOverview}
        isPollMiniOverview={isPollMiniOverview}
      >
        {!noHeader && description && (
          <>
            <Icon />
            <Text
              size={TEXT_SIZE[miniOverview ? 'H4' : 'T3']}
              weight={miniOverview ? 'normal' : '900'}
              textColor={TEXT_COLOR.DARK_BLUE}
            >
              {isPollMiniOverview ? (
                  this.props.t('Common.UI.media')
              ) : (
                description
              )}
            </Text>
            {isPollMiniOverview && (
              <DescriptionContainer>
                <Text size={TEXT_SIZE.H4} textColor={TEXT_COLOR.DARK_BLUE}>
                  {description}
                </Text>
              </DescriptionContainer>
            )}
          </>
        )}
        {this.renderMedia()}
      </Wrapper>
    );
  }
}

Media.defaultProps = {
  styleOverride: {},
};

Media.propTypes = {
  contentUrl: PropTypes.string,
  contentType: PropTypes.string,
  description: PropTypes.string,
  openPopUp: PropTypes.func,
  styleOverride: PropTypes.object,
  noHeader: PropTypes.bool,
  miniOverview: PropTypes.bool,
  withDescription: PropTypes.bool,
};

export function mapDispatchToProps(dispatch) {
  return {
    openPopUp: (props) => dispatch(openPopUpAction(POPUP_TYPES.MEDIA, props)),
  };
}

const withConnect = connect(null, mapDispatchToProps);

export default compose(
    withConnect,
    withTranslation()
)(Media);
