import {styled} from "@mui/material";
import { getIconsByType, iconTypes } from '@services/icons';
import { getFlexibleSize } from '@utils/cssHelpers';

export const Wrapper = styled('div')({
  gridArea: 'conversation',
  padding: '40px 20px',
  display: 'flex',
  flexDirection: 'column-reverse',
  overflowY: 'auto',
});

export const MessageWrapper = styled('div')(
  {
    display: 'flex',
    flexShrink: 0,
  },
  ({ self, isQueue }) => ({
    marginTop: isQueue ? 10 : 20,
    flexDirection: self ? 'row-reverse' : 'row',
  })
);

export const StyledAvatar = styled('img')({
  width: 40,
  height: 40,
  objectFit: 'cover',
  borderRadius: '50%',
});

export const Message = styled('div')(
  {
    maxWidth: getFlexibleSize(565, 824),
    padding: '12px 20px',
    borderRadius: 9,
  },
  ({ theme, self, isQueue }) => ({
    direction: theme.RTL ? 'rtl' : 'ltr',
    backgroundColor: self
      ? theme.palette.novotalk.$messagingOthersMessageColor
      : theme.palette.novotalk.$messagingSelfMessageColor,
    [self ? 'marginRight' : 'marginLeft']: isQueue ? 50 : 10,
    [self ? 'borderTopRightRadius' : 'borderTopLeftRadius']: 0,
  })
);

export const NewMessagesIndicator = styled('div')(
  {
    width: '100%',
    margin: '30px 0 10px',
    display: 'flex',
    justifyContent: 'stretch',
    lineHeight: '15px',
    '&:before, &:after': {
      display: 'block',
      content: '""',
      height: 1,
      flexGrow: 1,
      position: 'relative',
      top: 8,
    },
  },
  ({ theme }) => ({
    '&:before, &:after': {
      backgroundColor: theme.palette.novotalk.$borderColorLight,
    },
  })
);

export const SeenIndicator = styled('div')({
  marginRight: 50,
  lineHeight: '14px',
  textAlign: 'right',
});

const CheckmarkIcon = getIconsByType(iconTypes.CHECKMARK);

export const StyledCheckmarkIcon = styled(CheckmarkIcon)(
  {
    fontSize: 14,
    marginRight: 2,
  },
  ({ theme }) => ({
    color: theme.palette.novotalk.$textColorDisabledLight,
  })
);
