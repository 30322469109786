import {styled} from "@mui/material";
import Text from '@components/Text';
import { getResponsiveCSS } from '@utils/cssHelpers';

export const Wrapper = styled('div')({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  marginBottom: 30,
});

export const Section = styled('div')(
  {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    textAlign: 'center',
    margin: '0 10px',
    borderRadius: 4,
  },
  getResponsiveCSS('width', 280, 388),
  getResponsiveCSS('height', 120, 140),
  getResponsiveCSS('paddingTop', 16, 28),
  ({ theme }) => ({
    backgroundColor: theme.palette.novotalk.$backgroundColorMedium,
  })
);

export const CountText = styled(Text)(
  {
    fontSize: 36,
  },
  getResponsiveCSS('marginBottom', 7, 16)
);

export const LabelText = styled(Text)({
  width: '50%',
});
