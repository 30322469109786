import styled from '@emotion/styled';
import { getIconsByType, iconTypes } from '@services/icons';

export const VersionIcon = styled(getIconsByType(iconTypes.VERSION))(
  {
    fontSize: 23,
  },
  ({ theme }) => ({
    color: theme.palette.novotalk.$textColorDisabledLight,
    marginLeft: theme.RTL ? 12 : 0,
    marginRight: theme.RTL ? 12 : 6,
  }),
);
