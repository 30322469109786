import {
  call,
  put,
  takeLatest,
  takeLeading,
  all,
  fork,
} from 'redux-saga/effects';
import { toast } from 'react-toastify';
import { t } from 'i18next';
import ChatService from '@services/chatService';
import { clearToken, types } from '@containers/App/actions';
import { TOAST_AUTO_CLOSE_TIME } from '@shared/constants';
import {
  TYPES,
  onError,
  setMirrorConnectionStatusToConnected,
  setMirrorConnectionStatusToDisconnected,
} from './actions';

export function* callConnectToMirrorSession() {
  try {
    yield put(setMirrorConnectionStatusToConnected());
  } catch (error) {
    yield put(onError(error.message));
    toast.error(t('Errors.mirrorNotLoad'), {
      autoClose: TOAST_AUTO_CLOSE_TIME,
      position: 'bottom-right',
    });
  }
}

export function* callStartVideoRecording(action) {
  try {
    yield call(ChatService.startVideoMonitorRecording, action.activityContext);
  } catch (error) {
    yield put(onError(error.message));
  }
}

export function* callStopVideoRecording() {
  try {
    yield call(ChatService.stopVideoMonitorRecording);
  } catch (error) {
    yield put(onError(error.message));
  }
}

export function* callDisconnectFromMirrorSession({ archiveId, isLogout }) {
  try {
    yield call(ChatService.disconnectFromMirrorSession, archiveId);
    yield put(setMirrorConnectionStatusToDisconnected());
  } catch (error) {
    yield put(onError(error.message));
  } finally {
    if (isLogout) {
      yield put(clearToken());
    }
  }
}

function* watchCallConnectToMirrorSession() {
  yield takeLatest(TYPES.CONNECT_TO_MIRROR_SESSION, callConnectToMirrorSession);
}
function* watchCallStartVideoRecording() {
  yield takeLatest(TYPES.START_VIDEO_RECORDING, callStartVideoRecording);
}
function* watchCallStopVideoRecording() {
  yield takeLeading(TYPES.STOP_VIDEO_RECORDING, callStopVideoRecording);
}
function* watchCallDisconnectFromMirrorSession() {
  yield takeLatest(
    TYPES.DISCONNECT_FROM_MIRROR_SESSION,
    callDisconnectFromMirrorSession,
  );
}
function* watchCallStopVideoRecordingAfterLogout() {
  yield takeLeading(types.LOG_OUT, callStopVideoRecording);
}
function* watchCallDisconnectFromMirrorSessionAfterLogout() {
  yield takeLatest(types.LOG_OUT, callDisconnectFromMirrorSession);
}

export default function* getVideoMirrorSaga() {
  yield all([
    fork(watchCallConnectToMirrorSession),
    fork(watchCallStartVideoRecording),
    fork(watchCallStopVideoRecording),
    fork(watchCallDisconnectFromMirrorSession),
    fork(watchCallStopVideoRecordingAfterLogout),
    fork(watchCallDisconnectFromMirrorSessionAfterLogout),
  ]);
}
