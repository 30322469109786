import { useMemo } from 'react';
import PieWrapper from '@containers/Admin/containers/ActivityPage/__fixtures__/__ActivityPage/containers/ActivityStats/components/PieWrapper';
import { PieChart } from './styles';
import { formatVisiblePercents } from '../../helpers';
import { isDesktop } from '@shared/constants/media-queries';
import {theme} from "@services/theme";

export const COLORS_LIST = [
    theme.palette.novotalk.$mainError,
  theme.palette.novotalk.$firstAdditionalError,
  theme.palette.novotalk.$secondAdditionalError,
  theme.palette.novotalk.$otherError
];

const sizesPieChart = isDesktop() ? 200 : 140;

/**
 * Pie chart component
 */
export const PracticeErrorsPieChart = ({ formattedErrors }) => {
  const visiblePercent = useMemo(() => formatVisiblePercents(formattedErrors), [formattedErrors]);
  return (
    <PieChart>
      <PieWrapper
        data={visiblePercent}
        colors={COLORS_LIST}
        width={sizesPieChart}
        height={sizesPieChart}
      />
    </PieChart>
  );
};
