import { RESOURCES } from './types';

// MiniOverview
import MiniOverviewMedia from './Media/MiniOverview';
import MiniOverviewMonitor from './Monitor/MiniOverview';
import OverviewListItem from './shared/components/OverviewListItem';
import {
  OverviewSectionCourse
} from '@shared/Resources/shared/components/OverviewSection/components/OverviewSectionCourse';
import { detectCurrentCourseLevel } from './shared/components/OverviewListItem/helpers';

export const createMiniOverview = (
  type,
  resource,
  additionalProps,
  isFirstTier = true,
  isInnerList = false,
  areItemsExpanded,
  areItemsCollapsed,
  onItemStateChange,
  isUserPageProgram,
) => {
  const listCommonProps = {
    createMiniOverview,
    resource,
    isInnerList,
    areItemsExpanded,
    areItemsCollapsed,
    onItemStateChange,
    isUserPageProgram,
    ...additionalProps,
  };
  switch (type) {
    case RESOURCES.MEDIA: return <MiniOverviewMedia {...resource} />;
    case RESOURCES.MONITOR: return <MiniOverviewMonitor {...resource} />;
    // list mini overview
    case RESOURCES.SKILL: {
      const containedLesson = isUserPageProgram ? resource.Lessons?.length > 0 : resource.payload.contains[RESOURCES.LESSON] || 0;
      const containedPractice = isUserPageProgram ? resource.Practices?.length > 0 : resource.payload.contains[RESOURCES.PRACTICE] || 0;
      const containedPoll = isUserPageProgram ? resource.Polls?.length > 0 : resource.payload.contains[RESOURCES.POLL] || 0;
      const hasChildren = (containedLesson + containedPractice + containedPoll) > 0;

      const checkCurrentLevelInLessons = detectCurrentCourseLevel(resource.Lessons);
      const checkCurrentLevelInPractices = detectCurrentCourseLevel(resource.Practices);
      const checkCurrentLevelInPolls = detectCurrentCourseLevel(resource.Polls);
      const hasCurrentLevel = checkCurrentLevelInLessons || checkCurrentLevelInPractices || checkCurrentLevelInPolls;

      return <OverviewListItem
        {...listCommonProps}
        deepList={isFirstTier && hasChildren}
        hasCurrentLevel={hasCurrentLevel}
      />
    }
    case RESOURCES.LESSON: return <OverviewListItem {...listCommonProps} />;
    case RESOURCES.PRACTICE: return <OverviewListItem {...listCommonProps} deepList={isFirstTier} />;
    case RESOURCES.POLL: return <OverviewListItem {...listCommonProps} />;
    case RESOURCES.EXERCISE: return <OverviewListItem {...listCommonProps} />;
    case RESOURCES.COURSE: return <OverviewSectionCourse isDefault={additionalProps.isDefault} />
    default: return null;
  }
};
