import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { connect } from 'react-redux';
import {
  openPopUp,
  closePopUp,
} from '@containers/App/containers/PopUp/actions';
import { updateComponentPayload } from './actions';

/**
 * This is a wrapper for all editor components
 * it allows all component to dispatch payload update with the injected "submitPayload" method
 * all wrapped components props will have
 * - payload
 * - id
 * - type
 * - submitPayload
 */
const DEBOUNCE_DURATION = 1000;
const EditorComponentWrapper = (EditorComponent) => {
  class EditorBaseComponent extends React.Component {
    constructor(props) {
      super(props);

      // injected method
      this.submitPayload = _.debounce(
        this.dispatchUpdatePayload,
        DEBOUNCE_DURATION
      );
    }

    dispatchUpdatePayload = (payload) => {
      const { id } = this.props;
      this.props.dispatchUpdateComponentPayload(id, payload);
    };

    render() {
      const { payload, id, type } = this.props;
      const passedProps = { payload, id, type };
      return (
        <div className='editorComponentWrapper'>
          <EditorComponent
            {...passedProps}
            submitPayload={this.submitPayload}
            openPopup={this.props.dispatchOpenPopup}
            closePopup={this.props.dispatchClosePopup}
          />
        </div>
      );
    }
  }

  EditorBaseComponent.propTypes = {
    payload: PropTypes.object,
    type: PropTypes.string,
    id: PropTypes.string,
    dispatchUpdateComponentPayload: PropTypes.func,
    dispatchOpenPopup: PropTypes.func,
    dispatchClosePopup: PropTypes.func,
  };

  function mapDispatchToProps(dispatch) {
    return {
      dispatchUpdateComponentPayload: (id, payload) =>
        dispatch(updateComponentPayload(id, payload)),
      dispatchOpenPopup: (type, params) => dispatch(openPopUp(type, params)),
      dispatchClosePopup: () => dispatch(closePopUp()),
    };
  }
  return connect(null, mapDispatchToProps)(EditorBaseComponent);
};

export default EditorComponentWrapper;
