import React from 'react';
import PropTypes from 'prop-types';
import Text, { TEXT_SIZE, TEXT_COLOR } from '@components/Text';
import { WidgetsContainer, getStyledIcon } from './styles';

const WidgetItem = ({ widget, onSelect }) => {
  const Icon = getStyledIcon(widget.type);
  return (
    <WidgetsContainer
      id={`widget_${widget.type}`}
      onClick={() => onSelect(widget)}
    >
      <Icon />
      <Text
        size={TEXT_SIZE.T3}
        textColor={TEXT_COLOR.DARK_BLUE}
        margin='0 12px'
      >
        {widget.payload.title}
      </Text>
    </WidgetsContainer>
  );
};

WidgetItem.propTypes = {
  widget: PropTypes.object,
  onSelect: PropTypes.func,
};

export default WidgetItem;
