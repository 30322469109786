import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import Text, { TEXT_SIZE, TEXT_COLOR, TEXT_WEIGHT } from '@components/Text';
import autoFeedback from '@shared/constants/autoFeedback';
import SectionLoading from '@components/SectionLoading';
import AutoFeedback from '../AutoFeedback';
import {
  Wrapper,
  HeaderContainer,
  StyledTextArea,
  PostButtonContainer,
  StyledCTAButton,
} from './styles';
import { withTranslation } from 'react-i18next';
import { compose } from 'redux';

class AutoFeedbackTextArea extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      text: '',
      previousText: '',
    };
  }

  shouldComponentUpdate(nextProps) {
    const { externalText, hasError } = nextProps;
    if (externalText !== this.props.externalText) {
      this.setState({ text: externalText });
    }
    if (hasError && !this.props.hasError) {
      this.setState({ text: this.state.previousText });
    }
    return true;
  }

  onPostFeedbackClick = () => {
    const { onPostClicked } = this.props;
    const { text } = this.state;
    onPostClicked(text);
    this.setState({ 
      previousText: text,
      text: '' 
    });
  };

  onFeedbackSelect = (text) => {
    this.setState({ text: this.state.text + text });
  };

  onValueChange = (event) => {
    const { value } = event.target;
    this.setState({ text: value });
  };

  render() {
    const { text } = this.state;
    const {
      disabled,
        t
    } = this.props;
    return (
      <Wrapper className='wrapper'>
        <HeaderContainer>
          <Text
            size={TEXT_SIZE.T1}
            textColor={TEXT_COLOR.DARK_BLUE}
            weight={TEXT_WEIGHT.BOLD}
          >
            {t('Common.UI.feedback')}
          </Text>
          <AutoFeedback
            dataTree={autoFeedback}
            onSelect={this.onFeedbackSelect}
            groupName='Group Name'
            boundariesElement='window'
          />
        </HeaderContainer>
        {disabled ? (
          <SectionLoading />
        ) : (
          <div>
            <StyledTextArea
              id='feedback_text_input'
              className='feedbackInput'
              placeholder={t('Common.Placeholders.leaveFeedback')}
              name='text'
              value={text}
              onChange={this.onValueChange}
            />
            <PostButtonContainer>
              <StyledCTAButton
                className='postFeedbackButton'
                action
                expanded
                disabled={disabled || _.isEmpty(this.state.text)}
                onClick={this.onPostFeedbackClick}
              >
                {t('Actions.Instance.submit')}
              </StyledCTAButton>
            </PostButtonContainer>
          </div>
        )}
      </Wrapper>
    );
  }
}

AutoFeedbackTextArea.propTypes = {
  onPostClicked: PropTypes.func,
  externalText: PropTypes.string,
  disabled: PropTypes.bool,
  hasError: PropTypes.bool,
};

export default compose(
    withTranslation()
)(AutoFeedbackTextArea);
