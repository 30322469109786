import React from 'react';

const icon = () => (<svg width="100%" viewBox="0 0 64 64" version="1.1" xmlns="http://www.w3.org/2000/svg">
  <g id="Project-Styleguide" stroke="none" strokeWidth="inherit" fill="none" fillRule="evenodd">
    <g id="Illustrations" transform="translate(-80.000000, -452.000000)">
      <rect fill="inherit" x="0" y="0" width="1690" height="630"></rect>
      <g id="01-Volume-Control" transform="translate(80.000000, 452.000000)">
        <circle id="Oval" stroke="#23B7E5" fill="inherit" cx="32" cy="32" r="31.5"></circle>
        <path d="M24.6382979,37.5319149 L24.6382979,26.4680851 L19.106383,26.4680851 L19.106383,37.5319149 L24.6382979,37.5319149 L24.6382979,37.5319149 Z M24.6382979,25.3617021 L40.4042553,19 L40.4042553,45 L24.6382979,38.6382979 L18,38.6382979 L18,25.3617021 L24.6382979,25.3617021 L24.6382979,25.3617021 Z M25.7446809,26.108325 L25.7446809,37.891675 L39.2978723,43.3605066 L39.2978723,20.6394934 L25.7446809,26.108325 L25.7446809,26.108325 Z M43.7572932,39.0354221 L42.589917,38.2938946 C43.7758364,36.4269189 44.4148936,34.2615577 44.4148936,32 C44.4148936,29.7425332 43.7740185,27.5742334 42.5877806,25.7051896 L43.755438,24.9641051 C45.0811471,27.0529006 45.7978723,29.4778272 45.7978723,32 C45.7978723,34.5261496 45.0830106,36.9483643 43.7572932,39.0354221 Z M28.2340426,28.4042553 L29.3404255,28.4042553 L29.3404255,35.5957447 L28.2340426,35.5957447 L28.2340426,28.4042553 Z" id="Shape" fill="#23B7E5" fillRule="nonzero"></path>
      </g>
    </g>
  </g>
</svg>);

export default icon;
