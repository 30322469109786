export const types = {
  UNAUTHORIZED_CONNECTION_ERROR: 'UNAUTHORIZED_CONNECTION_ERROR',
};


export function loginAfterUnauthorizedConnection(reason) {
  return {
    type: types.UNAUTHORIZED_CONNECTION_ERROR,
    reason,
  };
}
