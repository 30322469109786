import { GRAPH_SIZE, SAMPLES_IN_SEC } from '@shared/DSPHandler/containers/Monitor/constants';
import { LOCATION_TYPES } from '@shared/Resources/Monitor/types';

// Calculate tooltip position for one second monitor
export const getErrorPointForOneSecMonitor = (duration, location, toastWidth) => {
  const widthMonitor = Math.round(duration * GRAPH_SIZE.ONE_SECOND.sampleWidth * SAMPLES_IN_SEC);
  const wrapperWidth = document.getElementById('MonitorsWrapper')?.offsetWidth;
  const waveLength = widthMonitor / duration
  const positionInMonitor = waveLength * location;
  const differenceInSizes = wrapperWidth - widthMonitor
  return differenceInSizes / 2 + positionInMonitor - toastWidth / 2
};

export const getErrorPositionByLocationType = (type) => {
  switch (type) {
    case LOCATION_TYPES.RIGHT_SIDE_HIGH:
      return {
        top: '23%',
      }
    case LOCATION_TYPES.RIGHT_SIDE_MIDDLE:
      return {
        top: '50%',
        bottom: '50%',
      }
    default:
      return {
        top: '50%',
        bottom: '50%',
      }
  }
};
