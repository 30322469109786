import _ from 'lodash';
import { EDITOR_OBJECT_TYPES, BUILDER_TYPES } from './editorObjectTypes';
import Defaults from './editorObjectsDefaults';

const getEmptyEntityWidget = (type) => ({
  type,
  payload: {
    title: _.capitalize(type),
    id: null,
    version: null,
  },
});

const PollWidgets = [
  {
    type: EDITOR_OBJECT_TYPES.QUESTION,
    payload: {
      ...Defaults.QUESTION.payload,
    },
  },
  {
    type: EDITOR_OBJECT_TYPES.MEDIA,
    payload: {
      title: _.capitalize(EDITOR_OBJECT_TYPES.MEDIA),
    },
  },
  {
    type: EDITOR_OBJECT_TYPES.TEXT,
    payload: {
      title: 'Instructions',
      text: '',
      editorPlaceholder: 'Insert Instructions here...',
    },
  },
];


const PracticeWidgets = [
  getEmptyEntityWidget(EDITOR_OBJECT_TYPES.EXERCISE),
];

const SkillWidgets = [
  getEmptyEntityWidget(EDITOR_OBJECT_TYPES.PRACTICE),
  getEmptyEntityWidget(EDITOR_OBJECT_TYPES.LESSON),
  getEmptyEntityWidget(EDITOR_OBJECT_TYPES.POLL),
];

const CourseWidgets = [
  getEmptyEntityWidget(EDITOR_OBJECT_TYPES.SKILL),
];

export const getWidgets = (type) => {
  switch (type) {
    case BUILDER_TYPES.POLL : return PollWidgets;
    case BUILDER_TYPES.PRACTICE : return PracticeWidgets;
    case BUILDER_TYPES.SKILL : return SkillWidgets;
    case BUILDER_TYPES.COURSE : return CourseWidgets;
    case BUILDER_TYPES.EXERCISE:
    case BUILDER_TYPES.LESSON:
    default: return [];
  }
};

