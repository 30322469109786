import {styled} from "@mui/material";

export const Wrapper = styled('div')(
  {
    backgroundColor: '#FFF',
    display: 'grid',
    gridTemplateAreas: `
  "TrialBar"
  "Header"
  "CourseProgress"
  "CourseMainPane"
  `,
  },
  ({ theme }) => ({
    gridTemplateRows: `auto ${theme.palette.novotalk.courseOverviewHeaderHeight}px ${theme.palette.novotalk.courseOverviewProgressBarHeight}px auto`,
  })
);
