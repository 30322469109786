import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import {styled} from "@mui/material";
import Text from '@components/Text';
import progressConsts from '../../helpers/courseProgressConsts';
import courseTypes from '../../helpers/courseTypes';
import {
  IconWraper,
  ClockIconWrapper,
  Clock,
  Lock,
  Checkmark,
  ArrowRight,
  ArrowLeft,
  DurationWrapper,
  PollIcon,
  LessonIcon,
  PracticeIcon,
  TextWrapper,
  StyledContainer,
} from './styles';
import { FeedbackIcon } from '@containers/User/containers/CourseRouter/containers/Practice/components/FeedbackIndicator/styles'
import POPUP_TYPES from '@containers/App/containers/PopUp/types';
import { getPracticeFeedbacks } from '@containers/User/helpers/feedbackHelpers';
import { openPopUp as openPopUpAction } from '@containers/App/containers/PopUp/actions';
import {withTranslation} from "react-i18next";

export const FeedbackWrapper = styled('div')(({language}) => ({
  [language !== 'he' ? 'marginRight' : 'marginLeft']: language === 'he' ? 28 : 20,
  position: 'relative',
  right: language === 'he' ? 12 : -12,
  cursor: 'pointer',
}));

export const FeedbackWrapperEmpty = styled('div')(({language}) => ({
  [language !== 'he' ? 'marginRight' : 'marginLeft']: language !== 'he' ? 32 : 40,
}))


export const Wrapper = styled('div')(props => ({
  display: 'flex',
  margin: '7px 0',
  alignItems: 'center',
  justifyContent: 'space-between',
  textDecoration: 'none',
  position: 'relative',
  bottom: 3,
  right: 4,
  color: props.newFeedback ? '#21C4EA' : '#9C9C9C'
}));

class SkillPart extends Component {
  handleClick(e, isLocked) {
    if (isLocked) {
      e.preventDefault();
    } else if (this.props.close) {
      this.props.close();
    }
  }

  renderDuration(durationString) {
    return (
      <DurationWrapper>
        <ClockIconWrapper>
          <Clock />
        </ClockIconWrapper>
        <Text textColor='disabled' size='H5'>
          {durationString}
        </Text>
      </DurationWrapper>
    );
  }

  renderProgressIcon() {
    const { status, i18n } = this.props;
    const { LOCKED, COMPLETED, CURRENT } = progressConsts;
    switch (status) {
      case LOCKED:
        return <Lock />;
      case COMPLETED:
        return <Checkmark />;
      case CURRENT:
        return i18n.language === 'he' ? <ArrowLeft /> : <ArrowRight />;
        return <ArrowRight />;
        // no default
    }
    return null;
  }

  renderTypeIcon() {
    const { type } = this.props;
    const { POLL, PRACTICE, LESSON } = courseTypes;
    switch (type) {
      case POLL:
        return <PollIcon />;
      case PRACTICE:
        return <PracticeIcon />;
      case LESSON:
        return <LessonIcon />;
      // no default
    }
    return null;
  }

  render() {
    const {
      name,
      status,
      duration,
      type,
      currentlyDisplayed,
      courseIndex,
      skillIndex,
      exerciseIndex,
      skillpartIndex,
      openPopUp,
      feedbacks,
      user,
        t,
        i18n
    } = this.props;
    const { PRACTICE } = courseTypes;
    const durationString = t('Dates.durationInMinutes', {duration: duration});
    const isLocked = status === progressConsts.LOCKED;
    const nameWeight = status === progressConsts.CURRENT ? '600' : '400';
    const textWrapperLink = exerciseIndex ?
      `/course/${courseIndex}/skill${skillIndex}/${type.toLowerCase()}${skillpartIndex}/exercise${exerciseIndex}` :
      `/course/${courseIndex}/skill${skillIndex}/${type.toLowerCase()}${skillpartIndex}`;
    const practiceFeedbacks = feedbacks?.length ? getPracticeFeedbacks(feedbacks, name) : [];
    const hasFeedbacks = practiceFeedbacks.length > 0;
    const showFeedbackIcon = type === PRACTICE && hasFeedbacks && !isLocked;
    const feedBacksReadByUser = practiceFeedbacks.filter((feedback) => feedback.seenBy.includes(user.id));
    const newFeedback = feedBacksReadByUser.length !== practiceFeedbacks.length;

    return (
      <Wrapper
        className='skillPart'
        onClick={(e) => {
          this.handleClick(e, isLocked);
        }}
      >
        <StyledContainer alignItems='flex-start'>
          {showFeedbackIcon ?
            <FeedbackWrapper
                language={i18n.language}
              onClick={() => {
                openPopUp(POPUP_TYPES.CLINICIAN_FEEDBACK, {
                  practiceName: name,
                  feedbacks: practiceFeedbacks,
                  isEnteringPractice: true,
                })
              }
              }
            >
              <Wrapper newFeedback={newFeedback}>
                <FeedbackIcon />
              </Wrapper>
            </FeedbackWrapper> : <FeedbackWrapperEmpty language={i18n.language} />}
          <IconWraper status={status}>{this.renderProgressIcon()}</IconWraper>
          <IconWraper>{this.renderTypeIcon()}</IconWraper>
          <TextWrapper
            isLocked={isLocked || undefined}
            status={status}
            isDisplayed={currentlyDisplayed}
            to={textWrapperLink}
          >
            <Text textTransform='capitalizeFirst' size='H4' weight={nameWeight}>
              {name}
            </Text>
          </TextWrapper>
        </StyledContainer>
        {duration ? this.renderDuration(durationString) : null}
      </Wrapper>
    );
  }
}

SkillPart.propTypes = {
  status: PropTypes.string,
  name: PropTypes.string,
  duration: PropTypes.number,
  close: PropTypes.func,
  type: PropTypes.string,
  currentlyDisplayed: PropTypes.bool,
  courseIndex: PropTypes.number,
  skillIndex: PropTypes.number,
  skillpartIndex: PropTypes.number,
  currentExercise: PropTypes.object,
  feedbacks: PropTypes.array,
  user: PropTypes.object
};

export const mapStateToProps = (state) => {
  const currentPractice = state.getIn(['currentPractice']).toJS();
  const user = state.getIn(['app', 'authentication', 'user']).toJS();
  const { feedbacks } = currentPractice;
  return { feedbacks, user };
};

export function mapDispatchToProps(dispatch) {
  return {
    openPopUp: (type, props) => dispatch(openPopUpAction(type, props)),
  };
}

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default compose(
    withConnect,
    withTranslation()
    )(SkillPart);
