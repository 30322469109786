import { useMemo } from 'react';
import PropTypes from 'prop-types';
import {
  ProgressBarWrapper,
  Progress,
  Mastery,
  PracticeErrorsProgress,
} from './styles';
import { Tooltip } from '@components/Tooltip';
import { ConditionalWrapper } from '@utils/conditionalWrapper';

const ProgressBar = ({
  border,
  height = 5,
  width = 160,
  progress,
  mastery,
  className,
  isPracticeError,
  titles,
}) => {
  const innerHeight = useMemo(() => border ? height - 4 : height, [border, height]);
  const wrapperProps = useMemo(() => ({ border, height, width, isPracticeError }), [border, height, isPracticeError, width]);
  const masteryProps = useMemo(() => ({ mastery, height: innerHeight, progress }), [innerHeight, mastery, progress]);
  const progressProps = useMemo(() => ({
    mastery,
    height: innerHeight,
    progress,
    completed: progress === 100,
    isPracticeError,
    practiceErrorsPercents: 100 - progress,
  }), [innerHeight, isPracticeError, mastery, progress]);

  return (
    <ProgressBarWrapper {...wrapperProps} className={className ? `progressBar ${className}` : 'progressBar'}>
      {Boolean(mastery) && <Mastery {...masteryProps} />}
      <ConditionalWrapper
        condition={isPracticeError}
        wrapper={(children) =>
          <Tooltip
            hasArrow
            content={titles.at(0)}
            placement="bottom"
          >
            {children}
          </Tooltip>
        }
      >
        <Progress {...progressProps} />
      </ConditionalWrapper>
      {isPracticeError &&
        <Tooltip
          hasArrow
          content={titles.at(1)}
          placement="bottom"
        >
          <PracticeErrorsProgress {...progressProps} />
        </Tooltip>
      }
    </ProgressBarWrapper>
  );
};

ProgressBar.propTypes = {
  progress: PropTypes.number,
  border: PropTypes.bool,
  height: PropTypes.number,
  width: PropTypes.number,
  mastery: PropTypes.number,
  className: PropTypes.string,
  isPracticeError: PropTypes.bool,
  titles: PropTypes.array,
};

export default ProgressBar;
