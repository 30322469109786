import React from 'react';

const icon = () => (<svg width="170px" height="160px" viewBox="0 0 170 160" version="1.1" xmlns="http://www.w3.org/2000/svg">
  <defs>
    <path d="M164.6875,79.6875 C164.6875,123.697274 129.011485,159.375 85.0017112,159.375 C40.9902264,159.375 5.3125,123.697274 5.3125,79.6875 C5.3125,35.6777264 40.9902264,0 85.0017112,0 C129.011485,0 164.6875,35.6777264 164.6875,79.6875" id="path-1"></path>
  </defs>
  <g id="Project-Styleguide" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
    <g id="Illustrations" transform="translate(-80.000000, -232.000000)">
      <rect fill="#FFFFFF" x="0" y="0" width="1440" height="520"></rect>
      <g id="children" transform="translate(80.000000, 232.000000)">
        <mask id="mask-2" fill="white">
          <use href="#path-1"></use>
        </mask>
        <use id="Mask" fill="#D5F6FF" href="#path-1"></use>
        <g mask="url(#mask-2)">
          <g transform="translate(6.375000, 24.437500)">
            <g id="Group" strokeWidth="1" fill="none" transform="translate(66.937500, 41.769531)">
              <path d="M2.55199219,18.3331055 C7.39666016,18.1182812 11.2568555,14.1212891 11.2568555,9.22582031 C11.2568555,4.19355469 7.17419922,0.110898437 2.13794922,0.110898437 C1.68472656,0.110898437 1.23548828,0.14609375 0.801523438,0.208515625" id="Path" fill="#E0B394"></path>
            </g>
            <path d="M54.0078711,86.5910937 C63.6450781,88.4677344 70.9205469,96.9594336 70.9205469,107.146152 L70.9205469,134.957754 L0.898808594,134.957754 L0.898808594,107.146152 C0.898808594,96.9591016 8.17427734,88.4677344 17.8114844,86.5910937 L54.0078711,86.5910937 Z" id="Path" fill="#F4AAB6"></path>
            <path d="M26.7178906,86.5910937 L17.8114844,86.5910937 C8.17427734,88.4677344 0.898808594,96.9594336 0.898808594,107.146152 L0.898808594,134.957754 L9.80488281,134.957754 L9.80488281,107.146152 C9.80521484,96.9587695 17.0806836,88.4670703 26.7178906,86.5910937 Z" id="Path" fill="#E28B9C"></path>
            <path d="M55.4385938,87.0469727 C55.4385938,88.0450586 55.3648828,89.0278711 55.2207813,89.9857813 C53.8020117,99.3742969 45.6971289,106.572734 35.912832,106.572734 C26.1285352,106.572734 18.0266406,99.3772852 16.6075391,89.9917578 C16.4631055,89.0305273 16.3864063,88.0477148 16.3864063,87.0466406 C16.3864063,87.0038086 16.3864063,86.9639648 16.3893945,86.9208008 L55.4356055,86.9208008 C55.4385938,86.9639648 55.4385938,87.0041406 55.4385938,87.0469727 Z" id="Path" fill="#F7CBA4"></path>
            <g id="Group" strokeWidth="1" fill="none" transform="translate(11.886719, 111.496094)">
              <path d="M4.88185547,23.4616602 L0.275585937,23.4616602 L0.275585937,2.42449219 C0.275585937,1.15214844 1.306875,0.121191406 2.57888672,0.121191406 C3.85123047,0.121191406 4.8821875,1.15248047 4.8821875,2.42449219 C4.88185547,2.42449219 4.88185547,23.4616602 4.88185547,23.4616602 Z" id="Path" fill="#E28B9C"></path>
              <path d="M47.7706641,23.4616602 L43.1640625,23.4616602 L43.1640625,2.42449219 C43.1640625,1.15214844 44.1953516,0.121191406 45.4673633,0.121191406 C46.739707,0.121191406 47.7706641,1.15248047 47.7706641,2.42449219 L47.7706641,23.4616602 Z" id="Path" fill="#E28B9C"></path>
            </g>
            <path d="M55.4385938,87.0469727 C55.4385938,88.0450586 55.3648828,89.0278711 55.2207813,89.9857813 L47.1590625,95.8424805 C40.4516992,100.713379 31.3673242,100.713379 24.6599609,95.8424805 L16.6075391,89.9920898 C16.4631055,89.0308594 16.3864063,88.0480469 16.3864063,87.0469727 C16.3864063,87.0041406 16.3864063,86.9642969 16.3893945,86.9211328 L55.4356055,86.9211328 C55.4385938,86.9639648 55.4385938,87.0041406 55.4385938,87.0469727 Z" id="Path" fill="#E0B394"></path>
            <path d="M60.2570313,81.4114063 L47.1587305,90.92875 C40.4513672,95.7996484 31.3669922,95.7996484 24.6596289,90.92875 L11.5613281,81.4114063 C3.98769531,75.9076563 -0.0534570312,66.7655078 0.975175781,57.459668 L2.66123047,42.2114648 C2.95308594,39.5641797 3.55173828,37.0151758 4.4146875,34.6013086 L4.4146875,34.5983203 C9.08005859,21.5677539 21.5242578,12.4368945 35.9091797,12.4368945 C44.4344141,12.4368945 52.2783203,15.6433203 58.2240039,20.9684375 C64.1696875,26.2935547 68.2204688,33.7380273 69.1571289,42.2111328 L69.7039844,47.1587305 L70.8431836,57.4590039 C71.8721484,66.7655078 67.8306641,75.9079883 60.2570313,81.4114063 Z" id="Path" fill="#F7CBA4"></path>
            <g id="Group" strokeWidth="1" fill="none" transform="translate(0.597656, 12.218750)">
              <path d="M35.3118555,0.219804688 C18.2603906,0.219804688 3.93822266,13.0461719 2.06423828,29.994375 L0.378515625,45.2425781 C-0.650117188,54.5477539 3.39070313,63.6899023 10.964668,69.1926563 L24.0636328,78.709668 C27.4144922,81.1444531 31.3583594,82.3623438 35.3025586,82.3643359 C32.2910352,82.3616797 29.2805078,81.1437891 26.721875,78.709668 L16.7187695,69.1926563 C10.9351172,63.6899023 7.84921875,54.5477539 8.63480469,45.2425781 L9.92208984,29.994375 C11.3531445,13.0461719 22.2905859,0.219804688 35.3118555,0.219804688" id="Path" fill="#E0B394"></path>
              <path d="M69.1059961,34.9406445 C52.7859961,35.9692773 43.658457,23.1445703 43.5020703,22.8772852 C34.903125,29.8486133 21.6756641,35.2019531 2.61640625,29.9900586 C3.14765625,27.5177539 3.51322266,24.947168 3.81736328,22.3828906 L3.81736328,22.3799023 C8.48240234,9.34933594 20.9266016,0.218476563 35.3115234,0.218476563 C43.8367578,0.218476563 51.6806641,3.42490234 57.6263477,8.75001953 C63.5720312,14.0751367 67.6228125,21.5196094 68.5594727,29.9927148 L69.1059961,34.9406445 Z" id="Path" fill="#E0B394"></path>
            </g>
            <path d="M55.2788867,18.6163281 C54.8153711,19.4055664 54.2685156,20.2871094 53.6326758,21.2267578 C52.5638672,22.811543 51.2433789,24.5709766 49.6556055,26.3679297 C48.0771289,28.1582422 46.2313672,29.9887305 44.1000586,31.7176172 C36.677168,37.7340234 25.808457,42.5464844 10.7139844,40.3969141 C8.32136719,40.0589062 5.82150391,39.5432617 3.21439453,38.8307227 C6.19669922,24.9494922 17.6790039,14.4642773 31.7113086,12.7045117 C32.8906836,12.5541016 34.0883203,12.4677734 35.3015625,12.4465234 C35.3938672,12.4435352 35.4858398,12.4402148 35.5811328,12.4435352 C35.6887109,12.4405469 35.7989453,12.4405469 35.9095117,12.4405469 C40.5898242,12.4405469 45.0642773,13.4080859 49.1339844,15.1582227 C51.3107813,16.0948828 53.3687109,17.2589844 55.2788867,18.6163281 Z" id="Path" fill="#FEC95B"></path>
            <g id="Group" strokeWidth="1" fill="none" transform="translate(29.152344, 12.218750)">
              <path d="M40.1664844,31.4692578 C23.1249805,33.1951562 15.1060938,19.7714648 14.9463867,19.4982031 C20.4836719,15.0051562 24.1011523,9.84273437 26.1255469,6.39691406 C27.1511914,7.12804687 28.1340039,7.91429687 29.0706641,8.75234375 C35.0193359,14.0774609 39.0671289,21.5219336 40.0037891,29.9950391 L40.1664844,31.4692578 Z" id="Path" fill="#EAAE44"></path>
              <path d="M24.480332,9.00833984 C23.4115234,10.593125 22.0910352,12.3525586 20.5032617,14.1495117 L17.9788281,12.3100586 L16.0716406,12.1075195 L4.80083984,10.9065625 C0.74375,10.4735938 -1.32613281,5.81154297 1.0784375,2.51646484 L2.55863281,0.486425781 C3.93457031,0.311445313 5.33507812,0.222460938 6.75650391,0.222460938 C11.4368164,0.222460938 15.9112695,1.19 19.9809766,2.94013672 L21.0311914,5.30818359 L21.8084766,7.06164063 L24.480332,9.00833984 Z" id="Path" fill="#EAAE44"></path>
            </g>
            <path d="M77.1086133,33.2499414 L70.8803711,41.7941016 C68.4787891,45.0895117 63.4083398,44.5489648 61.752832,40.8205859 L57.1555273,30.4552344 L56.3782422,28.7017773 L47.2845703,22.0714453 L45.3773828,21.8689062 L34.106582,20.6679492 C30.0494922,20.2349805 27.9796094,15.5729297 30.3841797,12.2778516 L36.6091016,3.73103516 C39.0136719,0.435625 44.0841211,0.976171875 45.7392969,4.70455078 L50.3369336,15.0695703 L51.1142187,16.8230273 L60.2045703,23.4533594 L62.1147461,23.6558984 L73.3855469,24.8598437 C77.445957,25.2898242 79.5131836,29.9548633 77.1086133,33.2499414 Z" id="Path" fill="#D2B4EF"></path>
            <path d="M62.1150781,23.6562305 C61.7923438,26.7029492 59.8456445,29.2642383 57.1551953,30.4555664 L56.3779102,28.7021094 L47.2842383,22.0717773 L45.3770508,21.8692383 C45.6994531,18.8225195 47.6464844,16.2612305 50.3369336,15.0699023 L51.1142188,16.8233594 L60.2045703,23.4536914 L62.1150781,23.6562305 Z" id="Path" fill="#B99ED8"></path>
            <circle id="Oval" fill="#D2B4EF" cx="53.7445703" cy="22.7647266" r="6.49751953"></circle>
            <path d="M50.3103711,22.7627344 C50.3103711,19.7024023 52.4287305,17.1434375 55.2768945,16.4541406 C54.7851563,16.3352734 54.2741602,16.2652148 53.7462305,16.2652148 C50.1579688,16.2652148 47.2487109,19.1741406 47.2487109,22.7627344 C47.2487109,26.3509961 50.1576367,29.2602539 53.7462305,29.2602539 C54.2748242,29.2602539 54.7854883,29.1901953 55.2768945,29.0713281 C52.4287305,28.3823633 50.3103711,25.8230664 50.3103711,22.7627344 Z" id="Path" fill="#B99ED8"></path>
            <path d="M25.4664648,74.2455078 C26.0661133,74.3278516 26.5903906,74.4410742 27.0824609,74.5599414 C27.5991016,74.6472656 28.0851953,74.7349219 28.5410742,74.8332031 C29.0082422,74.9142188 29.4730859,74.9636914 29.8974219,75.0513477 C30.3386914,75.108457 30.7723242,75.1479688 31.1787305,75.2196875 C32.8458594,75.4102734 34.3732031,75.500918 35.9085156,75.5002539 C37.4438281,75.4939453 38.9698437,75.4036328 40.6349805,75.2117188 C41.0407227,75.1393359 41.4743555,75.1011523 41.915625,75.044043 C42.3396289,74.9557227 42.8048047,74.9082422 43.2716406,74.8268945 C43.7278516,74.7299414 44.2139453,74.6426172 44.73125,74.5562891 C45.2239844,74.438418 45.7492578,74.3261914 46.3502344,74.2455078 C46.2343555,74.8122852 46.0431055,75.3485156 45.8189844,75.8784375 C45.5736133,76.3841211 45.2698047,76.8552734 44.9510547,77.3350586 C44.6074023,77.7779883 44.2255664,78.1986719 43.8224805,78.6087305 C43.4067773,78.9962109 42.9502344,79.3375391 42.4833984,79.6801953 C40.5775391,80.9694727 38.239707,81.6454883 35.9078516,81.6411719 C33.5769922,81.6378516 31.2398242,80.9625 29.335957,79.6715625 C28.8701172,79.3279102 28.4129102,78.9882422 27.9978711,78.6007617 C27.5951172,78.189707 27.2126172,77.7710156 26.8692969,77.3280859 C26.5502148,76.8492969 26.2460742,76.3791406 26.0003711,75.8744531 C25.775918,75.3458594 25.5836719,74.810957 25.4664648,74.2455078 Z" id="Path" fill="#FFFFFF"></path>
            <path d="M37.8545508,66.3843359 L33.9644727,66.3843359 C32.6921289,66.3843359 31.6611719,65.3530469 31.6611719,64.0810352 C31.6611719,62.8090234 32.6924609,61.7777344 33.9644727,61.7777344 L37.8545508,61.7777344 C39.1268945,61.7777344 40.1578516,62.8090234 40.1578516,64.0810352 C40.1578516,65.3530469 39.1268945,66.3843359 37.8545508,66.3843359 Z" id="Path" fill="#E0B394"></path>
            <path d="M31.7113086,12.7045117 C32.8906836,12.5541016 34.0883203,12.4677734 35.3015625,12.4465234 C23.0170703,12.824043 12.7101562,24.6171289 10.7143164,40.3969141 C8.32169922,40.0589063 5.82183594,39.5432617 3.21472656,38.8307227 C6.19636719,24.9488281 17.6793359,14.4642773 31.7113086,12.7045117 Z" id="Path" fill="#EAAE44"></path>
            <g id="Group" strokeWidth="1" fill="none" transform="translate(15.207031, 53.058594)">
              <path d="M39.6392187,5.45162109 C39.2464258,5.45162109 38.8533008,5.301875 38.5534766,5.00205078 C37.4368555,3.88542969 35.9523437,3.27050781 34.3728711,3.27050781 C32.7933984,3.27050781 31.3095508,3.88542969 30.1922656,5.00205078 C29.5932812,5.60169922 28.6200977,5.60169922 28.0207812,5.00205078 C27.4208008,4.40240234 27.4208008,3.43021484 28.0207812,2.83023438 C29.7174609,1.13355469 31.9732812,0.19921875 34.3725391,0.19921875 C36.7721289,0.19921875 39.0279492,1.13355469 40.7242969,2.83023438 C41.3242773,3.42988281 41.3242773,4.40207031 40.7242969,5.00205078 C40.4258008,5.30154297 40.0323438,5.45162109 39.6392187,5.45162109 Z" id="Path" fill="#C99F83"></path>
              <path d="M12.2964453,5.45162109 C11.9036523,5.45162109 11.5105273,5.301875 11.2107031,5.00205078 C8.90574219,2.69708984 5.15511719,2.69708984 2.85015625,5.00205078 C2.25017578,5.60169922 1.27832031,5.60169922 0.678339844,5.00205078 C0.0786914063,4.40240234 0.0786914063,3.43021484 0.678339844,2.83023438 C4.1809375,-0.672363281 9.87958984,-0.672363281 13.3821875,2.83023438 C13.9818359,3.42988281 13.9818359,4.40207031 13.3821875,5.00205078 C13.0823633,5.30154297 12.6892383,5.45162109 12.2964453,5.45162109 Z" id="Path" fill="#C99F83"></path>
            </g>
            <g id="Group" strokeWidth="1" fill="none" transform="translate(83.273438, 40.773438)">
              <path d="M7.15460938,14.3391016 C3.34654297,14.1704297 0.315429687,11.028418 0.315429687,7.18017578 C0.315429687,3.2246875 3.52185547,0.0152734375 7.48033203,0.0152734375 C7.83660156,0.0152734375 8.18689453,0.03984375 8.53054688,0.0923046875" id="Path" fill="#E0B394"></path>
            </g>
            <path d="M157.046133,102.538555 L157.046133,134.95709 L87.0247266,134.95709 L87.0247266,102.538555 C87.0247266,93.4236328 92.8505469,85.6657227 100.98,82.7943164 C101.483691,82.6130273 101.99668,82.4533203 102.515645,82.3151953 L102.515645,82.312207 C102.98248,82.1863672 103.458281,82.0754688 103.937734,81.9838281 L140.133789,81.9838281 C140.61623,82.0791211 141.091699,82.1863672 141.561855,82.3155273 C142.08082,82.4569727 142.593809,82.6166797 143.0975,82.7946484 C151.223301,85.669043 157.046133,93.4266211 157.046133,102.538555 Z" id="Path" fill="#F4942D"></path>
            <path d="M112.843809,81.982832 L103.937734,81.982832 C94.3005273,83.8594727 87.0250586,92.3511719 87.0250586,102.537891 L87.0250586,134.956094 L95.9311328,134.956094 L95.9311328,102.537891 C95.9314648,92.3511719 103.206602,83.8594727 112.843809,81.982832 Z" id="Path" fill="#E98418"></path>
            <path d="M141.564844,82.4413672 C141.564844,82.9882227 141.543262,83.5317578 141.497441,84.0659961 C140.671348,94.0903516 132.274941,101.967461 122.03875,101.967461 C111.802559,101.967461 103.406484,94.0900195 102.583047,84.0689844 C102.533906,83.5314258 102.512324,82.9912109 102.512324,82.4410352 C102.512324,82.3982031 102.512324,82.3583594 102.515313,82.3151953 L102.515313,82.312207 C102.982148,82.1863672 103.457949,82.0754688 103.937402,81.9838281 L140.133457,81.9838281 C140.615898,82.0791211 141.091367,82.1863672 141.561523,82.3155273 C141.564844,82.3583594 141.564844,82.3985352 141.564844,82.4413672 Z" id="Path" fill="#F7CBA4"></path>
            <path d="M141.564844,82.4413672 C141.564844,82.9882227 141.543262,83.5317578 141.497441,84.0659961 L132.400781,90.6720898 C126.218691,95.1621484 117.849844,95.1621484 111.670742,90.6720898 L102.583379,84.0689844 C102.534238,83.5314258 102.512656,82.9912109 102.512656,82.4410352 C102.512656,82.3982031 102.512656,82.3583594 102.515645,82.3151953 L102.515645,82.312207 C102.98248,82.1863672 103.458281,82.0754688 103.937734,81.9838281 L140.133789,81.9838281 C140.61623,82.0791211 141.091699,82.1863672 141.561855,82.3155273 C141.564844,82.3583594 141.564844,82.3985352 141.564844,82.4413672 Z" id="Path" fill="#E0B394"></path>
            <path d="M145.007344,78.4427148 L132.400449,87.6008008 C126.218359,92.0908594 117.849512,92.0908594 111.67041,87.6008008 L99.0635156,78.4427148 C92.4023047,73.6026953 88.7967773,65.5964258 89.5890039,57.3995703 L91.4991797,37.6460352 L91.7757617,34.7746289 C93.2805273,19.18875 106.378828,7.29738281 122.03543,7.29738281 C129.86373,7.29738281 137.050215,10.2703906 142.470625,15.1900977 C146.45998,18.8108984 149.488105,23.4882227 151.109746,28.8103516 C151.693125,30.7205273 152.095547,32.7170312 152.295098,34.7742969 L154.478535,57.3992383 C155.271094,65.5960937 151.668555,73.6026953 145.007344,78.4427148 Z" id="Path" fill="#F7CBA4"></path>
            <g id="Group" strokeWidth="1" fill="none" transform="translate(89.250000, 7.238281)">
              <path d="M8.18390625,54.6221289 C7.97173828,58.2060742 9.13916016,61.731582 11.4484375,64.4804688 L24.6244336,80.6713086 C25.3120703,81.4914258 26.0923437,82.1790625 26.9370312,82.7289063 C25.3522461,82.1764063 23.8288867,81.3838477 22.4194141,80.3611914 L9.81251953,71.2031055 C3.15130859,66.3630859 -0.451230469,58.3594727 0.341328125,50.1626172 L2.52476563,27.5346875 C4.01757813,12.102207 16.8731641,0.290859375 32.3298828,0.0667382812 C22.8095508,0.297167969 12.0288281,9.27097656 11.4666992,18.8281641 L8.18390625,54.6221289 Z" id="Path" fill="#E0B394"></path>
              <path d="M61.8597461,21.5720703 C56.7341797,19.4683203 50.3585156,18.5625391 43.3563086,21.3970898 C34.6467969,24.9199414 28.6151172,24.6463477 24.1097852,22.6747461 C24.0081836,22.6256055 23.9039258,22.5794531 23.799668,22.5303125 C23.7013867,22.4874805 23.6064258,22.4413281 23.5111328,22.3951758 C22.5960547,21.9529102 21.7450586,21.4372656 20.9498437,20.8721484 C20.9405469,20.9000391 20.9282617,20.9275977 20.9163086,20.9548242 C17.4767969,29.7841992 2.47728516,30.7945703 2.47728516,30.7945703 C2.47728516,30.7945703 2.39128906,30.6594336 2.24984375,30.4074219 L2.52642578,27.5360156 C4.03119141,11.9501367 17.1294922,0.0587695313 32.7860937,0.0587695313 C40.6143945,0.0587695313 47.8008789,3.03177734 53.2212891,7.95148438 C57.2099805,11.5726172 60.2381055,16.2496094 61.8597461,21.5720703 Z" id="Path" fill="#E0B394"></path>
            </g>
            <path d="M112.546309,0.112890625 C112.546309,0.112890625 96.8193164,16.5985742 110.166309,25.4282813 C106.726797,34.2576563 91.7272852,35.2680273 91.7272852,35.2680273 C91.7272852,35.2680273 85.0046484,24.5404297 88.6898633,16.2486133 C91.6100781,9.67339844 99.3586914,2.71435547 112.546309,0.112890625 Z" id="Path" fill="#4D4D4D"></path>
            <g id="Group" strokeWidth="1" fill="none" transform="translate(86.925781, 119.796875)">
              <rect id="Rectangle" fill="#F7CBA4" x="56.5508984" y="0.112558594" width="13.568457" height="15.0483203"></rect>
              <rect id="Rectangle" fill="#F7CBA4" x="0.0976171875" y="0.112558594" width="13.5648047" height="15.0483203"></rect>
            </g>
            <rect id="Rectangle" fill="#E0B394" x="87.0233984" y="119.909434" width="8.90607422" height="15.0483203"></rect>
            <path d="M108.363047,58.1214062 C107.090703,58.1214062 106.059746,57.0901172 106.059746,55.8181055 L106.059746,52.1338867 C106.059746,50.861543 107.091035,49.8305859 108.363047,49.8305859 C109.635059,49.8305859 110.666348,50.861875 110.666348,52.1338867 L110.666348,55.8181055 C110.666348,57.0901172 109.635059,58.1214062 108.363047,58.1214062 Z" id="Path" fill="#4D4D4D"></path>
            <g id="Group" strokeWidth="1" fill="none" transform="translate(101.203125, 39.113281)">
              <path d="M39.769043,5.95730469 C39.2972266,5.95730469 38.8257422,5.77734375 38.4661523,5.41742187 C37.4076367,4.35890625 36.0004883,3.77585938 34.5033594,3.77585938 C33.0065625,3.77585938 31.59875,4.35890625 30.5405664,5.41742187 C29.8210547,6.13693359 28.6536328,6.13693359 27.9344531,5.41742187 C27.2149414,4.69791016 27.2149414,3.53115234 27.9341211,2.81130859 C29.6889063,1.05685547 32.0214258,0.0903125 34.5030273,0.0903125 C36.9849609,0.0903125 39.3174805,1.05685547 41.0722656,2.81130859 C41.7917773,3.53082031 41.7917773,4.69757813 41.0719336,5.41742187 C40.7123438,5.77767578 40.2408594,5.95730469 39.769043,5.95730469 Z" id="Path" fill="#666666"></path>
              <path d="M12.4259375,5.95730469 C11.9541211,5.95730469 11.4826367,5.77734375 11.1230469,5.41742188 C8.93828125,3.23232422 5.38222656,3.23232422 3.19679687,5.41742188 C2.47728516,6.13693359 1.31052734,6.13693359 0.590683594,5.41742188 C-0.128828125,4.69791016 -0.128828125,3.53115234 0.590683594,2.81130859 C4.21314453,-0.811152344 10.1066992,-0.810488281 13.7288281,2.81130859 C14.4483398,3.53082031 14.4483398,4.69757813 13.7288281,5.41742188 C13.3689063,5.77767578 12.8977539,5.95730469 12.4259375,5.95730469 Z" id="Path" fill="#666666"></path>
            </g>
            <g id="Group" strokeWidth="1" fill="none" transform="translate(113.488281, 49.738281)">
              <path d="M22.2182031,8.383125 C20.9458594,8.383125 19.9149023,7.35183594 19.9149023,6.07982422 L19.9149023,2.39560547 C19.9149023,1.12326172 20.9458594,0.0923046875 22.2182031,0.0923046875 C23.4905469,0.0923046875 24.5215039,1.12359375 24.5215039,2.39560547 L24.5215039,6.07982422 C24.5215039,7.35183594 23.4902148,8.383125 22.2182031,8.383125 Z" id="Path" fill="#4D4D4D"></path>
              <path d="M0.177304687,22.58875 C1.95300781,22.992832 3.35152344,23.3119141 4.70820313,23.4842383 C6.04994141,23.6651953 7.29505859,23.7531836 8.54615234,23.7521875 C9.79724609,23.7475391 11.0410352,23.6598828 12.3817773,23.4779297 C13.7374609,23.3052734 15.1349805,22.9885156 16.915,22.58875 C16.4996289,24.3764063 15.2764258,25.81875 13.7683398,26.8291211 C12.2473047,27.8341797 10.3942383,28.3607813 8.54648438,28.358125 C6.69873047,28.3554688 4.84699219,27.8291992 3.32662109,26.8231445 C1.81953125,25.8117773 0.596660156,24.3717578 0.177304687,22.58875 Z" id="Path" fill="#4D4D4D"></path>
            </g>
            <path d="M119.599648,65.3384375 L123.597637,64.2729492 C124.829141,63.9449023 125.56127,62.6805273 125.233223,61.4490234 L122.508906,51.223457" id="Path" fill="#E0B394"></path>
            <g id="Group" strokeWidth="1" fill="none" transform="translate(97.218750, 81.945312)">
              <path d="M44.3427734,0.370214844 C43.8729492,0.241386719 43.3968164,0.133476562 42.914707,0.038515625 L42.8071289,0.038515625 L42.8071289,0.370214844 C42.8071289,0.407070312 42.8071289,0.422675781 42.8101172,0.462519531 L42.8101172,0.496386719 C42.8101172,10.4158203 34.7394336,18.4868359 24.819668,18.4868359 C14.8969141,18.4868359 6.82921875,10.4158203 6.82921875,0.496386719 L6.82921875,0.462519531 C6.82921875,0.428984375 6.83220703,0.394785156 6.83220703,0.360917969 L6.83220703,0.038515625 L6.71865234,0.038515625 C6.23953125,0.130820313 5.76339844,0.241054688 5.2965625,0.366894531 L5.2965625,0.369882813 C4.77759766,0.508007813 4.26460938,0.667714844 3.76091797,0.849003906 C3.95150391,12.2984375 13.3210938,21.5574609 24.819668,21.5574609 C36.3145898,21.5574609 45.687832,12.2981055 45.878418,0.849003906 C45.3747266,0.671035156 44.8620703,0.511328125 44.3427734,0.370214844 Z" id="Path" fill="#E98418"></path>
              <polygon id="Path" fill="#E98418" points="3.37177734 37.9621289 3.37177734 22.7620703 0.248027344 37.9621289"></polygon>
              <polygon id="Path" fill="#EA7653" points="46.2595898 37.9621289 46.2595898 22.7620703 49.3830078 37.9621289"></polygon>
            </g>
          </g>
        </g>
        <g id="Group-4" transform="translate(0.000000, 23.375000)">
          <path d="M8.70087891,61.1651367 C3.85621094,60.9503125 2.89546165e-13,56.9536523 2.89546165e-13,52.0578516 C2.89546165e-13,47.0255859 4.07900391,42.9429297 9.11492188,42.9429297 C9.56814453,42.9429297 10.0137305,42.9741406 10.4513477,43.0405469" id="Path" fill="#E0B394"></path>
          <path d="M160.013164,56.1750391 C163.82123,56.0063672 166.855664,52.8643555 166.855664,49.0161133 C166.855664,45.060625 163.646582,41.8512109 159.687441,41.8512109 C159.331172,41.8512109 158.978223,41.8787695 158.637227,41.9282422" id="Path" fill="#E0B394"></path>
          <path d="M170,36.0449805 C170,36.0449805 156.44084,19.8694141 138.981973,26.9330469 C130.272461,30.4558984 124.240781,30.1823047 119.735449,28.2107031 C119.633848,28.1615625 119.52959,28.1154102 119.425332,28.0662695 C119.327051,28.0234375 119.23209,27.9772852 119.136797,27.9311328 C118.221719,27.4888672 117.370723,26.9732227 116.575508,26.4081055 C116.566211,26.4356641 116.553926,26.4635547 116.541641,26.4907812 C113.102129,35.3201562 98.1026172,36.3305273 98.1026172,36.3305273 C98.1026172,36.3305273 91.3799805,25.6029297 95.0651953,17.3111133 C97.9857422,10.7358984 105.734355,3.77652344 118.921641,1.17572266 C122.367461,0.493730469 126.184824,0.112890625 130.383027,0.112890625 C150.651875,0.112558594 170,13.6255664 170,36.0449805 Z" id="Path" fill="#666666"></path>
        </g>
      </g>
    </g>
  </g>
</svg>);

export default icon;

