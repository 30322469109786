import { useTranslation } from 'react-i18next';
import { PracticeSubTitle } from '../practice-sub-title';
import { PracticeErrorsCard } from './components/practice-errors-card';
import { ActivityTitle, SkillTitle } from './styles';

/**
 * Practice activity panel component
 */
export const PracticeActivityPanel = ({ activity }) => {
  const { t } = useTranslation();
  return (
    <>
      <ActivityTitle size="H3">
        {t('UI.typeAndTitle', {
          type: activity.type.split('_').at(1),
          title: activity?.practice?.name,
        })}
      </ActivityTitle>
      <SkillTitle size="H4">
        {t('UI.skillTitle', {
          title: activity?.skill?.name,
        })}
      </SkillTitle>
      <PracticeSubTitle
        status={activity.status}
        id={activity.id}
      />
      {activity.charts.length > 0 && activity.charts.map((charts) => (
        <PracticeErrorsCard data={charts} />
      ))}
    </>
  );
};
