import { styled } from '@mui/material';

export const StyledLoader = styled('div')(({ theme }) => ({
  width: 48,
  height: 48,
  border: `5px solid ${theme.palette.novotalk.$novotalkBlue}`,
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  padding: 10,
  borderBottomColor: 'transparent',
  borderRadius: '50%',
  display: 'inline-block',
  boxSizing: 'border-box',
  animation: 'rotation 1s linear infinite',
  '@keyframes rotation': {
    '0%': {
      transform: 'rotate(0deg)',
    },
    '100%': {
      transform: 'rotate(360deg)',
    },
  },
}));
