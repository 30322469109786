import { fromJS } from 'immutable';
import { Types } from './actions';
import { Types as uplodaTypes } from '@containers/App/containers/PopUp/popups/MediaUpload/actions';
// The initial state of the App
export const initialState = fromJS({
  items: [],
  loadingItems: false,
});

function pendingDashboardReducer(state = initialState, action) {
  switch (action.type) {
    case Types.GET_MEDIA_ITEMS:
      return state.set('loadingItems', true);
    case Types.SET_MEDIA_ITEMS:
      return state.set('loadingItems', false)
        .set('items', fromJS(action.items));
    case uplodaTypes.SET_UPLOAD_STATUS : {
      const { sentItem } = action.payload;
      const items = state.get('items').toJS();
      if (sentItem) items.push(sentItem);
      return state.set('items', fromJS(items));
    }
    default:
      return state;
  }
}

export default pendingDashboardReducer;
