import React from 'react';
import { toggleShowMonitorVideoChat } from '../../actions';
import {
  ControlsIconWrapper,
  StyledImage
} from '../../styles';
import MonitorIcon from './monitor-icon.svg';
import { useDispatch } from 'react-redux';

export const VideoChatMonitorBtn = () => {
  const dispatch = useDispatch();

  const handleToggleShowMonitor = () => {
    dispatch(toggleShowMonitorVideoChat(true, true))
  }

  return (
    <ControlsIconWrapper onClick={handleToggleShowMonitor}>
      <StyledImage src={MonitorIcon} />
    </ControlsIconWrapper>
  );
};
