import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { ROUTES } from '@shared/constants';
import { LOCAL_STORAGE_ITEM, getFromLocalStorage, setToLocalStorage } from '@utils/localStorageHelper';

const ONE_DAY = 24 * 60 * 60 * 1000;

const protectedCheck = (roles, user) => {
  if (!user || !user.token || !user.roles) {
    return false;
  }
  if (!roles.length) {
    return true;
  }
  const rolesObj = {};
  for (const role of roles) {
    rolesObj[role] = role;
  }
  for (const role of user.roles) {
    if (rolesObj[role]) {
      return true;
    }
  }
  return false;
};

const refreshCheck = () => {
  const refreshDate = getFromLocalStorage(LOCAL_STORAGE_ITEM.REFRESH_TIME);
  if (!refreshDate) {
    setToLocalStorage(LOCAL_STORAGE_ITEM.REFRESH_TIME, new Date().getTime());
  } else {
    const oneDay = refreshDate + ONE_DAY;
    const now = new Date().getTime();
    if (now >= oneDay) {
      setToLocalStorage(LOCAL_STORAGE_ITEM.REFRESH_TIME, new Date().getTime());
      window.location.reload(true);
    }
  }
};

export const ProtectedRoute = ({
  component: Component,
  roles,
  user,
  ...rest
}) => (
  <Route
    {...rest}
    render={(props) => {
      refreshCheck();
      if (protectedCheck(roles, user)) {
        return <Component {...props} />;
      }
      return (
        <Redirect
          to={{
            pathname: user.token ? ROUTES.HOME : ROUTES.SIGN_IN,
            state: { from: props.location },
          }}
        />
      );
    }}
  />
);

ProtectedRoute.propTypes = {
  location: PropTypes.object,
  component: PropTypes.func,
  user: PropTypes.object,
};

export const mapStateToProps = (state) => {
  const user = state.getIn(['app', 'authentication', 'user']).toJS();
  return { user };
};

const withConnect = connect(mapStateToProps);

export default compose(withConnect)(ProtectedRoute);
