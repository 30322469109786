import {styled} from "@mui/material";

export const DescriptionArea = styled('div')(
  {
    paddingBottom: 15,
    marginBottom: 15,
    lineHeight: '19px',
  },
  ({ theme }) => ({
    borderBottom: `1px solid ${theme.palette.novotalk.$resourcesTableBorderColor}`,
  })
);

export const TitleContainer = styled('div')({
  marginBottom: 10,
});
