import { fromJS } from 'immutable';
import { TYPES } from './actions';

export const initialState = fromJS({
  searchingForExistingName: false,
  existingName: false,
});

function nameCheckerReducer(state = initialState, action) {
  switch (action.type) {
    case TYPES.CHECK_FOR_EXISTING_NAME: return state
      .set('searchingForExistingName', true);
    case TYPES.CHECK_FOR_EXISTING_NAME_COMPLETE: {
      const { nameExists } = action.payload;
      return state
        .set('existingName', nameExists)
        .set('searchingForExistingName', false);
    }
    case TYPES.CHECK_FOR_EXISTING_NAME_ERROR: return state
      .set('existingName', true)
      .set('searchingForExistingName', false);
    default:
      return state;
  }
}

export default nameCheckerReducer;
