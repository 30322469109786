import {styled} from "@mui/material";
import { getIconsByType, iconTypes } from "@services/icons";

const OtherPracticeErrors = getIconsByType(iconTypes.PRACTICE_ERRORS);

export const OtherErrorsIcon = styled(OtherPracticeErrors)({
  fontSize: 15,
  marginLeft: 9,
  cursor: 'pointer',
},
({ theme }) => ({
  color: theme.palette.novotalk.$textColorLightGray,
  '&:hover': {
    color: theme.palette.novotalk.$secondaryButtonColorHover,
  },
}));
