export const TYPES = {
  BILLING_START: 'BILLING_START',
  BILLING_SUCCESS: 'BILLING_SUCCESS',
  BILLING_FAILURE: 'BILLING_FAILURE',
  BILLING_RESET: 'BILLING_RESET',
  GET_PLANS: 'GET_PLANS',
  SET_PLANS: 'SET_PLANS',
  SET_INVALID_COUPON_ERROR: 'SET_INVALID_COUPON_ERROR',
  BILLING_LOADING_HANDLE: 'BILLING_LOADING_HANDLE',
};

export const billingAction = (stripeToken, couponCode, planId) => ({
  type: TYPES.BILLING_START,
  payload: { stripeToken, couponCode, planId },
});

export const billingSuccessAction = (message) => ({
  type: TYPES.BILLING_SUCCESS,
  payload: { message },
});

export const billingFailAction = (error) => ({
  type: TYPES.BILLING_FAILURE,
  payload: { error },
});

export const billingResetAction = () => ({
  type: TYPES.BILLING_RESET,
});

export const getPlans = (entityId, couponCode) => ({
  type: TYPES.GET_PLANS,
  entityId,
  couponCode,
});

export const setPlans = (plans, discounted) => ({
  type: TYPES.SET_PLANS,
  plans,
  discounted,
});

export const setInvalidCouponError = (error) => ({
  type: TYPES.SET_INVALID_COUPON_ERROR,
  error,
});
export const billingLoadingHandleAction = (loading) => ({
  type: TYPES.BILLING_LOADING_HANDLE,
  loading,
});
