import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import Text, { TEXT_SIZE, TEXT_COLOR } from '@components/Text';
import Collapse from '@components/Collapse';
import Container from '@components/Container';
import TextButton from '@components/TextButton';
import { ROUTES } from '~/shared/constants';
import _ from 'lodash';
import progressConsts from '../../../../../../../../helpers/courseProgressConsts';
import {
  Wrapper,
  CardWrapper,
  Header,
  Description,
  ToggleWrapper,
  LearnWrapper,
  PracticeWrapper,
  LockWrapper,
  StyledChevronDown,
  StyledChevronUp,
  StyledLockIcon,
  UpgradeMessage,
} from './styles';
import ProgressBar from '@components/ProgressBar';
import SkillPart from '../../../../../../../../components/SkillPart';
import courseTypes from '../../../../../../../../helpers/courseTypes';
import { getUserFeedbacks } from '@containers/User/containers/CourseRouter/containers/Practice/actions';
import {withTranslation} from "react-i18next";

export class CourseSkill extends Component {
  constructor(props) {
    super(props);
    this.state = {
      collapsed: false, // props.status === progressConsts.COMPLETED,
    };
  }

  componentDidMount() {
    const {
      dispatchGetUserFeedbacks
    } = this.props;
    dispatchGetUserFeedbacks();
  }

  getUpgradeMessage = () => {
    const {t} = this.props;
    return (
      <UpgradeMessage>
        <Link to={ROUTES.BILLING}>
          <TextButton underline>
            <Text size={TEXT_SIZE.T4} margin='0 5px 0 0'>
              {t("Actions.General.upgradeNow")}
            </Text>
          </TextButton>
        </Link>
        <Text size={TEXT_SIZE.T4} textColor={TEXT_COLOR.DISABLED}>
          {t("Informative.Notifying.unlockCourse")}
        </Text>
      </UpgradeMessage>
    );
  };

  renderSkillParts(type) {
    const { courseIndex, skillIndex, exerciseIndex } = this.props;
    const partKey = `${_.capitalize(type)}s`;
    return this.props[partKey].map((part, index) => (
      <SkillPart
        key={part.id}
        {...part}
        skillIndex={skillIndex}
        exerciseIndex={exerciseIndex}
        courseIndex={courseIndex}
        skillpartIndex={index}
      />
    ));
  }

  renderProgressBar(isLocked) {
    const { progress } = this.props;

    if (isLocked) return null;
    return (
      <Container>
        <ProgressBar progress={progress} />
        <Text textColor={TEXT_COLOR.DARK_BLUE} size='T4'>{`${progress}%`}</Text>
      </Container>
    );
  }

  renderHeader() {
    const { collapsed } = this.state;
    const { status, free, hasUpgradeMessage } = this.props;
    const { COMPLETED, LOCKED } = progressConsts;

    const isLocked = status === LOCKED;
    let colorName;
    switch (status) {
      case COMPLETED:
        colorName = TEXT_COLOR.COMPLETED;
        break;
      case LOCKED:
        colorName = TEXT_COLOR.DISABLED_LIGHT;
        break;
      default:
        colorName = TEXT_COLOR.ACTIVE;
        break;
    }

    const toggleIcon = collapsed ? <StyledChevronUp /> : <StyledChevronDown />;
    return (
      <Header
        className='skillHeader'
        collapsed={collapsed}
        locked={isLocked}
        isFree={free}
        onClick={() => this.setState({ collapsed: !collapsed })}
      >
        <Container>
          <LockWrapper show={status === progressConsts.LOCKED}>
            <StyledLockIcon />
          </LockWrapper>
          <Text
            textColor={colorName}
            size='T4'
            textTransform='uppercase'
            weight='900'
          >
            {this.props.name}
          </Text>
        </Container>
        <Container>
          {hasUpgradeMessage && this.getUpgradeMessage()}
          {this.renderProgressBar(isLocked)}
          <ToggleWrapper>{toggleIcon}</ToggleWrapper>
        </Container>
      </Header>
    );
  }

  render() {
    const { collapsed } = this.state;
    const { description } = this.props.texts;
    const { POLL, PRACTICE, LESSON } = courseTypes;
    return (
      <Wrapper className='skillWrapper'>
        {this.renderHeader()}
        <Collapse isCollapsed={collapsed}>
          <CardWrapper>
            <Description>
              <Text size='T3'>{description}</Text>
            </Description>
            <LearnWrapper className='learnWrapper'>
              {this.renderSkillParts(LESSON)}
            </LearnWrapper>
            <PracticeWrapper className='practiceWrapper'>
              {this.renderSkillParts(PRACTICE)}
              {this.renderSkillParts(POLL)}
            </PracticeWrapper>
          </CardWrapper>
        </Collapse>
      </Wrapper>
    );
  }
}

CourseSkill.propTypes = {
  texts: PropTypes.object,
  name: PropTypes.string,
  progress: PropTypes.number,
  status: PropTypes.string,
  free: PropTypes.bool,
  skillIndex: PropTypes.number,
  exerciseIndex: PropTypes.number,
  courseIndex: PropTypes.number,
  practice: PropTypes.object,
  currentExercise: PropTypes.object,
  hasUpgradeMessage: PropTypes.bool,
  // eslint-disable-next-line
  Lessons: PropTypes.array, // props accessed dynamically
  Practices: PropTypes.array, // eslint-disable-line
  Polls: PropTypes.array, // eslint-disable-line
  skill: PropTypes.object,
  dispatchGetUserFeedbacks: PropTypes.func
};

export function mapDispatchToProps(dispatch) {
  return {
    dispatchGetUserFeedbacks: () => dispatch(getUserFeedbacks())
  };
}

const withConnect = connect(null, mapDispatchToProps);

export default compose(withTranslation(), withConnect)(CourseSkill);
