import { fromJS } from 'immutable';
import { TYPES } from './actions';

const DEFAULT_SYLLABLE_COUNT = 1;

export const initialState = fromJS({
  stimuli: [],
  originalStimuli: [],
  isLoading: false,
  syllableCount: DEFAULT_SYLLABLE_COUNT,
  wordIndex: 0,
});

function stimuliReducer(state = initialState, action) {
  switch (action.type) {
    case TYPES.RESET_STIMULI: {
      return state.set('stimuli', []);
    }
    case TYPES.SET_STIMULI: {
      return state
        .set('stimuli', action.stimuli)
        .set('isLoading', false);
    }
    case TYPES.TOGGLE_LOADING_STIMULI: {
      return state.set('isLoading', action.isLoading);
    }
    default:
      return state;
  }
}

export default stimuliReducer;
