import { useSelector } from 'react-redux';
import Version from '../Version';
import {useTranslation} from "react-i18next";

const ServerVersion = ({ showIcon }) => {
  const { loading, properties } = useSelector((state) =>
    state.getIn(['app', 'serverProperties']).toJS(),
  );
  const {t} = useTranslation()
  const version = properties?.find((p) => p.name === 'version');
  return (
    <Version
      label={t('UI.serverVersion')}
      loading={loading}
      value={version?.value}
      showIcon={showIcon}
    />
  );
};

export default ServerVersion;
