import {
  call,
  put,
  takeLatest,
  delay,
  all,
  fork,
  join,
} from 'redux-saga/effects';
import { authenticationWrapper } from '@services/errorHandlingService';
import {
  sendDismissActivity,
  getPageData,
  getHistoryFromHours,
  getSessionId,
} from './services';
import {
  Types,
  setPendingDashboardData,
  dismissActivityComplete,
  setHistoryFromHours,
  setChatSessionId,
} from './actions';
import { pageData } from './__fixtures__';

const useFixtures = process.env.USE_FIXTURES;

export function* callsetPendingDashboardData(action) {
  yield authenticationWrapper(function* codeBlock() {
    if (useFixtures) {
      yield delay(1000);
    }
    const { hours } = action.payload;
    const res = yield fork(getPageData, hours);
    const payload = useFixtures ? pageData : yield join(res);
    yield put(setPendingDashboardData(payload));
  });
}

export function* callDismissActivity(action) {
  yield authenticationWrapper(function* codeBlock() {
    if (useFixtures) {
      yield delay(500);
    }
    const { activity, reason } = action.payload;

    yield call(sendDismissActivity, activity, reason);
    yield put(dismissActivityComplete());
  });
}

export function* callGetLastHours(action) {
  yield authenticationWrapper(function* codeBlock() {
    if (useFixtures) {
      yield delay(500);
    }
    const { hours } = action.payload;
    const res = yield fork(getHistoryFromHours, hours);
    const activities = yield join(res);
    yield put(setHistoryFromHours(hours, activities));
  });
}
export function* callStartVideoChat({ payload: { inviteList } }) {
  yield authenticationWrapper(function* codeBlock() {
    if (useFixtures) {
      yield delay(500);
    }
    const res = yield fork(getSessionId, inviteList);
    const sessionId = yield join(res);
    yield put(setChatSessionId(sessionId));
  });
}

function* watchCallsetPendingDashboardData() {
  yield takeLatest(Types.GET_PENDING_DATA, callsetPendingDashboardData);
}
function* watchCallDismissActivity() {
  yield takeLatest(Types.DISMISS_ACTIVITY, callDismissActivity);
}
function* watchCallGetLastHours() {
  yield takeLatest(Types.GET_HISTORY_FROM_HOURS, callGetLastHours);
}
function* watchCallStartVideoChat() {
  yield takeLatest(Types.INVITE_TO_VIDEO_CHAT, callStartVideoChat);
}

export default function* pendingDashboardDataSaga() {
  yield all([
    fork(watchCallsetPendingDashboardData),
    fork(watchCallDismissActivity),
    fork(watchCallGetLastHours),
    fork(watchCallStartVideoChat),
  ]);
}
