export const Types = {
  CONFIRM_EMAIL: 'TRY_CONFIRM_EMAIL',
  CONFIRM_EMAIL_SUCCESS: 'CONFIRM_EMAIL_SUCCESS',
  CONFIRM_EMAIL_FAILURE: 'CONFIRM_EMAIL_FAILURE',
};

export const confirmEmail = (token) => ({
  type: Types.CONFIRM_EMAIL,
  token,
});

export const confirmEmailSuccess = (email) => ({
  type: Types.CONFIRM_EMAIL_SUCCESS,
  email,
});

export const confirmEmailFailure = (error) => ({
  type: Types.CONFIRM_EMAIL_FAILURE,
  error,
});

