/**
 *
 * Select
 *
 */
import React from 'react';
import PropTypes from 'prop-types';
import SelectBase from 'react-select';
import {withTheme} from "@mui/styles";
import theme from 'src/services/theme'
import { StyledDropdownDownIcon, getSelectStyles, StyledDropdownUpIcon } from './styles';
import { Option } from './components/option';
import { ValueContainer } from './components/value-container';

const Select = ({
  value,
  options,
  onChange,
  isDisabled,
  width,
  theme,
  isMulti,
  optionsWithCheckboxes,
  isOptionDisabled,
  hideSelectedOptions,
  onBlur,
  showCountSelectedOptions,
  defaultValue,
  closeMenuOnSelect,
}) => (
  <SelectBase
    isDisabled={isDisabled}
    isOptionDisabled={isOptionDisabled}
    isClearable={false}
    isMulti={isMulti}
    pageSize={8}
    value={value}
    options={options}
    onChange={onChange}
    onBlur={(onBlur)}
    styles={getSelectStyles(width)}
    hideSelectedOptions={hideSelectedOptions}
    defaultValue={defaultValue}
    closeMenuOnSelect={closeMenuOnSelect}
    components={{
      ...(optionsWithCheckboxes && { Option: (props) => <Option {...props} /> }),
      ...(showCountSelectedOptions && { ValueContainer }),
      DropdownIndicator: (state) =>
        state.selectProps.menuIsOpen ? (
          <StyledDropdownUpIcon />
        ) : (
          <StyledDropdownDownIcon />
        ),
    }}
  />
);

Select.propTypes = {
  isDisabled: PropTypes.bool,
  isOptionDisabled: PropTypes.bool,
  isMulti: PropTypes.bool,
  value: PropTypes.any,
  defaultValue: PropTypes.any,
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.any,
      label: PropTypes.any,
    })
  ),
  width: PropTypes.number,
  theme: PropTypes.object,
  optionsWithCheckboxes: PropTypes.bool,
  hideSelectedOptions: PropTypes.bool,
  showCountSelectedOptions: PropTypes.bool,
  closeMenuOnSelect: PropTypes.bool,
};

export default withTheme(Select);
