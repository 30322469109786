const map = {
  'Gentle voice  production': {
    'Tense posture': 'Make sure that you relax your neck muscles',
    'Head and neck movements': 'Make sure that the loud voice is produced by using diaphragmatic breathing and not the head or neck',
    'Appropriate loud voice': 'Well done,  loud voice is produced correctly',
    'Too strong': 'Pay attention, vocal intensity is too strong and might result in over exertion',
    'appropriate duration': 'Well done! loud voice was produced  for just a short amount of time',
  },
  'Loud voice production ': {
    'Tense posture': 'Make sure that you relax your neck muscles',
    'Too long': 'Pay attention, loud voice production was too long',
    'Too short ': 'Pay attention, loud voice production was too short',
    'correct mouth opening': 'well done! you need  to use a wide open mouth  to ensure that vocal volume is increased',
    'closed mouth': 'make sure that your mouth is open wide to enable an increase in vocal volume',
  },
  'Transition between voices': {
    'Tense posture': 'Make sure that you relax your neck muscles',
    'Head and neck movements': 'Make sure that the loud voice is produced by using diaphragmatic breathing and not the head or neck',
    'Match the shaded pattern': 'Try to produce a sound similar to the goal of the shaded pattern',
    'Loud voice in the middle and gentle voice on both sides ': 'Make sure that the gentle voice is produced at the beginning and end of the pattern and that the loud voice is  produced only in the middle of the pattern . Onset and offset are slightly longer than the middle of the pattern.',
  },
  Relaxation: { Yawning: "Make sure that you yawn and don't just open your mouth!" },
  'Symmetrical Transition Pattern': {
    'Symmetry of the pattern': 'Well done! you produced the transition pattern symmetrically. Gradual rise from gentle voice to loud voice and then a gradual drop to gentle voice',
    '" Not symmetrical" error': 'Try producing the pattern symmetrically. Make sure that the length of voice production at the beginning and end of  the pattern are similar. Try making a gradual rise and fall like going up a slope and not up and down steps, which are not smooth and can be jerky.Make sure that you start the wave pattern as soon as you exhale. Use the Monitor to guide you with correct time allocation as well as wavepattern',
  },
  'Vowel Production': {
    'exaggarated Mouth opening': 'Well done! you produced the vowel sounds by using an exaggerated open mouth position',
    'Regular mouth opening': 'Make sure that you produce the vowel sounds using a more exaggerated open mouth position than a regular,normal one. ',
    'Little or no mouth opening': 'It is very difficult to understand somebody who speaks with their mouth closed or barely open. Limited mouth and jaw muscle movement can also have a negative effect on speech fluency. Use wide and open mouth posture and exaggerated mouth movements during vowel production',
    'Vowel production': 'Make sure that there is no interruption to airflow when producing a vowel sound. Be sure to use diaphragmatic breathing and not shallow chest breathing.',
  },
};
export default map;
