import { styled } from '@mui/material';
import { getIconsByType, iconTypes } from '@services/icons';
import { LOCATION_TYPES } from '@shared/Resources/Monitor/types';
import Text from '@components/Text/index';
import { getErrorPointForOneSecMonitor, getErrorPositionByLocationType } from './helpers';

const CheckmarkIcon = getIconsByType(iconTypes.CIRCLE_CHECKMARK_OVERFLOWED);

export const StyledCheckmarkIcon = styled(CheckmarkIcon)(
  {
    marginRight: '5px',
  },
  ({ theme }) => ({
    color: theme.palette.novotalk.textColorReversed,
  }),
);

export const StyledTooltip = styled('div')(
  {
    position: 'absolute',
    padding: '9px 14px',
    letterSpacing: '-0.46px',
    borderRadius: 4,
    lineHeight: '16px',
    zIndex: '200',
    textAlign: 'center',
    '&:after': {
      display: 'block',
      content: '""',
      position: 'absolute',
      width: 12,
      height: 12,
      zIndex: '201',
    },
  },
  ({
    location, duration, width, isVideoChat,
  }) => getPlacementClass(location, duration, width, isVideoChat),
  ({ theme, isError }) => ({
    backgroundColor: isError
      ? theme.palette.novotalk.$monitorFeedbackErrorColor
      : theme.palette.novotalk.$monitorFeedbackCorrectColor,
    '&:after': {
      backgroundColor: isError
        ? theme.palette.novotalk.$monitorFeedbackErrorColor
        : theme.palette.novotalk.$monitorFeedbackCorrectColor,
    },
  }),
);

const getInhalePlacement = () => ({
  bottom: '0',
  left: '-10px',
  transform: 'translateX(-100%)',
  '&:after': {
    right: -3,
    top: 'calc(50% - 6px)',
    transform: 'rotate(135deg)',
  },
  '@media(max-width: 1280px)': {
    bottom: '0px',
    left: '0px',
    transform: 'translateY(calc(100% - 9px))',
    '&:after': {
      top: -3,
      right: 'calc(50% - 6px)',
      transform: 'rotate(-45deg)',
    },
  },
});

export const getPlacementClass = (location, duration, widthProp, isVideoChat) => {
  const monitor = document.getElementById('monitorCanvas');
  const monitorPosition = monitor?.getBoundingClientRect();
  const monitorWrapper = document.getElementById('MonitorsWrapper');
  const monitorPositionWidth = monitorPosition?.width || 0;
  const width = widthProp || 0;
  switch (location) {
    case LOCATION_TYPES.PRODUCTION:
      return {
        bottom: '117px',
        right: '-10px',
        transform: 'translateX(100%)',
        '&:after': {
          left: -3,
          top: 'calc(50% - 6px)',
          transform: 'rotate(-135deg)',
        },
      };
    case LOCATION_TYPES.INHALATION:
      return getInhalePlacement();
    case LOCATION_TYPES.BEGINNING:
      const styleBegin = {
        left: monitorWrapper.offsetWidth / 2 - width / 2 - monitorPositionWidth / 2,
        '&:after': {
          left: 'calc(50% - 6px)',
          transform: 'rotate(45deg)',
        },
      };
      return isVideoChat ? {
        ...styleBegin,
        transform: 'translate(0, calc(-100% - 10px))',
      } : styleBegin;
    case LOCATION_TYPES.PEAK:
      return {
        right: '50%',
        top: '0',
        transform: 'translate(50%, calc(-100% - 8px))',
        '&:after': {
          left: 'calc(50% - 6px)',
          transform: 'rotate(135deg)',
        },
      };
    case LOCATION_TYPES.END:
      const styleEnd = {
        right: monitorWrapper.offsetWidth / 2 - width / 2 - monitorPositionWidth / 2,
        '&:after': {
          left: 'calc(50% - 6px)',
          transform: 'rotate(-45deg)',
        },
      };
      return isVideoChat ? {
        ...styleEnd,
        transform: 'translate(0, calc(-100% - 10px))',
      } : styleEnd;
    case LOCATION_TYPES.RIGHT_SIDE_HIGH:
    case LOCATION_TYPES.RIGHT_SIDE_MIDDLE:
      const heightStyles = getErrorPositionByLocationType(location);
      return {
        right: -90,
        minHeight: 35,
        minWidth: 83,
        ...heightStyles,
        transform: 'translateY(calc(-100% - 8px))',
        '&:after': {
          left: -3,
          top: 'calc(50% - 6px)',
          transform: 'rotate(-135deg)',
        },
      };
    default:
      // This will make error invisible in case it's location exceeds the monitor
      if (Number(location) > duration) {
        return {
          display: 'none!important',
        };
      }

      const errorPosition = getErrorPointForOneSecMonitor(duration, location, width).toString();
      return {
        top: '0',
        left: `${errorPosition}px`,
        transform: 'translateY(calc(-100% - 8px))',
        '&:after': {
          left: 'calc(50% - 7px)',
          transform: 'rotate(-45deg)',
        },
      };
  }
};

export const PopupText = styled(Text)(({ theme }) => ({
  color: theme.palette.novotalk.$textColorReversed,
  fontSize: 13,
}));
