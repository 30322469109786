import React from 'react';

const icon = () => (<svg width="160px" height="160px" viewBox="0 0 160 160" version="1.1" xmlns="http://www.w3.org/2000/svg">
  <defs>
    <path d="M159.458962,79.729481 C159.458962,123.76244 123.764152,159.458962 79.7311932,159.458962 C35.6965222,159.458962 0,123.76244 0,79.729481 C0,35.6965222 35.6965222,0 79.7311932,0 C123.764152,0 159.458962,35.6965222 159.458962,79.729481" id="path-1"></path>
  </defs>
  <g id="Project-Styleguide" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
    <g id="Illustrations" transform="translate(-85.000000, -412.000000)">
      <rect fill="#FFFFFF" x="0" y="0" width="1690" height="943"></rect>
      <g id="Not-available-Image" transform="translate(85.000000, 412.000000)">
        <mask id="mask-2" fill="white">
          <use href="#path-1"></use>
        </mask>
        <use id="Fill-7" fill="#E4F9FF" href="#path-1"></use>
        <g id="Main" mask="url(#mask-2)">
          <g transform="translate(20.000000, 27.000000)">
            <polygon id="Path" fill="#23B7E5" fillRule="nonzero" points="114.9425 101.7225 120 117.9275 59.95 117.9275 59.95 101.7225"></polygon>
            <polygon id="Path" fill="#A8ECFF" fillRule="nonzero" points="80.885 69.5925 104.9125 69.5925 114.9425 101.7225 59.95 101.7225 59.95 117.9275 0 117.9275 16.485 69.5925 44.2575 69.5925 60.35 91.1075 76.5825 69.405 76.8325 69.5925"></polygon>
            <path d="M44.2575,69.5925 L33.8075,55.6225 C28.9175,48.9175 26.1225,40.815 26.6825,32.4325 C27.8,15.25 41.49,0.025 60.35,0.025 C79.21,0.025 92.8975,15.25 94.0175,32.4325 C94.575,40.675 91.92,48.9175 86.8925,55.6225 L76.5825,69.405 L60.35,91.1075 L44.2575,69.5925 Z M82.6116455,34.525 C82.6116455,22.0985931 72.5380523,12.025 60.1116455,12.025 C47.6852386,12.025 37.6116455,22.0985931 37.6116455,34.525 C37.6116455,46.9514069 47.6852386,57.025 60.1116455,57.025 C72.5378426,57.0244938 82.6111392,46.9511972 82.6116455,34.525 L82.6116455,34.525 Z" id="Shape" fill="#F4942D" fillRule="nonzero"></path>
            <path d="M60,40.485805 L53.3051662,44.1015891 C53.0621957,44.232814 52.7588505,44.1422263 52.6276256,43.8992558 C52.5715708,43.795467 52.5540154,43.675202 52.5780629,43.5597206 L54.0794476,36.3497155 L48.4769235,31.3826024 C48.2702959,31.1994095 48.2512983,30.8833975 48.4344912,30.6767699 C48.5164952,30.5842757 48.6298547,30.5254364 48.7526904,30.5116084 L56.3408974,29.6573821 L59.5488613,22.9440953 C59.6679217,22.6949383 59.9664209,22.5894741 60.215578,22.7085345 C60.3187165,22.7578196 60.4018537,22.8409568 60.4511387,22.9440953 L63.6591026,29.6573821 L71.2473096,30.5116084 C71.5217187,30.5424994 71.7191291,30.7899941 71.688238,31.0644032 C71.6744101,31.1872389 71.6155708,31.3005984 71.5230765,31.3826024 L65.9205524,36.3497155 L67.4219371,43.5597206 C67.4782324,43.8300638 67.3047123,44.0948566 67.0343691,44.1511519 C66.9188876,44.1751993 66.7986226,44.1576439 66.6948338,44.1015891 L60,40.485805 Z" id="Star" fill="#23B7E5" fillRule="evenodd"></path>
          </g>
        </g>
      </g>
    </g>
  </g>
</svg>);

export default icon;
