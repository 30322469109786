import React from 'react';
import Text, { TEXT_SIZE, TEXT_COLOR } from '@components/Text';
import { getFlexibleSize } from '@utils/cssHelpers';
import { RESOURCES } from '../../types';
import { getStyledTypeIcon, CountWrapper } from './styles';
import ResourcesList from '@shared/Resources/shared/components/ResourcesList';
import { getDateDisplay } from '@shared/Resources/shared/components/ListWrappers';
import { listInnerEntityNameHelper } from '@shared/Resources/helpers';
import {useTranslation} from "react-i18next";

const getTypeCount = (type, item, isSelected) => {
  const TypeIcon = getStyledTypeIcon(type);
  const count = item.contains ? item.contains[type] || 0 : 0;
  return (
    <CountWrapper>
      <TypeIcon isSelected={isSelected} />
      <Text
        size={TEXT_SIZE.H4}
        textColor={TEXT_COLOR[isSelected ? 'ACTIVE' : 'DARK_BLUE']}
      >
        {count}
      </Text>
    </CountWrapper>
  );
};

const SkillList = (props) => {
  const {t} = useTranslation()
  const tableConf = [
    {
      getDisplay: (item) => listInnerEntityNameHelper(item, t),
      name: t('Common.UI.name'),
      textOptions: {
        size: 'H4',
        color: 'DARK_BLUE',
        withEllipsis: true,
      },
    },
    {
      getDisplay: (item, isSelected) =>
        getTypeCount(RESOURCES.LESSON, item, isSelected),
      name: '',
      width: '7%',
    },
    {
      getDisplay: (item, isSelected) =>
        getTypeCount(RESOURCES.PRACTICE, item, isSelected),
      name: '',
      width: '7%',
    },
    {
      getDisplay: (item, isSelected) =>
        getTypeCount(RESOURCES.POLL, item, isSelected),
      name: '',
      width: '10%',
    },
    {
      getDisplay: (item) => getDateDisplay(item.entityCreated),
      name: t('Common.UI.created'),
      textOptions: {
        withEllipsis: true,
      },
      width: getFlexibleSize(95, 140),
    },
    {
      getDisplay: (item) => getDateDisplay(item.entityUpdated),
      name: t('Common.UI.modified'),
      textOptions: {
        withEllipsis: true,
      },
      width: getFlexibleSize(95, 120),
    },
    {
      getDisplay: (item) => item.isTemplate ? t('Common.UI.template') : `${t('Common.UI.ownBy')} ${item.owner?.fullName}`,
      name: t('Common.UI.type'),
      textOptions: {
        withEllipsis: true,
      },
      width: getFlexibleSize(95, 120),
    },
  ];
  return <ResourcesList {...props} tableConf={tableConf} />;
};

export default SkillList;
