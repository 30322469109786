/**
 *
 * MonitorStimulus
 *
 */
import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';

import { capitalizeScreamingCase } from '@utils/textHelpers';
import Text, { TEXT_SIZE, TEXT_COLOR } from '@components/Text';
import StimulusConfig from './containers/StimulusConfig';
import { defaults } from './defaults';
import { STIMULUS_TYPE } from '../../../types';
import { StimulusTitle } from '@shared/Resources/Monitor/shared/Titles';
import { StimulusType, StimulusTypesContainer } from './styles';
import StimulusImage from '@shared/Resources/Monitor/shared/StimulusImage';
import { getFormattedTextCurry } from '@shared/Resources/Monitor/shared/utils/formattedText';

export const getFormattedText = getFormattedTextCurry();

class MonitorStimulus extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      stimulus: _.cloneDeep(props.stimulus),
    };
  }

  updateField = (field) => (value) => {
    const { stimulus } = this.state;
    const res = {...stimulus};
    _.set(res, field, value);
    this.setState({ stimulus:res });
    this.props.onChange(res);
  };

  updateType = (type) => () => {
    const stimulus = { type, config: _.cloneDeep(defaults[type]) };
    this.setState({ stimulus });
    this.props.onChange(stimulus);
  };

  renderStimulusType = (type) => {
    const isSelected = type === this.state.stimulus.type;
    return (
      <StimulusType
        isSelected={isSelected}
        key={type}
        className='stimulusType'
        onClick={this.updateType(type)}
      >
        <StimulusImage isSelected={isSelected} type={type} />
        <Text
          size={TEXT_SIZE.T4}
          textColor={TEXT_COLOR[isSelected ? 'ACTIVE' : 'DARK_BLUE']}
          className='stimulusTypeName'
        >
          {capitalizeScreamingCase(type)}
        </Text>
      </StimulusType>
    );
  };

  render() {
    return (
      <div>
        <StimulusTitle />
        <StimulusTypesContainer>
          {_.keys(STIMULUS_TYPE).map(this.renderStimulusType)}
        </StimulusTypesContainer>
        <StimulusConfig
          onChange={this.updateField('config')}
          type={this.state.stimulus.type}
          config={this.state.stimulus.config || {}}
        />
      </div>
    );
  }
}

MonitorStimulus.propTypes = {
  onChange: PropTypes.func,
  stimulus: PropTypes.shape({
    type: PropTypes.string,
    config: PropTypes.object,
  }),
};

export default MonitorStimulus;
