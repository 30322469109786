import React from 'react';
import PropTypes from 'prop-types';
import Text, { TEXT_COLOR } from '@components/Text';
import { Wrapper, Title, Body, Button } from './styles';
import {useTranslation} from "react-i18next";

const FeedbackPreview = ({ titleText, bodyText, onClose }) => {
    const {t} = useTranslation()
    return (
        <Wrapper>
            <Title>
                <Text size="H3" textColor={TEXT_COLOR.HIGHLIGHTED} weight="600">{titleText}</Text>
            </Title>
            <Body>
                <Text >{bodyText}</Text>
            </Body>
            <Button onClick={onClose} >
                <Text size="H3" textColor="reversed">
                    {t('Actions.Instance.close')}
                </Text>
            </Button>
        </Wrapper>
    );
}

FeedbackPreview.propTypes = {
  onClose: PropTypes.func,
  titleText: PropTypes.node,
  bodyText: PropTypes.node,
};
export default FeedbackPreview;
