import _ from 'lodash';
import { fromJS } from 'immutable';
import { TYPES } from '@shared/Resources/types';
import { EditorObjectsFactory } from '../editorObjects/editorObjectsFactory';
import { getWidgets } from '../editorObjects/editorWidgets';
import { setRoot } from './rootObject';
import { extractInfo } from './infoExtractor';
import { getBuilderChildrenFromData } from './childrenExtractor';
import { createdEntityToEditorComponent } from './savedEntityConverter';

export const reduceSave = (state, action) => {
  // should update the current location at breadcrumbs
  const breadcrumbs = state.get('breadcrumbs').toJS();
  const { type, info, components } = action.payload;
  info.saved = true;
  const filteredComponents = components.filter(
    (component) => !component.payload.mapToField,
  );
  breadcrumbs[breadcrumbs.length - 1] = {
    type,
    info,
    components: filteredComponents,
  };
  return state.set('loading', true).set('breadcrumbs', fromJS(breadcrumbs));
};

export const reduceSaveComplete = (state) => state
  .set('editState', fromJS({ dirty: false, lastSaved: Date.now() }))
  .set('loading', false);

export const reduceSaveFailed = (state) => state.set('loading', false);

export const reduceUpdateField = (state, action) => {
  const {
    fieldName, value, valid, additionalData,
  } = action.payload;

  const oldValue = state.getIn(['info', ...fieldName.split('.')]);
  const changeInMedia = fieldName.includes('media.');
  const updatedFieldState = state
    .setIn(['editState', 'dirty'], !_.isEqual(oldValue, value) || changeInMedia)
    .setIn(['fieldsValidationMap', fieldName], fromJS(valid))
    .setIn(['info', ...fieldName.split('.')], fromJS(value))
    .setIn(['info', 'additionalData', ...fieldName.split('.')], fromJS(additionalData));

  if (fieldName === 'monitor' && !value.errors.on) {
    return updatedFieldState
      .setIn(['info', 'monitor', 'errors', 'types'], [])
      .setIn(['info', 'monitor', 'errors', 'masteryLevel'], 0);
  }

  return updatedFieldState;
};

const addWidgetTitle = (parentType) => (item) => {
  // FIXME: should be apart of object creation class
  // eslint-disable-next-line
  if (parentType === TYPES.POLL && item.type === TYPES.TEXT) {item.payload.title = 'Instructions';}
  // eslint-disable-next-line
  else item.payload.title = _.capitalize(item.type);
};

export const reduceReset = (state) => {
  const newRootObject = EditorObjectsFactory.createObject(state.get('type'));
  setRoot(newRootObject);
  return state
    .set('editState', fromJS({ dirty: null, lastSaved: null }))
    .set('info', fromJS(newRootObject.payload))
    .set('components', fromJS(newRootObject.children));
};

export const reduceLoad = (state) => state.set('loading', true);

const getPositionInBreadcrumbs = (entity, entities) => entities.findIndex(({ info }) => info.id === entity.id);

const isMissingInBreadcrumbs = (entity, entities) => getPositionInBreadcrumbs(entity, entities) === -1;

const getUpdatedBreadcrumbs = (state, current, type) => {
  const breadCrumbs = state.get('breadcrumbs').toJS();
  let childToAdd = null;
  if (isMissingInBreadcrumbs(current, breadCrumbs)) {
    breadCrumbs.push({ type, info: current });
  } else {
    const index = getPositionInBreadcrumbs(current, breadCrumbs);
    const deletedEntities = breadCrumbs.splice(
      index + 1,
      breadCrumbs.length - 1,
    );
    if (deletedEntities.length === 1 && deletedEntities[0].info.saved) {
      const { type: entityType, info, components } = deletedEntities[0];
      childToAdd = createdEntityToEditorComponent(entityType, info, components);
    }
  }
  // if current is one before (meaning )
  return {
    breadCrumbs,
    childToAdd,
  };
};

// loadCompleted
export const reduceLoadComplete = (state, action) => {
  const { type, data } = action.payload;

  // TODO: move builder children to "CreateRootObject" instead of creatFromJson
  // move all "extractors" to some class for specific type
  const newRootObject = EditorObjectsFactory.createFromJSON(data);

  newRootObject.children.forEach(addWidgetTitle(data.type));

  const builderChildren = getBuilderChildrenFromData(type, data);

  builderChildren.forEach((child) => newRootObject.addChildToTop(
    EditorObjectsFactory.createFromJSON(child),
  ));

  const info = extractInfo(type, data);
  const widgets = getWidgets(type);
  const { breadCrumbs, childToAdd } = getUpdatedBreadcrumbs(state, info, type);

  newRootObject.children.forEach(function (child, index) {
    if (!child.id && child.payload.id) {
      this[index].id = child.payload.id;
    }
  }, newRootObject.children);

  if (childToAdd) {
    const editorObject = EditorObjectsFactory.createFromJSON(childToAdd);

    if (
      !newRootObject.children.find(
        ({ payload }) => payload.id === editorObject.payload.id,
      )
    ) {
      newRootObject.addChild(editorObject);
    }
  }
  setRoot(newRootObject);
  return state
    .set('editState', fromJS({ dirty: null, lastSaved: null }))
    .set('originalName', info.name)
    .set('widgets', fromJS(widgets))
    .set('loading', false)
    .set('type', type)
    .set('info', fromJS(info))
    .set('components', fromJS(newRootObject.children))
    .set('breadcrumbs', fromJS(breadCrumbs));
};

export const reduceLoadFailed = (state, action) => state
  .set('error', action.payload.error)
  .set('loading', false);
