import {
  OneSecondWave,
  TwoSecondsWave,
  ThreeSecondsWave,
  FourSecondsWave,
  FiveSecondsWave,
  SixSecondsWave,
} from "./pattern-outlines";

export const WAVES_OUTLINE = {
  1: <OneSecondWave />,
  2: <TwoSecondsWave />,
  3: <ThreeSecondsWave />,
  4: <FourSecondsWave />,
  5: <FiveSecondsWave />,
  6: <SixSecondsWave />,
};
