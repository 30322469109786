import React from 'react';
import {useTranslation} from "react-i18next";
import {ButtonText, ExpandButtonContainer} from "../../styles";
import TextButton from "@components/TextButton/index.js";

interface PersonalProgramListHeaderProps {
    isExpanded: boolean
    onSkillsListToggle: () => void
}

export const SkillsListHeader: React.FC<PersonalProgramListHeaderProps> = ({isExpanded, onSkillsListToggle}) => {
    const {t} = useTranslation()

    return (
        <ExpandButtonContainer>
            <TextButton onClick={onSkillsListToggle}>
                <ButtonText>
                    {t(`Actions.Multiple.${isExpanded ? 'collapseAll' : 'expandAll'}`)}
                </ButtonText>
            </TextButton>
        </ExpandButtonContainer>
    );
};