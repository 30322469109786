import { ACTIVITY_TYPES } from "@shared/Resources/types";

export const USER_PAGE_TYPES = {
  INFORMATION: 'information',
  PERSONAL_PROGRAM: 'personalProgram',
  LOG: 'log',
}

export const TABS = [
  { type: USER_PAGE_TYPES.INFORMATION, title: 'User Information' },
  { type: USER_PAGE_TYPES.PERSONAL_PROGRAM, title: 'Personal Program' },
  { type: USER_PAGE_TYPES.LOG, title: 'User Activity Log' },
];

export const NO_NAME = 'No Name';

export const USER_ACTIVITIES = [
  { value: 'All', label: "All Activities" },
  { value: ACTIVITY_TYPES.ACTIVITY_PRACTICE, label: "Practices" },
  { value: ACTIVITY_TYPES.ACTIVITY_LESSON, label: "Lessons" },
  { value: ACTIVITY_TYPES.ACTIVITY_CHAT, label: "Video chats" }
]

export const TOAST_TYPES = {
  SHOW_SUCCESS_CHANGED_LEVEL: 'SHOW_SUCCESS_CHANGED_LEVEL',
  SHOW_ERROR_CHANGED_LEVEL: 'SHOW_ERROR_CHANGED_LEVEL',
  SHOW_SUCCESS_ATTACHED: 'SHOW_SUCCESS_ATTACHED',
  SHOW_ERROR_ATTACHED: 'SHOW_ERROR_ATTACHED',
}

export const USER_STATUS = {
  ACTIVE: 'ACTIVE',
  INACTIVE: 'INACTIVE',
}

export const DEFAULT_VALUES = {
  MENTORS: [],
  COURSE: null,
  STATUS: '',
}
