import React, {useState} from 'react';
import {RESOURCES} from "@shared/Resources/types";
import {
    SkillsListItem
} from "@containers/Admin/containers/UserPage/user-page-wrapper/components/UserPersonalProgram/components/SkillsListItem";
import {useTranslation} from "react-i18next";
import {
    SkillsListHeader
} from "@containers/Admin/containers/UserPage/user-page-wrapper/components/UserPersonalProgram/components/SkillsListHeader";
import {Box} from "@mui/material";
import Text from "@components/Text/index.js";
import {useCustomSelector} from "src/hooks/useCustomSelector";
import {
    SkillsListWrapper
} from "@containers/Admin/containers/UserPage/user-page-wrapper/components/UserPersonalProgram/components/SkillsList/styles";
import {ISkill} from "@containers/Admin/containers/UserPage/user-page-wrapper/components/UserPersonalProgram/types";

export const SkillsList = () => {
    const {overview: {resource}} = useCustomSelector('resourceManager')
    const {t} = useTranslation()
    const [isExpanded, setIsExpanded] = useState(false)

    const onSkillsListToggle = () => setIsExpanded((prev) => !prev)

    return (
        <SkillsListWrapper>
            <SkillsListHeader isExpanded={isExpanded} onSkillsListToggle={onSkillsListToggle}/>
            {
                !!resource?.Skills?.length
                    ? resource?.Skills?.map((item: ISkill) => (
                        <div key={item.name} className={RESOURCES.SKILL}>
                            <SkillsListItem item={item} isExpandedAll={isExpanded} />
                        </div>
                    ))
                    : <Box mt={2}>
                        <Text>{t('Errors.dataMissing')}</Text>
                    </Box>
            }
        </SkillsListWrapper>
    );
};