import {styled} from "@mui/material";

export const TypeSelection = styled('button')(
  {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    outline: 'none',
    padding: 12,
    marginRight: 16,
    borderRadius: 4,
    textAlign: 'center',
    border: '1px solid',
    cursor: 'pointer',
  },
  ({ isSelected, theme }) => ({
    borderColor: isSelected
      ? theme.palette.novotalk.$activeColor
      : theme.palette.novotalk.$monitorTypesBorderColor,
    '&:hover': {
      borderColor: theme.palette.novotalk.$monitorTypesBorderColorHover,
    },
  })
);

export const TypeSelectionSquare = styled('div')({
  width: 88,
  height: 88,
  display: 'flex',
  justifyContent: 'stretch',
  alignItems: 'stretch',
  marginBottom: 12,
  '& img': {
    width: '100%',
  },
});
