import {styled} from "@mui/material";

export const CustomStimulusTitle = styled('div')({
  display: 'grid',
  gridColumnGap: 19,
  gridTemplateColumns: 'max-content max-content',
  alignItems: 'end',
  lineHeight: '19px',
  marginBottom: 10,
});

export const InputContainer = styled('div')({
  width: 180,
});
