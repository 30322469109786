import React from 'react';
import PropTypes from 'prop-types';
import { TEXT_SIZE, TEXT_COLOR } from '@components/Text';
import { ConfigSection } from '../../../../../styles';
import withUpdateField from '@shared/Resources/Monitor/Client/shared/HOC/ConfigUpdater';
import { ToggleGroupTitle, ToggleWrapper, ToggleItem } from './styles';
import { getFormattedTextCurry } from '@shared/Resources/Monitor/shared/utils/formattedText';

const getFormattedText = getFormattedTextCurry();

const ToggleGroup = ({ config, updateField, groupName, groupValues }) => {
  const choosenValues = config[groupName] || [];
  return (
    <ConfigSection className={groupName}>
      <ToggleGroupTitle>
        {getFormattedText(`Stimuli.${groupName}`, {
          weight: 'bold',
          textColor: TEXT_COLOR.DARK_BLUE,
          size: TEXT_SIZE.H4,
        })}
        {getFormattedText('Actions.Multiple.selectOne', {
          textColor: TEXT_COLOR.LIGHT_GRAY,
          size: TEXT_SIZE.T4,
          margin: '0 9px',
        })}
      </ToggleGroupTitle>
      <ToggleWrapper>
        {groupValues.map(({ value, message }) => {
          const isSelected = choosenValues[value];
          return (
            <ToggleItem
              key={value}
              onClick={() => updateField(groupName)({ [value]: true })}
              isSelected={isSelected}
            >
              {getFormattedText(
                `Stimuli.${message}`,
                {
                  textColor: TEXT_COLOR[isSelected ? 'ACTIVE' : 'DARK_BLUE'],
                  size: TEXT_SIZE.T4,
                  weight: isSelected ? 'bold' : 'normal',
                },
                'wordCountType'
              )}
              {getFormattedText(
                `Stimuli.${message}Description`,
                {
                  textColor: TEXT_COLOR[isSelected ? 'ACTIVE' : 'DISABLED'],
                  size: TEXT_SIZE.T4,
                },
                'wordCountDescription'
              )}
            </ToggleItem>
          );
        })}
      </ToggleWrapper>
    </ConfigSection>
  );
};

ToggleGroup.propTypes = {
  config: PropTypes.object,
  updateField: PropTypes.func,
  groupName: PropTypes.string,
  groupValues: PropTypes.array,
};

export default withUpdateField(ToggleGroup);
