import {ERROR_TYPES, LOCATION_TYPES, PATTERN_TYPES} from "@shared/Resources/Monitor/types";
import {checkErrorsAndGetNames} from "@containers/User/helpers/monitorErrors";

export const filterForStaticMonitorErrors = (currentMonitorType, errors) => {
    return errors.filter(
        (error) =>
            !(currentMonitorType === PATTERN_TYPES.ONE_SECOND && error.type === ERROR_TYPES.nonGentleOnsetOsm ||
            currentMonitorType === PATTERN_TYPES.SELF_MANAGED && error.type === ERROR_TYPES.nonGentleOnsetSmm ||
            currentMonitorType === PATTERN_TYPES.VOICE_TRANSITION && (
                error.type === ERROR_TYPES.notGentleBeginning ||
                    error.type === ERROR_TYPES.notGentleEnd

                ) ||
            currentMonitorType === PATTERN_TYPES.TWO_SECONDS && (
                error.type === ERROR_TYPES.nonGentleOnset ||
                error.type === ERROR_TYPES.nonGentleOffset ||
                error.type === ERROR_TYPES.volumeControlBeginning ||
                error.type === ERROR_TYPES.shapeBeginning ||
                error.type === ERROR_TYPES.sharpRiseBeginning ||
                error.type === ERROR_TYPES.volumeRiseBeginning ||
                error.type === ERROR_TYPES.shapeEnd ||
                error.type === ERROR_TYPES.volumeFallEnd ||
                error.type === ERROR_TYPES.volumeControlEnd ||
                error.type === ERROR_TYPES.sharpFallEnd
                ) ||
                currentMonitorType === PATTERN_TYPES.VOICE_TRANSITION && (
                    error.type === ERROR_TYPES.notGentleBeginning ||
                    error.type === ERROR_TYPES.notGentleEnd
                )

            )
    )
}

export const defineErrorsList = (currentMonitorType, monitorErrors) => {
    let errorsToRender = []
    let errors

    switch (currentMonitorType) {
        case PATTERN_TYPES.ONE_SECOND:
            errors = monitorErrors.filter((e) => e.type === ERROR_TYPES.nonGentleOnsetOsm)
            errorsToRender = [...errorsToRender, ...errors]
            break
        case PATTERN_TYPES.SELF_MANAGED:
            errors = monitorErrors.filter((e) => e.type === ERROR_TYPES.nonGentleOnsetSmm)
            errorsToRender = [...errorsToRender, ...errors]
            break
        case PATTERN_TYPES.TWO_SECONDS:
            errors = monitorErrors.filter((e) =>
                e.type === ERROR_TYPES.nonGentleOnset ||
                e.type === ERROR_TYPES.nonGentleOffset ||
                e.type === ERROR_TYPES.volumeControlBeginning ||
                e.type === ERROR_TYPES.volumeControlEnd
            )
            errorsToRender = [...errorsToRender, ...errors]
            break
        case PATTERN_TYPES.VOICE_TRANSITION:
            errors = monitorErrors.filter((e) => e.type === ERROR_TYPES.notGentleBeginning || e.type === ERROR_TYPES.notGentleEnd)
            errorsToRender = [...errorsToRender, ...errors]
            break
    }

    errorsToRender = errorsToRender.filter(
        (error) => error.location !== LOCATION_TYPES.INHALATION && error.displayed
    )

    errorsToRender = checkErrorsAndGetNames(errorsToRender);

    return errorsToRender
}