import React from 'react';
import PropTypes from 'prop-types';
import EditorComponentWrapper from '@shared/Resources/shared/components/BuilderComponentWrapper';
import EntitySelectionOptions from '@shared/Resources/shared/components/EntitySelectionOptions';
import { LESSON } from '../../types';

export const EditorLesson = ({ submitPayload }) => (
  <EntitySelectionOptions entityType={LESSON} onItemSelect={submitPayload} />
);

EditorLesson.propTypes = {
  submitPayload: PropTypes.func,
};

export default EditorComponentWrapper(EditorLesson);
