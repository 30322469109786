import {
  put, takeLatest, all, fork, join,
} from 'redux-saga/effects';
import { authenticationWrapper } from '@services/errorHandlingService';
import { STIMULUS_TYPE } from '@shared/Resources/Monitor/types';
import { getStimuli } from './service';
import { setStimuli, toggleLoadingStimuli, TYPES } from './actions';
import {
  syllablesStimuli,
  wordsStimuli,
  sentenceStimuli,
} from './__fixtures__/stimuli';

const useFixtures = process.env.USE_FIXTURES;

const getMockStimuli = (parameters) => {
  let dataObj = {};
  switch (parameters.type) {
    case STIMULUS_TYPE.SYLLABLES:
      dataObj = syllablesStimuli;
      break;
    case STIMULUS_TYPE.WORDS:
      dataObj = wordsStimuli;
      break;
    case STIMULUS_TYPE.SENTENCES:
      dataObj = sentenceStimuli;
      break;
    default:
      break;
  }
  const stimuli = dataObj.textStimuli;
  return stimuli;
};

export function* callGetStimuli(action) {
  const { parameters } = action;
  yield put(toggleLoadingStimuli(true));
  yield authenticationWrapper(function* codeBlock() {
    const res = yield fork(getStimuli, parameters);
    const stimuli = useFixtures ? getMockStimuli(parameters) : yield join(res);
    yield put(setStimuli(stimuli, parameters));
  });
}

function* watchCallGetStimuli() {
  yield takeLatest(TYPES.GET_STIMULI, callGetStimuli);
}

export default function* getSupervisorDashboardStimuliModuleDataSaga() {
  yield all([fork(watchCallGetStimuli)]);
}
