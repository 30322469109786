import { EDITOR_MEDIA_TYPES, EDITOR_QUESTION_TYPES } from './editorObjectTypes';

export const getDefaultFreeText = (mapToField, questionText, value) => ({
  type: 'QUESTION',
  payload: {
    type: 'FREE_TEXT',
    mandatory: true,
    questionText,
    mapToField,
    value,
  },
});

export const getMonitor = (data) => ({
  type: 'MONITOR',
  payload: {
    type: 'MONITOR',
    mandatory: true,
    mapToField: 'monitor',
    config: data.monitor,
  },
});

export const getDefaultMediaSelection = (mapToField, title) => ({
  type: 'MEDIA',
  payload: {
    contentType: EDITOR_MEDIA_TYPES.VIDEO,
    url: '',
    description: '',
    mandatory: true,
    title,
    mapToField,
  },
});


const POLL = {
  payload: { name: 'default poll', description: 'some default description', id: null },
  children: [
    {
      type: 'QUESTION',
      payload: {
        type: 'FREE_TEXT',
        questionText: 'Description',
        mandatory: true,
        mapToField: 'description',
        value: 'some default description',
      },
    },
  ],
};

const emptyEntity = {
  payload: {
    id: null,
    version: null,
  },
};

const PRACTICE = emptyEntity;
const EXERCISE = emptyEntity;
const LESSON = emptyEntity;
const SKILL = emptyEntity;

const TEXT = {
  payload: { title: 'Text', text: '', placeHolder: 'Add instructions here' },
  children: [],
};

const MEDIA = {
  payload: {
    title: 'Media',
    type: EDITOR_MEDIA_TYPES.VIDEO,
    url: '',
    description: 'default description',
  },
  children: [],
};

const QUESTION = {
  payload: {
    title: 'Question',
    type: EDITOR_QUESTION_TYPES.FREE_TEXT,
    questionText: '',
  },
  children: [],
};

const defaults = {
  POLL,
  MEDIA,
  TEXT,
  QUESTION,
  PRACTICE,
  EXERCISE,
  LESSON,
  SKILL,
};

export default defaults;
