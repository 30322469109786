import React from 'react';
import Text, { TEXT_COLOR, TEXT_SIZE } from '@components/Text';
import {
  Wrapper,
  TitleContainer,
  TextContainer,
  IconContainer,
  MailIcon,
} from './styles';
import {useTranslation} from "react-i18next";

const AgeNotificationInfo = () => {
    const {t}=useTranslation()
    return (
        <Wrapper>
            <IconContainer>
                <MailIcon />
            </IconContainer>
            <TitleContainer>
                <Text textColor={TEXT_COLOR.ACTIVE} size={TEXT_SIZE.T1} weight='bold'>
                    {t('Authentication.Distribution.interest')}
                </Text>
            </TitleContainer>
            <TextContainer>
                <Text textColor={TEXT_COLOR.DARK_BLUE} size={TEXT_SIZE.H4}>
                    {t('Authentication.Distribution.childFriendly')}
                </Text>
            </TextContainer>
        </Wrapper>
    );
}

export default AgeNotificationInfo;
