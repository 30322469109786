import React, { Component } from 'react';
import _ from 'lodash';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { withTranslation } from 'react-i18next';
import injectReducer from '@utils/injectReducer';
import injectSaga from '@utils/injectSaga';
import Text, { TEXT_COLOR, TEXT_SIZE } from '@components/Text';
import SectionLoading from '@components/SectionLoading';
import DSPHandler from '@shared/DSPHandler';
import { checkErrorsAndGetNames } from '@containers/User/helpers/monitorErrors';
import {
  MONITOR_HANDLER_STATUS,
} from '@shared/Resources/Monitor/types';
import {
  setMonitorErrors, setErrorsInProductionResults,
  setMonitorSubProductionInProgressFlag, saveActivityProduction, setMonitorProduction, changeCalibrationStatus,
} from '@containers/User/containers/CourseRouter/containers/MonitorHandler/actions';
import { NoiseCalibration } from '@components/NoiseCalibration';
import {
  MonitorFeedbackTooltip,
} from '@containers/User/containers/CourseRouter/containers/MonitorHandler/components/MonitorFeedbackTooltip/index.tsx';
import {
  defineErrorsList,
  filterForStaticMonitorErrors,
} from '@containers/User/containers/CourseRouter/containers/MonitorHandler/helpers';
import reducer from './reducer';
import saga from './saga';
import VideoCall from './components/VideoCall';
import {
  LoadingWrapper,
  MonitorVideoWrapper,
  MonitorWrapperVideoChat,
  ButtonsWrapper,
  HeaderMonitor,
  InhaleInstructionWrapper,
  MonitorWithErrors,
} from './styles';
import { MonitorCloseBtn } from './components/MonitorCloseBtn/index';
import { MonitorSettingsBtn } from './components/MonitorSettingsBtn/index';
import { MonitorSettings } from './components/MonitorSettings/index';
import { parsePayloadErrors } from './helpers/parsePayloadErrors';
import { PRODUCTION, GREAT, INHALATION } from './constants';
import { toggleShowMonitorVideoChat } from './actions';

const videoChatSyllablesAmount = 6;

export class VideoChat extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: true,
      inhaling: false,
      productionDuration: 0,
    };
  }

  componentDidMount() {
    this.setState({ isLoading: false });
  }

  componentDidUpdate(prevProps) {
    const {
      error, monitorProps, dispatchToggleShowMonitorVideoChat,
    } = this.props;
    if (!_.isEmpty(error)) {
      this.props.history.push('/');
    }

    const productionDuration = _.get(
      monitorProps,
      'pattern.productionDuration',
      0,
    );

    if ((prevProps.monitorProps?.pattern?.productionDuration && (prevProps.monitorProps?.pattern?.productionDuration !== this.state.productionDuration))
      || (prevProps.monitorProps?.pattern?.type && (prevProps.monitorProps.pattern.type !== monitorProps.pattern.type))) {
      this.setProductionDuration(productionDuration);
      this.setInhaling(false);
      dispatchToggleShowMonitorVideoChat(true, false);
    }
  }

  setProductionDuration(value) {
    this.setState({ productionDuration: value });
  }

  setInhaling(value) {
    this.setState({ inhaling: value });
  }

  updateExerciseProgression = (type, payload) => {
    const {
      dispatchSetMonitorErrors,
      dispatchSetMonitorSubProductionInProgressFlag,
      dispatchSetMonitorProduction,
      dispatchSetErrorsInProductionResults,
      monitorProps,
    } = this.props;
    switch (type) {
      case MONITOR_HANDLER_STATUS.PRODUCTION_START:
        dispatchSetMonitorSubProductionInProgressFlag(true);
        break;
      case MONITOR_HANDLER_STATUS.MAIN_MONITOR_RESULTS: {
        const { productionErrors } = payload.monitorResult;
        const parsedErrors = parsePayloadErrors(productionErrors, monitorProps);
        const hasErrorsToDisplay = _.find(
          parsedErrors,
          (error) => error.location !== INHALATION && error.displayed === true,
        );

        if (hasErrorsToDisplay) {
          dispatchSetErrorsInProductionResults();
        }

        this.setInhaling(true); // finishes exhaling
        dispatchSetMonitorSubProductionInProgressFlag(false);
        dispatchSetMonitorErrors(parsedErrors);
        break;
      }
      case MONITOR_HANDLER_STATUS.SINGLE_MONITOR_PRODUCTION_END: {
        const { productionErrors } = payload.monitorResult;
        const { updatedProduction } = payload;
        const parsedErrors = parsePayloadErrors(productionErrors, monitorProps);

        this.setInhaling(false); // finishes inhaling
        dispatchSetMonitorSubProductionInProgressFlag(false);
        dispatchSetMonitorErrors(parsedErrors);
        dispatchSetMonitorProduction(updatedProduction);
        break;
      }
      default:
        break;
    }
  };

  renderInhaleInstruction = () => (this.state.inhaling ? (
    <InhaleInstructionWrapper>
      <Text
        textColor={TEXT_COLOR.DARK}
        size={TEXT_SIZE.T2}
        textTransform="uppercase"
        weight="bold"
      >
        {this.props.t('Common.UI.inhale')}
      </Text>
    </InhaleInstructionWrapper>
  ) : null);

  // This function renders some of the toast errors that should be positioned absolutely according to the screen
  renderStaticMonitorsFeedback() {
    const {
      monitorErrors,
      productionDuration,
      monitorProps,
    } = this.props;
    if (this.state.inhaling) {
      const currentMonitorType = monitorProps.pattern.type;
      const errorsToRender = defineErrorsList(currentMonitorType, monitorErrors);

      return (
        <>
          {errorsToRender.map((error) => (
            <MonitorFeedbackTooltip
              isVideoChat
              location={error.location}
              error={error.name}
              key={`${error.location}-${error.type}`}
              duration={productionDuration}
            />
          ))}
        </>
      );
    }
  }

  renderMonitorsFeedbacks(listAmountsOfSyllables) {
    const {
      monitorProps,
      production,
      monitorErrors,
    } = this.props;
    const productionDuration = monitorProps?.pattern?.productionDuration;

    if (this.state.inhaling) {
      // display production errors
      let displayedErrors = monitorErrors.filter(
        (error) => error.location !== INHALATION && error.displayed,
      );
      displayedErrors = checkErrorsAndGetNames(displayedErrors);
      if (displayedErrors.length === 0) {
        return <MonitorFeedbackTooltip location={PRODUCTION} error={GREAT} />;
      }

      // some cases where toast error should be positioned as absolute were moved to the separate function renderStaticMonitorsFeedback()
      // this filter function is used to find them and remove from array
      displayedErrors = filterForStaticMonitorErrors(monitorProps.pattern.type, displayedErrors);

      return (
        <>
          {displayedErrors.map((error) => (
            <MonitorFeedbackTooltip
              location={error.location}
              error={error.name}
              key={`${error.location}-${error.type}`}
              duration={listAmountsOfSyllables ?? productionDuration}
            />
          ))}
        </>
      );
    }
    // display inhalation errors
    if (production === 0) return null;
    let displayedErrors = monitorErrors.filter(
      (error) => error.location === INHALATION && error.displayed,
    );
    displayedErrors = checkErrorsAndGetNames(displayedErrors);
    if (displayedErrors.length === 0) {
      return null;
    }

    return (
      <>
        {displayedErrors.map((error) => (
          <MonitorFeedbackTooltip
            location={error.location}
            error={error.name}
            key={`${error.location}-${error.type}`}
          />
        ))}
      </>
    );
  }

  renderMonitors() {
    const {
      showVideoMonitor,
      monitorProps,
      showMonitorSettings,
      calibrationStatus,
      startNoiseCalibration,
      dispatchChangeCalibrationStatus,
      microphoneStarted,
      isVideoChatConnected,
    } = this.props;

    if (monitorProps.monitor?.type === 'NONE') return null;

    const props = {
      calibrationStatus,
      startNoiseCalibration,
      dispatchChangeCalibrationStatus,
      microphoneStarted,
      ...monitorProps,
    };

    // Redefine 'updateMonitorResults' in props
    props.updateMonitorResults = this.updateExerciseProgression;

    return (
      <MonitorVideoWrapper>
        <HeaderMonitor>
          <NoiseCalibration />
          <ButtonsWrapper>
            {showMonitorSettings && <MonitorSettings />}
            {!isVideoChatConnected && <MonitorSettingsBtn />}
            <MonitorCloseBtn />
          </ButtonsWrapper>
        </HeaderMonitor>
        <MonitorWrapperVideoChat withMirror={false}>
          <MonitorWithErrors>
            {showVideoMonitor && this.renderStaticMonitorsFeedback()}
            {monitorProps.pattern && <DSPHandler {...props} />}
            {showVideoMonitor && this.renderMonitorsFeedbacks(videoChatSyllablesAmount)}
          </MonitorWithErrors>
          {showVideoMonitor && this.renderInhaleInstruction()}
        </MonitorWrapperVideoChat>
      </MonitorVideoWrapper>
    );
  }

  render() {
    const { showVideoMonitor, monitorProps } = this.props;
    const { isLoading } = this.state;

    if (isLoading) {
      return (
        <LoadingWrapper>
          <SectionLoading />
        </LoadingWrapper>
      );
    }
    return (
      <>
        <VideoCall>
          <VideoCall.Container>
            <VideoCall.SelfContainer />
            <VideoCall.OtherVideos />
            <VideoCall.TextChatWrapper />
          </VideoCall.Container>
        </VideoCall>
        {showVideoMonitor && monitorProps.pattern && this.renderMonitors()}
      </>
    );
  }
}

export const mapStateToProps = (state) => {
  const isVideoChatConnected = state.getIn(['videoChat', 'connectedToChat']);
  const showVideoMonitor = state.getIn(['videoChat', 'showMonitor']);
  const monitorProps = state.getIn(['videoChat', 'monitorProps']);
  const error = state.getIn(['videoChat', 'error']);
  const showMonitorSettings = state.getIn(['videoChat', 'monitorSettings']);
  const currentPractice = state.getIn(['currentPractice']).toJS();
  const {
    currentExercise,
  } = currentPractice;
  const monitorHandler = state.getIn(['monitorHandler']) ? state.getIn(['monitorHandler']).toJS() : {};
  const {
    production,
    monitorActive,
    microphoneStarted,
    exercisePaused,
    subProductionInProgress,
    monitorErrors,
    currentSequenceIndex,
    repetitionInSequenceIndex,
    repetitionsInSequence,
    repetitionsResults,
    startNoiseCalibration,
    calibrationStatus,
  } = monitorHandler;

  const { activityContext } = state.getIn(['course']) ? state.getIn(['course']).toJS() : {};

  return {
    production,
    monitorActive,
    microphoneStarted,
    exercisePaused,
    subProductionInProgress,
    monitorErrors,
    currentSequenceIndex,
    repetitionInSequenceIndex,
    repetitionsInSequence,
    repetitionsResults,
    startNoiseCalibration,
    currentExercise,
    error,
    activityContext,
    showVideoMonitor,
    monitorProps,
    showMonitorSettings,
    calibrationStatus,
    isVideoChatConnected,
  };
};

export function mapDispatchToProps(dispatch) {
  return {
    dispatchSaveActivityProduction: (payload) => dispatch(saveActivityProduction(payload)),
    dispatchSetMonitorErrors: (errors) => dispatch(setMonitorErrors(errors)),
    dispatchSetMonitorSubProductionInProgressFlag: (value) => dispatch(setMonitorSubProductionInProgressFlag(value)),
    dispatchSetErrorsInProductionResults: () => dispatch(setErrorsInProductionResults()),
    dispatchSetMonitorProduction: (production) => dispatch(setMonitorProduction(production)),
    dispatchToggleShowMonitorVideoChat: (value, showDropdown) => dispatch(toggleShowMonitorVideoChat(value, showDropdown)),
    dispatchChangeCalibrationStatus: (status) => dispatch(changeCalibrationStatus(status)),
  };
}

const withConnect = connect(mapStateToProps, mapDispatchToProps);
const withReducer = injectReducer({ key: 'videoChat', reducer });
const withSaga = injectSaga({ key: 'videoChat', saga });

export default compose(withTranslation(), withSaga, withReducer, withConnect)(VideoChat);
