import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { withRouter } from 'react-router-dom';
import _ from 'lodash';
import { getUSADateFromFullDate } from '~/utils/timeHelpers';
import Text, { TEXT_COLOR } from '@components/Text';
import Container from '@components/Container';
import CTAButton from '@components/CTAButton';
import FeedbackList from '@components/FeedbackList';
import PracticeInsights from '../../components/PracticeInsights';
import {
  Wrapper,
  MainPartContainer,
  HeaderContainer,
  StartOverContainer,
  CompletingContainer,
  CompletingTitleContainer,
  OptionalPartContainer,
  PracticeIcon,
  RestartIcon,
  CheckmarkIcon,
  ButtonsContainer,
  FinishDateContainer,
} from './styles';
import { getSortedFeedbacks } from '@containers/User/helpers/feedbackHelpers';
import { withTranslation } from 'react-i18next';

class PracticeSummary extends Component {
  getJustFinishedPractice = () => {
    const {
      justFinished,
      goToNextPractice,
      backToCoursePage,
      t
    } = this.props;

    if (!justFinished) return null;

    return (
      <CompletingContainer>
        <CompletingTitleContainer>
          <CheckmarkIcon />
          <Text weight='bold' size='T0' textColor={TEXT_COLOR.DARK}>
            {t('Feedback.greatJob')}
          </Text>
        </CompletingTitleContainer>
        {goToNextPractice && (
          <Container column>
            <Text size='H4' textColor={TEXT_COLOR.DARK_BLUE}>
              {t('Informative.Recommending.onePerDay')}
            </Text>
            <Text size='H4' textColor={TEXT_COLOR.DARK_BLUE}>
              {t('Informative.Confirming.continueTomorrow')}
            </Text>
          </Container>
        )}
        <ButtonsContainer>
          {goToNextPractice && (
            <CTAButton secondary onClick={() => goToNextPractice()}>
              {t('Actions.Navigation.nextPractice')}
            </CTAButton>
          )}
          <CTAButton onClick={() => backToCoursePage()}>
            {t('Actions.Navigation.backToOverview')}
          </CTAButton>
        </ButtonsContainer>
      </CompletingContainer>
    );
  };

  render() {
    const {
      practice,
      justFinished,
      t,
      i18n
    } = this.props;
    const feedbacks = getSortedFeedbacks(practice.Activities);
    const hasFeedback = feedbacks.length > 0;
    const firstCompletedActivity = _.find(
      practice.Activities,
      (activity) =>
        activity.summary.hasPassed || activity.summary.achievedMasteryLevel > 40
    );
    const completionDate = _.get(
      firstCompletedActivity,
      'summary.passedAt',
      new Date()
    );
    const insights = _.get(firstCompletedActivity, 'summary.insights', []);
    const hasInsights = insights.length > 0;

    return (
      <Wrapper>
        <MainPartContainer>
          <HeaderContainer>
            <Container column alignItems='flex-start'>
              <Container alignItems='center' margin='0 0 5px 0'>
                <PracticeIcon />
                <Text
                  size='T2'
                  textColor={TEXT_COLOR.DARK_BLUE}
                  textTransform='capitalize'
                  weight={i18n.language === 'he' ? 'bold' : 'normal'}
                >
                  {practice.name}
                </Text>
              </Container>
              {!justFinished && (
                <FinishDateContainer>
                  <Text size='H4' textColor={TEXT_COLOR.DARK_BLUE}>
                    {t('Dates.completedOn', {date: getUSADateFromFullDate(completionDate)})}
                  </Text>
                </FinishDateContainer>
              )}
            </Container>
            <StartOverContainer onClick={this.props.restartPractice}>
              <RestartIcon />
              <Text size='T2' weight={i18n.language === 'he' ? 'bold' : 'normal'}>
                {t('UI.startOver')}
              </Text>
            </StartOverContainer>
          </HeaderContainer>
          {this.getJustFinishedPractice()}
        </MainPartContainer>
        <OptionalPartContainer>
          {hasInsights && <PracticeInsights insights={insights} />}
          {hasFeedback && <FeedbackList withTitle feedbacks={feedbacks} />}
        </OptionalPartContainer>
      </Wrapper>
    );
  }
}

PracticeSummary.propTypes = {
  practice: PropTypes.object,
  restartPractice: PropTypes.func,
  goToNextPractice: PropTypes.func,
  backToCoursePage: PropTypes.func,
  justFinished: PropTypes.bool,
  history: PropTypes.object,
};

export default compose(
    withRouter,
    withTranslation()
)(PracticeSummary);
