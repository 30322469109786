import Text, { TEXT_COLOR, TEXT_SIZE } from '@components/Text';
import { WITHOUT_PROGRESS } from '@shared/constants';
import { PROGRESS_TYPE } from '@shared/Resources/Monitor/types';

export const ProgressCounter = ({
  progressType,
  currentActivity,
  progressTotalAmount,
}) => {
  const progressCounter = () => {
    switch (progressType) {
      case PROGRESS_TYPE.TIMER: return currentActivity
      case PROGRESS_TYPE.SEQUENCES: return `${currentActivity}/${progressTotalAmount}`
      default: return WITHOUT_PROGRESS
    }
  };

  return (
    <Text
      textColor={TEXT_COLOR.NORMAL}
      size={progressType === PROGRESS_TYPE.TIMER ? TEXT_SIZE.T1 : TEXT_SIZE.T4}
      margin={progressType === PROGRESS_TYPE.TIMER && '0 0 0 20px'}
    >
      {progressCounter()}
    </Text>
  );
};
