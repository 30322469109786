/**
 *
 * OverviewListItem
 *
 */

import { Component } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { RESOURCES } from '@shared/Resources/types';
import Loading from '@components/SectionLoading';
import Button from '@components/OverviewButton';
import OverviewBaseListItem from './components/OverviewBaseListItem';
import OverviewSectionPartial from '../OverviewSection/components/OverviewSectionPartial';
import {
  ListItem,
  StyledArrowRightIcon,
  StyledArrowDownIcon,
  Item,
  SubList,
} from './styles';
import { formatSectionsByTypeMap } from './helpers';

class OverviewListItem extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isOpen: false,
    };
  }

  componentDidMount() {
    if (this.props.hasCurrentLevel) {
      this.setState({ isOpen: true })
    }
  }

  shouldComponentUpdate(nextProps) {
    if (
      nextProps.areItemsExpanded &&
      !this.props.areItemsExpanded &&
      this.props.deepList &&
      !this.state.isOpen
    ) {
      this.onItemToggle();
      return false;
    }
    if (
      nextProps.areItemsCollapsed &&
      !this.props.areItemsCollapsed &&
      this.props.deepList &&
      this.state.isOpen
    ) {
      this.onItemToggle();
      return false;
    }
    return true;
  }

  onItemToggle = () => {
    const { isOpen } = this.state;
    const newOpenState = !isOpen;
    const {
      resource: { type, payload, id },
      onExpand,
      onItemStateChange,
      isUserPageProgram,
    } = this.props;
    this.setState({ isOpen: newOpenState });
    if (newOpenState) {
      onItemStateChange(true);
      !isUserPageProgram && onExpand({ type, payload, id });
    } else {
      onItemStateChange(false);
    }
  };

  renderButton = (
    WrapperElement,
    onClickFunction,
    className,
    message,
    full = false
  ) => {
    const {
      resource: { type, payload, id },
    } = this.props;
    return (
      <WrapperElement>
        <Button
          full={full}
          onClick={() => onClickFunction({ type, payload, id })}
          className={className}
        >
          <span>{message}</span>
        </Button>
      </WrapperElement>
    );
  };

  renderSections = () => {
    const {
      resource,
      onView,
      onEdit,
      onMoveToCourseLevel,
      createMiniOverview,
      readOnly,
      isUserPageProgram,
    } = this.props;

    let sectionsByTypeMap;
    if (isUserPageProgram) {
      const children = [];
      Object.keys(resource).forEach((item) => Array.isArray(resource[item]) && children.push(...resource[item]));
      sectionsByTypeMap = formatSectionsByTypeMap(
        children,
        onView,
        onEdit,
        onMoveToCourseLevel,
        isUserPageProgram,
        createMiniOverview,
        readOnly,
      )
    } else {
      sectionsByTypeMap = formatSectionsByTypeMap(
        resource.children,
        onView,
        onEdit,
        onMoveToCourseLevel,
        isUserPageProgram,
        createMiniOverview,
        readOnly,
      )
    }
    return _.keys(sectionsByTypeMap).map((type) => (
      <OverviewSectionPartial
        className='subListSection'
        key={type}
        {...sectionsByTypeMap[type]}
        isInnerList
        flatChildren
      />
    ));
  };

  renderSubList = () => {
    const {
      resource,
      isUserPageProgram,
    } = this.props;
      return (
        <SubList isUserPageProgram={isUserPageProgram}>
          {resource ? this.renderSections(resource) : <Loading className='loading' />}
        </SubList>
      );
  };

  render() {
    const {
      deepList,
      resource: { type },
      isUserPageProgram,
      onMoveToCourseLevel,
      hasCurrentLevel,
    } = this.props;
    const { isOpen } = this.state;
    const ToggleIcon = isOpen ? StyledArrowDownIcon : StyledArrowRightIcon;

    return (
      <ListItem isExpandableItem={type === RESOURCES.SKILL || deepList}>
        {deepList && (
          <ToggleIcon
            onClick={this.onItemToggle}
            className='expandItemButton'
          />
        )}
        <Item>
          <OverviewBaseListItem
            {...this.props}
            isExpandable={deepList}
            onExpand={this.onItemToggle}
            isUserPageProgram={isUserPageProgram}
            onMoveToCourseLevel={onMoveToCourseLevel}
            isFirstElement={deepList}
            hasCurrentLevel={hasCurrentLevel}
          />
        </Item>
        {isOpen && this.renderSubList()}
      </ListItem>
    );
  }
}

OverviewListItem.defaultProps = {
  deepList: false,
};

OverviewListItem.propTypes = {
  // view component props
  readOnly: PropTypes.bool,
  deepList: PropTypes.bool,
  // viewed resource props
  resource: PropTypes.shape({
    children: PropTypes.array,
    type: PropTypes.string.isRequired,
    payload: PropTypes.object.isRequired,
    id: PropTypes.string.isRequired,
  }),
  onView: PropTypes.func,
  onEdit: PropTypes.func,
  onExpand: PropTypes.func,
  createMiniOverview: PropTypes.func, // injected from factory
  areItemsExpanded: PropTypes.bool,
  areItemsCollapsed: PropTypes.bool,
  onItemStateChange: PropTypes.func,
};

export default OverviewListItem;
