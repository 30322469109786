import {styled} from "@mui/material";
import CTAButton from '@components/CTAButton';
import { getResponsiveCSS } from '@utils/cssHelpers';

export const Wrapper = styled('div')(
  {
    borderRadius: 4,
    paddingBottom: 5,
  },
  getResponsiveCSS('width', 820, 900),
  getResponsiveCSS('paddingTop', 47, 52),
  getResponsiveCSS('paddingLeft', 30, 40),
  getResponsiveCSS('paddingRight', 30, 40),
  ({ theme }) => ({
    direction: theme.RTL ? 'rtl' : 'ltr',
    backgroundColor: theme.palette.novotalk.$backgroundColor,
    color: theme.palette.novotalk.$textColorDark,
  })
);

export const HeaderContainer = styled('div')(
  {
    marginBottom: 20,
    borderBottom: '1px solid',
  },
  getResponsiveCSS('paddingBottom', 8, 18),
  ({ theme }) => ({
    borderColor: theme.palette.novotalk.$borderColorLight,
  })
);

export const EnterPracticeHeader = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  marginTop: -12,
  marginBottom: 12,
  lineHeight: '26px',
});

export const PracticeHeader = styled('div')({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'flex-end',
});

export const StyledImage = styled('img')({
  display: 'block',
  width: 90,
  margin: '0 auto 20px',
});

export const StyledCTAButton = styled(CTAButton)(
  {
    width: 220,
    height: 44,
    margin: '0 auto',
  },
  getResponsiveCSS('height', 44, 48),
  getResponsiveCSS('marginBottom', 15, 25),
  getResponsiveCSS('marginTop', -10, 0)
);
