import {
  takeLatest, call, put, all, fork,
} from 'redux-saga/effects';
import { signUp } from '@services/authenticationService';
import {
  Types,
  onSignUpSuccess,
  onSignUpFail,
  onLoadingSwitch,
} from './actions';
import { openPopUp } from '../../PopUp/actions';
import { TYPES } from '../../PopUp/popups/SignUpUnavailable';
import POPUP_TYPES from '../../PopUp/types';

const EXIST_EMAIL_MESSAGE = 'Email already exists';
const BACK_BUTTON_TEXT = 'Back';

const popUpSagaHelper = (list) => {
  if (list[0]) return TYPES.COUNTRY;
  if (list[1]) return TYPES.STATE;
  if (list[2]) return TYPES.AGE;
};

function* callPostSignUpData(action) {
  try {
    const {
      firstName,
      lastName,
      email,
      birthdate,
      password,
      country,
      state,
      available,
      locale,
    } = action.payload;
    yield put(onLoadingSwitch(true));

    const response = yield call(
      signUp,
      firstName,
      lastName,
      email,
      password,
      birthdate,
      country,
      state,
      locale,
    );

    const {
      underAge, unavailableCountry, unavailableState, message,
    } = response;
    if (underAge || unavailableCountry || unavailableState) {
      yield put(
        openPopUp(POPUP_TYPES.SIGN_UP_UNAVAILABLE, {
          type: popUpSagaHelper([
            unavailableCountry,
            unavailableState,
            underAge,
          ]),
          hideCloseIcon: true,
        }),
      );
    }
    if (message === EXIST_EMAIL_MESSAGE) {
      yield put(
        openPopUp(POPUP_TYPES.ALERT, {
          titleText: message,
          buttonText: BACK_BUTTON_TEXT,
        }),
      );
    } else {
      yield put(onSignUpSuccess(available));
    }
    yield put(onLoadingSwitch(false));
  } catch (error) {
    yield put(onSignUpFail(error.message));
    yield put(onLoadingSwitch(false));
  }
}

function* watchCallPostSignUpData() {
  yield takeLatest(Types.SIGN_UP, callPostSignUpData);
}

export default function* watchLoginSignActions() {
  yield all([fork(watchCallPostSignUpData)]);
}
