import { USER_STATUS } from '@containers/Admin/containers/UserPage/consts';
import { capitalizeScreamingCase } from '@utils/textHelpers';
import { UserStatus } from './types';

export const SELECTOR_WIDTH: number = 240;

export const USER_STATUS_OPTIONS: Array<UserStatus> = [
  { label: capitalizeScreamingCase(USER_STATUS.ACTIVE), value: 'CONFIRMED' },
  { label: capitalizeScreamingCase(USER_STATUS.INACTIVE), value: 'BLOCKED' },
];
