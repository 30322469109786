import { fromJS } from 'immutable';
import { TYPES } from './actions';

// The initial state of the Learn
export const initialState = fromJS({
  lesson: {},
  lessonInProgress: false,
  loading: true,
  currentLessonActivity: {},
  error: '',
});

function lessonReducer(state = initialState, action) {
  switch (action.type) {
    case TYPES.SET_CURRENT_LESSON:
      return state.set('lesson', action.lesson).set('loading', false);
    case TYPES.SET_ACTIVITY_LESSON:
      return state.set('currentLessonActivity', action.activity);
    case TYPES.START_ACTIVITY_LESSON:
      return state.set('lessonInProgress', true);
    case TYPES.END_ACTIVITY_LESSON:
      return state.set('lessonInProgress', false);
    case TYPES.RESET_ACTIVITY_LESSON:
      return state.set('currentLessonActivity', {});
    case TYPES.RESET_LESSON:
      return state.set('lesson', {});
    case TYPES.SET_ERROR_LESSON:
      return state.set('error', action.error);
    default:
      return state;
  }
}

export default lessonReducer;
