import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import Text, { TEXT_COLOR } from '@components/Text';
import CTAButton from '@components/CTAButton';
import { TYPES } from '@shared/Resources/types';
import {
  Wrapper,
  TitleContainer,
  MessageContainer,
  InProgressMessageContainer,
  ButtonsContainer,
  AlertIcon,
} from './styles';
import {withTranslation} from "react-i18next";

const getPopupText = (type, textType) => {
    switch (textType) {
        case "caution":
            return `Informative.Notifying.${type === TYPES.PRACTICE ? "activityWaiting" : "lessonNotFinished"}`
        case "question":
            return `Informative.Confirming.${type === TYPES.PRACTICE ? "wantToQuit" : "areYouSure"}`
        case "quit":
            return `Actions.Confirmations.${type === TYPES.PRACTICE ? "quitAnyway" : "finishLater"}`
        case "continue":
            return `Actions.Confirmations.${type === TYPES.PRACTICE ? "continuePracticing" : "continueLesson"}`

    }
}

class ExerciseExit extends Component {
  getMessage = () => {
    const {
      exerciseInProgress,
      type,
      t
    } = this.props;
    if (type === TYPES.PRACTICE && exerciseInProgress) {
      return (
        <InProgressMessageContainer>
          <Text
            size='H4'
            textColor={TEXT_COLOR.DARK_BLUE}
            className='popup-text'
          >
            {t('Informative.Notifying.unfinishedExerciseAlert')}
          </Text>
          <Text
            size='H4'
            textColor={TEXT_COLOR.DARK_BLUE}
            className='popup-text'
          >
              {t('Informative.Recommending.exerciseCompletionRecommendation')}
          </Text>
        </InProgressMessageContainer>
      );
    }
    return (
      <MessageContainer>
        <Text
          size='H4'
          textColor={TEXT_COLOR.DARK_BLUE}
          className='popup-text'
          margin='0 10px 0 0'
        >
            {t(getPopupText(type, 'caution'))}
        </Text>
      </MessageContainer>
    );
  };

  handleClick = (button) => () => {
    const { exitExercise, onClose } = this.props;
    onClose();
    if (button === 'quit') exitExercise();
  };

  render() {
    const {
      exerciseInProgress,
      type,
      t
    } = this.props;
    return (
      <Wrapper>
        <AlertIcon />
        <TitleContainer exerciseInProgress={exerciseInProgress}>
          <Text
            size='T1'
            textColor={TEXT_COLOR.DARK_BLUE}
            weight='bold'
            className='popup-title'
          >
              {t(getPopupText(type, 'question'))}
          </Text>
        </TitleContainer>
        {this.getMessage()}
        <ButtonsContainer>
          <CTAButton onClick={this.handleClick('quit')} secondary>
              {t(getPopupText(type, 'quit'))}
          </CTAButton>
          <CTAButton onClick={this.handleClick('continue')}>
              {t(getPopupText(type, 'continue'))}
          </CTAButton>
        </ButtonsContainer>
      </Wrapper>
    );
  }
}

ExerciseExit.defaultProps = {
  type: TYPES.PRACTICE,
};

ExerciseExit.propTypes = {
  onClose: PropTypes.func.isRequired,
  exitExercise: PropTypes.func,
  exerciseInProgress: PropTypes.bool,
  type: PropTypes.string,
};

export default compose(withTranslation())(ExerciseExit);
