import { RESOURCES } from '@shared/Resources/types';

// as saved on db
export const skillDB = {
  id: '1',
  name: 'breathing',
  texts: {
    description: 'Breathing is the basis of speaking. In this skill you will learn how to breathe correctly and to coordinate breathing and voice production',
  },
  type: RESOURCES.SKILL,
  children: [
    {
      id: 'l1',
      type: RESOURCES.LESSON,
    },
    {
      id: 'l2',
      type: RESOURCES.LESSON,
    },
    {
      id: 'l3',
      type: RESOURCES.LESSON,
    },
    {
      id: 'p1',
      type: RESOURCES.PRACTICE,
    },
    {
      id: 'p2',
      type: RESOURCES.PRACTICE,
    },
    {
      id: 'p3',
      type: RESOURCES.PRACTICE,
    },
    {
      id: 'p4',
      type: RESOURCES.POLL,
    },
  ],
};


export const gql = `{
  //common
  type
  id
  createdBy
  name

  // special
  description
  children:{
    id
    type
  }


  //----- edges:
  entityCreated{
    by
    at
  }
  entityUpdated {
    by
    at
  }
  //array
  usedIn{
    type
    id
  }
}`;


export const skillGQL = {
  id: '1',
  name: 'breathing',
  description: 'Breathing is the basis of speaking. In this skill you will learn how to breathe correctly and to coordinate breathing and voice production',
  type: RESOURCES.SKILL,
  children: [
    {
      id: 'l1',
      name: 'basics',
      type: RESOURCES.LESSON,
    },
    {
      id: 'l2',
      name: 'increasing breath capacity',
      type: RESOURCES.LESSON,
    },
    {
      id: 'l3',
      name: 'volume control',
      type: RESOURCES.LESSON,
    },
    {
      id: 'p1',
      duration: 10,
      name: 'breathing beginner',
      type: RESOURCES.PRACTICE,
    },
    {
      id: 'p2',
      duration: 15,
      name: 'breathing advanced',
      type: RESOURCES.PRACTICE,
    },
    {
      id: 'p3',
      duration: 20,
      name: 'breathing expert',
      type: RESOURCES.PRACTICE,
    },
  ],
};


export const skillsList = [
  { id: 1, contains: { LESSON: 4, PRACTICE: 1, POLL: 0 }, name: 'Breating', created: 'Thu Aug 09 2018 08:46:00 GMT+0000 (UTC)', updated: 'Thu SEP 06 2018 08:46:00 GMT+0000 (UTC)' },
  { id: 2, contains: { LESSON: 4, PRACTICE: 1, POLL: 0 }, name: 'Relaxation', created: 'Thu Aug 16 2018 18:46:30 GMT+0000 (UTC)', updated: 'Thu SEP 13 2018 18:46:30 GMT+0000 (UTC)' },
  { id: 3, contains: { LESSON: 4, PRACTICE: 1, POLL: 0 }, name: 'Voice Production', created: 'Thu Aug 23 2018 02:46:54 GMT+0000 (UTC)', updated: 'Thu SEP 20 2018 02:46:54 GMT+0000 (UTC)' },
  { id: 4, contains: { LESSON: 4, PRACTICE: 1, POLL: 0 }, name: '2 Second Pattern', created: 'Thu Aug 30 2018 23:46:15 GMT+0000 (UTC)', updated: 'Thu SEP 27 2018 23:46:15 GMT+0000 (UTC)' },
  { id: 5, contains: { LESSON: 4, PRACTICE: 1, POLL: 0 }, name: '1 Second Pattern', created: 'Thu Aug 30 2018 23:46:15 GMT+0000 (UTC)', updated: 'Thu SEP 27 2018 23:46:15 GMT+0000 (UTC)' },
  { id: 6, contains: { LESSON: 4, PRACTICE: 1, POLL: 0 }, name: '1 Second with intonation', created: 'Thu Aug 30 2018 23:46:15 GMT+0000 (UTC)', updated: 'Thu SEP 27 2018 23:46:15 GMT+0000 (UTC)' },
];
