import PropTypes from 'prop-types';
import Text from '../Text';
import {useTranslation} from "react-i18next";

export const ErrorName = ({ additionalTextProps, errorType }) => {
  const {t} = useTranslation()
  return (
      <Text className="errorNameWrapper" {...additionalTextProps}>
        {t(`Errors.${errorType}`)}
      </Text>
  );
}

ErrorName.propTypes = {
  additionalTextProps: PropTypes.object,
  errorType: PropTypes.string,
};