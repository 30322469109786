import {
  STIMULUS_DIVISION_TYPE,
  STIMULUS_SPEECH_TOPICS_TYPES,
  STIMULUS_PICTURES_TYPES,
  STIMULUS_WORDS_COUNT,
  STIMULUS_CONSONANTS_FEATURES,
  STIMULUS_SYLLABLE_STRUCTURE,
  INTONATION,
} from '../../../types';

export const defaults = {
  SYLLABLES: {
    syllableStructure: { [STIMULUS_SYLLABLE_STRUCTURE.v]: true },
    consonantsFeatures: { [STIMULUS_CONSONANTS_FEATURES.PLOSIVE]: true },
  },
  WORDS: {
    wordType: {
      REAL: true,
    },
    division: STIMULUS_DIVISION_TYPE.DIVIDED,
    syllableCount: { min: 1, max: 6 },
    syllableStructure: { [STIMULUS_SYLLABLE_STRUCTURE.v]: true },
    consonantsFeatures: { [STIMULUS_CONSONANTS_FEATURES.PLOSIVE]: true },
  },
  SENTENCES: {
    division: STIMULUS_DIVISION_TYPE.DIVIDED_TO_WORDS_SYLLABLES,
    intonation: {
      [INTONATION.FACT]: true,
      [INTONATION.QUESTION]: true,
    },
    wordCount: {
      [STIMULUS_WORDS_COUNT.SHORT]: true,
    },
  },
  PARAGRAPHS: {
    wordCount: {
      [STIMULUS_WORDS_COUNT.SHORT]: true,
    },
  },
  SPEECH_TOPICS: {
    speechTopicsType: {
      [STIMULUS_SPEECH_TOPICS_TYPES.BASIC]: true,
    },
  },
  PICTURES: {
    picturesType: { [STIMULUS_PICTURES_TYPES.BASIC]: true },
  },
  CUSTOM: {
    customText: 'Some Text',
  },
};
