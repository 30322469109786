import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Text, { TEXT_COLOR, TEXT_SIZE } from '@components/Text';

import {
  TooltipWrapper,
  StyledHelpIcon,
  StyledTooltip,
  getPlacementClass,
  placementTypes as types,
} from './styles';

export const placementTypes = types;

class CustomTooltip extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isTooltipShown: false,
    };
  }

  render() {
    const { classes, text, placement, tiny } = this.props;
    const children = this.props.children || (
      <StyledHelpIcon className='custom-tooltip-icon' />
    );
    const placementClass = getPlacementClass(placement, tiny);

    return (
      <TooltipWrapper
        className={`custom-tooltip-wrapper ${classes}`}
        onMouseEnter={() => this.setState({ isTooltipShown: true })}
        onMouseLeave={() => this.setState({ isTooltipShown: false })}
      >
        {this.state.isTooltipShown && (
          <StyledTooltip
            className={`${placementClass} custom-tooltip-text`}
            tiny={tiny}
          >
            <Text textColor={TEXT_COLOR.DARK_BLUE} size={TEXT_SIZE.T5}>
              {text}
            </Text>
          </StyledTooltip>
        )}
        {children}
      </TooltipWrapper>
    );
  }
}

CustomTooltip.defaultProps = {
  classes: '',
};

CustomTooltip.propTypes = {
  classes: PropTypes.string,
  text: PropTypes.string.isRequired,
  placement: PropTypes.string,
  children: PropTypes.node,
  tiny: PropTypes.bool,
};

export default CustomTooltip;
