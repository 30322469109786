import {styled} from "@mui/material";

export const SummaryColumn = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  minWidth: 170,
  margin: '0 0 10px 0',
})

export const SummaryWrapper = styled('div')({
  display: 'flex',
  justifyContent: 'space-between',
  marginBottom: 20,
})
