import {styled} from "@mui/material";

const ToggleButton = styled('div')(
  {
    width: 112,
    height: 32,
    borderRadius: 4,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    fontSize: 13,
  },
  ({ theme, isSelected }) => ({
    backgroundColor: isSelected ? theme.palette.novotalk.$outlineButtonColorActive : '',
    color: isSelected ? theme.palette.novotalk.$outlineButtonColorReversed : '',
  }),
  ({ theme, isSelected, disabled }) => {
    if (disabled) {
      return {
        pointerEvents: 'none',
        color: isSelected ? theme.palette.novotalk.$textColorReversed : theme.palette.novotalk.$textColorDisabled,
        backgroundColor: isSelected
          ? theme.palette.novotalk.$buttonColorDisabled
          : theme.palette.novotalk.$backgroundColor,
        border: isSelected
          ? 'none'
          : `1px solid ${theme.palette.novotalk.$toggleButtonBorderColor}`,
      };
    }
    return {
      color: isSelected ? theme.palette.novotalk.$textColorReversed : theme.palette.novotalk.$textColorDarkBlue,
      border: isSelected
        ? 'none'
        : `1px solid ${theme.palette.novotalk.$toggleButtonBorderColor}`,
      backgroundColor: isSelected
        ? theme.palette.novotalk.$actionButtonColor
        : theme.palette.novotalk.$backgroundColor,
      '&:hover': {
        border: 'none',
        backgroundColor: isSelected
          ? theme.palette.novotalk.$actionButtonColorHover
          : theme.palette.novotalk.$toggleButtonBgColorHover,
      },
      '&:active': {
        border: 'none',
        backgroundColor: isSelected
          ? theme.palette.novotalk.$actionButtonColorActive
          : theme.palette.novotalk.$toggleButtonBgColorActive,
      },
    };
  }
);

export default ToggleButton;
