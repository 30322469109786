import React from 'react';
import PropTypes from 'prop-types';
import OverviewSection from '@shared/Resources/shared/components/OverviewSection';
import { EXERCISE } from '../../types';

const PracticeOverview = ({ resource: { children },
  onEdit,
  onView,
  onExpand,
  readOnly }) => {
  const itemProps = {
    items: children,
    type: EXERCISE,
    onEdit,
    onView,
    onExpand,
    readOnly,
  };
  return <OverviewSection {...itemProps} />;
};

PracticeOverview.propTypes = {
  readOnly: PropTypes.bool,
  resource: PropTypes.object,
  onEdit: PropTypes.func,
  onView: PropTypes.func,
  onExpand: PropTypes.func,
};

export default PracticeOverview;
