import {styled} from "@mui/material";
import { css } from 'emotion';
import { getIconsByType, iconTypes } from '@services/icons';
import { getResponsiveCSS } from '@utils/cssHelpers';

const HelpIcon = getIconsByType(iconTypes.HELP_FILLED);

export const placementTypes = {
  TOP: 'TOP',
  BOTTOM: 'BOTTOM',
  LEFT: 'LEFT',
  RIGHT: 'RIGHT',
};

export const StyledHelpIcon = styled(HelpIcon)(({ theme }) => ({
  fontSize: 25,
  color: theme.palette.novotalk.$activeColor,
  cursor: 'pointer',
  transition: '0.3s ease all',
}));

export const TooltipWrapper = styled('div')({
  display: 'inline-block',
  position: 'relative',
});

export const StyledTooltip = styled('div')(
  {
    position: 'absolute',
    letterSpacing: '-0.15px',
    borderRadius: 4,
    zIndex: '200',
  },
  ({ theme }) => ({
    backgroundColor: theme.palette.novotalk.$backgroundColor,
    border: `1px solid ${theme.palette.novotalk.$borderColorTooltip}`,
    boxShadow: `0 4px 32px 0 ${theme.palette.novotalk.$shadowTooltipColor}`,
  }),
  ({ theme, tiny }) => {
    if (tiny) {
      return {
        lineHeight: '0.8em',
        padding: '5px 8px',
      };
    }
    return {
      lineHeight: '17px',
      padding: 14,
      ...getResponsiveCSS('width', 186, 224),
      '&:after': {
        display: 'block',
        content: '""',
        position: 'absolute',
        width: 12,
        height: 12,
        zIndex: '201',
        backgroundColor: theme.palette.novotalk.$backgroundColor,
        borderTop: `1px solid ${theme.palette.novotalk.$borderColorTooltip}`,
        borderLeft: `1px solid ${theme.palette.novotalk.$borderColorTooltip}`,
      },
    };
  }
);

export const getPlacementClass = (placement, tiny) => {
  const mainOffset = tiny ? '110%' : '160%';
  const crossOffset = tiny ? '20%' : '50%';
  switch (placement) {
    case placementTypes.TOP:
      return css({
        bottom: mainOffset,
        left: crossOffset,
        transform: `translateX(-${crossOffset})`,
        '&:after': {
          bottom: -6,
          left: 'calc(50% - 6px)',
          transform: 'rotate(-135deg)',
        },
      });
    case placementTypes.BOTTOM:
      return css({
        top: mainOffset,
        left: crossOffset,
        transform: `translateX(-${crossOffset})`,
        '&:after': {
          top: -6,
          left: 'calc(50% - 6px)',
          transform: 'rotate(45deg)',
        },
      });
    case placementTypes.LEFT:
      return css({
        right: mainOffset,
        top: crossOffset,
        transform: `translateY(-${crossOffset})`,
        '&:after': {
          right: -6,
          top: 'calc(50% - 6px)',
          transform: 'rotate(135deg)',
        },
      });
    case placementTypes.RIGHT:
    default:
      return css({
        left: mainOffset,
        top: crossOffset,
        transform: `translateY(-${crossOffset})`,
        '&:after': {
          left: -6,
          top: 'calc(50% - 6px)',
          transform: 'rotate(-45deg)',
        },
      });
  }
};
