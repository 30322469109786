const { INTONATION, STIMULUS_WORDS_COUNT } = require("@shared/Resources/Monitor/types");

export const SENTENCES_WORDS = [
  { value: STIMULUS_WORDS_COUNT.SHORT, message: 'shortSentences' },
  { value: STIMULUS_WORDS_COUNT.MEDIUM, message: 'mediumSentences' },
  { value: STIMULUS_WORDS_COUNT.LONG, message: 'longSentences' },
];

export const INTONATION_STRUCTURE = Object.values(INTONATION);

export const NAME_INTONATION_FIELD = 'intonation';
