import Text, { TEXT_COLOR, TEXT_SIZE } from '@components/Text';
import { VersionIcon } from './styles';

const Version = ({ loading, value, label, showIcon }) => {
  return (
    <>
      {showIcon && <VersionIcon />}
      <Text
        textTransform="capitalize"
        size={TEXT_SIZE.H4}
        textColor={TEXT_COLOR.DISABLED_LIGHT}
        display="block"
      >
        {label} {loading || value === undefined ? 'Loading' : value}
      </Text>
    </>
  );
};

export default Version;
