import {styled} from "@mui/material";
import { getIconsByType, iconTypes } from '@services/icons';

const ChevronRightIcon = getIconsByType(iconTypes.CHEVRON_RIGHT);
const AddIcon = getIconsByType(iconTypes.PLUS_SQUARE);

export const StyledChevronRightIcon = styled(ChevronRightIcon)(
  {
    fontSize: 27,
  },
  ({ theme }) => ({
    color: theme.palette.novotalk.textColorDisabled,
  })
);

export const StyledAddIcon = styled(AddIcon)(
  {
    fontSize: 27,
  },
  ({ theme }) => ({
    color: theme.palette.novotalk.autoFeedbackAdd,
  })
);

export const MenuItemContainer = styled('div')({
  borderBottom: '1px solid lightgrey',
});

export const MenuItem = styled('div')({
  height: 56,
  padding: '16px 0 16px 16px',
  display: 'flex',
  justifyContent: 'space-between',
});

export const ItemText = styled('div')({
  width: 187,
  overflow: 'hidden',
  textOverflow: 'ellipsis',
});
