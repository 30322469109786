import PropTypes from 'prop-types';
import StimulusDivision from '../../shared/components/StimulusDivision';
import ToggleGroup from '../../shared/components/ToggleGroup';
import CheckBoxGroup from '../../shared/components/CheckBoxGroup';
import { STIMULUS_DIVISION_TYPE } from '../../../../../../../types';
import { SENTENCES_WORDS, NAME_INTONATION_FIELD, INTONATION_STRUCTURE } from './consts';

export const SentencesConfig = ({ config, updateField, updateConfig }) => (
  <>
    <CheckBoxGroup
      config={config}
      onChange={updateConfig}
      groupName={NAME_INTONATION_FIELD}
      groupValues={INTONATION_STRUCTURE}
      capitalize
    />
    <StimulusDivision
      value={config.division}
      onChange={updateField('division')}
      dividedValue={STIMULUS_DIVISION_TYPE.DIVIDED_TO_WORDS_SYLLABLES}
      dividedMessage={'dividedIntoWordsAndSyllables'}
    />
    <ToggleGroup
      config={config}
      onChange={updateConfig}
      groupName="wordCount"
      groupValues={SENTENCES_WORDS}
    />
  </>
);

SentencesConfig.propTypes = {
  config: PropTypes.object,
  updateField: PropTypes.func,
  updateConfig: PropTypes.func,
};
