export const OneSecondWave = () => (
  <svg
    width="220"
    height="83"
    viewBox="0 0 220 83"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <mask
      id="mask0_1195_1264"
      maskUnits="userSpaceOnUse"
      x="110"
      y="0"
      width="110"
      height="83"
    >
      <rect
        width="110"
        height="83"
        transform="matrix(-1 0 0 1 220 0)"
        fill="#D9D9D9"
      />
    </mask>
    <g mask="url(#mask0_1195_1264)">
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M-926 82.6317L220 82.6317C220 82.6317 176.89 73.8671 167.397 59.8409C147.692 30.7247 144.918 1.31592 109.929 1.31592C60.5678 1.31592 60.5678 63.5788 14.3268 63.5788C-31.9142 63.5788 -31.9142 1.40569 -78.1552 1.40569C-124.396 1.40569 -124.396 63.5788 -170.637 63.5788C-216.878 63.5788 -216.878 1.40569 -263.119 1.40569C-309.36 1.40569 -309.36 63.5788 -355.601 63.5788C-401.842 63.5788 -401.842 1.40569 -448.083 1.40569C-494.324 1.40569 -494.324 63.5788 -540.565 63.5788C-586.807 63.5788 -586.807 1.40569 -633.048 1.40569C-679.289 1.40569 -679.289 63.5788 -725.53 63.5788C-771.771 63.5788 -773.77 1.40569 -820.011 1.40569C-857.278 1.40569 -868.897 47.2753 -891.329 63.5788C-901.754 71.1556 -926 82.6317 -926 82.6317Z"
        fill="#E1E5EB"
        stroke="#D8DCE2"
        stroke-width="2"
      />
    </g>
    <mask
      id="mask1_1195_1264"
      maskUnits="userSpaceOnUse"
      x="0"
      y="0"
      width="110"
      height="83"
    >
      <rect width="110" height="83" fill="#D9D9D9" />
    </mask>
    <g mask="url(#mask1_1195_1264)">
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M1146 82.6317H0C0 82.6317 43.11 73.8671 52.6028 59.8409C72.3082 30.7247 75.082 1.31592 110.071 1.31592C159.432 1.31592 159.432 63.5788 205.673 63.5788C251.914 63.5788 251.914 1.40569 298.155 1.40569C344.396 1.40569 344.396 63.5788 390.637 63.5788C436.878 63.5788 436.878 1.40569 483.119 1.40569C529.36 1.40569 529.36 63.5788 575.601 63.5788C621.842 63.5788 621.842 1.40569 668.083 1.40569C714.324 1.40569 714.324 63.5788 760.565 63.5788C806.807 63.5788 806.807 1.40569 853.048 1.40569C899.289 1.40569 899.289 63.5788 945.53 63.5788C991.771 63.5788 993.77 1.40569 1040.01 1.40569C1077.28 1.40569 1088.9 47.2753 1111.33 63.5788C1121.75 71.1556 1146 82.6317 1146 82.6317Z"
        fill="#E1E5EB"
        stroke="#D8DCE2"
        stroke-width="2"
      />
    </g>
  </svg>
);

export const TwoSecondsWave = () => (
  <svg
    width="387"
    height="83"
    viewBox="0 0 387 83"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <mask
      id="mask0_1195_1256"
      maskUnits="userSpaceOnUse"
      x="0"
      y="0"
      width="195"
      height="83"
    >
      <rect width="195" height="83" fill="white" />
    </mask>
    <g mask="url(#mask0_1195_1256)">
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M1136.51 86.1908H-9.96875C-9.96875 86.1908 33.1594 77.2207 42.6561 62.8658C62.3698 33.0672 65.1447 2.96899 100.149 2.96899C149.53 2.96899 149.53 66.6913 195.791 66.6913C242.051 66.6913 242.051 3.06087 288.312 3.06087C334.572 3.06087 334.572 66.6913 380.832 66.6913C427.093 66.6913 427.093 3.06087 473.353 3.06087C519.614 3.06087 519.614 66.6913 565.874 66.6913C612.134 66.6913 612.134 3.06087 658.395 3.06087C704.655 3.06087 704.655 66.6913 750.916 66.6913C797.176 66.6913 797.176 3.06087 843.437 3.06087C889.697 3.06087 889.697 66.6913 935.957 66.6913C982.218 66.6913 984.218 3.06087 1030.48 3.06087C1067.76 3.06087 1079.38 50.0057 1101.83 66.6913C1112.26 74.4458 1136.51 86.1908 1136.51 86.1908Z"
        fill="#E2E5EB"
        stroke="#D8DCE2"
        stroke-width="2"
      />
    </g>
    <mask
      id="mask1_1195_1256"
      maskUnits="userSpaceOnUse"
      x="195"
      y="0"
      width="192"
      height="83"
    >
      <rect x="195" width="192" height="83" fill="white" />
    </mask>
    <g mask="url(#mask1_1195_1256)">
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M393.481 86.1908H-753C-753 86.1908 -709.872 77.2207 -700.375 62.8658C-680.661 33.0672 -677.887 2.96899 -642.882 2.96899C-593.501 2.96899 -593.501 66.6913 -547.241 66.6913C-500.98 66.6913 -500.98 3.06087 -454.72 3.06087C-408.459 3.06087 -408.459 66.6913 -362.199 66.6913C-315.938 66.6913 -315.938 3.06087 -269.678 3.06087C-223.418 3.06087 -223.418 66.6913 -177.157 66.6913C-130.897 66.6913 -130.897 3.06087 -84.6364 3.06087C-38.376 3.06087 -38.376 66.6913 7.88446 66.6913C54.1449 66.6913 54.1449 3.06087 100.405 3.06087C146.666 3.06087 146.666 66.6913 192.926 66.6913C239.187 66.6913 241.186 3.06087 287.447 3.06087C324.73 3.06087 336.354 50.0057 358.795 66.6913C369.224 74.4458 393.481 86.1908 393.481 86.1908Z"
        fill="#E2E5EB"
        stroke="#D8DCE2"
        stroke-width="2"
      />
    </g>
  </svg>
);

export const ThreeSecondsWave = () => (
  <svg
    width="568"
    height="83"
    viewBox="0 0 568 83"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <mask
      id="mask0_1195_1248"
      maskUnits="userSpaceOnUse"
      x="0"
      y="0"
      width="378"
      height="83"
    >
      <rect width="378" height="83" fill="white" />
    </mask>
    <g mask="url(#mask0_1195_1248)">
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M1136.51 85.969H-9.96875C-9.96875 85.969 33.1594 77.0228 42.6561 62.7061C62.3698 32.9869 65.1447 2.96899 100.149 2.96899C149.53 2.96899 149.53 66.5214 195.791 66.5214C242.051 66.5214 242.051 3.06062 288.312 3.06062C334.572 3.06062 334.572 66.5214 380.832 66.5214C427.093 66.5214 427.093 3.06062 473.353 3.06062C519.614 3.06062 519.614 66.5214 565.874 66.5214C612.134 66.5214 612.134 3.06062 658.395 3.06062C704.655 3.06062 704.655 66.5214 750.916 66.5214C797.176 66.5214 797.176 3.06062 843.437 3.06062C889.697 3.06062 889.697 66.5214 935.957 66.5214C982.218 66.5214 984.218 3.06062 1030.48 3.06062C1067.76 3.06062 1079.38 49.8803 1101.83 66.5214C1112.26 74.2552 1136.51 85.969 1136.51 85.969Z"
        fill="#E2E5EB"
        stroke="#D8DCE2"
        stroke-width="2"
      />
    </g>
    <mask
      id="mask1_1195_1248"
      maskUnits="userSpaceOnUse"
      x="378"
      y="0"
      width="190"
      height="83"
    >
      <rect x="378" width="190" height="83" fill="white" />
    </mask>
    <g mask="url(#mask1_1195_1248)">
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M575.481 85.969H-571C-571 85.969 -527.872 77.0228 -518.375 62.7061C-498.661 32.9869 -495.887 2.96899 -460.882 2.96899C-411.501 2.96899 -411.501 66.5214 -365.241 66.5214C-318.98 66.5214 -318.98 3.06062 -272.72 3.06062C-226.459 3.06062 -226.459 66.5214 -180.199 66.5214C-133.938 66.5214 -133.938 3.06062 -87.6781 3.06062C-41.4177 3.06062 -41.4176 66.5214 4.84277 66.5214C51.1032 66.5214 51.1032 3.06062 97.3636 3.06062C143.624 3.06062 143.624 66.5214 189.884 66.5214C236.145 66.5214 236.145 3.06062 282.405 3.06062C328.666 3.06062 328.666 66.5214 374.926 66.5214C421.187 66.5214 423.186 3.06062 469.447 3.06062C506.73 3.06062 518.354 49.8803 540.795 66.5214C551.224 74.2552 575.481 85.969 575.481 85.969Z"
        fill="#E2E5EB"
        stroke="#D8DCE2"
        stroke-width="2"
      />
    </g>
  </svg>
);

export const FourSecondsWave = () => (
  <svg
    width="759"
    height="83"
    viewBox="0 0 759 83"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <mask
      id="mask0_1195_1240"
      maskUnits="userSpaceOnUse"
      x="0"
      y="0"
      width="573"
      height="83"
    >
      <rect width="573" height="83" fill="white" />
    </mask>
    <g mask="url(#mask0_1195_1240)">
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M1136.51 86.1906H-9.96875C-9.96875 86.1906 33.1594 77.2205 42.6561 62.8656C62.3698 33.0669 65.1447 2.96875 100.149 2.96875C149.53 2.96875 149.53 66.6911 195.791 66.6911C242.051 66.6911 242.051 3.06062 288.312 3.06062C334.572 3.06062 334.572 66.6911 380.832 66.6911C427.093 66.6911 427.093 3.06062 473.353 3.06062C519.614 3.06062 519.614 66.6911 565.874 66.6911C612.134 66.6911 612.134 3.06062 658.395 3.06062C704.655 3.06062 704.655 66.6911 750.916 66.6911C797.176 66.6911 797.176 3.06062 843.437 3.06062C889.697 3.06062 889.697 66.6911 935.957 66.6911C982.218 66.6911 984.218 3.06062 1030.48 3.06062C1067.76 3.06062 1079.38 50.0055 1101.83 66.6911C1112.26 74.4455 1136.51 86.1906 1136.51 86.1906Z"
        fill="#E2E5EB"
        stroke="#D8DCE2"
        stroke-width="2"
      />
    </g>
    <mask
      id="mask1_1195_1240"
      maskUnits="userSpaceOnUse"
      x="573"
      y="0"
      width="186"
      height="83"
    >
      <rect x="573" width="186" height="83" fill="white" />
    </mask>
    <g mask="url(#mask1_1195_1240)">
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M766.481 86.1906H-380C-380 86.1906 -336.872 77.2205 -327.375 62.8656C-307.661 33.0669 -304.887 2.96875 -269.882 2.96875C-220.501 2.96875 -220.501 66.6911 -174.241 66.6911C-127.98 66.6911 -127.98 3.06062 -81.7197 3.06062C-35.4593 3.06062 -35.4593 66.6911 10.8011 66.6911C57.0615 66.6911 57.0615 3.06062 103.322 3.06062C149.582 3.06062 149.582 66.6911 195.843 66.6911C242.103 66.6911 242.103 3.06062 288.364 3.06062C334.624 3.06062 334.624 66.6911 380.884 66.6911C427.145 66.6911 427.145 3.06062 473.405 3.06062C519.666 3.06062 519.666 66.6911 565.926 66.6911C612.187 66.6911 614.186 3.06062 660.447 3.06062C697.73 3.06062 709.354 50.0055 731.795 66.6911C742.224 74.4455 766.481 86.1906 766.481 86.1906Z"
        fill="#E2E5EB"
        stroke="#D8DCE2"
        stroke-width="2"
      />
    </g>
  </svg>
);

export const FiveSecondsWave = () => (
  <svg
    width="943"
    height="82"
    viewBox="0 0 943 82"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <mask
      id="mask0_1195_1232"
      maskUnits="userSpaceOnUse"
      x="0"
      y="0"
      width="758"
      height="82"
    >
      <rect width="758" height="82" fill="white" />
    </mask>
    <g mask="url(#mask0_1195_1232)">
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M1135.51 84.7021H-10.9688C-10.9688 84.7021 32.1594 75.8637 41.6561 61.7196C61.3698 32.3584 64.1447 2.70215 99.1489 2.70215C148.53 2.70215 148.53 65.4889 194.791 65.4889C241.051 65.4889 241.051 2.79267 287.312 2.79267C333.572 2.79267 333.572 65.4889 379.832 65.4889C426.093 65.4889 426.093 2.79267 472.353 2.79267C518.614 2.79267 518.614 65.4889 564.874 65.4889C611.134 65.4889 611.134 2.79267 657.395 2.79267C703.655 2.79267 703.655 65.4889 749.916 65.4889C796.176 65.4889 796.176 2.79267 842.437 2.79267C888.697 2.79267 888.697 65.4889 934.957 65.4889C981.218 65.4889 983.218 2.79267 1029.48 2.79267C1066.76 2.79267 1078.38 49.0483 1100.83 65.4889C1111.26 73.1295 1135.51 84.7021 1135.51 84.7021Z"
        fill="#E2E5EB"
        stroke="#D8DCE2"
        stroke-width="2"
      />
    </g>
    <mask
      id="mask1_1195_1232"
      maskUnits="userSpaceOnUse"
      x="757"
      y="0"
      width="186"
      height="82"
    >
      <rect x="757" width="186" height="82" fill="white" />
    </mask>
    <g mask="url(#mask1_1195_1232)">
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M950.481 85.3692H-196C-196 85.3692 -152.872 76.4589 -143.375 62.1997C-123.661 32.5997 -120.887 2.70215 -85.8824 2.70215C-36.501 2.70215 -36.501 65.9997 9.75945 65.9997C56.0199 65.9997 56.0199 2.79341 102.28 2.79341C148.541 2.79341 148.541 65.9997 194.801 65.9997C241.062 65.9997 241.062 2.79341 287.322 2.79341C333.582 2.79341 333.582 65.9997 379.843 65.9997C426.103 65.9997 426.103 2.79341 472.364 2.79341C518.624 2.79341 518.624 65.9997 564.884 65.9997C611.145 65.9997 611.145 2.79341 657.405 2.79341C703.666 2.79341 703.666 65.9997 749.926 65.9997C796.187 65.9997 798.186 2.79341 844.447 2.79341C881.73 2.79341 893.354 49.4253 915.795 65.9997C926.224 73.7024 950.481 85.3692 950.481 85.3692Z"
        fill="#E2E5EB"
        stroke="#D8DCE2"
        stroke-width="2"
      />
    </g>
  </svg>
);

export const SixSecondsWave = () => (
  <svg
    width="1130"
    height="82"
    viewBox="0 0 1130 82"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M1119.03 85.1906H-10.9688C-10.9688 85.1906 31.5394 76.2205 40.8996 61.8656C60.3299 32.0669 63.065 1.96875 97.566 1.96875C146.237 1.96875 146.237 65.6911 191.833 65.6911C237.428 65.6911 237.428 2.06062 283.024 2.06062C328.619 2.06062 328.619 65.6911 374.215 65.6911C419.81 65.6911 419.81 2.06062 465.405 2.06062C511.001 2.06062 511.001 65.6911 556.596 65.6911C602.192 65.6911 602.192 2.06062 647.787 2.06062C693.383 2.06062 693.383 65.6911 738.978 65.6911C784.573 65.6911 784.573 2.06062 830.169 2.06062C875.764 2.06062 875.764 65.6911 921.36 65.6911C966.955 65.6911 968.926 2.06062 1014.52 2.06062C1051.27 2.06062 1062.73 49.0055 1084.84 65.6911C1095.12 73.4455 1119.03 85.1906 1119.03 85.1906Z"
      fill="#E2E5EB"
      stroke="#D8DCE2"
      stroke-width="2"
    />
  </svg>
);
