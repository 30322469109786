import { call, put, takeLatest, all, fork, join } from 'redux-saga/effects';
import ChatService from '@services/chatService';
import { TYPES, setChatInfo, onError } from './actions';
import { openPopUp } from '@containers/App/containers/PopUp/actions';
import POPUP_TYPES from '../../containers/App/containers/PopUp/types';

const useFixtures = process.env.USE_FIXTURES;
export function* callGetChatInfo(action) {
  try {
    const res = yield fork(ChatService.getChatInfo, action.chatId);
    const sessionInfo = yield join(res);
    if (sessionInfo?.error) {
      throw (sessionInfo.error)
    }
    yield put(setChatInfo(sessionInfo));
  } catch (error) {
    if (error.message) {
      yield put(onError(error.message));
    } else {
      yield put(onError(error));
    }
  }
}

export function* callConnectToChat(action) {
  try {
    const userPermissionRes = yield fork(ChatService.connectToChat, action.users, action.options);
    const userHasPermission = yield join(userPermissionRes);
    if (!userHasPermission) {
      yield put(
        openPopUp(POPUP_TYPES.MEDIA_DEVICES_PERMISSIONS)
      );
    }
  } catch (error) {
    yield put(onError(error.message));
  }
}

export function* callEndChat(action) {
  try {
    yield fork(ChatService.endChat);
    window.localStorage.setItem(
      action.sessionId,
      JSON.stringify(action.sessionInfo),
    );
  } catch (error) {
    yield put(onError(error.message));
  }
}

export function* callExitChat() {
  try {
    yield fork(ChatService.leaveSession);
  } catch (error) {
    yield put(onError(error.message));
  }
}

export function* callToggleChatVideo() {
  try {
    if (!useFixtures) yield fork(ChatService.toggleVideo);
  } catch (error) {
    yield put(onError(error.message));
  }
}

export function* callToggleChatAudio() {
  try {
    if (!useFixtures) yield fork(ChatService.toggleAudio);
  } catch (error) {
    yield put(onError(error.message));
  }
}

export function* callSendTextMessage(action) {
  try {
    yield fork(ChatService.sendMessage, action.message);
  } catch (error) {
    yield put(onError(error.message));
  }
}

function* watchCallGetChatInfo() {
  yield takeLatest(TYPES.GET_CHAT_INFO, callGetChatInfo);
}
function* watchCallConnectToChat() {
  yield takeLatest(TYPES.CONNECT_TO_CHAT, callConnectToChat);
}
function* watchCallEndChat() {
  yield takeLatest(TYPES.END_CHAT, callEndChat);
}
function* watchCallExitChat() {
  yield takeLatest(TYPES.LEAVE_CHAT, callExitChat);
}
function* watchCallToggleChatVideo() {
  yield takeLatest(TYPES.TOGGLE_CHAT_VIDEO, callToggleChatVideo);
}
function* watchCallToggleChatAudio() {
  yield takeLatest(TYPES.TOGGLE_CHAT_AUDIO, callToggleChatAudio);
}
function* watchCallSendTextMessage() {
  yield takeLatest(TYPES.SEND_TEXT_MESSAGE, callSendTextMessage);
}

export default function* getVideoChatSaga() {
  yield all([
    fork(watchCallGetChatInfo),
    fork(watchCallConnectToChat),
    fork(watchCallEndChat),
    fork(watchCallExitChat),
    fork(watchCallToggleChatVideo),
    fork(watchCallToggleChatAudio),
    fork(watchCallSendTextMessage),
  ]);
}
