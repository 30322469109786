const items = [
  {
    id: '0',
    type: 'audio',
    title: 'zzz_old_box5stars 2sec',
    name: 'zzz_old_box5stars 2sec',
    description: 'Some description zzz_old_box5stars',
    createdAt:"Thu Aug 30 2018 08:46:54 GMT+0000 (UTC)",
    value: 'https://s3-eu-west-1.amazonaws.com/nvtk-video-media/הדגמת כיול.mp4',
    tags: ['2sec'],
  },
  {
    id: '1',
    type: 'image',
    title: 'calibration (short)',
    name: 'calibration (short)',
    description: 'Some description calibration',
    createdAt:"Thu Aug 30 2018 08:46:54 GMT+0000 (UTC)",
    value: 'https://s3-eu-west-1.amazonaws.com/nvtk-video-media/הדגמת כיול.mp4',
    tags: [],
  },
  {
    id: '2',
    type: 'video',
    title: 'zzz_old_breathing',
    name: 'zzz_old_breathing',
    description: 'Some description zzz_old_breathing',
    value: 'https://s3-eu-west-1.amazonaws.com/nvtk-video-media/הדגמת כיול.mp4',
    tags: ['breath'],
  },
  {
    id: '3',
    type: 'video',
    title: 'zzz_old_gentle-loud-gentle',
    name: 'zzz_old_gentle-loud-gentle',
    description: 'Some description zzz_old_gentle',
    value: 'https://s3-eu-west-1.amazonaws.com/nvtk-video-media/הדגמת כיול.mp4',
    tags: ['vcontrol'],
  },
  {
    id: '4',
    type: 'video',
    title: 'zzz_old_box5stars 2sec',
    name: 'zzz_old_box5stars 2sec',
    description: 'Some description zzz_old_box5stars',
    value: 'https://s3-eu-west-1.amazonaws.com/nvtk-video-media/הדגמת כיול.mp4',
    tags: ['2sec'],
  },
  {
    id: '5',
    type: 'video',
    title: 'zzz_old_speech task',
    name: 'zzz_old_speech task',
    description: 'Some description zzz_old_speech',
    value: 'https://s3-eu-west-1.amazonaws.com/nvtk-video-media/הדגמת כיול.mp4',
    tags: ['speech-task'],
  },
  {
    id: '6',
    type: 'video',
    title: 'zzz_old_doc read in 2s/s',
    name: 'zzz_old_doc read in 2s/s',
    description: 'Some description zzz_old_doc',
    value: 'https://s3-eu-west-1.amazonaws.com/nvtk-video-media/הדגמת כיול.mp4',
    tags: ['2sec'],
  },
  {
    id: '7',
    type: 'video',
    title: 'calibration (short)',
    name: 'calibration (short)',
    description: 'Some description calibration',
    value: 'https://s3-eu-west-1.amazonaws.com/nvtk-video-media/הדגמת כיול.mp4',
    tags: [],
  },
  {
    id: '8',
    type: 'video',
    title: 'zzz_old_breathing',
    name: 'zzz_old_breathing',
    description: 'Some description zzz_old_breathing',
    value: 'https://s3-eu-west-1.amazonaws.com/nvtk-video-media/הדגמת כיול.mp4',
    tags: ['breath'],
  },
  {
    id: '9',
    type: 'video',
    title: 'zzz_old_gentle-loud-gentle',
    name: 'zzz_old_gentle-loud-gentle',
    description: 'Some description zzz_old_gentle',
    value: 'https://s3-eu-west-1.amazonaws.com/nvtk-video-media/הדגמת כיול.mp4',
    tags: ['vcontrol'],
  },
  {
    id: '10',
    type: 'video',
    title: 'zzz_old_box5stars 2sec',
    name: 'zzz_old_box5stars 2sec',
    description: 'Some description zzz_old_box5stars',
    value: 'https://s3-eu-west-1.amazonaws.com/nvtk-video-media/הדגמת כיול.mp4',
    tags: ['2sec'],
  },
  {
    id: '11',
    type: 'video',
    title: 'calibration (short)',
    name: 'calibration (short)',
    description: 'Some description calibration',
    value: 'https://s3-eu-west-1.amazonaws.com/nvtk-video-media/הדגמת כיול.mp4',
    tags: [],
  },
  {
    id: '12',
    type: 'video',
    title: 'zzz_old_breathing',
    name: 'zzz_old_breathing',
    description: 'Some description zzz_old_breathing',
    value: 'https://s3-eu-west-1.amazonaws.com/nvtk-video-media/הדגמת כיול.mp4',
    tags: ['breath'],
  },
  {
    id: '13',
    type: 'video',
    title: 'zzz_old_gentle-loud-gentle',
    name: 'zzz_old_gentle-loud-gentle',
    description: 'Some description zzz_old_gentle',
    value: 'https://s3-eu-west-1.amazonaws.com/nvtk-video-media/הדגמת כיול.mp4',
    tags: ['vcontrol'],
  },
  {
    id: '14',
    type: 'video',
    title: 'zzz_old_box5stars 2sec',
    name: 'zzz_old_box5stars 2sec',
    description: 'Some description zzz_old_box5stars',
    value: 'https://s3-eu-west-1.amazonaws.com/nvtk-video-media/הדגמת כיול.mp4',
    tags: ['2sec'],
  },
  {
    id: '15',
    type: 'video',
    title: 'zzz_old_speech task',
    name: 'zzz_old_speech task',
    description: 'Some description zzz_old_speech',
    value: 'https://s3-eu-west-1.amazonaws.com/nvtk-video-media/הדגמת כיול.mp4',
    tags: ['speech-task'],
  },
  {
    id: '16',
    type: 'video',
    title: 'zzz_old_doc read in 2s/s',
    name: 'zzz_old_doc read in 2s/s',
    description: 'Some description zzz_old_doc',
    value: 'https://s3-eu-west-1.amazonaws.com/nvtk-video-media/הדגמת כיול.mp4',
    tags: ['2sec'],
  },
  {
    id: '17',
    type: 'video',
    title: 'calibration (short)',
    name: 'calibration (short)',
    description: 'Some description calibration',
    value: 'https://s3-eu-west-1.amazonaws.com/nvtk-video-media/הדגמת כיול.mp4',
    tags: [],
  },
  {
    id: '18',
    type: 'video',
    title: 'zzz_old_breathing',
    name: 'zzz_old_breathing',
    description: 'Some description zzz_old_breathing',
    value: 'https://s3-eu-west-1.amazonaws.com/nvtk-video-media/הדגמת כיול.mp4',
    tags: ['breath', '2sec'],
  },
  {
    id: '19',
    type: 'video',
    title: 'zzz_old_gentle-loud-gentle',
    name: 'zzz_old_gentle-loud-gentle',
    description: 'Some description zzz_old_gentle',
    value: 'https://s3-eu-west-1.amazonaws.com/nvtk-video-media/הדגמת כיול.mp4',
    tags: ['vcontrol'],
  },
  {
    id: '20',
    type: 'video',
    title: 'zzz_old_box5stars 2sec',
    name: 'zzz_old_box5stars 2sec',
    description: 'Some description zzz_old_box5stars',
    value: 'https://s3-eu-west-1.amazonaws.com/nvtk-video-media/הדגמת כיול.mp4',
    tags: ['2sec'],
  },
  {
    id: '21',
    type: 'video',
    title: 'calibration (short)',
    name: 'calibration (short)2',
    description: 'Some description calibration',
    value: 'https://s3-eu-west-1.amazonaws.com/nvtk-video-media/הדגמת כיול.mp4',
    tags: [],
  },
  {
    id: '22',
    type: 'video',
    title: 'zzz_old_breathing',
    name: 'zzz_old_breathing',
    description: 'Some description zzz_old_breathing',
    value: 'https://s3-eu-west-1.amazonaws.com/nvtk-video-media/הדגמת כיול.mp4',
    tags: ['breath'],
  },
  {
    id: '23',
    type: 'video',
    title: 'zzz_old_gentle-loud-gentle',
    name: 'zzz_old_gentle-loud-gentle',
    description: 'Some description zzz_old_gentle',
    value: 'https://s3-eu-west-1.amazonaws.com/nvtk-video-media/הדגמת כיול.mp4',
    tags: ['vcontrol'],
  },
  {
    id: '24',
    type: 'video',
    title: 'zzz_old_box5stars 2sec',
    name: 'zzz_old_box5stars 2sec',
    description: 'Some description zzz_old_box5stars',
    value: 'https://s3-eu-west-1.amazonaws.com/nvtk-video-media/הדגמת כיול.mp4',
    tags: ['2sec'],
  },
  {
    id: '25',
    type: 'video',
    title: 'zzz_old_speech task',
    name: 'zzz_old_speech task',
    description: 'Some description zzz_old_speech',
    value: 'https://s3-eu-west-1.amazonaws.com/nvtk-video-media/הדגמת כיול.mp4',
    tags: ['speech-task'],
  },
  {
    id: '26',
    type: 'video',
    title: 'zzz_old_doc read in 2s/s',
    name: 'zzz_old_doc read in 2s/s',
    description: 'Some description zzz_old_doc',
    value: 'https://s3-eu-west-1.amazonaws.com/nvtk-video-media/הדגמת כיול.mp4',
    tags: ['2sec'],
  },
  {
    id: '27',
    type: 'video',
    title: 'calibration (short)',
    name: 'calibration (short)',
    description: 'Some description calibration',
    value: 'https://s3-eu-west-1.amazonaws.com/nvtk-video-media/הדגמת כיול.mp4',
    tags: [],
  },
  {
    id: '28',
    type: 'video',
    title: 'zzz_old_breathing',
    name: 'zzz_old_breathing',
    description: 'Some description zzz_old_breathing',
    value: 'https://s3-eu-west-1.amazonaws.com/nvtk-video-media/הדגמת כיול.mp4',
    tags: ['breath'],
  },
  {
    id: '29',
    type: 'video',
    title: 'zzz_old_gentle-loud-gentle',
    name: 'zzz_old_gentle-loud-gentle',
    description: 'Some description zzz_old_gentle',
    value: 'https://s3-eu-west-1.amazonaws.com/nvtk-video-media/הדגמת כיול.mp4',
    tags: ['vcontrol'],
  },
];

export default items;
