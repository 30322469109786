import { useCallback, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { ACTIVITY_TYPES } from '@shared/Resources/types';
import { removeUserActivityDetail } from '@containers/Admin/containers/UserPage/actions';
import { TextSelectCenter, ActivityPanelWrapper, ActivityPanelContent } from './styles';
import { LessonActivityPanel } from '../activity-panels/LessonActivityPanel';
import { PracticeActivityPanel } from '../activity-panels/PracticeActivityPanel';
import { VideoChatPanel } from '../activity-panels/VideoChatPanel';

/**
 * Wrapper for render panels by type
 */
export const ActivityPanel = () => {
  const dispatch = useDispatch();
  const { activityDetail } = useSelector((state) => state.get('userPage').toJS());

  /**
   * Render activity overview by type
   */
  const renderActivityPanel = useCallback(() => {
    
    const { type } = activityDetail;
    switch (type) {
      case ACTIVITY_TYPES.ACTIVITY_CHAT: return <VideoChatPanel activity={activityDetail} />;
      case ACTIVITY_TYPES.ACTIVITY_LESSON: return <LessonActivityPanel activity={activityDetail} />;
      case ACTIVITY_TYPES.ACTIVITY_PRACTICE: return <PracticeActivityPanel activity={activityDetail} />;
      default: return null;
    }
  }, [activityDetail]);

  useEffect(() => () => {
    dispatch(removeUserActivityDetail());
  }, []);

  return (
    <ActivityPanelWrapper>
      <ActivityPanelContent>
        {activityDetail
          ? renderActivityPanel()
          : <TextSelectCenter>Select activity</TextSelectCenter>}
      </ActivityPanelContent>
    </ActivityPanelWrapper>
  );
};
