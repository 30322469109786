import {MEDIA_TYPES, QUESTION_TYPES, TYPES} from '@shared/Resources/types';

const poll = {
    "id" : "59aaa23434400000",
    "description" : "קולורס מונפרד אדנדום סילקוף, מרגשי ומרגשח",
    "name" : " ברומץ כלרשט",
    "version" : 2,
    "children" : [ 
        {
            "id" : "59aaa27d4e000000",
            "type" : TYPES.QUESTION,
            "payload" : {
                "title" : "Question",
                "type" : QUESTION_TYPES.RATING,
                "questionText" : "דירוג שטף",
                "config": {
                    minimum: {
                        value: 1,
                        text: "חלש"
                    },
                    maximum: {
                        value: 5,
                        text: "חזק"
                    },
                    isOptional: {
                        text: "לא רלוונטי"
                    } 
                }
            }
        }, 
        {
            "id" : "59aaa27d2e000000",
            "type" : TYPES.QUESTION,
            "payload" : {
                "title" : "Question",
                "type" : QUESTION_TYPES.RATING,
                "questionText" : "דירוג טכניקות",
                "answers" : [
                    {
                        value: "minimum",
                        scalar: 1,
                        text: "חלש"
                    },
                    {
                        value: "maximum",
                        scalar: 5,
                        text: "חזק"
                        
                    },
                    {
                        value: "optional",
                        enabled: true,
                        text: "לא רלוונטי"
                    }
                ]
            }
        }, 
        {
            "id" : "59aaa27d9e000000",
            "type" : TYPES.QUESTION,
            "payload" : {
                "title" : "Question",
                "type" : QUESTION_TYPES.FREE_TEXT,
                "questionText" : "הערות נוספות",
                "answers" : []
            }
        }, 
        
    ],
}

export default poll;
