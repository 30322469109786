import { fromJS } from 'immutable';
import { Types } from './actions';
import { Types as ItemActions } from './containers/DraggableList/components/EditableBuilderComponent/actions';
import { TYPES } from "@containers/Admin/shared/ResourcesWrapper/actions";
import { getRoot, setRoot } from './reducers/rootObject';
import {
  reduceSave,
  reduceSaveComplete,
  reduceSaveFailed,
  reduceUpdateField,
  reduceReset,
  reduceLoad,
  reduceLoadComplete,
  reduceLoadFailed,
} from './reducers/builderReducers';
import {
  reduceAddComponent,
  reduceUpdateComponentIndex,
  reduceUpdateComponentPayload,
  reduceRemoveComponent,
  reduceDuplicateComponent,
  reduceCreateNewResource,
} from './reducers/componentsReducers';

const initialRootObject = { payload: {}, children: [] };
const initialMonitorObject = {
  monitor: {
    type: "",
    inhalation: {
      on: false,
      value: 0,
    },
    video: {
      on: false,
      url: null,
      id: null,
    },
    mirror: {
      on: false,
    },
    speedometer: {
      on: false,
    },
  },
  pattern: {
    type: "WAVES",
    productionDuration: 4,
  },
  errors: {
    on: false,
    masteryLevel: 0,
    inhalation: {
      tooShort: {
        on: false,
      },
    },
    production: {
      tooShort: {
        on: false,
        type: "EASY",
      },
      tooLong: {
        on: false,
        type: "EASY",
      },
      notLoud: {
        on: false,
        type: "EASY",
      },
      notGentle: {
        on: false,
        type: "EASY",
      },
      noPattern: {
        on: false,
        type: "EASY",
      },
    },
    specific: {
      beginning: {
        nonGentleOnset: {
          on: false,
          type: "EASY",
        },
        volumeControl: {
          on: false,
          type: "EASY",
        },
      },
      peak: {
        softPeak: {
          on: false,
          type: "EASY",
        },
        excessivePeak: {
          on: false,
          type: "EASY",
        },
      },
      end: {
        volumeControl: {
          on: false,
          type: "EASY",
        },
        nonGentleOffset: {
          on: false,
          type: "EASY",
        },
      },
    },
  },
  progress: {
    type: "SEQUENCES",
    value: 1,
    repetition: {
      on: false,
      count: 0,
    },
  },
  stimulus: {
    type: "SYLLABLES",
    config: {
      division: "DIVIDED",
      syllableCount: {
        min: 1,
        max: 1,
      },
      syllableStructure: {
        c: false,
        cv: false,
        vc: false,
        cvc: false,
      },
      consonantsFeatures: {
        PLOSIVE: false,
      },
    },
  },
}
setRoot(initialRootObject);

const initialJsState = {
  type: null,
  info: {
    monitor: initialMonitorObject,
  },
  components: initialRootObject.children,
  widgets: [],
  loading: false,
  fieldsValidationMap: {},
  urlToPush: null,
  editState: {
    dirty: null,
    lastSaved: null,
  },
  error: null,
  breadcrumbs: [],
  creating: false,
  id: null,
};


// The initial state of the Component
export const initialState = fromJS(initialJsState);

function builderReducer(state = initialState, action) {
  switch (action.type) {
    case Types.BUILDER_CLEAR_BREAD_CRUMBS: {
      setRoot(initialRootObject);
      return fromJS(initialJsState);
    }
    case Types.BUILDER_SAVE: return reduceSave(state, action, getRoot());
    case Types.BUILDER_SAVE_COMPLETED: return reduceSaveComplete(state, action, getRoot());
    case Types.BUILDER_SAVE_FAILED: return reduceSaveFailed(state, action, getRoot());

    case Types.BUILDER_EDIT: return reduceSave(state, action, getRoot());

    case Types.BUILDER_LOAD: return reduceLoad(state, action, getRoot());
    case Types.BUILDER_LOAD_COMPLETED: return reduceLoadComplete(state, action, getRoot());
    case Types.BUILDER_LOAD_FAILED: return reduceLoadFailed(state, action, getRoot());

    case Types.BUILDER_UPDATE_FIELD: return reduceUpdateField(state, action, getRoot());
    case Types.BUILDER_RESET: return reduceReset(state, action, getRoot());

    case Types.BUILDER_ADD_COMPONENT: return reduceAddComponent(state, action, getRoot());
    case Types.BUILDER_UPDATE_COMPONENT_PAYLOAD: return reduceUpdateComponentPayload(state, action, getRoot());
    case Types.BUILDER_UPDATE_COMPONENT_INDEX: return reduceUpdateComponentIndex(state, action, getRoot());
    case ItemActions.BUILDER_REMOVE_COMPONENT: return reduceRemoveComponent(state, action, getRoot());
    case ItemActions.BUILDER_DUPLICATE_COMPONENT: return reduceDuplicateComponent(state, action, getRoot());

    case TYPES.CREATE_NEW_RESOURCE_COMPLETE: return reduceCreateNewResource(state, action);
    default:
      return state;
  }
}

export default builderReducer;
