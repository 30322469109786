import React from 'react';
import _ from 'lodash';
import PropTypes from 'prop-types';
import Text, { TEXT_SIZE, TEXT_COLOR } from '@components/Text';
import { capitalizeScreamingCase, camelCaseToWords } from '@utils/textHelpers';
import Media from '../../Media/MiniOverview';
import { MonitorTypesTitle,
  PatternTypesTitle,
  ProductionDurationTitle,
  ErrorsTitle,
  ProgressTitle,
  RepetitionTitle,
  StimulusTitle } from '../shared/Titles';
import { MONITOR_TYPES } from '../types';
import { getFormattedTextCurry } from '../shared/utils/formattedText';
import MonitorImage from '../shared/MonitorImage';
import StimulusImage from '../shared/StimulusImage';

import {
  Section, SectionContent, SplitContainer,
  SelectedMonitorType, MonitorImageWrapper,
  ExtraFeatures, ExtraFeaturesTitle, TitleWrapper,
  ErrorGroup, DetailsList, ListItem, SpecificErrorsWrapper,
  InhalationIcon, MirrorIcon, VideoIcon,
  MasteryContainer, MasteryTitle, MasteryLevelContainer, StyledSlider,
} from './styles';
import {withTranslation} from "react-i18next";
import {compose} from "redux";

const getFormattedText = getFormattedTextCurry();

const titleTextProps = { size: TEXT_SIZE.H4, textColor: TEXT_COLOR.DARK_BLUE };
const detailsTextProps = { size: TEXT_SIZE.H4, textColor: TEXT_COLOR.DARK_GRAY };

const getTitleText = (className, inner) => (<Text {...titleTextProps} className={className}>
  {inner}
</Text>);
const getSimpleText = (className, inner) => (<Text {...detailsTextProps} className={className}>
  {inner}
</Text>);

class MonitorMiniOverview extends React.PureComponent {
  getElementList = (data, name) => {
    if (_.isObject(data)) {
      switch (name) {
        case 'syllableStructure' : return _.map(data, (isSelected, key) =>
          isSelected ? key : '');
        case 'syllableCount' : return [`${data.min}-${data.max}`];
        default: return _.compact(_.map(data, (isSelected, key) =>
          isSelected ? capitalizeScreamingCase(key) : ''));
      }
    } else {
      return [capitalizeScreamingCase(data)];
    }
  }

  renderTypes =(monitor) => {
    const hasExtraMonitors = monitor.inhalation.on || monitor.mirror.on || monitor.video.on;
    return (
      <Section>
        <MonitorTypesTitle miniOverview />
        <SectionContent>
          <SelectedMonitorType>
            <MonitorImageWrapper>
              <MonitorImage isSelected type={monitor.type} miniOverview />
            </MonitorImageWrapper>
            <Text size={TEXT_SIZE.T4} textColor={TEXT_COLOR.ACTIVE} className="selectedMonitorType">
              {capitalizeScreamingCase(monitor.type)}
            </Text>
          </SelectedMonitorType>
          {hasExtraMonitors &&
            <ExtraFeatures className="extraMonitorInhalation">
              <ExtraFeaturesTitle>
                {getFormattedText('extraMonitorsTitle', titleTextProps)}
              </ExtraFeaturesTitle>
              {monitor.inhalation.on &&
                <ExtraFeaturesTitle>
                  <SplitContainer>
                    <TitleWrapper>
                      <InhalationIcon />
                      {getFormattedText('extraMonitorInhale', titleTextProps)}
                    </TitleWrapper>
                    {getFormattedText('sec', detailsTextProps, null, { count: monitor.inhalation.value })}
                  </SplitContainer>
                </ExtraFeaturesTitle>
              }
              {monitor.mirror.on &&
                <ExtraFeaturesTitle className="extraMonitorMirror">
                  <TitleWrapper>
                    <MirrorIcon />
                    {getFormattedText('extraMonitorMirror', titleTextProps)}
                  </TitleWrapper>
                </ExtraFeaturesTitle>
              }
              {monitor.video.on &&
                <React.Fragment>
                  <ExtraFeaturesTitle className="extraMonitorVideo">
                    <TitleWrapper>
                      <VideoIcon />
                      {getFormattedText('extraMonitorVideo', titleTextProps)}
                    </TitleWrapper>
                  </ExtraFeaturesTitle>
                  <Media value={monitor.video.url} title="" innerSection />
                </React.Fragment>
              }
            </ExtraFeatures>
          }
        </SectionContent>
      </Section>
    );
  }

  renderPatten = (pattern, monitor) => (
    <React.Fragment>
      {monitor.type === MONITOR_TYPES.LOUDNESS &&
        <Section>
          <SplitContainer>
            <TitleWrapper>
              <PatternTypesTitle miniOverview />
            </TitleWrapper>
            {getSimpleText('selectedPatternType', capitalizeScreamingCase(pattern.type))}
          </SplitContainer>
        </Section>
      }
      <Section>
        <SplitContainer>
          <TitleWrapper>
            <ProductionDurationTitle miniOverview />
          </TitleWrapper>
          {getFormattedText('sec', detailsTextProps, 'productionDuration', { count: pattern.productionDuration })}
        </SplitContainer>
      </Section>
    </React.Fragment>
  );

  renderErrorsSection = (errors, errorType) =>
    errors.length > 0 &&
    <ErrorGroup>
      {getFormattedText(`${errorType}Title`, titleTextProps)}
      <DetailsList>
        {errors.map(({ type }) =>
          (<ListItem key={type} className={`${type}Item`}>
            {getSimpleText(`${errorType}ItemName`, camelCaseToWords(type))}
          </ListItem>)
        ) }
      </DetailsList>
    </ErrorGroup>

  renderErrors = ({ types, masteryLevel }) => {
    if (!types || types.length === 0) return null;
    const inhalationErrors = types.filter(({ type }) => type.toLowerCase().includes('inhalation'));
    const productionErrors = types.filter(({ type }) => type.toLowerCase().includes('production'));
    const productionBeginning = types.filter(({ type }) => type.toLowerCase().includes('beginning'));
    const productionPeak = types.filter(({ type }) => type.toLowerCase().includes('peak'));
    const productionEnd = types.filter(({ type }) => type.toLowerCase().includes('end'));
    return (<Section>
      <ErrorsTitle miniOverview />
      <SectionContent>
        {/** production */}
        {this.renderErrorsSection(inhalationErrors, 'inhalationError')}
        {this.renderErrorsSection(productionErrors, 'productionError')}
        {/** specific */}
        {(productionBeginning.length > 0 ||
          productionPeak.length > 0 ||
          productionEnd.length > 0) &&
          <ErrorGroup>
            {getFormattedText('specificErrorTitle', titleTextProps)}
            <SpecificErrorsWrapper>
              {this.renderErrorsSection(productionBeginning, 'beginning')}
              {this.renderErrorsSection(productionPeak, 'peak')}
              {this.renderErrorsSection(productionEnd, 'end')}
            </SpecificErrorsWrapper>
          </ErrorGroup>}
        {/** masteryText */}
        <ExtraFeatures>
          <MasteryContainer>
            <MasteryTitle>
              {getFormattedText('masteryText', titleTextProps)}
            </MasteryTitle>
            <MasteryLevelContainer>
              <StyledSlider
                value={Number.parseInt(masteryLevel, 10)}
                disabled
                className="masterySlider"
                noLabels
              />
              {getSimpleText('masteryValue', `${Number.parseInt(masteryLevel, 10)} %`)}
            </MasteryLevelContainer>
          </MasteryContainer>
        </ExtraFeatures>
      </SectionContent>
    </Section>);
  }

  renderProgress = (progress) => (
    <React.Fragment>
      <Section>
        <ProgressTitle miniOverview />
        <SectionContent>
          <SplitContainer>
            {getFormattedText('sequences', titleTextProps)}
            {getSimpleText('sequencesValue', progress.value)}
          </SplitContainer>
        </SectionContent>
      </Section>
      {progress.repetition.on &&
        <Section>
          <SplitContainer>
            <TitleWrapper>
              <RepetitionTitle miniOverview />
            </TitleWrapper>
            {getSimpleText('repetitionValue', progress.repetition.count)}
          </SplitContainer>
        </Section>
      }
    </React.Fragment>
  );

  renderStimulusConfigElement = (element, elementName) => !_.isEmpty(_.filter(element, Boolean)) && (
    <ExtraFeatures className="stimulusGroup" key={elementName}>
      {getTitleText('stimulusGroupName', camelCaseToWords(elementName))}
      <DetailsList>
        {this.getElementList(element, elementName).map((text) => (
          <ListItem key={text} className="stimulusConfigItem">
            {getSimpleText('stimulusGroupNameItem', text)}
          </ListItem>
        ))}
      </DetailsList>
    </ExtraFeatures>)

  renderStimulus = (stimulus) => (<Section>
    <StimulusTitle miniOverview />
    <SectionContent>
      <SelectedMonitorType>
        <StimulusImage isSelected type={stimulus.type} miniOverview />
        <Text size={TEXT_SIZE.T4} textColor={TEXT_COLOR.ACTIVE} className="stimulusType">
          {capitalizeScreamingCase(stimulus.type)}
        </Text>
      </SelectedMonitorType>
      {_.map(stimulus.config, this.renderStimulusConfigElement)}
    </SectionContent>
  </Section>)

  render() {
    const { monitor, pattern, errors, progress, stimulus } = this.props;

    if (!monitor || !pattern || !progress || !stimulus) {
      return (
        <Text size={TEXT_SIZE.T3} textColor={TEXT_COLOR.NORMAL}>
          {this.props.t('UI.dataIncorrect')}
        </Text>
      )
    }

    return (
      <div>
        {/** types */}
        {this.renderTypes(monitor)}
        {/** pattern */}
        {this.renderPatten(pattern, monitor)}
        {/** error */}
        {this.renderErrors(errors)}
        {/** progress */}
        {this.renderProgress(progress)}
        {/** stimulus */}
        {this.renderStimulus(stimulus)}
      </div>
    );
  }
}

MonitorMiniOverview.propTypes = {
  monitor: PropTypes.object,
  pattern: PropTypes.object,
  errors: PropTypes.object,
  progress: PropTypes.object,
  stimulus: PropTypes.object,
};
export default compose(
    withTranslation()
)(MonitorMiniOverview);
