import { fromJS } from 'immutable';
import { editStatus, Types } from './actions';

export const initialState = fromJS({
  editStatus: editStatus.DEFAULT,
  error: null,
});

const mediaEditReducer = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case Types.SET_ERROR: {
      const { error } = payload;
      return state
        .set('error', error)
        .set('editStatus', editStatus.ERROR);
    }

    case Types.SET_EDIT_STATUS: {
      const { status } = payload;
      return state
        .set('editStatus', status);
    }

    default: {
      return state;
    }
  }
};

export default mediaEditReducer;
