import {styled} from "@mui/material";
import Text from "@components/Text";
import { isDesktop } from "@shared/constants/media-queries";

export const TitleWithButton = styled('div')({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'end',
  marginBottom: isDesktop() ? 26 : 17,
})

export const PracticeTitle = styled(Text)({
  textTransform: 'uppercase',
})
