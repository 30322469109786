import React from 'react';
import { EDITOR_OBJECT_TYPES } from './editorObjectTypes';

import EditorQuestion from '@shared/Resources/Question/Builder';
import EditorMedia from '@shared/Resources/Media/Builder';
import EditorText from '@shared/Resources/Text/Builder';
import EditorExercise from '@shared/Resources/Exercise/Builder';
import EditorLesson from '@shared/Resources/Lesson/Builder';
import EditorPractice from '@shared/Resources/Practice/Builder';
import EditorPoll from '@shared/Resources/Poll/Builder';
import EditorSkill from '@shared/Resources/Skill/Builder';
import EditorCourse from '@shared/Resources/Course/Builder';

export const getEditorComponent = (type, props = {}) => {
  switch (type) {
    case EDITOR_OBJECT_TYPES.QUESTION: return <EditorQuestion {...props} />;
    case EDITOR_OBJECT_TYPES.MEDIA: return <EditorMedia {...props} />;
    case EDITOR_OBJECT_TYPES.TEXT: return <EditorText {...props} />;
    case EDITOR_OBJECT_TYPES.EXERCISE: return <EditorExercise {...props} />;
    case EDITOR_OBJECT_TYPES.LESSON: return <EditorLesson {...props} />;
    case EDITOR_OBJECT_TYPES.PRACTICE: return <EditorPractice {...props} />;
    case EDITOR_OBJECT_TYPES.POLL: return <EditorPoll {...props} />;
    case EDITOR_OBJECT_TYPES.SKILL: return <EditorSkill {...props} />;
    case EDITOR_OBJECT_TYPES.COURSE: return <EditorCourse {...props} />;
    default : throw new Error(`Invalid type ${type}`);
  }
};

export const isEntity = (type) =>
  [
    EDITOR_OBJECT_TYPES.POLL,
    EDITOR_OBJECT_TYPES.EXERCISE,
    EDITOR_OBJECT_TYPES.LESSON,
    EDITOR_OBJECT_TYPES.PRACTICE,
    EDITOR_OBJECT_TYPES.SKILL,
    EDITOR_OBJECT_TYPES.COURSE,
  ].includes(type);

export const isPollEntity = (type) => [
  EDITOR_OBJECT_TYPES.TEXT,
  EDITOR_OBJECT_TYPES.QUESTION,
  EDITOR_OBJECT_TYPES.MEDIA,
].includes(type)
