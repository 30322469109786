import React from 'react';
import PropTypes from 'prop-types';
import Text, { TEXT_SIZE } from '@components/Text';
import {
  Wrapper,
  Title,
  WidgetsContainer,
  WidgetContainer,
  getStyledResourceIcon,
} from './styles';
import {useTranslation} from "react-i18next";

const WidgetsBar = ({ widgets, onSelect }) => {
    const {t} = useTranslation()
    return (
        <Wrapper>
            <Title>
                {t('Actions.Multiple.selectComponent')}
            </Title>
            <WidgetsContainer>
                {widgets.map((widget) => {
                    const Icon = getStyledResourceIcon(widget.type);
                    return (
                        <WidgetContainer key={widget.type} onClick={() => onSelect(widget)}>
                            <Icon />
                            <Text size={TEXT_SIZE.T2}>{widget.payload.title}</Text>
                        </WidgetContainer>
                    );
                })}
            </WidgetsContainer>
        </Wrapper>
    );
}

WidgetsBar.propTypes = {
  widgets: PropTypes.array,
  onSelect: PropTypes.func,
};

export default WidgetsBar;
