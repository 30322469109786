import { ReactElement } from 'react';
import { getMessagingDate, dateToAge } from '@utils/timeHelpers';
import { ItemFields, UserData } from './types';
import {useTranslation} from "react-i18next";

/**
* Build fields for User Information Page
* @param {UserData} userData - All data for user
* @param {ReactElement} mentorsSelect - Select for pick mentors
* @param {ReactElement} courseSelect - Select for pick course
* @param {ReactElement} statusSelect - Select for status
* @returns {Array<ItemFields>} - All fields for User Information Page
*/
export const userInformationFields = (
  userData: UserData,
  mentorsSelect: ReactElement,
  courseSelect: ReactElement,
  statusSelect: ReactElement,
): Array<ItemFields> => {
  const {t} = useTranslation()
  return [
    { label: <span>{t('Informative.Labels.email' as any)}</span>, content: userData.email },
    { label: <span>{t('Informative.Labels.userName' as any)}</span>, content: userData.userName },
    { label: <span>{t('Informative.Labels.startDate' as any)}</span>, content: getMessagingDate(userData.createdAt) },
    { label: <span>{t('Informative.Labels.age' as any)}</span>, content: userData.birthdate && dateToAge(userData.birthdate) },
    { label: <span>{t('Informative.Labels.state' as any)}</span>, content: userData.countryState },
    { label: <span>{t('Informative.Labels.country' as any)}</span>, content: userData.country },
    { label: <span>{t('Informative.Labels.mentor' as any)}</span>, content: mentorsSelect },
    { label: <span>{t('Informative.Labels.course' as any)}</span>, content: courseSelect },
    { label: <span>{t('Informative.Labels.status' as any)}</span>, content: statusSelect },
  ];
}
