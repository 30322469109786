import { graphQuery, graphMutation } from '@services/commService';
import { ACTIVITY_TYPES, TYPES } from '@shared/Resources/types';

export const getUserInformation = async (id) => {
  const query = `
    {
      userData(id: "${id}") {
        id
        roles
        fullName
        lastName
        avatar
        email
        userName
        createdAt
        birthdate
        state
        status
        lastPracticed {
          type
          date
          skill {
            id
            name
          }
        }
        country
        countryState
        mentor {
          id
          fullName
        }
        course {
          name
          id
        }
      }
    }
  `;
  const res = await graphQuery(query, true);
  return res.data;
};

export const getAllMentors = async () => {
  const query = `
  {
    allMentors(offset: 0, limit: 0){
      id
      fullName
    }
  }
  `;
  const res = await graphQuery(query, true);
  return res.data;
};

export const getUserActivityLog = async (userId) => {
  const query = `
  {
    activityLogs(userId: "${userId}"){
      id
      type
      skillName
      startDate
      duration
      status
      entityStatus
      activityId
      externalId
    }
  }
  `;
  const res = await graphQuery(query, true);

  return res.data;
};

export const getUserPendingPracticeDetails = async (userId, activityId) => {
  const entity = `practice {
    id
    name
  }
  charts  {
    type
    subType
    exercises
    productionsWithErrors
    productionsWithoutErrors
    errors
  }
  `;
  const query = `
  {
    userActivityCharts(id: "${activityId}", userId: "${userId}" ){
      id
      type
      ${entity}
    }
  }`;

  const res = await graphQuery(query, true);

  return res.data;
};

export const getUserActivitiesDetailByExternalId = async (userId, externalId, activityType) => {
  const query = `{
  userActivitiesDetailByExternalId(userId: "${userId}", externalId: "${externalId}", type: "${activityType}"){
    id
      type
      
      skill {
        id
        name
      }
      startTime
      duration
      status
      practice {
        id
        name
      }
      charts {
        type
        subType
        exercises
        productionsWithErrors
        productionsWithoutErrors
        errors
      }
  }
}`;

  const res = await graphQuery(query, true);

  return res.data;
};

export const getUserActivityDetail = async (userId, activityId, activityType) => {
  let entity = '';
  if (activityType === ACTIVITY_TYPES.ACTIVITY_PRACTICE || activityType === TYPES.PRACTICE) {
    entity = `practice {
      id
      name
    }
    charts {
      type
      subType
      exercises
      productionsWithErrors
      productionsWithoutErrors
      errors
    }
    `;
  } else if (activityType === ACTIVITY_TYPES.ACTIVITY_LESSON || activityType === TYPES.LESSON) {
    entity = `
    lesson {
      id
      name
    }
    media {
      type
      parentType
      publicUrls
    }
    `;
  } else if (activityType === ACTIVITY_TYPES.ACTIVITY_CHAT) {
    entity = `
    chat {
      isGroup
      participants {
        fullName
        roles
        id
      }
      mentor {
        id
        fullName
      }
      summary {
        fluency
        techniques
        comments
      }
    }
    media {
      type
      parentType
      publicUrls
    }
    `;
  }
  const query = `
  {
    userActivitiesDetail(userId: "${userId}", id: "${activityId}", type: "${activityType}"){
      id
      type
      
      skill {
        id
        name
      }
      startTime
      duration
      status
      ${entity}
    }
  }
  `;
  const res = await graphQuery(query, true);

  return res.data;
};

export const sendUserMentors = async (userId, mentorId, isRemove) => {
  const relationshipType = 'USER_RELATIONSHIP_TYPE_MENTOR';
  const mutationFunction = isRemove ? 'removeUserRelationshipByUserId' : 'addUserRelationship';
  const mutation = `
    mutation {
      ${mutationFunction}(userIds: ["${mentorId}", "${userId}"], relationshipType: ${relationshipType})
    }
  `;
  return await graphMutation(mutation, {}, true);
};

export const sendUserCourse = async (courseId, userId) => {
  const mutation = `
    mutation {
      duplicateCourse(copiedFrom:"${courseId}", owner:"${userId}" ) {
        id
        name
        createdBy
      }
    }
  `;
  const { data } = await graphMutation(mutation, {}, true);
  return data;
};

export const moveUserToCourseLevel = async ({ userId, courseId, levelId }) => {
  const variables = { userId, courseId, levelId };
  const mutation = ''; // TODO write schema
  return await graphMutation(mutation, variables, true);
};

export const sendUserStatus = async (userId, state) => {
  const mutation = `
    mutation {
      createUserState(userId: "${userId}", state: ${state}){
          state
      }
    }
  `;
  return await graphMutation(mutation, {}, true);
};

export const getUserPersonalProgram = async (userId) => {
  const query = `
  {
    entityCoursesForUser(userId: "${userId}") {
      id
      name
      type
      Skills {
        id
        name
        type
        Lessons {
          id
          hasPassed
          isAccessible
          name
          type
        }
        Practices {
          id
          hasPassed
          isAccessible
          name
          type
        }
        Polls {
          id
          hasPassed
          isAccessible
          name
          type
        }
      }
    }
  }
  `;
  const res = await graphQuery(query, true);
  return res.data;
};
