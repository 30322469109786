/**
*
* RadioButtonsGroup
*
*/
import React from 'react';
import PropTypes from 'prop-types';
import {
  RadioOnIcon, RadioOffIcon, RadioInput, Option,
} from './styles';

class RadioButtonsGroup extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedOption: props.value,
    };
  }

  handleOptionChange = (selectedOption) => () => {
    this.setState({ selectedOption });
    this.props.onChange(selectedOption);
  }

  renderSingleOption = ({ label, value }) => {
    const icon = this.state.selectedOption === value ? <RadioOnIcon /> : <RadioOffIcon />;
    return (
      <Option className="radioOption" key={value}>
        <RadioInput
          className="radioOptionInput"
          value={value}
          onChange={this.handleOptionChange(value)}
          checked={this.state.selectedOption === value}
          type="radio"
        />
        {icon}
        {label}
      </Option>
    );
  }

  render() {
    const { options } = this.props;
    return (
      <div>
        {options.map(this.renderSingleOption)}
      </div>
    );
  }
}


RadioButtonsGroup.propTypes = {
  value: PropTypes.any,
  onChange: PropTypes.func,
  options: PropTypes.arrayOf(PropTypes.shape({
    label: PropTypes.any,
    value: PropTypes.any,
  })),
};

export default RadioButtonsGroup;
