import {styled} from "@mui/material";
import Text from '@components/Text';
import { getIconsByType, iconTypes } from '@services/icons';

export const Wrapper = styled('div')(
  {
    padding: 40,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    borderRadius: 4,
    textAlign: 'center',
    height: 690,
    width: 700,
  },
  ({ theme }) => ({
    backgroundColor: theme.palette.novotalk.$backgroundColor,
  })
);

const iconStyles = {
  margin: '0 10px',
  fontSize: 45,
};

export const StyledMicIcon = styled(getIconsByType(iconTypes.SOUND_ON))(
  iconStyles,
  {
    marginLeft: -5,
  },
  ({ theme }) => ({
    color: theme.palette.novotalk.$textColorDisabled,
  })
);

export const StyledVideoIcon = styled(getIconsByType(iconTypes.VIDEO_ON))(
  iconStyles,
  ({ theme }) => ({
    color: theme.palette.novotalk.$textColorDisabled,
  })
);

export const StyledText = styled(Text)({
  display: 'block',
  width: '85%',
  marginBottom: 30,
  lineHeight: '21px',
});

export const StyledImg = styled('img')({
  marginBottom: 36,
});

export const IconsWrapper = styled('div')({
  marginBottom: 30,
  display: 'flex',
  flexShrink: 0,
  textAlign: 'center',
});
