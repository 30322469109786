import Text from "@components/Text";
import { VideoChatParticipants } from './components/video-chat-participants';
import { VideoChatSummary } from './components/video-chat-summary';
import { ACTIVITY_MEDIA_TYPES } from "@shared/Resources/types";
import {useTranslation} from "react-i18next";

const TYPE_VIDEO_CHAT = {
  GROUP: 'Group',
  INDIVIDUAL: 'Individual',
};

/**
 * Video chat panel component
 */
export const VideoChatPanel = ({ activity }) => {
    const {t} = useTranslation()
  const isGroupVideoChat = activity.chat.isGroup;
  const media = activity.media
    .filter(({ type }) => type === ACTIVITY_MEDIA_TYPES.VIDEO)
    .map(({ publicUrls }) => {
      if (publicUrls?.length > 0) {
        return <video controls src={publicUrls[0]} />
      }
      return null;
    });

  return (
    <>
      <Text size="H3" margin="0 0 25px 0">
          {t('UI.typeAndTitle', {
              type: activity.type.split('_').at(1),
              title: isGroupVideoChat ? TYPE_VIDEO_CHAT.GROUP : TYPE_VIDEO_CHAT.INDIVIDUAL,
          })}
      </Text>
      <VideoChatParticipants
        isGroupVideoChat={isGroupVideoChat}
        participants={activity.chat.participants}
        mentor={activity.chat.mentor}
      />
      {activity.chat.summary &&
        <VideoChatSummary
          summary={activity.chat.summary}
        />
      }
      {media}
    </>
  );
};
