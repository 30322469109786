import { fromJS } from 'immutable';
import { Types } from './actions';

export const initialState = fromJS({
  email: '',
  error: null,
});

const confirmEmailReducer = (state = initialState, action) => {
  switch (action.type) {
    case Types.CONFIRM_EMAIL_SUCCESS: {
      return state.set('email', action.email);
    }

    case Types.CONFIRM_EMAIL_FAILURE: {
      return state.set('error', action.error);
    }

    default: {
      return state;
    }
  }
};

export default confirmEmailReducer;
