/**
 *
 * EditableBuilderComponent
 *
 */
import React from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { connect as connectRedux } from 'react-redux';
import Text, { TEXT_SIZE, TEXT_COLOR } from '@components/Text';
import { TYPES } from '@shared/Resources/types';

import {
  isEntity,
  isPollEntity,
} from '../../../../editorObjects/editorComponents';
import {
  EditorObjectHeader,
  DragIconContainer,
  IconContainer,
  getStyledResourceIcon,
  DragIcon,
  DuplicateIcon,
  DeleteIcon,
  EditIcon,
} from './styles';
import { removeComponent, duplicateComponent } from './actions';
import { ENABLE_EDIT_NESTED_RESOURCES } from '@shared/constants';
import {sliceText} from "@utils/textHelpers";

const maxBuilderCardTitleLength = 120

class EditableBuilderComponent extends React.Component {
  renderDraggingHandle = () => (
    <div>
      <DragIconContainer>
        <DragIcon />
      </DragIconContainer>
    </div>
  );

  renderHeader = (hasBody) => {
    const {
      widget: { id, type, payload },
      onRemove,
      onDuplicate,
      onEditResource,
    } = this.props;

    const StyledResourceIcon = getStyledResourceIcon(type);
    const isSkill = type === TYPES.SKILL;

    return (
      <EditorObjectHeader isSkill={isSkill} hasBody={hasBody}>
        {this.renderDraggingHandle()}
        {!isSkill && <StyledResourceIcon />}
        <Text
          size={TEXT_SIZE.T2}
          textColor={TEXT_COLOR.DARK_BLUE}
          className='editableComponentHeader'
        >
          {sliceText(isSkill || !hasBody ? payload.name : payload.title, maxBuilderCardTitleLength, true)}
        </Text>
        {isEntity(type) && ENABLE_EDIT_NESTED_RESOURCES &&
          <IconContainer
            className='editEntityButton'
            isAction
            onClick={() => onEditResource(type, id)}
          >
            <EditIcon />
          </IconContainer>
        }
        {isPollEntity(type) &&
          <IconContainer
            className='duplicateButton'
            isAction
            onClick={() => onDuplicate(id)}
          >
           <DuplicateIcon />
          </IconContainer>
        }
        <IconContainer
          className='removeButton'
          isAction
          onClick={() => onRemove(id)}
        >
          <DeleteIcon />
        </IconContainer>
      </EditorObjectHeader>
    );
  };

  render() {
    return <div>{this.renderHeader(this.props.hasBody)}</div>;
  }
}

/**
eslint disabled because props are used for DND components
*/
EditableBuilderComponent.propTypes = {
  widget: PropTypes.object,
  onRemove: PropTypes.func,
  onDuplicate: PropTypes.func,
  onEditResource: PropTypes.func,
};

export function mapDispatchToProps(dispatch) {
  return {
    onRemove: (id) => dispatch(removeComponent(id)),
    onDuplicate: (id) => dispatch(duplicateComponent(id)),
  };
}
const withConnect = connectRedux(null, mapDispatchToProps);
export const ConnectedEditableBuilderComponent = compose(withConnect)(
  EditableBuilderComponent
);

export default ConnectedEditableBuilderComponent;
