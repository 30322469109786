import React, { Component } from 'react';
import PropTypes from 'prop-types';
import ToggleButton from '@components/ToggleButton';
import { FilterButtons } from '../../styles';
import CustomRangeDialog from './components/CustomRangeDialog';
import { withTranslation } from 'react-i18next';
import { compose } from 'redux';

class DateFilter extends Component {
  constructor(props) {
    super(props);
    this.closeCustomDialog = this.closeCustomDialog.bind(this);
    this.dateRangeFilter = [
      { label: this.props.t('Dates.days7'), hours: 168 },
      { label: this.props.t('Dates.hours48'), hours: 48 },
      { label: this.props.t('Dates.hours24'), hours: 24 },
      { label: this.props.t('Common.Statuses.custom'), hours: 0 },
    ];

    this.state = {
      customOpen: false,
      customRangeNumber: 1,
      customRangeUnits: '',
    };
  }

  closeCustomDialog() {
    this.setState({ customOpen: false });
  }

  handleRangeButtonClick(range) {
    if (range.hours === 0) {
      this.setState({ customOpen: !this.state.customOpen });
    } else {
      this.props.setTimeRangeFilter(range.hours);
    }
  }

  renderButton(range) {
    let selected;
    if (range.hours !== 0) {
      selected = this.props.timeRangeFilter === range.hours;
    } else {
      selected = ![24, 48, 168].includes(this.props.timeRangeFilter);
    }

    return (
      <ToggleButton
        key={range.hours}
        id={`dateRange${range.hours}`}
        isSelected={selected}
        disabled={this.props.loading}
        onClick={() => this.handleRangeButtonClick(range)}
      >
        {range.label}
      </ToggleButton>
    );
  }

  render() {
    const { setTimeRangeFilter } = this.props;
    return (
      <div>
        <FilterButtons>
          {this.dateRangeFilter.map((range) => this.renderButton(range))}
        </FilterButtons>
        <CustomRangeDialog
          setTimeRangeFilter={setTimeRangeFilter}
          customOpen={this.state.customOpen}
          customClose={this.closeCustomDialog}
        />
      </div>
    );
  }
}

DateFilter.propTypes = {
  timeRangeFilter: PropTypes.number,
  setTimeRangeFilter: PropTypes.func,
  loading: PropTypes.bool,
};

export default compose(
    withTranslation()
)(DateFilter)