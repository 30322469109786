//import FlakeId from 'flake-idgen';
import intformat from 'biguint-format';
import EditorObject from './editorObject';
import defaults from './editorObjectsDefaults';
//const flakeIdGen = new FlakeId();

class EditorObjectsFactoryClass {
  createObject = (type) => {
    if (!defaults[type]) throw new Error(`Invalid type ${type}`);

    const { payload, children } = defaults[type];
    // set unique id
    //payload.id = intformat(flakeIdGen.next(), 'hex');
    payload.id = 'test';
    const newObject = new EditorObject(type).setPayload(payload);
    if (children) {
      children.forEach((child) => {
        newObject.addChild(EditorObject.fromJSON(child));
      });
    }
    return newObject;
  };

  createFromJSON = (data) => EditorObject.fromJSON(data);
}

export const EditorObjectsFactory = new EditorObjectsFactoryClass();
