import _ from 'lodash';
import { AUTH_SERVER, BILLING_SERVER, AUTH_ENDPOINTS, BILLING_ENDPOINTS } from '@services/servers';
import { graphQuery, postRequest } from '@services/commService';


export const getUserData = async () => {
  const query = `
  {
    users {
      id
      fullName
      email
      birthdate
      self
      plans{
        planId
        nextCharge
        status
        chargeFrequency
        price{
          amount
          currency
        }
      }
    }
    entityCourses {
      name
      Subscription{
        type
      }
    }
  }
  `;
  const res = await graphQuery(query, true);
  const { users, entityCourses } = res.data;
  const selfUser = _.find(users, { self: true });
  return { user: selfUser, course: entityCourses[0] };
};

export const changePassword = async (oldPassword, newPassword) => {
  try {
    const response = await postRequest(
      AUTH_SERVER, AUTH_ENDPOINTS.CHANGE_PASSWORD,
      { oldPassword, newPassword });
    return response.body;
  } catch (error) {
    throw (new Error(error.message));
  }
};

export const cancelSubscription = async (planId) => {
  try {
    const response = await postRequest(
      BILLING_SERVER, BILLING_ENDPOINTS.CANCEL_SUBSCRIPTION,
      { planId });
    return response.body;
  } catch (error) {
    throw (new Error(error.message));
  }
};
