import {styled} from "@mui/material";
import { getResponsiveCSS } from '@utils/cssHelpers';

export const MonitorContainer = styled('div')({
  margin: 'auto',
  minWidth: 780,
  maxWidth: 1130,
});

export const MonitorOverlay = styled('div')({
    position: 'absolute',
    top: 0,
    minHeight: 120,
    zIndex: 99,
    alignItems: 'center',
    justifyContent: 'center',
  },
  getResponsiveCSS('width', 780, 1130),
  ({ theme, monitorActive }) => ({
    backgroundColor: theme.palette.novotalk.$monitorOverlayColor,
    display: monitorActive ? 'none' : 'flex',
  })
);
