/**
 * Slider
 */
import React from 'react';
import _ from 'lodash';
import PropTypes from 'prop-types';
import Range from 'react-input-range';
import Text, { TEXT_SIZE, TEXT_COLOR } from '@components/Text';
import { RangeWrapper, LabelContainer, StyledLabel } from './styles';

class Slider extends React.Component {
  // eslint-disable-line react/prefer-stateless-function
  constructor(props) {
    super(props);
    this.state = {
      value: props.value,
    };
  }

  shouldComponentUpdate(nextProps) {
    if (!_.isEqual(nextProps.value, this.props.value)) {
      this.setState({ value: nextProps.value });
      return false;
    }
    return true;
  }

  getLabels = () => {
    const { minValue, maxValue, step, width, noLabels } = this.props;
    const { value } = this.state;
    const labels = [];
    for (let i = minValue; i <= maxValue; i += step) {
      const isValueChoosen = _.isNumber(value)
        ? i <= value
        : i >= value.min && i <= value.max;
      const position = width * ((i - minValue) / (maxValue - minValue));
      labels.push(
        <LabelContainer
          isValueChoosen={isValueChoosen}
          position={position}
          key={position}
        >
          {!noLabels && (
            <StyledLabel>
              <Text
                size={TEXT_SIZE.H4}
                textColor={TEXT_COLOR[isValueChoosen ? 'ACTIVE' : 'DARK_BLUE']}
              >
                {i}
              </Text>
            </StyledLabel>
          )}
        </LabelContainer>
      );
    }
    return labels;
  };

  updateValue = (value) => {
    this.setState({ value });
    this.props.onChange(value);
  };

  render() {
    const { value } = this.state;
    const { minValue, maxValue, step, width, disabled, className } = this.props;
    return (
      <RangeWrapper width={width} disabled={disabled} className={className}>
        {this.getLabels()}
        <Range
          allowSameValues
          value={value}
          onChange={this.updateValue}
          step={step}
          minValue={minValue}
          maxValue={maxValue}
          className='slider'
          disabled={disabled}
        />
      </RangeWrapper>
    );
  }
}

Slider.defaultProps = {
  value: 0,
  minValue: 0,
  step: 10,
  maxValue: 100,
  width: 280,
};

Slider.propTypes = {
  value: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.shape({
      min: PropTypes.number,
      max: PropTypes.number,
    }),
  ]),
  minValue: PropTypes.number,
  maxValue: PropTypes.number,
  step: PropTypes.number,
  onChange: PropTypes.func,
  width: PropTypes.number,
  noLabels: PropTypes.bool,
  disabled: PropTypes.bool,
  className: PropTypes.string,
};

export default Slider;
