import { Component } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { connect } from 'react-redux';
import {
  ERROR_TYPES, LOCATION_TYPES, MONITOR_HANDLER_STATUS, PATTERN_TYPES,
} from '@shared/Resources/Monitor/types';
import { CALIBRATION_STATUS } from '@components/NoiseCalibration/consts';
import Loading from '@components/SectionLoading';
import {
  changeCalibrationStatus,
  setExercisePausedFlag,
  setMicrophoneActiveFlag,
  setMonitorActiveFlag,
} from '@containers/User/containers/CourseRouter/containers/MonitorHandler/actions';
import Monitor from './containers/Monitor';
import { Wrapper } from './styles';
import {
  startMicrophone,
  stopMicrophone,
  setActivityId,
} from './services/MicrophoneService';
import { GRAPH_SIZE } from './containers/Monitor/constants';

class DSPHandler extends Component {
  constructor(props) {
    super(props);
    this.state = {
      production: 0,
      monitors: [],
      currentMonitor: 0,
      numberOfMonitors: 0,
      microphoneStarted: false,
      monitorErrors: [],
      monitorResult: {},
      activityId: null,
      inhalationTime: 0,
      oneSecondMonitorDuration: 1,
    };
  }

  timeStamp = 0;

  oneStepDuration = 0;

  countSignals = 1;

  componentDidMount() {
    this.setMonitorsFromProps();
  }

  componentDidUpdate(prevProps) {
    const {
      monitor,
      pattern,
      microphoneStarted,
      activityContext,
      startNoiseCalibration,
      calibrationStatus,
      dispatchChangeCalibrationStatus,
    } = this.props;

    const monitorType = monitor && monitor.type;
    const analysisType = monitorType === 'LOUDNESS' ? pattern.type : 'NO_ANALYSIS';

    if (!_.isEqual(monitor, prevProps.monitor) || !_.isEqual(pattern, prevProps.pattern) || (analysisType === PATTERN_TYPES.ONE_SECOND && prevProps?.pattern?.productionDuration !== this.state.oneSecondMonitorDuration)) {
      this.setState({ oneSecondMonitorDuration: pattern.productionDuration });
      this.setMonitorsFromProps();
    }

    if (activityContext
      && (activityContext.ACTIVITY_EXERCISE || activityContext.ACTIVITY_LESSON)
      && !this.state.activityId
    ) {
      const activityId = activityContext.ACTIVITY_EXERCISE || activityContext.ACTIVITY_LESSON;
      setActivityId(activityId);
      this.setState({ activityId });
    }

    if (microphoneStarted && !this.state.microphoneStarted) {
      startMicrophone(this.handleDSPInvocations, analysisType);
      this.setState({ microphoneStarted: true });
    }

    if (!microphoneStarted && this.state.microphoneStarted) {
      stopMicrophone();
      this.setState({ microphoneStarted: false });
    }

    // If microphone calibration turn off and status is "INACTIVE" - activate microphone and change status to "INITIAL"
    if (!startNoiseCalibration && calibrationStatus === CALIBRATION_STATUS.INACTIVE) {
      dispatchChangeCalibrationStatus(CALIBRATION_STATUS.INITIAL);
    }
  }

  componentWillUnmount() {
    if (this.state.microphoneStarted) stopMicrophone();
  }

  setMonitorsFromProps = () => {
    const { monitor, pattern } = this.props;
    const monitors = this.createMonitorsArray(monitor, pattern);
    const monitorErrors = monitors.map(() => []); // create an array of errors for each monitor
    this.setState({
      production: 0,
      monitors,
      currentMonitor: 0,
      numberOfMonitors: monitors.length,
      monitorErrors,
    });
  };

  createMonitorsArray = (monitor, pattern) => {
    const monitors = [];
    switch (monitor && monitor.type) {
      case 'BREATHING':
        monitors.push({
          patternType: 'BREATHING',
          dspResults: [],
          dspSignal: [],
          duration: pattern.productionDuration,
        });
        break;
      case 'LOUDNESS':
        monitors.push({
          patternType: pattern.type,
          dspResults: [],
          dspSignal: [],
          duration: pattern.productionDuration,
        });
        break;

      default:
        console.error('unknown monitor input', { monitor, pattern });
        break;
    }
    if (monitors.length > 0 && monitor.inhalation.on) {
      monitors.push({
        patternType: 'INHALE',
        dspResults: [],
        dspSignal: [],
        inhalationTime: monitor.inhalation.value,
        duration: monitor.inhalation.value,
      });
    }
    return monitors;
  };

  // **********************************************************
  //  this section is in charge of DSP and microphone handling
  // **********************************************************
  updateDSPSignal(payload) {
    // TODO: create a method that returns updated object and no side effect
    const { currentMonitor, monitors } = this.state;
    const newTimeStamp = new Date().getTime();

    monitors[currentMonitor].dspSignal.push(payload);
    this.setState({ monitors });

    // If signal of recording is first - define initial time stamp
    if (this.countSignals === 1) {
      this.timeStamp = newTimeStamp;
    }

    // If signal of recording is fifth - calculate one step duration
    if (this.countSignals === 5 && payload >= 0) {
      this.oneStepDuration = Math.abs(newTimeStamp - this.timeStamp) / 4;
    }

    // For the next signals don't calculate one step duration
    if (this.countSignals !== 6) {
      this.countSignals++;
    }
  }

  pushError = ({ type, location, payload }) => {
    const { monitorErrors, currentMonitor } = this.state;
    monitorErrors[currentMonitor].push({
      type,
      location,
      exists: payload === 0, // 0 means an error, 1 means not a problem
    });
  };

  handleDSPInvocations = (type, payload, vect) => {
    let location; // for errors;
    switch (type) {
      case 'originalDSPEngineAnalysisGraphicChunkAvailable':
        // new DSP graphic value available
        this.updateDSPSignal(payload);
        break;
      // two seconds per syllable, Gentle Voice and Loud Voice errors
      case ERROR_TYPES.nonGentleOnset:
      case ERROR_TYPES.sharpRiseBeginning:
      case ERROR_TYPES.volumeRiseBeginning:
      case ERROR_TYPES.shapeBeginning:
      case ERROR_TYPES.notGentleBeginning:
      case ERROR_TYPES.nonGentleOnsetSmm:
      case ERROR_TYPES.nonGentleOnsetOsm:
        location = LOCATION_TYPES.BEGINNING;
        this.pushError({ type, location, payload });
        break;
      case ERROR_TYPES.excessivePeak:
      case ERROR_TYPES.softPeak:
        location = LOCATION_TYPES.PEAK;
        this.pushError({ type, location, payload });
        break;
      case ERROR_TYPES.shapeEnd:
      case ERROR_TYPES.volumeFallEnd:
      case ERROR_TYPES.sharpFallEnd:
      case ERROR_TYPES.nonGentleOffset:
      case ERROR_TYPES.notGentleEnd:
        location = LOCATION_TYPES.END;
        this.pushError({ type, location, payload });
        break;
      case ERROR_TYPES.notGentleProduction:
      case ERROR_TYPES.notLoudProduction:
      case ERROR_TYPES.noLinkingSmm:
      case ERROR_TYPES.tooFlatSmm:
        location = LOCATION_TYPES.RIGHT_SIDE_HIGH;
        this.pushError({ type, location, payload });
        break;
      case ERROR_TYPES.tooFastOsm:
      case ERROR_TYPES.tooSlowOsm:
      case ERROR_TYPES.tooFastSmm:
      case ERROR_TYPES.tooSlowSmm:
        location = LOCATION_TYPES.RIGHT_SIDE_MIDDLE;
        this.pushError({ type, location, payload });
        break;
      case ERROR_TYPES.SoftPeakOsm:
      case ERROR_TYPES.noLinkingOsm:
      case ERROR_TYPES.tooFlatOsm:
        if (!payload) {
          const listOfTimeStamps = vect?.split(' ');
          listOfTimeStamps?.forEach((time) => {
            if (time?.length) {
              this.pushError({ type, location: time, payload });
            }
          });
          break;
        }
        this.pushError({ type, vect, payload });
        break;
      default:
        break;
    }
  };

  // **********************************************************
  //  end of section  in charge of DSP and microphone handling
  // **********************************************************

  handleMonitorCommunication = (type, payload) => {
    // this gets messages from monitor
    // TODO: tidy up into consts and updatedProduction etc...
    let { production, currentMonitor } = this.state;
    const { numberOfMonitors, monitors } = this.state;
    const { updateMonitorResults } = this.props;
    //
    switch (type) {
      case MONITOR_HANDLER_STATUS.PRODUCTION_INIT:
        updateMonitorResults(type, production);
        break;
      case MONITOR_HANDLER_STATUS.PRODUCTION_START:
        updateMonitorResults(type, production);
        break;
      case MONITOR_HANDLER_STATUS.SINGLE_MONITOR_PRODUCTION_END: {
        let { monitorErrors, monitorResult } = this.state;
        const { monitor, pattern } = this.props;
        const {
          dspSignal, startTS, endTS, errors,
        } = payload;
        if (currentMonitor === numberOfMonitors - 1) {
          monitorErrors = monitors.map(() => []); // create an array of errors for each monitor
          monitorResult.inhalationDuration = endTS - startTS;
          if (monitors[currentMonitor].patternType === 'INHALE') {
            monitorResult.productionErrors = _.concat(
              monitorResult.productionErrors,
              errors.map((error) => ({ ...error, location: 'inhalation' })),
            );
          }
          currentMonitor = 0;
          production += 1;
          updateMonitorResults(type, {
            updatedProduction: production,
            monitorResult,
          });
          monitorResult = {}; // reset for next production
        } else {
          monitorResult = {
            monitorType: monitor.type,
            patternType: pattern.type,
            speed: null,
            inhalationDuration: null,
            productionErrors: errors,
            dspSignal,
            startTS,
            endTS,
          };
          currentMonitor += 1;
          updateMonitorResults(MONITOR_HANDLER_STATUS.MAIN_MONITOR_RESULTS, {
            updatedProduction: production,
            monitorResult,
          });
        }
        this.setState({
          currentMonitor,
          production,
          monitorErrors,
          monitorResult,
        });
        break;
      }
      default:
        throw new Error('unknown request from monitor!');
    }
  };

  conditionHelper(patternType, duration) {
    const { listAmountsOfSyllables, currentSequenceIndex } = this.props;
    if (listAmountsOfSyllables?.length) {
      return GRAPH_SIZE[patternType] && duration && (listAmountsOfSyllables?.length > currentSequenceIndex);
    }
    return GRAPH_SIZE[patternType] && duration;
  }

  getSelfManagePace = () => {
    const { currentLesson, currentPractice } = this.props;
    let pace = {};

    if (currentLesson?.lesson && Object.keys(currentLesson.lesson).length !== 0) {
      pace = currentLesson.lesson.monitor.pattern?.selfManagePace;
    }

    if (currentPractice?.currentExercise && Object.keys(currentPractice.currentExercise).length !== 0) {
      pace = currentPractice.currentExercise.monitor.pattern?.selfManagePace;
    }

    return pace;
  };

  renderMonitors() {
    const {
      monitors, production, monitorErrors, currentMonitor,
    } = this.state;
    const {
      showMonitor,
      monitorActive,
      videoChatActive,
      exercisePaused,
      dispatchSetMonitorActiveFlag,
      dispatchSetMicrophoneActiveFlag,
      dispatchSetExercisePausedFlag,
      listAmountsOfSyllables,
      currentSequenceIndex,
      calibrationStatus,
    } = this.props;

    const monitorProps = {
      handleMonitorCommunication: this.handleMonitorCommunication,
      selfManagePace: this.getSelfManagePace(),
      currentMonitor,
      showMonitor,
      monitorActive,
      dispatchSetMonitorActiveFlag,
      dispatchSetMicrophoneActiveFlag,
      dispatchSetExercisePausedFlag,
      videoChatActive,
      exercisePaused,
    };

    return (
      <Wrapper id="MonitorsWrapper">
        {monitors.map(({
          patternType, dspSignal, inhalationTime, duration,
        }, index) => (
          <>
            {this.conditionHelper(patternType, duration)
              ? (
                <Monitor
                  patternType={patternType}
                  key={`${patternType}${index}${production}`}
                  dspSignal={dspSignal}
                  monitorNumber={index}
                  monitorErrors={monitorErrors[index]}
                  currentMonitor={currentMonitor}
                  inhalationTime={inhalationTime}
                  duration={listAmountsOfSyllables?.length ? listAmountsOfSyllables[currentSequenceIndex] : duration}
                  oneStepDuration={this.oneStepDuration}
                  calibrationStatus={calibrationStatus}
                  {...monitorProps}
                />
              )
              : <Loading className="loading" />}
          </>
        ))}
      </Wrapper>
    );
  }

  render() {
    return <div>{this.renderMonitors()}</div>;
  }
}

DSPHandler.propTypes = {
  monitor: PropTypes.object,
  pattern: PropTypes.object,
  updateMonitorResults: PropTypes.func,
  microphoneStarted: PropTypes.bool,
  activityContext: PropTypes.object,
  listAmountsOfSyllables: PropTypes.array,
};

const mapStateToProps = (state) => {
  const videoChatActive = state.getIn(['videoChat', 'chatId']);
  const currentLesson = state.getIn(['currentLesson'])?.toJS();
  const currentPractice = state.getIn(['currentPractice'])?.toJS();
  const {
    monitorActive,
    exercisePaused,
    currentSequenceIndex,
    calibrationStatus,
  } = state.getIn(['monitorHandler']).toJS();
  return {
    monitorActive,
    videoChatActive,
    exercisePaused,
    currentSequenceIndex,
    currentLesson,
    currentPractice,
    calibrationStatus,
  };
};

const mapDispatchToProps = (dispatch) => ({
  dispatchSetMonitorActiveFlag: (value) => dispatch(setMonitorActiveFlag(value)),
  dispatchSetMicrophoneActiveFlag: (value) => dispatch(setMicrophoneActiveFlag(value)),
  dispatchSetExercisePausedFlag: (value) => dispatch(setExercisePausedFlag(value)),
  dispatchChangeCalibrationStatus: (status) => dispatch(changeCalibrationStatus(status)),
});

export default connect(mapStateToProps, mapDispatchToProps)(DSPHandler);
