export const poll = {
  id: '59aaa23434400000',
  name: 'default poll',
  texts: {
    description: 'description of my excellent poll',
  },
  children: [
    {
      id: '59aaa36117c00000',
      type: 'TEXT',
      payload: {
        text: 'instructions for my excellent poll',
        editorPlaceholder: 'Insert Instructions here...',
      },
    },
    {
      id: '59aaa34945800000',
      type: 'MEDIA',
      payload: {
        description: 'My media for the poll',
        id: 'someMediaID',
      },
    },
    {
      id: '59aaa27d9e000000',
      type: 'QUESTION',
      payload: {
        type: 'FREE_TEXT',
        questionText: 'free text question',
        answers: [],
      },
    },
    {
      id: '59aaa285cb400000',
      type: 'QUESTION',
      payload: {
        type: 'SINGLE_SELECT',
        questionText: 'single select question',
        answers: [
          {
            id: '59aaa2927d000000',
            value: '1st answer',
          },
          {
            id: '59aaa29984c00000',
            value: '2nd answer',
          },
          {
            id: '59aaa307e7c00000',
            value: '3rd answer',
          },
          {
            id: '59aaa30be7000000',
            value: '4th answer',
          },
        ],
      },
    },
    {
      id: '59aaa314b4c00000',
      type: 'QUESTION',
      payload: {
        type: 'MULTI_SELECT',
        questionText: 'multi select question',
        answers: [
          {
            id: '59aaa2927d000000',
            value: '1st answer',
          },
          {
            id: '59aaa29984c00000',
            value: '2nd answer',
          },
          {
            id: '59aaa307e7c00000',
            value: '3rd answer',
          },
          {
            id: '59aaa30be7000000',
            value: '4th answer',
          },
        ],
      },
    },
    {
      id: '59aaa318be400000',
      type: 'QUESTION',
      payload: {
        type: 'DROP_DOWN',
        questionText: 'drop down question',
        answers: [
          {
            id: '59aaa2927d000000',
            value: '1st answer',
          },
          {
            id: '59aaa29984c00000',
            value: '2nd answer',
          },
          {
            id: '59aaa307e7c00000',
            value: '3rd answer',
          },
          {
            id: '59aaa30be7000000',
            value: '4th answer',
          },
        ],
      },
    },
    {
      id: '59aaa32225800000',
      type: 'QUESTION',
      payload: {
        type: 'DATE',
        questionText: 'date question',
        answers: [],
      },
    },
  ],
  version: 1,
  createdAt: '2018-10-25T09:28:32.868Z',
};


export const pollsList = [
  { id: 1, name: 'Poll simon', created: 'Thu Aug 09 2018 08:46:00 GMT+0000 (UTC)', updated: 'Thu SEP 06 2018 08:46:00 GMT+0000 (UTC)' },
  { id: 2, name: 'Polly poll poll', created: 'Thu Aug 16 2018 18:46:30 GMT+0000 (UTC)', updated: 'Thu SEP 13 2018 18:46:30 GMT+0000 (UTC)' },
  { id: 3, name: 'Some last poll before i go', created: 'Thu Aug 23 2018 02:46:54 GMT+0000 (UTC)', updated: 'Thu SEP 20 2018 02:46:54 GMT+0000 (UTC)' },
];
