import {styled} from "@mui/material";

const height = 22;
const width = 40;
const spacing = 2;
const switchSize = height - 2 * spacing;
const transform = `translateX(${width - switchSize - 2 * spacing}px)`;
const speed = '.1s';

export const SwitchWrapper = styled('span')({
  display: 'flex',
  margin: '10px 0',
});

export const Slider = styled('span')(
  {
    position: 'relative',
    width,
    height,
    borderRadius: height,
    cursor: 'pointer',
    transition: speed,
  },
  ({ on, theme, withMargin }) => ({
    margin: withMargin ? '0 8px' : 0,
    backgroundColor: on ? theme.palette.novotalk.$activeColor : theme.palette.novotalk.$buttonColorDisabled,
    '&:before': {
      position: 'absolute',
      content: "''",
      height: switchSize,
      width: switchSize,
      left: spacing,
      bottom: spacing,
      backgroundColor: theme.palette.novotalk.$backgroundColor,
      transition: speed,
      borderRadius: '50%',
      transform: on ? transform : null,
    },
  })
);
