const SET_PENDING_DASHBOARD_DATA = 'SET_PENDING_DASHBOARD_DATA';
const GET_PENDING_DATA = 'GET_PENDING_DATA';
const CONTACT_USER = 'CONTACT_USER';
const DISMISS_ACTIVITY = 'DISMISS_ACTIVITY';
const DISMISS_ACTIVITY_COMPLETE = 'DISMISS_ACTIVITY_COMPLETE';

const GET_HISTORY_FROM_HOURS = 'GET_HISTORY_FROM_HOURS';
const SET_HISTORY_FROM_HOURS = 'SET_HISTORY_FROM_HOURS';

const INVITE_TO_VIDEO_CHAT = 'INVITE_TO_VIDEO_CHAT';
const SET_CHAT_SESSION_ID = 'SET_CHAT_SESSION_ID';
const CHAT_SESSION_OPENED = 'CHAT_SESSION_OPENED';


export const Types = {
  SET_PENDING_DASHBOARD_DATA,
  GET_PENDING_DATA,
  GET_HISTORY_FROM_HOURS,
  SET_HISTORY_FROM_HOURS,
  CONTACT_USER,
  DISMISS_ACTIVITY,
  DISMISS_ACTIVITY_COMPLETE,
  INVITE_TO_VIDEO_CHAT,
  SET_CHAT_SESSION_ID,
  CHAT_SESSION_OPENED,
};

export const inviteToVideoChat = (inviteList) => ({
  type: INVITE_TO_VIDEO_CHAT,
  payload: { inviteList },
});

export const chatSessionOpened = () => ({
  type: CHAT_SESSION_OPENED,
});

export const setChatSessionId = (sessionId) => ({
  type: SET_CHAT_SESSION_ID,
  payload: { sessionId },
});

export const dismissActivity = (activity, reason) => ({
  type: DISMISS_ACTIVITY,
  payload: { activity, reason },
});

export const getHistoryFromHours = (hours) => ({
  type: GET_HISTORY_FROM_HOURS,
  payload: { hours },
});

export const setHistoryFromHours = (hours, activities) => ({
  type: SET_HISTORY_FROM_HOURS,
  payload: { hours, activities },
});

export const dismissActivityComplete = (activity, reason) => ({
  type: DISMISS_ACTIVITY_COMPLETE,
  payload: { activity, reason },
});

export const getPendingDashboardData = (hours) => ({
  type: GET_PENDING_DATA,
  payload: { hours },
});

export const setPendingDashboardData = (payload) => ({
  type: SET_PENDING_DASHBOARD_DATA,
  payload,
});
