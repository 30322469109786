/**
*
* ErrorIcon
*
*/
import React from 'react';
import PropTypes from 'prop-types';
import { ERROR_TYPES } from '@shared/Resources/Monitor/types';
import { getIconsByType, iconTypes } from '@services/icons';

const errorToIconMapping = {
  [ERROR_TYPES.tooShortInhalation]: iconTypes.INSIGHTS_LENGTH,
  [ERROR_TYPES.nonGentleOnset]: iconTypes.INSIGHTS_GENTLE,
  [ERROR_TYPES.volumeControlBeginning]: iconTypes.INSIGHTS_VOLUME_CONTROL,
  [ERROR_TYPES.notGentleBeginning]: iconTypes.INSIGHTS_GENTLE,
  [ERROR_TYPES.softPeak]: iconTypes.INSIGHTS_VOLUME_CONTROL,
  [ERROR_TYPES.excessivePeak]: iconTypes.INSIGHTS_VOLUME_CONTROL,
  [ERROR_TYPES.volumeControlEnd]: iconTypes.INSIGHTS_VOLUME_CONTROL,
  [ERROR_TYPES.nonGentleOffset]: iconTypes.INSIGHTS_GENTLE,
  [ERROR_TYPES.notGentleEnd]: iconTypes.INSIGHTS_GENTLE,
  [ERROR_TYPES.tooShortProduction]: iconTypes.INSIGHTS_LENGTH,
  [ERROR_TYPES.tooLongProduction]: iconTypes.INSIGHTS_LENGTH,
  [ERROR_TYPES.notLoudProduction]: iconTypes.INSIGHTS_VOLUME_CONTROL,
  [ERROR_TYPES.notGentleProduction]: iconTypes.INSIGHTS_GENTLE,
  [ERROR_TYPES.nonGentleOnsetOsm]: iconTypes.INSIGHTS_GENTLE,
  [ERROR_TYPES.SoftPeakOsm]: iconTypes.INSIGHTS_VOLUME_CONTROL,
  [ERROR_TYPES.noLinkingOsm]: iconTypes.INSIGHTS_TOO_DEEP,
  [ERROR_TYPES.tooFlatOsm]: iconTypes.INSIGHTS_TOO_FLAT,
  [ERROR_TYPES.tooFastOsm]: iconTypes.INSIGHTS_TOO_FAST,
  [ERROR_TYPES.tooSlowOsm]: iconTypes.INSIGHTS_TOO_SLOW,
  [ERROR_TYPES.nonGentleOnsetSmm]: iconTypes.INSIGHTS_GENTLE,
  [ERROR_TYPES.noLinkingSmm]: iconTypes.INSIGHTS_TOO_DEEP,
  [ERROR_TYPES.tooFlatSmm]: iconTypes.INSIGHTS_TOO_FLAT,
  [ERROR_TYPES.tooFastSmm]: iconTypes.INSIGHTS_TOO_FAST,
  [ERROR_TYPES.tooSlowSmm]: iconTypes.INSIGHTS_TOO_SLOW,
  noPattern: iconTypes.INSIGHTS_PATTERN,
};


const ErrorIcon = ({ errorType }) => {
  const errorMessage = `missing icon for ${errorType}`;
  if (!errorToIconMapping[errorType]) {
    console.error(errorMessage);
    return <span>{errorMessage}</span>;
  }
  const Icon = getIconsByType(errorToIconMapping[errorType]);
  return <Icon />;
};

ErrorIcon.propTypes = {
  errorType: PropTypes.string,
};

export default ErrorIcon;
