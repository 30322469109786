import { fromJS } from 'immutable';
import { Types, uploadStatus } from './actions';

export const initialState = fromJS({
  uploadStatus: uploadStatus.DEFAULT,
  loadingProgress: 0,
  error: null,
  sentItem: null,
});

const mediaUploadReducer = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case Types.SEND_MEDIA_DATA: {
      return state;
    }

    case Types.SET_ERROR: {
      const { error } = payload;
      return state.set('error', error)
        .set('uploadStatus', uploadStatus.ERROR);
    }

    case Types.SET_LOADING_PROGRESS: {
      const { value } = payload;
      return state.set('loadingProgress', value);
    }

    case Types.SET_UPLOAD_STATUS: {
      const { status, sentItem } = payload;
      return state.set('sentItem', sentItem)
        .set('uploadStatus', status);
    }
    default: {
      return state;
    }
  }
};

export default mediaUploadReducer;
