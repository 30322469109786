import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { TEXT_SIZE, TEXT_COLOR } from '@components/Text';
import Slider from '@components/Slider';
import CheckBoxGroup from '../../shared/components/CheckBoxGroup';
import {
  STIMULUS_SYLLABLE_STRUCTURE,
  STIMULUS_CONSONANTS_FEATURES,
  STIMULUS_WORDS,
  STIMULUS_DIVISION_TYPE,
} from '../../../../../../../types';
import { ConfigSection } from '../../../../styles';
import { SliderContainer } from './styles';
import { getFormattedTextCurry } from '@shared/Resources/Monitor/shared/utils/formattedText';
import StimulusDivision from '../../shared/components/StimulusDivision';

const getFormattedText = getFormattedTextCurry();
const syllableStructure = _.values(STIMULUS_SYLLABLE_STRUCTURE);
const consonantsFeatures = _.values(STIMULUS_CONSONANTS_FEATURES);
const words = _.values(STIMULUS_WORDS);

const WordsConfig = ({ config, updateConfig, updateField }) => {
  const createCheckbox = (groupName, groupValues, capitalize = true) => (
    <CheckBoxGroup
      config={config}
      onChange={updateConfig}
      {...{ groupName, groupValues }}
      groupName={groupName}
      groupValues={groupValues}
      capitalize={capitalize}
    />
  );

  return (
    <React.Fragment>
      {createCheckbox('wordType', words)}
      <StimulusDivision
        value={config.division}
        onChange={updateField('division')}
        dividedValue={STIMULUS_DIVISION_TYPE.DIVIDED}
        dividedMessage={'divided'}
      />
      <ConfigSection className='syllableCount'>
        <div>
          {getFormattedText('Stimuli.syllableCount', {
            weight: 'bold',
            textColor: TEXT_COLOR.DARK_BLUE,
            size: TEXT_SIZE.H4,
          })}
        </div>
        <SliderContainer>
          <Slider
            className='syllableCountInput'
            onChange={updateField('syllableCount')}
            value={config.syllableCount}
            minValue={1}
            maxValue={6}
            step={1}
          />
        </SliderContainer>
      </ConfigSection>
      {createCheckbox('syllableStructure', syllableStructure, false)}
      {createCheckbox('consonantsFeatures', consonantsFeatures)}
    </React.Fragment>
  );
};

WordsConfig.propTypes = {
  config: PropTypes.object,
  updateConfig: PropTypes.func,
  updateField: PropTypes.func,
};

export default WordsConfig;
