import React, { memo } from 'react';
import Text, { TEXT_SIZE } from '@components/Text';
import TextButton from '@components/TextButton';
import { BackIcon, BackButtonContainer } from './styles';

export const BackButton = memo(({ onClick, text, margin }) => (
  <BackButtonContainer margin={margin}>
    <TextButton onClick={onClick}>
      <BackIcon />
      <Text size={TEXT_SIZE.T3}>
        {text}
      </Text>
    </TextButton>
  </BackButtonContainer>
));
