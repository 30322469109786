import { Component } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import ProgressiveImage from 'react-progressive-graceful-image';
import Text, {TEXT_COLOR, TextSizeWrapper} from '@components/Text';
import {
  STIMULUS_DIVISION_TYPE,
  STIMULUS_TYPE,
} from '@shared/Resources/Monitor/types';
import injectReducer from '~/utils/injectReducer';
import injectSaga from '~/utils/injectSaga';
import reducer from './reducer';
import saga from './saga';
import { getStimuli, resetStimuli, toggleLoadingStimuli } from './actions';
import {
  Wrapper,
  Syllable,
  StimuliPratsWrapper,
  StimuliModuleWrapper,
  StimuliText,
  StimuliButton,
  SpeechTopicText,
  Loader,
  LoaderWrapper,
  SpeechTopicWrapper,
  CommonStimuliWrapper,
  StimuliSpeechTopicButton,
} from './styles';
import { PictureStimuli } from './PictureStimuliWrapper';
import {withTranslation} from "react-i18next";

const IMAGE_LOADER_DELAY = 500;

export class StimuliModule extends Component {
  state = {
    nextButtonCount: 0,
  };

  componentDidMount() {
    this.getStimuli();
  }

  componentDidUpdate(prevProps) {
    const { currentExercise } = this.props;
    if (currentExercise.id !== prevProps.currentExercise.id) {
      this.getStimuli();
    }
  }

  getStimuli() {
    const {
      type, config, progress, dispatchGetStimuli, currentExercise,
    } = this.props;
    const patternType = currentExercise?.monitor?.pattern?.type;
    const entity = { id: currentExercise.id, type: currentExercise.type };

    dispatchGetStimuli({ type, config, entity });
  }

  nextButtonHandle() {
    const { nextButtonCount } = this.state;
    const { stimuli, dispatchToggleLoadingStimuli } = this.props;
    if (nextButtonCount >= (stimuli.length - 1)) {
      this.getStimuli();
      this.setState({ nextButtonCount: 0 });
      dispatchToggleLoadingStimuli(true);
    } else {
      this.setState((prevState) => ({ nextButtonCount: prevState.nextButtonCount + 1 }));
    }
  }

  isShowNextButtonBeneath() {
    const { config, type } = this.props;
    const firstCondition = type === STIMULUS_TYPE.SENTENCES && config.division === STIMULUS_DIVISION_TYPE.UNDIVIDED;
    const secondCondition = type === STIMULUS_TYPE.PARAGRAPHS;
    return firstCondition || secondCondition;
  }

  renderStimuli = () => {
    const {
      type,
      currentExercise,
      productionCount,
      stimuli,
      isLoading,
    } = this.props;

    const { nextButtonCount } = this.state;
    if (type === STIMULUS_TYPE.CUSTOM && currentExercise) {
      const text = currentExercise.monitor?.stimulus?.config?.customText;
      return (
        <Syllable current>
          <Text textColor={TEXT_COLOR.HIGHLIGHTED}>
            <TextSizeWrapper>
              {text}
            </TextSizeWrapper>
          </Text>
        </Syllable>
      );
    }
    if (type === STIMULUS_TYPE.SPEECH_TOPICS) {
      const currentStimuli = stimuli?.[nextButtonCount];
      if (!currentStimuli || isLoading) {
        return (
          <LoaderWrapper type={STIMULUS_TYPE.SPEECH_TOPICS}>
            <Loader />
          </LoaderWrapper>
        );
      }
      return (
        <StimuliModuleWrapper>
          <SpeechTopicWrapper>
            <StimuliPratsWrapper>
              <Syllable>
                <SpeechTopicText>
                  {currentStimuli.text}
                </SpeechTopicText>
              </Syllable>
            </StimuliPratsWrapper>
            <StimuliSpeechTopicButton
              onClick={() => this.nextButtonHandle()}
            >
              {this.props.t('Actions.Navigation.next')}
            </StimuliSpeechTopicButton>
          </SpeechTopicWrapper>
        </StimuliModuleWrapper>
      );
    }
    if (type === STIMULUS_TYPE.PICTURES) {
      return (
        <StimuliModuleWrapper>
          <div>
            <ProgressiveImage
              src={stimuli?.[nextButtonCount]?.media}
              delay={IMAGE_LOADER_DELAY}
            >
              {(src, loading) => (loading || this.props.isLoading
                ? (
                  <LoaderWrapper type={STIMULUS_TYPE.PICTURES}>
                    <Loader />
                  </LoaderWrapper>
                )
                : (
                  <div>
                    <PictureStimuli
                      text={stimuli[nextButtonCount]?.text}
                      src={src}
                      handleGetStimuli={() => this.nextButtonHandle()}
                      message={this.props.t('Actions.Navigation.next')}
                    />
                  </div>
                ))}
            </ProgressiveImage>
          </div>
        </StimuliModuleWrapper>
      );
    }
    const index = this.isShowNextButtonBeneath() ? nextButtonCount : productionCount;
    if (!stimuli?.[index] || !stimuli?.[index].length) {
      return isLoading
        ? (
          <LoaderWrapper type={type}>
            <Loader />
          </LoaderWrapper>
        ) : <></>;
    }
    return (
      <StimuliModuleWrapper>
        <CommonStimuliWrapper type={type}>
          <StimuliPratsWrapper>
            <Syllable>
              {stimuli[index].map((stimuliItem) => {
                const text = stimuliItem?.text.replace(/(\r\n|\n|\r)/gm, ' ');
                return (
                  <StimuliText isCurrent={stimuliItem.current} key={stimuliItem.id}>
                    {text}
                  </StimuliText>
                );
              })}

            </Syllable>
          </StimuliPratsWrapper>
        </CommonStimuliWrapper>
      </StimuliModuleWrapper>
    );
  };

  render() {
    const { type, stimuli, isLoading } = this.props;
    if ((!stimuli || !stimuli.length) && !type === STIMULUS_TYPE.CUSTOM) {
      return <div />;
    }
    return (
      <>
        {isLoading ? (
          <LoaderWrapper type={type}>
            <Loader />
          </LoaderWrapper>
        )
          : <Wrapper>{this.renderStimuli()}</Wrapper>}
        {
          !isLoading && this.isShowNextButtonBeneath() && (
          <StimuliButton
            onClick={() => this.nextButtonHandle()}
          >
            {this.props.t('Actions.Navigation.next')}
          </StimuliButton>
          )
      }
      </>
    );
  }
}

StimuliModule.propTypes = {
  type: PropTypes.string,
  config: PropTypes.object,
  dispatchGetStimuli: PropTypes.func,
  stimuli: PropTypes.array,
  progress: PropTypes.object,
  currentSequence: PropTypes.number,
  currentSyllableIndex: PropTypes.number,
  currentExercise: PropTypes.object,
};

export const mapStateToProps = (state) => {
  const stimuliModule = state.getIn(['stimuliModule'])
    ? state.getIn(['stimuliModule']).toJS()
    : {};
  const { stimuli, textStimuli, isLoading } = stimuliModule;
  return { stimuli, textStimuli, isLoading };
};

export function mapDispatchToProps(dispatch) {
  return {
    dispatchGetStimuli: (parameters) => dispatch(getStimuli(parameters)),
    dispatchResetStimuli: () => dispatch(resetStimuli()),
    dispatchToggleLoadingStimuli: (toggle) => dispatch(toggleLoadingStimuli(toggle)),
  };
}

const withConnect = connect(mapStateToProps, mapDispatchToProps);
const withReducer = injectReducer({ key: 'stimuliModule', reducer });
const withSaga = injectSaga({ key: 'stimuliModule', saga });

export default compose(withConnect, withReducer, withSaga, withTranslation())(StimuliModule);
