
import React from 'react';
import PropTypes from 'prop-types';
import ResourcesList from '@shared/Resources/shared/components/ResourcesList';
import iconTypes from '@services/icons/generalIconTypes';
import { getIconsByType, getIconByResourceType } from '@services/icons';
import { PreviewIconWrapper, TypeIconWrapper } from './styles';
import { getMediaDate } from '@utils/timeHelpers';


const MediaList = ({ onPreview, ...rest }) => {
  const PreviewIcon = getIconsByType(iconTypes.PREVIEW);
  const tableConf = [
    { getDisplay: (item) => {
        const TypeIcon = getIconByResourceType(item.type);
        return <TypeIconWrapper><TypeIcon /></TypeIconWrapper>;
      },
      name: '',
      width: 35,
    },
    { getDisplay: (item) => item.title,
      name: 'NAME',
      width: 205,
      textOptions: {
        withEllipsis: true,
    }},
    { getDisplay: (item) => item.description,
      name: 'DESCRIPTION',
      textOptions: {
        withEllipsis: true,
      }},
    { getDisplay: (item) => getMediaDate(item.entityCreated.at),
      name: 'UPLOADED',
      width: 120 },
    { getDisplay: (item) => (
      <PreviewIconWrapper>
        <PreviewIcon
          onClick={(e) => {
            e.stopPropagation();
            onPreview(item);
          }}
        />
      </PreviewIconWrapper>),
    name: '',
    width: 35 },
  ];
  return <ResourcesList {...rest} tableConf={tableConf} />;
};

MediaList.defaultProps = {
  onPreview: () => {},
};

MediaList.propTypes = {
  onPreview: PropTypes.func,
};

export default MediaList;
