import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'redux';
import injectReducer from '@utils/injectReducer';
import Text, { TEXT_COLOR, TEXT_SIZE } from '@components/Text';
import SectionLoading from '@components/SectionLoading';
import _ from 'lodash';
import injectSaga from '@utils/injectSaga';
import PollClient from '@shared/Resources/Poll/Client';
import { savePoll } from '@shared/Resources/Poll/Client/actions';
import { setPollSubject } from '@containers/User/containers/CourseRouter/containers/Poll/actions';
import { setActivityContext } from '@containers/User/containers/CourseRouter/actions';
import { getChatSummaryPoll } from './actions';
import reducer from '../../../../../User/containers/CourseRouter/containers/Poll/reducer';
import saga from './saga';
import {
  Wrapper,
  Header,
  ButtonContent,
  Slider,
} from './styles';
import CTAButton from '@components/CTAButton';
import { MobileStepper } from '@mui/material';
import differenceInSeconds from 'date-fns/difference_in_seconds';
import { openPopUp as openPopUpAction } from '@containers/App/containers/PopUp/actions';
import POPUP_TYPES from '@containers/App/containers/PopUp/types';
import {withTranslation} from "react-i18next";

export class VideoChatSummary extends Component {
  constructor(props) {
    super(props);

    this.state = {
      usersToRate: [],
      totalNumberOfUsers: 0,
      activeStep: 0,
      answers: [],
      isDisabled: true,
    };
  }

  componentDidMount() {
    const {
      dispatchGetChatSummaryPoll,
      sessionInfo,
      dispatchSetActivityContext,
      chatId,
      activityContext,
        i18n
    } = this.props;
    const hebrewMock = i18n.language === 'he';

    this.parseChatUsers();
    dispatchGetChatSummaryPoll(hebrewMock);

    if (!_.isEmpty(sessionInfo) && activityContext) {
      const context = _.clone(activityContext);

      context.ACTIVITY_CHAT = chatId;
      dispatchSetActivityContext(context);
    }
  }

  parseChatUsers() {
    const { users } = this.props.sessionInfo;
    const usersToRate = users.filter((user) => !user.initiator);
    if (usersToRate.length > 0) {
      this.initiateNextPoll(usersToRate, {
        totalNumberOfUsers: usersToRate.length,
      });
    }
  }

  initiateNextPoll = (usersToRate, additionalState = {}) => {
    const startTime = new Date();
    this.setState({
      isResetting: false,
      startTime,
    });
    this.setState({
      usersToRate,
      ...additionalState,
    });
  };

  saveSummaryPoll = () => {
    const { answers, startTime, context } = this.state;
    const { dispatchSavePoll, pollSubject, openPopUp, t } = this.props;

    const timeSpent = differenceInSeconds(new Date(), startTime);
    const subject = pollSubject.id && pollSubject.type ? pollSubject : null;
    const listAnswersData = answers.map((answer) => {
      const listIDs = Object.keys(answer.answers);
      const listAnswers = listIDs.map((id) => {
        return {
          id,
          payload: answer.answers[id].answer
        }})
      return {
        ...answer,
        answers: listAnswers,
      }
    })

    listAnswersData.forEach((answer) => {
      dispatchSavePoll({
        context: {
          ...context,
          USER: answer.userID,
          ACTIVITY_CHAT:this.props?.sessionInfo?.sessionId
        },
        answers: answer.answers,
        subject,
        timeSpent,
      });
    });

    openPopUp({
      titleText: t('Informative.Notifying.feedbackSent'),
      buttonText: t('Actions.Navigation.goBack'),
      hideCloseIcon: true,
      disableBackdrop: true,
    });
  };

  handleBack = () => this.setState({ activeStep: this.state.activeStep - 1 });

  handleNext = () => {
    const { activeStep } = this.state;
    this.setState({ isDisabled: true });

    this.setState({ activeStep: activeStep + 1 });
  };

  handleStepChange = (activeStep) => this.setState({ activeStep });

  getContext = (context) => this.setState({ context });

  getAnswers = ({ questions }, userID) => {
    const answersArray = [...this.state.answers];
    const currentAnswer = answersArray.find((answer) => answer.userID === userID);
    let isNext = false;

    if (currentAnswer) {
      currentAnswer.answers = questions;
      Object.keys(currentAnswer.answers).forEach((answerID) => {
        if (!currentAnswer.answers[answerID].answer) {
          isNext = true;
        }
      })
    } else {
      isNext = true;
      answersArray.push({ answers: questions, userID })
    }

    this.setState({
      answers: answersArray,
      isDisabled: isNext
    });
  }

  render() {
    const { usersToRate, activeStep, isDisabled } = this.state;
    const { chatId, loading, t } = this.props;
    const isLastQuestion = activeStep === usersToRate.length - 1;

    if (loading) {
      return (
        <Wrapper>
          <SectionLoading />
        </Wrapper>
      );
    }

    return (
      <Wrapper>
        <Header>
          <Text size={TEXT_SIZE.T1} textColor={TEXT_COLOR.DARK_BLUE}>
            {t('Video.videoChatSummary')}
          </Text>
        </Header>
        <Slider
          enableMouseEvents
          index={activeStep}
          onChangeIndex={this.handleStepChange}
        >
          {usersToRate.map((user) => (
            <PollClient
              hideTitle
              isSummaryPoll
              user={user}
              chatId={chatId}
              activeStep={activeStep}
              getContext={this.getContext}
              getAnswers={this.getAnswers}
            />
          ))}
        </Slider>
        <ButtonContent>
          <MobileStepper
            variant="dots"
            steps={usersToRate.length}
            position="static"
            activeStep={activeStep}
            backButton={
              <CTAButton
                onClick={this.handleBack}
                disabled={activeStep === 0}
              >
                <Text size='T2' textColor={TEXT_COLOR.REVERSED} weight='900'>
                  {t('Actions.Navigation.previous')}
                </Text>
              </CTAButton>
            }
            nextButton={
              <CTAButton
                onClick={isLastQuestion ? this.saveSummaryPoll : this.handleNext}
                disabled={isDisabled}
              >
                <Text size='T2' textColor={TEXT_COLOR.REVERSED} weight='900'>
                  {t(isLastQuestion ? 'Actions.Instance.save' : 'Actions.Navigation.next')}
                </Text>
              </CTAButton>
            }
          />
        </ButtonContent>
      </Wrapper>
    );
  }
}

VideoChatSummary.propTypes = {
  sessionInfo: PropTypes.object,
  loading: PropTypes.bool,
  dispatchSetActivityContext: PropTypes.func,
  dispatchSetPollSubject: PropTypes.func,
  dispatchGetChatSummaryPoll: PropTypes.func,
  dispatchSavePoll: PropTypes.func,
  onClose: PropTypes.func,
  chatId: PropTypes.string,
  activityContext: PropTypes.object,
};

export const mapStateToProps = (state) => {
  const res = {};
  if (state.getIn(['currentPoll'])) {
    res['loading'] = state.getIn(['currentPoll']).toJS().loading;
    res['poll'] = state.getIn(['currentPoll']).toJS().poll;
    res['pollSubject'] = state.getIn(['currentPoll']).toJS().pollSubject;
  }
  if (state.getIn(['videoChat'])) {
    res['chatId'] = state.getIn(['videoChat']).toJS().chatId;
  }
  if (state.getIn(['course'])) {
    res['activityContext'] = state.getIn(['course']).toJS().activityContext;
  }

  return { ...res };
};

export function mapDispatchToProps(dispatch) {
  return {
    dispatchSetPollSubject: (id) =>
      dispatch(setPollSubject(id, 'ACTIVITY_CHAT_PARTICIPANT')),
    dispatchSetActivityContext: (activityContext) =>
      dispatch(setActivityContext(activityContext)),
    dispatchGetChatSummaryPoll: (hebrewMock) =>
      dispatch(getChatSummaryPoll(hebrewMock)),
    dispatchSavePoll: (poll) => dispatch(savePoll(poll)),
    openPopUp: (props) => dispatch(openPopUpAction(POPUP_TYPES.ALERT, props)),
  };
}

const withConnect = connect(mapStateToProps, mapDispatchToProps);
const withSaga = injectSaga({ key: 'videoChatSummary', saga });
const withReducer = injectReducer({ key: 'currentPoll', reducer });

export default compose(
 withTranslation(),
  withReducer,
  withSaga,
  withConnect
)(VideoChatSummary);
