import { RESOURCES } from '../../types';

export const overview = {
  id: '1',
  name: 'Intro to 2 Second Pattern',
  type: RESOURCES.PRACTICE,
  children: [
    {
      id: 'e1',
      payload:{name: 'Vowel production in 2 sec pattern'},
      type: RESOURCES.EXERCISE,
    },
    {
      id: 'e2',
      payload:{name: '2 Second Pattern'},
      type: RESOURCES.EXERCISE,
    },
    {
      id: 'e3',
      payload:{name: 'One syllable words in 2 sec pattern'},
      type: RESOURCES.EXERCISE,
    },
  ],
  entityCreated: {
    at: 'Thu Aug 02 2018 18:05:31 GMT+0000 (UTC)',
    by: 'User McUser',
  },
  entityUpdated: {
    at: 'Thu Aug 09 2018 08:46:00 GMT+0000 (UTC)',
    by: 'Useriko McUserico',
  },
  usedIn: [
    { id: 1, type: RESOURCES.COURSE, name: 'Some course 1' },
    { id: 2, type: RESOURCES.POLL, name: 'Some poll 2' },
  ]
};