import { useMemo } from 'react';
import { calculateOtherErrorsPercent } from '../../../../helpers';
import { Tooltip } from '@components/Tooltip';
import {
  PracticeErrorWrapper,
  IconError,
  IconWithText,
  ErrorLabel,
  ErrorPercent,
} from '../../styles';
import Text, { TEXT_SIZE } from "@components/Text";
import { OtherErrorsIcon } from './styles';
import { isDesktop } from '@shared/constants/media-queries';
import {useTranslation} from "react-i18next";

const showOtherErrors = (errors) => {
  const isDesktopSize = isDesktop();
  return errors.map(({ label, value }) => (
    <PracticeErrorWrapper key={label}>
      <IconWithText>
        <Text size={isDesktopSize ? TEXT_SIZE.H4 : TEXT_SIZE.H5}>{label}</Text>
      </IconWithText>
      <Text size={isDesktopSize ? TEXT_SIZE.H4 : TEXT_SIZE.H5}>{`${value}%`}</Text>
    </PracticeErrorWrapper>
  ))
}

/**
 * Other errors with tooltip
 */
export const OtherErrorsWithTooltip = ({ formattedErrors }) => {
  const {t} = useTranslation()
  const otherErrorsPercent = useMemo(() => calculateOtherErrorsPercent(formattedErrors), [formattedErrors]);
  const otherErrors = formattedErrors.slice(3);
  return (
    <PracticeErrorWrapper>
      <IconWithText>
        <IconError order={3} />
        <ErrorLabel size={TEXT_SIZE.H4}>
          {t('Common.UI.other')}
        </ErrorLabel>
        <Tooltip
          placement="top"
          padding={17}
          content={showOtherErrors(otherErrors)}
        >
          <div>
            <OtherErrorsIcon />
          </div>
        </Tooltip>
      </IconWithText>
      <ErrorPercent>{`${otherErrorsPercent}%`}</ErrorPercent>
    </PracticeErrorWrapper>
  );
};
