import { Component } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { withTranslation } from 'react-i18next';
import { compose } from 'redux';
import { connect } from 'react-redux';
import SectionLoading from '@components/SectionLoading';
import { ORDERS } from '@shared/constants';
import { fetchUsers, putOrder, putSortBy } from '@containers/Admin/containers/UsersList/actions';
import SortOrder from './components/SortOrder';
import {
  TableRow,
  TableHeader,
  Cell,
  HeaderColumn,
  NoItems,
  TableWrapper,
  TableList,
  LoadingOverlay,
  StyledArrowRightIcon,
  VirtualizedLogList,
} from './styles';

class SortableTable extends Component {
  constructor(props) {
    super(props);
    this.state = { sort: null };
  }

  componentDidMount() {
    window.addEventListener('error', this.errorIgnoreInVirtualizedList);
    const { sortDefaultByField } = this.props;
    window.addEventListener('error', this.errorIgnoreInVirtualizedList);
    if (sortDefaultByField) {
      this.setState({
        sort: {
          field: sortDefaultByField.FIELD,
          order: sortDefaultByField.ORDER ?? ORDERS.ASC,
          column: sortDefaultByField.COLUMN,
        },
      });
    }
  }

  componentWillUnmount() {
    window.removeEventListener('error', this.errorIgnoreInVirtualizedList);
  }

  errorIgnoreInVirtualizedList = (e) => {
    if (e.message.includes('ResizeObserver')) {
      const resizeObserverErrDiv = document.getElementById(
        'webpack-dev-server-client-overlay-div',
      );
      const resizeObserverErr = document.getElementById(
        'webpack-dev-server-client-overlay',
      );
      if (resizeObserverErr) {
        resizeObserverErr.setAttribute('style', 'display: none');
      }
      if (resizeObserverErrDiv) {
        resizeObserverErrDiv.setAttribute('style', 'display: none');
      }
    }
  };

  getSortedItems = () => {
    const { items, config, sortDefaultByField } = this.props;
    const { sort } = this.state;

    if (items && items.length === 0) return [];
    if (!sort) {
      if (sortDefaultByField) {
        return _.sortBy(items, sortDefaultByField.FIELD);
      }

      return items;
    }

    const { field, order } = sort;
    const columnToSortBy = config.find(({ sortField }) => sortField === field);
    const sortedList = _.sortBy(items, [columnToSortBy.sortField]);
    if (order === ORDERS.DESC) {
      return sortedList.reverse();
    }

    return sortedList;
  };

  getMinMax = (min, max) => `minmax(${min ? `${min}px` : 'auto'},${max ? `${max}px` : 'auto'})`;

  toggleSort = (column, field) => () => {
    const { sort } = this.state;
    const { dispatchPutOrder } = this.props;
    let order = ORDERS.ASC;
    if (sort && sort.column === column) {
      order = sort.order === ORDERS.ASC ? ORDERS.DESC : ORDERS.ASC;
    }
    dispatchPutOrder(order);
    this.setState({ sort: { field, order, column } });
  };

  calculateSpread = () => {
    const { config } = this.props;
    return config.map(({ minWidth, maxWidth, width }) => (minWidth || maxWidth
      ? this.getMinMax(minWidth, maxWidth)
      : (width || 'auto')))
      .join(' ');
  };

  renderRow = (spread) => (item) => {
    const { config, onRowSelect, selectedItemId } = this.props;

    const isSelected = selectedItemId && selectedItemId === item.id;

    const rowProps = {
      spread,
      onClick: () => onRowSelect(item),
      key: item.id,
      isHighlighted: item.visits?.length === 0,
      isSelected,
    };
    return (
      <TableRow className="row" {...rowProps}>
        {config.map(({ getDisplay }, colIndex) => (
          <Cell
            className="cell"
            key={`Row${item.id}Column${colIndex}`}
            isLast={colIndex === config.length - 1}
          >

            {getDisplay && getDisplay(item)}
          </Cell>
        ))}
        {isSelected && <StyledArrowRightIcon />}
      </TableRow>
    );
  };

  renderHeader = (spread) => {
    const { config, titlesUppercase, order } = this.props;
    return (
      <TableHeader spread={spread} className="tableHeader">
        {config.map(({ title, sortable, sortField }, index) => (
          <HeaderColumn
            key={`${title}-${index}`}
            onClick={() => {
              this.toggleSort(title, sortField)();
            }}
            className="headerCell"
            isLast={index === config.length - 1}
          >
            <Cell noPadding titlesUppercase={titlesUppercase}>
              {title}
            </Cell>
            {sortable && (
              <SortOrder
                className="orderIndication"
                order={order}
              />
            )}
          </HeaderColumn>
        ))}
      </TableHeader>
    );
  };

  render() {
    const {
      loading, noItemsMessage, t,
    } = this.props;
    const items = this.getSortedItems();
    const spread = this.calculateSpread();

    const row = (index) => this.renderRow(spread)(items[index]);
    return (
      <TableWrapper>
        {loading && <LoadingOverlay><SectionLoading /></LoadingOverlay>}
        {this.renderHeader(spread)}
        <TableList>
          {items.length === 0
            ? (
              <NoItems>
                {noItemsMessage || t('Informative.Negative.noItems')}
              </NoItems>
            )
            : (
              <VirtualizedLogList
                totalCount={items.length}
                itemContent={(index) => row(index)}
              />
            )}
        </TableList>
      </TableWrapper>
    );
  }
}

SortableTable.propTypes = {
  items: PropTypes.array,
  config: PropTypes.array,
  onRowSelect: PropTypes.func,
  noItemsMessage: PropTypes.node,
  showShortTable: PropTypes.bool,
  titlesUppercase: PropTypes.bool,
  loading: PropTypes.bool,
  order: PropTypes.string,
  sortBy: PropTypes.string,
  dispatchPutOrder: PropTypes.func,
  dispatchPutSortBy: PropTypes.func,
  dispatchGetUsers: PropTypes.func,

};

export function mapDispatchToProps(dispatch) {
  return {
    dispatchPutOrder: (order) => dispatch(putOrder(order)),
    dispatchPutSortBy: (sortBy) => dispatch(putSortBy(sortBy)),
    dispatchFetchUsers: (roles, isUserPage) => dispatch(fetchUsers(roles, isUserPage)),
  };
}

const mapStateToProps = (state) => {
  const order = state.get('usersList').get('order');
  const sortBy = state.get('usersList').get('sortBy');

  return {
    order,
    sortBy,
  };
};

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default compose(
  withConnect,
  withTranslation(),
)(SortableTable);
