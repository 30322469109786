// Extract info object from mongo data
import { BUILDER_TYPES } from '../editorObjects/editorObjectTypes';

export const extractInfo = (type, data) => {
  switch (type) {
    case BUILDER_TYPES.POLL: return ({
      id: data.id,
      description: data.description,
      name: data.name,
    });
    case BUILDER_TYPES.EXERCISE: return ({
      ...data,
      media: {
        demo: data.media.demoId,
      },
      additionalData: {
        media: {
          demo: data.media.demo,
        },
      },
    });
    case BUILDER_TYPES.LESSON: return ({
      ...data,
      media: {
        demo: data.media.demoId,
        lesson: data.media.lessonId,
      },
      additionalData: {
        media: {
          demo: data.media.demo,
          lesson: data.media.lesson,
        },
      },
    });
    default: return data;
  }
};
