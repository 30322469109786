import { fromJS } from 'immutable';
import { ROUTES } from '@shared/constants';
import { Types } from './actions';

export const initialState = fromJS({
  errors: {
    serverError: null,
  },
  sending: false,
  redirectToPage: null,
  loading: false,
});

const login = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case Types.SIGN_UP_FAIL: {
      return state.setIn(['errors', 'serverError'], fromJS(payload.error));
    }

    case Types.SIGN_UP_SUCCESS: {
      if (action.available) {
        return state.setIn(
          ['redirectToPage'],
          fromJS(ROUTES.VERIFY_EMAIL_SENT)
        );
      }
      return state.setIn(['redirectToPage'], fromJS(ROUTES.SIGN_IN));
    }
    case Types.LOADING_SWITCH: {
      return state.setIn(['loading'], fromJS(payload));
    }
    default: {
      return state;
    }
  }
};

export default login;
