import request from 'superagent';
import gql from 'graphql-tag';
import { ApolloClient, InMemoryCache, createHttpLink } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import fetch from 'unfetch';
import { GRAPHQL_SERVER } from '../servers';
import { handleRequestErrors } from '../errorHandlingService';
import packageJson from '../../../package.json';
import {
  getFromLocalStorage,
  LOCAL_STORAGE_ITEM,
} from '@utils/localStorageHelper';

const appVersion = packageJson.version;

const httpLink = createHttpLink({
  uri: GRAPHQL_SERVER,
});
const authLink = setContext((request) => {
  const storedUserString = getFromLocalStorage(LOCAL_STORAGE_ITEM.USER);
  const token = storedUserString ? storedUserString.token : '';

  return {
    headers: {
      token,
      'X-Novotalk-Source': 'novotalk_web_and_desktop',
      'X-Novotalk-Version': appVersion,
    },
  };
});

export const graphClient = new ApolloClient({
  link: authLink.concat(httpLink),
  fetchOptions: {
    fetch,
    mode: 'no-cors'
  },
  cache: new InMemoryCache(),
});

export const graphQuery = (query, wrapWithGQL = false, variables = {}) => {
  return graphClient
    .query({
      query: wrapWithGQL ? gql(query) : query,
      fetchPolicy: 'no-cache',
      variables,
    })
    .then((result) => {
      if (result.data.error) {
        throw new Error(result.data.error);
      }
      return result;
    })
    .catch((e) => {
      console.log(e);
      handleRequestErrors(e);
    });
};
export const graphMutation = (mutation, variables = {}, wrapWithGQL = false) =>
  graphClient
    .mutate({ mutation: wrapWithGQL ? gql(mutation) : mutation, variables })
    .then((result) => result)
    .catch(handleRequestErrors);

export const getRequest = (server, endpoint) =>
  request
    .get(`${server}${endpoint}`)
    .then((res) => res.body, handleRequestErrors);

export const postFormRequest = (
  server,
  endpoint,
  params = null,
  file = null
) => {
  const storedUserString = getFromLocalStorage(LOCAL_STORAGE_ITEM.USER);
  const token = storedUserString ? storedUserString.token : '';
  const req = request.post(`${server}${endpoint}`);
  if (params) {
    req.send({ ...params });
  }
  req.set('X-Novotalk-Version', appVersion);
  req.set('X-Novotalk-Source', 'novotalk_web_and_desktop');
  req.set('token', token);
  if (file) {
    const formData = new FormData();
    formData.append('file', file);
    req.send(formData);
  }
  req.then((res) => res.body, handleRequestErrors);
  return req;
};

export const postRequest = (server, endpoint, params = null, type = 'form') => {
  const storedUserString = getFromLocalStorage(LOCAL_STORAGE_ITEM.USER);
  const token = storedUserString ? storedUserString.refreshToken : '';
  const req = request.post(`${server}${endpoint}`);
  req.set('X-Novotalk-Version', appVersion);
  req.set('X-Novotalk-Source', 'novotalk_web_and_desktop');
  req.set('token', token);
  req.type(type);
  if (params) {
    req.send(params);
  }
  req.then((res) => {
    return res.body;
  }, handleRequestErrors);
  return req;
};
