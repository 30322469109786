import {styled} from "@mui/material";
import { isDesktop } from '@shared/constants/media-queries';

export const CanvasContainer = styled('div')(({ width, background }) => ({
  width,
  background,
  display: 'flex',
  margin: 'auto',
  position: 'relative',
}));

export const CanvasElement = styled('canvas')(({ width, height }) => ({
  width,
  height,
  zIndex: 2,
  border: '2px solid #7B7C80'
}));

export const PatternImage = styled('img')({
  display: 'none',
});

export const WavesSvg = styled('div')({
  position: 'absolute',
  bottom: !isDesktop() ? '-15px' : 0,
  '& svg': {
    maxWidth: '100%',
  }
});
