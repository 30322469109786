import React, { useState, useEffect, useRef } from 'react';
import {
  SubscribersWrapper,
  PERCENT_OF_WRAPPER_WIDTH,
  PERCENT_OF_WRAPPER_HEIGHT,
} from '../../styles';
import {
  VIDEO_CALL_SUB_WRAPPER_DIMENSIONS,
  VIDEO_CALL_NUM_OF_PARTICIPANTS,
} from '../VideoCall/reducer';
import { placeholdersHandler } from './helpers';
import { useSelector } from 'react-redux';
import Text, { TEXT_SIZE, TEXT_COLOR } from '@components/Text';
import VideoCall from '../VideoCall';
import { useVideoCallContext } from '@shared/hooks/useVideoCallContext';
import {useTranslation} from "react-i18next";

const SHOW_MONITOR_PADDING = 292;

export const SubAndSharingContainer = () => {
  const {t} = useTranslation()
  const [disconnectedUsers, setDisconnectedUsers] = useState(0);
  const { stateContext, dispatchContext } = useVideoCallContext();
  const { sessionInfo, showMonitor } = useSelector((state) =>
    state.getIn(['videoChat']).toJS()
  );
  const wrapperRef = useRef(null);
  useEffect(() => {
    const disUsers = sessionInfo.users.filter((user) => user.leftSession)
      .length;
    if (disUsers !== disconnectedUsers) {
      setDisconnectedUsers(disUsers);
    }
    const subscribers = sessionInfo.users.filter((user) => !user.self);
    const numOfParticipants = subscribers.length - disconnectedUsers;
    if (numOfParticipants !== stateContext.numOfParticipants) {
      dispatchContext({
        type: VIDEO_CALL_NUM_OF_PARTICIPANTS,
        payload: numOfParticipants,
      });
    }
  }, [sessionInfo.users.length]);
  useEffect(() => {
    if (wrapperRef.current && !stateContext.subWrapperDimensions) {
      dispatchContext({
        type: VIDEO_CALL_SUB_WRAPPER_DIMENSIONS,
        payload: {
          width: wrapperRef.current.offsetWidth,
          height: wrapperRef.current.offsetHeight,
        },
      });
    }
  }, [wrapperRef]);
  return (
    <SubscribersWrapper
      ref={wrapperRef}
      numOfParticipants={stateContext.numOfParticipants}
      showMonitor={showMonitor}
    >
      {stateContext.subWrapperDimensions && (
        <>
          <VideoCall.ScreenSharing />

          <VideoCall.SubscribersSubContainer>
            {placeholdersHandler(
              Math.min(stateContext.subWrapperDimensions.width, stateContext.subWrapperDimensions.height) * PERCENT_OF_WRAPPER_WIDTH,
              stateContext.subWrapperDimensions.height *
              PERCENT_OF_WRAPPER_HEIGHT,
              sessionInfo.users,
              stateContext.numOfParticipants,
              showMonitor ? SHOW_MONITOR_PADDING : 0
            )}
          </VideoCall.SubscribersSubContainer>
        </>
      )}
      {stateContext.numOfParticipants === 0 && (
        <Text size={TEXT_SIZE.H1} textColor={TEXT_COLOR.REVERSED}>
          {t("Informative.Notifying.emptyVideoCall")}
        </Text>
      )}
    </SubscribersWrapper>
  );
};
