import gql from 'graphql-tag';
import { graphQuery, graphMutation, postFormRequest } from '@services/commService';
import { CLOUD_SERVER, CLOUD_ENDPOINTS } from '@services/servers';

export const getAllMediaItems = async () => {
  const query = gql`
    {
      media {
        id
        type
        title
        tags
      }
    }
  `;
  // until we have design from product - we filter here. Later we'll need to redesign the posts schema.
  const res = await graphQuery(query);
  return res.data.media;
};

export const createMediaItemNode = async (
  type,
  title,
  description,
  tags = {},
  uploadedFileURL = ''
) => {
  const formatTags = tags.map((tag) => `"${tag}"`);
  const mutation = gql`
  mutation{
    createMedia(type:"${type}",title:"${title}",description:"${description}",value:"${uploadedFileURL}",tags:[${formatTags}]) {
      id
      title
      description
      tags
      type
      value
    }
  }
  `;
  const res = await graphMutation(mutation);
  return res.data.createMedia;
};

export const createMediaItem = async ({
  title,
  description,
  tags,
  uploadedFiles,
}) => {
  try {
    const file = uploadedFiles[0];

    const type = file.type.split('/')[0];

    const response = await postFormRequest(
      CLOUD_SERVER,
      CLOUD_ENDPOINTS.UPLOAD_MEDIA,
      null,
      file
    );

    if (response) {
      const uploadedFileURL = response.body.uploadedFile;

      return await createMediaItemNode(
        type,
        title,
        description,
        tags,
        uploadedFileURL
      );
    }
  } catch (e) {
    throw e;
  }
};

export const editMedia = async ({
  title,
  description,
  tags,
  type,
  id,
}) => {
  const formatTags = tags.map((tag) => `"${tag}"`);
  const mutation = gql`
    mutation{
      editMedia(id:"${id}",type:"${type}",title:"${title}",description:"${description}",tags:[${formatTags}]) {
        id
        title
        description
        tags
        type
        value
      }
    }
    `;
  return await graphMutation(mutation);
};
