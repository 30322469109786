import { RESOURCES, PRACTICE } from '../../types';

export const overview = {
  type: RESOURCES.COURSE,
  name: 'my generic course',
  description: 'description of my generic course',
  id: 'courseID1',
  entityCreated: {
    at: 'Thu Aug 02 2018 18:05:31 GMT+0000 (UTC)',
    by: 'User McUser',
  },
  entityUpdated: {
    at: 'Thu Aug 09 2018 08:46:00 GMT+0000 (UTC)',
    by: 'Useriko McUserico',
  },
  usedIn: [
    { id: '1', type: RESOURCES.COURSE, name: 'Some course 1' },
    { id: '2', type: RESOURCES.POLL, name: 'Some poll 2' },
  ],
  children: [
    {
      payload:{
        id: '1',
        name:'Breathing',
        contains:{
          LESSON:1,
          POLL:2,
          PRACTICE:4
        },
        type: RESOURCES.SKILL,
      },
      id: '1',
      type: RESOURCES.SKILL,
    },
    {
      id: '2',
      type: RESOURCES.SKILL,
      payload: {
        id: '2',
        name:'Relaxation',
        contains:{
          
        },
        type: RESOURCES.SKILL,
      },
    },
    {
      id: '3',
      type: RESOURCES.SKILL,
      payload: {
        id: '3',
        name:'Voice Production',
        contains:{
          
        },
        type: RESOURCES.SKILL,
      },
    },
  ],
};

const children = 
  [
    {
      id: 'l1',
      name: 'Vowel production in 2 sec pattern',
      type: RESOURCES.LESSON,
    },
    {
      id: 'l2',
      name: 'Consonants production in 2 sec pattern',
      type: RESOURCES.LESSON,
    },
    {
      id: 'l3',
      name: 'One syllable words in 2 sec pattern',
      type: RESOURCES.LESSON,
    },
    {
      id: 'l4',
      name: 'Multi syllable words in 2 sec pattern',
      type: RESOURCES.LESSON,
    },
    {
      id: 'l5',
      name: 'Sentences in 2 sec pattern',
      type: RESOURCES.LESSON,
    },
    {
      id: 'p1',
      duration: 10,
      name: 'breathing beginner',
      type: RESOURCES.PRACTICE,
    },
    {
      id: 'p2',
      duration: 15,
      name: 'breathing advanced',
      type: RESOURCES.PRACTICE,
    },
    {
      id: 'p3',
      duration: 20,
      name: 'breathing expert',
      type: RESOURCES.PRACTICE,
    },
    {
      id: 'pl1',
      name: 'My poll 1',
      type: RESOURCES.POLL,
    },
    {
      id: 'pl2',
      name: 'My poll 2',
      type: RESOURCES.POLL,
    },
  ]; 


export const expandedOverview  = {
  type: RESOURCES.COURSE,
  name: 'my generic course',
  description: 'description of my generic course',
  id: '1',
  entityCreated: {
    at: 'Thu Aug 02 2018 18:05:31 GMT+0000 (UTC)',
    by: 'User McUser',
  },
  entityUpdated: {
    at: 'Thu Aug 09 2018 08:46:00 GMT+0000 (UTC)',
    by: 'Useriko McUserico',
  },
  usedIn: [
    { id: 1, type: RESOURCES.COURSE, name: 'Some course 1' },
    { id: 2, type: RESOURCES.POLL, name: 'Some poll 2' },
  ],
  children: [
    {
      id: '1',
      name:'Breathing',
      contains:{
        LESSON:1,
        POLL:2,
        PRACTICE:4
      },
      type: RESOURCES.SKILL,
      children:children,
    },
    {
      id: '2',
      name:'Relaxation',
      contains:{
        
      },
      type: RESOURCES.SKILL,
      children:children,
    },
    {
      id: '3',
      name:'Voice Production',
      contains:{
        
      },
      type: RESOURCES.SKILL,
      children:children,
    },
  ],
};