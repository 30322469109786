import React from 'react';
import PropTypes from 'prop-types';
import EntitySelectionOptions from '@shared/Resources/shared/components/EntitySelectionOptions';
import { COURSE } from '../../types';
import { connect } from 'react-redux';
import { compose } from 'redux';
import withResources from '@containers/Admin/shared/ResourcesWrapper';

class EditorCourse extends React.Component {
  render() {
    const { submitPayload } = this.props;
    return (
      <EntitySelectionOptions
        entityType={COURSE}
        onItemSelect={submitPayload}
      />
    );
  }
}

EditorCourse.propTypes = {
  submitPayload: PropTypes.func,
};

const withConnect = connect(null, null);

export default compose(withConnect, withResources)(EditorCourse);
