import React from 'react';
import _ from 'lodash';
import { editStatus } from '@containers/App/containers/PopUp/popups/MediaEditor/actions';
import Text from '@components/Text';
import { ButtonContainer } from '@containers/App/containers/PopUp/popups/MediaEditor/components/loading-area/styles';
import CTAButton from '@components/CTAButton';
import { useSelector } from 'react-redux';
import {useTranslation} from "react-i18next";

export const Success = ({ onLoadingFinishedButtonClick, status }) => {
    const {t} = useTranslation()
  const { error } = useSelector((state) => state.getIn(['editMedia']).toJS());
  const errorMessage = _.get(error, 'response.body.message') || error;
  const message = status === editStatus.SENT
    ? t('Informative.Positive.loadedSuccessfully')
    : <div>
          {t('Errors.loadedWithError', {error: errorMessage})}
      </div>
  return (
    <span>
      <Text margin='20px 0 8px 0'>{message}</Text>
      <ButtonContainer>
        <CTAButton
          action
          data-cy='media_page_loading_progress_modal_ok_button'
          onClick={onLoadingFinishedButtonClick}
        >
            {t('Actions.Simple.ok').toUpperCase()}
        </CTAButton>
      </ButtonContainer>
    </span>
  );
};
