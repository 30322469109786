import React from 'react';
import PropTypes from 'prop-types';
import Container from '@components/Container';
import Text, { TEXT_COLOR } from '@components/Text';
import CTAButton from '@components/CTAButton';
import TextButton from '@components/TextButton';
import {
  Wrapper,
  ButtonWrapper,
  HeadingContainer,
  ExercisesListContainer,
  ExerciseContainer,
  BulletIcon,
  Clock,
  PreviousPracticeRedirectionWrapper,
} from './styles';
import {useTranslation} from "react-i18next";

const PracticeOverview = ({
  onClose,
  practice: { name, duration, exercises },
  redirectToPreviousPractice,
}) => {
  const {t} = useTranslation()
  const handlePreviousPracticeRedirection = () => {
    onClose();
    redirectToPreviousPractice();
  };

  return (
    <Wrapper>
      <HeadingContainer>
        <Text
          size='T4'
          textColor={TEXT_COLOR.DISABLED_LIGHT}
          textTransform='uppercase'
        >
          {t('Common.Greetings.greetings')}
        </Text>
        <Text
          size='T0'
          textColor={TEXT_COLOR.DARK_BLUE}
          textTransform='capitalize'
          weight='bold'
        >
          {name} {t('Common.UI.singlePractice')}
        </Text>
        {duration && (
          <Text
            size='H4'
            textColor={TEXT_COLOR.DISABLED_LIGHT}
            className='practice-duration'
          >
            <Clock /> ~ {duration}{' '}
            {t('Dates.mins')}
          </Text>
        )}
        <ButtonWrapper>
          <CTAButton onClick={onClose}>
            {t('Actions.General.startPractice')}
          </CTAButton>
        </ButtonWrapper>
      </HeadingContainer>
      <Container column>
        <ExercisesListContainer>
          {exercises.map(({ id, name }, index) => (
            <ExerciseContainer key={`${id}-${index}`}>
              <BulletIcon />
              <Text
                size='H4'
                textColor={TEXT_COLOR.DARK_BLUE}
                textTransform='capitalize'
              >
                {name}
              </Text>
            </ExerciseContainer>
          ))}
        </ExercisesListContainer>
        {redirectToPreviousPractice && (
          <PreviousPracticeRedirectionWrapper>
            <Text size='H4'>
              {t('Informative.Confirming.previousPractice')}
            </Text>
            <Container alignItems='baseline'>
              <Text size='H4'>
                {t('Informative.Notifying.refreshMemory')}
              </Text>
              <TextButton>
                <Text
                  size='H4'
                  margin='0 4px'
                  onClick={handlePreviousPracticeRedirection}
                >
                  {t('Common.UI.summary')}
                </Text>
              </TextButton>
            </Container>
          </PreviousPracticeRedirectionWrapper>
        )}
      </Container>
    </Wrapper>
  );
};

PracticeOverview.propTypes = {
  onClose: PropTypes.func.isRequired,
  practice: PropTypes.object,
  redirectToPreviousPractice: PropTypes.func,
};

export default PracticeOverview