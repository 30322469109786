import {styled} from "@mui/material";

export const Video = styled('video')({
  width: '100%',
  marginTop: 40,
});

export const Audio = styled('audio')({
  height: 60,
  marginTop: 40,
});

export const Image = styled('img')({
  display: 'block',
  margin: '40px auto 0',
  maxWidth: '350px',
});
