import { RESOURCES } from '@shared/Resources/types';

export const addNameToMedia = (resources, type) => {
  if (type === RESOURCES.MEDIA) {
    return resources.map((resource) => ({
      ...resource,
      name: resource.title,
    }));
  }
  return resources;
};
