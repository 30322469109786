import { styled } from '@mui/material';
import * as glamor from 'glamor';

const animationStyles = ({ duration, isRTL }) => {
  const grow = glamor.css.keyframes({
    '0%': {
      width: '0%',
      background:
        'linear-gradient(270deg, #E2E5EB 0px, #23B7E5 50px, #23B7E5 100%)',
    },
    '95%': {
      width: '95%',
      background:
        'linear-gradient(270deg, #E2E5EB 0px, #23B7E5 50px, #23B7E5 100%)',
    },
    '100%': {
      width: '100%',
      background:
        'linear-gradient(270deg, #E2E5EB 0px, #23B7E5 50px, #23B7E5 100%)',
    },
  });
  return {
    animation: `${grow} ${duration} linear`,
    width: '100%',
    background: '#23B7E5',
    transform: `rotate(${isRTL ? 180 : 0}deg)`,
  };
};

export const AnimatedBarContainer = styled('div')(animationStyles, () => ({
  height: '100%',
}));
