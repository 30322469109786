import {styled} from "@mui/material";

export const Wrapper = styled('div')(({ theme, miniOverview }) =>
  miniOverview
    ? {
        paddingBottom: 15,
        marginBottom: 15,
        lineHeight: '19px',
        borderBottom: `1px solid ${theme.palette.novotalk.$resourcesTableBorderColor}`,
      }
    : {
        minWidth: '800px',
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        flexShrink: 0,
        margin: '7px 0 40px 0',
      }
);

export const Title = styled('div')({
  marginBottom: '10px',
});
