import ChatService, { ScreenSharingRoleEnum } from '@services/chatService';
import {
  PERCENT_OF_WRAPPER_HEIGHT_WITH_SCREEN_SHARING,
  PERCENT_OF_WRAPPER_WIDTH,
  GAP_SUBS,
} from '@shared/VideoChat/styles';

export const styleWrapperHelper = (heightOfWrapper, widthOfWrapper) => {
  const isScreenSharingSubscriber = ChatService.screenSharingRole === ScreenSharingRoleEnum.SUBSCRIBER;
  const stylesObject = {
    height: isScreenSharingSubscriber ? heightOfWrapper * PERCENT_OF_WRAPPER_HEIGHT_WITH_SCREEN_SHARING : '93vh',
    width: widthOfWrapper * PERCENT_OF_WRAPPER_WIDTH,
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'row',
    gap: GAP_SUBS,
  }
  return isScreenSharingSubscriber
    ? { ...stylesObject, overflowX: 'auto' }
    : { ...stylesObject, alignItems: 'center' }
}
