import Text, { TEXT_SIZE } from "@components/Text";
import { isDesktop } from "@shared/constants/media-queries";
import { pluralizeText } from '@utils/textHelpers';
import { formatPracticeType } from "../../helpers";

const TITLES_RESOURCES = {
  EXERCISE: 'exercise',
  PRODUCTION: 'production',
};

const errorsTypes = (patternType, exercises, productions) => {
  const isDesktopSize = isDesktop();
  return [
    {
      size: isDesktopSize ? TEXT_SIZE.H2 : TEXT_SIZE.H3,
      content: formatPracticeType(patternType),
      weight: 700,
    },
    {
      size: isDesktopSize ? TEXT_SIZE.H4 : TEXT_SIZE.H5,
      content: pluralizeText(exercises, TITLES_RESOURCES.EXERCISE),
      weight: 0,
    },
    {
      size: isDesktopSize ? TEXT_SIZE.H4 : TEXT_SIZE.H5,
      content: pluralizeText(productions.productionsWithErrors + productions.productionsWithoutErrors, TITLES_RESOURCES.PRODUCTION),
      weight: 0,
    },
  ];
}

export const ErrorTypes = ({ patternType, exercises, productions }) => (
  <>
    {errorsTypes(patternType, exercises, productions).map(({ size, content, weight }) => (
      <Text size={size} weight={weight}>
        {content}
      </Text>
      ))
    }
  </>
);
