import {styled} from "@mui/material";

export const DashboardWrapper = styled('div')(
  {
    backgroundColor: '#FFF',
    display: 'grid',
    gridTemplateColumns: 'auto',
    gridTemplateRows: '90px auto auto',
  },
  (props) => ({
    gridTemplateAreas: `
    "${props.ViewsNav}"
    "${props.ViewHeader}"
    "${props.View}"
  `,
  })
);
