import gql from 'graphql-tag';
import _ from 'lodash';
import { graphQuery } from '@services/commService';

import { overview as course } from '@shared/Resources/Course/__fixtures__';
import { overview as exercise } from '@shared/Resources/Exercise/__fixtures__';
import { overview as lesson } from '@shared/Resources/Lesson/__fixtures__';
import { overview as media } from '@shared/Resources/Media/__fixtures__';
import { overview as poll } from '@shared/Resources/Poll/__fixtures__';
import { overview as practice } from '@shared/Resources/Practice/__fixtures__';
import { overview as skill } from '@shared/Resources/Skill/__fixtures__';
import { RESOURCES } from '@shared/Resources/types';

const useFixtures = process.env.USE_FIXTURES;

const extractEntityOverview = (res) => _.get(res, 'data.overview', [null])[0];
const extractEntityOverviewMedia = (res) => _.get(res, 'data.overview', [null]);

const getEntityQuery = async (nodeName, id, withChildren = true, extraGQL = '') => {
  const childrenAddition = withChildren ? `
  children {
    id
    type
    payload
  }
  ` : '';

  const query = gql`
  {
    overview: ${nodeName}(id: "${id}",range:[0,1],fullData:true){
    id
    name
    type
    isTemplate
    entityCreated{
      at
      by
    }
    entityUpdated{
      at
      by
    }
    usedIn {
      id
      name
      type
    }
    ${childrenAddition}
    ${extraGQL}
  }
  }
  `;
  const res = await graphQuery(query);
  return { errors: res?.errors || {}, overview: extractEntityOverview(res) };
};

const gqlCourse = async (id) => {
  if (useFixtures) return ({ errors: null, overview: { ...course, id } });
  return await getEntityQuery('entityCourses', id, true, 'isDefault');
};
const gqlExercise = async (id) => {
  if (useFixtures) return ({ errors: null, overview: { ...exercise, id } });
  const res = await getEntityQuery(
    'entityExercises',
    id,
    false,
    `
  masteryLevel
  media {
    demo
  }
  texts{
    instructions
  }
  monitor {
    monitor
    pattern
    errors
    progress
    stimulus
  }
  `,
  );
  return res;
};
const gqlLesson = async (id) => {
  if (useFixtures) return ({ errors: null, overview: { ...lesson, id } });
  const res = await getEntityQuery(
    'entityLessons',
    id,
    false,
    `
  media {
    demo
    lesson
  }
  texts{
    instructions
    description
  }
  monitor {
    monitor
    pattern
    errors
    progress
    stimulus
  }
  `,
  );
  return res;
};
const gqlMedia = async (id) => {
  try {
    if (useFixtures) return ({ errors: null, overview: { ...media, id } });

    const query = gql`
    {
      overview: mediaById(id:"${id}"){
        id
        title
        type
        tags
        description
        value
        usedIn {
          id
        }
        entityCreated{
          at
        }
      } 
    }
  `;

    const res = await graphQuery(query);

    return { errors: res?.errors || {}, overview: extractEntityOverviewMedia(res) };
  } catch (e) {}
};
const gqlPoll = async (id) => {
  if (useFixtures) return ({ errors: null, overview: { ...poll, id } });
  const res = await getEntityQuery('entityPolls', id, true, `
  description
  `);
  return res;
};
const gqlPractice = async (id) => {
  if (useFixtures) return ({ errors: null, overview: { ...practice, id } });

  const res = await getEntityQuery('entityPractices', id);
  return res;
};
const gqlSkill = async (id) => {
  if (useFixtures) return ({ errors: null, overview: { ...skill, id } });
  const res = await getEntityQuery('entitySkills', id, true, `
  texts{
    description
  }
  free
  `);
  return res;
};

// TBD
const gqlMonitor = async (id) => ({ errors: null, overview: { ...media, id } });
const gqlSpeechGoal = async (id) => ({ errors: null, overview: { ...media, id } });
const gqlStimulus = async (id) => ({ errors: null, overview: { ...media, id } });

const getAsyncFunction = (type) => {
  switch (type) {
    case RESOURCES.COURSE: return gqlCourse;
    case RESOURCES.EXERCISE: return gqlExercise;
    case RESOURCES.LESSON: return gqlLesson;
    case RESOURCES.MEDIA: return gqlMedia;
    case RESOURCES.MONITOR: return gqlMonitor;
    case RESOURCES.POLL: return gqlPoll;
    case RESOURCES.PRACTICE: return gqlPractice;
    case RESOURCES.SKILL: return gqlSkill;
    case RESOURCES.SPEECH_GOAL: return gqlSpeechGoal;
    case RESOURCES.STIMULUS: return gqlStimulus;
    default: return gqlMedia;
  }
};

export const getOverviewByTypeAndID = async (type, id) => {
  const asyncFunction = getAsyncFunction(type);
  const result = await asyncFunction(id);
  return result;
};
