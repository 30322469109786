import {
  put,
  takeEvery,
  takeLatest,
  all,
  fork,
} from 'redux-saga/effects';
import { setMonitorErrors, TYPES } from './actions';

import { startVideoRecording } from '@shared/VideoMonitor/actions';
import { createActivityProduction } from '../../services';

const useFixtures = process.env.USE_FIXTURES;

export function* callSaveActivityProduction({ payload }) {
  try{
  if (!useFixtures) {
    yield fork(createActivityProduction, payload);
  }
}catch(e){
  yield put(setMonitorErrors(e.message))
}
}

function* callSetExercisePausedFlag({ value }) {
  try{
  if (!useFixtures) {
    if (value === true) {
    } else if (value === false) {
      yield put(startVideoRecording());
    }
  }
}catch(e){
  yield put(setMonitorErrors(e.message))
}
}

function* watchCallSaveActivityProduction() {
  yield takeEvery(TYPES.SAVE_ACTIVITY_PRODUCTION, callSaveActivityProduction);
}
function* watchCallSetExercisePausedFlag() {
  yield takeLatest(TYPES.SET_EXERCISE_PAUSED_FLAG, callSetExercisePausedFlag)
}
export default function* practiceMonitorHandlerSaga() {
  yield all([
    fork(watchCallSaveActivityProduction),
    fork(watchCallSetExercisePausedFlag),
  ]);
}
