import React from 'react';
import PropTypes from 'prop-types';
import Text, { TEXT_SIZE, TEXT_COLOR } from '@components/Text';

import {
  MediaItemContainer,
  MediaItemInfo,
  IconWrapper,
  StyledIcon,
} from './styles';

class MediaItem extends React.PureComponent {
  static propTypes = {
    title: PropTypes.string.isRequired,
    description: PropTypes.string,
    onClick: PropTypes.func,
  };

  static defaultProps = {
    title: '',
    description: '',
  };

  render() {
    const { title, description, onClick } = this.props;
    return (
      <MediaItemContainer
        tabIndex='0'
        role='button'
        data-cy='media_item'
        onClick={onClick}
      >
        <IconWrapper>
          <StyledIcon />
        </IconWrapper>
        <MediaItemInfo>
          <Text size={TEXT_SIZE.T4} textColor={TEXT_COLOR.DARK_BLUE}>
            {title}
          </Text>
          <Text size={TEXT_SIZE.T4} textColor={TEXT_COLOR.DISABLED}>
            {description}
          </Text>
        </MediaItemInfo>
      </MediaItemContainer>
    );
  }
}

export default MediaItem;
