const map = {
  'Overall practice': {
    'very good': 'Your practice looks very good',
    good: 'Your practice sounded very good',
    'great workout': 'Great workout',
    excelent: 'Your practice was excellent',
    better: 'Your practice was much better! ',
    ok: 'Your practice looks ok',
  },
  'Practice progress pace': {
    maintain: 'You are really maintaining your practice routine, keep on the good work! ',
    medium: 'Try to increase your practice sessions, it is essential for your progress',
    low: "It's good to see you practice. Keep on with the practice routine. it is essential for your success. ",
  },
  posture: {
    good: 'Your posture is as it should be. Great!',
    hunched: 'Pay attention to your posture during the practice. You are hunched a bit and this way it will be harder for you to implement the techniques correctly.',
  },
  'overall look': {
    'Looks exhausted of tired': 'If you feel tired, try to do a short break and remember to drink some water between practice sessions',
  },
};
export default map;
