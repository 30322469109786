import React, { Component } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import isFuture from 'date-fns/is_future';
import { getMessagingDate } from '@utils/timeHelpers';
import Container from '@components/Container';
import Text, { TEXT_SIZE, TEXT_COLOR } from '@components/Text';
import {
  Header,
  CourseName,
  DetailsContainer,
  DetailsItem,
  NextInvoiceWrapper,
  NextInvoiceInfo,
  BillingCycle,
  BillingDetails,
  SubscriptionTextButton,
} from './styles';
import { compose } from 'redux';
import { withTranslation } from 'react-i18next';

class PlanDetails extends Component {
  getDetailsItem = (title, value, highlightValue) => (
    <DetailsItem>
      <Text size={TEXT_SIZE.T3} textColor={TEXT_COLOR.DISABLED_LIGHT}>
        {title}
      </Text>
      <Text
        size={TEXT_SIZE.T3}
        textColor={TEXT_COLOR[highlightValue ? 'DARK_BLUE' : 'DISABLED_LIGHT']}
      >
        {value}
      </Text>
    </DetailsItem>
  );

  getNextBillingText = (subscription, isPlanActive, hasPaidSubscription) => {
    const {isSubscriptionCancelled, planInfo, t} = this.props;

    if (isSubscriptionCancelled) {
      return (
        <Container column alignItems="flex-end">
          <Text size={TEXT_SIZE.T3} textColor={TEXT_COLOR.ERROR}>
              {t('Subscription.cancelled')}
          </Text>
          <Text size={TEXT_SIZE.H5} textColor={TEXT_COLOR.DISABLED}>
            {`${t('Common.Billing.ends')} ${getMessagingDate(
              subscription.expirationDate,
            )}`}
          </Text>
        </Container>
      );
    }

    const message =
      isPlanActive && hasPaidSubscription
        ? getMessagingDate(planInfo.nextCharge)
        : t('Informative.Negative.inactive');

    return (
      <Text
        size={TEXT_SIZE.T3}
        textColor={
          TEXT_COLOR[
            isPlanActive && hasPaidSubscription ? 'DARK_BLUE' : 'DISABLED_LIGHT'
          ]
        }
      >
        {message}
      </Text>
    );
  };

  getSubscriptionButton = (isPlanActive, hasPaidSubscription) => {
    const {onSubscriptionButtonClick, isSubscriptionCancelled} = this.props;
    let messageId;
    let isSubscriptionValid;
    if (isSubscriptionCancelled || (!isPlanActive && hasPaidSubscription)) {
      messageId = 'renewSubscriptionText';
      isSubscriptionValid = false;
    } else if (isPlanActive && hasPaidSubscription) {
      messageId = 'cancelSubscriptionText';
      isSubscriptionValid = true;
    } else {
      messageId = 'purchaseSubscriptionText';
      isSubscriptionValid = false;
    }
    return (
      <SubscriptionTextButton
        onClick={() => onSubscriptionButtonClick(isSubscriptionValid)}
      >
        <Text size={TEXT_SIZE.T4}>{formatMessage(messages[messageId])}</Text>
      </SubscriptionTextButton>
    );
  };

  renderPlanInfo = (subscription, isPlanActive, hasPaidSubscription) => {
    const {planInfo, t} = this.props;
    const billingFrequency = hasPaidSubscription
      ? this.getDetailsItem(
            t('Common.Billing.billed'),
          formatMessage(
            messages[`${planInfo.chargeFrequency.toLowerCase()}Text`],
          ),
          isPlanActive,
        )
      : this.getDetailsItem(
            t('Common.Billing.billed'),
            t('Subscription.free'),
          isPlanActive,
        );

    return (
      <div>
        {this.getDetailsItem(
            t('Common.UI.status'),
          t(`Common.Statuses.${isPlanActive ? 'active' : 'notActive'}`),
          true,
        )}
        {billingFrequency}
        {this.getDetailsItem(
            t('Dates.startDate'),
          getMessagingDate(subscription.startingDate),
          isPlanActive,
        )}
      </div>
    );
  };

  renderNextInvoiceInfo = (subscription, isPlanActive, hasPaidSubscription) => {
    const {course, isSubscriptionCancelled, planInfo, t} = this.props;
    const priceInfo = hasPaidSubscription
      ? `${planInfo.price.amount / 100}${
          planInfo.price.currency === 'usd' ? '$' : ''
        }`
      : t('Subscription.free');

    return (
      <NextInvoiceWrapper>
        <NextInvoiceInfo>
          <BillingCycle>
            <Text
              size={TEXT_SIZE.T3}
              textColor={TEXT_COLOR.DARK_BLUE}
              weight="bold"
            >
                {t('Subscription.billingCycle')}
            </Text>
            {this.getNextBillingText(
              subscription,
              isPlanActive,
              hasPaidSubscription,
            )}
          </BillingCycle>
          <BillingDetails>
            <Text
              size={TEXT_SIZE.H4}
              textColor={
                TEXT_COLOR[
                  isPlanActive && !isSubscriptionCancelled
                    ? 'DARK_BLUE'
                    : 'DISABLED_LIGHT'
                ]
              }
            >
              {`1x ${course.name}`}
            </Text>
            <Text
              size={TEXT_SIZE.H4}
              textColor={
                TEXT_COLOR[
                  isPlanActive && !isSubscriptionCancelled
                    ? 'DARK_BLUE'
                    : 'DISABLED_LIGHT'
                ]
              }
            >
              {priceInfo}
            </Text>
          </BillingDetails>
        </NextInvoiceInfo>
        {this.getSubscriptionButton(isPlanActive, hasPaidSubscription)}
      </NextInvoiceWrapper>
    );
  };

  render() {
    const {course, planInfo, t} = this.props;
    const hasPaidSubscription = course.Subscription.some(
      (item) => item.type === 'PAID_COURSE',
    );
    const hasActiveFreeSubscription =
      course.Subscription.length &&
      !hasPaidSubscription &&
      isFuture(course.Subscription[0].expirationDate);
    const subscription = hasPaidSubscription
      ? course.Subscription.find((item) => item.type === 'PAID_COURSE')
      : course.Subscription[0];
    const isPlanActive =
      _.get(planInfo, 'status') === 'ACTIVE' || hasActiveFreeSubscription;

    return (
      <div>
        <Header>
          <Text
            size={TEXT_SIZE.H5}
            textColor={TEXT_COLOR.DISABLED_LIGHT}
            weight="bold"
            textTransform="uppercase"
          >
              {t('Common.Billing.plan')}
          </Text>
        </Header>
        <CourseName>
          <Text
            size={TEXT_SIZE.T1}
            textColor={TEXT_COLOR.DARK_BLUE}
            weight="bold"
          >
            {course.name}
          </Text>
        </CourseName>
        <DetailsContainer>
          {this.renderPlanInfo(subscription, isPlanActive, hasPaidSubscription)}
          {this.renderNextInvoiceInfo(
            subscription,
            isPlanActive,
            hasPaidSubscription,
          )}
        </DetailsContainer>
      </div>
    );
  }
}

PlanDetails.propTypes = {
  course: PropTypes.object,
  planInfo: PropTypes.object,
  // changeBillingInfo: PropTypes.func,
  onSubscriptionButtonClick: PropTypes.func,
  isSubscriptionCancelled: PropTypes.bool,
};

export default compose(
    withTranslation()
)(PlanDetails);
