import { ROUTES } from '@shared/constants';
import { fromJS } from 'immutable';
import { Types } from './actions';

export const initialState = fromJS({
  errors: {
    serverError: null,
  },
  sending: false,
  redirectToPage: null,
});

const resetPasswordReducer = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case Types.PASSWORD_RESET_FAIL: {
      return state.setIn(['errors', 'serverError'], fromJS(payload.error));
    }

    case Types.PASSWORD_RESET_SUCCESS: {
      return state.setIn(['redirectToPage'], fromJS(ROUTES.SIGN_IN));
    }

    case Types.PASSWORD_CHANGE_SUCCESS: {
      return state.setIn(['redirectToPage'], fromJS(ROUTES.SIGN_IN));
    }
    case Types.CLEAR_REDIRECT: {
      return state.setIn(['redirectToPage'], fromJS(null));
    }
    default: {
      return state;
    }
  }
};

export default resetPasswordReducer;
