export const RATE_PACES = {
  BOTTOM_SLOW_RATE: 1,
  TOP_SLOW_RATE: 2.9,
  TOP_MANAGED_RATE: 4.2,
  TOP_HIGH_RATE: 6,
};

export const ROUTES = {
  HOME: "/",
  PENDING_DASHBOARD: "/pending",
  MEDIA_PAGE: "/media",
  SUPERVISOR_DASHBOARD: "/supervisor",
  SIGN_IN: "/signin",
  SIGN_UP: "/signup",
  FORGOT_PASSWORD: "/forgotPassword",
  RESET_PASSWORD: "/resetPassword",
  RESET_EMAIL_SENT: "/resetEmailSent",
  VERIFY_EMAIL_SENT: "/verifyEmailSent",
  CONFIRM_EMAIL: "/confirmEmail/:token",
  ACTIVITY_PAGE: "/activity",
  COURSE_PAGE: "/course",
  BUILDER: "/builder",
  ROLES_MANAGEMENT: "/rolesManagement",
  RESOURCES_MANAGER: "/resources",
  VIDEO_CHAT: "/videoChat",
  BILLING: "/billing",
  MESSAGING: "/messaging",
  TERMS_OF_USE: "/termsOfUse",
  PRIVACY_POLICY: "/privacyPolicy",
  SETTINGS: "/settings",
  USERS: {
    LIST: '/users',
    PAGE: '/users/:id/:pageType',
  },
};

export const CLIENT_VERSION = process.env.REACT_APP_VERSION;

export const getNovotalkUserAudioUploadURLFromURL = (url) =>
  `https://s3-eu-west-1.amazonaws.com/nvtk-user-audio-uploads/${url}.mp3`;

export const USE_PAID_SYSTEM_VERSION = false;

export const TOAST_AUTO_CLOSE_TIME = 5000;

export const ENABLE_EDIT_NESTED_RESOURCES = false;

export const REQUEST_TEXT_ERROR = 'Request error, try again later';

export const ENABLE_FREE_SKILL = false;

export const WITHOUT_PROGRESS = 'No progress';

export const DURATION_VALUES = {
  MIN: 1,
  MAX: 6,
  DEFAULT_VALUE: 1,
}

export const WITHOUT_PROGRESS_EMPTY = '';

export const ORDERS = {
  ASC: 'asc',
  DESC: 'desc',
};

export const MOCK_TAGS = [
  {
    label: 'Course',
    options: [
      { label: 'Foundation', value: 'Foundation' },
      { label: 'Implementation', value: 'Implementation' }
    ]
  },
  {
    label: 'Skills',
    options: [
      { label: 'Breathing', value: 'Breathing' },
      { label: 'Relaxation', value: 'Relaxation' },
      { label: 'Voice Production', value: 'Voice Production' },
      { label: 'Voice Transition', value: 'Voice Transition' },
      { label: 'Two Seconds per Syllable Pattern', value: 'Two Seconds per Syllable Pattern' },
      { label: 'One Second per Syllable', value: 'One Second per Syllable' },
      { label: 'One Second Pattern with Intonation', value: 'One Second Pattern with Intonation' },
      { label: 'Self-Managed Speech Rate', value: 'Self-Managed Speech Rate' },
      { label: 'Pace Changes', value: 'Pace Changes' },
      { label: 'Integrated Speech', value: 'Integrated Speech' },
    ]
  },
  {
    label: 'Techniques',
    options: [
      { label: 'Gentle Voice', value: 'Gentle Voice' },
      { label: 'Loud Voice', value: 'Loud Voice' },
      { label: 'Transition Between Voices', value: 'Transition Between Voices' },
      { label: 'Gentle initiation', value: 'Gentle initiation' },
      { label: 'Light Articulatory contacts', value: 'Light Articulatory contacts' },
    ]
  },
  {
    label: 'Type',
    options: [
      { label: 'Lesson', value: 'Lesson' },
      { label: 'Practices', value: 'Practices' },
      { label: 'Polls', value: 'Polls' },
      { label: 'Demo', value: 'Demo' },
    ]
  },
];
