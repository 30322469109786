const activities = [
  {
    year: 2018,
    month: 3,
    activities: [
      {
        name: 'Course - Basics',
        type: 'WORKOUT',
        level: 41,
        activityId: 19170,
        timestamp: 1521987867,
        __typename: 'Activity',
      },
      {
        name: 'Course - Basics',
        type: 'WORKOUT',
        level: 40,
        activityId: 19090,
        timestamp: 1521752308,
        __typename: 'Activity',
      },
      {
        name: 'Course - Basics',
        type: 'WORKOUT',
        level: -1,
        activityId: 18926,
        timestamp: 1521462456,
        __typename: 'Activity',
      },
      {
        name: 'Course - Basics',
        type: 'WORKOUT',
        level: 40,
        activityId: 18920,
        timestamp: 1521461212,
        __typename: 'Activity',
      },
      {
        name: 'Course - Basics',
        type: 'WORKOUT',
        level: 38,
        activityId: 18896,
        timestamp: 1521405998,
        __typename: 'Activity',
      },
      {
        name: 'Course - Basics',
        type: 'WORKOUT',
        level: 38,
        activityId: 18785,
        timestamp: 1521049079,
        __typename: 'Activity',
      },
      {
        name: 'Course - Basics',
        type: 'WORKOUT',
        level: 38,
        activityId: 18721,
        timestamp: 1520858065,
        __typename: 'Activity',
      },
      {
        name: 'Course - Basics',
        type: 'WORKOUT',
        level: 38,
        activityId: 18676,
        timestamp: 1520794831,
        __typename: 'Activity',
      },
      {
        name: 'Course - Basics',
        type: 'WORKOUT',
        level: 38,
        activityId: 18476,
        timestamp: 1520418455,
        __typename: 'Activity',
      },
      {
        name: 'Course - Basics',
        type: 'WORKOUT',
        level: 38,
        activityId: 18449,
        timestamp: 1520355695,
        __typename: 'Activity',
      },
      {
        name: 'Course - Basics',
        type: 'WORKOUT',
        level: 38,
        activityId: 18435,
        timestamp: 1520279123,
        __typename: 'Activity',
      },
      {
        name: 'Course - Basics',
        type: 'WORKOUT',
        level: 38,
        activityId: 18358,
        timestamp: 1520102098,
        __typename: 'Activity',
      },
      {
        "name": "speechGoal_114",
        "type": "SPEECH_GOAL",
        "level": 4,
        "activityId": "5b4355f841053a000f3a6834",
        "timestamp": 1531139576
      },
      {
        "name": "speechGoal_112",
        "type": "SPEECH_GOAL",
        "level": 4,
        "activityId": "5b43590c41053a000f3a6835",
        "timestamp": 1531140364
      },
      {
        "name": "speechGoal_112",
        "type": "SPEECH_GOAL",
        "level": 4,
        "activityId": "5b43591541053a000f3a6836",
        "timestamp": 1531140373
      },
      {
        "name": "speechGoal_112",
        "type": "SPEECH_GOAL",
        "level": 4,
        "activityId": "5b43591d41053a000f3a6837",
        "timestamp": 1531140381
      },
      {
        "name": "speechGoal_112",
        "type": "SPEECH_GOAL",
        "level": 4,
        "activityId": "5b43592541053a000f3a6838",
        "timestamp": 1531140389
      },
      {
        "name": "speechGoal_112",
        "type": "SPEECH_GOAL",
        "level": 4,
        "activityId": "5b43595a41053a000f3a6839",
        "timestamp": 1531140442
      },
      {
        "name": "speechGoal_112",
        "type": "SPEECH_GOAL",
        "level": 4,
        "activityId": "5b43598241053a000f3a683a",
        "timestamp": 1531140482
      },
      {
        "name": "speechGoal_114",
        "type": "SPEECH_GOAL",
        "level": 4,
        "activityId": "5b43599141053a000f3a683b",
        "timestamp": 1531140497
      },
      {
        "name": "speechGoal_114",
        "type": "SPEECH_GOAL",
        "level": 4,
        "activityId": "5b43599941053a000f3a683c",
        "timestamp": 1531140505
      },
      {
        "name": "speechGoal_113",
        "type": "SPEECH_GOAL",
        "level": 4,
        "activityId": "5b4359aa41053a000f3a683d",
        "timestamp": 1531140522
      },
      {
        "name": "speechGoal_113",
        "type": "SPEECH_GOAL",
        "level": 4,
        "activityId": "5b4359b441053a000f3a683e",
        "timestamp": 1531140532
      },
      {
        "name": "speechGoal_113",
        "type": "SPEECH_GOAL",
        "level": 4,
        "activityId": "5b4359c041053a000f3a683f",
        "timestamp": 1531140544
      },
      {
        "name": "speechGoal_115",
        "type": "SPEECH_GOAL",
        "level": 4,
        "activityId": "5b435a1441053a000f3a6840",
        "timestamp": 1531140628
      },
      {
        "name": "speechGoal_100",
        "type": "SPEECH_GOAL",
        "level": 1,
        "activityId": "5b435b3a4ad76e000fd67ddc",
        "timestamp": 1531140922
      },
      {
        "name": "speechGoal_138",
        "type": "SPEECH_GOAL",
        "level": 1,
        "activityId": "5b4e0c2c5a9a87000fb5e801",
        "timestamp": 1531841580
      },
      {
        "name": "speechGoal_116",
        "type": "SPEECH_GOAL",
        "level": 6,
        "activityId": "5b50b635f346450019178c24",
        "timestamp": 1532016182
      },
      {
        "name": "speechGoal_110",
        "type": "SPEECH_GOAL",
        "level": 4,
        "activityId": "5b51c3aaf346450019178c26",
        "timestamp": 1532085162
      },
      {
        "name": "speechGoal_138",
        "type": "SPEECH_GOAL",
        "level": 1,
        "activityId": "5b51e3613bec430019698163",
        "timestamp": 1532093281
      },
      {
        "name": "speechGoal_138",
        "type": "SPEECH_GOAL",
        "level": 1,
        "activityId": "5b51e3693bec430019698164",
        "timestamp": 1532093289
      },
      {
        "name": "speechGoal_138",
        "type": "SPEECH_GOAL",
        "level": 1,
        "activityId": "5b51e3703bec430019698165",
        "timestamp": 1532093296
      },
      {
        "name": "speechGoal_117",
        "type": "SPEECH_GOAL",
        "level": 6,
        "activityId": "5b51e3823bec430019698166",
        "timestamp": 1532093314
      },
      {
        "name": "speechGoal_118",
        "type": "SPEECH_GOAL",
        "level": 6,
        "activityId": "5b51e38b3bec430019698167",
        "timestamp": 1532093323
      },
      {
        "name": "speechGoal_119",
        "type": "SPEECH_GOAL",
        "level": 6,
        "activityId": "5b51e3953bec430019698168",
        "timestamp": 1532093333
      },
      {
        "name": "speechGoal_120",
        "type": "SPEECH_GOAL",
        "level": 6,
        "activityId": "5b51e39e3bec430019698169",
        "timestamp": 1532093342
      },
      {
        "name": "speechGoal_121",
        "type": "SPEECH_GOAL",
        "level": 6,
        "activityId": "5b51e3a83bec43001969816a",
        "timestamp": 1532093352
      },
      {
        "name": "speechGoal_121",
        "type": "SPEECH_GOAL",
        "level": 6,
        "activityId": "5b51e3b03bec43001969816b",
        "timestamp": 1532093360
      },
      {
        "name": "speechGoal_119",
        "type": "SPEECH_GOAL",
        "level": 6,
        "activityId": "5b51e3b93bec43001969816c",
        "timestamp": 1532093369
      }
    ],
    __typename: 'ActivityOverviewMonth',
  },
  {
    year: 2018,
    month: 2,
    activities: [

      {
        "name": "speechGoal_100",
        "type": "SPEECH_GOAL",
        "level": 1,
        "activityId": "5b4241124ad76e000fd67da8",
        "timestamp": 1531068690
      },
      {
        "name": "speechGoal_100",
        "type": "SPEECH_GOAL",
        "level": 1,
        "activityId": "5b4241134ad76e000fd67da9",
        "timestamp": 1531068691
      },
      {
        "name": "speechGoal_100",
        "type": "SPEECH_GOAL",
        "level": 1,
        "activityId": "5b4241134ad76e000fd67daa",
        "timestamp": 1531068691
      },
      {
        "name": "speechGoal_100",
        "type": "SPEECH_GOAL",
        "level": 1,
        "activityId": "5b4241134ad76e000fd67dab",
        "timestamp": 1531068691
      },
      {
        "name": "speechGoal_100",
        "type": "SPEECH_GOAL",
        "level": 1,
        "activityId": "5b4241134ad76e000fd67dac",
        "timestamp": 1531068691
      },
      {
        "name": "speechGoal_100",
        "type": "SPEECH_GOAL",
        "level": 1,
        "activityId": "5b4241144ad76e000fd67dad",
        "timestamp": 1531068692
      },
      {
        "name": "speechGoal_100",
        "type": "SPEECH_GOAL",
        "level": 1,
        "activityId": "5b4241144ad76e000fd67dae",
        "timestamp": 1531068692
      },
      {
        "name": "speechGoal_100",
        "type": "SPEECH_GOAL",
        "level": 1,
        "activityId": "5b4241144ad76e000fd67daf",
        "timestamp": 1531068692
      },
      {
        "name": "speechGoal_100",
        "type": "SPEECH_GOAL",
        "level": 1,
        "activityId": "5b4241154ad76e000fd67db0",
        "timestamp": 1531068693
      },
      {
        "name": "speechGoal_100",
        "type": "SPEECH_GOAL",
        "level": 1,
        "activityId": "5b4241154ad76e000fd67db1",
        "timestamp": 1531068693
      },
      {
        "name": "speechGoal_100",
        "type": "SPEECH_GOAL",
        "level": 1,
        "activityId": "5b4241154ad76e000fd67db2",
        "timestamp": 1531068693
      },
      {
        "name": "speechGoal_100",
        "type": "SPEECH_GOAL",
        "level": 1,
        "activityId": "5b4241164ad76e000fd67db3",
        "timestamp": 1531068694
      },
      {
        "name": "speechGoal_100",
        "type": "SPEECH_GOAL",
        "level": 1,
        "activityId": "5b4241164ad76e000fd67db4",
        "timestamp": 1531068694
      },
      {
        "name": "speechGoal_100",
        "type": "SPEECH_GOAL",
        "level": 1,
        "activityId": "5b4241164ad76e000fd67db5",
        "timestamp": 1531068694
      },
      {
        "name": "speechGoal_100",
        "type": "SPEECH_GOAL",
        "level": 1,
        "activityId": "5b4241164ad76e000fd67db6",
        "timestamp": 1531068694
      },
      {
        "name": "speechGoal_100",
        "type": "SPEECH_GOAL",
        "level": 1,
        "activityId": "5b4241174ad76e000fd67db7",
        "timestamp": 1531068695
      },
      {
        "name": "speechGoal_100",
        "type": "SPEECH_GOAL",
        "level": 1,
        "activityId": "5b4241174ad76e000fd67db8",
        "timestamp": 1531068695
      },
      {
        "name": "speechGoal_100",
        "type": "SPEECH_GOAL",
        "level": 1,
        "activityId": "5b4241174ad76e000fd67db9",
        "timestamp": 1531068695
      },
      {
        "name": "speechGoal_100",
        "type": "SPEECH_GOAL",
        "level": 1,
        "activityId": "5b4241174ad76e000fd67dba",
        "timestamp": 1531068695
      },
      {
        "name": "speechGoal_100",
        "type": "SPEECH_GOAL",
        "level": 1,
        "activityId": "5b4241184ad76e000fd67dbb",
        "timestamp": 1531068696
      },
      {
        "name": "speechGoal_100",
        "type": "SPEECH_GOAL",
        "level": 1,
        "activityId": "5b4241184ad76e000fd67dbc",
        "timestamp": 1531068696
      },
      {
        "name": "speechGoal_100",
        "type": "SPEECH_GOAL",
        "level": 1,
        "activityId": "5b4241184ad76e000fd67dbd",
        "timestamp": 1531068696
      },
      {
        "name": "speechGoal_101",
        "type": "SPEECH_GOAL",
        "level": 1,
        "activityId": "5b4323984ad76e000fd67dbe",
        "timestamp": 1531126680
      },
      {
        "name": "speechGoal_null",
        "type": "SPEECH_GOAL",
        "level": 1,
        "activityId": "5b43244841053a000f3a6810",
        "timestamp": 1531126856
      },
      {
        "name": "speechGoal_100",
        "type": "SPEECH_GOAL",
        "level": 1,
        "activityId": "5b43256e41053a000f3a6811",
        "timestamp": 1531127150
      },
      {
        "name": "speechGoal_102",
        "type": "SPEECH_GOAL",
        "level": 2,
        "activityId": "5b4326704ad76e000fd67dbf",
        "timestamp": 1531127408
      },
      {
        "name": "speechGoal_100",
        "type": "SPEECH_GOAL",
        "level": 1,
        "activityId": "5b43269b4ad76e000fd67dc0",
        "timestamp": 1531127451
      },
      {
        "name": "speechGoal_104",
        "type": "SPEECH_GOAL",
        "level": 2,
        "activityId": "5b432d8c41053a000f3a6812",
        "timestamp": 1531129228
      },
      {
        "name": "speechGoal_104",
        "type": "SPEECH_GOAL",
        "level": 2,
        "activityId": "5b432d9341053a000f3a6813",
        "timestamp": 1531129235
      },
      {
        "name": "speechGoal_104",
        "type": "SPEECH_GOAL",
        "level": 2,
        "activityId": "5b432d9b41053a000f3a6814",
        "timestamp": 1531129243
      },
      {
        "name": "speechGoal_104",
        "type": "SPEECH_GOAL",
        "level": 2,
        "activityId": "5b432da541053a000f3a6815",
        "timestamp": 1531129253
      },
      {
        "name": "speechGoal_104",
        "type": "SPEECH_GOAL",
        "level": 2,
        "activityId": "5b432dac41053a000f3a6816",
        "timestamp": 1531129260
      },
      {
        "name": "speechGoal_103",
        "type": "SPEECH_GOAL",
        "level": 2,
        "activityId": "5b432db541053a000f3a6817",
        "timestamp": 1531129269
      },
      {
        "name": "speechGoal_103",
        "type": "SPEECH_GOAL",
        "level": 2,
        "activityId": "5b432dbd41053a000f3a6818",
        "timestamp": 1531129277
      },
      {
        "name": "speechGoal_103",
        "type": "SPEECH_GOAL",
        "level": 2,
        "activityId": "5b432dc641053a000f3a6819",
        "timestamp": 1531129286
      },
      {
        "name": "speechGoal_103",
        "type": "SPEECH_GOAL",
        "level": 2,
        "activityId": "5b432dcd41053a000f3a681a",
        "timestamp": 1531129293
      },
      {
        "name": "speechGoal_103",
        "type": "SPEECH_GOAL",
        "level": 2,
        "activityId": "5b432dd641053a000f3a681b",
        "timestamp": 1531129302
      },
      {
        "name": "speechGoal_102",
        "type": "SPEECH_GOAL",
        "level": 2,
        "activityId": "5b432de241053a000f3a681c",
        "timestamp": 1531129314
      },
      {
        "name": "speechGoal_100",
        "type": "SPEECH_GOAL",
        "level": 1,
        "activityId": "5b432ded41053a000f3a681d",
        "timestamp": 1531129325
      },
      {
        "name": "speechGoal_101",
        "type": "SPEECH_GOAL",
        "level": 2,
        "activityId": "5b432dfa41053a000f3a681e",
        "timestamp": 1531129338
      },
      {
        "name": "speechGoal_101",
        "type": "SPEECH_GOAL",
        "level": 2,
        "activityId": "5b432e0141053a000f3a681f",
        "timestamp": 1531129345
      },
      {
        "name": "speechGoal_101",
        "type": "SPEECH_GOAL",
        "level": 2,
        "activityId": "5b432e0941053a000f3a6820",
        "timestamp": 1531129353
      },
      {
        "name": "speechGoal_101",
        "type": "SPEECH_GOAL",
        "level": 2,
        "activityId": "5b432e1041053a000f3a6821",
        "timestamp": 1531129360
      },
      {
        "name": "speechGoal_102",
        "type": "SPEECH_GOAL",
        "level": 2,
        "activityId": "5b432e1f41053a000f3a6822",
        "timestamp": 1531129375
      },
      {
        "name": "speechGoal_102",
        "type": "SPEECH_GOAL",
        "level": 2,
        "activityId": "5b432e2841053a000f3a6823",
        "timestamp": 1531129384
      },
      {
        "name": "speechGoal_102",
        "type": "SPEECH_GOAL",
        "level": 2,
        "activityId": "5b432e8441053a000f3a6824",
        "timestamp": 1531129476
      },
      {
        "name": "speechGoal_106",
        "type": "SPEECH_GOAL",
        "level": 3,
        "activityId": "5b4330f64ad76e000fd67dc1",
        "timestamp": 1531130102
      },
      {
        "name": "speechGoal_105",
        "type": "SPEECH_GOAL",
        "level": 3,
        "activityId": "5b43310d4ad76e000fd67dc2",
        "timestamp": 1531130125
      },
      {
        "name": "speechGoal_107",
        "type": "SPEECH_GOAL",
        "level": 3,
        "activityId": "5b4331184ad76e000fd67dc3",
        "timestamp": 1531130136
      },
      {
        "name": "speechGoal_107",
        "type": "SPEECH_GOAL",
        "level": 3,
        "activityId": "5b4331204ad76e000fd67dc4",
        "timestamp": 1531130144
      },
      {
        "name": "speechGoal_108",
        "type": "SPEECH_GOAL",
        "level": 3,
        "activityId": "5b43312b4ad76e000fd67dc5",
        "timestamp": 1531130155
      },
      {
        "name": "speechGoal_108",
        "type": "SPEECH_GOAL",
        "level": 3,
        "activityId": "5b4331844ad76e000fd67dc6",
        "timestamp": 1531130244
      },
      {
        "name": "speechGoal_108",
        "type": "SPEECH_GOAL",
        "level": 3,
        "activityId": "5b43318c4ad76e000fd67dc7",
        "timestamp": 1531130252
      },
      {
        "name": "speechGoal_108",
        "type": "SPEECH_GOAL",
        "level": 3,
        "activityId": "5b4331944ad76e000fd67dc8",
        "timestamp": 1531130260
      },
      {
        "name": "speechGoal_108",
        "type": "SPEECH_GOAL",
        "level": 3,
        "activityId": "5b43319c4ad76e000fd67dc9",
        "timestamp": 1531130268
      },
      {
        "name": "speechGoal_109",
        "type": "SPEECH_GOAL",
        "level": 3,
        "activityId": "5b4331a84ad76e000fd67dca",
        "timestamp": 1531130280
      },
      {
        "name": "speechGoal_109",
        "type": "SPEECH_GOAL",
        "level": 3,
        "activityId": "5b4331af4ad76e000fd67dcb",
        "timestamp": 1531130287
      },
      {
        "name": "speechGoal_109",
        "type": "SPEECH_GOAL",
        "level": 3,
        "activityId": "5b4331b74ad76e000fd67dcc",
        "timestamp": 1531130295
      },
      {
        "name": "speechGoal_109",
        "type": "SPEECH_GOAL",
        "level": 3,
        "activityId": "5b4331c04ad76e000fd67dcd",
        "timestamp": 1531130304
      },
      {
        "name": "speechGoal_109",
        "type": "SPEECH_GOAL",
        "level": 3,
        "activityId": "5b4331ca4ad76e000fd67dce",
        "timestamp": 1531130314
      },
      {
        "name": "speechGoal_110",
        "type": "SPEECH_GOAL",
        "level": 3,
        "activityId": "5b4331d44ad76e000fd67dcf",
        "timestamp": 1531130324
      },
      {
        "name": "speechGoal_110",
        "type": "SPEECH_GOAL",
        "level": 3,
        "activityId": "5b4331db4ad76e000fd67dd0",
        "timestamp": 1531130331
      },
      {
        "name": "speechGoal_110",
        "type": "SPEECH_GOAL",
        "level": 3,
        "activityId": "5b4331e34ad76e000fd67dd1",
        "timestamp": 1531130339
      },
      {
        "name": "speechGoal_110",
        "type": "SPEECH_GOAL",
        "level": 3,
        "activityId": "5b4331eb4ad76e000fd67dd2",
        "timestamp": 1531130347
      },
      {
        "name": "speechGoal_110",
        "type": "SPEECH_GOAL",
        "level": 3,
        "activityId": "5b4331f54ad76e000fd67dd3",
        "timestamp": 1531130357
      },
      {
        "name": "speechGoal_111",
        "type": "SPEECH_GOAL",
        "level": 4,
        "activityId": "5b4332194ad76e000fd67dd4",
        "timestamp": 1531130393
      },
      {
        "name": "speechGoal_111",
        "type": "SPEECH_GOAL",
        "level": 4,
        "activityId": "5b4332424ad76e000fd67dd5",
        "timestamp": 1531130434
      },
      {
        "name": "speechGoal_111",
        "type": "SPEECH_GOAL",
        "level": 4,
        "activityId": "5b43330541053a000f3a6825",
        "timestamp": 1531130629
      },
      {
        "name": "speechGoal_110",
        "type": "SPEECH_GOAL",
        "level": 3,
        "activityId": "5b43370f41053a000f3a6826",
        "timestamp": 1531131663
      },
      {
        "name": "speechGoal_110",
        "type": "SPEECH_GOAL",
        "level": 3,
        "activityId": "5b43374141053a000f3a6827",
        "timestamp": 1531131713
      },
      {
        "name": "speechGoal_100",
        "type": "SPEECH_GOAL",
        "level": 4,
        "activityId": "5b433adb41053a000f3a6828",
        "timestamp": 1531132635
      },
      {
        "name": "speechGoal_100",
        "type": "SPEECH_GOAL",
        "level": 4,
        "activityId": "5b433aec41053a000f3a6829",
        "timestamp": 1531132652
      },
      {
        "name": "speechGoal_100",
        "type": "SPEECH_GOAL",
        "level": 1,
        "activityId": "5b43471d4ad76e000fd67dd6",
        "timestamp": 1531135773
      },
      {
        "name": "speechGoal_107",
        "type": "SPEECH_GOAL",
        "level": 3,
        "activityId": "5b434e1a4ad76e000fd67dd7",
        "timestamp": 1531137562
      },
      {
        "name": "speechGoal_106",
        "type": "SPEECH_GOAL",
        "level": 3,
        "activityId": "5b434e5a41053a000f3a682a",
        "timestamp": 1531137626
      },
      {
        "name": "speechGoal_108",
        "type": "SPEECH_GOAL",
        "level": 3,
        "activityId": "5b434e8d41053a000f3a682b",
        "timestamp": 1531137677
      },
      {
        "name": "speechGoal_103",
        "type": "SPEECH_GOAL",
        "level": 2,
        "activityId": "5b434ec341053a000f3a682c",
        "timestamp": 1531137731
      },
      {
        "name": "speechGoal_107",
        "type": "SPEECH_GOAL",
        "level": 3,
        "activityId": "5b434f5241053a000f3a682d",
        "timestamp": 1531137874
      },
      {
        "name": "speechGoal_106",
        "type": "SPEECH_GOAL",
        "level": 3,
        "activityId": "5b4350fe41053a000f3a682e",
        "timestamp": 1531138302
      },
      {
        "name": "speechGoal_106",
        "type": "SPEECH_GOAL",
        "level": 3,
        "activityId": "5b43511241053a000f3a682f",
        "timestamp": 1531138322
      },
      {
        "name": "speechGoal_107",
        "type": "SPEECH_GOAL",
        "level": 3,
        "activityId": "5b43512f41053a000f3a6830",
        "timestamp": 1531138351
      },
      {
        "name": "speechGoal_106",
        "type": "SPEECH_GOAL",
        "level": 3,
        "activityId": "5b4352084ad76e000fd67dd8",
        "timestamp": 1531138568
      },
      {
        "name": "speechGoal_109",
        "type": "SPEECH_GOAL",
        "level": 3,
        "activityId": "5b43521f4ad76e000fd67dd9",
        "timestamp": 1531138591
      },
      {
        "name": "speechGoal_101",
        "type": "SPEECH_GOAL",
        "level": 2,
        "activityId": "5b4353924ad76e000fd67dda",
        "timestamp": 1531138962
      },
      {
        "name": "speechGoal_106",
        "type": "SPEECH_GOAL",
        "level": 3,
        "activityId": "5b43549e4ad76e000fd67ddb",
        "timestamp": 1531139230
      },
      {
        "name": "speechGoal_106",
        "type": "SPEECH_GOAL",
        "level": 3,
        "activityId": "5b43550b41053a000f3a6831",
        "timestamp": 1531139339
      },
      {
        "name": "speechGoal_105",
        "type": "SPEECH_GOAL",
        "level": 3,
        "activityId": "5b4355c341053a000f3a6832",
        "timestamp": 1531139523
      },
      {
        "name": "speechGoal_115",
        "type": "SPEECH_GOAL",
        "level": 4,
        "activityId": "5b4355e341053a000f3a6833",
        "timestamp": 1531139555
      },
      {
        name: 'Course - Basics',
        type: 'WORKOUT',
        level: 38,
        activityId: 18286,
        timestamp: 1519732779,
        __typename: 'Activity',
      },
      {
        name: 'Course - Basics',
        type: 'WORKOUT',
        level: 38,
        activityId: 18235,
        timestamp: 1519567175,
        __typename: 'Activity',
      },
      {
        name: 'Course - Basics',
        type: 'WORKOUT',
        level: 37,
        activityId: 18182,
        timestamp: 1519295632,
        __typename: 'Activity',
      },
      {
        name: 'Course - Basics',
        type: 'WORKOUT',
        level: 36,
        activityId: 18127,
        timestamp: 1519125087,
        __typename: 'Activity',
      },
      {
        name: 'Course - Basics',
        type: 'WORKOUT',
        level: 35,
        activityId: 18085,
        timestamp: 1519041585,
        __typename: 'Activity',
      },
      {
        name: 'Course - Basics',
        type: 'WORKOUT',
        level: 34,
        activityId: 18061,
        timestamp: 1518986183,
        __typename: 'Activity',
      },
      {
        name: 'Course - Basics',
        type: 'WORKOUT',
        level: 33,
        activityId: 18020,
        timestamp: 1518697133,
        __typename: 'Activity',
      },
      {
        name: 'Course - Basics',
        type: 'WORKOUT',
        level: -1,
        activityId: 17882,
        timestamp: 1518514053,
        __typename: 'Activity',
      },
      {
        name: 'Course - Basics',
        type: 'WORKOUT',
        level: 32,
        activityId: 17880,
        timestamp: 1518513174,
        __typename: 'Activity',
      },
      {
        name: 'Course - Basics',
        type: 'WORKOUT',
        level: 32,
        activityId: 17842,
        timestamp: 1518432286,
        __typename: 'Activity',
      },
      {
        name: 'Course - Basics',
        type: 'WORKOUT',
        level: 31,
        activityId: 17815,
        timestamp: 1518344808,
        __typename: 'Activity',
      },
      {
        name: 'Course - Basics',
        type: 'WORKOUT',
        level: 31,
        activityId: 17781,
        timestamp: 1518088548,
        __typename: 'Activity',
      },
      {
        name: 'Course - Basics',
        type: 'WORKOUT',
        level: 30,
        activityId: 17768,
        timestamp: 1518032822,
        __typename: 'Activity',
      },
      {
        name: 'Course - Basics',
        type: 'WORKOUT',
        level: 29,
        activityId: 17699,
        timestamp: 1517914529,
        __typename: 'Activity',
      },
      {
        name: 'Course - Basics',
        type: 'WORKOUT',
        level: 28,
        activityId: 17680,
        timestamp: 1517836265,
        __typename: 'Activity',
      },
      {
        name: 'Course - Basics',
        type: 'WORKOUT',
        level: 28,
        activityId: 17650,
        timestamp: 1517742130,
        __typename: 'Activity',
      },
      {
        name: 'Course - Basics',
        type: 'WORKOUT',
        level: 27,
        activityId: 17565,
        timestamp: 1517500352,
        __typename: 'Activity',
      },
    ],
    __typename: 'ActivityOverviewMonth',
  }
];

export default activities;
