import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Text, { TEXT_SIZE, TEXT_COLOR } from '@components/Text';
import { getMessagingDate } from '@utils/timeHelpers';
import {
  Wrapper,
  MenuItem,
  StyledPersonIcon,
  NewConversationText,
  MessageWrapper,
  StyledAvatar,
  MessageInfoWrapper,
  MessageTextWrapper,
  EndedBadge,
  MessageContentWrapper,
} from './styles';
import {withTranslation} from "react-i18next";
import {compose} from "redux";

export class MessagesList extends Component {
  renderNewConversationItem = () => {
    const {
      isNewConversationStarted,
    } = this.props;
    if (!isNewConversationStarted) return null;
    return (
      <MenuItem newItem>
        <StyledPersonIcon />
        <NewConversationText>
          <Text size={TEXT_SIZE.H4} textColor={TEXT_COLOR.NORMAL}>
            {this.props.t('Informative.Notifying.newMessages')}
          </Text>
        </NewConversationText>
      </MenuItem>
    );
  };

  renderList = () => {
    const {
      conversations,
      currentConversation,
      chooseConversation,
    } = this.props;
    return conversations.map((conversation) => {
      const { Thread, Users, id } = conversation;
      const post = Thread.Posts[0];
      if (!post) return null;
      const user = Users.find((userData) => !userData.self);
      if(!user?.fullName) return <></>
      return (
        <MenuItem
          key={id}
          isCurrent={id === currentConversation?.id}
          onClick={() => chooseConversation(conversation)}
        >
          {user?.avatar ? <StyledAvatar src={user.avatar} /> : <StyledPersonIcon />}
          <MessageWrapper>
            <MessageInfoWrapper>
              <Text
                size={TEXT_SIZE.H4}
                textColor={TEXT_COLOR.NORMAL}
                weight={post.unread ? 'bold' : 'normal'}
              >
                {user?.fullName}
              </Text>
              <Text size={TEXT_SIZE.T4} textColor={TEXT_COLOR.DISABLED}>
                {getMessagingDate(post.createdAt)}
              </Text>
            </MessageInfoWrapper>
            <MessageContentWrapper>
              <MessageTextWrapper>
                {post.self && (
                  <Text
                    size={TEXT_SIZE.T4}
                    textColor={TEXT_COLOR.DISABLED}
                    margin='0 5px 0 0'
                    weight='bold'
                  >
                    {this.props.t('Common.UI.you')}
                  </Text>
                )}
                <Text
                  size={TEXT_SIZE.T4}
                  textColor={
                    post.unread ? TEXT_COLOR.DARK_BLUE : TEXT_COLOR.DISABLED
                  }
                  weight={post.unread ? 'bold' : 'normal'}
                >
                  {post.payload.text}
                </Text>
              </MessageTextWrapper>
              {Thread.closed && (
                <EndedBadge>
                  <Text size={TEXT_SIZE.T4} textColor={TEXT_COLOR.WARNING}>
                    {this.props.t('Common.UI.ended').toUpperCase()}
                  </Text>
                </EndedBadge>
              )}
            </MessageContentWrapper>
          </MessageWrapper>
        </MenuItem>
      );
    });
  };

  render() {
    return (
      <Wrapper>
        {this.renderNewConversationItem()}
        {this.renderList()}
      </Wrapper>
    );
  }
}

MessagesList.propTypes = {
  conversations: PropTypes.array,
  currentConversation: PropTypes.object,
  isNewConversationStarted: PropTypes.bool,
  chooseConversation: PropTypes.func,
};

export default compose(
    withTranslation()
)(MessagesList);
