import { fromJS } from 'immutable';
export const ALL_STAGES_KEY = 'All stages';
// The initial state of the SupervisorDashboard
export const initialState = fromJS({
  overviewActivityWorkouts: [],
  loadingOverviewActivityWorkouts: true,
  allActivityWorkouts: [],
  loadingAllActivityWorkouts: true,
  ActivityWorkoutsFilters: {
    timeRangeFilter: 48,
    type: '',
    stageOptions: [],
    stagesFilter: [ALL_STAGES_KEY],
    usersOptions: [],
    usersFilter: '',
    feedbackFilter: 'All',
    feedbackGapSlider: {
      min: 0,
      max: 1,
      minWithFeedback: 0,
      maxWithFeedback: 1,
      filterMin: 0,
      filterMax: 1,
    },
  },
});

const getFiltersValues = (state, activities) => {
  const filters = state.get('ActivityWorkoutsFilters').toJS();
  const users = {};
  const stages = {};
  let bestFeedback;
  let worstFeedback;
  let sliderMin;
  let sliderMax;

  activities.forEach((activity) => {
    users[activity.user] = activity.user;
    stages[activity.stage] = activity.stage;
    const { feedbackTime, feedbackGap } = activity;
    if (!sliderMin) sliderMin = feedbackGap;
    else if (feedbackGap < sliderMin)sliderMin = feedbackGap;
    if (!sliderMax) sliderMax = feedbackGap;
    else if (feedbackGap > sliderMax)sliderMax = feedbackGap;

    if (feedbackTime) {
      if (!bestFeedback) bestFeedback = feedbackGap;
      else if (feedbackGap < bestFeedback) bestFeedback = feedbackGap;

      if (!worstFeedback) worstFeedback = feedbackGap;
      else if (feedbackGap > worstFeedback) worstFeedback = feedbackGap;
    }
  });

  const stagesValues = Object.values(stages).sort();
  stagesValues.unshift(ALL_STAGES_KEY);
  filters.feedbackGapSlider.min = sliderMin || 0;
  filters.feedbackGapSlider.filterMin = sliderMin || 0;
  filters.feedbackGapSlider.max = sliderMax || 1;
  filters.feedbackGapSlider.filterMax = sliderMax || 1;
  filters.feedbackGapSlider.minWithFeedback = bestFeedback || 0;
  filters.feedbackGapSlider.maxWithFeedback = worstFeedback || 1;
  const usersOptions = Object.keys(users).sort().map((user) => ({ value: user, label: user }));
  filters.usersOptions = usersOptions;
  filters.stageOptions = stagesValues;

  return filters;
};

const resetFilters = (state) => {
  const filters = state.get('ActivityWorkoutsFilters').toJS();
  filters.stagesFilter = [ALL_STAGES_KEY];
  filters.usersFilter = '';
  filters.feedbackFilter = 'All';
  filters.feedbackGapSlider.filterMin = filters.feedbackGapSlider.min;
  filters.feedbackGapSlider.filterMax = filters.feedbackGapSlider.max;
  return filters;
};

function supervisorDashboardReducer(state = initialState, action) {
  switch (action.type) {
    case 'GET_SUPERVISOR_OVERVIEW_ACTIVITY_WORKOUTS':
      return state.set('loadingOverviewActivityWorkouts', fromJS(true));
    case 'GET_SUPERVISOR_ALL_ACTIVITY_WORKOUTS':
      return state.set('loadingAllActivityWorkouts', fromJS(true));
    case 'SET_SUPERVISOR_OVERVIEW_ACTIVITY_WORKOUTS':
      return state.set('overviewActivityWorkouts', fromJS(action.activities)).set('loadingOverviewActivityWorkouts', fromJS(false));
    case 'SET_SUPERVISOR_ALL_ACTIVITY_WORKOUTS':
      return state.set('allActivityWorkouts', fromJS(action.activities)).set('loadingAllActivityWorkouts', fromJS(false));
    case 'SET_SUPERVISOR_ALL_ACTIVITY_WORKOUTS_FILTER_DEFAULTS':
      return state.set('ActivityWorkoutsFilters', fromJS(getFiltersValues(state, action.activities)));
    case 'SET_SUPERVISOR_ACTIVITIES_STAGE_FILTER':
      return state.setIn(['ActivityWorkoutsFilters', 'stagesFilter'], fromJS(action.stagesFilter));
    case 'SET_SUPERVISOR_ACTIVITIES_USERS_FILTER':
      return state.setIn(['ActivityWorkoutsFilters', 'usersFilter'], fromJS(action.usersFilter));
    case 'SET_SUPERVISOR_ACTIVITIES_FEEDBACK_FILTER':
      return state.setIn(['ActivityWorkoutsFilters', 'feedbackFilter'], fromJS(action.feedbackFilter));
    case 'SET_SUPERVISOR_ACTIVITIES_FEEDBACK_GAP_SLIDER':
      return state.setIn(['ActivityWorkoutsFilters', 'feedbackGapSlider', 'filterMin'], fromJS(action.feedbackGapSlider.filterMin))
        .setIn(['ActivityWorkoutsFilters', 'feedbackGapSlider', 'filterMax'], fromJS(action.feedbackGapSlider.filterMax));
    case 'SET_SUPERVISOR_ACTIVITIES_TIME_RANGE_FILTER':
      return state.setIn(['ActivityWorkoutsFilters', 'timeRangeFilter'], action.timeRange).set('loadingAllActivityWorkouts', fromJS(true));
    case 'RESET_SUPERVISOR_ACTIVITIES_FILTERS':
      return state.set('ActivityWorkoutsFilters', fromJS(resetFilters(state)));
    default:
      return state;
  }
}

export default supervisorDashboardReducer;
