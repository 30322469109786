const insights = {
  insightsPerAnalysis: {
    '2sec': [
      'ISSUE_BREATH_TOO_FAST',
    ],
    '1sec': [
      'ISSUE_GENTLE_OFFSET',
      'ISSUE_VOLCONTROL_DOWN',
    ],
    managed: [],
  },
  paceInsights: {
    '2sec': 1.16,
    '1sec': 1.28,
    managed: 3.76,
  },
};

export const onlyPaceInsights = {
  insightsPerAnalysis: {
    '1sec': ['ISSUE_BREATH_TOO_FAST'],
    managed: [],
  },
  paceInsights: {
    '2sec': 1.16,
  },
};


export const noInsights = {
  insightsPerAnalysis: {
  },
  sylsPerSec: {

  },
};

export const excellentInsights = {
  insightsPerAnalysis: {
    '2sec': [],
    '1sec': [],
    managed: [],
  },
  sylsPerSec: {

  },
};
export default insights;


// patterns
// {
//   "breath": {
//     "key": "ANALYZERSETTINGS.ANALYSISTYPES.BREATH"
//   },
//   "vcontrol": {
//     "key": "ANALYZERSETTINGS.ANALYSISTYPES.VCONTROL"
//   },
//   "2sec": {
//     "key": "ANALYZERSETTINGS.ANALYSISTYPES.2SEC"
//   },
//   "1sec": {
//     "key": "ANALYZERSETTINGS.ANALYSISTYPES.1SEC"
//   },
//   "managed": {
//     "key": "ANALYZERSETTINGS.ANALYSISTYPES.MANAGED"
//   },
//   "managed_changing": {
//     "key": "ANALYZERSETTINGS.ANALYSISTYPES.MANAGED_CHANGING"
//   },
//   "waves": {
//     "key": "ANALYZERSETTINGS.ANALYSISTYPES.WAVES"
//   }
// }
