import _ from 'lodash';
import { ERROR_TYPES } from '@shared/Resources/Monitor/types';
import { iconTypes } from '@services/icons';
import {t} from 'i18next'

export const getTipIcon = (errorId) => {
  switch (errorId) {
    case ERROR_TYPES.tooShortInhalation:
      return iconTypes.INSIGHTS_LENGTH;
    case ERROR_TYPES.nonGentleOnset:
      return iconTypes.INSIGHTS_GENTLE;
    case ERROR_TYPES.volumeControlBeginning:
      return iconTypes.INSIGHTS_VOLUME_CONTROL
    case ERROR_TYPES.notGentleBeginning:
      return iconTypes.INSIGHTS_GENTLE;
    case ERROR_TYPES.excessivePeak:
      return iconTypes.INSIGHTS_VOLUME_CONTROL;
    case ERROR_TYPES.volumeControlEnd:
      return iconTypes.INSIGHTS_VOLUME_CONTROL;
    case ERROR_TYPES.nonGentleOffset:
      return iconTypes.INSIGHTS_GENTLE;
    case ERROR_TYPES.notGentleEnd:
      return iconTypes.INSIGHTS_GENTLE;
    case ERROR_TYPES.tooShortProduction:
      case ERROR_TYPES.tooLongProduction:
        return iconTypes.INSIGHTS_LENGTH
    case ERROR_TYPES.notLoudProduction:
      return iconTypes.INSIGHTS_VOLUME_CONTROL;
    case ERROR_TYPES.notGentleProduction:
      return iconTypes.INSIGHTS_GENTLE
    case 'noPattern':
      return iconTypes.INSIGHTS_PATTERN
    case ERROR_TYPES.nonGentleOnsetOsm:
      return iconTypes.INSIGHTS_GENTLE;
    case ERROR_TYPES.SoftPeakOsm:
    case ERROR_TYPES.softPeak:
      return iconTypes.INSIGHTS_VOLUME_CONTROL
    case ERROR_TYPES.noLinkingOsm:
      return iconTypes.INSIGHTS_TOO_DEEP
    case ERROR_TYPES.tooFlatOsm:
      return iconTypes.INSIGHTS_TOO_FLAT;
    case ERROR_TYPES.tooFastOsm:
          return iconTypes.INSIGHTS_TOO_FAST
    case ERROR_TYPES.tooSlowOsm:
      return iconTypes.INSIGHTS_TOO_SLOW
    case ERROR_TYPES.nonGentleOnsetSmm:
      return iconTypes.INSIGHTS_GENTLE
    case ERROR_TYPES.noLinkingSmm:
      return iconTypes.INSIGHTS_TOO_DEEP
    case ERROR_TYPES.tooFlatSmm:
      return iconTypes.INSIGHTS_TOO_FLAT
    case ERROR_TYPES.tooFastSmm:
      return iconTypes.INSIGHTS_TOO_FAST;
    case ERROR_TYPES.tooSlowSmm:
      return iconTypes.INSIGHTS_TOO_SLOW

  }
}

export const getTipsData = (errorId, severity) => {
  const tip = {
    errorId: '',
    errorName: '',
    iconName: '',
    text: '',
  }
  const errorName = t(`Errors.${errorId}`);
  if (!errorName) {
    console.error('unknown monitor error', errorId);
    return tip
  }
  tip.errorName = errorName
  tip.errorId = errorId
  tip.iconName = getTipIcon(errorId)
  tip.text = t(`Advices.${errorId}_${severity}`);
  return tip;
};

export const getExerciseTips = (insights) =>
  _.flatten(insights.map((type) => type.insights.map((tip) => getTipsData(tip.error, tip.severity))));

export const checkErrorsAndGetNames = (errors) => {
  const parsedErrors = errors.map((error) => {
    if (t(`Errors.${error.type}`)) {
      return ({
        ...error,
        name: t(`Errors.${error.type}`),
      });
    }
    if (!bundledErrorIds.includes(error.type)) {
      console.error('unknown monitor error', error.type);
    }
    return null;
  });
  return _.compact(parsedErrors);
};

export const getPracticeTipsByType = (insights) => {
  const parsedInsights = insights.map((insight) => {
    let severity = 'PERFECT';
    const parsedTips = insight.insights.map((tip) => {
      severity = tip.severity;
      return getTipsData(tip.error, tip.severity);
    });
    return {
      type: insight.type,
      severity,
      tips: parsedTips,
    };
  });
  return parsedInsights;
};

const bundledErrorIds = [
  'sharpRiseBeginning',
  'volumeRiseBeginning',
  'shapeBeginning',
  'shapeEnd',
  'volumeFallEnd',
  'sharpFallEnd',
];
