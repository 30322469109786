import React from 'react';
import {t} from 'i18next'

export const popUpPropsHelper = (onConfirm) => {
  return {
    onConfirm,
    titleText: t('Informative.Confirming.areYouSure'),
    bodyText:  t('Informative.Notifying.endConversationForAll'),
    confirmText: t('Informative.Notifying.endConversation'),
    cancelText: t('Actions.Instance.cancel'),
    hideCloseIcon: true,
    disableBackdrop: true,
  };
};
