import React from 'react';
import { Section, SplitContainer } from '@shared/Resources/Course/Overview/styles';
import Text, { TEXT_COLOR, TEXT_SIZE, TEXT_WEIGHT } from '@components/Text';
import {useTranslation} from "react-i18next";
export const OverviewSectionCourse = ({ isDefault }) => {
    const {t} = useTranslation()
  return (
    <Section>
      <SplitContainer>
        <Text
          size={TEXT_SIZE.H4}
          textColor={TEXT_COLOR.DARK_BLUE}
          weight={TEXT_WEIGHT.BOLD}
        >
            {t('UI.defaultCourse')}
        </Text>
        <Text textColor={TEXT_COLOR.DARK_GRAY} size={TEXT_SIZE.H5}>{isDefault ? 'Yes' : 'No'}</Text>
      </SplitContainer>
    </Section>
  );
};
