/**
 *
 * Builder
 *
 */

import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import _ from 'lodash';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { withTranslation } from 'react-i18next';
import injectReducer from '@utils/injectReducer';
import injectSaga from '@utils/injectSaga';
import { ROUTES } from '@shared/constants';
import ResourcesFactory from '@shared/Resources/resourcesFactory';
import POPUP_TYPES from '@containers/App/containers/PopUp/types';
import { openPopUp } from '@containers/App/containers/PopUp/actions';
import { creatingInitialFormByType } from '@containers/Admin/containers/Builder/forms/creatingInitialFormByType';
import { getWidgets } from '@containers/Admin/containers/Builder/editorObjects/editorWidgets';
import { createNewResource } from '@containers/Admin/shared/ResourcesWrapper/actions';
import { BUILDER_TYPES } from './editorObjects/editorObjectTypes';
import WidgetsSelection from './containers/WidgetsSelection';
import WidgetsBar from './components/WidgetsBar';
import DraggableList from './containers/DraggableList';
import BuilderHeader from './containers/BuilderHeader';
import {
  BuilderContainer,
  HeaderWrapper,
  DetailsContainer,
  ContentWrapper,
  ComponentsContainer,
  LoadingOverlay,
  WidgetWrapper,
} from './styles';
import reducer from './reducer';
import saga from './saga';
import {
  addComponent,
  updateComponentIndex,
  updateField,
  save,
  load,
  loadFailed,
  clearBreadcrumbs,
  edit,
} from './actions';

export class Builder extends React.Component {
  componentDidMount() {
    this.checkUrl(true);
  }

  componentDidUpdate(prevProps) {
    this.checkUrl();
  }

  componentWillUnmount() {
    this.props.dispatchClearBreadcrumbs();
  }

  onItemIndexChange = (itemId, targetIndex) => {
    const { dispatchUpdateComponentIndex, components } = this.props;
    const mandatoryComponentsCount = components.filter(
      (component) => component.payload.mandatory,
    ).length;
    dispatchUpdateComponentIndex(
      itemId,
      targetIndex + mandatoryComponentsCount,
    );
  };

  onEditResource = (type, id) => this.checkDirtyBeforeAction(() => this.navigateToEditResource(type, id));

  onMapToFieldChange = (editorComponent) => ({
    valid,
    value,
    additionalData,
  }) => {
    // TODO: see if this can be removed\ moved to reducer
    editorComponent.payload.valid = valid; // eslint-disable-line
    const pathToUpdate = editorComponent.payload.mapToField;
    this.props.dispatchUpdateField(pathToUpdate, value, valid, additionalData);
  };

  onSave = () => {
    const {
      type, info, components, dispatchSave, dispatchEdit, id,
    } = this.props;

    if (!info.id) {
      dispatchSave(type, info, components);
    } else {
      dispatchEdit(type, info, components, id);
    }
  };

  onPreview = () => {
    console.log('onPreview');
  };

  onBreadcrumbsNavigation = (url) => this.checkDirtyBeforeAction(() => this.props.history.push(url));

  checkDirtyBeforeAction = (wrappedFunction) => {
    const { editState, dispatchOpenPopUp, t } = this.props;
    if (editState.dirty) {
      dispatchOpenPopUp(POPUP_TYPES.CONFIRM, {
        titleText: t('Informative.Negative.workNotSaved'),
        bodyText: t('Informative.Confirming.seeWorkBeforeLeave'),
        confirmText: (
          t('Actions.Confirmations.saveAndGo')
        ),
        cancelText: (
          t('Actions.Confirmations.notSaveAndGo')
        ),
        onConfirm: (saveBeforeNavigation) => {
          if (saveBeforeNavigation) {
            this.onSave();
            wrappedFunction();
          } else {
            wrappedFunction();
          }
        },
      });
    } else {
      wrappedFunction();
    }
  };

  updateInfoField = (field, value) => {
    this.props.dispatchUpdateField(field, value);
  };

  navigateToEditResource = (type, id) => {
    const entityToEdit = this.props.components.find((component) => component.id === id);
    const entityId = _.get(entityToEdit, 'payload.id', null);
    if (entityId) { this.props.history.push(`${ROUTES.BUILDER}/${type}/${entityId}`); }
  };

  checkUrl(isMount = false) {
    const {
      urlToPush,
      loading,
      error,
      type,
      info: {
        id,
      },
      match: {
        params,
      },
      dispatchLoadFailed,
      dispatchLoad,
      creating,
    } = this.props;
    const builderCurrentProps = { type, id };

    if (urlToPush || loading || error) return;
    if (
      !_.keys(BUILDER_TYPES).find(
        (key) => params.type && key.toLowerCase() === params.type.toLowerCase(),
      )
    ) {
      dispatchLoadFailed(`Missing entity type - "${params.type}"`);
    } else if ((isMount || params.id !== builderCurrentProps.id) && !creating) {
      dispatchLoad(params.type.toUpperCase(), params.id);
    }
  }

  widgetSelected = (widget) => {
    this.props.dispatchAddComponent(widget);
  };

  renderComponents = (components, type) => components.map((component) => (
    <div key={component.id}>
      {ResourcesFactory.createComponent(
        component.type,
        component.payload,
        this.onMapToFieldChange(component),
        type,
      )}
    </div>
  ));

  renderLoading = () => (
    <LoadingOverlay>
      {this.props.t('Common.Statuses.loading')}
    </LoadingOverlay>
  );

  render() {
    const {
      components,
      info,
      loading,
      fieldsValidationMap,
      type,
      breadcrumbs,
      editState,
      widgets,
      creating,
    } = this.props;

    const mappedToFieldComponents = [];
    const editableComponents = [];
    const isFormValid = _.values(fieldsValidationMap).find((item) => !item) === undefined;
    const hasWidgets = widgets.length > 0
      || (type !== BUILDER_TYPES.EXERCISE && type !== BUILDER_TYPES.LESSON);

    if (creating) {
      mappedToFieldComponents.push(...creatingInitialFormByType(type));
    }
    components.forEach((component) => {
      if (component?.payload) {
        if (component.payload.mapToField) {
          mappedToFieldComponents.push(component);
        } else {
          editableComponents.push(component);
        }
      }
    });

    return (
      <BuilderContainer>
        {loading && this.renderLoading()}
        <HeaderWrapper>
          <DetailsContainer>
            <BuilderHeader
              isFormValid={isFormValid}
              info={info}
              type={type}
              updateInfoField={this.updateInfoField}
              onSave={this.onSave}
              onPreview={this.onPreview}
              breadcrumbs={breadcrumbs}
              onBreadcrumbsNavigation={this.onBreadcrumbsNavigation}
              editState={editState}
            />
            {hasWidgets && (
              <WidgetWrapper>
                <WidgetsSelection
                  widgets={getWidgets(type)}
                  onSelect={this.widgetSelected}
                />
              </WidgetWrapper>
            )}
          </DetailsContainer>
        </HeaderWrapper>
        <ContentWrapper hasWidgets={hasWidgets}>
          <ComponentsContainer>
            {this.renderComponents(mappedToFieldComponents, type)}
          </ComponentsContainer>
          <ComponentsContainer>
            <DraggableList
              items={editableComponents}
              onIndexChange={this.onItemIndexChange}
              onEditResource={this.onEditResource}
            />
            {hasWidgets && (
              <WidgetsBar
                widgets={getWidgets(type)}
                onSelect={this.widgetSelected}
              />
            )}
          </ComponentsContainer>
        </ContentWrapper>
      </BuilderContainer>
    );
  }
}

Builder.propTypes = {
  type: PropTypes.string,
  error: PropTypes.string,
  loading: PropTypes.bool,
  info: PropTypes.object,
  fieldsValidationMap: PropTypes.object,
  breadcrumbs: PropTypes.array,
  components: PropTypes.array,
  widgets: PropTypes.array,
  dispatchAddComponent: PropTypes.func,
  dispatchUpdateComponentIndex: PropTypes.func,
  dispatchUpdateField: PropTypes.func,
  dispatchSave: PropTypes.func,
  match: PropTypes.object,
  urlToPush: PropTypes.string,
  editState: PropTypes.shape({
    dirty: PropTypes.bool,
    lastSaved: PropTypes.number,
  }),
  history: PropTypes.object,
  dispatchLoad: PropTypes.func,
  dispatchLoadFailed: PropTypes.func,
  dispatchClearBreadcrumbs: PropTypes.func,
  dispatchOpenPopUp: PropTypes.func,
};

const mapStateToProps = (state) => {
  const editState = state.getIn(['builder', 'editState']).toJS();
  const info = state.getIn(['builder', 'info']).toJS();
  const components = state.getIn(['builder', 'components']).toJS();
  const widgets = state.getIn(['builder', 'widgets']).toJS();
  const loading = state.getIn(['builder', 'loading']);
  const fieldsValidationMap = state
    .getIn(['builder', 'fieldsValidationMap'])
    .toJS();
  const type = state.getIn(['builder', 'type']);
  const urlToPush = state.getIn(['builder', 'urlToPush']);
  const error = state.getIn(['builder', 'error']);
  const breadcrumbs = state.getIn(['builder', 'breadcrumbs']).toJS();
  const creating = state.getIn(['builder', 'creating']);
  const id = state.getIn(['builder', 'id']);
  return {
    breadcrumbs,
    type,
    urlToPush,
    info,
    components,
    widgets,
    loading,
    fieldsValidationMap,
    error,
    editState,
    creating,
    id,
  };
};

export function mapDispatchToProps(dispatch) {
  return {
    dispatchClearBreadcrumbs: () => dispatch(clearBreadcrumbs()),
    dispatchAddComponent: ({ type, payload }) => dispatch(addComponent(type, payload)),
    dispatchUpdateComponentIndex: (itemId, targetIndex) => dispatch(updateComponentIndex(itemId, targetIndex)),
    dispatchUpdateField: (fieldName, value, valid, additionalData) => dispatch(updateField(fieldName, value, valid, additionalData)),
    dispatchSave: (type, info, components, id) => dispatch(save(type, info, components, id)),
    dispatchEdit: (type, info, components, id) => dispatch(edit(type, info, components, id)),
    dispatchLoad: (type, id) => dispatch(load(type, id)),
    dispatchLoadFailed: (error) => dispatch(loadFailed(error)),
    dispatchOpenPopUp: (type, props) => dispatch(openPopUp(type, props)),
    dispatchCreateNew: (type) => dispatch(createNewResource(type)),
  };
}

const withReducer = injectReducer({ key: 'builder', reducer });
const withConnect = connect(mapStateToProps, mapDispatchToProps);
const withSaga = injectSaga({ key: 'builder', saga });

export default compose(
  withReducer,
  withConnect,
  withRouter,
  withSaga,
  withTranslation(),
)(Builder);
