import React from 'react';
import PropTypes from 'prop-types';
import Text, { TEXT_SIZE, TEXT_COLOR, TEXT_WEIGHT } from '@components/Text';
import { TextArea } from './styles';
import EditorComponentWrapper from '@shared/Resources/shared/components/BuilderComponentWrapper';

export class EditorText extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      payload: props.payload,
    };
  }

  onChange = (event) => {
    const { value } = event.target;
    const { payload } = this.state;
    payload.text = value;
    this.setState({ payload });
    this.props.submitPayload(payload);
  };

  render() {
    const {
      payload: { text, editorPlaceholder, title },
    } = this.state;
    return (
      <div>
        <Text
          size={TEXT_SIZE.T4}
          textColor={TEXT_COLOR.DARK_BLUE}
          weight={TEXT_WEIGHT.BOLD}
          textTransform='uppercase'
        >
          {title}
        </Text>
        <TextArea
          autoFocus
          value={text}
          placeholder={editorPlaceholder || ''}
          onChange={this.onChange}
          error={text.length === 0}
        />
      </div>
    );
  }
}

EditorText.propTypes = {
  payload: PropTypes.object,
  submitPayload: PropTypes.func,
};

export default EditorComponentWrapper(EditorText);
