import {styled} from "@mui/material";
import { isDesktop } from "@shared/constants/media-queries";
import { getIconsByType, iconTypes } from '@services/icons';
import { Link } from 'react-router-dom';

export const SidebarWrapper = styled('aside')({
    paddingTop: 64,
    minWidth: isDesktop() ? 332 : 220,
  },
  ({ theme }) => ({
    backgroundColor: theme.palette.novotalk.$resourcesListBgColor,
    borderRight: `1px solid ${theme.palette.novotalk.$resourcesListBorderColor}`,
  })
);

export const AvatarWrapper = styled('div')({
  display: 'flex',
  alignItems: 'center',
  paddingLeft: isDesktop() && 28,
}, !isDesktop() && {
    flexDirection: 'column',
    textAlign: 'center',
});

export const Avatar = styled('img')({
  height: 58,
  width: 58,
  borderRadius: '50%',
  objectFit: 'cover',
  filter: "drop-shadow(0px 2px 4px rgba(0, 0, 0, 0.5))",
  border: "2px solid #D26F7B",
  marginRight: 10,
  display: 'block',
});

export const EmptyAvatar = styled('div')({
  height: 58,
  width: 58,
  borderRadius: '50%',
  filter: "drop-shadow(0px 2px 4px rgba(0, 0, 0, 0.5))",
  border: "2px solid #D26F7B",
  marginRight: 10,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  fontSize: 24,
}, ({ theme }) => ({
  backgroundColor: theme.palette.novotalk.$textColorDisabled,
  color: theme.palette.novotalk.$textColorReversed
}))

export const SidebarMenu = styled('div')({
    display: 'flex',
    flexDirection: 'column',
    marginTop: isDesktop() ? 70 : 28,
  },
);

export const StyledArrowRightIcon = styled(
  getIconsByType(iconTypes.FILLED_ARROW_RIGHT)
)({
    fontSize: 24,
    marginRight: 5,
  },
  ({ theme }) => ({
    color: theme.palette.novotalk.$activeColor,
  })
);

export const Tab = styled(Link)({
    textDecoration: 'none',
    display: 'flex',
    justifyContent: 'space-between',
    padding: '15px 0 15px 25px',
  },
  ({ theme, active }) => ({
    color: active ? theme.palette.novotalk.$activeColor : theme.palette.novotalk.$textColorDisabled,
    backgroundColor: active && theme.palette.novotalk.$resourcesListItemSelected,
    cursor: active ? 'default' : 'pointer',
  })
);
