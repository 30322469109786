import { call, put, takeLatest, all, fork, join } from 'redux-saga/effects';
import { createNewPost } from '@services/postsServices';
import { authenticationWrapper } from '@services/errorHandlingService';

import {
  Types,
  setFullActivityData,
  getFullActivityDataError,
  setUserActivities,
  getUserActivitiesError,
  createNewPostError,
  setCreatedPost,
} from './actions';
import { visit } from './services';
import activitiesFixures from './containers/ActivityOverview/__fixtures__/activities';
import activitiStatsFixures from './containers/ActivityStats/__fixtures__/activityData';
import speechGoalStatsFixtures from './containers/ActivitySpeechGoalStats/__fixtures__/speechGoalStats';
import { ACTIVITY_TYPES } from '@shared/Resources/types';
import { getAllActivities, getActivityStats } from '@services/activitiesService';
const useFixtures = process.env.USE_FIXTURES;

export function* callGetFullActivityData(action) {
  yield authenticationWrapper(
    function* codeBlock() {
      // do something with action
      const fixture =
        action.activityType === ACTIVITY_TYPES.SPEECH_GOAL
          ? speechGoalStatsFixtures
          : activitiStatsFixures;

      const res = useFixtures
        ? fixture
        : yield fork(getActivityStats, action.activityId, action.activityType);
      const activityStats = useFixtures ? fixture : yield join(res);
      yield fork(visit, action.activityType, action.activityId);
      activityStats.type = action.activityType;
      yield put(setFullActivityData(activityStats, action.activityId));
    },
    function* errorHandler(error) {
      yield put(getFullActivityDataError(error));
    }
  );
}

export function* callGetUserActivities(action) {
  const result = yield authenticationWrapper(
    function* codeBlock() {
      // do something with action
      const res = useFixtures
        ? activitiesFixures
        : yield fork(getAllActivities, action.userId);
      const activityMonths = useFixtures ? activitiesFixures : yield join(res);
      yield put(setUserActivities(activityMonths, action.userId));
      // returning first activity to chain result
      return activityMonths;
    },
    function* errorHandler(error) {
      yield put(getUserActivitiesError(error));
    }
  );
  return result;
}

export function* callGetUserActivitiesAndFullACtivity(action) {
  yield authenticationWrapper(
    function* codeBlock() {
      // do something with action
      const modifiedAction = action;

      const activityMonths = yield callGetUserActivities(action);
      if (activityMonths.length > 0) {
        const firstActivity = activityMonths[0].activities[0];
        if (firstActivity) {
          if (!action.activityId) {
            modifiedAction.activityId = firstActivity.activityId;
          }
          if (!action.activityType) {
            modifiedAction.activityType = firstActivity.type;
          }

          yield callGetFullActivityData(modifiedAction);
        }
      } else {
        yield put(getUserActivitiesError('No activities found'));
      }
    },
    function* errorHandler(error) {
      yield put(getUserActivitiesError(error));
    }
  );
}

export function* callCreateNewPost(action) {
  yield authenticationWrapper(
    function* codeBlock() {
      const res = useFixtures ? {} : yield fork(createNewPost, action.payload);
      const createdPost = useFixtures ? {} : yield join(res);
      yield put(setCreatedPost(createdPost));
    },
    function* errorHandler(error) {
      yield put(createNewPostError(error));
    }
  );
}

function* watchCallGetFullActivityData() {
  yield takeLatest(Types.ACTIVITY_ITEM_SELECTED, callGetFullActivityData);
}
function* watchCallGetUserActivities() {
  yield takeLatest(Types.GET_USER_ACTIVITIES, callGetUserActivities);
}
function* watchCallGetUserActivitiesAndFullACtivity() {
  yield takeLatest(
    Types.GET_USER_ACTIVITIES_AND_FULL_ACTIVITY_DATA,
    callGetUserActivitiesAndFullACtivity
  );
}
function* watchCallCreateNewPost() {
  yield takeLatest(Types.CREATE_POST, callCreateNewPost);
}

export default function* getPendingDashboardActivityPageDataSaga() {
  yield all([
    fork(watchCallGetFullActivityData),
    fork(watchCallGetUserActivities),
    fork(watchCallGetUserActivitiesAndFullACtivity),
    fork(watchCallCreateNewPost),
  ]);
}
