import {styled} from "@mui/material";
import InputBase from '@components/Input';

export const InputContainer = styled('div')({
  display: 'flex',
  alignItems: 'center',
  position: 'relative',
  height: 44,
  marginRight: 10,
  '&:hover': {
    '& > div:first-of-type': {
      minWidth: 240,
    },
  },
});

export const HiddenInput = styled('div')(
  {
    maxWidth: 600,
    height: 0,
    padding: '0 13px',
    overflow: 'hidden',
    fontSize: 23,
  },
  ({ isEditModeOn, error }) => ({
    minWidth: !isEditModeOn && !error ? 'auto' : 240,
  })
);

export const Input = styled(InputBase)(
  {
    position: 'absolute',
    left: 0,
    top: 0,
    width: '100%',
    height: 44,
    padding: '0 12px',
    fontSize: 23,
    lineHeight: '44px',
    outline: 'none',
  },
  ({ error, theme }) => ({
    color: theme.palette.novotalk.$textColorDarkBlue,
    border: `solid 1px ${error ? theme.palette.novotalk.$errorColor : theme.palette.novotalk.$textColorReversed}`,
    caretColor: theme.palette.novotalk.$activeColor,
    '&:hover': {
      cursor: 'default',
      border: `1px solid ${theme.palette.novotalk.$inputBorderColor}`,
    },
    '&:focus': {
      border: `1px solid ${theme.palette.novotalk.$activeColor}`,
    },
  })
);
