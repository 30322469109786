import gql from 'graphql-tag';
import { graphMutation } from '@services/commService';

export const createTemporaryEntity = async (type) => {
  const mutation = gql`
    mutation {
      entityId: createTemporaryEntity(type:${type})
    }
    `;
  const result = await graphMutation(mutation);
  return result;
};
