import {styled} from "@mui/material";
import { USE_PAID_SYSTEM_VERSION } from '~/shared/constants';

export const Wrapper = styled('div')(
  {
    gridArea: 'CourseMainPane',
    overflow: 'auto',
    zIndex: 1, // for properly shadow displaying
  },
  ({ theme }) => ({
    direction: theme.RTL ? 'rtl' : 'inherit',
    [theme.RTL ? 'marginLeft' : 'marginRight']: 50,
    overflowX: theme.RTL ? 'hidden' : 'inherit',
    paddingLeft: theme.RTL ? 15 : 50,
    paddingRight: theme.RTL ? 35 : 0,
  }),
  ({ withSidebar, theme, isTrial }) => {
    if (withSidebar) {
      return {
        display: 'grid',
        gridTemplateColumns: 'auto 400px',
        gridTemplateRows: `calc(100vh
        - ${theme.palette.novotalk.courseOverviewHeaderHeight}px
        - ${theme.palette.novotalk.courseOverviewProgressBarHeight}px
        - ${isTrial ? theme.palette.novotalk.trialBarHeight : 0}px)`,
      };
    }
    return {
      height: `calc(100vh
      - ${theme.palette.novotalk.courseOverviewHeaderHeight}px
      - ${theme.palette.novotalk.courseOverviewProgressBarHeight}px
      - ${theme.palette.novotalk.navigationBarHeight}px
      - ${USE_PAID_SYSTEM_VERSION && isTrial ? theme.palette.novotalk.trialBarHeight : 0}px)`,
    };
  },
);

export const SkillsWrapper = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'left',
  maxWidth: 1180,
  width: '100%',
  margin: '0 auto',
});
