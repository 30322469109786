import React from 'react';

const icon = () => (<svg width="100%" viewBox="0 0 64 64" version="1.1" xmlns="http://www.w3.org/2000/svg" >
  <g id="Project-Styleguide" stroke="none" strokeWidth="inherit" fill="none" fillRule="evenodd">
    <g id="Illustrations" transform="translate(-164.000000, -452.000000)">
      <rect fill="inherit" x="0" y="0" width="1690" height="630"></rect>
      <g id="02-Length" transform="translate(164.000000, 452.000000)">
        <circle id="Oval-Copy" stroke="#23B7E5" fill="inherit" cx="32" cy="32" r="31.5"></circle>
        <path d="M44.7455844,31.8 L41.9941125,29.0485281 L42.8426407,28.2 L47.0852814,32.4426407 L42.8426407,36.6852814 L41.9941125,35.8367532 L44.8308658,33 L19.2544156,33 L22.0911688,35.8367532 L21.2426407,36.6852814 L17,32.4426407 L21.2426407,28.2 L22.0911688,29.0485281 L19.339697,31.8 L44.7455844,31.8 L44.7455844,31.8 Z M14,27 L15.2,27 L15.2,37.8 L14,37.8 L14,27 Z M48.8,27 L50,27 L50,37.8 L48.8,37.8 L48.8,27 Z" id="Shape" fill="#23B7E5"></path>
      </g>
    </g>
  </g>
</svg>);

export default icon;
