import React, { Component } from 'react';
import PropTypes from 'prop-types';
import ToggleButton from '@components/ToggleButton';
import TextButton from '@components/TextButton';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Dialog from '@mui/material/Dialog';
import _ from 'lodash';
import {
  CustomWrapper,
  CustomSectionWrapper,
  CustomPickerWrapper,
  CustomRangeInput,
  StyledIncreaseRangeIcon,
  StyledDecreaseRangeIcon,
  TextButtonWrapper,
} from './styles';
import { withTranslation } from 'react-i18next';
import { compose } from 'redux';

class CustomRangeDialog extends Component {
  constructor(props) {
    super(props);

    this.customButtons = [
      { key: 'hours', label: this.props.t('Dates.hours') },
      { key: 'days', label: this.props.t('Dates.days') },
      { key: 'weeks', label: this.props.t('Dates.weeks') },
    ];
    this.state = {
      customRangeNumber: 1,
      customRangeUnits: '',
    };
  }

  getCustomRange() {
    const { customRangeUnits, customRangeNumber } = this.state;
    let hours;
    switch (customRangeUnits) {
      case 'hours':
        hours = customRangeNumber;
        break;
      case 'days':
        hours = customRangeNumber * 24;
        break;
      case 'weeks':
        hours = customRangeNumber * 168;
        break;
      default:
        hours = null;
        break;
    }
    if (hours) this.props.setTimeRangeFilter(hours);
    this.closeCustomDialog();
  }

  closeCustomDialog() {
    this.setState({ customRangeNumber: 1, customRangeUnits: '' });
    this.props.onClose();
  }

  decreaseCustomRange() {
    if (this.state.customRangeNumber > 1)
      this.setState({ customRangeNumber: this.state.customRangeNumber - 1 });
  }

  handleCustomRange(event) {
    const customRangeNumber = parseInt(event.target.value, 10);
    if (_.isNumber(customRangeNumber) && customRangeNumber > 0) {
      this.setState({ customRangeNumber });
    }
  }

  increaseCustomRange() {
    this.setState({ customRangeNumber: this.state.customRangeNumber + 1 });
  }

  render() {
    const { isOpen, t } = this.props;

    return (
      <Dialog
        maxWidth='xs'
        onClose={() => this.closeCustomDialog()}
        open={isOpen}
      >
        <DialogTitle>Select Custom Activity Range</DialogTitle>
        <DialogContent>
          <CustomWrapper>
            <CustomPickerWrapper>
              <StyledIncreaseRangeIcon
                onClick={() => this.increaseCustomRange()}
                id='increaseRange'
              />
              <CustomRangeInput
                id='number'
                value={this.state.customRangeNumber}
                onChange={(e) => this.handleCustomRange(e)}
              />
              <StyledDecreaseRangeIcon
                onClick={() => this.decreaseCustomRange()}
                id='decreaseRange'
              />
            </CustomPickerWrapper>
            <CustomSectionWrapper>
              {this.customButtons.map((btn) => (
                <ToggleButton
                  isSelected={this.state.customRangeUnits === btn.key}
                  key={btn.key}
                  id={`custom_${btn.key}`}
                  onClick={() => this.setState({ customRangeUnits: btn.key })}
                >
                  {btn.label}
                </ToggleButton>
              ))}
            </CustomSectionWrapper>
          </CustomWrapper>
        </DialogContent>
        <DialogActions>
          <TextButtonWrapper>
            <TextButton onClick={() => this.closeCustomDialog()} id='cancel'>
              {t('Actions.Instance.cancel')}
            </TextButton>
          </TextButtonWrapper>
          <TextButtonWrapper>
            <TextButton
              onClick={() => this.getCustomRange()}
              disabled={this.state.customRangeUnits === ''}
              id='ok'
            >
              {t('Actions.Simple.ok').toUpperCase()}
            </TextButton>
          </TextButtonWrapper>
        </DialogActions>
      </Dialog>
    );
  }
}

CustomRangeDialog.propTypes = {
  setTimeRangeFilter: PropTypes.func,
  onClose: PropTypes.func,
  isOpen: PropTypes.bool,
};

export default compose(
    withTranslation()
)(CustomRangeDialog)