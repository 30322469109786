import React, { useMemo } from 'react';
import Text from "@components/Text";
import { TitleWithButton, PracticeTitle } from './styles';
import { useSelector } from 'react-redux';
import DismissButton from '@containers/Admin/shared/DismissButton';
import { useHistory } from "react-router-dom";
import { ROUTES } from '@shared/constants';
import { ACTIVITY_TYPES } from '@shared/Resources/types';
import { STATUS_TYPES } from '@containers/Admin/containers/PendingDashboard/shared/SharedComponents';
import {useTranslation} from "react-i18next";

/**
 * Sub title for practices
 */
export const PracticeSubTitle = ({ status, id }) => {
  const { push } = useHistory();
  const { roles } = useSelector((state) => state.getIn(['app', 'authentication', 'user']).toJS());
  const {t} = useTranslation()
  const isClinicalMentor = useMemo(() => roles.includes('20') || roles.includes('10'), [roles]);

  /**
   * Handle navigation to feedback page
   */
  const goToFeedbackPage = () => {
    push(`${ROUTES.ACTIVITY_PAGE}/${ACTIVITY_TYPES.ACTIVITY_PRACTICE}/${id}`);
  }

  if (status === STATUS_TYPES.INCOMPLETE) {
    return (
      <Text size="H4" margin="0 0 29px 0" textTransform="uppercase">
        {t('Informative.Negative.practiceOverview')}
      </Text>
    )
  }
  return (
    <TitleWithButton>
      <PracticeTitle size="H4">
        {t('UI.practiceOverview')}
      </PracticeTitle>
      {isClinicalMentor &&
        <DismissButton onClick={goToFeedbackPage}>
          {t('UI.giveFeedback')}
        </DismissButton>
      }
    </TitleWithButton>
  );
};
