/**
 * Combine all reducers in this file and export the combined reducers.
 */

import { combineReducers } from 'redux-immutable';
import { fromJS } from 'immutable';
import { LOCATION_CHANGE } from 'connected-react-router';

import { connectRouter } from 'connected-react-router/immutable';
import languageProviderReducer from '@containers/LanguageProvider/reducer';
import errorHandlingReducer from '@services/errorHandlingService/reducer';
import appReducer from '@containers/App/reducer';
import builderReducer from '@containers/Admin/containers/Builder/reducer';
import mediaEditReducer from '@containers/App/containers/PopUp/popups/MediaEditor/reducer';
import practiceReducer from '@containers/User/containers/CourseRouter/containers/Practice/reducer';
import nameCheckerReducer from '@containers/Admin/containers/Builder/containers/BuilderHeader/reducer';
import { usersReducer } from '@containers/Admin/containers/UsersList/reducer';
import { userPageReducer } from '@containers/Admin/containers/UserPage/reducer';
import monitorHandlerReducer from '@containers/User/containers/CourseRouter/containers/MonitorHandler/reducer';
import activityPageReducer from '@containers/Admin/shared/ResourcesWrapper/reducer';
import lessonReducer from '@containers/User/containers/CourseRouter/containers/Lesson/reducer';

/*
 * routeReducer
 *
 * The reducer merges route location changes into our immutable state.
 * The change is necessitated by moving to react-router-redux@4
 *
 */

// Initial routing state
const routeInitialState = fromJS({
  location: null,
});

/**
 * Merge route into the global application state
 */
function routeReducer(state = routeInitialState, action) {
  switch (action.type) {
    /* istanbul ignore next */
    case LOCATION_CHANGE:
      return state.merge({
        location: action.payload,
      });
    default:
      return state;
  }
}

/**
 * Creates the main reducer with the dynamically injected ones
 */
export default function createReducer(history, injectedReducers) {
  return combineReducers({
    errors: errorHandlingReducer,
    router: connectRouter(history),
    route: routeReducer,
    language: languageProviderReducer,
    app: appReducer,
    builder: builderReducer,
    editMedia: mediaEditReducer,
    currentPractice: practiceReducer,
    currentLesson: lessonReducer,
    monitorHandler: monitorHandlerReducer,
    nameChecker: nameCheckerReducer,
    usersList: usersReducer,
    userPage: userPageReducer,
    resourceManager: activityPageReducer,
    ...injectedReducers,
  });
}
