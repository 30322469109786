import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Wrapper, Tab } from './styles';


export default class ViewsNav extends Component {
  render() {
    return (
      <Wrapper gridArea={this.props.gridArea}>
        {this.props.views.map((view, index) => (
          <Tab
            className="viewTab"
            key={view}
            selected={index === this.props.selected}
            onClick={() => this.props.onClick(index)}
          >{view}</Tab>
        ))}
      </Wrapper>
    );
  }
}

ViewsNav.propTypes = {
  views: PropTypes.arrayOf(PropTypes.string),
  selected: PropTypes.number,
  onClick: PropTypes.func,
  gridArea: PropTypes.string,
};
