import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import Text, { TEXT_COLOR } from '@components/Text';
import Container from '@components/Container';
import CTAButton from '@components/CTAButton';
import { Wrapper, CurrentLesson } from './styles';
import courseTypes from '../../../../../../helpers/courseTypes';
import {withTranslation} from "react-i18next";

class Sidebar extends Component {
  constructor(props) {
    super(props);
    this.state = {};
    const { paused, skillpartType } = props.locationInCourse;
    this.buttonText = this.props.t(`Actions.Instance.${paused && skillpartType === courseTypes.PRACTICE ? 'resume' : 'start'}`)
  }

  renderSubTitle(partType) {
    const {t} = this.props
    switch (partType.toUpperCase()) {
      case courseTypes.LESSON:
        return t('UI.currentLesson')
      case courseTypes.PRACTICE:
        return t('UI.currentPractice')
      case courseTypes.POLL:
        return t('UI.currentPoll')
      default:
        return null;
    }
  }

  render() {
    const {
      skillName,
      skillpartName,
      skillpartType,
    } = this.props.locationInCourse;

    return (
      <Wrapper>
        <Text
          textColor={TEXT_COLOR.HIGHLIGHTED}
          size='T0'
          weight='bold'
          textTransform='capitalizeFirst'
        >
          {this.props.t('Informative.Notifying.leftOff')}
        </Text>
        <CurrentLesson>
          <Text
            textColor={TEXT_COLOR.DARK}
            size='T4'
            weight='bold'
            textTransform='uppercase'
          >
            {this.renderSubTitle(skillpartType)}
          </Text>
          <Container margin='20' column>
            <Text
              textColor={TEXT_COLOR.HIGHLIGHTED}
              size='T3'
              textTransform='uppercase'
              weight={this.props.i18n.language === 'he' ? 'bold' : 'normal'}
            >
              {skillName}
            </Text>
            <Text
              textColor={TEXT_COLOR.DISABLED}
              size='T3'
              textTransform='capitalizeFirst'
            >
              {skillpartName}
            </Text>
          </Container>
          <CTAButton onClick={this.props.resumeCourse}>
            <Text
              textColor={TEXT_COLOR.REVERSED}
              size='T2'
              weight='bold'
              textTransform='capitalizeFirst'
            >
              {this.buttonText}
            </Text>
          </CTAButton>
        </CurrentLesson>
      </Wrapper>
    );
  }
}

Sidebar.propTypes = {
  locationInCourse: PropTypes.object,
  resumeCourse: PropTypes.func,
};


export default compose(
    withTranslation()
)(Sidebar);
