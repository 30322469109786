export const TYPES = {
  CHECK_FOR_WAITING_VIDEO_CALL: 'CHECK_FOR_WAITING_VIDEO_CALL',
  SET_PENDING_VIDEO_CHAT_INFO: 'SET_PENDING_VIDEO_CHAT_INFO',
};

export const checkForWaitingVideoCall = () => ({
  type: TYPES.CHECK_FOR_WAITING_VIDEO_CALL,
});

export const setPendingVideoChatInfo = (pendingVideoChatInfo) => ({
  type: TYPES.SET_PENDING_VIDEO_CHAT_INFO,
  pendingVideoChatInfo,
});
