import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'redux';
import StagesFilter from './components/StagesFilter';
import UsersFilter from './components/UsersFilter';
import FeedbackFilter from './components/FeedbackFilter';
import DateFilter from './containers/DateFilter';

import {
  setSuperviserActivitiesStageFilter,
  setSuperviserActivitiesUsersFilter,
  setSuperviserActivitiesFeedbackFilter,
  setSuperviserActivitiesFeedbackGapSlider,
  setSuperviserActivitiesTimeRangeFilter,
  resetSupervisorActivitiesFilters,
} from '../../actions';
import { withTranslation } from 'react-i18next';
import { Wrapper, Filter, FilterTitle, ResetFilters } from './styles';

class ActivitiesFilter extends Component {
  resetFilters() {
    this.props.resetFilters();
    this.props.setTimeRangeFilter(48);
  }

  renderDateFilter() {
    const { timeRangeFilter, setTimeRangeFilter, loadingAllActivityWorkouts, t } = this.props;
    return (
      <Filter>
        <FilterTitle>
          {t('UI.activityRange')}
        </FilterTitle>
        <DateFilter
          timeRangeFilter={timeRangeFilter}
          setTimeRangeFilter={setTimeRangeFilter}
          loading={loadingAllActivityWorkouts}
        />
      </Filter>
    );
  }
  renderUsersFilter() {
    const { usersOptions, setUsersFilter, t } = this.props;
    return (
      <Filter>
        <FilterTitle>
          {t('Common.Roles.users')}
        </FilterTitle>
        <UsersFilter
          usersOptions={usersOptions}
          setUsersFilter={setUsersFilter}
        />
      </Filter>
    );
  }
  renderStageFilter() {
    const { stagesFilter, stageOptions, setStageFilter, t } = this.props;
    return (
      <Filter>
        <FilterTitle>
          {t('Common.UI.stage')}
        </FilterTitle>
        <StagesFilter
          stagesFilter={stagesFilter}
          stageOptions={stageOptions}
          setStageFilter={setStageFilter}
        />
      </Filter>
    );
  }

  renderFeedbackFilter() {
    const { feedbackGapSlider, feedbackFilter, setFeedbackFilter, setFeedbackSlider } = this.props;
    return (
      <FeedbackFilter
        maxValue={feedbackGapSlider.max}
        minValue={feedbackGapSlider.min}
        minWithFeedback={feedbackGapSlider.minWithFeedback}
        maxWithFeedback={feedbackGapSlider.maxWithFeedback}
        filterMin={feedbackGapSlider.filterMin}
        filterMax={feedbackGapSlider.filterMax}
        feedbackFilter={feedbackFilter}
        setFeedbackFilter={setFeedbackFilter}
        setFeedbackSlider={setFeedbackSlider}
      />
    );
  }

  render() {
    const {t} = this.props
    return (
      <Wrapper gridArea={this.props.gridArea}>
        <ResetFilters onClick={() => this.resetFilters()}>
          {t('Actions.Multiple.resetAllFilters')}
        </ResetFilters>
        {this.renderDateFilter()}
        {this.renderUsersFilter()}
        {this.renderStageFilter()}
        {this.renderFeedbackFilter()}
      </Wrapper>
    );
  }
}

ActivitiesFilter.propTypes = {

  gridArea: PropTypes.string,
  usersOptions: PropTypes.arrayOf(PropTypes.object),
  stagesFilter: PropTypes.array,
  stageOptions: PropTypes.array,
  feedbackGapSlider: PropTypes.object,
  setStageFilter: PropTypes.func,
  setUsersFilter: PropTypes.func,
  setFeedbackFilter: PropTypes.func,
  feedbackFilter: PropTypes.string,
  setFeedbackSlider: PropTypes.func,
  timeRangeFilter: PropTypes.number,
  setTimeRangeFilter: PropTypes.func,
  loadingAllActivityWorkouts: PropTypes.bool,
  resetFilters: PropTypes.func,
};

const mapStateToProps = (state) => {
  const filters = state.getIn(['supervisorDashboard', 'ActivityWorkoutsFilters']).toJS();
  const loadingAllActivityWorkouts = state.getIn(['supervisorDashboard', 'loadingAllActivityWorkouts']);
  const { usersOptions, stagesFilter, stageOptions, feedbackGapSlider, feedbackFilter, timeRangeFilter } = filters;

  return { usersOptions, feedbackGapSlider, stagesFilter, stageOptions, feedbackFilter, timeRangeFilter, loadingAllActivityWorkouts };
};

export function mapDispatchToProps(dispatch) {
  return {
    setStageFilter: (stages) => dispatch(setSuperviserActivitiesStageFilter(stages)),
    setUsersFilter: (usersFilter) => dispatch(setSuperviserActivitiesUsersFilter(usersFilter)),
    setFeedbackFilter: (feedbackFilter) => dispatch(setSuperviserActivitiesFeedbackFilter(feedbackFilter)),
    setFeedbackSlider: (feedbackFilter) => dispatch(setSuperviserActivitiesFeedbackGapSlider(feedbackFilter)),
    setTimeRangeFilter: (timeRange) => dispatch(setSuperviserActivitiesTimeRangeFilter(timeRange)),
    resetFilters: () => dispatch(resetSupervisorActivitiesFilters()),
  };
}


const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default compose(
  withConnect,
    withTranslation()
)(ActivitiesFilter);
