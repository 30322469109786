import subHours from 'date-fns/sub_hours';
import subMinutes from 'date-fns/sub_minutes';
import getTime from 'date-fns/get_time';

export const mockOverviewActivities = [
  {
    id: 1,
    activityLevel: 21,
    user: 'Rachel Green',
    stage: 1,
    type: 'Emotional',
    lastExercise: subMinutes(subHours(new Date(), 40), 25),
    feedbackTime: subMinutes(subHours(new Date(), 10), 35),
  },
  {
    id: 2,
    activityLevel: 20,
    user: 'Rachel Green',
    stage: 5,
    type: 'Emotional',
    lastExercise: subMinutes(subHours(new Date(), 40), 10),
    feedbackTime: subMinutes(subHours(new Date(), 20), 11),
  },
  {
    id: 3,
    activityLevel: 3,
    user: 'Rachel Green',
    stage: 2,
    type: 'Emotional',
    lastExercise: subMinutes(subHours(new Date(), 40), 34),
    feedbackTime: subMinutes(subHours(new Date(), 30), 22),
  },
  {
    id: 4,
    activityLevel: 33,
    user: 'Chandler Bing',
    stage: 3,
    type: 'Clinical',
    lastExercise: subHours(new Date(), 25),
  },
  {
    id: 5,
    activityLevel: 5,
    user: 'Ross Geller',
    stage: 4,
    type: 'Clinical',
    lastExercise: subHours(new Date(), 39),
  },
  {
    id: 6,
    activityLevel: 17,
    user: 'Rachel Green',
    stage: 1,
    type: 'Clinical',
    lastExercise: subHours(new Date(), 5),
  },
];

export const mockAllActivities = [
  {
    id: 1,
    activityLevel: 21,
    user: 'Rachel Green',
    stage: 1,
    type: 'Emotional',
    lastExercise: getTime(subMinutes(subHours(new Date(), 40), 25)),
    feedbackTime: getTime(subMinutes(subHours(new Date(), 10), 35)),
    feedback: 1,
    feedbackGap: 30,
    displayFull: true,
  },
  {
    id: 2,
    activityLevel: 21,
    user: 'Rachel Green',
    stage: 1,
    type: 'Emotional',
    lastExercise: getTime(subMinutes(subHours(new Date(), 40), 10)),
    feedbackTime: getTime(subMinutes(subHours(new Date(), 20), 11)),
    feedback: 1,
    feedbackGap: 12,
    displayFull: true,
  },
  {
    id: 3,
    activityLevel: 21,
    user: 'Rachel Green',
    stage: 1,
    type: 'Emotional',
    lastExercise: getTime(subMinutes(subHours(new Date(), 40), 34)),
    feedbackTime: getTime(subMinutes(subHours(new Date(), 30), 22)),
    feedback: 1,
    feedbackGap: 10,
    displayFull: true,
  },
  {
    id: 3,
    activityLevel: 21,
    user: 'Rachel Green',
    stage: 1,
    type: 'Emotional',
    lastExercise: getTime(subMinutes(subHours(new Date(), 40), 34)),
    feedbackTime: getTime(subMinutes(subHours(new Date(), 40), 22)),
    feedback: 1,
    feedbackGap: 0,
    displayFull: true,
  },
  {
    id: 3,
    activityLevel: 21,
    user: 'Rachel Green',
    stage: 1,
    type: 'Emotional',
    lastExercise: getTime(subMinutes(subHours(new Date(), 40), 34)),
    feedbackTime: getTime(subMinutes(subHours(new Date(), 41), 50)),
    feedback: 1,
    feedbackGap: 0,
    displayFull: true,
  },
  {
    id: 4,
    activityLevel: 21,
    user: 'Rachel Green',
    stage: 15,
    type: 'Clinical',
    lastExercise: getTime(subHours(new Date(), 25)),
    feedback: 0,
    feedbackGap: 25,
    displayFull: true,
  },
  {
    id: 5,
    activityLevel: 21,
    user: 'Rachel Green',
    stage: 15,
    type: 'Clinical',
    lastExercise: getTime(subHours(new Date(), 19)),
    feedback: 0,
    feedbackGap: 19,
    displayFull: true,
  },
  {
    id: 6,
    activityLevel: 21,
    user: 'Rachel Green',
    stage: 15,
    type: 'Clinical',
    lastExercise: getTime(subHours(new Date(), 5)),
    feedback: 0,
    feedbackGap: 5,
    displayFull: true,
  },
  {
    id: 7,
    activityLevel: 21,
    user: 'Rachel Green',
    stage: 1,
    type: 'Emotional',
    lastExercise: getTime(subHours(new Date(), 26)),
    feedback: 0,
    feedbackGap: 26,
  },
  {
    id: 8,
    activityLevel: 21,
    user: 'Rachel Green',
    stage: 1,
    type: 'Emotional',
    lastExercise: getTime(subHours(new Date(), 19)),
    feedback: 0,
    feedbackGap: 19,
  },
  {
    id: 9,
    activityLevel: 21,
    user: 'Rachel Green',
    stage: 1,
    type: 'Emotional',
    lastExercise: getTime(subHours(new Date(), 10)),
    feedback: 0,
    feedbackGap: 10,
  },
];
