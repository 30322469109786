import {
  useEffect, useState, useMemo,
} from 'react';
import { useSelector, useDispatch, RootStateOrAny } from 'react-redux';
import {
  MenuItem, Select, FormControl, SelectChangeEvent,
} from '@mui/material';
import { attachUserPageCourse } from '@containers/Admin/containers/UserPage/actions';
import { formatCoursesToOptions } from './helpers';
import { UserCourse } from './types';
import { SELECTOR_WIDTH } from './consts';

interface SelectCourseProps {
  isClinicalMentor: boolean
}

/**
* Select for course
* @param {boolean} isClinicalMentor - Value for checking current user is supervisor
* @returns {React.ReactElement} React element
*/
export const SelectCourse = ({ isClinicalMentor }: SelectCourseProps) => {
  const dispatch = useDispatch();
  const { userCourse, userData: { id } } = useSelector((state: RootStateOrAny) => state.get('userPage').toJS()); // FIXME replace to typing store
  const { resources } = useSelector((state: RootStateOrAny) => state.get('resourceManager').toJS()); // FIXME replace to typing store
  const [value, setValue] = useState<UserCourse | null>(null);
  const options = useMemo(() => formatCoursesToOptions(resources, userCourse?.name), [resources, userCourse?.name]);
  /**
  * Send courses to backend by userID
  */
  const selectCourse = (event:SelectChangeEvent<UserCourse>): void => {
    dispatch(attachUserPageCourse(event.target.value, id));
  };

  useEffect(() => {
    setValue(userCourse ? { value: userCourse.id, label: userCourse.name } : null);
  }, [userCourse]);

  if (isClinicalMentor) return <div>{userCourse?.name ?? 'No course'}</div>;

  return (
    <div>
      <FormControl sx={{ width: SELECTOR_WIDTH }} size="small">
        <Select
          value={value as any}
          onChange={selectCourse}
          renderValue={() => value?.label}
        >
          {options.map((option) => (
            <MenuItem key={option.value} value={option.value} selected={value?.value === option.value}>
              {option.label}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </div>
  );
};
