import React, { Component } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import Text, { TEXT_COLOR } from '@components/Text';
import {
  Wrapper,
  RadioOffIcon,
  RadioOnIcon,
  AnswerWrapper,
  SelectWrapper,
  IconWrapper,
  StyledToggleButton,
  RadioButtonsWrapper,
} from './styles';
import { StyledQuestionIcon } from '../../styles';
import PollQuestionTitle from '../PollQuestionTitle';

export class RatingQuestion extends Component {
  constructor(props) {
    super(props);

    this.state = {
      selectedAnswer: null,
    };
    this.debouncedUpdateAnswer = _.debounce(
      (answer) => props.updateAnswer(answer),
      1000,
    );
  }

  componentWillUnmount() {
    this.debouncedUpdateAnswer.cancel();
  }

  handleClick(selectedAnswer) {
    if (selectedAnswer !== this.state.selectedAnswer) {
      this.setState({ selectedAnswer });
      this.debouncedUpdateAnswer(selectedAnswer);
    }
  }

  generateAnswers(config) {
    const min = config?.minimum ? config?.minimum : {
      value: 1,
      text: 'Poor',
    };
    const max = config?.maximum ? config?.maximum : {
      value: 5,
      text: 'Excellent',
    };
    const isOptional = config ? _.get(config, 'isOptional') : {
      text: 'Non relevant',
    };
    const generatedAnswers = [];

    for (let { value } = min; value <= max.value; value += 1) {
      let text = '';
      if (value === min.value) text = min.text;
      else if (value === max.value) text = max.text;

      const answer = {
        value,
        text,
      };
      generatedAnswers.push(answer);
    }
    if (isOptional) {
      const answer = {
        value: 'SKIP',
        text: isOptional.text,
      };
      generatedAnswers.push(answer);
    }
    return generatedAnswers;
  }

  renderRatingAnswer(answer) {
    const { selectedAnswer } = this.state;
    const { isDisabled, userAnswer } = this.props;

    let icon;
    if (answer.value === (selectedAnswer || (isDisabled && userAnswer))) {
      icon = <RadioOnIcon isDisabled={isDisabled} />;
    } else {
      icon = <RadioOffIcon isDisabled={isDisabled} />;
    }
    return (
      <AnswerWrapper
        isDisabled={isDisabled}
        key={answer.value}
        onClick={() => this.handleClick(answer.value)}
        aria-selected={
          answer.value === selectedAnswer || answer.value === userAnswer
        }
        aria-disabled={isDisabled}
      >
        <IconWrapper isDisabled={isDisabled}>{icon}</IconWrapper>
        <Text textColor={TEXT_COLOR.DARK_BLUE} size="H4">
          {answer.value}
        </Text>
        <Text textColor={TEXT_COLOR.DARK_BLUE} size="H4">
          {answer.text}
        </Text>
      </AnswerWrapper>
    );
  }

  renderSkipAnswer(answers) {
    const skipOption = _.find(answers, { value: 'SKIP' });
    if (!skipOption) return null;
    const { selectedAnswer } = this.state;
    const { isDisabled, userAnswer } = this.props;

    return (
      <StyledToggleButton
        onClick={() => this.handleClick(skipOption.value)}
        isSelected={
          skipOption.value === selectedAnswer || skipOption.value === userAnswer
        }
        disabled={isDisabled}
      >
        {skipOption.text}
      </StyledToggleButton>
    );
  }

  render() {
    const {
      questionIndex,
      questionText,
      config,
      checkAnswers,
      isDisabled,
      required,
    } = this.props;
    const { selectedAnswer } = this.state;
    const error = checkAnswers && !selectedAnswer && !isDisabled;
    const titleProps = {
      questionIndex,
      questionText,
      error,
      required,
    };

    const generatedAnswers = this.generateAnswers(config);
    return (
      <Wrapper>
        <StyledQuestionIcon />
        <PollQuestionTitle {...titleProps} />
        <SelectWrapper id={`rating_${questionIndex}`}>
          <RadioButtonsWrapper length={generatedAnswers.length}>
            {generatedAnswers.map((answer) => {
              if (answer.value !== 'SKIP') {
                return this.renderRatingAnswer(answer);
              }
              return null;
            })}
          </RadioButtonsWrapper>
          {this.renderSkipAnswer(generatedAnswers)}
        </SelectWrapper>
      </Wrapper>
    );
  }
}

RatingQuestion.propTypes = {
  isDisabled: PropTypes.bool,
  questionIndex: PropTypes.number,
  questionText: PropTypes.string,
  config: PropTypes.object,
  updateAnswer: PropTypes.func,
  checkAnswers: PropTypes.bool,
  userAnswer: PropTypes.string,
};

export default RatingQuestion;
