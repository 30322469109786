import { useEffect, useCallback, useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import { generatePath, useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Pagination } from '@components/Pagination';
import { ROUTES, TOAST_AUTO_CLOSE_TIME } from '@shared/constants';
import {
  Wrapper, PaginationWrapper, MainTitlePage, TotalCountUser,
} from './styles';
import {
  fetchUsers, putUsersOffset, fetchCountUsers, putUsersPageNumber,
} from './actions';
import { getTableColumn, userListOffset } from './helpers';
import { USER_PAGE_TYPES } from '../UserPage/consts';
import {
  FIELD_FOR_SORT, WIDTH_COLUMN, ROLES, DEFAULT_PAGE_NUMBER, DEFAULT_OFFSET,
} from './consts';
import { UserSortableTable } from './components/UserSortableTable';

/**
 * Component for users list
 * Mounting: fetch users list
 */
export const UsersList = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { push } = useHistory();
  const { roles } = useSelector((state) => state.getIn(['app', 'authentication', 'user']).toJS());
  const {
    loading, error, count, list, pageNumber,
  } = useSelector((state) => state.getIn(['usersList']).toJS());

  const isSupervisor = roles.includes(ROLES.SUPERVISOR.NUMBER);
  const widthColumnByRole = `${isSupervisor ? WIDTH_COLUMN.SUPERVISOR : WIDTH_COLUMN.MENTOR}%`;

  /**
   * Handle click on table row
   * @param {number} id - id user
   */
  const goToUserPage = ({ id }) => {
    push(generatePath(ROUTES.USERS.PAGE, { id, pageType: USER_PAGE_TYPES.INFORMATION }));
  };

  /**
   * Set page number, offset user list and then fetch users
   * @param {number} page - Selected page value
   */
  const changePage = useCallback((page) => {
    dispatch(putUsersPageNumber(page));
    dispatch(putUsersOffset(userListOffset * (page - 1)));
    dispatch(fetchUsers([ROLES.USER.TITLE], true));
  }, [dispatch]);

  useEffect(() => {
    dispatch(fetchUsers([ROLES.USER.TITLE], true));
    dispatch(fetchCountUsers([ROLES.USER.TITLE]));
    return () => {
      dispatch(putUsersPageNumber(DEFAULT_PAGE_NUMBER));
      dispatch(putUsersOffset(DEFAULT_OFFSET));
    };
  }, []);

  useEffect(() => {
    if (error) {
      toast.error(t('Informative.Negative.usersNotLoad'), {
        autoClose: TOAST_AUTO_CLOSE_TIME,
        position: 'bottom-right',
      });
    }
  }, [error]);

  return (
    <Wrapper>
      <MainTitlePage>
        {t('Common.Roles.users')}
      </MainTitlePage>
      <UserSortableTable />
      <PaginationWrapper>
        <TotalCountUser>
          {t('UI.totalUsers', {
            visibleUsers: list.length,
            totalCount: count,
          })}
        </TotalCountUser>
        {count > userListOffset && (
          <Pagination
            count={Math.ceil(count / userListOffset)}
            page={pageNumber}
            defaultPage={DEFAULT_PAGE_NUMBER}
            onChange={changePage}
          />
        )}
      </PaginationWrapper>
    </Wrapper>
  );
};
