import React from 'react';
import {
    ControlsIconWrapperVideoChat, ControlsIconWrapper,
    StyledImage
} from '../../styles';
import { toggleShowMonitorSettings } from '@shared/VideoChat/actions';

import MonitorSettingsIcon from './monitor-settings-icon.svg';
import { useDispatch, useSelector } from 'react-redux';

export const MonitorSettingsBtn = () => {
    const { monitorSettings } = useSelector((state) =>
        state.getIn(['videoChat']).toJS()
    );

    const dispatch = useDispatch();

    const onShowMonitorSettings = () => {
        dispatch(toggleShowMonitorSettings(!monitorSettings))
    }

    return !monitorSettings ?
        (<ControlsIconWrapperVideoChat onClick={onShowMonitorSettings}>
            <StyledImage src={MonitorSettingsIcon} />
        </ControlsIconWrapperVideoChat >)
        :
        (<ControlsIconWrapper onClick={onShowMonitorSettings}>
            <StyledImage src={MonitorSettingsIcon} />
        </ControlsIconWrapper>)
};
