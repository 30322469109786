import {
  takeLatest, put, all, fork, call,
} from 'redux-saga/effects';
import { signIn } from '@services/authenticationService';
import { ROUTES } from '@shared/constants';
import { getConversationsAction } from '@containers/App/containers/Messaging/actions';
import { changeLocale } from '@containers/LanguageProvider/actions';
import { Types, signInSuccess, signInFail } from './actions';
import { setRedirectUrl } from '../../../components/Redirector/actions';

function* callPostSignIn(action) {
  try {
    const { email, password } = action.payload;
    const user = yield call(signIn, email, password);

    if (user) {
      const {
        id,
        token,
        firstName,
        lastName,
        avatar,
        resetPasswordToken,
        experimentalFeatures,
        roles,
        refreshToken,
        locale,
      } = user;
      const payload = {
        id,
        token: resetPasswordToken ? null : token,
        refreshToken,
        firstName,
        lastName,
        avatar,
        resetPasswordToken,
        experimentalFeatures,
        roles,
        locale,
      };
      yield put(signInSuccess(payload));
      yield put(getConversationsAction());
      yield put(changeLocale(locale.toLowerCase()));
      if (resetPasswordToken) {
        const url = `${ROUTES.RESET_PASSWORD}/${refreshToken}`;
        const stateToPass = { reason: 'passwordExpired' };
        yield put(setRedirectUrl(url, stateToPass));
      }
    }
  } catch (error) {
    yield put(signInFail(error.message));
  }
}

function* watchCallPostSignIn() {
  yield takeLatest(Types.SIGN_IN, callPostSignIn);
}

export default function* watchLoginSignInActions() {
  yield all([fork(watchCallPostSignIn)]);
}
