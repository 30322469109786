import { fromJS } from 'immutable';
import { TYPES } from './actions';
import { RESOURCES } from '@shared/Resources/types';
import { toast } from 'react-toastify';
import { TOAST_AUTO_CLOSE_TIME } from '@shared/constants';
import React from 'react';

export const paginationLimit = 20

// The initial state of the Component
export const initialState = fromJS({
  type: undefined,
  overview: {
    loading: false,
    resource: undefined,
  },
  popup: {
    loading: false,
    resource: undefined,
  },
  loadingResources: true,
  resources: [],
  errorData: false,
  isTemplate: true,
  offset: 0,
  isLastPage: false,
  searchText: ''
});

const emptyResource = fromJS({ loading: false, resource: undefined });

function activityPageReducer(state = initialState, action) {
  switch (action.type) {
    case TYPES.GET_RESOURCES:
      const actionType = action.payload.type
        const {offset: resourcesOffset, type: resourcesType} = state.toJS()
      return state
        .set('loadingResources', true)
        .set('type', action.payload.type)
        .set('overview', emptyResource)
        .set('popup', emptyResource)
        .set('offset', actionType === resourcesType ? resourcesOffset : 0)
    case TYPES.SET_RESOURCES:
      const { resources, type, loadType } = action.payload;
      const {offset, resources: oldResources, searchText} = state.toJS()
      const currentOffset = searchText && resources.length <=20 ? resources.length :  offset + resources.length
      let resourcesList
      if(loadType === 'scroll') {
        resourcesList = [...oldResources, ...resources]
      }else if(searchText) {
        resourcesList = resources
      } else if(!searchText) {
        resourcesList = resources
      }

      return state
        .set('loadingResources', false)
        .set('type', type)
        .set('resources', fromJS(resourcesList))
        .set('offset', currentOffset)
        .set('isLastPage', resources.length < paginationLimit)
    case TYPES.GET_OVERVIEW: {
      const { source, type } = action.payload;
      const overviewProp = source || 'overview';
      const newOverview = state.get(overviewProp).toJS();
      newOverview.loading = true;
      const updatedState = state.set(overviewProp, fromJS(newOverview));
      if (overviewProp === 'overview') {
        return updatedState.set('type', type) && state.set('errorData', false);
      }
      return updatedState;
    }
    case TYPES.SET_OVERVIEW: {
      const baseOverview = action.payload.source || 'overview';
      const { type, resourceOverview } = action.payload;
      if (type === RESOURCES.MEDIA) {
        resourceOverview.tags = resourceOverview.tags.map((tag) => ({ "label": tag, "value": tag }));
      }
      const updatedState = state.set(baseOverview, fromJS({
        loading: false,
        resource: resourceOverview,
      }));
      if (baseOverview === 'overview') {
        return updatedState.set('type', type);
      }
      return updatedState;
    }
    case TYPES.SET_EXPANDED_CHILD: {
      const { expadedChildOverview, source } = action.payload;
      const overviewPath = source || 'overview';
      const overview = state.get(overviewPath).toJS();
      const replacteItemIndex = overview.resource.children.findIndex((child) => child.id === expadedChildOverview.id);
      const expandedChild = { ...overview.resource.children[replacteItemIndex], ...expadedChildOverview };
      overview.resource.children.splice(replacteItemIndex, 1, expandedChild);
      return state.set(overviewPath, fromJS(overview));
    }
    case TYPES.DELETE_SUCCESS: {
      const { id } = action.payload;
      const resources = state.get('resources').toJS();
      const replacteItemIndex = resources.findIndex((child) => child.id === id);
      resources.splice(replacteItemIndex, 1);
      return state
        .set('resources', fromJS(resources))
        .set('overview', emptyResource);
    }
    case TYPES.CHANGE_FILTER_TYPE: {
      return state
          .set('isTemplate', !action.payload)
          .set('offset', 0)
          .set('resources', [])
    }
    case TYPES.DATA_ERROR: {
      state.set('errorData', true);
      toast.error('Error', {
        autoClose: TOAST_AUTO_CLOSE_TIME,
        position: 'bottom-right',
      });
    }
    case TYPES.CLEAR_RESOURCES_DATA: {
      return state
          .set('resources', [])
          .set('offset', 0)
          .set('isLastPage', false)
          .set('searchText', '')
    }
    case TYPES.RESET_OFFSET: {
      return state
          .set('offset', 0)
    }
    case TYPES.SET_SEARCH_TEXT: {
      const searchText = action.payload
      return state
          .set('searchText', searchText)
    }
    default:
      return state;
  }
}

export default activityPageReducer;
