/* eslint-disable no-irregular-whitespace */
/* eslint-disable react/no-unescaped-entities */
import React from 'react';
import { Link } from 'react-router-dom';
import { ROUTES } from '@shared/constants';
import Text, { TEXT_COLOR, TEXT_SIZE } from '@components/Text';
import Container from '@components/Container';

const TermsOfUse = () => (
  <Container column margin="20px">
    <Container column margin="0 0 40px 0">
      <Text textColor={TEXT_COLOR.NORMAL} weight="bold" size={TEXT_SIZE.T1} >
            Website Terms of Use
      </Text>
      <Text textColor={TEXT_COLOR.NORMAL} size={TEXT_SIZE.T3} >
            (Date of Last Revision: March 05, 2019)
      </Text>
    </Container>
    <Container column alignItems="flex-start">
      <Text textColor={TEXT_COLOR.NORMAL} margin="0 0 10px 0">
            The website Novotalk.com, and associated mobile application (hereinafter referred to as: <b>“the Site”</b>) is owned and operated Novotalk Ltd. (hereinafter referred to as: <b>“Novotalk”</b>, <b>“we”</b> or <b>“us”</b>).  The Site provides information regarding services and other content offered by Novotalk, including Novotalk’s unique teletherapy tutorial platform that utilizes technology designed to teach, implement, practice and preserve fluency shaping techniques and overcome speech disorders and impediments and the ability for customers to access, utilize and purchase the same or contact Novotalk (hereinafter referred to as: the <b>“Services”</b>).
      </Text>
      <Text textColor={TEXT_COLOR.NORMAL} margin="0 0 10px 0">
            This Terms of Use agreement (hereinafter referred to as: the <b>“Terms of Use”</b>) and the <Link to={ROUTES.PRIVACY_POLICY}>Privacy Policy</Link> constitute a binding agreement between you and Novotalk.  Please read carefully through all sections of these Terms of Use; if you are under the age of 18, please read these Terms of Use with a parent or other guardian.  Your access to and use of the Site and the Services is subject to these Terms of Use and all applicable laws.  By registering with, creating an account, or accessing and/or otherwise using the Site or the Services, you agree to be bound by these Terms of Use.  If you do not agree to these Terms of Use, then you may not use the Site or the Services.  These Terms of Use may be changed by us from time to time.  We may provide you notice of such changes when they are material, such notice may be given by posting on the Site, by electronic or conventional mail or by any other means by which you obtain notice of the changes or updates.  Please review the posted Terms of Use on a regular basis as your use of the Site and the Services will be governed by the then-current Terms of Use and Privacy Policy.
      </Text>
      <Text textColor={TEXT_COLOR.NORMAL} margin="0 0 10px 0">
        <b>User Registration</b>
        <br />
           You may be required to register in order to access certain features of the Site and the Services.  If so, you will provide a username (in the form of an email address) and a password through the Site’s registration process.  You are responsible for keeping your username and password confidential.  You are responsible for all activities and purchases (whether by you or by others) that occur under your password and account.  You agree to notify us immediately of any unauthorized uses of your account or any other breach of security.  Novotalk cannot and will not be liable for any loss or damage arising from your failure to keep your account information protected.
      </Text>
      <Text textColor={TEXT_COLOR.NORMAL} margin="0 0 10px 0">
          By registering for an account on the Site and the Services, you assert that your information is true and accurate to the best of your knowledge.  You agree not to submit false information such as name, address, state or country of residence, email, social media addresses, and/or telephone number when registering on the Site and the Services.  By registering with the Site and the Services, you consent to receive periodic communication from Novotalk and its affiliates, employees, agents, representatives and any third-party service provider of Novotalk regarding any inquiry or for obtaining the Services.  For more information on how Novotalk may use the personal information you provide during registration or otherwise provide to Novotalk, please see the <Link to={ROUTES.PRIVACY_POLICY}>Privacy Policy</Link>.
      </Text>
      <Text textColor={TEXT_COLOR.NORMAL} margin="0 0 10px 0">
        <b>Proprietary Rights</b> <br />
          You may not use the contents of the Site or the Services in any manner or for any purpose that would constitute infringement of Novotalk’s, its licensors’, or the Site’s and Services’ other users’ intellectual property rights.  Novotalk or its licensors own all trademarks and service marks appearing on the Site and the Services.  The unauthorized use or misuse of these trademarks and service marks is prohibited.
      </Text>
      <Text textColor={TEXT_COLOR.NORMAL} margin="0 0 10px 0">
        <b>Your Communications to the Site and the Services</b> <br />
          Except to the extent your content or communications to Novotalk include Personal Data (as defined in the Privacy Policy), by forwarding any content or communications to Novotalk through the Site or the Services, or by other electronic means, you thereby grant Novotalk a perpetual, royalty-free, world-wide, irrevocable, nonexclusive license to use, reproduce, modify, adapt, publish, translate, create derivative works from, redistribute, and display such content and communications in any form for the purposes of providing the Services and any purpose tangentially related to the Services.  No compensation will be paid to you with respect to Novotalk’s or its sublicensees’ use of your communications.  By providing or submitting content, you represent and warrant that you own or otherwise control all of the rights to your submitted content and communications as described in this section including, without limitation, all the rights necessary for you to submit the content and communications and grant the license above.
      </Text>
      <Text textColor={TEXT_COLOR.NORMAL} margin="0 0 10px 0">
        <b>Prohibited Activities</b> <br />
          You may use the Site and the Services only for lawful purposes and in accordance with these Terms of Use. You agree not to use the Site or the Services:<br />
        <ul>
          <li>
              In any way that violates any applicable federal, state, local, or international law or regulation (including, without limitation, any laws regarding personal privacy and the export of data or software to and from the United States or other countries);
          </li>
          <li>
              To submit offensive content including, without limitation, language that is crude, obscene, blatant, violent, offensive, racist, or discriminatory, obscene references, obscene images, threatening or harassing messages, discriminatory actions, messages, or images, and defamatory statements;
          </li>
          <li>
              For the purpose of exploiting, harming, or attempting to exploit or harm minors in any way by exposing them to inappropriate content, asking for personally identifiable information, or otherwise;
          </li>
          <li>
              To transmit, or procure the sending of, any advertising or promotional material, including any “junk mail,” “chain letter,” “spam,” or any other similar solicitation;
          </li>
          <li>
              To impersonate or attempt to impersonate Novotalk, a Novotalk employee, another user, or any other person or entity (including, without limitation, by using email addresses or screen names associated with any of the foregoing); and
          </li>
          <li>
              To engage in any other conduct that restricts or inhibits anyone's use or enjoyment of the Site or the Services, or which, as determined by us, may harm Novotalk or users of the Site or the Services, or expose them to liability.
          </li>
        </ul>
            Additionally, you agree not to: <br />
        <ul>
          <li>
              Use the Site and Services in any manner that could disable, overburden, damage, or impair the Site or the Services or interfere with any other party’s use of the Site or the Services, including a denial-of-service attack or interfering with user’s ability to engage in real time activities through the Site or the Services;
          </li>
          <li>
              Use any robot, spider, or other automatic device, process, or means to access the Site or the Services for any purpose, including monitoring or copying any of the material on the Site or the Services;
          </li>
          <li>
              Use any manual process to monitor or copy any of the material on the Site or the Services or for any other unauthorized purpose without our prior written consent;
          </li>
          <li>
              Use any device, software, or routine that interferes with the proper working of the Site or the Services;
          </li>
          <li>
              Introduce any viruses, Trojan horses, worms, logic bombs, or other material that is malicious or technologically harmful;
          </li>
          <li>
              Attempt to gain unauthorized access to, interfere with, damage, or disrupt any parts of the Site or the Services, any server on which the Site or the Services is stored, or any server, computer, or database connected to the Site or the Services;
          </li>
          <li>
              And otherwise attempt to interfere with the proper working of the Site or the Services.
          </li>

        </ul>
      </Text>
      <Text textColor={TEXT_COLOR.NORMAL} margin="0 0 10px 0">
          Although Novotalk will not, as an ordinary practice, proactively monitor your activities for violations of these Terms of Use, there may be instances in which Novotalk, through its routine surveillance finds violations of these Terms of Use.  In these cases, Novotalk reserves the right to take whatever action it deems appropriate in its sole discretion.
      </Text>
      <Text textColor={TEXT_COLOR.NORMAL} margin="0 0 10px 0">
        <b>Network Security</b> <br />
          It is your responsibility to ensure the security of your network and the equipment that connects to the Services.  You are required to take all necessary steps to secure and manage the use of the Services in such a way to assure that network abuse and/or fraudulent activity is prevented.  You are also responsible for any fraudulent activity that may occur due to your failure to secure your network and the Services.
      </Text>
      <Text textColor={TEXT_COLOR.NORMAL} margin="0 0 10px 0">
        <b>Federal and State Laws</b> <br />
            The Site is operated from the United States.  When using the Site, on the Site, or when using any content provided by Novotalk, you must obey all applicable federal, state and local laws.
      </Text>
      <Text textColor={TEXT_COLOR.NORMAL} margin="0 0 10px 0">
        <b>Disclaimer of Warranties</b> <br />
            TO THE FULLEST EXTENT PROVIDED BY LAW AND EXCEPT AS OTHERWISE PROVIDED HEREIN, THE INFORMATION, PRODUCTS AND SERVICES OFFERED ON OR THROUGH THE SITE AND THE SERVICES AND ANY REFERENCED THIRD-PARTY SITE ARE PROVIDED “AS IS” AND WITHOUT WARRANTIES OF ANY KIND, EITHER EXPRESS OR IMPLIED.  THIS INCLUDES ANY LISTING OF ANY THIRD-PARTY GOODS OR SERVICE PROVIDER, AND THAT THIRD-PARTY PROVIDER’S WEBSITE, INCLUDED IN THE SITE OR THE SERVICES.  ANY THIRD-PARTY GOODS OR SERVICE PROVIDERS ARE SUPPLIED AS A CONVENIENCE TO YOU AND LISTING DOES NOT CONSTITUTE SPONSORSHIP, AFFILIATION, PARTNERSHIP, OR ENDORSEMENT.  TO THE FULLEST EXTENT PROVIDED BY LAW, NOVOTALK DISCLAIMS ALL EXPRESS AND IMPLIED WARRANTIES, INCLUDING BUT NOT LIMITED TO IMPLIED WARRANTY OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, TITLE, AND NON-INFRINGEMENT.<br /><br />

            TO THE FULLEST EXTENT PROVIDED BY LAW, NOVOTALK DOES NOT WARRANT OR MAKE ANY REPRESENTATIONS REGARDING THE USE OR THE RESULTS OF THE USE OF THE SITE, THE SERVICES, CONTENT, OR OTHER POSTED MATERIAL ON THE SITE AND THE SERVICES IN TERMS OF ITS CORRECTNESS, ACCURACY, TIMELINESS, RELIABILITY OR OTHERWISE.<br /><br />

            BY PROVIDING THE SERVICES ON THE SITE, NOVOTALK DOES NOT IN ANY WAY PROMISE THAT THE SITE OR THE SERVICES WILL REMAIN AVAILABLE TO YOU.  NOVOTALK IS ENTITLED TO TERMINATE ALL OR PART OF ANY OF THE SITE AT ANY TIME, IN ITS SOLE DISCRETION WITHOUT NOTICE TO YOU.
      </Text>
      <Text textColor={TEXT_COLOR.NORMAL} margin="0 0 10px 0">
        <b>Limitation of Liability</b> <br />
            THE LIABILITY OF NOVOTALK AND ITS AFFILIATES, EMPLOYEES, AGENTS, REPRESENTATIVES AND THIRD-PARTY CONTRACTORS AND SERVICE PROVIDERS WITH RESPECT TO ANY AND ALL CLAIMS ARISING OUT OF YOUR USE OF THE SITE AND THE SERVICES, OR THE CONTENT OR SERVICES OBTAINED THROUGH THE SITE OR THE SERVICES, WHETHER BASED ON WARRANTY, CONTRACT, NEGLIGENCE, STRICT LIABILITY OR OTHERWISE, SHALL NOT EXCEED, IN THE AGGREGATE, THE TOTAL AMOUNT OF FEES PAID BY YOU FOR RENDERED SERVICES DURING THE IMMEDIATELY PRECEDING SIX (6) MONTHS.<br /><br />

            IN NO EVENT WILL NOVOTALK BE LIABLE TO YOU OR ANY PARTY FOR ANY DIRECT, INDIRECT, SPECIAL OR OTHER CONSEQUENTIAL DAMAGES FOR ANY USE OF THE SITE OR THE SERVICES, OR ON ANY OTHER HYPERLINKED WEBSITE, INCLUDING, WITHOUT LIMITATION, ANY LOST PROFITS, BUSINESS INTERRUPTION, LOSS OF PROGRAMS OR OTHER DATA OR OTHERWISE, EVEN IF NOVOTALK IS EXPRESSLY ADVISED OF THE POSSIBILITY OF SUCH DAMAGES.<br /><br />

            YOU HEREBY ACKNOWLEDGE THAT SOME OF THE TECHNIQUES INCLUDE THE PRACTICE OF CERTAIN BREATHING MODES, INCLUDING “DIAPHRAGMATIC BREATHING,” WHICH, IN SOME CASES, MAY CAUSE DIZZINESS OR WEAKNESS, AND YOU WAIVE ANY CLAIMS AGAINST AND ANY LIABILITY OF NOVOTALK OR ITS EMPLOYEES IN CONNECTION WITH OR REGARDING THESE SYMPTOMS.
      </Text>
      <Text textColor={TEXT_COLOR.NORMAL} margin="0 0 10px 0">
        <b>Indemnification</b><br />
          You agree to indemnify, defend and hold harmless Novotalk and its affiliates, employees, agents, representatives and third-party service providers, for any and all claims, demands, actions, liability, fines, penalties and expenses that may arise from any of your acts through the use of the Site or the Services.  Such acts may include, without limitation, submissions, unauthorized use of material obtained through the Site or the Services, and that breach the terms of these Terms of Use.
      </Text>
      <Text textColor={TEXT_COLOR.NORMAL} margin="0 0 10px 0">
        <b>Medical Disclaimer</b><br />
          YOU ACKNOWLEDGE THAT THE INFORMATION ON THE SITE IS PROVIDED “AS-IS” FOR GENERAL INFORMATION ONLY.  THE INFORMATION CONTAINED ON THIS SITE IS NOT INTENDED, AND SHALL NOT BE CONSTRUED AS, MEDICAL ADVICE, DIAGNOSIS OR TREATMENT AND IS NOT A SUBSTITUTE FOR CONSULTATIONS WITH QUALIFIED HEALTH PROFESSIONALS.  <br /><br />

          THE SITE IS NOT INTENDED OR DESIGNED TO PROVIDE A RESPONSE IN A MEDICAL EMERGENCY.  IF THIS IS A MEDICAL EMERGENCY, PLEASE IMMEDIATELY CALL EMERGENCY SERVICES (911) TO GET PROMPT MEDICAL ATTENTION.  DO NOT RELY ON THE SITE OR ELECTRONIC COMMUNICATIONS FOR ASSISTANCE IN REGARD TO YOUR EMERGENCY MEDICAL NEEDS.
      </Text>
      <Text textColor={TEXT_COLOR.NORMAL} margin="0 0 10px 0">
        <b>Third-Party Links</b><br /><br />
          The Site or the Services may contain links to other third-party websites.  Such third-party websites are maintained by persons or organizations over which Novotalk exercises no control.  Novotalk expressly disclaims any responsibility for the content or results from your use of such third-party websites.
      </Text>
      <Text textColor={TEXT_COLOR.NORMAL} margin="0 0 10px 0">
        <b>Copyright Complaints</b><br />
        Novotalk respects the intellectual property of others.  If you believe that your work has been copied in a way that constitutes copyright infringement, please provide Novotalk’s copyright agent with the following information:
        <ol type="a">
          <li>
              An electronic or physical signature of the person authorized to act on behalf of the owner of the copyright interest;
          </li>
          <li>
              Description of the copyrighted work that you claim has been infringed;
          </li>
          <li>
              The location on the Site of the material that you claim is infringing;
          </li>
          <li>
              Your address, telephone number and email address;
          </li>
          <li>
              A statement that your claim of infringement is based on a good faith belief; and
          </li>
          <li>
              A statement made under penalty of perjury that the information you have provided is accurate and that you are the copyright owner or authorized to act on the copyright owner’s behalf.
          </li>
        </ol>
          Novotalk’s copyright agent for notice of claims of copyright infringement on the Site can be reached as follows: <br />

          Novotalk Ltd.<br />
          Attn: Copyright Agent<br />
        <Text textColor={TEXT_COLOR.HIGHLIGHTED} margin="0 0 10px 0">
          [ENTER ADDRESS]<br />
          [ENTER ADDRESS]<br />
          [ENTER ADDRESS]<br />
        </Text>
          support@novotalk.com<br />
      </Text>
      <Text textColor={TEXT_COLOR.NORMAL} margin="0 0 10px 0">
        <b>Miscellaneous Provisions</b><br /><br />

        <b><i>Severability</i></b>.  If any term or provision in these Terms of Use is found to be void, against public policy, or unenforceable by a court of competent jurisdiction and such finding or order becomes final with all appeals exhausted, then the offending provision shall be deemed modified to the extent necessary to make it valid and enforceable.  If the offending provision cannot be so modified, then the same shall be deemed stricken from these Terms of Use in its entirety and the remainder of these Terms of Use shall survive with the said offending provision eliminated.

        <i><b>Website Availability</b></i>.  Novotalk cannot guarantee the Site OR THE SERVICES will be available 100% of the time Because public networks, such as the internet, occasionally experience disruptions.  Although NOVOTALK strives to provide the most reliable webSite AND SERVICES reasonably possible, interruptions and delays in accessing the Site AND THE SERVICES are unavoidable and NOVOTALK disclaims any liability for damages resulting from such problems.

        <i><b>Typographical Errors.</b></i>  Information on the Site or the Services may contain technical inaccuracies or typographical errors.  We attempt to make the Site’s and the Service’s postings as accurate as possible, but Novotalk does not warrant the content of the Site or the Services is accurate, complete, reliable, current, or error-free.

        <i><b>Headings.</b></i>  Condition and section headings are for convenience of reference only and shall not affect the interpretation of these Terms of Use.

        <b><i>Controlling Law and Venue.</i></b>  It is understood and agreed that all the construction and interpretation of these Terms of Use and the relationship between the parties shall at all times and in all respects be governed by the internal laws of the State of Israel, without giving effect to the conflict of laws provisions thereof.  Venue of any action brought to enforce or relating to these Terms of Use or arising out of the relationship between the parties shall be brought exclusively in the courts of in Tel Aviv-Jaffa.
      </Text>
      <Text textColor={TEXT_COLOR.NORMAL} margin="0 0 10px 0">
        <b>Questions</b><br />
          If you have any questions or comments about these Terms of Use or this Site, please contact us by email at support@novotalk.com. You also may contact us in writing at: <br /><br />
          Novotalk Ltd.<br />
          Attn: Zohar Beeri<br />
          747 Third Ave, 2nd Floor<br />
          New York, NY 10017<br />
          support@novotalk.com<br />
      </Text>
    </Container>
  </Container>
);

export default TermsOfUse;
