import { Pagination as PaginationView } from '@mui/material';
import { PaginationItem } from '@mui/lab';

const FIRST_PAGE = 1;

export const Pagination = ({
  count,
  page,
  defaultPage,
  onChange,
}) => (
  <PaginationView
    count={count}
    defaultPage={defaultPage}
    onChange={(_, pageNum) => onChange(pageNum)}
    page={page}
    siblingCount={page === FIRST_PAGE ? -1 : 1}
    renderItem={(item) =>
      <PaginationItem {...{...item, page: item.page?.toString().padStart(2, '0')}} />
    }
  />
)
