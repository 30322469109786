import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { Wrapper } from './styles';

class Collapse extends Component {
  constructor(props) {
    super(props);
    this.state = {
      height: 0,
    };
    this.container = React.createRef();
    this.observer = null;
  }

  componentDidMount() {
    this.setHeight();
    // DOM observer uses for proper displaying of content by its change, while div-wrapper has hardcoded width
    this.observer = new MutationObserver(this.setHeight);
    this.observer.observe(this.container.current, {
      childList: true,
      subtree: true,
    });
    Array.prototype.forEach.call(
      this.container.current.getElementsByTagName('video'),
      (item) => item.addEventListener('loadeddata', this.setHeight)
    );
  }

  componentWillUnmount() {
    this.observer.disconnect();
  }

  setHeight = () => {
    this.setState({ height: this.container.current.scrollHeight });
  }

  render() {
    const { isCollapsed, children } = this.props;
    const { height } = this.state;
    return (
      <Wrapper isCollapsed={isCollapsed} height={height}>
        <div ref={this.container}>
          {children}
        </div>
      </Wrapper>
    );
  }
}

Collapse.propTypes = {
  children: PropTypes.any,
  isCollapsed: PropTypes.bool,
};

export default Collapse;
