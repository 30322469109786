import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import {
  ShownOverview,
  InstructionsSection,
  SubEntityContainer,
  getStyledResourceIcon,
  EditIcon,
  EditButton,
} from './styles';
import Text, { TEXT_SIZE, TEXT_COLOR } from '@components/Text';
import { SKILL, PRACTICE, POLL, LESSON } from '../../types';
import { ENABLE_EDIT_NESTED_RESOURCES, ROUTES } from '@shared/constants';
import EditorComponentWrapper from '@shared/Resources/shared/components/BuilderComponentWrapper';
import EntitySelectionOptions from '@shared/Resources/shared/components/EntitySelectionOptions';
import {sliceText} from "@utils/textHelpers";

const maxDescriptionLength = 150
const maxNameLength = 120

export class EditorSkill extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      payload: props.payload,
    };
  }

  onItemSelect = (payload) => {
    this.setState({ payload });
    this.props.submitPayload(payload);
  };

  getNestedEntities = (children, type) =>
    children
      .filter((child) => child.type === type)
      .map(this.renderSkillComponent);

  editEntity = ({ type, _id }) => () => {
    this.props.history.push(`${ROUTES.BUILDER}/${type}/${_id}`);
  };

  renderSkillComponent = ({ payload }) => {
    const Icon = getStyledResourceIcon(payload.type);
    return (
      <SubEntityContainer key={payload._id}>
        <div>
          <Icon />
          <Text
            size={TEXT_SIZE.T3}
            textColor={TEXT_COLOR.DARK_BLUE}
            className={payload.type.toLowerCase()}
          >
            {sliceText(payload.name, maxNameLength, true)}
          </Text>
        </div>
        {ENABLE_EDIT_NESTED_RESOURCES &&
          <EditButton onClick={this.editEntity(payload)}>
            <EditIcon />
          </EditButton>
        }
      </SubEntityContainer>
    );
  };

  renderEntity = (payload) => (
    <ShownOverview className='entityOverview'>
      <InstructionsSection className='instructionsWrapper'>
        <Text
          size={TEXT_SIZE.H4}
          textColor={TEXT_COLOR.DARK_BLUE}
          className='entityDescription'
        >
          {sliceText(payload.texts.description, maxDescriptionLength, true)}
        </Text>
      </InstructionsSection>
      <div className='nestedEntities'>
        {this.getNestedEntities(payload.children, PRACTICE)}
        {this.getNestedEntities(payload.children, LESSON)}
        {this.getNestedEntities(payload.children, POLL)}
      </div>
    </ShownOverview>
  );

  render() {
    const { payload } = this.state;
    return (
      <div>
        {payload && payload._id || payload.id ? (
          this.renderEntity(payload)
        ) : (
          <EntitySelectionOptions
            entityType={SKILL}
            onItemSelect={this.onItemSelect}
          />
        )}
      </div>
    );
  }
}

EditorSkill.propTypes = {
  history: PropTypes.object,
  payload: PropTypes.object,
  submitPayload: PropTypes.func,
};

export default EditorComponentWrapper(withRouter(EditorSkill));
