import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'redux';
import _ from 'lodash';
import injectReducer from '@utils/injectReducer';
import injectSaga from '@utils/injectSaga';
import ViewsNav from './components/ViewsNav';
import OverviewSummary from './components/OverviewSummary';
import Activities from './containers/Activities';
import ActivitiesFilter from './containers/ActivitiesFilter';
import {
  getSuperviserOverviewActivityWorkouts,
  getSuperviserAllActivityWorkouts,
} from './actions';
import reducer from './reducer';
import saga from './saga';
import { DashboardWrapper } from './styles';
import {withTranslation} from "react-i18next";

const OVERVIEW_TAB = 0;
const ALL_ACTIVITIES_TAB = 1;

class SupervisorDashboard extends Component {
  constructor(props) {
    super(props);
    this.gridAreaNames = {
      ViewHeader: 'viewHeader',
      ViewsNav: 'viewsNav',
      View: 'view',
    };
    this.views = [
        this.props.t('Common.UI.overview'),
        this.props.t('UI.allActivities')
    ];
    this.state = {
      view: OVERVIEW_TAB,
    };
    this.selectView = this.selectView.bind(this);
    this.overviewPollingInterval = null;
  }

  componentWillMount() {
    this.props.getAllActivityWorkouts(this.props.allActivitiesRange);
    this.props.getOverviewActivityWorkouts();
    this.overviewPollingInterval = setInterval(
      this.props.getOverviewActivityWorkouts,
      60000
    );
  }

  componentWillUnmount() {
    if (this.overviewPollingInterval)
      clearInterval(this.overviewPollingInterval);
  }

  selectView(view) {
    this.setState(() => ({
      view,
    }));
  }

  checkActivityStage(activity, filters) {
    if (filters.stagesFilter.includes('All stages')) return true;
    return filters.stagesFilter.includes(activity.stage);
  }

  checkActivityUser(activity, filters) {
    if (filters.usersFilter) {
      return activity.user === filters.usersFilter;
    }
    return true;
  }

  checkActivityFeedback(activity, filters) {
    switch (filters.feedbackFilter) {
      case 'All':
        return true;
      case 'Yes':
        return activity.feedbackTime;
      case 'No':
        return !activity.feedbackTime;
      default:
        return true;
    }
  }

  checkActivityFeedbackGap(activity, filters) {
    if (
      activity.feedbackGap &&
      activity.feedbackGap <= filters.feedbackGapSlider.filterMax &&
      activity.feedbackGap >= filters.feedbackGapSlider.filterMin
    ) {
      return true;
    }
    return false;
  }

  filterAllActivitiesByFilter(activities) {
    const filters = this.props.allActivitiesFilters;
    const filteredActivities = activities.filter(
      (activity) =>
        this.checkActivityUser(activity, filters) &&
        this.checkActivityFeedback(activity, filters) &&
        this.checkActivityFeedbackGap(activity, filters) &&
        this.checkActivityStage(activity, filters)
    );

    return filteredActivities;
  }

  isLoading() {
    const {
      loadingAllActivityWorkouts,
      loadingOverviewActivityWorkouts,
    } = this.props;
    const { view } = this.state;
    if (view === OVERVIEW_TAB && loadingOverviewActivityWorkouts) return true;
    else if (view === ALL_ACTIVITIES_TAB && loadingAllActivityWorkouts)
      return true;
    return false;
  }

  renderOverviewSummary(activities) {
    const longestWaitingUser = _.minBy(activities, 'lastExercise');
    const userWaitingLongest = longestWaitingUser
      ? longestWaitingUser.user
      : '';
    const activitiesNeedFeedback = activities.length;
    const avgClinicalResponse = '--';
    const avgEmotionalResponse = '--';

    const metrics = [
      {
        key: 'userWaitingLongest',
        value: userWaitingLongest,
        hours: false,
        red: false,
      },
      {
        key: 'activitiesNeedFeedback',
        value: activitiesNeedFeedback,
        hours: false,
        red: false,
      },
      {
        key: 'avgClinicalResponse',
        value: avgClinicalResponse,
        hours: true,
        red: avgClinicalResponse > 24,
      },
      {
        key: 'avgEmotionalResponse',
        value: avgEmotionalResponse,
        hours: true,
        red: avgEmotionalResponse > 24,
      },
    ];

    return (
      <OverviewSummary
        gridArea={this.gridAreaNames.ViewHeader}
        metrics={metrics}
      />
    );
  }

  render() {
    const { view } = this.state;

    let activities;
    let viewHeader;
    switch (view) {
      case OVERVIEW_TAB:
        activities = this.props.overviewActivities;
        viewHeader = this.renderOverviewSummary(activities);
        break;
      case ALL_ACTIVITIES_TAB:
        activities = this.filterAllActivitiesByFilter(this.props.allActivities);
        viewHeader = (
          <ActivitiesFilter gridArea={this.gridAreaNames.ViewHeader} />
        );
        break;
      default:
        activities = [];
        viewHeader = null;
        break;
    }

    return (
      <DashboardWrapper {...this.gridAreaNames}>
        <ViewsNav
          gridArea={this.gridAreaNames.ViewsNav}
          views={this.views}
          onClick={this.selectView}
          selected={view}
        />
        {viewHeader}
        <Activities
          gridArea={this.gridAreaNames.View}
          overview={view === OVERVIEW_TAB}
          activities={activities}
          loading={this.isLoading()}
        />
      </DashboardWrapper>
    );
  }
}

SupervisorDashboard.propTypes = {
  overviewActivities: PropTypes.arrayOf(PropTypes.object),
  allActivities: PropTypes.arrayOf(PropTypes.object),
  getAllActivityWorkouts: PropTypes.func,
  getOverviewActivityWorkouts: PropTypes.func,
  allActivitiesRange: PropTypes.number,
  allActivitiesFilters: PropTypes.object,
  loadingAllActivityWorkouts: PropTypes.bool,
  loadingOverviewActivityWorkouts: PropTypes.bool,
};

export const mapStateToProps = (state) => {
  const location = state.getIn(['route', 'location', 'pathname']);
  const loadingOverviewActivityWorkouts = state.getIn([
    'supervisorDashboard',
    'loadingOverviewActivityWorkouts',
  ]);
  const loadingAllActivityWorkouts = state.getIn([
    'supervisorDashboard',
    'loadingAllActivityWorkouts',
  ]);
  const overviewActivities = state
    .getIn(['supervisorDashboard', 'overviewActivityWorkouts'])
    .toJS();
  const allActivities = state
    .getIn(['supervisorDashboard', 'allActivityWorkouts'])
    .toJS();
  const allActivitiesRange = state.getIn([
    'supervisorDashboard',
    'ActivityWorkoutsFilters',
    'timeRangeFilter',
  ]);
  const allActivitiesFilters = state
    .getIn(['supervisorDashboard', 'ActivityWorkoutsFilters'])
    .toJS();
  return {
    location,
    loadingOverviewActivityWorkouts,
    loadingAllActivityWorkouts,
    overviewActivities,
    allActivities,
    allActivitiesRange,
    allActivitiesFilters,
  };
};

export function mapDispatchToProps(dispatch) {
  return {
    getAllActivityWorkouts: (dateRange) =>
      dispatch(getSuperviserAllActivityWorkouts(dateRange)),
    getOverviewActivityWorkouts: () =>
      dispatch(getSuperviserOverviewActivityWorkouts()),
  };
}

const withConnect = connect(mapStateToProps, mapDispatchToProps);
const withReducer = injectReducer({ key: 'supervisorDashboard', reducer });
const withSaga = injectSaga({ key: 'supervisorDashboard', saga });

export default compose(
  withReducer,
  withSaga,
  withConnect,
  withTranslation()
)(SupervisorDashboard);
