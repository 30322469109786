import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Text, { TEXT_SIZE, TEXT_COLOR } from '@components/Text';
import {
  Wrapper,
  StyledInput,
  UsersListWrapper,
  ListHeader,
  ListItem,
  StyledAvatar,
} from './styles';
import {StyledPersonIcon} from "@containers/App/containers/Messaging/components/MessagesList/styles";
import { compose } from 'redux';
import { withTranslation } from 'react-i18next';

export class UserSearch extends Component {
  constructor(props) {
    super(props);
    this.state = {
      value: '',
    };
  }

  componentDidMount() {
    const { users, selectUser } = this.props;
    const filteredUsers = this.getFilteredUsers(users);
    if (filteredUsers.length === 1) {
      selectUser(filteredUsers[0]);
    }
  }

  getFilteredUsers = () => {
    const { users } = this.props;
    const { value } = this.state;
    // TODO: make sure, that server doesn't filter date by the field 'self' by itself
    const otherUsers = users.filter(({ self }) => !self);
    const request = value.trim();
    if (request.length === 0) return otherUsers;
    return otherUsers.filter(({ fullName }) =>
      fullName.toLowerCase().includes(request.toLowerCase())
    );
  };

  handleInput = (ev) => {
    const { value } = ev.target;
    this.setState({ value });
  };

  handleKeyPress = ({ key }, filteredUsers) => {
    const { selectUser } = this.props;
    if (key === 'Enter' && filteredUsers.length === 1) {
      selectUser(filteredUsers[0]);
    }
  };

  renderUsersList = (filteredUsers) => {
    const {selectUser} = this.props;
    const { value } = this.state;
    if (!value || !filteredUsers.length) return null;
    return (
      <UsersListWrapper>
        <ListHeader>
          <Text
            size={TEXT_SIZE.H5}
            textColor={TEXT_COLOR.DISABLED_LIGHT}
            weight='bold'
          >
            {this.props.t('Common.Roles.users')}
          </Text>
        </ListHeader>
        {filteredUsers.map((user) => {
          const { id, fullName, avatar } = user;
          return (
            <ListItem key={id} onClick={() => selectUser(user)}>
              {avatar ? <StyledAvatar src={avatar} /> : <StyledPersonIcon />}
              <Text size={TEXT_SIZE.H4} textColor={TEXT_COLOR.NORMAL}>
                {fullName}
              </Text>
            </ListItem>
          );
        })}
      </UsersListWrapper>
    );
  };

  render() {
    const { value } = this.state;
    const filteredUsers = this.getFilteredUsers();

    return (
      <Wrapper>
        <Text size={TEXT_SIZE.T3} textColor={TEXT_COLOR.NORMAL}>
          {this.props.t('Common.UI.to')}
        </Text>
        <StyledInput
          id='messaging-search-input'
          autoFocus
          autoComplete='off'
          value={value}
          onChange={this.handleInput}
          onKeyPress={(ev) => this.handleKeyPress(ev, filteredUsers)}
          placeholder={this.props.t('Common.Placeholders.searchUser')}
        />
        {this.renderUsersList(filteredUsers)}
      </Wrapper>
    );
  }
}

UserSearch.propTypes = {
  users: PropTypes.array,
  selectUser: PropTypes.func,
};

export default compose(
    withTranslation()
)(UserSearch);
