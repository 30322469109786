import { AvatarUserWithName } from '../../../avatar-user-with-name';
import Text from "@components/Text";
import { MentorWrapper } from './styles';
import {useTranslation} from "react-i18next";

export const MentorActivityLog = ({
  mentor,
  currentUserId,
}) => {
    const {t} = useTranslation()
    return (
        <div>
            <Text size="H4" margin="0 0 29px 0" textTransform="uppercase">
                {t('Informative.Labels.mentor')}
            </Text>
            {mentor &&
                <MentorWrapper>
                    <AvatarUserWithName
                        url={mentor?.avatar_url}
                        fullName={mentor?.fullName}
                        participantId={mentor.id}
                        currentUserId={currentUserId}
                    />
                </MentorWrapper>
            }
        </div>
    );
}
