import {styled} from "@mui/material";
import isPropValid from '@emotion/is-prop-valid';
import OverviewButton from '@components/OverviewButton';
import Text from '@components/Text';
import { getResponsiveCSS } from '@utils/cssHelpers';
import {
  getIconsByType,
  iconTypes,
  getIconByResourceType,
} from '@services/icons';

export const ListItem = styled('div')({
  display: 'grid',
  alignItems: 'center',
  minHeight: 54,
},
({ theme, isUserPageProgram, isFirstElement }) => ({
  gridTemplate: `'${(isUserPageProgram && !isFirstElement) ? 'status' : ''} icon name info buttons'`,
  gridTemplateColumns: `${(isUserPageProgram && !isFirstElement) ? '26px' : ''} 26px auto max-content max-content`,
  paddingLeft: isUserPageProgram && !isFirstElement && 20,
  paddingRight: 20,
  '&:hover': {
    backgroundColor: isUserPageProgram && !isFirstElement && theme.palette.novotalk.$resourcesTableBgColorHover,
  },
})
);

export const getStyledIconByType = (type, isCountIcon) => styled(getIconByResourceType(type), {
    shouldForwardProp: isPropValid,
  })({
      gridArea: 'icon',
      fontSize: 19,
      marginTop: isCountIcon ? -4 : 0,
      width: 26,
      paddingRight: 7,
    },
    isCountIcon && getResponsiveCSS('marginRight', 4, 8),
    ({ theme, isExpandable, isCurrentLevel }) => ({
      color: isCurrentLevel ? theme.palette.novotalk.$novotalkBlue : theme.palette.novotalk.$textColorLightGray,
      cursor: isExpandable ? 'pointer' : 'auto',
    })
  );

export const NameContainer = styled('div')({
  gridArea: 'name',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  marginRight: 20,
});

export const NameText = styled(Text)({
    whiteSpace: 'nowrap',
  },
  ({ isExpandable }) => ({
    cursor: isExpandable ? 'pointer' : 'auto',
  })
);

export const InfoWrapper = styled('div')({
    gridArea: 'info',
    display: 'flex',
    alignItems: 'center',
  },
  getResponsiveCSS('marginRight', 0, 24)
);

export const CountWrapper = styled('div')(getResponsiveCSS('marginRight', 16, 26));

export const StyledOverviewButton = styled(OverviewButton)(
  ({ hideButton }) => ({
    height: 32,
    display: hideButton && 'none',
  }),
  getResponsiveCSS('marginLeft', 6, 8, '&:last-of-type')
);

export const IconWrapper = styled('div')({
  gridArea: 'status',
  display: 'flex',
  alignItems: 'center',
  marginTop: 1,
  height: 21,
  width: 40,
});

export const Lock = styled(getIconsByType(iconTypes.LOCK))({
    fontSize: 18,
  },
  ({ theme }) => ({
    color: theme.palette.novotalk.$backgroundColorDark,
  })
);

export const CheckMark = styled(getIconsByType(iconTypes.BOLD_CHECKMARK))({
    fontSize: 16,
  },
  ({ theme }) => ({
    color: theme.palette.novotalk.$completedColor,
  })
);

export const ArrowRight = styled(getIconsByType(iconTypes.THIN_ARROW_RIGHT))({
    fontSize: 22,
  },
  ({ theme }) => ({
    color: theme.palette.novotalk.$novotalkBlue,
  })
)
