import gql from 'graphql-tag';
import { graphMutation } from '../commService';

export const createNewPost = async ({ parentId, parentType, text }) => {
  const mutation = gql`mutation {
    createPost(parentId:"${parentId}",parentType: "${parentType}",data:"""${text}""") {
      id
      data
      createdAt
      publisherUser{
        userName
        pic
      }
    }
  }`;

  const res = await graphMutation(mutation);
  const result = res.data.createPost;
  return result;
};
