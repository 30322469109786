/**
*
* WidgetsSelection
*
*/

import React from 'react';
import PropTypes from 'prop-types';

import WidgetItem from './components/WidgetItem';
import ArrowBox from '@components/ArrowBox';
import {
  WidgetsContainerPopover,
  WidgetsSelectionContainer,
  WidgetsButton,
  PlusIcon,
} from './styles';

class WidgetsSelection extends React.Component { // eslint-disable-line react/prefer-stateless-function
  constructor(props) {
    super(props);
    this.state = {
      open: false,
    };

    this.handleClickOutside = this.handleClickOutside.bind(this);
  }

  componentDidMount() {
    document.addEventListener('mousedown', this.handleClickOutside);
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleClickOutside);
  }

  onWidgetSelect = (widget) => {
    this.props.onSelect(widget);
    this.toggleOpen();
  }

  toggleOpen = () => {
    const { open } = this.state;
    this.setState({ open: !open });
  }

  handleClickOutside(event) {
    if (this.state.open &&
      this.node &&
      !this.node.contains(event.target)
    ) {
      this.toggleOpen();
    }
  }

  renderAvailableWidgets = (widgets) =>
    (
      <ArrowBox>
        {widgets.map((widget) =>
          (<WidgetItem
            onSelect={this.onWidgetSelect}
            key={widget.payload.title}
            widget={widget}
          />)
        )}
      </ArrowBox>
    )

  render() {
    const { open } = this.state;
    const { widgets } = this.props;
    return (
      <WidgetsSelectionContainer ref={(node) => { this.node = node; }} >
        <WidgetsButton
          id="addWidgetsButton"
          onClick={this.toggleOpen}
        >
          <PlusIcon />
        </WidgetsButton>
        <WidgetsContainerPopover
          open={open}
        >
          {this.renderAvailableWidgets(widgets)}
        </WidgetsContainerPopover>
      </WidgetsSelectionContainer>
    );
  }
}

WidgetsSelection.propTypes = {
  widgets: PropTypes.array,
  onSelect: PropTypes.func,
};

export default WidgetsSelection;
