import React from 'react';
import PropTypes from 'prop-types';
import ToggleGroup from '../../shared/components/ToggleGroup';
import {
  STIMULUS_WORDS_COUNT,
} from '../../../../../../../types';

const paragraphWords = [
  { value: STIMULUS_WORDS_COUNT.SHORT, message: 'shortParagraphs' },
  { value: STIMULUS_WORDS_COUNT.MEDIUM, message: 'mediumParagraphs' },
  { value: STIMULUS_WORDS_COUNT.LONG, message: 'longParagraphs' },
];


const SyllablesConfig = ({ config, updateConfig }) => (
  <ToggleGroup
    config={config}
    onChange={updateConfig}
    groupName="wordCount"
    groupValues={paragraphWords}
  />
);

SyllablesConfig.propTypes = {
  config: PropTypes.object,
  updateConfig: PropTypes.func,
};

export default SyllablesConfig;
