import { RESOURCES } from '@shared/Resources/types';

export const courseMock = {
  type: RESOURCES.COURSE,
  name: 'my generic course',
  id: '1',
  children: [
    {
      id: '1',
      type: RESOURCES.SKILL,
    },
    {
      id: '2',
      type: RESOURCES.SKILL,
    },
    {
      id: '2',
      type: RESOURCES.SKILL,
    },
  ],
};

export const gql = `{
  //common
  type
  id
  createdBy
  name

  // special
  description
  children:{
    id
    type
  }


  //----- edges:
  entityCreated{
    by
    at
  }
  entityUpdated {
    by
    at
  }
  //array
  usedIn{
    type
    id
  }
}`;


export const coursesList = [
  { id: 1, name: 'Intro to 2 Seconds Pattern', created: 'Thu Aug 09 2018 08:46:00 GMT+0000 (UTC)', updated: 'Thu SEP 06 2018 08:46:00 GMT+0000 (UTC)' },
  { id: 2, name: '2 Seconds Pattern', created: 'Thu Aug 16 2018 18:46:30 GMT+0000 (UTC)', updated: 'Thu SEP 13 2018 18:46:30 GMT+0000 (UTC)' },
  { id: 3, name: 'Advanced 2 Second Pattern', created: 'Thu Aug 23 2018 02:46:54 GMT+0000 (UTC)', updated: 'Thu SEP 20 2018 02:46:54 GMT+0000 (UTC)' },
  { id: 4, name: '1 Second Pattern', created: 'Thu Aug 30 2018 23:46:15 GMT+0000 (UTC)', updated: 'Thu SEP 27 2018 23:46:15 GMT+0000 (UTC)' },
  { id: 5, name: '2 Second Pattern', created: 'Thu Aug 30 2018 23:46:15 GMT+0000 (UTC)', updated: 'Thu SEP 27 2018 23:46:15 GMT+0000 (UTC)' },
  { id: 6, name: 'Advanced 1 Second Pattern', created: 'Thu Aug 30 2018 23:46:15 GMT+0000 (UTC)', updated: 'Thu SEP 27 2018 23:46:15 GMT+0000 (UTC)' },
];
