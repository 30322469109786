import React, { Component } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import Text, { TEXT_COLOR } from '@components/Text';
import {
  Wrapper,
  RadioOffIcon,
  RadioOnIcon,
  AnswerWrapper,
  SelectWrapper,
  IconWrapper,
} from './styles';
import { StyledQuestionIcon } from '../../styles';
import PollQuestionTitle from '../PollQuestionTitle';
import { QUESTION_TYPES } from '../../../../types';
import MiniOverviewHeader from '../../shared/MiniOverviewHeader';

export class SingleSelectQuestion extends Component {
  constructor(props) {
    super(props);

    this.state = {
      selectedAnswer: null,
    };
  }

  handleClick(selectedAnswer) {
    const { updateAnswer } = this.props;
    if (selectedAnswer !== this.state.selectedAnswer) {
      this.setState({ selectedAnswer });
      updateAnswer(selectedAnswer)
    }
  }

  renderOptions = () => {
    const {
      questionIndex,
      answers,
      isDisabled,
      userAnswer,
      miniOverview,
    } = this.props;
    const { selectedAnswer } = this.state;

    return (
      <SelectWrapper
        id={`single_select_${questionIndex}`}
        miniOverview={miniOverview}
      >
        {answers.map((answer) => {
          let icon;
          if (answer.id === (selectedAnswer || (isDisabled && userAnswer))) {
            icon = <RadioOnIcon isDisabled={isDisabled} />;
          } else {
            icon = (
              <RadioOffIcon
                isDisabled={isDisabled}
                miniOverview={miniOverview}
              />
            );
          }
          return (
            <AnswerWrapper
              isDisabled={isDisabled}
              key={answer.id}
              onClick={() => this.handleClick(answer.id)}
              aria-selected={
                answer.id === selectedAnswer || answer.id === userAnswer
              }
              aria-disabled={isDisabled}
              miniOverview={miniOverview}
            >
              <IconWrapper isDisabled={isDisabled} miniOverview={miniOverview}>
                {icon}
              </IconWrapper>
              <Text textColor={TEXT_COLOR.DARK_BLUE} size='H4'>
                {answer.value}
              </Text>
            </AnswerWrapper>
          );
        })}
      </SelectWrapper>
    );
  };

  render() {
    const {
      questionIndex,
      questionText,
      checkAnswers,
      isDisabled,
      miniOverview,
    } = this.props;
    const { selectedAnswer } = this.state;
    const error = checkAnswers && !selectedAnswer && !isDisabled;
    const titleProps = {
      questionIndex,
      questionText,
      error,
    };
    return miniOverview ? (
      <MiniOverviewHeader
        type={QUESTION_TYPES.SINGLE_SELECT}
        questionText={questionText}
      >
        {this.renderOptions()}
      </MiniOverviewHeader>
    ) : (
      <Wrapper>
        <StyledQuestionIcon />
        <PollQuestionTitle {...titleProps} />
        {this.renderOptions()}
      </Wrapper>
    );
  }
}

SingleSelectQuestion.propTypes = {
  isDisabled: PropTypes.bool,
  questionIndex: PropTypes.number,
  questionText: PropTypes.string,
  answers: PropTypes.array,
  updateAnswer: PropTypes.func,
  checkAnswers: PropTypes.bool,
  userAnswer: PropTypes.string,
  miniOverview: PropTypes.bool,
};

export default SingleSelectQuestion;
