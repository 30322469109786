import React from 'react';
import { OtherVideosContainer } from '../../styles';
import VideoCall from '../VideoCall';
import { useVideoCallContext } from '@shared/hooks/useVideoCallContext';

export const OtherVideos = () => {
  const { stateContext } = useVideoCallContext();
  return (
    <OtherVideosContainer id='subscribersWrapper'>
      {stateContext.chatEnded ? (
        <VideoCall.ChatEnded />
      ) : (
        <VideoCall.SubAndSharingContainer />
      )}
    </OtherVideosContainer>
  );
};
