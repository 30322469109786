import React, { useEffect } from 'react';
import {StyledArrowRightIcon, TableRow,} from "@shared/Resources/shared/components/ResourcesList/styles";
import {VirtualizedList} from './styles'
import {renderRowData} from "@shared/Resources/shared/components/ResourcesList/VirtualizedResourcesList/helpers";

const VirtualizedResourcesList = ({
         spread,
         items,
         displayData,
         selectedItem,
         resource,
         fetchMore,
         onSelect
     }) => {
        const errorIgnoreInVirtualizedList = (e)=>{
            if (e.message.includes('ResizeObserver')) {
                const resizeObserverErrDiv = document.getElementById(
                    'webpack-dev-server-client-overlay-div'
                );
                const resizeObserverErr = document.getElementById(
                    'webpack-dev-server-client-overlay'
                );
                if (resizeObserverErr) {
                    resizeObserverErr.setAttribute('style', 'display: none');
                }
                if (resizeObserverErrDiv) {
                    resizeObserverErrDiv.setAttribute('style', 'display: none');
                }
            }
        }
        useEffect(() => {
        window.addEventListener('error', errorIgnoreInVirtualizedList);
            return ()=>{
                window.removeEventListener('error',errorIgnoreInVirtualizedList)
            }
        }, []);
        return (
            <VirtualizedList
                data={items}
                endReached={(lastItemIndex) => {
                    const {type, isLastPage, offset, searchText} = resource
                    !isLastPage && lastItemIndex + 1 === offset && fetchMore(type, searchText, 'scroll')
                }}
                itemContent={(_, item) => {
                    const isSelected = selectedItem && selectedItem.id === item.id;
                    return <TableRow
                        isSelected={isSelected}
                        className='resource'
                        onClick={() => onSelect(item)}
                        key={item.id}
                        spread={spread}
                    >
                        {renderRowData(displayData, item, isSelected)}
                        {isSelected && <StyledArrowRightIcon/>}
                    </TableRow>;
                }
                }
            />
        );
    };

export default VirtualizedResourcesList;