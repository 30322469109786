export const TYPES = {
  GET_CHAT_SUMMARY_POLL: 'GET_CHAT_SUMMARY_POLL',
};

export function getChatSummaryPoll(hebrewMock) {
  return {
    type: TYPES.GET_CHAT_SUMMARY_POLL,
    hebrewMock,
  };
}

