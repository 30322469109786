import React from 'react';
import _ from 'lodash';
import PropTypes from 'prop-types';
import Text, { TEXT_SIZE, TEXT_COLOR, TEXT_WEIGHT } from '@components/Text';
import Select from '@components/Select';
import AnswersEditor from './Components/AnswersEditor';
import QuestionInfoDummyDate from './Components/QuestionInfoDummyDate';
import { QUESTION_TYPES } from '../../types';
import {
  QuestionContainer,
  Title,
  QuestionInput,
  SelectWrapper,
  OptionsWrapper,
  getStyledOptionIcon,
  InfoText,
} from './styles';
import EditorComponentWrapper from '@shared/Resources/shared/components/BuilderComponentWrapper';
import {withTranslation} from "react-i18next";
import {compose} from "redux";

const TypesWithNoAnswers = [QUESTION_TYPES.DATE, QUESTION_TYPES.FREE_TEXT];

class EditorQuestion extends React.Component {
  constructor(props) {
    super(props);
    this.options = this.createOptionsFromEditorTypes();
    const { payload } = props;
    const selectedOption = _.find(this.options, { value: payload.type });
    if (!payload.answers) payload.answers = [];
    this.state = {
      payload,
      selectedOption,
    };
  }

  onDescriptionChange = (event) => {
    const { value } = event.target;
    const { payload } = this.state;
    payload.questionText = value;
    this.setState({ payload });
    this.props.submitPayload(payload);
  };

  onTypeSelected = (selectedOption) => {
    const { value } = selectedOption;
    const { payload } = this.state;
    payload.type = value;
    if (TypesWithNoAnswers.includes(value)) {
      payload.answers = [];
    }
    this.setState({ payload, selectedOption });
    this.props.submitPayload(payload);
  };

  getOptionsMessage = (type) => {
    const {t} = this.props
    switch (type) {
      case QUESTION_TYPES.SINGLE_SELECT:
        return t('UI.optionTextSingleSelect');
      case QUESTION_TYPES.MULTI_SELECT:
        return t('UI.optionTextMultiSelect');
      case QUESTION_TYPES.DATE:
        return t('Dates.optionTextDate');
      case QUESTION_TYPES.DROP_DOWN:
        return t('UI.optionTextDropDown');
      default:
        return t('UI.freeText');
    }
  };

  getOptionForType = (type) => {
    const Icon = getStyledOptionIcon(type);
    return (
      <OptionsWrapper className='questionTypeOption'>
        <Icon />
        {this.getOptionsMessage(type)}
      </OptionsWrapper>
    );
  };

  getInfoMessage = (type) => {
    const {t} = this.props
    switch (type) {
      case QUESTION_TYPES.SINGLE_SELECT:
        return t('Informative.Notifying.infoSingleSelect');
      case QUESTION_TYPES.MULTI_SELECT:
        return t('Informative.Notifying.infoMultiSelect');
      case QUESTION_TYPES.DATE:
        return t('Informative.Notifying.infoDate');
      case QUESTION_TYPES.DROP_DOWN:
        return t('UI.optionTextDropDown');
      default:
        return null;
    }
  };

  updateAnswers = (answers) => {
    const { payload } = this.state;
    payload.answers = answers;
    this.setState({ payload });
    this.props.submitPayload(payload);
  };

  createOptionsFromEditorTypes = () =>
    _.values(QUESTION_TYPES)
      .filter((type) => type !== QUESTION_TYPES.RATING)
      .map((questionType) => ({
        value: questionType,
        label: this.getOptionForType(questionType),
      }));

  renderExtraInfo = () => {
    const { type, answers } = this.props.payload;
    if (type === QUESTION_TYPES.FREE_TEXT) return null;
    const infoComponent = TypesWithNoAnswers.includes(type) ? (
      <QuestionInfoDummyDate />
    ) : (
      <AnswersEditor answers={answers} onChange={this.updateAnswers} />
    );
    const infoMessage = this.getInfoMessage(type);
    return (
      <React.Fragment>
        {infoComponent}
        <InfoText>
          <Text size={TEXT_SIZE.T3} textColor={TEXT_COLOR.DISABLED}>
            {infoMessage &&  (
                <span className='infoText'>
                  {infoMessage}
                </span>
            )}
          </Text>
        </InfoText>
      </React.Fragment>
    );
  };

  render() {
    const {t} = this.props
    const { questionText } = this.state.payload;
    const { selectedOption } = this.state;
    return (
      <QuestionContainer>
        <Title>
          <Text
            size={TEXT_SIZE.T4}
            textColor={TEXT_COLOR.DARK_BLUE}
            weight={TEXT_WEIGHT.BOLD}
            textTransform='uppercase'
          >
            {t('UI.question')}
          </Text>
        </Title>
        <QuestionInput
          autoFocus
          value={questionText}
          className='questionTextInput'
          onChange={this.onDescriptionChange}
          placeholder={t('Common.Placeholders.question')}
          error={questionText.length === 0}
        />
        <SelectWrapper>
          <Select
            className='questionTypeSelect'
            value={selectedOption}
            options={this.options}
            onChange={this.onTypeSelected}
            width={325}
          />
        </SelectWrapper>
        {this.renderExtraInfo()}
      </QuestionContainer>
    );
  }
}

EditorQuestion.propTypes = {
  payload: PropTypes.object,
  submitPayload: PropTypes.func,
};

export default EditorComponentWrapper(
    compose(
        withTranslation()
    )(EditorQuestion)
);
