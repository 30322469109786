import _ from 'lodash';
import {
  BILLING_SERVER,
  BILLING_ENDPOINTS,
} from '@services/servers';
import { postRequest, graphQuery } from '@services/commService';

export const billSubscribe = async (stripeToken, couponCode, planId) => {
  try {
    const response = await postRequest(
      BILLING_SERVER,
      BILLING_ENDPOINTS.SUBSCRIBE,
      { stripeToken, couponCode, planId }
    );
    return response.body;
  } catch (error) {
    throw new Error(error.message);
  }
};

export const getPlans = async (entityId, couponCode) => {
  const coupon = couponCode ? `couponCode: "${couponCode}"` : '';
  const query = `
  {
    plans (entityId: "${entityId}" ${coupon}) {
      planDescription
      planId
      type
      entityId
      chargeFrequency
      days
      price{
        currency
        amount
      }
    }
  }
`;
  const res = await graphQuery(query, true);
  const plans = _.get(res, 'data.plans', []);
  return plans;
};
