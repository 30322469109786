import {styled} from "@mui/material";

const Container = styled('div')(
  {
    display: 'flex',
  },
  ({ column, alignItems, margin }) => ({
    flexDirection: column ? 'column' : 'row',
    alignItems: alignItems || 'center',
    margin,
  })
);

export default Container;
