import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { withRouter } from 'react-router-dom';
import injectReducer from '@utils/injectReducer';
import injectSaga from '@utils/injectSaga';
import _ from 'lodash';
import Text, { TEXT_SIZE, TEXT_COLOR } from '@components/Text';
import SectionLoading from '@components/SectionLoading';
import { ROUTES, USE_PAID_SYSTEM_VERSION } from '@shared/constants';

import UserDetails from './components/UserDetails';
import PlanDetails from './components/PlanDetails';
import { Wrapper, Header } from './styles';
import {
  getUserInfoAction,
  changePasswordAction,
  cancelSubscriptionAction,
} from './actions';
import { openPopUp as openPopUpAction } from '../PopUp/actions';
import POPUP_TYPES from '../PopUp/types';
import saga from './saga';
import reducer from './reducer';
import {withTranslation} from "react-i18next";

export class Settings extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isSubscriptionCancelled: false,
    };
  }

  componentDidMount() {
    const { getUserInfo } = this.props;
    getUserInfo();
  }

  componentDidUpdate(prevProps) {
    const {
      openPopUp,
      loadingPasswordChange,
      changePasswordResponseError,
    } = this.props;
    if (!loadingPasswordChange && prevProps.loadingPasswordChange) {
      openPopUp(POPUP_TYPES.CHANGE_PASSWORD_CONFIRMATION, {
        isChangeSuccessful: !changePasswordResponseError,
        textError: changePasswordResponseError,
      });
    }
  }

  onSubmitPassword = (oldPassword, newPassword) => {
    this.props.changePassword(oldPassword, newPassword);
  };

  handleSubscriptionButtonClick = (isSubscriptionValid) => {
    const { openPopUp, history } = this.props;
    const { isSubscriptionCancelled } = this.state;
    if (!isSubscriptionCancelled && isSubscriptionValid) {
      openPopUp(POPUP_TYPES.CANCEL_SUBSCRIPTION, {
        onCancelSubscription: this.cancelSubscription,
      });
    } else {
      history.push(ROUTES.BILLING);
    }
  };

  handleChangePassword = () => {
    const { openPopUp, user, userHasRole } = this.props;
    openPopUp(POPUP_TYPES.CHANGE_PASSWORD, {
      userName: user.fullName,
      onSubmitPassword: this.onSubmitPassword,
      userHasRole,
      disableBackdrop: true,
    });
  };

  cancelSubscription = () => {
    const planId = this.props.user.plans[0].planId;
    this.setState({ isSubscriptionCancelled: true });
    this.props.cancelSubscription(planId);
  };

  render() {
    const {
      user,
      course,
    } = this.props;
    const { isSubscriptionCancelled } = this.state;
    if (_.isEmpty(user)) {
      return (
        <Wrapper>
          <SectionLoading />
        </Wrapper>
      );
    }

    return (
      <Wrapper>
        <Header>
          <Text size={TEXT_SIZE.H1} textColor={TEXT_COLOR.DARK_BLUE}>
            {this.props.t('Common.UI.settings')}
          </Text>
        </Header>
        <UserDetails user={user} onChangePassword={this.handleChangePassword} />
        {USE_PAID_SYSTEM_VERSION && !_.isEmpty(user.plans) && !_.isEmpty(course) && (
          <PlanDetails
            course={course}
            planInfo={user.plans[0]}
            onSubscriptionButtonClick={this.handleSubscriptionButtonClick}
            isSubscriptionCancelled={isSubscriptionCancelled}
          />
        )}
      </Wrapper>
    );
  }
}

Settings.propTypes = {
  user: PropTypes.object,
  course: PropTypes.object,
  getUserInfo: PropTypes.func,
  openPopUp: PropTypes.func,
  history: PropTypes.object,
  changePassword: PropTypes.func,
  cancelSubscription: PropTypes.func,
  loadingPasswordChange: PropTypes.bool,
  changePasswordResponseError: PropTypes.string,
  userHasRole: PropTypes.bool,
};

export const mapStateToProps = (state) => {
  const { user, course, loadingPasswordChange, changePasswordResponseError } =
    state.get('settings').toJS();
  const userHasRole = state.getIn([
    'app',
    'authentication',
    'user',
    'experimentalFeatures',
  ]);
  return {
    user,
    course,
    loadingPasswordChange,
    changePasswordResponseError,
    userHasRole,
  };
};

export function mapDispatchToProps(dispatch) {
  return {
    getUserInfo: () => dispatch(getUserInfoAction()),
    openPopUp: (type, props) => dispatch(openPopUpAction(type, props)),
    changePassword: (oldPassword, newPassword) =>
      dispatch(changePasswordAction(oldPassword, newPassword)),
    cancelSubscription: (planId) => dispatch(cancelSubscriptionAction(planId)),
  };
}

const withConnect = connect(mapStateToProps, mapDispatchToProps);
const withReducer = injectReducer({ key: 'settings', reducer });
const withSaga = injectSaga({ key: 'settings', saga });


export default compose(
  withReducer,
  withRouter,
  withSaga,
  withConnect,
    withTranslation()
)(Settings);
